import React, { FC, memo, useCallback } from 'react'

import { useFormData } from 'brief-form'
import { Request } from 'commons/utils/request'
import { useTableState } from 'query-table'
import { Page, PageFace } from 'ui/components/Page'
import { Panel, PanelFace } from 'ui/components/Panel'

import { ExportCSVButton, ReportBreadcrumbs } from '../../components'
import { DocumentsReportFilter } from './DocumentsReportFilter'
import { Table } from './components/Table'

const SiteAuditDocumentsReport: FC = memo(() => {
  const tableState = useTableState({
    filter: {},
    order: [{ field: 'relationship_name', direction: 'ASC' }],
    pagination: {
      page: 1,
      size: 20
    }
  })

  const filterForm = useFormData<DocumentsReportFilter>({
    initialValue: {
      customer_status: '',
      frequency: '',
      license_number: '',
      relationship_id: 0,
      relationship_name: ''
    }
  })

  const originalExportsChange = filterForm.config.onChange
  filterForm.config.onChange = useCallback(
    (v, e) => {
      tableState.pagination.change(1, tableState.pagination.size)
      originalExportsChange(v, e)
    },
    [originalExportsChange, tableState]
  )

  return (
    <Page
      face={PageFace.PRIMARY}
      title={<ReportBreadcrumbs title="Site Audit Documents Report" />}
      actions={
        <ExportCSVButton
          reportName="Site Audit Documents"
          url={Request.urls.bank.portalReportSiteCSV}
          parametersForm={filterForm.config.value}
        />
      }
    >
      <Panel rounded shadowed collapsible={false} face={PanelFace.SECONDARY}>
        <Table tableState={tableState} filterForm={filterForm} />
      </Panel>
    </Page>
  )
})

export default SiteAuditDocumentsReport
