import { QueryClient, useMutation } from 'react-query'

import { uploadBatchFile } from 'commons/service/bank/uploadBatchFile'
import { Toast } from 'ui/components/Toast'

export const useUploadBatchFile = (client: QueryClient) =>
  useMutation((formData: FormData) => uploadBatchFile(formData), {
    onError: (error: Error) => {
      // TODO: temporary solution for demo
      if (!error.message) {
        Toast.errorSmall('Something went wrong...')
      }
    },
    onSuccess: async (_, item) => {
      await client.invalidateQueries(['tables', 'CTR Batch List'])
      await client.invalidateQueries(['batches', (item as any).batch_id])
    }
  })
