import styled from 'styled-components'
import { Chip } from 'ui/components'

export const BadgeSection = styled.div`
  padding-top: 32px;
  padding-left: 32px;
  padding-bottom: 12px;
  max-width: 595px;
`

export const BadgesGroup = styled.div`
  display: inline-block;
  margin-top: -8px;
`

export const BadgeText = styled(Chip)`
  font-size: 14px;
  border-radius: 16px;
  text-transform: none;
  padding-top: 1px;
  padding-bottom: 1px;
`
