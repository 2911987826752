import styled from 'styled-components'

const Box = styled.footer`
  z-index: 1000;
  background: #fff;
  height: 66px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 50px 0 50px;
  border-top: 1px solid ${(p) => p.theme.uiKit.colors.global.grey.grey300};
`

const FooterTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.38px;
  margin-right: auto;
  color: ${(p) => p.theme.uiKit.colors.global.grey.grey700};
`

export const s = { Box, FooterTitle }
