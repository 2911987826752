/* eslint-disable camelcase */

export type History = {
  action?: number
  comment?: string
  created_at?: string
  entity_id?: number
  entity_type?: number
  id?: number
  // eslint-disable-next-line
  new?: any
  // eslint-disable-next-line
  old?: any
  organization_id?: number
  user_id?: string
  user_type?: number
}
