import { createRoute, redirect } from '@tanstack/react-router'

import { onboardingDocumentsCloneRoute } from './routes/OnboardingDocumentsCloneRoute'
import { onboardingDocumentsCreateRoute } from './routes/OnboardingDocumentsCreateRoute'
import { onboardingDocumentsEditRoute } from './routes/OnboardingDocumentsEditRoute'
import { setupDocumentsViewDetailsRoute } from './routes/SetupDocumentsViewDetails'

export const routeOnboardingDocumentsTree = (rootRoute: BPRootRoute) => {
  const onboardingDocumentsIndexRoute = createRoute({
    beforeLoad: async ({ context }) => {
      if (!context.accessibleRoutingFlags.setupOnboardingDocumentsRootAccess) {
        throw redirect({
          to: '/'
        })
      }
    },
    getParentRoute: () => rootRoute,
    path: 'application/$applicationId/onboarding-documents'
  })

  return onboardingDocumentsIndexRoute.addChildren([
    onboardingDocumentsCreateRoute<typeof onboardingDocumentsIndexRoute>(onboardingDocumentsIndexRoute),
    onboardingDocumentsEditRoute<typeof onboardingDocumentsIndexRoute>(onboardingDocumentsIndexRoute),
    onboardingDocumentsCloneRoute<typeof onboardingDocumentsIndexRoute>(onboardingDocumentsIndexRoute),
    setupDocumentsViewDetailsRoute<typeof onboardingDocumentsIndexRoute>(onboardingDocumentsIndexRoute)
  ])
}
