import * as React from 'react'

import SC from 'styled-components'

import { AnnualReviewQuestion } from '../../../AnnualReviewQuestions'
// eslint-disable-next-line max-len
import { AnnualReviewQuestionColumn } from '../../../AnnualReviewQuestions/AnnualReviewQuestion/AnnualReviewQuestionColumn'
import { AnnualReviewQuestionsSet } from '../../../AnnualReviewQuestions/AnnualReviewQuestion/AnnualReviewQuestionsSet'

const Questions = SC.div`
  margin-top: 34px;
`

export interface AnnualReviewLicensingQuestionsProps {
  licensingNamePrior?: React.ReactNode
  licensingLocChanges?: React.ReactNode
  licensingNameChanges?: React.ReactNode
  licensingLocChangesMmcc?: React.ReactNode
  licensingNameChangesMmcc?: React.ReactNode
  priorName?: React.ReactNode
  locationChanges?: boolean
  nameChanges?: boolean
}

export const AnnualReviewLicensingQuestions = ({
  licensingNamePrior,
  licensingLocChanges,
  licensingNameChanges,
  licensingLocChangesMmcc,
  licensingNameChangesMmcc,
  locationChanges,
  nameChanges
}: AnnualReviewLicensingQuestionsProps): JSX.Element => (
  <Questions>
    <AnnualReviewQuestionsSet>
      <AnnualReviewQuestionColumn>
        <AnnualReviewQuestion label="Any location changes in the last year?">
          {licensingLocChanges}
        </AnnualReviewQuestion>
      </AnnualReviewQuestionColumn>
      <AnnualReviewQuestionColumn>
        {(locationChanges || licensingLocChanges === 'Yes') && (
          <AnnualReviewQuestion label="If yes, approved by MMCC?">{licensingLocChangesMmcc}</AnnualReviewQuestion>
        )}
      </AnnualReviewQuestionColumn>
    </AnnualReviewQuestionsSet>
    <AnnualReviewQuestionsSet>
      <AnnualReviewQuestionColumn>
        <AnnualReviewQuestion label="Any name changes in the last year?">{licensingNameChanges}</AnnualReviewQuestion>
      </AnnualReviewQuestionColumn>
      <AnnualReviewQuestionColumn>
        {(nameChanges || licensingNameChanges === 'Yes') && (
          <AnnualReviewQuestion label="If yes, approved by MMCC?">{licensingNameChangesMmcc}</AnnualReviewQuestion>
        )}
      </AnnualReviewQuestionColumn>
    </AnnualReviewQuestionsSet>
    <AnnualReviewQuestionsSet>
      <AnnualReviewQuestionColumn>
        {licensingNamePrior && <AnnualReviewQuestion label="Prior Name:">{licensingNamePrior}</AnnualReviewQuestion>}
      </AnnualReviewQuestionColumn>
    </AnnualReviewQuestionsSet>
  </Questions>
)
