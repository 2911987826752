/* eslint-disable camelcase */

export type InternalTransfersExport = {
  id?: number
  created_at?: string
  credit_file_s3_key?: string
  debit_file_s3_key?: string
  csv_file_s3_key?: string
  deleted_at?: string
  organization_id?: number
  processing_date?: string
  sf_external_id?: string
  status?: string
  updated_at?: string
}
