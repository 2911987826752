import React from 'react'

import { AnnualReviewSalesDeposits, BankAccountDeprecated, LicenseDeprecated } from 'commons/types/DTO/bank'
import { formatCurrencyCent } from 'commons/utils'
import SC from 'styled-components'
import { TextMask } from 'ui/components/TextMask'

interface SalesAndDepositTableProps {
  salesAndDeposits: AnnualReviewSalesDeposits
  bankAccounts: BankAccountDeprecated[]
  license?: LicenseDeprecated
}

const Table = SC.table`
  width: 100%;
  border-collapse: collapse;
`

const Th = SC.td`
  text-align: center;
  font-weight: bold;
  border: 1px solid ${(p) => p.theme.uiKit.colors.global.grey.grey300};
  padding: 10px;
  background: ${(p) => p.theme.uiKit.colors.global.grey.grey300};
  
  @media print {
    background: ${(p) => p.theme.uiKit.colors.global.grey.grey300};
    -webkit-print-color-adjust: exact;
  }
`

const Td = SC.td`
  text-align: center;
  border: 1px solid ${(p) => p.theme.uiKit.colors.global.grey.grey300};
  padding: 10px;
`
const Text = SC.span``
const Title = SC.h3``
const Hr = SC.div`
  width: 100%;
  border-bottom: 2px solid #000;
  margin-bottom: 20px;
`

export const SalesAndDepositTable = ({
  salesAndDeposits,
  bankAccounts,
  license
}: SalesAndDepositTableProps): JSX.Element | null => {
  const bankAccount = bankAccounts.find((bankAccount) => bankAccount.id === salesAndDeposits.bank_account_id)

  const totalCashDeposit = salesAndDeposits.quarters_all_info?.cash

  const totalCashInvoices = salesAndDeposits.quarters_all_info?.invoices

  const totalTransfers = salesAndDeposits.quarters_all_info?.transfers

  const totalChecksAndRemote = salesAndDeposits.quarters_all_info?.checks

  const totalDebitCredit = salesAndDeposits.quarters_all_info?.credit_debit

  const totalOther = salesAndDeposits.quarters_all_info?.other

  return (
    <div>
      <Title>
        Bank Account $ <TextMask text={bankAccount?.number} isBankAccount alwaysHidden />
      </Title>
      <Hr />

      <Text>
        <b>Sales summary</b>
      </Text>
      <br />
      <br />

      <Table>
        <thead>
          <tr>
            <Th>
              <Text>Name</Text>
            </Th>
            <Th>
              <Text>{salesAndDeposits?.quarter1_info?.label}</Text>
            </Th>
            <Th>
              <Text>{salesAndDeposits?.quarter2_info?.label}</Text>
            </Th>
            <Th>
              <Text>{salesAndDeposits?.quarter3_info?.label}</Text>
            </Th>
            <Th>
              <Text>{salesAndDeposits?.quarter4_info?.label}</Text>
            </Th>
            <Th>
              <Text>Total</Text>
            </Th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <Td>
              <Text>Deposits</Text>
            </Td>
            <Td>
              <Text>{formatCurrencyCent(salesAndDeposits.quarter1_info?.deposits || '')}</Text>
            </Td>
            <Td>
              <Text>{formatCurrencyCent(salesAndDeposits.quarter2_info?.deposits || '')}</Text>
            </Td>
            <Td>
              <Text>{formatCurrencyCent(salesAndDeposits.quarter3_info?.deposits || '')}</Text>
            </Td>
            <Td>
              <Text>{formatCurrencyCent(salesAndDeposits.quarter4_info?.deposits || '')}</Text>
            </Td>
            <Td>
              <Text>
                {formatCurrencyCent(
                  (salesAndDeposits?.quarter1_info?.deposits || 0) +
                    (salesAndDeposits?.quarter2_info?.deposits || 0) +
                    (salesAndDeposits?.quarter3_info?.deposits || 0) +
                    (salesAndDeposits?.quarter4_info?.deposits || 0)
                )}
              </Text>
            </Td>
          </tr>
          {license?.subtype === 'dispensary' && (
            <tr>
              <Td>
                <Text>Points of sale</Text>
              </Td>
              <Td>
                <Text>{formatCurrencyCent(salesAndDeposits.quarter1_info?.pos || '' || '')}</Text>
              </Td>
              <Td>
                <Text>{formatCurrencyCent(salesAndDeposits.quarter2_info?.pos || '')}</Text>
              </Td>
              <Td>
                <Text>{formatCurrencyCent(salesAndDeposits.quarter3_info?.pos || '')}</Text>
              </Td>
              <Td>
                <Text>{formatCurrencyCent(salesAndDeposits.quarter4_info?.pos || '')}</Text>
              </Td>
              <Td>
                <Text>
                  {formatCurrencyCent(
                    salesAndDeposits?.quarter1_info?.pos ||
                      '' + salesAndDeposits?.quarter2_info?.pos ||
                      '' + salesAndDeposits?.quarter3_info?.pos ||
                      '' + salesAndDeposits?.quarter4_info?.pos ||
                      ''
                  )}
                </Text>
              </Td>
            </tr>
          )}
          <tr>
            <Td>
              <Text>Sales</Text>
            </Td>
            <Td>
              <Text>{formatCurrencyCent(salesAndDeposits.quarter1_info?.sales || '')}</Text>
            </Td>
            <Td>
              <Text>{formatCurrencyCent(salesAndDeposits.quarter2_info?.sales || '')}</Text>
            </Td>
            <Td>
              <Text>{formatCurrencyCent(salesAndDeposits.quarter3_info?.sales || '')}</Text>
            </Td>
            <Td>
              <Text>{formatCurrencyCent(salesAndDeposits.quarter4_info?.sales || '')}</Text>
            </Td>
            <Td>
              <Text>{formatCurrencyCent(salesAndDeposits.quarters_all_info?.sales || '')}</Text>
            </Td>
          </tr>
          <tr>
            <Td>
              <Text>Revenue from financials</Text>
            </Td>
            <Td />
            <Td />
            <Td />
            <Td />
            <Td>
              <Text>{formatCurrencyCent(salesAndDeposits.total_financials || 0)}</Text>
            </Td>
          </tr>
        </tbody>
      </Table>
      <Hr style={{ margin: 0, transform: 'translateY(-2px)' }} />
      <br />

      <Text>
        <b>Deposit detail</b>
      </Text>
      <br />
      <br />

      <Table>
        <thead>
          <tr>
            <Th>
              <Text>Name</Text>
            </Th>
            <Th>
              <Text>{salesAndDeposits.quarter1_info?.label}</Text>
            </Th>
            <Th>
              <Text>{salesAndDeposits.quarter2_info?.label}</Text>
            </Th>
            <Th>
              <Text>{salesAndDeposits.quarter3_info?.label}</Text>
            </Th>
            <Th>
              <Text>{salesAndDeposits.quarter4_info?.label}</Text>
            </Th>
            <Th>
              <Text>Total</Text>
            </Th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <Td>
              <Text>Cash Deposit</Text>
            </Td>
            <Td>
              <Text>{formatCurrencyCent(salesAndDeposits.quarter1_info?.cash || '')}</Text>
            </Td>
            <Td>
              <Text>{formatCurrencyCent(salesAndDeposits.quarter2_info?.cash || '')}</Text>
            </Td>
            <Td>
              <Text>{formatCurrencyCent(salesAndDeposits.quarter3_info?.cash || '')}</Text>
            </Td>
            <Td>
              <Text>{formatCurrencyCent(salesAndDeposits.quarter4_info?.cash || '')}</Text>
            </Td>
            <Td>
              <Text>{formatCurrencyCent(totalCashDeposit || 0)}</Text>
            </Td>
          </tr>
          <tr>
            <Td>
              <Text>Cash invoices</Text>
            </Td>
            <Td>
              <Text>{formatCurrencyCent(salesAndDeposits.quarter1_info?.invoices || '')}</Text>
            </Td>
            <Td>
              <Text>{formatCurrencyCent(salesAndDeposits.quarter2_info?.invoices || '')}</Text>
            </Td>
            <Td>
              <Text>{formatCurrencyCent(salesAndDeposits.quarter3_info?.invoices || '')}</Text>
            </Td>
            <Td>
              <Text>{formatCurrencyCent(salesAndDeposits.quarter4_info?.invoices || '')}</Text>
            </Td>
            <Td>
              <Text>{formatCurrencyCent(totalCashInvoices || 0)}</Text>
            </Td>
          </tr>
          <tr>
            <Td>
              <Text>Internal transfers</Text>
            </Td>
            <Td>
              <Text>{formatCurrencyCent(salesAndDeposits.quarter1_info?.transfers || '')}</Text>
            </Td>
            <Td>
              <Text>{formatCurrencyCent(salesAndDeposits.quarter2_info?.transfers || '')}</Text>
            </Td>
            <Td>
              <Text>{formatCurrencyCent(salesAndDeposits.quarter3_info?.transfers || '')}</Text>
            </Td>
            <Td>
              <Text>{formatCurrencyCent(salesAndDeposits.quarter4_info?.transfers || '')}</Text>
            </Td>
            <Td>
              <Text>{formatCurrencyCent(totalTransfers || 0)}</Text>
            </Td>
          </tr>
          {license?.subtype !== 'dispensary' && (
            <tr>
              <Td>
                <Text>Checks & Remote Deposit</Text>
              </Td>
              <Td>
                <Text>{formatCurrencyCent(salesAndDeposits.quarter1_info?.checks || '')}</Text>
              </Td>
              <Td>
                <Text>{formatCurrencyCent(salesAndDeposits.quarter2_info?.checks || '')}</Text>
              </Td>
              <Td>
                <Text>{formatCurrencyCent(salesAndDeposits.quarter3_info?.checks || '')}</Text>
              </Td>
              <Td>
                <Text>{formatCurrencyCent(salesAndDeposits.quarter4_info?.checks || '')}</Text>
              </Td>
              <Td>
                <Text>{formatCurrencyCent(totalChecksAndRemote || 0)}</Text>
              </Td>
            </tr>
          )}
          {license?.subtype !== 'grow' && license?.subtype !== 'processor' && (
            <tr>
              <Td>
                <Text>Credit / Debit</Text>
              </Td>
              <Td>
                <Text>{formatCurrencyCent(salesAndDeposits.quarter1_info?.credit_debit || '')}</Text>
              </Td>
              <Td>
                <Text>{formatCurrencyCent(salesAndDeposits.quarter2_info?.credit_debit || '')}</Text>
              </Td>
              <Td>
                <Text>{formatCurrencyCent(salesAndDeposits.quarter3_info?.credit_debit || '')}</Text>
              </Td>
              <Td>
                <Text>{formatCurrencyCent(salesAndDeposits.quarter4_info?.credit_debit || '')}</Text>
              </Td>
              <Td>
                <Text>{formatCurrencyCent(totalDebitCredit || 0)}</Text>
              </Td>
            </tr>
          )}
          <tr>
            <Td>
              <Text>Other</Text>
            </Td>
            <Td>
              <Text>{formatCurrencyCent(salesAndDeposits.quarter1_info?.other || '')}</Text>
            </Td>
            <Td>
              <Text>{formatCurrencyCent(salesAndDeposits.quarter2_info?.other || '')}</Text>
            </Td>
            <Td>
              <Text>{formatCurrencyCent(salesAndDeposits.quarter3_info?.other || '')}</Text>
            </Td>
            <Td>
              <Text>{formatCurrencyCent(salesAndDeposits.quarter4_info?.other || '')}</Text>
            </Td>
            <Td>
              <Text>{formatCurrencyCent(totalOther || 0)}</Text>
            </Td>
          </tr>
          <tr>
            <Td>
              <Text>Total deposit</Text>
            </Td>
            <Td>
              <Text>
                {formatCurrencyCent(
                  (salesAndDeposits.quarter1_info?.cash || 0) +
                    (salesAndDeposits.quarter1_info?.invoices || 0) +
                    (salesAndDeposits.quarter1_info?.transfers || 0) +
                    (salesAndDeposits.quarter1_info?.checks || 0) +
                    (salesAndDeposits.quarter1_info?.credit_debit || 0) +
                    (salesAndDeposits.quarter1_info?.other || 0)
                )}
              </Text>
            </Td>
            <Td>
              <Text>
                {formatCurrencyCent(
                  (salesAndDeposits.quarter2_info?.cash || 0) +
                    (salesAndDeposits.quarter2_info?.invoices || 0) +
                    (salesAndDeposits.quarter2_info?.transfers || 0) +
                    (salesAndDeposits.quarter2_info?.checks || 0) +
                    (salesAndDeposits.quarter2_info?.credit_debit || 0) +
                    (salesAndDeposits.quarter2_info?.other || 0)
                )}
              </Text>
            </Td>
            <Td>
              <Text>
                {formatCurrencyCent(
                  (salesAndDeposits.quarter3_info?.cash || 0) +
                    (salesAndDeposits.quarter3_info?.invoices || 0) +
                    (salesAndDeposits.quarter3_info?.transfers || 0) +
                    (salesAndDeposits.quarter3_info?.checks || 0) +
                    (salesAndDeposits.quarter3_info?.credit_debit || 0) +
                    (salesAndDeposits.quarter3_info?.other || 0)
                )}
              </Text>
            </Td>
            <Td>
              <Text>
                {formatCurrencyCent(
                  (salesAndDeposits.quarter4_info?.cash || 0) +
                    (salesAndDeposits.quarter4_info?.invoices || 0) +
                    (salesAndDeposits.quarter4_info?.transfers || 0) +
                    (salesAndDeposits.quarter4_info?.checks || 0) +
                    (salesAndDeposits.quarter4_info?.credit_debit || 0) +
                    (salesAndDeposits.quarter4_info?.other || 0)
                )}
              </Text>
            </Td>
            <Td>
              <Text>
                {formatCurrencyCent(
                  (totalCashDeposit || 0) +
                    (totalCashInvoices || 0) +
                    (totalTransfers || 0) +
                    (totalChecksAndRemote || 0) +
                    (totalDebitCredit || 0) +
                    (totalOther || 0)
                )}
              </Text>
            </Td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
}
