import { TablePagination, useFilterFormState } from 'ui/components/Table'

import { AlertsReportPayload } from '../types'
import { useGetAlertsReport } from './useGetAlertsReport'

const emptyFilter: AlertsReportPayload = {
  page: 1,
  size: 20
}

export const useAlertsReport = () => {
  const filter = useFilterFormState({
    initialValue: emptyFilter
  })

  const { data, isFetching } = useGetAlertsReport(filter.value, {
    onSuccess: (response) => {
      if (filter.value.page === null && typeof response.form.page === 'number') {
        filter.set({ newValue: response.form, reset: true })
      }
    }
  })

  const pagination: TablePagination = {
    page: filter.value.page || null,
    pageSize: filter.value.size || null,
    total: data?.total,
    onChange: (page, size) => {
      filter.set({ newValue: { page, size } })
    }
  }

  return {
    data,
    isFetching,
    pagination
  }
}
