import { DepositsFromSales } from './DepositsFromSales'
import { OtherActivityInfo } from './OtherActivityInfo'

export type DepositsInfo = {
  depositsFromSales: DepositsFromSales
  editButtonTooltip: string
  enableEdit: boolean
  notes: string
  otherActivity: OtherActivityInfo
}
