import React from 'react'

import { Panel, PanelFace } from 'ui/components'

import { CompanySectionPanelProps } from './CompanySectionPanel.types'

export const CompanySectionPanel = ({
  title,
  children,
  actions = undefined,
  blockOnClickOnTitle = false
}: CompanySectionPanelProps) => (
  <Panel
    title={title}
    titleTagAs="h3"
    face={PanelFace.SMALL_PRIMARY}
    isArrowButton={true}
    rounded={true}
    shadowed={true}
    blockOnClickOnTitle={blockOnClickOnTitle}
    actions={actions}
  >
    {children}
  </Panel>
)
