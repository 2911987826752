import React, { FC, memo, useEffect, useMemo, useState } from 'react'

import { useNavigate } from '@tanstack/react-router'
import { ADVERSE_MEDIA_PATH } from 'commons/constants/routes'
import { AdverseMedia } from 'commons/types/DTO/bank/companyDetails/adverseMedia'
import { TableDataQa } from 'commons/types/enums'
import { handlerServerError } from 'errors'
import { DataSourceItem, Layer, Table } from 'ui/components'
import { PageSection, PageSectionFace } from 'ui/components/Page'

import { CompanyAdverseMediaCaseTableProps } from './CompanyAdverseMediaCaseTableProps'
import { columns } from './columns'
import { useComponentsEssence } from './hooks/useComponentsEssence'
import { useFilterCompanyAdverseMediaWebPage } from './hooks/useFilterCompanyAdverseMediaWebPage'

export const CompanyAdverseMediaCaseTable: FC<CompanyAdverseMediaCaseTableProps> = memo(
  ({ companyId, initialAdverseMedia, initialAdverseMediaTotalCount }) => {
    const navigate = useNavigate()

    const [webPageData, setWebPageData] = useState({
      adverseMedia: initialAdverseMedia,
      adverseMediaTotalCount: initialAdverseMediaTotalCount
    })

    useEffect(() => {
      setWebPageData({
        adverseMedia: initialAdverseMedia,
        adverseMediaTotalCount: initialAdverseMediaTotalCount
      })
    }, [initialAdverseMedia, initialAdverseMediaTotalCount])

    const { routine: filterCompanyAdverseMediaWebPage } = useFilterCompanyAdverseMediaWebPage({
      onSuccess: async (_, result) => {
        await setWebPageData(result)
      },
      onError: (error) => handlerServerError(error)
    })
    const { loading, tableState, changePagination } = useComponentsEssence(companyId, filterCompanyAdverseMediaWebPage)

    const mappedDocuments: DataSourceItem<AdverseMedia>[] = useMemo(
      () =>
        webPageData?.adverseMedia?.map((el) => ({
          item: el
        })),
      [webPageData]
    )

    const memoizedColumns = useMemo(
      () =>
        columns(
          {
            rootUrl: ADVERSE_MEDIA_PATH
          },
          navigate
        ),
      [navigate]
    )

    return (
      <PageSection face={PageSectionFace.PRIMARY} title="Adverse Media" anchor="adverse">
        <Layer rounded shadowed stretch>
          <Table<AdverseMedia>
            dataQa={TableDataQa.BP_COMPANY_ADVERSE_MEDIA_CASE}
            columns={memoizedColumns()}
            dataSource={mappedDocuments}
            loading={loading}
            pagination={{
              page: tableState.page,
              pageSize: tableState.size,
              onChange: changePagination,
              total: webPageData.adverseMediaTotalCount
            }}
          />
        </Layer>
      </PageSection>
    )
  }
)
