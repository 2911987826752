import React from 'react'

import { getAnnualReviewDetailUrl, getAnnualReviewEditUrl } from 'bank-annual-review/src/Routes/AnnualReview/route'
import { AnnualReview } from 'commons/types/DTO/bank/companyDetails/annualReview'
import { DateTime } from 'ui/components'
import { Link } from 'ui/components/Link'
import { MenuItemAlignment, MenuItemBehavior, MenuItemTextFace, TableActions } from 'ui/components/QueryTable'
import { TableColumn } from 'ui/components/Table'

interface Columns {
  onDeleteReview: (review: AnnualReview) => void
  companyId: number
  showCompany?: boolean
  inAlerts?: boolean
}

export const columns = ({ onDeleteReview, companyId, inAlerts }: Columns): TableColumn<any>[] => [
  {
    title: 'Annual Review Number',
    render: (item) => (
      <Link to="/annual-review/detail/$companyId/$id" params={{ companyId: companyId, id: item.id }}>
        {item.annualReviewNumber}
      </Link>
    )
  },
  {
    title: 'Financials Date',
    align: 'center',
    render: ({ financialsDate }) => <DateTime date={financialsDate} />
  },
  {
    title: 'Status',
    align: 'center',
    key: 'status'
  },
  {
    title: 'Date Completed',
    align: 'center',
    render: ({ dateCompleted }) => <DateTime date={dateCompleted} />
  },
  {
    title: 'Created Date',
    align: 'center',
    render: ({ createdDate }) => <DateTime date={createdDate} />
  },
  {
    title: 'Action',
    align: 'center',
    width: '150px',
    render: (item) => (
      <TableActions
        menuContainerId="companyArTableMenuContainer"
        items={(
          [
            item.status === 'New' || item.status === 'Incomplete'
              ? {
                  key: 1,
                  title: item.status === 'Incomplete' ? 'CONTINUE REVIEW' : 'START REVIEW',
                  textFace: MenuItemTextFace.GREY,
                  textAlign: MenuItemAlignment.CENTER,
                  url: getAnnualReviewEditUrl({ id: item.id, companyId: companyId })
                }
              : null,
            item.status === 'Completed'
              ? {
                  key: 2,
                  title: 'APPROVE',
                  textFace: MenuItemTextFace.GREY,
                  textAlign: MenuItemAlignment.CENTER,
                  url: getAnnualReviewDetailUrl({ id: item.id, companyId: companyId })
                }
              : null,
            item.status === 'Approved'
              ? {
                  key: 1,
                  title: 'Edit',
                  iconName: 'edit',
                  iconHoverFace: 'primary',
                  textFace: MenuItemTextFace.GREY,
                  url: getAnnualReviewEditUrl({ id: item.id, companyId: companyId })
                }
              : null,
            !inAlerts
              ? {
                  key: 3,
                  title: 'Delete',
                  behavior: MenuItemBehavior.BUTTON,
                  iconName: 'delete',
                  iconHoverFace: 'danger',
                  textFace: MenuItemTextFace.GREY,
                  onClick: () => onDeleteReview(item)
                }
              : null
          ] as any
        ).filter(Boolean)}
      />
    )
  }
]
