import styled from 'styled-components'
import { Icon } from 'ui/components/Icon'

export const ConductorTooltip = styled.div`
  display: flex;
`

export const ConductorIcon = styled(Icon)`
  margin-left: 10px;
`

export const BusinessTypeTooltip = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
`

export const BusinessTypeTooltipContent = styled.div`
  max-width: 500px;
`

export const BusinessTypeTooltipIcon = styled.div`
  outline: none;
  padding-top: 25px;
`
