import styled from 'styled-components'
import { Icon } from 'ui/components/Icon'

export const WrappedIcon = styled(Icon)`
  margin-right: 22px;

  svg {
    color: ${(p) => p.theme.uiKit.colors.global.general.main};
  }
`

export const WrappedIconGreen = styled(WrappedIcon)`
  svg {
    color: ${(p) => p.theme.uiKit.colors.theme.brand.primary};
  }
`

export const SmallToastIcon = styled(Icon)`
  margin-right: 10px;
  margin-left: 8px;
  display: flex;

  svg {
    color: ${(p) => p.theme.uiKit.colors.global.general.main};
  }
`

export const SmallToastIconGreen = styled(SmallToastIcon)`
  svg {
    color: ${(p) => p.theme.uiKit.colors.theme.brand.primary};
  }
`

export const SmallToastContent = styled.div.attrs({
  'data-qa': 'feedback-toast'
})`
  display: flex;
  align-items: center;
  line-height: 22px;
  font-size: 14px;
  font-weight: 400;
`

export const BigToastContent = styled.div.attrs({
  'data-qa': 'feedback-toast'
})`
  margin: 16px;
`

export const Title = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
`

export const Content = styled.div`
  margin-top: 10px;
  font-size: 13px;
`

export const Button = styled.button`
  border: none;
  background-color: transparent;
  width: 20px;
  height: 20px;
  padding: 0;
  margin-top: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`
