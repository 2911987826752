import { HistoryEntityType } from './enums'

export const getPathByEntityType = (type?: HistoryEntityType) => {
  if (!type) {
    return 'main-list'
  }

  switch (type) {
    case HistoryEntityType.BSA_BATCH:
      return 'bsa-batch'
    case HistoryEntityType.BSA_CIF_SUBJECT:
      return 'bsa-cif-subject'
    case HistoryEntityType.BSA_CTR:
      return 'bsa-ctr'
    case HistoryEntityType.BSA_CTR_BATCH:
      return 'bsa-ctr-batch'
    case HistoryEntityType.BSA_DDA_SUBJECT:
      return 'bsa-dda-subject'
    case HistoryEntityType.BSA_SETTING:
      return 'bsa-setting'
    case HistoryEntityType.COMPANY:
      return 'company'
    case HistoryEntityType.DOCUMENT:
      return 'document'
    case HistoryEntityType.DOCUMENT_FILE:
      return 'document-file'
    case HistoryEntityType.DOCUMENT_PERIOD:
      return 'document-period'
    case HistoryEntityType.DOCUMENT_TEMPLATE:
      return 'document-template'
    case HistoryEntityType.INTERNAL_TRANSFER:
      return 'internal-transfer'
    case HistoryEntityType.INTERNAL_TRANSFERS_EXPORT:
      return 'internal-transfers-export'
    case HistoryEntityType.LICENSE:
      return 'license'
    case HistoryEntityType.ADVERSE_INFORMATION_SEARCH:
      return 'adverse-information-search'
    case HistoryEntityType.ADVERSE_INFORMATION_HIT:
      return 'adverse-information-hit'
    case HistoryEntityType.AFFILIATED_COMPANY:
      return 'affiliated-company'
    case HistoryEntityType.CONTACT_METADATA:
      return 'contact-metadata'
    case HistoryEntityType.CUSTOMERS_COMPANIES:
      return 'customers-companies'
    case HistoryEntityType.VENDORS_COMPANIES:
      return 'vendors-companies'
    case HistoryEntityType.ANNUAL_REVIEW:
      return 'annual-review'
    case HistoryEntityType.RETAIL_REPORT:
      return 'retail-report'
    case HistoryEntityType.WHOLESALE_REPORT:
      return 'wholesale-report'
    case HistoryEntityType.BSA_SAR:
      return 'bsa-sar'
    case HistoryEntityType.BANK_ACCOUNT:
      return 'bank-account'
    case HistoryEntityType.TAX_RECONCILIATION_REPORT:
      return 'tax-reconciliation-report'
    default:
      throw new Error('"getPathByEntityType" received Unknown entity type.')
  }
}

export const entityTypesDictionary = [
  { value: HistoryEntityType.BSA_BATCH, label: 'BSA Batch' },
  { value: HistoryEntityType.BSA_CIF_SUBJECT, label: 'BSA CIF Subject' },
  { value: HistoryEntityType.BSA_CTR, label: 'BSA CTR' },
  { value: HistoryEntityType.BSA_SETTING, label: 'BSA Setting' },
  { value: HistoryEntityType.COMPANY, label: 'Company' },
  { value: HistoryEntityType.DOCUMENT, label: 'Document' },
  { value: HistoryEntityType.DOCUMENT_FILE, label: 'Document File' },
  { value: HistoryEntityType.DOCUMENT_PERIOD, label: 'Document Period' },
  { value: HistoryEntityType.DOCUMENT_TEMPLATE, label: 'Document Template' },
  { value: HistoryEntityType.INTERNAL_TRANSFER, label: 'Internal Transfer' },
  { value: HistoryEntityType.INTERNAL_TRANSFERS_EXPORT, label: 'Internal Transfers Export' },
  { value: HistoryEntityType.LICENSE, label: 'License' },
  { value: HistoryEntityType.ADVERSE_INFORMATION_SEARCH, label: 'Adverse Information Search' },
  { value: HistoryEntityType.ADVERSE_INFORMATION_HIT, label: 'Adverse Information Hit' },
  { value: HistoryEntityType.AFFILIATED_COMPANY, label: 'Affiliated Company' },
  { value: HistoryEntityType.CONTACT_METADATA, label: 'Contact Meta Data' },
  { value: HistoryEntityType.CUSTOMERS_COMPANIES, label: 'Customers Companies' },
  { value: HistoryEntityType.VENDORS_COMPANIES, label: 'Vendors Companies' },
  { value: HistoryEntityType.ANNUAL_REVIEW, label: 'Annual Review' },
  { value: HistoryEntityType.RETAIL_REPORT, label: 'Retail Report' },
  { value: HistoryEntityType.WHOLESALE_REPORT, label: 'Wholesale Report' },
  { value: HistoryEntityType.TAX_RECONCILIATION_REPORT, label: 'Tax Reconciliation Report' },
  { value: HistoryEntityType.BSA_SAR, label: 'BSA SAR' },
  { value: HistoryEntityType.BANK_ACCOUNT, label: 'Bank Account' },
  { value: HistoryEntityType.TASK, label: 'Task' },
  { value: HistoryEntityType.CONTACT, label: 'Contact' },
  { value: HistoryEntityType.REPORT_MONTHLY, label: 'Report Monthly' },
  { value: HistoryEntityType.REPORT_QUARTERLY, label: 'Report Quarterly' },
  { value: HistoryEntityType.ADVERSE_MEDIA_CASE, label: 'Adverse Media Case' },
  { value: HistoryEntityType.ADVERSE_MEDIA_PUBLICATION, label: 'Adverse Media Publication' },
  { value: HistoryEntityType.BANK_ACCOUNT_LICENSES, label: 'Bank Account Licenses' },
  { value: HistoryEntityType.APPLICATION_DOCUSIGN_ENVELOPE, label: 'Application Docusign Envelope' }
]

export const getNameByEntityType = (type: HistoryEntityType) => {
  switch (type) {
    case HistoryEntityType.BSA_BATCH:
      return 'BSA batch'
    case HistoryEntityType.BSA_CIF_SUBJECT:
      return 'BSA CIF Subject'
    case HistoryEntityType.BSA_CTR:
      return 'BSA CTR'
    case HistoryEntityType.BSA_CTR_BATCH:
      return 'BSA CTR Batch'
    case HistoryEntityType.BSA_DDA_SUBJECT:
      return 'BSA DDA Subject'
    case HistoryEntityType.BSA_SETTING:
      return 'BSA Setting'
    case HistoryEntityType.COMPANY:
      return 'Company'
    case HistoryEntityType.DOCUMENT:
      return 'Document'
    case HistoryEntityType.DOCUMENT_FILE:
      return 'Document File'
    case HistoryEntityType.DOCUMENT_PERIOD:
      return 'Document Period'
    case HistoryEntityType.DOCUMENT_TEMPLATE:
      return 'Document Template'
    case HistoryEntityType.INTERNAL_TRANSFER:
      return 'Internal Transfer'
    case HistoryEntityType.INTERNAL_TRANSFERS_EXPORT:
      return 'Internal Transfers Export'
    case HistoryEntityType.LICENSE:
      return 'License'
    case HistoryEntityType.ADVERSE_INFORMATION_SEARCH:
      return 'Adverse Information Search'
    case HistoryEntityType.ADVERSE_INFORMATION_HIT:
      return 'Adverse Information Hit'
    case HistoryEntityType.CUSTOMERS_COMPANIES:
      return 'Customers Companies'
    case HistoryEntityType.VENDORS_COMPANIES:
      return 'Vendors Companies'
    case HistoryEntityType.CONTACT_METADATA:
      return 'Contact Meta Data'
    case HistoryEntityType.ANNUAL_REVIEW:
      return 'Annual Review'
    case HistoryEntityType.RETAIL_REPORT:
      return 'Retail Report'
    case HistoryEntityType.WHOLESALE_REPORT:
      return 'Wholesale Report'
    case HistoryEntityType.TAX_RECONCILIATION_REPORT:
      return 'Tax Reconciliation Report'
    case HistoryEntityType.BSA_SAR:
      return 'BSA SAR'
    case HistoryEntityType.BANK_ACCOUNT:
      return 'Bank Account'
    case HistoryEntityType.AFFILIATED_COMPANY:
      return 'Affiliated Company'
    case HistoryEntityType.TASK:
      return 'Task'
    case HistoryEntityType.CONTACT:
      return 'Contact'
    case HistoryEntityType.REPORT_MONTHLY:
      return 'Report Monthly'
    case HistoryEntityType.REPORT_QUARTERLY:
      return 'Report Quarterly'
    case HistoryEntityType.ADVERSE_MEDIA_CASE:
      return 'Adverse Media Case'
    case HistoryEntityType.ADVERSE_MEDIA_PUBLICATION:
      return 'Adverse Media Publication'
    case HistoryEntityType.BANK_ACCOUNT_LICENSES:
      return 'Bank Account Licenses'
    case HistoryEntityType.HISTORY_ENTITY_INVOICE_SUBMITTAL:
      return 'History Entity Invoice Submittal'
    case HistoryEntityType.REPORT_FILE:
      return 'Report File'
    case HistoryEntityType.APPLICATION_DOCUSIGN_ENVELOPE:
      return 'Application Docusign Envelope'
    default:
      return 'Unknown entity type'
  }
}
