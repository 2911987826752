import React from 'react'

import { A, B, C, D, E, Z } from './fields'

import { s } from './styles'

export const Structuring = () => (
  <>
    <s.CheckBoxWrapper withLabel>
      <A />
    </s.CheckBoxWrapper>
    <s.CheckBoxWrapper>
      <B />
    </s.CheckBoxWrapper>
    <s.CheckBoxWrapper>
      <C />
    </s.CheckBoxWrapper>
    <s.CheckBoxWrapper>
      <D />
    </s.CheckBoxWrapper>
    <s.CheckBoxWrapper>
      <E />
    </s.CheckBoxWrapper>
    <s.CheckBoxWrapper>
      <Z />
    </s.CheckBoxWrapper>
  </>
)
