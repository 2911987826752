import styled from 'styled-components'

export const Container = styled.div`
  :not(:last-child) {
    margin-bottom: 60px;
  }
`

export const Legend = styled.h5`
  color: ${(p) => p.theme.uiKit.colors.global.general.secondary};
  margin-bottom: 20px;
`
