import React, { FC, memo } from 'react'

import { Form } from 'brief-form'
import { useGetBankName } from 'commons/hooks/bank/organisation'
import { AnnualReview } from 'commons/types/DTO/bank'
import { BankName } from 'commons/types/enums'
import { LeaveConfirmation } from 'ui/components'
import { ButtonGroup } from 'ui/components/ButtonGroup'
import { Button, IconButton } from 'ui/components/Buttons'
import { CheckBox } from 'ui/components/CheckBox'
import { Hr } from 'ui/components/Hr'
import { IconName } from 'ui/components/Icon'
import { Layer } from 'ui/components/Layer'
import { Link } from 'ui/components/Link'
import { Page, PageFace } from 'ui/components/Page'
import { Panel } from 'ui/components/Panel'

import { getAnnualReviewDetailUrl } from '../../../route'
import { AnnualReviewEditFormProps } from './AnnualReviewEditFormProps'
import { AnnualReviewEditLicensing } from './components/AnnualReviewEditLicensing'
import { AnnualReviewEditOverview } from './components/AnnualReviewEditOverview'
import { AnnualReviewEditQuestions } from './components/AnnualReviewEditQuestions'
import { AnnualReviewEditCBTQuestions } from './components/AnnualReviewEditQuestions/AnnualReviewEditCBTQuestions'
import { generateFormData, generateFormDataForCBT } from './generateFormData'
import { useAnnualReviewEditForm } from './useAnnualReviewEditForm'

import * as SC from './styles'

const isStart = (annualReview: AnnualReview) => annualReview?.created_at === annualReview?.updated_at

export const AnnualReviewEditForm: FC<AnnualReviewEditFormProps> = memo((props) => {
  const { id, companyId, annualReview, onSubmit, isNotFound, company } = props

  const { id: bankId } = useGetBankName()
  const isShore = bankId === BankName.SHORE
  const isCBT = bankId === BankName.CBT

  const {
    config,
    Field,
    affiliatedCompanies,
    DeleteConfirmation,
    openDeleteConfirmation,
    handleSubmit,
    preventLeaving
  } = useAnnualReviewEditForm(id, +companyId, annualReview, onSubmit, isCBT ? generateFormDataForCBT : generateFormData)

  if (config.value['questionnaire.site_visit_last_date']) {
    const date = new Date(config.value['questionnaire.site_visit_last_date'])

    if (!/^(\d{4})-(\d{2})-(\d{2})/.test(date.toISOString())) {
      config.value['questionnaire.site_visit_last_date'] =
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1).toString().padStart(2, '0') +
        '-' +
        date.getDate().toString().padStart(2, '0')
    }
  }

  return (
    <LeaveConfirmation preventLeaving={preventLeaving}>
      <Page
        isNotFound={isNotFound}
        face={PageFace.SECONDARY}
        title={isStart(annualReview) ? 'Start Annual Review' : 'Edit Annual Review'}
        subTitle={`AR-${id}`}
        isPending={!id}
      >
        <DeleteConfirmation />
        <Form config={config}>
          <Layer rounded shadowed>
            <Panel collapsible={false} title={isStart(annualReview) ? 'Start Annual Review' : 'Edit Annual Review'}>
              <SC.PanelBody>
                <AnnualReviewEditOverview
                  key={0}
                  annualReview={annualReview}
                  company={company}
                  Field={Field}
                  affiliatedCompanies={affiliatedCompanies || []}
                />
                <Hr key={1} />
                {annualReview.questionnaire && (
                  <AnnualReviewEditLicensing
                    id={id}
                    key={2}
                    companyId={companyId}
                    Field={Field}
                    formValue={config.value}
                    isCBT={isCBT}
                  />
                )}
                <Hr key={3} />
                {annualReview.questionnaire && isCBT ? (
                  <AnnualReviewEditCBTQuestions Field={Field} />
                ) : (
                  <AnnualReviewEditQuestions
                    key={4}
                    Field={Field}
                    questionnaire={annualReview.questionnaire}
                    formValue={config.value}
                    isShore={isShore}
                  />
                )}
              </SC.PanelBody>
            </Panel>
          </Layer>
          <SC.Footer>
            <SC.IsReviewCompleted>
              <Field
                name="is_review_completed"
                input={CheckBox}
                inputProps={{
                  checkBoxLabel: 'Is Review Completed?',
                  'data-qa': 'Is Review Completed?'
                }}
              />
            </SC.IsReviewCompleted>
            <ButtonGroup margin="small">
              <IconButton face="negative" icon={IconName.DELETE} onClick={openDeleteConfirmation}>
                Delete
              </IconButton>
              <Link to={getAnnualReviewDetailUrl({ companyId, id })}>
                <Button face="neutral">Cancel</Button>
              </Link>
              <Button face="positive" onClick={handleSubmit}>
                Submit
              </Button>
            </ButtonGroup>
          </SC.Footer>
        </Form>
      </Page>
    </LeaveConfirmation>
  )
})
