import { getContactListWebPage } from 'commons/service/bank/contact'
import { ContactListWebPageRequest, ContactListWebPageResponse } from 'commons/types/contracts/api/bank/contactList'
import { Keys, ReadHook, useStateManager } from 'state-manager'

const getHandler = (payload: ContactListWebPageRequest) => (): Promise<ContactListWebPageResponse> =>
  getContactListWebPage(payload)

export const useGetContactListWebPage: ReadHook<ContactListWebPageResponse, ContactListWebPageRequest> = (opts) => {
  const sm = useStateManager()

  const { data, ...rest } = sm.use(Keys.Contact.List(opts), getHandler(opts), {
    keepPreviousData: true
  })

  return {
    ...rest,
    data
  }
}
