// eslint-disable-next-line max-len
import * as React from 'react'

import { FieldProps, FormInputProps } from 'brief-form'
import { TextInput } from 'ui/components/InputV2'
import { PageSection, PageSectionFace } from 'ui/components/Page'
import { RadioGroup } from 'ui/components/RadioGroup'
import { useAnnualReviewSalesDeposits } from '~bank-annual-review/hooks'

import { AnnualReviewLicensingQuestions } from '../../../../../AnnualReviewDetail/components/AnnualReviewLicensing/components/AnnualReviewLicensingQuestions/AnnualReviewLicensingQuestions'
// eslint-disable-next-line max-len
import { AnnualReviewLicensingTable } from '../../../../../AnnualReviewDetail/components/AnnualReviewLicensing/components/AnnualReviewLicensingTable'
import { AnnualReviewFormValue } from '../../AnnualReviewFormValue'

interface AnnualReviewEditLicensingProps {
  id: number
  companyId: number
  Field: <Input extends React.ComponentType<FormInputProps<any, any>>>(
    props: FieldProps<Input, AnnualReviewFormValue>
  ) => JSX.Element
  formValue: AnnualReviewFormValue
  isCBT: boolean
}

export const AnnualReviewEditLicensing = ({
  id,
  companyId,
  Field,
  formValue,
  isCBT
}: AnnualReviewEditLicensingProps): JSX.Element => {
  const annualReviewSalesDeposits = useAnnualReviewSalesDeposits({ id })
  const salesAndDeposits = annualReviewSalesDeposits.data?.records

  return (
    <PageSection title="Licensing" face={PageSectionFace.SECONDARY}>
      {salesAndDeposits && <AnnualReviewLicensingTable companyId={companyId} salesAndDeposits={salesAndDeposits} />}
      {!isCBT && (
        <AnnualReviewLicensingQuestions
          licensingNamePrior={<Field name="questionnaire.licensing_name_prior" input={TextInput} inputProps={{}} />}
          licensingLocChanges={
            <Field
              name="questionnaire.licensing_loc_changes"
              label=""
              input={RadioGroup}
              inputProps={{
                name: 'questionnaire.licensing_loc_changes',
                data: [
                  { label: 'Yes', value: 'Yes' },
                  { label: 'No', value: 'No' }
                ]
              }}
            />
          }
          licensingNameChanges={
            <Field
              name="questionnaire.licensing_name_changes"
              label=""
              input={RadioGroup}
              inputProps={{
                name: 'questionnaire.licensing_name_changes',
                data: [
                  { label: 'Yes', value: 'Yes' },
                  { label: 'No', value: 'No' }
                ]
              }}
            />
          }
          licensingLocChangesMmcc={
            <Field
              name="questionnaire.licensing_loc_changes_mmcc"
              label=""
              input={RadioGroup}
              inputProps={{
                name: 'questionnaire.licensing_loc_changes_mmcc',
                data: [
                  { label: 'Yes', value: 'Yes' },
                  { label: 'No', value: 'No' }
                ]
              }}
            />
          }
          licensingNameChangesMmcc={
            <Field
              name="questionnaire.licensing_name_changes_mmcc"
              label=""
              input={RadioGroup}
              inputProps={{
                name: 'questionnaire.licensing_name_changes_mmcc',
                data: [
                  { label: 'Yes', value: 'Yes' },
                  { label: 'No', value: 'No' }
                ]
              }}
            />
          }
          locationChanges={formValue['questionnaire.licensing_loc_changes'] === 'Yes'}
          nameChanges={formValue['questionnaire.licensing_name_changes'] === 'Yes'}
        />
      )}
    </PageSection>
  )
}
