import styled from 'styled-components'

export const BlockDelimiterCPStyled = styled.div`
  background-color: ${(p) => p.theme.uiKit.colors.global.grey.grey900};
  color: ${(p) => p.theme.uiKit.colors.global.general.main};
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  padding: 12px 100px;
  margin: 40px -100px;
`

export const BlockDelimiterBPStyled = styled(BlockDelimiterCPStyled)`
  padding: 12px 50px;
  margin: 40px -50px;

  // here is the hack to support BP page,
  // we want to remove page top padding for removing white line between delimiter and page's header
  // TODO: how find a better way? if we refact Page component with changing its padding we broke this
  &:first-child {
    margin-top: -20px;
  }
`
