import React, { memo } from 'react'

import IMask from 'imask'

import { SecuredInput } from './SecuredInput'
import { BaseInputProps } from './props'

export const SSNInput = memo((props: BaseInputProps) => {
  const mask = IMask.createMask({
    mask: '000-00-0000'
  })
  return <SecuredInput {...props} mask={mask} />
})
