import React from 'react'

import { DatePicker, FF } from 'ui/components'

import { useSARFormContext } from '../../../../../../../../context'

export const Date = ({ index }: { index: number }) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={`transactions_info.ip_addresses[${index}].date` as 'transactions_info.ip_addresses[0].date'}
      children={(field) => <FF label="Date" field={field} input={(p) => <DatePicker {...p} />} />}
    />
  )
}
