import { useQuery } from 'react-query'

import { BsaSar } from 'commons/types/DTO/bank/bsa'

import * as Service from '../../service'

export const useCompany = (id: number): { isLoading: boolean; companyDetails: BsaSar } => {
  const { data, isFetching } = useQuery<any, Error>(
    ['companies', id],
    () =>
      id !== 0
        ? Service.SAR.companyList({ _options: { filters: [{ field: 'id', type: 'eq', value: id }] } })
        : Promise.resolve({
            records: []
          }),
    { keepPreviousData: true }
  )

  return {
    isLoading: isFetching,
    companyDetails: data ? data.records[0] : null
  }
}
