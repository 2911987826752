import styled from 'styled-components'

const Text = styled.span<{ $inactive: boolean }>`
  color: ${(p) => (p.$inactive ? '#aaaaaa !important;' : 'inherit')};
  flex: 1 1;
`

const TextBlock = styled.div<{ $inactive: boolean }>`
  color: ${(p) => (p.$inactive ? '#aaaaaa !important;' : 'inherit')};
`

const TooltipContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
`
const TooltipWrapper = styled.div`
  flex: 1 1;
`

export const s = { Text, TextBlock, TooltipContainer, TooltipWrapper }
