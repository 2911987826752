import React, { FC, memo } from 'react'

import { Form } from 'brief-form'
import { CompanySelect } from 'ui/components/Select'
import { FilterPanel } from 'ui/components/Table'
import { FilterWrapper } from 'ui/temp/Filter'
import { TableFilterRow } from 'ui/temp/TableFilterRow'

import { FilterProps } from './FilterProps'

import * as SC from './styles'

export const Filter: FC<FilterProps> = memo((props) => {
  const { isExpanded, onClear, onToggle, form, webPage } = props
  const { config, Field } = form

  return (
    <FilterPanel isDirty={form.isDirty} onReset={onClear} expanded={isExpanded} onToggle={onToggle}>
      <FilterWrapper>
        <Form config={config}>
          <TableFilterRow>
            <SC.ItemWrapper>
              <Field
                name="company_id"
                label="Relationships"
                input={CompanySelect}
                inputProps={{
                  hideMyCompaniesFilter: true,
                  preloadedOptions: webPage.relationshipOptions
                }}
              />
            </SC.ItemWrapper>
          </TableFilterRow>
        </Form>
      </FilterWrapper>
    </FilterPanel>
  )
})
