import { UsersWebPageResponse } from '../../types'

export const NEW_USER: UsersWebPageResponse['users'][0] = {
  firstName: '',
  lastName: '',
  assignedCompaniesIds: [],
  email: '',
  id: '',
  phone: '',
  active: true,
  createdAt: '',
  enableDeactivate: true,
  enableEdit: true,
  enablePasswordResetOrInvite: true,
  isCurrentUser: false,
  lastLogin: null,
  role: {
    name: '',
    key: '',
    description: ''
  }
}
