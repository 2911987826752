import * as React from 'react'

interface OnboardingCustomerStatusLabelProps {
  value: string
}

export enum OnboardingApplicationStatus {
  APPLICATION_STATUS_ALL_ACTIVE = 'all_active',
  APPLICATION_STATUS_ALL_IN_ACTIVE = 'all_inactive',
  APPLICATION_STATUS_RFI_PENDING = 'rfi_pending',
  APPLICATION_STATUS_RFI_REVIEW = 'rfi_review',
  APPLICATION_STATUS_DOCUMENT_REQUEST_PENDING = 'document_request_pending',
  APPLICATION_STATUS_DOCUMENT_REQUEST_REVIEW = 'document_request_review',
  APPLICATION_STATUS_DOCUMENT_REQUEST_SUBMITTED = 'document_request_submitted',
  APPLICATION_STATUS_DOCUMENT_REQUEST_REVIEWED = 'document_request_reviewed',
  APPLICATION_STATUS_PENDING_CUSTOMER_REVIEW = 'pending_customer_review',
  APPLICATION_STATUS_CUSTOMER = 'customer',
  APPLICATION_STATUS_DENIED_CUSTOMER = 'denied_customer'
}

const statusMap: { [key: string]: string } = {
  [OnboardingApplicationStatus.APPLICATION_STATUS_RFI_PENDING]: 'RFI Pending',
  [OnboardingApplicationStatus.APPLICATION_STATUS_RFI_REVIEW]: 'RFI Review',
  [OnboardingApplicationStatus.APPLICATION_STATUS_DOCUMENT_REQUEST_PENDING]: 'Document Request Pending',
  [OnboardingApplicationStatus.APPLICATION_STATUS_DOCUMENT_REQUEST_REVIEW]: 'Document Request Review',
  [OnboardingApplicationStatus.APPLICATION_STATUS_DOCUMENT_REQUEST_SUBMITTED]: 'Document Request Submitted',
  [OnboardingApplicationStatus.APPLICATION_STATUS_DOCUMENT_REQUEST_REVIEWED]: 'Document Request Reviewed',
  [OnboardingApplicationStatus.APPLICATION_STATUS_PENDING_CUSTOMER_REVIEW]: 'Pending Customer Review',
  [OnboardingApplicationStatus.APPLICATION_STATUS_CUSTOMER]: 'Customer',
  [OnboardingApplicationStatus.APPLICATION_STATUS_DENIED_CUSTOMER]: 'Denied Customer'
}

export const getOnboardingCustomerStatusLabel = (value: string) => statusMap[value] || '---'

export const OnboardingCustomerStatusLabel = ({ value }: OnboardingCustomerStatusLabelProps) => (
  <span>{statusMap[value] || '---'}</span>
)
