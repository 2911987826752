import { Link } from '@tanstack/react-router'
import styled from 'styled-components'

export const Hint = styled.div`
  font-size: 14px;
  line-height: 1.35;
  letter-spacing: 0.44px;
  color: #fff;
  margin-bottom: 45px;
`

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  color: #fff;
`

export const ChooseDeviceLink = styled(Link)`
  text-decoration: none;
  color: #fff;

  &:visited {
    color: #fff;
  }
`
