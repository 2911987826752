/* eslint-disable camelcase */

export type ApplicationDocument = {
  application_id?: number
  created_at?: string
  deleted_at?: string
  document_name?: string
  file_name?: string
  id?: number
  notes?: string
  s3_key?: string
  template_s3_key?: string
  type?: string
  updated_at?: string
}
