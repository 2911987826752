import React, { FC, memo, useCallback, useState } from 'react'

import { Icon, IconName } from '../Icon'
import { TextMaskProps } from './TextMaskProps'

import * as SC from './styles'

export const TextMask: FC<TextMaskProps> = memo(
  ({ text, link, isBankAccount, fullyHidden, alwaysHidden, iconSize, onClick, iconFill, valueType, ...rest }) => {
    const [masked, setMasked] = useState(true)

    const showMasked = useCallback(
      (e) => {
        e.preventDefault()
        setMasked(false)
      },
      [setMasked]
    )

    const hideMasked = useCallback(
      (e) => {
        e.preventDefault()
        setMasked(true)
      },
      [setMasked]
    )

    let formattedText = text || ''
    const textLength = formattedText.length
    const countLastSymbols = textLength > 4 ? -4 : Math.round(textLength / 2)
    const lastSymbols = fullyHidden ? '' : formattedText.slice(countLastSymbols)
    const countSymbolsForHide = formattedText.length - Math.abs(fullyHidden ? 0 : countLastSymbols)
    const firstSymbols = formattedText.slice(0, fullyHidden ? textLength : countSymbolsForHide)

    if (valueType === 'SSN') {
      formattedText = formattedText.replace(/\D/g, '')
      formattedText = `${formattedText.slice(0, 3)}-${formattedText.slice(3, 5)}-${formattedText.slice(5, 9)}`
    } else if (valueType === 'EIN') {
      formattedText = formattedText.replace(/\D/g, '')
      formattedText = `${formattedText.slice(0, 2)}-${formattedText.slice(2, 9)}`
    }

    return (
      <>
        {text && text.length > 0 && (
          <SC.Mask {...rest}>
            {isBankAccount || fullyHidden ? (
              <SC.Text onClick={onClick}>
                {masked ? (
                  `${(firstSymbols || '').replace(/./g, '*')}${lastSymbols}`
                ) : link ? (
                  <SC.TextLink to={link}>{formattedText}</SC.TextLink>
                ) : (
                  formattedText
                )}
              </SC.Text>
            ) : (
              <SC.Text onClick={onClick}>
                {masked ? (
                  (formattedText || '').replace(/[A-Za-z0-9]/g, '*')
                ) : link ? (
                  <SC.TextLink to={link}>{formattedText}</SC.TextLink>
                ) : (
                  formattedText
                )}
              </SC.Text>
            )}
            {!alwaysHidden && (
              <>
                {masked ? (
                  <SC.IconWrapper onClick={showMasked} $masked={masked}>
                    <Icon size={iconSize} name={IconName.EYE} fill={iconFill || ''} />
                  </SC.IconWrapper>
                ) : (
                  <SC.IconWrapper onClick={hideMasked} $masked={masked}>
                    <Icon size={iconSize} name={IconName.EYE_CROSSED} fill={iconFill || ''} />
                  </SC.IconWrapper>
                )}
              </>
            )}
          </SC.Mask>
        )}
      </>
    )
  }
)
