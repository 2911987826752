import React, { memo } from 'react'

import { BsaSar } from 'commons/types/DTO/bank/bsa'
import { IconSize } from 'ui/components/Icon'
import { Info } from 'ui/components/Info'
import { PageSection, PageSectionFace } from 'ui/components/Page'
import { Panel, PanelFace } from 'ui/components/Panel'
import { TextMask } from 'ui/components/TextMask'
import { Col, Row } from 'ui/themes/globalStyles'

import * as SC from './styles'

interface Props {
  item: BsaSar
}

export const FinancialInstitutionWhereActivityOccurred = memo(({ item }: Props) => (
  <PageSection
    face={PageSectionFace.THIRD}
    title="Step 2. Financial Institution Where Activity Occurred"
    anchor="step2"
  >
    {item.institution_activities_info?.map((info: any, index) => (
      <Panel
        rounded
        shadowed
        key={index}
        collapsible={false}
        title={`Part III Information about Institution ${index + 1} of ${item.institution_activities_info?.length}`}
        face={PanelFace.PRIMARY}
      >
        <SC.InnerPanel>
          <Row>
            <Col>
              <Info label="51. Type of financial institution">
                {info.type === 'Other' && info.type_other}
                {info.type !== 'Other' && info.type}
              </Info>
            </Col>
            <Col>
              <Info label="52. Primary federal regulator">{info.federal_regulator}</Info>
            </Col>
          </Row>
          <Row>
            <Col>
              <Info label="55. Financial institution identification Type">{info.id_type}</Info>
            </Col>
            <Col>
              <Info label="Number">{info.id_number}</Info>
            </Col>
          </Row>
          <Row>
            <Col>
              <Info label="56. Financial institution's role in transaction">{info.role_in_transaction}</Info>
            </Col>
            <Col>
              <Info label="57. Legal name of financial institution">{info.legal_name}</Info>
            </Col>
          </Row>
          <Row>
            <Info label="58. Alternate Name">{info.dba}</Info>
          </Row>
          <Row>
            <Col>
              <Info label="59. TIN">{info.tin ? <TextMask text={info.tin} iconSize={IconSize.XS} /> : null}</Info>
            </Col>
            <Col>
              <Info label="60. TIN type">{info.tin_type}</Info>
            </Col>
          </Row>
          <Row>
            <Info label="61-65. Address">
              <div>{info.address}</div>
              <div>
                {[info.city, info.country === 'US' ? info.state : null, info.zip_code].filter(Boolean).join(', ')}
              </div>
              <div>{info.country}</div>
            </Info>
          </Row>
          <Row>
            <Info label="66. Internal control/file number">{info.internal_control_number}</Info>
          </Row>
          <Row>
            <Info label="67. Loss to financial institution">{info.loss_to_fin_institution}</Info>
          </Row>
          {!info.no_branch_involved && (
            <>
              <SC.WrappedHr />
              <Info>Branch where activity occurred information</Info>
              {info.institution_activity_branches_info?.map((branch: any, index: number) => (
                <div key={index}>
                  <Row>
                    <Col>
                      <Info label="68. Branch's role in transaction">{branch.role_in_transaction}</Info>
                    </Col>
                    <Col>
                      <Info label="70. RSSD Number">{branch.id_number}</Info>
                    </Col>
                  </Row>
                  <Row>
                    <Info label="69. 71-74. Address of branch or office where activity occurred	">
                      <div>{branch.address}</div>
                      <div>
                        {[branch.city, branch.country === 'US' ? branch.state : null, branch.zip_code]
                          .filter(Boolean)
                          .join(', ') || '-'}
                      </div>
                      <div>{branch.country}</div>
                    </Info>
                  </Row>
                </div>
              ))}
            </>
          )}
        </SC.InnerPanel>
      </Panel>
    ))}
  </PageSection>
))
