import { ATMServices } from 'commons/types/DTO/commons'

import { ATMItemFormValue, ATMServicesFormData } from '../../../../types/forms'

export const emptyATMServices: ATMServices = {
  ATMAddress: '',
  ATMAvgAmountReplenished: 0,
  ATMMaxAmount: 0,
  ATMReplenishFrequency: '',
  ATMFundsFromBank: 0,
  ATMFundsFromBankFalseDesc: ''
}

const getEmptyATMServices = ({ atm }: { atm: ATMServices }): ATMServicesFormData => ({
  formValues: {
    ATMAddress: atm.ATMAddress,
    ATMAvgAmountReplenished: atm.ATMAvgAmountReplenished,
    ATMMaxAmount: atm.ATMMaxAmount,
    ATMReplenishFrequency: atm.ATMReplenishFrequency,
    ATMFundsFromBank: atm.ATMFundsFromBank,
    ATMFundsFromBankFalseDesc: atm.ATMFundsFromBankFalseDesc
  },
  formErrors: {}
})

export const getEmptyATM = ({ atm }: { atm: ATMServices }): ATMItemFormValue => ({
  atmServices: getEmptyATMServices({ atm })
})
