import * as React from 'react'

import { Button, ButtonFace, ButtonSize } from '../Button'

interface Props {
  enlargement?: number
  size?: ButtonSize
  onPageChange: (page: number, size: number) => void
  pageCount: number
  selectedPage?: number
}

export const Pages = ({ pageCount, selectedPage, onPageChange, enlargement = 1, size }: Props): JSX.Element => {
  return (
    <>
      {Array(...Array(pageCount)).map((_, index) => {
        const key = index + enlargement
        return selectedPage === key ? (
          <Button face={ButtonFace.SECONDARY} key={key} size={size}>
            {key}
          </Button>
        ) : (
          <Button face={ButtonFace.SECONDARY} key={key} onClick={() => onPageChange(key, pageCount)} size={size}>
            {key}
          </Button>
        )
      })}
    </>
  )
}
