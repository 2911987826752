import React from 'react'

import { ButtonGroup } from 'ui/components/ButtonGroup'
import { HeaderButton } from 'ui/components/Buttons'
import { Search } from 'ui/components/Search'

import { ActionsProps } from './interface'

import { Wrapper } from './styles'

export const Actions = ({ onSearchTermChange, showNewButton, onOpenCreateModal, searchTerm }: ActionsProps) => (
  <Wrapper>
    <ButtonGroup margin="small">
      <Search onSearch={onSearchTermChange} value={searchTerm} placeholder="Search" data-qa="Search" />
      {showNewButton && (
        <HeaderButton size="middle" onClick={onOpenCreateModal}>
          Create New
        </HeaderButton>
      )}
    </ButtonGroup>
  </Wrapper>
)
