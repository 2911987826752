import { ApplicationWebPageDetail } from 'commons/types/DTO/bank'

export {
  ConnectBankAccountToLicensesFormFieldNames,
  ConnectBankAccountToLicensesFormValue,
  BankAccountFormOnChange,
  ConnectBankAccountToLicensesFormOnChange,
  ConnectBankAccountToLicensesFormOnChangeState,
  ConnectBankAccountToLicensesFormData,
  AddBankAccountValidateFunctionRefs,
  ConnectBankAccountToLicensesFormState,
  RelationshipBankAccountFormDataRecord,
  ApplicationBankAccountsDialogState,
  ApplicationBankAccountsFormDataList
} from './typesRelationshipBankAccountForm'

export type ApplicationDetailsLoaderReturn = { applicationId: number; application: ApplicationWebPageDetail }
