const length = (v: string, wantedLength: number) => {
  if (v.toString().length !== wantedLength) {
    return `Value length must be equal to ${wantedLength} characters`
  }
  return undefined
}

export const string = {
  length: (wantedLength: number) => (v: string) => length(v, wantedLength)
}
