import { useMutation } from 'react-query'

import { handlerServerError } from 'errors'
import { Toast } from 'ui/components/Toast'

import * as Service from '../../service'

export const useCTRsGeneration = () => {
  return useMutation(
    (date: string) => {
      return Service.CTR.generateCTRs({ date })
    },
    {
      onError: (error) => handlerServerError(error),
      onSuccess: () => {
        Toast.infoSuccessSmall(
          'The generation process has started successfully. It may take some time. Please refresh the page later.'
        )
      }
    }
  )
}
