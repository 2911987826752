import React, { FC, memo } from 'react'

import { Form } from 'brief-form'
import { FilterComponentProps } from 'query-table'
import { TextInput } from 'ui/components/InputV2'
import { CompanySelect, Select } from 'ui/components/Select'
import { FilterPanel } from 'ui/components/Table'

import { DocumentsReportFilter } from '../../../DocumentsReportFilter'

import * as SC from './styles'

export const FilterForm: FC<FilterComponentProps<DocumentsReportFilter>> = memo((props) => {
  const {
    form: { isDirty, set, config, Field },
    expanded,
    onToggle
  } = props

  return (
    <FilterPanel expanded={expanded} isDirty={isDirty} onReset={() => set({ reset: true })} onToggle={onToggle}>
      <SC.FormWrapper>
        <Form config={config}>
          <SC.Row>
            <Field
              name="relationship_id"
              label="Relationship"
              input={CompanySelect}
              inputProps={{
                defaultOption: { label: 'All', value: 0 },
                'data-qa': 'Relationship',
                'options-data-qa': 'Relationship',
                hideMyCompaniesFilter: true
              }}
            />
            <Field
              name="license_number"
              label="License Number"
              input={TextInput}
              inputProps={{
                'data-qa': 'License Number'
              }}
            />
            <Field
              name="frequency"
              label="Frequency"
              input={Select}
              inputProps={{
                data: [
                  { label: 'All', value: '' },
                  { label: 'Quarterly', value: 'quarterly' },
                  { label: 'Semi-Annual', value: 'semi-annual' },
                  { label: 'Annual', value: 'annual' }
                ]
              }}
            />
          </SC.Row>
        </Form>
      </SC.FormWrapper>
    </FilterPanel>
  )
})
