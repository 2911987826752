import styled from 'styled-components'

export const DotLabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0px 8px;
`

export const Dot = styled.div<{ $active: boolean }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: ${(p) =>
    p.$active ? p.theme.uiKit.colors.theme.brand.primary : p.theme.uiKit.colors.theme.negative.main};
`
