import React, { FC } from 'react'

import { ErrorBoundary } from '@sentry/react'
import { BankPortalContext } from 'bank-portal-context'
import { NotFound } from 'modules/404/components/NotFound'
import { ErrorDetails, NotFoundErrorBoundary } from 'ui/components/Errors'
import { ToastContainer, ToastGlobalStyle } from 'ui/components/Toast'

import { useMainPage } from './hooks'
import { AppRoutes } from './routes'

export const MainPage: FC = () => {
  const {
    activateZendeskWidget,
    barMinimized,
    changeBarMinimized,
    layout,
    isOnlyApplicationsAndAccountSettingsAccessible,
    customSidebarItems,
    setCustomSidebarItems
  } = useMainPage()

  return (
    <ErrorBoundary showDialog fallback={<ErrorDetails />}>
      <NotFoundErrorBoundary fallback={<NotFound />}>
        <BankPortalContext.Provider
          value={{
            activateZendeskWidget,
            barMinimized,
            changeBarMinimized,
            layout,
            isOnlyApplicationsAndAccountSettingsAccessible,
            customSidebarItems,
            setCustomSidebarItems
          }}
        >
          <AppRoutes accessibleRoutes={layout?.routerItems} />
        </BankPortalContext.Provider>
        <ToastContainer />
        <ToastGlobalStyle />
      </NotFoundErrorBoundary>
    </ErrorBoundary>
  )
}
