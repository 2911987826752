import styled, { css } from 'styled-components'
import { Icon } from 'ui/components/Icon'
import { Panel } from 'ui/components/Panel'

const TitleCss = css`
  margin: 19px 0px;
  display: flex;
  align-items: center;

  > a {
    color: ${(p) => p.theme.uiKit.colors.global.grey.grey500};
    text-decoration: none;
  }
`

const ArrowCss = css`
  margin: 0 20px;
  opacity: 0.3;
  position: relative;
  top: -8px;
`

const TablePanelCss = css`
  margin-bottom: 70px;
`

export const Title = styled.div`
  ${TitleCss}
`

export const Arrow = styled(Icon)`
  ${ArrowCss}
`

export const TablePanel = styled(Panel)`
  ${TablePanelCss}
`

export const LongFilename = styled.span`
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 1920px) {
    max-width: calc((100vw / 10) - 20px);
  }

  @media (max-width: 1650px) {
    max-width: calc((100vw / 10) - 60px);
  }

  @media (max-width: 1470px) {
    max-width: 70px;
  }
`
