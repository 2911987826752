import styled from 'styled-components'
import { Panel as BasePanel, FieldSet, Icon } from 'ui/components'

export const IconWrapper = styled.span`
  display: flex;
  vertical-align: middle;
  gap: 6px;
`

export const GreenIcon = styled(Icon)`
  svg {
    fill: ${(p) => p.theme.uiKit.colors.theme.positive.main};
    color: ${(p) => p.theme.uiKit.colors.theme.positive.main};
  }
`

export const DialogSection = styled.div`
  padding: 28px 32px 32px 32px;
  max-width: 595px;
`

export const GAP = styled.div`
  height: 9px;
`

export const AddNewSection = styled.div`
  padding-top: 28px;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 0;
  max-width: 595px;
`
export const PanelSection = styled.div`
  padding-top: 20px;
  padding-left: 32px;
  padding-right: 32px;
  max-width: 595px;
`

export const AddBtnSection = styled.div`
  padding-top: 12px;
  padding-bottom: 48px;

  padding-left: 32px;
  padding-right: 32px;
  max-width: 595px;
`

export const FieldSetBox = styled(FieldSet)`
  padding-top: 14px;
  padding-left: 32px;
  :not(:last-child) {
    margin-bottom: auto;
  }
`

export const Panel = styled(BasePanel)`
  margin-bottom: 20px;
`

export const Content = styled.div`
  min-width: 600px;
`

export const HeadingContent = styled.div`
  display: flex;
  & > i {
    margin-right: 8px;
  }
`

export const Red = styled.span`
  color: ${(p) => p.theme.uiKit.colors.theme.negative.main};
`
