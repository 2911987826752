import { Company } from 'commons/types/DTO/bank'

export interface RelationShipInformationProps {
  onSetCompanyOwners: (opts: { companyId: number; ids: string[] }) => Promise<Company>
  id: number
  name: string
  legalName: string
  dba: string
  isHolding: boolean
  holding?: { id: number; name: string }
  holding_group_companies?: { id: number; name?: string }[]
  customerStatus: string
  applicationId: number
  active?: boolean
  assignedBankUsers: { id: string; name: string }[]
  availableOwners: { id: string; fullName: string }[]
  phone: string
  website: string
  tags: string
}
