import styled from 'styled-components'
import { CSS } from 'ui/helpers'

export const Box = styled.div<{ $hasLabel: boolean }>`
  position: relative;
  padding-bottom: 23px;
  ${(p) => (p.$hasLabel ? 'padding-top: 24px;' : '')}
`

export const Label = styled.label`
  position: absolute;
  left: 0;
  top: 0;
  color: ${(p) => p.theme.uiKit.colors.global.grey.grey700};
  white-space: nowrap;
  text-overflow: ellipsis;
  ${(p) => CSS.populateFont(p.theme.uiKit.typography.bodyM.semiBold)};
`

export const Content = styled.div`
  word-break: break-word;
  ${(p) => CSS.populateFont(p.theme.uiKit.typography.bodyL.regular)};
`

export const Checkboxes = styled.div`
  display: flex;
  flex-flow: wrap;
  align-items: flex-start;
  gap: 10px;
`
