import styled from 'styled-components'

const Box = styled.footer`
  background: #fff;
  height: 66px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 48px 0 272px;
  border-top: 1px solid ${(p) => p.theme.uiKit.colors.global.grey.grey300};
`

export const s = { Box }
