import styled from 'styled-components'

export const FormWrapper = styled.div`
  padding: 20px 30px 32px 30px;
`

export const ItemWrapper = styled.div`
  width: 226px;
`

export const FilterWrapper = styled.div`
  display: flex;
  gap: 30px;
`
