import styled from 'styled-components'

export const NoFiles = styled.span`
  color: ${(p) => p.theme.uiKit.colors.global.grey.grey700};
`

export const HasFiles = styled.span`
  color: ${(p) => p.theme.uiKit.colors.theme.positive.darkest};
  text-decoration: underline;
  cursor: pointer;
`
