import React from 'react'

import { COMPANIES_PATH } from 'commons/constants/routes'
import { CheckMark } from 'ui/components/CheckMark'
import { Icon, IconName, IconSize } from 'ui/components/Icon'
import { MenuIconHoverFace, MenuItemBehavior, MenuItemTextFace, TableActions } from 'ui/components/QueryTable'
import { TableColumn } from 'ui/components/Table'

import { ContactRelatedCompanies } from '../../../../types'

import { NameLink } from './styles'

export const columns = (
  onEdit: (contact: ContactRelatedCompanies) => void,
  onDelete: (contact: ContactRelatedCompanies) => void
): TableColumn<ContactRelatedCompanies>[] => [
  {
    title: 'Company Name',
    render: ({ id, name, isDocuments, isFinancials }) => (
      <NameLink to={`${COMPANIES_PATH}/${id}`}>
        {name}
        {isDocuments && <Icon name={IconName.DOCUMENT} size={IconSize.XS} />}
        {isFinancials && <Icon name={IconName.FINANCIALS} size={IconSize.XS} />}
      </NameLink>
    )
  },
  {
    title: 'Title',
    key: 'title'
  },
  {
    title: 'Owner (Ownership %)',
    render: ({ isOwner, showOwnershipPercent }) =>
      isOwner && <CheckMark value rounded label={`${showOwnershipPercent.toFixed(2)} %`} />
  },
  {
    title: 'Debtholder',
    render: ({ isDebtholder }) => isDebtholder && <CheckMark value rounded label="" />
  },
  {
    title: 'Account Signer',
    render: ({ isAccountSigner }) => isAccountSigner && <CheckMark value rounded label="" />
  },
  {
    title: 'Action',
    width: '50px',
    render: (item: ContactRelatedCompanies) => (
      <TableActions
        items={[
          {
            key: 1,
            title: 'Edit',
            behavior: MenuItemBehavior.BUTTON,
            iconName: IconName.EDIT,
            iconHoverFace: MenuIconHoverFace.PRIMARY,
            textFace: MenuItemTextFace.GREY,
            onClick: () => onEdit(item)
          },
          {
            key: 2,
            title: 'Remove',
            behavior: MenuItemBehavior.BUTTON,
            iconName: IconName.DELETE,
            iconHoverFace: MenuIconHoverFace.DANGER,
            textFace: MenuItemTextFace.GREY,
            onClick: () => onDelete(item)
          }
        ]}
      />
    )
  }
]
