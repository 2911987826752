import { SICSAPIStatusReportFilterValue } from './types'

export const filterMapper = ({
  filter,
  pagination
}: {
  filter: SICSAPIStatusReportFilterValue
  pagination: { page: number; size: number }
}): SICSAPIStatusReportFilterValue => {
  const result = {
    relationship_id: filter.relationship_id,
    license_name: filter.license_name.toString(),
    license_number: filter.license_number.toString(),
    license_state: filter.license_state,

    offset: (pagination.page - 1) * pagination.size,
    limit: pagination.size
  }

  if (typeof filter.connected !== 'boolean') {
    return result
  }

  return {
    ...result,
    connected: filter.connected
  }
}
