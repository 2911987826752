import React, { FC } from 'react'

import { useRouter } from '@tanstack/react-router'
import { handlerServerError } from 'errors'
import { Toast } from 'ui/components'
import { useConfirmation } from 'ui/hooks'
import { useDeleteDocument } from '~bank-documents/service/deleteDocument'

type ConfirmationOpenParams = { id?: number; name?: string }

type UseDeleteDocumentConfirmationArgs = { onSuccess?: () => void }

const Message: FC<ConfirmationOpenParams> = (props) => (
  <>
    Are you sure want to delete
    <br />
    <i>{props?.name}</i> document?
  </>
)

export const useDeleteDocumentConfirmation = ({ onSuccess }: UseDeleteDocumentConfirmationArgs) => {
  const router = useRouter()

  const { routine: deleteDocument } = useDeleteDocument({
    onSuccess: async () => {
      Toast.successSmall(`Document was successfully deleted.`)

      if (onSuccess) onSuccess()
      else router.invalidate()
    },
    onError: (error) => handlerServerError(error)
  })

  const { open, Confirmation } = useConfirmation<ConfirmationOpenParams>({
    message: <Message />,
    onConfirm: async (params) => {
      await deleteDocument(Number(params?.id))
      router.invalidate()
    },
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  return { DeleteConfirmation: Confirmation, openDeleteDocumentConfirmation: open }
}
