import { useMutation, useQueryClient } from 'react-query'

import { useNavigate } from '@tanstack/react-router'
import { localStorageService } from 'commons/service/localStorageService'
import { PortalName } from 'commons/types/enums'
import { useStateManager } from 'state-manager'
import { sendSecurityCode } from '~auth/service/mfa'

import { redirectToProperPage } from '../helpers'

export const useSendSecurityCode = (p: PortalName, skipSavingToken?: boolean) => {
  const sm = useStateManager()
  const client = useQueryClient()
  const navigate = useNavigate()

  return useMutation(
    (data: { challengeId: string; code: string; token: string }) => sendSecurityCode(p, data.challengeId, data.code),
    {
      onError: (error: Error) => {
        throw error
      },
      onSuccess: async (response) => {
        localStorageService.setSessionExpiresAt(response.expires_at)
        if (!skipSavingToken) {
          // We are finishing auth process, save token and route to internal page.
          redirectToProperPage(sm, navigate, new URLSearchParams(window.location.search))
        } else {
          // We just adding new MFA device, refresh devices table.
          await client.invalidateQueries(['tables', 'mfa-devices-table'])
        }
        client.removeQueries(['mfa'])
      }
    }
  )
}
