import { SENDING_AND_RECEIVING } from 'commons/constants/internalTransfers'
import { getBankAccountList } from 'commons/service/bank/bankAccount'
import { BankAccountDeprecated } from 'commons/types/DTO/bank'
import { ReadHook, useStateManager } from 'state-manager'

type UseGetBankAccountListOpts = {
  ids: number[]
  companyId?: number
}

export const useGetBankAccountList: ReadHook<BankAccountDeprecated[], UseGetBankAccountListOpts> = (opts) => {
  const sm = useStateManager()

  const { data, ...rest } = sm.use(
    ['bank-accounts', opts.ids],
    () =>
      opts.ids
        ? getBankAccountList({ _options: { filters: [{ field: 'id', type: 'in', value: opts.ids }] } })
        : Promise.resolve({
            records: [
              {
                id: 0,
                company_id: opts.companyId,
                number: '',
                notes: '',
                created_at: null,
                updated_at: null,
                licensed: false,
                license_ids: [],
                opening_date: null,
                internal_transfers_type: SENDING_AND_RECEIVING
              }
            ]
          }),
    {
      keepPreviousData: true
    }
  )

  return {
    ...rest,
    data: data?.records || []
  }
}
