import React from 'react'

import { A, B, C, D, E, F, G, H, I, J, K, L, M, Z } from './fields'

import { s } from './styles'

export const Fraud = () => {
  return (
    <>
      <s.CheckBoxWrapper withLabel>
        <A />
      </s.CheckBoxWrapper>
      <s.CheckBoxWrapper>
        <B />
      </s.CheckBoxWrapper>
      <s.CheckBoxWrapper>
        <C />
      </s.CheckBoxWrapper>
      <s.CheckBoxWrapper>
        <D />
      </s.CheckBoxWrapper>
      <s.CheckBoxWrapper>
        <E />
      </s.CheckBoxWrapper>
      <s.CheckBoxWrapper>
        <F />
      </s.CheckBoxWrapper>
      <s.CheckBoxWrapper>
        <G />
      </s.CheckBoxWrapper>
      <s.CheckBoxWrapper>
        <H />
      </s.CheckBoxWrapper>
      <s.CheckBoxWrapper>
        <I />
      </s.CheckBoxWrapper>
      <s.CheckBoxWrapper>
        <J />
      </s.CheckBoxWrapper>
      <s.CheckBoxWrapper>
        <K />
      </s.CheckBoxWrapper>
      <s.CheckBoxWrapper>
        <L />
      </s.CheckBoxWrapper>
      <s.CheckBoxWrapper>
        <M />
      </s.CheckBoxWrapper>
      <s.CheckBoxWrapper>
        <Z />
      </s.CheckBoxWrapper>
    </>
  )
}
