import { FormStatusMessages } from '../../../enums'

export const getFormStatusMessage = ({
  isDirty,
  hasChanges,
  isAutosaveUpdating
}: {
  isDirty: boolean
  hasChanges: boolean
  isAutosaveUpdating: boolean
}): FormStatusMessages => {
  // form isn't touched
  if (!isDirty) {
    return FormStatusMessages.ISNT_DIRTY
  }

  // touched, and wait debounce delay
  if (hasChanges && !isAutosaveUpdating) {
    return FormStatusMessages.HAS_CHANGES
  }

  // auto-save
  if (hasChanges && isAutosaveUpdating) {
    return FormStatusMessages.SAVING_IN_PROGERESS
  }

  // auto-save finished
  if (!hasChanges && !isAutosaveUpdating) {
    return FormStatusMessages.CHANGES_SAVED
  }
  return FormStatusMessages.ISNT_DIRTY
}
