import { AnnualReviewSalesDeposits } from 'commons/types/DTO/bank'
import { formatCurrencyCent } from 'commons/utils'

export const createSalesDataSources = (item: AnnualReviewSalesDeposits, subtype?: string) =>
  [
    {
      item: {
        id: 'Deposits',
        name: 'Deposits',
        quarter1: formatCurrencyCent(item.quarter1_info?.deposits ? item.quarter1_info.deposits : 0),
        quarter2: formatCurrencyCent(item.quarter2_info?.deposits ? item.quarter2_info.deposits : 0),
        quarter3: formatCurrencyCent(item.quarter3_info?.deposits ? item.quarter3_info.deposits : 0),
        quarter4: formatCurrencyCent(item.quarter4_info?.deposits ? item.quarter4_info.deposits : 0),
        total: formatCurrencyCent(item.quarters_all_info?.deposits ? item.quarters_all_info.deposits : 0)
      }
    },
    subtype === 'dispensary' && {
      item: {
        id: 'Point of Sale',
        name: 'Point of Sale',
        quarter1: formatCurrencyCent(item.quarter1_info?.pos ? item.quarter1_info.pos : 0),
        quarter2: formatCurrencyCent(item.quarter2_info?.pos ? item.quarter2_info.pos : 0),
        quarter3: formatCurrencyCent(item.quarter3_info?.pos ? item.quarter3_info.pos : 0),
        quarter4: formatCurrencyCent(item.quarter4_info?.pos ? item.quarter4_info.pos : 0),
        total: formatCurrencyCent(item.quarters_all_info?.pos ? item.quarters_all_info.pos : 0)
      }
    },
    {
      item: {
        id: 'Metrc Sales',
        name: 'Sales',
        quarter1: formatCurrencyCent(item.quarter1_info?.sales ? item.quarter1_info.sales : 0),
        quarter2: formatCurrencyCent(item.quarter2_info?.sales ? item.quarter2_info.sales : 0),
        quarter3: formatCurrencyCent(item.quarter3_info?.sales ? item.quarter3_info.sales : 0),
        quarter4: formatCurrencyCent(item.quarter4_info?.sales ? item.quarter4_info.sales : 0),
        total: formatCurrencyCent(item.quarters_all_info?.sales ? item.quarters_all_info.sales : 0)
      }
    },
    {
      item: {
        id: 'Revenue from Financials',
        name: 'Revenue from Financials',
        quarter1: '',
        quarter2: '',
        quarter3: '',
        quarter4: '',
        total: formatCurrencyCent(item.total_financials ? item.total_financials : 0)
      }
    }
  ].filter(Boolean)

interface SumTotal {
  cash?: number
  invoices?: number
  transfers?: number
  checks?: number
  credit_debit?: number
  other?: number
}

const sumTotal = ({ cash, invoices, transfers, checks, credit_debit, other }: SumTotal) => {
  const updCash = cash ?? 0
  const updInvoices = invoices ?? 0
  const updTransfers = transfers ?? 0
  const updChecks = checks ?? 0
  const updCreditDebit = credit_debit ?? 0
  const updOther = other ?? 0

  return formatCurrencyCent(updCash + updInvoices + updTransfers + updChecks + updCreditDebit + updOther)
}

export const createDepositDataSources = (item: AnnualReviewSalesDeposits, subtype?: string) => {
  const totalCashDeposit = item.quarters_all_info?.cash

  const totalCashInvoices = item.quarters_all_info?.invoices

  const totalTransfers = item.quarters_all_info?.transfers

  const totalChecksAndRemote = item.quarters_all_info?.checks

  const totalDebitCredit = item.quarters_all_info?.credit_debit

  const totalOther = item.quarters_all_info?.other

  return [
    {
      item: {
        id: 'Cash Deposit',
        name: 'Cash Deposit',
        quarter1: formatCurrencyCent(item.quarter1_info?.cash ? item.quarter1_info.cash : 0),
        quarter2: formatCurrencyCent(item.quarter2_info?.cash ? item.quarter2_info.cash : 0),
        quarter3: formatCurrencyCent(item.quarter3_info?.cash ? item.quarter3_info.cash : 0),
        quarter4: formatCurrencyCent(item.quarter4_info?.cash ? item.quarter4_info.cash : 0),
        total: formatCurrencyCent(totalCashDeposit ? totalCashDeposit : 0)
      }
    },
    {
      item: {
        id: 'Cash Invoices',
        name: 'Cash Invoices',
        quarter1: formatCurrencyCent(item.quarter1_info?.invoices ? item.quarter1_info.invoices : 0),
        quarter2: formatCurrencyCent(item.quarter2_info?.invoices ? item.quarter2_info.invoices : 0),
        quarter3: formatCurrencyCent(item.quarter3_info?.invoices ? item.quarter3_info.invoices : 0),
        quarter4: formatCurrencyCent(item.quarter4_info?.invoices ? item.quarter4_info.invoices : 0),
        total: formatCurrencyCent(totalCashInvoices ? totalCashInvoices : 0)
      }
    },
    {
      item: {
        id: 'Internal Transfers',
        name: 'Internal Transfers',
        quarter1: formatCurrencyCent(item.quarter1_info?.transfers ? item.quarter1_info.transfers : 0),
        quarter2: formatCurrencyCent(item.quarter2_info?.transfers ? item.quarter2_info.transfers : 0),
        quarter3: formatCurrencyCent(item.quarter3_info?.transfers ? item.quarter3_info.transfers : 0),
        quarter4: formatCurrencyCent(item.quarter4_info?.transfers ? item.quarter4_info.transfers : 0),
        total: formatCurrencyCent(totalTransfers ? totalTransfers : 0)
      }
    },
    subtype !== 'dispensary' && {
      item: {
        id: 'Checks & Remote Deposit',
        name: 'Checks & Remote Deposit',
        quarter1: formatCurrencyCent(item.quarter1_info?.checks ? item.quarter1_info.checks : 0),
        quarter2: formatCurrencyCent(item.quarter2_info?.checks ? item.quarter2_info.checks : 0),
        quarter3: formatCurrencyCent(item.quarter3_info?.checks ? item.quarter3_info.checks : 0),
        quarter4: formatCurrencyCent(item.quarter4_info?.checks ? item.quarter4_info.checks : 0),
        total: formatCurrencyCent(totalChecksAndRemote ? totalChecksAndRemote : 0)
      }
    },
    subtype !== 'grow' &&
      subtype !== 'processor' && {
        item: {
          id: 'Credit / Debit',
          name: 'Credit / Debit',
          quarter1: formatCurrencyCent(item.quarter1_info?.credit_debit ? item.quarter1_info.credit_debit : 0),
          quarter2: formatCurrencyCent(item.quarter2_info?.credit_debit ? item.quarter2_info.credit_debit : 0),
          quarter3: formatCurrencyCent(item.quarter3_info?.credit_debit ? item.quarter3_info.credit_debit : 0),
          quarter4: formatCurrencyCent(item.quarter4_info?.credit_debit ? item.quarter4_info.credit_debit : 0),
          total: formatCurrencyCent(totalDebitCredit ? totalDebitCredit : 0)
        }
      },
    {
      item: {
        id: 'Other',
        name: 'Other',
        quarter1: formatCurrencyCent(item.quarter1_info?.other ? item.quarter1_info.other : 0),
        quarter2: formatCurrencyCent(item.quarter2_info?.other ? item.quarter2_info.other : 0),
        quarter3: formatCurrencyCent(item.quarter3_info?.other ? item.quarter3_info.other : 0),
        quarter4: formatCurrencyCent(item.quarter4_info?.other ? item.quarter4_info.other : 0),
        total: formatCurrencyCent(totalOther ? totalOther : 0)
      }
    },
    {
      item: {
        id: 'Total Deposit',
        name: 'Total Deposit',
        quarter1: sumTotal({
          cash: item.quarter1_info?.cash,
          invoices: item.quarter1_info?.invoices,
          transfers: item.quarter1_info?.transfers,
          checks: item.quarter1_info?.checks,
          credit_debit: item.quarter1_info?.credit_debit,
          other: item.quarter1_info?.other
        }),
        quarter2: sumTotal({
          cash: item.quarter2_info?.cash,
          invoices: item.quarter2_info?.invoices,
          transfers: item.quarter2_info?.transfers,
          checks: item.quarter2_info?.checks,
          credit_debit: item.quarter2_info?.credit_debit,
          other: item.quarter2_info?.other
        }),
        quarter3: sumTotal({
          cash: item.quarter3_info?.cash,
          invoices: item.quarter3_info?.invoices,
          transfers: item.quarter3_info?.transfers,
          checks: item.quarter3_info?.checks,
          credit_debit: item.quarter3_info?.credit_debit,
          other: item.quarter3_info?.other
        }),
        quarter4: sumTotal({
          cash: item.quarter4_info?.cash,
          invoices: item.quarter4_info?.invoices,
          transfers: item.quarter4_info?.transfers,
          checks: item.quarter4_info?.checks,
          credit_debit: item.quarter4_info?.credit_debit,
          other: item.quarter4_info?.other
        }),
        total: sumTotal({
          cash: totalCashDeposit,
          invoices: totalCashInvoices,
          transfers: totalTransfers,
          checks: totalChecksAndRemote,
          credit_debit: totalDebitCredit,
          other: totalOther
        })
      }
    }
  ].filter(Boolean)
}
