import styled from 'styled-components'
import { PageButton } from 'ui/components/Buttons'

export const Buttons = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
`

export const WrappedButton = styled(PageButton)`
  margin: 0 20px 20px 0;
`
