import React from 'react'

import { Sars } from 'commons/types/DTO/bank/alerts/sars'
import { formatCurrency } from 'commons/utils'
import { IconName } from 'ui/components'
import { IconButton } from 'ui/components/Buttons'
import { DateTime } from 'ui/components/DateTime'
import { Link } from 'ui/components/Link'
import { TableActions } from 'ui/components/QueryTable'
import { TableColumn } from 'ui/components/Table'

import { NameRow } from './styles'

type Opts = {
  onShowWarnings: (data: string) => void
}

export const columns = (opts: Opts): TableColumn<Sars>[] => [
  {
    title: 'ID',
    align: 'center',
    render: ({ id }) => <Link to={`/bsa/sars/${id}`}>{id}</Link>
  },
  {
    title: 'Period',
    align: 'center',
    key: 'periodStartDate'
  },
  {
    title: 'Relationship',
    align: 'center',
    key: 'relationship'
  },
  {
    title: 'Total',
    align: 'center',
    render: ({ total }) => formatCurrency(total)
  },
  {
    title: 'Status',
    align: 'center',
    render: (item) => (
      <NameRow>
        {!!item.warnings && (
          <IconButton icon={IconName.ERROR_RED} tabIndex={0} onClick={() => opts.onShowWarnings(item.warnings)} />
        )}
        {item.status || '-'}
      </NameRow>
    )
  },
  {
    title: 'Created Date',
    align: 'center',
    render: ({ createdDate }) => <DateTime date={createdDate} withTime />
  },
  {
    title: 'Last Modified Date',
    align: 'center',
    render: ({ lastModifiedDate }) => <DateTime date={lastModifiedDate} withTime />
  },
  {
    title: 'Action',
    align: 'center',
    render: (item) => (
      <TableActions
        items={[
          {
            key: 1,
            title: 'Edit',
            url: `/bsa/sars/${item.id}/edit?returnUrl=${encodeURIComponent('/alerts#sar')}`,
            iconName: IconName.EDIT
          }
        ]}
      />
    )
  }
]
