import styled from 'styled-components'

export const Inner = styled.div`
  padding: 30px 35px;
`

export const MainText = styled.b`
  margin: 0 0 15px 0;
  display: block;
`

export const SecondText = styled.div`
  margin-bottom: 5px;
`
