import React, { useEffect } from 'react'

import { FieldApi } from '@tanstack/react-form'
import { BsaSar } from 'commons/types/DTO/bank/bsa'
import { usAndTerritories } from 'commons/types/dictionaries'
import { FF, Select } from 'ui/components'

import { useSARFormContext } from '../../../../../../../../context'
import { useSubjectInformationItemContext } from '../../../SubjectInformationItemContext'

const EMPTY_OPTION = { label: 'Not selected', value: '' }

type CountryProps = {
  index: number
}

type CountryRendererProps = {
  field: FieldApi<BsaSar, 'subjects_info[0].address_info[0].country'>
  index: number
}

const CountryRenderer = ({ field, index }: CountryRendererProps) => {
  const { formApi } = useSARFormContext()
  const {
    state: { value }
  } = field

  const { isAllInfoUnavailable } = useSubjectInformationItemContext()
  const stateKeyName = `subjects_info[${index}].address_info[0].state` as 'subjects_info[0].address_info[0].state'

  useEffect(() => {
    if (typeof value !== 'undefined' && value !== 'US') {
      formApi.setFieldValue(stateKeyName, '', { dontUpdateMeta: true })
      const stateMeta = formApi.getFieldMeta(stateKeyName)
      formApi.setFieldMeta(stateKeyName, {
        ...stateMeta,
        errors: [],
        errorMap: {}
      })
    }
  }, [value, formApi, stateKeyName])

  return (
    <FF
      field={field}
      label="15. Country"
      input={(p) => (
        <Select
          {...p}
          data={usAndTerritories}
          emptyOption={EMPTY_OPTION}
          disabled={isAllInfoUnavailable}
          data-qa="15. Country"
          options-data-qa="15. Country option"
        />
      )}
    ></FF>
  )
}

export const Country = ({ index }: CountryProps) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={`subjects_info[${index}].address_info[0].country` as 'subjects_info[0].address_info[0].country'}
      children={(field) => <CountryRenderer field={field} index={index} />}
    />
  )
}
