import React from 'react'

import { BSAIdentifierInput, FF, Validators } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

export const PriorReportBSAId = () => {
  const { formApi } = useSARFormContext()
  const filingTypeInitial = formApi.useStore((s) => s.values.filing_type_initial)

  return (
    <formApi.Field
      name="prior_report_bsa_id"
      validators={{
        onChangeListenTo: ['filing_type_initial'],
        onChange: ({ value, fieldApi }) => {
          const { filing_type_correct_amend, filing_type_continuing } = fieldApi.form.state.values
          return filing_type_correct_amend || filing_type_continuing ? Validators.required.field(value) : undefined
        }
      }}
      children={(field) => (
        <FF
          required={!filingTypeInitial}
          label="Prior report BSA Identifier"
          field={field}
          input={(p) => <BSAIdentifierInput {...p} maxLength={14} disabled={filingTypeInitial} />}
        />
      )}
    />
  )
}
