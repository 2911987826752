import { useMutation } from 'react-query'

import { useNavigate } from '@tanstack/react-router'
import { AnnualReviewSalesDeposits } from 'commons/types/DTO/bank'
import { Request } from 'commons/utils/request'
import { handlerServerError } from 'errors'

export const useUpdateAnnualReviewSalesDeposits = (companyId: number, id: number) => {
  const navigate = useNavigate()

  return useMutation(
    (props: AnnualReviewSalesDeposits) => Request.put(Request.urls.bank.annualReviewSalesDeposits, { ...props }),
    {
      onError: (error) => handlerServerError(error),
      onSuccess: () => {
        navigate({ to: '/annual-review/detail/$companyId/$id', params: { companyId: companyId, id: id } })
      }
    }
  )
}
