import React, { FC, memo, useCallback, useState } from 'react'
import { useQueryClient } from 'react-query'

import { Link, getRouteApi } from '@tanstack/react-router'
import { BsaBatch } from 'commons/types/DTO/bank/bsa'
import { IconName, IconSize } from 'ui/components/Icon'
import { Page, PageFace } from 'ui/components/Page'
import { PanelFace } from 'ui/components/Panel'
import { TableData, useTableState } from 'ui/components/QueryTable'
import { useConfirmation } from 'ui/hooks'
import { Batch as BatchHook } from '~bank-bsa-reporting/hooks'

import { Footer, Table } from './components'
import { DownloadXMLDialog } from './components/DownloadXMLDialog'
import { Filter } from './components/Table/components/Filter'
import { Batch } from './types'

import { Arrow, TablePanel, Title } from './styles'

const routeApi = getRouteApi('/bsa/ctr-batch-list')

const BatchList: FC = memo(() => {
  const [chosenItems, setChosenItems] = useState<BsaBatch[]>([])
  const [itemToDelete, setItemToDelete] = useState<BsaBatch | null>(null)
  const [tableData, setTableData] = useState<BsaBatch[]>()
  const queryClient = useQueryClient()
  const deleteMutation = BatchHook.useDelete(queryClient)

  const query = routeApi.useSearch()
  const navigate = routeApi.useNavigate()

  const batchIdFromQuery = query.batchId
  const batchNameFromQuery = query.batchName

  const batchFromQuery: Batch | null =
    batchIdFromQuery && batchNameFromQuery
      ? {
          id: parseInt(batchIdFromQuery),
          name: batchNameFromQuery
        }
      : null
  const [batch, setBatch] = useState<Batch | null>(batchFromQuery)

  const tableState = useTableState({
    order: [{ field: 'updated_at', direction: 'DESC' }],
    filter: new Filter()
  })

  const onItemSelect = (item?: BsaBatch) => {
    if (!item) {
      if (chosenItems.length < tableData?.length) {
        setChosenItems((tableData || []).map((i: any) => ({ id: i.id })))
      } else {
        setChosenItems([])
      }
    } else {
      const index = chosenItems.findIndex((i) => i.id === item.id)
      if (index === -1) {
        setChosenItems(chosenItems.concat(item))
      } else {
        const copy = [...chosenItems]
        copy.splice(index, 1)
        setChosenItems(copy)
      }
    }
  }

  const { open: startDeleting, Confirmation } = useConfirmation({
    message: (
      <span>
        Are you sure you want to delete{' '}
        <i>{itemToDelete || chosenItems.length === 1 ? (itemToDelete || chosenItems[0]).name : 'these'}</i> Batch(es)?
      </span>
    ),
    onConfirm: () =>
      deleteMutation.mutateAsync(itemToDelete ? [itemToDelete.id] : chosenItems.map((i) => i.id)).then(() => {
        setChosenItems([])
        setItemToDelete(null)
      }),
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  const onDeleteFromTableMenu = useCallback(
    (item: BsaBatch) => {
      setItemToDelete(item)
      startDeleting()
    },
    [setItemToDelete, startDeleting]
  )

  const onCloseXMLDialog = () => {
    navigate({ to: '/bsa/ctr-batch-list', replace: true })
    setBatch(null)
  }

  const onReturnData = (data: TableData) => {
    setTableData(data?.data?.list?.records)
  }

  return (
    <Page
      face={PageFace.PRIMARY}
      title={
        <Title>
          <Link to="/bsa">BSA Reporting</Link>
          <Arrow name={IconName.ARROW_SMALL} size={IconSize.XS} />
          CTR Batch List
        </Title>
      }
    >
      <Confirmation />
      {batch && <DownloadXMLDialog batch={batch} onClose={onCloseXMLDialog} isGenerating={false} />}
      <TablePanel rounded shadowed collapsible={false} face={PanelFace.SECONDARY}>
        <Table
          tableState={tableState}
          onItemSelect={onItemSelect}
          onDelete={onDeleteFromTableMenu}
          chosenItems={chosenItems}
          isAllItemsChosen={chosenItems.length === tableState.size}
          onReturnData={onReturnData}
        />
      </TablePanel>
      <Footer onDeleteBatches={startDeleting} hasChosenItems={!!chosenItems.length} />
    </Page>
  )
})

export default BatchList
