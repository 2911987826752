/* eslint-disable camelcase */

export type Organization = {
  SFOrgID?: string
  createdAt?: string
  id: number
  name?: string
  royaltyMultiplier?: number
  type?: string
  updatedAt?: string
  api_allowed_cidrs: { IP: string; Mask: string }[]
  test_organization_id: number
  organization_id?: number
  nextcloud_oidc_redirect_uri?: string
}
