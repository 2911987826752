import { Request } from 'commons/utils/request'
import { WriteHook, useMutationManager } from 'state-manager'
import { ManagePeriodForm, ManagePeriodResponse } from '~bank-documents/types/periodEdit'

type ManagePeriodRequestType = ManagePeriodForm & { id?: number; documentId?: number }

const saveDocumentPeriod = (document: ManagePeriodRequestType) => {
  const fn = document.documentId ? Request.post : Request.put
  return fn(Request.urls.bank.manageDocumentPeriod, document)
}

export const useSaveDocumentPeriod: WriteHook<ManagePeriodRequestType, unknown> = (opts) =>
  useMutationManager<ManagePeriodRequestType, unknown>(saveDocumentPeriod, opts)

export const getDocumentPeriodEditModePage = (opts: { id: number }) =>
  Request.post<ManagePeriodResponse>(Request.urls.bank.documentPeriodEditPage, opts)
