import styled from 'styled-components'
import { CSS } from 'ui/helpers'

export const Switcher = styled.div<{ $disabled: boolean; $checked: boolean; $noMarginRight?: boolean }>`
  border-radius: 7px;
  width: 30px;
  height: 14px;
  margin-right: ${(p) => (p.$noMarginRight ? '0px ' : '10px')};
  background-color: ${(p) =>
    p.$disabled
      ? p.$checked
        ? p.theme.uiKit.colors.global.grey.grey300
        : p.theme.uiKit.colors.global.grey.grey700
      : p.$checked
      ? p.theme.uiKit.colors.theme.brand.lightest
      : p.theme.uiKit.colors.global.grey.grey500};
  transition: ${() => CSS.populateTransition(['background-color'])};
  position: relative;
`

export const Circle = styled.div<{ $checked: boolean; $disabled?: boolean }>`
  width: 18px;
  height: 18px;
  border-radius: 18px;
  background-color: ${(p) =>
    p.$disabled
      ? p.theme.uiKit.colors.global.grey.grey500
      : p.$checked
      ? p.theme.uiKit.colors.theme.brand.lighter
      : p.theme.uiKit.colors.global.grey.grey300};
  position: absolute;
  left: ${(p) => `${p.$checked ? 12 : 0}px`};
  top: -2px;
  border-width: ${(p) => p.theme.uiKit.borderWidth.strokeS};
  border-style: solid;
  border-color: transparent;
  transition: ${() => CSS.populateTransition(['left', 'background-color', 'border-color', 'box-shadow'])};
`

export const Box = styled.div<{ $checked: boolean; $disabled?: boolean; $noMarginRight?: boolean }>`
  display: inline-block;
  margin-right: ${(p) => (p.$noMarginRight ? '0px ' : '15px')};
  outline: none;

  &:hover,
  &:focus {
    ${Switcher} {
      box-shadow: ${(p) => !p.$disabled && CSS.populateBoxShadow(p.theme.uiKit.boxShadows.offset.offset80Y6)};
    }

    ${Circle} {
      border-color: ${(p) =>
        p.$disabled
          ? 'transparent'
          : p.$checked
          ? p.theme.uiKit.colors.theme.brand.primary
          : p.theme.uiKit.colors.global.grey.grey500};
    }
  }
`

export const Inner = styled.div<{ $disabled?: boolean }>`
  display: flex;
  align-items: center;
  cursor: ${(props) => (props.$disabled ? 'not-allowed' : 'pointer')};
  flex-grow: 0;
  margin: 1px;
`

export const Label = styled.label<{ $checked: boolean; $disabled?: boolean }>`
  cursor: ${(props) => (props.$disabled ? 'not-allowed' : 'pointer')};
  ${(p) => CSS.populateFont(p.theme.uiKit.typography.bodyM.regular)};
  color: ${(p) =>
    p.$disabled ? p.theme.uiKit.colors.global.grey.grey700 : p.theme.uiKit.colors.global.general.secondary};
`
