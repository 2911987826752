import React from 'react'

import { CurrencyInput, FF, Validators } from 'ui/components'

import { useSARFormContext } from '../../../../../../context'

type ACHInProps = {
  index: number
}

export const ACHIn = ({ index }: ACHInProps) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={`transactions_info.by_account[${index}].ach_in` as 'transactions_info.by_account[0].ach_in'}
      validators={{ onChange: ({ value }) => Validators.required.notEmpty(value) }}
      children={(field) => <FF required field={field} label="ACH In" input={(p) => <CurrencyInput {...p} />} />}
    />
  )
}
