import { useEffect, useState } from 'react'
import { useStateManager } from 'react-query-state-manager'

import { useLogout } from 'auth'
import { Keys } from 'state-manager'

import { hooks } from '../../../hooks'
import { UsersWebPagePayload } from '../../../types'
import { useChangeStatus } from './useChangeStatus'
import { useEditUser } from './useEditUser'
import { useResetPassword } from './useResetPassword'

const emptyFilter: UsersWebPagePayload = {
  active: null,
  searchTerm: null,
  page: null,
  size: null
}

export const useUsers = () => {
  const [filter, onFilterChange] = useState<UsersWebPagePayload>(emptyFilter)
  const [initialFilter, setInitialFilter] = useState<UsersWebPagePayload>(emptyFilter)
  const { data, isFetching, invalidate } = hooks.useListPage(filter)
  const sm = useStateManager()
  const { logout } = useLogout()

  useEffect(() => {
    if (filter.page === null && typeof data?.form?.page === 'number') {
      onFilterChange(data.form)
      setInitialFilter(data.form)
    }
  }, [data, filter])

  const onSaved = (isCurrentUser: boolean) => {
    invalidate()
    if (isCurrentUser) {
      sm.invalidate(Keys.BankPortal.Layout)
    }
  }

  const onStatusChanged = (forceToLogout: boolean) => {
    if (forceToLogout) {
      logout({})
    } else {
      invalidate()
    }
  }

  const edit = useEditUser()
  const changeStatus = useChangeStatus(onStatusChanged)
  const resetPassword = useResetPassword()

  const resetFilter = () => onFilterChange(initialFilter)
  const onSearch = (searchTerm: string) => onFilterChange({ ...filter, searchTerm, page: 1 })
  const onActiveChange = (active: string | null) => onFilterChange({ ...filter, active, page: 1 })
  const onPageChange = (page: number, size: number) => onFilterChange({ ...filter, page, size })

  return {
    resetFilter,
    onSaved,
    onStatusChanged,
    onFilterChange,
    data,
    isFetching,
    edit,
    changeStatus,
    resetPassword,
    filter: {
      ...filter,
      onSearch,
      onActiveChange,
      onPageChange
    }
  }
}
