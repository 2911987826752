import { useCallback } from 'react'

export const useScrollTo = (items: any[]) => {
  const scrollTo = useCallback(async (id: string) => {
    const element = document.getElementById(`${id}`)

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })

      if (id === items[0].hash) {
        const page = document.querySelector('main[data-helios-page]')

        if (page) {
          // TODO: doesn't work now
          page.scrollTop -= 120
        }
      }
    }
  }, [])

  return {
    scrollTo
  }
}
