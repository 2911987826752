import React from 'react'

import { IconName } from 'ui/components'
import { IconButton } from 'ui/components/Buttons'

import { useSARFormContext } from '../../../../../../../../context'
import { BankAccount } from './fields'

type BankAccountsProps = {
  index: number
}

export const BankAccounts = ({ index }: BankAccountsProps) => {
  const { formApi } = useSARFormContext()
  const noKnownAccountsInvolved = formApi.useStore((s) => s.values.subjects_info[index].no_known_accounts_involved)

  return (
    <formApi.Field
      mode="array"
      name={
        `subjects_info[${index}].institution_info[0].account_numbers` as 'subjects_info[0].institution_info[0].account_numbers'
      }
    >
      {(field) => {
        if (typeof field.state.value === 'undefined') {
          return null
        }

        return (
          <>
            {(field.state.value || []).map((_, index2) => (
              <BankAccount key={index2} index1={index} index2={index2} parentField={field} />
            ))}
            {!noKnownAccountsInvolved && (
              <IconButton icon={IconName.ADD_GREEN} onClick={() => field.pushValue({ number: '', closed: false })} />
            )}
          </>
        )
      }}
    </formApi.Field>
  )
}
