import React from 'react'

import { PageSection, PageSectionFace } from 'ui/components/Page'
import { Panel } from 'ui/components/Panel'
import { Col, FormRow } from 'ui/themes/globalStyles'

import {
  Address,
  City,
  ContactOffice,
  Country,
  DBA,
  FederalRegulator,
  FiledDate,
  FilerName,
  IDNumber,
  IDType,
  InternalControlNumber,
  LEContactAgency,
  LEContactDate,
  LEContactName,
  LEContactPhone,
  LEContactPhoneExt,
  PhoneNumber,
  PhoneNumberExt,
  State,
  TIN,
  TINType,
  Type,
  TypeOther,
  ZipCode
} from './fields'

import { s } from './styles'

export const FilingInstitutionContactInformationForm = () => (
  <PageSection face={PageSectionFace.THIRD} title="Step 1. Filing Institution Contact Information" anchor="step1">
    <Panel rounded shadowed collapsible={false} title="Edit Institution Contact Information">
      <s.Inner>
        <FormRow>
          <Col>
            <Type />
          </Col>
          <Col>
            <TypeOther />
          </Col>
        </FormRow>
        <FormRow>
          <Col>
            <FederalRegulator />
          </Col>
        </FormRow>
        <FormRow>
          <Col>
            <FilerName />
          </Col>
        </FormRow>
        <FormRow>
          <Col>
            <TIN />
          </Col>
          <Col>
            <TINType />
          </Col>
        </FormRow>
        <FormRow>
          <Col>
            <IDType />
          </Col>
          <Col>
            <IDNumber />
          </Col>
        </FormRow>
        <FormRow>
          <Col>
            <Address />
          </Col>
          <Col>
            <City />
          </Col>
        </FormRow>
        <FormRow>
          <Col>
            <State />
          </Col>
          <Col>
            <ZipCode />
          </Col>
        </FormRow>
        <FormRow>
          <Col>
            <Country />
          </Col>
        </FormRow>
        <FormRow>
          <Col>
            <DBA />
          </Col>
        </FormRow>
        <FormRow>
          <Col>
            <InternalControlNumber />
          </Col>
        </FormRow>
        <FormRow>
          <Col>
            <LEContactAgency />
          </Col>
          <Col>
            <LEContactName />
          </Col>
        </FormRow>
        <FormRow>
          <Col>
            <Col>
              <LEContactPhone />
            </Col>
          </Col>
          <Col>
            <LEContactPhoneExt />
          </Col>
        </FormRow>
        <FormRow>
          <Col>
            <LEContactDate />
          </Col>
        </FormRow>
        <FormRow>
          <Col>
            <ContactOffice />
          </Col>
        </FormRow>
        <FormRow>
          <Col>
            <PhoneNumber />
          </Col>
          <Col>
            <PhoneNumberExt />
          </Col>
        </FormRow>
        <FormRow>
          <Col>
            <FiledDate />
          </Col>
        </FormRow>
      </s.Inner>
    </Panel>
  </PageSection>
)
