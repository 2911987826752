import React from 'react'

import { OnboardingDocumentSignerItem } from 'commons/types/DTO/bank/applications/onboardingDocuments'
import { getChipFaceByOnboardingDocumentStatus } from 'commons/utils'
import { Chip, DateTime, TableColumn } from 'ui/components'

import { s } from './styles'

export const columns: TableColumn<OnboardingDocumentSignerItem>[] = [
  {
    title: 'User',
    align: 'left',
    render: ({ name }) => <>{name}</>
  },
  {
    title: 'Date',
    align: 'left',
    render: ({ statusChangedAt }) => <DateTime date={statusChangedAt} withTime />
  },
  {
    title: 'Email',
    align: 'left',
    render: ({ email }) => <>{email}</>
  },
  {
    title: 'Status',
    align: 'left',
    render: ({ statusLabel, status }) => (
      <s.StatusBadgeWrapper>
        <Chip face={getChipFaceByOnboardingDocumentStatus(status)}>{statusLabel}</Chip>
      </s.StatusBadgeWrapper>
    )
  }
]
