import React, { FC } from 'react'

import { IconName, IconV2 } from 'ui/components'
import { ButtonGroup } from 'ui/components/ButtonGroup'
import { Button } from 'ui/components/Buttons'

import { FooterState } from '../types'

import * as SC from './styles'

export const AddBankAccountsDialogFooter: FC<{
  state: FooterState
}> = ({ state }) => (
  <SC.ContainerBox>
    <ButtonGroup margin="small">
      <Button face={'neutral'} disabled={state.isPrevDisabled} onClick={state.onPrev}>
        <SC.IconButtonInner>
          <IconV2 name={IconName.ARROW_PREVIOUS} size={20} />
          Prev
        </SC.IconButtonInner>
      </Button>
      {!state.isShowSubmitBtn && (
        <Button face={'neutral'} disabled={state.isNextDisabled} loading={state.isSaving} onClick={state.onNext}>
          <SC.IconButtonInner>
            Next
            <IconV2 name={IconName.ARROW_NEXT} size={20} />
          </SC.IconButtonInner>
        </Button>
      )}
      {state.isShowSubmitBtn && (
        <Button face={'positive'} onClick={state.onFinish}>
          Submit
        </Button>
      )}
    </ButtonGroup>

    {state.isSaving && (
      <SC.RightColumn>
        <SC.SavingText>Saving...</SC.SavingText>
      </SC.RightColumn>
    )}
  </SC.ContainerBox>
)
