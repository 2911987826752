export enum BPRouterItems {
  COMPANIES = 'companies',
  ALERTS = 'alerts',
  DOCUMENTS = 'documents',
  INTERNAL_TRANSFERS = 'internal-transfers',
  CONTACTS = 'contacts',
  USERS = 'users',
  CLIENT_USER_MANAGEMENT = 'client-users-management',
  APPLICATIONS = 'applications',
  HISTORY = 'history',
  BSA = 'bsa',
  ADVERSE_MEDIA = 'adverse-media',
  REPORTS = 'reports',
  ACCOUNT_SETTINGS = 'account-settings',
  NEXT_CLOUD_LOGIN = 'next-cloud-login',
  CONNECTIONS = 'connections',
  SETUP_ONBOARDING_DOCUMENTS = 'setup-onboarding-documents'
}
