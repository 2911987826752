import React, { useEffect } from 'react'

import { FieldApi } from '@tanstack/react-form'
import { BsaSar } from 'commons/types/DTO/bank/bsa'
import { CheckBox, FF } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

const FilingTypeInitialRenderer = ({ field }: { field: FieldApi<BsaSar, 'filing_type_initial'> }) => {
  const { formApi } = useSARFormContext()

  useEffect(() => {
    if (field.state.value) {
      formApi.setFieldValue('filing_type_correct_amend', false, { dontUpdateMeta: true })
      formApi.setFieldValue('filing_type_continuing', false, { dontUpdateMeta: true })
      formApi.setFieldValue('prior_report_bsa_id', null, { dontUpdateMeta: true })
    }
  }, [field.state.value, formApi])

  return <FF field={field} input={(p) => <CheckBox checkBoxLabel="Initial report" {...p} />} />
}

export const FilingTypeInitial = () => {
  const { formApi } = useSARFormContext()
  return <formApi.Field name="filing_type_initial" children={(field) => <FilingTypeInitialRenderer field={field} />} />
}
