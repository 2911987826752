const eventRepeatMap: { [key: string]: string } = {
  never: 'Never',
  daily: 'Daily',
  weekly: 'Weekly',
  monthly: 'Monthly',
  yearly: 'Yearly',
  custom: 'Custom'
}

export const getEventRepeatLabel = (name: string) => {
  return eventRepeatMap[name] || name
}
