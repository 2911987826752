import styled from 'styled-components'
import { CSS } from 'ui/helpers'

export const Hint = styled.span<{ $disabled?: boolean }>`
  padding-top: 5px;
  display: block;
  color: ${(p) => (p.$disabled ? p.theme.uiKit.colors.global.grey.grey700 : p.theme.uiKit.colors.global.grey.grey900)};

  ${(p) => CSS.populateFont(p.theme.uiKit.typography.bodyS.regular)};
`
