import styled from 'styled-components'
import { FieldSet } from 'ui/components/FieldSet'

export const Box = styled.div`
  padding: 1px 40px 50px 40px;
`

export const FieldSetStyled = styled(FieldSet)`
  margin: 20px 0 10px 0;
`

export const InternalTransfersInputContainer = styled.div`
  height: 82px;
`

export const VisibilityBox = styled.div<{ $visible: boolean }>`
  visibility: ${(p) => (p.$visible ? 'visible' : 'hidden')};
`
