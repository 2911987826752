import React, { FC, memo } from 'react'

import { Form } from 'brief-form'
import { YesNoRadioGroup } from 'commons/types/enums'
import { CheckBox, Validators } from 'ui/components'
import { InfoSet } from 'ui/components/Info'
import { TextInput, TextareaInput } from 'ui/components/InputV2'
import { RadioGroup } from 'ui/components/RadioGroup'
import { Col, Col2x, FormRow } from 'ui/themes/globalStyles'

import { OnboardingFormFieldNames } from '../../enums'
import { ProfessionalPartnershipsFormProps } from './ProfessionalPartnershipsFormProps'
import { useProfessionalPartnershipsForm } from './useProfessionalPartnershipsForm'

import * as SC from './styles'

export const ProfessionalPartnershipsForm: FC<ProfessionalPartnershipsFormProps> = memo(
  ({
    value,
    companyIndex,
    state: {
      isProPartPrevClosureReasonEnable,
      isProPartCurrentServicesOtherDescEnable,
      isProPartCurrentServicesLogisticEnable,
      isProPartCurrentServicesLogisticDescEnable,
      isProPartCurrentServicesOtherDescDisabledEnable,
      isProPartInternetGamblingEnable
    },
    onChange
  }) => {
    const { Field, config } = useProfessionalPartnershipsForm({ companyIndex, formData: value, onChange })

    return (
      <Form config={config}>
        <InfoSet legend="Professional Partnerships">
          <FormRow>
            <Col2x>
              <Field
                name={OnboardingFormFieldNames.PRO_PART_CURRENTLY_BANKED}
                label="Does the organization currently have an existing banking relationship?"
                input={RadioGroup}
                inputProps={{
                  name: `${OnboardingFormFieldNames.PRO_PART_CURRENTLY_BANKED}${companyIndex}`,
                  'data-qa': 'Does the organization currently have an existing banking relationship?',
                  data: [
                    { value: YesNoRadioGroup.YES, label: 'Yes' },
                    { value: YesNoRadioGroup.NO, label: 'No' }
                  ]
                }}
                required
                validator={Validators.required.notNull}
              />
            </Col2x>
          </FormRow>
          <FormRow>
            <Col2x>
              <Field
                name={OnboardingFormFieldNames.PRO_PART_PAYROLL_SOFTWARE}
                label="What payroll software or service provider is the organization currently utilizing?"
                input={TextInput}
                inputProps={{
                  'data-qa': 'What payroll software or service provider is the organization currently utilizing?'
                }}
              />
            </Col2x>
          </FormRow>
          {isProPartPrevClosureReasonEnable && (
            <FormRow>
              <Col2x>
                <Field
                  name={OnboardingFormFieldNames.PRO_PART_PREV_CLOSURE_REASON}
                  label="Reason for any previous account closures?"
                  required={config.value.proPartCurrentlyBanked === YesNoRadioGroup.YES}
                  validator={
                    config.value.proPartCurrentlyBanked === YesNoRadioGroup.YES ? Validators.required.field : null
                  }
                  input={TextareaInput}
                  inputProps={{
                    'data-qa': 'Reason for any previous account closures?',
                    rows: 3
                  }}
                />
              </Col2x>
            </FormRow>
          )}
          <FormRow>
            <Col2x>
              <p style={{ marginBottom: '10px' }}>
                What professional services is the organization currently in contract with or have on staff?
              </p>
              <SC.Checkboxes>
                <div>
                  <Field
                    name={OnboardingFormFieldNames.PRO_PART_CURRENT_SERVICES_CP_AS}
                    input={CheckBox}
                    inputProps={{
                      checkBoxLabel: 'CPAs',
                      'data-qa': 'CPAs'
                    }}
                  />
                </div>
                <div>
                  <Field
                    name={OnboardingFormFieldNames.PRO_PART_CURRENT_SERVICES_LAW}
                    input={CheckBox}
                    inputProps={{
                      checkBoxLabel: 'Lawyers/General Counsel',
                      'data-qa': 'Lawyers/General Counsel'
                    }}
                  />
                </div>

                {isProPartCurrentServicesLogisticEnable && (
                  <div>
                    <Field
                      name={OnboardingFormFieldNames.PRO_PART_CURRENT_SERVICES_LOGISTIC}
                      input={CheckBox}
                      inputProps={{
                        checkBoxLabel: 'Cash Logistic Services (list below)',
                        'data-qa': 'Cash Logistic Services'
                      }}
                    />
                  </div>
                )}
                <div>
                  <Field
                    name={OnboardingFormFieldNames.PRO_PART_CURRENT_SERVICES_OTHER}
                    input={CheckBox}
                    inputProps={{
                      checkBoxLabel: isProPartCurrentServicesOtherDescEnable
                        ? 'Other (For example, cash processor, physical security, compliance related vendors)'
                        : 'Other (describe below)',
                      'data-qa': 'Other (describe below)'
                    }}
                  />
                </div>
              </SC.Checkboxes>
            </Col2x>
          </FormRow>

          {isProPartCurrentServicesLogisticDescEnable && (
            <FormRow>
              <Col2x>
                <Field
                  name={OnboardingFormFieldNames.PRO_PART_CURRENT_SERVICES_LOGISTIC_DESC}
                  label="Cash Logistic Services list"
                  input={TextareaInput}
                  inputProps={{
                    'data-qa': 'Cash Logistic Services list',
                    rows: 3,
                    disabled: !config.value.proPartCurrentServicesLogistic
                  }}
                />
              </Col2x>
            </FormRow>
          )}
          <FormRow>
            <Col2x>
              <Field
                name={OnboardingFormFieldNames.PRO_PART_CURRENT_SERVICES_OTHER_DESC}
                label="Other services description"
                input={TextareaInput}
                inputProps={{
                  'data-qa': 'Other services description',
                  rows: 3,
                  disabled: isProPartCurrentServicesOtherDescDisabledEnable
                    ? !config.value.proPartCurrentServicesOther &&
                      !config.value.proPartCurrentServicesCPAs &&
                      !config.value.proPartCurrentServicesLaw
                    : !config.value.proPartCurrentServicesOther
                }}
              />
            </Col2x>
          </FormRow>
          <FormRow>
            <Col2x>
              <Field
                name={OnboardingFormFieldNames.PRO_PART_LITIGATION}
                // eslint-disable-next-line max-len
                label="List any litigation against the business entity in the last ten (10) years and provide a brief summary of each, if any:"
                input={TextareaInput}
                inputProps={{
                  'data-qa': 'Pro part litigation',
                  rows: 3
                }}
              />
            </Col2x>
          </FormRow>
          <FormRow>
            <Col2x>
              <Field
                name={OnboardingFormFieldNames.PRO_PART_STATE_ENFORCEMENTS}
                // eslint-disable-next-line max-len
                label="List any State Enforcement Actions against the MRB in the last ten (10) years and provide a brief summary of each, if any:"
                input={TextareaInput}
                inputProps={{
                  'data-qa': 'Pro part state enforcements',
                  rows: 3
                }}
              />
            </Col2x>
          </FormRow>
          {isProPartInternetGamblingEnable && (
            <FormRow>
              <Col>
                <Field
                  name={OnboardingFormFieldNames.PRO_PART_INTERNET_GAMBLING}
                  label="Does the business engage in internet gambling?"
                  input={RadioGroup}
                  inputProps={{
                    name: `${OnboardingFormFieldNames.PRO_PART_INTERNET_GAMBLING}${companyIndex}`,
                    'data-qa': 'Does the business engage in internet gambling?',
                    data: [
                      { value: YesNoRadioGroup.YES, label: 'Yes' },
                      { value: YesNoRadioGroup.NO, label: 'No' }
                    ]
                  }}
                />
              </Col>
            </FormRow>
          )}
        </InfoSet>
      </Form>
    )
  }
)
