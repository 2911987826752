import { Link } from '@tanstack/react-router'
import styled from 'styled-components'

export const Box = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${(p) => p.theme.uiKit.colors.global.grey.grey300};
  padding: 0 50px 15px 50px;
  margin: 0 -50px;
`

export const Company = styled(Link)`
  color: ${(p) => p.theme.uiKit.colors.global.grey.grey500};
  text-decoration: none;
`

export const Account = styled.span`
  color: ${(p) => p.theme.uiKit.colors.global.grey.grey700};
  margin: 0 10px;
  display: flex;
`

export const Arrow = styled.b`
  display: block;
  margin-left: 10px;
  color: ${(p) => p.theme.uiKit.colors.global.grey.grey500};
`

export const Wrapper = styled.div`
  display: flex;
  color: ${(p) => p.theme.uiKit.colors.global.grey.grey700};
`
