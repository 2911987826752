import { useGetContact } from 'commons/hooks/bank/contact'
import { Contact } from 'commons/types/DTO/bank'

export const emptyContact: Contact = {
  bio: '',
  birthdate: null,
  city: '',
  country: 'US',
  created_at: null,
  deleted_at: null,
  ein_ssn: '',
  email: '',
  entity_name: '',
  first_name: '',
  id: 0,
  last_name: '',
  mobile_phone: '',
  organization_id: 0,
  phone: '',
  sf_external_id: null,
  state: '',
  street: '',
  updated_at: null,
  zip_code: '',
  contact_metadata: []
}

export type UseContactItemReturnValue = {
  isFetched: boolean
  isFetching: boolean
  contact?: Contact
}

export const useContactItem = (id: number): UseContactItemReturnValue => {
  const { isFetching, data: contact, isFetched } = useGetContact(id)

  if (id) {
    return {
      isFetched,
      isFetching,
      contact
    }
  }
  return {
    isFetched: true,
    isFetching: false,
    contact: emptyContact
  }
}
