import React from 'react'

import { Validators } from 'ui/components/Form'
import { TextareaInput } from 'ui/components/InputV2'
import { Select } from 'ui/components/Select'
import { Col, Col2x, FormRow } from 'ui/themes/globalStyles'

import { MainText, SecondText } from '../../style'
import { ReviewTheCashActiviyProps } from '../ReviewTheCashActivity/ReviewTheCashActiviyProps'

export const ComparePriorAndCurrentPercentage = ({ formValue, Field }: ReviewTheCashActiviyProps) => (
  <>
    <MainText>
      The business receives substantially more revenue than may reasonably be expected given the relevant limitations
      imposed by the state in which it operates.
    </MainText>
    <MainText>
      The business makes cash deposits or withdrawals over a short period of time that are excessive related to local
      competitors or the expected activity of the business.
    </MainText>
    <SecondText>
      Compare prior and current year’s activity for a 25% variance. If not within the variance, contact customer to
      understand differences. (Fed approved 11/07/17)
    </SecondText>
    <FormRow>
      <Col>
        <Field
          name="comparePriorAndCurrentPercentageRedFlag"
          label="Red Flag?"
          input={Select}
          inputProps={{
            data: [
              {
                label: 'Yes',
                value: 'yes'
              },
              {
                label: 'No',
                value: 'no'
              }
            ]
          }}
        />
      </Col>
      <Col>
        <Field
          required
          name="comparePriorAndCurrentPercentageVariance"
          label="Is activity within variance?"
          input={Select}
          inputProps={{
            data: [
              {
                label: 'All activity in Variance',
                value: 'All activity in Variance'
              },
              {
                label: 'See validated Variance and/or Annual Questions',
                value: 'See validated Variance and/or Annual Questions'
              },
              {
                label: 'New Account - No historical data',
                value: 'New Account'
              },
              {
                label: 'Other - See Comments',
                value: 'other'
              }
            ]
          }}
          validator={Validators.required.field}
        />
      </Col>
    </FormRow>
    <FormRow>
      <Col2x>
        <Field
          name="comparePriorAndCurrentPercentageComments"
          required={formValue.comparePriorAndCurrentPercentageVariance === 'other'}
          label="Comments"
          input={TextareaInput}
          inputProps={{}}
          validator={
            formValue.comparePriorAndCurrentPercentageVariance === 'other' ? Validators.required.field : undefined
          }
        />
      </Col2x>
    </FormRow>
  </>
)
