import styled from 'styled-components'
import { Icon } from 'ui/components'

//region Styled

export const ErrorMessageBox = styled.div`
  text-align: left;
`

export const ErrorIcon = styled(Icon)`
  > svg {
    fill: ${(p) => p.theme.uiKit.colors.theme.negative.main};
    color: ${(p) => p.theme.uiKit.colors.theme.negative.main};
  }
  display: inline;
  vertical-align: middle;
`

export const ErrorsBlock = styled.div`
  margin-bottom: 30px;
`

export const Header = styled.h5`
  margin-bottom: 10px;
`

//endregion
