import { useState } from 'react'

import { getRouteApi, useNavigate } from '@tanstack/react-router'
import { useDeleteBatch, useGetBsaBatchDetailsWebPage } from 'commons/hooks/bank/bsa'
import { BsaBatchDetailsOptions } from 'commons/types/DTO/bank/bsa'
import { handlerServerError } from 'errors'
import { TablePaginationChangeHandler } from 'ui/components'
import { useConfirmation } from 'ui/hooks'

export const useBsaBatchDetails = () => {
  const navigate = useNavigate()
  const { id } = getRouteApi('/bsa/batch-details/$id').useParams()
  const [options, setOptions] = useState<BsaBatchDetailsOptions>({ id: +id, historyPage: 1, historySize: 10 })
  const { data: details, isFetching } = useGetBsaBatchDetailsWebPage(options)

  const onHistoryPageChange: TablePaginationChangeHandler = (historyPage, historySize) => {
    setOptions((options) => ({ ...options, historyPage, historySize }))
  }

  const { routine: deleteBatch } = useDeleteBatch({
    onSuccess: async () => {
      navigate({ to: '/bsa/sar-batch-list' })
    },
    onError: (error) => handlerServerError(error)
  })

  const { open: startDeleting, Confirmation: DeleteConfirmation } = useConfirmation({
    message: `Are you sure you want to delete ${id || 'this'} Batch?`,
    onConfirm: () => deleteBatch(+id),
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  return {
    details,
    isFetching,
    options,
    onHistoryPageChange,
    startDeleting,
    DeleteConfirmation
  }
}
