import styled from 'styled-components'

export const Columns = styled.div`
  display: flex;
`

export const Column1 = styled.div`
  margin-right: 60px;
`

export const FormWrapper = styled.div`
  padding: 20px 30px 32px 30px;
`

export const StatusWrapper = styled.div`
  width: 156px;
  margin-right: 30px;
`

export const NumberWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 182px;

  > span {
    display: inline-block;
    font-size: 13px;
    margin: 0 7px -20px 0;
  }
`

export const DateFromWrapper = styled.div`
  width: 156px;
  margin-right: 52px;
`

export const CompanyWrapper = styled.div`
  width: 156px;
  margin-right: 30px;
`

export const LicenseWrapper = styled.div`
  width: 230px;
  margin-right: 30px;
`
