import { createRoute, redirect } from '@tanstack/react-router'
import { HISTORY_PATH } from 'commons/constants/routes'

import { BankHistoryWrapper } from './BankHistoryWrapper'
import { historyListRoute } from './routes/HistoryAuditList/route'

export const routeHistoryTree = (rootRoute: BPRootRoute) => {
  const companiesIndexRoute = createRoute({
    beforeLoad: async ({ context }) => {
      if (!context.accessibleRoutingFlags.historyRootAccess) {
        throw redirect({
          to: '/'
        })
      }
    },
    getParentRoute: () => rootRoute,
    path: HISTORY_PATH,
    component: BankHistoryWrapper
  })

  return companiesIndexRoute.addChildren([historyListRoute<typeof companiesIndexRoute>(companiesIndexRoute)])
}
