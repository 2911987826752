import { useCallback, useEffect } from 'react'

import { useFormData } from 'brief-form'
import { isEqual } from 'lodash'

import {
  AddBankAccountValidateFunctionRefs,
  BankAccountFormOnChange,
  ConnectBankAccountToLicensesFormData,
  ConnectBankAccountToLicensesFormState,
  ConnectBankAccountToLicensesFormValue
} from '../../types'

type UseConnectBankAccountToLicensesFormReturn = {
  formData: ConnectBankAccountToLicensesFormData
  onRemoveLicense: (license: number | string) => void
}

type UseConnectBankAccountToLicensesFormParams = {
  formState: ConnectBankAccountToLicensesFormState
}

type UseConnectBankAccountToLicensesFormHook = (
  params: UseConnectBankAccountToLicensesFormParams
) => UseConnectBankAccountToLicensesFormReturn

export const useConnectBankAccountToLicensesForm: UseConnectBankAccountToLicensesFormHook = ({ formState }) => {
  const { initialValue, formName, validateFunctionRefs, onChange: formStateOnChange } = formState

  const onFormChanged: BankAccountFormOnChange = useCallback(
    (formValues, formErrors) => formStateOnChange({ formName, formData: { value: formValues, errors: formErrors } }),
    [formStateOnChange, formName]
  )

  const formData = useFormData<ConnectBankAccountToLicensesFormValue>({
    initialValue: initialValue.value,
    initialErrors: initialValue.errors,
    onFormChanged
  })

  // outside update
  useEffect(() => {
    if (!isEqual(formData.config.value, initialValue.value)) {
      formData.config.onChange(initialValue.value, initialValue.errors)
    }
  }, [initialValue.value, formData.config, initialValue.errors])

  useEffect(() => {
    if (validateFunctionRefs) {
      validateFunctionRefs.current = {
        ...validateFunctionRefs.current,
        [`${formName}`]: { formData }
      }
    }

    return () => {
      const newValidationFormData: AddBankAccountValidateFunctionRefs = {}
      const oldValidationFormData = validateFunctionRefs.current
      Object.keys(oldValidationFormData).forEach((formKey) => {
        if (formKey === formName) {
          return
        }
        newValidationFormData[formKey] = oldValidationFormData[formKey]
      })
      validateFunctionRefs.current = newValidationFormData
    }
    // validateFunctionRefs is ref no nedd to observe
  }, [formData, formName])

  const handleRemoveLicense = (licenseId: number | string) => {
    const newAssignedLicenses = formData.config.value.assignedLicenses.filter((id) => id !== licenseId)
    const newFormValue = { ...formData.config.value, assignedLicenses: newAssignedLicenses }
    formData.config.onChange(newFormValue, formData.config.errors)
  }
  return { formData, onRemoveLicense: handleRemoveLicense }
}
