import { useEffect, useRef } from 'react'
import { useQueryClient } from 'react-query'

import { useLogout } from 'auth'
import { AuthMFAState } from '~auth/types/DTO'

import { useResetChallenge } from './useResetChallenge'

export const useChallengeExpiration = (isLogout: boolean) => {
  const timer = useRef<any>({ id: 0 })
  const client = useQueryClient()
  const { logout } = useLogout()
  const resetMutation = useResetChallenge()

  useEffect(() => {
    timer.current.id = setInterval(() => {
      const mfa = client.getQueryData<AuthMFAState>(['mfa'])
      if (new Date(mfa?.challengeExpiresAt || '').getTime() < Date.now()) {
        if (isLogout) {
          logout({})
        } else {
          resetMutation.mutate()
        }
      }
    }, 5000)

    return () => {
      clearInterval(timer.current.id)
    }
  }, [logout, resetMutation, isLogout])
}
