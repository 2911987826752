/* eslint-disable camelcase */
import { ApplicationQuestionnaireATMInfoItem } from './applicationQuestionnaireATMInfoItem'

export type ApplicationQuestionnairePart = {
  contacts_foreign_connections?: boolean
  states_licensed?: string
  a_wholesale_products?: boolean
  a_wholesale_products_desc?: string
  b_type_of_products?: string
  c_type_of_ancillary_products?: string
  d_how_many_locations?: string
  e_online_orders_for_pickup?: boolean
  f_minor_selling_ensure?: string
  g_payment_methods?: string
  h_transfer_to_other_states_desc?: string
  h_transfer_to_other_states?: boolean
  i_are_you_affiliated_desc?: string
  i_are_you_affiliated?: boolean
  who_supplies_you?: string
  where_is_your_supplier?: string
  who_do_you_supply_grower?: string
  grower_customers_location?: string
}

export type ApplicationProfessionalPartnership = {
  pro_part_currently_banked?: boolean
  pro_part_prev_closure_reason?: string
  pro_part_current_services_cpas?: boolean
  pro_part_current_services_law?: boolean
  pro_part_current_services_logistic?: boolean
  pro_part_current_services_logistic_desc?: string
  pro_part_current_services_other_desc?: string
  pro_part_current_services_other?: boolean
  pro_part_litigation?: string
  pro_part_state_enforcements?: string
  pro_part_internet_gambling: boolean
}

export type ApplicationATMGeneralInfo = {
  atm_have?: boolean
  atm_own?: boolean
  atm_count?: string
  atm_replenishing_company?: boolean
  atm_replenishing_third_party?: boolean
  pro_part_payroll_software?: string
}

export type ApplicationAvailableServices = {
  avail_service_checking_operating?: boolean
  avail_service_checking_payroll?: boolean
  avail_service_checking?: boolean
  avail_service_savings?: boolean
  avail_service_ach_origination?: boolean
  avail_service_mobile_deposit?: boolean
  avail_service_wire?: boolean
  avail_service_rolling_coin_change_order?: boolean
  avail_service_remote_deposit_capture?: boolean
  avail_service_escrow?: boolean
  avail_service_online_mobile_banking?: boolean
  avail_service_bill_pay?: boolean
  avail_service_debit_card?: boolean
  avail_service_credit_card?: boolean
  avail_service_wire_domestic?: boolean
  avail_service_wire_domestic_only?: boolean
  avail_service_wire_foreign?: boolean
  avail_service_personal_checking?: boolean
  avail_service_personal_savings?: boolean
  avail_service_atm_settlement?: boolean // TODO: no in forms
  avail_service_how_many_accounts_is_opening: boolean
}

export type ApplicationAnticipatedMonthlyActivityNA = {
  activity_deposits_checks_items?: number
  activity_deposits_checks_amount?: number
  activity_deposits_checks_na?: boolean
  activity_deposits_cash_items?: number
  activity_deposits_cash_amount?: number
  activity_deposits_cash_na?: boolean
  activity_deposits_other_items?: number
  activity_deposits_other_amount?: number
  activity_deposits_other_na?: boolean
  activity_withdrawals_cash_amount?: number
  activity_withdrawals_cash_items?: number
  activity_withdrawals_cash_na?: boolean
}

export type ApplicationAnticipatedMonthlyActivity = {
  anticipated_activity_ach_deposits: boolean
  anticipated_activity_ach_deposits_amount: number
  anticipated_activity_ach_deposits_frequency: string
  anticipated_activity_wires_international_countries: string
  anticipated_activity_wires_international: boolean
  anticipated_activity_wires_international_amount: number
  anticipated_activity_wires_international_frequency: string
  anticipated_activity_ach_originating: boolean
  anticipated_activity_ach_originating_amount: number
  anticipated_activity_ach_originating_frequency: string
  anticipated_activity_atm_withdrawals: boolean
  anticipated_activity_atm_withdrawals_amount: number
  anticipated_activity_atm_withdrawals_frequency: string
  anticipated_activity_cash_deposits: boolean
  anticipated_activity_cash_deposits_amount: number
  anticipated_activity_cash_deposits_frequency: string
  anticipated_activity_check_deposits: boolean // no in Application
  anticipated_activity_check_deposits_amount: number // no in Application
  anticipated_activity_check_deposits_frequency: string // no in Application
  anticipated_activity_cash_withdrawals: boolean
  anticipated_activity_cash_withdrawals_amount: number
  anticipated_activity_cash_withdrawals_frequency: string
  anticipated_activity_check_withdrawals: boolean
  anticipated_activity_check_withdrawals_amount: number
  anticipated_activity_check_withdrawals_frequency: string
  anticipated_activity_remote_deposits: boolean
  anticipated_activity_remote_deposits_amount: number
  anticipated_activity_remote_deposits_frequency: string

  anticipated_activity_wires_domestic_only?: boolean
  anticipated_activity_wires_domestic_only_amount?: number
  anticipated_activity_wires_domestic_only_frequency?: string

  anticipated_activity_wires_domestic: boolean
  anticipated_activity_wires_domestic_amount: number
  anticipated_activity_wires_domestic_frequency: string

  anticipated_activity_atm_settlement: boolean
  anticipated_activity_atm_settlement_amount: number
  anticipated_activity_atm_settlement_frequency: string
}

export type ApplicationMerchantQuestionnaire = {
  legacy_cash?: boolean
  merchant_processing?: boolean
  merchant_processing_desc?: string
  primary_method_to_fund?: string
  cryptocurrency_transactions?: boolean
  real_time_payment_services?: boolean
}

export type ApplicationQuestionnaire = ApplicationQuestionnairePart &
  ApplicationProfessionalPartnership &
  ApplicationATMGeneralInfo &
  ApplicationAvailableServices &
  ApplicationAnticipatedMonthlyActivityNA &
  ApplicationAnticipatedMonthlyActivity &
  ApplicationMerchantQuestionnaire & {
    // Questionnaire
    // ApplicationAnticipatedMonthlyActivityNA
    // ApplicationAnticipatedMonthlyActivity

    // ApplicationATMGeneralInfo

    atm_info?: ApplicationQuestionnaireATMInfoItem[]
    // ApplicationAvailableServices
    // ApplicationMerchantQuestionnaire

    // ApplicationProfessionalPartnership
  }
