import { useMutation, useQueryClient } from 'react-query'

import { PortalName } from 'commons/types/enums'
import { createDevice } from '~auth/service/mfa'
import { AuthCreateDeviceResponse, AuthMFAState } from '~auth/types/DTO'

import { useSendChallenge } from './useSendChallenge'

export const useCreateDevice = (p: PortalName) => {
  const client = useQueryClient()
  const challengeMutation = useSendChallenge(p)

  return useMutation((data: { type: string; name: string; token: string }) => createDevice(p, data.type, data.name), {
    onError: (error: Error) => {
      throw error
    },
    onSuccess: async (response: AuthCreateDeviceResponse, variables) => {
      const mfa = client.getQueryData<AuthMFAState>(['mfa'])

      client.setQueryData<Partial<AuthMFAState>>(['mfa'], {
        ...mfa,
        chosen: {
          id: response.id,
          type: variables.type,
          name: variables.name,
          totpUrl: response.totp_url || '',
          totpSecret: response.totp_secret || ''
        }
      })

      challengeMutation.mutate({ deviceId: response.id, token: variables.token })
    }
  })
}
