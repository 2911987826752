import { useCallback } from 'react'
import { useMutation } from 'react-query'

import { getApplicationLink } from '../service'

type UseGetApplicationLinkReturnValue = {
  getLink: (id: number) => Promise<{ link: string }>
}

export const useGetApplicationLink = (): UseGetApplicationLinkReturnValue => {
  const mutation = useMutation((id: number) => getApplicationLink(id))

  const getLink = useCallback((id: number) => mutation.mutateAsync(id), [mutation])

  return {
    getLink
  }
}
