import React, { useState } from 'react'

import { useGetBankAccountList } from 'commons/hooks/bank/bankAccount'
import { LicenseDeprecated } from 'commons/types/DTO/bank'
import { TableDataQa } from 'commons/types/enums'
import SC from 'styled-components'
import { IconSize } from 'ui/components/Icon'
import { AnnualReviewStatusLabel } from 'ui/components/Labels'
import { Layer } from 'ui/components/Layer'
import { PageSection, PageSectionFace } from 'ui/components/Page'
import { Table } from 'ui/components/Table'
import { TextMask } from 'ui/components/TextMask'
import { useConfirmation } from 'ui/hooks'
import { useAnnualReviewSalesDeposits, useRecalculateAnnualReviewSalesDeposits } from '~bank-annual-review/hooks'
// eslint-disable-next-line max-len
import { useDeleteAnnualReviewSalesDeposits } from '~bank-annual-review/hooks/AnnualReview/useDeleteAnnualReviewSalesDeposits'

import { AnnualReviewActions } from '../AnnualReviewActions'
import { createColumns } from './columns'
import { createDepositDataSources, createSalesDataSources } from './dataSource'

const Wrapper = SC.div`
  margin-top: 30px;
`

const Title = SC.div`
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.53px;
  padding: 12px 16px;
  color: #000000;
  border-top: 2px solid #000000;

  @media print {
    border-top-width: 0px;
  }
`

const Text = SC.p`
  margin: 0;
  font-size: 14px;
  font-weight: normal;
`

export interface AnnualReviewSummaryTablesProps {
  id: number
  companyId: number
  showRecalculateBtn?: boolean
  licenses: LicenseDeprecated[]
}

const getLicense = (licenses: LicenseDeprecated[], id?: number) => licenses.find((li) => li.id === id)

export const AnnualReviewSummaryTables = ({
  id,
  companyId,
  showRecalculateBtn,
  licenses
}: AnnualReviewSummaryTablesProps): JSX.Element => {
  const [salesDepositIdForDelete, setSalesDepositIdForDelete] = useState<number | null>(null)

  const { data, isFetching } = useAnnualReviewSalesDeposits({ id })
  const mutation = useRecalculateAnnualReviewSalesDeposits()
  const deleteMutation = useDeleteAnnualReviewSalesDeposits()

  const bankAccountIds: number[] = []
  const annualReviewSalesDeposits = data?.records

  if (!isFetching && annualReviewSalesDeposits) {
    annualReviewSalesDeposits.forEach((item) => item.bank_account_id && bankAccountIds.push(item.bank_account_id))
  }

  const bankAccountList = useGetBankAccountList({ ids: bankAccountIds })

  const deleteSalesDeposit = () => {
    salesDepositIdForDelete &&
      deleteMutation.mutate({
        id: salesDepositIdForDelete,
        company_id: companyId,
        annual_review_id: id
      })
  }

  const onRecalculate = (tableId?: number) => {
    tableId && mutation.mutate(tableId)
  }

  const generateOnDeleteSalesDepositCallback = (depositId?: number) => () => {
    if (depositId) {
      setSalesDepositIdForDelete(depositId)
      openDeleteConfirmation()
    }
  }

  const { Confirmation: DeleteConfirmation, open: openDeleteConfirmation } = useConfirmation({
    message: (
      <span>
        Are you sure want to delete <i>SDS-{salesDepositIdForDelete}</i> Sales and Deposit table?
      </span>
    ),
    onConfirm: deleteSalesDeposit,
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  return (
    <>
      <a id="sales" />
      <Wrapper>
        <DeleteConfirmation />
        <PageSection title="Sales & Deposit Summary Tables" face={PageSectionFace.THIRD}>
          {!isFetching && annualReviewSalesDeposits && annualReviewSalesDeposits.length !== 0 ? (
            annualReviewSalesDeposits.map((item) => {
              const bankAccount =
                bankAccountList.data &&
                bankAccountList.data.find((bankAccount) => bankAccount.id === item.bank_account_id)

              const title = (
                <p style={{ display: 'flex' }}>
                  Bank Account $
                  <TextMask
                    isBankAccount
                    text={bankAccount ? bankAccount.number : ''}
                    link={`/bank-accounts/${bankAccount?.id}`}
                    iconSize={IconSize.XS}
                  />
                </p>
              )

              return (
                <section key={item.id}>
                  <a id={`sales-${item.id}`} />
                  <PageSection
                    face={PageSectionFace.PRIMARY}
                    title={title}
                    title-before={
                      <AnnualReviewStatusLabel
                        name={item.status ?? ''}
                        created={Date.parse(item.created_at ?? '')}
                        updated={Date.parse(item.updated_at ?? '')}
                      />
                    }
                    actions={
                      <AnnualReviewActions
                        id={item.id}
                        edit
                        onRecalculate={showRecalculateBtn ? onRecalculate : undefined}
                        editUrl={`/annual-review/edit-sales-and-deposit/${companyId}/${id}/${item.id}?bank_account=${
                          bankAccountList.data &&
                          bankAccountList.data.find((bankAccount) => bankAccount.id === item.bank_account_id)?.id
                        }`}
                        deleteCallback={generateOnDeleteSalesDepositCallback(item.id)}
                      />
                    }
                  >
                    <Layer rounded shadowed>
                      <Title>Sales Summary</Title>
                      <Table
                        dataQa={TableDataQa.BP_ANNUAL_REVIEW_DETAILS_SALES_SUMMARY}
                        columns={createColumns(item)}
                        dataSource={createSalesDataSources(item, getLicense(licenses, item.license_id)?.subtype)}
                      />
                      <Title>Deposit Detail</Title>
                      <Table
                        dataQa={TableDataQa.BP_ANNUAL_REVIEW_DETAILS_DEPOSIT_DETAIL}
                        columns={createColumns(item)}
                        dataSource={createDepositDataSources(item, getLicense(licenses, item.license_id)?.subtype)}
                      />
                    </Layer>
                  </PageSection>
                </section>
              )
            })
          ) : (
            <Text>Nothing to show</Text>
          )}
        </PageSection>
      </Wrapper>
    </>
  )
}
