import { AnyRoute, createRoute, lazyRouteComponent } from '@tanstack/react-router'

export const ListRoute = <T extends AnyRoute>(indexRoute: T) =>
  createRoute({
    getParentRoute: () => indexRoute,
    path: `/`,
    component: lazyRouteComponent(() => import('./List')),
    validateSearch: (search: Record<string, string>) => {
      return {
        trans_start_date: (search.trans_start_date as string) || '',
        trans_end_date: (search.trans_end_date as string) || ''
      }
    }
  })
