import { notFound } from '@tanstack/react-router'
import { getApplicationWebPage } from 'commons/service/bank/application'
import isInteger from 'lodash/isInteger'

import { ApplicationDetailsLoaderReturn } from '../../types'

type ApplicationDetailsLoader = ({ params }: { params: any }) => Promise<ApplicationDetailsLoaderReturn>

export const applicationDetailsLoader: ApplicationDetailsLoader = async ({ params }) => {
  const { applicationId: applicationIdStr } = params
  const applicationIdRequest = parseInt(applicationIdStr, 10)

  // invalid applicationId Search param
  // TODO: replace to parser
  if (!isInteger(applicationIdRequest)) {
    throw notFound()
  }

  const applicationWP = getApplicationWebPage(applicationIdRequest)

  const [applicationResponse] = await Promise.all([applicationWP])

  const applicationId = applicationResponse?.applicationID

  // 404
  if (!isInteger(applicationId)) {
    throw notFound()
  }

  return { application: applicationResponse, applicationId }
}
