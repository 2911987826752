import styled, { css } from 'styled-components'
import { Spinner as UnstyledSpinner } from 'ui/components/Spinner'

import { ButtonFace } from './ButtonFace'
import {
  getActiveBgColor,
  getActiveTextColor,
  getFocusBgColor,
  getFocusTextColor,
  getHoverBgColor,
  getHoverTextColor,
  getMainBgColor,
  getMainTextColor
} from './helpers'

export const Spinner = styled(UnstyledSpinner)`
  border-color: var(--theme-colors-global-general-main) transparent var(--theme-colors-global-general-main) transparent;
`

export const Inner = styled.span.attrs({ className: `theme-tags-typography-bodyM-button` })`
  color: inherit;
  display: block;
  white-space: nowrap;
`

export const Box = styled.button<{ $face: ButtonFace; disabled?: boolean }>`
  ${({ $face }) => css`
    cursor: pointer;
    min-width: 96px;
    border: none;

    background-color: ${getMainBgColor($face)};
    color: ${getMainTextColor($face)};
    /* figma use border name, but actually, this look like outline, so use outline, except border */
    outline: solid var(--theme-colors-global-general-transparent) var(--theme-borderWidth-strokeM);

    height: var(--theme-size-buttonsHeight-middle);
    padding-left: var(--theme-space-padding-button-middle);
    padding-right: var(--theme-space-padding-button-middle);

    border-radius: var(--theme-borderRadius-default);

    transition: background 0.15s, color 0.15s, outline 0.15s, box-shadow 0.15s;

    &:hover {
      background-color: ${getHoverBgColor($face)};
      color: ${getHoverTextColor($face)};
      box-shadow: var(--theme-boxShadows-offset-offset100Y4);
    }

    &:active {
      background-color: ${getActiveBgColor($face)};
      color: ${getActiveTextColor($face)};
      box-shadow: none;
    }

    &:focus-visible {
      background-color: ${getFocusBgColor($face)};
      outline-color: var(--theme-colors-global-grey-grey500);
      color: ${getFocusTextColor($face)};
      box-shadow: var(--theme-boxShadows-offset-offset100Y4);
    }

    &:disabled {
      background-color: var(--theme-colors-global-grey-grey300);
      color: var(--theme-colors-global-grey-grey500);
      cursor: not-allowed;
      box-shadow: none;
    }
  `}
`

export const BoxLoading = styled(Box)`
  ${({ $face }) => css`
    transition: none;
    position: relative;
    &:active,
    &:focus-visible,
    &:hover,
    &:disabled {
      cursor: wait;
      border: none;
      background-color: ${getMainBgColor($face)};
      outline-width: var(--theme-borderWidth-strokeNone);
      box-shadow: var(--theme-boxShadows-none-shadowNone);
    }
  `}
`
