import styled from 'styled-components'
import { Button } from 'ui/components/Buttons'

export const ItemFiles = styled.div`
  display: flex;
  gap: 10px;
  justify-content: end;
`

export const ItemFilesButton = styled(Button)`
  margin-left: auto;
  padding: 0 !important;
`
