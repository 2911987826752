import React, { FC, memo } from 'react'

import { mergeArrayToString } from 'commons/utils/mergeArrayToString'
import { Info, InfoSet, InfoSetDirection } from 'ui/components/Info'

import { AddressInformationProps } from './AddressInformationProps'

export const AddressInformation: FC<AddressInformationProps> = memo((props) => {
  const {
    street,
    city,
    state,
    postalCode,
    country,
    mailingStreet,
    mailingCity,
    mailingState,
    mailingPostalCode,
    mailingCountry
  } = props

  const physicalAddress = mergeArrayToString(', ', [street, city, state, postalCode, country])
  const mailingAddress = mergeArrayToString(', ', [
    mailingStreet,
    mailingCity,
    mailingState,
    mailingPostalCode,
    mailingCountry
  ])

  return (
    <InfoSet legend="Address Information" direction={InfoSetDirection.COLUMN}>
      <Info label="Physical Address">{physicalAddress}</Info>
      <Info label="Mailing Address">{mailingAddress}</Info>
    </InfoSet>
  )
})
