import React, { FC } from 'react'

import { Link } from 'ui/components'

import { EntityActionLabels } from './enums'

import { SC } from './styles'

const AssignedEntitiesChangedLink: FC<{
  action: EntityActionLabels
  to: string
  elementName: string
  entityTypeLabel: string
}> = ({ action, to, elementName, entityTypeLabel }) => (
  <SC.Row>
    <span data-qa={`details-application-field-label`}>
      {action} {entityTypeLabel}
    </span>{' '}
    -{' '}
    <Link to={to} data-qa={`details-application-field-value`}>
      {elementName}
    </Link>
  </SC.Row>
)

export default AssignedEntitiesChangedLink
