import React, { Suspense } from 'react'

import { Outlet } from '@tanstack/react-router'
import { BPLayout } from 'ui/components/BPLayout'
import { Spinner, SpinnerSize } from 'ui/components/Spinner'

import { AlertsSideBar } from './index'

export const BankAlertsWrapper = () => (
  <BPLayout sidebar={<AlertsSideBar />}>
    <Suspense fallback={<Spinner centered size={SpinnerSize.M} />}>
      <Outlet />
    </Suspense>
  </BPLayout>
)
