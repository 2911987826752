import { AnyRoute, createRoute, lazyRouteComponent } from '@tanstack/react-router'

type Search = {
  licenseId: number
  licenseName: string
  returnUrl: string
}

export const CreateRoute = <T extends AnyRoute>(indexRoute: T) =>
  createRoute({
    getParentRoute: () => indexRoute,
    path: `add`,
    component: lazyRouteComponent(() => import('./CreateInvoice')),
    validateSearch: (search: Record<string, unknown>): Search => {
      const licenseId = Number(search.licenseId)
      return {
        returnUrl: (search.returnUrl as string) || '',
        licenseName: (search.licenseName as string) || '',
        licenseId: Number.isNaN(licenseId) ? 0 : licenseId
      }
    }
  })
