import { ATMItemFormValue } from '../../../types/forms'
import { CompanyFormValues } from '../../../types/state'

export const setNewAtms = ({
  atmIndex,
  oldCompany,
  newATM
}: {
  atmIndex: number
  oldCompany: CompanyFormValues
  newATM: ATMItemFormValue
}) => oldCompany.atms.map((atmItem, atmItemIndex) => (atmItemIndex === atmIndex ? newATM : atmItem))
