import { Request } from 'commons/utils/request'

import { InternalTransfersDetailsWebpage, InternalTransfersDetailsWebpagePayload } from './types'

export const api = {
  getDetailsWebpage: (payload: InternalTransfersDetailsWebpagePayload) =>
    Request.post<InternalTransfersDetailsWebpage, InternalTransfersDetailsWebpagePayload>(
      Request.urls.bank.internalTransferDetailsWebPage,
      payload
    )
}
