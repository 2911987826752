import React, { RefObject, memo, useCallback } from 'react'

import { BsaCtrPersonInfo } from 'commons/types/DTO/bank/bsa'
import isEqual from 'lodash/isEqual'
import { Button } from 'ui/components/Buttons'
import { PageSection, PageSectionFace } from 'ui/components/Page'

import { PersonInvolvedInformationForm } from './PersonInvolvedInformationForm'

interface Props {
  value: BsaCtrPersonInfo[]
  errors: any
  onChange: (value: BsaCtrPersonInfo[], errors: { [key: string]: any }[]) => any
  validateFunctionRefs: RefObject<any>
  markFormAsDirty: () => void
}

const EMPTY_PERSON_INFO = {
  account_numbers_in: [],
  account_numbers_out: [],
  address: null,
  alternate_name: null,
  birthdate: null,
  business_type: null,
  cash_in: 0,
  cash_out: 0,
  city: null,
  country: null,
  email: null,
  entity_name: null,
  first_name: null,
  gender: null,
  id_country: null,
  id_form: null,
  id_form_other: null,
  id_number: null,
  id_state: null,
  is_entity: false,
  last_name: null,
  middle_name: null,
  multiple_transactions: false,
  naics_code: null,
  phone: null,
  phone_ext: null,
  postal_code: null,
  state: null,
  suffix: null,
  tin: null,
  tin_type: null,
  type: null
}

export const PersonsInvolvedInformationList = memo((props: Props) => {
  const { value, errors, onChange, validateFunctionRefs, markFormAsDirty } = props

  const onChangeWrapper = useCallback(
    (i, v, e) => {
      const valueChanged = !isEqual(v, value[i])
      const errorsChanged = !isEqual(e, errors[i])

      if (valueChanged) {
        value[i] = v
      }
      if (errorsChanged) {
        errors[i] = e
      }
      if (valueChanged || errorsChanged) {
        onChange(value, errors)
      }
    },
    [value, onChange, errors]
  )

  const onAddWrapper = useCallback(() => {
    onChange(value.concat(EMPTY_PERSON_INFO), errors)

    markFormAsDirty()
  }, [value, errors, onChange])

  const onDeleteWrapper = useCallback(
    (index: number) => {
      const copy = [...value]
      copy.splice(index, 1)
      onChange(copy, errors)

      markFormAsDirty()
    },
    [value, errors, onChange]
  )

  return (
    <PageSection face={PageSectionFace.THIRD} title="Step 3. Person(s) Involved Information" anchor="step3">
      {value.map((item, index) => (
        <PersonInvolvedInformationForm
          key={index}
          value={item}
          errors={errors[index]}
          onChange={onChangeWrapper}
          onDelete={value.length > 1 ? onDeleteWrapper : undefined}
          index={index}
          total={value.length}
          validateFunctionRefs={validateFunctionRefs}
          markFormAsDirty={markFormAsDirty}
        />
      ))}
      <Button face="positive" onClick={onAddWrapper}>
        Add
      </Button>
    </PageSection>
  )
})
