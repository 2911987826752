import styled from 'styled-components'
import { IconButton } from 'ui/components/Buttons'

export const Box = styled.footer`
  z-index: 1000;
  height: 66px;
  max-width: calc(1920px - 224px);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: #fff;
  padding: 0 50px 0 50px;
  border-top: 1px solid ${(p) => p.theme.uiKit.colors.global.grey.grey300};
`

export const DeleteButton = styled(IconButton)`
  margin-right: auto;
`
