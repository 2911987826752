import React, { FC } from 'react'

import { Link } from '@tanstack/react-router'
import { Tooltip } from 'ui/components'
import { Button } from 'ui/components/Buttons'

import { SetupOnboardingDocumentsButtonProps } from './SetupOnboardingDocumentsButton.types'

import { s } from './styles'

export const SetupOnboardingDocumentsButton: FC<SetupOnboardingDocumentsButtonProps> = ({
  applicationId,
  enableSetupOnboardingDocuments
}) => {
  const buttonComponent = (
    <Link to="/application/$applicationId/onboarding-documents/setup" params={{ applicationId: applicationId }}>
      <Button face="positive" disabled={!enableSetupOnboardingDocuments}>
        Setup Onboarding Documents
      </Button>
    </Link>
  )

  if (enableSetupOnboardingDocuments) return buttonComponent
  else
    return (
      <Tooltip
        id="smart-filter-hint"
        type="dark"
        event="hover"
        // eslint-disable-next-line max-len
        content={
          <s.TooltipContentWrapper>
            You cannot set up onboarding documents because you have already created or sent a set of documents. Please
            edit or cancel them first.
          </s.TooltipContentWrapper>
        }
      >
        {buttonComponent}
      </Tooltip>
    )
}
