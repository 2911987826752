import { AnyRoute, createRoute, lazyRouteComponent } from '@tanstack/react-router'
import { NotFoundError } from 'commons/errors'
import { z } from 'zod'
import { getDocumentEditModeWebPage } from '~bank-documents/service/documentEdit'

const documentEditSchema = z.object({
  returnUrl: z.string().default('')
})

export const editDocumentRoute = <T extends AnyRoute>(indexRoute: T) =>
  createRoute({
    getParentRoute: () => indexRoute,
    component: lazyRouteComponent(() => import('./EditDocument')),
    path: '$documentId/edit',
    validateSearch: documentEditSchema,
    loader: async ({ params }) => {
      if ('documentId' in params) return await getDocumentEditModeWebPage(Number(params?.documentId))
      else throw new NotFoundError()
    },
    // this function checks if it's not first route appearance
    // (so mutating history via LeaveConfirmation for example)
    // we shouldn`t reload route (including loader-fn)
    shouldReload: (p) => p.cause === 'enter'
  })
