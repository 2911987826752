import { z } from 'zod'

export const documentNewSchema = z.object({
  subjectType: z.string().default(undefined).catch(undefined),
  subjectName: z.string().default(undefined).catch(undefined),
  companyId: z.number().default(undefined).catch(undefined),
  contactId: z.number().default(undefined).catch(undefined),
  licenseId: z.number().default(undefined).catch(undefined),
  bankAccountId: z.number().default(undefined).catch(undefined),
  returnUrl: z.string().default('').catch(undefined)
})
