import { useCallback } from 'react'

import {
  useDocumentFileCreateIsolated,
  useDocumentFileDeleteIsolated,
  useDocumentPeriodFileCreate,
  useDocumentPeriodFileDelete
} from '../../hooks/documentFile'
import { useCustomerAcknowledgement } from '../../hooks/useCustomerAcknowledgement'

export const useStep2Documents = ({ invalidateWebpageData }: { invalidateWebpageData: () => void }) => {
  const handleUpdateDocFileSuccess = useCallback(() => {
    invalidateWebpageData()
  }, [invalidateWebpageData])

  const handleUpdateDocFileSuccessError = useCallback(() => {}, [])

  const { createDocumentFileIsolated, isLoading: isDocumentsLoadingIsolated } = useDocumentFileCreateIsolated({
    onSuccess: handleUpdateDocFileSuccess,
    onError: handleUpdateDocFileSuccessError
  })

  const { createDocumentPeriodFile, isLoading: isDocumentsLoading } = useDocumentPeriodFileCreate({
    onSuccess: handleUpdateDocFileSuccess,
    onError: handleUpdateDocFileSuccessError
  })

  const { deleteDocumentFileIsolated, isLoading: isDocumentDeletingIsolated } = useDocumentFileDeleteIsolated({
    onSuccess: handleUpdateDocFileSuccess,
    onError: handleUpdateDocFileSuccessError
  })

  const { deleteDocumentPeriodFile, isLoading: isDocumentDeleting } = useDocumentPeriodFileDelete({
    onSuccess: handleUpdateDocFileSuccess,
    onError: handleUpdateDocFileSuccessError
  })

  const { sendAgreement } = useCustomerAcknowledgement({ onSuccess: handleUpdateDocFileSuccess, onError: () => {} })

  const handleRemoveFileIsolated = useCallback(
    (id: number) => deleteDocumentFileIsolated(id),
    [deleteDocumentFileIsolated]
  )

  const handleRemoveFile = useCallback((id: number) => deleteDocumentPeriodFile(id), [deleteDocumentPeriodFile])

  const handleAddFiles = useCallback(
    (files: File[], periodId: number) => files.forEach((file: File) => createDocumentPeriodFile({ file, periodId })),
    [createDocumentPeriodFile]
  )

  const handleAddFilesIsolated = useCallback(
    (files: File[], documentId: number) =>
      files.forEach((file: File) => createDocumentFileIsolated({ file, applicationClientDocumentId: documentId })),
    [createDocumentFileIsolated]
  )

  const handleRequestAcknowledge = useCallback(
    (id: number) => {
      sendAgreement(id)
    },
    [sendAgreement]
  )

  return {
    ui: { isDocumentsLoading, isDocumentDeleting, isDocumentsLoadingIsolated, isDocumentDeletingIsolated },
    handlers: {
      handleAddFiles,
      handleRemoveFile,
      handleRequestAcknowledge,
      handleAddFilesIsolated,
      handleRemoveFileIsolated
    }
  }
}
