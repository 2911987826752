const statusMap: { [key: string]: string } = {
  new: 'New',
  approved: 'Approved',
  cancelled: 'Cancelled',
  submitted: 'Submitted',
  acknowledged_with_warnings: 'Acknowledged With Warnings',
  acknowledged: 'Acknowledged'
}

export const getBsaSarStatusLabel = (status: string) => statusMap[status]
