import React, { RefObject, memo, useCallback, useEffect } from 'react'

import { Form, useFormData } from 'brief-form'
import { BsaCtrInstitutionContactInfo } from 'commons/types/DTO/bank/bsa'
import {
  filingInstitutionIDTypes,
  primaryFederalRegulators,
  typesOfFinancialInstitution,
  usAndTerritories,
  usStates
} from 'commons/types/dictionaries'
import SC from 'styled-components'
import { Select, Validators } from 'ui/components'
import { EINInput, TextInput } from 'ui/components/InputV2'
import { PageSection, PageSectionFace } from 'ui/components/Page'
import { Panel } from 'ui/components/Panel'
import { Col, FormRow } from 'ui/themes/globalStyles'

import { VisibilityBox } from './styles'

const EMPTY_OPTION = { label: 'Not selected', value: '' }

interface Props {
  value: BsaCtrInstitutionContactInfo
  errors: { [key: string]: string }
  onChange: (value: BsaCtrInstitutionContactInfo, errors: { [key: string]: string }) => void
  validateFunctionRefs: RefObject<any>
  markFormAsDirty: () => void
}

//region Styles

const Inner = SC.div`
  padding: 30px 35px;
`

//endregion

export const FilingInstitutionContactInformationForm = memo((props: Props) => {
  const { value, onChange, errors, validateFunctionRefs, markFormAsDirty } = props
  const { isDirty, config, validate, Field } = useFormData<any>({
    initialValue: value,
    initialErrors: errors,
    alwaysSyncWithInitialValueAndErrors: true
  })

  useEffect(() => {
    if (isDirty) {
      markFormAsDirty()
    }
  }, [isDirty])

  useEffect(() => {
    validateFunctionRefs.current.contact_information = {
      validate,
      registeredFields: config.registeredFields.current
    }
  }, [validate])

  const originalChangeHandler = config.onChange
  config.onChange = useCallback(
    (v, e) => {
      const newValue = { ...v, state: !v.country || v.country === 'US' ? v.state : v.country }
      originalChangeHandler(newValue, e)
      onChange(v, e)
    },
    [originalChangeHandler, onChange]
  )

  return (
    <PageSection face={PageSectionFace.THIRD} title="Step 1. Filing Institution Contact Information" anchor="step1">
      <Panel rounded shadowed collapsible={false} title="Edit Institution Contact Information">
        <Form config={config}>
          <Inner>
            <FormRow>
              <Col>
                <Field
                  required
                  name="type"
                  label="52. Type of financial institution"
                  input={Select}
                  validator={Validators.required.field}
                  inputProps={{
                    data: typesOfFinancialInstitution,
                    'data-qa': '52. Type of financial institution',
                    'options-data-qa': '52. Type of financial institution option'
                  }}
                />
              </Col>
              <Col>
                <Field
                  required
                  name="federal_regulator"
                  label="43. Primary federal regulator"
                  input={Select}
                  validator={Validators.required.field}
                  inputProps={{
                    data: primaryFederalRegulators,
                    'data-qa': '43. Primary federal regulator',
                    'options-data-qa': '43. Primary federal regulator option'
                  }}
                />
              </Col>
            </FormRow>
            <VisibilityBox $visible={value.type === 'Other'}>
              <FormRow>
                <Col>
                  <Field
                    required={value.type === 'Other'}
                    validator={(v, f) =>
                      !!v || f.type !== 'Other'
                        ? undefined
                        : '"Other (specify)" required when choose "Other" as "52. Type of financial institution"'
                    }
                    name="type_other"
                    label="Other (specify)"
                    input={TextInput}
                    inputProps={{
                      maxLength: 50
                    }}
                  />
                </Col>
              </FormRow>
            </VisibilityBox>
            <FormRow>
              <Col>
                <Field
                  required
                  name="legal_name"
                  label="44. Legal name of filing institution"
                  input={TextInput}
                  validator={Validators.required.field}
                  inputProps={{
                    maxLength: 150,
                    'data-qa': '44. Legal name of filing institution'
                  }}
                />
              </Col>
              <Col>
                <Field
                  name="dba"
                  label="45. Alternate name, e.g. trade name, DBA"
                  input={TextInput}
                  inputProps={{
                    maxLength: 150
                  }}
                />
              </Col>
            </FormRow>
            <FormRow>
              <Col>
                <Field
                  required
                  name="ein"
                  label="46. EIN"
                  input={EINInput}
                  validator={Validators.required.field}
                  inputProps={{
                    'data-qa': '46. EIN'
                  }}
                />
              </Col>
            </FormRow>
            <FormRow>
              <Col>
                <Field
                  required
                  name="address"
                  label="47. Address"
                  input={TextInput}
                  validator={Validators.required.field}
                  inputProps={{
                    maxLength: 100,
                    'data-qa': '47. Address'
                  }}
                />
              </Col>
              <Col>
                <Field
                  required
                  name="city"
                  label="48. City"
                  input={TextInput}
                  validator={Validators.required.field}
                  inputProps={{
                    maxLength: 50,
                    'data-qa': '48. City'
                  }}
                />
              </Col>
            </FormRow>
            <FormRow>
              <Col>
                <Field
                  required
                  name="zip_code"
                  label="49. ZIP Code"
                  input={TextInput}
                  validator={Validators.required.field}
                  inputProps={{
                    maxLength: 9,
                    'data-qa': '49. ZIP Code'
                  }}
                />
              </Col>
            </FormRow>
            <FormRow>
              <Col>
                <Field
                  required
                  name="country"
                  label="50. Country"
                  input={Select}
                  validator={Validators.required.field}
                  inputProps={{
                    data: usAndTerritories,
                    'data-qa': '50. Country',
                    'options-data-qa': '50. Country option'
                  }}
                />
              </Col>
              <VisibilityBox $visible={value.country === 'US'}>
                <Col>
                  <Field
                    required={value.country === 'US'}
                    name="state"
                    validator={(v, f) => {
                      // state field has predefined values that a copy of country field and not valid for state.
                      // so we're checking "is state value are listed in this select"
                      if (f.country == 'US' && (!v || !usStates.find((el) => el.value == v))) {
                        return '"51. State" required when choose "US" as "50. Country"'
                      }
                      return undefined
                    }}
                    label="51. State"
                    input={Select}
                    inputProps={{
                      data: usStates,
                      'data-qa': '51. State',
                      'options-data-qa': '51. State options'
                    }}
                  />
                </Col>
              </VisibilityBox>
            </FormRow>
            <FormRow>
              <Col>
                <Field
                  name="id_type"
                  label="54. Filing institution ID type"
                  input={Select}
                  inputProps={{
                    data: filingInstitutionIDTypes,
                    emptyOption: EMPTY_OPTION
                  }}
                />
              </Col>
              <Col>
                <Field
                  name="id_number"
                  label="ID Number"
                  input={TextInput}
                  inputProps={{
                    maxLength: 24
                  }}
                />
              </Col>
            </FormRow>
            <FormRow>
              <Col>
                <Field
                  required
                  name="contact_office"
                  label="55. Contact office"
                  input={TextInput}
                  validator={Validators.required.field}
                  inputProps={{
                    maxLength: 150,
                    'data-qa': '55. Contact office'
                  }}
                />
              </Col>
              <Col>
                <Field
                  required
                  name="phone"
                  label="56. Phone number"
                  input={TextInput}
                  validator={Validators.required.field}
                  inputProps={{
                    maxLength: 10,
                    'data-qa': '56. Phone number'
                  }}
                />
              </Col>
            </FormRow>
            <FormRow>
              <Col>
                <Field
                  name="phone_ext"
                  label="Ext."
                  input={TextInput}
                  inputProps={{
                    maxLength: 6
                  }}
                />
              </Col>
            </FormRow>
          </Inner>
        </Form>
      </Panel>
    </PageSection>
  )
})
