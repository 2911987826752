import { LicenseWebPageInvoiceSubmital } from 'commons/types/DTO/bank'

export interface InvoicesProps {
  data: LicenseWebPageInvoiceSubmital
  licenseId: number
  licenseName: string
  returnUrl: string
  config: {
    isFetching: boolean
    filter: {
      page: number
      size: number
    }
    onFilterChange: (page: number, size: number) => void
  }
  onInvoiceDeleted: () => void
}
