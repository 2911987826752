import { useMutation, useQueryClient } from 'react-query'

import { useNavigate } from '@tanstack/react-router'
import { localStorageService } from 'commons/service/localStorageService'
import { PortalName } from 'commons/types/enums'
import { useStateManager } from 'state-manager'
import { submitPassword } from '~auth/service/user'
import { AuthMFAState, AuthPasswordSubmissionResponse } from '~auth/types/DTO'

import { redirectToProperPage } from '../helpers'
import { useSendChallenge } from '../mfa'

export const useSubmitPassword = (p: PortalName) => {
  const client = useQueryClient()
  const sm = useStateManager()
  const challengeMutation = useSendChallenge(p)
  const navigate = useNavigate()

  return useMutation((data: { login: string; password: string }) => submitPassword(p, data.login, data.password), {
    onError: (error: Error) => {
      throw error
    },
    onSuccess: async (response: AuthPasswordSubmissionResponse) => {
      // MFA auth is not required, just save token and load user data.
      if (!response.mfa_required) {
        localStorageService.setSessionExpiresAt(response.expires_at)
        redirectToProperPage(sm, navigate, new URLSearchParams(window.location.search))
        client.removeQueries(['mfa'])
      }

      if (response.mfa_required) {
        // Try to detect default or last used device.
        const device =
          response.mfa_devices.find((d) => d.default) ||
          response.mfa_devices.sort(
            (a, b) =>
              new Date(b.last_used_at || '1970-1-1').getTime() - new Date(a.last_used_at || '1970-1-1').getTime()
          )[0]

        client.setQueryData<AuthMFAState>(['mfa'], {
          devices: response.mfa_devices,
          token: response.mfa_token,
          chosen: device
            ? {
                id: device.id,
                type: device.type,
                name: device.email || device.phone_number || '',
                totpUrl: device.totp_url || '',
                totpSecret: device.totp_secret || ''
              }
            : undefined
        })

        if (device) {
          await challengeMutation.mutateAsync({ deviceId: device.id, token: response.mfa_token }).catch((error) => {
            throw error
          })
        }
      }
    }
  })
}
