import React, { useCallback } from 'react'

import { useForm } from '@tanstack/react-form'
import { getRouteApi, useNavigate } from '@tanstack/react-router'
import { bankAccountNumberClear } from 'commons/utils'
import { handlerServerError } from 'errors'
import { Toast } from 'ui/components'
import { useConfirmation } from 'ui/hooks'

import { BANK_ACCOUNTS_PATH } from '../../constants'
import { hooks } from '../../hooks'
import { BankAccountForm } from '../../types'

const routeApi = getRouteApi('/bank-accounts/$bankAccountId/edit')

const emptyForm: BankAccountForm = {
  id: 0,
  internalTransfersType: 0,
  licenseIds: [],
  notes: '',
  number: '',
  openingDate: ''
}

export const useEdit = () => {
  const navigate = useNavigate({ from: `${BANK_ACCOUNTS_PATH}/$bankAccountId/edit` })
  const id = routeApi.useParams({ select: (s) => Number(s.bankAccountId) })
  const { data, isFetching } = hooks.useEditPage({ id })
  const returnUrl = routeApi.useSearch({ select: (s) => s.returnUrl })
  const cancelManage = useCallback(() => history.back(), [])

  const {
    routine: updateBankAccount,
    isLoading,
    isSuccess
  } = hooks.useUpdate({
    onError: (error) => handlerServerError(error),
    onSuccess: async () => {
      Toast.successSmall('Bank account has been saved.')
      if (returnUrl) {
        navigate({ to: returnUrl, hash: 'bank-accounts' })
      }
    }
  })

  const formApi = useForm<BankAccountForm>({
    defaultValues: data?.form || emptyForm,
    onSubmit: ({ value }) =>
      updateBankAccount({
        ...value,
        number: bankAccountNumberClear(value.number)
      })
  })

  const { handleSubmit, useStore } = formApi
  const value = useStore((s) => s.values)

  const { open: openSaveConfirmation, Confirmation: SaveConfirmation } = useConfirmation({
    message: (
      <>
        This bank account is not assigned to any
        <br />
        license. Do you want to proceed?
      </>
    ),
    onConfirm: handleSubmit,
    confirmationButtonText: 'Submit',
    isConfirmationNegative: true
  })

  const submitForm = value.licenseIds?.length ? handleSubmit : openSaveConfirmation

  return {
    id,
    cancelManage,
    data,
    formApi,
    isFetching,
    isLoading,
    isSuccess,
    SaveConfirmation,
    submitForm
  }
}
