import styled from 'styled-components'
import { CSS } from 'ui/helpers'

export const Required = styled.span<{ $isEmpty: boolean }>`
  height: calc(100% + 2px);
  width: 3px;
  display: block;
  position: absolute;
  top: -1px;
  left: -1px;
  background: ${(p) => p.theme.uiKit.colors.theme.negative.main};
  border: solid 1px ${(p) => p.theme.uiKit.colors.theme.negative.main}; // Chrome draw artefacts without border
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-right: transparent;

  transition: ${CSS.populateTransition(['opacity'])};
  opacity: ${(p) => (p.$isEmpty ? '1' : '0')};
`
