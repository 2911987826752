import { ContactDetailsFormData } from '../../../types/forms'
import { OnboardingFormState } from '../../../types/state'
import { getSelectedCompany } from './getSelectedCompany'
import { setNewCompanies } from './setNewCompanies'
import { setNewContacts } from './setNewContacts'

export const contactDetailsToState = ({
  companyIndex,
  contactIndex,
  currentState,
  formData
}: {
  companyIndex: number
  contactIndex: number
  currentState: OnboardingFormState
  formData: ContactDetailsFormData
}) => {
  const oldCompany = getSelectedCompany({ companyIndex, currentState })
  const newContact = { ...oldCompany.contacts[contactIndex], details: { ...formData } }

  // show changes contacts
  const newContacts = setNewContacts({
    contactIndex,
    newContact,
    oldCompany
  })

  // change ref in list
  const newCompaniesFormValues = setNewCompanies({
    companyIndex,
    oldCompanies: currentState.formValue.companiesFormValues,
    newCompany: { ...oldCompany, contacts: newContacts }
  })

  return {
    formValue: {
      ...currentState.formValue,
      companiesFormValues: newCompaniesFormValues
    }
  }
}
