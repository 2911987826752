import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 19px 35px;
`

export const Title = styled.h4`
  font-size: 22px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.49px;
  color: #000000;
  margin: 0;
`

export const TitleWithMargin = styled(Title)`
  padding: 19px 35px;
  margin: -19px -35px 0;
`
