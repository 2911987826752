import { Applications } from './Applications'
import { PageHeader } from './PageHeader'
import { Pagination } from './Pagination'
import { TableFilters } from './TableFilters'

export type WebPage = {
  header: PageHeader
  filters: TableFilters
  applications: Applications[]
  pagination: Pagination
}
