import React from 'react'

import { Col, FormRow } from 'ui/themes/globalStyles'

import { BirthDate, FirstName, Gender, MiddleName, Suffix } from './fields'

type EntityFieldsProps = {
  index: number
}

export const EntityFields = ({ index }: EntityFieldsProps) => {
  return (
    <>
      <FormRow>
        <Col>
          <FirstName index={index} />
        </Col>
        <Col>
          <MiddleName index={index} />
        </Col>
      </FormRow>
      <FormRow>
        <Col>
          <Suffix index={index} />
        </Col>
        <Col>
          <Gender index={index} />
        </Col>
      </FormRow>
      <FormRow>
        <Col>
          <BirthDate index={index} />
        </Col>
      </FormRow>
    </>
  )
}
