import { useState } from 'react'

import { DialogFace, Toast } from 'ui/components'
import { useConfirmation } from 'ui/hooks'

import { documentFileDelete, documentFileUpload } from '../services'

export const useFilesActions = (documentId: number, invalidate: () => Promise<void>) => {
  const [isUploading, setIsUploading] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [fileToBeDeleted, setFileToBeDeleted] = useState<number>(null)

  const uploadFiles = async (files: File[]) => {
    setIsUploading(true)
    for (const file of files) {
      await documentFileUpload(documentId, file).catch((err: Error) => {
        Toast.errorSmall(err.message)
      })
    }
    await invalidate()
    setIsUploading(false)
  }

  const deleteFile = async () => {
    setIsDeleting(true)
    await documentFileDelete(fileToBeDeleted)
    await invalidate()
    setIsDeleting(false)
  }

  const { open: openDeleteConfirmation, Confirmation: DeleteConfirmation } = useConfirmation({
    face: DialogFace.DEFAULT,
    message: 'Are you sure you want to delete this file?',
    onConfirm: deleteFile,
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  const startDeleting = (id: number) => {
    setFileToBeDeleted(id)
    openDeleteConfirmation()
  }

  return {
    isUploading,
    isDeleting,

    uploadFiles,
    startDeleting,

    DeleteConfirmation
  }
}
