import styled from 'styled-components'
import { CSS } from 'ui/helpers'

import { Icon } from '../../Icon'
import { Pagination } from '../../Pagination'

export const Box = styled.div`
  position: relative;
  overflow-x: auto;
`

export const SorterIcon = styled(Icon)<{
  direction: 'ASC' | 'DESC'
  $sorted: boolean
}>`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  height: 12px;
  width: 10px;
  top: ${(p) => (p.direction === 'ASC' ? '-1px' : '0')};
  left: 7px;
  transform: ${(p) => (p.direction === 'ASC' ? 'none' : 'rotate(180deg)')};
  svg {
    height: 12px;
    width: 10px;
  }
  svg,
  path {
    fill: ${(p) => (p.$sorted ? '#B8B8B8' : '#45D5B4')} !important;
  }
`

export const T = styled.table<{ $loading?: boolean; $isEmpty?: boolean; $pagination?: boolean }>`
  opacity: ${(p) => (p.$loading ? '.3' : '1')};
  width: 100%;
  border-collapse: collapse;
  border-bottom: 1px solid ${(p) => p.theme.uiKit.colors.global.grey.grey500};
`

export const H = styled.thead`
  background: ${(p) => p.theme.uiKit.colors.global.grey.grey300};
`

export const TH = styled.th<{ align: string; sortable: boolean; $width?: string; $color: string }>`
  padding: 8px 16px;
  color: ${(p) => (p.$color ? p.$color : p.theme.uiKit.colors.global.general.secondary)};
  * {
    ${(p) => CSS.populateFont(p.theme.uiKit.typography.bodySm.activeMenu)}
  }
  ${(p) => CSS.populateFont(p.theme.uiKit.typography.bodySm.activeMenu)}
  text-align: ${(p) => p.align};
  white-space: nowrap;
  vertical-align: middle;
  cursor: ${(p) => (p.sortable ? 'pointer' : 'default')};
  ${(p) => (p.$width ? `width: ${p.$width}` : '')};
  &:hover {
    ${SorterIcon} {
      svg,
      path {
        fill: #45d5b4 !important;
      }
    }
  }
`

export const EmptyTd = styled.td`
  padding: 50px;
  text-align: center;
  font-size: 14px;
  line-height: 14px;
  color: ${(p) => p.theme.uiKit.colors.global.grey.grey500};
`

export const TD = styled.td<{ $align: string }>`
  padding: 8px 16px;
  color: ${(p) => p.theme.uiKit.colors.global.general.secondary};
  text-align: ${(p) => p.$align};
`

export const TR = styled.tr<{ $errorHighlight?: boolean }>`
  background: ${(p) => (p.$errorHighlight ? '#fff5f5' : p.theme.uiKit.colors.global.general.main)};

  &:hover {
    background: ${(p) => (p.$errorHighlight ? '#ebe2e2' : '#ebebeb')} !important;
  }

  &:nth-child(even) {
    background: ${(p) => (p.$errorHighlight ? '#f5ebeb' : p.theme.uiKit.colors.global.grey.grey100)};
  }
`

export const WrapperPagination = styled(Pagination)`
  margin: 16px 0;
`

export const Bottom = styled.div<{ $pagination: boolean }>`
  height: ${(p) => (p.$pagination ? '80px' : '60px')};
`
