import * as Sentry from '@sentry/react'
import { LoggerInterface } from 'commons/utils/logger'

/**
 * Utility providing methods for logging unhandled errors and manual posting messages to sentry.io.
 */
export class SentryHandler implements LoggerInterface {
  private static _instance: SentryHandler

  /**
   * Indicates whether class is ready for use.
   * @private
   * @type {boolean}
   */
  private initialized = false

  /**
   * Error to be thrown if any class method is called without initialization.
   * @private
   * @type {Error}
   */
  private readonly notInitialized = new Error('Call ErrorHandler.initialize before use other class methods')

  private constructor(dsn: string, release: string, tracePropagationTargets: string[]) {
    this.initialize(dsn, release, tracePropagationTargets)
  }

  public static getInstance(dsn: string, release: string, tracingOrigins: string[]): LoggerInterface {
    return this._instance || (this._instance = new this(dsn, release, tracingOrigins))
  }

  /**
   * Initialize handler. We need initialize DSN parameter to determine Sentry project
   * we will write to. After this call Sentry will automatically capture and log all
   * unhandled errors. This call should be placed at the very top of the application.
   * @param dsn {string} project DSN string.
   * @param tracingOrigins The JavaScript SDK will attach the sentry-trace header to all
   *                       outgoing XHR/fetch requests whose destination contains a string
   *                       in the list or matches a regex in the list.
   * @throws {Error} - if DSN parameter is empty or null.
   */
  private initialize(dsn: string, release: string, tracePropagationTargets: string[]): void {
    if (!dsn) {
      throw new Error('Sentry DSN parameter is invalid.')
    }
    if (!release) {
      throw new Error('Sentry release parameter is invalid.')
    }
    const tracingOriginsWithRegex: (string | RegExp)[] = tracePropagationTargets
    tracingOriginsWithRegex.push(/^\//)

    Sentry.init({
      dsn,
      release,
      integrations: [],
      tracesSampleRate: 1.0,
      normalizeDepth: 5
    })
    this.initialized = true
  }

  /**
   * Log message to Sentry.
   * @param message {string} message to be logged.
   * @throws {Error} - if class instance wasn't initialized.
   */
  log(message: string): void {
    if (!this.initialized) {
      throw this.notInitialized
    }
    Sentry.captureMessage(message, 'log')
  }

  /**
   * Log info to Sentry.
   * @param message {string} message to be logged.
   * @throws {Error} - if class instance wasn't initialized.
   */
  info(message: string): void {
    if (!this.initialized) {
      throw this.notInitialized
    }
    Sentry.captureMessage(message, 'info')
  }

  /**
   * Log warning to Sentry.
   * @param message {string} message to be logged.
   * @throws {Error} - if class instance wasn't initialized.
   */
  warning(message: string): void {
    if (!this.initialized) {
      throw this.notInitialized
    }
    Sentry.captureMessage(message, 'warning')
  }

  /**
   * Log error to Sentry.
   * @param message {string} message to be logged.
   * @throws {Error} - if class instance wasn't initialized.
   */
  error(message: string): void {
    if (!this.initialized) {
      throw this.notInitialized
    }
    Sentry.captureException(new Error(message))
  }
}

/**
 * Sentry enhancer, which can be used to capture redux errors.
 * @type {any}
 */
export const sentryReduxEnhancer = Sentry.createReduxEnhancer()
