import React from 'react'

import { CheckBox, FF } from 'ui/components'

import { useSARFormContext } from '../../../../../../../../context'

type AppraiserProps = {
  index: number
}

export const Appraiser = ({ index }: AppraiserProps) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={
        `subjects_info[${index}].relationships_info[0].appraiser` as 'subjects_info[0].relationships_info[0].appraiser'
      }
      children={(field) => (
        <FF field={field} input={(p) => <CheckBox {...p} checkBoxLabel="d. Appraiser" />} errorPosition="right" />
      )}
    />
  )
}
