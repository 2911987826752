import React from 'react'

import { Col, Col2x, FormRow } from 'ui/themes/globalStyles'

import { IdCountry, IdForm, IdFormOther, IdNumber, IdState } from './fields'

type FormOfIdentificationProps = {
  index: number
}

export const FormOfIdentification = ({ index }: FormOfIdentificationProps) => {
  return (
    <>
      <FormRow>
        <Col2x>
          <h5>18. Form of identification for subject</h5>
        </Col2x>
      </FormRow>
      <FormRow>
        <Col>
          <IdForm index={index} />
        </Col>
        <Col>
          <IdFormOther index={index} />
        </Col>
      </FormRow>
      <FormRow>
        <Col>
          <IdNumber index={index} />
        </Col>
        <Col>
          <IdCountry index={index} />
        </Col>
      </FormRow>
      <FormRow>
        <Col>
          <IdState index={index} />
        </Col>
      </FormRow>
    </>
  )
}
