import styled from 'styled-components'
import { IconButton } from 'ui/components/Buttons'

const Box = styled.footer`
  z-index: 1000;
  background: #fff;
  height: 66px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 50px 0 50px;
  border-top: 1px solid ${(p) => p.theme.uiKit.colors.global.grey.grey300};
`

const DeleteButton = styled(IconButton)`
  margin-right: auto;
`

export const s = { Box, DeleteButton }
