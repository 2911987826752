import React from 'react'

import { ButtonGroup, Search } from 'ui/components'
import { HeaderButton } from 'ui/components/Buttons'

import { ActionsProps } from './Actions.types'

export const Actions = (props: ActionsProps) => {
  const { searchTerm, onSearch, onAddUser } = props

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <ButtonGroup margin="small">
        <Search onSearch={onSearch} value={searchTerm} placeholder="Search" />
        {onAddUser && (
          <HeaderButton onClick={onAddUser} size="middle" data-qa="Add New">
            Add New
          </HeaderButton>
        )}
      </ButtonGroup>
    </div>
  )
}
