import React from 'react'

import { Label } from 'ui/temp/Label'
import { Col, Col2x, FormRow } from 'ui/themes/globalStyles'

import {
  CompanyId,
  FilingName,
  FilingTypeContinuing,
  FilingTypeCorrectAmend,
  FilingTypeInitial,
  FilingTypeJoint,
  Notes,
  NotesToFincen,
  PriorReportBSAId,
  Status,
  TransEndDate,
  TransStartDate
} from './fields'

import { s } from './styles'

export const GeneralInformationForm = () => (
  <s.PanelWithBottomMargin rounded shadowed collapsible={false} title="Edit General information" anchor="general">
    <s.FormInner>
      <FormRow>
        <Col2x>
          <FilingName />
        </Col2x>
      </FormRow>
      <FormRow>
        <Col2x>
          <Label>1. Type of filing</Label>
          <s.FilingTypeWrapper>
            <FilingTypeInitial />
            <FilingTypeCorrectAmend />
            <FilingTypeContinuing />
            <FilingTypeJoint />
          </s.FilingTypeWrapper>
        </Col2x>
      </FormRow>
      <FormRow>
        <Col>
          <PriorReportBSAId />
        </Col>
      </FormRow>
      <FormRow>
        <Col2x>
          <NotesToFincen />
        </Col2x>
      </FormRow>
      <FormRow>
        <Col>
          <CompanyId />
        </Col>
        <Col>
          <Status />
        </Col>
      </FormRow>
      <FormRow>
        <Col>
          <TransStartDate />
        </Col>
        <Col>
          <TransEndDate />
        </Col>
      </FormRow>
      <FormRow>
        <Col2x>
          <Notes />
        </Col2x>
      </FormRow>
    </s.FormInner>
  </s.PanelWithBottomMargin>
)
