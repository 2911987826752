import React from 'react'

import { FF, TextInput } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

export const IDNumber = ({ index }: { index: number }) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={`institution_activities_info[${index}].id_number` as 'institution_activities_info[0].id_number'}
      children={(field) => <FF label="ID Number" field={field} input={(p) => <TextInput {...p} />} />}
    />
  )
}
