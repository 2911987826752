import { BeforeFormChangeHandler, FormChangedHandler, UseFormDataReturnType } from 'brief-form'

export type TableState<F = any> = {
  filterForm: UseFormDataReturnType<F, any>
  expanded: boolean
  onPageChange: (page: number, size: number) => void
  onFilterToggle: () => void
  onFilterOpen: () => void
  onFilterClear: () => void
  onOrderChange: (value: { field: string; direction: 'ASC' | 'DESC' }) => any
  page: number
  size: number
  order: { field: string; direction: 'ASC' | 'DESC' }[]
}

export type TableStateConfig<F = any> = {
  filter: F
  order?: { field: string; direction: 'ASC' | 'DESC' }[]
  expanded?: boolean
  page?: number
  size?: number
  onBeforeFilterChange?: BeforeFormChangeHandler<F>
  onFilterChanged?: FormChangedHandler<F>
}
