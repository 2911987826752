import React from 'react'

import { FF, SecuredInput } from 'ui/components'

import { useSARFormContext } from '../../../../../../../../context'

type TINProps = {
  index: number
}

export const TIN = ({ index }: TINProps) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={`subjects_info[${index}].relationships_info[0].tin` as 'subjects_info[0].relationships_info[0].tin'}
      children={(field) => (
        <FF field={field} label="a. Institution TIN" input={(p) => <SecuredInput {...p} maxLength={25} />} />
      )}
    />
  )
}
