import styled from 'styled-components'

export const Content = styled.div`
  padding: 30px 40px 35px 40px;
`

export const Files = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
`
