import styled from 'styled-components'
import { HEADER_HEIGHT } from 'ui/themes/variables'

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-right: 50px;

  flex: none;
  height: ${HEADER_HEIGHT};
  background-color: ${(p) => p.theme.uiKit.colors.global.grey.grey100};
  box-shadow: 5px 0 7px rgba(0, 0, 0, 0.05);

  @media print {
    display: none;
  }
`
