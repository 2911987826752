import React, { useMemo } from 'react'

import { Uploads } from 'commons/types/DTO/bank/alerts/uploads'
import { TableDataQa } from 'commons/types/enums'
import { Dialog, DialogFace } from 'ui/components/Dialog'
import { Layer } from 'ui/components/Layer'
import { Link } from 'ui/components/Link'
import { PageSection, PageSectionFace } from 'ui/components/Page'
import { DataSourceItem, Table } from 'ui/components/Table'

import { UploadsPendingApprovalProps } from './UploadsPendingApprovalProps'
import { columns } from './columns'
import { Filter } from './components/Filter'
import { useComponentsEssence } from './hooks/useComponentsEssence'

import { DialogContent } from './styles'

type UploadsPendingApprovalTable = {
  id: number
  relationship: string
}

export const UploadsPendingApproval = ({ webPage, onAlertFilterChange }: UploadsPendingApprovalProps) => {
  const {
    showRelatedCompanyList,
    relatedCompanyData,
    closeRelatedCompanyList,
    openRelatedCompanyList,
    changePagination,
    loading,
    tableState,
    onSort,
    onClear
  } = useComponentsEssence(onAlertFilterChange, webPage)

  const memoizedColumns = useMemo(() => columns(openRelatedCompanyList), [openRelatedCompanyList])

  const mappedUploadsPendingApproval: DataSourceItem<Uploads>[] = useMemo(
    () =>
      webPage.uploads.map((el) => ({
        item: el
      })),
    [webPage.uploads]
  )

  return (
    <div data-qa="uploads-pending-approval">
      <PageSection face={PageSectionFace.PRIMARY} title="Uploads Pending Approval" anchor="uploads-pending-approval">
        {showRelatedCompanyList && (
          <Dialog
            id="related-company-list"
            title="Related Companies"
            subTitle={relatedCompanyData.subTitle}
            face={DialogFace.PRIMARY}
            onClose={closeRelatedCompanyList}
          >
            <DialogContent>
              <Table<UploadsPendingApprovalTable>
                dataQa={TableDataQa.BP_ALERTS_UPLOADS_PENDING_APPROVAL}
                columns={[
                  {
                    align: 'left',
                    title: 'Relationship',
                    render: (item) => (
                      <Link to="/relationships/$companyId" params={{ companyId: item.id }}>
                        {item.relationship}
                      </Link>
                    )
                  }
                ]}
                dataSource={relatedCompanyData.companyList.map((item) => ({
                  item: {
                    relationship: item.name,
                    id: item.id
                  }
                }))}
              />
            </DialogContent>
          </Dialog>
        )}
        <Layer rounded shadowed stretch>
          <Filter
            isDirty={tableState.filterForm.isDirty}
            isExpanded={tableState.expanded}
            onToggle={tableState.onFilterToggle}
            form={tableState.filterForm}
            onClear={onClear}
            webPage={webPage}
          />
          <Table<Uploads>
            dataQa={TableDataQa.BP_ALERTS_UPLOADS}
            columns={memoizedColumns}
            dataSource={mappedUploadsPendingApproval}
            loading={loading}
            pagination={{
              page: tableState.page,
              pageSize: tableState.size,
              onChange: changePagination,
              total: webPage.uploadsTotalCount
            }}
            sorting={{
              order: tableState.order,
              onChange: (value) => onSort(value.direction, value.field)
            }}
          />
        </Layer>
      </PageSection>
    </div>
  )
}
