import React, { FC, memo } from 'react'

import { TableDataQa } from 'commons/types/enums'
import { Request } from 'commons/utils/request'
import { QueryTable } from 'ui/components/QueryTable'

import { DocumentReportTableProps } from './TableProps'
import { columns } from './columns'
import { FilterForm } from './components'
import { dataSourceSelector } from './dataSourceSelector'

export const Table: FC<DocumentReportTableProps> = memo((props) => {
  const {
    tableState: { filter, pagination, sorting },
    filterForm
  } = props

  return (
    <QueryTable
      name="Licenses List"
      dataQa={TableDataQa.BP_REPORTS_SITE_AUDIT_DOCUMENTS_REPORT}
      dataSources={[
        {
          key: 'list',
          url: Request.urls.bank.portalReportSite,
          options: (_: any, filter?: any) => ({
            relationship_id: filter.relationship_id,
            license_number: filter.license_number.toString(),
            frequency: filter.frequency,
            customer_status: filter.customer_status,
            offset: (pagination.page - 1) * pagination.size,
            limit: pagination.size
          })
        }
      ]}
      columns={columns}
      dataSourceSelector={dataSourceSelector}
      sorting={{
        order: sorting.order,
        onChange: sorting.change
      }}
      pagination={{
        page: pagination.page,
        size: pagination.size,
        onChange: pagination.change
      }}
      filter={{
        value: filterForm.config.value,
        // TODO: remove this fix when we are on the latest brief-form
        component: <FilterForm form={filterForm as any} expanded={filter.expanded} onToggle={filter.toggle} />
      }}
    />
  )
})
