import { ApiReadResponse } from 'commons/types'
import {
  OnboardingDocumentCreateModeWebPage,
  OnboardingDocumentEditModeWebPage,
  OnboardingDocumentSetupFormRequest,
  OnboardingDocumentsDetailsWebPage,
  OnboardingDocumentsDetailsWebPageOptions
} from 'commons/types/DTO/bank/applications/onboardingDocuments'
import { Request } from 'commons/utils/request'

export const createEnvelopeOnboardingDocuments = (
  form: OnboardingDocumentSetupFormRequest & { applicationId: number }
): Promise<void> => Request.post(Request.urls.bank.createEnvelopeOnboardingDocuments, form)

export const editEnvelopeOnboardingDocuments = (
  form: OnboardingDocumentSetupFormRequest & { applicationId: number; id: number }
): Promise<void> => Request.post(Request.urls.bank.editEnvelopeOnboardingDocuments, form)

export const getOnboardingDocumentCreateModeWebPage = (applicationId: number) =>
  Request.post<OnboardingDocumentCreateModeWebPage>(Request.urls.bank.onboardingDocumentsCreateModeWebPage, {
    applicationId
  })

export const getOnboardingDocumentEditModeWebPage = (appDocusignEnvelopeId: number) =>
  Request.post<OnboardingDocumentEditModeWebPage>(Request.urls.bank.onboardingDocumentsEditModeWebPage, {
    appDocusignEnvelopeId
  })

export const getOnboardingDocumentCloneModeWebPage = (appDocusignEnvelopeId: number) =>
  Request.post<OnboardingDocumentEditModeWebPage>(Request.urls.bank.onboardingDocumentsCloneModeWebPage, {
    appDocusignEnvelopeId
  })

export const getOnboardingDocumentDetailsWebPage = (options: OnboardingDocumentsDetailsWebPageOptions) =>
  Request.post<ApiReadResponse<OnboardingDocumentsDetailsWebPage>>(
    Request.urls.bank.onboardingDocumentsDetailsWebPage,
    options
  )

export const deleteOnboardingDocument = (appDocusignEnvelopeId: number) =>
  Request.delete(Request.urls.bank.deleteOnboardingDocument, { appDocusignEnvelopeId })

export const cancelOnboardingDocument = (opts) => Request.put(Request.urls.bank.cancelOnboardingDocument, opts)

export const sendOnboardingDocument = (appDocusignEnvelopeId: number) =>
  Request.put(Request.urls.bank.sendOnboardingDocuments, { appDocusignEnvelopeId })

export const resendOnboardingDocument = (appDocusignEnvelopeId: number) =>
  Request.put(Request.urls.bank.resendOnboardingDocuments, { appDocusignEnvelopeId })

export const getOnboardingDocumentSigners = (appDocusignEnvelopeId: number) =>
  Request.post(Request.urls.bank.getOnboardingDocumentSignersList, { appDocusignEnvelopeId })
