import { useCallback } from 'react'
import { useMutation } from 'react-query'

import { Application } from 'commons/types/DTO/commons'

import { setActiveApplication as setActiveApplicationService } from '../service'

type UseSetActiveApplicationReturnValue = {
  setActiveApplication: (id: number, active: boolean) => Promise<Application>
}

type ActiveApplication = {
  id: number
  active: boolean
}

export const useSetActiveApplication = (): UseSetActiveApplicationReturnValue => {
  const mutation = useMutation((application: ActiveApplication) =>
    setActiveApplicationService(application.id, application.active)
  )

  const setActiveApplication = useCallback(
    (id: number, active: boolean) => mutation.mutateAsync({ id: id, active: active }),
    [mutation]
  )

  return {
    setActiveApplication
  }
}
