import React, { FC, memo } from 'react'

import { DateTime, UsPhoneLabel } from 'ui/components'
import { Info, InfoSet } from 'ui/components/Info'
import { Col, Row } from 'ui/themes/globalStyles'

import { LicenseDetailsProps } from './LicenseDetailsProps'

export const LicenseDetails: FC<LicenseDetailsProps> = memo(({ value, state: { isLicenseMetrcApiKeyEnable } }) => (
  <InfoSet legend="License Details">
    <Row>
      <Col>
        <Info label="License Name">{value.name}</Info>
      </Col>
    </Row>
    <Row>
      <Col>
        <Info label="License Type">{value.type}</Info>
      </Col>
      <Col>
        <Info label="License Subtype">{value.subtype}</Info>
      </Col>
    </Row>
    <Row>
      <Col>
        <Info label="License Number">{value.licenseNumber}</Info>
      </Col>
      <Col>
        <Info label="POS Type">{value.POSType}</Info>
      </Col>
    </Row>
    <Row>
      <Col>
        <Info label="Phone">
          <UsPhoneLabel value={value.phone} />
        </Info>
      </Col>
      {value.POSType === 'Other' && (
        <Col>
          <Info label="POS Type Other">{value.POSTypeOther}</Info>
        </Col>
      )}
    </Row>
    <Row>
      <Col>
        <Info label="License Issuance Date">{value.issueDate}</Info>
      </Col>
      <Col>
        <Info label="License Expiration Date">
          <DateTime date={value.expirationDate} />
        </Info>
      </Col>
    </Row>
    {isLicenseMetrcApiKeyEnable && (
      <Row>
        <Col>
          <Info label="METRC API key">{value.licenseMetrcAPIKey}</Info>
        </Col>
      </Row>
    )}
  </InfoSet>
))
