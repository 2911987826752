import React, { FC, memo } from 'react'

import { Form, useFormData } from 'brief-form'
import { ButtonGroup } from 'ui/components/ButtonGroup'
import { Button } from 'ui/components/Buttons'
import { Dialog, DialogFace } from 'ui/components/Dialog'
import { TextareaInput } from 'ui/components/InputV2'
import { FormContentWrapper } from 'ui/temp/Dialogs/FormContentWrapper'

import { AcceptDialogProps } from './AcceptDialogProps'

export const AcceptDialog: FC<AcceptDialogProps> = memo((props) => {
  const { isAccepting, notes, onClose, onSubmit } = props
  const { config, Field } = useFormData<{ notes: string }>({ initialValue: { notes } })

  const handleSubmit = () => {
    onSubmit(config.value.notes)
  }

  return (
    <Dialog
      face={DialogFace.PRIMARY}
      id="accept-dialog"
      title="Approve Document"
      onClose={onClose}
      customFooterActions={
        <ButtonGroup margin="small">
          <Button face="positive" data-qa="Save" loading={isAccepting} onClick={handleSubmit}>
            Save
          </Button>
          <Button face="neutral" data-qa="Cancel" onClick={onClose}>
            Cancel
          </Button>
        </ButtonGroup>
      }
    >
      <Form config={config}>
        <FormContentWrapper>
          <Field
            name="notes"
            label="Notes (visible for client)"
            input={TextareaInput}
            inputProps={{
              'data-qa': 'document-notes'
            }}
          />
        </FormContentWrapper>
      </Form>
    </Dialog>
  )
})
