import { useCallback } from 'react'
import { useMutation } from 'react-query'

import { Application } from 'commons/types/DTO/commons'

import { Notes } from '../interfaces/Notes'
import { addNotes as addNotesService } from '../service'

type UseAddNotesReturnValue = {
  addNotes: (notes: Notes) => Promise<Application>
}

export const useAddNotes = (): UseAddNotesReturnValue => {
  const mutation = useMutation((notes: Notes) => addNotesService(notes))

  const addNotes = useCallback((notes: Notes) => mutation.mutateAsync(notes), [mutation])

  return {
    addNotes
  }
}
