import { Request } from 'commons/utils/request'

import { HistoryEntityType } from '../../../../enums'

export const dataSources = (id?: number, type?: HistoryEntityType) => [
  {
    key: 'main',
    url: Request.urls.bank.historyList,
    options: (previous: any, current: any) => ({
      _options: {
        // eslint-disable-next-line
        ...previous._options,
        filters: [
          id ? { field: 'entity_id', type: 'eq', value: id } : null,
          type || current?.entity_type
            ? {
                field: 'entity_type',
                type: 'eq',
                value: type || current?.entity_type
              }
            : null,
          current?.action_type
            ? {
                field: 'action',
                type: 'eq',
                value: current.action_type
              }
            : null,
          current?.date_from
            ? {
                field: 'created_at',
                type: 'gte',
                value: current.date_from
              }
            : null,
          current?.date_to
            ? {
                field: 'created_at',
                type: 'lte',
                value: current.date_to
              }
            : null,
          current?.user_id
            ? {
                field: 'user_id',
                type: 'eq',
                value: current.user_id
              }
            : null
        ].filter(Boolean)
      }
    })
  }
]
