export interface ContactMetadata {
  id?: number
  contact_id?: number
  company_id?: number
  company_name?: string
  title?: string
  is_documents?: boolean
  is_financials?: boolean
  is_owner?: boolean
  is_debtholder?: boolean
  is_account_signer?: boolean
  ownership?: number
  ownership_amount?: number
  debt_amount?: number
  debt_monthly_payment?: number
  debt_payment_frequency?: string
  debt_maturity_date?: string | null
  created_at?: string | null
  updated_at?: string | null
  deleted_at?: string | null
  sf_external_id?: string | null
}
