import { WriteHookOpts } from 'react-query-state-manager'

import { useForm } from '@tanstack/react-form'
import { Toast, handleFormSubmissionError } from 'ui/components'

import { hooks } from '../../../../hooks'
import { UpdateBankUserRequest } from '../../../../types'
import { NEW_USER } from '../../constants'
import { ManageUserDialogProps } from './ManageUserDialog.types'
import { getFormFromTableItem } from './getFormFromTableItem'

export const useManageUserDialog = (props: ManageUserDialogProps) => {
  const { initialValue, roles, onSaved, onClose } = props

  const formApi = useForm<UpdateBankUserRequest>({
    defaultValues: initialValue ? getFormFromTableItem(initialValue) : undefined,
    onSubmit: ({ value }) => saveUser(value)
  })

  const { Field, useStore, handleSubmit } = formApi
  const isDirty = useStore((state) => state.isDirty)
  const isSubmitting = useStore((state) => state.isSubmitting)
  const values = useStore((state) => state.values)

  const rolesOptions = [{ label: 'No role', value: '' }].concat(
    roles.map((role) => ({ label: role.name, value: role.key }))
  )

  const options: WriteHookOpts<UpdateBankUserRequest, unknown> = {
    onSuccess: async (payload) => {
      onSaved(initialValue.isCurrentUser)
      formApi.update({ defaultValues: getFormFromTableItem(NEW_USER) })
      formApi.reset()
      onClose()
      Toast.success('Success', `User was ${payload.id ? 'updated' : 'created'}.`)
    },
    onError: (error) => handleFormSubmissionError<UpdateBankUserRequest>(error, formApi)
  }

  const { routine: createUser } = hooks.useCreate(options)
  const { routine: updateUser } = hooks.useUpdate(options)

  const saveUser = (user: UpdateBankUserRequest) => {
    if (user.id) {
      return updateUser(user)
    }
    return createUser(user)
  }

  const onCloseDialog = () => {
    formApi.reset()
    onClose()
  }

  return {
    Field,
    isDirty,
    isSubmitting,
    handleSubmit,
    rolesOptions,
    isFormReady: !!Object.keys(values).length,
    onCloseDialog
  }
}
