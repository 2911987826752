import React, { useEffect } from 'react'

import { FieldApi } from '@tanstack/react-form'
import { BsaSar } from 'commons/types/DTO/bank/bsa'
import { typesOfFinancialInstitution } from 'commons/types/dictionaries'
import { FF, Select, Validators } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

const TypeRenderer = ({
  field,
  index
}: {
  field: FieldApi<BsaSar, 'institution_activities_info[0].type'>
  index: number
}) => {
  const { formApi } = useSARFormContext()

  useEffect(() => {
    if (field.state.value === 'Other') {
      const typeOtherMeta = formApi.getFieldMeta('institution_contact_info.type_other')
      formApi.setFieldMeta(
        `institution_activities_info[${index}].type_other` as 'institution_activities_info[0].type_other',
        {
          ...typeOtherMeta,
          errors: [],
          errorMap: {}
        }
      )
    } else {
      formApi.setFieldValue(
        `institution_activities_info[${index}].type_other` as 'institution_activities_info[0].type_other',
        '',
        { dontUpdateMeta: true }
      )
    }
  }, [field.state.value, formApi])

  return (
    <FF
      required
      field={field}
      label="51. Type of financial institution"
      input={(p) => (
        <Select
          {...p}
          data={typesOfFinancialInstitution}
          data-qa="51. Type of financial institution"
          options-data-qa="51. Type of financial institution option"
        />
      )}
    ></FF>
  )
}

export const Type = ({ index }: { index: number }) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={`institution_activities_info[${index}].type` as 'institution_activities_info[0].type'}
      validators={{
        onChange: ({ value }) => Validators.required.field(value)
      }}
      children={(field) => <TypeRenderer field={field} index={index} />}
    />
  )
}
