import React from 'react'

import { usStates } from 'commons/types/dictionaries'
import { FF, Select } from 'ui/components'

import { useSARFormContext } from '../../../../../../../../context'
import { useSubjectInformationItemContext } from '../../../SubjectInformationItemContext'

const EMPTY_OPTION = { label: 'Not selected', value: '' }

type IdStateProps = {
  index: number
}

export const IdState = ({ index }: IdStateProps) => {
  const { formApi } = useSARFormContext()
  const { isAllInfoUnavailable } = useSubjectInformationItemContext()

  return (
    <formApi.Field
      name={`subjects_info[${index}].id_info[0].id_state` as 'subjects_info[0].id_info[0].id_state'}
      children={(field) => (
        <FF
          field={field}
          label="Issuing State"
          input={(p) => <Select {...p} data={usStates} emptyOption={EMPTY_OPTION} disabled={isAllInfoUnavailable} />}
        />
      )}
    />
  )
}
