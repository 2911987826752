import { Value } from '../types'

export const IsEmpty = (v: Value) => {
  if (v === undefined || v === null) {
    return true
  }
  if (typeof v == 'string' && v.length == 0) {
    return true
  }

  return false
}
