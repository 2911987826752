import { AnyRoute, createRoute } from '@tanstack/react-router'

import { SARListRoute } from './List'

export const SARBatchesRoute = <T extends AnyRoute>(indexRoute: T) => {
  const route = createRoute({
    getParentRoute: () => indexRoute,
    path: `sar-batch-list`
  })

  return route.addChildren([SARListRoute(route)])
}
