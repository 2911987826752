const entityTypeMap: { [key: string]: string } = {
  sole_proprietor: 'Sole Proprietor',
  corporation: 'Corporation',
  llc: 'LLC',
  partnership: 'Partnership',
  nonprofit: 'Nonprofit',
  trust: 'Trust'
}

export const getEntityTypeLabel = (name: string) => (entityTypeMap[name] ? entityTypeMap[name] : name || '---')
