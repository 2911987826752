import React from 'react'

import { DateTime, PageSection, PageSectionFace, Panel } from 'ui/components'
import { Label } from 'ui/temp/Label'
import { Col, Col2x, FormRow } from 'ui/themes/globalStyles'

import { useSARFormContext } from '../../../../context'
import {
  AmountUnknown,
  CUSIPNumberList,
  CommodityTypes,
  CurrentAmount,
  CurrentCumulativeAmount,
  CyberEventIndicators,
  Fraud,
  FraudOther,
  IPAddresses,
  Identification,
  IdentificationOther,
  Laundering,
  LaunderingOther,
  MarketWhereTradedList,
  NoAmountInvolved,
  OtherActivities,
  OtherActivitiesOther,
  PaymentMechanisms,
  PaymentMechanismsOther,
  ProductInstrumentDescriptionList,
  ProductTypes,
  ProductTypesOther,
  Structuring,
  StructuringOther,
  TerrorismFinancing,
  TerrorismFinancingOther
} from './fields'

import { s } from './styles'

export const SuspiciousActivityInfo = () => {
  const { formApi } = useSARFormContext()
  const from = formApi.useStore((s) => s.values.trans_start_date)
  const to = formApi.useStore((s) => s.values.trans_end_date)
  const transactionsInfo = formApi.useStore((s) => s.values.transactions_info)

  if (!transactionsInfo) {
    return null
  }

  const {
    structuring,
    terrorist_financing,
    fraud,
    laundering,
    identification,
    other_activities,
    product_types,
    payment_mechanisms
  } = transactionsInfo

  return (
    <PageSection face={PageSectionFace.THIRD} title="Step 4. Suspicious Activity Information" anchor="step4">
      <Panel rounded shadowed collapsible={false} title="Edit Suspicious Activity Information">
        <s.Inner>
          <FormRow>
            <Col2x>
              <Label>29. Amount involved in this report (rounded)</Label>
              <AmountUnknown />
              <NoAmountInvolved />
            </Col2x>
          </FormRow>
          <FormRow>
            <Col>
              <CurrentAmount />
            </Col>
          </FormRow>
          {from && to && (
            <>
              <FormRow>
                <Label>30. Date or date range of suspicious activity for this report</Label>
              </FormRow>
              <FormRow>
                <s.ColumnThin>
                  <Label>From</Label>
                  <DateTime date={from} />
                </s.ColumnThin>
                <s.ColumnThin>
                  <Label>To</Label>
                  <DateTime date={to} />
                </s.ColumnThin>
              </FormRow>
            </>
          )}
          {/* 31 */}
          <FormRow>
            <Col>
              <CurrentCumulativeAmount />
            </Col>
          </FormRow>
          {/* 32 */}
          <FormRow>
            <s.ColumnWide>
              <Structuring />
              <s.VisibilityBox $visible={structuring.z}>
                <s.OtherInputWrapper>
                  <StructuringOther />
                </s.OtherInputWrapper>
              </s.VisibilityBox>
            </s.ColumnWide>
          </FormRow>
          {/* 33 */}
          <FormRow>
            <s.ColumnWide>
              <TerrorismFinancing />
              <s.VisibilityBox $visible={terrorist_financing.z}>
                <s.OtherInputWrapper>
                  <TerrorismFinancingOther />
                </s.OtherInputWrapper>
              </s.VisibilityBox>
            </s.ColumnWide>
          </FormRow>
          {/* 34 */}
          <FormRow>
            <s.ColumnWide>
              <Fraud />
              <s.VisibilityBox $visible={fraud.z}>
                <s.OtherInputWrapper>
                  <FraudOther />
                </s.OtherInputWrapper>
              </s.VisibilityBox>
            </s.ColumnWide>
          </FormRow>
          {/* 36 */}
          <FormRow>
            <s.ColumnWide>
              <Laundering />
              <s.VisibilityBox $visible={laundering.z}>
                <s.OtherInputWrapper>
                  <LaunderingOther />
                </s.OtherInputWrapper>
              </s.VisibilityBox>
            </s.ColumnWide>
          </FormRow>
          {/* 37 */}
          <FormRow>
            <s.ColumnWide>
              <Identification />
              <s.VisibilityBox $visible={identification.z}>
                <s.OtherInputWrapper>
                  <IdentificationOther />
                </s.OtherInputWrapper>
              </s.VisibilityBox>
            </s.ColumnWide>
          </FormRow>
          {/* 38 */}
          <FormRow>
            <s.ColumnWide>
              <OtherActivities />
              <s.VisibilityBox $visible={other_activities.z}>
                <s.OtherInputWrapper>
                  <OtherActivitiesOther />
                </s.OtherInputWrapper>
              </s.VisibilityBox>
            </s.ColumnWide>
          </FormRow>
          <FormRow>
            <s.ColumnWide>
              <ProductTypes />
              <s.VisibilityBox $visible={product_types.z}>
                <s.OtherInputWrapper>
                  <ProductTypesOther />
                </s.OtherInputWrapper>
              </s.VisibilityBox>
            </s.ColumnWide>
          </FormRow>
          <FormRow>
            <s.ColumnWide>
              <PaymentMechanisms />
              <s.VisibilityBox $visible={payment_mechanisms.z}>
                <s.OtherInputWrapper>
                  <PaymentMechanismsOther />
                </s.OtherInputWrapper>
              </s.VisibilityBox>
            </s.ColumnWide>
          </FormRow>
          <IPAddresses />
          <CyberEventIndicators />
          <CommodityTypes />
          <ProductInstrumentDescriptionList />
          <MarketWhereTradedList />
          <CUSIPNumberList />
        </s.Inner>
      </Panel>
    </PageSection>
  )
}
