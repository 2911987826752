import { getAdverseMediaListWebPage } from 'commons/service/bank/adverseMedia'
import { AdverseMediaListWebPageRequest, AdverseMediaListWebPageResponse } from 'commons/types/DTO/bank/adverseMedia'
import { Keys, ReadHook, useStateManager } from 'state-manager'

export const useGetAdverseMediaListWebPage: ReadHook<
  AdverseMediaListWebPageResponse,
  AdverseMediaListWebPageRequest
> = (opts) =>
  useStateManager().use(Keys.AdverseMediaSearchCase.List(opts), () => getAdverseMediaListWebPage(opts), {
    keepPreviousData: true
  })
