import { DataSourceItem } from 'ui/components'

import { ContactRelatedCompanies } from '../../../../types'

export const selector = (relatedCompanies: ContactRelatedCompanies[]): DataSourceItem<ContactRelatedCompanies>[] =>
  relatedCompanies.map((company) => ({
    item: {
      id: company.id,
      isAccountSigner: company.isAccountSigner,
      isDebtholder: company.isDebtholder,
      isOwner: company.isOwner,
      name: company.name,
      showOwnershipPercent: company.showOwnershipPercent,
      title: company.title,
      isDocuments: company.isDocuments,
      isFinancials: company.isFinancials
    }
  }))
