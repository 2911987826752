import React, { memo, useCallback, useEffect, useRef } from 'react'

import { FormInputProps } from 'brief-form'
import SC from 'styled-components'
import { Icon, IconName, IconSize } from 'ui/components/Icon'
import { CurrencyInput, TextInput } from 'ui/components/InputV2'
import { FormRow } from 'ui/themes/globalStyles'

interface Props extends FormInputProps<{ cash: number; country: string }[], unknown> {}

//region Styles

const Box = SC.div``

const AddIcon = SC(Icon)`
  margin-top: 7px;
  margin-left: 9px;
  cursor: pointer;
`

const DeleteIcon = SC(Icon)`
  margin-top: 7px;
  margin-left: 9px;
  cursor: pointer;
  fill: ${(p) => p.theme.uiKit.colors.global.grey.grey500};
`

const Inputs = SC.div`
  display: flex;
  width: 500px;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  & > div {
    width: 320px;
  }
`

//endregion

export const ForeignInOut = memo((props: Props) => {
  const { value, onChange } = props
  const finalValue = !value?.length ? [{ cash: 0, country: '' }] : value
  const listRef = useRef(value)

  useEffect(() => {
    listRef.current = [...finalValue]
  }, [finalValue, listRef])

  const onAdd = useCallback(() => {
    onChange(listRef.current.concat({ cash: 0, country: '' }), undefined)
  }, [listRef, onChange])

  const onDelete = useCallback(
    (index: number) => {
      const copy = [...listRef.current]
      copy.splice(index, 1)
      onChange(copy, undefined)
    },
    [listRef, onChange]
  )

  const onCashChange = useCallback(
    (index: number, v: number, e?: string) => {
      const copy = [...listRef.current]
      copy[index].cash = v || null
      onChange(copy, e)
    },
    [listRef, onChange]
  )

  const onCountryChange = useCallback(
    (index: number, v: string, e?: string) => {
      const copy = [...listRef.current]
      copy[index].country = v
      onChange(copy, e)
    },
    [listRef, onChange]
  )

  return (
    <Box>
      {finalValue.map((accountNumber: any, index: number) => (
        <FormRow key={index}>
          <Inputs>
            <CurrencyInput value={accountNumber.cash} onChange={onCashChange.bind(null, index)} allowDecimal={false} />
            <TextInput
              toUpper
              value={accountNumber.country}
              onChange={onCountryChange.bind(null, index)}
              maxLength={2}
            />
          </Inputs>
          {finalValue.length > 1 && (
            <DeleteIcon name={IconName.CAN} size={IconSize.M} onClick={() => onDelete(index)} />
          )}
          {index === finalValue.length - 1 && <AddIcon name={IconName.ADD_GREEN} size={IconSize.M} onClick={onAdd} />}
        </FormRow>
      ))}
    </Box>
  )
})
