import { lazy } from 'react'

export * from './enums'
export * from './helpers'
export const HISTORY_PATH = '/history'

export { BankHistoryWrapper } from './BankHistoryWrapper'
export const EntityHistory = lazy(() => import('./components/EntityHistory'))

export * from './historyRoutes'

export { HistoryTable } from './components'
