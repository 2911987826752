import { useCallback, useMemo, useState } from 'react'

import { notFound } from '@tanstack/react-router'
import { useGetLicenseWebPage } from 'commons/hooks/bank/license'
import { LicenseWebPageDocumentFilter, LicenseWebPageRequest } from 'commons/types/DTO/bank'
import isEqual from 'lodash/isEqual'
import { TableSortingChangeHandler, TableSortingOrder } from 'ui/components/Table'
import { Toast } from 'ui/components/Toast'

const getInitialFilter = (id: number): LicenseWebPageRequest => ({
  id,
  documentsFilter: undefined,
  historyPage: 1,
  historySize: 10,
  invoicesPage: 1,
  invoicesSize: 10
})

export const useLicenseDetails = (licenseId: number) => {
  const [filter, setFilter] = useState<LicenseWebPageRequest>(getInitialFilter(licenseId))

  // We have a single endpoint to show data for the whole page, but want to identify
  // which UI element triggered update.
  const [updateCaller, setUpdateCaller] = useState('')

  const resetPending = useCallback(() => setUpdateCaller(''), [])

  const { isFetched, isFetching, data, invalidate } = useGetLicenseWebPage(filter, {
    onSuccess: resetPending,
    onError: resetPending
  })

  if ((isFetched && !data) || !licenseId) {
    throw notFound()
  }

  const onInvoicesFilterChange = useCallback(
    (page: number, size: number) => {
      if (page !== filter.invoicesPage || size !== filter.invoicesSize) {
        setFilter({ ...filter, invoicesPage: page, invoicesSize: size })
        setUpdateCaller('invoices')
      }
    },
    [filter]
  )

  const onDocumentsFilterChange = useCallback(
    (documentsFilter: LicenseWebPageDocumentFilter) => {
      if (!isEqual(documentsFilter, filter.documentsFilter)) {
        setFilter({ ...filter, documentsFilter })
        setUpdateCaller('documents')
      }
    },
    [filter]
  )

  const onHistoryFilterChange = useCallback(
    (page: number, size: number) => {
      if (page !== filter.historyPage || size !== filter.historySize) {
        setFilter({ ...filter, historyPage: page, historySize: size })
        setUpdateCaller('history')
      }
    },
    [filter]
  )

  const onInvoiceDeleted = useCallback(() => {
    invalidate()
    setUpdateCaller('invoices')
  }, [invalidate])

  const onDocumentDeleted = useCallback(() => {
    Toast.successSmall('Document was deleted')
    invalidate()
    setUpdateCaller('documents')
  }, [invalidate])

  const invoicesTableConfig = useMemo(
    () => ({
      isFetching: updateCaller === 'invoices',
      filter: {
        page: filter.invoicesPage,
        size: filter.invoicesSize
      },
      onFilterChange: onInvoicesFilterChange
    }),
    [filter, onInvoicesFilterChange, isFetching, updateCaller]
  )

  const historyTableConfig = useMemo(
    () => ({
      isFetching: updateCaller === 'history',
      filter: {
        page: filter.historyPage,
        size: filter.historySize
      },
      onFilterChange: onHistoryFilterChange
    }),
    [filter, onHistoryFilterChange, isFetching, updateCaller]
  )

  const onOrderChange: TableSortingChangeHandler = useCallback(
    (value) => {
      setFilter({
        ...filter,
        documentsFilter: { ...filter.documentsFilter, sortBy: value.field, sortDesc: value.direction === 'DESC' }
      })
      setUpdateCaller('documents')
    },
    [filter]
  )

  const order: TableSortingOrder = useMemo(
    () => ({
      field: filter.documentsFilter?.sortBy ?? '',
      direction: filter.documentsFilter?.sortDesc ? 'DESC' : 'ASC'
    }),
    [filter.documentsFilter]
  )

  const documentsTableConfig = useMemo(
    () => ({
      onOrderChange,
      isFetching: updateCaller === 'documents',
      onFilterChange: onDocumentsFilterChange,
      order: [order]
    }),
    [onDocumentsFilterChange, updateCaller, onOrderChange, order]
  )

  return {
    data,
    isFetching,
    invoicesTableConfig,
    documentsTableConfig,
    historyTableConfig,
    onInvoiceDeleted,
    onDocumentDeleted
  }
}
