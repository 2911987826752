import React from 'react'

import { FF, TextInput } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

export const InternalControlNumber = ({ index }: { index: number }) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={
        `institution_activities_info[${index}].internal_control_number` as 'institution_activities_info[0].internal_control_number'
      }
      children={(field) => (
        <FF label="66. Internal control/file number" field={field} input={(p) => <TextInput {...p} maxLength={50} />} />
      )}
    />
  )
}
