import { OnboardingFormState } from '../../../types/state'
import { emptyATMServices, getEmptyATM } from './emptyEntity/getEmptyATM'
import { setNewCompanies } from './setNewCompanies'

export const getStateAfterAddedATM = ({
  currentState,
  companyIndex
}: {
  currentState: OnboardingFormState
  companyIndex: number
}) => {
  const selectedCompany = currentState.formValue.companiesFormValues[companyIndex]
  const oldATMs = selectedCompany.atms
  const newATM = getEmptyATM({ atm: emptyATMServices })
  const newATMs = [...oldATMs, newATM]
  const newCompany = { ...selectedCompany, atms: newATMs }
  const newCompaniesFormValues = setNewCompanies({
    companyIndex,
    oldCompanies: currentState.formValue.companiesFormValues,
    newCompany
  })
  return {
    formValue: {
      ...currentState.formValue,
      companiesFormValues: newCompaniesFormValues
    }
  }
}
