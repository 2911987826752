import SC from 'styled-components'

export const FormWrapper = SC.div`
  padding: 20px 30px 32px 30px;
`

export const ItemWrapper = SC.div`
  width: 226px;
`

export const FilterWrapper = SC.div`
  display: flex;
  gap: 30px;
`
