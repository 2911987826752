import * as React from 'react'

import SC from 'styled-components'

import { Header } from './components'
import { PageSectionFace } from './pageSectionFace'

export interface PageSectionProps {
  face?: PageSectionFace
  bottomIndent?: boolean
  className?: string
  anchor?: string
  title?: React.ReactNode
  titleBefore?: React.ReactNode
  titleAfter?: React.ReactNode
  actions?: React.ReactNode
  children?: React.ReactNode
  first?: boolean
  id?: string
}

const defaultProps: Partial<PageSectionProps> = {
  face: PageSectionFace.PRIMARY,
  bottomIndent: true
}

//region Styles

const Box = SC.section<{ face?: PageSectionFace; bottomIndent?: boolean }>`
  border-top: ${(p) =>
    p.face === PageSectionFace.PRIMARY ? `1px solid ${p.theme.uiKit.colors.global.grey.grey500}` : 'none'};
  margin-top: ${(p) => (p.face === PageSectionFace.PRIMARY ? '80px' : '0')};
  ${(p) => (p.face === PageSectionFace.PRIMARY && p.bottomIndent ? 'margin-bottom: 70px;' : '')}
  ${(p) => (p.face === PageSectionFace.SECONDARY && p.bottomIndent ? 'margin-bottom: 50px;' : '')}
  ${(p) => (p.face === PageSectionFace.THIRD && p.bottomIndent ? 'margin-bottom: 50px;' : '')}
`

const Content = SC.div`
`

//endregion

export const PageSection = React.memo((props: PageSectionProps) => {
  const { children, face, bottomIndent, anchor, title, titleAfter, titleBefore, actions, first, ...rest } = {
    ...defaultProps,
    ...props
  }

  return (
    <Box face={face} bottomIndent={bottomIndent} {...(!!anchor && { id: anchor })} {...rest}>
      <Header
        face={face}
        title={title}
        actions={actions}
        first={first}
        titleAfter={titleAfter}
        titleBefore={titleBefore}
      />
      <Content>{children}</Content>
    </Box>
  )
})
