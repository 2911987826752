import React, { memo } from 'react'

import { IconName } from 'ui/components/Icon'

import * as SC from './styles'

interface Props {
  isMainActive: boolean
  isVisible: boolean
  isMinimized: boolean

  onClickMain: () => void
  onClickPage: () => void
}

export const Toggle = memo(({ onClickMain, onClickPage, isMainActive, isVisible, isMinimized }: Props) => {
  if (!isVisible) {
    return <SC.Box $visible={false}></SC.Box>
  }

  return isMainActive ? (
    <SC.Box onClick={onClickPage} $visible={isVisible} data-qa="main-menu-toggle">
      {isVisible && (
        <>
          <SC.IconWrapper name={IconName.ARROW_RIGHT_FILLED} />
          {!isMinimized && <>Page Menu</>}
        </>
      )}
    </SC.Box>
  ) : (
    <SC.Box onClick={onClickMain} $visible={isVisible} data-qa="main-menu-toggle">
      {isVisible && (
        <>
          <SC.IconWrapper name={IconName.ARROW_LEFT_FILLED} />
          {!isMinimized && <>Main Menu</>}
        </>
      )}
    </SC.Box>
  )
})
