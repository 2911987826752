import { PageLabels } from '../../../enums'

export const getPageLabel = (step: number): PageLabels | '' => {
  switch (step) {
    case 1:
      return PageLabels.GENERAL_INFORMATION
    case 2:
      return PageLabels.DOCUMENTS_UPLOADING
    case 3:
      return PageLabels.ON_BOARDING_DOCUMENTS
    case 4:
      return PageLabels.ACCOUNT_OPENED
    default:
      return ''
  }
}
