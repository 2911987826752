import { InputHTMLAttributes, ReactNode } from 'react'

import { FormInputProps } from 'brief-form'

export type CheckBoxOpts = Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'> & {
  disabled?: boolean
  checked?: boolean
  hint?: ReactNode
  checkBoxLabel?: ReactNode
  'data-qa'?: string
  'data-testid'?: string
}

export interface CheckBoxProps extends Omit<FormInputProps<boolean, CheckBoxOpts>, 'label'> {}
