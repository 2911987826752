import { AnyRoute, createRoute, lazyRouteComponent } from '@tanstack/react-router'

import { applicationDocumentEditLoader } from './applicationDocumentEditLoader'

export const DocumentEditRoute = <T extends AnyRoute>(indexRoute: T) =>
  createRoute({
    getParentRoute: () => indexRoute,
    path: '$applicationId/document/$documentId/edit',
    loader: applicationDocumentEditLoader,
    component: lazyRouteComponent(() => import('./ApplicationDocumentEdit')),
    shouldReload: (p) => p.cause === 'enter',
    gcTime: 0
  })
