import React, { FC, memo, useCallback } from 'react'

import { Form } from 'brief-form'
import { DatePicker } from 'ui/components/DatePicker'
import { FieldSet } from 'ui/components/FieldSet'
import { IntegerInput } from 'ui/components/InputV2'
import { CompanySelect, LicenseSelect, Select } from 'ui/components/Select'
import { FilterPanel } from 'ui/components/Table'

import { FilterProps } from './FilterProps'

import * as SC from './styles'

export const Filter: FC<FilterProps> = memo((props) => {
  const {
    form: { isDirty, config, set, Field },
    expanded,
    onToggle,
    statuses
  } = props
  const DEFAULT_OPTION = { label: 'All', value: 0 }

  const mappedDtatuses = Object.keys(statuses).map((item) => ({
    label: statuses[item],
    value: item
  }))

  const originalChangeHandler = config.onChange
  config.onChange = useCallback(
    (v) => {
      if (v.recipientCompanyID !== config.value.recipientCompanyID) {
        v.recipientLicenseID = 0
      }
      if (v.senderCompanyID !== config.value.senderCompanyID) {
        v.senderLicenseID = 0
      }
      originalChangeHandler(v, {})
    },
    [originalChangeHandler, config.value]
  )

  return (
    <FilterPanel isDirty={isDirty} onReset={() => set({ reset: true })} expanded={expanded} onToggle={onToggle}>
      <SC.FormWrapper>
        <Form config={config}>
          <SC.Columns>
            <SC.Column1>
              <FieldSet legend="Transfer Status and Number">
                <SC.Columns>
                  <SC.StatusWrapper>
                    <Field
                      name="status"
                      label="Status"
                      input={Select}
                      inputProps={{
                        data: mappedDtatuses
                      }}
                    />
                  </SC.StatusWrapper>
                  <SC.NumberWrapper>
                    <span>IT-</span>
                    <Field name="id" label="Number" input={IntegerInput} inputProps={{}} />
                  </SC.NumberWrapper>
                </SC.Columns>
              </FieldSet>
              <FieldSet legend="Select a Period">
                <SC.Columns>
                  <SC.DateFromWrapper>
                    <Field name="dateFrom" label="Start Date" input={DatePicker} inputProps={{}} />
                  </SC.DateFromWrapper>
                  <Field name="dateTo" label="End Date" input={DatePicker} inputProps={{}} />
                </SC.Columns>
              </FieldSet>
            </SC.Column1>
            <div>
              <FieldSet legend="Sender Details">
                <SC.Columns>
                  <SC.CompanyWrapper>
                    <Field
                      name="senderCompanyID"
                      label="Sender"
                      input={CompanySelect}
                      inputProps={{
                        defaultOption: DEFAULT_OPTION,
                        hideMyCompaniesFilter: true
                      }}
                    />
                  </SC.CompanyWrapper>
                  <SC.LicenseWrapper>
                    <Field
                      name="senderLicenseID"
                      label="License"
                      input={LicenseSelect}
                      inputProps={{
                        defaultOption: DEFAULT_OPTION
                      }}
                    />
                  </SC.LicenseWrapper>
                </SC.Columns>
              </FieldSet>
              <FieldSet legend="Recipient Details">
                <SC.Columns>
                  <SC.CompanyWrapper>
                    <Field
                      name="recipientCompanyID"
                      label="Recipient"
                      input={CompanySelect}
                      inputProps={{
                        defaultOption: DEFAULT_OPTION,
                        hideMyCompaniesFilter: true
                      }}
                    />
                  </SC.CompanyWrapper>
                  <SC.LicenseWrapper>
                    <Field
                      name="recipientLicenseID"
                      label="License"
                      input={LicenseSelect}
                      inputProps={{
                        defaultOption: DEFAULT_OPTION,
                        filters: config.value.recipientCompanyID
                          ? [
                              {
                                field: 'company_id',
                                type: 'eq',
                                value: config.value.recipientCompanyID
                              }
                            ]
                          : undefined
                      }}
                    />
                  </SC.LicenseWrapper>
                </SC.Columns>
              </FieldSet>
            </div>
          </SC.Columns>
        </Form>
      </SC.FormWrapper>
    </FilterPanel>
  )
})
