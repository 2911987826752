import * as React from 'react'

import { getAdverseInformationHitRiskLevelLabel } from './getAdverseInformationHitRiskLevelLabel'

interface Props {
  name: string
}

export const AdverseInformationHitRiskLevelLabel = ({ name }: Props) => (
  <span>{getAdverseInformationHitRiskLevelLabel(name)}</span>
)
