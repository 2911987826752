import React, { FC, memo } from 'react'

import { Form } from 'brief-form'
import { Dialog, DialogFace, DialogFooterActions, Hr } from 'ui/components'
import { LeaveConfirmation } from 'ui/components/LeaveConfirmation'

import { CreateUserDialogProps } from './CreateUserDialogProps'
import { AssignedRelationshipsForm, ClientUserGeneralInformationForm, PermissionsForm } from './components'
import { useCreateUserDialog } from './hooks'

export const CreateUserDialog: FC<CreateUserDialogProps> = memo((props) => {
  const { open, onClose, clientUserForEditing, permissionsStructure, reloadClientUserList } = props

  const { isModeCreating, onCloseWrapper, onSubmitWrapper, isFormChanged, config, Field, isSubmittingClientUserForm } =
    useCreateUserDialog(clientUserForEditing, permissionsStructure, onClose, reloadClientUserList)

  if (!open) {
    return null
  }

  return (
    <LeaveConfirmation
      ignoreUrlHashChanging={true}
      onLeave={onCloseWrapper}
      preventLeaving={isFormChanged && !isSubmittingClientUserForm}
    >
      <Dialog
        id="create-client-user-dialog"
        title={isModeCreating ? 'Add New Client User' : `${clientUserForEditing.name}`}
        subTitle={!isModeCreating ? 'Edit Relationships & Permissions' : null}
        face={DialogFace.PRIMARY}
        onClose={onCloseWrapper}
        footerActions={[DialogFooterActions.SAVE, DialogFooterActions.CANCEL]}
        onSubmit={onSubmitWrapper}
        isSubmitting={isSubmittingClientUserForm}
      >
        <Form config={config}>
          {isModeCreating && <ClientUserGeneralInformationForm Field={Field} />}
          <AssignedRelationshipsForm Field={Field} isModeCreating={isModeCreating} />
          <Hr />
          <PermissionsForm permissions={permissionsStructure} formValue={config.value} Field={Field} />
        </Form>
      </Dialog>
    </LeaveConfirmation>
  )
})
