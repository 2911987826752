import React from 'react'

import { INTERNAL_TRANSFERS_PATH } from 'commons/constants/routes'
import { InternalTransferList } from 'commons/types/DTO/bank'
import { formatCurrency } from 'commons/utils'
import { LicenseInfo } from 'ui/components/LicenseInfo'
import { Link } from 'ui/components/Link'
import { MenuItemBehavior, MenuItemTextFace, TableActions } from 'ui/components/QueryTable'
import { TableColumn } from 'ui/components/Table'

type Columns = {
  onCancel: (item: InternalTransferList) => void
}

export const columns = ({ onCancel }: Columns): TableColumn<any>[] => [
  {
    title: 'Transfer number',
    align: 'left',
    render: (item) => <Link to={`${INTERNAL_TRANSFERS_PATH}/${item.id}`}>{item.transferNumber}</Link>
  },
  {
    title: 'Sender',
    align: 'left',
    render: (item) =>
      item.senderLicenseID ? (
        <LicenseInfo
          companyName={item.senderCompanyName}
          licenseName={item.senderLicenseName}
          licenseId={item.senderLicenseID}
          licenseType={item.senderLicenseType}
          companyId={item.senderCompanyID}
        />
      ) : (
        <LicenseInfo companyName={item.senderName} companyId={item.senderCompanyID} />
      )
  },
  {
    title: 'Recipient',
    align: 'left',
    render: (item) =>
      item.recipientLicenseID ? (
        <LicenseInfo
          companyName={item.recipientCompanyName}
          licenseName={item.recipientLicenseName}
          licenseId={item.recipientLicenseID}
          licenseType={item.recipientLicenseType}
          companyId={item.recipientCompanyID}
        />
      ) : (
        <LicenseInfo companyName={item.recipientName} companyId={item.recipientCompanyID} />
      )
  },
  {
    title: 'Amount',
    align: 'center',
    render: ({ amount }) => formatCurrency(amount)
  },
  {
    title: 'Status',
    key: 'status',
    align: 'center'
  },
  {
    title: 'Created Date',
    key: 'createdDate'
  },
  {
    title: 'Actions',
    align: 'center',
    width: '50px',
    render: (item) =>
      item.enableCancel && (
        <TableActions
          menuContainerId="transferTableMenuContainer"
          items={[
            {
              key: 1,
              title: 'Cancel',
              behavior: MenuItemBehavior.BUTTON,
              textFace: MenuItemTextFace.GREY,
              onClick: () => onCancel(item)
            }
          ]}
        />
      )
  }
]
