import { AnyRoute, createRoute } from '@tanstack/react-router'

import { DetailsRoute } from './Details'
import { ListRoute } from './List'
import { ManageSARRoute } from './Manage'

export const SARRoute = <T extends AnyRoute>(indexRoute: T) => {
  const route = createRoute({
    getParentRoute: () => indexRoute,
    path: `sars`
  })

  return route.addChildren([ManageSARRoute(route), ListRoute(route), DetailsRoute(route)])
}
