import SC from 'styled-components'

export const Wrapper = SC.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

export const TableWrapper = SC.div`
  width: 100%;
`
