import React from 'react'

import { FF, TextInput } from 'ui/components'

import { useSARFormContext } from '../../../../../../context'

type AlternateNameProps = {
  index: number
}

export const AlternateName = ({ index }: AlternateNameProps) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={`subjects_info[${index}].alternate_names[0].value` as 'subjects_info[0].alternate_names[0].value'}
      children={(field) => <FF field={field} label="9. Alternate name" input={(p) => <TextInput {...p} />} />}
    />
  )
}
