import React, { FC, memo } from 'react'

import { TableButtonProps } from './TableButtonProps'

import * as SC from './styles'

export const TableButton: FC<TableButtonProps> = memo((props) => {
  const { children, face = 'neutral', 'data-qa': dataQA, ...rest } = props

  const data_qa = typeof children === 'string' && !dataQA ? children : dataQA

  const isBrand = face === 'brand'
  const isWarning = face === 'warning'
  const isDander = face === 'danger'
  const isNeutral = face === 'neutral'

  return (
    <>
      {isBrand && (
        <SC.Brand data-qa={data_qa} {...rest}>
          <SC.Inner>{children}</SC.Inner>
        </SC.Brand>
      )}
      {isWarning && (
        <SC.Warning data-qa={data_qa} {...rest}>
          <SC.Inner>{children}</SC.Inner>
        </SC.Warning>
      )}
      {isDander && (
        <SC.Dander data-qa={data_qa} {...rest}>
          <SC.Inner>{children}</SC.Inner>
        </SC.Dander>
      )}
      {isNeutral && (
        <SC.Neutral data-qa={data_qa} {...rest}>
          <SC.Inner>{children}</SC.Inner>
        </SC.Neutral>
      )}
    </>
  )
})
