// dublicate types with packages/commons/types/DTO/bank/license/license.ts
export type LicensesSICS = {
  dba_name: string
  last_connection_date: string
  license_name: string
  license_number: string
  license_subtype: string
  license_state: string
  organization_id: number
  relationship_id: number
  relationship_name: string
  connected: boolean
}
