export enum BankName {
  DEV = 1,
  SHORE = 2,
  CBT = 3,
  INB = 5,
  MPB = 6,
  HERRING = 7,
  TRANSACT = 8,
  CHAMPION = 9,
  DEMO = 10,
  MED_MEN = 11,
  FLAGLER = 12,
  VALLIANCE = 13,
  BISON = 14
}

export enum BankNames {
  DEV = 'Dev Bank'
}
