import { FieldProps, FormConfig, FormInputProps } from 'brief-form'
import { LicenseManageRequest } from 'commons/types/DTO/bank'

export interface ManageLicenseFormProps {
  companyName: string
  Confirmation: () => JSX.Element | null
  config: FormConfig<LicenseManageRequest>
  Field: <Input extends React.ComponentType<FormInputProps<any, any>>>(
    props: FieldProps<Input, LicenseManageRequest>
  ) => JSX.Element
}
