import * as VARS from 'ui/themes/variables'

import { ButtonFace } from '../ButtonFace'

export const getActiveTextColor = (face: ButtonFace) => {
  switch (face) {
    case 'neutral':
      return 'var(--theme-colors-global-general-main)'
    case 'negative':
      return 'var(--theme-colors-global-general-main)'
    case 'positive':
      return 'var(--theme-colors-global-general-main)'
    default:
      return VARS.COLOR_NOT_FOUND
  }
}
