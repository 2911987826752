import React, { FC, memo } from 'react'

import { Form } from 'brief-form'
import { CheckBox, Hint } from 'ui/components'
import { InfoSet } from 'ui/components/Info'
import { TextInput } from 'ui/components/InputV2'
import { Col2x, FormRow } from 'ui/themes/globalStyles'

import { OnboardingFormFieldNames } from '../../enums'
import { AvailableServicesFormProps } from './AvailableServicesFormProps'
import { getAvailableServicesList } from './helpers'
import { useAvailableServicesForm } from './useAvailableServicesForm'

import * as SC from './styles'

export const AvailableServicesForm: FC<AvailableServicesFormProps> = memo(
  ({
    companyIndex,
    value,
    state: {
      isAvailableServicesDescEnable,
      isAvailServiceHowManyAccountsIsOpeningEnable,
      conditions,
      isAvailServiceOnlineBankingDescEnable
    },
    onChange
  }) => {
    const { Field, config } = useAvailableServicesForm({ companyIndex, formData: value, onChange })
    const availableServicesList = getAvailableServicesList(conditions)

    return (
      <Form config={config}>
        <InfoSet legend="Available Services">
          {isAvailableServicesDescEnable && (
            <Hint>
              Please note that not all services are available for all types of entities, e.g., debit card and foreign
              wires are not permissible with licensed accounts.
            </Hint>
          )}
          {availableServicesList.length > 0 && (
            <>
              <SC.SubTitle>Please select all the bank services your organization would like to access:</SC.SubTitle>
              <div>
                <SC.CheckBoxes>
                  {availableServicesList.map((el, id) => (
                    <SC.CheckBoxItem key={id}>
                      <Field
                        name={el.name as any}
                        input={CheckBox}
                        inputProps={{
                          'data-qa': el.dataQa ?? el.label,
                          checkBoxLabel: el.label,
                          hint:
                            isAvailServiceOnlineBankingDescEnable && el.name === 'availServiceOnlineMobileBanking'
                              ? '$3,000 daily limit on mobile bank deposits'
                              : null
                        }}
                      />
                    </SC.CheckBoxItem>
                  ))}
                </SC.CheckBoxes>
              </div>
            </>
          )}
          {isAvailServiceHowManyAccountsIsOpeningEnable && (
            <FormRow>
              <Col2x>
                <Field
                  name={OnboardingFormFieldNames.AVAIL_SERVICE_HOW_MANY_ACCOUNTS_IS_OPENING}
                  label="How many accounts and what types of accounts is the business looking to open?"
                  input={TextInput}
                  inputProps={{
                    'data-qa': 'How many accounts and what types of accounts is the business looking to open?'
                  }}
                />
              </Col2x>
            </FormRow>
          )}
        </InfoSet>
      </Form>
    )
  }
)
