/**
 * Most common filter record - compare field by its value.
 */
export type SimpleFilterRecord = {
  field: string
  type: string
  value: any
}

/**
 * Filter, implementing OR condition syntax.
 */
export type OrFilterRecord = {
  type: 'or'
  filters: (SimpleFilterRecord | OrFilterRecord)[]
}

/**
 * Shape of query options to be sent to server.
 */
export type ServerOptions = {
  _options?: {
    offset?: number
    limit?: number
    filters?: (SimpleFilterRecord | OrFilterRecord)[]
    orders?: { field: string; direction: 'ASC' | 'DESC' }[]
  }
  [key: string]: any
}
