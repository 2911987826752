import React, { useEffect } from 'react'

import { scrollToElement } from 'commons/utils'
import { FormFieldClassNames } from 'ui/components'
import { useConfirmation } from 'ui/hooks'

import { OnboardingFormDispatch } from '../../types/actions'
import { ErrorsMessage } from '../ErrorsMessage'
import * as actions from './actions'

export const useOnboardingFormErrors = ({
  lastErrorCount,
  generalErrors,
  formDispatch
}: {
  lastErrorCount: number
  generalErrors: Array<string>
  formDispatch: OnboardingFormDispatch
}) => {
  const handleScrollToFirstErrorField = () => {
    const errorElementSelector = ['', FormFieldClassNames.FORM_FIELD_ERROR, FormFieldClassNames.ERROR_VISIBLE].join('.')
    const firstErrorField = document.querySelector(errorElementSelector)
    if (!firstErrorField) {
      return
    }
    scrollToElement({ elementToScroll: firstErrorField })
  }

  const { Confirmation: ErrorMessagesDialog, open: openErrorMessagesDialog } = useConfirmation({
    message: <ErrorsMessage requiredFieldsLen={lastErrorCount} formErrors={generalErrors} />,
    onConfirm: () => {
      handleScrollToFirstErrorField()
      formDispatch(actions.flushLastErrorCount())
    },
    onCancel: () => formDispatch(actions.flushLastErrorCount()),
    confirmationButtonText: 'Go to fields',
    isConfirmationNegative: false,
    isCancelButtonNeeded: false
  })

  useEffect(() => {
    if (lastErrorCount || generalErrors.length) {
      openErrorMessagesDialog()
    }
  }, [lastErrorCount, generalErrors, openErrorMessagesDialog])

  return {
    confirmations: {
      ErrorMessagesDialog
    },
    handlers: {}
  }
}
