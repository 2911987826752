import React, { FC } from 'react'

import { Form } from 'brief-form'
import { YesNoRadioGroup } from 'commons/types/enums'
import { Icon, IconName, IconSize, Tooltip, Validators } from 'ui/components'
import { InfoSet } from 'ui/components/Info'
import { TextInput, TextareaInput } from 'ui/components/InputV2'
import { RadioGroup } from 'ui/components/RadioGroup'
import { Col2x, FormRow } from 'ui/themes/globalStyles'

import { OnboardingFormFieldNames } from '../../enums'
import { MerchantQuestionnaireFormProps } from './types'
import { useMerchantQuestionnaireForm } from './useMerchantQuestionnaireForm'

import * as SC from './styles'

export const MerchantQuestionnaireForm: FC<MerchantQuestionnaireFormProps> = ({
  companyIndex,
  value,
  state: { isPrimaryMethodToFundEnable, isCryptocurrencyTransactionsEnable, isRealTimePaymentServicesEnable },
  onChange
}) => {
  const { Field, config } = useMerchantQuestionnaireForm({ companyIndex, formData: value, onChange })

  return (
    <Form config={config}>
      <InfoSet>
        <FormRow>
          <Col2x>
            <Field
              name={OnboardingFormFieldNames.LEGACY_CASH}
              label={
                <SC.ToolTipBox>
                  Does the organization anticipate depositing any legacy cash?{' '}
                  <Tooltip
                    id={OnboardingFormFieldNames.LEGACY_CASH}
                    type="dark"
                    content={<>Legacy cash is unbanked money accumulated by a business.</>}
                  >
                    <SC.TooltipTriggerButton tabIndex={0}>
                      <Icon name={IconName.INFO} size={IconSize.S} />
                    </SC.TooltipTriggerButton>
                  </Tooltip>
                </SC.ToolTipBox>
              }
              input={RadioGroup}
              inputProps={{
                name: `${OnboardingFormFieldNames.LEGACY_CASH}${companyIndex}`,
                data: [
                  { value: YesNoRadioGroup.YES, label: 'Yes' },
                  { value: YesNoRadioGroup.NO, label: 'No' }
                ]
              }}
              required
              validator={Validators.required.notNull}
            />
          </Col2x>
        </FormRow>
        <FormRow>
          <Col2x>
            <Field
              name={OnboardingFormFieldNames.MERCHANT_PROCESSING}
              label="Does the company have any merchant processing (Credit Cards, Debit Cards, etc.)?"
              input={RadioGroup}
              inputProps={{
                name: `${OnboardingFormFieldNames.MERCHANT_PROCESSING}${companyIndex}`,
                data: [
                  { value: YesNoRadioGroup.YES, label: 'Yes' },
                  { value: YesNoRadioGroup.NO, label: 'No' }
                ]
              }}
              required
              validator={Validators.required.notNull}
            />
          </Col2x>
        </FormRow>
        {config.value.merchantProcessing === YesNoRadioGroup.YES && (
          <FormRow>
            <Col2x>
              <SC.DescriptionHint>
                <p>Please list all merchant processors currently in use by the business.</p>
              </SC.DescriptionHint>
              <Field
                name={OnboardingFormFieldNames.MERCHANT_PROCESSING_DESC}
                input={TextareaInput}
                inputProps={{
                  rows: 4
                }}
              />
            </Col2x>
          </FormRow>
        )}
        {isPrimaryMethodToFundEnable && (
          <FormRow>
            <Col2x>
              <Field
                name={OnboardingFormFieldNames.PRIMARY_METHOD_TO_FUND}
                input={TextInput}
                // eslint-disable-next-line max-len
                label="What is the primary method used to fund the initial deposit on this account? (Cash, wire transfer, check)"
                inputProps={{
                  'data-qa':
                    // eslint-disable-next-line max-len
                    'What is the primary method used to fund the initial deposit on this account? (Cash, wire transfer, check)'
                }}
              />
            </Col2x>
          </FormRow>
        )}
        {isCryptocurrencyTransactionsEnable && (
          <FormRow>
            <Col2x>
              <Field
                name={OnboardingFormFieldNames.CRYPTOCURRENCY_TRANSACTIONS}
                label="Does the business exchange or invest in or conduct transactions with cryptocurrency?"
                input={RadioGroup}
                inputProps={{
                  name: `${OnboardingFormFieldNames.CRYPTOCURRENCY_TRANSACTIONS}${companyIndex}`,
                  data: [
                    { value: YesNoRadioGroup.YES, label: 'Yes' },
                    { value: YesNoRadioGroup.NO, label: 'No' }
                  ],
                  'data-qa': 'Does the business exchange or invest in or conduct transactions with cryptocurrency?'
                }}
              />
            </Col2x>
          </FormRow>
        )}
        {isRealTimePaymentServicesEnable && (
          <FormRow>
            <Col2x>
              <Field
                name={OnboardingFormFieldNames.REAL_TIME_PAYMENT_SERVICES}
                // eslint-disable-next-line max-len
                label="Does the business anticipate using real-time payment services (ex: PayPal, Venmo, Google Wallet, etc.)?"
                input={RadioGroup}
                inputProps={{
                  name: `${OnboardingFormFieldNames.REAL_TIME_PAYMENT_SERVICES}${companyIndex}`,
                  data: [
                    { value: YesNoRadioGroup.YES, label: 'Yes' },
                    { value: YesNoRadioGroup.NO, label: 'No' }
                  ],
                  'data-qa':
                    // eslint-disable-next-line max-len
                    'Does the business anticipate using real-time payment services (ex: PayPal, Venmo, Google Wallet, etc.)?'
                }}
              />
            </Col2x>
          </FormRow>
        )}
      </InfoSet>
    </Form>
  )
}
