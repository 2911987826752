import { SalesSourceItemInfo } from './SalesSourceItemInfo'
import { SalesSourceLicense } from './SalesSourceLicense'

export type SalesSourceItem = {
  enableOverride: boolean
  enableSendTask: boolean
  licenses: SalesSourceLicense[]
  overrideButtonTooltip: string
  overrideGramsSoldAmount: number
  overrideGramsSoldQty: number
  overrideTotalSalesAmount: number
  overrideUnitsSoldAmount: number
  overrideUnitsSoldQty: number
  sources: SalesSourceItemInfo[]
}
