import { ReactNode } from 'react'

import { AnnualReviewQuestionnaire } from 'commons/types/DTO/bank'
import flatten from 'lodash/flatten'
import { formatDateTime } from 'ui/components/DateTime'

interface AnnualReviewQuestionnaireMappingProps {
  financials: {
    investorDebtholders: string | ReactNode
    depositsMatching: string | ReactNode
    becomingFailing: string | ReactNode
    profitabilityTrend: string | ReactNode
    periodCollected: string | ReactNode
    businessCondition: string | ReactNode
    isMaterialsRegardingIncome: string | ReactNode
    currentConditionIsAcceptable: string | ReactNode
    materialsNotes: string | ReactNode
    acceptableNotes: string | ReactNode
  }

  transactions: {
    notes: string | ReactNode
    matchTrans: string | ReactNode
    expectExplain?: string | ReactNode
    expectExactly: string | ReactNode
    volProjected: string | ReactNode
    volProjectedExactly: string | ReactNode | null

    type: string | ReactNode
  }

  covenant: {
    transparent: string | ReactNode
    nonComplianceDesc: string | ReactNode
    complying: string | ReactNode
  }

  siteVisit: {
    shutdownRisk: string | ReactNode
    scoresAcceptable: string | ReactNode
    scoresAcceptableExactly: string | ReactNode
    violationRectified: string | ReactNode
    complyingRegulations: string | ReactNode
    lastDate: string | null | ReactNode
    freq: string | ReactNode
    siteVisitHasStateAuditAtReviewPeriod: string | ReactNode
    siteVisitStateAuditNotes: string | ReactNode
  }

  ownership: {
    badNewsClearedExplain: string | ReactNode
    badNewsCleared: string | ReactNode
    badNewsDesc: string | ReactNode
    badNews: string | ReactNode
    ofacTloReviewed: string | ReactNode
    changesApproved: string | ReactNode
    providedForms: string | ReactNode
    changes: string | ReactNode
    ownershipHasMaterialChangesInManagementAtReviewPeriod: string | ReactNode
    ownershipMaterialChangesInManagementNotes: string | ReactNode
  }

  recom: {
    changeVisits: string | ReactNode
    status: string | ReactNode
    recommended: string | ReactNode
    recomComments: string | ReactNode
  }
}

// TODO: fix return type
// eslint-disable-next-line
export const annualReviewQuestionnaireMapping = (
  data: AnnualReviewQuestionnaireMappingProps,
  // TODO: fix any types
  // eslint-disable-next-line
  params: Record<string, any> = {},
  viewMode?: boolean,
  isShore?: boolean
) => {
  const { financials, transactions, covenant, siteVisit, ownership, recom } = data
  return [
    {
      title: 'Financials',
      items: [
        ['Period Collected:', financials.periodCollected, { ...params?.financials?.periodCollected }],
        !isShore && [
          'Current financial condition of business:',
          financials.businessCondition,
          params?.financials?.businessCondition
        ],
        !isShore && ['Profitability trend:', financials.profitabilityTrend, params?.financials?.profitabilityTrend],
        !isShore && [
          'If condition is unstable, likelihood of company becoming solvent or failing:',
          financials.becomingFailing,
          params?.financials?.becomingFailing
        ],
        isShore && [
          'Were there any material concerns regarding changes in income, expenses or equity?',
          financials.isMaterialsRegardingIncome,
          params?.financials?.isMaterialsRegardingIncome
        ],
        isShore && ['If yes, must fill out notes', financials.materialsNotes, params?.financials?.materialsNotes],
        isShore && [
          'Is current financial condition of business acceptable?',
          financials.currentConditionIsAcceptable,
          params?.financials?.currentConditionIsAcceptable
        ],
        isShore && ['If no, must fill out notes', financials.acceptableNotes, params?.financials?.acceptableNotes],
        ['Is deposit activity in line with sales?', financials.depositsMatching, params?.financials?.depositsMatching],
        !isShore && ['Investors/Debtholders:', financials.investorDebtholders, params?.financials?.investorDebtholders]
      ].filter(Boolean)
    },
    !isShore && {
      title: 'Transactions',
      items: [
        ['Types of transactions performed:', transactions.type, params?.transactions?.type],
        [
          'Volumes as anticipated/projected?',
          flatten(
            [
              transactions.volProjected,
              transactions.volProjectedExactly && [' (', transactions.volProjectedExactly, ')']
            ].filter(Boolean)
          ),
          params?.transactions?.volProjected
        ],
        ['Expected volume changes:', transactions.expectExactly, params?.transactions?.expectExactly],
        !viewMode || transactions.expectExactly === 'Unknown'
          ? ['If unknown, explain:', transactions.expectExplain, params?.transactions?.expectExplain]
          : // eslint-disable-next-line
            ('' as any),
        ['Transaction activity matches sales?', transactions.matchTrans, params?.transactions?.matchTrans],
        ['Transaction Notes:', transactions.notes, params?.transactions?.notes]
      ].filter(Boolean)
    },
    {
      title: 'Covenants/Agreements',
      items: [
        [
          'Is the company complying with bank covenants/account agreements?',
          covenant.complying,
          params?.covenant?.complying
        ],
        ['Describe any areas of non-compliance:', covenant.nonComplianceDesc, params?.covenant?.nonComplianceDesc],
        ['Is the company transparent about its operations?', covenant.transparent, params?.covenant?.transparent]
      ]
    },
    {
      title: 'Site Visits',
      items: [
        ['Frequency:', siteVisit.freq, params?.siteVisit?.freq],
        ['Date of last visit:', siteVisit.lastDate, { ...params?.siteVisit?.lastDate }],
        !isShore && [
          'Is company complying with state and local cannabis regulations verified by bank?',
          siteVisit.complyingRegulations,
          params?.siteVisit?.complyingRegulations
        ],
        ['If violation found, was it rectified?', siteVisit.violationRectified, params?.siteVisit?.violationRectified],
        [
          isShore ? 'Were the company’s site audits acceptable?' : 'Company’s audit scores acceptable?',
          isShore ? siteVisit.scoresAcceptable : [siteVisit.scoresAcceptable, ' ', siteVisit.scoresAcceptableExactly],
          params?.siteVisit?.scoresAcceptable
        ],
        [
          'Based on site visits, is company at risk of being shut down?',
          siteVisit.shutdownRisk,
          params?.siteVisit?.shutdownRisk
        ],
        isShore && [
          'Did the company undergo a state regulatory audit during the review period?',
          siteVisit.siteVisitHasStateAuditAtReviewPeriod,
          params?.siteVisit?.siteVisitHasStateAuditAtReviewPeriod
        ],
        isShore && [
          'If yes, please include findings and date of audit:',
          siteVisit.siteVisitStateAuditNotes,
          params?.siteVisit?.siteVisitStateAuditNotes
        ]
      ].filter(Boolean)
    },
    {
      title: 'Ownership & Management',
      items: [
        !isShore && [
          'Has ownership of 5% or more of the company changed over the last year?',
          ownership.changes,
          params?.ownership?.changes
        ],
        !isShore && [
          'Company provided Beneficial Ownership Certification?',
          ownership.providedForms,
          params?.ownership?.providedForms
        ],
        !isShore && [
          'Have 5% or more ownership changes been approved by the commission?',
          ownership.changesApproved,
          params?.ownership?.changesApproved
        ],
        !isShore && [
          'Have OFAC/TLO checks been reviewed for new owners with >=5% interest?',
          ownership.ofacTloReviewed,
          params?.ownership?.ofacTloReviewed
        ],
        [
          'Have there been any negative news posts about the company or its owners?',
          ownership.badNews,
          params?.ownership?.badNews
        ],
        ['If yes, please provide application details:', ownership.badNewsDesc, params?.ownership?.badNewsDesc],
        isShore && [
          'Have there been any material changes to key personnel or executive management during the review period?',
          ownership.ownershipHasMaterialChangesInManagementAtReviewPeriod,
          params?.ownership?.ownershipHasMaterialChangesInManagementAtReviewPeriod
        ],
        isShore && [
          'If yes, provide details:',
          ownership.ownershipMaterialChangesInManagementNotes,
          params?.ownership?.ownershipMaterialChangesInManagementNotes
        ],
        !isShore && [
          'Negative news alerts cleared by BSA/Compliance?',
          ownership.badNewsCleared,
          params?.ownership?.badNewsCleared
        ],
        !isShore && [
          'If no, explain status:',
          ownership.badNewsClearedExplain,
          params?.ownership?.badNewsClearedExplain
        ]
      ].filter(Boolean)
    },
    {
      title: 'Recommendations',
      items: [
        [
          // eslint-disable-next-line max-len
          'Based on the above information, do you recommend that the bank continue to offer this customer banking services?',
          recom.recommended,
          params?.recom?.recommended
        ],
        [
          isShore
            ? 'Per this review, relationship has been operating:'
            : 'What status do you believe the customer has operated over the last year?',
          recom.status,
          params?.recom?.status
        ],
        isShore && ['Comments:', recom.recomComments, params?.recom?.recomComments],
        ['Recommended Site Visit Schedule:', recom.changeVisits, params?.recom?.changeVisits]
      ].filter(Boolean)
    }
  ].filter(Boolean)
}

export const annualReviewQuestionnaireQuestions = (data: AnnualReviewQuestionnaire, isShore: boolean) =>
  annualReviewQuestionnaireMapping(
    {
      financials: {
        profitabilityTrend: data.financials_profitability_trend,
        periodCollected: data.financials_period_collected,
        investorDebtholders: data.financials_investor_debtholders,
        depositsMatching: data.financials_deposits_matching,
        businessCondition: data.financials_business_condition,
        becomingFailing: data.financials_becoming_failing,
        currentConditionIsAcceptable: data.financials_current_condition_is_acceptable,
        isMaterialsRegardingIncome: data.financials_is_materials_regarding_income,
        materialsNotes: data.financials_materials_notes,
        acceptableNotes: data.financials_acceptable_notes
      },
      transactions: {
        type: [
          data.transactions_cash && 'Cash',
          data.transactions_atm && 'ATM',
          data.transactions_ach && 'ACH',
          data.transactions_check && 'Check Deposit'
        ]
          .filter(Boolean)
          .join(', '),
        volProjected: data.transactions_vol_projected,
        volProjectedExactly: data.transactions_vol_projected_exactly,
        expectExactly: data.transactions_expect_exactly,
        expectExplain: data.transactions_expect_explain,
        matchTrans: data.transactions_match_trans,
        notes: data.transactions_notes
      },
      covenant: {
        complying: data.covenant_complying,
        nonComplianceDesc: data.covenant_non_compliance_desc,
        transparent: data.covenant_transparent
      },
      siteVisit: {
        freq: data.site_visit_freq,
        lastDate: formatDateTime(data.site_visit_last_date),
        complyingRegulations: data.site_visit_complying_regulations,
        violationRectified: data.site_visit_violation_rectified,
        scoresAcceptable: data.site_visit_scores_acceptable,
        scoresAcceptableExactly: data.site_visit_scores_acceptable_exactly,
        shutdownRisk: data.site_visit_shutdown_risk,
        siteVisitHasStateAuditAtReviewPeriod: data.site_visit_has_state_audit_at_review_period,
        siteVisitStateAuditNotes: data.site_visit_state_audit_notes
      },
      ownership: {
        changes: data.ownership_changes,
        providedForms: data.ownership_provided_forms,
        changesApproved: data.ownership_changes_approved,
        ofacTloReviewed: data.ownership_ofac_tlo_reviewed,
        badNews: data.ownership_bad_news,
        badNewsDesc: data.ownership_bad_news_desc,
        badNewsCleared: data.ownership_bad_news_cleared,
        badNewsClearedExplain: data.ownership_bad_news_cleared_explain,
        ownershipHasMaterialChangesInManagementAtReviewPeriod:
          data.ownership_has_material_changes_in_management_at_review_period,
        ownershipMaterialChangesInManagementNotes: data.ownership_material_changes_in_management_notes
      },
      recom: {
        recommended: data.recom_recommended,
        status: data.recom_status,
        changeVisits: data.recom_change_visits,
        recomComments: data.recom_comments
      }
    },
    {},
    true,
    isShore
  )
