import React, { FC, memo, useMemo } from 'react'

import { useGetBankSettings } from 'commons/hooks/bank/settings'
import { Document } from 'commons/types/DTO/bank/companyDetails/document'
import { DocumentSubjectType, TableDataQa } from 'commons/types/enums'
import { DataSourceItem, Layer, Table } from 'ui/components'
import { HeaderButton } from 'ui/components/Buttons'
import { Link } from 'ui/components/Link'
import { PageSection } from 'ui/components/Page'
import { DocumentsPaths } from '~bank-documents/constants'

import { BankAccountDocumentsProps } from './BankAccountDocuments.types'
import { columns } from './columns'
import { Filter } from './components'
import { useComponentsEssence } from './hooks/useComponentsEssence'

export const BankAccountDocuments: FC<BankAccountDocumentsProps> = memo((props) => {
  const { webPage, documentEditCancelReturnUrl, onFilterBankAccountDetails } = props
  const { items: documents, total } = webPage.documents
  const { id: bankAccountId } = webPage

  const { data: settings } = useGetBankSettings()

  const firstAlert = settings?.bankDocumentFirstNotificationBeforeExpiration || 0
  const initialAlert = settings?.bankDocumentPreNotificationBeforeExpiration || 0
  const lastAlert = settings?.bankDocumentLastNotificationBeforeExpiration || 0

  const {
    loading,
    tableState,
    filterForm,
    changePagination,
    onSort,
    DeleteConfirmation,
    onDeleteDocument,
    clearFilter
  } = useComponentsEssence(onFilterBankAccountDetails, webPage)

  const mappedDocuments: DataSourceItem<any>[] = useMemo(
    () =>
      documents.map((el) => ({
        item: el
      })),
    [documents]
  )

  const memoizedColumns = useMemo(
    () => columns(firstAlert, initialAlert, lastAlert, onDeleteDocument, documentEditCancelReturnUrl),
    []
  )

  return (
    <PageSection
      title="Documents"
      anchor="documents"
      actions={
        <Link
          to={DocumentsPaths.NEW}
          search={{
            subjectType: DocumentSubjectType.BANK_ACCOUNT,
            bankAccountId: bankAccountId,
            subjectName: webPage.number,
            returnUrl: documentEditCancelReturnUrl
          }}
        >
          <HeaderButton size="middle">Add New</HeaderButton>
        </Link>
      }
    >
      <DeleteConfirmation />
      <Layer rounded shadowed stretch>
        <Filter
          form={filterForm}
          expanded={tableState.expanded}
          onToggle={tableState.onFilterToggle}
          webPage={webPage}
          onClear={clearFilter}
        />
        <Table<Document>
          dataQa={TableDataQa.BP_BANK_ACCOUNT_DOCUMENTS}
          columns={memoizedColumns}
          dataSource={mappedDocuments}
          loading={loading}
          pagination={{
            page: tableState.page,
            pageSize: tableState.size,
            onChange: changePagination,
            total: total
          }}
          sorting={{ order: tableState.order || [], onChange: (value) => onSort(value.direction, value.field) }}
        />
      </Layer>
    </PageSection>
  )
})
