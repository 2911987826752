import { AnyRoute, createRoute, lazyRouteComponent, redirect } from '@tanstack/react-router'
import { BankApplicationsRouterPaths } from 'commons/constants/routes'
import { ApplicationEditRoute } from 'onboarding-form'

import { BankOnboardingWrapper } from './BankOnboardingWrapper'
import { applicationDetailsLoader } from './routes/ApplicationDetails'
import { DocumentCreateRoute } from './routes/ApplicationDocumentCreate/route'
import { DocumentDetailsRoute } from './routes/ApplicationDocumentDetails/route'
import { DocumentEditRoute } from './routes/ApplicationDocumentEdit/route'
import { ListRoute } from './routes/ApplicationList/route'

export const routeOnboardingTree = (rootRoute: BPRootRoute) => {
  const OnboardingIndexRoute = createRoute({
    beforeLoad: async ({ context }) => {
      if (!context.accessibleRoutingFlags.onboardingRootAccess) {
        throw redirect({
          to: '/'
        })
      }
    },
    getParentRoute: () => rootRoute,
    // /onboarding/applications
    path: BankApplicationsRouterPaths.INDEX,
    component: BankOnboardingWrapper
  })

  const DetailsRoute = <T extends AnyRoute>(indexRoute: T) =>
    createRoute({
      getParentRoute: () => indexRoute,
      // /onboarding/applications/$applicationId
      path: BankApplicationsRouterPaths.DETAILS,
      loader: ({ params }) => applicationDetailsLoader({ params }),

      component: lazyRouteComponent(() => import('./routes/ApplicationDetails/ApplicationDetails'))
    })

  return OnboardingIndexRoute.addChildren([
    ApplicationEditRoute<typeof OnboardingIndexRoute>(OnboardingIndexRoute),
    DetailsRoute<typeof OnboardingIndexRoute>(OnboardingIndexRoute),
    ListRoute<typeof OnboardingIndexRoute>(OnboardingIndexRoute),
    DocumentCreateRoute<typeof OnboardingIndexRoute>(OnboardingIndexRoute),
    DocumentEditRoute<typeof OnboardingIndexRoute>(OnboardingIndexRoute),
    DocumentDetailsRoute<typeof OnboardingIndexRoute>(OnboardingIndexRoute)
  ])
}
