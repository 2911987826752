import React, { FocusEvent, forwardRef, memo, useCallback, useEffect, useState } from 'react'

import { BaseInput } from './BaseInput'
import { BaseMaskedInputProps } from './props'

const MASK_FILL_ERROR = 'Please, complete filling this field'

export const BaseMaskedInput = memo(
  forwardRef<HTMLInputElement, BaseMaskedInputProps>((props, ref) => {
    const { value, onChange, error, onBlur, mask, ...rest } = props

    const [internalValue, setInternalValue] = useState(
      value !== undefined && value !== null ? value.toString() : undefined
    )

    // sync value if it will change outside
    useEffect(() => {
      setInternalValue(value !== undefined && value !== null ? value.toString() : undefined)
    }, [value])

    // here we getting masked value and unmask it to send onwards
    // high level components doesn't know about masks
    // they must get clear value
    const handleChange = useCallback(
      (v: any, e?: string) => {
        if (mask) {
          mask.reset()
          mask.append(v, { input: true }, '')
          setInternalValue(mask.value)
          onChange(
            mask.unmaskedValue,
            error && !mask.isComplete && mask.unmaskedValue && mask.unmaskedValue !== '' ? MASK_FILL_ERROR : e
          )
        } else {
          onChange(v, e)
        }
      },
      [mask, error, onChange]
    )

    const handleBlur = useCallback(
      (event: FocusEvent<HTMLInputElement>) => {
        if (mask && !mask.isComplete && internalValue && internalValue !== '') {
          onChange(mask.unmaskedValue, MASK_FILL_ERROR)
        }
        if (onBlur) {
          onBlur(event)
        }
      },
      [mask, onBlur, onChange, internalValue]
    )

    // https://github.com/uNmAnNeR/imaskjs/issues/666
    if (mask) {
      mask.reset()
      mask.append(internalValue ?? '', { input: true }, '')
    }

    return (
      <BaseInput
        {...rest}
        placeholder={mask ? mask.value : undefined}
        onBlur={handleBlur}
        value={internalValue && mask ? mask.value : value ?? ''}
        onChange={handleChange}
        ref={ref}
        error={error}
      />
    )
  })
)
