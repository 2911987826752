import { AnyRoute, createRoute, lazyRouteComponent, redirect } from '@tanstack/react-router'
import { BANK_ALERTS } from 'commons/constants/routes'

import { BankAlertsWrapper } from './BankAlertsWrapper'

const AlertsRoute = <T extends AnyRoute>(indexRoute: T) =>
  createRoute({
    getParentRoute: () => indexRoute,
    path: `/`,
    component: lazyRouteComponent(() => import('./AlertsPage'))
  })

export const routeAlertsTree = (rootRoute: BPRootRoute) => {
  const AlertsIndexRoute = createRoute({
    beforeLoad: async ({ context }) => {
      if (!context.accessibleRoutingFlags.alertRootAccess) {
        throw redirect({
          to: '/'
        })
      }
    },
    getParentRoute: () => rootRoute,
    path: BANK_ALERTS,
    component: BankAlertsWrapper
  })

  return AlertsIndexRoute.addChildren([AlertsRoute<typeof AlertsIndexRoute>(AlertsIndexRoute)])
}
