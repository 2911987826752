import { UseWebPageProps, WebPage } from 'commons/types/DTO/bank'
import { Request } from 'commons/utils/request'
import { Keys, ReadHook, useStateManager } from 'state-manager'

export const useGetApplicationsWebPage: ReadHook<WebPage, UseWebPageProps> = ({
  applicationsGeneralStatus,
  applicationsModifiedSortDirection,
  applicationsPageNumber,
  applicationsPerPage,
  applicationsSearchQuery,
  applicationsCreatedByID,
  applicationsCurrentOwnerID
}) => {
  const sm = useStateManager()

  const { data, ...rest } = sm.use(
    Keys.BankApplication.ListByCriteria({
      applicationsGeneralStatus,
      applicationsModifiedSortDirection,
      applicationsPageNumber,
      applicationsPerPage,
      applicationsSearchQuery,
      applicationsCreatedByID,
      applicationsCurrentOwnerID
    }),
    () =>
      Request.post(Request.urls.bank.applicationListWebpages, {
        applicationsGeneralStatus,
        applicationsModifiedSortDirection,
        applicationsPageNumber,
        applicationsPerPage,
        applicationsSearchQuery,
        applicationsCreatedByID,
        applicationsCurrentOwnerID
      }),
    { keepPreviousData: true }
  )

  return {
    ...rest,
    data
  }
}
