import React, { FC, memo, useCallback, useEffect, useState } from 'react'

import { FormInputProps } from 'brief-form'
import styled from 'styled-components'
import { Icon, IconName, IconSize } from 'ui/components/Icon'

type Opts = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'> & {
  mode?: 'number' | 'integer'
  skipLocalState?: boolean
  validator?: (value: string | number | null) => string | undefined
  icon?: IconName
  iconSize?: IconSize
  iconColor?: string
  dataQa?: string
  'data-lpignore'?: boolean
}

export interface Props extends FormInputProps<string | number, Opts> {}

//region Styled

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const WrappedIcon = styled(Icon)<{ size: number }>`
  width: ${(p) => `${p.size}px`};
  height: ${(p) => `${p.size}px`};
  position: absolute;
  margin: 0 12px;
  z-index: 1;
`

const SCInput = styled.input<{
  error: boolean
  iconSize?: number
}>`
  position: relative;
  height: 54px;
  color: #fff;
  border: 1.5px solid ${(p) => (p.error ? p.theme.uiKit.colors.theme.negative.main : 'transparent')};
  border-radius: 6px;
  overflow: hidden;
  width: 100%;
  font-size: 13px;
  background-color: ${(p) =>
    p.error ? p.theme.uiKit.colors.theme.negative.transparent.opacity12 : 'rgba(255,255,255,0.22)'};
  padding: 8px;
  padding-left: ${(p) => (p.iconSize ? `calc(${p.iconSize}px + 24px)` : '8px')};
  margin: 0;
  outline: none;
  box-sizing: border-box;
  -moz-appearance: textfield !important;

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::placeholder {
    color: #fff;
  }

  :focus {
    background: ${(p) =>
      p.error ? p.theme.uiKit.colors.theme.negative.transparent.opacity12 : 'transparent'} !important;
    border-color: ${(p) => (p.error ? p.theme.uiKit.colors.theme.negative.main : '#fff')} !important;
  }

  :-webkit-autofill,
  :-webkit-autofill:hover {
    -webkit-box-shadow: ${(p) => `0 0 0 30px ${p.theme.uiKit.colors.theme.brand.lightest} inset`} !important;
    -webkit-text-fill-color: white !important;
    border-color: ${(p) => p.theme.uiKit.colors.theme.brand.lightest} !important;
    filter: none !important;
  }
  :-webkit-autofill:focus {
    -webkit-box-shadow: ${(p) => `0 0 0 30px ${p.theme.uiKit.colors.theme.brand.primary} inset`} !important;
    -webkit-text-fill-color: white !important;
    border-color: #fff !important;
    filter: none !important;
  }
`
// endregion

export const AuthInput: FC<Props> = memo((props) => {
  const {
    onChange,
    value,
    required,
    error,
    mode,
    skipLocalState,
    validator,
    icon,
    iconSize = icon ? IconSize.S : 0,
    iconColor,
    dataQa,
    ...rest
  } = props

  const [text, setText] = useState(value)

  useEffect(() => {
    setText(value)
  }, [value])

  const getError = useCallback(
    (value: string | number | null) => {
      if (required && (!value || !value.toString().trim())) {
        return 'Required'
      }
      if (validator) {
        return validator(value)
      }
      return undefined
    },
    [validator, required]
  )

  const onTextChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let value: number | string | null = event.target.value
      if (mode === 'integer') {
        value = parseInt(value, 10) || null
      } else if (mode === 'number') {
        value = parseFloat(event.target.value) || null
      }
      setText(value || '')
      if (skipLocalState) {
        onChange(value, getError(value))
      }
    },
    [onChange, mode, required]
  )

  const onKeyDown = useCallback(
    (event: any) => {
      if (event.key === 'Enter') {
        onChange(text, getError(text))
      }
    },
    [onChange, text, required]
  )

  const onBlur = useCallback(() => {
    onChange(text, getError(text))
  }, [onChange, text, required])

  return (
    <Container>
      {icon && <WrappedIcon name={icon} fill={iconColor} size={iconSize} />}
      <SCInput
        {...rest}
        data-qa={dataQa}
        required={required}
        error={!!error}
        onChange={onTextChange}
        value={text || ''}
        onKeyDown={onKeyDown}
        onBlur={skipLocalState ? undefined : onBlur}
        iconSize={iconSize}
      />
    </Container>
  )
})
