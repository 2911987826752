import { AnyRoute, createRoute, lazyRouteComponent, notFound } from '@tanstack/react-router'
import { NotFoundError } from 'commons/errors'
import * as z from 'zod'
import { getPeriodDetails } from '~bank-documents/service/periodDetails'

const sch = z.object({
  historyPage: z.number().int().positive().default(1),
  historySize: z.number().int().positive().default(10)
})

export const periodDetailsRoute = <T extends AnyRoute>(indexRoute: T) =>
  createRoute({
    getParentRoute: () => indexRoute,
    path: '$documentId/periods/$periodId',
    // for future versions on tanstack-router
    // params: {
    //   parse: (params) => ({
    //     documentId: z.number().int().parse(Number(params?.documentId)),
    //     periodId: z.number().int().parse(Number(params?.periodId))
    //   }),
    //   stringify: ({ documentId, periodId }) => ({ documentId: `${documentId}`, periodId: `${periodId}` })
    // },
    validateSearch: (search: Record<string, unknown>) => sch.parse(search),
    loaderDeps: (all) => ({ search: all.search }),
    beforeLoad: ({ search, params, ...all }) => {
      if ('periodId' in params) return { ...all, flatDeps: { id: Number(params.periodId), ...search } }
      else throw new NotFoundError()
    },
    loader: ({ context: { flatDeps } }) =>
      getPeriodDetails({
        id: flatDeps.id,
        historyPage: flatDeps.historyPage,
        historySize: flatDeps.historySize
      }),
    shouldReload: (p) => p.location.href !== p.route.path,
    component: lazyRouteComponent(() => import('./PeriodDetails')),
    onError: async () => {
      throw notFound()
    }
  })
