import styled from 'styled-components'

const CompanyBox = styled.div`
  padding: 32px 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 20px 0px;

  & > * {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    gap: 20px 0px;
  }
`

export const s = { CompanyBox }
