import React, { memo, useCallback } from 'react'

import { OtherActivity } from 'commons/types/DTO/bank/quarterlyReport/bankAccount/OtherActivity'
import { formatCurrency } from 'commons/utils'

import { Box, Columns, Label, Labels, TV, Table, TableWrap, Td, Th, Title, WrappedLayer } from './styles'

export const OtherBankActivityTable = memo(
  ({ currentQuarter, changesPriorQuarter, difference, priorQuarter }: OtherActivity) => {
    const PQ = useCallback((v: number) => {
      if (v === -1) {
        return 'N/A'
      }

      return v >= 0 ? <TV>{v.toFixed(2)}%</TV> : <TV $negative>({(-v).toFixed(2)})%</TV>
    }, [])

    return (
      <WrappedLayer rounded shadowed>
        <Box>
          <Title>OTHER BANKING ACTIVITY</Title>
          <Columns>
            <Labels>
              <Label>Current Quarter</Label>
              {priorQuarter && <Label>Prior Quarter</Label>}
              {difference && <Label>Difference</Label>}
              {changesPriorQuarter && (
                <Label>
                  <b>% Change</b>
                </Label>
              )}
            </Labels>
            <TableWrap>
              <Table>
                <thead>
                  <tr>
                    <Th rowSpan={2}>Total</Th>
                    <Th rowSpan={2}>Cash Withdrawals</Th>
                    <Th rowSpan={2}>Checks Debits</Th>
                    <Th rowSpan={2}>ACH Debits</Th>
                    <Th $bottomBorder colSpan={2}>
                      Debit Cards
                    </Th>
                    <Th $bottomBorder colSpan={2}>
                      Transfer
                    </Th>
                    <Th $bottomBorder colSpan={2}>
                      Wires
                    </Th>
                    <Th $bottomBorder colSpan={2}>
                      Sweeps
                    </Th>
                    <Th rowSpan={2}>Bank Fees</Th>
                    <Th rowSpan={2}>Money Orders Purchased</Th>
                    <Th rowSpan={2}>Cashiers Checks Purchased</Th>
                    <Th $bottomBorder colSpan={2}>
                      CB&T Credit Card
                    </Th>
                  </tr>
                  <tr>
                    <Th>Credits</Th>
                    <Th>Debits</Th>
                    <Th>Credits</Th>
                    <Th>Debits</Th>
                    <Th>Incoming</Th>
                    <Th>Outgoing</Th>
                    <Th>Incoming</Th>
                    <Th>Outgoing</Th>
                    <Th>Charges</Th>
                    <Th>Payments</Th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <Td>{formatCurrency(currentQuarter.total, false)}</Td>
                    <Td>{formatCurrency(currentQuarter.cashWithdrawals, false)}</Td>
                    <Td>{formatCurrency(currentQuarter.checksDebits, false)}</Td>
                    <Td>{formatCurrency(currentQuarter.aCHDebits, false)}</Td>
                    <Td>{formatCurrency(currentQuarter.debitCardsCredits, false)}</Td>
                    <Td>{formatCurrency(currentQuarter.debitCardsDebits, false)}</Td>
                    <Td>{formatCurrency(currentQuarter.transferCredits, false)}</Td>
                    <Td>{formatCurrency(currentQuarter.transferDebits, false)}</Td>
                    <Td>{formatCurrency(currentQuarter.wiresIncoming, false)}</Td>
                    <Td>{formatCurrency(currentQuarter.wiresOutgoing, false)}</Td>
                    <Td>{formatCurrency(currentQuarter.sweepsIncoming, false)}</Td>
                    <Td>{formatCurrency(currentQuarter.wiresOutgoing, false)}</Td>
                    <Td>{formatCurrency(currentQuarter.bankFees, false)}</Td>
                    <Td>{formatCurrency(currentQuarter.moneyOrdersPurchased, false)}</Td>
                    <Td>{formatCurrency(currentQuarter.cashiersChecksPurchased, false)}</Td>
                    <Td>{formatCurrency(currentQuarter.cBTCreditCardCharges, false)}</Td>
                    <Td>{formatCurrency(currentQuarter.cBTCreditCardPayments, false)}</Td>
                  </tr>
                  {priorQuarter && (
                    <tr>
                      <Td>{formatCurrency(priorQuarter.total, false)}</Td>
                      <Td>{formatCurrency(priorQuarter.cashWithdrawals, false)}</Td>
                      <Td>{formatCurrency(priorQuarter.checksDebits, false)}</Td>
                      <Td>{formatCurrency(priorQuarter.aCHDebits, false)}</Td>
                      <Td>{formatCurrency(priorQuarter.debitCardsCredits, false)}</Td>
                      <Td>{formatCurrency(priorQuarter.debitCardsDebits, false)}</Td>
                      <Td>{formatCurrency(priorQuarter.transferCredits, false)}</Td>
                      <Td>{formatCurrency(priorQuarter.transferDebits, false)}</Td>
                      <Td>{formatCurrency(priorQuarter.wiresIncoming, false)}</Td>
                      <Td>{formatCurrency(priorQuarter.wiresOutgoing, false)}</Td>
                      <Td>{formatCurrency(priorQuarter.sweepsIncoming, false)}</Td>
                      <Td>{formatCurrency(priorQuarter.wiresOutgoing, false)}</Td>
                      <Td>{formatCurrency(priorQuarter.bankFees, false)}</Td>
                      <Td>{formatCurrency(priorQuarter.moneyOrdersPurchased, false)}</Td>
                      <Td>{formatCurrency(priorQuarter.cashiersChecksPurchased, false)}</Td>
                      <Td>{formatCurrency(priorQuarter.cBTCreditCardCharges, false)}</Td>
                      <Td>{formatCurrency(priorQuarter.cBTCreditCardPayments, false)}</Td>
                    </tr>
                  )}
                  {difference && (
                    <tr>
                      <Td>{formatCurrency(difference.total, false)}</Td>
                      <Td>{formatCurrency(difference.cashWithdrawals, false)}</Td>
                      <Td>{formatCurrency(difference.checksDebits, false)}</Td>
                      <Td>{formatCurrency(difference.aCHDebits, false)}</Td>
                      <Td>{formatCurrency(difference.debitCardsCredits, false)}</Td>
                      <Td>{formatCurrency(difference.debitCardsDebits, false)}</Td>
                      <Td>{formatCurrency(difference.transferCredits, false)}</Td>
                      <Td>{formatCurrency(difference.transferDebits, false)}</Td>
                      <Td>{formatCurrency(difference.wiresIncoming, false)}</Td>
                      <Td>{formatCurrency(difference.wiresOutgoing, false)}</Td>
                      <Td>{formatCurrency(difference.sweepsIncoming, false)}</Td>
                      <Td>{formatCurrency(difference.wiresOutgoing, false)}</Td>
                      <Td>{formatCurrency(difference.bankFees, false)}</Td>
                      <Td>{formatCurrency(difference.moneyOrdersPurchased, false)}</Td>
                      <Td>{formatCurrency(difference.cashiersChecksPurchased, false)}</Td>
                      <Td>{formatCurrency(difference.cBTCreditCardCharges, false)}</Td>
                      <Td>{formatCurrency(difference.cBTCreditCardPayments, false)}</Td>
                    </tr>
                  )}
                  {changesPriorQuarter && (
                    <tr>
                      <Td $hl={changesPriorQuarter.total.alerted}>{PQ(changesPriorQuarter.total.percent)}</Td>
                      <Td $hl={changesPriorQuarter.cashWithdrawals.alerted}>
                        {PQ(changesPriorQuarter.cashWithdrawals.percent)}
                      </Td>
                      <Td $hl={changesPriorQuarter.checksDebits.alerted}>
                        {PQ(changesPriorQuarter.checksDebits.percent)}
                      </Td>
                      <Td $hl={changesPriorQuarter.aCHDebits.alerted}>{PQ(changesPriorQuarter.aCHDebits.percent)}</Td>
                      <Td $hl={changesPriorQuarter.debitCardsCredits.alerted}>
                        {PQ(changesPriorQuarter.debitCardsCredits.percent)}
                      </Td>
                      <Td $hl={changesPriorQuarter.debitCardsDebits.alerted}>
                        {PQ(changesPriorQuarter.debitCardsDebits.percent)}
                      </Td>
                      <Td $hl={changesPriorQuarter.transferCredits.alerted}>
                        {PQ(changesPriorQuarter.transferCredits.percent)}
                      </Td>
                      <Td $hl={changesPriorQuarter.transferDebits.alerted}>
                        {PQ(changesPriorQuarter.transferDebits.percent)}
                      </Td>
                      <Td $hl={changesPriorQuarter.wiresIncoming.alerted}>
                        {PQ(changesPriorQuarter.wiresIncoming.percent)}
                      </Td>
                      <Td $hl={changesPriorQuarter.wiresOutgoing.alerted}>
                        {PQ(changesPriorQuarter.wiresOutgoing.percent)}
                      </Td>
                      <Td $hl={changesPriorQuarter.sweepsIncoming.alerted}>
                        {PQ(changesPriorQuarter.sweepsIncoming.percent)}
                      </Td>
                      <Td $hl={changesPriorQuarter.sweepsOutgoing.alerted}>
                        {PQ(changesPriorQuarter.sweepsOutgoing.percent)}
                      </Td>
                      <Td $hl={changesPriorQuarter.bankFees.alerted}>{PQ(changesPriorQuarter.bankFees.percent)}</Td>
                      <Td $hl={changesPriorQuarter.moneyOrdersPurchased.alerted}>
                        {PQ(changesPriorQuarter.moneyOrdersPurchased.percent)}
                      </Td>
                      <Td $hl={changesPriorQuarter.cashiersChecksPurchased.alerted}>
                        {PQ(changesPriorQuarter.cashiersChecksPurchased.percent)}
                      </Td>
                      <Td $hl={changesPriorQuarter.cBTCreditCardCharges.alerted}>
                        {PQ(changesPriorQuarter.cBTCreditCardCharges.percent)}
                      </Td>
                      <Td $hl={changesPriorQuarter.cBTCreditCardPayments.alerted}>
                        {PQ(changesPriorQuarter.cBTCreditCardPayments.percent)}
                      </Td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </TableWrap>
          </Columns>
        </Box>
      </WrappedLayer>
    )
  }
)
