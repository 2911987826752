import styled from 'styled-components'

const TooltipBody = styled.p`
  margin: 0;
  overflow: hidden;
  textoverflow: ellipsis;
  fontsize: inherit;
  fontweight: inherit;
  lineheight: inherit;
`

export const s = { TooltipBody }
