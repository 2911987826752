import { COMPANIES_PATH } from 'commons/constants/routes'

import { LicenseInfoProps } from './LicenseInfoProps'

export const LicenseInfoDefaultProps: Partial<LicenseInfoProps> = {
  licenseId: 0,
  licenseName: '---',
  licenseType: '',
  companyName: '---',
  companyId: 0,
  companyDetailsPath: `${COMPANIES_PATH}/{companyId}`,
  licenseDetailsPath: '/licenses/{licenseId}'
}
