import React, { FC, memo, useCallback, useState } from 'react'

import { Form, useFormData } from 'brief-form'
import { ContactSelect, RadioGroup, Validators } from 'ui/components'
import { Button } from 'ui/components/Buttons'
import { Col, FormRow } from 'ui/themes/globalStyles'
import { CreateMetadataModeFormValue } from '~bank-contacts/types/forms'

import { CreateModeSelectionFormProps } from './CreateModeSelectionFormProps'

import { Footer, FormBody, NextButton, Question } from './styles'

export const CreateModeSelectionForm: FC<CreateModeSelectionFormProps> = memo((props) => {
  const { onSubmit, onCancel } = props
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { config, Field, validate } = useFormData<CreateMetadataModeFormValue>({
    initialValue: { mode: 'new', contact_id: null }
  })
  const originalOnChange = config.onChange

  config.onChange = useCallback(
    (v: CreateMetadataModeFormValue) => {
      originalOnChange(v, {})
    },
    [originalOnChange]
  )

  const submitWrapper = useCallback(async () => {
    if (validate({ updateFields: true }).valid) {
      setIsSubmitting(true)
      await onSubmit(config.value)
      setIsSubmitting(false)
    }
  }, [onSubmit, config.value, validate])

  return (
    <Form config={config}>
      <FormBody>
        <Question>How do you want to add a contact?</Question>
        <FormRow>
          <Col>
            <Field
              name="mode"
              label=""
              input={RadioGroup}
              inputProps={{
                name: 'mode',
                data: [
                  { label: 'Create New Contact', value: 'new' },
                  { label: 'Choose an Existing Contact', value: 'existing' }
                ],
                'data-qa': 'mode'
              }}
            />
          </Col>
        </FormRow>
        {config?.value?.mode === 'existing' && (
          <FormRow>
            <Col>
              <Field
                name="contact_id"
                label="Contact"
                input={ContactSelect}
                required
                validator={Validators.required.field}
                inputProps={{}}
              />
            </Col>
          </FormRow>
        )}
        <Footer>
          <NextButton face="positive" disabled={isSubmitting} onClick={submitWrapper}>
            Next
          </NextButton>
          <Button face="neutral" onClick={onCancel}>
            Cancel
          </Button>
        </Footer>
      </FormBody>
    </Form>
  )
})
