import React from 'react'

import { phoneTypeTypes } from 'commons/types/dictionaries'
import { FF, Select } from 'ui/components'

import { useSARFormContext } from '../../../../../../context'

const EMPTY_OPTION = { label: 'Not selected', value: '' }

type PhoneTypeProps = {
  index: number
}

export const PhoneType = ({ index }: PhoneTypeProps) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={`subjects_info[${index}].phone_numbers[0].phone_type` as 'subjects_info[0].phone_numbers[0].phone_type'}
      children={(field) => (
        <FF
          field={field}
          label="20. Type"
          input={(p) => <Select {...p} emptyOption={EMPTY_OPTION} data={phoneTypeTypes} />}
        />
      )}
    />
  )
}
