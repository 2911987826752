export interface DocumentsDueProps {
  documentsLastNotificationCount: number
  documentsFirstNotificationCount: number
  documentsPreNotificationCount: number
  documentsPastNotificationCount: number
  documentsIncompleteCount: number
  initialAlert: number
  firstAlert: number
  lastAlert: number
}
