import styled, { keyframes } from 'styled-components'

const scaling = keyframes`
  0%   {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.03, 1.03);
  }
  100% {
    transform: scale(1, 1);
  }
`

export const Picture = styled.picture`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  animation: ${scaling} 20s infinite;
`

export const Img = styled.img`
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
`
