import React from 'react'

import { FF, TextInput } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

export const DBA = ({ index }: { index: number }) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={`institution_activities_info[${index}].dba` as 'institution_activities_info[0].dba'}
      children={(field) => (
        <FF label="58. Alternate Name" field={field} input={(p) => <TextInput {...p} data-qa="58. Alternate Name" />} />
      )}
    />
  )
}
