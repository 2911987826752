import * as React from 'react'

import SC from 'styled-components'

import { PageSectionFace } from '../pageSectionFace'

interface Props {
  face?: PageSectionFace
  title?: React.ReactNode
  titleBefore?: React.ReactNode
  titleAfter?: React.ReactNode
  actions?: React.ReactNode
  first?: boolean
}

//region Styled

const Box = SC.header<{ face?: PageSectionFace; first?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: ${(p) => (p.face === PageSectionFace.THIRD ? '68px' : '72px')};
  margin: ${(p) => (p.face === PageSectionFace.THIRD ? '0 -50px 20px' : '0')};
  padding: ${(p) => (p.face === PageSectionFace.THIRD ? '0 50px' : '0')};
  background-color: ${(p) =>
    p.face === PageSectionFace.THIRD ? p.theme.uiKit.colors.global.grey.grey300 : 'transparent'};
  ${(p) => (p.face === PageSectionFace.THIRD && p.first ? 'margin-top: -20px;' : '')}
`

const Heading = SC.div`
  flex: 1;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
`

const Actions = SC.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
`

//endregion

export const Header = React.memo(({ title, titleBefore, titleAfter, face, actions, first }: Props) => {
  return (
    <Box face={face} first={first}>
      <Heading>
        {!!titleBefore && <div>{titleBefore}</div>}
        <h3 data-qa={title}>{title}</h3>
        {!!titleAfter && <div>{titleAfter}</div>}
      </Heading>
      {!!actions && <Actions>{actions}</Actions>}
    </Box>
  )
})
