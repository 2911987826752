import React from 'react'

import { CheckBox, FF } from 'ui/components'

import { useSARFormContext } from '../../../../../../../../context'

type AccountantProps = {
  index: number
}

export const Accountant = ({ index }: AccountantProps) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={
        `subjects_info[${index}].relationships_info[0].accountant` as 'subjects_info[0].relationships_info[0].accountant'
      }
      children={(field) => (
        <FF field={field} input={(p) => <CheckBox {...p} checkBoxLabel="b. Accountant" />} errorPosition="right" />
      )}
    />
  )
}
