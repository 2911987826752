import { PortalName } from 'commons/types/enums'
import { Request } from 'commons/utils/request'

export const logout = async ({ portalName }: { portalName: PortalName }) => {
  const requestUrl = {
    [PortalName.BP]: Request.urls.bank.authLogout,
    [PortalName.CP]: Request.urls.client.authLogout,
    [PortalName.BOP]: Request.urls.support.authLogout
  }

  return Request.post(requestUrl[portalName])
}

export const heartbeat = async ({ portalName }: { portalName: PortalName }) => {
  const requestUrl = {
    [PortalName.BP]: Request.urls.bank.heartbeat,
    [PortalName.CP]: Request.urls.client.heartbeat,
    [PortalName.BOP]: Request.urls.support.heartbeat
  }

  return Request.post<{ expires_at: string }>(requestUrl[portalName])
}

export const submitPassword = (p: PortalName, login: string, password: string): Promise<any> => {
  const requestUrl = {
    [PortalName.BP]: Request.urls.bank.authLogin,
    [PortalName.CP]: Request.urls.client.authLogin,
    [PortalName.BOP]: Request.urls.support.authLogin
  }

  return Request.post(requestUrl[p], { login, password })
}

export const resetPassword = (p: PortalName, login: string): Promise<any> => {
  const requestUrl = {
    [PortalName.BP]: Request.urls.bank.authPasswordReset,
    [PortalName.CP]: Request.urls.client.authPasswordReset,
    [PortalName.BOP]: Request.urls.support.authPasswordReset
  }

  return Request.post(requestUrl[p], { login })
}

export const setPassword = (p: PortalName, key: string, password: string): Promise<any> => {
  const requestUrl = {
    [PortalName.BP]: Request.urls.bank.authPassword,
    [PortalName.CP]: Request.urls.client.authPassword,
    [PortalName.BOP]: Request.urls.support.authPassword
  }

  return Request.post(requestUrl[p], { key, password })
}

export const setNewPassword = (p: PortalName, form: { old_password: string; password: string }): Promise<any> => {
  const requestUrl = {
    [PortalName.BP]: Request.urls.bank.profilePassword,
    [PortalName.CP]: Request.urls.client.profilePassword,
    [PortalName.BOP]: Request.urls.support.profilePassword
  }

  return Request.post(requestUrl[p], form)
}

export const authCheckLink = (p: PortalName, key: string) => {
  const requestUrl = {
    [PortalName.CP]: Request.urls.client.authCheckLink,
    [PortalName.BP]: Request.urls.bank.authCheckLink
  }

  return Request.post(requestUrl[p], { key })
}
