import { AnnualReviewStatuses } from 'commons/types/DTO/bank'

export type AnnualReviewFormValue = {
  last_ar_date: string
  financials_start_date: string
  financials_end_date: string
  status: AnnualReviewStatuses | string
  is_review_completed: boolean
  'questionnaire.licensing_name_prior'?: string
  'questionnaire.licensing_loc_changes'?: string
  'questionnaire.licensing_name_changes'?: string
  'questionnaire.licensing_loc_changes_mmcc'?: string
  'questionnaire.licensing_name_changes_mmcc'?: string
  'questionnaire.financials_profitability_trend'?: string
  'questionnaire.financials_period_collected'?: string
  'questionnaire.financials_investor_debtholders'?: string
  'questionnaire.financials_deposits_matching'?: string
  'questionnaire.financials_business_condition'?: string
  'questionnaire.financials_becoming_failing'?: string
  'questionnaire.financials_acceptable_notes'?: string
  'questionnaire.financials_current_condition_is_acceptable'?: string
  'questionnaire.financials_is_materials_regarding_income'?: string
  'questionnaire.financials_materials_notes'?: string
  'questionnaire.transactions_cash'?: boolean
  'questionnaire.transactions_atm'?: boolean
  'questionnaire.transactions_ach'?: boolean
  'questionnaire.transactions_check'?: boolean
  'questionnaire.transactions_vol_projected'?: string
  'questionnaire.transactions_vol_projected_exactly'?: string
  'questionnaire.transactions_expect_exactly'?: string
  'questionnaire.transactions_expect_explain'?: string
  'questionnaire.transactions_match_trans'?: string
  'questionnaire.transactions_notes'?: string
  'questionnaire.covenant_complying'?: string
  'questionnaire.covenant_non_compliance_desc'?: string
  'questionnaire.covenant_transparent'?: string
  'questionnaire.site_visit_freq'?: string
  'questionnaire.site_visit_last_date'?: string
  'questionnaire.site_visit_complying_regulations'?: string
  'questionnaire.site_visit_violation_rectified'?: string
  'questionnaire.site_visit_scores_acceptable'?: string
  'questionnaire.site_visit_scores_acceptable_exactly'?: string
  'questionnaire.site_visit_shutdown_risk'?: string
  'questionnaire.ownership_changes'?: string
  'questionnaire.ownership_provided_forms'?: string
  'questionnaire.ownership_changes_approved'?: string
  'questionnaire.ownership_ofac_tlo_reviewed'?: string
  'questionnaire.ownership_bad_news'?: string
  'questionnaire.ownership_bad_news_desc'?: string
  'questionnaire.ownership_bad_news_cleared'?: string
  'questionnaire.ownership_bad_news_cleared_explain'?: string
  'questionnaire.recom_recommended'?: string
  'questionnaire.recom_status'?: string
  'questionnaire.recom_change_visits'?: string

  // for cbt
  'questionnaire.has_more_revenue'?: string
  'questionnaire.revenue_from_mj_only'?: string
  'questionnaire.information_for_METRC_dump'?: string
  'questionnaire.comparing_sics_and_bank_deposits_red_flag'?: string
  'questionnaire.comparing_sics_and_bank_deposits_variance'?: string
  'questionnaire.comparing_sics_and_bank_deposits_comments'?: string
  'questionnaire.has_more_cash_deposits'?: string
  'questionnaire.compare_cash_amount_dump'?: string
  'questionnaire.comparing_cash_deposits_and_tax_returns_red_flag'?: string
  'questionnaire.comparing_cash_deposits_and_tax_returns_variance'?: string
  'questionnaire.comparing_cash_deposits_and_tax_returns_comments'?: string
  'questionnaire.inconsistent_financial_statement'?: string
  'questionnaire.validate_contributions_dump'?: string
  'questionnaire.financial_statement_validation_red_flag'?: string
  'questionnaire.financial_statement_validation_variance'?: string
  'questionnaire.financial_statement_validation_comments'?: string
  'questionnaire.disguising_mrb_activity'?: string
  'questionnaire.inconsistent_mrb_purporting'?: string
  'questionnaire.inconsistent_mrb_purporting_due_diligence_completed'?: string
  'questionnaire.owner_outside_state'?: string
  'questionnaire.owner_outside_state_approved'?: string
  'questionnaire.adverse_media_required'?: string
  'questionnaire.adverse_media_reviewed'?: string
  'questionnaire.mrb_on_federal_property'?: string
  'questionnaire.mrb_school_proximity_consistent'?: string
  'questionnaire.mrb_school_proximity_due_diligence_completed'?: string
  'questionnaire.site_visit_has_state_audit_at_review_period': string
  'questionnaire.site_visit_state_audit_notes': string
  'questionnaire.ownership_has_material_changes_in_management_at_review_period': string
  'questionnaire.ownership_material_changes_in_management_notes': string
  'questionnaire.recom_comments': string
}
