import styled from 'styled-components'
import { Button } from 'ui/components/Buttons'
import { Icon } from 'ui/components/Icon'

const Box = styled.footer`
  z-index: 11;
  background: #fff;
  height: 66px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 48px;
  border-top: 1px solid ${(p) => p.theme.uiKit.colors.global.grey.grey300};
`

const Right = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

const IconButton = styled(Button)`
  path {
    transition: fill 0.15s;
  }
  &:hover {
    path {
      fill: #fff;
    }
  }
`

const StyledIcon = styled(Icon)`
  vertical-align: middle;
  margin-right: 10px;
`

export const s = { Box, Right, IconButton, StyledIcon }
