import styled from 'styled-components'
import { Link } from 'ui/components/Link'

export const RelationshipDetailColumn = styled.div`
  flex: 1;
  margin-right: 30px;
`

export const RelationshipDetailLink = styled(Link)`
  flex: 1;
  margin-right: 30px;
`
