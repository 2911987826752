import { FormInputProps } from 'brief-form'

export interface ToggleOpts {
  disabled?: boolean
  label?: string | React.ReactNode
  /** Shows dynamic "On/Off" label */
  showOnOffLabel?: boolean
  'data-qa'?: string
  noMarginRight?: boolean
}

export interface ToggleProps extends FormInputProps<boolean, ToggleOpts> {}
