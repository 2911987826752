import { DocumentListFilters } from '../../components/DocumentListTable/types'

export type FlatDocumentFilterParams = {
  page: number // 2
  size: number // 10

  sortBy: string // name
  sortDesc: boolean // false

  alerts: string
  onlyMyCompanies: boolean // false
  internal: boolean
  bankAccountId: string
  companyId: string
  contactId: string
  frequency: string
  licenseId: string
  search: string // ''
  subjectType: string
}

const parseParamsToFilters = ({
  search,
  query,
  assignedCompaniesIDs
}: {
  search: string
  query: FlatDocumentFilterParams
  assignedCompaniesIDs: Array<number>
}): DocumentListFilters => ({
  search, // initValue in useState(query.search || '') in useDocumentList
  filter: {
    ...(query.alerts ? { alerts: query.alerts } : {}),
    ...(query.onlyMyCompanies ? { onlyMyCompanies: query.onlyMyCompanies } : {}),
    ...(query.internal ? { internal: query.internal } : {}),
    ...(query.bankAccountId ? { bankAccountId: parseInt(query.bankAccountId, 10) } : {}),
    ...(query.companyId ? { companyId: parseInt(query.companyId, 10) } : {}),
    ...(query.contactId ? { contactId: parseInt(query.contactId, 10) } : {}),
    ...(query.frequency ? { frequency: query.frequency } : {}),
    ...(query.licenseId ? { licenseId: parseInt(query.licenseId, 10) } : {}),

    ...(query.subjectType ? { subjectType: query.subjectType } : {}),

    ...(query.page ? { page: query.page } : {}),
    ...(query.size ? { size: query.size } : {}),

    ...(query.sortBy ? { sortBy: query.sortBy } : {}),
    ...(query.sortDesc ? { sortDesc: query.sortDesc } : {}),

    ...(assignedCompaniesIDs.length
      ? // hack: in case of onlyMyCompanies: true, CompanySelect return companyId as array
        { onlyMyCompanies: true, companyId: assignedCompaniesIDs as unknown as number }
      : {})
  }
})

export default parseParamsToFilters
