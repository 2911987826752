import { AnyRoute, createRoute, lazyRouteComponent } from '@tanstack/react-router'

export const CreateRoute = <T extends AnyRoute>(indexRoute: T) =>
  createRoute({
    getParentRoute: () => indexRoute,
    path: `add`,
    component: lazyRouteComponent(() => import('./CreateLicense')),
    validateSearch: (search: Record<string, unknown>) => {
      const companyId = Number(search.companyId)
      return {
        returnUrl: (search.returnUrl as string) || '',
        companyId: Number.isNaN(companyId) ? 0 : companyId
      }
    }
  })
