import styled from 'styled-components'

export const Header = styled.div`
  background-color: #f5f5f5;
  padding: 14px 35px;
  margin: 0 -35px;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  color: #484848;
`
