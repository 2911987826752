import { useMutation } from 'react-query'

import { getRouteApi, useNavigate } from '@tanstack/react-router'
import { PortalName } from 'commons/types/enums'
import { resetPassword } from '~auth/service/user'

import { useLogout } from '../user/useLogout'

export const useResetPassword = (p: PortalName) => {
  const { logout } = useLogout()
  const navigate = useNavigate()
  const returnUrl = getRouteApi('/auth/forgot-password').useSearch({ select: (s) => s?.returnUrl })

  return useMutation((data: { login: string }) => resetPassword(p, data.login), {
    onError: (error: Error) => {
      throw error
    },
    onSuccess: async () => {
      await logout({ returnUrl })
      navigate({ to: '/auth/login' })
    }
  })
}
