import { AnyRoute, createRoute, lazyRouteComponent } from '@tanstack/react-router'

export const DetailsRoute = <T extends AnyRoute>(indexRoute: T) =>
  createRoute({
    getParentRoute: () => indexRoute,
    path: `$id`,
    component: lazyRouteComponent(() => import('./Details')),
    validateSearch: (search: Record<string, string>) => {
      return {
        company_id: (search.company_id as string) || '',
        status: (search.status as string) || '',
        trans_start_date: (search.trans_start_date as string) || '',
        trans_end_date: (search.trans_end_date as string) || ''
      }
    }
  })
