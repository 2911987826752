import React, { FC, memo } from 'react'

import { Request } from 'commons/utils/request'
import { Page, PageFace, Panel, PanelFace, useTableState } from 'ui/components'

import { ExportCSVButton, ReportBreadcrumbs } from '../../components'
import { Filter } from './components/Filter'
import { Table } from './components/Table'

const DEFAULT_FILTER = {
  date_start: new Date().toJSON(),
  date_end: new Date().toJSON()
}

const EmailHistoryReport: FC = memo(() => {
  const tableState = useTableState({
    expanded: true,
    filter: DEFAULT_FILTER
  })

  const onClear = () => {
    tableState.filterForm.set({ reset: true })
  }

  return (
    <Page
      face={PageFace.PRIMARY}
      title={<ReportBreadcrumbs title="Email History Report" />}
      actions={
        <ExportCSVButton
          reportName="Email History"
          url={Request.urls.bank.portalReportEmailHistoryCSV}
          parametersForm={{
            date_start: tableState.filterForm.config.value.date_start,
            date_end: tableState.filterForm.config.value.date_end
          }}
          method="put"
        />
      }
    >
      <Panel rounded shadowed collapsible={false} face={PanelFace.SECONDARY}>
        <Filter
          isExpanded={tableState.expanded}
          onToggle={tableState.onFilterToggle}
          onClear={onClear}
          filterForm={tableState.filterForm}
        />
        <Table tableState={tableState} />
      </Panel>
    </Page>
  )
})

export default EmailHistoryReport
