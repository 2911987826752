import styled from 'styled-components'
import { Hr } from 'ui/components'
import { IconButton } from 'ui/components/Buttons'

const HrWithBottomMargin = styled(Hr)`
  margin-bottom: 21px;
`

const Row = styled.div`
  display: flex;

  > * {
    width: 400px;

    &:first-child {
      margin-right: 100px;
    }
  }
`

const HistoryButton = styled(IconButton)`
  font-weight: bold;
  color: #000;
  padding: 0;
  width: 150px;

  &:hover {
    color: #000;
  }
`

export const s = { HrWithBottomMargin, Row, HistoryButton }
