import React, { useCallback, useState } from 'react'

import { Button } from 'ui/components/Buttons'

import { RevertToDialog } from '../RevertToDialog'
import { RevertToRFIPendingProps } from './RevertToRFIPendingProps'

export const RevertToRFIPending = ({ application }: RevertToRFIPendingProps) => {
  const [showModal, setShowModal] = useState(false)

  const onHideModal = useCallback(() => {
    setShowModal(false)
  }, [])

  const onShowModal = useCallback(() => {
    setShowModal(true)
  }, [])

  return (
    <>
      {showModal && <RevertToDialog application={application} onCloseModal={onHideModal} status="rfi_pending" />}
      <Button face="neutral" onClick={onShowModal}>
        Revert to RFI Pending
      </Button>
    </>
  )
}
