import React, { ErrorInfo, PureComponent, ReactNode } from 'react'

import { NotFoundError } from 'commons/errors'
import { Logger } from 'commons/utils/logger'

interface State {
  hasError: boolean
}

interface Props {
  fallback?: ReactNode
  children: ReactNode
}

export class NotFoundErrorBoundary extends PureComponent<Props> {
  state: State = {
    hasError: false
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    this.setState({ hasError: true })
    if (error instanceof NotFoundError) {
      Logger.getInstance().warning(error.message, info)
    } else {
      throw error
    }
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback || <h1>Not Found</h1>
    }
    return this.props.children
  }
}
