import { ReactNode } from 'react'

import { PageFace } from './pageFace'

export interface PageProps {
  actions?: ReactNode
  children?: ReactNode
  face?: PageFace.PRIMARY | PageFace.SECONDARY | PageFace.DISABLED
  footer?: ReactNode
  breadCrumbs?: ReactNode
  subTitle?: ReactNode
  title?: ReactNode
  isPending?: boolean
  isNotFound?: boolean
  noPadding?: boolean
}

export const defaultProps: Partial<PageProps> = {
  face: PageFace.PRIMARY
}
