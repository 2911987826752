import { AnnualReview } from 'commons/types/DTO/bank/annualReview'
import { Company } from 'commons/types/DTO/bank/company'

import { AnnualReviewFormValue } from './AnnualReviewFormValue'

export interface AnnualReviewEditFormProps {
  id: number
  companyId: number
  isPending: boolean
  annualReview: AnnualReview
  onSubmit: (formValue: AnnualReviewFormValue) => void
  isNotFound: boolean
  company: Company
}
