import { OnboardingFormState } from '../../../types/state'
import { getEmptyCompany } from './emptyEntity/getEmptyCompany'

export const getStateAfterCompanyAdded = ({ currentState }: { currentState: OnboardingFormState }) => {
  const newCompany = getEmptyCompany()
  const newCompaniesFormValues = [...currentState.formValue.companiesFormValues, newCompany]
  const newCompaniesDiff = [...currentState.companiesDiff, newCompany]
  return {
    companiesDiff: newCompaniesDiff,
    formValue: {
      ...currentState.formValue,
      companiesFormValues: newCompaniesFormValues
    }
  }
}
