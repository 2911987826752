import { RoleInformationFormData } from '../../../types/forms'
import { OnboardingFormState } from '../../../types/state'
import { getSelectedCompany } from './getSelectedCompany'
import { setNewCompanies } from './setNewCompanies'
import { setNewContacts } from './setNewContacts'

export const roleInformationToState = ({
  companyIndex,
  contactIndex,
  currentState,
  formData
}: {
  companyIndex: number
  contactIndex: number
  currentState: OnboardingFormState
  formData: RoleInformationFormData
}) => {
  const oldCompany = getSelectedCompany({ companyIndex, currentState })

  // show changes contacts
  const newContacts = setNewContacts({
    contactIndex,
    newContact: { ...oldCompany.contacts[contactIndex], roleInformation: { ...formData } },
    oldCompany
  })

  // change ref in list
  const newCompaniesFormValues = setNewCompanies({
    companyIndex,
    oldCompanies: currentState.formValue.companiesFormValues,
    newCompany: { ...oldCompany, contacts: newContacts }
  })

  return {
    formValue: {
      ...currentState.formValue,
      companiesFormValues: newCompaniesFormValues
    }
  }
}
