import { AnyRoute, createRoute, lazyRouteComponent } from '@tanstack/react-router'
import { HistoryAuditListFilter } from 'commons/types/DTO/bank/historyAudit'

export const historyListRoute = <T extends AnyRoute>(indexRoute: T) =>
  createRoute({
    getParentRoute: () => indexRoute,
    path: '/',
    validateSearch: (search) => new HistoryAuditListFilter(search),
    component: lazyRouteComponent(() => import('./HistoryAuditList'))
  })
