import React from 'react'

import { UploadedFile } from 'commons/types/DTO/bank/reportWebPage'
import { Request } from 'commons/utils/request'
import { TableButton } from 'ui/components/Buttons'
import { DateTime } from 'ui/components/DateTime'
import { DownloadLink } from 'ui/components/DownloadLink'
import { TableColumn, TableColumnAlign } from 'ui/components/Table'
import { Toast } from 'ui/components/Toast'

const handleError = (message: string) => Toast.error('Error', message)
const baseUrl = Request.urls.bank.reportFileDownload

export const columns = (onDelete: (item: any) => void) => (): TableColumn<UploadedFile>[] =>
  [
    {
      title: 'File Name',
      align: TableColumnAlign.LEFT,
      render: ({ fileName, id }) => (
        <DownloadLink
          direct
          parametersForm={{ reportFileId: id, attachment: true }}
          baseUrl={baseUrl}
          name={fileName}
          handleError={handleError}
          method="put"
        >
          {fileName}
        </DownloadLink>
      )
    },
    {
      title: 'Uploaded Date',
      align: TableColumnAlign.LEFT,
      render: ({ uploadedDate }) => <DateTime date={uploadedDate} withTime />
    },
    {
      title: 'Action(s)',
      align: TableColumnAlign.RIGHT,
      render: ({ id, enableDelete }) => (
        <>
          {enableDelete && (
            <TableButton face="neutral" onClick={() => onDelete(id)}>
              Delete
            </TableButton>
          )}
        </>
      )
    }
  ]
