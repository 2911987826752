import { BsaSar } from 'commons/types/DTO/bank/bsa'

export const emptySAR: BsaSar = {
  id: 0,
  filing_name: null,
  filing_type_continuing: false,
  filing_type_correct_amend: false,
  filing_type_initial: false,
  filing_type_joint: false,
  prior_report_bsa_id: null,
  notes_to_fincen: null,
  fincen_warnings: null,
  company_id: 0,
  custom: true,
  trans_start_date: null,
  trans_end_date: null,
  status: '',
  notes: '',
  attention: '',
  filed_date: null,
  transactions_info: {
    amount_unknown: false,
    no_amount_involved: false,
    current_amount: 0,
    current_cumulative_amount: 0,
    by_account: [
      {
        account_number: '',
        total_in: 0,
        cash_in: 0,
        check_in: 0,
        ach_in: 0,
        transfer_in: 0,
        total_out: 0,
        check_out: 0,
        ach_out: 0,
        debit_card_out: 0,
        transfer_out: 0,
        wires_out: 0,
        returned_checks_out: 0,
        total_bank_fees: 0,
        withdrawals_out: 0,
        cash_out: 0,
        total: 0,
        wires_in: 0
      }
    ],
    total: {
      current: 0,
      previous_cumulative: 0,
      current_cumulative: 0
    },
    structuring: {
      a: false,
      b: false,
      c: false,
      d: false,
      e: false,
      z: false
    },
    structuring_other: '',
    terrorist_financing: {
      a: false,
      z: false
    },
    terrorist_financing_other: '',
    fraud: {
      a: false,
      b: false,
      c: false,
      d: false,
      e: false,
      f: false,
      g: false,
      h: false,
      i: false,
      j: false,
      k: false,
      l: false,
      m: false,
      z: false
    },
    fraud_other: '',
    gaming: {
      a: false,
      b: false,
      c: false,
      d: false,
      z: false
    },
    gaming_other: '',
    laundering: {
      a: false,
      b: false,
      c: false,
      d: false,
      e: false,
      f: false,
      g: false,
      h: false,
      i: false,
      j: false,
      k: false,
      l: false,
      m: false,
      z: false
    },
    laundering_other: '',
    identification: {
      a: false,
      b: false,
      c: false,
      d: false,
      e: false,
      f: false,
      z: false
    },
    identification_other: '',
    other_activities: {
      a: false,
      b: false,
      c: false,
      d: false,
      e: false,
      f: false,
      g: false,
      h: false,
      i: false,
      j: false,
      k: false,
      l: false,
      m: false,
      n: false,
      o: false,
      p: false,
      q: false,
      r: false,
      s: false,
      z: false
    },
    other_activities_other: '',
    insurance: {
      a: false,
      b: false,
      c: false,
      d: false,
      e: false,
      f: false,
      z: false
    },
    insurance_other: '',
    securities: {
      a: false,
      b: false,
      c: false,
      d: false,
      e: false,
      z: false
    },
    securities_other: '',
    mortgage: {
      a: false,
      b: false,
      c: false,
      d: false,
      e: false,
      z: false
    },
    mortgage_other: '',
    cyber: {
      a: false,
      b: false,
      z: false
    },
    cyber_other: '',
    product_types: {
      a: false,
      b: false,
      c: false,
      d: false,
      e: false,
      f: false,
      g: false,
      h: false,
      i: false,
      j: false,
      k: false,
      l: false,
      m: false,
      n: false,
      o: false,
      p: false,
      q: false,
      r: false,
      s: false,
      t: false,
      z: false
    },
    product_types_other: '',
    payment_mechanisms: {
      a: false,
      b: false,
      c: false,
      d: false,
      e: false,
      f: false,
      g: false,
      h: false,
      i: false,
      z: false
    },
    payment_mechanisms_other: '',
    ip_addresses: [
      {
        ip: null,
        date: null,
        time: null
      }
    ],
    cyber_event_indicators: [
      {
        date: null,
        time: null,
        type: null,
        type_other: null,
        value: null
      }
    ],
    commodity_types: [{ value: null }],
    product_instrument_desc: [{ value: null }],
    markets_traded: [{ value: null }],
    cusip_numbers: [{ value: null }]
  },
  institution_contact_info: {
    type: '',
    type_other: null,
    federal_regulator: '',
    filer_name: '',
    tin: '',
    tin_type: '',
    id_type: '',
    id_number: '',
    address: '',
    city: '',
    state: '',
    zip_code: '',
    country: '',
    internal_control_number: '',
    contact_office: '',
    phone: '',
    phone_ext: '',
    dba: null,
    le_contact_agency: null,
    le_contact_name: null,
    le_contact_phone: null,
    le_contact_phone_ext: null,
    le_contact_date: null
  },
  institution_activities_info: [
    {
      type: '',
      type_other: null,
      federal_regulator: '',
      id_type: '',
      id_number: '',
      role_in_transaction: '',
      legal_name: '',
      tin: '',
      tin_type: '',
      address: '',
      city: '',
      state: '',
      zip_code: '',
      country: '',
      internal_control_number: '',
      dba: null,
      loss_to_fin_institution: null,
      no_branch_involved: false,
      institution_activity_branches_info: [
        {
          role_in_transaction: '',
          id_number: '',
          address: '',
          city: '',
          state: '',
          zip_code: '',
          country: ''
        }
      ]
    }
  ],
  subjects_info: [
    {
      is_entity: false,
      is_all_info_unavailable: false,
      suffix: null,
      dba: null,
      entity_name: '',
      last_name: '',
      first_name: '',
      middle_name: '',
      gender: '',
      birthdate: null,
      alternate_names: [
        {
          value: ''
        }
      ],
      business_type: '',
      naics_code: '',
      tin: '',
      tin_type: '',
      phone_numbers: [
        {
          phone: '',
          phone_type: '',
          phone_ext: null
        }
      ],
      emails: [
        {
          value: ''
        }
      ],
      websites: [
        {
          value: ''
        }
      ],
      corroborative_statement_to_filer: '',
      role_in_activity: '',
      address_info: [
        {
          address: '',
          city: '',
          state: '',
          postal_code: '',
          country: ''
        }
      ],
      id_info: [
        {
          id_form: '',
          id_form_other: '',
          id_number: '',
          id_country: '',
          id_state: ''
        }
      ],
      relationships_info: [
        {
          tin: '',
          accountant: false,
          agent: false,
          appraiser: false,
          attorney: false,
          borrower: false,
          customer: false,
          director: false,
          employee: false,
          no_relationship: false,
          officer: false,
          owner: false,
          other: false,
          other_desc: '',
          relationship_status: '',
          action_date: null
        }
      ],
      no_known_accounts_involved: false,
      institution_info: [
        {
          tin: '',
          account_numbers: [
            {
              number: '',
              closed: false
            }
          ]
        }
      ]
    }
  ],
  narrative: '',
  created_at: null,
  updated_at: null,
  deleted_at: null
}
