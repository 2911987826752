import { useMutation } from 'react-query'

import { handlerServerError } from 'errors'
import { WriteHook, useMutationManager } from 'state-manager'
import { deleteQR } from '~bank-quarterly-report/service/qr'

import * as Service from '../../service'

export const useDeleteQR: WriteHook<number, void> = (id) => useMutationManager<number, void>(deleteQR, id)

export const useDeleteOnWebpage = (refreshWebpage: () => void) =>
  useMutation((id: number) => Service.QR.deleteQR(id), {
    onError: (error: Error) => handlerServerError(error),
    onSuccess: async () => {
      refreshWebpage()
    }
  })
