/* eslint-disable camelcase */

export type BankAccountDeprecated = {
  id: number
  company_id: number
  number: string
  notes: string
  created_at: Date
  updated_at: Date
  licensed: boolean
  license_ids: number[]
  opening_date: string
  internal_transfers_type: number
}
