import styled, { createGlobalStyle } from 'styled-components'

import { CSS } from '../helpers/cssHelpers'

export const DialogCol = styled.div`
  width: 536px;
`

export const Col = styled.div`
  width: 320px;
`

export const Col2x = styled.div`
  width: 720px;
`

export const FormRow = styled.div`
  display: flex;
  align-self: stretch;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }

  ${Col} {
    &:first-child {
      margin-right: 80px;
    }
  }
`

export const Row = styled.div`
  display: flex;

  ${Col} {
    &:first-child {
      margin-right: 80px;
    }
  }
`

export const AppContainer = styled.div`
  height: 100%;
  max-width: 1920px;
`

export const UIGlobalStyles = createGlobalStyle`
  html,
  body {
    font-family: ${(p) => p.theme.uiKit.fontFamilies.brandFont}, sans-serif;
    margin: 0;
    background-color: ${(p) => p.theme.uiKit.colors.global.grey.grey100};
    
    * {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
    }
  }

  body {
    font-size: ${(p) => p.theme.uiKit.typography.bodyM.regular.fontSize};
  }

  h1 {
    ${(p) => CSS.populateFont(p.theme.uiKit.typography.h1.regular)}
  }

  h2 {
    ${(p) => CSS.populateFont(p.theme.uiKit.typography.h2.regular)}

    strong, b {
      ${(p) => CSS.populateFont(p.theme.uiKit.typography.h2.semiBold)}
    }
  }

  h3 {
    ${(p) => CSS.populateFont(p.theme.uiKit.typography.h3.regular)}
  }

  h4 {
    ${(p) => CSS.populateFont(p.theme.uiKit.typography.h4.regular)}

    strong, b {
      ${(p) => CSS.populateFont(p.theme.uiKit.typography.h4.semiBold)}
    }
  }

  h5 {
    ${(p) => CSS.populateFont(p.theme.uiKit.typography.h5.regular)}

    strong, b {
      ${(p) => CSS.populateFont(p.theme.uiKit.typography.h5.semiBold)}
    }
  }

  p {
    ${(p) => CSS.populateFont(p.theme.uiKit.typography.bodyM.regular)}

    strong, b {
      ${(p) => CSS.populateFont(p.theme.uiKit.typography.bodyM.semiBold)}
    }

    i, em {
      ${(p) => CSS.populateFont(p.theme.uiKit.typography.bodyM.regularItalic)}
    }

    u {
      ${(p) => CSS.populateFont(p.theme.uiKit.typography.bodyM.underline)}
    }

    a {
      ${(p) => CSS.populateFont(p.theme.uiKit.typography.bodyM.underline)}
    }
  }

  /*
  body {
    overflow: auto;
  }
*/

  #root {
    height: 100%;
    background-color: ${(p) => p.theme.uiKit.colors.global.grey.grey300};
  }
  
  body.using-mouse :focus {
    outline: none;
  }

  body.using-mouse :focus-within {
    outline: none;
  }

  .Toastify__toast-container--bottom-right {
    bottom: 5.5em !important;
    right: 2em !important;
  }

  .fc-popover {
    position: absolute;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
  }

  /*
  .title {
    font-size: 42px;
    font-weight: bold;
    line-height: 1;
    letter-spacing: 1px;
    color: #fff;
    text-transform: uppercase;
    margin: 0 0 38px 0;
  }
  */
  
  .fc .fc-daygrid-day.fc-day-today {
    box-shadow: inset 0 2px 0 #77e0c8;
    background-color: initial;
  }
 
  .fc .fc-daygrid-day-number {
    color: #000;
    text-decoration: none;
  }

  .fc .fc-more-popover .fc-popover-body {
    max-width: 500px;
  }

  .fc .fc-scroller-liquid {
    height: auto;
  }

  .fc-h-event .fc-event-title {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .entity-history-stars-field {
    cursor: pointer;
    text-decoration: underline;
  }
  .entity-history-stars-hidden-value {
    display: none;
  }
  
  .entity-history-stars-hidden-checkbox:checked ~ .entity-history-stars-hidden-value {
    display: inline;
  }
  
  .entity-history-stars-hidden-checkbox:checked ~ .entity-history-stars-hidden-stars {
    display: none;
  }

  article {
    background: #fff; // here we overwriting global grey background setted in #root tag
    h1, h2, h3, h4, h5, h6 {
      margin: 0.67em 0;
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    p {
      margin: 1em 0;
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    hr {
      margin-top: 25px;
      margin-bottom: 25px;
    }
  }
`

export const Title = styled.h1.attrs({ 'data-qa': 'Welcome to Orbit' })`
  font-size: 42px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: 1px;
  color: #fff;
  text-transform: uppercase;
  margin: 0 0 38px 0;
`

export * from '../components/InputV2/styles/InputGlobalStyles'
