import { DocumentListV2RequestPayload } from 'bank-documents/src/types/documentListV2'
import { FilterCompanyContactsWebPage } from 'commons/service/bank/companyDetails'
import { RelationshipsReportFilter } from 'commons/types/DTO/bank'
import { AdverseMediaListWebPageRequest } from 'commons/types/DTO/bank/adverseMedia'
import { ApplicationPipelineReportFilter } from 'commons/types/DTO/bank/applicationPipelineReport'
import { BsaBatchDetailsOptions } from 'commons/types/DTO/bank/bsa'
import { CompanyListWebPageRequest } from 'commons/types/DTO/bank/companyList/CompanyListWebPageRequest'
import { HistoryAuditListFilter } from 'commons/types/DTO/bank/historyAudit'
import { LicenseWebPageRequest } from 'commons/types/DTO/bank/license'
import { QRWebPagePayload } from 'commons/types/DTO/bank/quarterlyReport'
import { MRWebPagePayload } from 'commons/types/DTO/bank/reportWebPage'
import { SalesDataSourceWebPagePayload } from 'commons/types/DTO/bank/salesDataSourceWebPage'
import { ServerOptions } from 'commons/types/api'
import { GetClientUserListRequest } from 'commons/types/contracts/api/bank/clientUser'
import { ContactListWebPageRequest } from 'commons/types/contracts/api/bank/contactList'
import { TasksListRequest } from 'commons/types/contracts/api/client/tasks'

import { Atom } from './Atom'
import { Scope } from './Scope'
import { buildKey } from './buildKey'

export const Keys = {
  [Scope.ADVERSE_MEDIA_PUBLICATION]: {
    List: buildKey(Scope.ADVERSE_MEDIA_PUBLICATION, Atom.LIST),
    ListByCriteria: (opts: ServerOptions) => buildKey(Scope.ADVERSE_MEDIA_PUBLICATION, Atom.LIST, opts)
  },
  [Scope.ADVERSE_MEDIA_SEARCH_CASE]: {
    List: (filter: AdverseMediaListWebPageRequest) => buildKey(Scope.ADVERSE_MEDIA_SEARCH_CASE, Atom.LIST, filter),
    ById: (id: number) => buildKey(Scope.ADVERSE_MEDIA_SEARCH_CASE, Atom.ITEM, id)
  },
  [Scope.AFFILIATED_COMPANY]: {
    ById: (id: number) => buildKey(Scope.AFFILIATED_COMPANY, Atom.ITEM, id)
  },
  [Scope.ALERTS]: {
    WebPage: buildKey(Scope.ALERTS, Atom.LIST)
  },
  [Scope.APPLICATION_PIPELINE_REPORT]: {
    List: (filter: ApplicationPipelineReportFilter) => buildKey(Scope.APPLICATION_PIPELINE_REPORT, Atom.LIST, filter)
  },

  [Scope.BANK_APPLICATION]: {
    List: buildKey(Scope.BANK_APPLICATION, Atom.LIST),
    ListByCriteria: (opts: ServerOptions) => buildKey(Scope.BANK_APPLICATION, Atom.LIST, opts)
  },
  [Scope.APPLICATION_BANK_ACCOUNTS]: {
    RelationshipBankAccounts: (id: number) => buildKey(Scope.APPLICATION_BANK_ACCOUNTS, Atom.ITEM, id)
  },
  [Scope.BACK_OFFICE_STATISTICS]: {
    ListByDate: (date: string) => buildKey(Scope.BACK_OFFICE_STATISTICS, Atom.LIST, { date })
  },
  [Scope.BANK]: {
    Settings: buildKey(Scope.BANK, Atom.ITEM, 'settings')
  },
  [Scope.BIOTRACK_KEY]: {
    List: buildKey(Scope.BIOTRACK_KEY, Atom.LIST)
  },
  [Scope.CALENDAR]: {
    CurrentDate: buildKey(Scope.CALENDAR, Atom.ITEM, 'current-date')
  },
  [Scope.CALENDAR_EVENT]: {
    List: buildKey(Scope.CALENDAR_EVENT, Atom.LIST)
  },
  [Scope.CALENDAR_EVENT_CATEGORY]: {
    List: buildKey(Scope.CALENDAR_EVENT_CATEGORY, Atom.LIST),
    Selected: buildKey(Scope.CALENDAR_EVENT_CATEGORY, Atom.LIST, 'selected')
  },
  [Scope.CONNECTION]: {
    List: (connectionsLimit?: number, connectionsOffset?: number, connectionsSearchQuery?: string) =>
      buildKey(Scope.CONNECTION, Atom.LIST, [
        connectionsLimit || 10,
        connectionsOffset || 0,
        connectionsSearchQuery || ''
      ])
  },
  [Scope.CLIENT_APPLICATION]: {
    List: buildKey(Scope.CLIENT_APPLICATION, Atom.LIST)
  },
  [Scope.COMPANY]: {
    ById: (id: number) => buildKey(Scope.COMPANY, Atom.ITEM, id),
    List: buildKey(Scope.COMPANY, Atom.LIST),
    ListByIds: (ids: number[]) => buildKey(Scope.COMPANY, Atom.LIST, { ids })
  },
  [Scope.COMPANY_DETAILS]: {
    WebPage: (id: number) => buildKey(Scope.COMPANY_DETAILS, Atom.ITEM, id)
  },
  [Scope.COMPANY_DETAILS_CONTACTS_LIST]: {
    List: (opts?: FilterCompanyContactsWebPage) => buildKey(Scope.COMPANY_DETAILS_CONTACTS_LIST, Atom.LIST, opts)
  },
  [Scope.CONFIGURATION]: {
    Item: buildKey(Scope.CONFIGURATION, Atom.ITEM)
  },
  [Scope.CONTACT]: {
    ById: (id: number) => buildKey(Scope.CONTACT, Atom.ITEM, id),
    List: (opts?: ContactListWebPageRequest) => buildKey(Scope.CONTACT, Atom.LIST, opts)
  },
  [Scope.DOCUMENT]: {
    ById: (id: number) => buildKey(Scope.DOCUMENT, Atom.ITEM, id),
    ExpiredList: (companyId: number) => buildKey(Scope.DOCUMENT, Atom.LIST, { companyId, type: 'expired' }),
    ListByCompanies: (companyIds: number[]) => buildKey(Scope.DOCUMENT, Atom.LIST, { companyIds }),
    ListTable: ({ tableId, filterValue }: { tableId: string; filterValue: DocumentListV2RequestPayload }) =>
      buildKey(Scope.DOCUMENT, Atom.LIST, { tableId, filterValue })
  },
  [Scope.DOCUMENT_PERIOD]: {
    List: buildKey(Scope.DOCUMENT_PERIOD, Atom.LIST),
    ById: (id: number, documentId: number) => buildKey(Scope.DOCUMENT_PERIOD, Atom.ITEM, { id, documentId }),
    ListByDocument: (documentId: number) => buildKey(Scope.DOCUMENT_PERIOD, Atom.LIST, { documentId }),
    ListByCompanies: (companyIds: number[]) => buildKey(Scope.DOCUMENT_PERIOD, Atom.LIST, { companyIds })
  },
  [Scope.DOCUMENT_FILE]: {
    ById: (id: number) => buildKey(Scope.DOCUMENT_FILE, Atom.ITEM, id),
    List: buildKey(Scope.DOCUMENT_FILE, Atom.LIST),
    ListByPeriod: (periodId: number) => buildKey(Scope.DOCUMENT_FILE, Atom.LIST, { periodId }),
    ListByCompany: (companyId: number) => buildKey(Scope.DOCUMENT_FILE, Atom.LIST, { companyId }),
    ListByCompanies: (companyIds: number[]) => buildKey(Scope.DOCUMENT_FILE, Atom.LIST, { companyIds })
  },
  [Scope.INTERNAL_TRANSFER]: {
    ById: (id: number) => buildKey(Scope.INTERNAL_TRANSFER, Atom.ITEM, { id }),
    List: buildKey(Scope.INTERNAL_TRANSFER, Atom.LIST),
    ListByCriteria: (opts: ServerOptions) => buildKey(Scope.INTERNAL_TRANSFER, Atom.LIST, opts)
  },
  [Scope.INTERNAL_TRANSFER_EXPORT]: {
    ById: (id: number) => buildKey(Scope.INTERNAL_TRANSFER_EXPORT, Atom.ITEM, { id })
  },
  [Scope.INVOICE]: {
    ById: (id: number) => buildKey(Scope.INVOICE, Atom.ITEM, { id })
  },
  [Scope.LICENSE]: {
    ById: (id: number) => buildKey(Scope.LICENSE, Atom.ITEM, { id }),
    Details: (filter: LicenseWebPageRequest) => buildKey(Scope.LICENSE, Atom.ITEM, filter),
    List: (opts: ServerOptions) => buildKey(Scope.LICENSE, Atom.LIST, opts)
  },
  [Scope.METRC_KEY]: {
    List: buildKey(Scope.METRC_KEY, Atom.LIST)
  },
  [Scope.MFA]: {
    Item: buildKey(Scope.MFA, Atom.ITEM)
  },
  [Scope.ORGANISATION]: {
    Current: buildKey(Scope.ORGANISATION, Atom.ITEM, 'current'),
    List: buildKey(Scope.ORGANISATION, Atom.LIST),
    Settings: buildKey(Scope.ORGANISATION, Atom.ITEM, 'current-organisation-settings')
  },
  [Scope.TASK]: {
    List: buildKey(Scope.TASK, Atom.LIST),
    ListByCriteria: (opts?: TasksListRequest) => buildKey(Scope.TASK, Atom.LIST, opts)
  },
  [Scope.TEST]: {
    Test: buildKey(Scope.TEST, Atom.TEST)
  },
  [Scope.USER]: {
    Current: buildKey(Scope.USER, Atom.ITEM, 'current'),
    Session: buildKey(Scope.USER, Atom.ITEM, 'session'),
    ById: (id: number) => buildKey(Scope.USER, Atom.ITEM, id)
  },
  [Scope.USER_LIST]: {
    List: (searchTerm?: string | null, status?: string | null, page?: number | null, size?: number | null) =>
      buildKey(Scope.USER_LIST, Atom.LIST, ['user-list', searchTerm, status, page, size])
  },
  [Scope.CLIENT_USER]: {
    List: (opts?: GetClientUserListRequest) => buildKey(Scope.CLIENT_USER, Atom.LIST, opts)
  },
  [Scope.RELATIONSHIPS]: {
    List: (filter: CompanyListWebPageRequest) =>
      buildKey(Scope.RELATIONSHIPS, Atom.LIST, ['relationships-list', filter])
  },
  [Scope.RELATIONSHIPS_REPORT]: {
    List: (filter: RelationshipsReportFilter) => buildKey(Scope.RELATIONSHIPS_REPORT, Atom.LIST, filter)
  },
  [Scope.WEB_PAGE_DOCUMENT]: {
    ItemByOpts: (opts: ServerOptions) => buildKey(Scope.WEB_PAGE_DOCUMENT, Atom.ITEM, opts),
    ListByCriteria: (opts: ServerOptions) => buildKey(Scope.WEB_PAGE_DOCUMENT, Atom.LIST, opts)
  },
  [Scope.BANK_PORTAL]: {
    Layout: buildKey(Scope.BANK_PORTAL, Atom.ITEM, ['layout'])
  },
  [Scope.CLIENT_PORTAL]: {
    Layout: buildKey(Scope.CLIENT_PORTAL, Atom.ITEM, ['layout'])
  },
  [Scope.MR]: {
    ById: (id: number) => buildKey(Scope.MR, Atom.ITEM, { id }),
    Details: (filter: MRWebPagePayload) => buildKey(Scope.MR, Atom.ITEM, filter)
  },
  [Scope.QR]: {
    ItemByOpts: (opts: QRWebPagePayload) => buildKey(Scope.MR, Atom.ITEM, { opts })
  },
  [Scope.SALES_DATA]: {
    ById: (id: number) => buildKey(Scope.SALES_DATA, Atom.ITEM, { id }),
    Details: (filter: SalesDataSourceWebPagePayload) => buildKey(Scope.SALES_DATA, Atom.ITEM, filter)
  },
  [Scope.BSA]: {
    Summary: buildKey(Scope.BSA, Atom.ITEM, ['bsa-web-page']),
    BatchDetails: (filter?: BsaBatchDetailsOptions) => buildKey(Scope.BSA, Atom.ITEM, filter)
  },
  [Scope.HISTORY_AUDIT]: {
    ListByCriteria: (opts: HistoryAuditListFilter) => buildKey(Scope.HISTORY_AUDIT, Atom.LIST, { opts })
  },
  [Scope.BANK_DOCUMENT_ONBOARDING_SIGNERS]: {
    List: (opts) => buildKey(Scope.BANK_DOCUMENT_ONBOARDING_SIGNERS, Atom.LIST, { opts })
  }
}
