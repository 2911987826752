import { QueryClient, useMutation } from 'react-query'

import { handlerServerError } from 'errors'

import * as Service from '../../service'

export const useDelete = (client: QueryClient) => {
  return useMutation((ids: number[]) => Promise.all(ids.map((id) => Service.Batch.remove({ id }))), {
    onError: (error) => handlerServerError(error),
    onSuccess: async () => {
      await client.invalidateQueries(['tables', 'CTR Batch List'])
    }
  })
}
