import React, { useEffect } from 'react'

import { FieldApi } from '@tanstack/react-form'
import { BsaSar } from 'commons/types/DTO/bank/bsa'
import { usAndTerritories } from 'commons/types/dictionaries'
import { FF, Select, Validators } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

const CountryRenderer = ({ field }: { field: FieldApi<BsaSar, 'institution_contact_info.country'> }) => {
  const { formApi } = useSARFormContext()

  useEffect(() => {
    if (typeof field.state.value !== 'undefined' && field.state.value !== 'US') {
      formApi.setFieldValue('institution_contact_info.state', '', { dontUpdateMeta: true })
      const stateMeta = formApi.getFieldMeta('institution_contact_info.state')
      formApi.setFieldMeta('institution_contact_info.state', { ...stateMeta, errors: [], errorMap: {} })
    }
  }, [field.state.value, formApi])

  return (
    <FF
      required
      label="86. Country"
      field={field}
      input={(p) => (
        <Select {...p} data={usAndTerritories} data-qa="86. Country" options-data-qa="86. Country option" />
      )}
    />
  )
}

export const Country = () => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name="institution_contact_info.country"
      validators={{
        onChange: ({ value }) => Validators.required.field(value)
      }}
      children={(field) => <CountryRenderer field={field} />}
    />
  )
}
