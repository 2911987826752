import React from 'react'

import { CheckMark } from 'ui/components/CheckMark'
import { DateTime } from 'ui/components/DateTime'
import { IconName } from 'ui/components/Icon'
import { FrequencyLabel } from 'ui/components/Labels'
import { Link } from 'ui/components/Link'
import { MenuIconHoverFace, MenuItemBehavior, MenuItemTextFace, TableActions } from 'ui/components/QueryTable'
import { TableColumn, TableColumnAlign } from 'ui/components/Table'
import { DocumentsPaths } from '~bank-documents/constants'

import { AlertsTooltip } from '../AlertsTooltip'
import { RelatedCompany } from '../RelatedCompany'
import { getSubjectType } from './helpers'
import { TableMappedDocument } from './types'

type DocumentTableColumnParams = {
  baseUrl: string
  hideSubjectColumn: boolean
  hideRelatedCompanyColumn: boolean
  onDocumentDelete: (documentData: { id: number; name: string }) => void
  returnUrl: string
}

export const columns = ({
  returnUrl,
  hideSubjectColumn,
  hideRelatedCompanyColumn,
  onDocumentDelete
}: DocumentTableColumnParams): TableColumn<TableMappedDocument>[] => {
  const rawColumns: TableColumn<TableMappedDocument>[] = [
    {
      title: 'Document Name',
      sortable: true,
      key: 'name',
      align: TableColumnAlign.LEFT,
      render: (item) => (
        <Link to={DocumentsPaths.DETAILS} params={{ documentId: item.id }}>
          {item.name}
        </Link>
      )
    },
    {
      title: 'Internal',
      sortable: true,
      key: 'internal',
      render: ({ internal }) => (internal ? <CheckMark value rounded /> : null)
    },
    !hideSubjectColumn
      ? {
          title: 'Subject',
          align: 'left',
          render: ({ subject: { id, label, name, type } }) =>
            getSubjectType({
              id,
              label,
              name,
              type
            })
        }
      : null,

    !hideRelatedCompanyColumn
      ? {
          title: 'Relationship',
          align: 'left',
          render: ({ relationships }) =>
            relationships.length > 0 && <RelatedCompany asInfoBlock={false} relatedCompanies={relationships} />
        }
      : null,
    {
      title: 'Frequency',
      sortable: true,
      key: 'frequency',
      align: TableColumnAlign.LEFT,
      render: ({ frequency }) => <FrequencyLabel name={frequency} />
    },
    {
      title: 'Expiration Date',
      sortable: true,
      key: 'expirationDate',
      align: TableColumnAlign.LEFT,
      render: ({ expirationDate }) => <DateTime date={expirationDate} />
    },
    {
      align: TableColumnAlign.LEFT,
      title: 'Alerts',
      render: (item) => <AlertsTooltip alerts={item.alerts} id={item.id} expirationDate={item.expirationDate} />
    },
    {
      title: 'Actions',
      width: '50px',
      render: (item) => (
        <TableActions
          items={[
            {
              key: 1,
              title: 'Edit',
              iconName: IconName.EDIT,
              iconHoverFace: MenuIconHoverFace.PRIMARY,
              textFace: MenuItemTextFace.GREY,
              url: `/documents/${item.id}/edit?returnUrl=${returnUrl}`
            },
            {
              key: 2,
              title: 'Delete',
              behavior: MenuItemBehavior.BUTTON,
              iconName: IconName.DELETE,
              iconHoverFace: MenuIconHoverFace.DANGER,
              textFace: MenuItemTextFace.GREY,
              onClick: () => onDocumentDelete({ id: item.id, name: item.name })
            }
          ]}
        />
      )
    }
  ]
  const resultColumns = rawColumns.filter(Boolean)

  return resultColumns
}
