import styled from 'styled-components'
import { CSS } from 'ui/helpers'

import { RadioGroupOrientation } from './RadioGroupOrientation'

export const RadioGroupContainer = styled.div<{ orientation: RadioGroupOrientation }>`
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${(props) => (props.orientation === RadioGroupOrientation.VERTICAL ? 'column' : 'row')};
`

export const Hint = styled.div<{ disabled: boolean }>`
  ${(p) => CSS.populateFont(p.theme.uiKit.typography.bodyS.regular)};
  color: ${(p) => (p.disabled ? p.theme.uiKit.colors.global.grey.grey700 : p.theme.uiKit.colors.global.grey.grey900)};
`
