import { DocumentFile } from 'commons/types/DTO/client/documentFile'
import { ApiReadResponse, ServerOptions } from 'commons/types/api'
import { Request } from 'commons/utils/request/request'
import { format } from 'date-fns'

export const getDocumentFileList = (opts: ServerOptions): Promise<ApiReadResponse<DocumentFile>> =>
  Request.post<ApiReadResponse<DocumentFile>>(Request.urls.client.documentFileList, opts)

export type CreateDocumentFileRequestIsolated = {
  file: File
  applicationClientDocumentId: number
}

export type CreateDocumentFileRequest = {
  file: File
  periodId: number
  deliveredAt?: string
}

export const createDocumentFile = ({
  file,
  periodId,
  deliveredAt
}: CreateDocumentFileRequest): Promise<DocumentFile> => {
  const form = new FormData()
  const _deliveredAt = deliveredAt ? new Date(deliveredAt) : new Date()
  form.append('period_id', periodId.toString())
  form.append('delivered_at', format(_deliveredAt, 'yyyy-MM-dd'))
  form.append('document', file)
  return Request.post(Request.urls.client.documentFileUpload, form)
}

export const deleteFile = (id: number) => Request.delete(Request.urls.client.documentFile, { id })

export const createDocumentFileIsolated = ({
  file,
  applicationClientDocumentId
}: CreateDocumentFileRequestIsolated): Promise<DocumentFile> => {
  const form = new FormData()
  form.append('applicationClientDocumentId', applicationClientDocumentId.toString())
  form.append('document', file)
  return Request.post(Request.urls.client.applicationClientDocumentFileUpload, form)
}

export const deleteFileIsolated = (id: number) =>
  Request.delete(Request.urls.client.applicationClientDocumentFile, { id })
