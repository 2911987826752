import { useEffect } from 'react'
import { QueryObserver, useMutation, useQueryClient } from 'react-query'

import { handlerServerError } from 'errors'
import { useStateManager } from 'state-manager'

import { ServerOptions } from '../../QueryTable'

interface Props {
  type: any
  url: string
  serverOptions: ServerOptions
  method?: string
}

export const useGetData = (queryKey: string) => {
  const sm = useStateManager()
  const queryClient = useQueryClient()

  /*
    Data without observer doesn't glue with component and query are inactive and will removed
    after cacheTime.

    https://tanstack.com/query/v4/docs/react/reference/QueryClient#queryclientsetquerydata
    https://tkdodo.eu/blog/inside-react-query#active-and-inactive-queries

    Needed to find a way to work with that, this is temp solution
  */
  useEffect(() => {
    const observer = new QueryObserver(queryClient, { queryKey: [queryKey] })
    const unsubscribe = observer.subscribe()
    return () => {
      unsubscribe()
    }
  }, [])

  return useMutation(({ type, url, serverOptions, method }: Props) => type[method || 'post'](url, serverOptions), {
    onError: (error) => handlerServerError(error),
    onSuccess: (data: any) => {
      sm.set(queryKey, data)
    }
  })
}
