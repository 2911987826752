import React from 'react'

import { Request } from 'commons/utils/request'
import { DateTime } from 'ui/components/DateTime'
import { DownloadLink } from 'ui/components/DownloadLink'
import { MenuItemBehavior, MenuItemTextFace, TableActions } from 'ui/components/QueryTable'
import { TableColumn } from 'ui/components/Table'
import { Toast } from 'ui/components/Toast'
import { Tooltip } from 'ui/components/Tooltip'

import { Notes } from './styles'

const handleError = (message: string) => Toast.error('Error', message)

export const columns = (onDeleteDocument: (id: number, name: string) => void): TableColumn<any>[] => [
  {
    title: 'Document Name',
    align: 'left',
    key: 'name'
  },
  {
    align: 'center',
    title: 'File Name',
    render: (item) => {
      const url = Request.urls.bank.applicationDocumentDirectDownload
      return (
        <DownloadLink
          direct
          parametersForm={{ id: item.documentID }}
          baseUrl={url}
          name={item.fileName}
          handleError={handleError}
        >
          {item.fileName}
        </DownloadLink>
      )
    }
  },
  {
    title: 'Upload Date',
    align: 'center',
    render: ({ uploadDate }) => !!uploadDate && <DateTime date={uploadDate} withTime />
  },
  {
    title: 'Notes',
    align: 'center',
    render: (item) =>
      item.notes ? (
        <Tooltip id={`hint${item.documentID}`} type="dark" event="hover" content={item.notes}>
          <Notes>{item.notes}</Notes>
        </Tooltip>
      ) : (
        <Notes>---</Notes>
      )
  },
  {
    title: 'Actions',
    align: 'center',
    width: '50px',
    render: (item) =>
      item.isDeletable ? (
        <TableActions
          menuContainerId="internalDocumentsContainer"
          items={[
            {
              key: 1,
              title: 'Delete',
              behavior: MenuItemBehavior.BUTTON,
              textFace: MenuItemTextFace.GREY,
              onClick: () => onDeleteDocument(item.documentID, item.name)
            }
          ]}
        />
      ) : null
  }
]
