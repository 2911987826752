import { createContext, useContext } from 'react'

import { useForm } from '@tanstack/react-form'
import { BsaSar } from 'commons/types/DTO/bank/bsa'

export type ManageSARContextValue = {
  formApi: ReturnType<typeof useForm<BsaSar, undefined>> | null
}

export const ManageSARContext = createContext<ManageSARContextValue>(null)

export const useSARFormContext = () => useContext(ManageSARContext)
