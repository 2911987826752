import { getInternalTransferExportsList } from 'commons/service/bank/internalTransfer'
import { InternalTransfersExportList } from 'commons/types/DTO/bank'
import { ApiReadResponse } from 'commons/types/api'
import { Keys, ReadHook, useStateManager } from 'state-manager'

type UseGetInternalTransferExport = {
  id: number
  enabled: boolean
}

export const useGetInternalTransferExport: ReadHook<ApiReadResponse<InternalTransfersExportList>> = (
  { id, enabled }: UseGetInternalTransferExport,
  callbacks
) => {
  const sm = useStateManager()

  return sm.use(Keys.InternalTransferExport.ById(id), () => getInternalTransferExportsList(id), {
    enabled,
    ...callbacks
  })
}
