import React from 'react'

import { BankAccountInput, FF, Validators } from 'ui/components'
import { HeaderButton } from 'ui/components/Buttons'

import { BankAccountNumberInputProps } from './BankAccountNumberInput.types'

import { s } from './styles'

export const BankAccountNumberInput = ({ field, onSubmit, form }: BankAccountNumberInputProps) => (
  <s.BankAccountInputBox>
    <form.Subscribe
      children={(all) => {
        const companiesIdString = field.name.slice(0, field.name.indexOf('.bankAccountNumber'))

        return (
          <FF
            label="Bank Account Number"
            field={field}
            error={all.fieldMeta[companiesIdString + '.bankAccounts']?.errors[0]}
            input={(props) => <BankAccountInput {...props} />}
          />
        )
      }}
    />

    <s.BankAccountInputButton>
      <HeaderButton
        size="middle"
        type="button"
        disabled={!!Validators.bankAccountNumber.notEmptyAndValid(field.state.value)}
        onClick={onSubmit}
      >
        Add
      </HeaderButton>
    </s.BankAccountInputButton>
  </s.BankAccountInputBox>
)
