import React, { memo } from 'react'

import { getRouteApi } from '@tanstack/react-router'

import { Box, Container } from './styles'

export const ApplicationValidateError = memo(() => {
  const errorMessage = getRouteApi('/auth/application-validate-error').useSearch({ select: (s) => s.errorMessage })

  return (
    <Box>
      <Container>{errorMessage || 'An error occurred during user identification'}</Container>
    </Box>
  )
})
