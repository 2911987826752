import styled from 'styled-components'

export const Columns = styled.div`
  display: flex;
  padding: 20px 30px 32px 30px;
`

export const Column = styled.div`
  margin-right: 30px;
`

export const FixedColumn = styled.div`
  margin-right: 30px;
  width: 240px;
`
