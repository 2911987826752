/* eslint-disable camelcase */

export type TransmitterInfo = {
  city?: string
  contact_name?: string
  country?: string
  name?: string
  phone?: string
  state?: string
  street?: string
  tcc?: string
  tin?: string
  zip_code?: string
}
