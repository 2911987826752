import { useCallback, useMemo } from 'react'

import { BeforeFormChangeHandler, useFormData } from 'brief-form'
import { useGetAdverseMediaListWebPage } from 'commons/hooks/bank/adverseMedia'
import { AdverseMediaListWebPageRequest } from 'commons/types/DTO/bank/adverseMedia'
import { TablePagination, TablePaginationChangeHandler } from 'ui/components'

const InitialFilter: AdverseMediaListWebPageRequest = {
  entityType: '',
  page: 1,
  size: 20,
  riskLevel: '',
  searchCase: ''
}

export const useAdverseMediaList = () => {
  const beforeChangeHandler: BeforeFormChangeHandler<AdverseMediaListWebPageRequest> = useCallback(
    ({ value, oldValue, errors }) => {
      // This means that pagination is not chaned, consequently some
      // filter paramerers are updated, so we should go to the first page.
      const filterUpdated = value.page === oldValue.page && value.size === oldValue.size

      return {
        errors,
        value: filterUpdated ? { ...value, page: 1 } : value
      }
    },
    []
  )

  const form = useFormData({ initialValue: InitialFilter, onBeforeChange: beforeChangeHandler })
  const { config: filterFormConfig, set } = form
  const { value } = filterFormConfig
  const { page, size } = value
  const { data, isFetching } = useGetAdverseMediaListWebPage(value)

  const paginationChangeHangler: TablePaginationChangeHandler = useCallback(
    (page, size) => {
      set({ value: { ...value, page, size } })
    },
    [value, set]
  )

  const termChangeHangler = useCallback(
    (searchCase: string) => {
      set({ value: { ...value, searchCase, page: 1 } })
    },
    [value, set]
  )

  const pagination: TablePagination = useMemo(
    () => ({
      page,
      pageSize: size,
      total: data?.total || 0,
      onChange: paginationChangeHangler
    }),
    [page, size, data, paginationChangeHangler]
  )

  return {
    data,
    isFetching,
    pagination,
    form,
    termChangeHangler
  }
}
