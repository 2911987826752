import { FormInputProps } from 'brief-form'
import { InputHintProps } from 'ui/core/InputHint'
import { ChoiceOption, ChoiceValue } from 'ui/types'

export type SelectOpts = InputHintProps & {
  data: ChoiceOption[]
  searchable?: boolean
  disabled?: boolean
  placeholder?: string
  emptyOption?: ChoiceOption
  onSearch?: (term: string) => void
  onOpen?: () => void
  'data-qa'?: string
  'options-data-qa'?: string
  'data-testid'?: string
}

export interface SelectProps extends FormInputProps<ChoiceValue, SelectOpts> {}
