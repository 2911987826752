import { useEffect, useMemo, useState } from 'react'

import { notFound } from '@tanstack/react-router'

import { hooks } from '../../hooks'
import { BankAccountDetailsPage, BankAccountDetailsPagePayload, BankAccountsDocumentsFilter } from '../../types'

export const useBankAccountDetails = (id: number) => {
  const initialPayload: BankAccountDetailsPagePayload = useMemo(
    () => ({
      id: id,
      documentsFilter: null
    }),
    [id]
  )

  const [filter, setFilter] = useState<BankAccountDetailsPagePayload>(initialPayload)
  const [webPageData, setWebPageData] = useState<BankAccountDetailsPage | undefined>(undefined)
  const { data, isFetched } = hooks.useDetailsPage(filter)

  useEffect(() => {
    if (isFetched) {
      setWebPageData(data)
    }
  }, [isFetched, data])

  useEffect(() => {
    if (isFetched && !data) {
      throw notFound()
    }
  })

  const onFilterBankAccountDetails = async (payload: BankAccountsDocumentsFilter) =>
    setFilter({ id: id, documentsFilter: { ...payload } })

  return {
    webPageData,
    onFilterBankAccountDetails
  }
}
