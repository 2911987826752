import React from 'react'

import { bsaCTRStatuses } from 'commons/types/dictionaries'
import { formatCurrency } from 'commons/utils'
import { DateTime } from 'ui/components/DateTime'
import { Icon, IconName, IconSize } from 'ui/components/Icon'
import { Link } from 'ui/components/Link'
import { MenuItemBehavior, TableActions } from 'ui/components/QueryTable'
import { TableColumn } from 'ui/components/Table'

import { ColumsProps } from './ColumnsProps'

import * as SC from './styles'

export const columns = (opts: ColumsProps) => (): TableColumn<any>[] =>
  [
    {
      title: (
        <SC.WrappedCheckBox
          checkBoxLabel=""
          value={opts.isAllItemsChosen}
          onChange={() => opts.onItemSelect(null)}
          data-qa="SARs List all checkbox"
        />
      ),
      sortable: false,
      width: '50px',
      align: 'center',
      dataQa: (v: string) => `title_${v}`,
      render: (item) => (
        <SC.WrappedCheckBox
          checkBoxLabel=""
          value={opts.chosenItems.findIndex((i) => i.id === item.id) !== -1}
          onChange={() => opts.onItemSelect(item)}
          data-qa={`SARs List item checkbox ${item.id}`}
        />
      )
    },
    {
      title: 'ID',
      align: 'center',
      render: ({ id }) => (
        <Link
          to="/bsa/sars/$id"
          params={{ id }}
          search={{
            status: opts.filter.status ? opts.filter.status : undefined,
            company_id: opts.filter.company_id ? opts.filter.company_id : undefined,
            trans_start_date: opts.filter.trans_start_date ? opts.filter.trans_start_date : undefined,
            trans_end_date: opts.filter.trans_end_date ? opts.filter.trans_end_date : undefined
          }}
        >
          {id}
        </Link>
      )
    },
    {
      title: 'Period',
      key: 'period',
      align: 'center'
    },
    {
      title: 'Relationship',
      align: 'center',
      render: ({ company }) => (
        <Link to="/relationships/$companyId" params={{ companyId: company?.id }}>
          {company?.name || ''}
        </Link>
      )
    },
    {
      title: 'Total',
      align: 'center',
      render: ({ total }) => formatCurrency(total)
    },
    {
      title: 'Status',
      align: 'center',
      render: ({ id, status, attention }) => {
        const type = bsaCTRStatuses.find((t) => t.value === status)
        return (
          <SC.NameRow key={'status' + id}>
            {!!attention && (
              <SC.IconButton type="button" tabIndex={0} onClick={() => opts.onShowWarnings(attention)}>
                <Icon name={IconName.ERROR_RED} size={IconSize.XS} />
              </SC.IconButton>
            )}

            {type ? type.label : '-'}
          </SC.NameRow>
        )
      }
    },
    {
      title: 'Created Date',
      align: 'center',
      render: ({ created_at }) => <DateTime date={created_at} withTime />
    },
    {
      title: 'Last Modified Date',
      align: 'center',
      render: ({ updated_at }) => <DateTime date={updated_at} withTime />
    },
    {
      title: 'Action',
      align: 'center',
      render: (item) => (
        <TableActions
          items={[
            {
              key: 1,
              title: 'Edit',
              url: `/bsa/sars/${item.id}/edit`,
              iconName: IconName.EDIT
            },
            {
              key: 2,
              title: 'Delete',
              behavior: MenuItemBehavior.BUTTON,
              onClick: () => opts.onDelete(item),
              iconName: IconName.DELETE
            }
          ]}
        />
      )
    }
  ]
