import React, { FC, memo } from 'react'

import { Icon, IconName, IconSize } from 'ui/components/Icon'
import { Tooltip } from 'ui/components/Tooltip'
import { DocumentItemAlerts } from '~bank-documents/types/documentListV2'

import * as SC from './styles'

type AlertsTooltip = {
  id: number
  expirationDate: string
  alerts: DocumentItemAlerts
}

export const AlertsTooltip: FC<AlertsTooltip> = memo((props) => {
  const {
    expirationDate,
    id,
    alerts: { firstAlerted, initialAlerted, lastAlerted, pastDue }
  } = props

  const showAlerts = !!firstAlerted.count || !!lastAlerted.count || !!initialAlerted.count
  const showPastDue = !!pastDue.count
  const showOk = !expirationDate && !showPastDue
  const alertMessages = [
    !!initialAlerted.count && initialAlerted.label,
    !!firstAlerted.count && firstAlerted.label,
    !!lastAlerted.count && lastAlerted.label
  ]
    .filter(Boolean)
    .join(', ')

  const alertsCount = firstAlerted.count + lastAlerted.count + initialAlerted.count
  return (
    <SC.DueRow>
      {showAlerts && (
        <SC.DueCount>
          <Tooltip id={`firstHint${id}`} type="warning" event="hover" content={alertMessages}>
            <SC.TooltipContent>
              <Icon name={IconName.EXCLAMATION_ORANGE} size={IconSize.S} /> {alertsCount}
            </SC.TooltipContent>
          </Tooltip>
        </SC.DueCount>
      )}
      {showPastDue && (
        <SC.DueCount>
          <Tooltip id={`pastDue${id}`} type="error" event="hover" content={pastDue.label}>
            <SC.TooltipContent>
              <Icon name={IconName.EXCLAMATION} size={IconSize.S} /> {pastDue.count}
            </SC.TooltipContent>
          </Tooltip>
        </SC.DueCount>
      )}
      {showOk && (
        <SC.DueCount>
          <Icon name={IconName.CHECK_GREEN} size={IconSize.S} />
        </SC.DueCount>
      )}
    </SC.DueRow>
  )
})
