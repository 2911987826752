import { useCallback } from 'react'

import { FormChangedHandler, useFormData } from 'brief-form'

import { FormPaths } from '../../enums'
import { useSyncFormData } from '../../hooks'
import { useValidateFormPersist } from '../../hooks/useValidateFormPersist'
import {
  ProfessionalPartnershipChangeHandler,
  ProfessionalPartnershipFormData,
  ProfessionalPartnershipFormValue
} from '../../types/forms'

export const useProfessionalPartnershipsForm = ({
  formData,
  companyIndex,
  onChange
}: {
  formData: ProfessionalPartnershipFormData
  companyIndex
  onChange: ProfessionalPartnershipChangeHandler
}) => {
  const onFormChanged: FormChangedHandler<ProfessionalPartnershipFormValue> = useCallback(
    (formValues, formErrors) => onChange({ companyIndex, formData: { formValues, formErrors } }),
    [onChange, companyIndex]
  )

  const { config, Field, set, validate } = useFormData<ProfessionalPartnershipFormValue>({
    onFormChanged,
    initialValue: formData.formValues,
    initialErrors: formData.formErrors
  })

  useSyncFormData({ set, formData })
  const formKey = `${FormPaths.PROFESSIONAL_PARTNERSHIPS}-${companyIndex}`
  useValidateFormPersist<ProfessionalPartnershipFormValue>({
    formName: formKey,
    validate,
    config
  })
  return { Field, config }
}
