import { getRelationshipsReport } from 'commons/service/bank/report'
import { RelationshipsReportFilter, RelationshipsReportResponse } from 'commons/types/DTO/bank'
import { Keys, ReadHook, useStateManager } from 'state-manager'

export const useGetRelationshipsReport: ReadHook<RelationshipsReportResponse, RelationshipsReportFilter> = (filter) => {
  const sm = useStateManager()

  return sm.use(Keys.RelationshipsReport.List(filter), () => getRelationshipsReport(filter), {
    keepPreviousData: true
  })
}
