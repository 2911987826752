import styled from 'styled-components'
import { CSS } from 'ui/helpers/cssHelpers'

import { IconV2 } from '../../../Icon'

export const IconWrapper = styled(IconV2)`
  color: ${(p) => p.theme.uiKit.colors.theme.brand.darker};
  transition: ${CSS.populateTransition(['color'], '0.3s')};
`

export const Box = styled.div<{ $visible: boolean }>`
  display: flex;
  align-items: center;
  height: 44px;
  width: 100%;
  background-color: transparent;
  overflow-x: hidden;
  white-space: nowrap;
  padding-left: 24px;
  gap: 12px;
  color: ${(p) => p.theme.uiKit.colors.theme.brand.darkest};
  border-bottom: 1px solid ${(p) => p.theme.uiKit.colors.global.grey.grey300};
  ${(p) => CSS.populateFont(p.theme.uiKit.typography.bodyL.regular)};

  transition: ${CSS.populateTransition(['background-color', 'color'], '0.3s')};

  ${(p) =>
    p.$visible &&
    `
    cursor: pointer;
    &:hover,
    &:visited:hover {
      background-color: rgba(0, 0, 0, 0.02);
      color: ${(p) => p.theme.uiKit.colors.theme.brand.primary};
      ${IconWrapper} {
        color: ${(p) => p.theme.uiKit.colors.theme.brand.primary};    
      }
    }
  `}
`

export const MainMenuBox = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`
