import {
  ApplicationPipelineReportFilter,
  ApplicationPipelineReportResponse,
  ApplicationWebPageDetail
} from 'commons/types/DTO/bank'
import { ApplicationRelationshipBankAccountsResponse } from 'commons/types/DTO/bank/applications'
import { ApplicationSaveBankAccountsPayload } from 'commons/types/DTO/bank/applications/bankAccounts/bankAccounts'
import { AssignOwnerRequest } from 'commons/types/DTO/bank/applications/manage/AssignOwnerRequest'
import { Request } from 'commons/utils/request'

export const sendApplicationLink = (id: number) => Request.post(Request.urls.bank.applicationLinkSend, { id })

// eslint-disable-next-line import/no-unused-modules
export const getApplicationWebPage = (id: number) =>
  Request.post<ApplicationWebPageDetail>(Request.urls.bank.applicationDetailsViewWebpages, { id })

export const assignApplicationOwner = (opts: AssignOwnerRequest) =>
  Request.post(Request.urls.bank.applicationAssignOwner, opts)

export const getApplicationPipelineReport = (filter: ApplicationPipelineReportFilter) =>
  Request.post<ApplicationPipelineReportResponse>(Request.urls.bank.portalReportApplicationPipeline, filter)

// https://swagger.dev.helioscompliance.com/#/bank%20accounts/postWebpagesOnboardingbankaccounts
// eslint-disable-next-line import/no-unused-modules
export const getApplicationRelationshipBankAccounts = (
  applicationId: number
): Promise<ApplicationRelationshipBankAccountsResponse> =>
  Request.post<ApplicationRelationshipBankAccountsResponse>(Request.urls.bank.applicationBankAccounts, {
    id: applicationId
  })

// https://swagger.dev.helioscompliance.com/#/applications/postApplicationSavebankaccounts
// eslint-disable-next-line import/no-unused-modules
export const saveBankAccounts = (payload: ApplicationSaveBankAccountsPayload) =>
  Request.post(Request.urls.bank.applicationSaveBankAccount, payload)

// https://swagger.dev.helioscompliance.com/#/applications/postApplicationFinalize
// eslint-disable-next-line import/no-unused-modules
export const finalizationApplication = ({ applicationId }: { applicationId: number }) =>
  Request.post(Request.urls.bank.applicationFinalization, { id: applicationId })

// eslint-disable-next-line max-len
export const submitDocumentRequestStep = (id: number) =>
  Request.put<void>(Request.urls.bank.applicationSubmitDocumentRequest, { id })
