import React, { memo } from 'react'

import { getRouteApi } from '@tanstack/react-router'
import { useGetCTRList } from '~bank-bsa-reporting/hooks/ctr'

import { CTRList } from './components'

const routeApi = getRouteApi('/bsa/ctrs/print')

const PrintCTRList = memo(() => {
  const search = routeApi.useSearch()

  const filter = {
    transactionDate: search.date,
    size: search.size,
    page: search.page,
    ...(search.status !== 'undefined' && { status: search.status }),
    ...(search.type !== 'undefined' && { filingType: search.type })
  }

  const { data } = useGetCTRList(filter)

  return <CTRList items={data?.items || []} />
})

export default PrintCTRList
