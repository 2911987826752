import { useCallback, useState } from 'react'

export const useChangePasswordForm = (initiallyVisible = false) => {
  const [passwordFormVisible, setPasswordFormVisible] = useState(initiallyVisible)
  const openPasswordForm = useCallback(() => setPasswordFormVisible(true), [setPasswordFormVisible])
  const closePasswordForm = useCallback(() => setPasswordFormVisible(false), [setPasswordFormVisible])
  return {
    passwordFormVisible,
    openPasswordForm,
    closePasswordForm
  }
}
