import { ContactMetadata } from 'commons/types/DTO/bank'
import { Contact } from 'commons/types/DTO/bank'

export const emptyMetadata: ContactMetadata = {
  id: 0,
  contact_id: 0,
  company_id: 0,
  company_name: '',
  title: '',
  is_documents: false,
  is_financials: false,
  is_owner: false,
  is_debtholder: false,
  is_account_signer: false,
  ownership: 0,
  ownership_amount: 0,
  debt_amount: 0,
  debt_monthly_payment: 0,
  debt_payment_frequency: '',
  debt_maturity_date: null,
  created_at: null,
  updated_at: null,
  deleted_at: null,
  sf_external_id: null
}

export const useContactMetadataItem = (contact?: Contact, companyId?: number): ContactMetadata | undefined => {
  const emptyReturnMetadata = {
    ...emptyMetadata,
    contact_id: contact?.id || 0,
    company_id: companyId || 0
  }

  if (companyId && contact?.contact_metadata && contact.contact_metadata.length > 0) {
    const foundMetadata = contact.contact_metadata.find((i) => i.company_id === companyId)

    return foundMetadata ? foundMetadata : emptyReturnMetadata
  }

  return emptyReturnMetadata
}
