import { FormInputProps } from 'brief-form'
import { BankName } from 'commons/types/enums'
import { ChoiceValue } from 'ui/types'

import { SelectOpts } from '../Select'

export type BusinessTypeSelectOpts = Omit<SelectOpts, 'data'> & {
  bankName: BankName
}

export interface BusinessTypeSelectProps extends FormInputProps<ChoiceValue, BusinessTypeSelectOpts> {}
