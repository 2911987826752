import React, { FC } from 'react'

import { DotLabelBadgeProps } from './DotLabelBadgeProps'

import * as SC from './styles'

export const DotLabelBadge: FC<DotLabelBadgeProps> = ({ dotActive, label }) => (
  <SC.DotLabelWrapper>
    <SC.Dot $active={dotActive} data-qa="labelBadge_dot" />
    <p>{label}</p>
  </SC.DotLabelWrapper>
)
