import { ReactNode } from 'react'

import { FileSelectorMode } from './FileSelectorMode'

export interface FileSelectorProps {
  children?: ReactNode
  mode?: FileSelectorMode
  dialogProps?: {
    title?: ReactNode
    submitButtonText?: ReactNode
  }
  onFilesSelect: (files: File[]) => void
  showDialogAfterConfirm?: boolean
  showSpinner?: boolean
  filesWasLoaded?: boolean
}
