import { Request } from 'commons/utils/request'
import { WriteHook, useMutationManager } from 'state-manager'
import { DocumentStatuses } from '~bank-documents/types/enums/DocumentStatuses'

type SetDocumentPeriodStatusParams = { status: DocumentStatuses; periodId: number; notes: string }

const setStatusUrls: {
  [K in DocumentStatuses.APPROVED | DocumentStatuses.INCOMPLETE | DocumentStatuses.REJECTED]: string
} = {
  [DocumentStatuses.APPROVED]: Request.urls.bank.documentPeriodApproved,
  [DocumentStatuses.INCOMPLETE]: Request.urls.bank.documentPeriodIncomplete,
  [DocumentStatuses.REJECTED]: Request.urls.bank.documentPeriodRejected
}

const setDocumentPeriodStatus = ({ periodId, status, notes }: SetDocumentPeriodStatusParams) =>
  Request.put(setStatusUrls[status], { id: periodId, notes })

export const useSetDocumentPeriodStatus: WriteHook<SetDocumentPeriodStatusParams, unknown> = (opts) =>
  useMutationManager<SetDocumentPeriodStatusParams, unknown>(setDocumentPeriodStatus, opts)
