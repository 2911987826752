import { format } from 'date-fns'
import { isArray } from 'lodash'

export const getOptionsFilters = (filter: any) => {
  const dayStartDate = filter?.trans_start_date ? new Date(filter.trans_start_date) : null
  const dayEndDate = filter?.trans_end_date ? new Date(filter.trans_end_date) : null

  return [
    filter?.status ? { field: 'status', type: 'eq', value: filter.status } : (null as any),
    filter?.company_id
      ? {
          field: 'company_id',
          type: isArray(filter?.company_id) ? 'in' : 'eq',
          value: filter.company_id
        }
      : (null as any),
    dayStartDate ? { field: 'trans_start_date', type: 'gt', value: format(dayStartDate, 'yyyy-MM-dd') } : (null as any),
    dayEndDate ? { field: 'trans_end_date', type: 'lte', value: format(dayEndDate, 'yyyy-MM-dd') } : (null as any)
  ].filter(Boolean)
}
