import { AdverseMediaPublication, AdverseMediaSearchCase } from 'commons/types/DTO/bank'
import { ApiReadResponse, ServerOptions } from 'commons/types/api'
import { Request } from 'commons/utils/request/request'

export const getPublicationList = (options: ServerOptions) =>
  Request.post<ApiReadResponse<AdverseMediaPublication>>(Request.urls.bank.adverseMediaPublicationList, options)

export const update = (body: AdverseMediaPublication) => Request.patch(Request.urls.bank.adverseMediaPublication, body)

export const setSmartFilter = (opts: { id: number; smart: boolean }) =>
  Request.put<AdverseMediaSearchCase>(Request.urls.bank.adverseMediaCaseSmartFilter, {
    id: opts.id,
    smart_filter: opts.smart
  })
