import styled from 'styled-components'

const DialogContent = styled.div`
  padding: 7px 0 0 0;
`

const Content = styled.div`
  padding: 20px 40px 20px 40px;
`

const TooltipInside = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`

const TooltipContainer = styled.div`
  display: flex;
  height: 100%;
`

const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  max-width: 240px;
  padding-bottom: 12px;
`

export const s = { DialogContent, Content, TooltipContainer, TooltipInside, TooltipContent }
