import React, { ReactNode, memo } from 'react'

import SC from 'styled-components'
import { Layer } from 'ui/components/Layer'

interface Props {
  title?: string
  rows: { label: ReactNode; value: ReactNode }[]
}

//region Styles

const Box = SC(Layer)`
  min-width: auto;
  margin-bottom: 30px;
  border-top: 1.4px solid #000;
`

const Title = SC.h2`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  margin: 0;
  padding: 10px 20px;
`

const Rows = SC.div`
`

const Row = SC.div`
  padding: 0 20px;
  height: 46px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  line-height: 16px;
  
  &:nth-child(odd) {
    background: ${(p) => p.theme.uiKit.colors.global.grey.grey100};
  }
`

const Label = SC.label`
  margin-right: 100px;
`

//endregion

export const CashInOut = memo(({ title, rows }: Props) => {
  return (
    <Box rounded shadowed>
      {title && <Title>{title}</Title>}
      <Rows>
        {rows.map((item, index) => (
          <Row key={index}>
            <Label>{item.label}</Label>
            <span>{item.value}</span>
          </Row>
        ))}
      </Rows>
    </Box>
  )
})
