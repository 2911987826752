import { OnboardingFormActions } from '../../../enums'
import { OnChangeActionHandler } from '../../../types/actions'

export const onChange: OnChangeActionHandler = ({ path, formData, params }) => ({
  type: OnboardingFormActions.ON_CHANGE,
  payload: {
    path,
    formData,
    params
  }
})
