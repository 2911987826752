import React, { useEffect } from 'react'

import { FieldApi } from '@tanstack/react-form'
import { BsaSar } from 'commons/types/DTO/bank/bsa'
import { CheckBox, FF } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

const FilingTypeContinuingRenderer = ({ field }: { field: FieldApi<BsaSar, 'filing_type_continuing'> }) => {
  const { formApi } = useSARFormContext()

  useEffect(() => {
    if (field.state.value) {
      formApi.setFieldValue('filing_type_initial', false, { dontUpdateMeta: true })
    } else {
      if (!formApi.state.values.filing_type_correct_amend) {
        const bsaIdMeta = formApi.getFieldMeta('prior_report_bsa_id')
        formApi.setFieldMeta('prior_report_bsa_id', { ...bsaIdMeta, errors: [], errorMap: {} })
      }
    }
  }, [field.state.value, formApi])

  return <FF field={field} input={(p) => <CheckBox {...p} checkBoxLabel="Continuing activity report" />} />
}

export const FilingTypeContinuing = () => {
  const { formApi } = useSARFormContext()
  return (
    <formApi.Field name="filing_type_continuing" children={(field) => <FilingTypeContinuingRenderer field={field} />} />
  )
}
