import React, { FC, memo } from 'react'

import { ButtonGroup } from 'ui/components/ButtonGroup'
import { Button } from 'ui/components/Buttons'

import { FooterProps } from './FooterProps'

import * as SC from './styles'

export const Footer: FC<FooterProps> = memo((props) => {
  const { onReview, onReCalculate, onResolveWarnings } = props

  return (
    <SC.Box>
      <ButtonGroup margin="small">
        <Button onClick={onReCalculate} face="neutral">
          Recalculate
        </Button>
        {!!onReview && (
          <Button onClick={onReview} face="positive">
            Review
          </Button>
        )}
        {!!onResolveWarnings && (
          <Button onClick={onResolveWarnings} face="positive">
            Acknowledge all Warnings
          </Button>
        )}
      </ButtonGroup>
    </SC.Box>
  )
})
