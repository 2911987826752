import React, { FC, memo, useCallback, useEffect, useState } from 'react'

import { Form, useFormData } from 'brief-form'
import { ButtonGroup } from 'ui/components/ButtonGroup'
import { Button } from 'ui/components/Buttons'
import { Info, InfoSet, InfoSetDirection } from 'ui/components/Info'
import { TextareaInput } from 'ui/components/InputV2'
import { QuarterlyReportStatusLabel } from 'ui/components/Labels'
import { LeaveConfirmation } from 'ui/components/LeaveConfirmation'
import { PageSection, PageSectionFace } from 'ui/components/Page'
import { Col, Col2x, FormRow, Row } from 'ui/themes/globalStyles'

import { GeneralInformationProps } from './GeneralInformationProps'

import * as SC from './styles'

export const GeneralInformation: FC<GeneralInformationProps> = memo(({ item, onSaveNotes }) => {
  const { config, isDirty, Field, set } = useFormData<{ infoNotes: string }>({
    initialValue: { infoNotes: item?.infoNotes }
  })
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    set({ reset: true, value: { infoNotes: item?.infoNotes } })
  }, [item])

  const onSaveWrapper = useCallback(async () => {
    try {
      setSubmitting(true)
      await onSaveNotes({ id: item?.quarterlyAnalyticsID, notes: config.value.infoNotes })
    } finally {
      setSubmitting(false)
    }
  }, [onSaveNotes, config.value, item?.quarterlyAnalyticsID])

  return (
    <LeaveConfirmation preventLeaving={isDirty && !submitting}>
      <PageSection face={PageSectionFace.SECONDARY} title="General Information" anchor="general">
        <SC.WrappedHr />
        <InfoSet direction={InfoSetDirection.COLUMN}>
          <Row>
            <Col>
              <Info label="Relationship Name">
                <SC.WrappedLink to="/relationships/$companyId" params={{ companyId: item.infoRelationshipID }}>
                  {item.infoRelationshipName || '---'}
                </SC.WrappedLink>
              </Info>
              <Info label="Period">{item?.infoPeriod}</Info>
            </Col>
          </Row>
          <Row>
            <Col>
              <Info label="Report Status">
                <QuarterlyReportStatusLabel name={item?.infoStatus} />
              </Info>
            </Col>
          </Row>
          {item?.infoNotes !== undefined && (
            <Form config={config}>
              <FormRow>
                <Col2x>
                  <Field name="infoNotes" label="Notes" input={TextareaInput} inputProps={{}} />
                </Col2x>
              </FormRow>
              {item?.enableSaveNotes && (
                <FormRow>
                  <Col2x>
                    <ButtonGroup margin="small">
                      <Button
                        onClick={onSaveWrapper}
                        disabled={!isDirty || submitting}
                        loading={submitting}
                        face="positive"
                      >
                        Save Notes{submitting}
                      </Button>
                    </ButtonGroup>
                  </Col2x>
                </FormRow>
              )}
            </Form>
          )}
        </InfoSet>
      </PageSection>
    </LeaveConfirmation>
  )
})
