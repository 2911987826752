import styled, { css } from 'styled-components'
import { Icon } from 'ui/components/Icon'

import { BaseMaskedInput } from './../BaseMaskedInput'

export const BaseInputStyled = styled(BaseMaskedInput)<{ $secureOn: boolean }>`
  ${(p) =>
    p.$secureOn
      ? css`
          font-family: 'text-security-disc';
        `
      : css``}
`

export const IconWrapper = styled(Icon)`
  cursor: pointer;
`
