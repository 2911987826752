import { HistoryAuditListFilter } from 'commons/types/DTO/bank/historyAudit'
import { isMatch, omit } from 'lodash'

export const checkIsTableFilterDirty = (
  defaultFilter: HistoryAuditListFilter,
  searchParams: HistoryAuditListFilter
): boolean => {
  const defaultFilterOther = omit(defaultFilter, ['page', 'size'])
  const searchParamsOther = omit(searchParams, ['page', 'size'])

  if (searchParams?.page !== 1 || !isMatch(defaultFilterOther, searchParamsOther)) return true

  return false
}
