import { useCallback, useEffect, useRef } from 'react'

import { useNavigate } from '@tanstack/react-router'
import { BankApplicationPaths } from 'commons/constants/routes'
import { useSubmitOnboardingDocuments, useSubmitRFIForm } from 'commons/hooks/client/onboarding'
import { PortalName } from 'commons/types/enums'
import { Toast } from 'ui/components/Toast'

import { OnboardingFormDispatch } from '../../types/actions'
import { FormsValidationHandlers, OnboardingFormsData } from '../../types/state'
import * as actions from './actions'
import { formValidate } from './helpers/formValidate'

type SubmitProps = {
  applicationId: number
  isFormSubmitting: boolean
  isAutosaveUpdating: boolean
  isShowClientNotesDialog: boolean
  currentStep: number
  formDispatch: OnboardingFormDispatch
  invalidateWebpageData: () => void
  formValue: OnboardingFormsData
  portalName: PortalName
  handleNotesSave: () => Promise<void>
  handleSaveRFI: (force: boolean) => Promise<void>
}

export const useSubmit = ({
  applicationId,
  currentStep,
  isFormSubmitting,
  isAutosaveUpdating,
  isShowClientNotesDialog,
  formDispatch,
  invalidateWebpageData,
  portalName,
  handleNotesSave,
  handleSaveRFI
}: SubmitProps) => {
  const navigate = useNavigate()
  // TODO: -> move to validation stuff
  const validateFunctionRefs = useRef<FormsValidationHandlers>()
  const shouldSubmitFirstStep = useRef<boolean>(false)
  const shouldSubmitSecondStep = useRef<boolean>(false)

  const { routine: submitRFIForm } = useSubmitRFIForm()
  const { routine: submitDocuments } = useSubmitOnboardingDocuments()

  const doSubmitFirstStep = useCallback(async () => {
    try {
      await handleSaveRFI(true)
      await submitRFIForm({ id: applicationId, p: portalName })

      Toast.success('Form was successfully submitted.')
      invalidateWebpageData()

      if (portalName === PortalName.BP) {
        navigate({ to: BankApplicationPaths.DETAILS, params: { id: applicationId } })
      }
    } catch (error) {
      if (!error.meta) {
        Toast.errorSmall('Something went wrong')
        formDispatch(actions.setIsFormSubmitting(false))
        return
      }
      formDispatch(actions.setSubmitErrors(error.meta))
      return
    }
    formDispatch(actions.setIsFormSubmitting(false))
  }, [formDispatch, handleSaveRFI, submitRFIForm, applicationId, portalName, invalidateWebpageData, navigate])

  const doSubmitSecondStep = useCallback(async () => {
    try {
      await submitDocuments({ id: applicationId })
      Toast.success('Documents were successfully submitted.')
      invalidateWebpageData()
    } catch (error) {
      formDispatch(actions.setGeneralErrors([error.message]))
      return
    }
    formDispatch(actions.setIsFormSubmitting(false))
  }, [formDispatch, applicationId, invalidateWebpageData, submitDocuments])
  // submit first step hook
  // -> submitStep1
  // should invoke save rfi form
  // wait for end of saving
  // loading status to submit button
  // client side validation
  // show errors or save client note dialog
  useEffect(() => {
    /**
      in submitStep1 we set shouldSubmitFirstStep.current to true
     this shows submit started
     + if isAutosaveUpdating === true
        we also terminate submitting
        and wait for changing isAutosaveUpdating to false
        terminate also client notes dialog showed isShowClientNotesDialog === true
    **/

    if (!shouldSubmitFirstStep.current || !isFormSubmitting || isAutosaveUpdating || isShowClientNotesDialog) {
      return
    }
    shouldSubmitFirstStep.current = false
    doSubmitFirstStep()
  }, [isFormSubmitting, isAutosaveUpdating, isShowClientNotesDialog, doSubmitFirstStep])

  useEffect(() => {
    if (!shouldSubmitSecondStep.current || !isFormSubmitting || isAutosaveUpdating || isShowClientNotesDialog) {
      return
    }

    shouldSubmitSecondStep.current = false
    doSubmitSecondStep()
  }, [isFormSubmitting, isAutosaveUpdating, isShowClientNotesDialog, doSubmitSecondStep])

  const submitStep1 = useCallback(async () => {
    if (isFormSubmitting) {
      return
    }
    // do validate
    // client validation
    const lastErrorCount = formValidate(validateFunctionRefs)
    if (lastErrorCount > 0) {
      formDispatch(actions.setErrors({ lastErrorCount }))
      return
    }

    // validate success
    formDispatch(actions.setIsFormSubmitting(true))
    shouldSubmitFirstStep.current = true

    if (portalName === PortalName.CP) {
      formDispatch(actions.setShowClientNoteDialog(true))
    }
  }, [formDispatch, isFormSubmitting, portalName])

  const submitStep2 = useCallback(async () => {
    formDispatch(actions.setIsFormSubmitting(true))
    shouldSubmitSecondStep.current = true
    formDispatch(actions.setShowClientNoteDialog(true))
  }, [formDispatch])

  const handleSubmitForm = useCallback(async () => {
    if (currentStep === 1) {
      submitStep1()
    }
    if (currentStep === 2) {
      submitStep2()
    }
  }, [submitStep1, submitStep2, currentStep])

  const handleCancelSaveNotesDialog = useCallback(() => {
    shouldSubmitFirstStep.current = false
    shouldSubmitSecondStep.current = false
    formDispatch(actions.setCancelClientNoteDialog())
  }, [formDispatch])

  const handleConfirmNotesSave = useCallback(
    async ({ isNoteChanged }: { isNoteChanged: boolean }) => {
      try {
        // MP-8264: do not save notes if value isn't changed
        if (isNoteChanged) {
          await handleNotesSave()
        }
        formDispatch(actions.setShowClientNoteDialog(false))
      } catch (error) {
        handleCancelSaveNotesDialog()
        Toast.errorSmall('Notes saving failed.')
      }
    },
    [handleNotesSave, handleCancelSaveNotesDialog, formDispatch]
  )

  return {
    validateFunctionRefs,
    handleSubmitForm,
    handleCancelSaveNotesDialog,
    handleConfirmNotesSave
  }
}
