import React from 'react'

import { TableDataQa } from 'commons/types/enums'
import { PageSection, PageSectionFace, Panel, PanelFace } from 'ui/components'
import { Link } from 'ui/components/Link'
import { Table } from 'ui/components/Table'

import { Tag } from '../../../../../../components/Tag'
import { BSAStatus } from '../../../../../../types'
import { BatchItemsProps } from './BatchItems.types'

import { s } from './styles'

export const BatchItems = ({ items, type }: BatchItemsProps) => (
  <PageSection face={PageSectionFace.SECONDARY} title={`${type === 'ctr' ? 'CTR' : 'SAR'}s in the Batch`} anchor="sars">
    <s.HrWithBottomMargin />
    <Panel rounded shadowed collapsible={false} face={PanelFace.SECONDARY}>
      <Table<(typeof items)[0]>
        dataQa={TableDataQa.BP_BATCH_SARS}
        columns={[
          {
            title: 'Name',
            align: 'left',
            render: ({ name, id }) => (
              <Link to={`/bsa/${type === 'ctr' ? 'ctrs' : 'sars'}/$id`} params={{ id }}>
                {name}
              </Link>
            )
          },
          {
            title: 'Status',
            align: 'right',
            render: ({ status }) => <Tag status={status.toLocaleLowerCase() as BSAStatus} />
          }
        ]}
        dataSource={items.map((item) => ({ item }))}
      />
    </Panel>
  </PageSection>
)
