import React, { FC, memo } from 'react'

import { RadioButtonProps } from './RadioButtonProps'

import * as SC from './styles'

export const RadioButton: FC<RadioButtonProps> = memo((props) => {
  const {
    readonly = false,
    dataQa = undefined,
    disabled = false,
    error = '',
    required = false,
    name = '',
    checked = false,
    label = '',
    value,
    onChange
  } = props

  return (
    <>
      {((readonly && checked) || !readonly) && (
        <SC.RadioItemContainer
          disabled={disabled}
          error={error}
          readOnly={readonly}
          data-qa={dataQa ? `${dataQa} - ${label}` : null}
        >
          <input
            type="radio"
            required={required}
            disabled={disabled}
            value={value.toString()}
            name={name}
            checked={checked}
            onChange={onChange ? onChange : undefined}
          />
          <SC.Checkmark className="checkmark" disabled={disabled} error={error} />
          <SC.Label disabled={disabled} error={error} readOnly={readonly}>
            {label}
          </SC.Label>
        </SC.RadioItemContainer>
      )}
    </>
  )
})
