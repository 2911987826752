import { FieldProps, FormConfig, FormInputProps } from 'brief-form'
import { Contact } from 'commons/types/DTO/bank'

import { ContactMetadataFormValue } from '../../../../../../types/forms'

export interface ContactMetadataManageFormProps {
  config: FormConfig<ContactMetadataFormValue>
  Field: <Input extends React.ComponentType<FormInputProps<any, any>>>(
    props: FieldProps<Input, ContactMetadataFormValue>
  ) => JSX.Element
  contact?: Contact
  companyId?: number
}
