import styled from 'styled-components'
import { Hr, Info } from 'ui/components'
import { CSS } from 'ui/helpers'

const StyledHr = styled(Hr)`
  margin: 0;
`

const InfoWithHint = styled(Info)`
  padding-bottom: 0px;
`

const InfoWarning = styled.div`
  border-left: 4px solid ${(p) => p.theme.uiKit.colors.theme.negative.main};
  padding: 2px;
  padding-left: 17px;
`

const InfoWarningLabel = styled.p`
  color: ${(p) => p.theme.uiKit.colors.global.grey.grey700};
  margin-bottom: 3px;
`

const InfoWarningContent = styled.p`
  ${(p) => CSS.populateFont(p.theme.uiKit.typography.bodyL.regular)};
`

export const s = { StyledHr, InfoWarning, InfoWithHint, InfoWarningContent, InfoWarningLabel }
