import React from 'react'

import { SiteDocuments } from 'commons/types/DTO/bank'
import { FrequencyLabel } from 'ui/components/Labels'
import { Link } from 'ui/components/Link'
import { TableColumn } from 'ui/components/Table'

import { LastNote } from './LastNotes/LastNotes'

export const columns = (): TableColumn<any>[] => [
  {
    title: 'Relationship',
    align: 'left',
    sortable: true,
    key: 'relationship_name',
    render: (item: SiteDocuments) => <Link to={`/relationships/${item.relationship_id}`}>{item.relationship_name}</Link>
  },
  {
    title: 'License Number',
    key: 'license_number',
    align: 'left',
    sortable: true
  },
  {
    title: 'Street',
    key: 'street',
    align: 'left',
    sortable: true
  },
  {
    title: 'City',
    key: 'city',
    align: 'left',
    sortable: true
  },
  {
    title: 'State',
    key: 'state',
    align: 'left',
    sortable: true
  },
  {
    title: 'Postal Code',
    key: 'postal_code',
    align: 'left',
    sortable: true
  },
  {
    title: 'Next Due Date',
    key: 'next_due_date',
    align: 'left',
    sortable: true
  },
  {
    title: 'Last Period Approved',
    key: 'last_period_approved',
    align: 'left',
    sortable: true
  },
  {
    title: 'Frequency',
    align: 'left',
    sortable: true,
    key: 'frequency',
    render: ({ frequency }) => <FrequencyLabel name={frequency} />
  },
  {
    title: 'Last Notes',
    align: 'left',
    sortable: true,
    key: 'last_notes',
    render: (item: SiteDocuments) => <LastNote note={item.last_notes} id={item.document_id} />
  }
]
