import React, { FC, memo, useCallback } from 'react'

import { IconName, IconSize } from 'ui/components/Icon'

import { AuthFormProps } from './AuthFormProps'

import * as SC from './styles'

export const AuthForm: FC<AuthFormProps> = memo((props) => {
  const { children, logo, error, info, footer } = props
  const preventSubmit = useCallback((event) => event.preventDefault(), [])

  return (
    <SC.Container>
      {!!error && (
        <SC.Error data-qa={error}>
          <SC.Exclamation name={IconName.EXCLAMATION_WHITE} size={IconSize.S} />
          <SC.Text>{error}</SC.Text>
        </SC.Error>
      )}
      {!!info && !error && (
        <SC.Info>
          <SC.Exclamation name={IconName.EXCLAMATION_WHITE} size={IconSize.S} />
          <SC.Text>{info}</SC.Text>
        </SC.Info>
      )}
      <SC.Form onSubmit={preventSubmit} $error={!!error || !!info}>
        {logo}
        {children}
      </SC.Form>
      {footer}
    </SC.Container>
  )
})
