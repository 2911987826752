import React, { FC, memo } from 'react'

import { Request } from 'commons/utils/request'
import { ChoiceOption } from 'ui/types'

import { SingleSelectAutoSuggest } from '../SingleSelectAutoSuggest'
import { BankAccountSelectProps } from './BankAccountSelectProps'

const PLACEHOLDER = 'Start entering the bank account'

export const BankAccountSelect: FC<BankAccountSelectProps> = memo((props) => {
  const { value, defaultOption, filters, isSearchRequest = false, ...rest } = props
  const options: ChoiceOption[] = []

  if (defaultOption) {
    options.push(defaultOption)
  }

  const serverOptions = isSearchRequest
    ? {
        filters,
        url: Request.urls.bank.bankAccountSearch,
        requestSearchString: 'searchString',
        limit: 500,
        mapper: (item) => ({ label: `${item.formattedNumber}`, value: item.id })
      }
    : {
        filters,
        valueItemFilter: value ? { field: 'id', type: 'eq', value } : undefined,
        url: '/bank/v1/bank-account-list',
        limit: 500,
        mapper: (item) => ({ label: item.number.substring(item.number.length - 4), value: item.id }),
        searchFields: ['number']
      }

  return (
    <SingleSelectAutoSuggest
      noFirstRequest
      options={options}
      placeholder={PLACEHOLDER}
      serverOptions={serverOptions}
      value={value}
      {...rest}
    />
  )
})
