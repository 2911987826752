import React, { FC, memo } from 'react'

import { TextButtonProps } from './TextButtonProps'

import * as SC from './styles'

export const TextButton: FC<TextButtonProps> = memo((props) => {
  const { children, overColoredPad = false, disabled, 'data-qa': dataQA, ...rest } = props

  const data_qa = typeof children === 'string' && !dataQA ? children : dataQA

  return (
    <SC.Box $overColoredPad={overColoredPad} disabled={disabled} data-qa={data_qa} {...rest}>
      <SC.Inner>{children}</SC.Inner>
    </SC.Box>
  )
})
