import React, { memo, useEffect, useState } from 'react'

import { Request } from 'commons/utils/request'
import { Page } from 'ui/components/Page'
import { Spinner } from 'ui/components/Spinner'

import { Container, ErrorBox } from './style'

const NextCloudContainer = memo(() => {
  const [isPending, setPending] = useState(false)
  const [error, setError] = useState('')

  useEffect(() => {
    try {
      setPending(true)

      const urlParams = new URLSearchParams(window.location.search)
      const clientId = urlParams.get('client_id')
      const state = urlParams.get('state')
      const redirectUri = urlParams.get('redirect_uri')
      const responseType = urlParams.get('response_type')

      if (!redirectUri) {
        throw new Error('Empty redirect_uri')
      }

      Request.post(Request.urls.bank.authNextcloudCode, {
        client_id: clientId,
        state: state,
        redirect_uri: redirectUri,
        response_type: responseType
      })
        .then((response) => {
          window.location.assign(`${redirectUri}?code=${response.code}&state=${state}`)
        })
        .catch((error) => {
          setError(error.message)
        })
    } catch (error: unknown) {
      setError((error as Error).message)
    } finally {
      setPending(false)
    }
  }, [setPending])

  return (
    <Page isPending={isPending}>
      <Container>
        {!!error && (
          <ErrorBox>
            <b>Error:</b> {error}
          </ErrorBox>
        )}
        {isPending && <Spinner />}
      </Container>
    </Page>
  )
})

export default NextCloudContainer
