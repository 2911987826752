import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'

import styled from 'styled-components'

export const ReactTooltipMultiline = styled(Tooltip)`
  white-space: pre-line;
  z-index: 1000; // TODO: but some troubles on CP without this

  color: ${(p) => p.theme.uiKit.colors.global.general.main};

  &,
  * {
    font-family: ${(p) => p.theme.uiKit.typography.bodyS.regular.fontFamily};
    font-weight: ${(p) => p.theme.uiKit.typography.bodyS.regular.fontWeight};
    font-size: ${(p) => p.theme.uiKit.typography.bodyS.regular.fontSize} !important;
    line-height: ${(p) => p.theme.uiKit.typography.bodyS.regular.lineHeight};
    letter-spacing: ${(p) => p.theme.uiKit.typography.bodyS.regular.letterSpacing};
    text-transform: ${(p) => p.theme.uiKit.typography.bodyS.regular.textCase};
    text-decoration: ${(p) => p.theme.uiKit.typography.bodyS.regular.textDecoration};
    margin: ${(p) => p.theme.uiKit.typography.bodyS.regular.paragraphSpacing};
  }
`
