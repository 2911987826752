import { CompanyInformation } from 'commons/types/DTO/commons'

import { CompanyInformationFormValue } from '../../../../types'

export const mapCompanyInformationForm = ({
  companyID,
  entityUID,
  legalName,
  DBA,
  phone,
  website,
  description
}: CompanyInformationFormValue): CompanyInformation => ({
  companyID: +companyID,
  entityUID,
  legalName,
  DBA,
  phone,
  website,
  description
})
