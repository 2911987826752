import styled from 'styled-components'

const Header = styled.div`
  background-color: ${(p) => p.theme.uiKit.colors.global.grey.grey300};
  margin: 0 -50px 30px -50px;
  padding: 12px 50px;
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const StatusBadgeWrapper = styled.div`
  & > * {
    margin: auto 0;
  }
`

export const s = { Header, StatusBadgeWrapper }
