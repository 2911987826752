import React from 'react'

import { FF, TextInput, Validators } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

export const City = () => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name="institution_contact_info.city"
      validators={{
        onChange: ({ value }) => Validators.required.field(value)
      }}
      children={(field) => (
        <FF
          required
          label="83. City"
          field={field}
          input={(p) => <TextInput {...p} maxLength={50} data-qa="83. City" />}
        />
      )}
    />
  )
}
