import { getCompanyDetailsWebPage } from 'commons/service/bank/companyDetails'
import { CompanyDetailsWebPage } from 'commons/types/DTO/bank'
import { Keys, ReadHook, useStateManager } from 'state-manager'

export const useGetCompanyDetailsWebPage: ReadHook<CompanyDetailsWebPage, number> = (relationshipID: number) => {
  const sm = useStateManager()

  const { data, ...rest } = sm.use(
    Keys.CompanyDetails.WebPage(relationshipID),
    () => getCompanyDetailsWebPage(relationshipID),
    {
      keepPreviousData: true
    }
  )

  return {
    ...rest,
    data
  }
}
