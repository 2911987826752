import { ReadHook, WriteHook, useMutationManager, useStateManager } from 'react-query-state-manager'

import { Keys } from 'state-manager'

import { api } from './api'
import {
  ChangeActiveStatusPayload,
  CreateBankUserRequest,
  GetResetUrlPayload,
  ResetUrlResponse,
  SendResetUrlPayload,
  UpdateBankUserRequest,
  UsersWebPagePayload,
  UsersWebPageResponse
} from './types'

const useCreate: WriteHook<CreateBankUserRequest, number> = (opts) => useMutationManager(api.create, opts)

const useUpdate: WriteHook<UpdateBankUserRequest, void> = (opts) => useMutationManager(api.update, opts)

const useChangeActiveStatus: WriteHook<ChangeActiveStatusPayload, void> = (opts) =>
  useMutationManager(api.changeActiveStatus, opts)

const useGetResetUrl: WriteHook<GetResetUrlPayload, ResetUrlResponse> = (opts) =>
  useMutationManager<GetResetUrlPayload, ResetUrlResponse>(api.getResetUrl, opts)

const useSendResetUrl: WriteHook<SendResetUrlPayload, unknown> = (opts) =>
  useMutationManager<SendResetUrlPayload, unknown>(api.sendResetUrl, opts)

const useListPage: ReadHook<UsersWebPageResponse, UsersWebPagePayload> = (opts) =>
  useStateManager().use(
    Keys.UserList.List(opts?.searchTerm, opts?.active, opts?.page, opts?.size),
    () => api.getListPage(opts),
    { keepPreviousData: true }
  )

export const hooks = {
  useCreate,
  useUpdate,
  useChangeActiveStatus,
  useListPage,
  useGetResetUrl,
  useSendResetUrl
}
