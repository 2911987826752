export class ApiError extends Error {
  public meta: any
  public httpCode?: number

  constructor(message: string, meta: any, httpCode?: number) {
    super(message)
    this.name = 'ApiError'
    this.meta = meta
    this.httpCode = httpCode
  }
}
