/* eslint-disable camelcase */

export type Company = {
  accountingSyncStatus: string
  active: boolean
  bankSyncStatus: string
  business_type: string
  cif: string
  city: string
  country: string
  created_at: string
  customer_status: string
  dateFounded: string
  dba: string
  deleted_at: string
  description: string
  ein: string
  employees: number | null
  entity_type: string
  fax: string
  hasAccountingPlatform: boolean
  holding_id: number | null
  id: number
  is_holding: boolean
  lastAccountingSyncAt: string
  lastBankSyncAt: string
  lastManualDataUpdateAt: string
  legal_name: string
  name: string
  organization_id: number
  phone: string
  postal_code: string
  primaryDataSource: string
  report_alerts_criteria: number
  reportedAccountingPlatform: string
  reportedAccountingPlatformUpdateFrequency: string
  requiredApprovalsAmount: number
  requiredApprovalsCount: number
  sf_acc_id: string
  state: string
  stateFounded: string
  street: string
  updated_at: string
  website: string
  mailing_city: string
  mailing_country: string
  mailing_postal_code: string
  mailing_state: string
  mailing_street: string
  tags: string | string[]
  meta?: {
    application_id: number
    assigned_bank_users:
      | {
          id: string
          name: string
        }[]
      | null
    holding_name: string
    holding_group_companies:
      | {
          id: number
          name?: string
        }[]
      | null
  }
}
