import styled, { css } from 'styled-components'
import { Icon } from 'ui/components/Icon'

import { BaseInput } from '../BaseInput'

export const IconWrapper = styled(Icon)<{ $disabled: boolean; $notEmpty: boolean }>`
  ${(p) =>
    !p.$notEmpty
      ? css`
          color: ${(p) => p.theme.uiKit.colors.global.grey.grey500};

          svg {
            color: ${(p) => p.theme.uiKit.colors.global.grey.grey500};
            fill: ${(p) => p.theme.uiKit.colors.global.grey.grey500};
          }
        `
      : css`
          color: ${(p) => p.theme.uiKit.colors.global.grey.grey700};

          svg {
            color: ${(p) => p.theme.uiKit.colors.global.grey.grey700};
            fill: ${(p) => p.theme.uiKit.colors.global.grey.grey700};
          }

          cursor: pointer;
        `}

  ${(p) =>
    p.$disabled
      ? css`
          color: ${(p) => p.theme.uiKit.colors.global.grey.grey300};

          svg {
            color: ${(p) => p.theme.uiKit.colors.global.grey.grey300};
            fill: ${(p) => p.theme.uiKit.colors.global.grey.grey300};
          }

          pointer-events: none;
          cursor: not-allowed;
        `
      : null}
`

export const BaseInputStyled = styled(BaseInput)<{ $disabled?: boolean; $error?: boolean }>`
  &:focus-within {
    ${IconWrapper} {
      ${(p) =>
        !p.$disabled && !p.$error
          ? css`
              color: ${(p) => p.theme.uiKit.colors.global.grey.grey700};

              svg {
                color: ${(p) => p.theme.uiKit.colors.global.grey.grey700};
                fill: ${(p) => p.theme.uiKit.colors.global.grey.grey700};
              }
            `
          : null}
    }
  }
`
