import { useCallback, useMemo, useState } from 'react'

import { notFound } from '@tanstack/react-router'
import { BeforeFormChangeHandler, useFormData } from 'brief-form'
import { useGetApplicationPipelineReport } from 'commons/hooks/bank/report'
import { ApplicationPipelineReportFilter, ApplicationPipelineReportItem } from 'commons/types/DTO/bank'
import { DataSourceItem, useTableState } from 'ui/components'

const DEFAULT_FILTER: ApplicationPipelineReportFilter = {
  page: 1,
  size: 10,
  status: null
}

export const useApplicationPipelineReportEssence = () => {
  const [filter, setFilter] = useState<ApplicationPipelineReportFilter>(DEFAULT_FILTER)

  const { isFetched, isFetching, data } = useGetApplicationPipelineReport(filter)

  const onBeforeChange: BeforeFormChangeHandler<ApplicationPipelineReportFilter> = useCallback(
    ({ value, oldValue }) => {
      const isFilterChanged = value.status !== oldValue.status
      if (isFilterChanged) value = { ...value, page: DEFAULT_FILTER.page }

      setFilter(value)
      return {
        value,
        errors: {}
      }
    },
    []
  )

  const filterForm = useFormData({ initialValue: filter, onBeforeChange })
  const { config, set } = filterForm

  const onPageChange = useCallback(
    (page: number, size: number) => {
      config.onChange({ ...filter, page, size }, {})
    },
    [filter]
  )

  const onClear = useCallback(() => {
    set({ reset: true })
    setFilter(DEFAULT_FILTER)
  }, [filter])

  const dataSource: DataSourceItem<ApplicationPipelineReportItem>[] = useMemo(
    () => data?.records?.map((item) => ({ item })),
    [data]
  )

  const tableState = useTableState({ filter: {} })

  if (isFetched && !data) {
    throw notFound()
  }

  return {
    isFetching,
    dataSource,
    config,
    filterForm,
    tableState,
    totalCount: data?.totalCount,
    onPageChange,
    onClear,
    statusOptions: data?.statusOptions
  }
}
