import { PortalName } from 'commons/types/enums'
import { Request } from 'commons/utils/request'

const urls = {
  [PortalName.BP]: Request.urls.bank.deviceList,
  [PortalName.CP]: Request.urls.client.deviceList,
  [PortalName.BOP]: Request.urls.support.deviceList
}

export const dataSources = (p: PortalName) => [
  {
    key: 'main',
    url: urls[p]
  }
]
