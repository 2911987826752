import { Link } from '@tanstack/react-router'
import SC from 'styled-components'
import { Icon } from 'ui/components/Icon'
import { CSS } from 'ui/helpers'

export const Row = SC.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`

export const Name = SC.div`
  margin: 0 10px;
  white-space: nowrap;
  ${(p) => CSS.populateFont(p.theme.uiKit.typography.bodyM.semiBold)};
`

export const ArrowIcon = SC(Icon)<{ $open: boolean }>`
  transition: all;
  transform: rotate(${(p) => (p.$open ? '180deg' : '0')});
  margin-top: ${(p) => (p.$open ? '0px' : '3px')};
  margin-bottom: ${(p) => (p.$open ? '3px' : '3px')};
  margin-left: ${(p) => (p.$open ? '6px' : '10px')};
  margin-right: ${(p) => (p.$open ? '10px' : '6px')};
  svg {
    width: 12px;
    height: 12px;
    fill: ${(p) => p.theme.uiKit.colors.global.grey.grey700};
  }
`

export const MenuItemIcon = SC(Icon)`
  margin-right: 10px;
  height: 24px;
  fill: ${(p) => p.theme.uiKit.colors.global.grey.grey500};
`

export const MenuItem = SC(Link)`
  width: 230px;
  color: ${(p) => p.theme.uiKit.colors.global.grey.grey900} !important;
  text-decoration: none;
  padding: 10px 10px;
  display: flex;
  align-items: center;
  
  &:hover {
    text-decoration: none;
    color: ${(p) => p.theme.uiKit.colors.global.grey.grey900} !important;
    background: ${(p) => p.theme.uiKit.colors.global.grey.grey100};
    
    i {
      fill: ${(p) => p.theme.uiKit.colors.theme.brand.primary};
    }
  }
`
