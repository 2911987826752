import { DISABLED } from 'commons/constants/internalTransfers'
import { getBankAccountList } from 'commons/service/bank/bankAccount'
import { BankAccountDeprecated } from 'commons/types/DTO/bank'
import { ApiReadResponse } from 'commons/types/api'

export const getHandler = (id: number, companyId: number) => (): Promise<ApiReadResponse<BankAccountDeprecated>> =>
  id
    ? getBankAccountList({ _options: { filters: [{ field: 'id', type: 'eq', value: id }] } })
    : Promise.resolve({
        records: [
          {
            id: 0,
            company_id: companyId,
            number: '',
            notes: '',
            created_at: null,
            updated_at: null,
            licensed: false,
            license_ids: [],
            opening_date: null,
            internal_transfers_type: DISABLED
          }
        ]
      })
