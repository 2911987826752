import { UseFormDataReturnType } from 'brief-form'
import { InternalTransferList } from 'commons/types/DTO/bank'
import { TableState } from 'query-table'

import { InternalTransferTableFilterValue } from '../../types'

export type InternalTransferTableProps = {
  items: InternalTransferList[]
  tableState: TableState
  filterForm: UseFormDataReturnType<InternalTransferTableFilterValue>
  setLoading: (isLoading: boolean) => void
  loading: boolean
  totalCount: number
  statuses: {
    [key: string]: string
  }
}
