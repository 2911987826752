import styled from 'styled-components'

const Row = styled.div`
  display: flex;

  > * {
    width: 400px;

    &:first-child {
      margin-right: 100px;
    }
  }
`

export const s = { Row }
