export const filingInstitutionIDTypes = [
  {
    label: 'Central Registration Depository (CRD) number',
    value: 'Central Registration Depository (CRD) number'
  },
  {
    label: 'Investment Adviser Registration Depository (IARD) number',
    value: 'Investment Adviser Registration Depository (IARD) number'
  },
  { label: 'National Futures Association (NFA) number', value: 'National Futures Association (NFA) number' },
  {
    label: 'Research, Statistics, Supervision and Discount (RSSD) number',
    value: 'Research, Statistics, Supervision and Discount (RSSD) number'
  },
  {
    label: 'Securities and Exchange Commission (SEC) number',
    value: 'Securities and Exchange Commission (SEC) number'
  }
]
