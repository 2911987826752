/* eslint-disable camelcase */

export type InternalTransferDeprecated = {
  amount: number
  approval_date: string
  approvals_count: number
  created_at: string
  date: string
  deleted_at: string
  export_id: number
  id: number
  manifest_number: string
  notes: string
  recipient_license_id: number
  recipient_bank_account_id: number
  required_approvals_count: number
  sender_license_id: number
  sender_bank_account_id: number
  sf_external_id: string
  status: string
  updated_at: string
  meta?: {
    recipient_company_id: number
    recipient_company_name: string
    recipient_name: string
    sender_company_id: number
    sender_company_name: string
    sender_name: string
  }
}
