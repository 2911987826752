import React, { memo, useCallback } from 'react'

import { SalesToDepositsComparison } from 'commons/types/DTO/bank/quarterlyReport/bankAccount/SalesToDepositsComparison'
import { formatCurrency } from 'commons/utils'
import SC from 'styled-components'
import { Layer } from 'ui/components/Layer'

interface Props {
  item: SalesToDepositsComparison
}

//region Styles

const WrappedLayer = SC(Layer)`
  display: inline-block;
  margin-bottom: 47px;
  width: 100%;
`

const Box = SC.div`
  border-top: 2px solid #000;
`

const Title = SC.h2`
  padding: 10px 20px;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  margin: 0;
`

const Columns = SC.div`
  display: flex;
`

const Labels = SC.div`
  background: ${(p) => p.theme.uiKit.colors.global.grey.grey100};
  padding: 38px 0 0 0;
`

const Label = SC.div`
  height: 34px;
  padding: 9px 16px;
  font-size: 13px;
  line-height: 16px;
`

const Table = SC.table`
  border-collapse: collapse;
`

const Th = SC.th<{ $bottomBorder?: boolean }>`
  font-size: 14px;
  line-height: 22px;
  color: #fff;
  background: ${(p) => p.theme.uiKit.colors.global.grey.grey900};
  padding: 8px 30px;
  border-right: 1px solid #fff;
  ${(p) => (p.$bottomBorder ? 'border-bottom: 1px solid #fff' : '')};
  
  &:last-child {
    border-right: none;
  }
`

const Td = SC.td<{ $hl?: boolean }>`
  font-size: 13px;
  line-height: 16px;
  padding: 9px 16px;
  text-align: center;
  border-right: 1px solid ${(p) => p.theme.uiKit.colors.global.grey.grey100};
  background: ${(p) => (p.$hl ? p.theme.uiKit.colors.theme.negative.main : 'transparent')};
  
  &:last-child {
    border-right: none;
  }
`

const Stub = SC.td`
  background: ${(p) => p.theme.uiKit.colors.global.grey.grey100};
`

const TV = SC.span<{ $negative?: boolean }>`
  color: ${(p) =>
    p.$negative ? p.theme.uiKit.colors.theme.negative.main : p.theme.uiKit.colors.global.general.secondary};
`

//endregion

export const SalesToDepositComparisonTable = memo((props: Props) => {
  const { item } = props

  const PQ = useCallback((v: number) => {
    if (v === -1) {
      return 'N/A'
    }

    return v >= 0 ? <TV>{v.toFixed(2)}%</TV> : <TV $negative>({(-v).toFixed(2)})%</TV>
  }, [])

  return (
    <WrappedLayer rounded shadowed>
      <Box>
        <Title>SALES TO DEPOSIT COMPARISON</Title>
        <Columns>
          <Labels>
            <Label>Product Sales</Label>
            <Label>Taxes</Label>
            <Label>Total Sales</Label>
            <Label>Total Deposits</Label>
            <Label>$ Difference</Label>
            <Label>% Difference</Label>
          </Labels>
          <Table>
            <thead>
              <tr>
                <Th>Total</Th>
                <Th>{item.firstMonth.header}</Th>
                <Th>{item.secondMonth.header}</Th>
                <Th>{item.thirdMonth.header}</Th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <Td>{formatCurrency(item.total.sales, false)}</Td>
                <Td>{formatCurrency(item.firstMonth.sales, false)}</Td>
                <Td>{formatCurrency(item.secondMonth.sales, false)}</Td>
                <Td>{formatCurrency(item.thirdMonth.sales, false)}</Td>
              </tr>
              <tr>
                <Td>{formatCurrency(item.total.estimatedTaxes, false)}</Td>
                <Td>{formatCurrency(item.firstMonth.estimatedTaxes, false)}</Td>
                <Td>{formatCurrency(item.secondMonth.estimatedTaxes, false)}</Td>
                <Td>{formatCurrency(item.thirdMonth.estimatedTaxes, false)}</Td>
              </tr>
              <tr>
                <Td>{formatCurrency(item.total.totalSales, false)}</Td>
                <Td>{formatCurrency(item.firstMonth.totalSales, false)}</Td>
                <Td>{formatCurrency(item.secondMonth.totalSales, false)}</Td>
                <Td>{formatCurrency(item.thirdMonth.totalSales, false)}</Td>
              </tr>
              <tr>
                <Td>{formatCurrency(item.total.totalDeposits, false)}</Td>
                <Td>{formatCurrency(item.firstMonth.totalDeposits, false)}</Td>
                <Td>{formatCurrency(item.secondMonth.totalDeposits, false)}</Td>
                <Td>{formatCurrency(item.thirdMonth.totalDeposits, false)}</Td>
              </tr>
              <tr>
                <Td>{formatCurrency(item.total.differenceAbsolute, false)}</Td>
                <Stub />
                <Stub />
                <Stub />
              </tr>
              <tr>
                <Td $hl={item.total.differenceRelative.alerted}>{PQ(item.total.differenceRelative.percent)}</Td>
                <Stub />
                <Stub />
                <Stub />
              </tr>
              <tr>
                <Stub />
                <Stub />
                <Stub />
              </tr>
            </tbody>
          </Table>
        </Columns>
      </Box>
    </WrappedLayer>
  )
})
