import React, { FC } from 'react'

import { FieldProps, FormInputProps } from 'brief-form'
import { Questionnaire } from 'commons/types/DTO/bank/quarterlyReport/questionnaire'
import { Validators } from 'ui/components/Form'
import { Select } from 'ui/components/Select'
import { Col, FormRow } from 'ui/themes/globalStyles'

import { SecondText } from '../../style'

type CurrentSARFilingStatusProps = {
  Field: <Input extends React.ComponentType<FormInputProps<any, any>>>(
    props: FieldProps<Input, Questionnaire>
  ) => JSX.Element
}

export const CurrentSARFilingStatus: FC<CurrentSARFilingStatusProps> = ({ Field }) => (
  <>
    <SecondText>
      <i>Current SAR Filing Status:</i>
    </SecondText>
    <FormRow>
      <Col>
        <Field
          required
          name="currentSARFilingStatus"
          label=""
          input={Select}
          inputProps={{
            data: [
              {
                label: 'Limited SAR',
                value: 'limited_sar'
              },
              {
                label: 'Priority SAR',
                value: 'priority_sar'
              },
              {
                label: 'Termination SAR',
                value: 'termination_sar'
              }
            ]
          }}
          validator={Validators.required.field}
        />
      </Col>
    </FormRow>
  </>
)
