import { useEffect } from 'react'

export const useStorageListener = () => {
  const handleStorageChanges = (event: any) => {
    console.log(event)
  }

  useEffect(() => {
    console.log('useStorageListener')

    window.addEventListener('storage', handleStorageChanges)

    return () => {
      window.removeEventListener('storage', handleStorageChanges)
    }
  }, [handleStorageChanges])
}
