import { useState } from 'react'

import { UsersWebPageResponse } from '../../../types'

export const useEditUser = () => {
  const [userToEdit, setUserToEdit] = useState<UsersWebPageResponse['users'][0] | null>(null)
  const onEdit = (user: UsersWebPageResponse['users'][0]) => setUserToEdit(user)
  const closeEditDialog = () => setUserToEdit(null)

  return {
    onEdit,
    userToEdit,
    closeEditDialog
  }
}
