import styled from 'styled-components'
import { CSS } from 'ui/helpers/cssHelpers'

import { BaseInput } from '../InputV2'

export const Box = styled.div`
  width: 255px;
`

export const InputWrapper = styled(BaseInput)`
  border-radius: 100px;
  height: 44px;
  border: 1px solid ${(p) => p.theme.uiKit.colors.global.grey.grey300};
  input::placeholder {
    ${(p) => CSS.populateFont(p.theme.uiKit.typography.bodyM.regular)}
    color: ${(p) => p.theme.uiKit.colors.global.grey.grey700}
  }
  input {
    padding: 0 12px;
  }
`
