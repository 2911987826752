import React from 'react'

import { FF, TextInput } from 'ui/components'

import { useSARFormContext } from '../../../../../../context'

type BusinessTypeProps = {
  index: number
}

export const BusinessType = ({ index }: BusinessTypeProps) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={`subjects_info[${index}].business_type` as 'subjects_info[0].business_type'}
      children={(field) => (
        <FF field={field} label="10. Occupation or type of business" input={(p) => <TextInput {...p} />} />
      )}
    />
  )
}
