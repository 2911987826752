import React, { FC, memo } from 'react'

import { useParams } from '@tanstack/react-router'
import { COMPANIES_PATH } from 'commons/constants/routes'
import { Page, PageFace } from 'ui/components/Page'

import { ManageCompanyForm, useManageCompanyFormContext } from './components/ManageCompanyForm'
import { Footer } from './components/ManageCompanyForm/components/Footer'
import { useManageCompanyPage } from './hooks/useManageCompanyPage'

export const ManageCompanyPage: FC = memo(() => {
  const { companyId } = useParams({ strict: false })

  const { company, isFetching, onDeleteCompany, DeleteConfirmation } = useManageCompanyPage(Number(companyId) || 0)

  const { data } = useManageCompanyFormContext()

  return (
    <Page
      title="Company Profile"
      face={PageFace.SECONDARY}
      isPending={isFetching}
      subTitle={company?.name || (!!company && !company.id && 'New Company') || '...'}
      footer={
        <Footer
          returnUrl={company?.id ? `${COMPANIES_PATH}/${data?.value.id}` : COMPANIES_PATH}
          onSubmit={data?.submitHandler}
          onDelete={company?.id ? data?.deleteHandler : undefined}
          isSubmitting={data?.isSubmitting ?? false}
        />
      }
    >
      <DeleteConfirmation />
      {!!company && !isFetching && <ManageCompanyForm initialValue={company} onDelete={onDeleteCompany} />}
    </Page>
  )
})
