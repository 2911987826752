import { FormPaths } from '../../../enums'
import {
  ATMAdditionalParams,
  ContactAdditionalParams,
  LicenseAdditionalParams,
  OnChangeAdditionParams
} from '../../../types/actions'
import {
  // atms
  ATMGeneralInfoFormData,
  ATMServicesFormData,
  AnticipatedMonthlyActivityFormData,
  AnticipatedMonthlyActivityNAFormData, //
  AvailableServicesFormData,
  CompanyAccountDetailsFormData,
  CompanyInformationFormData,
  CompanyMailingAddressFormData,
  CompanyPhysicalAddressFormData,
  ContactAdditionalInformationFormData, // Contacts
  ContactDetailsFormData,
  FormData,
  LicenseAddressInformationFormData, // Licenses
  LicenseDetailsFormData,
  MerchantQuestionnaireFormData,
  NotesFormData,
  ProfessionalPartnershipFormData, //
  QuestionnaireFormData,
  RoleInformationFormData
} from '../../../types/forms'
import { OnboardingFormState, OnboardingFormsData } from '../../../types/state'
import { companyAccountDetailsToState } from '../helpers/companyAccountDetailsToState'
import { companyMailingAddressToState } from '../helpers/companyMailingAddressToState'
import { companyMerchantQuestionnaireToState } from '../helpers/companyMerchantQuestionnaireToState'
import { companyPhysicalAddressToState } from '../helpers/companyPhysicalAddressToState'
import { contactAdditionalInformationToState } from '../helpers/contactAdditionalInformationToState'
import { contactDetailsToState } from '../helpers/contactDetailsToState'
import { anticipatedMonthlyActivityNAToState } from './anticipatedMonthlyActivityNAToState'
import { anticipatedMonthlyActivityToState } from './anticipatedMonthlyActivityToState'
import { atmGeneralInfoToState } from './atmGeneralInfoToState'
import { atmServicesToState } from './atmServicesToState'
import { availableServicesToState } from './availableServicesToState'
import { companyInformationToState } from './companyInformationToState'
import { licenseAddressInformationToState } from './licenseAddressInformationToState'
import { licenseDetailsToState } from './licenseDetailsToState'
import { notesFormToState } from './notesFormToState'
import { professionalPartnershipsToState } from './professionalPartnershipsToState'
import { questionnaireToState } from './questionnaireToState'
import { roleInformationToState } from './roleInformationToState'

export const calculateFormValue = ({
  currentState,
  path,
  formData,
  params
}: {
  currentState: OnboardingFormState
  path: FormPaths
  formData: FormData
  params: OnChangeAdditionParams
}): { formValue: OnboardingFormsData } | void => {
  switch (path) {
    case FormPaths.NOTES:
      return notesFormToState({
        currentState,
        formData: formData as unknown as NotesFormData
      })

    case FormPaths.COMPANY_INFORMATION:
      return companyInformationToState({
        currentState,
        companyIndex: params.companyIndex,
        formData: formData as unknown as CompanyInformationFormData
      })

    case FormPaths.COMPANY_ACCOUNT_DETAILS:
      return companyAccountDetailsToState({
        currentState,
        companyIndex: params.companyIndex,
        formData: formData as unknown as CompanyAccountDetailsFormData
      })

    case FormPaths.COMPANY_PHYSICAL_ADDRESS:
      return companyPhysicalAddressToState({
        currentState,
        companyIndex: params.companyIndex,
        formData: formData as unknown as CompanyPhysicalAddressFormData
      })

    case FormPaths.COMPANY_MAILING_ADDRESS:
      return companyMailingAddressToState({
        currentState,
        companyIndex: params.companyIndex,
        formData: formData as unknown as CompanyMailingAddressFormData
      })
    // Licenses
    case FormPaths.LICENSE_DETAILS:
      return licenseDetailsToState({
        currentState,
        companyIndex: params.companyIndex,
        formData: formData as unknown as LicenseDetailsFormData,
        licenseIndex: (params as unknown as LicenseAdditionalParams).licenseIndex
      })

    case FormPaths.LICENSE_ADDRESS_INFORMATION:
      return licenseAddressInformationToState({
        currentState,
        companyIndex: params.companyIndex,
        formData: formData as unknown as LicenseAddressInformationFormData,
        licenseIndex: (params as unknown as LicenseAdditionalParams).licenseIndex
      })
    // Contacts
    case FormPaths.CONTACT_DETAILS:
      return contactDetailsToState({
        companyIndex: params.companyIndex,
        contactIndex: (params as unknown as ContactAdditionalParams).contactIndex,
        currentState,
        formData: formData as unknown as ContactDetailsFormData
      })

    case FormPaths.ROLE_INFORMATION:
      return roleInformationToState({
        companyIndex: params.companyIndex,
        contactIndex: (params as unknown as ContactAdditionalParams).contactIndex,
        currentState,
        formData: formData as unknown as RoleInformationFormData
      })

    case FormPaths.CONTACT_ADDITIONAL_INFORMATION:
      return contactAdditionalInformationToState({
        companyIndex: params.companyIndex,
        contactIndex: (params as unknown as ContactAdditionalParams).contactIndex,
        currentState,
        formData: formData as unknown as ContactAdditionalInformationFormData
      })

    //
    case FormPaths.QUESTIONNAIRE:
      return questionnaireToState({
        companyIndex: params.companyIndex,
        currentState,
        formData: formData as unknown as QuestionnaireFormData
      })

    case FormPaths.PROFESSIONAL_PARTNERSHIPS:
      return professionalPartnershipsToState({
        companyIndex: params.companyIndex,
        currentState,
        formData: formData as unknown as ProfessionalPartnershipFormData
      })

    case FormPaths.ATM_GENERAL_INFO:
      return atmGeneralInfoToState({
        companyIndex: params.companyIndex,
        currentState,
        formData: formData as unknown as ATMGeneralInfoFormData
      })

    case FormPaths.ATM_SERVICES:
      return atmServicesToState({
        companyIndex: params.companyIndex,
        atmIndex: (params as unknown as ATMAdditionalParams).atmIndex,
        currentState,
        formData: formData as unknown as ATMServicesFormData
      })

    case FormPaths.AVAILABLE_SERVICES:
      return availableServicesToState({
        companyIndex: params.companyIndex,
        currentState,
        formData: formData as unknown as AvailableServicesFormData
      })

    case FormPaths.ANTICIPATED_MONTHLY_ACTIVITY:
      return anticipatedMonthlyActivityToState({
        companyIndex: params.companyIndex,
        currentState,
        formData: formData as unknown as AnticipatedMonthlyActivityFormData
      })

    case FormPaths.ANTICIPATED_MONTHLY_ACTIVITY_NA:
      return anticipatedMonthlyActivityNAToState({
        companyIndex: params.companyIndex,
        currentState,
        formData: formData as unknown as AnticipatedMonthlyActivityNAFormData
      })

    case FormPaths.COMPANY_MERCHANT_QUESTIONNAIRE:
      return companyMerchantQuestionnaireToState({
        companyIndex: params.companyIndex,
        currentState,
        formData: formData as unknown as MerchantQuestionnaireFormData
      })
    default:
      return undefined
  }
}
