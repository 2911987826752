import React, { FC, memo } from 'react'

import { ButtonGroup, ButtonGroupMargin } from 'ui/components/ButtonGroup'
import { Button } from 'ui/components/Buttons'
import { IconName } from 'ui/components/Icon'
import { Link } from 'ui/components/Link'

import { FooterProps } from './FooterProps'

import { Box, DeleteButton } from './styles'

export const Footer: FC<FooterProps> = memo((props) => {
  const { onDelete, onSubmit, isSubmitting, returnUrl } = props

  return (
    <Box>
      {!!onDelete && (
        <DeleteButton data-qa="Delete" icon={IconName.DELETE} face="negative" onClick={onDelete}>
          Delete
        </DeleteButton>
      )}
      <ButtonGroup margin={ButtonGroupMargin.SMALL}>
        <Link to={returnUrl}>
          <Button face="neutral">Cancel</Button>
        </Link>

        {!!onSubmit && (
          <Button data-qa="Submit" onClick={onSubmit} face="positive" loading={isSubmitting}>
            Submit
          </Button>
        )}
      </ButtonGroup>
    </Box>
  )
})
