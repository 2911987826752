import { LicenseManageRequest } from 'commons/types/DTO/bank'

export interface ManageLicensePageProps {
  license: LicenseManageRequest
  companyName: string
  onDelete: (id: number) => Promise<void>
  onSave: (payload: LicenseManageRequest) => Promise<LicenseManageRequest>
  onCancel: () => void
  isSaving: boolean
}
