import React, { FC, memo } from 'react'

import { FieldSet } from 'ui/components/FieldSet'
import { Hr } from 'ui/components/Hr'
import { Col, FormRow } from 'ui/themes/globalStyles'

import { TagsProps } from './TagsProps'
import { TagsSelect } from './TagsSelect'

import * as SC from './styles'

export const Tags: FC<TagsProps> = memo(({ Field }) => (
  <FieldSet legend="Tags">
    <SC.FiedWrapper>
      <FormRow>
        <Col>
          <Field
            name="tags"
            input={TagsSelect}
            inputProps={{
              'data-qa': 'Tags'
            }}
          />
        </Col>
      </FormRow>
    </SC.FiedWrapper>
    <Hr />
  </FieldSet>
))
