import { Company } from 'commons/types/DTO/bank/company'
import { Keys, ReadHook, useStateManager } from 'state-manager'

import { getHandler } from './getHandler'

export type UseGetCompanyParams = {
  id: number
  enabled?: boolean
}

export const useGetCompany: ReadHook<Company, UseGetCompanyParams> = ({ id }) => {
  const sm = useStateManager()

  const { data, ...rest } = sm.use(Keys.Company.ById(id), getHandler(id), {
    keepPreviousData: true
  })

  return {
    ...rest,
    data: data?.records[0]
  }
}
