import React from 'react'

import { CheckBox, FF } from 'ui/components'

import { useSARFormContext } from '../../../../../../../context'

export const K = () => {
  const { formApi } = useSARFormContext()
  return (
    <formApi.Field
      name="transactions_info.laundering.k"
      children={(field) => (
        <FF
          field={field}
          input={(p) => <CheckBox {...p} checkBoxLabel="k. Suspicious use of third-party transactors (straw-man)" />}
          errorPosition="right"
        />
      )}
    />
  )
}
