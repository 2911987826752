import { ApplicationRelationshipBankAccountsItem } from 'commons/types/DTO/bank/applications'

export const checkReadyForSubmit = ({
  relationships
}: {
  relationships: ApplicationRelationshipBankAccountsItem[]
}) => {
  let isValid = true
  relationships.forEach((relationship) =>
    relationship.licenses.forEach((relationshipLicense) => {
      if (!relationshipLicense.isAssigned) {
        isValid = relationshipLicense.isAssigned
      }
    })
  )

  return isValid
}
