import React, { FC } from 'react'

import { Chip } from 'ui/components'
import { DocumentStatuses } from '~bank-documents/types/enums/DocumentStatuses'

import { getDocumentPeriodChip } from './getDocumentPeriodStatusChip'

export const DocumentPeriodStatusChip: FC<{
  name: DocumentStatuses
  withoutMargins?: boolean
}> = ({ name, withoutMargins }) => (
  <Chip face={getDocumentPeriodChip(name)} withoutMargins={withoutMargins}>
    <span data-qa="document-period-status">{name}</span>
  </Chip>
)
