import { getApplicationPipelineReport } from 'commons/service/bank/application'
import { ApplicationPipelineReportFilter, ApplicationPipelineReportResponse } from 'commons/types/DTO/bank'
import { Keys, ReadHook, useStateManager } from 'state-manager'

export const useGetApplicationPipelineReport: ReadHook<
  ApplicationPipelineReportResponse,
  ApplicationPipelineReportFilter
> = (filter) =>
  useStateManager().use(Keys.ApplicationPipelineReport.List(filter), () => getApplicationPipelineReport(filter), {
    keepPreviousData: true
  })
