import React, { FC, useCallback } from 'react'
import Dropzone from 'react-dropzone'

import { HeaderButton } from 'ui/components/Buttons'

export const UploadButton: FC<{
  onUpload: (files: File[]) => any
  'data-qa'?: string
}> = (props) => {
  const { onUpload, 'data-qa': dataQa } = props

  const onUploadWrapper = useCallback((acceptedFiles) => onUpload(acceptedFiles), [onUpload])

  return (
    <Dropzone onDrop={onUploadWrapper}>
      {({ getRootProps, getInputProps }) => (
        <HeaderButton data-qa={dataQa} onClick={onUploadWrapper} size="middle" {...getRootProps()}>
          Upload
          <input {...getInputProps()} data-qa={`${dataQa}-input`} />
        </HeaderButton>
      )}
    </Dropzone>
  )
}
