import styled from 'styled-components'

const BankAccountPanelHeader = styled.div`
  display: flex;
  gap: 0px 8px;

  & > * {
    max-width: auto;
  }
`

export const s = { BankAccountPanelHeader }
