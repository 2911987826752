import styled from 'styled-components'
import { Hr } from 'ui/components/Hr'

const WrappedHr = styled(Hr)`
  margin-bottom: 21px;
`

const Row = styled.div`
  display: flex;

  > * {
    width: 400px;

    &:first-child {
      margin-right: 100px;
    }
  }
`

export const s = { WrappedHr, Row }
