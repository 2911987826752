import { Request } from 'commons/utils/request/request'
import { WriteHook, useMutationManager } from 'state-manager'
import { ManageDocumentFormType } from '~bank-documents/types/documentEdit'

type ManageDocumentRequest = ManageDocumentFormType & { id?: number }

const saveDocument = (document: ManageDocumentRequest) => {
  const func = document.id ? Request.put : Request.post
  return func(Request.urls.bank.document, document)
}

export const useSaveDocument: WriteHook<Partial<ManageDocumentRequest>, any> = (document) =>
  useMutationManager<ManageDocumentRequest, any>(saveDocument, document)
