import React, { FC, memo, useCallback, useMemo } from 'react'

import { DocumentsPaths } from 'bank-documents/src/constants'
import { LicenseWebPageDocumentItem } from 'commons/types/DTO/bank/license'
import { TableDataQa } from 'commons/types/enums'
import { HeaderButton } from 'ui/components/Buttons'
import { Layer } from 'ui/components/Layer'
import { Link } from 'ui/components/Link'
import { PageSection, PageSectionFace } from 'ui/components/Page'
import { Table } from 'ui/components/Table'

import { DocumentsProps } from './DocumentsProps'
import { Filter } from './components/Filter'
import { useDocuments } from './hooks'
import { useColumns } from './useColumns'

export const Documents: FC<DocumentsProps> = memo((props) => {
  const { webPage, licenseId, returnUrl, onDocumentDeleted, onFilterChange, isFetching, order, onOrderChange } = props

  const dataSource = useMemo(() => webPage.documents.items.map((item) => ({ item })), [webPage.documents.items])
  const { page, size } = webPage.config.documentsFilter
  const { DeleteConfirmation, startDeletion, isDeleting } = useDocuments(onDocumentDeleted)
  const columns = useColumns(startDeletion, returnUrl)

  const onPageChange = useCallback(
    (page: number, size: number) => onFilterChange({ ...webPage.config.documentsFilter, page, size }),
    [webPage.config.documentsFilter, onFilterChange]
  )

  const pagination = useMemo(
    () => ({ page, pageSize: size, onChange: onPageChange, total: webPage.documents.total }),
    [webPage.documents, size, page, onPageChange]
  )

  return (
    <PageSection
      title="Documents"
      face={PageSectionFace.SECONDARY}
      anchor="documents"
      actions={
        <Link
          to={DocumentsPaths.NEW}
          search={{
            licenseId: licenseId,
            subjectType: 'license',
            subjectName: webPage.name,
            returnUrl: returnUrl
          }}
        >
          <HeaderButton size="middle">Add New</HeaderButton>
        </Link>
      }
    >
      <DeleteConfirmation />
      <Layer rounded shadowed>
        <Filter webPage={webPage} onChange={onFilterChange} />
        <Table<LicenseWebPageDocumentItem>
          dataQa={TableDataQa.BP_LICENSE_DOCUMENTS}
          loading={isDeleting || isFetching}
          dataSource={dataSource}
          pagination={pagination}
          sorting={{ order, onChange: onOrderChange }}
          columns={columns}
        />
      </Layer>
    </PageSection>
  )
})
