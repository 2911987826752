import React, { FC, RefObject, useCallback, useEffect } from 'react'

import { BeforeFormChangeHandler, Form, FormChangedHandler, useFormData } from 'brief-form'
import { BsaCtrTransactionLocationInfo } from 'commons/types/DTO/bank/bsa'
import {
  filingInstitutionIDTypes,
  primaryFederalRegulators,
  typesOfFinancialInstitution,
  usAndTerritories,
  usStates
} from 'commons/types/dictionaries'
import { isEqual } from 'lodash'
import SC from 'styled-components'
import { IconName, Panel, Select, Validators } from 'ui/components'
import { IconButton } from 'ui/components/Buttons'
import { CurrencyInput, EINInput, TextInput, WithCheckboxInput } from 'ui/components/InputV2'
import { useConfirmation } from 'ui/hooks'
import { Col, FormRow } from 'ui/themes/globalStyles'

import { VisibilityBox } from './styles'

const EMPTY_OPTION = { label: 'Not selected', value: '' }

interface Props {
  value: BsaCtrTransactionLocationInfo
  errors: { [key: string]: any }
  onChange: (index: number, value: BsaCtrTransactionLocationInfo, errors?: { [key: string]: string }) => void
  onDelete?: (index: number) => void
  index: number
  total: number
  validateFunctionRefs: RefObject<any>
  markFormAsDirty: () => void
}

//region Styles

const Inner = SC.div`
  padding: 30px 35px;
`

const Item = SC(Panel)`
  margin-bottom: 30px;
`

//endregion

export const TransactionLocationInformationForm: FC<Props> = (props) => {
  const { value, onChange, onDelete, errors, index, total, validateFunctionRefs, markFormAsDirty } = props

  const onBeforeChange: BeforeFormChangeHandler<BsaCtrTransactionLocationInfo> = useCallback(
    ({ value, errors }) => {
      const newValue = { ...value, state: value.country === 'US' ? value.state : value.country }

      return {
        errors,
        value: newValue
      }
    },
    [index]
  )

  const onFormChanged: FormChangedHandler<BsaCtrTransactionLocationInfo> = useCallback(
    (value, errors) => {
      onChange(index, value, errors)
    },
    [index]
  )

  const { isDirty, config, validate, Field, set } = useFormData<BsaCtrTransactionLocationInfo>({
    onBeforeChange,
    onFormChanged,
    alwaysSyncWithInitialValueAndErrors: true,
    initialValue: value,
    initialErrors: errors
  })

  useEffect(() => {
    if (isDirty) {
      markFormAsDirty()
    }
  }, [isDirty])

  useEffect(() => {
    validateFunctionRefs.current.transaction_locations_info[index] = {
      validate,
      registeredFields: config.registeredFields.current
    }
  }, [validate])

  useEffect(() => {
    if (!isEqual(config.errors, errors)) {
      // TODO: form probably clear value if we do not pass it here, marks form as dirty
      // value shouldn't change here
      set({ value: config.value, errors: { ...config.errors, ...errors } })
    }
  }, [set, config, errors])

  useEffect(() => {
    if (!isEqual(config.value, value)) {
      set({ value })
    }
  }, [set, config, value])

  const { open: startDeleting, Confirmation } = useConfirmation({
    message: `Are you sure you want to remove this item?`,
    onConfirm: () => {
      if (onDelete) {
        onDelete(index)
      }
    },
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  return (
    <Item
      rounded
      shadowed
      collapsible={false}
      title={`Edit Part III Transaction Location ${+index + 1} of ${total}`}
      data-qa={`Edit Part III Transaction Location ${+index + 1}`}
      actions={
        !!onDelete && (
          <IconButton face="negative" icon={IconName.DELETE} onClick={startDeleting}>
            Remove
          </IconButton>
        )
      }
    >
      <Confirmation />
      <Form config={config}>
        <Inner>
          <FormRow>
            <Col>
              <Field
                required
                name="type"
                label="38. Type of financial institution"
                input={Select}
                validator={Validators.required.field}
                inputProps={{
                  data: typesOfFinancialInstitution,
                  'data-qa': '38. Type of financial institution',
                  'options-data-qa': '38. Type of financial institution option'
                }}
              />
            </Col>
            <Col>
              <Field
                required
                name="federal_regulator"
                label="29. Primary federal regulator"
                input={Select}
                validator={Validators.required.field}
                inputProps={{
                  data: primaryFederalRegulators,
                  'data-qa': '29. Primary federal regulator',
                  'options-data-qa': '29. Primary federal regulator option'
                }}
              />
            </Col>
          </FormRow>
          <VisibilityBox $visible={config.value.type === 'Other'}>
            <FormRow>
              <Col>
                <Field
                  required={config.value.type === 'Other'}
                  validator={(v, f) =>
                    !!v || f.type !== 'Other'
                      ? undefined
                      : '"Other (specify)" required when choose "Other" as "38. Type of financial institution"'
                  }
                  name="type_other"
                  label="Other (specify)"
                  input={TextInput}
                  inputProps={{
                    maxLength: 50
                  }}
                />
              </Col>
            </FormRow>
          </VisibilityBox>
          <FormRow>
            <Col>
              <Field
                required
                name="legal_name"
                label="30. Legal name of filing institution"
                input={TextInput}
                validator={Validators.required.field}
                inputProps={{
                  maxLength: 150,
                  'data-qa': '30. Legal name of filing institution'
                }}
              />
            </Col>
            <Col>
              <Field
                name="dba"
                label="31. Alternate name, e.g. trade name, DBA"
                input={TextInput}
                inputProps={{
                  maxLength: 150
                }}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <Field
                name="ein"
                label="32. EIN"
                required={config.value.country === 'US'}
                validator={(v, f) => {
                  if (f.country == 'US') {
                    return Validators.required.field(v)
                  }
                  return undefined
                }}
                input={WithCheckboxInput}
                inputProps={{
                  input: EINInput,
                  'data-qa': '32. EIN'
                }}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <Field
                required
                name="address"
                label="33. Address"
                input={TextInput}
                validator={Validators.required.field}
                inputProps={{
                  maxLength: 100,
                  'data-qa': '33. Address'
                }}
              />
            </Col>
            <Col>
              <Field
                required
                name="city"
                label="34. City"
                input={TextInput}
                validator={Validators.required.field}
                inputProps={{
                  maxLength: 50,
                  'data-qa': '34. City'
                }}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <Field
                required
                name="zip_code"
                label="35. ZIP Code"
                input={TextInput}
                validator={Validators.required.field}
                inputProps={{
                  maxLength: 9,
                  'data-qa': '35. ZIP Code'
                }}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <Field
                required
                name="country"
                label="36. Country"
                input={Select}
                validator={Validators.required.field}
                inputProps={{
                  data: usAndTerritories,
                  'data-qa': '36. Country',
                  'options-data-qa': '36. Country option'
                }}
              />
            </Col>
            <VisibilityBox $visible={config.value.country === 'US'}>
              <Col>
                <Field
                  name="state"
                  required={config.value.country === 'US'}
                  validator={(v, f) => {
                    // state field has predefined values that a copy of country field and not valid for state.
                    // so we're checking "is state value are listed in this select"
                    if (f.country == 'US' && (!v || !usStates.find((el) => el.value == v))) {
                      return '"37. State" required when choose "US" as "36. Country"'
                    }
                    return undefined
                  }}
                  label="37. State"
                  input={Select}
                  inputProps={{
                    data: usStates,
                    'data-qa': '37. State',
                    'options-data-qa': '37. State option'
                  }}
                />
              </Col>
            </VisibilityBox>
          </FormRow>
          <FormRow>
            <Col>
              <Field
                name="id_type"
                label="40. Filing institution ID type"
                input={Select}
                inputProps={{
                  data: filingInstitutionIDTypes,
                  emptyOption: EMPTY_OPTION
                }}
              />
            </Col>
            <Col>
              <Field
                name="id_number"
                label="ID Number"
                input={TextInput}
                inputProps={{
                  maxLength: 24
                }}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <Field
                required
                name="cash_in"
                label="41. Cash in amount for transaction location"
                input={CurrencyInput}
                validator={Validators.required.notEmpty}
                inputProps={{
                  allowDecimal: false,
                  'data-qa': '41. Cash in amount for transaction location'
                }}
              />
            </Col>
            <Col>
              <Field
                required
                name="cash_out"
                label="42. Cash out amount for transaction location"
                input={CurrencyInput}
                validator={Validators.required.notEmpty}
                inputProps={{
                  allowDecimal: false,
                  'data-qa': '42. Cash out amount for transaction location'
                }}
              />
            </Col>
          </FormRow>
        </Inner>
      </Form>
    </Item>
  )
}
