/* eslint-disable camelcase */
import { ApplicationStatus } from '../../enums'
import { ApplicationDocument } from './applicationDocument'
import { ApplicationRFIInfo } from './applicationRFIInfo'
import { ApplicationReviewResult } from './applicationReviewResult'

export type Application = {
  active?: boolean
  bank_notes?: string
  client_notes?: string
  company_name?: string
  contact_first_name?: string
  contact_last_name?: string
  created_at?: string
  deleted_at?: string
  email?: string
  id: number
  internal_bank_documents?: ApplicationDocument[]
  internal_bank_notes?: string
  internal_review_compliance?: ApplicationReviewResult
  internal_review_credit?: ApplicationReviewResult
  internal_review_mhcpa?: ApplicationReviewResult
  misc_client_documents?: ApplicationDocument[]
  onboarding_client_documents?: ApplicationDocument[]
  organization_id?: number
  phone?: string
  rfi_info: ApplicationRFIInfo
  status: ApplicationStatus
  updated_at?: string
}
