import { useEffect } from 'react'

import { notFound } from '@tanstack/react-router'

export const useNotFound = ({ notFoundCondition }: { notFoundCondition: boolean }) =>
  useEffect(() => {
    if (notFoundCondition) {
      throw notFound()
    }
  }, [notFoundCondition])
