import React from 'react'

import { DatePicker, FF, Info, Validators } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

export const TransEndDate = () => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name="trans_end_date"
      validators={{ onChange: ({ value }) => Validators.required.field(value) }}
      children={(field) =>
        field.form.state.values.id ? (
          <Info label="Transactions End  Date">{field.state.value}</Info>
        ) : (
          <FF
            required
            field={field}
            label="Transactions End Date"
            input={(p) => <DatePicker {...p} data-qa="General. Transactions End Date" />}
          />
        )
      }
    />
  )
}
