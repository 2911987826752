import React, { ReactNode } from 'react'

import { Link } from 'ui/components/Link'
import { TableColumn } from 'ui/components/Table'

// TODO: add typings
// eslint-disable-next-line
const getNameComponent = (entity: any): ReactNode => {
  let nameNode

  switch (entity.type) {
    case 'Company':
      nameNode = (
        <Link to="/relationships/$companyId" params={{ companyId: entity.id }}>
          {entity.name}
        </Link>
      )
      break
    case 'Contact':
      nameNode = <Link to={`/contacts/${entity.id}`}>{entity.name}</Link>
      break
    default:
      if (entity.internalLicense) {
        const {
          internalLicense: { id }
        } = entity
        nameNode = (
          <Link to="/licenses/$licenseId" params={{ licenseId: id }}>
            {entity.name}
          </Link>
        )
      } else {
        nameNode = entity.name
      }
      break
  }

  return nameNode
}

export const columns = (): TableColumn<any>[] => [
  {
    title: 'Name',
    align: 'left',
    sortable: false,
    render: (item) => getNameComponent(item)
  },
  {
    title: 'Type',
    key: 'type',
    align: 'right',
    sortable: false
  }
]
