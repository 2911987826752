import React from 'react'

import { FF, TextInput } from 'ui/components'

import { useSARFormContext } from '../../../../../../../../context'
import { useSubjectInformationItemContext } from '../../../SubjectInformationItemContext'

type CityProps = {
  index: number
}

export const City = ({ index }: CityProps) => {
  const { formApi } = useSARFormContext()
  const { isAllInfoUnavailable } = useSubjectInformationItemContext()

  return (
    <formApi.Field
      name={`subjects_info[${index}].address_info[0].city` as 'subjects_info[0].address_info[0].city'}
      children={(field) => (
        <FF
          field={field}
          label="12. City"
          input={(p) => <TextInput {...p} disabled={isAllInfoUnavailable} maxLength={50} />}
        />
      )}
    />
  )
}
