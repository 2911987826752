import React, { FC, memo } from 'react'

import { formatCurrency } from 'commons/utils'
import { Info } from 'ui/components/Info'
import { PageSection, PageSectionFace } from 'ui/components/Page'
import { Panel, PanelFace } from 'ui/components/Panel'
import { TextMask } from 'ui/components/TextMask'
import { CTRDetailsResponse } from '~bank-bsa-reporting/types'

import { getFormattedEin } from '../helpers'

import { s } from './styles'

type Props = {
  data: CTRDetailsResponse
}

export const TransactionsLocationsInformation: FC<Props> = memo(({ data }) => {
  const { transactionLocationsInfo } = data

  return (
    <PageSection face={PageSectionFace.THIRD} title="Step 2. Transaction Location(s) Information" anchor="step2">
      {transactionLocationsInfo.map((info, index) => {
        const {
          type,
          federalRegulator,
          legalName,
          dba,
          ein,
          address,
          city,
          country,
          state,
          zipCode,
          idType,
          idNumber,
          cashIn,
          cashOut
        } = info

        return (
          <Panel
            rounded
            shadowed
            key={index}
            collapsible={false}
            title={`Part III Transaction Location ${index + 1} of ${transactionLocationsInfo.length}`}
            data-qa={`Part III Transaction Location ${index + 1}`}
            face={PanelFace.PRIMARY}
          >
            <s.InnerPanel>
              <s.Row>
                <Info label="38. Type of financial institution">{type}</Info>
                <Info label="29. Primary federal regulator">{federalRegulator}</Info>
              </s.Row>
              <s.Row>
                <Info label="30. Legal name of filing institution">{legalName}</Info>
                <Info label="31. Alternate name, e.g. trade name, DBA">{dba}</Info>
              </s.Row>
              <s.Row>
                <Info label="32. EIN">{ein ? <TextMask text={getFormattedEin(true, ein)} /> : undefined}</Info>
              </s.Row>
              <s.Row>
                <Info label="33-37. Address">
                  {address}
                  <div>{[city, country === 'US' ? state : null, zipCode].filter(Boolean).join(', ') || '-'}</div>
                  <div>{country}</div>
                </Info>
              </s.Row>
              <s.Row>
                <Info label="40. Filing institution ID type">{idType}</Info>
                <Info label="ID number">{idNumber}</Info>
              </s.Row>
              <s.Row>
                <Info label="41. Cash in amount for transaction location	">{formatCurrency(cashIn || 0)}</Info>
                <Info label="42. Cash out amount for transaction location">{formatCurrency(cashOut || 0)}</Info>
              </s.Row>
            </s.InnerPanel>
          </Panel>
        )
      })}
    </PageSection>
  )
})
