import React from 'react'

import { financialInstitutionRoles } from 'commons/types/dictionaries'
import { FF, Select } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

const EMPTY_OPTION = { label: 'Not selected', value: '' }

export const FinancialInstitutionRole = ({ index }: { index: number }) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={
        `institution_activities_info[${index}].role_in_transaction` as 'institution_activities_info[0].role_in_transaction'
      }
      children={(field) => (
        <FF
          field={field}
          label="56. Financial institution's role in transaction"
          input={(p) => <Select {...p} data={financialInstitutionRoles} emptyOption={EMPTY_OPTION} />}
        />
      )}
    />
  )
}
