import React, { FC, memo } from 'react'

import { Form } from 'brief-form'
import { bsaBatchStatuses } from 'commons/types/dictionaries'
import { DatePicker, Select } from 'ui/components'
import { NumberInput, TextInput } from 'ui/components/InputV2'
import { FilterPanel } from 'ui/components/Table'

import { FilterFormProps } from './FilterFormProps'

import * as SC from './styles'

export const FilterForm: FC<FilterFormProps> = memo(({ config, isDirty, reset, Field }) => (
  <FilterPanel isDirty={isDirty} onReset={() => reset()}>
    <SC.FormWrapper>
      <Form config={config}>
        <SC.Row>
          <SC.LeftWrapper>
            <Field name="id" label="Batch ID" input={NumberInput} inputProps={{}} />
            <Field name="tracking_id" label="BSA Tracking ID" input={TextInput} inputProps={{}} />
          </SC.LeftWrapper>
          <SC.RightWrapper>
            <Field name="date" label="Date" input={DatePicker} inputProps={{}} />
            <Field
              name="status"
              label="Status"
              input={Select}
              inputProps={{
                data: [{ label: 'All', value: '' }].concat(bsaBatchStatuses)
              }}
            />
          </SC.RightWrapper>
        </SC.Row>
      </Form>
    </SC.FormWrapper>
  </FilterPanel>
))
