import React from 'react'

import { FF, IconName, Toggle, Validators } from 'ui/components'
import { IconButton } from 'ui/components/Buttons'

import { BankAccountPanelHeader, CompanySectionPanel, TemplatePanel } from '../../../../components'
import { BankAccountNumberInput } from '../../../../components/BankAccountNumberInput'
import { s } from '../../styles'
import { getCorrectFieldInput } from '../../utils/getCorrectFieldInput'
import { BankAccountFormSectionProps } from './BankAccountFormSection.types'

export const BankAccountFormSection = ({
  form,
  companyIndex,
  onDeleteBankAccount,
  addBankAccountToCompany
}: BankAccountFormSectionProps) => (
  <form.Field
    name={`companiesData[${companyIndex}].bankAccounts`}
    mode="array"
    validators={{
      onSubmit: ({ value }) => (value.length > 0 ? undefined : 'There must be at least 1 Bank Account')
    }}
    children={(bankAccountsListField) => (
      <>
        {bankAccountsListField.state.value.map((bankAccount, bankAccountIndex) => (
          <form.Field
            key={bankAccountIndex}
            // eslint-disable-next-line max-len
            name={`companiesData[${companyIndex}].bankAccounts[${bankAccountIndex}].bankAccountTemplates`}
            mode="array"
            children={(bankAccountTemplatesListField) => (
              <CompanySectionPanel
                key={bankAccount.bankAccountNumber}
                title={<BankAccountPanelHeader bankAccountNumber={bankAccount.bankAccountNumber || ''} />}
                blockOnClickOnTitle={true}
                actions={
                  <IconButton
                    face="negative"
                    icon={IconName.DELETE}
                    onClick={() => onDeleteBankAccount(bankAccountsListField, bankAccountIndex)}
                  >
                    Remove
                  </IconButton>
                }
              >
                {bankAccountTemplatesListField.state.value.map((bankAccountTemplate, bankAccountTemplateIndex) => {
                  const isBankAccountTemplateSelected = form.useStore(
                    (s) =>
                      s.values.companiesData[companyIndex].bankAccounts[bankAccountIndex].bankAccountTemplates[
                        bankAccountTemplateIndex
                      ].isSelected
                  )

                  return (
                    <TemplatePanel
                      key={`${companyIndex} ${bankAccountIndex} ${bankAccountTemplateIndex}`}
                      title={bankAccountTemplate.name}
                      expanded={isBankAccountTemplateSelected}
                      disabled={!isBankAccountTemplateSelected}
                      actions={
                        <form.Field
                          // eslint-disable-next-line max-len
                          name={`companiesData[${companyIndex}].bankAccounts[${bankAccountIndex}].bankAccountTemplates[${bankAccountTemplateIndex}].isSelected`}
                        >
                          {(bankAccountTemplateToggleField) => (
                            <Toggle
                              value={bankAccountTemplateToggleField.state.value}
                              onChange={bankAccountTemplateToggleField.handleChange}
                            />
                          )}
                        </form.Field>
                      }
                    >
                      <s.FormBox>
                        <form.Field
                          // eslint-disable-next-line max-len
                          name={`companiesData[${companyIndex}].bankAccounts[${bankAccountIndex}].bankAccountTemplates[${bankAccountTemplateIndex}].fields`}
                          mode="array"
                        >
                          {(ff) =>
                            ff.state.value.map((bankAccountTemplateField, bankAccountTemplateFieldIndex) => (
                              <form.Field
                                key={bankAccountTemplateFieldIndex}
                                // eslint-disable-next-line max-len
                                name={`companiesData[${companyIndex}].bankAccounts[${bankAccountIndex}].bankAccountTemplates[${bankAccountTemplateIndex}].fields[${bankAccountTemplateFieldIndex}].value`}
                                validators={{
                                  onSubmit: ({ value }) => Validators.required.field(value)
                                }}
                                children={(field) => (
                                  <FF
                                    label={bankAccountTemplateField.label}
                                    field={field}
                                    input={(props) =>
                                      getCorrectFieldInput({
                                        fieldName: bankAccountTemplateField.externalKey,
                                        props: { ...props, required: true }
                                      })
                                    }
                                  />
                                )}
                              />
                            ))
                          }
                        </form.Field>
                      </s.FormBox>
                    </TemplatePanel>
                  )
                })}
              </CompanySectionPanel>
            )}
          />
        ))}

        <form.Field name={`companiesData[${companyIndex}].bankAccountNumber`} mode="value">
          {(bankAccountNumberField) => (
            <BankAccountNumberInput
              form={form}
              field={bankAccountNumberField}
              onSubmit={() => {
                addBankAccountToCompany(bankAccountsListField, bankAccountNumberField.state.value, companyIndex)
                bankAccountNumberField.setValue('')
              }}
            />
          )}
        </form.Field>
      </>
    )}
  />
)
