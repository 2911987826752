import React, { memo, useCallback, useEffect, useState } from 'react'

import { useFormData } from 'brief-form'
import { useTableState } from 'query-table'
import { Spinner, SpinnerSize } from 'ui/components'
import { Layer } from 'ui/components/Layer'
import { Page, PageSection, PageSectionFace } from 'ui/components/Page'

import { InternalTransferExportsTable, InternalTransferTable } from './components'
import { useInternalTransfersList } from './hooks'
import {
  InternalTransferData,
  InternalTransferExportsTableFilterValue,
  InternalTransferTableFilterValue
} from './types'

const InternalTransfersList = memo(() => {
  const [internalTransferExportsLoading, setInternalTransferExportsLoading] = useState(false)
  const [internalTransferLoading, setInternalTransferLoading] = useState(false)
  const [webPage, setWebpage] = useState<null | InternalTransferData>(null)

  const exportsTableState = useTableState({ filter: {} })
  const internalTransferTableState = useTableState({ filter: {} })

  const exportsFilterForm = useFormData<InternalTransferExportsTableFilterValue>({
    initialValue: {
      status: 'new'
    }
  })

  const originalExportsChange = exportsFilterForm.config.onChange
  exportsFilterForm.config.onChange = useCallback(
    (v, e) => {
      exportsTableState.pagination.change(1, exportsTableState.pagination.size)
      originalExportsChange(v, e)
    },
    [originalExportsChange, exportsTableState]
  )

  const transfersTableFilterForm = useFormData<InternalTransferTableFilterValue>({
    initialValue: {
      status: '',
      id: undefined,
      dateFrom: '',
      dateTo: '',
      senderCompanyID: undefined,
      senderLicenseID: undefined,
      recipientCompanyID: undefined,
      recipientLicenseID: undefined
    }
  })

  const originalTransfersChange = transfersTableFilterForm.config.onChange
  transfersTableFilterForm.config.onChange = useCallback(
    (v, e) => {
      internalTransferTableState.pagination.change(1, internalTransferTableState.pagination.size)
      originalTransfersChange(v, e)
    },
    [originalTransfersChange, internalTransferTableState]
  )

  const { isFetching, data } = useInternalTransfersList({
    internalTransferExportsStatus: exportsFilterForm.config.value.status,
    internalTransferExportsPage: exportsTableState.pagination.page,
    internalTransferExportsSize: exportsTableState.pagination.size,
    internalTransferSize: internalTransferTableState.pagination.size,
    internalTransferPage: internalTransferTableState.pagination.page,
    internalTransferStatus: transfersTableFilterForm.config.value.status,
    internalTransfersID: transfersTableFilterForm.config.value.id || undefined,
    internalTransfersDateFrom: transfersTableFilterForm.config.value.dateFrom,
    internalTransfersDateTo: transfersTableFilterForm.config.value.dateTo,
    internalTransfersSenderCompanyID: transfersTableFilterForm.config.value.senderCompanyID || undefined,
    internalTransfersSenderLicenseID: transfersTableFilterForm.config.value.senderLicenseID || undefined,
    internalTransfersRecipientCompanyID: transfersTableFilterForm.config.value.recipientCompanyID || undefined,
    internalTransfersRecipientLicenseID: transfersTableFilterForm.config.value.recipientLicenseID || undefined
  })

  useEffect(() => {
    setInternalTransferLoading(isFetching)
    setInternalTransferExportsLoading(isFetching)
  }, [isFetching])

  useEffect(() => {
    if (data) {
      setWebpage(data)
    }
  }, [data])

  if (!webPage) {
    return <Spinner centered size={SpinnerSize.M} />
  }
  return (
    <>
      <Page title="Internal Transfers">
        <PageSection title="Internal Transfers Export" face={PageSectionFace.SECONDARY}>
          <Layer rounded shadowed stretch>
            <InternalTransferExportsTable
              items={webPage.internalTransferExports}
              tableState={exportsTableState}
              filterForm={exportsFilterForm}
              setLoading={setInternalTransferExportsLoading}
              loading={internalTransferExportsLoading}
              totalCount={webPage.internalTransferExportsTotalCount}
              statuses={webPage.internalTransferExportsStatuses}
            />
          </Layer>
        </PageSection>
        <PageSection title="Internal Transfers">
          <Layer rounded shadowed stretch>
            <InternalTransferTable
              items={webPage.internalTransfers}
              tableState={internalTransferTableState}
              filterForm={transfersTableFilterForm}
              setLoading={setInternalTransferLoading}
              loading={internalTransferLoading}
              totalCount={webPage.internalTransfersTotalCount}
              statuses={webPage.internalTransfersStatuses}
            />
          </Layer>
        </PageSection>
      </Page>
    </>
  )
})

export default InternalTransfersList
