import * as React from 'react'

import { useGetBankName } from 'commons/hooks/bank/organisation'
import { AnnualReviewQuestionnaire } from 'commons/types/DTO/bank'
import { BankName } from 'commons/types/enums'
import { PageSection } from 'ui/components/Page'

import { AnnualReviewQuestionList } from './AnnualReviewQuestionList/AnnualReviewQuestionList'
import { annualReviewQuestionnaireQuestions } from './selector'
import { annualReviewCBTQuestionnaireQuestions } from './selectorForCBT'

export interface AnnualReviewQuestionsProps {
  questionnaire: AnnualReviewQuestionnaire
  bottomIndent?: boolean
  isCBT: boolean
}

export const AnnualReviewQuestions = ({
  questionnaire,
  bottomIndent,
  isCBT
}: AnnualReviewQuestionsProps): JSX.Element => {
  const { id: bankId } = useGetBankName()
  const isShore = bankId === BankName.SHORE

  return (
    <>
      {isCBT
        ? annualReviewCBTQuestionnaireQuestions(questionnaire).map((data) => (
            <PageSection title={data.title} key={data.title}>
              <AnnualReviewQuestionList bottomIndent={bottomIndent} items={data.items} />
            </PageSection>
          ))
        : annualReviewQuestionnaireQuestions(questionnaire, isShore).map((data) => (
            <PageSection title={data.title} key={data.title}>
              <AnnualReviewQuestionList bottomIndent={bottomIndent} items={data.items} />
            </PageSection>
          ))}
    </>
  )
}
