import styled from 'styled-components'

export const Columns = styled.div`
  padding: 20px 30px 32px 30px;
  display: flex;
  gap: 28px;
`

export const Column = styled.div`
  width: 200px;
`
