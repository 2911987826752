import React, { memo } from 'react'

import { IconName, IconSize } from 'ui/components/Icon'

import * as SC from './warningStyles'

interface WarningsProps {
  attention: string
  onResolve: () => any
}

export const Warnings = memo(({ attention, onResolve }: WarningsProps) => (
  <SC.Box>
    <SC.Title $weight={700}>
      <SC.WarningIcon name={IconName.ERROR_RED} size={IconSize.M} /> Warning(s)
    </SC.Title>
    <SC.List>
      {attention.split('\n').map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </SC.List>
    <SC.ResolveButton onClick={onResolve}>Mark all warnings as resolved</SC.ResolveButton>
  </SC.Box>
))
