import React, { FC, memo, useEffect, useMemo, useState } from 'react'

import { Vendor } from 'commons/types/DTO/bank/companyDetails/vendor'
import { TableDataQa } from 'commons/types/enums'
import { handlerServerError } from 'errors'
import { DataSourceItem, Layer, PageSection, PageSectionFace, Table } from 'ui/components'

import { CompanyVendorsTableProps } from './CompanyVendorsTableProps'
import { columns } from './columns'
import { Filter } from './components/Filter/Filter'
import { useComponentsEssence } from './hooks/useComponentsEssence'
import { useFilterCompanyVendorsWebPage } from './hooks/useFilterCompanyVendorsWebPage'

export const CompanyVendorsTable: FC<CompanyVendorsTableProps> = memo(
  ({ companyId, initialVendors, initialVendorsTotalCount }) => {
    const [webPageData, setWebPageData] = useState({
      vendors: initialVendors,
      vendorsTotalCount: initialVendorsTotalCount
    })

    useEffect(() => {
      setWebPageData({
        vendors: initialVendors,
        vendorsTotalCount: initialVendorsTotalCount
      })
    }, [initialVendors, initialVendorsTotalCount])

    const { routine: filterCompanyVendorsWebPage } = useFilterCompanyVendorsWebPage({
      onSuccess: async (_, result) => {
        await setWebPageData(result)
      },
      onError: (error) => handlerServerError(error)
    })

    const { loading, tableState, filterForm, changePagination, clearFilter } = useComponentsEssence(
      companyId,
      filterCompanyVendorsWebPage
    )

    const mappedVendors: DataSourceItem<Vendor>[] = useMemo(
      () =>
        webPageData.vendors.map((el) => ({
          item: el
        })),
      [webPageData]
    )

    return (
      <PageSection face={PageSectionFace.PRIMARY} title="Vendors" anchor="vendors">
        <Layer rounded shadowed stretch>
          <Filter
            form={filterForm}
            expanded={tableState.expanded}
            onToggle={tableState.onFilterToggle}
            onClear={clearFilter}
          />
          <Table<Vendor>
            dataQa={TableDataQa.BP_COMPANY_VENDORS}
            columns={columns}
            dataSource={mappedVendors}
            loading={loading}
            pagination={{
              page: tableState.page,
              pageSize: tableState.size,
              onChange: changePagination,
              total: webPageData.vendorsTotalCount
            }}
          />
        </Layer>
      </PageSection>
    )
  }
)
