import { FormInputProps } from 'brief-form'
import { ChoiceValue } from 'ui/types'

import { SingleSelectAutoSuggestOpts } from '../SingleSelectAutoSuggest'

export type BankAccountSelectOpts = Omit<SingleSelectAutoSuggestOpts, 'listName' | 'options'> & {
  filters?: { field: string; type: string; value: string | number }[]
  isSearchRequest?: boolean
}

export interface BankAccountSelectProps extends FormInputProps<ChoiceValue, BankAccountSelectOpts> {}
