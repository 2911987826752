import { useCallback } from 'react'

import { FormChangedHandler, useFormData } from 'brief-form'

import { FormPaths } from '../../enums'
import { useSyncFormData } from '../../hooks'
import { useValidateFormPersist } from '../../hooks/useValidateFormPersist'
import { ATMGeneralInfoChangeHandler, ATMGeneralInfoFormData, ATMGeneralInfoFormValue } from '../../types/forms'

export const useATMInfoForm = ({
  companyIndex,
  formData,
  onChange
}: {
  companyIndex: number
  formData: ATMGeneralInfoFormData
  onChange: ATMGeneralInfoChangeHandler
}) => {
  const onFormChanged: FormChangedHandler<ATMGeneralInfoFormValue> = useCallback(
    (formValues, formErrors) => onChange({ companyIndex, formData: { formValues, formErrors } }),
    [onChange, companyIndex]
  )

  const { config, Field, set, validate } = useFormData<ATMGeneralInfoFormValue>({
    onFormChanged,
    initialValue: formData.formValues,
    initialErrors: formData.formErrors
  })

  useSyncFormData({ set, formData })
  const formKey = `${FormPaths.ATM_GENERAL_INFO}-${companyIndex}`
  useValidateFormPersist<ATMGeneralInfoFormValue>({
    formName: formKey,
    validate,
    config
  })
  return { Field, config }
}
