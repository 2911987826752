import { useCallback, useState } from 'react'

import { useSaveAffiliatedCompany } from 'commons/hooks/bank/affiliatedCompany'
import { handlerServerError } from 'errors'
import { Toast } from 'ui/components/Toast'

import { MappedAffiliatedCompany } from '../MappedAffiliatedCompany'

export const useAffiliatedCompanyManage = (companyId: number, refreshWebpage: () => void) => {
  const [relation, setRelation] = useState<MappedAffiliatedCompany | undefined>(undefined)

  const { routine: saveAffiliatedCompany, isLoading } = useSaveAffiliatedCompany({
    onSuccess: async () => {
      refreshWebpage()
      Toast.successSmall(`Affiliation successfully saved.`)
      setRelation(undefined)
    },
    onError: (error) => handlerServerError(error)
  })

  const openAddDialog = useCallback(() => {
    setRelation({
      id: 0,
      mainId: companyId,
      affiliatedId: 0,
      notes: '',
      name: ''
    })
  }, [setRelation, companyId])

  const openEditDialog = useCallback(
    (item: MappedAffiliatedCompany) => {
      setRelation({ ...item, name: item?.name, mainId: item.parenCompanyID, affiliatedId: item.childCompanyID })
    },
    [setRelation]
  )

  const closeManageDialog = useCallback(() => {
    setRelation(undefined)
  }, [setRelation])

  const onSubmit = useCallback(
    ({ id, notes, affiliatedId, mainId }: MappedAffiliatedCompany) => {
      if (id) {
        return saveAffiliatedCompany({
          id,
          notes
        })
      } else {
        return saveAffiliatedCompany({
          notes,
          parentCompanyId: mainId,
          childCompanyId: affiliatedId
        })
      }
    },
    [saveAffiliatedCompany]
  )
  return {
    openAddDialog,
    openEditDialog,
    relation,
    onSubmit,
    closeManageDialog,
    isLoading
  }
}
