import { Contact } from 'commons/types/DTO/bank/companyDetails/contact'

type Filter = {
  label: string
  value: string
}

export interface CompanyContactsTableProps {
  companyId: number
  initialContacts: Contact[]
  initialContactsTotalCount: number
  contactsTypeValues: Filter[]
}
