import styled from 'styled-components'
import { CheckBox } from 'ui/components/CheckBox'

export const Box = styled.footer`
  position: sticky;
  left: 0;
  right: 0;
  bottom: 1px;
  background: #fff;
  height: 65px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 48px 0 32px;
  border-top: 1px solid ${(p) => p.theme.uiKit.colors.global.grey.grey300};
  z-index: 10;
`

export const Left = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const WrappedCheckBox = styled(CheckBox)`
  margin: 0 12px 0 0;
`

export const Right = styled.div`
  display: flex;
`
