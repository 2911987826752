import { useCallback } from 'react'

import { useSaveContact } from 'commons/hooks/bank/contact'
import { Contact, ContactMetadata } from 'commons/types/DTO/bank'
import { handlerServerError } from 'errors'
import { Toast } from 'ui/components/Toast'

export const useDetachContactAndCompany = (onCompanyDeleted: () => void) => {
  const { routine: saveContact } = useSaveContact({
    onSuccess: async () => {
      onCompanyDeleted()
      Toast.successSmall('Contact and company were detached.')
    },
    onError: (error) => handlerServerError(error)
  })

  const detachContactAndCompany = useCallback(
    async (contact: Contact, contactId: number, companyId: number) => {
      if (contact) {
        const copy: Contact = {
          ...contact,
          contact_metadata: contact.contact_metadata.filter(
            (i: ContactMetadata) => !(i.contact_id === contactId && i.company_id === companyId)
          )
        }
        await saveContact(copy)
      }
    },
    [saveContact]
  )

  return {
    detachContactAndCompany
  }
}
