import React, { FC, memo, useCallback, useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'

import { getRouteApi, notFound, useRouterState } from '@tanstack/react-router'
import { BsaSar } from 'commons/types/DTO/bank/bsa'
import { BSACTRStatus } from 'commons/types/enums'
import { Request } from 'commons/utils/request'
import { format } from 'date-fns'
import { ButtonGroup } from 'ui/components/ButtonGroup'
import { HeaderButton, IconButton } from 'ui/components/Buttons'
import { DownloadLink } from 'ui/components/DownloadLink'
import { IconName } from 'ui/components/Icon'
import { Page, PageFace } from 'ui/components/Page'
import { Toast } from 'ui/components/Toast'
import { useConfirmation } from 'ui/hooks'
import { SAR } from '~bank-bsa-reporting/hooks'

import {
  EditPeriodDialog,
  FilingInstitutionContactInformation,
  FinancialInstitutionWhereActivityOccurred,
  FincenWarnings,
  Footer,
  GeneralInformation,
  History,
  Narrative,
  SubjectInformation,
  SuspiciousActivityInformation,
  TransactionsInformation,
  Warnings
} from './components'

const routeApi = getRouteApi('/bsa/sars/$id')

const SARDetails: FC = memo(() => {
  const state = useRouterState()
  const navigate = routeApi.useNavigate()
  const params = routeApi.useParams()
  const search = routeApi.useSearch()

  const { isFetching, data: item } = SAR.useGetSAR(+(params.id || 0))
  const queryClient = useQueryClient()
  const deleteMutation = SAR.useDelete(queryClient)
  const saveMutation = SAR.useSave(queryClient)

  useEffect(() => {
    if ((!isFetching && !item) || +params.id === 0 || isNaN(+params.id)) {
      throw notFound()
    }
  }, [isFetching, item, params.id])

  const filters = []
  search?.company_id ? filters.push({ field: 'company_id', type: 'eq', value: search.company_id }) : null
  search?.status ? filters.push({ field: 'status', type: 'eq', value: search.status }) : null
  search?.trans_start_date
    ? filters.push({
        field: 'trans_start_date',
        type: 'gt',
        value: format(new Date(search.trans_start_date), 'yyyy-MM-dd')
      })
    : null
  search?.trans_end_date
    ? filters.push({ field: 'company_id', type: 'lte', value: format(new Date(search.trans_end_date), 'yyyy-MM-dd') })
    : null

  const { data } = SAR.useSARList({
    _options: {
      filters,
      orders: [{ field: 'updated_at', direction: 'DESC' }]
    }
  })
  const { isLoading, companyDetails } = SAR.useCompany(+(item?.company_id || 0))

  const itemIndex = data?.records?.findIndex((i: BsaSar) => i.id === item?.id)
  const nextItem = itemIndex < data?.records?.length - 1 ? data?.records[itemIndex + 1] : null
  const prevItem = itemIndex > 0 ? data?.records[itemIndex - 1] : null

  const { open: startDeleting, Confirmation } = useConfirmation({
    message: `Are you sure you want to delete #${item?.id} SAR?`,
    onConfirm: () =>
      deleteMutation.mutateAsync([item?.id || 0]).then(() => {
        Toast.successSmall(`SAR #${item?.id} was successfully deleted.`)
        navigate({ to: '/bsa/sars' })
      }),
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  const resolveWarnings = useCallback(() => {
    saveMutation.mutate({ ...item, attention: '' })
  }, [saveMutation, item])

  const [openEditPeriodDialog, setOpenEditPeriodDialog] = useState(false)

  const handleError = (message: string) => Toast.error('Error', message)
  const baseUrl = Request.urls.bank.bsaSarTransactionsXls

  return (
    <Page
      face={PageFace.SECONDARY}
      title={item?.id ? `SAR #${item?.id}` : '...'}
      subTitle="SAR"
      isPending={isFetching || isLoading}
      actions={
        <ButtonGroup margin="big">
          <IconButton onClick={startDeleting} icon={IconName.DELETE} face="neutral" data-qa="Delete SAR">
            Delete SAR
          </IconButton>
          <DownloadLink
            withIcon={false}
            direct
            mimeType="application/vnd.ms-excel"
            name={`Transactions_${item?.id}`}
            parametersForm={{
              sar_id: item?.id
            }}
            baseUrl={baseUrl}
            handleError={handleError}
          >
            <HeaderButton size="middle" overColoredPad={true}>
              Download Transactions
            </HeaderButton>
          </DownloadLink>
        </ButtonGroup>
      }
      footer={
        <Footer
          id={item?.id}
          nextId={nextItem?.id}
          previousId={prevItem?.id}
          canApprove={item?.status === BSACTRStatus.NEW}
          search={state.location.search}
        />
      }
    >
      <Confirmation />
      {openEditPeriodDialog && (
        <EditPeriodDialog
          id={item.id}
          initialValue={{ startDate: new Date(item.trans_start_date), endDate: new Date(item.trans_end_date) }}
          onClose={() => setOpenEditPeriodDialog(false)}
        />
      )}
      {!!item && (
        <>
          {!!item.attention && <Warnings attention={item.attention} onResolve={resolveWarnings} />}
          {!!item.fincen_warnings && <FincenWarnings warnings={item.fincen_warnings} />}
          <GeneralInformation
            item={item}
            companyDetails={companyDetails}
            openEditPeriodDialog={() => setOpenEditPeriodDialog(true)}
          />
          <FilingInstitutionContactInformation item={item} />
          <FinancialInstitutionWhereActivityOccurred item={item} />
          <SubjectInformation item={item} />
          <SuspiciousActivityInformation item={item} />
          <TransactionsInformation item={item} />
          <Narrative item={item} />
          <History id={item.id || 0} />
        </>
      )}
    </Page>
  )
})

export default SARDetails
