import { useCallback } from 'react'

import { useFormData } from 'brief-form'

import { ClientPortalInvite } from '../../../../types'

export const useClientPortalInviteDialog = (
  companyId: number,
  onSubmit: (invite: ClientPortalInvite) => Promise<void>
) => {
  const { config, validate, Field } = useFormData<ClientPortalInvite>({
    initialValue: {
      companyIds: [companyId],
      email: '',
      firstName: '',
      lastName: '',
      phone: ''
    }
  })

  const formSubmitHandler = useCallback(() => {
    if (validate({ updateFields: true }).valid) {
      return onSubmit(config.value)
    }
    return Promise.resolve()
  }, [onSubmit, config.value, validate])

  return {
    config,
    Field,
    formSubmitHandler
  }
}
