import { LicensesSICS } from 'commons/types/DTO/bank'

export const dataSourceSelector = (data: any) =>
  (data?.list?.records || []).map((record: LicensesSICS) => ({
    item: {
      dba_name: record.dba_name,
      last_connection_date: record.last_connection_date,
      license_number: record.license_number,
      license_name: record.license_name,
      license_state: record.license_state,
      license_subtype: record.license_subtype,
      organization_id: record.organization_id,
      relationship_id: record.relationship_id,
      relationship_name: record.relationship_name,
      connected: record.connected
    }
  }))
