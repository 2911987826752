import React, { FC, memo } from 'react'

import { Icon } from 'ui/components/Icon'

import { OutlineButtonProps } from './OutlineButtonProps'

import * as SC from './styles'

export const OutlineButton: FC<OutlineButtonProps> = memo((props) => {
  const { children, face, size = 'small', icon, disabled, 'data-qa': dataQA, ...rest } = props

  const data_qa = typeof children === 'string' && !dataQA ? children : dataQA

  const isPositive = face === 'positive'
  const isNegative = face === 'negative'
  const isBrand = face === 'brand'
  const isNeutral = face === 'neutral'

  const isMiddle = size === 'middle'
  const isSmall = size === 'small'

  return (
    <>
      {isPositive && isSmall && (
        <SC.PositiveSmall disabled={disabled} data-qa={data_qa} {...rest}>
          <SC.Inner>{children}</SC.Inner>
        </SC.PositiveSmall>
      )}
      {isNegative && isSmall && (
        <SC.NegativeSmall disabled={disabled} data-qa={data_qa} {...rest}>
          <SC.Inner>{children}</SC.Inner>
        </SC.NegativeSmall>
      )}
      {isNegative && isMiddle && (
        <SC.NegativeMiddle disabled={disabled} data-qa={data_qa} {...rest}>
          <SC.Inner>{children}</SC.Inner>
        </SC.NegativeMiddle>
      )}
      {isPositive && isMiddle && (
        <SC.PositiveMiddle disabled={disabled} data-qa={data_qa} {...rest}>
          <SC.Inner>{children}</SC.Inner>
        </SC.PositiveMiddle>
      )}
      {isBrand && (
        <SC.Brand $icon={!!icon} disabled={disabled} data-qa={data_qa} {...rest}>
          <SC.Inner>
            {!!icon && <Icon name={icon} size={24} />}
            {children}
          </SC.Inner>
        </SC.Brand>
      )}
      {isNeutral && (
        <SC.Neutral $icon={!!icon} disabled={disabled} data-qa={data_qa} {...rest}>
          <SC.Inner>
            {!!icon && <Icon name={icon} size={24} />}
            {children}
          </SC.Inner>
        </SC.Neutral>
      )}
    </>
  )
})
