import { Request } from 'commons/utils/request'

import { ApplicationDocumentCreateWebpage, ApplicationDocumentPayload } from './types'

export const getApplicationDocumentCreateWebPage = (id: number) =>
  Request.post<ApplicationDocumentCreateWebpage>(Request.urls.bank.applicationClientDocumentCreateWebpage, {
    applicationId: id
  })

export const postApplicationClientDocument = (payload: ApplicationDocumentPayload) =>
  Request.post(Request.urls.bank.applicationClientDocument, payload)

export const deleteApplicationClientDocument = (id: number) =>
  Request.delete(Request.urls.bank.applicationClientDocument, {
    id
  })
