import { useMutation, useQueryClient } from 'react-query'

import { Request } from 'commons/utils/request'
import { handlerServerError } from 'errors'

interface DeleteAnnualReviewSalesDepositsProp {
  id: number
  company_id: number
  annual_review_id: number
}

export const useDeleteAnnualReviewSalesDeposits = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (props: DeleteAnnualReviewSalesDepositsProp) =>
      Request.delete(Request.urls.bank.annualReviewSalesDeposits, { ...props }),
    {
      onError: (error: Error) => handlerServerError(error),
      onSuccess: () => {
        queryClient.invalidateQueries('annual-review-sales-deposits')
      }
    }
  )
}
