import React from 'react'

import { FF, TextInput, Validators } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

export const FilingName = () => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name="filing_name"
      validators={{
        onChange: ({ value }) => Validators.required.field(value)
      }}
      children={(field) => (
        <FF
          required
          field={field}
          label="Filing Name"
          input={(p) => <TextInput data-qa="filingName" {...p} autoFocus />}
        />
      )}
    />
  )
}
