import React, { FC, memo, useCallback, useMemo } from 'react'

import { Contact } from 'commons/types/DTO/bank'

import { useContactItem, useContactMetadataItem, useSaveContact } from '../../hooks'
import { ContactMetadataFormValue } from '../../types/forms'
import { ContactMetadataManageProps } from './ContactMetadataManageProps'
import { ContactMetadataManageDialog } from './components'

export const ContactMetadataManage: FC<ContactMetadataManageProps> = memo((props) => {
  const { contactId, companyId, onClose } = props
  const { contact } = useContactItem(contactId)
  const metadata = useContactMetadataItem(contact, companyId)
  const { saveContact } = useSaveContact({})

  const formInitialValue: ContactMetadataFormValue | undefined = useMemo(
    () =>
      metadata
        ? {
            ...metadata,
            permissions: [
              metadata?.is_documents ? 'documents' : '',
              metadata?.is_financials ? 'financials' : ''
            ].filter(Boolean)
          }
        : undefined,
    [metadata]
  )

  const onSubmit = useCallback(
    (metadata: ContactMetadataFormValue) => {
      const copy: Contact = { ...contact, contact_metadata: [...contact.contact_metadata] }
      metadata.is_documents = metadata.permissions.indexOf('documents') !== -1
      metadata.is_financials = metadata.permissions.indexOf('financials') !== -1
      metadata.ownership = parseInt(metadata.ownership.toString())

      if (!metadata.id) {
        copy.contact_metadata.push(metadata)
      } else {
        const index = copy.contact_metadata.findIndex((i) => i.id === metadata.id)
        if (index != -1) {
          copy.contact_metadata[index] = metadata
        }
      }

      return saveContact(copy)
    },
    [contact, saveContact]
  )

  if (!(contact && metadata)) {
    return null
  }

  return (
    <ContactMetadataManageDialog
      onClose={onClose}
      onSubmit={onSubmit}
      isOpen={true}
      metadata={formInitialValue}
      contact={contact}
      companyId={companyId}
    />
  )
})
