import { UpdateBankUserRequest, UsersWebPageResponse } from '../../../../types'

export const getFormFromTableItem = (item: UsersWebPageResponse['users'][0]): UpdateBankUserRequest => {
  const { id, firstName, lastName, email, phone, assignedCompaniesIds, role } = item

  return {
    id,
    firstName,
    lastName,
    email,
    phone,
    assignedCompaniesIds,
    role: role.key
  }
}
