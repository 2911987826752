import React from 'react'

import { FF, SecuredInput, Validators } from 'ui/components'

import { useSARFormContext } from '../../../../../../../../context'

type TINProps = {
  index: number
}

export const TIN = ({ index }: TINProps) => {
  const { formApi } = useSARFormContext()
  const noKnownAccountsInvolved = formApi.useStore((s) => s.values.subjects_info[index].no_known_accounts_involved)

  return (
    <formApi.Field
      name={`subjects_info[${index}].institution_info[0].tin` as 'subjects_info[0].institution_info[0].tin'}
      validators={{
        onChange: ({ value }) => (noKnownAccountsInvolved ? undefined : Validators.required.field(value))
      }}
      children={(field) => (
        <FF
          required={!noKnownAccountsInvolved}
          field={field}
          label="Institution TIN"
          input={(p) => <SecuredInput {...p} disabled={noKnownAccountsInvolved} maxLength={25} />}
        />
      )}
    />
  )
}
