import React from 'react'

import { FF, UrlInput } from 'ui/components'

import { useSARFormContext } from '../../../../../../context'

type WebSiteProps = {
  index: number
}

export const WebSite = ({ index }: WebSiteProps) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={`subjects_info[${index}].websites[0].value` as 'subjects_info[0].websites[0].value'}
      children={(field) => <FF field={field} label="22a. Website (URL) address" input={(p) => <UrlInput {...p} />} />}
    />
  )
}
