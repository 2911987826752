import { HistoryEntity } from '../historyAudit'
import { BankAccount } from './BankAccount'
import { UploadedFile } from './UploadedFile'

export type ReportMonthlyWebPage = {
  bankAccounts: BankAccount[]
  enableAcknowledgeAllWarnings: boolean
  enableRecalculate: boolean
  enableReview: boolean
  enableSaveNotes: boolean
  enableUploadFile: boolean
  infoMonth: string
  infoNotes: string
  infoRelationshipID: number
  infoRelationshipName: string
  infoStatus: string
  infoWarnings: string[]
  monthlyAnalyticsID: number
  history: HistoryEntity[]
  historyTotalCount: number
  monthlyAnalyticsName: string
  quarterlyAnalyticsID: number
  quarterlyAnalyticsName: string
  title: string
  titleOverline: string
  uploadedFiles: UploadedFile[]
  uploadedFilesTotalCount: number
}
