import SC from 'styled-components'

import { ButtonSize } from '../buttonSize'
import { StyledProps } from './styledProps'

export const Secondary = SC.button<StyledProps>`
  outline: none;
  display: inline-block;
  overflow: hidden;
  padding: ${(p) =>
    p.$onlyIcon ? '0' : p.size === ButtonSize.LARGE ? '0 40px' : p.size === ButtonSize.SMALL ? '0 30px' : '0 30px'};
  height: ${(p) =>
    p.$onlyIcon ? 'auto' : p.size === ButtonSize.LARGE ? '52px' : p.size === ButtonSize.SMALL ? '36px' : '40px'};
  border-radius: 3px;
  border: none;
  color: ${(p) => p.theme.uiKit.colors.global.grey.grey900};
  background: ${(p) => p.theme.uiKit.colors.global.grey.grey100};
  font-weight: 700;
  font-size: ${(p) => (p.size === ButtonSize.LARGE ? '16px' : p.size === ButtonSize.SMALL ? '11px' : '14px')};
  cursor: pointer;
  
  &:hover {
    color: ${(p) => p.theme.uiKit.colors.global.general.main};
    background: ${(p) => p.theme.uiKit.colors.global.grey.grey500};
  }
  
  &:active {
    color: ${(p) => p.theme.uiKit.colors.global.general.main};
    background: ${(p) => p.theme.uiKit.colors.global.grey.grey700};
  }
  
  &:disabled {
    color: ${(p) => p.theme.uiKit.colors.global.grey.grey500};
    background: ${(p) => p.theme.uiKit.colors.global.grey.grey300};
    cursor: not-allowed;
  }
  
  &:focus {
    outline: 4px solid #7b7b7b;
  }
`
