{
  "name": "CP",
  "logo": {
    "_1x": "logo1x.png",
    "_2x": "logo2x.png",
    "_3x": "logo3x.png"
  },
  "authLogo": {
    "_1x": "authLogo1x.png",
    "_2x": "authLogo2x.png",
    "_3x": "authLogo3x.png"
  },
  "background": {
    "_1x": "bg1x.png",
    "_2x": "bg2x.png",
    "_3x": "bg3x.png"
  },
  "uiKit": {
    "colors": {
      "theme": {
        "brand": {
          "lightest": "#A1C1D3",
          "lighter": "#81ADC5",
          "primary": "#6298B6",
          "darker": "#4E7A92",
          "darkest": "#3B5B6D"
        },
        "gradient": {
          "login": "linear-gradient(200deg, #81ADC5 0%, #4E7A92 100%)"
        },
        "positive": {
          "main": "#6298B6",
          "darker": "#4E7A92",
          "darkest": "#3B5B6D"
        }
      }
    }
  }
}
