import { isValid } from 'date-fns'

const emptyResult = undefined

type RemainingTimeRecord = {
  min: number
  minSec: number
  totalSec: number
  totalMs: number
}

export const getRemainTime = (expiresAt: unknown): RemainingTimeRecord | typeof emptyResult => {
  if (!isValid(new Date(expiresAt as string))) {
    return emptyResult
  }

  const durationTotalMs = new Date(expiresAt as string).getTime() - new Date().getTime()

  if (durationTotalMs <= 0) {
    return emptyResult
  }

  const durationTotalSec = durationTotalMs / 1000
  const remainMin = Math.trunc(durationTotalSec / 60)
  const remainMinSec = Math.trunc(durationTotalSec - remainMin * 60)

  return {
    totalMs: durationTotalMs,
    totalSec: durationTotalSec,
    min: remainMin,
    minSec: remainMinSec
  }
}
