export type DepositsFromSales = {
  cashATMLoad: number
  cashDeposits: number
  cashInvoices: number
  checks: number
  creditDebitCards: number
  internalTransfers: number
  other: number
  total: number
}
