import { useCallback, useEffect, useState } from 'react'
import { useStateManager } from 'react-query-state-manager'

import { useGetConnectionsWebPage } from 'commons/hooks/bank/connections'
import { UseWebPageProps, WebPage } from 'commons/types/DTO/bank/connections'
import { Keys } from 'state-manager'
import { useTableState } from 'ui/components/QueryTable'

const CONNECTIONS_LIMIT = 10
const CONNECTIONS_OFFSET = 0

export const useConnectionsList = () => {
  const sm = useStateManager()

  const [webPageData, setWebPageData] = useState<WebPage | undefined>(undefined)
  const [webPageFilter, setWebPageFilter] = useState<UseWebPageProps>({
    connectionsLimit: CONNECTIONS_LIMIT,
    connectionsOffset: CONNECTIONS_OFFSET,
    connectionsSearchQuery: ''
  })

  const tableState = useTableState({
    size: CONNECTIONS_LIMIT,
    page: 1,
    filter: {
      connectionsSearchQuery: ''
    }
  })

  const { data: webPage, isFetching } = useGetConnectionsWebPage(webPageFilter)

  const onSetWebPagePagination = useCallback(
    (connectionsPage: number, connectionsLimit: number) => {
      const connectionsOffset = (connectionsPage - 1) * connectionsLimit
      tableState.onPageChange(connectionsPage, connectionsLimit)
      setWebPageFilter((filter) => ({ ...filter, connectionsOffset, connectionsLimit }))
    },
    [tableState]
  )

  const onSetWebPageQuery = (searchWord: string) => {
    setWebPageFilter((filter) => ({ ...filter, connectionsSearchQuery: searchWord }))
    tableState.filterForm.set({ value: { ...tableState.filterForm.config.value, connectionsSearchQuery: searchWord } })
  }

  useEffect(() => {
    webPage && setWebPageData(webPage)
  }, [webPage])

  const invalidateTable = useCallback(
    () =>
      sm.invalidate(
        Keys.Connection.List(
          webPageFilter.connectionsLimit,
          webPageFilter.connectionsOffset,
          webPageFilter.connectionsSearchQuery
        )
      ),
    [sm, webPageFilter]
  )

  const pagination = {
    connectionsTotalCount: webPageData?.connectionsTotalCount || 0,
    limit: webPageFilter.connectionsLimit,
    offset: webPageFilter.connectionsOffset
  }

  const [isSetupKeyDialogOpen, setSetupKeyDialogOpen] = useState(false)

  const openSetupKeyDialog = () => setSetupKeyDialogOpen(true)
  const closeSetupKeyDialog = () => setSetupKeyDialogOpen(false)

  return {
    tableState,
    webPageData,
    isFetching,
    onSetWebPagePagination,
    onSetWebPageQuery,
    invalidateTable,
    pagination,
    isSetupKeyDialogOpen,
    openSetupKeyDialog,
    closeSetupKeyDialog
  }
}
