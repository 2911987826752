export type UseTableStateOpts = {
  filter?: {
    expanded?: boolean
    dirty?: boolean
  }
  order?: { field: string; direction: 'ASC' | 'DESC' }[]
  pagination?: {
    page: number
    size: number
  }
}
