import { LicenseDetails } from 'commons/types/DTO/commons'

import { LicenseDetailsFormValue } from '../../../../types'

export const mapLicenseDetailsForm = ({
  licenseID,
  entityUID,
  name,
  type,
  subtype,
  POSType,
  POSTypeOther,
  licenseNumber,
  issueDate,
  expirationDate,
  phone,
  licenseMetrcAPIKey
}: LicenseDetailsFormValue): LicenseDetails => ({
  licenseID: +licenseID,
  entityUID,
  name,
  type,
  subtype,
  POSType,
  POSTypeOther,
  licenseNumber,
  issueDate: issueDate === '' ? null : issueDate,
  expirationDate: expirationDate === '' ? null : expirationDate,
  phone,
  licenseMetrcAPIKey
})
