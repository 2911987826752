import styled from 'styled-components'

export const SliderWrapper = styled.div`
  display: flex;
  align-items: center;

  &:hover {
    svg {
      color: green;
    }
  }
`

export const IconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    svg {
      fill: #45d5b4;
    }
  }
`

export const OwnersLabelRow = styled.div`
  display: flex;
  word-break: break-word;
  gap: 0px 16px;

  > i {
    margin-top: 24px;
    cursor: pointer;
  }
`
