import React, { FC, ReactNode, memo, useState } from 'react'

import { IconName } from 'ui/components'

import * as SC from './styles'

type AccordeonProps = {
  title: ReactNode
  action: ReactNode
  content: ReactNode
  hasWarning: boolean
}

export const Accordeon: FC<AccordeonProps> = memo((props: AccordeonProps) => {
  const { title, action, content, hasWarning } = props

  const [opened, setOpened] = useState(false)

  return (
    <SC.Box>
      <SC.Header $opened={opened} $warning={hasWarning} onClick={() => setOpened(!opened)}>
        <SC.Title>
          <SC.WrappedIcon name={opened ? IconName.MINUS : IconName.PLUS} /> {title}
        </SC.Title>
        <SC.Action>{opened && action}</SC.Action>
      </SC.Header>
      <SC.Content>{opened && content}</SC.Content>
    </SC.Box>
  )
})
