import { useState } from 'react'

import { DocumentDetailsFile } from '~bank-documents/types/documentDetailsFile'

import { useDeleteFile } from './useDeleteFile'
import { useUploadFile } from './useUploadFile'

export const useDocFiles = ({ id, withDialog }) => {
  // edit
  const [fileForEditDialog, setFileForEditDialog] = useState<DocumentDetailsFile | null>(null)

  const closeFileEditDialog = () => setFileForEditDialog(null)
  const openFileEditDialog = (file: DocumentDetailsFile) => setFileForEditDialog(file)

  // upload
  const { onUpload, showUploadFilesDialog, closeFileUploadDialog, onSubmitFileDialog } = useUploadFile({
    id,
    withDialog
  })

  // delete
  // closeFileEditDialog is necessary for closing editDialog while deleting doc from editDIalog
  const { openDeleteConfirmation, DeleteConfirmation } = useDeleteFile(closeFileEditDialog)

  return {
    DeleteConfirmation,
    openDeleteConfirmation,

    fileForEditDialog,
    openFileEditDialog,
    closeFileEditDialog,

    onUpload,
    showUploadFilesDialog,
    closeFileUploadDialog,
    onSubmitFileDialog
  }
}
