import { useSetCompanyOwners as useHook } from 'commons/hooks/bank/company'
import { handlerServerError } from 'errors'

export const useSetCompanyOwners = (resetWebpage: () => void) => {
  const { routine } = useHook({
    onSuccess: async () => {
      resetWebpage()
    },
    onError: (error) => handlerServerError(error)
  })

  return {
    onSetCompanyOwners: routine
  }
}
