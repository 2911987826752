import styled from 'styled-components'
import { CSS } from 'ui/helpers'

const PermissionCheckboxHeight = 22
const PermissionCheckboxVerticalMargin = 6

export const Wrapper = styled.div`
  max-width: 600px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 5px 32px 30px 32px;
`

export const Title = styled.h5`
  ${(p) => CSS.populateFont(p.theme.uiKit.typography.h5.semiBold)};
  margin-bottom: 12px;
`

export const FormWrapper = styled.div`
  width: 100%;
`

export const PermissionWrapper = styled.div`
  width: 100%;
`

export const TogglePermissionWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0px;
`
export const TogglePermissionTitle = styled.div`
  ${(p) => CSS.populateFont(p.theme.uiKit.typography.bodyL.regular)}
`

export const ToggleHint = styled.p`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0px 8px;
`

export const ToggleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0px 16px;
`

export const CheckboxColumnWrapper = styled.div<{ $isOpened: boolean; $elementsCount: number }>`
  position: relative;
  overflow: hidden;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
  height: 100%;
  max-height: ${(p) =>
    p.$isOpened ? p.$elementsCount * (PermissionCheckboxHeight + 2 * PermissionCheckboxVerticalMargin) + 'px' : '0px'};
`

export const CheckboxPermission = styled.div`
  position: relative;
  margin: ${PermissionCheckboxVerticalMargin}px 8px;
  max-height: ${PermissionCheckboxHeight}px;
`
