import React, { memo } from 'react'

import { IconButton } from 'ui/components/Buttons'

import { IconName } from '../../Icon'
import { Panel, PanelFace } from '../../Panel'
import { FilterPanelProps } from './FilterPanel.types'

export const FilterPanel = memo((props: FilterPanelProps) => {
  const { children, title = 'Filters', onReset, isDirty = false, expanded, onToggle } = props

  return (
    <Panel
      face={PanelFace.SECONDARY}
      title={title}
      expanded={expanded}
      onToggle={onToggle}
      actions={
        isDirty ? (
          <IconButton icon={IconName.DELETE} face="negative" onClick={onReset}>
            Clear
          </IconButton>
        ) : undefined
      }
    >
      {children}
    </Panel>
  )
})
