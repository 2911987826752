import React from 'react'

import { ctrTINTypes } from 'commons/types/dictionaries'
import { FF, Select, Validators } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

export const TINType = () => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name="institution_contact_info.tin_type"
      validators={{
        onChange: ({ value }) => Validators.required.field(value)
      }}
      children={(field) => (
        <FF
          required
          label="78. TIN type"
          field={field}
          input={(p) => (
            <Select {...p} data={ctrTINTypes} data-qa="78. TIN type" options-data-qa="78. TIN type option" />
          )}
        />
      )}
    />
  )
}
