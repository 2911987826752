import { FormPaths, OnboardingFormActions } from '../../enums'
import { OnboardingFormAction } from '../../types/actions'
import { OnboardingFormState } from '../../types/state'
import * as helpers from './helpers'

export const formReducer = (state: OnboardingFormState, action: OnboardingFormAction): OnboardingFormState => {
  let newState
  switch (action.type) {
    case OnboardingFormActions.SET_INIT_APPLICATION:
      newState = helpers.mapApplicationToState({ currentState: state, responseData: action.payload })
      return newState

    /** onChange  */
    case OnboardingFormActions.ON_CHANGE:
      newState = helpers.calculateNewState({
        currentState: state,
        path: action.payload.path,
        formData: action.payload.formData,
        params: action.payload.params
      })
      return {
        ...newState,
        hasChanges: true,
        // autosave no needed for notes
        isDirty: action.payload.path !== FormPaths.NOTES ? true : false
      }
    case OnboardingFormActions.SET_IS_AUTOSAVE_UPDATING:
      return {
        ...state,
        isAutosaveUpdating: action.payload
      }
    case OnboardingFormActions.SET_HAS_CHANGES:
      return {
        ...state,
        hasChanges: action.payload
      }

    /** ui */
    case OnboardingFormActions.SET_PREV_STEP:
      return state.step < 2 ? state : { ...state, step: state.step - 1 }
    case OnboardingFormActions.SET_NEXT_STEP:
      return state.step >= state.currentStep ? state : { ...state, step: state.step + 1 }

    case OnboardingFormActions.SET_SHOW_CLIENT_NOTE_DIALOG:
      newState = { ...state, isShowClientNotesDialog: action.payload }
      return newState

    /** Validation, Submit */
    case OnboardingFormActions.SET_IS_FORM_SUBMITTING:
      return {
        ...state,
        isFormSubmitting: action.payload,
        isDirty: false
      }
    case OnboardingFormActions.SET_GENERAL_ERRORS:
      return { ...state, generalErrors: action.payload }
    case OnboardingFormActions.SET_SUBMIT_ERRORS:
      newState = {
        ...state,
        ...helpers.handleSubmitErrors({ currentState: state, submitErrors: action.payload })
      }
      return newState
    case OnboardingFormActions.SET_ERRORS:
      newState = {
        ...state,
        lastErrorCount: action.payload
      }
      return newState
    case OnboardingFormActions.FLUSH_LAST_ERROR_COUNT:
      newState = {
        ...state,
        lastErrorCount: 0,
        isFormSubmitting: false,
        generalErrors: []
      }
      return newState
    case OnboardingFormActions.SET_CANCEL_CLIENT_NOTE_DIALOG:
      newState = {
        ...state,
        isShowClientNotesDialog: false,
        isFormSubmitting: false
      }
      return newState
    /**  Company */

    case OnboardingFormActions.ADD_COMPANY:
      return {
        ...state,
        isDirty: true, // TODO: find out whether it is necessary to do the same in other places?
        ...helpers.getStateAfterCompanyAdded({ currentState: state })
      }
    case OnboardingFormActions.REMOVE_COMPANY:
      newState = {
        ...state,
        isDirty: true,
        ...helpers.getStateAfterCompanyRemoved({ currentState: state, companyIndex: action.payload.companyIndex })
      }
      return newState
    case OnboardingFormActions.COPY_COMPANY_PHYSICAL_ADDRESS_TO_MAILING:
      return helpers.stateAfterCopyComapnyPhysicalAddressToMailing({
        currentState: state,
        companyIndex: action.payload.companyIndex
      })

    // License
    case OnboardingFormActions.ADD_LICENSE:
      return {
        ...state,
        isDirty: true,
        ...helpers.getStateAfterAddedLicense({ currentState: state, companyIndex: action.payload.companyIndex })
      }
    case OnboardingFormActions.REMOVE_LICENSE:
      return {
        ...state,
        isDirty: true,
        ...helpers.getStateAfterLicenseRemoved({
          currentState: state,
          licenseIndex: action.payload.licenseIndex,
          companyIndex: action.payload.companyIndex
        })
      }
    // Contact
    case OnboardingFormActions.ADD_CONTACT:
      return {
        ...state,
        isDirty: true,
        ...helpers.getStateAfterAddedContact({ currentState: state, companyIndex: action.payload.companyIndex })
      }

    case OnboardingFormActions.REMOVE_CONTACT:
      return {
        ...state,
        isDirty: true,
        ...helpers.getStateAfterContactRemoved({
          currentState: state,
          companyIndex: action.payload.companyIndex,
          contactIndex: action.payload.contactIndex
        })
      }

    case OnboardingFormActions.COPY_CONTACT:
      newState = helpers.getStateAfterContactCopy({ currentState: state, copyData: action.payload })
      return {
        ...state,
        ...newState,
        isDirty: true
      }
    case OnboardingFormActions.ADD_ATM:
      return {
        ...state,
        isDirty: true,
        ...helpers.getStateAfterAddedATM({ currentState: state, companyIndex: action.payload.companyIndex })
      }
    case OnboardingFormActions.REMOVE_ATM:
      return {
        ...state,
        isDirty: true,
        ...helpers.getStateAfterATMRemoved({
          currentState: state,
          companyIndex: action.payload.companyIndex,
          atmIndex: action.payload.atmIndex
        })
      }
    default:
      return state
  }
}
