import React from 'react'

import { ButtonSize } from '../../Button/buttonSize'
import { IconName, IconSize } from '../../Icon'
import { Spinner, SpinnerSize } from '../../Spinner'
import { DEFAULT_PAGE_SIZE_OPTIONS } from '../constants'
import { TableProps } from './TableProps'
import { TableColumn } from './tableColumn'

import * as SC from './styles'

export const Table = function <T extends { [key: string]: any }>(props: TableProps<T>) {
  const { loading, columns, dataSource, noDataMessage, sorting, pagination, dataQa, ...rest } = props
  const isEmpty = !loading && !dataSource?.length
  const isPaginationReady = !!pagination?.page && !!pagination?.pageSize && !!pagination?.total

  const thClick = React.useCallback(
    (column: TableColumn<T>) => {
      if (!sorting || column.sortable === false) {
        return
      }

      if (column.sortable && !column.key) {
        throw new Error('Table column is sortable but does not have the key.')
      }

      const field = sorting.order.find((item) => item.field === column.key)

      // Click on same column, just change column sort order.
      if (field) {
        sorting.onChange({
          field: field.field,
          direction: field.direction === 'ASC' ? 'DESC' : 'ASC'
        })
      } else {
        sorting.onChange({
          field: column.key.toString(),
          direction: sorting.order[0].direction
        })
      }
    },
    [sorting]
  )

  return (
    <SC.Box {...rest}>
      {loading && <Spinner centered size={SpinnerSize.S} />}
      <SC.T $loading={loading} $isEmpty={isEmpty} $pagination={!!pagination} data-qa={dataQa}>
        <SC.H>
          <tr>
            {columns.map((column, index) => {
              const field = sorting?.order.find((item) => item.field === column.key)

              return (
                <SC.TH
                  key={index}
                  align={column.align || 'center'}
                  sortable={!!sorting && !!column.sortable}
                  onClick={column.sortable ? () => thClick(column) : undefined}
                  $width={column.width}
                  $color={column.color}
                >
                  {column.title}
                  {column.sortable && (
                    <SC.SorterIcon
                      $sorted={!field}
                      direction={field?.direction || 'ASC'}
                      size={IconSize.XS}
                      name={IconName.UP}
                    />
                  )}
                </SC.TH>
              )
            })}
          </tr>
        </SC.H>
        <tbody>
          {isEmpty && (
            <tr>
              <SC.EmptyTd data-qa="table-no-items-message" colSpan={columns.length}>
                {noDataMessage || 'No items to show'}
              </SC.EmptyTd>
            </tr>
          )}
          {(dataSource || []).map((record, index) => (
            <SC.TR key={index} $errorHighlight={record?.rowProps?.errorHighlight || undefined}>
              {columns.map((column, tdIndex) => {
                const { key, render } = column

                if (!key && !render) {
                  throw new Error('Table column should specify either "key" name or "render" function')
                }

                const item = record.item
                const valueFromKey = key ? item[key.toString()] : undefined
                const finalValue = render ? render(item) : valueFromKey
                const dataQa = column.dataQa ? column.dataQa(valueFromKey) : `[${column?.title}]_[${index}]`
                return (
                  <SC.TD height="48px" data-qa={dataQa} key={tdIndex} $align={column.align || 'center'}>
                    {finalValue}
                  </SC.TD>
                )
              })}
            </SC.TR>
          ))}
        </tbody>
      </SC.T>
      {isPaginationReady && (
        <SC.Bottom $pagination={!!pagination}>
          {!!pagination && !isEmpty && (
            <SC.WrapperPagination
              withPageSize
              total={pagination?.total}
              pageSize={pagination?.pageSize}
              pageSizeOptions={pagination?.pageSizeOptions ?? DEFAULT_PAGE_SIZE_OPTIONS}
              tooMany={pagination.tooMany}
              page={pagination.page}
              onPageChange={pagination?.onChange}
              size={ButtonSize.MEDIUM}
            />
          )}
        </SC.Bottom>
      )}
    </SC.Box>
  )
}
