import { useCallback, useState } from 'react'

import { TableState, UseTableStateOpts } from '../types'

export const useTableState = (opts: UseTableStateOpts): TableState => {
  /**
   * Pagination.
   */

  const [pagination, setPagination] = useState({ ...(opts.pagination || { page: 1, size: 10 }) })
  const changePage = useCallback((page: number, size: number) => setPagination({ page, size }), [setPagination])

  /**
   * Filter expanded/collapsed state.
   */

  const [expanded, setExpanded] = useState(opts.filter?.expanded || false)
  const toggle = useCallback(() => setExpanded(!expanded), [setExpanded, expanded])
  const open = useCallback(() => setExpanded(true), [setExpanded])
  const close = useCallback(() => setExpanded(false), [setExpanded])

  /**
   * Sorting.
   */

  const [order, setOrder] = useState(opts?.order || [])
  const changeOrder = useCallback((value: { field: string; direction: 'ASC' | 'DESC' }) => {
    setOrder([value])
  }, [])

  /**
   * Return final shape.
   */

  return {
    filter: {
      expanded,
      toggle,
      open,
      close
    },
    pagination: {
      ...pagination,
      change: changePage
    },
    sorting: {
      order,
      change: changeOrder
    }
  }
}
