import { createRoute, redirect } from '@tanstack/react-router'
import { BANK_CLIENT_USERS_PATH } from 'commons/constants/routes'

import { ClientUsersWrapper } from './ClientUsersWrapper'
import { clientUsersListRoute } from './Routes/ClientUsersList/routes'

export const routeClientUsersTree = (rootRoute: BPRootRoute) => {
  const clientsUsersIndexRoute = createRoute({
    beforeLoad: async ({ context }) => {
      if (!context.accessibleRoutingFlags.clientUserRootAccess) {
        throw redirect({
          to: '/'
        })
      }
    },
    getParentRoute: () => rootRoute,
    path: BANK_CLIENT_USERS_PATH,
    component: ClientUsersWrapper
  })

  return clientsUsersIndexRoute.addChildren([
    clientUsersListRoute<typeof clientsUsersIndexRoute>(clientsUsersIndexRoute)
  ])
}
