import { OnboardingFormState } from '../../../types/state'
import { setNewCompanies } from './setNewCompanies'

export const getStateAfterATMRemoved = ({
  companyIndex,
  currentState,
  atmIndex
}: {
  companyIndex: number
  currentState: OnboardingFormState
  atmIndex: number
}) => {
  const selectedCompany = currentState.formValue.companiesFormValues[companyIndex]
  const oldATMs = selectedCompany.atms

  const newATMs = oldATMs.filter((_, index) => index !== atmIndex)
  const newCompany = { ...selectedCompany, atms: newATMs }
  const newCompaniesFormValues = setNewCompanies({
    companyIndex,
    oldCompanies: currentState.formValue.companiesFormValues,
    newCompany
  })

  return {
    selectedCompany: newCompany,
    formValue: {
      ...currentState.formValue,
      companiesFormValues: newCompaniesFormValues
    }
  }
}
