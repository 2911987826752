const isValid = (v: string) => {
  if (!v) {
    return undefined
  }

  // eslint-disable-next-line
  return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
    (v || '').toString()
  )
    ? undefined
    : 'Invalid URL format'
}

export const url = {
  valid: (v: string) => isValid(v)
}
