import React from 'react'

import { FF, TextInput } from 'ui/components'

import { useSARFormContext } from '../../../../../../../../context'

type OtherDeskProps = {
  index: number
}

export const OtherDesk = ({ index }: OtherDeskProps) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={
        `subjects_info[${index}].relationships_info[0].other_desc` as 'subjects_info[0].relationships_info[0].other_desc'
      }
      children={(field) => <FF field={field} input={(p) => <TextInput {...p} />} errorPosition="right" />}
    />
  )
}
