import React from 'react'

import { ClientUserPermissionsHelper } from 'commons/utils/clientUserPermissionsHelper'
import { DateTime } from 'ui/components/DateTime'
import { Link } from 'ui/components/Link'
import { TableColumn } from 'ui/components/Table'

import { Response } from '../../../types'

export const columns: TableColumn<Response['items'][0]>[] = [
  {
    title: 'Relationship',
    align: 'left',
    sortable: true,
    key: 'companyName',
    render: ({ id, companyName }) => <Link to={`/relationships/${id}`}>{companyName}</Link>
  },
  {
    title: 'User Email',
    align: 'left',
    sortable: true,
    key: 'userEmail'
  },
  {
    title: 'User Last Login',
    align: 'right',
    key: 'userLastLoginAt',
    render: ({ userLastLoginAt }) => <DateTime date={userLastLoginAt} withTime />
  },
  {
    title: 'User Created At',
    align: 'right',
    sortable: true,
    key: 'userCreatedAt',
    render: ({ userCreatedAt }) => <DateTime date={userCreatedAt} withTime />
  },
  {
    title: 'Company Business Type',
    align: 'right',
    sortable: true,
    key: 'companyBusinessType'
  },
  {
    title: 'Company Customer Status',
    align: 'right',
    sortable: true,
    key: 'companyCustomerStatus'
  },
  {
    title: 'Permissions',
    align: 'center',
    sortable: false,
    render: ({ permissions }) => ClientUserPermissionsHelper.getAccessibleRolesLabel(permissions)
  }
]
