import React from 'react'

import { CompaniesPath } from 'commons/constants/routes'
import { Document } from 'commons/types/DTO/bank/companyDetails/document'
import { DateTime } from 'ui/components'
import { CheckMark } from 'ui/components/CheckMark'
import { IconName } from 'ui/components/Icon'
import { FrequencyLabel } from 'ui/components/Labels'
import { Link } from 'ui/components/Link'
import { MenuIconHoverFace, MenuItemBehavior, MenuItemTextFace, TableActions } from 'ui/components/QueryTable'
import { TableColumn } from 'ui/components/Table'
import { DocumentsPaths } from '~bank-documents/constants'

import { DueTooltip } from './components/DueTooltip'

export const columns = (
  initialAlert: number,
  firstAlert: number,
  lastAlert: number,
  onDeleteDocument: (document: Document | undefined) => void,
  returnUrl?: string
) =>
  [
    {
      title: 'Document Name',
      sortable: true,
      key: 'name',
      align: 'left',
      render: (item) => (
        <Link to={DocumentsPaths.DETAILS} params={{ documentId: item.id }}>
          {item.name}
        </Link>
      )
    },
    {
      title: 'Internal',
      sortable: true,
      key: 'internal',
      render: ({ internal }) => (internal ? <CheckMark value rounded /> : null)
    },
    {
      title: 'Subject',
      align: 'left',
      render: (item) => (
        <>
          <Link
            to={CompaniesPath.DETAILS}
            params={{
              companyId: item.company.id
            }}
          >
            {item.company.name}
          </Link>
        </>
      )
    },
    {
      title: 'Frequency',
      sortable: true,
      key: 'frequency',
      align: 'left',
      render: ({ frequency }) => <FrequencyLabel name={frequency} />
    },
    {
      title: 'Expiration Date',
      sortable: true,
      key: 'expirationDate',
      align: 'left',
      render: ({ expirationDate }) => <DateTime date={expirationDate} />
    },
    {
      align: 'left',
      title: 'Alerts',
      render: (item) => (
        <DueTooltip
          id={item.id || 0}
          pastDue={item.alerts.pastDue.count}
          firstAlerted={item.alerts.firstAlerted.count}
          initialAlerted={item.alerts.initialAlerted.count}
          lastAlerted={item.alerts.lastAlerted.count}
          expiration_date={item.expirationDate}
          firstAlert={firstAlert}
          initialAlert={initialAlert}
          lastAlert={lastAlert}
        />
      )
    },
    {
      title: 'Actions',
      width: '50px',
      render: (item: Document) => (
        <TableActions
          items={[
            {
              key: 1,
              title: 'Edit',
              iconName: IconName.EDIT,
              iconHoverFace: MenuIconHoverFace.PRIMARY,
              textFace: MenuItemTextFace.GREY,
              url: `/documents/${item?.id}/edit?returnUrl=${returnUrl}`
            },
            {
              key: 2,
              title: 'Delete',
              behavior: MenuItemBehavior.BUTTON,
              iconName: IconName.DELETE,
              iconHoverFace: MenuIconHoverFace.DANGER,
              textFace: MenuItemTextFace.GREY,
              onClick: () => onDeleteDocument(item)
            }
          ]}
        />
      )
    }
  ].filter(Boolean) as TableColumn<any>[]
