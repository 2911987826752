import { OnboardingFormState } from '../../../types/state'
import { companyMailingAddressToState } from './companyMailingAddressToState'

export const stateAfterCopyComapnyPhysicalAddressToMailing = ({
  currentState,
  companyIndex
}: {
  currentState: OnboardingFormState
  companyIndex: number
}): OnboardingFormState => {
  const formData = currentState.formValue.companiesFormValues[companyIndex].physicalAddress
  const { formValues } = formData

  const newFormValue = companyMailingAddressToState({
    currentState,
    companyIndex,
    formData: {
      formValues: {
        mailingStreet: formValues.street,
        mailingCity: formValues.city,
        mailingState: formValues.state,
        mailingCountry: formValues.country,
        mailingPostalCode: formValues.postalCode
      },
      formErrors: {}
    }
  })
  const oldCompanyDiff = currentState.companiesDiff[companyIndex]
  const newSelectedCompany = {
    ...oldCompanyDiff,
    mailingAddress: { ...newFormValue.formValue.companiesFormValues[companyIndex].mailingAddress }
  }

  const newCompaniesDiff = currentState.companiesDiff.map((companyItem, companyItemIndex) =>
    companyItemIndex === companyIndex ? newSelectedCompany : companyItem
  )

  return { ...currentState, ...newFormValue, companiesDiff: newCompaniesDiff }
}
