import { LicenseDetails } from 'commons/types/DTO/commons'
import { LicenseAddressInformation, WebPageLicenseItem } from 'commons/types/DTO/commons/webpagesOnboardinForm'

import { LicenseDetailsFormData, LicenseItemFormValue } from '../../../../types/forms'

export const emptyLicenseDetails: LicenseDetails = {
  licenseID: 0,
  entityUID: '',
  name: '',
  type: '',
  subtype: '',
  licenseNumber: '',
  POSType: '',
  phone: '',
  POSTypeOther: '',
  issueDate: '',
  expirationDate: '',
  licenseMetrcAPIKey: ''
}
// selectLicense({ application, companyIndex, licenseIndex })?
const getEmptyLicenseDetails = ({ license }: { license: LicenseDetails }): LicenseDetailsFormData => ({
  formValues: {
    licenseID: license.licenseID,
    entityUID: license.entityUID,
    name: license.name,
    type: license.type,
    subtype: license.subtype,
    licenseNumber: license.licenseNumber,
    POSType: license.POSType,
    phone: license.phone,
    POSTypeOther: license.POSTypeOther,
    issueDate: license.issueDate,
    expirationDate: license.expirationDate,
    licenseMetrcAPIKey: license.licenseMetrcAPIKey
  },
  formErrors: {}
})

export const emptyAddressInformation = {
  street: '',
  city: '',
  state: '',
  postalCode: ''
}

const getEmptyAddressInformation = ({ license }: { license: LicenseAddressInformation }) => ({
  formValues: {
    street: license.street,
    city: license.city,
    state: license.state,
    postalCode: license.postalCode
  },
  formErrors: {}
})

export const getEmptyLicense = ({ license }: { license: WebPageLicenseItem }): LicenseItemFormValue => ({
  details: getEmptyLicenseDetails({ license }),
  addressInformation: getEmptyAddressInformation({ license })
})
