import { ButtonHTMLAttributes } from 'react'

import { IconName } from '../../Icon'
import { IconButtonFace } from './IconButtonFace'

export interface IconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon: IconName
  face?: IconButtonFace

  'data-qa'?: string
}
