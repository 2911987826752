import React, { FC, memo, useMemo } from 'react'

import { AdverseMediaListWebPageResponseItem } from 'commons/types/DTO/bank/adverseMedia'
import { TableDataQa } from 'commons/types/enums'
import { DataSourceItem, Table } from 'ui/components/Table'

import { AdverseMediaListTableProps } from './AdverseMediaListTableProps'
import { useColumns } from './useColumns'

export const AdverseMediaListTable: FC<AdverseMediaListTableProps> = memo((props) => {
  const { data, isLoading, pagination } = props
  const { columns } = useColumns()

  const dataSource: DataSourceItem<AdverseMediaListWebPageResponseItem>[] = useMemo(
    () => data.items.map((item) => ({ item, rowProps: { errorHighlight: item.riskLevel === 'Unknown' } })),
    [data]
  )

  return (
    <Table<AdverseMediaListWebPageResponseItem>
      dataQa={TableDataQa.ADVERSE_MEDIA_LIST}
      columns={columns}
      dataSource={dataSource}
      loading={isLoading}
      pagination={pagination}
    />
  )
})
