import { ChoiceOption } from 'ui/types'

import { HistoryAuditListFilter } from './HistoryAuditListFilter'
import { HistoryEntity } from './HistoryEntity'

export type HistoryAuditListResponse = {
  config: HistoryAuditListFilter
  actionOptions: ChoiceOption[]
  bankUserOptions: ChoiceOption[]
  entityTypeOptions: ChoiceOption[]
  items: HistoryEntity[]
  totalCount: number
}
