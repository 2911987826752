import { useQuery } from 'react-query'

import { BsaBatch } from 'commons/types/DTO/bank/bsa'

import * as Service from '../../service'

export const useBatchItem = (id: number): { isFetching: boolean; item: BsaBatch } => {
  const { data, isFetching } = useQuery<any, Error>(
    ['batches', id],
    () => Service.Batch.getList({ _options: { filters: [{ field: 'id', type: 'eq', value: id }] } }),
    { keepPreviousData: true }
  )

  return {
    isFetching,
    item: data ? data.records[0] : null
  }
}
