import React, { useCallback } from 'react'

import { useNavigate, useRouter } from '@tanstack/react-router'
import { BankApplicationPaths } from 'commons/constants/routes'
import { ApplicationStatus } from 'commons/types/enums'
import { ButtonGroup } from 'ui/components/ButtonGroup'
import { HeaderButton, IconButton } from 'ui/components/Buttons'
import { IconName } from 'ui/components/Icon'
import { Toast } from 'ui/components/Toast'
import { useConfirmation } from 'ui/hooks'

import { useDeleteApplication } from '../../../../hooks/useDeleteApplication'
import { useGetOldApplication } from '../../../../hooks/useGetOldApplication'
import { useUpdateApplication } from '../../../../hooks/useUpdateApplication'
import { ActionsProps } from './ActionsProps'

export const Actions = ({ application }: ActionsProps) => {
  const router = useRouter()
  const navigate = useNavigate()
  const { deleteApplication } = useDeleteApplication()
  const { getOldApplication } = useGetOldApplication()
  const { updateApplication } = useUpdateApplication()

  const onDelete = () => {
    deleteApplication(application.applicationID).then(
      () => {
        Toast.success('Application was deleted')
        navigate({ to: BankApplicationPaths.LIST })
      },
      () => {
        Toast.error('Cannot delete application')
      }
    )
  }

  const onDeny = useCallback(async () => {
    // TODO: this is hot fix for tests
    // TODO: remove this after the backend creates separate endpoints
    const oldApplication = await getOldApplication(application.applicationID)

    // oldApplication.then((value) => {
    try {
      await updateApplication({ ...oldApplication.records[0], status: ApplicationStatus.DENIED_CUSTOMER })
      Toast.success('Application was denied')
      router.invalidate()
    } catch (error) {
      Toast.error('A trouble was occurred while denieding')
    }
  }, [application.applicationID, getOldApplication, router, updateApplication])

  const { Confirmation: DeleteConfirmation, open: openDeleteConfirmation } = useConfirmation({
    message: <span>{`Are you sure want to delete ${application.title} application?`}</span>,
    onConfirm: () => onDelete(),
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  const { Confirmation: DenyConfirmation, open: openDenyConfirmation } = useConfirmation({
    message: <span>Are you sure want to deny this application?</span>,
    onConfirm: () => onDeny(),
    confirmationButtonText: 'Deny',
    isConfirmationNegative: true
  })

  return (
    <>
      <DeleteConfirmation />
      <DenyConfirmation />
      <ButtonGroup margin="big">
        {application.enableDeleteApplication && (
          <IconButton face="neutral" icon={IconName.DELETE} onClick={openDeleteConfirmation}>
            Delete Application
          </IconButton>
        )}
        {application.enableDenyApplication && (
          <HeaderButton size="middle" overColoredPad={true} onClick={openDenyConfirmation}>
            Deny
          </HeaderButton>
        )}
      </ButtonGroup>
    </>
  )
}
