import { useState } from 'react'

import { UsersWebPageResponse } from '../../../types'

export const useResetPassword = () => {
  const [userToResetPassword, setUserToResetPassword] = useState<UsersWebPageResponse['users'][0] | null>(null)
  const onResetPassword = (user: UsersWebPageResponse['users'][0]) => setUserToResetPassword(user)
  const closeResetPasswordDialog = () => setUserToResetPassword(null)

  return {
    userToResetPassword,
    onResetPassword,
    closeResetPasswordDialog
  }
}
