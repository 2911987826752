import { CSSProperties, ReactNode } from 'react'

export enum ButtonGroupMargin {
  BIG = 'big',
  SMALL = 'small'
}

export interface ButtonGroupProps {
  children: ReactNode
  margin: `${ButtonGroupMargin}`
  style?: CSSProperties
}
