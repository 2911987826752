import React, { FC, memo } from 'react'

import { Form } from 'brief-form'
import { CompanySelect, FilterPanel, Select } from 'ui/components'

import { FilterProps } from './FilterProps'

import { CompanyWrapper, FormBox, TypeWrapper } from './styles'

export const Filter: FC<FilterProps> = memo((props) => {
  const {
    form: { config, set, isDirty, Field },
    expanded,
    onToggle,
    typesFilterValues,
    relationshipsFilterValues
  } = props

  return (
    <FilterPanel isDirty={isDirty} onReset={() => set({ reset: true })} expanded={!expanded} onToggle={onToggle}>
      <Form config={config}>
        <FormBox>
          <CompanyWrapper>
            <Field
              name="company_id"
              label="Relationships"
              input={CompanySelect}
              inputProps={{
                hideMyCompaniesFilter: true,
                preloadedOptions: relationshipsFilterValues,
                'options-data-qa': 'Relationships',
                'data-qa': 'Relationships Type'
              }}
            />
          </CompanyWrapper>
          <TypeWrapper>
            <Field
              name="type"
              label="Type"
              input={Select}
              inputProps={{
                data: typesFilterValues || []
              }}
            />
          </TypeWrapper>
        </FormBox>
      </Form>
    </FilterPanel>
  )
})
