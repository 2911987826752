import React, { RefObject, memo, useCallback } from 'react'

import {
  BsaCtr,
  BsaCtrPersonInfo,
  BsaCtrTransactionInfo,
  BsaCtrTransactionLocationInfo
} from 'commons/types/DTO/bank/bsa'
import SC from 'styled-components'

import { FormErrors } from '../../formErrors'
import {
  AmountAndTypeOfTransactions,
  FilingInstitutionContactInformationForm,
  GeneralInformationForm,
  PersonsInvolvedInformationList,
  TransactionLocationsInformationList
} from './components'

interface Props {
  value: BsaCtr
  errors: FormErrors
  onChange: (value: BsaCtr, errors: FormErrors) => any
  validateFunctionRefs: RefObject<any>
  markFormAsDirty: () => void
}

//region Styled

const Box = SC.div`
`

const WrappedGeneralInformationForm = SC(GeneralInformationForm)`
  margin-bottom: 50px;
`

//endregion

export const Form = memo((props: Props) => {
  const { value, errors, onChange, validateFunctionRefs, markFormAsDirty } = props

  const onGeneralInformationFormChange = useCallback(
    (v, e) => {
      onChange(v, { ...errors, general: e })
    },
    [onChange, errors]
  )

  const onFilingInstitutionContactInformationFormChange = useCallback(
    (v, e) => {
      onChange({ institution_contact_info: v }, { ...errors, contactInformation: e })
    },
    [onChange, errors]
  )

  const onTransactionLocationsInformationFormChange = useCallback(
    (v: BsaCtrTransactionLocationInfo[], e: { [key: string]: string }[]) => {
      onChange({ transaction_locations_info: v }, { ...errors, transactionLocations: e })
    },
    [onChange, errors]
  )

  const onPersonsInformationFormChange = useCallback(
    (v: BsaCtrPersonInfo[], e: { [key: string]: string }[]) => {
      onChange({ persons_info: v }, { ...errors, personsInfo: e })
    },
    [onChange, errors]
  )

  const onTransactionsInfoFormChange = useCallback(
    (v: BsaCtrTransactionInfo, e) => {
      const totalIn = [
        v.cash_in_a,
        v.cash_in_b,
        v.cash_in_c,
        v.cash_in_d,
        v.cash_in_e,
        v.cash_in_f,
        v.cash_in_g,
        v.cash_in_h,
        v.cash_in_i,
        v.cash_in_z
      ].reduce((p, c) => +(p || 0) + +(c || 0), 0)

      const totalOut = [
        v.cash_out_a,
        v.cash_out_b,
        v.cash_out_c,
        v.cash_out_d,
        v.cash_out_e,
        v.cash_out_f,
        v.cash_out_g,
        v.cash_out_h,
        v.cash_out_i,
        v.cash_out_j,
        v.cash_out_z
      ].reduce((p, c) => +(p || 0) + +(c || 0), 0)

      onChange(
        {
          transaction_info: {
            ...v,
            cash_in_total: totalIn,
            cash_out_total: totalOut
          }
        },
        { ...errors, transactionsInfo: e }
      )
    },
    [onChange]
  )

  return (
    <Box>
      <WrappedGeneralInformationForm
        value={{
          name_desc: value.name_desc,
          filing_name: value.filing_name,
          filing_type: value.filing_type,
          prior_report_bsa_id: value.prior_report_bsa_id,
          status: value.status,
          notes: value.notes
        }}
        errors={errors.general}
        onChange={onGeneralInformationFormChange}
        validateFunctionRefs={validateFunctionRefs}
        markFormAsDirty={markFormAsDirty}
      />
      <FilingInstitutionContactInformationForm
        value={value.institution_contact_info}
        errors={errors.contactInformation}
        onChange={onFilingInstitutionContactInformationFormChange}
        validateFunctionRefs={validateFunctionRefs}
        markFormAsDirty={markFormAsDirty}
      />
      <TransactionLocationsInformationList
        value={value.transaction_locations_info || []}
        errors={errors.transactionLocations}
        onChange={onTransactionLocationsInformationFormChange}
        validateFunctionRefs={validateFunctionRefs}
        markFormAsDirty={markFormAsDirty}
      />
      <PersonsInvolvedInformationList
        value={value.persons_info || []}
        errors={errors.personsInfo}
        onChange={onPersonsInformationFormChange}
        validateFunctionRefs={validateFunctionRefs}
        markFormAsDirty={markFormAsDirty}
      />
      <AmountAndTypeOfTransactions
        value={value.transaction_info}
        errors={errors.transactionsInfo}
        onChange={onTransactionsInfoFormChange}
        validateFunctionRefs={validateFunctionRefs}
        markFormAsDirty={markFormAsDirty}
      />
    </Box>
  )
})
