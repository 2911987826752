import React, { FC, memo } from 'react'

import { Icon, IconName } from '../../Icon'
import { FloatingButtonProps } from './FloatingButtonProps'

import { Box } from './styles'

/**
 * This button can't be disabled, do not set that attr
 */
export const FloatingButton: FC<FloatingButtonProps> = memo((props) => {
  return (
    <Box {...props}>
      <Icon name={IconName.UP} size={16} color="#fff" fill="#fff" />
    </Box>
  )
})
