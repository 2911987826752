import { useEffect } from 'react'

import { LEAVE_CONFIRMATION_MESSAGE } from 'commons/constants/common'

export const useUnloadPageConfirmation = (when: boolean) => {
  const unloadEventListener = (event: BeforeUnloadEvent) => {
    const dialogText = LEAVE_CONFIRMATION_MESSAGE
    event.returnValue = dialogText
    return dialogText
  }

  useEffect(() => {
    if (when) {
      document.addEventListener('beforeunload', unloadEventListener)
    }

    return () => {
      document.removeEventListener('beforeunload', unloadEventListener)
    }
  }, [when])
}
