import styled from 'styled-components'

export const Box = styled.div`
  display: flex;
  gap: 30px;
  padding: 20px 30px 32px;
`

export const Wrapper = styled.div`
  width: 160px;
`
