import React from 'react'

import { BankApplicationPaths } from 'commons/constants/routes'
import { ApplicationPipelineReportItem } from 'commons/types/DTO/bank'
import { DateTime, Icon, TableColumn, UsPhoneLabel } from 'ui/components'
import { IconName, Link } from 'ui/components/Link'
import { Tooltip } from 'ui/components/Tooltip'

import * as SC from './styles'

export const columns: TableColumn<ApplicationPipelineReportItem>[] = [
  {
    title: 'ID',
    render: ({ applicationID, applicationNotes }) => (
      <SC.IdColumnWithNotes>
        <Link to={BankApplicationPaths.DETAILS} params={{ applicationId: applicationID }}>
          ID-{applicationID}
        </Link>
        {applicationNotes && applicationNotes.length > 0 && (
          <Tooltip id={applicationID + applicationNotes} type="dark" event="hover" content={<>{applicationNotes}</>}>
            <Icon name={IconName.DIALOG} />
          </Tooltip>
        )}
      </SC.IdColumnWithNotes>
    )
  },
  {
    title: 'Company Name',
    key: 'companyName',
    align: 'left',
    width: '300px'
  },
  {
    title: 'Status',
    key: 'status',
    align: 'right'
  },
  {
    title: 'Contact Name',
    key: 'contactName',
    align: 'left'
  },
  {
    title: 'Email',
    align: 'left',
    render: ({ email }) => <SC.EmailLink href={`mailto:${email}`}>{email}</SC.EmailLink>
  },
  {
    title: 'Phone',
    align: 'left',
    render: ({ phone }) => (
      <>
        {phone && (
          <SC.Phone>
            <UsPhoneLabel value={phone || ''} />
          </SC.Phone>
        )}
      </>
    )
  },
  {
    title: 'Current Owner',
    key: 'currentOwner',
    align: 'left'
  },
  {
    title: 'Last Modified',
    align: 'left',
    render: ({ lastModified }) => <DateTime withTime date={lastModified} />
  },
  {
    title: 'Created By',
    key: 'createdBy',
    align: 'left'
  }
]
