import * as React from 'react'

import { AnnualReviewQuestion } from '../AnnualReviewQuestion'
import { AnnualReviewQuestionColumn } from '../AnnualReviewQuestion/AnnualReviewQuestionColumn'
import { AnnualReviewQuestionsSet } from '../AnnualReviewQuestion/AnnualReviewQuestionsSet'

export interface AnnualReviewQuestionListProps {
  items: string[][]
  bottomIndent?: boolean
}

export const AnnualReviewQuestionList = ({ items, bottomIndent }: AnnualReviewQuestionListProps): JSX.Element => (
  <AnnualReviewQuestionsSet>
    <AnnualReviewQuestionColumn>
      {items.map(([label, value, params]) => (
        // @ts-ignore
        <AnnualReviewQuestion bottomIndent={bottomIndent} {...params} key={label} label={label}>
          {value}
        </AnnualReviewQuestion>
      ))}
    </AnnualReviewQuestionColumn>
  </AnnualReviewQuestionsSet>
)
