import React, { FC, memo, useCallback, useContext, useEffect, useState } from 'react'

import { getRouteApi } from '@tanstack/react-router'
import { BeforeFormChangeHandler, Form, useFormData } from 'brief-form'
import { PortalName } from 'commons/types/enums'
import { LoginButton } from 'ui/components/Buttons'
import { Validators } from 'ui/components/Form/validators'
import { IconName, IconSize } from 'ui/components/Icon'
import { FormRow } from 'ui/themes/globalStyles'
import { AuthContext } from '~auth/AuthContext'
import { AuthForm, AuthInput } from '~auth/components'
import { useCheckLink } from '~auth/hooks/user'

import { SetPasswordFormProps } from './SetPasswordFormProps'
import { SetPasswordFormValue } from './SetPasswordFormValue'

import * as SC from './styles'

export const SetPasswordForm: FC<SetPasswordFormProps> = memo(({ onSubmit, logoElement, footer }) => {
  const key = getRouteApi('/auth/password-reset').useSearch({ select: (s) => s?.key })

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string>('')
  const { portalName } = useContext(AuthContext)
  const checkLink = useCheckLink(portalName)

  const onBeforeChange: BeforeFormChangeHandler<SetPasswordFormValue> = useCallback(({ value, errors }) => {
    setError('')

    return {
      value,
      errors
    }
  }, [])

  const { config, Field } = useFormData<SetPasswordFormValue>({
    onBeforeChange,
    initialValue: {
      password: '',
      password_confirm: ''
    }
  })

  const { value } = config

  useEffect(() => {
    if (portalName !== PortalName.BOP && key) {
      checkLink.mutateAsync(key)
    }
  }, [])

  const submitWrapper = useCallback(() => {
    if (value.password !== value.password_confirm) {
      setError('Passwords do not match')
    } else {
      setLoading(true)
      onSubmit(value)
        .catch((error) => {
          let message = error.message

          if (error.meta?.password?.length > 0) {
            message = `${error.message}. ${error.meta.password.join('. ')}`
          }

          setError(message)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [value, onSubmit, setError, setLoading])

  return (
    <AuthForm error={error} logo={logoElement} footer={footer}>
      <Form config={config}>
        <FormRow>
          <SC.LoginCol>
            <SC.Label>Input your new password:</SC.Label>
            <Field
              required
              name="password"
              input={AuthInput}
              validator={Validators.required.string}
              inputProps={{
                type: 'password',
                skipLocalState: true,
                placeholder: 'Password',
                icon: IconName.LOCK,
                iconSize: IconSize.M,
                iconColor: '#fff'
              }}
            />
          </SC.LoginCol>
        </FormRow>
        <FormRow>
          <SC.LoginCol>
            <Field
              required
              name="password_confirm"
              input={AuthInput}
              validator={Validators.required.string}
              inputProps={{
                skipLocalState: true,
                placeholder: 'Confirm New Password',
                icon: IconName.LOCK,
                iconSize: IconSize.M,
                iconColor: '#fff',
                type: 'password'
              }}
            />
          </SC.LoginCol>
        </FormRow>
      </Form>
      <SC.Footer>
        <LoginButton onClick={submitWrapper} loading={loading}>
          SUBMIT
        </LoginButton>
      </SC.Footer>
    </AuthForm>
  )
})
