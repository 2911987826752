import React from 'react'

import { FF, TextInput } from 'ui/components'

import { useSARFormContext } from '../../../../../../../../context'
import { useSubjectInformationItemContext } from '../../../SubjectInformationItemContext'

type AddressProps = {
  index: number
}

export const Address = ({ index }: AddressProps) => {
  const { formApi } = useSARFormContext()
  const { isAllInfoUnavailable } = useSubjectInformationItemContext()

  return (
    <formApi.Field
      name={`subjects_info[${index}].address_info[0].address` as 'subjects_info[0].address_info[0].address'}
      children={(field) => (
        <FF
          field={field}
          label="11. Address"
          input={(p) => <TextInput {...p} disabled={isAllInfoUnavailable} maxLength={100} />}
        />
      )}
    />
  )
}
