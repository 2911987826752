import { createRoute, redirect } from '@tanstack/react-router'
import { MR_PATH, QR_PATH } from 'commons/constants/routes'

import { MRPageWrapper } from './MRPageWrapper'
import { QRPageWrapper } from './QRPageWrapper'
import { MRDetailsWebPageRoute } from './routes/MRDetailsWebPage'
import { MRWebPageDepositsManageRoute } from './routes/MRWebPageDepositsManage'
import { QRDetailsRoute } from './routes/QRDetails'

export const routeMRTree = (rootRoute: BPRootRoute) => {
  const MRIndexRoute = createRoute({
    beforeLoad: async ({ context }) => {
      if (!context.accessibleRoutingFlags.monthlyReportRootAccess) {
        throw redirect({
          to: '/'
        })
      }
    },
    getParentRoute: () => rootRoute,
    path: MR_PATH,
    component: MRPageWrapper
  })

  return MRIndexRoute.addChildren([
    MRDetailsWebPageRoute<typeof MRIndexRoute>(MRIndexRoute),
    MRWebPageDepositsManageRoute<typeof MRIndexRoute>(MRIndexRoute)
  ])
}

export const routeQRTree = (rootRoute: BPRootRoute) => {
  const QRIndexRoute = createRoute({
    beforeLoad: async ({ context }) => {
      if (!context.accessibleRoutingFlags.quarterlyReportRootAccess) {
        throw redirect({
          to: '/'
        })
      }
    },
    getParentRoute: () => rootRoute,
    path: QR_PATH,
    component: QRPageWrapper
  })

  return QRIndexRoute.addChildren([QRDetailsRoute<typeof QRIndexRoute>(QRIndexRoute)])
}
