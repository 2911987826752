import { OnboardingFormActions } from '../../../enums'
import {
  FlushLastErrorCountAction,
  SetErrorsAction,
  SetGeneralErrorsAction,
  SetIsFormSubmittingAction,
  SetSubmitErrorsAction,
  SubmitErrorsPayload
} from '../../../types/actions'

export const setErrors = ({ lastErrorCount }: { lastErrorCount: number }): SetErrorsAction => ({
  type: OnboardingFormActions.SET_ERRORS,
  payload: lastErrorCount
})

export const setIsFormSubmitting = (isFormSubmitting: boolean): SetIsFormSubmittingAction => ({
  type: OnboardingFormActions.SET_IS_FORM_SUBMITTING,
  payload: isFormSubmitting
})
export const setSubmitErrors = (submitErrors: SubmitErrorsPayload): SetSubmitErrorsAction => ({
  type: OnboardingFormActions.SET_SUBMIT_ERRORS,
  payload: submitErrors
})

export const setGeneralErrors = (submitErrors: string[]): SetGeneralErrorsAction => ({
  type: OnboardingFormActions.SET_GENERAL_ERRORS,
  payload: submitErrors
})

export const flushLastErrorCount = (): FlushLastErrorCountAction => ({
  type: OnboardingFormActions.FLUSH_LAST_ERROR_COUNT
})
