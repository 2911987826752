import { useState } from 'react'

import { Toast } from 'ui/components'

import { documentReject } from '../services'

export const useReject = (documentId: number, invalidate: () => Promise<void>) => {
  const [isShowingRejectDialog, setIsShowingRejectDialog] = useState(false)
  const [isRejecting, setIsRejecting] = useState(false)

  const closeRejectDialog = () => {
    setIsShowingRejectDialog(false)
  }

  const openRejectDialog = () => {
    setIsShowingRejectDialog(true)
  }

  const reject = async (notes: string) => {
    setIsRejecting(true)
    await documentReject(documentId, notes)
      .catch((err: Error) => {
        Toast.errorSmall(err.message)
      })
      .finally(async () => {
        await invalidate()
        setIsRejecting(false)
        closeRejectDialog()
      })
  }

  return {
    isShowingRejectDialog,
    isRejecting,

    openRejectDialog,
    closeRejectDialog,
    reject
  }
}
