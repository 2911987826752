import styled from 'styled-components'
import { Icon } from 'ui/components/Icon'
import { PageSection } from 'ui/components/Page'
import { Pagination } from 'ui/components/Pagination'

export const Title = styled.div`
  font-weight: normal;
  font-size: 26px;
  margin: 19px 0;
  display: flex;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  > a {
    color: ${(p) => p.theme.uiKit.colors.global.grey.grey500};
    text-decoration: none;
    font-size: 26px;
    line-height: 26px;
  }

  > i {
    flex-shrink: 0;
  }

  > div {
    font-size: 26px;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const Arrow = styled(Icon)`
  margin: 0 20px;
  opacity: 0.3;
  position: relative;
  top: -5px;
  line-height: 26px;
  font-size: 26px;
`

export const FilterContainer = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: space-between;
  padding: 2px 0 32px;
`

export const WrappedPageSection = styled(PageSection)`
  margin-bottom: 90px;
  position: relative;
`

export const WrappedPagination = styled(Pagination)`
  margin-top: 40px;
`

export const TooltipContent = styled.div`
  width: 500px;
`

export const ToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const EmptyLabel = styled.div`
  font-size: 16px;
  text-align: center;
  padding: 50px;
  color: ${(p) => p.theme.uiKit.colors.global.grey.grey500};
`

export const Row = styled.div`
  display: flex;
  align-items: flex-end;

  > * {
    margin-right: 30px;
    width: 250px;
  }
`

export const PoweredContainer = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;

  span {
    font-size: 13px;
    color: #868686;
  }
`

export const SmartFilterInfoIcon = styled(Icon)`
  cursor: pointer;
  position: relative;

  svg {
    transition: fill 0.15s;
  }

  &:hover {
    svg {
      fill: ${(p) => p.theme.uiKit.colors.theme.brand.primary};
    }
  }
`
