import * as React from 'react'

import isEqual from 'lodash/isEqual'

type Result = [boolean]

export function useNotFound<T, U>(value: T, notFoundEquivalent: U, isPending = false): Result {
  const [isNotFound, setIsNotFound] = React.useState<boolean>(false)

  React.useEffect(() => {
    if (!isPending && isEqual(value, notFoundEquivalent)) {
      setIsNotFound(true)
    } else {
      setIsNotFound(false)
    }
  }, [value, isPending, notFoundEquivalent])

  return [isNotFound]
}
