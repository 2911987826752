import React, { FC } from 'react'

import { getLinkByEntityType } from '../../utils/getLinkByEntityType'
import AssignedEntitiesChangedLink from './AssignedEntitiesChangedLink'
import { AssignedEntitiesChangedProps } from './HistoryRecordDetails.types'
import { EntityActionLabels } from './enums'
import getAssignedUnAssignedLists from './getAssignedUnAssignedLists'

const AssignedEntitiesChanged: FC<AssignedEntitiesChangedProps> = ({ entityType, entityTypeLabel, after, before }) => {
  const { assignedList, unAssignedList } = getAssignedUnAssignedLists({ after, before })
  return (
    <>
      {assignedList.map(({ id, name }) => {
        const to = getLinkByEntityType(entityType, id)
        return (
          <AssignedEntitiesChangedLink
            key={`a-${id}`}
            action={EntityActionLabels.ASSIGNED}
            entityTypeLabel={entityTypeLabel}
            elementName={name}
            to={to}
          />
        )
      })}
      {unAssignedList.map(({ id, name }) => {
        const to = getLinkByEntityType(entityType, id)
        return (
          <AssignedEntitiesChangedLink
            key={`u-${id}`}
            action={EntityActionLabels.UNASSIGNED}
            entityTypeLabel={entityTypeLabel}
            elementName={name}
            to={to}
          />
        )
      })}
    </>
  )
}

export default AssignedEntitiesChanged
