import styled from 'styled-components'
import { CSS } from 'ui/helpers'

export const SavingText = styled.div`
  ${(p) => CSS.populateFont(p.theme.uiKit.typography.bodyL.buttonL)};
  color: ${(p) => p.theme.uiKit.colors.global.grey.grey700};
`

export const RightColumn = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const ContainerBox = styled.div`
  display: flex;
  padding: 30px 40px;
`

export const IconButtonInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`
