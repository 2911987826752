import { ApplicationRelationshipBankAccountsItem } from 'commons/types/DTO/bank/applications'

import { ApplicationBankAccountsFormDataList } from '../../../types'

const formDataExistsBankAccountsReducer = (accumulator, currentValue, index) => ({
  ...accumulator,
  [`${index}`]: {
    value: { ...currentValue, assignedLicenses: currentValue.assignedLicenses.map(({ id }) => id) },
    errors: {}
  }
})

export const getRelationshipBankAccountsFormData = ({
  relationship
}: {
  relationship: ApplicationRelationshipBankAccountsItem
}): ApplicationBankAccountsFormDataList => {
  const bankAccounts = relationship.bankAccounts
  const formDataListOfExistsBankAccounts = bankAccounts.reduce(formDataExistsBankAccountsReducer, {})
  return formDataListOfExistsBankAccounts
}
