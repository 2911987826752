import * as React from 'react'
import { useQuery, useQueryClient } from 'react-query'

import { UseFormDataReturnType } from 'brief-form'
import { Request } from 'commons/utils/request'
import { handlerServerError } from 'errors'
import isEqual from 'lodash/isEqual'

import { RemoteDataSource } from '../types'

export const useTableData = <Data extends { [key: string]: any }, Filter extends { [key: string]: any }>(
  name: string,
  dataSource: RemoteDataSource<Data, Filter>[],
  form: UseFormDataReturnType<unknown>,
  refreshInterval?: number,
  initialData?: Data,
  filter?: Filter,
  pagination?: { page: number; size: number },
  sorting?: { field: string; direction: 'ASC' | 'DESC' }[],
  httpMethod?: string
) => {
  const client = useQueryClient()
  const [data, setData] = React.useState<Data>(initialData || ({} as any))
  const [hasData, setHasData] = React.useState(false)
  const [lastIndex, setLastIndex] = React.useState<number[]>([])

  let fetching = false

  dataSource.forEach((source, index) => {
    const options = source.options ? source.options(data, filter) : {}

    /**
     * Server pagination logic can be applied only to first main datasource.
     * If we want server pagination we pass it parameter explicitly,
     * otherwise all pagination data will ve removed from request options.
     */
    if (index === 0) {
      if (pagination) {
        if (!options._options) {
          options._options = {}
        }

        // eslint-disable-next-line no-underscore-dangle
        options._options.offset = (pagination.page - 1) * pagination.size
        // eslint-disable-next-line no-underscore-dangle
        options._options.limit = pagination.size
      }
      if (sorting) {
        if (!options._options) {
          options._options = {}
        }

        // eslint-disable-next-line no-underscore-dangle
        options._options.orders = sorting
      }
    } else {
      if (options?._options) {
        // eslint-disable-next-line no-underscore-dangle
        delete options?._options?.offset
        // eslint-disable-next-line no-underscore-dangle
        delete options?._options?.limit
        // eslint-disable-next-line no-underscore-dangle
        delete options?._options.orders
      }
    }

    const result = useQuery(
      // Key.
      ['tables', name, source.key, pagination ? pagination.page : 1, options],
      // Fetch function,
      () => {
        if (httpMethod === 'GET') {
          return Request.get(source.url)
        }
        return Request.post(source.url, options)
      },
      // Options.
      {
        // Set optional re-fetch timeout.
        refetchInterval: refreshInterval || undefined,
        // Save cached data for future usage.
        keepPreviousData: true,
        // first request starts automatically, next data sources should wait for
        // finish of previous one.
        enabled: index === 0 || lastIndex[lastIndex.length - 1] === index - 1,
        onSuccess: () => {
          if (!lastIndex.filter((item) => item === index).length) {
            setLastIndex([...lastIndex, index])
          }
          if (dataSource.length === index + 1) {
            fetching = false
            setLastIndex([])
          }
        },
        onError: (error: Error) => {
          handlerServerError(error, {
            form: form?.config
          })
        }
      }
    )

    if (result.isFetching) {
      fetching = true
    }

    if (!!result.data && (!data || !isEqual(result.data, data[source.key]))) {
      setData({ ...{}, ...data, [source.key]: result.data })
      setHasData(true)
    }

    if (
      !result.data &&
      result.status === 'error' &&
      result.isLoading === false &&
      (!data || !isEqual(result.data, data[source.key]))
    ) {
      setData({} as any)
      setHasData(false)
    }

    // Delete queries with empty options (because later it will be re-created with
    // options we needed).
    client.removeQueries(['tables', name, source.key, null])
  })

  return {
    data,
    loading: !hasData && fetching,
    fetching
  }
}
