import React from 'react'

import { ctrTINTypes } from 'commons/types/dictionaries'
import { FF, Select, Validators } from 'ui/components'

import { useSARFormContext } from '../../../../../../context'
import { useSubjectInformationItemContext } from '../SubjectInformationItemContext'

type TINTypeProps = {
  index: number
}

export const TINType = ({ index }: TINTypeProps) => {
  const { formApi } = useSARFormContext()
  const { isAllInfoUnavailable } = useSubjectInformationItemContext()

  return (
    <formApi.Field
      name={`subjects_info[${index}].tin_type` as 'subjects_info[0].tin_type'}
      validators={{
        onChange: ({ value }) => (isAllInfoUnavailable ? undefined : Validators.required.field(value))
      }}
      children={(field) => (
        <FF
          required={!isAllInfoUnavailable}
          field={field}
          label="17. TIN type"
          input={(p) => (
            <Select {...p} data={ctrTINTypes} data-qa="17. TIN type" options-data-qa="17. TIN type option" />
          )}
        />
      )}
    />
  )
}
