import { useCallback } from 'react'

import { FormChangedHandler, useFormData } from 'brief-form'

import { FormPaths } from '../../enums'
import { useSyncFormData } from '../../hooks'
import { useValidateFormPersist } from '../../hooks/useValidateFormPersist'
import { RoleInformationFormData, RoleInformationFormValue, RoleInformationHandler } from '../../types/forms'

export const useRoleInformationForm = ({
  contactIndex,
  companyIndex,
  formData,
  onChange
}: {
  contactIndex: number
  companyIndex: number
  formData: RoleInformationFormData
  onChange: RoleInformationHandler
}) => {
  const onFormChanged: FormChangedHandler<RoleInformationFormValue> = useCallback(
    (formValues, formErrors) => onChange({ companyIndex, formData: { formValues, formErrors }, contactIndex }),
    [companyIndex, onChange, contactIndex]
  )

  const { config, Field, set, validate } = useFormData<RoleInformationFormValue>({
    onFormChanged,
    initialValue: formData.formValues,
    initialErrors: formData.formErrors
  })

  useSyncFormData({ set, formData })
  const formKey = `${FormPaths.ROLE_INFORMATION}-${companyIndex}-${contactIndex}`
  useValidateFormPersist<RoleInformationFormValue>({
    formName: formKey,
    validate,
    config
  })

  return { Field, config }
}
