import React, { FC, memo } from 'react'

import { Form } from 'brief-form'
import { useGetBankName } from 'commons/hooks/bank/organisation'
import { BankName } from 'commons/types/enums'
import { isAdverseMediaShow } from 'commons/utils/adverseMediaShowCondition'
import { FilterComponentProps } from 'query-table'
import { DatePicker } from 'ui/components/DatePicker'
import { BankUserSelect, Select } from 'ui/components/Select'
import { FilterPanel } from 'ui/components/Table'

import { HistoryEntityType } from '../../../../../../enums'
import { entityTypesDictionary } from '../../../../../../helpers'
import { EntityHistoryFilterValue } from '../../../../EntityHistoryFilterValue'

import * as SC from './styles'

type dataSourceType = { value: HistoryEntityType; label: string }[]

const getDataSource = (dataSource: dataSourceType, currentBank: BankName) => {
  if (currentBank === BankName.DEV) {
    return dataSource
  }

  // Internal Transfer section hidden for CBT bank

  if (currentBank === BankName.CBT) {
    return entityTypesDictionary.filter(
      (i) =>
        i.value !== HistoryEntityType.BSA_SAR &&
        i.value !== HistoryEntityType.INTERNAL_TRANSFER &&
        i.value !== HistoryEntityType.INTERNAL_TRANSFERS_EXPORT
    )
  }

  // We don't show some options if Adverse Media section is hidden

  if (!isAdverseMediaShow(currentBank)) {
    return entityTypesDictionary.filter(
      (i) =>
        i.value !== HistoryEntityType.ADVERSE_MEDIA_CASE &&
        i.value !== HistoryEntityType.ADVERSE_MEDIA_PUBLICATION &&
        i.value !== HistoryEntityType.ADVERSE_INFORMATION_HIT &&
        i.value !== HistoryEntityType.ADVERSE_INFORMATION_SEARCH
    )
  }

  return entityTypesDictionary
}

export const Filter: FC<FilterComponentProps<EntityHistoryFilterValue>> = memo((properties) => {
  const {
    form: { config, isDirty, set, Field },
    expanded,
    onToggle
  } = properties
  const bank = useGetBankName()
  const dataSource = getDataSource(entityTypesDictionary, bank.id)

  return (
    <FilterPanel isDirty={isDirty} onReset={() => set({ reset: true })} expanded={expanded} onToggle={onToggle}>
      <Form config={config}>
        <SC.Columns>
          <SC.FixedColumn>
            <Field
              name="entity_type"
              label="Entity Type"
              input={Select}
              inputProps={{
                data: [{ value: '', label: 'All' }, ...dataSource],
                'options-data-qa': 'Entity',
                'data-qa': 'Entity Type'
              }}
            />
          </SC.FixedColumn>
          <SC.FixedColumn>
            <Field
              name="action_type"
              label="Action Type"
              input={Select}
              inputProps={{
                data: [
                  { value: '', label: 'All' },
                  { value: '1', label: 'Create' },
                  { value: '2', label: 'Update' },
                  { value: '3', label: 'Delete' }
                ]
              }}
            />
          </SC.FixedColumn>
          <SC.Column>
            <Field
              name="date_from"
              label="Date From"
              input={DatePicker}
              inputProps={{
                showTimeSelect: true
              }}
            />
          </SC.Column>
          <SC.Column>
            <Field
              name="date_to"
              label="Date To"
              input={DatePicker}
              inputProps={{
                showTimeSelect: true
              }}
            />
          </SC.Column>
          <SC.FixedColumn>
            <Field
              name="user_id"
              label="Bank User"
              input={BankUserSelect}
              inputProps={{
                defaultOption: { label: 'All', value: '' }
              }}
            />
          </SC.FixedColumn>
        </SC.Columns>
      </Form>
    </FilterPanel>
  )
})
