import React, { FC, memo } from 'react'

import { MenuItemProps } from './MenuItemProps'

import * as SC from './styles'

export const MenuItem: FC<MenuItemProps> = memo((props) => {
  const {
    isActive = false,
    isMinimized = false,
    isDisabled = false,
    iconName,
    to,
    hash,
    onClick,
    children,
    ...rest
  } = props

  return (
    <>
      {isDisabled && (
        <SC.MenuItemDisabled>
          <SC.MenuItemInner $minimized={isMinimized}>{children}</SC.MenuItemInner>
        </SC.MenuItemDisabled>
      )}
      {!isDisabled && (
        <SC.MenuItem
          {...rest}
          $active={isActive}
          noUnderline
          to={to}
          hash={hash}
          active={isActive}
          iconName={iconName}
          onClick={onClick}
        >
          <SC.MenuItemInner $minimized={isMinimized}>{children}</SC.MenuItemInner>
        </SC.MenuItem>
      )}
    </>
  )
})
