import { useCallback } from 'react'
import { useMutation } from 'react-query'

import { deleteDocument as deleteDocumentService } from '../service'

type UseDeleteDocumentReturnValue = {
  deleteDocument: (appId: number, documentId: number) => Promise<number>
}

type Document = {
  appId: number
  id: number
}

export const useDeleteDocument = (): UseDeleteDocumentReturnValue => {
  const mutation = useMutation((document: Document) => deleteDocumentService(document.appId, document.id))

  const deleteDocument = useCallback(
    (appId: number, documentId: number) => mutation.mutateAsync({ appId: appId, id: documentId }),
    [mutation]
  )

  return {
    deleteDocument
  }
}
