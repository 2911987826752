import styled from 'styled-components'
import { Hr } from 'ui/components'

export const StyledHr = styled(Hr)`
  margin: 0;
  height: 1px;
`

export const Box = styled.div`
  padding: 20px 36px 20px;
`

export const BoxSection = styled.div`
  padding: 20px 36px 28px;
`
