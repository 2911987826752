import { createRoute, redirect } from '@tanstack/react-router'
import { BANK_INVOICES_PATH } from 'commons/constants/routes'

import { BankInvoicesWrapper } from './BankInvoicesWrapper'
import { CreateRoute } from './routes/CreateInvoice'
import { EditRoute } from './routes/EditInvoice'
import { DetailsRoute } from './routes/InvoiceDetails'

export const routeInvoicesTree = (rootRoute: BPRootRoute) => {
  const InvoicesIndexRoute = createRoute({
    beforeLoad: async ({ context }) => {
      if (!context.accessibleRoutingFlags.bankInvoiceRootAccess) {
        throw redirect({
          to: '/'
        })
      }
    },
    getParentRoute: () => rootRoute,
    path: BANK_INVOICES_PATH,
    component: BankInvoicesWrapper
  })

  return InvoicesIndexRoute.addChildren([
    DetailsRoute<typeof InvoicesIndexRoute>(InvoicesIndexRoute),
    CreateRoute<typeof InvoicesIndexRoute>(InvoicesIndexRoute),
    EditRoute<typeof InvoicesIndexRoute>(InvoicesIndexRoute)
  ])
}
