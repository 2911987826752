import React, { memo, useState } from 'react'

import * as SC from './styles/SecuredInputStyles'
import { IconName, IconSize } from 'ui/components/Icon'

import { BaseMaskedInputProps } from './props'
import { InputIconPosition } from './types'

export const SecuredInput = memo((props: BaseMaskedInputProps) => {
  const [secureOn, setSecureOn] = useState(true)

  return (
    <SC.BaseInputStyled
      {...props}
      renderIcon={
        <SC.IconWrapper
          name={secureOn ? IconName.EYE : IconName.EYE_CROSSED}
          size={IconSize.S}
          onClick={() => setSecureOn(!secureOn)}
        />
      }
      iconPosition={InputIconPosition.RIGHT_FAR}
      $secureOn={secureOn}
      type={secureOn && props.type ? props.type : 'text'}
      autoComplete={props.autoComplete || 'on'}
    />
  )
})
