const customerStatusMap: { [key: string]: string } = {
  qualified_lead: 'Qualified Lead',
  applicant: 'Applicant',
  pending_customer: 'Pending Customer',
  customer: 'Customer',
  denied_customer: 'Denied Customer',
  former_customer: 'Former Customer',
  watch_list: 'Watch List'
}

export const getCustomerStatusLabel = (status: string) =>
  customerStatusMap[status] ? customerStatusMap[status] : status || '---'
