import React, { FC, memo, useEffect, useMemo, useState } from 'react'

import { getRouteApi } from '@tanstack/react-router'
import { useBankPortalContext } from 'bank-portal-context'
import { useGetMRWebPage } from 'commons/hooks/bank/mr'
import { useBankSourceDelete, useSalesDataClientSourceCancellation } from 'commons/hooks/bank/salesData'
import { MRWebPagePayload } from 'commons/types/DTO/bank/reportWebPage'
import { ReportMonthlyWebPage } from 'commons/types/DTO/bank/reportWebPage/ReportMonthlyWebPage'
import { TableDataQa } from 'commons/types/enums'
import { handlerServerError } from 'errors'
import { TablePagination, Toast } from 'ui/components'
import { Page, PageFace } from 'ui/components/Page'
import { Spinner, SpinnerSize } from 'ui/components/Spinner'
import { useConfirmation } from 'ui/hooks'
import { MR } from '~bank-quarterly-report/hooks'

import { HistorySection } from '../../components/HistorySection'
import {
  BankAccountData,
  BreadCrubms,
  Footer,
  GeneralInformation,
  SendTaskDialog,
  UploadedFilesTable,
  Warnings
} from './components'
import { MRContext } from './context'

const routeApi = getRouteApi('/mr/$id')

const MRDetailsWebPage: FC = memo(() => {
  const { id: MRId } = routeApi.useParams()

  const [webPageData, setWebPageData] = useState<ReportMonthlyWebPage | undefined>(undefined)
  const [bankSourceToDelete, setBankSourceToDelete] = useState(undefined)
  const [licenseIdToSendTask, setLicenseIdToSendTask] = useState(undefined)
  const [licenseIdToCancelTask, setLicenseIdToCancelTask] = useState(undefined)
  const [showSendTaskDialog, setShowSendTaskDialog] = useState(false)
  const { setCustomSidebarItems } = useBankPortalContext()

  const [filter, setFilter] = useState<MRWebPagePayload>({
    monthlyAnalyticsID: +MRId,
    uploadedFilesLimit: 0,
    uploadedFilesOffset: 0,
    uploadedFilesSortBy: '',
    uploadedFilesSortDesc: true,
    historyLimit: 10,
    historyOffset: 0
  })

  const { data, isFetched, isFetching, invalidate } = useGetMRWebPage(filter)

  useEffect(() => {
    if (isFetched) {
      setWebPageData(data)
    }
  }, [isFetched, data])

  const { routine: saveNotes } = MR.useSaveNotes({
    onSuccess: async () => {
      invalidate()
      Toast.successSmall('Notes have been saved.')
    },
    onError: (error) => handlerServerError(error)
  })

  const { routine: reviewMR } = MR.useReview({
    onSuccess: async () => {
      invalidate()
      Toast.successSmall('Report has been saved.')
    },
    onError: (error) => handlerServerError(error)
  })

  const { routine: resolveWarnings } = MR.useAcknowledgeWarnings({
    onSuccess: async () => {
      invalidate()
      Toast.successSmall('All warnings have been resolved.')
    },
    onError: (error) => handlerServerError(error)
  })

  const { routine: recalculateMR } = MR.useRecalculateNew({
    onSuccess: async () => {
      invalidate()
      Toast.successSmall('Report has been saved.')
    },
    onError: (error) => handlerServerError(error)
  })

  const { routine: deleteBankSource } = useBankSourceDelete({
    onSuccess: async () => {
      invalidate()
      Toast.successSmall('Banker source has been deleted.')
    },
    onError: (error) => handlerServerError(error)
  })

  const { routine: cancelClientSourceTask, isLoading: isClientSourceTaskCancelling } =
    useSalesDataClientSourceCancellation({
      onSuccess: async () => {
        invalidate()
      },
      onError: (error) => handlerServerError(error)
    })

  const { open: openReview, Confirmation: ReviewConfirmation } = useConfirmation({
    message: `Are you sure want to review this report?`,
    onConfirm: () => reviewMR(webPageData.monthlyAnalyticsID),
    confirmationButtonText: 'Review',
    isConfirmationNegative: false
  })

  const { open: openDeleteBankSourceConfirmation, Confirmation: DeleteBankSourceConfirmation } = useConfirmation({
    dialogId: 'mr-delete-bank-source-confirmation',
    message: `Are you sure you want to proceed? Report will be recalculated.`,
    onConfirm: () => deleteBankSource(bankSourceToDelete),
    confirmationButtonText: 'Confirm',
    isConfirmationNegative: true
  })

  const { open: openWarnings, Confirmation: WarningsConfirmation } = useConfirmation({
    dialogId: 'mr-warnings-confirmation',
    message: `Are you sure want to acknowledge all warnings?`,
    onConfirm: () => resolveWarnings(webPageData.monthlyAnalyticsID),
    confirmationButtonText: 'Confirm',
    isConfirmationNegative: false
  })

  const { open: openRecalculate, Confirmation: RecalculateConfirmation } = useConfirmation({
    message: `Are you sure you want to recalculate the report? It may change report status.`,
    onConfirm: () => recalculateMR(webPageData.monthlyAnalyticsID),
    confirmationButtonText: 'Recalculate',
    isConfirmationNegative: false
  })

  const historyPagination: TablePagination = useMemo(
    () => ({
      total: webPageData?.historyTotalCount,
      page: filter.historyOffset / filter.historyLimit + 1,
      pageSize: filter.historyLimit,
      onChange: (page: number, size: number) =>
        setFilter((filter) => ({ ...filter, historyOffset: (page - 1) * size, historyLimit: size }))
    }),
    [filter, webPageData]
  )

  const { open: openCancelTask, Confirmation: CancelTaskConfirmation } = useConfirmation({
    message: `Are you sure you want to Cancel Task?`,
    onConfirm: () =>
      cancelClientSourceTask({
        licenseId: licenseIdToCancelTask,
        monthlyAnalyticsId: webPageData.monthlyAnalyticsID
      }),
    confirmationButtonText: 'Cancel Task',
    isConfirmationNegative: true
  })

  useEffect(() => {
    if (webPageData) {
      setCustomSidebarItems(
        [].concat(
          { label: 'General Information', hash: 'general' },
          webPageData.bankAccounts.map((_, index) => ({
            label: `Bank Account ${index + 1}`,
            hash: `bank-account-${index}`
          })),
          { label: 'Uploaded Files', hash: 'uploaded_files' },
          { label: 'History', hash: 'history' }
        )
      )
    }
    return () => {
      setCustomSidebarItems([])
    }
  }, [webPageData])

  if (!webPageData) {
    return <Spinner centered size={SpinnerSize.M} />
  }

  return (
    <MRContext.Provider
      value={{
        deleteBankSource: (bankSourceId: number) => {
          setBankSourceToDelete(bankSourceId)
          openDeleteBankSourceConfirmation()
        },
        cancelTask: (licenseId: number) => {
          setLicenseIdToCancelTask(licenseId)
          openCancelTask()
        },
        sendTask: (licenseId: number) => {
          setLicenseIdToSendTask(licenseId)
          setShowSendTaskDialog(true)
        },
        isSourcesReloading: isClientSourceTaskCancelling || isFetching
      }}
    >
      <Page
        face={PageFace.SECONDARY}
        title={webPageData.titleOverline}
        subTitle={webPageData.title}
        isPending={false}
        footer={
          <Footer
            onReview={webPageData.enableReview ? openReview : undefined}
            onResolveWarnings={webPageData.enableAcknowledgeAllWarnings ? openWarnings : undefined}
            onReCalculate={webPageData.enableRecalculate ? openRecalculate : undefined}
          />
        }
        breadCrumbs={
          <BreadCrubms
            monthlyAnalyticsName={webPageData.monthlyAnalyticsName}
            quarterlyAnalyticsID={webPageData.quarterlyAnalyticsID}
            quarterlyAnalyticsName={webPageData.quarterlyAnalyticsName}
          />
        }
      >
        {showSendTaskDialog && (
          <SendTaskDialog
            licenseId={licenseIdToSendTask}
            monthlyAnalyticsId={+MRId}
            onClose={() => setShowSendTaskDialog(false)}
            invalidate={invalidate}
          />
        )}
        <ReviewConfirmation />
        <WarningsConfirmation />
        <RecalculateConfirmation />
        <CancelTaskConfirmation />
        <DeleteBankSourceConfirmation />
        {webPageData.infoWarnings !== undefined && <Warnings warnings={webPageData.infoWarnings} />}
        <GeneralInformation webPage={webPageData} onSaveNotes={saveNotes} />
        <BankAccountData webPage={webPageData} />
        <UploadedFilesTable webPage={webPageData} invalidateMR={invalidate} />
        <HistorySection
          tableDataQa={TableDataQa.BP_MONTHLY_ANALYTICS_HISTORY}
          loading={isFetching}
          historyItems={webPageData.history}
          historyPagination={historyPagination}
        />
      </Page>
    </MRContext.Provider>
  )
})

export default MRDetailsWebPage
