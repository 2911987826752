import styled from 'styled-components'

export const NumBadge = styled.span`
  display: inline-flex;
  align-items: center;
  background-color: #fa5252;
  color: #fff;
  border-radius: 11px;
  padding: 5px 8px;
  font-size: 14px;
  line-height: 13px;
  text-align: center;
  justify-content: center;
`
