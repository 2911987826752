import styled from 'styled-components'
import { CheckBox } from 'ui/components/CheckBox'
import { Icon } from 'ui/components/Icon'

export const PublicationWrapper = styled.li`
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px 30px 30px;
  margin-bottom: 28px;
  position: relative;
  border-radius: 8px;

  svg {
    filter: drop-shadow(0px 4px 12px rgb(0 0 0 / 10%));
  }

  &:before {
    content: '';
    position: absolute;
    width: 13px;
    height: 74px;
    top: 50%;
    left: 13px;
    transform: translate(-100%, -50%);
    z-index: 1;
    background: #fff;
  }
`

export const PublicationRewiewedWrapper = styled.li`
  padding: 20px 30px 30px;
  background: #f5f5f5;
  margin-bottom: 28px;
`

export const Header = styled.header`
  display: grid;
  grid-template-columns: 30px 1fr max-content max-content;
  grid-template-rows: 1fr;
  align-items: center;
  margin-bottom: 6px;
`

export const WrappedCheckBox = styled(CheckBox)`
  position: absolute;
  left: -17px;
  top: 50%;
  z-index: 10;
  transform: translateY(-50%);
`

export const WrappedIcon = styled(Icon)`
  margin-right: 10px;
  height: 24px;
`

export const DateTimeWrapper = styled.span`
  font-weight: 700;
  color: #868686;
  margin-right: 20px;
  grid-column: 1 / 3;
`
export const PublicationPreview = styled.p`
  margin-top: 8px;
`

export const WrappedCheckBoxContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-100%, -50%);
  height: 74px;
`
export const TitleLink = styled.h4`
  grid-column: 1 / 5;
  display: flex;
  align-items: center;
  color: ${(p) => p.theme.uiKit.colors.global.general.secondary};
  cursor: pointer;
`

export const Title = styled.h4.attrs(() => ({ role: 'button' }))`
  grid-column: 1 / 5;
  color: ${(p) => p.theme.uiKit.colors.global.general.secondary};
  cursor: pointer;
`
