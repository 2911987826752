import React from 'react'

import { DateTime } from 'ui/components'

import { Name } from '../Name'

export const columns = (readonly: boolean, onDelete: (id: number) => void) => [
  {
    title: 'File Name',
    align: 'left',
    render: (record) => <Name id={record.id} name={record.name} readonly={readonly} onDelete={onDelete} />
  },
  {
    title: 'Upload date',
    align: 'right',
    render: (record) => <DateTime date={record.createdAt} withTime />
  }
]
