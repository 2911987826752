import { useCallback, useState } from 'react'
import { useStateManager } from 'react-query-state-manager'

import { BeforeFormChangeHandler, useFormData } from 'brief-form'
import { useGetClientUserList } from 'commons/hooks/bank/clientUser'
import { Keys } from 'state-manager'

import { FilterFormValue } from '../types'

export const FORM_INITIAL_VALUE: FilterFormValue = {
  searchString: '',
  page: 1,
  pageSize: 10,
  myCompaniesOnly: false,
  companyID: 'all'
}

export const useClientUsers = () => {
  const sm = useStateManager()
  const [filter, setFilter] = useState<FilterFormValue>(FORM_INITIAL_VALUE)

  const { data, isFetching } = useGetClientUserList({
    ...filter,
    myCompaniesOnly: filter.companyID === 'my',
    companyID: typeof filter.companyID === 'string' ? null : filter.companyID
  })

  const onBeforeChange: BeforeFormChangeHandler<FilterFormValue> = useCallback(({ value, oldValue }) => {
    const isFilterChanged = value.searchString !== oldValue.searchString || value.companyID !== oldValue.companyID

    const pages = isFilterChanged ? { page: 1, pageSize: 10 } : { page: value.page, pageSize: value.pageSize }

    const newValue = { ...value, ...pages }

    setFilter({
      ...newValue
    })

    return {
      value: newValue,
      errors: {}
    }
  }, [])

  const filterForm = useFormData<FilterFormValue>({
    initialValue: FORM_INITIAL_VALUE,
    onBeforeChange
  })

  const clearHandler = useCallback(() => {
    setFilter(FORM_INITIAL_VALUE)
    filterForm.set({ reset: true })
  }, [])

  const reloadClientUserList = () => sm.invalidate(Keys.ClientUser.List())

  return {
    filterForm,
    isFetching,
    users: data,
    reloadClientUserList,
    clearHandler
  }
}
