export class MRWebPagePayload {
  monthlyAnalyticsID = 0
  uploadedFilesLimit = 0
  uploadedFilesOffset = 0
  uploadedFilesSortBy = ''
  uploadedFilesSortDesc = true
  historyOffset? = 0
  historyLimit? = 10

  constructor(monthlyAnalyticsID?: number) {
    this.monthlyAnalyticsID = monthlyAnalyticsID || 0
  }
}
