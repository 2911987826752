import { useCallback } from 'react'
import { useMutation } from 'react-query'

import { Application } from 'commons/types/DTO/commons'

import { updateApplication as updateApplicationService } from '../service'

type UseUpdateApplicationReturnValue = {
  updateApplication: (application: Application) => Promise<Application>
  isUpdateApplicationLoading: boolean
}

export const useUpdateApplication = (): UseUpdateApplicationReturnValue => {
  const mutation = useMutation((application: Application) => updateApplicationService(application))

  const updateApplication = useCallback((application: Application) => mutation.mutateAsync(application), [mutation])

  return {
    updateApplication,
    isUpdateApplicationLoading: mutation.isLoading
  }
}
