import * as React from 'react'

import { Chip, ChipFace } from '../../Chip'
import { getAnnualReviewStatusLabel } from './getAnnualReviewStatusLabel'

export interface AnnualReviewStatusLabelProps {
  name: string
  created: number
  updated: number
}

const stateMap: { [key: string]: ChipFace } = {
  complete: ChipFace.DEFAULT,
  completed: ChipFace.DEFAULT,
  approved: ChipFace.DEFAULT,
  incomplete: ChipFace.DANGER,
  new: ChipFace.PRIMARY
}

const mapStatus = (created: number, updated: number, name: string) => {
  if (name === 'new' && created !== updated) {
    return 'incomplete'
  }

  return name
}

export const AnnualReviewStatusLabel = ({ name, created, updated, ...rest }: AnnualReviewStatusLabelProps) => (
  <Chip face={stateMap[mapStatus(created, updated, name)]} {...rest}>
    {getAnnualReviewStatusLabel(mapStatus(created, updated, name))}
  </Chip>
)
