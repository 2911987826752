import React from 'react'

import { sarSubjectIdentificationTypes } from 'commons/types/dictionaries'
import { FF, Select } from 'ui/components'

import { useSARFormContext } from '../../../../../../../../context'
import { useSubjectInformationItemContext } from '../../../SubjectInformationItemContext'

const EMPTY_OPTION = { label: 'Not selected', value: '' }

type IdFormProps = {
  index: number
}

export const IdForm = ({ index }: IdFormProps) => {
  const { formApi } = useSARFormContext()
  const { isAllInfoUnavailable } = useSubjectInformationItemContext()

  return (
    <formApi.Field
      name={`subjects_info[${index}].id_info[0].id_form` as 'subjects_info[0].id_info[0].id_form'}
      children={(field) => (
        <FF
          field={field}
          label="Type"
          input={(p) => (
            <Select
              {...p}
              data={sarSubjectIdentificationTypes}
              emptyOption={EMPTY_OPTION}
              disabled={isAllInfoUnavailable}
            />
          )}
        ></FF>
      )}
    />
  )
}
