import React, { FC } from 'react'

import { Icon, IconName, IconSize } from 'ui/components'

import * as SC from './styles'

// eslint-disable-next-line import/no-unused-modules
export const LabelBadge: FC<{
  text: JSX.Element | string
  disabled?: boolean
  icon?: { enabled: IconName; disabled: IconName }
}> = (props) => {
  const { text, disabled = false } = props
  const iconEnabled = props.icon?.enabled || IconName.CHECK_GREEN
  const iconDisabled = props.icon?.disabled || IconName.CHECK_GREY
  return (
    <SC.Box>
      <Icon name={disabled ? iconDisabled : iconEnabled} size={IconSize.S} /> {text}
    </SC.Box>
  )
}

export default LabelBadge
