import React from 'react'

import { filingInstitutionIDTypes } from 'commons/types/dictionaries'
import { FF, Select } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

const EMPTY_OPTION = { label: 'Not selected', value: '' }

export const FinancialInstitution = ({ index }: { index: number }) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={`institution_activities_info[${index}].id_type` as 'institution_activities_info[0].id_type'}
      children={(field) => (
        <FF
          field={field}
          label="55. Financial institution"
          input={(p) => (
            <Select
              {...p}
              data={filingInstitutionIDTypes}
              emptyOption={EMPTY_OPTION}
              data-qa="52. Primary federal regulator"
              options-data-qa="52. Primary federal regulator option"
            />
          )}
        />
      )}
    />
  )
}
