import React, { FC, memo } from 'react'

import { ButtonGroup } from 'ui/components/ButtonGroup'
import { Button } from 'ui/components/Buttons'
import { IconName } from 'ui/components/Icon'
import { Link } from 'ui/components/Link'

import { FooterProps } from './FooterProps'

import { Box, DeleteButton } from './styles'

export const Footer: FC<FooterProps> = memo((props) => {
  const { onDelete, onSubmit, returnUrl, isSubmitting } = props

  return (
    <Box>
      {!!onDelete && (
        <DeleteButton icon={IconName.CAN} face="negative" onClick={onDelete} data-qa="Delete">
          Delete
        </DeleteButton>
      )}
      <ButtonGroup margin="small">
        <Link to={returnUrl}>
          <Button face="neutral">Cancel</Button>
        </Link>
        <Button loading={isSubmitting} onClick={onSubmit} face="positive" data-qa="Submit">
          Submit
        </Button>
      </ButtonGroup>
    </Box>
  )
})
