import React, { CSSProperties, FC, memo } from 'react'

import { FormInputProps } from 'brief-form'
import { Request } from 'commons/utils/request'

import { MultiSelectAutoSuggest } from './MultiSelectAutoSuggest'

export interface CompanyMultiSelectProps
  extends FormInputProps<
    number[],
    {
      filters?: {
        field: string
        type: string
        value: string | number | boolean
      }[]
      containerStyle?: CSSProperties
      // PortalName.BP, PortalName.CP
      portal?: 'bank' | 'client'
      opts?: any
      'data-qa'?: string
      'data-qa-input'?: string
    }
  > {}

export const CompanyMultiSelect: FC<CompanyMultiSelectProps> = memo((props) => {
  const {
    value,
    onChange,
    filters,
    portal = 'bank',
    opts,
    ...rest
  } = {
    ...props
  }

  const dataQA = rest['data-qa'] || (opts ? opts['data-qa'] : undefined)
  const dataQAInput = rest['data-qa-input'] || (opts ? opts['data-qa-input'] : undefined)
  const mergedPortal = opts?.portal ? opts.portal : portal
  const mergedFilters = opts?.filters ? opts.filters : filters

  return (
    <MultiSelectAutoSuggest
      {...rest}
      value={value}
      onChange={onChange}
      valueTitle="Add Company"
      serverOptions={{
        filters: mergedFilters,
        url: mergedPortal === 'bank' ? Request.urls.bank.companyList : Request.urls.client.companyList,
        limit: 10,
        mapper: (item) => ({ label: item.name, value: item.id }),
        searchFields: ['name'],
        method: 'post'
      }}
      data-qa={dataQA}
      data-qa-input={dataQAInput}
    />
  )
})
