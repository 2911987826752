import React from 'react'

import { FF, TextInput } from 'ui/components'

import { useSARFormContext } from '../../../../../../../../context'

type RelationshipStatusProps = {
  index: number
}

export const RelationshipStatus = ({ index }: RelationshipStatusProps) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={
        `subjects_info[${index}].relationships_info[0].relationship_status` as 'subjects_info[0].relationships_info[0].relationship_status'
      }
      children={(field) => <FF field={field} label="25. Status of relationship" input={(p) => <TextInput {...p} />} />}
    />
  )
}
