export const required = {
  field: (v: unknown) => (v ? undefined : 'Field is required'),
  fieldWithCustomMessage: (customMessage: string) => (v: unknown) => v ? undefined : customMessage,
  string: (v: string | number | null) => (v || v === 0 ? undefined : 'Field is required'),
  stringWithPossibleZeroLength: (v: string | undefined) => (typeof v === 'string' ? undefined : 'Field is required'),
  number: (message?: string) => (v: number) => v || v === 0 ? undefined : message || 'Field is required',
  files: (v: File[]) => (v.length ? undefined : 'At least one file should be chosen'),
  notNull: (v: unknown) => (v !== null ? undefined : 'Field is required'),
  notEmpty: (v: unknown) => (v !== '' ? undefined : 'Field is required'),
  array: (message?: string) => (v?: unknown[]) => v?.length ? undefined : message || 'Field is required'
}
