import { ButtonHTMLAttributes } from 'react'

import { HeaderButtonSize } from './HeaderButtonSize'

export interface HeaderButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: HeaderButtonSize
  /* set true, if buttons used over something filled with brand color */
  overColoredPad?: boolean

  'data-qa'?: string
}
