import styled from 'styled-components'

const FormBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  padding: 28px 32px 48px 32px;
  gap: 20px 80px;

  & > * {
    width: 320px;
  }
`

export const s = { FormBox }
