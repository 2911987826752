import React, { FC, memo } from 'react'

import { Search } from 'ui/components/Search'

import { ActionsProps } from './ActionsProps'

export const Actions: FC<ActionsProps> = memo((props) => {
  const { onSearchTermChange, searchTerm } = props

  return <Search onSearch={onSearchTermChange} value={searchTerm} placeholder="Search" />
})
