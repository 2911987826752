import { ReactNode } from 'react'

export enum TableColumnAlign {
  CENTER = 'center',
  LEFT = 'left',
  RIGHT = 'right'
}

export interface TableColumn<T extends { [key: string]: any }> {
  title: React.ReactNode
  key?: keyof T
  align?: TableColumnAlign | 'center' | 'left' | 'right'
  sortable?: boolean
  render?: (record: T) => ReactNode
  width?: string
  color?: string
  dataQa?: (value: string) => string
}
