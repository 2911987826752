import { AnyRoute, createRoute } from '@tanstack/react-router'

import { ListRoute } from './List'

export const CTRBatchesRoute = <T extends AnyRoute>(indexRoute: T) => {
  const route = createRoute({
    getParentRoute: () => indexRoute,
    path: `ctr-batch-list`
  })

  return route.addChildren([ListRoute(route)])
}
