// types packages/commons/types/DTO/bank/license/licensesSics.ts are dublicated this
export type LicenseDeprecated = {
  active: boolean
  city: string
  city_tax: number
  company_id: number
  county_tax: number
  created_at: string
  deleted_at: string
  expiration_date: string
  id: number
  internal_transfers: boolean
  issue_date: string
  license_number: string
  mj_retail_tax: number
  name: string
  phone: string
  pos_type: string
  pos_type_other: string
  postal_code: string
  sf_external_id: string
  special_tax: number
  state: string
  state_tax: number
  street_address: string
  subtype: string
  type: string
  updated_at: string
}
