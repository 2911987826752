import styled, { css } from 'styled-components'
import { CSS } from 'ui/helpers'

export const Inner = styled.span`
  ${(p) => CSS.populateFont(p.theme.uiKit.typography.bodyM.button)}
  color: inherit;
  display: block;
`

const Box = styled.button`
  ${({
    theme: {
      uiKit: { size, space, colors, borderWidth, borderRadius }
    }
  }) => css`
    cursor: pointer;

    height: ${size.buttonsHeight.small};
    padding-left: ${space.padding.button.small};
    padding-right: ${space.padding.button.small};

    outline: solid ${colors.global.general.transparent} ${borderWidth.strokeM};

    border-radius: ${borderRadius.default};

    transition: background 0.15s, color 0.15s, outline 0.15s, box-shadow 0.15s, border-color 0.15s;

    svg {
      transition: fill 0.15s, color 0.15s;
    }

    &:hover,
    &:focus-visible,
    &:active {
      border-color: ${colors.global.general.transparent};
      color: ${colors.global.general.main};
    }

    &:focus-visible {
      outline-color: ${colors.global.grey.grey500};
    }

    &:hover,
    &:focus-visible {
      box-shadow: ${(p) => CSS.populateBoxShadow(p.theme.uiKit.boxShadows.offset.offset100Y4)};
    }

    &:active {
      box-shadow: none;
    }

    &:disabled {
      border-color: ${colors.global.general.transparent} !important;
      background-color: ${colors.global.grey.grey300} !important;
      color: ${colors.global.grey.grey500} !important;
      cursor: not-allowed;
      box-shadow: none !important;
    }
  `}
`

export const PositiveSmall = styled(Box)`
  ${({
    theme: {
      uiKit: { colors, borderWidth }
    }
  }) => css`
    border: solid ${colors.theme.positive.main} ${borderWidth.strokeM};
    background-color: ${colors.global.general.transparent};
    color: ${colors.theme.positive.main};

    &:hover,
    &:focus-visible {
      background-color: ${colors.theme.positive.main};
    }

    &:active {
      background-color: ${colors.theme.positive.darker};
    }
  `}
`

export const NegativeSmall = styled(Box)`
  ${({
    theme: {
      uiKit: { colors, borderWidth }
    }
  }) => css`
    border: solid ${colors.theme.negative.main} ${borderWidth.strokeM};
    background-color: ${colors.global.general.transparent};
    color: ${colors.theme.negative.main};

    &:hover,
    &:focus-visible {
      background-color: ${colors.theme.negative.main};
    }

    &:active {
      background-color: ${colors.theme.negative.darker};
    }
  `}
`

export const NegativeMiddle = styled(Box)`
  ${({
    theme: {
      uiKit: { size, space, colors, borderWidth }
    }
  }) => css`
    height: ${size.buttonsHeight.middle};
    padding-left: ${space.padding.button.middle};
    padding-right: ${space.padding.button.middle};

    border: solid ${colors.theme.negative.main} ${borderWidth.strokeM};
    background-color: ${colors.global.general.transparent};
    color: ${colors.theme.negative.main};

    &:hover,
    &:focus-visible {
      background-color: ${colors.theme.negative.main};
    }

    &:active {
      background-color: ${colors.theme.negative.darker};
    }
  `}
`

export const PositiveMiddle = styled(Box)`
  ${({
    theme: {
      uiKit: { size, space, colors, borderWidth }
    }
  }) => css`
    height: ${size.buttonsHeight.middle};
    padding-left: ${space.padding.button.middle};
    padding-right: ${space.padding.button.middle};

    border: solid ${colors.theme.positive.main} ${borderWidth.strokeM};
    background-color: ${colors.global.general.transparent};
    color: ${colors.theme.positive.main};

    &:hover,
    &:focus-visible {
      background-color: ${colors.theme.positive.main};
    }

    &:active {
      background-color: ${colors.theme.positive.darker};
    }
  `}
`

export const Brand = styled(Box)<{ $icon?: boolean }>`
  ${({
    $icon,
    theme: {
      uiKit: { size, space, colors, borderWidth }
    }
  }) => css`
    height: ${size.buttonsHeight.middle};

    padding-left: ${$icon ? space.padding.button.sm : space.padding.button.middle};
    padding-right: ${space.padding.button.middle};

    border: solid ${colors.global.general.main} ${borderWidth.strokeM};
    background-color: ${colors.global.general.transparent};
    color: ${colors.global.general.main};

    ${Inner} {
      display: flex;
      align-items: center;
      > i {
        margin-right: ${space.spacing.outlineButton};
      }
    }

    svg {
      fill: ${colors.global.general.main};
      color: ${colors.global.general.main};
    }

    &:hover,
    &:focus-visible {
      background-color: ${colors.global.general.main};
      color: ${colors.theme.brand.primary};

      svg {
        fill: ${colors.theme.brand.primary};
        color: ${colors.theme.brand.primary};
      }
    }

    &:active {
      background-color: ${colors.theme.brand.darker};
      color: ${colors.global.general.main};

      svg {
        fill: ${colors.global.general.main};
        color: ${colors.global.general.main};
      }
    }

    &:disabled {
      border-color: ${colors.global.general.transparent};
      background-color: ${colors.global.grey.grey300};
      color: ${colors.global.grey.grey500};
      svg {
        fill: ${colors.global.grey.grey500};
        color: ${colors.global.grey.grey500};
      }
    }
  `}
`

export const Neutral = styled(Box)<{ $icon?: boolean }>`
  ${({
    $icon,
    theme: {
      uiKit: { size, space, colors, borderWidth }
    }
  }) => css`
    height: ${size.buttonsHeight.small};

    padding-left: calc(${$icon ? space.padding.button.xs : space.padding.button.small} - ${borderWidth.strokeM});
    padding-right: calc(${space.padding.button.small} - ${borderWidth.strokeM});

    border: solid ${colors.global.grey.grey700} ${borderWidth.strokeM};

    background-color: ${colors.global.general.transparent};

    color: ${colors.global.grey.grey700};

    ${Inner} {
      display: flex;
      align-items: center;
      > i {
        margin-right: ${space.spacing.default};
      }
    }

    svg {
      fill: ${colors.global.grey.grey700};
      color: ${colors.global.grey.grey700};
    }

    &:hover,
    &:focus-visible {
      background-color: ${colors.global.grey.grey900};

      color: ${colors.global.general.main};

      svg {
        fill: ${colors.global.general.main};
        color: ${colors.global.general.main};
      }
    }

    &:active {
      background-color: ${colors.global.grey.grey700};

      color: ${colors.global.general.main};

      svg {
        fill: ${colors.global.general.main};
        color: ${colors.global.general.main};
      }
    }

    &:disabled {
      border-color: ${colors.global.general.transparent};

      background-color: ${colors.global.grey.grey300};

      color: ${colors.global.grey.grey500};

      svg {
        fill: ${colors.global.grey.grey500};
        color: ${colors.global.grey.grey500};
      }
    }
  `}
`
