import { FormPaths, OnboardingFormFieldNames, ParserNames } from '../../../enums'
import { getFormNameByFieldName } from './getFormNameByFieldName'

export type ParseFieldPathReturn = {
  isCompany: boolean
  isLicense: boolean
  licenseIndex: number
  isCompanyItem: boolean
  isContact: boolean
  isAtm: boolean
  atmIndex: number
  contactIndex: number
  companyIndex?: number
  formName: FormPaths
  fieldName: OnboardingFormFieldNames
}

export type ParseSubmitErrorPathHandler = (submitErrorPath: string) => ParseFieldPathReturn

// '.name'
const parseFieldFromRestPath = ({
  restPath,
  isContact
}: {
  restPath: string
  isContact: boolean
}): { formName?: FormPaths; fieldName?: OnboardingFormFieldNames } => {
  const strDotSplitted = restPath.split('.')
  const partsLen = strDotSplitted.length
  const fieldNameSrc = strDotSplitted[partsLen - 1]
  const fieldName = !partsLen || fieldNameSrc === '' ? undefined : (fieldNameSrc as unknown as OnboardingFormFieldNames)
  const formName = fieldName
    ? getFormNameByFieldName({ fieldName: fieldName as unknown as OnboardingFormFieldNames, isContact })
    : undefined
  return { formName, fieldName }
}

const parseCompanyItem = (
  submitErrorPath: string
): { isCompanyItem: boolean; companyIndex: number | undefined; restPath } => {
  // "rfi_info.relationships[0].name" -> ['', '0].name']
  const [, companyIndexAndRest] = submitErrorPath.split(`${ParserNames.RFI_INFO}.${ParserNames.COMPANY_ITEM}[`)
  // '0].name' -> ['0', '.name']
  const [companyIndexStr, ...restFieldNames] = companyIndexAndRest.split(']')
  const companyIndex = parseInt(companyIndexStr, 10)
  const isCompanyItem = Number.isInteger(companyIndex)
  return { isCompanyItem, companyIndex: isCompanyItem ? companyIndex : undefined, restPath: restFieldNames.join(']') }
}
const getContactIndex = (value: string): number | undefined => {
  const splitByContacts = value.split('.contacts[')
  if (splitByContacts.length < 2) {
    return undefined
  }
  // 0]... => ['0', '...']
  const splitByEnds = splitByContacts[splitByContacts.length - 1].split(']')
  if (splitByEnds.length < 2 || !Number.isInteger(parseInt(splitByEnds[0], 10))) {
    return undefined
  }
  return parseInt(splitByEnds[0], 10)
}

export const parseSubmitErrorPath: ParseSubmitErrorPathHandler = (submitErrorPath) => {
  const { isCompanyItem, companyIndex, restPath } = parseCompanyItem(submitErrorPath)
  const isLicense = submitErrorPath.includes('.licenses[')
  // TODO: MOCK!!!
  const licenseIndex = 0
  const isContact = submitErrorPath.includes('.contacts[')
  const contactIndex = getContactIndex(submitErrorPath) || 0
  // TODO: backend not validate atms yet
  const isAtm = false
  const atmIndex = 0
  const { formName, fieldName } = parseFieldFromRestPath({ restPath, isContact }) // '.name'
  return {
    isCompany: isCompanyItem,
    isLicense,
    licenseIndex,
    isCompanyItem,
    isContact,
    isAtm,
    atmIndex,
    contactIndex,
    companyIndex,
    formName,
    fieldName
  }
}
