import { StepperProps } from './StepperProps'

type Step = {
  title: string
  done: boolean
  active: boolean
}

const allSteps: { [key: number]: Step } = {
  1: {
    title: 'General Information',
    done: false,
    active: false
  },
  2: { title: 'Document Uploading', done: false, active: false },
  3: { title: 'OnBoarding Documents', done: false, active: false },
  4: { title: 'Account Opened', done: false, active: false }
}

const getSteps = ({ step, availableSteps }: StepperProps): Step[] => {
  const steps: Step[] = []

  availableSteps.forEach((el) => {
    const tempStep = allSteps[el]
    tempStep.done = step > el
    tempStep.active = step === el
    steps.push(tempStep)
  })
  return steps
}

export default getSteps
