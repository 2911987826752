import React from 'react'

import { Request } from 'commons/utils/request'
import { Link } from 'ui/components'
import { CheckBox, DownloadLink, IconName, TableColumn, Toast, formatDateTime } from 'ui/components'
import { IconButton, TextButton } from 'ui/components/Buttons'
import { UploadBatchPdfButton } from 'ui/components/UploadBatchPdfButton'

import { SARListItem, SARListWebPage } from './types'

import * as SC from './styles'

const handleError = (message: string) => Toast.error('Error', message)

const baseUrl = Request.urls.bank.batchFileDownload

export const columns = (
  chosenSarItems: SARListItem[],
  webPage: SARListWebPage,
  setSarItemForInfoView: (sar: SARListItem) => void,
  handleSarItemSelect: (sar: SARListItem) => void,
  handleDeleteFromTableMenu: (sar: SARListItem) => void
): TableColumn<SARListItem>[] => [
  {
    title: (
      <CheckBox
        checkBoxLabel=""
        value={chosenSarItems.length && chosenSarItems.length === webPage?.items.length}
        onChange={() => handleSarItemSelect(null)}
      />
    ),
    align: 'center',
    sortable: false,
    render: (item) => (
      <CheckBox
        checkBoxLabel=""
        value={chosenSarItems.findIndex((i) => i.id === item.id) !== -1}
        onChange={() => handleSarItemSelect(item)}
      />
    )
  },
  {
    title: 'ID',
    render: ({ id }) => <Link to={`/bsa/batch-details/${id}`}>{id}</Link>
  },
  {
    title: 'SARs Period',
    align: 'left',
    render: ({ startDate, endDate }) => {
      const sarsStartDate = formatDateTime(startDate)
      const sarsEndDate = formatDateTime(endDate)
      return (
        <>
          {sarsStartDate}&nbsp;-&nbsp;{sarsEndDate}
        </>
      )
    }
  },
  {
    title: 'Status',
    key: 'status',
    align: 'left'
  },
  {
    title: 'Batch XML',
    align: 'left',
    render: ({ batchXML, id }) => {
      if (batchXML) {
        return (
          <DownloadLink
            direct
            parametersForm={{ id, file_type: 'xml' }}
            baseUrl={baseUrl}
            name={batchXML}
            handleError={handleError}
          >
            <SC.LongFilename title={batchXML}>{batchXML}</SC.LongFilename>
          </DownloadLink>
        )
      }

      return null
    }
  },
  {
    title: 'Batch PDF',
    align: 'left',
    render: ({ batchPDF, id }) => {
      if (batchPDF) {
        return (
          <DownloadLink
            direct
            parametersForm={{ id, file_type: 'sarx' }}
            baseUrl={baseUrl}
            name={batchPDF}
            handleError={handleError}
          >
            <SC.LongFilename title={batchPDF}>{batchPDF}</SC.LongFilename>
          </DownloadLink>
        )
      }

      return <UploadBatchPdfButton batchId={id} isAcknowledgments={false} />
    }
  },
  {
    title: 'Acknowledgments PDF',
    align: 'left',
    render: ({ acknowledgmentsPDF, id }) => {
      if (acknowledgmentsPDF) {
        return (
          <DownloadLink
            direct
            parametersForm={{ id, file_type: 'acknowledgments' }}
            baseUrl={baseUrl}
            name={acknowledgmentsPDF}
            handleError={handleError}
          >
            <SC.LongFilename title={acknowledgmentsPDF}>{acknowledgmentsPDF}</SC.LongFilename>
          </DownloadLink>
        )
      }

      return <UploadBatchPdfButton batchId={id} isAcknowledgments />
    }
  },
  {
    title: 'SARs',
    align: 'left',
    render: (sar) =>
      sar.sarsInfo && sar.sarsInfo.length ? (
        <TextButton onClick={() => setSarItemForInfoView(sar)}>{sar.sarsInfo.length}</TextButton>
      ) : null
  },
  {
    title: 'BSA Tracking ID',
    key: 'bsaTrackingId',
    align: 'left'
  },
  {
    title: 'Action',
    align: 'center',
    render: (item) => {
      if (item.enableDelete) {
        return (
          <IconButton icon={IconName.DELETE} face="negative" onClick={() => handleDeleteFromTableMenu(item)}>
            Delete
          </IconButton>
        )
      }

      return null
    }
  }
]
