import React, { FC, Fragment, memo } from 'react'

import ChangesItem from './ChangesItem'
import { HeaderLink } from './HeaderLink'
import { HistoryRecordDetailsProps } from './HistoryRecordDetailsProps'

export const HistoryRecordDetails: FC<HistoryRecordDetailsProps> = memo((props) => {
  const { changes, links } = props

  if (!changes && !links) {
    return <p>---</p>
  }

  return (
    <div>
      {links &&
        links.map((link, index) => (
          <Fragment key={'link' + index}>
            <HeaderLink {...link} />
          </Fragment>
        ))}

      {changes.map((element, index) => (
        <ChangesItem key={index} element={element} />
      ))}
    </div>
  )
})
