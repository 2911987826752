import { useMutation } from 'react-query'

import { PortalName } from 'commons/types/enums'
import { setNewPassword } from '~auth/service/user'

export const useSetNewPassword = (p: PortalName) => {
  return useMutation((form: { password: string; old_password: string }) => setNewPassword(p, form), {
    onError: (error: any) => {
      throw error
    }
  })
}
