import React, { FC, memo } from 'react'

import { ResourcePath } from 'ui/temp/ResourcePath'

import { LicensesTitleProps } from './LicensesTitleProps'

export const LicensesTitle: FC<LicensesTitleProps> = memo(({ licenses, withUrl }) => {
  const mainLicense = licenses.find((el) => el.isMain)

  return (
    <>
      {licenses.length === 1 && (
        <>
          {withUrl ? (
            <>
              <ResourcePath
                finishedPath={`/licenses/${mainLicense?.licenseID}`}
                name={mainLicense?.licenseNumber}
                id={mainLicense?.licenseID}
              />
            </>
          ) : (
            <>{mainLicense.licenseNumber}</>
          )}
        </>
      )}
      {licenses.length > 1 && (
        <>
          (
          {licenses.map((el, i) => (
            <>
              {withUrl ? (
                <ResourcePath finishedPath={`/licenses/${el.licenseID}`} name={el.licenseNumber} id={el.licenseID} />
              ) : (
                <>{el.licenseNumber}</>
              )}
              {i === licenses.length - 1 ? '' : ', '}
            </>
          ))}
          )
        </>
      )}
    </>
  )
})
