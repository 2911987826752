export const messages = {
  'bankContacts.createSuccess': 'Contact successfully added',
  'bankContacts.updateSuccess': 'Contact successfully updated',
  'bankContacts.deleteSuccess': 'Contact was deleted.',
  'bankContacts.deleteConfirmation': ({ contactFirstName, contactLastName }) =>
    `Are you sure want to delete ${`${contactFirstName || ''} ${contactLastName || ''}`.trim()} contact?`,
  'bankContacts.editTitle': 'Edit Contact',
  'bankContacts.createTitle': 'Create New Contact',
  'bankContacts.delete': 'Delete'
}
