import React, { FC, memo } from 'react'

import { Dialog, DialogFace } from 'ui/components/Dialog'

import { CreateModeSelectDialogProps } from './CreateModeSelectDialogProps'
import { CreateModeSelectionForm } from './components'

export const CreateModeSelectDialog: FC<CreateModeSelectDialogProps> = memo((props) => {
  const { onClose, isOpen, onSubmit } = props

  if (!isOpen) {
    return null
  }

  return (
    <Dialog id="add-contact-metadata" face={DialogFace.PRIMARY} title="Add New Contact" onClose={onClose}>
      <CreateModeSelectionForm onSubmit={onSubmit} onCancel={onClose} />
    </Dialog>
  )
})
