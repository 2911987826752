import { AnnualReview } from 'commons/types/DTO/bank/annualReview'
import { getHistoricalDate } from 'ui/components/DateTime'

import { NewArFormValue } from '../../../components/AddNewArDialog/NewArFormValue'
import { AnnualReviewFormValue } from './components/AnnualReviewEditForm'

export const generateSubmitModel = (
  formValue: AnnualReviewFormValue | NewArFormValue,
  annualReview?: AnnualReview
) => ({
  ...annualReview,
  last_ar_date: formValue.last_ar_date,
  financials_start_date: formValue.financials_start_date
    ? formValue.financials_start_date
    : annualReview?.financials_end_date,
  status: (formValue as AnnualReviewFormValue)?.status
    ? (formValue as AnnualReviewFormValue)?.status
    : annualReview?.status,
  financials_end_date: formValue.financials_end_date
    ? formValue.financials_end_date
    : annualReview?.financials_end_date,
  questionnaire: {
    covenant_complying:
      formValue['questionnaire.covenant_complying'] || annualReview?.questionnaire?.covenant_complying,
    covenant_non_compliance_desc:
      formValue['questionnaire.covenant_non_compliance_desc'] ||
      annualReview?.questionnaire?.covenant_non_compliance_desc,
    covenant_transparent:
      formValue['questionnaire.covenant_transparent'] || annualReview?.questionnaire?.covenant_transparent,
    financials_becoming_failing:
      formValue['questionnaire.financials_becoming_failing'] ||
      annualReview?.questionnaire?.financials_becoming_failing,
    financials_acceptable_notes:
      formValue['questionnaire.financials_acceptable_notes'] ||
      annualReview?.questionnaire?.financials_acceptable_notes,
    financials_current_condition_is_acceptable:
      formValue['questionnaire.financials_current_condition_is_acceptable'] ||
      annualReview?.questionnaire?.financials_current_condition_is_acceptable,
    financials_is_materials_regarding_income:
      formValue['questionnaire.financials_is_materials_regarding_income'] ||
      annualReview?.questionnaire?.financials_is_materials_regarding_income,
    financials_materials_notes:
      formValue['questionnaire.financials_materials_notes'] || annualReview?.questionnaire?.financials_materials_notes,
    financials_business_condition:
      formValue['questionnaire.financials_business_condition'] ||
      annualReview?.questionnaire?.financials_business_condition,
    financials_deposits_matching:
      formValue['questionnaire.financials_deposits_matching'] ||
      annualReview?.questionnaire?.financials_deposits_matching,
    financials_investor_debtholders:
      formValue['questionnaire.financials_investor_debtholders'] ||
      annualReview?.questionnaire?.financials_investor_debtholders,
    financials_period_collected:
      formValue['questionnaire.financials_period_collected'] ||
      annualReview?.questionnaire?.financials_period_collected,
    financials_profitability_trend:
      formValue['questionnaire.financials_profitability_trend'] ||
      annualReview?.questionnaire?.financials_profitability_trend,
    licensing_loc_changes:
      formValue['questionnaire.licensing_loc_changes'] || annualReview?.questionnaire?.licensing_loc_changes,
    licensing_loc_changes_mmcc:
      formValue['questionnaire.licensing_loc_changes_mmcc'] || annualReview?.questionnaire?.licensing_loc_changes_mmcc,
    licensing_name_changes:
      formValue['questionnaire.licensing_name_changes'] || annualReview?.questionnaire?.licensing_name_changes,
    licensing_name_changes_mmcc:
      formValue['questionnaire.licensing_name_changes_mmcc'] ||
      annualReview?.questionnaire?.licensing_name_changes_mmcc,
    licensing_name_prior:
      formValue['questionnaire.licensing_name_prior'] || annualReview?.questionnaire?.licensing_name_prior,
    ownership_bad_news:
      formValue['questionnaire.ownership_bad_news'] || annualReview?.questionnaire?.ownership_bad_news,
    ownership_bad_news_cleared:
      formValue['questionnaire.ownership_bad_news_cleared'] || annualReview?.questionnaire?.ownership_bad_news_cleared,
    ownership_bad_news_cleared_explain:
      formValue['questionnaire.ownership_bad_news_cleared_explain'] ||
      annualReview?.questionnaire?.ownership_bad_news_cleared_explain,
    ownership_bad_news_desc:
      formValue['questionnaire.ownership_bad_news_desc'] || annualReview?.questionnaire?.ownership_bad_news_desc,
    ownership_changes: formValue['questionnaire.ownership_changes'] || annualReview?.questionnaire?.ownership_changes,
    ownership_changes_approved:
      formValue['questionnaire.ownership_changes_approved'] || annualReview?.questionnaire?.ownership_changes_approved,
    ownership_ofac_tlo_reviewed:
      formValue['questionnaire.ownership_ofac_tlo_reviewed'] ||
      annualReview?.questionnaire?.ownership_ofac_tlo_reviewed,
    ownership_provided_forms:
      formValue['questionnaire.ownership_provided_forms'] || annualReview?.questionnaire?.ownership_provided_forms,
    recom_change_visits:
      formValue['questionnaire.recom_change_visits'] || annualReview?.questionnaire?.recom_change_visits,
    recom_recommended: formValue['questionnaire.recom_recommended'] || annualReview?.questionnaire?.recom_recommended,
    recom_status: formValue['questionnaire.recom_status'] || annualReview?.questionnaire?.recom_status,
    site_visit_complying_regulations:
      formValue['questionnaire.site_visit_complying_regulations'] ||
      annualReview?.questionnaire?.site_visit_complying_regulations,
    site_visit_freq: formValue['questionnaire.site_visit_freq'] || annualReview?.questionnaire?.site_visit_freq,
    site_visit_last_date: formValue['questionnaire.site_visit_last_date']
      ? getHistoricalDate(formValue['questionnaire.site_visit_last_date'])
      : annualReview?.questionnaire?.site_visit_last_date,
    site_visit_scores_acceptable:
      formValue['questionnaire.site_visit_scores_acceptable'] ||
      annualReview?.questionnaire?.site_visit_scores_acceptable,
    site_visit_scores_acceptable_exactly:
      formValue['questionnaire.site_visit_scores_acceptable_exactly'] ||
      annualReview?.questionnaire?.site_visit_scores_acceptable_exactly,
    site_visit_shutdown_risk:
      formValue['questionnaire.site_visit_shutdown_risk'] || annualReview?.questionnaire?.site_visit_shutdown_risk,
    site_visit_violation_rectified:
      formValue['questionnaire.site_visit_violation_rectified'] ||
      annualReview?.questionnaire?.site_visit_violation_rectified,
    transactions_ach: formValue['questionnaire.transactions_ach'] || annualReview?.questionnaire?.transactions_ach,
    transactions_atm: formValue['questionnaire.transactions_atm'] || annualReview?.questionnaire?.transactions_atm,
    transactions_cash: formValue['questionnaire.transactions_cash'] || annualReview?.questionnaire?.transactions_cash,
    transactions_check:
      formValue['questionnaire.transactions_check'] || annualReview?.questionnaire?.transactions_check,
    transactions_expect_exactly:
      formValue['questionnaire.transactions_expect_exactly'] ||
      annualReview?.questionnaire?.transactions_expect_exactly,
    transactions_expect_explain:
      formValue['questionnaire.transactions_expect_explain'] ||
      annualReview?.questionnaire?.transactions_expect_explain,
    transactions_match_deposit:
      formValue['questionnaire.transactions_match_deposit'] || annualReview?.questionnaire?.transactions_match_deposit,
    transactions_match_trans:
      formValue['questionnaire.transactions_match_trans'] || annualReview?.questionnaire?.transactions_match_trans,
    transactions_notes:
      formValue['questionnaire.transactions_notes'] || annualReview?.questionnaire?.transactions_notes,
    transactions_transfer:
      formValue['questionnaire.transactions_transfer'] || annualReview?.questionnaire?.transactions_transfer,
    transactions_vol_projected:
      formValue['questionnaire.transactions_vol_projected'] || annualReview?.questionnaire?.transactions_vol_projected,
    transactions_vol_projected_exactly:
      formValue['questionnaire.transactions_vol_projected_exactly'] ||
      annualReview?.questionnaire?.transactions_vol_projected_exactly,
    site_visit_has_state_audit_at_review_period:
      formValue['questionnaire.site_visit_has_state_audit_at_review_period'] ||
      annualReview?.questionnaire?.site_visit_has_state_audit_at_review_period,
    site_visit_state_audit_notes:
      formValue['questionnaire.site_visit_state_audit_notes'] ||
      annualReview?.questionnaire?.site_visit_state_audit_notes,
    ownership_has_material_changes_in_management_at_review_period:
      formValue['questionnaire.ownership_has_material_changes_in_management_at_review_period'] ||
      annualReview?.questionnaire?.ownership_has_material_changes_in_management_at_review_period,
    ownership_material_changes_in_management_notes:
      formValue['questionnaire.ownership_material_changes_in_management_notes'] ||
      annualReview?.questionnaire?.ownership_material_changes_in_management_notes,
    recom_comments: formValue['questionnaire.recom_comments'] || annualReview?.questionnaire?.recom_comments
  }
})

export const generateCBTSubmitModel = (formValue: AnnualReviewFormValue, annualReview?: AnnualReview) => ({
  ...annualReview,
  last_ar_date: formValue?.last_ar_date,
  financials_start_date: formValue.financials_start_date
    ? formValue.financials_start_date
    : annualReview?.financials_start_date,
  financials_end_date: formValue.financials_end_date
    ? formValue.financials_end_date
    : annualReview?.financials_end_date,
  status: formValue.status ? formValue.status : annualReview?.status,
  questionnaire: {
    has_more_revenue: 'Yes',
    revenue_from_mj_only: 'No',
    comparing_sics_and_bank_deposits_red_flag:
      formValue['questionnaire.comparing_sics_and_bank_deposits_red_flag'] ||
      annualReview?.questionnaire?.comparing_sics_and_bank_deposits_red_flag,
    comparing_sics_and_bank_deposits_variance:
      formValue['questionnaire.comparing_sics_and_bank_deposits_variance'] ||
      annualReview?.questionnaire?.comparing_sics_and_bank_deposits_variance,
    comparing_sics_and_bank_deposits_comments:
      formValue['questionnaire.comparing_sics_and_bank_deposits_comments'] ||
      annualReview?.questionnaire?.comparing_sics_and_bank_deposits_comments,
    has_more_cash_deposits: 'Yes',
    comparing_cash_deposits_and_tax_returns_red_flag:
      formValue['questionnaire.comparing_cash_deposits_and_tax_returns_red_flag'] ||
      annualReview?.questionnaire?.comparing_cash_deposits_and_tax_returns_red_flag,
    comparing_cash_deposits_and_tax_returns_variance:
      formValue['questionnaire.comparing_cash_deposits_and_tax_returns_variance'] ||
      annualReview?.questionnaire?.comparing_cash_deposits_and_tax_returns_variance,
    comparing_cash_deposits_and_tax_returns_comments:
      formValue['questionnaire.comparing_cash_deposits_and_tax_returns_comments'] ||
      annualReview?.questionnaire?.comparing_cash_deposits_and_tax_returns_comments,
    inconsistent_financial_statement: 'Yes',
    financial_statement_validation_red_flag:
      formValue['questionnaire.financial_statement_validation_red_flag'] ||
      annualReview?.questionnaire?.financial_statement_validation_red_flag,
    financial_statement_validation_variance:
      formValue['questionnaire.financial_statement_validation_variance'] ||
      annualReview?.questionnaire?.financial_statement_validation_variance,
    financial_statement_validation_comments:
      formValue['questionnaire.financial_statement_validation_comments'] ||
      annualReview?.questionnaire?.financial_statement_validation_comments,
    disguising_mrb_activity: 'Yes',
    inconsistent_mrb_purporting: 'Yes',
    inconsistent_mrb_purporting_due_diligence_completed: 'Yes',
    owner_outside_state: 'Yes',
    owner_outside_state_approved: 'Yes',
    adverse_media_required: 'Yes',
    adverse_media_reviewed: 'Yes',
    mrb_on_federal_property: 'Yes',
    mrb_school_proximity_consistent: 'No',
    mrb_school_proximity_due_diligence_completed: 'Yes'
  }
})
