import React from 'react'

import { CheckBox, FF } from 'ui/components'

import { useSARFormContext } from '../../../../../../../context'

export const E = () => {
  const { formApi } = useSARFormContext()
  return (
    <formApi.Field
      name="transactions_info.laundering.e"
      children={(field) => (
        <FF
          field={field}
          input={(p) => (
            <CheckBox {...p} checkBoxLabel="e. Suspicious designation of beneficiaries, assignees or joint owners" />
          )}
          errorPosition="right"
        />
      )}
    />
  )
}
