import React, { memo, useCallback, useState } from 'react'

import * as SC from './styles/WithCheckboxInputStyles'

import { WithCheckboxInputProps } from './props'

export const WithCheckboxInput = memo((props: WithCheckboxInputProps) => {
  const { value, input: Input, 'data-qa': dataQa } = props
  const [isChecked, setIsChecked] = useState(value === '')

  const handleCheckBox = useCallback((check: boolean) => {
    setIsChecked(check)
  }, [])

  if (!Input) {
    throw new Error('Set input prop with correct component')
  }

  return (
    <SC.Wrapper>
      <Input {...props} value={value} disabled={isChecked} />
      <SC.CheckBoxStyled
        data-qa={dataQa ? `${dataQa}_Unknown` : undefined}
        value={isChecked}
        checkBoxLabel={'Unknown'}
        onChange={handleCheckBox}
      />
    </SC.Wrapper>
  )
})
