import React, { FC, memo, useCallback, useState } from 'react'

import { Dialog, DialogFace } from 'ui/components/Dialog'
import { UserMultiSelect } from 'ui/components/Select'

import { CompanyOwnersProps } from './CompanyOwnersProps'

import { Inner, SaveButton } from './styles'

export const CompanyOwners: FC<CompanyOwnersProps> = memo((props) => {
  const { onClose, visible, ids, companyId, onChange, availableOwners } = props
  const [value, setValue] = useState(ids)
  const onSubmit = useCallback(() => onChange({ companyId, ids: value }), [onChange, value])

  if (!visible) {
    return null
  }

  return (
    <Dialog id="company-owners" onClose={onClose} face={DialogFace.PRIMARY} title="Relationship Owner(s)">
      <Inner>
        <UserMultiSelect value={value} onChange={setValue} options={availableOwners} />
        <SaveButton onClick={onSubmit} face="positive">
          Save
        </SaveButton>
      </Inner>
    </Dialog>
  )
})
