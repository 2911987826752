import { useCallback, useEffect, useMemo, useRef } from 'react'

import debounce from 'lodash/debounce'

type CallbackFunction = (...args: any[]) => any

export const useDebounce = <T extends CallbackFunction>(callback: T, wait = 1200): T => {
  const ref = useRef<T>()

  useEffect(() => {
    ref.current = callback
  }, [callback])

  const debouncedCallback = useMemo(() => {
    const func = (...args: Parameters<T>) => {
      ref.current?.(...args)
    }

    return debounce(func, wait) as unknown as T
  }, [wait])

  return useCallback(debouncedCallback, [debouncedCallback])
}
