import { ButtonHTMLAttributes } from 'react'

import { TableButtonFace } from './TableButtonFace'

export interface TableButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  face?: TableButtonFace
  // TODO: deal with it
  innerRef?: any
  'data-qa'?: string
}
