import React from 'react'

import { FF, TextInput } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

export const DBA = () => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name="institution_contact_info.dba"
      children={(field) => (
        <FF
          label="87. Alternate name"
          field={field}
          input={(p) => <TextInput {...p} maxLength={9} data-qa="87. Alternate name" />}
        />
      )}
    />
  )
}
