import React from 'react'

import { A, B, C, D, E, F, G, H, I, J, K, L, M, N, O, P, Q, R, S, Z } from './fields'

import { s } from './styles'

export const OtherActivities = () => (
  <>
    <s.CheckBoxWrapper withLabel>
      <A />
    </s.CheckBoxWrapper>
    <s.CheckBoxWrapper>
      <B />
    </s.CheckBoxWrapper>
    <s.CheckBoxWrapper>
      <C />
    </s.CheckBoxWrapper>
    <s.CheckBoxWrapper>
      <D />
    </s.CheckBoxWrapper>
    <s.CheckBoxWrapper>
      <E />
    </s.CheckBoxWrapper>
    <s.CheckBoxWrapper>
      <F />
    </s.CheckBoxWrapper>
    <s.CheckBoxWrapper>
      <G />
    </s.CheckBoxWrapper>
    <s.CheckBoxWrapper>
      <H />
    </s.CheckBoxWrapper>
    <s.CheckBoxWrapper>
      <I />
    </s.CheckBoxWrapper>
    <s.CheckBoxWrapper>
      <J />
    </s.CheckBoxWrapper>
    <s.CheckBoxWrapper>
      <K />
    </s.CheckBoxWrapper>
    <s.CheckBoxWrapper>
      <L />
    </s.CheckBoxWrapper>
    <s.CheckBoxWrapper>
      <M />
    </s.CheckBoxWrapper>
    <s.CheckBoxWrapper>
      <N />
    </s.CheckBoxWrapper>
    <s.CheckBoxWrapper>
      <O />
    </s.CheckBoxWrapper>
    <s.CheckBoxWrapper>
      <P />
    </s.CheckBoxWrapper>
    <s.CheckBoxWrapper>
      <Q />
    </s.CheckBoxWrapper>
    <s.CheckBoxWrapper>
      <R />
    </s.CheckBoxWrapper>
    <s.CheckBoxWrapper>
      <S />
    </s.CheckBoxWrapper>
    <s.CheckBoxWrapper>
      <Z />
    </s.CheckBoxWrapper>
  </>
)
