import { Request } from 'commons/utils/request'

export const affiliatedCompaniesList = (id?: number) =>
  Request.post(Request.urls.bank.affiliatedCompanyList, {
    _options: {
      filters: [
        {
          type: 'or',
          filters: [
            { field: 'parent_company_id', type: 'eq', value: id },
            { field: 'child_company_id', type: 'eq', value: id }
          ]
        }
      ]
    }
  })
