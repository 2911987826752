import React, { FC, memo } from 'react'

import { IconSize } from 'ui/components/Icon'
import { Info, InfoSet } from 'ui/components/Info'
import { TextMask } from 'ui/components/TextMask'
import { Col, Row } from 'ui/themes/globalStyles'

import { AccountDetailsProps } from './AccountDetailsProps'

export const AccountDetails: FC<AccountDetailsProps> = memo(({ applicationRelationship }) => (
  <InfoSet legend="Account Details">
    <Row>
      <Col>
        <Info label="Business Type">{applicationRelationship.businessType}</Info>
      </Col>
      <Col>
        <Info label="Entity Type">{applicationRelationship.entityType}</Info>
      </Col>
    </Row>
    <Row>
      <Col>
        <Info label="State of Incorporation or Organization">{applicationRelationship.incorpState}</Info>
      </Col>
      <Col>
        <Info label={applicationRelationship.einLabel}>
          {applicationRelationship.einValue ? (
            <TextMask text={applicationRelationship.einValue} iconSize={IconSize.XS} valueType="EIN" />
          ) : (
            '---'
          )}
        </Info>
      </Col>
    </Row>
    {applicationRelationship?.preLicenseMRBInfo && (
      <Row>
        <Col>
          <Info label="Pending license details">{applicationRelationship.preLicenseMRBInfo}</Info>
        </Col>
      </Row>
    )}
  </InfoSet>
))
