import styled from 'styled-components'

const TemplateInsideBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  padding: 28px 32px 48px 32px;
  gap: 20px 80px;

  & > * {
    width: 320px;
  }
`

const Footer = styled.footer`
  z-index: 1000;
  background: #fff;
  height: 66px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 50px 0 50px;
  border-top: 1px solid ${(p) => p.theme.uiKit.colors.global.grey.grey300};
`

export const s = { TemplateInsideBox, Footer }
