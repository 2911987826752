import React from 'react'

import { useRouter } from '@tanstack/react-router'
import { useSubmitDocumentRequestStep } from 'commons/hooks/bank/application'
import { handlerServerError } from 'errors'
import { Button } from 'ui/components/Buttons'
import { Toast } from 'ui/components/Toast'
import { useConfirmation } from 'ui/hooks'

import { AdvancePastDocsProps } from './AdvancePastDocsProps'

export const AdvancePastDocs = ({ application }: AdvancePastDocsProps) => {
  const router = useRouter()

  const { routine: submitDocumentRequestStep, isLoading } = useSubmitDocumentRequestStep({
    onSuccess: async () => {
      Toast.success('Application has been saved')
      router.invalidate()
    },
    onError: (error) => handlerServerError(error, { title: 'Cannot save application' })
  })

  const { Confirmation: AdvancePastDocsConfirmation, open: openAdvancePastDocsConfirmation } = useConfirmation({
    message: <span>Are you sure want to move the Application to Document Request Submitted status?</span>,
    onConfirm: () => submitDocumentRequestStep(application.applicationID),
    confirmationButtonText: 'Submit Document Request'
  })

  return (
    <>
      <AdvancePastDocsConfirmation />
      <Button loading={isLoading} face="positive" onClick={openAdvancePastDocsConfirmation}>
        Submit Document Request
      </Button>
    </>
  )
}
