import SC from 'styled-components'
import { Link } from 'ui/components/Link'

export const FormBody = SC.div`
  padding: 28px 42px 35px 42px;
`

export const EditLink = SC(Link)`
  font-style: italic
`
