import { ReactNode } from 'react'

import { FormConfig } from 'brief-form'
import { Toast } from 'ui/components/Toast'

import { errorMessages } from './enums'
import { takeFirstErrorsFromMeta } from './takeFirstErrorsFromMeta'
import { ServerErrorResponse } from './types'

export type HandlerServerErrorOpts<T = unknown> = {
  form?: FormConfig<T>
  title?: ReactNode
}

export const handlerServerError = <T>(error: any, opts?: HandlerServerErrorOpts<T>) => {
  if (!error.message && !error.meta) {
    throw new Error('Given object does not have "ServerErrorResponse" interface!')
  }

  const data: ServerErrorResponse = error as ServerErrorResponse

  if (data.message) {
    if (opts?.title) {
      Toast.error(opts.title, data.message)
    } else {
      Toast.errorSmall(data.message)
    }
  } else {
    Toast.errorSmall(errorMessages.SOMETHING_WENT_WRONT)
  }

  if (opts?.form && data.meta) {
    opts.form.onChange(opts.form.value, { ...opts.form.errors, ...takeFirstErrorsFromMeta(data.meta) })
  }
}
