import { WriteHook, useMutationManager } from 'react-query-state-manager'

import { Request } from 'commons/utils/request'
import { getHistoricalDate } from 'ui/components'

type CreateDocumentFileRequest = {
  file: File
  periodId: number
  deliveredAt?: string
}

export const uploadDocumentFile = (payload: CreateDocumentFileRequest) => {
  const form = new FormData()
  form.append('period_id', payload.periodId.toString())
  if (payload.deliveredAt) {
    form.append('delivered_at', getHistoricalDate(payload.deliveredAt))
  }
  form.append('document', payload.file)
  return Request.post<unknown>(Request.urls.bank.documentFileUpload, form)
}

export const useUploadDocumentPeriodFile: WriteHook<CreateDocumentFileRequest, unknown> = (opts) =>
  useMutationManager<CreateDocumentFileRequest, unknown>(uploadDocumentFile, opts)
