{
  "name": "Valliance",
  "logo": {
    "_1x": "logo1x.png",
    "_2x": "logo2x.png",
    "_3x": "logo3x.png"
  },
  "authLogo": {
    "_1x": "authLogo1x.png",
    "_2x": "authLogo2x.png",
    "_3x": "authLogo3x.png"
  },
  "background": {
    "_1x": "bg1x.png",
    "_2x": "bg2x.png",
    "_3x": "bg3x.png"
  },
  "uiKit": {
    "colors": {
      "theme": {
        "brand": {
          "lightest": "#B5DB91",
          "lighter": "#83C348",
          "primary": "#33A148",
          "darker": "#016A39",
          "darkest": "#014022"
        },
        "gradient": {
          "login": "linear-gradient(200deg, #33A148 0%, #016A39 100%)"
        },
        "positive": {
          "main": "#83C348",
          "darker": "#016A39",
          "darkest": "#014022"
        }
      }
    },
    "borderRadius": {
      "default": "0px",
      "roundedModal": "0px"
    },
    "typography": {
      "bodyM": {
        "button": {
          "fontFamily": "Source Sans Pro",
          "fontWeight": "600",
          "lineHeight": "16px",
          "fontSize": "14px",
          "letterSpacing": "0.2px",
          "paragraphSpacing": "0px",
          "textCase": "uppercase",
          "textDecoration": "none"
        }
      },
      "bodyL": {
        "buttonL": {
          "textCase": "uppercase",
          "textDecoration": "none",
          "paragraphSpacing": "0px",
          "fontFamily": "Source Sans Pro",
          "fontWeight": "700",
          "lineHeight": "20px",
          "fontSize": "16px",
          "letterSpacing": "0.2px"
        }
      }
    }
  }
}
