import { ChipFace } from 'ui/components'
import { DocumentStatuses } from '~bank-documents/types/enums/DocumentStatuses'

const documentPeriodFaceMap: Partial<Record<DocumentStatuses, ChipFace>> = {
  [DocumentStatuses.APPROVED]: ChipFace.PRIMARY,
  [DocumentStatuses.ACCEPTED]: ChipFace.PRIMARY,
  [DocumentStatuses.REJECTED]: ChipFace.DANGER,
  [DocumentStatuses.INCOMPLETE]: ChipFace.DANGER
}

export const getDocumentPeriodChip = (name: DocumentStatuses): ChipFace =>
  documentPeriodFaceMap[name] || ChipFace.DEFAULT
