import React, { memo } from 'react'
import { useQueryClient } from 'react-query'

import { AnySearchSchema, Link } from '@tanstack/react-router'
import { ButtonGroup } from 'ui/components/ButtonGroup'
import { IconName } from 'ui/components/Icon'
import { useSARApprove } from '~bank-bsa-reporting/hooks/sar/useSARApprove'

import * as SC from './styles'

interface Props {
  id: number
  nextId?: number
  previousId?: number
  canApprove?: boolean
  search?: AnySearchSchema
}

export const Footer = memo((props: Props) => {
  const { id, nextId, previousId, canApprove, search } = props

  const queryClient = useQueryClient()

  const onSuccessMutation = async () => {
    await queryClient.invalidateQueries(['tables', 'SARs List'])
    await queryClient.invalidateQueries(['sars', id])
  }

  const { routine: approveSAR, isLoading: isApproving } = useSARApprove(onSuccessMutation, () => {})

  return (
    <SC.Box>
      {previousId ? (
        <SC.PrevLink to="/bsa/sars/$id" params={{ id: previousId }} search={search}>
          <SC.IconButton face="neutral">
            <SC.StyledIcon name={IconName.ARROW_PREVIOUS} /> Prev
          </SC.IconButton>
        </SC.PrevLink>
      ) : (
        <span />
      )}
      <ButtonGroup margin="small">
        {nextId && (
          <Link to="/bsa/sars/$id" params={{ id: nextId }} search={search}>
            <SC.IconButton face="neutral">
              <SC.StyledIcon name={IconName.ARROW_NEXT} /> Next
            </SC.IconButton>
          </Link>
        )}
        {canApprove && (
          <SC.ApproveButton face="positive" disabled={isApproving} onClick={() => approveSAR({ id })}>
            Approve
          </SC.ApproveButton>
        )}
      </ButtonGroup>
    </SC.Box>
  )
})
