import { ClientPortalInvite } from '../../../../types'

export interface ClientPortalInviteDialogProps {
  visible: boolean
  isPending: boolean
  onClose: () => void
  companyName?: string
  companyId: number
  onSubmit: (invite: ClientPortalInvite) => Promise<void>
}
