import SC, { css } from 'styled-components'

const EntityHistoryWrapperCss = css`
  max-width: 1000px;
  width: 100%;

  table {
    td,
    th {
      padding: 10px;

      &:first-child {
        text-align: left;
        padding: 10px 30px;
      }

      &:nth-child(2) {
        text-align: left;
      }

      &:last-child {
        text-align: right;
        padding: 10px 30px;
      }
    }
  }
`

export const EntityHistoryWrapper = SC.div`${EntityHistoryWrapperCss}`
