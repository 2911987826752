import React, { FC, memo } from 'react'

import { FieldSet } from 'ui/components/FieldSet'
import { Hr } from 'ui/components/Hr'
import { TextareaInput } from 'ui/components/InputV2'
import { Col2x, FormRow } from 'ui/themes/globalStyles'

import { DescriptionProps } from './DescriptionProps'

export const Description: FC<DescriptionProps> = memo(({ Field }) => (
  <FieldSet legend="Description">
    <FormRow>
      <Col2x>
        <Field
          name="description"
          label=""
          input={TextareaInput}
          inputProps={{
            style: { height: '160px' },
            'data-qa': 'Description'
          }}
        />
      </Col2x>
    </FormRow>
    <br />
    <Hr />
  </FieldSet>
))
