import styled from 'styled-components'
import { CSS } from 'ui/helpers'

export const Wrapper = styled.div<{ $biggerTopPadding: boolean }>`
  max-width: 600px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: ${(p) => (p.$biggerTopPadding ? '20px' : '5px')} 32px 20px 32px;
`

export const Title = styled.h5`
  ${(p) => CSS.populateFont(p.theme.uiKit.typography.h5.semiBold)};
  margin-bottom: 12px;
`

export const InputWrapper = styled.div`
  width: 320px;
`
