import React from 'react'

import { FieldApi } from '@tanstack/react-form'
import { BsaSar } from 'commons/types/DTO/bank/bsa'
import { BankAccountInput, FF, IconName } from 'ui/components'
import { IconButton } from 'ui/components/Buttons'
import { useConfirmation } from 'ui/hooks'
import { Col, FormRow } from 'ui/themes/globalStyles'

import { useSARFormContext } from '../../../../../../../../../context'

type BankAccountProps = {
  index1: number
  index2: number
  parentField: FieldApi<BsaSar, 'subjects_info[0].institution_info[0].account_numbers'>
}

export const BankAccount = (props: BankAccountProps) => {
  const { index1, index2, parentField } = props
  const { formApi } = useSARFormContext()

  const { open: startDeleting, Confirmation } = useConfirmation({
    message: `Are you sure you want to remove this item?`,
    onConfirm: () => parentField.removeValue(index2),
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  return (
    <>
      <Confirmation />
      <formApi.Field
        name={
          `subjects_info[${index1}].institution_info[0].account_numbers[${index2}].number` as 'subjects_info[0].institution_info[0].account_numbers[0].number'
        }
        children={(field) => (
          <FormRow>
            <Col>
              <FF field={field} input={(p) => <BankAccountInput {...p} />} label="Account number (s)" />
            </Col>
            {parentField.state.value.length > 1 && (
              <IconButton face="negative" icon={IconName.DELETE} onClick={startDeleting} />
            )}
          </FormRow>
        )}
      />
    </>
  )
}
