import { ReactNode } from 'react'

interface AnnualReviewCBTQuestionnaireMappingProps {
  hasMoreRevenue: string | ReactNode
  revenueFromMJOnly: string | ReactNode
  informationForMETRC_dump: string | ReactNode
  comparingSICSAndBankDepositsRedFlag: string | ReactNode
  comparingSICSAndBankDepositsVariance: string | ReactNode
  comparingSICSAndBankDepositsComments: string | ReactNode
  hasMoreCashDeposits: string | ReactNode
  compareCashAmount_dump: string | ReactNode
  comparingCashDepositsAndTaxReturnsRedFlag: string | ReactNode
  comparingCashDepositsAndTaxReturnsVariance: string | ReactNode
  comparingCashDepositsAndTaxReturnsComments: string | ReactNode
  inconsistentFinancialStatement: string | ReactNode
  validateContributions_dump: string | ReactNode
  financialStatementValidationRedFlag: string | ReactNode
  financialStatementValidationVariance: string | ReactNode
  financialStatementValidationComments: string | ReactNode
  disguisingMRBActivity: string | ReactNode
  inconsistentMRBPurporting: string | ReactNode
  inconsistentMRBPurportingDueDiligenceCompleted: string | ReactNode
  ownerOutsideState: string | ReactNode
  ownerOutsideStateApproved: string | ReactNode
  adverseMediaRequired: string | ReactNode
  adverseMediaReviewed: string | ReactNode
  mrbOnFederalProperty: string | ReactNode
  mrbSchoolProximityConsistent: string | ReactNode
  mrbSchoolProximityDueDiligenceCompleted: string | ReactNode
}

export const annualReviewCBTQuestionnaireMapping = (
  data: AnnualReviewCBTQuestionnaireMappingProps,
  params: Record<string, any> = {}
): any[] => [
  {
    title: 'Questionnaire',
    items: [
      [
        'The business receives substantially more revenue that it’s local competitors or than might be expected given the population demographics.',
        data.hasMoreRevenue,
        params?.hasMoreRevenue
      ],
      [
        'The business is unable to demonstrate that its revenue is derived exclusively from the ' +
          'sale of marijuana in compliance with state law, as opposed to derived from (i) the sale of other illicit ' +
          'drugs, (ii) the sale of marijuana not in compliance with state law, or (iii) other illegal activity.',
        data.revenueFromMJOnly,
        params?.revenueFromMJOnly
      ],
      [
        'Compare the prior year information for METRC and Bank Deposit activity.',
        data.informationForMETRC_dump,
        params?.informationForMETRC_dump
      ],
      ['Red Flag?', data.comparingSICSAndBankDepositsRedFlag, params?.comparingSICSAndBankDepositsRedFlag],
      [
        'Is activity within variance?',
        data.comparingSICSAndBankDepositsVariance,
        params?.comparingSICSAndBankDepositsVariance
      ],
      ['Comments:', data.comparingSICSAndBankDepositsComments, params?.comparingSICSAndBankDepositsComments],
      [
        'The business is depositing more cash than is commensurate with the amount of marijuana-related ' +
          'revenue it is reporting for federal and state tax purposes.',
        data.hasMoreCashDeposits,
        params?.hasMoreCashDeposits
      ],
      [
        'Compare the cash amount for the prior year matching the most complete annual information for Sales Tax Returns.',
        data.compareCashAmount_dump,
        params?.compareCashAmount_dump
      ],
      ['Red Flag?', data.comparingCashDepositsAndTaxReturnsRedFlag, params?.comparingCashDepositsAndTaxReturnsRedFlag],
      [
        'Is activity within variance?',
        data.comparingCashDepositsAndTaxReturnsVariance,
        params?.comparingCashDepositsAndTaxReturnsVariance
      ],
      [
        'Comments:',
        data.comparingCashDepositsAndTaxReturnsComments,
        params?.comparingCashDepositsAndTaxReturnsComments
      ],
      [
        'Financial statements provided by the business to the financial institution are inconsistent with actual account activity.',
        data.inconsistentFinancialStatement,
        params?.inconsistentFinancialStatement
      ],
      [
        'Validate contributions, distributions, loans, etc. on financial statement.',
        data.validateContributions_dump,
        params?.validateContributions_dump
      ],
      ['Red Flag?', data.financialStatementValidationRedFlag, params?.financialStatementValidationRedFlag],
      [
        'Is there activity to validate?',
        data.financialStatementValidationVariance,
        params?.financialStatementValidationVariance
      ],
      ['Comments:', data.financialStatementValidationComments, params?.financialStatementValidationComments],
      [
        'A customer seeks to conceal or disguise involvement in marijuana-related business activity. ' +
          '(For example, the customer may be using a business with a non-descript name ' +
          '(e.g., a “consulting”, “holding”, or “management” company) that purports to engage in commercial ' +
          'activity unrelated to marijuana, but is depositing cash that smells like marijuana.)',
        data.disguisingMRBActivity,
        params?.disguisingMRBActivity
      ],
      [
        'A marijuana-related business purporting to be a “non-profit” is engaged in commercial activity ' +
          'inconsistent with that classification, or is making excessive payments to its manager(s) or employee(s).',
        data.inconsistentMRBPurporting,
        params?.inconsistentMRBPurporting
      ],
      [
        'Due diligence is completed during the account opening process.',
        data.inconsistentMRBPurportingDueDiligenceCompleted,
        params?.inconsistentMRBPurportingDueDiligenceCompleted
      ],
      [
        'The owner(s) or manager(s) of a marijuana-related business reside outside the state in which the business is located.',
        data.ownerOutsideState,
        params?.ownerOutsideState
      ],
      [
        'Due diligence is completed during the account opening process.  ' +
          'The MED and CB&T Board of Directors have approved out-of-state ownership for cannabis companies.',
        data.ownerOutsideStateApproved,
        params?.ownerOutsideStateApproved
      ],
      [
        'Review of publicly available sources and databases about the business, ' +
          'its owner(s), manager(s), or other related parties, reveal negative information, ' +
          'such as criminal record, involvement in the illegal purchase or sale of drugs, violence, ' +
          'or other potential connections to illicit activity.',
        data.adverseMediaRequired,
        params?.adverseMediaRequired
      ],
      [
        'Public sources are searched by Orbit and reviewed weekly by a BSA Analyst/Officer.',
        data.adverseMediaReviewed,
        params?.adverseMediaReviewed
      ],
      [
        'A marijuana-related business is located on federal property or the marijuana ' +
          'sold by the business was grown on federal property.',
        data.mrbOnFederalProperty,
        params?.mrbOnFederalProperty
      ],
      [
        'A marijuana-related business’ proximity to a school is not compliant with state law.',
        data.mrbSchoolProximityConsistent,
        params?.mrbSchoolProximityConsistent
      ],
      [
        'Due diligence is completed by the state in which the company operates at the time of license issuance.',
        data.mrbSchoolProximityDueDiligenceCompleted,
        params?.mrbSchoolProximityDueDiligenceCompleted
      ]
    ].filter(Boolean)
  }
]

export const annualReviewCBTQuestionnaireQuestions = (data) =>
  annualReviewCBTQuestionnaireMapping({
    hasMoreRevenue: '',
    revenueFromMJOnly: '',
    informationForMETRC_dump: '',
    comparingSICSAndBankDepositsRedFlag: data.comparing_sics_and_bank_deposits_red_flag,
    comparingSICSAndBankDepositsVariance: data.comparing_sics_and_bank_deposits_variance,
    comparingSICSAndBankDepositsComments: data.comparing_sics_and_bank_deposits_comments,
    hasMoreCashDeposits: '',
    compareCashAmount_dump: '',
    comparingCashDepositsAndTaxReturnsRedFlag: data.comparing_cash_deposits_and_tax_returns_red_flag,
    comparingCashDepositsAndTaxReturnsVariance: data.comparing_cash_deposits_and_tax_returns_variance,
    comparingCashDepositsAndTaxReturnsComments: data.comparing_cash_deposits_and_tax_returns_comments,
    inconsistentFinancialStatement: '',
    validateContributions_dump: '',
    financialStatementValidationRedFlag: data.financial_statement_validation_red_flag,
    financialStatementValidationVariance: data.financial_statement_validation_variance,
    financialStatementValidationComments: data.financial_statement_validation_comments,
    disguisingMRBActivity: '',
    inconsistentMRBPurporting: '',
    inconsistentMRBPurportingDueDiligenceCompleted: '',
    ownerOutsideState: '',
    ownerOutsideStateApproved: '',
    adverseMediaRequired: '',
    adverseMediaReviewed: '',
    mrbOnFederalProperty: '',
    mrbSchoolProximityConsistent: '',
    mrbSchoolProximityDueDiligenceCompleted: ''
  })
