import styled from 'styled-components'

export const Box = styled.div`
  padding: 27px 42px;
`

export const SentHeader = styled.b`
  display: block;
  font-size: 18px;
  font-weight: 400;
  margin: 10px 0 21px;
`
