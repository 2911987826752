import { Company } from '../company'

export class CompanyManageFormValue {
  id: number | null
  name = null
  legalName: string | null
  dba: string | null
  customerStatus: string | null
  businessType: string | null
  entityType: string | null
  description: string | null
  stateFounded: string | null
  dateFounded: string | null
  isHolding = false
  phone: string | null
  website: string | null
  tags: string | string[] | null
  ein: string | null
  street: string | null
  city: string | null
  state: string | null
  postalCode: string | null
  country = 'US'
  mailingStreet: string | null
  mailingCity: string | null
  mailingState: string | null
  mailingPostalCode: string | null
  mailingСountry: string | null
  holdingGroupCompaniesIds: number[] | null

  constructor(company?: Company) {
    if (company) {
      this.id = company.id
      this.name = company.name
      this.legalName = company.legal_name
      this.dba = company.dba
      this.customerStatus = company.customer_status
      this.businessType = company.business_type
      this.entityType = company.entity_type
      this.description = company.description
      this.stateFounded = company.stateFounded
      this.dateFounded = company.dateFounded
      this.isHolding = company.is_holding
      this.phone = company.phone
      this.website = company.website
      this.ein = company.ein
      this.street = company.street
      this.city = company.city
      this.state = company.state
      this.postalCode = company.postal_code
      this.country = company.country
      this.mailingStreet = company.mailing_street
      this.mailingCity = company.mailing_city
      this.mailingState = company.mailing_state
      this.mailingPostalCode = company.mailing_postal_code
      this.mailingСountry = company.mailing_country
      this.holdingGroupCompaniesIds = company.meta.holding_group_companies?.map((company) => company.id) || null
      if (company.tags && typeof company.tags === 'string') {
        this.tags = company.tags?.split(';')
      } else {
        this.tags = []
      }
    }
  }
}
