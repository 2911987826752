import React, { FC, memo } from 'react'

import { Form } from 'brief-form'
import { SearchInput } from 'ui/components'
import { Select } from 'ui/components/Select'
import { FilterPanel } from 'ui/components/Table'
import { TableFilterRow } from 'ui/temp/TableFilterRow'

import { FilterProps } from './FilterProps'

import * as SC from './styles'

export const Filter: FC<FilterProps> = memo((props) => {
  const { filterForm, onClear, onToggle, isExpanded, inApplicationProcessOnlyValues } = props
  const { Field } = filterForm

  return (
    <FilterPanel expanded={isExpanded} onToggle={onToggle} isDirty={filterForm.isDirty} onReset={onClear}>
      <SC.FormWrapper>
        <TableFilterRow>
          <Form config={filterForm.config}>
            <SC.FilterWrapper>
              <SC.ItemWrapper>
                <Field name="search" label="Search" input={SearchInput} inputProps={{}} />
              </SC.ItemWrapper>
              <SC.ItemWrapper>
                <Field
                  name="inApplicationProcessOnly"
                  label="In Application Process Only"
                  input={Select}
                  inputProps={{ data: inApplicationProcessOnlyValues || [] }}
                />
              </SC.ItemWrapper>
            </SC.FilterWrapper>
          </Form>
        </TableFilterRow>
      </SC.FormWrapper>
    </FilterPanel>
  )
})
