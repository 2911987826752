import React, { FC, memo } from 'react'

import { Pagination, Spinner, SpinnerSize } from 'ui/components'
import { Page } from 'ui/components/Page'

import { Actions, ConnectionsListTable, CreateModal, NoConnections } from './components'
import { useConnectionsList } from './hooks/useConnectionsList'

import * as SC from './styles'

export const ConnectionsList: FC = memo(() => {
  const {
    tableState,
    webPageData,
    isFetching,
    onSetWebPagePagination,
    onSetWebPageQuery,
    invalidateTable,
    pagination,
    isSetupKeyDialogOpen,
    openSetupKeyDialog,
    closeSetupKeyDialog
  } = useConnectionsList()

  if (!webPageData) {
    return <Spinner centered size={SpinnerSize.M} />
  }

  return (
    <Page
      title="Connections"
      actions={
        <Actions
          onSearchTermChange={(searchWord) => onSetWebPageQuery(searchWord)}
          onOpenCreateModal={openSetupKeyDialog}
          searchTerm={tableState.filterForm.config.value.connectionsSearchQuery}
        />
      }
      footer={
        <SC.PaginationWrapper>
          <Pagination
            withPageSize
            page={tableState.page}
            pageSize={tableState.size}
            total={pagination.connectionsTotalCount}
            onPageChange={onSetWebPagePagination}
          />
        </SC.PaginationWrapper>
      }
    >
      <CreateModal
        open={isSetupKeyDialogOpen}
        closeSetupKeyDialog={closeSetupKeyDialog}
        invalidateTable={invalidateTable}
      />
      {webPageData.connections.length ? (
        <ConnectionsListTable isFetching={isFetching} connections={webPageData.connections} />
      ) : (
        <NoConnections />
      )}
    </Page>
  )
})

export default ConnectionsList
