import styled from 'styled-components'

export const Wrapper = styled.div`
  margin: 40px 0 0 0;
`

export const Header = styled.div`
  background-color: #e2e2e2;
  margin: 0 -50px 30px -50px;
  padding: 20px 50px;
  font-size: 22px;
`

export const Title = styled.b`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  color: #7b7b7b;
  margin: 30px 0 14px 0;
  display: block;
`

export const Notes = styled.span`
  font-size: 14px;
  line-height: 20px;
`
