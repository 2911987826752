/*
    single entry point to localStorage stuff
*/

enum LocalStorageKeys {
  SESSION_EXPIRES_AT_KEY = 'expiresAt'
}

const setSessionExpiresAt = (expiresAt?: string | undefined): void => {
  if (!expiresAt) {
    localStorage.removeItem(LocalStorageKeys.SESSION_EXPIRES_AT_KEY)
    return
  }
  localStorage.setItem(LocalStorageKeys.SESSION_EXPIRES_AT_KEY, expiresAt)
}

const getSessionExpiresAt = (): string | void => {
  const expiresAt = localStorage.getItem(LocalStorageKeys.SESSION_EXPIRES_AT_KEY)
  if (!expiresAt) {
    return
  }
  return expiresAt
}

export const localStorageService = {
  getSessionExpiresAt,
  setSessionExpiresAt
}
