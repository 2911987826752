import { Link } from '@tanstack/react-router'
import styled from 'styled-components'

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
`

export const LogInLink = styled(Link)`
  text-decoration: none;
  color: #fff;
  font-size: 12px;

  &:visited {
    color: #fff;
  }
`

export const LoginCol = styled.div`
  width: 314px;
`
