import {
  ContactAdditionalInformation,
  ContactDetails,
  RoleInformation,
  WebPageContactItem
} from 'commons/types/DTO/commons'

import { ContactAdditionalInformationFormData, ContactItemFormValue } from '../../../../types/forms'

export const emptyContactDetails: ContactDetails = {
  contactID: 0,
  entityUID: '',
  contactMetadataID: 0,
  lastName: '',
  firstName: '',
  email: '',
  phone: '',
  mobile: '',
  homePhone: '',
  title: '',
  otherMJRelatedBusiness: '',
  bio: ''
}

export const emptyContactRoleInformation: RoleInformation = {
  accountSigner: 0,
  conductor: 0,
  debtholder: 0,
  owner: 0
}

export const emptyContactAdditionalInformation: ContactAdditionalInformation = {
  debtholderDebtAmount: null,
  debtholderPaymentFrequency: '',
  debtholderPaymentAmount: null,
  debtholderMaturityDate: '',
  ownerInvestmentAmount: null,
  ownerOwnershipPercent: null,
  birthdate: '',
  EINSSN: '',
  street: '',
  city: '',
  state: '',
  country: 'US',
  postalCode: '',
  mailingStreet: '',
  mailingCity: '',
  mailingState: '',
  mailingCountry: 'US',
  mailingPostalCode: '',
  USCitizen: 0
}

const getEmptyContactDetails = ({ contact }: { contact: ContactDetails }) => ({
  formValues: {
    contactID: contact.contactID,
    entityUID: contact.entityUID,
    contactMetadataID: contact.contactMetadataID,
    lastName: contact.lastName,
    firstName: contact.firstName,
    email: contact.email,
    phone: contact.phone,
    mobile: contact.mobile,
    homePhone: contact.homePhone,
    title: contact.title,
    otherMJRelatedBusiness: contact.otherMJRelatedBusiness,
    bio: contact.bio
  },
  formErrors: {}
})

const getEmptyContactRoleInformation = ({ contact }: { contact: RoleInformation }) => ({
  formValues: {
    accountSigner: contact.accountSigner,
    conductor: contact.conductor,
    debtholder: contact.debtholder,
    owner: contact.owner
  },
  formErrors: {}
})

const getEmptyContactAdditionalInformation = ({
  contact
}: {
  contact: ContactAdditionalInformation
}): ContactAdditionalInformationFormData => ({
  formValues: {
    // https://helioscompliance.atlassian.net/browse/MP-6560
    debtholderDebtAmount: contact.debtholderDebtAmount,
    debtholderPaymentFrequency: contact.debtholderPaymentFrequency,
    // https://helioscompliance.atlassian.net/browse/MP-6560
    debtholderPaymentAmount: contact.debtholderPaymentAmount,
    debtholderMaturityDate: contact.debtholderMaturityDate,
    // https://helioscompliance.atlassian.net/browse/MP-6560
    ownerInvestmentAmount: contact.ownerInvestmentAmount,
    // https://helioscompliance.atlassian.net/browse/MP-6560
    ownerOwnershipPercent: contact.ownerOwnershipPercent,
    birthdate: contact.birthdate,
    EINSSN: contact.EINSSN,
    street: contact.street,
    city: contact.city,
    state: contact.state,
    country: contact.country,
    postalCode: contact.postalCode,
    mailingStreet: contact.mailingStreet,
    mailingCity: contact.mailingCity,
    mailingState: contact.mailingState,
    mailingCountry: contact.mailingCountry,
    mailingPostalCode: contact.mailingPostalCode,
    USCitizen: contact.USCitizen
  },
  formErrors: {}
})

export const getEmptyContact = ({ contact }: { contact: WebPageContactItem }): ContactItemFormValue => ({
  details: getEmptyContactDetails({ contact }),
  roleInformation: getEmptyContactRoleInformation({ contact }),
  contactAdditionalInformation: getEmptyContactAdditionalInformation({ contact })
})
