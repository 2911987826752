import React from 'react'

import { gender } from 'commons/types/dictionaries'
import { FF, Select } from 'ui/components'

import { useSARFormContext } from '../../../../../../../../context'
import { useSubjectInformationItemContext } from '../../../SubjectInformationItemContext'

const EMPTY_OPTION = { label: 'Not selected', value: '' }

type GenderProps = {
  index: number
}

export const Gender = ({ index }: GenderProps) => {
  const { formApi } = useSARFormContext()
  const { isAllInfoUnavailable } = useSubjectInformationItemContext()

  return (
    <formApi.Field
      name={`subjects_info[${index}].gender` as 'subjects_info[0].gender'}
      children={(field) => (
        <FF
          field={field}
          label="8. Gender"
          input={(p) => <Select {...p} data={gender} emptyOption={EMPTY_OPTION} disabled={isAllInfoUnavailable} />}
        />
      )}
    />
  )
}
