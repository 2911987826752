import SC, { css } from 'styled-components'
import { Icon } from 'ui/components/Icon'

const BoxCss = css`
  background: ${(p) => p.theme.uiKit.colors.theme.negative.transparent.opacity12};
  padding: 12px 12px 20px 12px;
  border-radius: 8px;
`

const WarningIconCss = css`
  margin-right: 10px;
`

const TitleCss = css<{ $weight: number }>`
  color: ${(p) => p.theme.uiKit.colors.theme.negative.main};
  display: flex;
  align-items: center;
  font-size: 13px;
  line-height: 16px;
  font-weight: ${(p) => p.$weight};
`

const ListCss = css`
  font-size: 14px;
  line-height: 22px;
  margin: 14px 0 0 0;
  padding: 0 0 0 22px;
  color: ${(p) => p.theme.uiKit.colors.theme.negative.main};
`

export const Box = SC.div`${BoxCss}`

export const WarningIcon = SC(Icon)`${WarningIconCss}`

export const Title = SC.span<{ $weight: number }>`${TitleCss}`

export const List = SC.ol`${ListCss}`

export const ResolveButton = SC.button`
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  background: transparent;
  border-radius: 3px;
  color: ${(p) => p.theme.uiKit.colors.theme.negative.main};
  border: 2px solid ${(p) => p.theme.uiKit.colors.theme.negative.main};
  margin-top: 21px;
  cursor: pointer;
  padding: 1px 6px;
`
