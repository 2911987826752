import React, { FC, memo } from 'react'

import { Dialog, DialogFace, DialogFooterActions } from 'ui/components/Dialog'
import { LeaveConfirmation } from 'ui/components/LeaveConfirmation/LeaveConfirmation'

import { ManageAffiliatedCompanyProps } from './ManageAffiliatedCompanyProps'
import { ManageAffiliatedCompanyForm } from './components/ManageAffiliatedCompanyForm'
import { useComponentEssence } from './hooks'

import { Spacer } from './styles'

export const ManageAffiliatedCompany: FC<ManageAffiliatedCompanyProps> = memo((props) => {
  const { onClose, onSubmit, initialValue } = props

  if (!initialValue) {
    return null
  }

  const { config, Field, onSubmitWrapper, validateAffiliated, isDirty, isSubmitting } = useComponentEssence(
    initialValue,
    onSubmit
  )

  return (
    <LeaveConfirmation preventLeaving={!isSubmitting && isDirty} ignoreUrlHashChanging={true} onLeave={onClose}>
      <Dialog
        id="manage-affiliated"
        onClose={onClose}
        onSubmit={onSubmitWrapper}
        isSubmitting={isSubmitting}
        face={DialogFace.PRIMARY}
        title="Manage Affiliation"
        footerActions={[DialogFooterActions.SAVE, DialogFooterActions.CANCEL]}
      >
        <Spacer>
          <ManageAffiliatedCompanyForm config={config} Field={Field} validateAffiliated={validateAffiliated} />
        </Spacer>
      </Dialog>
    </LeaveConfirmation>
  )
})
