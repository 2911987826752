import React, { FC, memo } from 'react'

import { sanitize } from 'dompurify'
import { DateTime } from 'ui/components/DateTime'
import { Dialog, DialogFace } from 'ui/components/Dialog'
import { Spinner, SpinnerSize } from 'ui/components/Spinner'

import { highlight } from '../../helpers'
import { PublicationDialogProps } from './PublicationDialogProps'

import * as SC from './styles'

export const PublicationDialog: FC<PublicationDialogProps> = memo(({ publication, onClose, searchCase }) => {
  if (!publication || publication.content_type === 'url') {
    return null
  }

  const {
    copyright_notice: notice,
    copyright_holder: holder,
    publication_date: publicationDate,
    title,
    content
  } = publication

  const highlightedContent = (searchCase && content && highlight(content, searchCase)) || ''

  return (
    <Dialog
      face={DialogFace.PRIMARY}
      id="search-term-publication-dialog"
      onClose={onClose}
      title={<SC.Title>{title}</SC.Title>}
    >
      {!publication.content && <Spinner size={SpinnerSize.S} centered />}
      {publication.content && (
        <SC.Content>
          <div dangerouslySetInnerHTML={{ __html: sanitize(highlightedContent) as string }} />
          <SC.Footer>
            {publicationDate && (
              <SC.InfoRow>
                Published: <DateTime date={publicationDate} />
              </SC.InfoRow>
            )}
            {holder && <SC.InfoRow>Holder: {holder}</SC.InfoRow>}
            {notice && <SC.InfoRow>Notice: {notice}</SC.InfoRow>}
          </SC.Footer>
        </SC.Content>
      )}
    </Dialog>
  )
})
