import React, { FC } from 'react'

import { IconName, LabelBadge } from 'ui/components'
import { ButtonGroup } from 'ui/components/ButtonGroup'
import { OutlineButton } from 'ui/components/Buttons'

import * as SC from './styles'

const AddButton: FC<{
  state: {
    isDisabledAddAccountButton: boolean
    isDisabledAddButtonHint: boolean
    addTooltipContent: string
    showErrors: boolean
    addBankAccount: () => void
  }
}> = ({
  state: { isDisabledAddAccountButton, isDisabledAddButtonHint, addTooltipContent, showErrors, addBankAccount }
}) => (
  <SC.AddBtnSection>
    <ButtonGroup margin="small">
      <OutlineButton
        size="middle"
        face={showErrors ? 'negative' : 'positive'}
        disabled={isDisabledAddAccountButton}
        onClick={addBankAccount}
      >
        Add
      </OutlineButton>

      <LabelBadge
        disabled={isDisabledAddButtonHint}
        text={showErrors ? <SC.Red>{addTooltipContent}</SC.Red> : addTooltipContent}
        icon={{ enabled: IconName.INFO_GREEN, disabled: showErrors ? IconName.ERROR_RED : IconName.INFO }}
      />
    </ButtonGroup>
  </SC.AddBtnSection>
)
export default AddButton
