import { useCallback } from 'react'

import { handlerServerError } from 'errors'
import { WriteHook, useMutationManager, useStateManager } from 'state-manager'

import { deleteReportFile } from './DeleteReportFileRequest'

export const useDeleteReportFileRequest: WriteHook<number, unknown> = (id) =>
  useMutationManager<number, unknown>(deleteReportFile, id)

export const useDeleteReportFile = (tableKey: string, invalidate: () => Promise<void>, reportQuarterlyId?: number) => {
  const sm = useStateManager()
  const queryClient = sm.__client__

  const { routine } = useDeleteReportFileRequest({
    onSuccess: async () => {
      if (reportQuarterlyId) invalidate()
      else queryClient.invalidateQueries(['tables', `uploaded-files-${tableKey}`])
    },
    onError: (error) => handlerServerError(error)
  })

  const deleteReportFile = useCallback((id: number) => routine(id), [routine])

  return {
    deleteReportFile
  }
}
