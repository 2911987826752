import { ContactMetadata } from './contactMetadata'

export type Contact = {
  bio: string
  birthdate: string | null
  city: string
  contact_metadata: ContactMetadata[]
  country: string
  created_at: string | null
  deleted_at: string | null
  ein_ssn: string
  email: string
  entity_name: string
  first_name: string
  id: number
  last_name: string
  mobile_phone: string
  organization_id: number
  phone: string
  sf_external_id: string | null
  state: string
  street: string
  updated_at: string | null
  zip_code: string
}
