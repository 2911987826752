import React, { FC, memo } from 'react'

import { Form } from 'brief-form'
import { CompanySelect, Select } from 'ui/components/Select'
import { FilterPanel } from 'ui/components/Table'
import { FilterWrapper } from 'ui/temp/Filter'
import { TableFilterRow } from 'ui/temp/TableFilterRow'

import { FilterProps } from './filterProps'

import * as SC from './styles'

export const Filter: FC<FilterProps> = memo((props) => {
  const { isDirty, isExpanded, onClear, onToggle, form, webPage } = props
  const { config, Field } = form

  return (
    <FilterPanel isDirty={isDirty} onReset={onClear} expanded={isExpanded} onToggle={onToggle}>
      <FilterWrapper>
        <Form config={config}>
          <TableFilterRow>
            {webPage.uploadTypeOptions.length > 1 && (
              <SC.ItemWrapper>
                <Field
                  name="type"
                  label="Type"
                  input={Select}
                  inputProps={{
                    data: webPage.uploadTypeOptions
                  }}
                />
              </SC.ItemWrapper>
            )}
            <SC.ItemWrapper>
              <Field
                name="company_id"
                label="Relationship"
                input={CompanySelect}
                inputProps={{
                  hideMyCompaniesFilter: true,
                  preloadedOptions: webPage.relationshipOptions,
                  'data-qa': 'Relationships Type',
                  'options-data-qa': 'Relationships'
                }}
              />
            </SC.ItemWrapper>
          </TableFilterRow>
        </Form>
      </FilterWrapper>
    </FilterPanel>
  )
})
