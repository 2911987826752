import { useCallback } from 'react'

import { FormChangedHandler, useFormData } from 'brief-form'

import { FormPaths } from '../../enums'
import { useSyncFormData } from '../../hooks'
import { useValidateFormPersist } from '../../hooks/useValidateFormPersist'
import {
  ContactAdditionalInformationChangeHandler,
  ContactAdditionalInformationFormData,
  ContactAdditionalInformationFormValue
} from '../../types/forms'

export const useContactAdditionalInformationForm = ({
  contactIndex,
  companyIndex,
  formData,
  onChange
}: {
  contactIndex: number
  companyIndex: number
  formData: ContactAdditionalInformationFormData
  onChange: ContactAdditionalInformationChangeHandler
}) => {
  const onFormChanged: FormChangedHandler<ContactAdditionalInformationFormValue> = useCallback(
    (formValues, formErrors) => onChange({ companyIndex, formData: { formValues, formErrors }, contactIndex }),
    [onChange, contactIndex, companyIndex]
  )

  const { config, Field, set, validate } = useFormData<ContactAdditionalInformationFormValue>({
    onFormChanged,
    initialValue: formData.formValues,
    initialErrors: formData.formErrors
  })

  useSyncFormData({ set, formData })
  const formKey = `${FormPaths.CONTACT_ADDITIONAL_INFORMATION}-${companyIndex}-${contactIndex}`
  useValidateFormPersist<ContactAdditionalInformationFormValue>({
    formName: formKey,
    validate,
    config
  })
  return { Field, config }
}
