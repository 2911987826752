import React from 'react'

import { CheckBox, FF } from 'ui/components'

import { useSARFormContext } from '../../../../../../context'

type IsAllInfoUnavailableProps = {
  index: number
}

export const IsAllInfoUnavailable = ({ index }: IsAllInfoUnavailableProps) => {
  const { formApi } = useSARFormContext()
  return (
    <formApi.Field
      name={`subjects_info[${index}].is_all_info_unavailable` as 'subjects_info[0].is_all_info_unavailable'}
      children={(field) => (
        <FF
          field={field}
          input={(p) => <CheckBox {...p} checkBoxLabel="if all critical* subject information is unavailable" />}
        />
      )}
    />
  )
}
