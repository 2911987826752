import * as React from 'react'

import { Request } from 'commons/utils/request'
import { ChoiceOption, ChoiceValue } from 'ui/types'

import { SingleSelectAutoSuggest } from './SingleSelectAutoSuggest'

interface Props {
  value: ChoiceValue
  onChange: (value: ChoiceValue) => any
  searchable?: boolean
  className?: string
  filters?: { field: string; type: string; value: ChoiceValue }[]
  emptyOption?: ChoiceOption
  disabled?: boolean
}

const defaultProps: Partial<Props> = {
  searchable: true
}

export const EventCategorySelect = React.memo((props: Props) => {
  const { value, onChange, searchable, className, filters, disabled, ...rest } = {
    ...defaultProps,
    ...props
  }

  return (
    <SingleSelectAutoSuggest
      {...rest}
      value={value}
      onChange={onChange}
      className={className}
      disabled={disabled}
      searchable={searchable}
      serverOptions={{
        filters,
        valueItemFilter: value ? { field: 'id', type: 'eq', value } : undefined,
        url: Request.urls.calendar.categoryList,
        limit: 50,
        mapper: (item) => ({ label: item.name, value: item.id }),
        searchFields: ['name']
      }}
    />
  )
})
