import { useCallback, useState } from 'react'

type UseComponentEssence = {
  initialFiles: File[]
  onSubmit: (files: File[]) => void
  onClose: () => void
  showDialogAfterConfirm?: boolean
}

export const useComponentEssence = ({
  initialFiles,
  onClose,
  onSubmit,
  showDialogAfterConfirm
}: UseComponentEssence) => {
  const [files, setFiles] = useState(initialFiles.map((file, index) => ({ file, uid: Date.now() + index })))

  const addFiles = useCallback(
    (newFiles: File[]) => {
      setFiles(
        files.concat(
          newFiles.map((file, index) => ({
            file,
            uid: Date.now() + index
          }))
        )
      )
    },
    [setFiles, files]
  )

  const deleteFile = useCallback(
    (uid: number) => {
      const index = files.findIndex((file) => file.uid === uid)
      if (index !== -1) {
        const copy = [...files]
        copy.splice(index, 1)
        setFiles(copy)
      }
    },
    [setFiles, files]
  )

  const submitHandler = useCallback(() => {
    onSubmit(files.map((file) => file.file))
    !showDialogAfterConfirm && onClose()
  }, [onSubmit, onClose, files, showDialogAfterConfirm])

  return {
    files,
    addFiles,
    deleteFile,
    submitHandler
  }
}
