import React from 'react'

import { FF, SecuredInput, Validators } from 'ui/components'

import { useSARFormContext } from '../../../../../../context'

type AccountNumberProps = {
  index: number
}

export const AccountNumber = ({ index }: AccountNumberProps) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={`transactions_info.by_account[${index}].account_number` as 'transactions_info.by_account[0].account_number'}
      validators={{ onChange: ({ value }) => Validators.required.notEmpty(value) }}
      children={(field) => (
        <FF
          required
          field={field}
          label="Bank Account Number"
          input={(p) => <SecuredInput {...p} data-lpignore={true} data-qa="Bank Account Number" />}
        />
      )}
    />
  )
}
