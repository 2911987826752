export const apiUrls = {
  bank: {
    application: '/api/bank/v1/application',
    applicationRevert: '/api/bank/v1/application/revert-to-rfi',
    applicationList: '/api/bank/v1/application-list',
    applicationListWebpages: '/api/bank/v1/webpages/application-list',
    applicationRelatedRecords: '/api/bank/v1/application-related-records',
    applicationAssignOwner: '/api/bank/v1/application/assign-owner',

    applicationClientDocument: '/api/bank/v1/application-client-document',
    applicationClientDocumentAccept: '/api/bank/v1/application-client-document/accept',
    applicationClientDocumentReject: '/api/bank/v1/application-client-document/reject',
    applicationClientDocumentFile: '/api/bank/v1/application-client-document-file',
    applicationClientDocumentWebpage: '/api/bank/v1/webpages/application-client-document-details',
    applicationClientDocumentCreateWebpage: '/api/bank/v1/webpages/application-client-document-create-mode',
    applicationClientDocumentEditWebpage: '/api/bank/v1/webpages/application-client-document-edit-mode',
    applicationClientDocumentFileDirectDownload: '/api/bank/v1/application-client-document-file-download',
    applicationClientDocumentFileUpload: '/api/bank/v1/application-client-document/file-upload',

    applicationDocument: '/api/bank/v1/application-document',
    applicationDocumentUpload: '/api/bank/v1/application-document-upload',

    applicationLink: '/api/bank/v1/application-link',
    applicationLinkSend: '/api/bank/v1/application-link-send',
    applicationDetailsViewWebpages: '/api/bank/v1/webpages/application-details-view',

    applicationDocumentDirectDownload: '/api/bank/v1/application-document-direct-download',
    applicationZipDocumentsDownload: '/api/bank/v1/application-zip-documents-download',

    applicationWebPage: '/api/bank/v1/application-get-rfi-form',
    applicationSave: '/api/bank/v1/application-rfi-form',
    applicationSubmitRFI: '/api/bank/v1/application-submit-rfi-form',
    applicationSubmitDocumentRequest: '/api/bank/v1/application/submit-document-request',

    applicationBankAccounts: '/api/bank/v1/webpages/onboarding-bank-accounts',
    applicationSaveBankAccount: '/api/bank/v1/application/save-bank-accounts',
    applicationFinalization: '/api/bank/v1/application/finalize',

    adverseMediaCase: '/api/bank/v1/adverse-media-case',
    adverseMediaCaseList: '/api/bank/v1/adverse-media-case-list',
    companiesAdverseMediaCaseList: '/api/bank/v1/companies-adverse-media-case-list',
    adverseMediaListWebPage: '/api/bank/v1/webpages/adverse-media-list',
    adverseMediaPublicationContent: '/api/bank/v1/adverse-media-publication-content',
    adverseMediaCaseClearUnreviewedPublications: '/api/bank/v1/adverse-media-case/clear-unreviewed-publications',
    adverseMediaPublicationList: '/api/bank/v1/adverse-media-publication-list',
    adverseMediaPublication: '/api/bank/v1/adverse-media-publication',
    adverseMediaCaseSmartFilter: '/api/bank/v1/adverse-media-case-smart-filter',

    alertsView: '/api/bank/v1/webpages/alerts-view',

    affiliatedCompany: '/api/bank/v1/affiliated-company',
    affiliatedCompanyList: '/api/bank/v1/affiliated-company-list',

    annualReview: '/api/bank/v1/annual-review',
    annualReviewList: '/api/bank/v1/annual-review-list',
    annualReviewSalesDeposits: '/api/bank/v1/annual-review-sales-deposits',
    annualReviewSalesDepositsList: '/api/bank/v1/annual-review-sales-deposits-list',
    annualReviewSalesDepositsRecalculation: '/api/bank/v1/annual-review-sales-deposits-recalculation',

    authLogin: '/api/bank/auth/login',
    authLogout: '/api/bank/auth/logout',
    authPassword: '/api/bank/auth/password',
    authPasswordReset: '/api/bank/auth/password-reset',
    authNextcloudCode: '/api/bank/auth/nextcloud/code',
    authMfaDevice: '/api/bank/auth/mfa/device',
    authMfaDeviceDefault: '/api/bank/auth/mfa/device-default',
    authMfaChallenge: '/api/bank/auth/mfa/challenge',
    authMfaLogin: '/api/bank/auth/mfa/login',

    bankAccountList: '/api/bank/v1/bank-account-list',

    bankAccountSearch: '/api/bank/v1//bank-account-search',

    bankUserListWebpages: '/api/bank/v1/webpages/bank-user-list',

    calendarList: '/api/bank/v1/calendar-list',

    clientUser: '/api/bank/v1/client-user',
    clientUserCompanies: '/api/bank/v1/client-user-companies',

    cifSubjectList: '/api/bank/v1/cif-subject-list',

    company: '/api/bank/v1/company',
    companyList: '/api/bank/v1/company-list',
    companyListWebpages: '/api/bank/v1/webpages/relationship-list',
    companyUsersAssignment: '/api/bank/v1/company-users-assignment',

    companyDetailsWebpage: '/api/bank/v1/webpages/relationship-details',

    companyAdverseMediaWebpage: '/api/bank/v1/webpages/relationship-details/adverse-media',
    companyDocumentsWebpage: '/api/bank/v1/webpages/relationship-details/documents',
    companyContactsWebpage: '/api/bank/v1/webpages/relationship-details/contacts',
    companyQuarterlyAnalyticsWebpage: '/api/bank/v1/webpages/relationship-details/quarterly-analytics',
    companyAffiliatedCompaniesWebpage: '/api/bank/v1/webpages/relationship-details/affiliated-companies',
    companyLicensesWebpage: '/api/bank/v1/webpages/relationship-details/licenses',
    companyCustomersWebpage: '/api/bank/v1/webpages/relationship-details/customers',
    companyAnnualReviewsWebpage: '/api/bank/v1/webpages/relationship-details/annual-reviews',
    companyBankAccountsWebpage: '/api/bank/v1/webpages/relationship-details/bank-accounts',
    companyVendorsWebpage: '/api/bank/v1/webpages/relationship-details/vendors',
    companyHistoryDetailsWebpage: '/api/bank/v1/webpages/relationship-details/activity-history',

    companySearch: '/api/bank/v1/company-search',

    connectionsListWebpages: '/api/bank/v1/webpages/connection-list',
    connectionsKeySetup: '/api/bank/v1/metrc-credentials',

    contact: '/api/bank/v1/contact',
    contactList: '/api/bank/v1/contact-list',
    contactDisconnect: '/api/bank/v1/contact-disconnect',
    contactSearch: '/api/bank/v1/contact-search',
    contactListWebPage: '/api/bank/v1/webpages/contact-list',
    contactDetailsWebPage: '/api/bank/v1/webpages/contact-details',

    bsaReportingPage: '/api/bank/v1/webpages/bsa-reporting',
    bsaBatch: '/api/bank/v1/bsa/batch',
    bsaCtrUploadBatchFile: '/api/bank/v1/bsa/ctr/upload-batch-file',
    batchList: '/api/bank/v1/bsa/batch-list',
    bsaBatchDetailsWebPage: '/api/bank/v1/webpages/bsa-batch-details',
    batchFileDownload: '/api/bank/v1/bsa/batch-file-download',
    bsaCtr: '/api/bank/v1/bsa/ctr',
    bsaCtrValidate: '/api/bank/v1/bsa/ctr/validate',
    bsaCtrFormBatch: '/api/bank/v1/bsa/ctr/form-batch',
    bsaCtrForm: '/api/bank/v1/bsa/ctr-form',
    bsaCtrApprove: '/api/bank/v1/bsa/ctr/approve',
    bsaCtrCancel: '/api/bank/v1/bsa/ctr/cancel',
    bsaSarTransactionsXls: '/api/bank/v1/bsa/sar-transactions-xls',

    sar: '/api/bank/v1/bsa/sar',
    sarList: '/api/bank/v1/bsa/sar-list',
    sarFormBatch: '/api/bank/v1/bsa/sar/form-batch',
    sarBatchListWebPage: '/api/bank/v1/webpages/bsa-sar-batch-list',
    bsaSarTransactionsZip: '/v1/bsa/sar-transactions-zip',
    editSarPeriod: '/api/bank/v1/bsa/sar-period',
    bsaSarApprove: '/api/bank/v1/bsa/sar/approve',

    clientPortalUsersActivityReport: '/api/bank/v1/webpages/companies-with-client-users',
    portalReportCompaniesCSV: '/api/bank/v1/portal-report/companies-with-client-users-csv',
    portalReportLicenses: '/api/bank/v1/portal-report/licenses-with-sics-status',
    portalReportLicensesCSV: '/api/bank/v1/portal-report/licenses-with-sics-status-csv',
    portalReportSite: '/api/bank/v1/portal-report/site-audit-documents',
    portalReportSiteCSV: '/api/bank/v1/portal-report/site-audit-documents-csv',
    portalReportDocuments: '/api/bank/v1/portal-report/document-templates',
    portalReportDocumentsCSV: '/api/bank/v1/portal-report/document-templates-csv',
    portalReportUser: '/api/bank/v1/portal-report/bank-users',
    portalReportUserCSV: '/api/bank/v1/portal-report/bank-users-csv',
    portalReportRelationships: '/api/bank/v1/portal-report/onboarding-relationships-report',
    portalReportRelationshipsCSV: '/api/bank/v1/portal-report/onboarding-relationships-report-csv',
    portalReportApplicationPipeline: '/api/bank/v1/portal-report/application-pipeline',
    portalReportApplicationPipelineCSV: '/api/bank/v1/portal-report/application-pipeline-csv',
    portalReportEmailHistory: '/api/bank/v1/portal-report/client-emails',
    portalReportEmailHistoryCSV: '/api/bank/v1/portal-report/client-emails-csv',
    portalReportAlerts: '/api/bank/v1/portal-report/documents-due',
    portalReportAlertsCSV: '/api/bank/v1/portal-report/documents-due-csv',

    reportFile: '/api/bank/v1/report/file',
    reportFileDownload: '/api/bank/v1/report/file-download',
    reportFileList: '/api/bank/v1/report/file-list',
    reportFileUpload: '/api/bank/v1/report/file-upload',

    ctrList: '/api/bank/v1/bsa/ctr-list',
    ctrListWebpage: '/api/bank/v1/webpages/bsa-ctr-list',
    ctrDetailsWebpage: '/api/bank/v1/webpages/bsa-ctr-details',
    customerList: '/api/bank/v1/customer-list',

    deviceList: '/api/bank/auth/mfa/device-list',

    /* Documents */
    document: '/api/bank/v1/document',
    documentFile: '/api/bank/v1/document-file',
    documentFileList: '/api/bank/v1/document-file-list',
    documentFileUpload: '/api/bank/v1/document-file-upload',
    documentFileDirectDownload: '/api/bank/v1/document-file-direct-download',

    documentPeriod: '/api/bank/v1/document-period',
    documentListWP: '/api/bank/v1/webpages/document-list-v2',

    documentDetailsPage: '/api/bank/v1/webpages/document-details',
    documentEditPage: '/api/bank/v1/webpages/document-edit-mode',

    manageDocumentPeriod: '/api/bank/v1/document-period-v2',
    documentPeriodDetailsPage: '/api/bank/v1/webpages/document-period-details',
    documentPeriodEditPage: '/api/bank/v1/webpages/document-period-edit-mode',
    // https://swagger.dev.helioscompliance.com/#/document-periods/putDocumentperiodaccept
    documentPeriodApproved: '/api/bank/v1/document-period-accept',
    // https://swagger.dev.helioscompliance.com/#/document-periods/putDocumentperiodincomplete
    documentPeriodIncomplete: '/api/bank/v1/document-period-incomplete',
    // https://swagger.dev.helioscompliance.com/#/document-periods/putDocumentperiodreject
    documentPeriodRejected: '/api/bank/v1/document-period-reject',

    externalLicenseList: '/api/bank/v1/external-license-list',

    heartbeat: '/api/bank/auth/heartbeat',
    historyList: '/api/bank/v1/history-list',
    webPageHistoryList: '/api/bank/v1/webpages/history-list',

    internalTransfer: '/api/bank/v1/internal-transfer',
    internalTransferCancel: '/api/bank/v1/internal-transfer-cancel',
    internalTransferList: '/api/bank/v1/internal-transfer-list',
    internalTransfersExport: '/api/bank/v1/internal-transfers-export',
    internalTransfersExportProcess: '/api/bank/v1/internal-transfers-export-process',
    internalTransfersExportList: '/api/bank/v1/internal-transfers-export-list',
    internalTransfersExportFileDirectDownload: '/api/bank/v1/internal-transfers-export-file-direct-download',
    internalTransferWebPage: '/bank/v1/webpages/internal-transfer-list',
    internalTransferDetailsWebPage: '/bank/v1/webpages/internal-transfer-details',

    invoiceSubmittal: '/api/bank/v1/invoice-submittal',
    invoiceSubmittalList: '/api/bank/v1/invoice-submittal-list',
    invoiceSubmittalFileDownload: '/api/bank/v1/invoice-submittal-file-download',
    invoiceSubmittalFileUpload: '/api/bank/v1/invoice-submittal-file-upload',
    invoiceSubmittalDetails: '/bank/v1/webpages/invoice-submittal-details',
    invoiceSubmittalEditMode: '/bank/v1/webpages/invoice-submittal-edit-mode',

    license: '/api/bank/v1/license',
    licenseDetails: '/api/bank/v1/webpages/license-details',
    licenseList: '/api/bank/v1/license-list',
    licenseSearch: '/api/bank/v1/license-search',

    organization: '/api/bank/v1/organization',
    organizationSetting: '/api/bank/v1/organization-setting',

    parsedTransactions: '/api/bank/v1/bsa/parsed-transactions',
    parsedTransactionsDownload: '/api/bank/v1/bsa/parsed-transactions/download',

    profile: '/api/bank/v1/profile',
    profilePassword: '/api/bank/v1/profile/password',

    reportQuarterly: '/api/bank/v1/report/quarterly',
    reportQuarterlyList: '/api/bank/v1/report/quarterly-list',
    reportQuarterlyAcknowledgeWarnings: '/api/bank/v1/report/quarterly-acknowledge-warnings',
    reportQuarterlyReview: '/api/bank/v1/report/quarterly-review',
    reportQuarterlySaveNotes: '/api/bank/v1/report/quarterly-save-notes',
    quarterlyAnalyticsDetailsWebpage: '/api/bank/v1/webpages/quarterly-analytics-details',
    quarterlyQuestionnaire: '/api/bank/v1/report/quarterly-questionnaire',

    reportMonthly: '/api/bank/v1/report/monthly',
    reportMonthlyList: '/api/bank/v1/report/monthly-list',
    reportMonthlyRecalculate: '/api/bank/v1/report/monthly-recalculate',
    reportMonthlyAcknowledgeWarnings: '/api/bank/v1/report/monthly-acknowledge-warnings',
    reportMonthlyReview: '/api/bank/v1/report/monthly-review',
    reportMonthlySaveNotes: '/api/bank/v1/report/monthly-save-notes',
    reportMonthlyWebPage: '/api/bank/v1/webpages/monthly-analytics-details',

    depositsDataBankSource: '/api/bank/v1/deposits-data/bank-source',

    salesDataSourceWebPage: '/api/bank/v1/webpages/sales-data-source-details',
    salesDataBankSource: '/api/bank/v1/sales-data/bank-source',
    salesDataSourceFile: '/api/bank/v1/sales-data/source-file',
    salesDataSourceFileUpload: '/api/bank/v1/sales-data/source-file-upload',
    salesDataSourceFileDownload: '/api/bank/v1/sales-data/source-file-download',
    salesDataClientSourceApprove: '/api/bank/v1/sales-data/client-source-approve',
    salesDataClientSourceReject: '/api/bank/v1/sales-data/client-source-reject',
    salesDataSicsSourceDownloadCSV: '/api/bank/v1/sales-data/sics-source-download-csv',
    salesDataSourceSaveInternalNotes: '/api/bank/v1/sales-data/source-save-internal-notes',
    salesDataSourceSaveRejectionReason: '/api/bank/v1/sales-data/source-save-rejection-reason',
    salesDataClientSourceTaskCancellation: '/api/bank/v1/sales-data/client-source-task-cancellation',
    salesDataClientSourceTaskSending: '/api/bank/v1/sales-data/client-source-task-sending',

    onboardingDocumentsCreateModeWebPage: '/api/bank/v1/webpages/onboarding-documents-create-mode',
    onboardingDocumentsEditModeWebPage: '/api/bank/v1/webpages/onboarding-documents-edit-mode',
    onboardingDocumentsCloneModeWebPage: '/api/bank/v1/webpages/onboarding-documents-clone-mode',
    createEnvelopeOnboardingDocuments: '/api/bank/v1/document-signing/create-envelope',
    editEnvelopeOnboardingDocuments: '/api/bank/v1/document-signing/update-envelope',
    onboardingDocumentsDetailsWebPage: '/api/bank/v1/webpages/onboarding-documents-details',
    deleteOnboardingDocument: '/api/bank/v1/document-signing/envelope',
    cancelOnboardingDocument: '/api/bank/v1/document-signing/cancel-envelope',
    getOnboardingDocumentSignersList: '/api/bank/v1/document-signing/signers-sync',
    downloadOnboardingDocuments: '/api/bank/v1/document-signing/zip-documents-download',
    sendOnboardingDocuments: '/api/bank/v1/document-signing/send-envelope',
    resendOnboardingDocuments: '/api/bank/v1/document-signing/resend-envelope',

    user: '/api/bank/v1/user',
    userList: '/api/bank/v1/user-list',
    userStatus: '/api/bank/v1/user/active-status',
    userPasswordResetUrl: '/api/bank/v1/user-password-reset-url',
    userAuthPasswordReset: '/api/bank/auth/password-reset',
    authCheckLink: '/api/bank/auth/check-link',

    vendorList: '/api/bank/v1/vendor-list',

    layout: '/api/bank/v1/webpages/bank-portal-layout',
    clientUserList: '/api/bank/v1/webpages/client-users-management',
    createClientUser: '/api/bank/v1/client-user-management/create',
    editClientUser: '/api/bank/v1/client-user-management/edit',
    removeClientUser: '/api/bank/v1/client-user-management/remove',
    sendInviteLinkClientUser: '/api/bank/v1/client-user-management/send-link'
  },
  calendar: {
    category: '/api/calendar/v1/category',
    categoryList: '/api/calendar/v1/category-list',

    event: '/api/calendar/v1/event',
    eventList: '/api/calendar/v1/event-list'
  },
  client: {
    applicationSave: '/api/client/v1/application-rfi-form',
    applicationList: '/api/client/v1/application-list',
    applicationDocumentRequestAcknowledge: '/api/client/v1/application-document-request-acknowledge',
    applicationDocumentDirectDownload: '/api/client/v1/application-document-direct-download',
    applicationDocumentUpload: '/api/client/v1/application-document-upload',
    applicationDocument: '/api/client/v1/application-document',
    applicationWebPage: '/api/client/v1/webpages/onboarding',
    applicationSaveClientNotes: '/api/client/v1/application-notes',
    applicationSubmitRFI: '/api/client/v1/application-submit-rfi-form',
    applicationDocumentsSubmit: '/api/client/v1/application-submit-document-request',

    authLogin: '/api/client/auth/login',
    authLogout: '/api/client/auth/logout',
    authPassword: '/api/client/auth/password',
    authPasswordReset: '/api/client/auth/password-reset',
    authApplicationTokenValidate: '/api/client/auth/application-token-validate',
    authMfaDevice: '/api/client/auth/mfa/device',
    authMfaDeviceDefault: '/api/client/auth/mfa/device-default',
    authMfaChallenge: '/api/client/auth/mfa/challenge',
    authMfaLogin: '/api/client/auth/mfa/login',
    authCheckLink: '/api/client/auth/check-link',

    biotrackCredentials: '/api/client/v1/biotrack-credentials',
    biotrackCredentialsList: '/api/client/v1/biotrack-credentials-list',

    clientUser: '/api/client/v1/client-user',
    clientUserList: `/api/client/v1/client-user-list`,
    clientUserCompanies: '/api/client/v1/client-user-companies',
    clientUserPermissions: '/api/client/v1/client-user-permissions',

    companyList: '/api/client/v1/company-list',

    deviceList: '/api/client/auth/mfa/device-list',

    documentFile: '/api/client/v1/document-file',
    documentFileList: '/api/client/v1/document-file-list',
    documentFileUpload: '/api/client/v1/document-file-upload',
    documentFileDirectDownload: '/api/client/v1/document-file-direct-download',

    applicationClientDocumentFileDirectDownload: '/api/client/v1/application-client-document-file-download',
    applicationClientDocumentFileUpload: '/api/client/v1/application-client-document-file-upload',
    applicationClientDocumentFile: '/api/client/v1/application-client-document-file',

    internalTransfer: '/api/client/v1/internal-transfer',
    internalTransferHistory: `/api/client/v1/internal-transfer-history`,

    internalTransferApprove: '/api/client/v1/internal-transfer-approve',
    internalTransferCancel: '/api/client/v1/internal-transfer-cancel',
    internalTransferSetting: '/api/client/v1/internal-transfer-setting',

    internalTransferListWebPage: '/api/client/v1/webpages/internal-transfer-list',
    internalTransferCreateWebPage: '/api/client/v1//webpages/internal-transfers-create-mode',

    invoiceSubmittal: '/api/client/v1/invoice-submittal',
    invoiceSubmittalList: `/api/client/v1/invoice-submittal-list`,
    invoiceSubmittalFileDownload: '/api/client/v1/invoice-submittal-file-download',
    invoiceSubmittalFileUpload: '/api/client/v1/invoice-submittal-file-upload',

    heartbeat: '/api/client/auth/heartbeat',

    licenseList: '/api/client/v1/license-list',

    monthlyReport: '/api/client/v1/client-sales-data',
    monthlyReportFile: '/api/client/v1/client-sales-data-file-upload',

    organizationList: '/api/client/v1/organization-list',

    profile: '/api/client/v1/profile',
    profilePassword: '/api/client/v1/profile/password',

    taskList: '/api/client/v1/task-list',
    tasksListWebPage: '/api/client/v1/webpages/tasks-list',

    metrcCredentials: '/api/client/v1/metrc-credentials',
    metrcCredentialsList: '/api/client/v1/metrc-credentials-list',

    layout: '/api/client/v1/webpages/client-portal-layout'
  },
  support: {
    authLogin: '/api/support/auth/login',
    authLogout: '/api/support/auth/logout',
    authPassword: '/api/support/auth/password',
    authPasswordReset: '/api/support/auth/password-reset',
    authMfaDevice: '/api/support/auth/mfa/device',
    authMfaDeviceDefault: '/api/support/auth/mfa/device-default',
    authMfaChallenge: '/api/support/auth/mfa/challenge',
    authMfaLogin: '/api/support/auth/mfa/login',

    bankUser: '/api/support/v1/bank-user',
    bankUserList: '/api/support/v1/bank-user-list',

    clientPortalReport: '/api/support/v1/client-portal-report',
    clientPortalReportCsv: '/api/support/v1/client-portal-report-csv',

    licensesWithBankAccountsReport: '/api/support/v1/portal-report/licenses-with-bank-accounts-report',
    licensesWithBankAccountsReportCsv: '/api/support/v1/portal-report/licenses-with-bank-accounts-report-csv',

    documentTemplatesReport: '/api/support/v1/portal-report/document-templates',
    documentTemplatesReportCsv: '/api/support/v1/portal-report/document-templates-csv',

    relationshipsDuplicateReport: '/api/support/v1/portal-report/relationships-duplicate-report',
    relationshipsDuplicateReportCsv: '/api/support/v1/portal-report/relationships-duplicate-report-csv',

    deviceList: '/api/support/auth/mfa/device-list',

    heartbeat: '/api/support/auth/heartbeat',

    organization: '/api/support/v1/organization',
    organizationList: '/api/support/v1/organization-list',

    profile: '/api/support/v1/profile',
    profilePassword: '/api/support/v1/profile/password',

    statisticsTable: (isoDateString: string) => `/api/support/v1/statistics-table?date=${isoDateString}`,
    setupDocumentTemplate: '/api/support/v1/document-templates/setup',
    validateDocumentTemplate: '/api/support/v1/document-templates/validate',

    importDocusignTemplatesFile: '/api/support/v1/docusign-templates/import'
  }
}
