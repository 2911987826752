import React, { FC, useState } from 'react'

import * as SC from '../styles'
import { HistoryChanges } from 'commons/types/DTO/bank/historyAudit'

import { EntityHistoryDetailsTextMask } from './EntityHistoryDetailsTextMask'

const checkIsValueEmpty = (value: string) => (value.length === 0 ? '""' : value)

export const HistoryUpdateLabel: FC<Pick<HistoryChanges, 'subject' | 'from' | 'to' | 'mustBeMasked'>> = ({
  subject,
  from,
  to,
  mustBeMasked
}) => {
  const [expanded, setExpanded] = useState<boolean>(false)

  const switchExpanded = () => setExpanded(!expanded)

  const oldValueTooBig: boolean = from.toString().length > 50
  const newValueTooBig: boolean = to.toString().length > 50

  const oldValue = from
  const newValue = to

  if (oldValueTooBig || newValueTooBig) {
    const styledOldVal =
      oldValueTooBig && !expanded ? `${oldValue.toString().slice(0, 50)}...` : checkIsValueEmpty(oldValue)
    const styledNewVal =
      newValueTooBig && !expanded ? `${newValue.toString().slice(0, 50)}...` : checkIsValueEmpty(newValue)
    return (
      <SC.Row>
        <b data-qa="details-field-label">{subject}</b>
        {' changed from '}
        <i>{styledOldVal}</i>
        {' to '}
        <b data-qa="details-field-value">
          <i>{styledNewVal}</i>
        </b>
        <SC.SwitcherLink onClick={switchExpanded}>{expanded ? 'Collapse' : 'Expand'}</SC.SwitcherLink>
      </SC.Row>
    )
  }
  return (
    <SC.Row>
      <b data-qa="details-field-label">{subject}</b>
      {' changed from '}
      {mustBeMasked ? (
        <EntityHistoryDetailsTextMask text={oldValue} />
      ) : (
        <b data-qa="details-field-value">{oldValue}</b>
      )}
      {' to '}
      <b data-qa="details-field-value">
        {' '}
        {mustBeMasked ? (
          <EntityHistoryDetailsTextMask text={newValue} />
        ) : (
          <b data-qa="details-field-value">{newValue}</b>
        )}
      </b>
    </SC.Row>
  )
}
