import React, { FC, memo } from 'react'

import { ButtonGroup } from 'ui/components/ButtonGroup'
import { Button } from 'ui/components/Buttons'

import { FooterProps } from './FooterProps'

import { s } from './styles'

export const Footer: FC<FooterProps> = memo((props) => {
  const { onCancel, onSubmit, loading } = props

  return (
    <s.Box>
      <ButtonGroup margin="small">
        <Button face="neutral" onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={onSubmit} face="positive" loading={loading}>
          Save
        </Button>
      </ButtonGroup>
    </s.Box>
  )
})
