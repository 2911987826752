import React from 'react'

import { CheckBox, FF } from 'ui/components'

import { useSARFormContext } from '../../../../../../../../context'

type NoRelationshipProps = {
  index: number
}

export const NoRelationship = ({ index }: NoRelationshipProps) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={
        `subjects_info[${index}].relationships_info[0].no_relationship` as 'subjects_info[0].relationships_info[0].no_relationship'
      }
      children={(field) => (
        <FF
          field={field}
          input={(p) => <CheckBox {...p} checkBoxLabel="j. No relationship to institution" />}
          errorPosition="right"
        />
      )}
    />
  )
}
