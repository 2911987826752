import React from 'react'

import { FF, TextInput } from 'ui/components'

import { useSARFormContext } from '../../../../../../context'

type NAICSCodeProps = {
  index: number
}

export const NAICSCode = ({ index }: NAICSCodeProps) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={`subjects_info[${index}].naics_code` as 'subjects_info[0].naics_code'}
      children={(field) => <FF field={field} label="10a. NAICS Code" input={(p) => <TextInput {...p} />} />}
    />
  )
}
