import React from 'react'

import { CheckBox, FF } from 'ui/components'

import { useSARFormContext } from '../../../../../../context'

type NoKnownAccountsInvolvedProps = {
  index: number
}

export const NoKnownAccountsInvolved = ({ index }: NoKnownAccountsInvolvedProps) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={`subjects_info[${index}].no_known_accounts_involved` as 'subjects_info[0].no_known_accounts_involved'}
      children={(field) => (
        <FF field={field} input={(p) => <CheckBox {...p} checkBoxLabel="No known accounts involved" />} />
      )}
    />
  )
}
