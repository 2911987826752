import { createRoute, redirect } from '@tanstack/react-router'

import { BankBsaWrapper } from './BankBsaWrapper'
import { BsaBatchDetailsRoute } from './routes/BsaBatchDetails'
import { CTRRoute } from './routes/CTR'
import { CTRBatchesRoute } from './routes/CTRBatches'
import { OverviewRoute } from './routes/Overview'
import { PCTRoute } from './routes/PCT'
import { SARRoute } from './routes/SAR'
import { SARBatchesRoute } from './routes/SARBatches'

export const routeBSATree = (rootRoute: BPRootRoute) => {
  const BSAIndexRoute = createRoute({
    beforeLoad: async ({ context }) => {
      if (!context.accessibleRoutingFlags.bsaRootAccess) {
        throw redirect({
          to: '/'
        })
      }
    },
    getParentRoute: () => rootRoute,
    path: 'bsa',
    component: BankBsaWrapper
  })

  return BSAIndexRoute.addChildren([
    SARRoute<typeof BSAIndexRoute>(BSAIndexRoute),
    PCTRoute(BSAIndexRoute),
    OverviewRoute(BSAIndexRoute),
    CTRRoute(BSAIndexRoute),
    CTRBatchesRoute(BSAIndexRoute),
    SARBatchesRoute(BSAIndexRoute),
    BsaBatchDetailsRoute(BSAIndexRoute)
  ])
}
