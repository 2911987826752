import { RefObject, useEffect } from 'react'

const FOCUSABLE_ELEMENTS = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'

/**
 * The hook is prevent moving tab focus to the elements which is outside of ref object DOM Node.
 * @param {RefObject<HTMLElement>} boundingElementRef - focus travel bounds element;
 * @param {RefObject<HTMLElement>} focusTrapContainerRef - (optional parameter) container
 *  which contains focus trap element. if this parameter is not passed, first focusable element
 *  in boundingElementRef will be focused;
 */
export function useTabNavigationBound(
  boundingElementRef: RefObject<HTMLElement>,
  focusTrapContainerRef?: RefObject<HTMLElement>
): void {
  useEffect(() => {
    const { current: modal } = boundingElementRef

    if (!modal) {
      return
    }

    const firstFocusableElement = modal.querySelector(FOCUSABLE_ELEMENTS)
    // const focusableContent = modal.querySelectorAll(FOCUSABLE_ELEMENTS);
    // const lastFocusableElement = focusableContent[focusableContent.length - 1];
    let focusTrapElement

    if (focusTrapContainerRef && focusTrapContainerRef.current) {
      const { current: focusTrapContainer } = focusTrapContainerRef
      focusTrapElement = focusTrapContainer.querySelector(FOCUSABLE_ELEMENTS)
    }

    if (focusTrapElement) {
      ;(focusTrapElement as HTMLElement).focus()
    } else if (firstFocusableElement) {
      ;(firstFocusableElement as HTMLElement).focus()
    }

    // const handler = (e: KeyboardEvent) => {
    //   const isTabPressed = e.key === "Tab";
    //
    //   if (!isTabPressed) {
    //     return;
    //   }
    //
    //   if (e.shiftKey) {
    //     if (document.activeElement === firstFocusableElement) {
    //       (lastFocusableElement as HTMLElement).focus();
    //       e.preventDefault();
    //     }
    //   } else {
    //     if (document.activeElement === lastFocusableElement) {
    //       (firstFocusableElement as HTMLElement).focus();
    //       e.preventDefault();
    //     }
    //   }
    // };
    //
    // document.addEventListener("keydown", handler);
    //
    // return () => {
    //   document.removeEventListener("keydown", handler);
    // };
  }, [])
}
