import { ApplicationRelationshipLicense } from 'commons/types/DTO/bank/applications'
import { ChoiceOption } from 'commons/types/DTO/commons'

import selectMapper from '../../../helpers/selectMapper'
import { ApplicationBankAccountsFormDataList } from '../../../types'

export const getAssignedLicensesDataForFormInput = ({
  relationshipLicenses,
  formDataList
}: {
  relationshipLicenses?: ApplicationRelationshipLicense[]
  formDataList: ApplicationBankAccountsFormDataList
}): ChoiceOption[] => {
  if (!relationshipLicenses) {
    return []
  }
  let assignedLicensesFromForms: (number | string)[] = []

  // collect id list of assigned in form licenses
  Object.keys(formDataList).forEach((formDataKey) => {
    const licenseIdList = formDataList[formDataKey].value.assignedLicenses
    assignedLicensesFromForms = [...assignedLicensesFromForms, ...licenseIdList]
  })

  const accesibleLicenses = relationshipLicenses.filter(({ id: licenseId, entityUID: licenseEntityUID }) =>
    licenseId ? !assignedLicensesFromForms.includes(licenseId) : !assignedLicensesFromForms.includes(licenseEntityUID)
  )

  return accesibleLicenses.map(selectMapper)
}
