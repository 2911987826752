import { FieldProps, FormInputProps } from 'brief-form'
import { CompanyManageFormValue } from 'commons/types/DTO/bank'

export interface RelationshipsProps {
  id: number
  holdingId?: number | null
  isHolding: boolean
  Field: <Input extends React.ComponentType<FormInputProps<any, any>>>(
    props: FieldProps<Input, CompanyManageFormValue>
  ) => JSX.Element
}
