import React from 'react'

import { Col, FormRow } from 'ui/themes/globalStyles'

import { BankAccounts, TIN } from './fields'

type InstitutionInfoProps = {
  index: number
}

export const InstitutionInfo = ({ index }: InstitutionInfoProps) => {
  return (
    <>
      <FormRow>
        <Col>
          <TIN index={index} />
        </Col>
      </FormRow>
      <BankAccounts index={index} />
    </>
  )
}
