import { createRoute, redirect } from '@tanstack/react-router'
import { ADVERSE_MEDIA_PATH } from 'commons/constants/routes'

import { BankAdverseMediaWrapper } from './BankAdverseMediaWrapper'
import { searchCaseRoute } from './routes/SearchCase'
import { listRoute } from './routes/SearchCaseList'

export const routeAdverseMediaTree = (rootRoute: BPRootRoute) => {
  const adverseMediaIndexRoute = createRoute({
    beforeLoad: async ({ context }) => {
      if (!context.accessibleRoutingFlags.adverseMediaRootAccess) {
        throw redirect({
          to: '/'
        })
      }
    },
    getParentRoute: () => rootRoute,
    path: ADVERSE_MEDIA_PATH,
    component: BankAdverseMediaWrapper
  })

  return adverseMediaIndexRoute.addChildren([
    searchCaseRoute<typeof adverseMediaIndexRoute>(adverseMediaIndexRoute),
    listRoute<typeof adverseMediaIndexRoute>(adverseMediaIndexRoute)
  ])
}
