import React, { FC, memo, useEffect, useMemo } from 'react'

import { useLoaderData, useNavigate } from '@tanstack/react-router'
import { BankApplicationPaths } from 'commons/constants/routes'
import { PortalName } from 'commons/types/enums'
import { Link, Spinner, SpinnerSize } from 'ui/components'
import { BPLayout } from 'ui/components/BPLayout'
import { HeaderButton } from 'ui/components/Buttons'
import { Page, PageFace } from 'ui/components/Page'

import { OnboardingForm } from '../../components'
import { Footer } from '../../components/OnboardingForm/Footer'
import { OnboardingFormContext } from '../../components/OnboardingForm/OnboardingFormContext'
import { useOnboardingForm } from '../../components/OnboardingForm/useOnboardingForm'
import { OnboardingFormLoaderReturn } from '../../types'

const BankOnboardingForm: FC = memo(() => {
  const loaderData: OnboardingFormLoaderReturn = useLoaderData({ from: BankApplicationPaths.EDIT })
  const { applicationId, applicationData } = loaderData
  const navigate = useNavigate()

  const { state, Confirmations } = useOnboardingForm({ portalName: PortalName.BP, applicationId, applicationData })

  const {
    state: { isCurrentStepEditEnable, applicationStatus, applicationName },
    ui: { ErrorMessagesDialog, isOnboardingFormEnable },
    isWebpabgeFetchLock
  } = state

  const applicationDetailsUrlParams = useMemo(
    () => ({ to: BankApplicationPaths.DETAILS, params: { id: applicationId } }),
    [applicationId]
  )

  useEffect(() => {
    if (isWebpabgeFetchLock && !isCurrentStepEditEnable) {
      navigate(applicationDetailsUrlParams)
    }
  }, [applicationDetailsUrlParams, isCurrentStepEditEnable, isWebpabgeFetchLock, navigate])

  if (!isWebpabgeFetchLock) {
    return <Spinner centered size={SpinnerSize.M} />
  }

  return (
    <BPLayout>
      <OnboardingFormContext.Provider value={state}>
        <Page
          actions={
            <Link {...applicationDetailsUrlParams}>
              <HeaderButton size="middle" overColoredPad={true}>
                Back to application
              </HeaderButton>
            </Link>
          }
          footer={<Footer />}
          face={PageFace.SECONDARY}
          title={applicationStatus}
          subTitle={applicationName}
        >
          {isOnboardingFormEnable && <OnboardingForm />}
          <ErrorMessagesDialog />
        </Page>
      </OnboardingFormContext.Provider>

      {Confirmations.CompanyDelete()}
      {Confirmations.ContactDelete()}
      {Confirmations.AtmDelete()}
      {Confirmations.LicenseDelete()}
    </BPLayout>
  )
})

export default BankOnboardingForm
