import { useCallback, useMemo, useState } from 'react'

import { useGetInternalTransferExport, useProcessInternalTransferExport } from 'commons/hooks/bank/internalTransfer'
import { InternalTransfersExportList } from 'commons/types/DTO/bank'
import { handlerServerError } from 'errors'
import { Keys, useStateManager } from 'state-manager'
import { DataSourceItem } from 'ui/components/Table'
import { Toast } from 'ui/components/Toast'
import { useConfirmation } from 'ui/hooks'

export const useTable = (
  internalTransferExports: InternalTransfersExportList[],
  setLoading: (isLoading: boolean) => void
) => {
  const [selectedTransfer, setSelectedTransfer] = useState<null | InternalTransfersExportList>(null)
  const [startProcess, setStartProcess] = useState(false)
  const sm = useStateManager()

  const mappedInternalTransfersExport: DataSourceItem<InternalTransfersExportList>[] = useMemo(
    () =>
      internalTransferExports.map((el) => ({
        item: el
      })),
    [internalTransferExports]
  )

  const { routine: processInternalTransferExport } = useProcessInternalTransferExport({
    onSuccess: async () => {
      await sm.invalidate(Keys.InternalTransfer.ListByCriteria({}))
      Toast.successSmall('File was processed.')
      setLoading(false)
      setStartProcess(false)
    },
    onError: (error) => {
      handlerServerError(error)
      setLoading(false)
      setStartProcess(false)
    }
  })

  useGetInternalTransferExport(
    {
      id: selectedTransfer?.id,
      enabled: startProcess
    },
    {
      onSuccess: async (data) => {
        await processInternalTransferExport(data.records[0].id)
        setLoading(false)
        setStartProcess(false)
      },
      onError: (error) => {
        handlerServerError(error)
        setLoading(false)
        setStartProcess(false)
      }
    }
  )

  const { open, Confirmation: ProcessConfirmation } = useConfirmation({
    message: `Are you sure you want to process ITE-${selectedTransfer?.id} export?`,
    onConfirm: () => {
      setLoading(true)
      setStartProcess(true)
    },
    confirmationButtonText: 'Process'
  })

  const startInternalTransferExport = useCallback(
    (item: InternalTransfersExportList) => {
      setSelectedTransfer(item)
      open()
    },
    [setSelectedTransfer, open]
  )

  return {
    ProcessConfirmation,
    startInternalTransferExport,
    mappedInternalTransfersExport
  }
}
