import styled from 'styled-components'

export const Wrapper = styled.div<{ $vertical?: boolean; $marginBottom?: string }>`
  display: flex;
  font-size: 14px;
  line-height: 2;
  flex-direction: ${(p) => (p.$vertical ? 'column' : 'row')};
  margin-bottom: ${(p) => p.$marginBottom || '0'};
`
export const Label = styled.i`
  margin-right: 35px;
`
