import React, { FC, memo } from 'react'

import { Hint, HintFace } from 'ui/components/Hint'
import { IconName, IconSize } from 'ui/components/Icon'

import * as SC from './styles'

export type ContactsDescriptionProps = {
  isOwner: boolean
  isAccountSigner: boolean
  bankPercents: number
}

export const ContactsDescription: FC<ContactsDescriptionProps> = memo(({ isOwner, isAccountSigner, bankPercents }) => {
  const isWarningBlockEnable = !isOwner || !isAccountSigner

  return (
    <>
      <Hint>
        <SC.HintTitle>Please fill out information for each of the following:</SC.HintTitle>
        <SC.HelpUl>
          <li>All Individuals or Entities with Ownership of {bankPercents}% or greater</li>
          <li>All Authorized Signers</li>
          <li>All Debtholders</li>
        </SC.HelpUl>
      </Hint>
      {isWarningBlockEnable && (
        <Hint face={HintFace.ERROR}>
          <SC.ErrorTitle id="ERROR_OWNER_SIGNER">
            <SC.WarningIcon name={IconName.ERROR_RED} size={IconSize.M} />
            Warning
          </SC.ErrorTitle>
          <i>The company must have at least one owner and one account signer</i>
        </Hint>
      )}
    </>
  )
})
