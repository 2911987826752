import styled from 'styled-components'

import { Button } from '../Button'

export const Container = styled.section`
  overflow: hidden;
`

const HeaderBase = styled.header<{ expanded: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  white-space: nowrap;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
`

export const PrimaryHeader = styled(HeaderBase)`
  height: 56px;
  padding: 0 22px 0 35px;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.49px;
  color: ${(p) => p.theme.uiKit.colors.global.general.secondary};
  background: ${(p) => p.theme.uiKit.colors.global.grey.grey300};
`

export const SecondaryHeader = styled(HeaderBase)<{ $disabled?: boolean }>`
  height: 46px;
  border-bottom: 1px solid ${(p) => (p.expanded ? p.theme.uiKit.colors.global.grey.grey300 : 'transparent')};
  padding: 0 24px;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: 0.38px;
  color: ${(p) => p.theme.uiKit.colors.global.grey.grey500};
  background: ${(p) =>
    p.$disabled ? p.theme.uiKit.colors.global.grey.grey100 : p.theme.uiKit.colors.global.general.main};
`

export const SmallPrimaryHeader = styled(HeaderBase)`
  height: 46px;
  padding: 0 24px;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: 0.38px;
  color: ${(p) => p.theme.uiKit.colors.global.general.secondary};
  background: ${(p) => p.theme.uiKit.colors.global.grey.grey300};
`

const TitleBase = styled.h1<{ $clicable: boolean }>`
  flex: 1;
  margin: 0;
  white-space: nowrap;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.38px;
  cursor: ${(p) => (p.$clicable ? 'pointer' : 'auto')};
`

export const PrimaryTitle = styled(TitleBase)`
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.49px;
  color: ${(p) => p.theme.uiKit.colors.global.general.secondary};
`

export const SecondaryTitle = styled(TitleBase)`
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.38px;
  color: ${(p) => p.theme.uiKit.colors.global.general.secondary};
`

export const SmallPrimaryTitle = styled(TitleBase)`
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.38px;
  color: ${(p) => p.theme.uiKit.colors.global.general.secondary};
`

export const WrappedButton = styled(Button)<{ $disabled?: boolean }>`
  margin-right: 6px;
  height: 22px;
  position: relative;
  top: -1px;
  svg {
    fill: ${(p) =>
      p.$disabled ? p.theme.uiKit.colors.global.grey.grey500 : p.theme.uiKit.colors.global.general.secondary};
  }
`

export const Body = styled.div<{ expanded: boolean }>`
  overflow: hidden;
  transition: all 0.3s ease;
  opacity: ${(p) => (p.expanded ? '100%' : '0')};
  max-height: ${(p) => (p.expanded ? 'max-content' : '0')};
`

export const Actions = styled.div`
  float: right;
  display: flex;
  align-items: center;
`
