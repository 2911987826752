import * as React from 'react'

import styled from 'styled-components'

import { Button, ButtonFace, ButtonSize } from '../Button'
import { CompactPages } from './CompactPages'
import { Options } from './Options'
import { Pages } from './Pages'
import { MAXIMUM_PAGES } from './consts'

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  hideLabels?: boolean
  onPageChange: (page: number, size: number) => void
  blockPagination?: () => void
  pageSize: number
  page?: number
  total: number
  size?: ButtonSize
  isTablet?: boolean
  className?: string
  disabled?: boolean
  withPageSize?: boolean
  pageSizeOptions?: string[]
  tooMany?: boolean
}

const handlePageChange =
  (onPageChange: { (number: number, size: number): void }, allPages: number, pageSize: number) =>
  (nextPageIndex: number) => {
    if (nextPageIndex <= allPages && nextPageIndex >= 0) {
      onPageChange(nextPageIndex, pageSize)
    }
  }

const Content = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Pagination = React.memo((props: Props) => {
  const {
    pageSize,
    page = 1,
    total,
    onPageChange,
    size = ButtonSize.SMALL,
    isTablet = false,
    disabled = false,
    withPageSize = false,
    pageSizeOptions,
    blockPagination,
    tooMany = false,
    ...rest
  } = props
  // eslint-disable-next-line
  const items = Boolean(total) ? total : 1
  const allPages = Math.floor((items - 1) / pageSize) + 1
  const pageChanged = handlePageChange(onPageChange, allPages, pageSize)
  const pageSizeChanged = (size: number) => handlePageChange(onPageChange, allPages, size)(1)

  return (
    <Content {...rest}>
      {!isTablet ? (
        <>
          <Button
            onClick={() => {
              blockPagination && blockPagination()
              pageChanged(page - 1)
            }}
            face={ButtonFace.SECONDARY}
            size={size}
            disabled={page <= 1 || disabled}
          >
            Previous
          </Button>
          <Button face={ButtonFace.LINK} size={size}>
            {page} of {tooMany ? 'too many' : allPages}
          </Button>
          <Button
            onClick={() => {
              blockPagination && blockPagination()
              pageChanged(page + 1)
            }}
            face={ButtonFace.SECONDARY}
            size={size}
            disabled={allPages <= page || disabled}
          >
            Next
          </Button>
        </>
      ) : (
        <>
          <Button
            onClick={() => {
              blockPagination && blockPagination()
              pageChanged(page - 1)
            }}
            face={ButtonFace.SECONDARY}
            size={size}
            disabled={page <= 1 || disabled}
          >
            Previous
          </Button>
          {allPages <= MAXIMUM_PAGES ? (
            <Pages pageCount={allPages} selectedPage={page} onPageChange={onPageChange} size={size} />
          ) : (
            <CompactPages pageCount={allPages} selectedPage={page} onPageChange={onPageChange} size={size} />
          )}
          <Button
            onClick={() => {
              blockPagination && blockPagination()
              pageChanged(page + 1)
            }}
            face={ButtonFace.SECONDARY}
            size={size}
            disabled={allPages <= page || tooMany || disabled}
          >
            Next
          </Button>
        </>
      )}
      {withPageSize && (
        <Options
          disabled={disabled}
          changeSize={pageSizeChanged}
          pageSize={pageSize}
          pageSizeOptions={pageSizeOptions}
        />
      )}
    </Content>
  )
})
