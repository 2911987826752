import React from 'react'

import { CheckBox, FF } from 'ui/components'

import { useSARFormContext } from '../../../../../../../../context'

type OtherProps = {
  index: number
}

export const Other = ({ index }: OtherProps) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={`subjects_info[${index}].relationships_info[0].other` as 'subjects_info[0].relationships_info[0].other'}
      children={(field) => (
        <FF field={field} input={(p) => <CheckBox {...p} checkBoxLabel="z. Other" />} errorPosition="right" />
      )}
    />
  )
}
