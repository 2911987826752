import { OnboardingDocumentsItemStatusEnum } from 'commons/types/DTO/bank/applications/manage/OnboardingDocumentsItem'
import { ChipFace } from 'ui/components'

export const getChipFaceByOnboardingDocumentStatus = (status: OnboardingDocumentsItemStatusEnum) => {
  switch (status.toLocaleLowerCase()) {
    case OnboardingDocumentsItemStatusEnum.CREATED:
      return ChipFace.WARNING
    case OnboardingDocumentsItemStatusEnum.SIGNED:
      return ChipFace.PRIMARY
    default:
      return ChipFace.DEFAULT
  }
}
