import React from 'react'

import { FF, TextInput } from 'ui/components'

import { useSARFormContext } from '../../../../../../context'

type PhoneExtProps = {
  index: number
}

export const PhoneExt = ({ index }: PhoneExtProps) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={`subjects_info[${index}].phone_numbers[0].phone_ext` as 'subjects_info[0].phone_numbers[0].phone_ext'}
      children={(field) => <FF field={field} label="Ext." input={(p) => <TextInput {...p} maxLength={6} />} />}
    />
  )
}
