import styled from 'styled-components'

export const Container = styled.div`
  padding: 20px 30px;
`
export const Content = styled.div`
  margin-bottom: 15px;
`

export const CTRGuideContainer = styled.div`
  margin: 25px 0 15px;
`
export const Text = styled.p`
  margin-bottom: 5px;
`
export const QuestionBlock = styled.div`
  margin-bottom: 20px;
`

export const Question = styled.p`
  margin: 10px 0;
`
