// eslint-disable-next-line max-len
import React from 'react'

import { AnnualReviewQuestionnaire, LicenseDeprecated } from 'commons/types/DTO/bank'
import { PageSection } from 'ui/components/Page'
import { useAnnualReviewSalesDeposits } from '~bank-annual-review/hooks'

import { AnnualReviewLicensingQuestions } from './components/AnnualReviewLicensingQuestions/AnnualReviewLicensingQuestions'
import { AnnualReviewLicensingTable } from './components/AnnualReviewLicensingTable'

export interface AnnualReviewLicensingProps {
  id: number
  companyId: number
  questionnaire?: AnnualReviewQuestionnaire
  onReturnData: (data: LicenseDeprecated[]) => void
}

export const AnnualReviewLicensing = ({
  id,
  companyId,
  questionnaire,
  onReturnData
}: AnnualReviewLicensingProps): JSX.Element => {
  const annualReviewSalesDeposits = useAnnualReviewSalesDeposits({ id })
  const salesAndDeposits = annualReviewSalesDeposits.data?.records
  const hasAnswers =
    questionnaire?.licensing_loc_changes ||
    questionnaire?.licensing_loc_changes_mmcc ||
    questionnaire?.licensing_name_changes ||
    questionnaire?.licensing_name_changes_mmcc ||
    questionnaire?.licensing_name_prior

  return (
    <PageSection title="Licensing">
      {salesAndDeposits && (
        <AnnualReviewLicensingTable
          salesAndDeposits={salesAndDeposits}
          companyId={companyId}
          onReturnData={onReturnData}
        />
      )}
      {hasAnswers && (
        <AnnualReviewLicensingQuestions
          licensingLocChanges={questionnaire?.licensing_loc_changes}
          licensingNameChanges={questionnaire?.licensing_name_changes}
          licensingLocChangesMmcc={questionnaire?.licensing_loc_changes_mmcc}
          licensingNameChangesMmcc={questionnaire?.licensing_name_changes_mmcc}
          licensingNamePrior={questionnaire?.licensing_name_prior}
        />
      )}
    </PageSection>
  )
}
