import SC from 'styled-components'

import { Link } from '../Link'

export const Box = SC.div`
  text-align: left;
  display: inline-block;
`

export const WrappedLink = SC(Link)`
  display: block;
  line-height: 1.3;
`

export const WrappedType = SC.div`
  font-size: 13px;
  font-style: italic;
  text-decoration: underline;
`
