import { UseNavigateResult } from '@tanstack/react-router'
import { Keys, StateManager } from 'state-manager'

export const redirectToProperPage = async (
  stateManager: StateManager,
  navigate: UseNavigateResult<string>,
  searchParams: URLSearchParams
) => {
  // set the parameter in order to know that we will receive data about the user
  // we check this parameter in the method useSmartRedirect
  stateManager.set(Keys.User.Session, undefined)
  const query = Object.fromEntries([...searchParams])
  const returnUrl = query.returnUrl

  await stateManager.invalidate(Keys.User.Session)
  await stateManager.invalidate(Keys.User.Current)
  await stateManager.invalidate(Keys.Organisation.List)
  await stateManager.invalidate(Keys.ClientPortal.Layout)

  if (returnUrl && returnUrl.search(/^http/) !== -1) {
    window.location.href = returnUrl
  } else {
    navigate({ to: returnUrl ? returnUrl : '/' })
  }
}
