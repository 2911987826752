import styled from 'styled-components'

const BranchHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`

const BranchTitle = styled.h5`
  margin-right: 10px;
`

export const s = { BranchHeader, BranchTitle }
