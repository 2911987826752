import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 19px 35px;
  border-top: solid 1px #b8b8b8;
  background-color: rgba(0, 0, 0, 0.02);
  margin-bottom: 19px;
`

export const Title = styled.h4`
  font-size: 22px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.49px;
  color: #000000;
  background: #fff;
  padding: 19px 35px;
  margin: -19px -35px 0;
`

export const Header = styled.div`
  background-color: #f5f5f5;
  padding: 14px 35px;
  margin: 0 -35px;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  color: #484848;
`

export const Columns = styled.div`
  display: flex;
`

export const Column = styled.div`
  width: 340px;
`

export const Column2 = styled.div`
  width: 170px;
`
