import { localStorageService } from 'commons/service/localStorageService'
import { PortalName } from 'commons/types/enums'

import { heartbeat } from '../../../service/user'

const prolongUserSession = async ({ portalName, hasUser }: { portalName: PortalName; hasUser: boolean }) => {
  try {
    if (!hasUser) {
      return
    }
    const { expires_at: expiresAt } = await heartbeat({ portalName })
    localStorageService.setSessionExpiresAt(expiresAt)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  }
}

export default prolongUserSession
