import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 0 35px 19px 35px;
`

export const Title = styled.h4`
  font-size: 22px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.49px;
  color: #000000;
  margin: 0 0 20px 0;
`

export const SubHeader = styled.h3`
  margin: 18px 0 22px 0;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
`
