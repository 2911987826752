const businessTypeMap: { [key: string]: string } = {
  mrb: 'Licensed MRB',
  mrb_related: 'MRB Related',
  ancillary: 'Ancillary',
  tribal_mrb: 'Tribal MRB',
  investment: 'Investment',
  pre_license_mrb: 'Pre License MRB',
  management: 'Management',
  consumer_account: 'Consumer Account',
  hemp: 'Hemp',
  cbd: 'CBD'
}

export const getBusinessTypeLabel = (name: string) => (businessTypeMap[name] ? businessTypeMap[name] : name || '---')
