import { FormApi, ReactFormApi } from '@tanstack/react-form'

import { ManagePeriodResponse } from './response.types'

export class ManagePeriodForm {
  startDate: Date
  endDate: Date
  deliveredAt?: string | Date | null
  notes?: string

  constructor(isNew: boolean, initialValue?: ManagePeriodResponse['form']) {
    if (isNew) {
      this.startDate = undefined
      this.endDate = undefined
      this.notes = undefined
    } else if (initialValue) {
      this.startDate = new Date(initialValue.startDate)
      this.endDate = new Date(initialValue.endDate)
      this.notes = initialValue.notes
      this.deliveredAt = initialValue.deliveredAt ? new Date(initialValue.deliveredAt) : undefined
    }
  }
}

export type ManagePeriodFormApiType = FormApi<ManagePeriodForm, undefined> & ReactFormApi<ManagePeriodForm, undefined>
