import React, { FC, memo, useCallback } from 'react'

import { useFormData } from 'brief-form'
import { History } from 'commons/types/DTO/bank'
import { useTableState } from 'query-table'
import { QueryTable } from 'ui/components/QueryTable'

import { EntityHistoryFilterValue } from '../../EntityHistoryFilterValue'
import { TableProps } from './TableProps'
import { columns } from './columns'
import { Filter } from './components/Filter'
import { dataSources } from './datasources'

export const Table: FC<TableProps> = memo(({ id, showTypeColumn, type, hideFilter, name, dataQa }) => {
  const { pagination, sorting, filter } = useTableState({
    filter: {},
    order: [{ field: 'created_at', direction: 'DESC' }]
  })

  const filterForm = useFormData<EntityHistoryFilterValue>({
    initialValue: {
      entity_type: '',
      action_type: '',
      date_from: null,
      date_to: null,
      user_id: ''
    }
  })

  const originalChangeHandler = filterForm.config.onChange
  filterForm.config.onChange = useCallback(
    (v, e) => {
      pagination.change(1, pagination.size)
      originalChangeHandler(v, e)
    },
    [originalChangeHandler, pagination]
  )

  const dataSourceSelector = (data: any) =>
    (data?.main?.records || []).map((record: History) => ({
      item: record
    }))

  return (
    <QueryTable
      name={name || 'main-history'}
      dataQa={dataQa}
      columns={columns(showTypeColumn)}
      dataSources={dataSources(id, type)}
      dataSourceSelector={dataSourceSelector}
      sorting={{
        order: sorting.order,
        onChange: sorting.change
      }}
      pagination={{
        page: pagination.page,
        size: pagination.size,
        onChange: pagination.change
      }}
      filter={
        hideFilter
          ? undefined
          : {
              value: filterForm.config.value,
              component: <Filter form={filterForm} expanded={filter.expanded} onToggle={filter.toggle} />
            }
      }
    />
  )
})
