export type ExternalLicense = {
  address: string
  city: string
  company_ids_customer: number[]
  company_ids_vendor: number[]
  created_at: string
  email: string
  external_id: string
  id: number
  license_id: number
  name: string
  organization_id: number
  phone: string
  state: string
  updated_at: string
  website: string
  zip_code: string
}
