import { useCallback } from 'react'
import { useMutation } from 'react-query'

import { CreateApplicationRequest } from 'commons/types/DTO/bank/applications/manage/CreateApplicationRequest'

import { createApplication as createApplicationService } from '../service'

type UseCreateApplicationReturnValue = {
  createApplication: (formValue: CreateApplicationRequest) => Promise<CreateApplicationRequest>
}

export const useCreateApplication = (): UseCreateApplicationReturnValue => {
  const mutation = useMutation((formValue: CreateApplicationRequest) => createApplicationService(formValue))

  const createApplication = useCallback(
    (formValue: CreateApplicationRequest) => mutation.mutateAsync(formValue),
    [mutation]
  )

  return {
    createApplication
  }
}
