/* eslint-disable camelcase */

export type ApplicationContactDetails = {
  email?: string // email,
  first_name?: string // first_name,
  last_name?: string // last_name,
  mobile?: string // mobile,
  phone?: string // phone,
  home_phone?: string // home_phone,
  title?: string // title,
  other_mj_related_business?: string // other_mj_related_business,

  bio?: string // bio
}
export type ApplicationRoleInformation = {
  account_signer?: boolean
  conductor?: boolean
  debtholder?: boolean
  owner?: boolean
}

export type ApplicationContactAdditionalInformation = {
  debtholder_debt_amount?: number
  debtholder_payment_frequency?: string // +
  debtholder_payment_amount?: number
  debtholder_maturity_date?: string
  owner_investment_amount?: number
  owner_ownership_percent?: number
  birthdate?: string
  ein_ssn?: string
  street?: string
  city?: string
  state?: string
  country?: string
  postal_code?: string
  mailing_state?: string
  mailing_postal_code?: string
  mailing_city?: string
  mailing_street?: string
  mailing_country?: string
  us_citizen?: boolean
}

export type ApplicationRelationshipContact = ApplicationContactDetails &
  ApplicationRoleInformation &
  ApplicationContactAdditionalInformation & {
    uid?: string // not exists in ContactAdditionalInformationFormValue
  }
