import React, { FC, memo, useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'

import { getRouteApi, notFound } from '@tanstack/react-router'
import { BSA_CTR_PATH } from 'commons/constants/routes'
import { ButtonGroup } from 'ui/components/ButtonGroup'
import { HeaderButton, IconButton } from 'ui/components/Buttons'
import { IconName } from 'ui/components/Icon'
import { Page, PageFace } from 'ui/components/Page'
import { useConfirmation } from 'ui/hooks'
import { CTR } from '~bank-bsa-reporting/hooks'
import { useGetCTRDetails } from '~bank-bsa-reporting/hooks/ctr/useGetCTRDetails'

import { Transactions } from '../components'
import {
  AmountAndTypeOfTransactions,
  FilingInstitutionContactInformation,
  FincenWarnings,
  Footer,
  GeneralInformation,
  History,
  PersonsInvolvedInformation,
  TransactionsLocationsInformation,
  Warnings
} from './components'

const routeApi = getRouteApi('/bsa/ctrs/$id')

const Details: FC = memo(() => {
  const params = routeApi.useParams()
  const navigate = routeApi.useNavigate()

  const [open, setOpen] = useState(false)
  const queryClient = useQueryClient()
  const deleteMutation = CTR.useDelete(queryClient)

  const { data, isFetching, historyTableConfig } = useGetCTRDetails(+params.id)

  const {
    nextId,
    previousId,
    id,
    fincenWarnings,
    attention,
    history,
    historyTotalCount,
    transactions,
    generalInfo,
    canApprove,
    canCancel
  } = data || {}

  const { nameDesc } = generalInfo || {}

  useEffect(() => {
    if ((!isFetching && !data) || +params.id === 0 || isNaN(+params.id)) {
      throw notFound()
    }
  }, [isFetching, data, params.id])

  const { open: startDeleting, Confirmation } = useConfirmation({
    message: `Are you sure you want to delete ${nameDesc} CTR?`,
    onConfirm: () =>
      deleteMutation.mutateAsync([id || 0]).then(() => {
        navigate({ to: BSA_CTR_PATH })
      }),
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  return (
    <Page
      face={PageFace.SECONDARY}
      title={nameDesc || '...'}
      subTitle="CTR"
      actions={
        <ButtonGroup margin="big">
          <IconButton data-qa="Delete CTR" onClick={startDeleting} icon={IconName.DELETE} face="neutral">
            Delete CTR
          </IconButton>
          <HeaderButton onClick={() => setOpen(true)} size="middle" overColoredPad={true}>
            Transactions
          </HeaderButton>
        </ButtonGroup>
      }
      footer={
        <Footer id={id} nextId={nextId} previousId={previousId} showApprove={canApprove} showCancel={canCancel} />
      }
    >
      <Confirmation />
      {!!data && (
        <>
          {open && <Transactions onClose={() => setOpen(false)} transactions={transactions} ctrId={id} />}
          {attention.length > 0 && <Warnings attention={attention} />}
          {fincenWarnings.length > 0 && <FincenWarnings warnings={fincenWarnings} />}
          <GeneralInformation data={data} />
          <FilingInstitutionContactInformation data={data} />
          <TransactionsLocationsInformation data={data} />
          <PersonsInvolvedInformation data={data} />
          <AmountAndTypeOfTransactions data={data} />
          <History
            config={historyTableConfig}
            initialCompanyHistory={history}
            initialCompanyHistoryTotalCount={historyTotalCount}
          />
        </>
      )}
    </Page>
  )
})

export default Details
