import React, { FC, cloneElement, isValidElement, memo } from 'react'

import { TooltipProps } from './TooltipProps'

import { ReactTooltipMultiline } from './styles'

export const Tooltip: FC<TooltipProps> = memo((props) => {
  const { id, type, children, content, event = 'hover', place = 'top', isVisible = true } = props

  return (
    <>
      {cloneElement(children, { ['data-tooltip-id']: id })}
      {(isValidElement(content) || typeof content === 'string') && isVisible && (
        <>
          <ReactTooltipMultiline id={id} place={place} variant={type} events={[event]} clickable delayShow={150}>
            {content}
          </ReactTooltipMultiline>
        </>
      )}
    </>
  )
})
