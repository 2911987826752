import { Contact } from 'commons/types/DTO/bank'
import { ApiReadResponse } from 'commons/types/api'
import { ContactListWebPageRequest } from 'commons/types/contracts/api/bank/contactList'
import { Request } from 'commons/utils/request'

export const getContactList = (id: number) =>
  Request.post<ApiReadResponse<Contact>>(Request.urls.bank.contactList, {
    _options: { filters: [{ field: 'id', type: 'eq', value: id }] }
  })

export const deleteContact = (id: number) => Request.delete<void>(Request.urls.bank.contact, { id })

export const saveContact = (contact: Contact) => {
  const fn = contact.id ? Request.put : Request.post
  return fn<Contact>(Request.urls.bank.contact, contact)
}

export type DisconnectContactProp = {
  id: number
  companyId: number
}

export const disconnectContactFromCompany = (opts: DisconnectContactProp) =>
  Request.post<void>(Request.urls.bank.contactDisconnect, { id: opts.id, company_id: opts.companyId })

export const getContactListWebPage = (opts: ContactListWebPageRequest) =>
  Request.post(Request.urls.bank.contactListWebPage, opts)
