import styled from 'styled-components'

export const Controls = styled.div`
  position: sticky;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
`

export const Wrapper = styled.div`
  position: relative; // timeline positioning absolute
  padding: 0 50px;
  height: 66px;
  background-color: ${(p) => p.theme.uiKit.colors.global.general.main};
  border-top: 1px solid ${(p) => p.theme.uiKit.colors.global.grey.grey300};
  display: flex;
  align-items: center;
  justify-content: space-between;
`
