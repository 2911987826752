export enum ApplicationStatus {
  RFI_PENDING = 'rfi_pending',
  RFI_REVIEW = 'rfi_review',
  DOCUMENT_REQUEST_PENDING = 'document_request_pending',
  DOCUMENT_REQUEST_REVIEW = 'document_request_review',
  DOCUMENT_REQUEST_SUMBITTED = 'document_request_submitted',
  DOCUMENT_REQUEST_REVIEWED = 'document_request_reviewed',
  PENDING_CUSTOMER = 'pending_customer',
  PENDING_CUSTOMER_REVIEW = 'pending_customer_review',
  CUSTOMER = 'customer',
  DENIED_CUSTOMER = 'denied_customer'
}
