import { TaskRelatedEntityType } from 'commons/types/enums'

const map: { [key: string]: string } = {
  [TaskRelatedEntityType.DOCUMENT_PERIOD]: 'Document period',
  [TaskRelatedEntityType.REPORT_WHOLESALE]: 'Report wholesale',
  [TaskRelatedEntityType.REPORT_RETAIL]: 'Report retail',
  [TaskRelatedEntityType.BIOTRACK_CREDS]: 'BIO track creds',
  [TaskRelatedEntityType.METRC_CREDS]: 'METRC creds'
}

export const getTaskRelatedEntityTypeLabel = (name: string) => map[name] || name
