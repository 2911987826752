import { CompanyWebPage } from './CompanyWebPage'

type FilterValue = { label: string; value: string }

export type CompanyListWebPageResponse = {
  enableAddNew: boolean
  relationships: CompanyWebPage[]
  relationshipsActiveValues: FilterValue[]
  relationshipsBusinessTypeValues: FilterValue[]
  relationshipsCustomerStatusValues: FilterValue[]
  relationshipsEntityTypeValues: FilterValue[]
  relationshipsIsHoldingValueValues: FilterValue[]
  relationshipsScopeValues: FilterValue[]
  relationshipsTotalCount: number
}
