import SC from 'styled-components'

export const DueRow = SC.div`
  display: flex;
  align-items: center;
`

export const DueCount = SC.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  
  i {
    color: ${(p) => p.theme.uiKit.colors.theme.negative.main};
    margin-right: 5px;
  }
`

export const TooltipContent = SC.span`
  display: flex;
  align-items: center;
`
