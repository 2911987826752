import styled from 'styled-components'
import { Link } from 'ui/components'

export const Box = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 512px;
  height: 315px;
  padding: 60px;
`

export const Bg = styled.i`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`

export const Header = styled.h1`
  font-size: 36px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.7;
  letter-spacing: normal;
  color: #000000;
  z-index: 1;
  position: relative;
`

export const Message = styled.div`
  width: 254px;
  margin: 14px 0 12px 1px;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: #000000;
  z-index: 1;
  position: relative;
`

export const BackLink = styled(Link)`
  position: relative;
  z-index: 1;
`
