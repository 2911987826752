import React, { FC, memo, useCallback, useState } from 'react'

import { useSourceFileDelete, useSourceFileUpload } from 'commons/hooks/bank/salesData'
import { UploadedFile } from 'commons/types/DTO/bank/salesDataSourceWebPage'
import { TableDataQa } from 'commons/types/enums'
import { Request } from 'commons/utils/request'
import { handlerServerError } from 'errors'
import { DownloadLink, Layer, PageSection, Table, Toast } from 'ui/components'
import { TableButton } from 'ui/components/Buttons'
import { formatDateTime } from 'ui/components/DateTime'
import { useConfirmation } from 'ui/hooks'

import { UploadedFilesTableProps } from './UploadedFilesTableProps'
import { UploadButton } from './components/UploadButton/UploadButton'

export const UploadedFilesTable: FC<UploadedFilesTableProps> = memo((props) => {
  const { webPage, invalidate } = props
  const [fileToDelete, setFileToDelete] = useState<number>()

  const { routine: uploadFile, isLoading: isFileUploading } = useSourceFileUpload({
    onSuccess: async () => {
      Toast.successSmall('File was uploaded.')
      invalidate()
    },
    onError: (error) => () => handlerServerError(error)
  })

  const { routine: deleteUploadedFile, isLoading: isUploadedFileDeleting } = useSourceFileDelete({
    onSuccess: async () => {
      Toast.successSmall('File was deleted.')
      invalidate()
    },
    onError: (error) => () => handlerServerError(error)
  })

  const handleUpload = useCallback(
    (files: File[]) => {
      files.forEach(async (file) => {
        const form = new FormData()
        form.append('salesDataSourceId', webPage.salesDataSourceID.toString())
        form.append('file', file)
        await uploadFile(form)
      })
    },
    [webPage.salesDataSourceID, uploadFile]
  )

  const { Confirmation: FileDeleteConfirmation, open: fileDeleteConfirmationOpen } = useConfirmation({
    message: `Are you sure you want to delete this file?`,
    onConfirm: () => deleteUploadedFile(fileToDelete),
    onCancel: () => setFileToDelete(null),
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  return (
    <PageSection
      title="Uploaded Files"
      anchor="uploaded_files"
      actions={webPage.enableUploadFiles && <UploadButton onUpload={handleUpload} />}
    >
      <Layer shadowed rounded>
        <Table
          dataQa={TableDataQa.BP_SALESDATA_SOURCE_DETAILS_UPLOADED_FILES}
          loading={isFileUploading || isUploadedFileDeleting}
          columns={[
            {
              title: 'File Name',
              width: '300px',
              sortable: false,
              align: 'left',
              render: (file: UploadedFile) => (
                <DownloadLink
                  direct
                  parametersForm={{ id: file.id }}
                  baseUrl={Request.urls.bank.salesDataSourceFileDownload}
                  name={file.fileName}
                  handleError={(error) => Toast.error('Error', error)}
                  method="post"
                >
                  {file.fileName}
                </DownloadLink>
              )
            },
            {
              title: 'Uploaded Date',
              width: '300px',
              sortable: false,
              align: 'left',
              render: ({ uploadedDate }) => <>{formatDateTime(uploadedDate, true)}</>
            },
            {
              title: '',
              sortable: false,
              align: 'right',
              render: (file: UploadedFile) => (
                <>
                  {file.enableDelete && (
                    <TableButton
                      face="neutral"
                      onClick={() => {
                        setFileToDelete(file.id)
                        fileDeleteConfirmationOpen()
                      }}
                    >
                      Delete
                    </TableButton>
                  )}
                </>
              )
            }
          ]}
          dataSource={webPage.uploadedFiles.map((file) => ({ item: file }))}
        />
        <FileDeleteConfirmation />
      </Layer>
    </PageSection>
  )
})
