import React, { FC } from 'react'

import { FieldProps, FormInputProps } from 'brief-form'
import { Questionnaire } from 'commons/types/DTO/bank/quarterlyReport/questionnaire'
import { Validators } from 'ui/components/Form'
import { TextareaInput } from 'ui/components/InputV2'
import { Col2x, FormRow } from 'ui/themes/globalStyles'

import { SecondText } from '../../style'

type FollowUpItemsProps = {
  Field: <Input extends React.ComponentType<FormInputProps<any, any>>>(
    props: FieldProps<Input, Questionnaire>
  ) => JSX.Element
}

export const FollowUpItems: FC<FollowUpItemsProps> = ({ Field }) => (
  <>
    <SecondText>
      <i>Follow Up FormRow:</i>
    </SecondText>
    <FormRow>
      <FormRow>
        <Col2x>
          <Field
            required
            name="followUpItemsNotes"
            label=""
            input={TextareaInput}
            inputProps={{}}
            validator={Validators.required.field}
          />
        </Col2x>
      </FormRow>
    </FormRow>
  </>
)
