import React, { memo, useEffect, useRef, useState } from 'react'

import styled from 'styled-components'

interface Properties {
  startedAt: number
  onRetry: () => any
  dark?: boolean
}

const INTERVAL_IN_SECONDS = 30

//region Styled

const Link = styled.span<{ $disabled: boolean; $dark: boolean }>`
  cursor: ${(p) => (p.$disabled ? 'text' : 'pointer')};
  display: block;
  font-size: 13px;
  color: ${(p) => (p.$dark ? '#000' : '#fff')};
  font-weight: 400;

  &:hover {
    text-decoration: underline;
  }

  b {
    font-size: 16px;
    font-weight: 400;
  }
`

//endregion

export const RetryLink = memo(({ startedAt, onRetry, dark }: Properties) => {
  const [left, setLeft] = useState(INTERVAL_IN_SECONDS)
  const timer = useRef<any>(undefined)

  /**
   * Timer ref can hold three types of values. "undefined" means that timer never has been started.
   * "null" - means that it was started and now it's stopped. Number value means that
   * timer is currently running. We need this approach to skip initial render, because we
   * don't want user to see countdown until he pushed "retry" first time.
   */

  // Update "seconds left" value.
  const timerHandler = () => {
    const secondsLeft = INTERVAL_IN_SECONDS - Math.round((Date.now() - startedAt) / 1000)
    setLeft(secondsLeft)
    if (secondsLeft <= 0) {
      clearInterval(timer.current)
      timer.current = null
      setLeft(INTERVAL_IN_SECONDS)
    }
  }

  // Setup timer to check ability to retry challenge.
  useEffect(() => {
    // Skip initial render.
    if (typeof timer.current !== 'undefined') {
      timer.current = setInterval(timerHandler, 1000)
    } else {
      timer.current = null
    }
  }, [startedAt])

  // Kill timer on component destroy.
  useEffect(() => () => clearInterval(timer.current), [])

  return (
    <div>
      <Link
        $disabled={!!timer.current}
        $dark={!!dark}
        role="button"
        tabIndex={0}
        onClick={timer.current ? undefined : onRetry}
      >
        Retry sending code {timer.current ? <b>{`in ${left}s`}</b> : ''}
      </Link>
    </div>
  )
})
