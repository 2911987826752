import React, { FC, memo, useCallback } from 'react'

import { Form } from 'brief-form'
import { Select } from 'ui/components/Select'
import { FilterPanel } from 'ui/components/Table'

import { FilterProps } from './FilterProps'

import * as SC from './styles'

export const Filter: FC<FilterProps> = memo((props) => {
  const { form, riskLevelOptions, entityTypeOptions } = props
  const { config, Field, isDirty, set } = form
  const clear = useCallback(() => set({ reset: true }), [set])

  return (
    <FilterPanel isDirty={isDirty} onReset={clear}>
      <Form config={config}>
        <SC.Box>
          <SC.Wrapper>
            <Field
              name="riskLevel"
              label="Risk Level"
              input={Select}
              inputProps={{
                data: riskLevelOptions
              }}
            />
          </SC.Wrapper>
          <SC.Wrapper>
            <Field
              name="entityType"
              label="Entity Type"
              input={Select}
              inputProps={{
                data: entityTypeOptions
              }}
            />
          </SC.Wrapper>
        </SC.Box>
      </Form>
    </FilterPanel>
  )
})
