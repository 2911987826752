import React, { memo } from 'react'

import { BsaSar } from 'commons/types/DTO/bank/bsa'
import { formatDateTime } from 'ui/components/DateTime'
import { IconSize } from 'ui/components/Icon'
import { Info, InfoSet, InfoSetDirection } from 'ui/components/Info'
import { PageSection, PageSectionFace } from 'ui/components/Page'
import { TextMask } from 'ui/components/TextMask'
import { Col, Row } from 'ui/themes/globalStyles'

interface Props {
  item: BsaSar
}

export const FilingInstitutionContactInformation = memo(({ item }: Props) => (
  <PageSection face={PageSectionFace.THIRD} title="Step 1. Filing Institution Contact Information" anchor="step1">
    <InfoSet direction={InfoSetDirection.COLUMN}>
      <Row>
        <Col>
          <Info label="79. Type of financial institution">
            {item.institution_contact_info?.type === 'Other' && item.institution_contact_info?.type_other}
            {item.institution_contact_info?.type !== 'Other' && item.institution_contact_info?.type}
          </Info>
        </Col>
        <Col>
          <Info label="75. Primary federal regulator">{item.institution_contact_info?.federal_regulator}</Info>
        </Col>
      </Row>
      <Row>
        <Info label="76. Filer Name	">{item.institution_contact_info?.filer_name}</Info>
      </Row>
      <Row>
        <Col>
          <Info label="77. TIN">
            {item.institution_contact_info?.tin ? (
              <TextMask text={item.institution_contact_info?.tin} iconSize={IconSize.XS} />
            ) : null}
          </Info>
        </Col>
        <Col>
          <Info label="78. TIN type">{item.institution_contact_info?.tin_type}</Info>
        </Col>
      </Row>
      <Row>
        <Col>
          <Info label="81. Filing institution ID Type">{item.institution_contact_info?.id_type}</Info>
        </Col>
        <Col>
          <Info label="Number">{item.institution_contact_info?.id_number}</Info>
        </Col>
      </Row>
      <Row>
        <Info label="82-86. Address">
          <div>{item.institution_contact_info?.address}</div>
          <div>
            {[
              item.institution_contact_info?.city,
              item.institution_contact_info?.country === 'US' ? item.institution_contact_info?.state : null,
              item.institution_contact_info?.zip_code
            ]
              .filter(Boolean)
              .join(', ')}
          </div>
          <div>{item.institution_contact_info?.country}</div>
        </Info>
      </Row>
      <Row>
        <Info label="87. Alternate name">{item.institution_contact_info?.dba}</Info>
      </Row>
      <Row>
        <Info label="88. Internal control/file number">{item.institution_contact_info?.internal_control_number}</Info>
      </Row>
      <Row>
        <Col>
          <Info label="89. LE contact agency">{item.institution_contact_info?.le_contact_agency}</Info>
        </Col>
        <Col>
          <Info label="90. LE contact name">{item.institution_contact_info?.le_contact_name}</Info>
        </Col>
      </Row>
      <Row>
        <Col>
          <Info label="91. LE contact phone number">{item.institution_contact_info?.le_contact_phone}</Info>
        </Col>
        <Col>
          <Info label="Ext.">{item.institution_contact_info?.le_contact_phone_ext}</Info>
        </Col>
      </Row>
      <Row>
        <Col>
          <Info label="92. LE contact date">{item.institution_contact_info?.le_contact_date}</Info>
        </Col>
      </Row>
      <Row>
        <Col>
          <Info label="93. Contact office">{item.institution_contact_info?.contact_office}</Info>
        </Col>
        <Col>
          <Info label="94. Phone number">{item.institution_contact_info?.phone}</Info>
        </Col>
      </Row>
      <Row>
        {item.filed_date && (
          <Col>
            <Info label="57. Date Filed">{formatDateTime(item.filed_date)}</Info>
          </Col>
        )}
        <Col>
          <Info label="Ext.">{item.institution_contact_info?.phone_ext}</Info>
        </Col>
      </Row>
    </InfoSet>
  </PageSection>
))
