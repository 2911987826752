import React, { useEffect, useRef, useState } from 'react'

import { ApplicationStatus } from 'commons/types/enums'
import { Layer } from 'ui/components/Layer'
import { Panel } from 'ui/components/Panel'

import { Questionnaire } from '../Questionnaire'
import { CompanyDetailsProps } from './CompanyDetailsProps'
import { CompanyInformation, ContactsInformation, LicenseInformation } from './components'

import { CompanyWrapper, Header, Wrapper } from './styles'

export const CompanyDetails = ({ application }: CompanyDetailsProps) => {
  const initialIndex = application.relationships.length > 1 ? 0 : -1
  const [expandedCompany, setExpandedCompany] = useState(initialIndex)
  const firstRender = useRef<boolean>(true)
  const prevStatus = useRef<ApplicationStatus | null>(application.progressTimelineStatus)

  // MP-7001 after click "Send Document Requst" companies items should collapse
  const checkExpanded = (index) => {
    // status changed to "ApplicationStatus.DOCUMENT_REQUEST_PENDING"
    const isChangedToCollapseStatus =
      prevStatus.current !== ApplicationStatus.DOCUMENT_REQUEST_PENDING &&
      application.progressTimelineStatus === ApplicationStatus.DOCUMENT_REQUEST_PENDING

    // first render and status "ApplicationStatus.DOCUMENT_REQUEST_PENDING"
    // => checkExpanded => true
    const isFirstRenderCollapseStatus =
      firstRender.current && application.progressTimelineStatus === ApplicationStatus.DOCUMENT_REQUEST_PENDING

    const isShouldCollapsed = isChangedToCollapseStatus || isFirstRenderCollapseStatus

    const originalCondition = expandedCompany === index
    const result = isShouldCollapsed ? false : originalCondition

    return result
  }

  useEffect(() => {
    firstRender.current = false
  }, [])

  useEffect(() => {
    prevStatus.current = application.progressTimelineStatus
  }, [application.progressTimelineStatus])

  return (
    <Wrapper>
      <Header>Company Details</Header>
      {application.relationships.map((company, index) => (
        <section id={`company_${index + 1}`} key={index}>
          <CompanyWrapper>
            <Layer rounded shadowed>
              <Panel
                title={`Company #${index + 1}`}
                expanded={checkExpanded(index)}
                onToggle={(expanded) => {
                  if (!expanded) {
                    setExpandedCompany(-1)
                  } else {
                    setExpandedCompany(index)
                  }
                }}
              >
                <CompanyInformation applicationRelationship={company} />
                {company?.licenses?.length > 0 && <LicenseInformation licenses={company.licenses} />}
                {company?.contacts?.length > 0 && <ContactsInformation contacts={company.contacts} />}
                {company?.questionnaire && <Questionnaire questionnaire={company.questionnaire} />}
              </Panel>
            </Layer>
          </CompanyWrapper>
        </section>
      ))}
    </Wrapper>
  )
}
