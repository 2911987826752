import styled from 'styled-components'

export const Wrapper = styled.div`
  margin: 40px 0 0 0;
`

export const Header = styled.div`
  background-color: #e2e2e2;
  margin: 0 -50px 30px -50px;
  padding: 20px 50px;
  font-size: 22px;
`
export const CompanyWrapper = styled.div`
  margin-top: 20px;
`
