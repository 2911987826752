import React, { FC, memo } from 'react'

import SC from 'styled-components'

import { getIconFile } from './getIconFile'
import { IconName } from './iconName'
import { IconSize } from './iconSize'

interface Props extends React.HTMLAttributes<HTMLElement> {
  name: IconName
  size?: IconSize | number
}

const RawIcon: FC<Props> = memo(({ name, ...rest }) => {
  const file = getIconFile(name)
  return <i dangerouslySetInnerHTML={{ __html: file }} {...rest} />
})

const SVG = SC(RawIcon)<{
  size?: IconSize | number
}>`
  display: inline-block;
  width: ${(p) => p.size || IconSize.M}px;
  height: ${(p) => p.size || IconSize.M}px;
  transition: border-radius .1s ease-in, background-color .1s ease-in, padding .1s ease-in;
  
  > svg {
    margin: 0;
    padding: 0;
    width: ${(p) => p.size || IconSize.M}px;
    height: ${(p) => p.size || IconSize.M}px;
  }
`

export const IconV2: FC<Props> = memo((props) => <SVG {...props} />)
