import { Dispatch, SetStateAction } from 'react'
import { useMutation } from 'react-query'

import { Request } from 'commons/utils/request'
import { handlerServerError } from 'errors'

export const getAdverseMediaPublicationContent = (publicationId: number) =>
  Request.post(Request.urls.bank.adverseMediaPublicationContent, {
    publication_id: publicationId
  })

export interface AdverseMediaPublicationContent {
  content: string
  copyright_holder: string
  copyright_notice: string
}

export const useAdverseMediaPublicationContent = (
  setPublicationContent: Dispatch<SetStateAction<null | AdverseMediaPublicationContent>>
) =>
  useMutation((publicationId: number) => getAdverseMediaPublicationContent(publicationId), {
    onError: (error) => handlerServerError(error),
    onSuccess: async (data: AdverseMediaPublicationContent) => {
      setPublicationContent(data)
    }
  })
