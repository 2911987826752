import { useContext } from 'react'

import { AuthContext } from '../../AuthContext'
import { UseLogoutReturnValue } from './useLogoutAuth'

/**
 * this hook just get `logout` func from `AuthContainer` context
 *
 * implementation of logout in ./useLogoutAuth.ts
 */
export const useLogout = (): UseLogoutReturnValue => useContext(AuthContext)
