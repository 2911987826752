export const applicationBankAccountsDialogStateEmptyValue = {
  showFormErrors: false,
  isOldDirty: false,
  selectedRelationshipIndex: 0,
  isAddBankAccountFormValid: false,
  relationshipsBankAccounts: undefined,
  formDataList: {},
  formExpandedList: {},
  isReadyForSubmit: false, // show submit button
  woSelectedRelationship: false // `setinit` without set selected relationship
}
