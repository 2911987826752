import styled from 'styled-components'
import { Link } from 'ui/components/Link'

const riskLevelColors = {
  Unknown: '#B63C3C',
  Low: '#45D5B4',
  'Acknowledged medium': '#B63C3C',
  Medium: '#B63C3C',
  'Acknowledged high': '#FA5252',
  High: '#FA5252'
}

export const RiskLevel = styled.span<{ $riskLevel: string }>`
  font-weight: bold;
  color: ${(p) => riskLevelColors[p.$riskLevel]};
`

export const ActionLink = styled(Link)<{ $needsReview: boolean }>`
  font-weight: bold;

  a,
  a:hover {
    text-decoration: none;
    color: ${(p) => (p.$needsReview ? '#FA5252' : '#868686')};
  }
`
