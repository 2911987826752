export type LicenseType = {
  value: string
  label: string
}

export const licenseTypes: LicenseType[] = [
  { value: 'med', label: 'Medical' },
  { value: 'rec', label: 'Recreational' },
  { value: 'comp', label: 'Comprehensive' },
  { value: 'medrec', label: 'Medical and Recreational' }
]
