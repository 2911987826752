import { LicenseAddressInformationFormValue } from '../../../../types'

export const mapLicenseAddressInformationForm = ({
  street,
  city,
  postalCode,
  state
}: LicenseAddressInformationFormValue) => ({
  street,
  city,
  postalCode,
  state
})
