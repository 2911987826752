import styled from 'styled-components'
import { Button } from 'ui/components/Buttons'
import { UrlInput as _UrlInput } from 'ui/components/InputV2'

export const ModalContent = styled.div`
  padding: 30px;
`

export const Row = styled.div`
  display: flex;
`

export const CopyButton = styled(Button)`
  height: auto;
`

export const UrlInput = styled(_UrlInput)`
  width: 360px;
`
