import SC, { css } from 'styled-components'
import { Button } from 'ui/components/Buttons'

export const Inner = SC.div`${css`
  width: 500px;
  padding: 27px 42px 27px 40px;
`}`

export const SaveButton = SC(Button)`${css`
  margin-top: 27px;
`}`
