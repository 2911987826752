import React, { FC, memo } from 'react'

import { ClientUsersPage } from './components/ClientUsersPage'
import { useClientUsers } from './hooks'

const ClientUsers: FC = memo(() => {
  const { isFetching, users, filterForm, reloadClientUserList, clearHandler } = useClientUsers()

  return (
    <ClientUsersPage
      data={users}
      isFetching={isFetching}
      filterForm={filterForm}
      reloadClientUserList={reloadClientUserList}
      clearHandler={clearHandler}
    />
  )
})

export default ClientUsers
