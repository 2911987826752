import { useCallback, useMemo, useState } from 'react'

import { BeforeFormChangeHandler } from 'brief-form'
import { FilterAlertsWebPage } from 'commons/service/bank/alerts'
import { AlertsWebPage } from 'commons/types/DTO/bank/alerts'
import { useTableState } from 'ui/components'

import { UploadsPendingApprovalTableState } from '../types/UploadsPendingApprovalTableState'

type RelatedCompanyData = {
  subTitle: string
  companyList: Array<{ id: number; name: string }>
}

export const useComponentsEssence = (
  onAlertFilterChange: (filter: FilterAlertsWebPage) => void,
  webPage: AlertsWebPage
) => {
  const [loading, setLoading] = useState(false)
  const [relatedCompanyData, setRelatedCompanyData] = useState<RelatedCompanyData | null>(null)
  const [showRelatedCompanyList, setShowRelatedCompanyList] = useState(false)
  const { config } = webPage

  const initialFilter = useMemo(
    () => ({
      uploadsType: config.uploadsType,
      uploadsCompanyID: config.uploadsCompanyID,
      uploadsSortBy: config.uploadsSortBy,
      uploadsSortDesc: config.uploadsSortDesc,
      uploadsOffset: config.uploadsOffset,
      uploadsLimit: config.uploadsLimit
    }),
    []
  )

  const [tableFilter, setTableFilter] = useState(initialFilter)

  const onBeforeFilterChange: BeforeFormChangeHandler<UploadsPendingApprovalTableState> = useCallback(
    ({ value, errors }) => {
      setLoading(true)

      const filter = {
        ...tableFilter,
        uploadsOffset: undefined,
        uploadsLimit: undefined,
        uploadsType: value.type,
        uploadsCompanyID: value.company_id
      }

      setTableFilter(filter)

      onAlertFilterChange(filter)

      setLoading(false)

      return {
        value: {
          ...value
        },
        errors
      }
    },
    [onAlertFilterChange, tableFilter]
  )

  const tableState = useTableState<UploadsPendingApprovalTableState>({
    onBeforeFilterChange,
    filter: {
      type: config.uploadsType,
      company_id: config.uploadsCompanyID
    },
    order: [{ field: config.uploadsSortBy, direction: config.uploadsSortDesc ? 'DESC' : 'ASC' }]
  })

  const changePagination = useCallback(
    async (page: number, size: number) => {
      setLoading(true)

      tableState.onPageChange(page, size)

      const filter = { ...tableFilter, uploadsOffset: (page - 1) * size, uploadsLimit: size }

      setTableFilter(filter)

      await onAlertFilterChange(filter)

      setLoading(false)
    },
    [onAlertFilterChange, tableState, tableFilter]
  )

  const onSort = useCallback(
    async (direction: 'DESC' | 'ASC', field: string) => {
      const isDesc = direction === 'DESC'

      tableState.onOrderChange({ field, direction })

      setLoading(true)

      const filter = {
        ...tableFilter,
        uploadsSortBy: field || undefined,
        uploadsSortDesc: isDesc || undefined
      }

      setTableFilter(filter)

      await onAlertFilterChange(filter)
      setLoading(false)
    },
    [onAlertFilterChange, tableFilter, tableState]
  )

  const onClear = async () => {
    tableState.onFilterClear()
    tableState.filterForm.set({ reset: true })

    setLoading(true)

    setTableFilter(initialFilter)

    await onAlertFilterChange(initialFilter)

    setLoading(false)
  }

  const openRelatedCompanyList = useCallback((subTitle, companyList) => {
    setRelatedCompanyData({
      subTitle: subTitle,
      companyList: companyList
    })
    setShowRelatedCompanyList(true)
  }, [])

  const closeRelatedCompanyList = useCallback(() => {
    setShowRelatedCompanyList(false)
  }, [])

  return {
    loading,
    relatedCompanyData,
    showRelatedCompanyList,
    tableState,
    changePagination,
    onSort,
    onClear,
    openRelatedCompanyList,
    closeRelatedCompanyList
  }
}
