import React, { useCallback } from 'react'

import { useFormData } from 'brief-form'
import { LicenseManageRequest } from 'commons/types/DTO/bank'
import { useConfirmation } from 'ui/hooks'

import { transformEditLicenseFormValues } from './formHelper'

export const useBehavior = (
  initial: LicenseManageRequest,
  deleteHandler: (id: number) => Promise<void>,
  saveHandler: (payload: LicenseManageRequest) => Promise<LicenseManageRequest>
) => {
  if (!initial.id) initial.active = true
  const { config, Field, Form, validate } = useFormData<LicenseManageRequest>({ initialValue: initial })

  const deleteLicense = useCallback(() => deleteHandler(initial.id), [initial, deleteHandler])

  const { Confirmation, open } = useConfirmation({
    message: (
      <span>
        Are you sure want to delete License <i>{initial.name}</i>?
      </span>
    ),
    onConfirm: deleteLicense,
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  const saveLicense = useCallback(() => {
    if (!validate({ updateFields: true }).valid) {
      return Promise.resolve(config.value)
    }
    return saveHandler({ ...config.value, ...transformEditLicenseFormValues(config.value) })
  }, [validate, config, saveHandler])

  return {
    Form,
    Field,
    config,
    saveLicense,
    Confirmation,
    deleteLicense: open
  }
}
