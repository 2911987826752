import { useMutation, useQueryClient } from 'react-query'

import { useNavigate } from '@tanstack/react-router'
import { Request } from 'commons/utils/request'
import { handlerServerError } from 'errors'

interface DeleteAnnualReviewProp {
  id: number
  company_id: number
  annual_review_id: number
}

export const useDeleteAnnualReview = (companyId: number) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  return useMutation((props: DeleteAnnualReviewProp) => Request.delete(Request.urls.bank.annualReview, { ...props }), {
    onError: (error) => handlerServerError(error),
    onSuccess: () => {
      if (!companyId) {
        queryClient.invalidateQueries(['tables', 'annual-review-list-table'])
      } else {
        navigate({ to: '/relationships/$companyId', params: { companyId } })
      }
    }
  })
}
