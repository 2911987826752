import { useCallback } from 'react'

import { ReportFile } from 'commons/types/DTO/bank'
import { handlerServerError } from 'errors'
import { WriteHook, useMutationManager } from 'state-manager'

import { UploadFileRequest, uploadFile } from './UploadFileRequest'

export const useUploadFileRequest: WriteHook<UploadFileRequest, ReportFile> = (opts) =>
  useMutationManager<UploadFileRequest, ReportFile>(uploadFile, opts)

export const useUploadFileCreate = (mrId: number, invalidateMR: () => Promise<void>) => {
  const { routine, isLoading } = useUploadFileRequest({
    onSuccess: async () => {
      if (mrId) invalidateMR()
    },
    onError: (error) => handlerServerError(error)
  })

  const createFile = useCallback(
    (payload: UploadFileRequest) =>
      routine({
        file: payload.file,
        reportMonthlyId: payload.reportMonthlyId
      }),
    [routine]
  )

  return {
    createFile,
    isLoading
  }
}
