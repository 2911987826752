import React, { FC, memo } from 'react'

import { Icon } from '../../Icon'
import { IconButtonProps } from './IconButtonProps'

import * as SC from './styles'

export const IconButton: FC<IconButtonProps> = memo((props) => {
  const { children, face = 'neutral', disabled, icon, 'data-qa': dataQA, ...rest } = props

  const data_qa = typeof children === 'string' && !dataQA ? children : dataQA

  const isPositive = face === 'positive'
  const isNegative = face === 'negative'
  const isNeutral = face === 'neutral'

  return (
    <>
      {isPositive && (
        <SC.BoxPositive disabled={disabled} data-qa={data_qa} {...rest}>
          <SC.Inner>
            <Icon name={icon} size={24} /> {children}
          </SC.Inner>
        </SC.BoxPositive>
      )}
      {isNegative && (
        <SC.BoxNegative disabled={disabled} data-qa={data_qa} {...rest}>
          <SC.Inner>
            <Icon name={icon} size={24} /> {children}
          </SC.Inner>
        </SC.BoxNegative>
      )}
      {isNeutral && (
        <SC.BoxNeutral disabled={disabled} data-qa={data_qa} {...rest}>
          <SC.Inner>
            <Icon name={icon} size={24} /> {children}
          </SC.Inner>
        </SC.BoxNeutral>
      )}
    </>
  )
})
