import React, { memo, useMemo } from 'react'

import { TableDataQa } from 'commons/types/enums'
import { Dialog, DialogFace } from 'ui/components/Dialog'
import { Link } from 'ui/components/Link'
import { DataSourceItem, Table } from 'ui/components/Table'

import { SARInfo, SARListItem } from '../../List/types'

interface SARsInfoProps {
  item: SARListItem
  onClose: () => void
}

export const SARsInfo = memo((props: SARsInfoProps) => {
  const { item, onClose } = props

  const mappedSars: DataSourceItem<SARInfo>[] = useMemo(
    () =>
      item?.sarsInfo.map((el) => ({
        item: el
      })),
    [item?.sarsInfo]
  )

  return item ? (
    <Dialog
      id={`batch-${item.id}`}
      onClose={onClose}
      face={DialogFace.PRIMARY}
      title={`Batch ID ${item.id}`}
      subTitle="SARs in the Batch"
      headerStyle={{ width: 800 }}
    >
      <Table<SARInfo>
        dataQa={TableDataQa.BP_BATCH_SARS}
        columns={[
          {
            title: 'Name',
            align: 'left',
            render: (item) => (
              <Link to="/bsa/sars/$id" params={{ id: item.id }}>
                {item.name}
              </Link>
            )
          },
          {
            title: 'Status',
            key: 'status',
            align: 'left'
          }
        ]}
        dataSource={mappedSars}
      />
    </Dialog>
  ) : null
})
