import React from 'react'

import { CheckBox, FF } from 'ui/components'

import { useSARFormContext } from '../../../../../../../context'

export const J = () => {
  const { formApi } = useSARFormContext()
  return (
    <formApi.Field
      name="transactions_info.other_activities.j"
      children={(field) => (
        <FF
          field={field}
          input={(p) => (
            <CheckBox
              {...p}
              checkBoxLabel="j. Little or no concern for product performance penalties, fees, or tax consequences"
            />
          )}
          errorPosition="right"
        />
      )}
    />
  )
}
