import styled from 'styled-components'

export const Checkboxes = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px 10px;
  margin-bottom: 10px;
  flex-wrap: wrap;
`
