import React from 'react'

import { Title, Wrapper } from '../styles'
import { Hr } from 'ui/components/Hr'

import { CompanyInformationProps } from './CompanyInformationProps'
import { AccountDetails, AddressInformation, MailingAddress, RelationshipDetails } from './components'

export const CompanyInformation = ({ applicationRelationship }: CompanyInformationProps) => (
  <Wrapper>
    <Title>Company Information</Title>
    <RelationshipDetails applicationRelationship={applicationRelationship} />
    <Hr />
    <AccountDetails applicationRelationship={applicationRelationship} />
    <Hr />
    <AddressInformation applicationRelationship={applicationRelationship} />
    <Hr />
    <MailingAddress applicationRelationship={applicationRelationship} />
  </Wrapper>
)
