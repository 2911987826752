import { LicenseWebPageDocumentFilter, LicenseWebPageResponse } from 'commons/types/DTO/bank'
import { TableSortingChangeHandler, TableSortingOrder } from 'ui/components/Table'

export interface DocumentsProps {
  webPage: LicenseWebPageResponse
  licenseId: number
  companyId: number
  returnUrl: string
  isFetching: boolean
  onFilterChange: (filter: LicenseWebPageDocumentFilter) => void
  order: TableSortingOrder[]
  onOrderChange: TableSortingChangeHandler
  onDocumentDeleted: (id: number) => void
}
