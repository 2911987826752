import React, { FC, memo } from 'react'

import { formatCurrency } from 'commons/utils'
import { DateTime } from 'ui/components/DateTime'
import { Info, InfoSet, InfoSetDirection } from 'ui/components/Info'
import { Col, Row } from 'ui/themes/globalStyles'

import { AdditionalInformationProps } from './AdditionalInformationProps'
import { IsUSCitizen } from './IsUSCitizen'
import { PersonalAddress } from './PersonalAddress'
import { PhysicalMailingAddress } from './PhysicalMailingAddress'
import { SocialSecurityNumber } from './SocialSecurityNumber'

export const AdditionalInformation: FC<AdditionalInformationProps> = memo(({ contact }) => {
  const {
    birthdate,
    personalAddress,
    EINSSN,
    isUSCitizen,
    isCompanyDebtholder,
    debtAmount,
    debtMonthlyPaymentAmount,
    debtPaymentFrequency,
    debtMaturityDate,
    isCompanyOwner,
    ownershipInvestmentAmount,
    ownershipPercent
  } = contact

  const showAdditionalInformationBlock =
    'birthdate' in contact ||
    'personalAddress' in contact ||
    'physicalAddress' in contact ||
    'mailingAddress' in contact ||
    'EINSSN' in contact ||
    'isUSCitizen' in contact

  return isCompanyDebtholder || isCompanyOwner || showAdditionalInformationBlock ? (
    <InfoSet direction={InfoSetDirection.ROW} legend="Additional Information">
      {isCompanyDebtholder && (
        <>
          <Row>
            <Col>
              <Info label="Debt Amount">{debtAmount ? formatCurrency(debtAmount) : '---'}</Info>
            </Col>
            <Col>
              <Info label="Payment Frequency">{debtPaymentFrequency}</Info>
            </Col>
          </Row>
          <Row>
            <Col>
              <Info label="Monthly Payment">
                {debtMonthlyPaymentAmount ? formatCurrency(debtMonthlyPaymentAmount) : '---'}
              </Info>
            </Col>
            <Col>
              <Info label="Maturity Date">
                <DateTime date={debtMaturityDate} />
              </Info>
            </Col>
          </Row>
        </>
      )}
      {isCompanyOwner && (
        <>
          <Row>
            <Col>
              <Info label="Investment">
                {ownershipInvestmentAmount ? formatCurrency(ownershipInvestmentAmount) : '---'}
              </Info>
            </Col>
            <Col>
              <Info label="Ownership">{ownershipPercent}%</Info>
            </Col>
          </Row>
        </>
      )}

      {showAdditionalInformationBlock && (
        <div>
          {'birthdate' in contact && (
            <Info label="Date Of Birth">
              <DateTime date={birthdate} />
            </Info>
          )}
          {'personalAddress' in contact && <PersonalAddress address={personalAddress} />}
          {('physicalAddress' in contact || 'mailingAddress' in contact) && (
            <PhysicalMailingAddress contact={contact} />
          )}
          {'EINSSN' in contact && <SocialSecurityNumber EINSSN={EINSSN} />}
          {'isUSCitizen' in contact && <IsUSCitizen isUSCitizen={isUSCitizen} />}
        </div>
      )}
    </InfoSet>
  ) : (
    <></>
  )
})
