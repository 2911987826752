import React, { FC, memo, useContext } from 'react'

import { BankNotes, BlockDelimiter, WelcomeBlock } from '..'
import { Hint } from 'ui/components'

import { OnboardingFormContext } from '../OnboardingForm/OnboardingFormContext'

export const OnboardingDocuments: FC = memo(() => {
  const onboardingContext = useContext(OnboardingFormContext)
  const { state } = onboardingContext
  const { documentSign, isCurrentStepEditEnable } = state
  const { description, title } = documentSign

  return (
    <>
      <WelcomeBlock />
      <BlockDelimiter text="Contact with the bank" />
      <BankNotes bankNotes={state.bankNotes} readonly={!isCurrentStepEditEnable} />
      <BlockDelimiter text="On-Boarding Documents" />
      <Hint>
        <b>{title}</b>
        <br />
        {description}
      </Hint>
    </>
  )
})
