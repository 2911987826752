import React, { FC, memo } from 'react'

import { HeaderButtonProps } from './HeaderButtonProps'

import * as SC from './styles'

export const HeaderButton: FC<HeaderButtonProps> = memo((props) => {
  const { children, disabled, size = 'middle', overColoredPad = false, 'data-qa': dataQA, ...rest } = props

  const data_qa = typeof children === 'string' && !dataQA ? children : dataQA

  const isLarge = size === 'large'

  return (
    <>
      {isLarge && (
        <SC.BoxLarge $overColoredPad={overColoredPad} disabled={disabled} data-qa={data_qa} {...rest}>
          <SC.Inner>{children}</SC.Inner>
        </SC.BoxLarge>
      )}
      {!isLarge && (
        <SC.BoxMiddle $overColoredPad={overColoredPad} disabled={disabled} data-qa={data_qa} {...rest}>
          <SC.Inner>{children}</SC.Inner>
        </SC.BoxMiddle>
      )}
    </>
  )
})
