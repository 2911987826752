import React, { useEffect } from 'react'

import { FieldApi } from '@tanstack/react-form'
import { BsaSar } from 'commons/types/DTO/bank/bsa'
import { usAndTerritories } from 'commons/types/dictionaries'
import { FF, Select, Validators } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

const CountryRenderer = ({
  field,
  index
}: {
  field: FieldApi<BsaSar, 'institution_activities_info[0].country'>
  index: number
}) => {
  const { formApi } = useSARFormContext()
  const stateKeyName = `institution_activities_info[${index}].state` as 'institution_activities_info[0].state'

  useEffect(() => {
    if (typeof field.state.value !== 'undefined' && field.state.value !== 'US') {
      formApi.setFieldValue(stateKeyName, '', { dontUpdateMeta: true })
      const stateMeta = formApi.getFieldMeta(stateKeyName)
      formApi.setFieldMeta(stateKeyName, {
        ...stateMeta,
        errors: [],
        errorMap: {}
      })
    }
  }, [field.state.value, formApi, stateKeyName])

  return (
    <FF
      required
      label="65. Country"
      field={field}
      input={(p) => (
        <Select {...p} data={usAndTerritories} data-qa="65. Country" options-data-qa="65. Country option" />
      )}
    />
  )
}

export const Country = ({ index }: { index: number }) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={`institution_activities_info[${index}].country` as 'institution_activities_info[0].country'}
      validators={{
        onChange: ({ value }) => Validators.required.field(value)
      }}
      children={(field) => <CountryRenderer field={field} index={index} />}
    />
  )
}
