import React, { FC, memo } from 'react'

import { ButtonFace } from '../Button'
import { IconName } from '../Icon'
import { Layer } from '../Layer'

/**
 
{
  Actions,
  Body,
  Container,
  PrimaryHeader,
  PrimaryTitle,
  SecondaryHeader,
  SecondaryTitle,
  WrappedButton
}

 */
import { PanelProps } from './PanelProps'
import { PanelFace } from './panelFace'

import * as SC from './styles'

const panelFaceTitles = {
  [`${PanelFace.PRIMARY}`]: SC.PrimaryTitle,
  [`${PanelFace.SECONDARY}`]: SC.SecondaryTitle,
  [`${PanelFace.SMALL_PRIMARY}`]: SC.SmallPrimaryTitle
}

const panelFaceHeader = {
  [`${PanelFace.PRIMARY}`]: SC.PrimaryHeader,
  [`${PanelFace.SECONDARY}`]: SC.SecondaryHeader,
  [`${PanelFace.SMALL_PRIMARY}`]: SC.SmallPrimaryHeader
}

export const Panel: FC<PanelProps> = memo((props) => {
  const {
    children,
    expanded,
    title,
    face = PanelFace.PRIMARY,
    onToggle,
    actions,
    rounded,
    shadowed,
    stretch,
    collapsible = true,
    anchor,
    isArrowButton = false,
    disabled = false,
    titleTagAs = 'h1',
    blockOnClickOnTitle = false,
    ...rest
  } = props
  const [localExpanded, setLocalExpanded] = React.useState<boolean>(expanded || true)
  const Header = panelFaceHeader[face]
  const Title = panelFaceTitles[face]

  React.useEffect(() => {
    if (typeof expanded !== 'undefined') {
      setLocalExpanded(expanded)
    }
  }, [expanded, setLocalExpanded])

  const onLocalToggle = React.useCallback(() => {
    if (!disabled) {
      setLocalExpanded(!localExpanded)
      if (onToggle) {
        onToggle(!localExpanded)
      }
    }
  }, [localExpanded, setLocalExpanded, onToggle, disabled])

  let icon: IconName

  if (isArrowButton) icon = !localExpanded || disabled ? IconName.EXPANDING_ARROW_TOP : IconName.EXPANDING_ARROW_DOWN
  else icon = localExpanded ? IconName.MINUS : IconName.PLUS

  return (
    <Layer rounded={rounded} shadowed={shadowed} stretch={stretch} id={anchor} {...rest}>
      <SC.Container>
        {(collapsible || !!title || !!actions) && (
          <Header expanded={localExpanded} $disabled={disabled}>
            {collapsible && (
              <SC.WrappedButton
                face={ButtonFace.LINK}
                icon={icon}
                onClick={onLocalToggle}
                data-qa="toggle-filter-btn"
                $disabled={disabled}
              />
            )}
            <Title
              as={titleTagAs}
              $clicable={collapsible}
              onClick={collapsible && !blockOnClickOnTitle ? onLocalToggle : undefined}
            >
              {title}
            </Title>
            {!!actions && <SC.Actions>{actions}</SC.Actions>}
          </Header>
        )}
        <SC.Body expanded={localExpanded}>{localExpanded && children}</SC.Body>
      </SC.Container>
    </Layer>
  )
})
