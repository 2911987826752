import { CompanyFormValues } from '../../../types/state'

// eslint-disable-next-line import/no-unused-modules
export const getCompanyContactsEmailDict = (company: CompanyFormValues): Record<string, ''> => {
  const { contacts } = company
  const result = {}
  contacts.forEach((contactItem) => {
    const { email } = contactItem.details.formValues

    if (email) {
      result[email] = ''
    }
  })
  return result
}
