import React, { FC } from 'react'

import { Dialog, DialogFace, DialogFooterActions, DialogOverlay } from 'ui/components/Dialog'
import { FF, Validators } from 'ui/components/Form'
import { Hr } from 'ui/components/Hr'
import { Icon, IconName, IconSize } from 'ui/components/Icon'
import { EmailInput, TextInput } from 'ui/components/InputV2'
import { USPhoneInput } from 'ui/components/InputV2'
import { LeaveConfirmation } from 'ui/components/LeaveConfirmation'
import { CompanyMultiSelect, Select } from 'ui/components/Select'
import { Tooltip } from 'ui/components/Tooltip'
import { Col, FormRow } from 'ui/themes/globalStyles'

import { ManageUserDialogProps } from './ManageUserDialog.types'
import { useManageUserDialog } from './useManageUserDialog'

import { s } from './styles'

export const ManageUserDialog: FC<ManageUserDialogProps> = (props) => {
  const { initialValue, enableRoleSelector, roles } = props
  const rolesWithDescriptions = roles.filter((role) => role.description)
  const { Field, isDirty, isSubmitting, isFormReady, handleSubmit, rolesOptions, onCloseDialog } =
    useManageUserDialog(props)

  if (!initialValue || !isFormReady) {
    return null
  }

  return (
    <LeaveConfirmation preventLeaving={isDirty}>
      <Dialog
        id={`${initialValue.id ? 'edit' : 'add'}-user-dialog`}
        title={`${initialValue.id ? 'Edit' : 'Add New'} User`}
        face={DialogFace.PRIMARY}
        overlay={DialogOverlay.DARK}
        onClose={onCloseDialog}
        footerActions={[DialogFooterActions.SAVE, DialogFooterActions.CANCEL]}
        onSubmit={handleSubmit}
        isSubmitting={isSubmitting}
      >
        <s.DialogContent>
          <s.Content>
            <FormRow>
              <Col>
                <Field
                  name="firstName"
                  validators={{ onChange: ({ value }) => Validators.required.string(value) }}
                  children={(field) => (
                    <FF
                      required
                      field={field}
                      label="First Name"
                      input={(p) => <TextInput {...p} data-qa="First Name" maxLength={100} />}
                    />
                  )}
                />
              </Col>
              <Col>
                <Field
                  name="lastName"
                  validators={{ onChange: ({ value }) => Validators.required.string(value) }}
                  children={(field) => (
                    <FF
                      required
                      field={field}
                      label="Last Name"
                      input={(p) => <TextInput {...p} data-qa="Last Name" maxLength={100} />}
                    />
                  )}
                />
              </Col>
            </FormRow>
            <FormRow>
              <Col>
                <Field
                  name="email"
                  validators={{ onChange: ({ value }) => Validators.required.string(value) }}
                  children={(field) => (
                    <FF
                      required
                      field={field}
                      label="Email"
                      input={(p) => <EmailInput {...p} data-qa="Email" maxLength={100} />}
                    />
                  )}
                />
              </Col>
              <Col>
                <Field
                  name="phone"
                  children={(field) => (
                    <FF
                      field={field}
                      label="Phone"
                      input={(p) => <USPhoneInput {...p} data-qa="Phone" maxLength={25} />}
                    />
                  )}
                />
              </Col>
            </FormRow>
          </s.Content>
          <Hr thin={true} />
          {enableRoleSelector && (
            <s.Content>
              <FormRow>
                <Col>
                  <Field
                    name="role"
                    children={(field) => (
                      <FF
                        field={field}
                        label="Assign Role"
                        input={(p) => <Select {...p} data-qa="Role" options-data-qa="Role" data={rolesOptions} />}
                      />
                    )}
                  />
                </Col>
                {rolesWithDescriptions.length > 0 && (
                  <Col>
                    <s.TooltipContainer>
                      <Tooltip
                        id="name"
                        type="dark"
                        place="right"
                        content={
                          <s.TooltipContent>
                            {rolesWithDescriptions.map((role) => (
                              <div key={role.key}>
                                <b>{role.name}</b>
                                {` — ${role.description}`}
                              </div>
                            ))}
                          </s.TooltipContent>
                        }
                      >
                        <s.TooltipInside>
                          <Icon name={IconName.INFO} size={IconSize.S} />
                          Role Descriptions
                        </s.TooltipInside>
                      </Tooltip>
                    </s.TooltipContainer>
                  </Col>
                )}
              </FormRow>
            </s.Content>
          )}
          <Hr thin={true} />
          <s.Content>
            <FormRow>
              <Col>
                <Field
                  name="assignedCompaniesIds"
                  children={(field) => (
                    <FF
                      field={field}
                      label="Assign Companies"
                      input={(p) => (
                        <CompanyMultiSelect
                          {...p}
                          data-qa-input="Assigned Companies Input"
                          data-qa="Assigned Companies"
                        />
                      )}
                    />
                  )}
                />
              </Col>
            </FormRow>
          </s.Content>
        </s.DialogContent>
      </Dialog>
    </LeaveConfirmation>
  )
}
