import React, { ReactNode, cloneElement, useCallback, useState } from 'react'

import { ConfirmationDialog, DialogFace } from '../components/Dialog'

type Options<ConfirmationOpenParams> = {
  message: JSX.Element | string
  onConfirm: (params?: ConfirmationOpenParams) => void
  onCancel?: (params?: ConfirmationOpenParams) => void
  confirmationButtonText: string
  isConfirmationNegative?: boolean
  dialogId?: string
  title?: string | ReactNode
  subTitle?: string | ReactNode
  face?: DialogFace
  'data-qa'?: string
  isCancelButtonNeeded?: boolean
}

export const useConfirmation = <ConfirmationOpenParams,>(
  opts: Options<ConfirmationOpenParams>
): {
  Confirmation: () => JSX.Element
  open: (openParams?: ConfirmationOpenParams) => void
} => {
  const [isOpen, setOpen] = useState(false)
  const [openParams, setOpenParams] = useState<ConfirmationOpenParams>(undefined)
  const open = useCallback(
    (params?: ConfirmationOpenParams) => {
      setOpenParams(params)
      setOpen(true)
    },
    [setOpen]
  )

  const onConfirm = useCallback(() => {
    setOpen(false)
    opts.onConfirm(openParams)
  }, [opts, openParams])

  const onCancel = useCallback(() => {
    setOpen(false)
    if (opts.onCancel) {
      opts.onCancel(openParams)
    }
  }, [opts, openParams])

  const Confirmation = useCallback(
    () =>
      isOpen ? (
        <ConfirmationDialog
          id={opts.dialogId || 'confirmation-dialog'}
          data-qa={opts['data-qa'] || 'confirmation-dialog'}
          message={typeof opts.message === 'string' ? opts.message : cloneElement(opts.message, openParams || {})}
          onConfirm={onConfirm}
          onCancel={onCancel}
          isConfirmationNegative={!!opts.isConfirmationNegative}
          confirmationButtonText={opts.confirmationButtonText}
          title={opts.title}
          subTitle={opts.subTitle}
          face={opts.face}
          isCancelButtonNeeded={opts.isCancelButtonNeeded}
        />
      ) : null,
    [isOpen, opts, onConfirm, onCancel, openParams]
  )

  return {
    Confirmation,
    open
  }
}
