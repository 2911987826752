export enum FormPaths {
  NOTES = 'notes',
  COMPANY_INFORMATION = 'companyInformation',
  COMPANY_ACCOUNT_DETAILS = 'companyAccountDetails',
  COMPANY_PHYSICAL_ADDRESS = 'companyPhysicalAddress',
  COMPANY_MAILING_ADDRESS = 'companyMailingAddress',
  // licenses
  LICENSE_DETAILS = 'licenseDetails',
  LICENSE_ADDRESS_INFORMATION = 'licenseAddressInformation',

  // contacts
  CONTACT_DETAILS = 'details',
  ROLE_INFORMATION = 'roleInformation',
  CONTACT_ADDITIONAL_INFORMATION = 'contactAdditionalInformation',
  //
  QUESTIONNAIRE = 'questionnaire',
  PROFESSIONAL_PARTNERSHIPS = 'professionalPartnerships',

  // atms
  ATM_GENERAL_INFO = 'atmGeneralInfo',
  ATM_SERVICES = 'atmServices',
  //
  AVAILABLE_SERVICES = 'availableServices',
  ANTICIPATED_MONTHLY_ACTIVITY = 'anticipatedMonthlyActivity',
  ANTICIPATED_MONTHLY_ACTIVITY_NA = 'anticipatedMonthlyActivityNA',
  //
  COMPANY_MERCHANT_QUESTIONNAIRE = 'companyMerchantQuestionnaire'
}

export enum OnboardingFormActions {
  ON_CHANGE = 'onChange',
  SET_INIT_APPLICATION = 'setInitApplication',
  SET_IS_AUTOSAVE_UPDATING = 'setIsAutosaveUpdating',
  SET_HAS_CHANGES = 'setHasChanges',
  // ui
  SET_PREV_STEP = 'setPrevStep',
  SET_NEXT_STEP = 'setNextStep',
  SET_SHOW_CLIENT_NOTE_DIALOG = 'setShowClientNoteDialog',

  // errors, submit
  SET_IS_FORM_SUBMITTING = 'setIsFormSubmitting',
  SET_SUBMIT_ERRORS = 'setSubmitErrors',
  SET_ERRORS = 'setErrors',
  SET_GENERAL_ERRORS = 'setGeneralErrors',
  FLUSH_LAST_ERROR_COUNT = 'flushLastErrorCount',
  SET_CANCEL_CLIENT_NOTE_DIALOG = 'setCancelClientNoteDialog',

  ADD_COMPANY = 'addCompany',
  REMOVE_COMPANY = 'removeCompany',
  COPY_COMPANY_PHYSICAL_ADDRESS_TO_MAILING = 'copyCompanyPhysicalAddressToMailing',

  ADD_LICENSE = 'addLicense',
  REMOVE_LICENSE = 'removeLicense',

  ADD_CONTACT = 'addContact',
  REMOVE_CONTACT = 'removeContact',
  COPY_CONTACT = 'copyContact',

  ADD_ATM = 'addATM',
  REMOVE_ATM = 'removeATM'
}

export enum FormStatusMessages {
  ISNT_DIRTY = '',
  SAVING_IN_PROGERESS = 'Changes are saving...',
  HAS_CHANGES = 'You have unsaved changes',
  CHANGES_SAVED = 'Changes saved'
}

export enum PageLabels {
  GENERAL_INFORMATION = 'General Information',
  DOCUMENTS_UPLOADING = 'Documents Uploading',
  ON_BOARDING_DOCUMENTS = 'On-Boarding Documents',
  ACCOUNT_OPENED = 'Account Opened'
}

export enum OnboardingFormFieldNames {
  CLIENT_NOTES = 'clientNotes',

  // CompanyInformationForm
  COMPANY_ID = 'companyID', // hidden
  ENTITY_UID = 'entityUID', // hidden https://helioscompliance.atlassian.net/browse/MP-8528
  LEGAL_NAME = 'legalName',
  DBA = 'DBA',
  PHONE = 'phone',
  WEBSITE = 'website',
  DESCRIPTION = 'description',
  // CompanyPhysicalAddressForm
  COMPANY_STREET = 'street',
  COMPANY_CITY = 'city',
  COMPANY_STATE = 'state',
  COMPANY_COUNTRY = 'country',
  COMPANY_POSTAL_CODE = 'postalCode',
  // CompanyMailingAddressForm
  MAILING_STREET = 'mailingStreet',
  MAILING_CITY = 'mailingCity',
  MAILING_STATE = 'mailingState',
  MAILING_COUNTRY = 'mailingCountry',
  MAILING_POSTAL_CODE = 'mailingPostalCode',
  // LicenseDetailsForm
  LICENSE_ID = 'licenseID',
  LICENSE_NAME = 'name',
  TYPE = 'type',
  SUBTYPE = 'subtype',
  LICENSE_NUMBER = 'licenseNumber',
  POS_TYPE = 'POSType',
  LICENSE_PHONE = 'phone',
  POS_TYPE_OTHER = 'POSTypeOther',
  ISSUE_DATE = 'issueDate',
  EXPIRATION_DATE = 'expirationDate',
  LICENSE_METRC_API_KEY = 'licenseMetrcAPIKey',
  // LicenseAddressForm
  LICENSE_STREET = 'street',
  LICENSE_CITY = 'city',
  LICENSE_POSTAL_CODE = 'postalCode',
  LICENSE_STATE = 'state',
  // CompanyAccountDetailsForm
  BUSINESS_TYPE = 'businessType',
  ENTITY_TYPE = 'entityType',
  STATE_OF_INCORPORATION = 'stateOfIncorporation',
  EIN = 'EIN',
  PRE_LICENSE_MRB_INFO = 'preLicenseMRBInfo',
  // ContactDetailsForm
  CONTACT_ID = 'contactID',
  CONTACT_METADATA_ID = 'contactMetadataID',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  CONTACT_PHONE = 'phone',
  MOBILE = 'mobile',
  HOME_PHONE = 'homePhone',
  TITLE = 'title',
  OTHER_MJ_RELATED_BUSINESS = 'otherMJRelatedBusiness',
  BIO = 'bio',
  // RoleInformationForm
  OWNER = 'owner',
  DEBTHOLDER = 'debtholder',
  ACCOUNT_SIGNER = 'accountSigner',
  CONDUCTOR = 'conductor',
  // ContactAdditionalInformationForm
  DEBTHOLDER_DEBT_AMOUNT = 'debtholderDebtAmount',
  DEBTHOLDER_PAYMENT_FREQUENCY = 'debtholderPaymentFrequency',
  DEBTHOLDER_PAYMENT_AMOUNT = 'debtholderPaymentAmount',
  DEBTHOLDER_MATURITY_DATE = 'debtholderMaturityDate',
  OWNER_INVESTMENT_AMOUNT = 'ownerInvestmentAmount',
  OWNER_OWNERSHIP_PERCENT = 'ownerOwnershipPercent',
  BIRTHDATE = 'birthdate',
  EINSSN = 'EINSSN',
  STREET = 'street',
  CITY = 'city',
  STATE = 'state',
  COUNTRY = 'country',
  POSTAL_CODE = 'postalCode',
  CONTACT_MAILING_STREET = 'mailingStreet',
  CONTACT_MAILING_CITY = 'mailingCity',
  CONTACT_MAILING_STATE = 'mailingState',
  CONTACT_MAILING_COUNTRY = 'mailingCountry',
  CONTACT_MAILING_POSTAL_CODE = 'mailingPostalCode',
  US_CITIZEN = 'USCitizen',
  // QuestionnaireForm
  CONTACTS_FOREIGN_CONNECTIONS = 'contactsForeignConnections',
  STATES_LICENSED = 'statesLicensed',
  A_WHOLESALE_PRODUCTS = 'aWholesaleProducts',
  A_WHOLESALE_PRODUCTS_DESC = 'aWholesaleProductsDesc',
  B_TYPE_OF_PRODUCTS = 'bTypeOfProducts',
  C_TYPE_OF_ANCILLARY_PRODUCTS = 'cTypeOfAncillaryProducts',
  WHO_SUPPLIES_YOU = 'whoSuppliesYou',
  WHERE_IS_YOUR_SUPPLIER = 'whereIsYourSupplier',
  WHO_DO_YOUR_SUPPLY_GROWER = 'whoDoYourSupplyGrower',
  GROWER_CUSTOMERS_LOCATION = 'growerCustomersLocation',
  D_HOW_MANY_LOCATIONS = 'dHowManyLocations',
  E_ONLINE_ORDERS_FOR_PICKUP = 'eOnlineOrdersForPickup',
  F_MINOR_SELLING_ENSURE = 'fMinorSellingEnsure',
  G_PAYMENT_METHODS = 'gPaymentMethods',
  H_TRANSFER_TO_OTHER_STATES = 'hTransferToOtherStates',
  H_TRANSFER_TO_OTHER_STATES_DESC = 'hTransferToOtherStatesDesc',
  I_ARE_YOU_AFFILIATED = 'iAreYouAffiliated',
  I_ARE_YOU_AFFILIATED_DESC = 'iAreYouAffiliatedDesc',
  // ProfessionalPartnershipsForm
  PRO_PART_CURRENTLY_BANKED = 'proPartCurrentlyBanked',
  PRO_PART_PREV_CLOSURE_REASON = 'proPartPrevClosureReason',
  PRO_PART_CURRENT_SERVICES_CP_AS = 'proPartCurrentServicesCPAs',
  PRO_PART_CURRENT_SERVICES_LAW = 'proPartCurrentServicesLaw',
  PRO_PART_CURRENT_SERVICES_LOGISTIC = 'proPartCurrentServicesLogistic',
  PRO_PART_CURRENT_SERVICES_OTHER = 'proPartCurrentServicesOther',
  PRO_PART_CURRENT_SERVICES_LOGISTIC_DESC = 'proPartCurrentServicesLogisticDesc',
  PRO_PART_CURRENT_SERVICES_OTHER_DESC = 'proPartCurrentServicesOtherDesc',
  PRO_PART_LITIGATION = 'proPartLitigation',
  PRO_PART_STATE_ENFORCEMENTS = 'proPartStateEnforcements',
  PRO_PART_INTERNET_GAMBLING = 'proPartInternetGambling',
  PRO_PART_PAYROLL_SOFTWARE = 'proPartPayrollSoftware',
  // ATMInfoForm
  ATM_HAVE = 'ATMHave',
  ATM_OWN = 'ATMOwn',
  ATM_COUNT = 'ATMCount',
  ATM_REPLENISHING_THIRD_PARTY = 'ATMReplenishingThirdParty',
  ATM_REPLENISHING_COMPANY = 'ATMReplenishingCompany',
  // ATMServicesForm
  ATM_ADDRESS = 'ATMAddress',
  ATM_AVG_AMOUNT_REPLENISHED = 'ATMAvgAmountReplenished',
  ATM_MAX_AMOUNT = 'ATMMaxAmount',
  ATM_REPLENISH_FREQUENCY = 'ATMReplenishFrequency',
  ATM_FUNDS_FROM_BANK = 'ATMFundsFromBank',
  ATM_FUNDS_FROM_BANK_FALSE_DESC = 'ATMFundsFromBankFalseDesc',
  // AvailableServicesForm
  AVAIL_SERVICE_HOW_MANY_ACCOUNTS_IS_OPENING = 'availServiceHowManyAccountsIsOpening',

  // AnticipatedMonthlyActivityNAForm
  ACTIVITY_DEPOSITS_CHECKS_NA = 'activityDepositsChecksNA',
  ACTIVITY_DEPOSITS_CHECKS_ITEMS = 'activityDepositsChecksItems',
  ACTIVITY_DEPOSITS_CHECKS_AMOUNT = 'activityDepositsChecksAmount',
  ACTIVITY_DEPOSITS_CASH_NA = 'activityDepositsCashNA',
  ACTIVITY_DEPOSITS_CASH_ITEMS = 'activityDepositsCashItems',
  ACTIVITY_DEPOSITS_CASH_AMOUNT = 'activityDepositsCashAmount',
  ACTIVITY_DEPOSITS_OTHER_NA = 'activityDepositsOtherNA',
  ACTIVITY_DEPOSITS_OTHER_ITEMS = 'activityDepositsOtherItems',
  ACTIVITY_DEPOSITS_OTHER_AMOUNT = 'activityDepositsOtherAmount',
  ACTIVITY_WITHDRAWALS_CASH_NA = 'activityWithdrawalsCashNA',
  ACTIVITY_WITHDRAWALS_CASH_ITEMS = 'activityWithdrawalsCashItems',
  ACTIVITY_WITHDRAWALS_CASH_AMOUNT = 'activityWithdrawalsCashAmount',

  // AnticipatedMonthlyActivityForm
  ANTICIPATED_ACTIVITY_ACH_DEPOSITS = 'anticipatedActivityACHDeposits',
  ANTICIPATED_ACTIVITY_ACH_DEPOSITS_AMOUNT = 'anticipatedActivityACHDepositsAmount',
  ANTICIPATED_ACTIVITY_ACH_DEPOSITS_FREQUENCY = 'anticipatedActivityACHDepositsFrequency',
  ANTICIPATED_ACTIVITY_WIRES_INTERNATIONAL_AMOUNT = 'anticipatedActivityWiresInternationalAmount',
  ANTICIPATED_ACTIVITY_WIRES_INTERNATIONAL_FREQUENCY = 'anticipatedActivityWiresInternationalFrequency',
  ANTICIPATED_ACTIVITY_ACH_ORIGINATING_AMOUNT = 'anticipatedActivityACHOriginatingAmount',
  ANTICIPATED_ACTIVITY_ACH_ORIGINATING_FREQUENCY = 'anticipatedActivityACHOriginatingFrequency',
  ANTICIPATED_ACTIVITY_ATM_WITHDRAWALS_AMOUNT = 'anticipatedActivityATMWithdrawalsAmount',
  ANTICIPATED_ACTIVITY_ATM_WITHDRAWALS_FREQUENCY = 'anticipatedActivityATMWithdrawalsFrequency',
  ANTICIPATED_ACTIVITY_CASH_DEPOSITS_AMOUNT = 'anticipatedActivityCashDepositsAmount',
  ANTICIPATED_ACTIVITY_CASH_DEPOSITS_FREQUENCY = 'anticipatedActivityCashDepositsFrequency',
  ANTICIPATED_ACTIVITY_CHECK_DEPOSITS_AMOUNT = 'anticipatedActivityCheckDepositsAmount',
  ANTICIPATED_ACTIVITY_CHECK_DEPOSITS_FREQUENCY = 'anticipatedActivityCheckDepositsFrequency',
  ANTICIPATED_ACTIVITY_CASH_WITHDRAWALS_AMOUNT = 'anticipatedActivityCashWithdrawalsAmount',
  ANTICIPATED_ACTIVITY_CASH_WITHDRAWALS_FREQUENCY = 'anticipatedActivityCashWithdrawalsFrequency',
  ANTICIPATED_ACTIVITY_CHECK_WITHDRAWALS_AMOUNT = 'anticipatedActivityCheckWithdrawalsAmount',
  ANTICIPATED_ACTIVITY_CHECK_WITHDRAWALS_FREQUENCY = 'anticipatedActivityCheckWithdrawalsFrequency',
  ANTICIPATED_ACTIVITY_REMOTE_DEPOSITS_AMOUNT = 'anticipatedActivityRemoteDepositsAmount',
  ANTICIPATED_ACTIVITY_REMOTE_DEPOSITS_FREQUENCY = 'anticipatedActivityRemoteDepositsFrequency',
  ANTICIPATED_ACTIVITY_WIRES_DOMESTIC_ONLY_AMOUNT = 'anticipatedActivityWiresDomesticOnlyAmount',
  ANTICIPATED_ACTIVITY_WIRES_DOMESTIC_ONLY_FREQUENCY = 'anticipatedActivityWiresDomesticOnlyFrequency',
  ANTICIPATED_ACTIVITY_WIRES_DOMESTIC_AMOUNT = 'anticipatedActivityWiresDomesticAmount',
  ANTICIPATED_ACTIVITY_WIRES_DOMESTIC_FREQUENCY = 'anticipatedActivityWiresDomesticFrequency',
  ANTICIPATED_ACTIVITY_ATM_SETTLEMENT_AMOUNT = 'anticipatedActivityATMSettlementAmount',
  ANTICIPATED_ACTIVITY_ATM_SETTLEMENT_FREQUENCY = 'anticipatedActivityATMSettlementFrequency',

  // MerchantQuestionnaireForm
  LEGACY_CASH = 'legacyCash',
  MERCHANT_PROCESSING = 'merchantProcessing',
  MERCHANT_PROCESSING_DESC = 'merchantProcessingDesc',
  PRIMARY_METHOD_TO_FUND = 'primaryMethodToFund',
  CRYPTOCURRENCY_TRANSACTIONS = 'cryptocurrencyTransactions',
  REAL_TIME_PAYMENT_SERVICES = 'realTimePaymentServices'
}

export enum ParserNames {
  RFI_INFO = 'rfiInfo',
  COMPANY_ITEM = 'relationships'
}
