import { Reducer } from 'react'

import { ApplicationBankAccountsDialogState } from '../../types'
import { AddBankAccountDialogStateAction, AddBankAccountDialogStateActions } from './types'

export const addBankAccountDialogStateReducer: Reducer<
  ApplicationBankAccountsDialogState,
  AddBankAccountDialogStateAction
> = (state, action) => {
  switch (action.type) {
    case AddBankAccountDialogStateActions.ON_CHANGE:
      return { ...state, ...action.payload }

    default:
      return state
  }
}
