import {
  OnboardingDocumentCreateModeWebPage,
  OnboardingDocumentEditModeCompanyType,
  OnboardingDocumentEditModeWebPage
} from 'commons/types/DTO/bank/applications/onboardingDocuments'
import { keyBy, mapValues } from 'lodash'

import { MappedCompanyFormType } from '../../../types/MappedCompanyFormType'
import { SetupDocumentFormType } from '../../../types/SetupDocumentFormType'

export const mapResponseCompanies = (
  webPageData: OnboardingDocumentEditModeWebPage | OnboardingDocumentCreateModeWebPage
) => {
  const initialFormValue: SetupDocumentFormType[] = []

  const mappedCompaniesPageData: MappedCompanyFormType[] = webPageData.companies.map(
    (company: OnboardingDocumentEditModeCompanyType) => {
      // company documents
      let documents: SetupDocumentFormType['documents']

      // check for edit mode
      if (company?.selectedCompanyDocuments) {
        documents = company?.selectedCompanyDocuments
          .map(({ fields, id }) => ({
            fields: fields.filter((field) => field.externalKey !== 'signature'),
            id,
            isSelected: true
          }))
          .concat(
            company.availableCompanyDocuments.map(({ fields, id }) => ({
              fields: fields.filter((field) => field.externalKey !== 'signature'),
              id,
              isSelected: false
            }))
          )
      } else {
        // check for createMode
        documents = company.availableCompanyDocuments.map(({ fields, id }) => ({
          fields: fields.filter((field) => field.externalKey !== 'signature'),
          id,
          isSelected: true
        }))
      }

      // bankAccounts
      let bankAccounts: SetupDocumentFormType['bankAccounts'] = []

      if (company?.selectedCompanyDocuments) {
        bankAccounts = company.bankAccounts.map((bankAccount) => ({
          bankAccountNumber: bankAccount.number,
          bankAccountTemplates: bankAccount.selectedTemplates
            .map(({ fields, id, name }) => ({
              fields: fields.filter((field) => field.externalKey !== 'signature'),
              id,
              name,
              isSelected: true
            }))
            .concat(
              bankAccount.availableTemplates.map(({ fields, id, name }) => ({
                fields: fields.filter((field) => field.externalKey !== 'signature'),
                id,
                name,
                isSelected: false
              }))
            )
        }))
      }

      initialFormValue.push({
        bankAccountNumber: '',
        documents: documents,
        bankAccounts: bankAccounts
      })

      const companyDocumentsForMappingValues = company.availableCompanyDocuments.concat(
        company?.selectedCompanyDocuments || []
      )

      const availableBankAccountsTemplates =
        company?.availableBankAccountsTemplates.map((availableBankAccountsTemplate) => ({
          ...availableBankAccountsTemplate,
          fields: availableBankAccountsTemplate.fields.filter((field) => field.externalKey !== 'signature'),
          isSelected: true
        })) || []

      return {
        ...company,
        companyDocuments: mapValues(keyBy(companyDocumentsForMappingValues, 'id'), (o) => o),
        availableBankAccountsTemplates: availableBankAccountsTemplates
      }
    }
  )

  return {
    mappedCompaniesPageData,
    initialFormValue
  }
}
