import React, { FC, memo } from 'react'

import { FieldProps, FormInputProps } from 'brief-form'
import { Contact } from 'commons/types/DTO/bank'
import { usAndTerritories, usStates } from 'commons/types/dictionaries'
import { FieldSet, Select } from 'ui/components'
import { TextInput } from 'ui/components/InputV2'
import { Col, FormRow } from 'ui/themes/globalStyles'

interface Props {
  Field: <Input extends React.ComponentType<FormInputProps<any, any>>>(props: FieldProps<Input, Contact>) => JSX.Element
}

const EMPTY_OPTION = { label: 'Not selected', value: '' }

export const ContactAddress: FC<Props> = memo((props: Props) => {
  const { Field } = props

  return (
    <FieldSet legend="Address Information">
      <FormRow>
        <Col>
          <Field
            name="street"
            label="Street"
            input={TextInput}
            inputProps={{
              'data-qa': 'Street'
            }}
          />
        </Col>
        <Col>
          <Field
            name="state"
            label="State/Province"
            input={Select}
            inputProps={{
              'data-qa': 'State/Province',
              'options-data-qa': 'State/Province',
              data: usStates,
              emptyOption: EMPTY_OPTION
            }}
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col>
          <Field
            name="city"
            label="City"
            input={TextInput}
            inputProps={{
              'data-qa': 'City'
            }}
          />
        </Col>
        <Col>
          <Field
            name="zip_code"
            label="Zip/Postal Code"
            input={TextInput}
            inputProps={{
              'data-qa': 'Zip/Postal Code'
            }}
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col>
          <Field
            name="country"
            label="Country"
            input={Select}
            inputProps={{
              'data-qa': 'Country',
              'options-data-qa': 'Country',
              data: usAndTerritories,
              emptyOption: EMPTY_OPTION
            }}
          />
        </Col>
      </FormRow>
    </FieldSet>
  )
})
