import styled from 'styled-components'
import { Hr } from 'ui/components'

export const Row = styled.div`
  display: flex;

  > * {
    width: 400px;

    &:first-child {
      margin-right: 100px;
    }
  }
`

export const WrappedHr = styled(Hr)`
  margin-bottom: 21px;
`

export const Title = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: ${(p) => p.theme.uiKit.colors.global.grey.grey700};
  margin: 15px 0 20px 0;
`

export const License = styled.div`
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 20px;
`
