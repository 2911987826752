import { useCallback, useEffect } from 'react'

import { useNavigate } from '@tanstack/react-router'
import { BankApplicationPaths } from 'commons/constants/routes'
import { useSaveApplicationNotes, useUpdateApplication } from 'commons/hooks/client/onboarding'
import { useDebounce } from 'commons/hooks/common'
import { PortalName } from 'commons/types/enums'
import { Toast } from 'ui/components/Toast'

import { FormPaths } from '../../enums'
import { OnChangeAdditionParams, OnboardingFormDispatch } from '../../types/actions'
import {
  ATMGeneralInfoChangeHandler,
  ATMServicesChangeHandler,
  AnticipatedMonthlyActivityChangeHanlder,
  AnticipatedMonthlyActivityNAChangeHandler,
  AvailableServicesChangeHandler,
  CompanyAccountDetailsChangeHandler,
  CompanyInformationChangeHandler,
  CompanyMailingAddressChangeHandler,
  CompanyPhysicalAddressChangeHandler,
  CompanyPhysicalAddressCopyToMailingHandler,
  ContactAdditionalInformationChangeHandler,
  ContactDetailsChangeHandler,
  FormData,
  LicenseAddressInformationChangeHanlder,
  LicenseDetailsChangeHanlder,
  MerchantQuestionnaireChangeHandler,
  NotesChangeHandler,
  ProfessionalPartnershipChangeHandler,
  QuestionnaireChangeHandler,
  RoleInformationHandler
} from '../../types/forms'
import { OnboardingFormState } from '../../types/state'
import * as actions from './actions'
import { mapFormValuesForSave } from './helpers/mapFormValuesForSave'

type OnboardingFormChangeSaveHandlersProps = {
  applicationId: number
  isDirty: boolean
  isFormSubmitting: boolean
  formDispatch: OnboardingFormDispatch
  formState: OnboardingFormState
  portalName: PortalName
}

export const useOnboardingFormChangeSaveHandlers = ({
  applicationId,
  isDirty,
  isFormSubmitting,
  formState,
  formDispatch,
  portalName
}: OnboardingFormChangeSaveHandlersProps) => {
  const navigate = useNavigate()
  const { formValue, proxyWebpageData } = formState
  const { routine: saveNotes } = useSaveApplicationNotes()
  const { routine: updateApplication } = useUpdateApplication()

  const handleSaveForm = useCallback(
    async (force = false) => {
      if (!force && isFormSubmitting) {
        return
      }
      try {
        const saveApplicationPayload = mapFormValuesForSave({ formValue, applicationId, proxyWebpageData })
        formDispatch(actions.setIsAutosaveUpdating(true))
        await updateApplication({ application: saveApplicationPayload, p: portalName })
        formDispatch(actions.setIsAutosaveUpdating(false))
        formDispatch(actions.setHasChanges(false))
      } catch (error) {
        // TODO: check how it works in ui
        // eslint-disable-next-line no-console
        console.log('[handleSaveForm] error', error)
      }
    },
    [isFormSubmitting, formValue, applicationId, formDispatch, updateApplication, portalName, proxyWebpageData]
  )

  // Manual save on BP
  const handleManualSaveForm = useCallback(async () => {
    try {
      await handleSaveForm()
      navigate({ to: BankApplicationPaths.DETAILS, params: { id: applicationId } })
      Toast.success('Form was successfully saved.')
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('[handleManualSaveForm] error', error)
    }
  }, [applicationId, handleSaveForm, navigate])

  const memDebounce = useDebounce(handleSaveForm as unknown as () => void, 1000)

  // auto-save
  useEffect(() => {
    // no changes, no touched
    if (!isDirty || formState.currentStep !== 1) {
      return
    }
    // formState.formValue value have changed after onChange action
    memDebounce()
  }, [isDirty, formValue, memDebounce, formState.currentStep])

  // form values
  const handleNotesChange: NotesChangeHandler = useCallback(
    (formData) => formDispatch(actions.onChange({ path: FormPaths.NOTES, formData })),
    []
  )

  const handleNotesSave = useCallback(async () => {
    try {
      await saveNotes({
        clientNotes: formValue.notes.formValues.clientNotes,
        id: applicationId
      })
      Toast.success(`Notes were successfully saved.`)
    } catch (error) {
      Toast.error('Notes saving failed.')
    }
  }, [applicationId, saveNotes, formValue.notes.formValues.clientNotes])

  const handleOnChange = useCallback(
    ({ path, formData, params }: { path: FormPaths; formData: FormData; params: OnChangeAdditionParams }): void =>
      formDispatch(actions.onChange({ path, formData, params })),
    []
  )

  // Company
  const handleCompanyInformationChange: CompanyInformationChangeHandler = useCallback(
    ({ formData, companyIndex }) =>
      handleOnChange({ path: FormPaths.COMPANY_INFORMATION, formData, params: { companyIndex } }),
    [handleOnChange]
  )

  const handleCompanyAccountDetailsChange: CompanyAccountDetailsChangeHandler = useCallback(
    ({ formData, companyIndex }) =>
      handleOnChange({ path: FormPaths.COMPANY_ACCOUNT_DETAILS, formData, params: { companyIndex } }),
    [handleOnChange]
  )

  const handleCompanyPhysicalAddressChange: CompanyPhysicalAddressChangeHandler = useCallback(
    ({ formData, companyIndex }) =>
      handleOnChange({ path: FormPaths.COMPANY_PHYSICAL_ADDRESS, formData, params: { companyIndex } }),
    [handleOnChange]
  )

  const handleCompanyPhysicalAddressCopyToMailing: CompanyPhysicalAddressCopyToMailingHandler = useCallback(
    ({ companyIndex }: { companyIndex: number }) => {
      formDispatch(actions.copyCompanyPhysicalAddressToMailing(companyIndex))
      memDebounce()
    },
    [memDebounce, formDispatch]
  )

  const handleCompanyMailingAddressChange: CompanyMailingAddressChangeHandler = useCallback(
    ({ formData, companyIndex }) =>
      handleOnChange({ path: FormPaths.COMPANY_MAILING_ADDRESS, formData, params: { companyIndex } }),
    [handleOnChange]
  )
  // Licenses
  const handleLicenseDetailsChange: LicenseDetailsChangeHanlder = useCallback(
    ({ formData, licenseIndex, companyIndex }) =>
      handleOnChange({
        path: FormPaths.LICENSE_DETAILS,
        formData,
        params: { companyIndex, licenseIndex }
      }),
    [handleOnChange]
  )

  const handleLicenseAddressInformationChange: LicenseAddressInformationChangeHanlder = useCallback(
    ({ formData, licenseIndex, companyIndex }) =>
      handleOnChange({
        path: FormPaths.LICENSE_ADDRESS_INFORMATION,
        formData,
        params: { companyIndex, licenseIndex }
      }),
    [handleOnChange]
  )

  // Contacts
  const handleContactDetailsChange: ContactDetailsChangeHandler = useCallback(
    ({ formData, companyIndex, contactIndex }) =>
      handleOnChange({ path: FormPaths.CONTACT_DETAILS, formData, params: { companyIndex, contactIndex } }),
    [handleOnChange]
  )

  const handleRoleInformationChange: RoleInformationHandler = useCallback(
    ({ formData, companyIndex, contactIndex }) =>
      handleOnChange({ path: FormPaths.ROLE_INFORMATION, formData, params: { companyIndex, contactIndex } }),
    [handleOnChange]
  )

  const handleContactAdditionalInformationChange: ContactAdditionalInformationChangeHandler = useCallback(
    ({ formData, companyIndex, contactIndex }) =>
      handleOnChange({
        path: FormPaths.CONTACT_ADDITIONAL_INFORMATION,
        formData,
        params: { companyIndex, contactIndex }
      }),
    [handleOnChange]
  )
  //
  const handleQuestionnaireChange: QuestionnaireChangeHandler = useCallback(
    ({ formData, companyIndex }) =>
      handleOnChange({ path: FormPaths.QUESTIONNAIRE, formData, params: { companyIndex } }),
    [handleOnChange]
  )
  const handleProssionalParnershipsChange: ProfessionalPartnershipChangeHandler = useCallback(
    ({ formData, companyIndex }) =>
      handleOnChange({ path: FormPaths.PROFESSIONAL_PARTNERSHIPS, formData, params: { companyIndex } }),
    [handleOnChange]
  )
  // atms
  const handleATMGeneralInfoChange: ATMGeneralInfoChangeHandler = useCallback(
    ({ formData, companyIndex }) =>
      handleOnChange({ path: FormPaths.ATM_GENERAL_INFO, formData, params: { companyIndex } }),
    [handleOnChange]
  )
  const handleATMServicesChange: ATMServicesChangeHandler = useCallback(
    ({ atmIndex, formData, companyIndex }) =>
      handleOnChange({ path: FormPaths.ATM_SERVICES, formData, params: { atmIndex, companyIndex } }),
    [handleOnChange]
  )
  //
  const handleAvailableServicesChange: AvailableServicesChangeHandler = useCallback(
    ({ formData, companyIndex }) =>
      handleOnChange({ path: FormPaths.AVAILABLE_SERVICES, formData, params: { companyIndex } }),
    [handleOnChange]
  )
  const handleAnticipatedMonthlyActivityChange: AnticipatedMonthlyActivityChangeHanlder = useCallback(
    ({ formData, companyIndex }) =>
      handleOnChange({ path: FormPaths.ANTICIPATED_MONTHLY_ACTIVITY, formData, params: { companyIndex } }),
    [handleOnChange]
  )
  const handleAnticipatedMonthlyActivityNAChange: AnticipatedMonthlyActivityNAChangeHandler = useCallback(
    ({ formData, companyIndex }) =>
      handleOnChange({ path: FormPaths.ANTICIPATED_MONTHLY_ACTIVITY_NA, formData, params: { companyIndex } }),
    [handleOnChange]
  )

  const handleMerchantQuestionnaireChange: MerchantQuestionnaireChangeHandler = useCallback(
    ({ formData, companyIndex }) =>
      handleOnChange({ path: FormPaths.COMPANY_MERCHANT_QUESTIONNAIRE, formData, params: { companyIndex } }),
    [handleOnChange]
  )

  return {
    handlers: {
      handleSaveRFI: handleSaveForm,
      handleNotesChange,
      handleNotesSave,
      handleCompanyInformationChange,
      handleCompanyAccountDetailsChange,
      handleCompanyPhysicalAddressChange,
      handleCompanyPhysicalAddressCopyToMailing,
      handleCompanyMailingAddressChange,
      handleLicenseDetailsChange,
      handleLicenseAddressInformationChange,
      handleContactDetailsChange,
      handleRoleInformationChange,
      handleContactAdditionalInformationChange,
      handleQuestionnaireChange,
      handleProssionalParnershipsChange,
      handleATMGeneralInfoChange,
      handleATMServicesChange,
      handleAvailableServicesChange,
      handleAnticipatedMonthlyActivityChange,
      handleAnticipatedMonthlyActivityNAChange,
      handleMerchantQuestionnaireChange,

      handleSaveForm,
      handleManualSaveForm
    }
  }
}
