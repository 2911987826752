import React from 'react'

import { Request } from 'commons/utils/request'
import { DownloadLink, Toast, UploadBatchPdfButton } from 'ui/components'

import { AcknowledgmentsPdfLinkProps } from './AcknowledgmentsPdfLink.types'

import { s } from './styles'

export const AcknowledgmentsPdfLink = (props: AcknowledgmentsPdfLinkProps) => {
  const { id, showDownloadAcknowledgmentsPDF, showUploadAcknowledgmentsPDF } = props

  if (showDownloadAcknowledgmentsPDF) {
    return (
      <DownloadLink
        direct
        parametersForm={{ id, file_type: 'acknowledgments' }}
        baseUrl={Request.urls.bank.batchFileDownload}
        name={showDownloadAcknowledgmentsPDF}
        handleError={(message) => Toast.error('Error', message)}
      >
        <s.LongFilename title={showDownloadAcknowledgmentsPDF}>{showDownloadAcknowledgmentsPDF}</s.LongFilename>
      </DownloadLink>
    )
  }

  if (showUploadAcknowledgmentsPDF) {
    return <UploadBatchPdfButton batchId={id} isAcknowledgments />
  }

  return null
}
