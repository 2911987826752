import React, { useCallback, useEffect, useState } from 'react'

import { notFound, useNavigate } from '@tanstack/react-router'
import { useFormData } from 'brief-form'
import { useGetSalesDataSourceWebPage } from 'commons/hooks/bank/salesData'
import {
  BankSourceCreateFormValue,
  SalesDataSourceWebPage,
  SalesDataSourceWebPagePayload
} from 'commons/types/DTO/bank/salesDataSourceWebPage'
import { useConfirmation } from 'ui/hooks'

import { useSaveSourceAndFilesManage } from '../../hooks'

export const useBankSourceEdit = (sourceId: number, returnUrl?: string) => {
  const navigate = useNavigate()
  const [webPageData, setWebPageData] = useState<SalesDataSourceWebPage | undefined>(undefined)
  const { config, Field } = useFormData<BankSourceCreateFormValue>({ initialValue: new BankSourceCreateFormValue() })

  const {
    onSubmit,
    isSubmitting,
    isFileUploading,
    files,
    addFiles,
    deleteFile,
    deleteUploadedFile,
    isUploadedFileDeleting
  } = useSaveSourceAndFilesManage(sourceId, webPageData?.monthlyAnalyticsID, config, returnUrl)

  const originalChangeHandler = config.onChange

  const { data, isFetched } = useGetSalesDataSourceWebPage(new SalesDataSourceWebPagePayload(sourceId))

  useEffect(() => {
    if (isFetched) {
      if (!data) {
        throw notFound()
      }
      setWebPageData(data)
    }
  }, [isFetched, data])

  const { open: openSubmitConfirm, Confirmation: SubmitConfirmation } = useConfirmation({
    message: (
      <>
        Are you sure you want to proceed?
        <br />
        Report will be recalculated.
      </>
    ),
    onConfirm: () => onSubmit(),
    confirmationButtonText: 'Confirm',
    isConfirmationNegative: false
  })

  const onCancel = useCallback(() => {
    if (returnUrl) {
      navigate({ to: returnUrl })
    } else {
      navigate({ to: '/mr/$id', params: { id: webPageData.monthlyAnalyticsID.toString() } })
    }
  }, [navigate, webPageData?.monthlyAnalyticsID, returnUrl])

  useEffect(() => {
    if (webPageData) {
      originalChangeHandler(
        new BankSourceCreateFormValue().fillFromSalesDataSourceWebPage(webPageData),
        config.errors || {}
      )
    }
  }, [webPageData])

  return {
    webPageData,
    config,
    Field,
    onCancel,
    openSubmitConfirm,
    SubmitConfirmation,
    isSubmitting: isSubmitting || isFileUploading,
    files,
    addFiles,
    deleteFile,
    deleteUploadedFile,
    isUploadedFileDeleting
  }
}
