import { Request } from 'commons/utils/request'
import { RemoteDataSource } from 'ui/components/QueryTable'

// TODO: fix any types
// eslint-disable-next-line
export const dataSource = (companyId: number): RemoteDataSource<any, any>[] => [
  {
    key: 'license_list',
    url: Request.urls.bank.licenseList,
    options: () => ({
      _options: {
        filters: [
          {
            field: 'company_id',
            type: 'in',
            value: companyId
          }
        ]
      }
    })
  }
]
