import { ComponentType } from 'react'

import { FormInputProps } from 'brief-form'

import { BaseInputOpts } from './BaseInputProps'

type WithCheckboxInputOpts = BaseInputOpts & {
  input: ComponentType<FormInputProps<string | number | null, BaseInputOpts>>
  checkBoxText?: string
}

export interface WithCheckboxInputProps extends FormInputProps<string | number | null, WithCheckboxInputOpts> {}
