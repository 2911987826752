import React, { FC, memo, useEffect } from 'react'

import { Form } from 'brief-form'
import { generateContext } from 'context-factory'
import { LeaveConfirmation } from 'ui/components/LeaveConfirmation'
import { Panel } from 'ui/components/Panel'

import { ManageCompanyFormContextValue } from './ManageCompanyFormContextValue'
import { ManageCompanyFormProps } from './ManageCompanyFormProps'
import { AccountDetails } from './components/AccountDetails'
import { AddressInformation } from './components/AddressInformation'
import { Description } from './components/Description'
import { MailingAddressInformation } from './components/MailingAddressInformation'
import { Relationships } from './components/Relationships'
import { Tags } from './components/Tags'
import { useManageCompanyForm } from './hooks/useManageCompanyForm'

import * as SC from './styles'

const { Provider: ManageCompanyFormContextProvider, useContextValue } = generateContext<ManageCompanyFormContextValue>()

export const ManageCompanyForm: FC<ManageCompanyFormProps> = memo((props) => {
  const { initialValue, onDelete } = props
  const { setter } = useContextValue()

  const { config, Field, submitHandler, deleteHandler, isSubmitting, isDirty } = useManageCompanyForm(
    initialValue,
    onDelete
  )

  useEffect(() => {
    setter({
      value: config.value,
      submitHandler,
      deleteHandler,
      isSubmitting
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config.value, isSubmitting])

  return (
    <Panel
      rounded
      shadowed
      title={initialValue.id ? 'Edit Company Information' : 'Add Company Information'}
      collapsible={false}
      content-className={''}
    >
      <SC.Box>
        <LeaveConfirmation preventLeaving={!isSubmitting && isDirty}>
          <Form config={config}>
            <Relationships
              id={config.value.id}
              isHolding={config.value.isHolding}
              holdingId={initialValue.holding_id}
              Field={Field}
            />
            <Description Field={Field} />
            <Tags Field={Field} />
            <AccountDetails Field={Field} isConsumerAccount={config.value.businessType === 'consumer_account'} />
            <SC.AddressWrapper>
              <AddressInformation Field={Field} />
              <MailingAddressInformation Field={Field} />
            </SC.AddressWrapper>
          </Form>
        </LeaveConfirmation>
      </SC.Box>
    </Panel>
  )
})

export { ManageCompanyFormContextProvider }
export { useContextValue as useManageCompanyFormContext }
