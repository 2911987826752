import * as React from 'react'

import { FieldProps, FormInputProps } from 'brief-form'
import { RadioGroup, Select } from 'ui/components'
import { Hr } from 'ui/components/Hr'
import { TextareaInput } from 'ui/components/InputV2'
import { PageSection, PageSectionFace } from 'ui/components/Page'

import { AnnualReviewQuestionList } from '../../../../../AnnualReviewDetail/components/AnnualReviewQuestions'
// eslint-disable-next-line max-len
import { annualReviewCBTQuestionnaireMapping } from '../../../../../AnnualReviewDetail/components/AnnualReviewQuestions/selectorForCBT'
import { AnnualReviewFormValue } from '../../AnnualReviewFormValue'

interface AnnualReviewEditCBTQuestionsProps {
  Field: <Input extends React.ComponentType<FormInputProps<any, any>>>(
    props: FieldProps<Input, AnnualReviewFormValue>
  ) => JSX.Element
}

export const AnnualReviewEditCBTQuestions = ({ Field }: AnnualReviewEditCBTQuestionsProps): JSX.Element => {
  const emptyInput = (
    <>
      <br />
      <br />
    </>
  )
  const items = annualReviewCBTQuestionnaireMapping(
    {
      hasMoreRevenue: emptyInput,
      revenueFromMJOnly: emptyInput,
      informationForMETRC_dump: emptyInput,
      comparingSICSAndBankDepositsRedFlag: (
        <Field
          name="questionnaire.comparing_sics_and_bank_deposits_red_flag"
          input={RadioGroup}
          inputProps={{
            name: 'questionnaire.comparing_sics_and_bank_deposits_red_flag',
            data: [
              { label: 'Yes', value: 'Yes' },
              { label: 'No', value: 'No' }
            ]
          }}
        />
      ),
      comparingSICSAndBankDepositsVariance: (
        <div style={{ width: '200px' }}>
          <Field
            name="questionnaire.comparing_sics_and_bank_deposits_variance"
            input={Select}
            inputProps={{
              data: [
                { label: 'Within Variance', value: 'Within Variance' },
                { label: 'Out of Variance - See Comments', value: 'Out of Variance' },
                { label: 'Wholesale Only - Cannot Compare', value: 'Wholesale Only' },
                { label: 'Not Operational - Cannot Compare', value: 'Not Operational' }
              ]
            }}
          />
        </div>
      ),
      comparingSICSAndBankDepositsComments: (
        <Field name="questionnaire.comparing_sics_and_bank_deposits_comments" input={TextareaInput} inputProps={{}} />
      ),
      hasMoreCashDeposits: emptyInput,
      compareCashAmount_dump: emptyInput,
      comparingCashDepositsAndTaxReturnsRedFlag: (
        <Field
          name="questionnaire.comparing_cash_deposits_and_tax_returns_red_flag"
          input={RadioGroup}
          inputProps={{
            name: 'questionnaire.comparing_cash_deposits_and_tax_returns_red_flag',
            data: [
              { label: 'Yes', value: 'Yes' },
              { label: 'No', value: 'No' }
            ]
          }}
        />
      ),
      comparingCashDepositsAndTaxReturnsVariance: (
        <div style={{ width: '200px' }}>
          <Field
            name="questionnaire.comparing_cash_deposits_and_tax_returns_variance"
            input={Select}
            inputProps={{
              data: [
                { label: 'Cash < Sales Tax Returns - OK', value: 'Cash < Sales Tax Returns' },
                { label: 'Cash > Sales Tax Returns - See Comments', value: 'Cash > Sales Tax Returns' },
                { label: 'Wholesale Only - Cannot Compare', value: 'Wholesale Only' },
                { label: 'Not Operational - Cannot Compare', value: 'Not Operational' }
              ]
            }}
          />
        </div>
      ),
      comparingCashDepositsAndTaxReturnsComments: (
        <Field
          name="questionnaire.comparing_cash_deposits_and_tax_returns_comments"
          input={TextareaInput}
          inputProps={{}}
        />
      ),
      inconsistentFinancialStatement: emptyInput,
      validateContributions_dump: emptyInput,
      financialStatementValidationRedFlag: (
        <Field
          name="questionnaire.financial_statement_validation_red_flag"
          input={RadioGroup}
          inputProps={{
            name: 'questionnaire.financial_statement_validation_red_flag',
            data: [
              { label: 'Yes', value: 'Yes' },
              { label: 'No', value: 'No' }
            ]
          }}
        />
      ),
      financialStatementValidationVariance: (
        <div style={{ width: '200px' }}>
          <Field
            name="questionnaire.financial_statement_validation_variance"
            input={Select}
            inputProps={{
              data: [
                { label: 'Yes - See Comments', value: 'Yes' },
                { label: 'No Transactions of This Type', value: 'No' }
              ]
            }}
          />
        </div>
      ),
      financialStatementValidationComments: (
        <Field name="questionnaire.financial_statement_validation_comments" input={TextareaInput} inputProps={{}} />
      ),
      disguisingMRBActivity: emptyInput,
      inconsistentMRBPurporting: emptyInput,
      inconsistentMRBPurportingDueDiligenceCompleted: emptyInput,
      ownerOutsideState: emptyInput,
      ownerOutsideStateApproved: emptyInput,
      adverseMediaRequired: emptyInput,
      adverseMediaReviewed: emptyInput,
      mrbOnFederalProperty: emptyInput,
      mrbSchoolProximityConsistent: emptyInput,
      mrbSchoolProximityDueDiligenceCompleted: emptyInput
    },
    {
      comparingSICSAndBankDepositsComments: {
        multiline: true
      },
      comparingCashDepositsAndTaxReturnsComments: {
        multiline: true
      },
      financialStatementValidationComments: {
        multiline: true
      }
    }
  )

  return (
    <>
      {items.map((data) => (
        <section key={data.title}>
          <PageSection key={`${data.title}-0`} title={data.title} face={PageSectionFace.SECONDARY}>
            <AnnualReviewQuestionList items={data.items as any[][]} bottomIndent={false} />
          </PageSection>
          <Hr key={`${data.title}-1`} />
        </section>
      ))}
    </>
  )
}
