import React, { FC } from 'react'

import { StringListChangedProps } from './HistoryRecordDetails.types'
import { HistoryCreateLabel, HistoryUpdateLabel } from './components'

const StringListChanged: FC<StringListChangedProps> = ({ after, before, mustBeMasked }) => {
  const isNew = before?.length === 0

  const to = after.join(',')
  const from = before.join(',')
  if (isNew) {
    return <HistoryCreateLabel value={to} subject="New values were set" mustBeMasked={mustBeMasked} warning="" />
  }

  return <HistoryUpdateLabel subject="" from={from} to={to} mustBeMasked={mustBeMasked} />
}

export default StringListChanged
