import React from 'react'

import { CheckBox, FF } from 'ui/components'

import { useSARFormContext } from '../../../../../../../context'

export const A = () => {
  const { formApi } = useSARFormContext()
  return (
    <formApi.Field
      name="transactions_info.fraud.a"
      children={(field) => (
        <FF
          field={field}
          label="34. Fraud"
          input={(p) => <CheckBox {...p} checkBoxLabel="a. ACH" />}
          errorPosition="right"
        />
      )}
    />
  )
}
