import { MutableRefObject } from 'react'

import { FormErrorsShape, UseFormDataReturnType } from 'brief-form'
import {
  ApplicationRelationshipBankAccountsResponse,
  ApplicationRelationshipLicense
} from 'commons/types/DTO/bank/applications'
import { ChoiceOption } from 'commons/types/DTO/commons'

export enum ConnectBankAccountToLicensesFormFieldNames {
  ACCOUNT_NUMBER = 'accountNumber',
  OPENING_DATE = 'openingDate',
  ASSIGNED_LICENSES = 'assignedLicenses'
}

export type ConnectBankAccountToLicensesFormValue = {
  id: number // hidden, set by backend
  accountNumber: string | null
  openingDate: string | null
  assignedLicenses: (number | string)[]
  enableRemove: boolean
}

export type BankAccountFormOnChange = (
  value: ConnectBankAccountToLicensesFormValue,
  errors: FormErrorsShape<ConnectBankAccountToLicensesFormValue>
) => void

export type RelationshipBankAccountFormDataRecord = {
  value: ConnectBankAccountToLicensesFormValue
  errors: FormErrorsShape<ConnectBankAccountToLicensesFormValue>
}

export type ConnectBankAccountToLicensesFormOnChange = (params: RelationshipBankAccountFormDataRecord) => void

export type ConnectBankAccountToLicensesFormOnChangeState = (params: {
  formName: string
  formData: RelationshipBankAccountFormDataRecord
}) => void

export type ConnectBankAccountToLicensesFormData = UseFormDataReturnType<ConnectBankAccountToLicensesFormValue, unknown>

export type AddBankAccountValidateFunctionRefs = Record<
  string,
  { formData: UseFormDataReturnType<ConnectBankAccountToLicensesFormValue, unknown> }
>

export type ConnectBankAccountToLicensesFormState = {
  licenses: ApplicationRelationshipLicense[]
  initialValue: RelationshipBankAccountFormDataRecord
  isDisabled: boolean
  formName: string
  validateFunctionRefs: MutableRefObject<AddBankAccountValidateFunctionRefs>
  assignedLicensesSelectData: ChoiceOption[]
  onChange: ConnectBankAccountToLicensesFormOnChangeState
}

export type ApplicationBankAccountsFormDataList = Record<string, RelationshipBankAccountFormDataRecord>

export type ApplicationBankAccountsDialogState = {
  showFormErrors: boolean // unassigned license red highlighting
  isOldDirty: boolean // exists BA forms dirty
  isAddBankAccountFormValid: boolean
  relationshipsBankAccounts: ApplicationRelationshipBankAccountsResponse | undefined
  formDataList: ApplicationBankAccountsFormDataList
  selectedRelationshipIndex: number
  formExpandedList: Record<string, boolean>
  isReadyForSubmit: boolean // show submit btn
  woSelectedRelationship: boolean // `setinit` without set selected relationship
}
