import {
  BANK_BISON_ID,
  BANK_CBT_ID,
  BANK_CHAMPION_ID,
  BANK_DEMO_ID,
  BANK_DEV_ID,
  BANK_FLAGLER_ID,
  BANK_HERRING_ID,
  BANK_INB_ID,
  BANK_MEDMEN_ID,
  BANK_MPB_ID,
  BANK_SHORE_ID,
  BANK_TRANSACT_ID,
  BANK_VALLIANCE_ID
} from 'commons/constants/banks'
import { BankName } from 'commons/types/enums'

export const idsMapping: { [key: number]: BankName | undefined } = {
  [BANK_DEV_ID]: BankName.DEV,
  [BANK_SHORE_ID]: BankName.SHORE,
  [BANK_CBT_ID]: BankName.CBT,
  [BANK_INB_ID]: BankName.INB,
  [BANK_MPB_ID]: BankName.MPB,
  [BANK_HERRING_ID]: BankName.HERRING,
  [BANK_TRANSACT_ID]: BankName.TRANSACT,
  [BANK_CHAMPION_ID]: BankName.CHAMPION,
  [BANK_DEMO_ID]: BankName.DEMO,
  [BANK_MEDMEN_ID]: BankName.MED_MEN,
  [BANK_FLAGLER_ID]: BankName.FLAGLER,
  [BANK_VALLIANCE_ID]: BankName.VALLIANCE,
  [BANK_BISON_ID]: BankName.BISON
}

const namesMapping = {
  [BANK_DEV_ID]: 'Development Bank',
  [BANK_SHORE_ID]: 'Shore United Bank',
  [BANK_CBT_ID]: 'Colorado Bank & Trust',
  [BANK_INB_ID]: 'INB',
  [BANK_MPB_ID]: 'Metro Phoenix Bank',
  [BANK_HERRING_ID]: 'Herring Bank',
  [BANK_TRANSACT_ID]: 'Transact Bank',
  [BANK_CHAMPION_ID]: 'Champion Bank',
  [BANK_DEMO_ID]: 'Demo Bank',
  [BANK_MEDMEN_ID]: 'MedMen',
  [BANK_FLAGLER_ID]: 'Flagler Bank',
  [BANK_VALLIANCE_ID]: 'Valliance Bank',
  [BANK_BISON_ID]: 'Bison State Bank'
}

export type BankReturnShape = {
  id: BankName
  rawId: number
  name: string
  isFetching: boolean | undefined
}

/**
 * Get bank by organization ID.
 * @param organizationId - bank ID. When passing this parameter, remember
 * that, if organisation ID = 1 and test_organisation_id = 5, it means that
 * on the DEV environment this bank has ID = 1, but on the production
 * environment this bank will have ID = 5. So, "test ID" should be
 * passed here, when available, because banks will be selected by production IDs.
 * @param isFetchingOrganization - optional parameter for handling isFetching flag
 * if organizationId pass asynchronously.
 */
export const useBank = (organizationId: number, isFetchingOrganization?: boolean): BankReturnShape => {
  const bank = idsMapping[organizationId]
  return {
    id: bank,
    rawId: organizationId,
    name: bank ? namesMapping[bank] : '',
    isFetching: isFetchingOrganization
  }
}
