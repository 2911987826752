import React, { FC, memo } from 'react'

import { ButtonGroup } from 'ui/components/ButtonGroup'
import { Button } from 'ui/components/Buttons'
import { Link } from 'ui/components/Link'

import { FooterProps } from './FooterProps'

import { Box } from './styles'

export const Footer: FC<FooterProps> = memo((props) => {
  const { applicationId, isSubmitting, onSubmit } = props

  return (
    <Box>
      <ButtonGroup margin="small">
        <Link to={'/onboarding/applications/$applicationId'} params={{ applicationId }}>
          <Button face="neutral">Cancel</Button>
        </Link>
        <Button data-qa="Submit" onClick={onSubmit} face="positive" loading={isSubmitting}>
          Submit
        </Button>
      </ButtonGroup>
    </Box>
  )
})
