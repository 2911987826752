import React from 'react'

import { FF, TextInput, Validators } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

export const IDNumber = () => {
  const { formApi } = useSARFormContext()
  const type = formApi.useStore((s) => s.values.institution_contact_info?.id_type)

  return (
    <formApi.Field
      name="institution_contact_info.id_number"
      validators={{
        onChange: ({ value }) => (type?.length > 0 ? Validators.required.field(value) : undefined)
      }}
      children={(field) => (
        <FF label="ID Number" required={type?.length > 0} field={field} input={(p) => <TextInput {...p} />} />
      )}
    />
  )
}
