import styled from 'styled-components'

export const Footer = styled.div`
  height: 66px;
  max-width: 1920px;
  padding: 0px 50px;
  border-top: 1px solid #e2e2e2;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: #fff;
  z-index: 1000;
`

export const Page = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.38px;
  margin-right: auto;
  color: ${(p) => p.theme.uiKit.colors.global.grey.grey700};
`

export const Box = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
`
