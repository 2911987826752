import styled from 'styled-components'
import { CSS } from 'ui/helpers'

export const Wrapper = styled.div`
  max-width: 600px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-top: 28px;
  margin-left: 32px;
`

export const Title = styled.h5`
  ${(p) => CSS.populateFont(p.theme.uiKit.typography.h5.semiBold)};
  margin-bottom: 12px;
`

export const Columns = styled.div`
  display: flex;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 240px;
  margin-right: 40px;
  gap: 20px 0px;
`
