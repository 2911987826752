import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { useQueryClient } from 'react-query'

import { Request } from 'commons/utils/request'
import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'
import { IconName, IconSize } from 'ui/components/Icon'
import { Popper } from 'ui/components/Popper'

import { useGetData } from '../hooks/useGetData'
import { Item } from './Item'
import { MultiSelectAutoSuggestProps } from './MultiSelectAutoSuggestProps'
import { OptionLicenseItem } from './OptionLicenseItem'
import * as SC from './style'

export const MultiLicenseSelectAutoSuggest = memo((props: MultiSelectAutoSuggestProps) => {
  const { value, onChange, options, valueTitle, equalCondition, apiAxios, serverOptions, ...rest } = props
  const listRef = useRef([])
  const inputRef = useRef<HTMLInputElement>()
  const [edit, setEdit] = useState(false)
  const [open, setOpen] = useState(false)
  const [text, setText] = useState('')
  const [serverItems, setServerItems] = useState<Item[]>([])
  const [rightColumnItems, setRightColumnItems] = useState<Item[]>([])
  const ref = useRef<any>()
  const dropDownRef = useRef<HTMLDivElement>(null)
  const items = options && options.length > 0 ? options : serverItems
  const queryClient = useQueryClient()
  const queryKey = 'multi-select-auto-suggest-data'
  const getDataMutation = useGetData(queryKey)

  const onSearch = debounce(
    useCallback(
      (term: string) => {
        setText(term)
      },
      [serverOptions]
    ),
    500
  )

  useEffect(() => {
    const onEscPress = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        endEdit()
      }
    }

    document.addEventListener('keydown', onEscPress, false)

    return () => document.removeEventListener('keydown', onEscPress, false)
  }, [])

  const onOptionChange = useCallback(
    (optionValue: string, disabled?: boolean) => {
      if (ref.current) {
        ref.current.focus()
      }

      if (disabled) {
        return
      }

      const cond = (el: string | any) => (equalCondition && equalCondition(el, optionValue)) || isEqual(el, optionValue)
      const index = (value || []).findIndex(cond)

      if (index === -1) {
        onChange((value || []).concat(optionValue))
        const selectedItem = serverItems.filter((item) => item.value === optionValue)
        setRightColumnItems((prev) => prev.concat(selectedItem[0]))
      } else {
        const copy = [...value]
        copy.splice(index, 1)
        setRightColumnItems((prev) => {
          prev.splice(index, 1)
          return prev
        })
        onChange(copy)
      }
    },
    [value, onChange, rightColumnItems, serverItems]
  )

  const startEdit = useCallback(() => {
    setEdit(true)
    setOpen(true)
  }, [])

  useEffect(() => {
    if (edit) {
      // temp solution, for setting focus after floating ui manage their focus
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current?.focus()
        }
      }, 0)
    }
  }, [edit])

  const endEdit = useCallback(() => {
    setEdit(false)
    setOpen(false)
    setText('')
    onSearch('')
  }, [])

  const onTextChange = useCallback((text: string) => {
    setText(text)
    onSearch(text)
  }, [])

  const stopClickPropagation = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()
  }, [])

  const typeOptions = items.filter(
    (item) =>
      !(value || []).some((i: any) =>
        !!i && !!item.value ? i === item.value : !!i?.id && !!item?.value?.id ? i.id === item.value.id : false
      )
  )

  const typeSelectedOptions = items.filter((item) =>
    (value || []).some((i: any) =>
      !!i && !!item.value ? i === item.value : !!i?.id && !!item?.value?.id ? i.id === item.value.id : false
    )
  )

  const optionsToSelect = typeOptions.map((item) => {
    if (!typeSelectedOptions.includes(item)) {
      return (
        <OptionLicenseItem
          key={item.value?.id || item.value}
          text={item.label}
          value={item.value}
          disabled={item.disabled}
          title={item.title}
          checked={rightColumnItems.includes(item.value.id)}
          onClick={onOptionChange}
        />
      )
    }

    return false
  })

  const selectedOptions = typeSelectedOptions.map((item) => {
    if (!typeOptions.includes(item)) {
      return (
        <OptionLicenseItem
          key={item.value?.id || item.value}
          text={item.label}
          value={item.value}
          disabled={item.disabled}
          title={item.title}
          checked
          onClick={onOptionChange}
        />
      )
    }

    return false
  })

  const dropDownRenderer = useCallback(
    () => (
      <SC.Columns onMouseDownCapture={stopClickPropagation}>
        <SC.LeftColumn>
          {optionsToSelect.length ? optionsToSelect : null}
          {selectedOptions.length ? selectedOptions : null}
        </SC.LeftColumn>
      </SC.Columns>
    ),
    [optionsToSelect, selectedOptions, value]
  )

  const labels = (value || []).map((val) => {
    let item = items.filter((item) => item.value === val)
    if (!item || item.length == 0) {
      item = rightColumnItems.filter((item) => item.value === val)
    }
    return item && item.length ? item[0].label : val
  })

  const fetchOptions = useCallback(
    async (customFilters?: any) => {
      if (serverOptions) {
        await getDataMutation.mutateAsync({
          method: serverOptions.method || 'post',
          type: apiAxios || Request,
          url: serverOptions.url,
          serverOptions: {
            _options: {
              offset: 0,
              limit: serverOptions.limit,
              filters: customFilters
                ? customFilters
                : text && serverOptions.searchFields?.length
                ? ([
                    {
                      type: 'or',
                      filters: serverOptions.searchFields.map((field) => ({
                        field,
                        type: 'like',
                        value: `%${text}%`
                      }))
                    }
                  ]
                    .concat(serverOptions.filters as any)
                    .filter(Boolean) as any)
                : serverOptions.filters
            }
          }
        })

        const data = queryClient.getQueryData(queryKey) as any
        // eslint-disable-next-line
        const collection = Array.isArray(data?.records) ? data.records : Array.isArray(data) ? data : []
        const processedCollection = collection.map(serverOptions.mapper).filter(Boolean)

        if (customFilters) {
          setRightColumnItems(processedCollection)
        } else {
          setServerItems(processedCollection)
        }
      }
    },
    [serverOptions, text]
  )

  useEffect(() => {
    if (serverOptions) {
      fetchOptions()
    }
  }, [
    serverOptions && serverOptions.filters ? serverOptions.filters.map((f) => JSON.stringify(f)).join() : undefined,
    text
  ])

  useEffect(() => {
    if (value && value.length > 0) {
      fetchOptions([
        {
          type: 'in',
          field: 'id',
          value: value
        }
      ])
    }
  }, [])

  return (
    <Popper
      scrollClose={true}
      autoSize={true}
      autoSizeWidth={'fill'}
      listRef={listRef}
      placement="bottom-start"
      opened={open}
      onOpen={startEdit}
      onClose={endEdit}
      render={() => (
        <SC.DropDown open={open} ref={dropDownRef}>
          {dropDownRenderer()}
        </SC.DropDown>
      )}
    >
      <div style={{ height: '40px', width: '250px' }}>
        <SC.ValueSC {...rest} role="listbox">
          {edit ? (
            <SC.WrappedInput value={text} onChange={onTextChange} ref={inputRef} />
          ) : (
            <SC.Title>{labels?.length ? labels.join(',') : valueTitle}</SC.Title>
          )}
          <SC.WrappedIcon name={IconName.DOWN} size={IconSize.XS} open={open} />
        </SC.ValueSC>
      </div>
    </Popper>
  )
})
