import { FilterValues } from 'commons/types/DTO/commons/webpagesOnboardinForm'

export const mapFilterObjectToValueLabelArray = (
  obj: FilterValues[]
): {
  value: string
  label: string
}[] => {
  const arr = []

  obj.forEach((el) => arr.push({ value: el.value, label: el.label }))

  return arr
}
