import React from 'react'

import { A, Z } from './fields'

import { s } from './styles'

export const TerrorismFinancing = () => {
  return (
    <>
      <s.CheckBoxWrapper withLabel>
        <A />
      </s.CheckBoxWrapper>
      <s.CheckBoxWrapper>
        <Z />
      </s.CheckBoxWrapper>
    </>
  )
}
