import React, { KeyboardEvent, memo, useCallback, useMemo, useState } from 'react'

import { Files as FilesType } from 'commons/types/DTO/bank/applications/manage/Files'
import { TableDataQa } from 'commons/types/enums'
import { Request } from 'commons/utils/request'
import { DateTime } from 'ui/components/DateTime'
import { Dialog, DialogFace } from 'ui/components/Dialog'
import { DownloadLink } from 'ui/components/DownloadLink'
import { DataSourceItem, Table } from 'ui/components/Table'
import { Toast } from 'ui/components/Toast'

import { FilesProps } from './FilesProps'

import { HasFiles, NoFiles } from './styles'

export const Files = memo((props: FilesProps) => {
  const { files, companyName, documentName } = props
  const [modal, setModal] = useState(false)
  const onVisibleChange = useCallback((visible) => setModal(visible), [modal, setModal])

  const mappedFiles: DataSourceItem<FilesType>[] = useMemo(
    () =>
      files.map((el) => ({
        item: el
      })),
    [files]
  )

  if (!files.length) {
    return <NoFiles>No Files Loaded</NoFiles>
  }

  const handleError = (message: string) => Toast.error('Error', message)
  const url = Request.urls.bank.documentFileDirectDownload
  return (
    <div>
      <HasFiles
        tabIndex={0}
        role="button"
        onKeyDown={(event: KeyboardEvent<HTMLSpanElement>) => {
          if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault()
            setModal(true)
          }
        }}
        onClick={() => setModal(true)}
      >
        {files.length} file{files.length > 1 ? 's' : ''} {files.length > 1 ? 'have' : 'has'} been uploaded
      </HasFiles>
      {modal && (
        <Dialog
          id="get-link"
          onClose={() => onVisibleChange(false)}
          face={DialogFace.PRIMARY}
          title={companyName}
          subTitle={documentName}
        >
          <Table<FilesType>
            dataQa={TableDataQa.BP_ONBOARDING_APPLICATION_DOCUMENTS_FILES}
            columns={[
              {
                align: 'left',
                title: 'Filename',
                render: (item) => (
                  <DownloadLink
                    direct
                    parametersForm={{ id: item.fileID }}
                    baseUrl={url}
                    name={item.name}
                    handleError={handleError}
                  >
                    {item.name}
                  </DownloadLink>
                )
              },
              {
                width: '150px',
                align: 'center',
                title: 'Upload Date',
                render: ({ uploadDate }) => <DateTime date={uploadDate} withTime />
              }
            ]}
            dataSource={mappedFiles}
          />
        </Dialog>
      )}
    </div>
  )
})
