// eslint-disable-next-line max-len
import React from 'react'

import { AnnualReviewQuestionnaire, LicenseDeprecated } from 'commons/types/DTO/bank'
import SC from 'styled-components'
import { DateTime } from 'ui/components/DateTime'
import { LicenseTypeLabel } from 'ui/components/Labels'
import { PageSection } from 'ui/components/Page'

import { AnnualReviewLicensingQuestions } from '../../../AnnualReviewLicensing/components/AnnualReviewLicensingQuestions/AnnualReviewLicensingQuestions'

interface LicensingProps {
  licenses: LicenseDeprecated[]
  questionnaire?: AnnualReviewQuestionnaire
}

const Table = SC.table`
  width: 100%;
  border-collapse: collapse;
`

const Th = SC.td`
  text-align: center;
  font-weight: bold;
  border: 1px solid ${(p) => p.theme.uiKit.colors.global.grey.grey300};
  padding: 10px;
  background: ${(p) => p.theme.uiKit.colors.global.grey.grey300};
  
  @media print {
    background: ${(p) => p.theme.uiKit.colors.global.grey.grey300};
    -webkit-print-color-adjust: exact;
  }
`

const Td = SC.td`
  text-align: center;
  border: 1px solid ${(p) => p.theme.uiKit.colors.global.grey.grey300};
  padding: 10px;
`

export const Licensing = ({ licenses, questionnaire }: LicensingProps): JSX.Element => {
  const hasAnswers =
    questionnaire?.licensing_loc_changes ||
    questionnaire?.licensing_loc_changes_mmcc ||
    questionnaire?.licensing_name_changes ||
    questionnaire?.licensing_name_changes_mmcc ||
    questionnaire?.licensing_name_prior

  return (
    <PageSection title="Licensing">
      <Table>
        <thead>
          <tr>
            <Th>License Type</Th>
            <Th>Issue Date</Th>
            <Th>Expiration Date</Th>
            <Th>Number</Th>
            <Th>Location</Th>
          </tr>
        </thead>
        <tbody>
          {licenses.map((license, index) => (
            <tr key={index}>
              <Td>
                <LicenseTypeLabel name={license.type} />
              </Td>
              <Td>
                <DateTime date={license.issue_date || ''} />
              </Td>
              <Td>
                <DateTime date={license.expiration_date || ''} />
              </Td>
              <Td>{license.license_number}</Td>
              <Td>{license.street_address}</Td>
            </tr>
          ))}
        </tbody>
      </Table>
      {hasAnswers && (
        <AnnualReviewLicensingQuestions
          licensingLocChanges={questionnaire?.licensing_loc_changes}
          licensingNameChanges={questionnaire?.licensing_name_changes}
          licensingLocChangesMmcc={questionnaire?.licensing_loc_changes_mmcc}
          licensingNameChangesMmcc={questionnaire?.licensing_name_changes_mmcc}
          licensingNamePrior={questionnaire?.licensing_name_prior}
        />
      )}
    </PageSection>
  )
}
