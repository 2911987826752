import React, { FC, memo } from 'react'

import { Request } from 'commons/utils/request'
import { ChoiceOption } from 'ui/types'

import { SingleSelectAutoSuggest } from '../SingleSelectAutoSuggest'
import { BankUserSelectProps } from './BankUserSelectProps'

const PLACEHOLDER = 'Start entering name or email'

export const BankUserSelect: FC<BankUserSelectProps> = memo((props) => {
  const { value, defaultOption, filters, ...rest } = props
  const options: ChoiceOption[] = []

  if (defaultOption) {
    options.push(defaultOption)
  }

  return (
    <SingleSelectAutoSuggest
      noFirstRequest
      options={options}
      placeholder={PLACEHOLDER}
      serverOptions={{
        filters,
        url: Request.urls.bank.userList,
        limit: 500,
        mapper: (item) => ({ label: `${item.first_name} ${item.last_name}`.trim(), value: item.id }),
        searchFields: ['first_name', 'last_name', 'email']
      }}
      value={value}
      {...rest}
    />
  )
})
