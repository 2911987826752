import React, { FC, memo } from 'react'

import { SalesInfo } from 'commons/types/DTO/bank/reportWebPage'
import { formatCurrency, formatNumber } from 'commons/utils'

import { SalesSourcesTable } from './SalesSourcesTable'

import * as SC from './styles'

export const SalesReportTable: FC<{ salesInfo: SalesInfo; monthlyAnalyticsID: number; bankAccountId: number }> = memo(
  ({ salesInfo, monthlyAnalyticsID, bankAccountId }) => (
    <>
      <SC.WrappedLayer rounded shadowed>
        <SC.Columns>
          <SC.Labels>
            <SC.Label>Current Month</SC.Label>
          </SC.Labels>
          <SC.Table>
            <thead>
              <tr>
                <SC.Th>Total</SC.Th>
                <SC.Th colSpan={3}>Per Gram</SC.Th>
                <SC.Th colSpan={3}>Per Unit</SC.Th>
              </tr>
              <tr>
                <SC.Th2>$ Sold</SC.Th2>
                <SC.Th2>Grams Sold</SC.Th2>
                <SC.Th2>$ Sold</SC.Th2>
                <SC.Th2>$ per Gram </SC.Th2>
                <SC.Th2>Units Sold </SC.Th2>
                <SC.Th2>$ Sold</SC.Th2>
                <SC.Th2>$ per Unit</SC.Th2>
              </tr>
            </thead>
            <tbody>
              <tr>
                <SC.Td>{formatCurrency(salesInfo.total, false)}</SC.Td>
                <SC.Td>{formatNumber(salesInfo.gramsQty, false)}</SC.Td>
                <SC.Td>{formatCurrency(salesInfo.gramsSold, false)}</SC.Td>
                <SC.Td>{formatCurrency(salesInfo.gramsPerQty)}</SC.Td>
                <SC.Td>{formatNumber(salesInfo.unitsQty, false)}</SC.Td>
                <SC.Td>{formatCurrency(salesInfo.unitsSold, false)}</SC.Td>
                <SC.Td>{formatCurrency(salesInfo.unitsPerQty)}</SC.Td>
              </tr>
            </tbody>
          </SC.Table>
        </SC.Columns>
      </SC.WrappedLayer>
      {salesInfo.salesSourceItems.map((el, index) => (
        <SalesSourcesTable
          key={index}
          salesSourceItem={el}
          monthlyAnalyticsID={monthlyAnalyticsID}
          bankAccountId={bankAccountId}
        />
      ))}
    </>
  )
)
