import { BSAStatus } from '../types'

export const statusColorMap = {
  [BSAStatus.NEW]: '#FA5252',
  [BSAStatus.APPROVED]: '#FA5252',
  [BSAStatus.SUBMITTED]: '#FFB930',
  [BSAStatus.WARNINGS]: '#FFB930',
  [BSAStatus.ACKNOWLEDGED]: '#45D5B4',
  [BSAStatus.CANCELLED]: '#B8B8B8'
}
