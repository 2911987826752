import styled from 'styled-components'

export const Table = styled.table`
  text-align: left;
  max-width: 950px;
`

export const TableTitle = styled.th`
  width: 33%;
  font-weight: normal;
  padding: 0 10px 10px;
  &:first-child {
    padding-left: 0;
  }
`

export const TableRow = styled.tr`
  vertical-align: middle;

  td:first-child {
    vertical-align: top;
    padding-top: 10px;
    padding-left: 0;
  }

  td {
    padding: 0 10px;
    padding-bottom: 20px;
    vertical-align: top;
  }
`
