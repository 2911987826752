import { SortDirection } from 'ui/components'

import { DocumentListFilters } from '../types'
import { TableState } from '../useTableState/types'
import emptyTableState from './emptyTableState'

const calculateInitState = ({
  externalTableState,
  initFilters
}: {
  externalTableState: Partial<TableState>
  initFilters: DocumentListFilters
}): TableState => ({
  ...emptyTableState,
  ...externalTableState,
  pageSize: initFilters.filter.size || emptyTableState.pageSize,
  page: initFilters.filter.page || emptyTableState.page,
  order: [
    {
      field: initFilters.filter.sortBy || initFilters.filter.sortBy,
      direction: initFilters.filter.sortDesc ? SortDirection.DESC : SortDirection.ASC
    }
  ]
})

export default calculateInitState
