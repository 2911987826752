import React from 'react'

import { LabelValue, Value } from '../../../styles'
import { AccountSignerProps } from './AccountSignerProps'

export const AccountSigner = ({ contact }: AccountSignerProps) => {
  const { isCompanyAccountSigner } = contact
  return (
    <>
      <LabelValue>
        <div>
          Is this contact the <b>Account Signer</b>?
        </div>
        <Value>{isCompanyAccountSigner ? 'Yes' : 'No'}</Value>
      </LabelValue>
    </>
  )
}
