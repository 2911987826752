import { RefObject, useCallback, useRef } from 'react'

import { useInterval } from 'commons/hooks/common'
import { localStorageService } from 'commons/service/localStorageService'

import { getRemainTime } from './getRemainTime'

export type ExpiresTime = ReturnType<typeof getRemainTime>

export const useGetSessionExpiresAt = ({ hasUser }: { hasUser: boolean }): RefObject<ExpiresTime> => {
  const expiresIn = useRef<ExpiresTime>()

  const checkSessionExpiration = useCallback(() => {
    if (!hasUser && !expiresIn) {
      return
    }

    expiresIn.current = hasUser ? getRemainTime(localStorageService.getSessionExpiresAt()) : undefined
  }, [expiresIn, hasUser])

  useInterval(checkSessionExpiration)

  return expiresIn
}
