import { useEffect } from 'react'

import { zendeskService } from 'commons/service/zendesk'

type UseZendeskParams = {
  zendeskWidgetKey: string
  hideOnDestroy?: boolean
}

type UseZendeskValue = {
  activateZendeskWidget: () => void
  hideZendeskWidget: () => void
}

export const useZendesk = ({ zendeskWidgetKey, hideOnDestroy = true }: UseZendeskParams): UseZendeskValue => {
  // load script, after component destroy hide widget if `hideOnDestroy`
  useEffect(() => {
    if (zendeskWidgetKey) {
      zendeskService.init({ key: zendeskWidgetKey })
    }

    return () => {
      if (hideOnDestroy) {
        zendeskService.hideWidget()
      }
    }
  }, [zendeskWidgetKey])

  return {
    activateZendeskWidget: zendeskService.activateWidget,
    hideZendeskWidget: zendeskService.hideWidget
  }
}
