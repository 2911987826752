import React, { FC, ReactNode, memo } from 'react'

import { CheckBox } from 'ui/components/CheckBox'
import { RadioButton } from 'ui/components/RadioGroup'

import { InfoProps } from './InfoProps'

import * as SC from './styles'

export const Info: FC<InfoProps> = memo(({ children, label, content, type, dataQA, ...rest }) => {
  let toRender: ReactNode

  if (type == 'checkbox' && Array.isArray(content) && content.length) {
    toRender = (
      <SC.Checkboxes>
        {content.map((value) => (
          <CheckBox readOnly value={value.selected} checkBoxLabel={value.label} />
        ))}
      </SC.Checkboxes>
    )
  }

  if (type == 'radio' && Array.isArray(content) && content.length) {
    // TODO: for unknown reasons readonly mode = disabled, will fixed when we rethink readonly modes
    toRender = content.map((value) => (
      <RadioButton disabled readonly value={value.selected} checked={value.selected} label={value.label} />
    ))
  }

  return (
    <SC.Box $hasLabel={!!label} {...rest}>
      {!!label && <SC.Label>{label}</SC.Label>}
      <SC.Content data-qa={dataQA || label}>{children || toRender || '---'}</SC.Content>
    </SC.Box>
  )
})
