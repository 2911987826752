import React, { FC, memo, useMemo } from 'react'

import { Form } from 'brief-form'
import { BankAccountSelect, ContactSelect, LicenseSelect, Select } from 'ui/components/Select'
import { FilterPanel } from 'ui/components/Table'

import { FilterProps } from './FilterProps'

import { FormBox, SelectWrapper } from './styles'

export const Filter: FC<FilterProps> = memo((props) => {
  const {
    form: { config, isDirty, Field },
    expanded,
    onToggle,
    isSubjectHidden,
    webPage,
    onClear
  } = props
  const subjectType = config?.value?.subjectType
  const showLicenseFilter = useMemo(() => subjectType === 'license', [config])
  const showContactFilter = useMemo(() => subjectType === 'contact', [config])
  const showBankAccountFilter = useMemo(() => subjectType === 'bank-account', [config])

  return (
    <FilterPanel isDirty={isDirty} onReset={onClear} expanded={expanded} onToggle={onToggle}>
      <Form config={config}>
        <FormBox>
          <SelectWrapper>
            <Field
              name="isInternal"
              label="Internal"
              input={Select}
              inputProps={{
                data: webPage.internalOptions
              }}
            />
          </SelectWrapper>
          {!isSubjectHidden && (
            <SelectWrapper>
              <Field
                name="subjectType"
                label="Subject Type"
                input={Select}
                inputProps={{
                  data: webPage.subjectTypeOptions
                }}
              />
            </SelectWrapper>
          )}
          {showLicenseFilter && (
            <SelectWrapper>
              <Field name="licenseID" label="License" input={LicenseSelect} inputProps={{}} />
            </SelectWrapper>
          )}
          {showContactFilter && (
            <SelectWrapper>
              <Field name="contactID" label="Contact" input={ContactSelect} inputProps={{}} />
            </SelectWrapper>
          )}
          {showBankAccountFilter && (
            <SelectWrapper>
              <Field name="bankAccountID" label="Bank Account" input={BankAccountSelect} inputProps={{}} />
            </SelectWrapper>
          )}
          <SelectWrapper>
            <Field
              name="frequency"
              label="Frequency"
              input={Select}
              inputProps={{
                data: webPage.frequencyOptions
              }}
            />
          </SelectWrapper>
          <SelectWrapper>
            <Field
              name="alerts"
              label="Alerts"
              input={Select}
              inputProps={{
                data: webPage.alertsOptions
              }}
            />
          </SelectWrapper>
        </FormBox>
      </Form>
    </FilterPanel>
  )
})
