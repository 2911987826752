import React, { FC, memo } from 'react'

import { Form, useFormData } from 'brief-form'
import { Dialog, DialogFace, DialogFooterActions } from 'ui/components/Dialog'
import { TextareaInput } from 'ui/components/InputV2'

import { PublicationNotesDialogProps } from './PublicationNotesDialogProps'

import * as SC from './styles'

export const PublicationNotesDialog: FC<PublicationNotesDialogProps> = memo(({ publication, onClose, onSaveNotes }) => {
  if (!publication) {
    return null
  }

  const { isDirty, config, Field } = useFormData<{ notes: string }>({
    initialValue: { notes: publication.notes || '' }
  })

  const handleSaveNotes = () => {
    onSaveNotes(config.value.notes)
  }

  return (
    <Dialog
      face={DialogFace.PRIMARY}
      id="publication-notes-edit-dialog"
      onClose={onClose}
      title={<SC.Title>{publication.title}</SC.Title>}
      subTitle="Edit Notes"
      displayCloseConfirmation={isDirty}
      footerActions={[DialogFooterActions.SAVE, DialogFooterActions.CANCEL]}
      onSubmit={() => handleSaveNotes()}
    >
      <SC.Content>
        <Form config={config}>
          <Field
            name="notes"
            label="Notes"
            input={TextareaInput}
            inputProps={{
              style: {
                height: '190px'
              }
            }}
          />
        </Form>
      </SC.Content>
    </Dialog>
  )
})
