import * as React from 'react'

import SC from 'styled-components'

import { Icon, IconName, IconPosition, IconSize } from '../Icon'
import { Spinner } from '../Spinner'
import { ButtonFace } from './buttonFace'
import { ButtonSize } from './buttonSize'
import { Default, Link, Primary, Secondary } from './components'

/** @deprecated see Button */
export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  face?: ButtonFace
  children?: any
  icon?: IconName
  iconPosition?: IconPosition
  size?: ButtonSize
  isPending?: boolean
  'data-qa'?: string
}

/** @deprecated see Button */
const iconSizes = {
  [ButtonSize.LARGE]: IconSize.XL,
  [ButtonSize.MEDIUM]: IconSize.M,
  [ButtonSize.SMALL]: IconSize.XS
}

//region Styled

const Container = SC.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const WrappedIcon = SC(Icon)<{
  $onlyIcon: boolean
  $margin: string
}>`
  margin: ${(p) => (p.$onlyIcon ? '0' : p.$margin)};
`

const WrappedSpinner = SC(Spinner)<{
  $size?: IconSize
  $margin?: string
}>`
  width: ${(p) => `${p.$size}px`};
  height: ${(p) => `${p.$size}px`};
  margin: ${(p) => p.$margin};
  border-width: 2px;
`

//endregion

interface ContentProps {
  icon?: IconName
  iconSize?: IconSize
  iconPosition?: IconPosition
  isPending?: boolean
  children?: any
  dataQa?: string
}

const Content = ({ icon, iconSize, iconPosition, isPending, children, dataQa }: ContentProps) => {
  const marginRight = '0 6px 0 0'
  const marginLeft = '0 0 0 6px'

  return iconPosition === IconPosition.RIGHT ? (
    <Container>
      {children}
      {!!icon && !isPending && (
        <WrappedIcon $onlyIcon={Boolean(!children)} $margin={marginLeft} name={icon} size={iconSize} />
      )}
      {isPending && <WrappedSpinner $margin={marginLeft} $size={iconSize} />}
    </Container>
  ) : (
    <Container>
      {!!icon && !isPending && (
        <WrappedIcon
          $onlyIcon={Boolean(!children)}
          $margin={marginRight}
          name={icon}
          size={iconSize}
          data-qa={dataQa ? `${dataQa}-icon` : undefined}
        />
      )}
      {isPending && <WrappedSpinner $margin={marginRight} $size={iconSize} />}
      {children}
    </Container>
  )
}

/**
 * @deprecated
 *
 * Used only in the ui kit components
 *
 * Look at Buttons for replacement
 */
export const Button = React.memo((props: ButtonProps) => {
  const { icon, face, size, children, isPending, 'data-qa': dataQA, ...rest } = props
  const finalSize = size || ButtonSize.MEDIUM
  const iconSize = iconSizes[finalSize]
  const iconPosition = props.iconPosition || IconPosition.LEFT

  const data_qa = typeof children === 'string' && !dataQA ? children : dataQA

  if (face === ButtonFace.PRIMARY) {
    // in use
    return (
      <Primary data-qa={data_qa} size={finalSize} $onlyIcon={Boolean(icon && !children)} {...rest}>
        <Content icon={icon} iconSize={iconSize} iconPosition={iconPosition} isPending={isPending} dataQa={dataQA}>
          {children}
        </Content>
      </Primary>
    )
  }

  if (face === ButtonFace.SECONDARY) {
    // in use
    return (
      <Secondary data-qa={data_qa} size={finalSize} $onlyIcon={Boolean(icon && !children)} {...rest}>
        <Content icon={icon} iconSize={iconSize} iconPosition={iconPosition} isPending={isPending} dataQa={dataQA}>
          {children}
        </Content>
      </Secondary>
    )
  }

  if (face === ButtonFace.LINK) {
    // in use
    return (
      <Link data-qa={data_qa} size={finalSize} $onlyIcon={Boolean(icon && !children)} {...rest}>
        <Content icon={icon} iconSize={iconSize} iconPosition={iconPosition} isPending={isPending} dataQa={dataQA}>
          {children}
        </Content>
      </Link>
    )
  }

  return (
    <Default data-qa={data_qa} size={finalSize} $onlyIcon={Boolean(icon && !children)} {...rest}>
      <Content icon={icon} iconSize={iconSize} iconPosition={iconPosition} isPending={isPending} dataQa={dataQA}>
        {children}
      </Content>
    </Default>
  )
})
