import React from 'react'

import { getRouteApi, useNavigate } from '@tanstack/react-router'
import { useEditEnvelopeOnboardingDocuments } from 'commons/hooks/bank/onboardingSetupDocuments'
import { OnboardingDocumentEditModeWebPage } from 'commons/types/DTO/bank/applications/onboardingDocuments'
import { Toast } from 'ui/components'

import { ManageContainer } from '../../containers/ManageContainer'

const routeApi = getRouteApi('/application/$applicationId/onboarding-documents/$id/edit')

const OnboardingDocumentsEdit = () => {
  const navigate = useNavigate()

  const applicationId = routeApi.useParams({ select: (s) => s.applicationId })
  const webPageData: OnboardingDocumentEditModeWebPage = routeApi.useLoaderData({ select: (s) => s.webPageData })

  const { routine: create } = useEditEnvelopeOnboardingDocuments({
    onSuccess: async () => {
      navigate({
        from: '/application/$applicationId/onboarding-documents/$id/edit',
        to: '/application/$applicationId/onboarding-documents/$id/details',
        params: { applicationId, id: webPageData.id }
      })
      Toast.successSmall(`The set of documents ID-${webPageData.id} was updated successfully`)
    }
  })

  const onSubmit = (form) => create({ ...form, applicationId: Number(applicationId), id: webPageData.id })

  return (
    <ManageContainer
      webPageData={webPageData}
      pageTitle={webPageData.idLabel}
      pageSubTitle="Setup Onboarding Documents"
      footerSubmitButtonText="Edit Onboarding Documents"
      onSubmitForm={onSubmit}
    />
  )
}

export default OnboardingDocumentsEdit
