// used: packages/bank-onboarding/src/routes.ts
import { AnyRoute, createRoute, lazyRouteComponent } from '@tanstack/react-router'
import { BankApplicationsRouterPaths } from 'commons/constants/routes'
import { PortalName } from 'commons/types/enums'

import { onboardingFormLoader } from './onboardingFormLoader'

export const ApplicationEditRoute = <T extends AnyRoute>(indexRoute: T) =>
  createRoute({
    // /onboarding/applications/edit/$applicationId
    path: BankApplicationsRouterPaths.EDIT,
    component: lazyRouteComponent(() => import('./BankOnboardingForm')),
    parseParams: (rawParams): Record<'applicationId', number> => ({ applicationId: Number(rawParams?.applicationId) }),
    loader: async ({ params }) => await onboardingFormLoader({ portalName: PortalName.BP, params }),
    getParentRoute: () => indexRoute
  })
