import { bankAccountNumber } from 'ui/components/Form/validators/bankAccountNumber'

import { RelationshipBankAccountFormDataRecord } from '../../../types'

export const isFormValid = ({ formData }: { formData: RelationshipBankAccountFormDataRecord }) => {
  const { value: formValue } = formData

  const isBankAccountValid = !bankAccountNumber.notEmptyAndValid(formValue.accountNumber)

  const isOpeningDateValid = !!formValue.openingDate
  const isAssignedLicensesValid = !!formValue.assignedLicenses.length
  return isBankAccountValid && isOpeningDateValid && isAssignedLicensesValid
}
