import { MutableRefObject, useCallback, useEffect } from 'react'

import { DialogStack } from '../components/Dialog/DialogStack'

export const useOutsideClick = (ref: MutableRefObject<any>, callback: (target: HTMLElement) => any, isModal = true) => {
  // sometimes outsideclick triggers by hidden programmatically created tags, that cause unwanted closing of dialogs
  // as an example: DowloadLink component create <a> tag and call click() method, if that component was placed
  // on popup, popup will be closed by outsideclick, because of that we check element visibility
  // TODO: move it to some common utils lib?
  const isVisible = (node: HTMLElement | SVGElement) => {
    const rects = node.getClientRects()
    return rects[0] && rects[0].width > 0 && rects[0].height > 0
  }
  const handleClick = useCallback(
    (event: any) => {
      const isUserSelectsText = window.getSelection()!.type === 'Range'
      const stack = DialogStack.getInstance()

      const condition =
        ref?.current && !ref.current.contains(event.target) && !isUserSelectsText && isVisible(event.target)

      if (!isModal && condition) {
        callback(event.target)
      }

      if (isModal && condition && stack.isOnTop(ref.current)) {
        callback(event.target)
      }
    },
    [callback, isModal, ref]
  )

  useEffect(() => {
    document.addEventListener('mousedown', handleClick)

    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [handleClick])
}
