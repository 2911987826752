type AnnualReviewEdit = {
  companyId: number | string
  id: number | string
}

type AnnualReviewDetail = AnnualReviewEdit & { hash?: string }

export const getAnnualReviewDetailUrl = ({ companyId, id, hash }: AnnualReviewDetail): string =>
  `/annual-review/detail/${companyId}/${id}${hash ? `#${hash}` : ''}`

export const getAnnualReviewEditUrl = ({ companyId, id }: AnnualReviewEdit): string =>
  `/annual-review/edit/${companyId}/${id}`
