import React from 'react'

import { BankAccountsPath, CompaniesPath, ContactsPath, LicensesPath } from 'commons/constants/routes'
import { IconSize } from 'ui/components/Icon'
import { Link } from 'ui/components/Link'
import { TextMask } from 'ui/components/TextMask'
import { DocumentSubject } from '~bank-documents/types/documentList/response.types'

export const getSubjectType = (subject: DocumentSubject) => {
  switch (subject.type) {
    case 'company':
      return (
        <>
          <div>{subject.label}</div>
          <Link to={CompaniesPath.DETAILS} params={{ companyId: subject.id }}>
            {subject.name}
          </Link>
        </>
      )
    case 'license':
      return (
        <>
          <div>{subject.label}</div>
          <Link to={LicensesPath.DETAILS} params={{ licenseId: subject.id }}>
            {subject.name}
          </Link>
        </>
      )
    case 'bank_account':
      return (
        <>
          <div>{subject.label}</div>
          <Link to={BankAccountsPath.DETAILS} params={{ bankAccountId: subject.id }}>
            <TextMask isBankAccount text={subject.name} iconSize={IconSize.XS} />
          </Link>
        </>
      )
    case 'contact':
      return (
        <>
          <div>{subject.label}</div>
          <Link to={ContactsPath.DETAILS} params={{ contactId: subject.id }}>
            {subject.name}
          </Link>
        </>
      )
    default:
      // eslint-disable-next-line no-console
      console.log('wrong subject.type', subject.type)
      return <></>
  }
}
