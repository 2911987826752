import React from 'react'

import { FF, Select } from 'ui/components'

import { useSARFormContext } from '../../../../../../context'

const EMPTY_OPTION = { label: 'Not selected', value: '' }

type CorroborativeStatementToFilerProps = {
  index: number
}

export const CorroborativeStatementToFiler = ({ index }: CorroborativeStatementToFilerProps) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={
        `subjects_info[${index}].corroborative_statement_to_filer` as 'subjects_info[0].corroborative_statement_to_filer'
      }
      children={(field) => (
        <FF
          field={field}
          label="23. Corroborative statement to filer?"
          input={(p) => (
            <Select
              {...p}
              data={[
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' }
              ]}
              emptyOption={EMPTY_OPTION}
            />
          )}
        ></FF>
      )}
    />
  )
}
