import React from 'react'

import { PageSection, PageSectionFace } from 'ui/components'
import { Button } from 'ui/components/Buttons'
import { FormRow } from 'ui/themes/globalStyles'

import { useSARFormContext } from '../../../../context'
import { TransactionInfo } from './TransactionInfo'

const EMPTY_ACCOUNT = {
  account_number: '',
  total_in: 0,
  cash_in: 0,
  check_in: 0,
  ach_in: 0,
  transfer_in: 0,
  total_out: 0,
  check_out: 0,
  ach_out: 0,
  debit_card_out: 0,
  transfer_out: 0,
  wires_out: 0,
  returned_checks_out: 0,
  total_bank_fees: 0,
  withdrawals_out: 0,
  cash_out: 0,
  total: 0,
  wires_in: 0
}

export const TransactionsInfo = () => {
  const { formApi } = useSARFormContext()

  return (
    <PageSection face={PageSectionFace.THIRD} title="Transactions Information" anchor="transactions-info">
      <formApi.Field name="transactions_info.by_account" mode="array">
        {(field) => {
          if (typeof field.state.value === 'undefined') {
            return null
          }

          return (
            <>
              {(field.state.value || []).map((_, index) => (
                <TransactionInfo key={index} index={index} total={field.state.value.length} field={field} />
              ))}
              <FormRow>
                <Button face="positive" onClick={() => field.pushValue(EMPTY_ACCOUNT)}>
                  Add
                </Button>
              </FormRow>
            </>
          )
        }}
      </formApi.Field>
    </PageSection>
  )
}
