import SC from 'styled-components'
import { Layer } from 'ui/components/Layer'

export const WrappedLayer = SC(Layer)`
  display: inline-block;
  margin-bottom: 47px;
  width: 100%;
`

export const Box = SC.div`
  border-top: 2px solid #000;
`

export const Title = SC.h5`
  padding: 10px 20px;
  font-weight: 600;
`

export const SubTitle = SC.span`
  font-size: 0.78em;
`

export const Columns = SC.div`
  display: flex;
`

export const Labels = SC.div`
  background: ${(p) => p.theme.uiKit.colors.global.grey.grey100};
  padding: 72px 0 0 0;
`

export const Label = SC.div`
  height: 34px;
  padding: 9px 16px;
  font-size: 13px;
  line-height: 16px;
`

export const Table = SC.table`
  border-collapse: collapse;
`

export const Th = SC.th`
  font-size: 14px;
  line-height: 22px;
  color: #fff;
  background: ${(p) => p.theme.uiKit.colors.global.grey.grey900};
  padding: 8px 30px;
  border-right: 1px solid #fff;
  
  &:last-child {
    border-right: none;
  }
`

export const Th2 = SC.th`
  font-size: 11px;
  line-height: 14px;
  color: ${(p) => p.theme.uiKit.colors.global.grey.grey500};
  background: ${(p) => p.theme.uiKit.colors.global.grey.grey300};
  padding: 10px 40px;
  border-right: 1px solid #fff;
  
  &:last-child {
    border-right: none;
  }
`

export const Td = SC.td<{ $hl?: boolean }>`
  font-size: 13px;
  line-height: 16px;
  padding: 9px 16px;
  text-align: center;
  border-right: 1px solid ${(p) => p.theme.uiKit.colors.global.grey.grey100};
  background: ${(p) => (p.$hl ? p.theme.uiKit.colors.theme.negative.main : 'transparent')};
  
  &:last-child {
    border-right: none;
  }
`

export const Stub = SC.td`
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  background: ${(p) => p.theme.uiKit.colors.global.grey.grey100};
  height: 34px;
`

export const TV = SC.span<{ $negative?: boolean }>`
  color: ${(p) =>
    p.$negative ? p.theme.uiKit.colors.theme.negative.main : p.theme.uiKit.colors.global.general.secondary};
`
