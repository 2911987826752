import React from 'react'

import { TitleWithMargin, Wrapper } from '../styles'
import { Hr } from 'ui/components/Hr'

import { LicenseInformationProps } from './LicenseInformationProps'
import { AddressInformation, LicenseDetails } from './components'

import { Header } from './styles'

export const LicenseInformation = ({ licenses }: LicenseInformationProps) => (
  <Wrapper>
    <TitleWithMargin>License Information</TitleWithMargin>
    {licenses.map((license, index) => (
      <div key={index}>
        <Header>License #{index + 1}</Header>
        <LicenseDetails license={license} />
        <Hr />
        <AddressInformation license={license} />
      </div>
    ))}
  </Wrapper>
)
