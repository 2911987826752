import SC from 'styled-components'

import { Button } from '../Button'
import { CheckBox } from '../CheckBox'

export const DateControlWrapper = SC.div<{
  $error?: boolean
  $required?: boolean
  $withTime?: boolean
  $opened?: boolean
}>`
  background: white;

  input {
    width: ${(p) => (p.$withTime ? 190 : 160)}px;
  }

  .react-datepicker__month {
    margin: 0.4rem;
    text-align: center;
  }
  
  .react-datepicker__header {
    background: ${(p) => p.theme.uiKit.colors.global.grey.grey100};
    border-bottom: 1px solid #aeaeae;
    text-align: center;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    padding-top: 8px;
    position: relative;
  }
  
  div[class='react-datepicker__header'] {
    border-bottom: none;
  }

  .react-datepicker__header--time {
    padding-bottom: 8px;
    padding-left: 5px;
    padding-right: 5px;
  }
  
  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    margin-top: 0;
    color: #000;
    font-weight: bold;
    font-size: 0.944rem;
  }

  .react-datepicker__year-dropdown-container--select,
  .react-datepicker__month-dropdown-container--select,
  .react-datepicker__month-year-dropdown-container--select,
  .react-datepicker__year-dropdown-container--scroll,
  .react-datepicker__month-dropdown-container--scroll,
  .react-datepicker__month-year-dropdown-container--scroll {
    display: inline-block;
    margin: 0 2px;
  }

  .react-datepicker__day-names,
  .react-datepicker__week {
    white-space: nowrap;
  }
  
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    color: #000;
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
  }
  
  .react-datepicker__day,
  .react-datepicker__month-text {
    cursor: pointer;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range {
    border-radius: 0.3rem;
    color: #fff;
    background: ${(p) => p.theme.uiKit.colors.theme.brand.primary};
    
    &:hover {
      background: ${(p) => p.theme.uiKit.colors.theme.brand.primary};
    }
  }
  
  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover {
    border-radius: 0.3rem;
    background-color: #f0f0f0;
  }

  .react-datepicker__day--today,
  .react-datepicker__month-text--today {
    background: ${(p) => p.theme.uiKit.colors.theme.brand.primary};
  }
  
  .react-datepicker__time-container {
    border-left: 1px solid #aeaeae;
  }

  div[class*='react-datepicker__time-container'] {
    width: 100px;
    float: left;
  }

  .react-datepicker-time__header {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  
  .react-datepicker__time-container .react-datepicker__time {
    position: relative;
    background: white;
  }
    
  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    overflow-x: hidden;
    margin: 0 auto;
    text-align: center;
  }

  div[class*='react-datepicker__time-box'] {
    width: 100px !important;
  }

  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
    list-style: none;
    margin: 0;
    height: calc(195px + (1.7rem / 2));
    overflow-y: scroll;
    padding-right: 0px;
    padding-left: 0px;
    width: 100%;
    box-sizing: content-box;
  }

  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
    height: 30px;
    padding: 5px 10px;
  }
  
  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    color: white;
    font-weight: bold;
    background-color: ${(p) => p.theme.uiKit.colors.theme.brand.primary} !important;
  }
  
  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
    cursor: pointer;
    background-color: #f0f0f0;
  }

  button[class*='react-datepicker__navigation'] {
    background: none;
    line-height: 1.7rem;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 10px;
    padding: 0;
    border: 0.45rem solid transparent;
    z-index: 1;
    height: 10px;
    width: 10px;
    text-indent: -999em;
    overflow: hidden;
  }

  button[class*='react-datepicker__navigation--previous'] {
    left: 10px;
    border-right-color: #ccc;
  }
  
  button[class*='react-datepicker__navigation--next'] {
    border-left-color: #ccc;
    right: ${(p) => (p.$withTime ? 110 : 10)}px !important;
  }
  
  div[class='react-datepicker__month-container'] {
    float: left;
  }
  
  div[class='react-datepicker__triangle'] {
    display: none;
  }
  
  div[class='react-datepicker-popper'] {
    border: none;
    border-radius: 4px;
    overflow: hidden;
    z-index: 10000;
    width: ${(p) => (p.$withTime ? 21.3 : 15)}rem;
  }
  
  div[class='react-datepicker'] {
    border: none;
    display: block !important;
    overflow: hidden !important;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 0.8rem;
    background-color: #fff;
    color: #000;
    border-radius: 0.3rem;
    position: relative;
  }
  
  div[class='react-datepicker__month-dropdown'] {
    height: 220px;
    overflow: scroll;
  }
`

export const Outer = SC.div`
  position: relative;
  padding: 0;
  width: 100%;
  display: flex;
  align-items: center;
`

export const OKButton = SC(Button)`
  float: right;
  clear: left;
  margin: 7px 12px 7px 0;
`

export const CheckBoxWrapper = SC(CheckBox)`
  margin: 0 0 0 10px;
`
