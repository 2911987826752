import {
  ADVERSE_MEDIA_PATH,
  BANK_ALERTS,
  BANK_CLIENT_USERS_PATH,
  BANK_USERS_PATH,
  BSA_MODULE_PATH,
  BankApplicationPaths,
  COMPANIES_PATH,
  CONTACTS_PATH,
  DOCUMENTS_PATH,
  HISTORY_PATH,
  INTERNAL_TRANSFERS_PATH,
  KEY_CONNECTIONS_PATH,
  REPORTS_PATH
} from 'commons/constants/routes'
import { IconName } from 'ui/components/Icon'

export const allMenuItems = [
  {
    to: BANK_ALERTS,
    children: 'Alerts',
    icon: IconName.MAIN_MENU_ALERTS
  },
  {
    to: COMPANIES_PATH,
    children: 'Relationships',
    icon: IconName.MAIN_MENU_RELATIONSHIPS
  },
  {
    to: BankApplicationPaths.LIST,
    children: 'Applications',
    icon: IconName.MAIN_MENU_APPLICATION
  },
  {
    to: BSA_MODULE_PATH,
    children: 'BSA Reporting',
    icon: IconName.MAIN_MENU_BSA
  },
  {
    to: CONTACTS_PATH,
    children: 'Contacts',
    icon: IconName.MAIN_MENU_CONTACTS
  },
  {
    to: DOCUMENTS_PATH,
    children: 'Documents',
    icon: IconName.MAIN_MENU_DOCUMENTS
  },
  {
    to: KEY_CONNECTIONS_PATH,
    children: 'Connections',
    icon: IconName.MAIN_MENU_CONNECTIONS
  },
  {
    to: BANK_CLIENT_USERS_PATH,
    children: 'Client Users',
    icon: IconName.MAIN_MENU_CLIENT_USERS
  },
  {
    to: ADVERSE_MEDIA_PATH,
    children: 'Adverse Media',
    icon: IconName.MAIN_MENU_ADVERSE_MEDIA
  },
  {
    to: INTERNAL_TRANSFERS_PATH,
    children: 'Internal Transfers',
    icon: IconName.MAIN_MENU_INTERNAL_TRANSFERS
  },
  {
    to: REPORTS_PATH,
    children: 'Reports',
    icon: IconName.MAIN_MENU_REPORTS
  },
  {
    to: BANK_USERS_PATH,
    children: 'Users',
    icon: IconName.MAIN_MENU_USERS
  },
  {
    to: HISTORY_PATH,
    children: 'Activity Audit',
    icon: IconName.MAIN_MENU_ACTIVITY_AUDIT
  }
]
