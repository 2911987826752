import { ButtonHTMLAttributes } from 'react'

import { ButtonFace } from './ButtonFace'

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  face?: ButtonFace
  loading?: boolean

  'data-qa'?: string
}
