import React, { FC, memo } from 'react'

import { CompanyDocumentsTitleProps } from './CompanyDocumentsTitleProps'

import * as SC from './styles'

export const CompanyDocumentsTitle: FC<CompanyDocumentsTitleProps> = memo(({ companyName, title }) => (
  <div>
    <SC.Subtitle>{title}</SC.Subtitle>
    <h3>{companyName}</h3>
  </div>
))
