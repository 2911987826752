import styled from 'styled-components'
import { DownloadLink } from 'ui/components'

export const DownloadFile = styled(DownloadLink)`
  height: auto;
`

export const FileName = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  text-decoration: underline;
`

export const DeleteButton = styled.div`
  display: flex;
  cursor: pointer;
`
