import styled from 'styled-components'
import { DownloadLink } from 'ui/components/DownloadLink'

export const CSVLink = styled(DownloadLink)`
  width: 116px;
  height: 38px;
  color: ${(p) => p.theme.uiKit.colors.global.grey.grey900} !important;
  font-weight: ${(p) => p.theme.uiKit.fontWeights['700']};
  background: ${(p) => p.theme.uiKit.colors.global.grey.grey100};
  border-radius: ${(p) => p.theme.uiKit.borderRadius.default};

  svg {
    > path {
      fill: ${(p) => p.theme.uiKit.colors.global.grey.grey100} !important;
    }
    g {
      path {
        fill: ${(p) => p.theme.uiKit.colors.global.grey.grey900} !important;
      }
    }
  }
`
