import { useCallback, useMemo, useState } from 'react'

import { notFound } from '@tanstack/react-router'
import { BeforeFormChangeHandler, useFormData } from 'brief-form'
import { useGetRelationshipsReport } from 'commons/hooks/bank/report'
import { RelationshipsReportFilter, RelationshipsReportItem } from 'commons/types/DTO/bank'
import { DataSourceItem, useTableState } from 'ui/components'

const DEFAULT_FILTER: RelationshipsReportFilter = {
  page: 1,
  size: 10,
  search: '',
  inApplicationProcessOnly: false
}

export const useRelationshipsReportEssence = () => {
  const [filter, setFilter] = useState<RelationshipsReportFilter>(DEFAULT_FILTER)

  const { isFetched, isFetching, data } = useGetRelationshipsReport(filter)

  const onBeforeChange: BeforeFormChangeHandler<RelationshipsReportFilter> = useCallback(({ value, oldValue }) => {
    const isFilterChanged =
      value.search !== oldValue.search || value.inApplicationProcessOnly !== oldValue.inApplicationProcessOnly

    if (isFilterChanged) value = { ...value, page: DEFAULT_FILTER.page }

    setFilter(value)
    return {
      value,
      errors: {}
    }
  }, [])

  const filterForm = useFormData({ initialValue: filter, onBeforeChange })
  const { config, set } = filterForm

  const onPageChange = useCallback(
    (page: number, size: number) => {
      config.onChange({ ...filter, page, size }, {})
    },
    [filter]
  )

  const onClear = useCallback(() => {
    set({ reset: true })
    setFilter(DEFAULT_FILTER)
  }, [filter])

  const dataSource: DataSourceItem<RelationshipsReportItem>[] = useMemo(
    () => data?.records?.map((item) => ({ item })),
    [data]
  )

  const tableState = useTableState({ filter: {} })

  if (isFetched && !data) {
    throw notFound()
  }

  return {
    isFetching,
    dataSource,
    config,
    filterForm,
    tableState,
    totalCount: data?.totalCount,
    onPageChange,
    onClear,
    inApplicationProcessOnlyValues: data?.inApplicationProcessOnlyValues
  }
}
