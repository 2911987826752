import { useCallback, useState } from 'react'

import { FilterAlertsWebPage } from 'commons/service/bank/alerts'
import { BsaBatches } from 'commons/types/DTO/bank/alerts/bsaBatches'
import { BsaBatch } from 'commons/types/DTO/bank/bsa'
import { useTableState } from 'ui/components'

export const useComponentsEssence = (onAlertFilterChange: (filter: FilterAlertsWebPage) => void) => {
  const [modalItem, setModalItem] = useState<BsaBatch | null>(null)
  const [loading, setLoading] = useState(false)
  const tableState = useTableState({ filter: {} })

  const changePagination = useCallback(
    async (page: number, size: number) => {
      setLoading(true)

      tableState.onPageChange(page, size)

      const filter = {
        bsaBatchesOffset: (page - 1) * size,
        bsaBatchesLimit: size
      }

      await onAlertFilterChange(filter)

      setLoading(false)
    },
    [onAlertFilterChange, tableState]
  )

  const onClose = useCallback(() => setModalItem(null), [])

  const onSetModalItem = useCallback((item: BsaBatches) => {
    const mappedItem: BsaBatch = {
      id: item.id,
      ctrs_info: item.CTRs.map((ctr) => ({
        ctr_id: ctr.id,
        ctr_name: ctr.name,
        ctr_status: ctr.status.toLowerCase()
      }))
    }
    setModalItem(mappedItem)
  }, [])

  return {
    modalItem,
    loading,
    changePagination,
    onClose,
    tableState,
    onSetModalItem
  }
}
