import * as React from 'react'

import { getInternalTransferStatusLabel } from './getInternalTransferStatusLabel'

export interface InternalTransferStatusLabelProps {
  name: string
}

export const InternalTransferStatusLabel = ({ name }: InternalTransferStatusLabelProps) => (
  <span>{getInternalTransferStatusLabel(name)}</span>
)
