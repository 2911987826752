import React, { FC, memo } from 'react'

import { Spinner, SpinnerSize } from 'ui/components'

import { DetailsPage } from './components'
import { useDetails } from './useDetails'

const InternalTransferDetails: FC = memo(() => {
  const { data, isFetching, cancelInternalTransfer, CancelConfirmation, pagination } = useDetails()

  if (isFetching && !data) {
    return <Spinner centered size={SpinnerSize.M} />
  }

  // Don't render page until filter contains values returned from API.
  if (data?.form.historyPage === null) {
    return null
  }

  return (
    <>
      <CancelConfirmation />
      <DetailsPage data={data} onCancel={cancelInternalTransfer} pagination={pagination} />
    </>
  )
})

export default InternalTransferDetails
