import { FormApi } from '@tanstack/react-form'
import { ServerErrorResponse } from 'errors'

import { Toast } from '../Toast'

/**
 * Handles submission error - shows toast with description and optionally highlights form invalid field.
 * @param {unknown} error - server error.
 * @param {FormApi} formApi - tanstack form API.
 */
export const handleFormSubmissionError = <T>(error: unknown, formApi: FormApi<T, undefined>) => {
  const submitError = error as ServerErrorResponse

  Toast.errorSmall(submitError.message)

  if (submitError.meta) {
    delete submitError.meta['transactions_info.fraud']
    delete submitError.meta['transactions_info.cyber']
    delete submitError.meta['transactions_info.gaming']
    delete submitError.meta['transactions_info.identification']
    delete submitError.meta['transactions_info.insurance']
    delete submitError.meta['transactions_info.laundering']
    delete submitError.meta['transactions_info.mortgage']
    delete submitError.meta['transactions_info.other_activities']
    delete submitError.meta['transactions_info.securities']
    delete submitError.meta['transactions_info.structuring']
    delete submitError.meta['transactions_info.terrorist_financing']

    formApi.setErrorMap(submitError.meta)

    setTimeout(() => {
      const element = document.querySelector(`[data-field-with-error]`)
      element?.scrollIntoView({ block: 'center', behavior: 'smooth' })
    }, 0)
  }
}
