import React, { FC } from 'react'

import { IconName, IconSize } from 'ui/components'

import * as SC from './styles'

export const ErrorsMessage: FC<{ requiredFieldsLen: number; formErrors: Array<string> }> = ({
  requiredFieldsLen,
  formErrors
}) => (
  <SC.ErrorMessageBox>
    {!!requiredFieldsLen && (
      <SC.ErrorsBlock>
        <SC.Header>
          <strong>
            <SC.ErrorIcon name={IconName.ERROR} size={IconSize.S} />
            &nbsp;Please fill in the required fields
          </strong>
        </SC.Header>
        <p>You need to fill in {requiredFieldsLen} required fields</p>
      </SC.ErrorsBlock>
    )}
    {!!formErrors.length && (
      <SC.ErrorsBlock>
        <SC.Header>
          <strong>
            <SC.ErrorIcon name={IconName.ERROR} size={IconSize.S} />
            &nbsp;Warning
          </strong>
        </SC.Header>
        {formErrors.map((errorItem, index) => (
          <p key={[errorItem, index].join('-')}>{errorItem}</p>
        ))}
      </SC.ErrorsBlock>
    )}
  </SC.ErrorMessageBox>
)
