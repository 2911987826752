import React from 'react'

import { Navigate } from '@tanstack/react-router'
import { useBankPortalContext } from 'bank-portal-context'
import { BankApplicationsRouterPaths, COMPANIES_PATH } from 'commons/constants/routes'
import { Spinner, SpinnerSize } from 'ui/components/Spinner'
import { extractPathFromReturnUrl } from '~auth/hooks/helpers'

export const BaseRoute = () => {
  const { search, pathname } = window.location
  const returnUrl = search && extractPathFromReturnUrl(search)
  const isIndex = pathname === '/'
  const { isOnlyApplicationsAndAccountSettingsAccessible } = useBankPortalContext()
  let redirectUrl = COMPANIES_PATH
  if (returnUrl) {
    redirectUrl = returnUrl
  }
  // TODO: what is the case?
  if (!returnUrl && isIndex && isOnlyApplicationsAndAccountSettingsAccessible) {
    redirectUrl = BankApplicationsRouterPaths.INDEX
  }

  return (
    <>
      <Spinner centered size={SpinnerSize.M} />
      <Navigate from={pathname} to={redirectUrl} />
    </>
  )
}
