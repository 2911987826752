import React from 'react'

import { usStates } from 'commons/types/dictionaries'
import { FF, Select } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

export const State = () => {
  const { formApi } = useSARFormContext()
  const country = formApi.useStore((s) => s.values.institution_contact_info?.country)

  return (
    <formApi.Field
      name="institution_contact_info.state"
      validators={{
        onChange: ({ value }) =>
          country !== 'US' || !!value
            ? undefined
            : '"84. State" required when choose "United States of America" as "86. Country"'
      }}
      children={(field) => (
        <FF
          required={country === 'US'}
          label="84. State"
          field={field}
          input={(p) => <Select {...p} data={usStates} data-qa="84. State" options-data-qa="84. State option" />}
        />
      )}
    />
  )
}
