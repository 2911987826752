import { TaskRelatedEntityType, TaskStatus, TaskType } from '../../enums'
import { DocumentStatuses } from './documentStatuses'

export interface TaskMetaMetrc {
  license_id: string
  license_name: string
  license_number: string
  company_name: string
  document_start_date_type: string
}

export interface TaskMetaDocumentsUpload extends TaskMetaMetrc {
  company_id: string
  document_period_end_date: string
  document_period_start_date: string
  document_period_notes: string
  document_period_status: DocumentStatuses // 'pending_upload' | 'rejected' | 'incomplete'
}

export interface TaskMetaSalesData extends Omit<TaskMetaMetrc, 'license_number' | 'license_id'> {
  company_name: string
  license_id: string
  license_name: string
  license_number: string
  license_sales_type: string
}

export type TaskMeta = TaskMetaDocumentsUpload | TaskMetaMetrc | TaskMetaSalesData

export type Task = {
  id: number
  organization_id: number
  type: TaskType
  status: TaskStatus
  title: string
  description: string
  start_date?: string
  end_date?: string
  created_at: string
  updated_at: string
  related_entity: {
    type: TaskRelatedEntityType
    id: number
  }
  meta: TaskMeta
}
