import { InputHTMLAttributes } from 'react'

import { FormInputProps } from 'brief-form'
import { InputHintProps } from 'ui/core/InputHint'

type TextareaOpts = InputHintProps &
  Omit<InputHTMLAttributes<HTMLTextAreaElement>, 'onChange' | 'value'> & {
    validator?: (v: string | number | null) => string | undefined
    maxLength?: number
    rows?: number
    'data-qa'?: string
  }

export interface TextareaProps extends FormInputProps<string, TextareaOpts> {}
