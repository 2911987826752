import styled from 'styled-components'

import { Link } from '../Link'

export const Mask = styled.span`
  display: flex;
  align-items: center;
`

export const Text = styled.span`
  margin-right: 10px;
`
export const TextLink = styled(Link)`
  a {
    font-size: inherit !important;
  }
`

export const IconWrapper = styled.span<{ $masked: boolean }>`
  cursor: pointer;
  display: flex;
  position: relative;
  top: ${(p) => (p.$masked ? '0' : '1px')};
`
