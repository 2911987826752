import { FormInputProps } from 'brief-form'
import IMask from 'imask'

import { BaseInputOpts } from './BaseInputProps'

interface BaseMaskedInputOpts extends BaseInputOpts {
  /*
  Use to create mask:

  mask = IMask.createMask({
    mask: Number,
    thousandsSeparator: ','
    ...options
  })
  */
  mask?: IMask.AnyMasked
}

export interface BaseMaskedInputProps extends FormInputProps<string | number | null, BaseMaskedInputOpts> {}
