import { Reducer } from 'react'

import { TableState, TableStateAction, TableStateActions } from './types'

export const tableStateReducer: Reducer<TableState, TableStateAction> = (state, action) => {
  switch (action.type) {
    case TableStateActions.ON_CHANGE:
      return { ...state, ...action.payload }

    default:
      return state
  }
}
