import React from 'react'

import { CheckBox, FF } from 'ui/components'

import { useSARFormContext } from '../../../../../../../context'

export const G = () => {
  const { formApi } = useSARFormContext()
  return (
    <formApi.Field
      name="transactions_info.product_types.g"
      children={(field) => (
        <FF
          field={field}
          input={(p) => <CheckBox {...p} checkBoxLabel="g. Forex transactions" />}
          errorPosition="right"
        />
      )}
    />
  )
}
