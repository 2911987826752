type RelatedEntities = number[] | null

export type AdverseMediaSearchCase = {
  created_at: string | null
  external_id?: string
  id: number
  is_organization: boolean
  last_screening_date: string | null
  meta: {
    alerted_publications_count: number
    total_publications_count: number
    company_ids: RelatedEntities
    contact_ids: RelatedEntities
    external_license_ids: RelatedEntities
  }
  needs_review: boolean
  notes: string
  organization_id: number
  prev_risk_level: 'unknown' | 'low' | 'acknowledged_medium' | 'medium' | 'acknowledged_high' | 'high'
  prev_risk_level_set_at: string | null
  risk_level: 'unknown' | 'low' | 'acknowledged_medium' | 'medium' | 'acknowledged_high' | 'high'
  risk_level_set_at: string | null
  risk_related_fields_changed_at: string | null
  search_term: string
  smart_filter: boolean
  updated_at: string | null
}
