import React from 'react'

import { MonthlyAnalytics } from 'commons/types/DTO/bank/alerts/monthlyAnalytics'
import { DateTime, Link } from 'ui/components'
import { TableColumn } from 'ui/components/Table'

import * as SC from './styles'

export const columns = (): TableColumn<MonthlyAnalytics>[] => [
  {
    title: 'Period',
    align: 'center',
    render: (item) => (
      <Link to={`/mr/$id`} params={{ id: item.id }}>
        {item.period}
      </Link>
    )
  },
  {
    title: 'Status',
    align: 'center',
    render: ({ status, highlightStatus }) =>
      highlightStatus ? <SC.HighlightStatus>{status}</SC.HighlightStatus> : <>{status}</>
  },
  {
    title: 'Relationship',
    align: 'center',
    render: (item) => (
      <Link to="/relationships/$companyId" params={{ companyId: item.relationshipID }}>
        {item.relationship}
      </Link>
    )
  },
  {
    title: 'Last Modified Date',
    align: 'center',
    render: (item) => !!item && <DateTime date={item.lastModifiedDate} withTime />
  }
]
