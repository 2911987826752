{
  "name": "BP",
  "logo": {
    "_1x": "logo1x.png",
    "_2x": "logo2x.png",
    "_3x": "logo3x.png"
  },
  "authLogo": {
    "_1x": "authLogo1x.png",
    "_2x": "authLogo2x.png",
    "_3x": "authLogo3x.png"
  },
  "background": {
    "_1x": "bg1x.png",
    "_2x": "bg2x.png",
    "_3x": "bg3x.png"
  },
  "uiKit": {
    "borderRadius": {
      "roundedNone": "0px",
      "default": "4px",
      "roundedFull": "100px",
      "roundedModal": "12px"
    },
    "borderWidth": {
      "strokeNone": "0px",
      "strokeM": "2px",
      "strokeS": "1px"
    },
    "boxShadows": {
      "soft": {
        "soft100": {
          "color": "#0000001a",
          "type": "dropShadow",
          "x": "0px",
          "y": "0px",
          "blur": "20px",
          "spread": "0px"
        },
        "soft200": {
          "color": "#00000033",
          "type": "dropShadow",
          "x": "0px",
          "y": "0px",
          "blur": "20px",
          "spread": "0px"
        }
      },
      "offset": {
        "offset100Y4": {
          "color": "#0000001a",
          "type": "dropShadow",
          "x": "0px",
          "y": "4px",
          "blur": "12px",
          "spread": "0px"
        },
        "offset100Y8": {
          "color": "#0000001a",
          "type": "dropShadow",
          "x": "0px",
          "y": "8px",
          "blur": "20px",
          "spread": "0px"
        },
        "offset80Y6": {
          "x": "0px",
          "y": "6px",
          "blur": "24px",
          "spread": "0px",
          "color": "#00000014",
          "type": "dropShadow"
        }
      },
      "none": {
        "shadowNone": {
          "color": "#000000",
          "type": "dropShadow",
          "x": "0px",
          "y": "0px",
          "blur": "0px",
          "spread": "0px"
        }
      }
    },
    "colors": {
      "global": {
        "general": {
          "main": "#ffffff",
          "secondary": "#000000",
          "transparent": "#ffffff00"
        },
        "grey": {
          "grey100": "#f5f5f5",
          "grey300": "#e2e2e2",
          "grey500": "#b8b8b8",
          "grey700": "#868686",
          "grey900": "#484848"
        }
      },
      "theme": {
        "brand": {
          "darker": "#00916f",
          "darkest": "#006a51",
          "lighter": "#45d5b4",
          "lightest": "#77E0C8",
          "primary": "#00c698",
          "transparent": {
            "opacity12": "#00c6981f",
            "opacity60": "#00c69899"
          }
        },
        "gradient": {
          "login": "linear-gradient(200deg, #77E0C8 0%, #00c698 100%)"
        },
        "positive": {
          "darker": "#00916f",
          "darkest": "#006a51",
          "main": "#00c698",
          "transparent": {
            "opacity12": "#00c6981f"
          }
        },
        "negative": {
          "darker": "#b63c3c",
          "darkest": "#722626",
          "main": "#FA5252",
          "transparent": {
            "opacity12": "#FA52521f",
            "opacity24": "#FA52523d",
            "opacity60": "#FA525299"
          }
        },
        "warning": {
          "main": "#ffb930",
          "secondary": "#AA80EF",
          "transparent": {
            "opacity12": "#ffb9301f",
            "opacity60": "#ffb93099"
          }
        }
      }
    },
    "fontFamilies": {
      "brandFont": "Source Sans Pro"
    },
    "fontWeights": {
      "400": "400",
      "600": "600",
      "700": "700",
      "italic": "Italic"
    },
    "fontSize": {
      "h1": "26px",
      "h2": "24px",
      "h3": "22px",
      "h4": "20px",
      "h5": "18px",
      "bodyL": "16px",
      "bodyM": "14px",
      "bodySm": "13px",
      "bodyS": "12px",
      "special": "10px"
    },
    "letterSpacing": {
      "l": "0.6px",
      "m": "0.5px",
      "s": "0.2px",
      "none": "0px"
    },
    "lineHeights": {
      "l": "24px",
      "m": "20px",
      "s": "16px",
      "xl": "28px",
      "xxl": "32px"
    },
    "opacity": {
      "opacity600": "60%",
      "visible": "100%",
      "unvisible": "0%"
    },
    "paragraphSpacing": {
      "none": "0px"
    },
    "size": {
      "grid": "4px",
      "buttonsHeight": {
        "small": "32px",
        "middle": "44px",
        "large": "48px",
        "table": "24px"
      }
    },
    "space": {
      "padding": {
        "headerButton": {
          "large": "48px",
          "middle": "36px"
        },
        "button": {
          "large": "40px",
          "middle": "32px",
          "sm": "26px",
          "small": "24px",
          "xs": "16px"
        },
        "tableButton": {
          "table": "12px"
        }
      },
      "spacing": {
        "default": "4px",
        "outlineButton": "6px"
      }
    },
    "textCase": {
      "none": "none",
      "uppercase": "uppercase",
      "capitalize": "capitalize"
    },
    "textDecoration": {
      "none": "none",
      "underline": "underline"
    },
    "typography": {
      "h1": {
        "regular": {
          "fontFamily": "Source Sans Pro",
          "fontWeight": "400",
          "lineHeight": "32px",
          "fontSize": "26px",
          "letterSpacing": "0.6px",
          "paragraphSpacing": "0px",
          "textCase": "none",
          "textDecoration": "none"
        }
      },
      "h2": {
        "regular": {
          "fontFamily": "Source Sans Pro",
          "fontWeight": "400",
          "lineHeight": "32px",
          "fontSize": "24px",
          "letterSpacing": "0.6px",
          "paragraphSpacing": "0px",
          "textCase": "none",
          "textDecoration": "none"
        },
        "semiBold": {
          "fontFamily": "Source Sans Pro",
          "fontWeight": "600",
          "lineHeight": "32px",
          "fontSize": "24px",
          "letterSpacing": "0.6px",
          "paragraphSpacing": "0px",
          "textCase": "none",
          "textDecoration": "none"
        }
      },
      "h3": {
        "regular": {
          "fontFamily": "Source Sans Pro",
          "fontWeight": "400",
          "lineHeight": "28px",
          "fontSize": "22px",
          "letterSpacing": "0.5px",
          "paragraphSpacing": "0px",
          "textCase": "none",
          "textDecoration": "none"
        }
      },
      "h4": {
        "regular": {
          "fontFamily": "Source Sans Pro",
          "fontWeight": "400",
          "lineHeight": "28px",
          "fontSize": "20px",
          "letterSpacing": "0.2px",
          "paragraphSpacing": "0px",
          "textCase": "none",
          "textDecoration": "none"
        },
        "semiBold": {
          "fontFamily": "Source Sans Pro",
          "fontWeight": "600",
          "lineHeight": "28px",
          "fontSize": "20px",
          "letterSpacing": "0.2px",
          "paragraphSpacing": "0px",
          "textCase": "none",
          "textDecoration": "none"
        }
      },
      "h5": {
        "regular": {
          "fontFamily": "Source Sans Pro",
          "fontWeight": "400",
          "lineHeight": "24px",
          "fontSize": "18px",
          "letterSpacing": "0.2px",
          "paragraphSpacing": "0px",
          "textCase": "none",
          "textDecoration": "none"
        },
        "semiBold": {
          "fontFamily": "Source Sans Pro",
          "fontWeight": "600",
          "lineHeight": "24px",
          "fontSize": "18px",
          "letterSpacing": "0.2px",
          "paragraphSpacing": "0px",
          "textCase": "none",
          "textDecoration": "none"
        }
      },
      "bodyL": {
        "semiBold": {
          "fontFamily": "Source Sans Pro",
          "fontWeight": "600",
          "lineHeight": "20px",
          "fontSize": "16px",
          "letterSpacing": "0.2px",
          "paragraphSpacing": "0px",
          "textCase": "none",
          "textDecoration": "none"
        },
        "regular": {
          "fontFamily": "Source Sans Pro",
          "fontWeight": "400",
          "lineHeight": "20px",
          "fontSize": "16px",
          "letterSpacing": "0.2px",
          "paragraphSpacing": "0px",
          "textCase": "none",
          "textDecoration": "none"
        },
        "buttonL": {
          "fontFamily": "Source Sans Pro",
          "fontWeight": "700",
          "lineHeight": "20px",
          "fontSize": "16px",
          "letterSpacing": "0.2px",
          "paragraphSpacing": "0px",
          "textCase": "capitalize",
          "textDecoration": "none"
        },
        "linkButtonL": {
          "fontFamily": "Source Sans Pro",
          "fontWeight": "700",
          "lineHeight": "20px",
          "fontSize": "16px",
          "letterSpacing": "0.2px",
          "paragraphSpacing": "0px",
          "textCase": "capitalize",
          "textDecoration": "underline"
        }
      },
      "bodyM": {
        "semiBold": {
          "fontFamily": "Source Sans Pro",
          "fontWeight": "600",
          "lineHeight": "20px",
          "fontSize": "14px",
          "letterSpacing": "0px",
          "paragraphSpacing": "0px",
          "textCase": "none",
          "textDecoration": "none"
        },
        "regular": {
          "fontFamily": "Source Sans Pro",
          "fontWeight": "400",
          "lineHeight": "20px",
          "fontSize": "14px",
          "letterSpacing": "0px",
          "paragraphSpacing": "0px",
          "textCase": "none",
          "textDecoration": "none"
        },
        "regularItalic": {
          "fontFamily": "Source Sans Pro",
          "fontWeight": "Italic",
          "lineHeight": "20px",
          "fontSize": "14px",
          "letterSpacing": "0px",
          "paragraphSpacing": "0px",
          "textCase": "none",
          "textDecoration": "none"
        },
        "underline": {
          "fontFamily": "Source Sans Pro",
          "fontWeight": "400",
          "lineHeight": "20px",
          "fontSize": "14px",
          "letterSpacing": "0px",
          "paragraphSpacing": "0px",
          "textCase": "none",
          "textDecoration": "underline"
        },
        "button": {
          "fontFamily": "Source Sans Pro",
          "fontWeight": "700",
          "lineHeight": "16px",
          "fontSize": "14px",
          "letterSpacing": "0.2px",
          "paragraphSpacing": "0px",
          "textCase": "capitalize",
          "textDecoration": "none"
        },
        "linkButton": {
          "fontFamily": "Source Sans Pro",
          "fontWeight": "700",
          "lineHeight": "16px",
          "fontSize": "14px",
          "letterSpacing": "0.2px",
          "paragraphSpacing": "0px",
          "textCase": "capitalize",
          "textDecoration": "underline"
        }
      },
      "bodySm": {
        "activeMenu": {
          "fontFamily": "Source Sans Pro",
          "fontWeight": "600",
          "lineHeight": "16px",
          "fontSize": "13px",
          "letterSpacing": "0.2px",
          "paragraphSpacing": "0px",
          "textCase": "none",
          "textDecoration": "none"
        },
        "menuItem": {
          "fontFamily": "Source Sans Pro",
          "fontWeight": "400",
          "lineHeight": "16px",
          "fontSize": "13px",
          "letterSpacing": "0.2px",
          "paragraphSpacing": "0px",
          "textCase": "none",
          "textDecoration": "none"
        },
        "note": {
          "fontFamily": "Source Sans Pro",
          "fontWeight": "Italic",
          "lineHeight": "16px",
          "fontSize": "13px",
          "letterSpacing": "0.2px",
          "paragraphSpacing": "0px",
          "textCase": "none",
          "textDecoration": "none"
        }
      },
      "bodyS": {
        "semiBold": {
          "fontFamily": "Source Sans Pro",
          "fontWeight": "600",
          "lineHeight": "16px",
          "fontSize": "12px",
          "letterSpacing": "0.2px",
          "paragraphSpacing": "0px",
          "textCase": "none",
          "textDecoration": "none"
        },
        "regular": {
          "fontFamily": "Source Sans Pro",
          "fontWeight": "400",
          "lineHeight": "16px",
          "fontSize": "12px",
          "letterSpacing": "0.2px",
          "paragraphSpacing": "0px",
          "textCase": "none",
          "textDecoration": "none"
        },
        "tableButton": {
          "fontFamily": "Source Sans Pro",
          "fontWeight": "600",
          "lineHeight": "16px",
          "fontSize": "12px",
          "letterSpacing": "0.2px",
          "paragraphSpacing": "0px",
          "textCase": "uppercase",
          "textDecoration": "none"
        }
      },
      "special": {
        "tip": {
          "fontFamily": "Source Sans Pro",
          "fontWeight": "400",
          "lineHeight": "16px",
          "fontSize": "10px",
          "letterSpacing": "0.2px",
          "paragraphSpacing": "0px",
          "textCase": "none",
          "textDecoration": "none"
        }
      }
    }
  }
}
