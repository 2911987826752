import React from 'react'

import { DatePicker, FF } from 'ui/components'

import { useSARFormContext } from '../../../../../../../../context'
import { useSubjectInformationItemContext } from '../../../SubjectInformationItemContext'

type BirthDateProps = {
  index: number
}

export const BirthDate = ({ index }: BirthDateProps) => {
  const { formApi } = useSARFormContext()
  const { isAllInfoUnavailable } = useSubjectInformationItemContext()

  return (
    <formApi.Field
      name={`subjects_info[${index}].birthdate` as 'subjects_info[0].birthdate'}
      children={(field) => (
        <FF
          field={field}
          label="19. Date of birth"
          input={(p) => <DatePicker {...p} disabled={isAllInfoUnavailable} />}
        />
      )}
    />
  )
}
