import React, { FC, memo } from 'react'

import { OtherActivityInfo } from 'commons/types/DTO/bank/reportWebPage'
import { formatCurrency } from 'commons/utils'

import * as SC from './styles'

export const OtherBankingActivityTable: FC<{ otherActivityInfo: OtherActivityInfo }> = memo(({ otherActivityInfo }) => (
  <SC.WrappedLayer rounded shadowed>
    <SC.Title>Other Banking Activity</SC.Title>
    <SC.Columns>
      <SC.Labels>
        <SC.Label>Current Month</SC.Label>
      </SC.Labels>
      <SC.Table>
        <thead>
          <tr>
            <SC.Th rowSpan={2}>Total</SC.Th>
            <SC.Th rowSpan={2}>Cash Withdrawals</SC.Th>
            <SC.Th rowSpan={2}>Check Debits</SC.Th>
            <SC.Th rowSpan={2}>ACH Debits</SC.Th>
            <SC.Th colSpan={2} $bottomBorder>
              Debit Cards
            </SC.Th>
            <SC.Th colSpan={2} $bottomBorder>
              Transfer
            </SC.Th>
            <SC.Th colSpan={2} $bottomBorder>
              Wires
            </SC.Th>
            <SC.Th colSpan={2} $bottomBorder>
              Sweeps
            </SC.Th>
            <SC.Th rowSpan={2}>Bank Fees</SC.Th>
            <SC.Th rowSpan={2}>Money Orders Purchased</SC.Th>
            <SC.Th rowSpan={2}>Cashiers Checks Purchased</SC.Th>
            <SC.Th colSpan={2} $bottomBorder>
              CB&T Credit Card
            </SC.Th>
          </tr>
          <tr>
            <SC.Th>Credits</SC.Th>
            <SC.Th>Debits</SC.Th>
            <SC.Th>Credits</SC.Th>
            <SC.Th>Debits</SC.Th>
            <SC.Th>Credits</SC.Th>
            <SC.Th>Debits</SC.Th>
            <SC.Th>Incoming</SC.Th>
            <SC.Th>Outgoing</SC.Th>
            <SC.Th>Charges</SC.Th>
            <SC.Th>Payments</SC.Th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <SC.Td>{formatCurrency(otherActivityInfo.total)}</SC.Td>
            <SC.Td>{formatCurrency(otherActivityInfo.cashWithdrawals)}</SC.Td>
            <SC.Td>{formatCurrency(otherActivityInfo.checksDebits)}</SC.Td>
            <SC.Td>{formatCurrency(otherActivityInfo.aCHDebits)}</SC.Td>
            <SC.Td>{formatCurrency(otherActivityInfo.debitCardsCredits)}</SC.Td>
            <SC.Td>{formatCurrency(otherActivityInfo.debitCardsDebits)}</SC.Td>
            <SC.Td>{formatCurrency(otherActivityInfo.transferCredits)}</SC.Td>
            <SC.Td>{formatCurrency(otherActivityInfo.transferDebits)}</SC.Td>
            <SC.Td>{formatCurrency(otherActivityInfo.wiresIncoming)}</SC.Td>
            <SC.Td>{formatCurrency(otherActivityInfo.wiresOutgoing)}</SC.Td>
            <SC.Td>{formatCurrency(otherActivityInfo.sweepsIncoming)}</SC.Td>
            <SC.Td>{formatCurrency(otherActivityInfo.sweepsOutgoing)}</SC.Td>
            <SC.Td>{formatCurrency(otherActivityInfo.bankFees)}</SC.Td>
            <SC.Td>{formatCurrency(otherActivityInfo.moneyOrdersPurchased)}</SC.Td>
            <SC.Td>{formatCurrency(otherActivityInfo.cashiersChecksPurchased)}</SC.Td>
            <SC.Td>{formatCurrency(otherActivityInfo.cBTCreditCardCharges)}</SC.Td>
            <SC.Td>{formatCurrency(otherActivityInfo.cBTCreditCardPayments)}</SC.Td>
          </tr>
        </tbody>
      </SC.Table>
    </SC.Columns>
  </SC.WrappedLayer>
))
