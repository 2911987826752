import { useCallback, useState } from 'react'

import { useDeleteCompany as useHook } from 'commons/hooks/bank/company'
import { handlerServerError } from 'errors'
import { Toast } from 'ui/components/Toast'
import { useConfirmation } from 'ui/hooks'

export const useCompanyDelete = (onSuccess: () => void) => {
  const [companyForDelete, setCompanyForDelete] = useState<{ id: number; name: string } | null>(null)
  const { routine: deleteCompany } = useHook({
    onSuccess: async () => {
      onSuccess()
      Toast.successSmall(`Company "${companyForDelete?.name}" was successfully deleted.`)
    },
    onError: (error) => handlerServerError(error)
  })

  const deleteChosenCompany = useCallback(() => {
    if (companyForDelete) {
      deleteCompany(companyForDelete?.id)
    }
  }, [deleteCompany, companyForDelete])

  const { Confirmation: DeleteConfirmation, open: openDeleteConfirmation } = useConfirmation({
    message: `Are you sure want to delete "${companyForDelete?.name || 'this'}" company?`,
    onConfirm: deleteChosenCompany,
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  const onDeleteCompany = useCallback(
    (company: { id: number; name: string }) => {
      setCompanyForDelete(company)
      openDeleteConfirmation()
    },
    [openDeleteConfirmation, setCompanyForDelete]
  )

  return {
    DeleteConfirmation,
    onDeleteCompany
  }
}
