import { UseFormDataReturnType } from 'brief-form'
import { InternalTransfersExportList } from 'commons/types/DTO/bank'
import { TableState } from 'query-table'

import { InternalTransferExportsTableFilterValue } from '../../types'

export type InternalTransferExportsTableProps = {
  items: InternalTransfersExportList[]
  tableState: TableState
  filterForm: UseFormDataReturnType<InternalTransferExportsTableFilterValue>
  setLoading: (isLoading: boolean) => void
  loading: boolean
  totalCount: number
  statuses: {
    [key: string]: string
  }
}
