import { interpolatePath } from '@tanstack/react-router'
import {
  ADVERSE_MEDIA_PATH,
  BANK_ACCOUNTS,
  BANK_ANNUAL_REVIEW_DETAIL,
  BANK_INVOICES_PATH,
  BANK_USERS_PATH,
  BSA_CTR_PATH,
  BSA_SARS_PATH,
  BankApplicationPaths,
  COMPANIES_PATH,
  CONTACTS_PATH,
  DOCUMENTS_PATH,
  INTERNAL_TRANSFERS_PATH,
  LICENSES_PATH,
  MR_PATH,
  QR_PATH,
  SALESDATA_PATH
} from 'commons/constants/routes'

import { HistoryEntityType } from '../enums'

export const getLinkByEntityType = (type: HistoryEntityType, entityId, additionalId?: string): string => {
  switch (type) {
    case HistoryEntityType.AFFILIATED_COMPANY:
      return `${COMPANIES_PATH}/${entityId}`
    case HistoryEntityType.INTERNAL_TRANSFER:
      return `${INTERNAL_TRANSFERS_PATH}/${entityId}`
    case HistoryEntityType.COMPANY:
      return `${COMPANIES_PATH}/${entityId}`
    case HistoryEntityType.DOCUMENT:
      return `${DOCUMENTS_PATH}/${entityId}`
    case HistoryEntityType.DOCUMENT_PERIOD:
      return `${DOCUMENTS_PATH}/${additionalId}/periods/${entityId}`
    case HistoryEntityType.DOCUMENT_FILE:
      return ''
    case HistoryEntityType.LICENSE:
      return `${LICENSES_PATH}/${entityId}`
    case HistoryEntityType.BSA_CTR:
      return `${BSA_CTR_PATH}/${entityId}`
    case HistoryEntityType.BSA_BATCH:
      return `/bsa/batch-details/${entityId}`
    case HistoryEntityType.BSA_CTR_BATCH:
      return `/bsa/batch-details/${entityId}`
    case HistoryEntityType.CONTACT_METADATA:
      return `${CONTACTS_PATH}/${entityId}`
    case HistoryEntityType.ANNUAL_REVIEW:
      return `${BANK_ANNUAL_REVIEW_DETAIL}/${additionalId}/${entityId}`
    case HistoryEntityType.BSA_SAR:
      return `${BSA_SARS_PATH}/${entityId}`
    case HistoryEntityType.BANK_ACCOUNT:
      return `${BANK_ACCOUNTS}/${entityId}`
    case HistoryEntityType.BANK_ACCOUNT_LICENSES:
      return `${LICENSES_PATH}/${entityId}`
    case HistoryEntityType.CONTACT:
      return `${CONTACTS_PATH}/${entityId}`
    case HistoryEntityType.REPORT_MONTHLY:
      return `${MR_PATH}/${entityId}`
    case HistoryEntityType.REPORT_QUARTERLY:
      return `${QR_PATH}/${entityId}`
    case HistoryEntityType.ADVERSE_MEDIA_CASE:
      return `${ADVERSE_MEDIA_PATH}/${entityId}`
    case HistoryEntityType.HISTORY_ENTITY_INVOICE_SUBMITTAL:
      return `${BANK_INVOICES_PATH}/${entityId}`
    case HistoryEntityType.APPLICATION:
      // INFO: https://github.com/TanStack/router/blob/main/packages/react-router/src/path.ts
      return interpolatePath({ path: BankApplicationPaths.DETAILS, params: { $applicationId: entityId } })
    case HistoryEntityType.ADVERSE_MEDIA_PUBLICATION:
      return ''
    case HistoryEntityType.SALES_DATA:
      return `${SALESDATA_PATH}/${entityId}`
    case HistoryEntityType.BANK_USER:
      return `${BANK_USERS_PATH}/${entityId}`
    case HistoryEntityType.CLIENT_USER:
      return `${BANK_USERS_PATH}/${entityId}`
    default:
      return ''
  }
}
