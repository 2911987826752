import styled from 'styled-components'
import { CSS } from 'ui/helpers'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 32px 0px;
  margin-left: 32px;
  margin-bottom: 32px;
`

export const LicensesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 4px 0px;
  margin-top: 8px;
`

export const Title = styled.span<{ $isSuccess: boolean }>`
  ${(p) => CSS.populateFont(p.theme.uiKit.typography.bodySm.activeMenu)};
  color: ${(p) =>
    p.$isSuccess ? p.theme.uiKit.colors.global.general.secondary : p.theme.uiKit.colors.theme.negative.main};
`

export const LicenseLabel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px 4px;
  ${(p) => CSS.populateFont(p.theme.uiKit.typography.bodyM.semiBold)};
  color: ${(p) => p.theme.uiKit.colors.global.general.secondary};
`

export const BoldText = styled.div`
  font-weight: ${(p) => p.theme.uiKit.fontWeights['600']};
`

export const LicenseMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px 0px;
`

export const LicenseMessage = styled.div`
  font-size: ${(p) => p.theme.uiKit.fontSize.bodySm};
  font-weight: ${(p) => p.theme.uiKit.fontWeights.italic};
  color: ${(p) => p.theme.uiKit.colors.theme.negative.main};
  letter-spacing: ${(p) => p.theme.uiKit.letterSpacing.s};
  font-style: italic;
`

export const LicenseName = styled.div`
  display: flex;
  flex-direction: row;
`
