import React from 'react'

import { CurrencyInput, FF } from 'ui/components'
import { Col, FormRow } from 'ui/themes/globalStyles'

import { useSARFormContext } from '../../../../context'

import { s } from './styles'

export const EditTransactionsInformation = () => {
  const { formApi } = useSARFormContext()

  return (
    <s.WrappedPanel rounded shadowed collapsible={false} title="Edit Transactions Information">
      <s.Inner>
        <FormRow>
          <Col>
            <formApi.Field
              name="transactions_info.total.previous_cumulative"
              children={(field) => (
                <FF
                  field={field}
                  label="Previous SAR Cumulative Amount"
                  input={(p) => <CurrencyInput {...p} data-qa="Previous SAR Cumulative Amount" />}
                />
              )}
            />
          </Col>
        </FormRow>
      </s.Inner>
    </s.WrappedPanel>
  )
}
