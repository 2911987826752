export const licensePosTypes = [
  { value: 'greenbits', label: 'Greenbits' },
  { value: 'biotrackthc', label: 'Biotrackthc' },
  { value: 'adilas', label: 'Adilas' },
  { value: 'flowhub', label: 'Flowhub' },
  { value: 'mj_freeway', label: 'Mjfreeway' },
  { value: 'leaf_logix', label: 'Leaf Logix' },
  { value: 'thsuite', label: 'Thsuite' },
  { value: 'other', label: 'Other' },
  { value: 'manual', label: 'Manual' }
]

export const getLicensePosTypesList = () => licensePosTypes
