import { AnyRoute, createRoute, lazyRouteComponent } from '@tanstack/react-router'

export const ListRoute = <T extends AnyRoute>(indexRoute: T) =>
  createRoute({
    getParentRoute: () => indexRoute,
    path: `/`,
    component: lazyRouteComponent(() => import('./List')),
    validateSearch: (search: Record<string, string>) => {
      return {
        batchId: (search.batchId as string) || '',
        batchName: (search.batchName as string) || ''
      }
    }
  })
