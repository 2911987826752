const tablet1x = require('./assets/auth-tablet@1x.jpg')
const tablet2x = require('./assets/auth-tablet@2x.jpg')
const tablet3x = require('./assets/auth-tablet@3x.jpg')

export const desktop1x = require('./assets/auth-desktop@1x.jpg')
const desktop2x = require('./assets/auth-desktop@2x.jpg')
const desktop3x = require('./assets/auth-desktop@3x.jpg')

export const tabletSet = `${tablet1x}, ${tablet2x} 2x, ${tablet3x} 3x`
export const desktopSet = `${desktop1x}, ${desktop2x} 2x, ${desktop3x} 3x`
