export const licenseSubTypes = [
  { value: 'processor', label: 'Processor' },
  { value: 'dispensary', label: 'Dispensary' },
  { value: 'grow', label: 'Grow' }
]

export const licenseSubTypesExtended = [
  { value: 'processor', label: 'Processor' },
  { value: 'dispensary', label: 'Dispensary' },
  { value: 'delivery', label: 'Delivery' },
  { value: 'grow', label: 'Grow' },
  { value: 'transportation_storage', label: 'Transportation/Storage' },
  { value: 'research_and_development', label: 'Research and Development' },
  { value: 'testing_facility', label: 'Testing Facility' },
  { value: 'waste_disposal', label: 'Waste Disposal' },
  { value: 'operator', label: 'Operator' }
]

export const getLicenseSubTypesOnboardList = () => licenseSubTypesExtended
