import SC, { keyframes } from 'styled-components'

import { SpinnerProps } from './SpinnerProps'
import { SpinnerSize } from './SpinnerSize'

const borderSizes = {
  [SpinnerSize.XS]: 4,
  [SpinnerSize.S]: 4,
  [SpinnerSize.M]: 8,
  [SpinnerSize.L]: 14
}

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

export const Box = SC.div<SpinnerProps>`
  position: ${(p) => (p.centered ? 'absolute' : 'relative')};
  left: ${(p) => (p.centered ? '50%' : '0')};
  top: ${(p) => (p.centered ? '50%' : '0')};
  margin-left: ${(p) => (p.centered ? `-${+(p.size || SpinnerSize.M) / 2}px` : '')};
  margin-top: ${(p) => (p.centered ? `-${+(p.size || SpinnerSize.M) / 2}px` : '')};
  width: ${(p) => p.size}px;
  height: ${(p) => p.size}px;
  border-color: ${(p) => p.theme.uiKit.colors.global.grey.grey700 || '#e2e2e2'} transparent ${(p) =>
  p.theme.uiKit.colors.global.grey.grey700 || '#e2e2e2'} transparent;
  border-style: solid;
  border-radius: 50%;
  border-width: ${(p) => borderSizes[p.size || SpinnerSize.M]}px;
  animation: ${rotate} ${(p) => p.durationInSeconds || 2}s infinite linear;
  display: inline-box;
`
