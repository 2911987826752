import React, { FC, memo } from 'react'

import { Info, InfoSet, InfoSetDirection } from 'ui/components/Info'
import { Col, Row } from 'ui/themes/globalStyles'

import { PersonalAddressProps } from './PersonalAddressProps'

export const PersonalAddress: FC<PersonalAddressProps> = memo(({ address }) => {
  const { city, country, postalCode, state, street } = address

  return (
    <InfoSet direction={InfoSetDirection.ROW} legend="Personal Address">
      <Row>
        <Col>
          <Info label="Street">{street}</Info>
        </Col>
        <Col>
          <Info label="City">{city}</Info>
        </Col>
      </Row>
      <Row>
        <Col>
          <Info label="State">{state}</Info>
        </Col>
        <Col>
          <Info label="Country">{country}</Info>
        </Col>
      </Row>
      <Row>
        <Info label="Postal Code">{postalCode}</Info>
      </Row>
    </InfoSet>
  )
})
