import React, { memo } from 'react'

import { ButtonGroup } from 'ui/components/ButtonGroup'
import { Button } from 'ui/components/Buttons'
import { IconName } from 'ui/components/Icon'

import { Box, DeleteButton } from './styles'

interface Props {
  onSubmit?: () => void
  onDelete?: () => void
  onCancel: () => void
  isSubmitting: boolean
}

export const Footer = memo((props: Props) => {
  const { onDelete, onCancel, onSubmit, isSubmitting } = props

  return (
    <Box>
      {onDelete ? (
        <DeleteButton icon={IconName.DELETE} face="negative" onClick={onDelete}>
          Delete Page
        </DeleteButton>
      ) : (
        <span />
      )}
      <ButtonGroup margin="small">
        <Button face="neutral" onClick={onCancel}>
          Cancel
        </Button>
        <Button data-qa="Save" loading={isSubmitting} onClick={onSubmit} face="positive">
          Save
        </Button>
      </ButtonGroup>
    </Box>
  )
})
