import { UseFormDataReturnType } from 'brief-form'

import { ConnectBankAccountToLicensesFormValue, RelationshipBankAccountFormDataRecord } from '../../../types'
import { isFormValid } from './isFormValid'

export const validateFormByKey = ({
  formValidateData,
  formValue,
  withFormUpdate
}: {
  formValidateData: {
    formData: UseFormDataReturnType<ConnectBankAccountToLicensesFormValue, unknown>
  }
  formValue: RelationshipBankAccountFormDataRecord
  withFormUpdate: boolean
}): boolean => {
  if (!formValue) {
    return false
  }
  if (!withFormUpdate) {
    return isFormValid({ formData: formValue })
  }
  if (!formValidateData) {
    return false
  }
  const { formData } = formValidateData
  if (!formData) {
    return false
  }
  return formData.validate({ updateFields: withFormUpdate }).valid
}
