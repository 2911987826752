/* eslint-disable camelcase */
import { ApplicationRelationship } from './applicationRelationship'

export type ApplicationRFIInfo = {
  relationships: ApplicationRelationship[]
  document_request_acknowledged_by?: {
    user_name: string
    user_email: string
    acknowledged_at?: string
  }
}
