import React, { FC } from 'react'

import { Dialog, DialogFace, Hr, IconName, PanelFace } from 'ui/components'
import { IconButton } from 'ui/components/Buttons'
import { LeaveConfirmation } from 'ui/components/LeaveConfirmation'

import { ConnectBankAccountToLicensesForm } from '../ConnectBankAccountToLicensesForm/ConnectBankAccountToLicensesForm'
import { AddBankAccountsDialogFooter } from './AddBankAccountsDialogFooter/AddBankAccountsDialogFooter'
import AddButton from './AddButton'
import NoLicensesMessage from './NoLicensesMessage'
import { RelationshipLicenses } from './RelationshipLicenses'
import { AddBankAccountsDialogConfig } from './enums'
import { useAddBankAccountsDialog } from './useAddBankAccountsDialog'

import * as SC from './styles'

type AddBankAccountsDialogState = {
  applicationId: number
  showDialog: boolean
  onCancel: () => void
  onSubmit: () => void
}

type AddBankAccountsDialogProps = {
  state: AddBankAccountsDialogState
}

export const AddBankAccountsDialog: FC<AddBankAccountsDialogProps> = ({
  state: { applicationId, showDialog, onCancel, onSubmit }
}) => {
  const state = useAddBankAccountsDialog({ applicationId, onCancel, onSubmit })
  const { ui, handlers, bankAccountFormsData } = state
  const {
    ui: { isRelationshipsLoaded, bankAccountFormList, licenses, FinalizeConfirmation, CancelConfirmation }
  } = state
  const { validateFunctionRefs, formDataList, assignedLicensesSelectData } = bankAccountFormsData
  const newBankAccountInitialValue = formDataList[AddBankAccountsDialogConfig.NEW_FORM_NAME]

  const newFormState = {
    licenses,
    validateFunctionRefs,
    formName: AddBankAccountsDialogConfig.NEW_FORM_NAME,
    assignedLicensesSelectData,
    initialValue: newBankAccountInitialValue,
    isDisabled: ui.isFormsDisabled,
    onChange: handlers.formOnChange
  }

  const addButtonState = {
    isDisabledAddAccountButton: ui.isDisabledAddAccountButton,
    isDisabledAddButtonHint: ui.isDisabledAddButtonHint,
    addTooltipContent: ui.addTooltipContent,
    showErrors: ui.showFormErrors,
    addBankAccount: handlers.addBankAccount
  }

  const isNeedRenderDialog = showDialog && isRelationshipsLoaded
  return (
    <>
      <FinalizeConfirmation />
      <CancelConfirmation />
      <LeaveConfirmation preventLeaving={false}>
        {isNeedRenderDialog && (
          <Dialog
            id="add-accounts-licenses-dialog"
            title={ui.titleDialogRelationshipsCursor}
            subTitle={ui.subTitleRelationshipName}
            face={DialogFace.PRIMARY}
            onClose={handlers.closeDialog}
            stickyFooter={<AddBankAccountsDialogFooter state={state.footerState} />}
            height={ui.noLicenses ? null : '80vh'}
          >
            <SC.Content>
              {/* case: relationship hasn't licenses */}
              <NoLicensesMessage show={ui.noLicenses} />

              {/* case: relationship has licenses */}
              {!ui.noLicenses && (
                <>
                  {/* License badges */}

                  <RelationshipLicenses licenses={licenses} showErrors={ui.showFormErrors} />

                  <Hr />
                  <SC.FieldSetBox legend="Bank Accounts" />

                  {/* Exists Bank Accounts */}

                  {bankAccountFormList.map((bankAccountFormItem, index) => {
                    const formName = String(index)

                    const formState = {
                      licenses,
                      validateFunctionRefs,
                      assignedLicensesSelectData,
                      formName,
                      isDisabled: ui.isFormsDisabled,
                      initialValue: bankAccountFormItem,
                      onChange: handlers.formOnChange
                    }

                    return (
                      <SC.Panel
                        key={formName}
                        face={PanelFace.SMALL_PRIMARY}
                        title={`Bank Account #${index + 1}`}
                        expanded={ui.formExpandedList[formName]}
                        actions={
                          bankAccountFormItem.value.enableRemove && (
                            <IconButton
                              children="Remove"
                              face="negative"
                              icon={IconName.DELETE}
                              onClick={() => handlers.removeBankAccount({ index })}
                            />
                          )
                        }
                      >
                        <SC.PanelSection>
                          <ConnectBankAccountToLicensesForm key={index} formState={formState} />
                        </SC.PanelSection>
                      </SC.Panel>
                    )
                  })}

                  {/* New Bank Account */}

                  {ui.isShowAddNewFrom && (
                    <SC.Panel
                      face={PanelFace.SMALL_PRIMARY}
                      title={`Bank Account #${bankAccountFormList.length + 1}`}
                      expanded={true}
                      data-qa="new-form-panel"
                    >
                      <SC.AddNewSection>
                        <ConnectBankAccountToLicensesForm formState={newFormState} />
                      </SC.AddNewSection>
                    </SC.Panel>
                  )}

                  <AddButton state={addButtonState} />
                </>
              )}
            </SC.Content>
          </Dialog>
        )}
      </LeaveConfirmation>
    </>
  )
}
