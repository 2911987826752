import { MerchantQuestionnaire } from 'commons/types/DTO/commons'

import { MerchantQuestionnaireFormValue } from '../../../../types'

export const mapMerchantQuestionnaireForm = ({
  legacyCash,
  merchantProcessing,
  merchantProcessingDesc,
  primaryMethodToFund,
  cryptocurrencyTransactions,
  realTimePaymentServices
}: MerchantQuestionnaireFormValue): MerchantQuestionnaire => ({
  legacyCash,
  merchantProcessing,
  merchantProcessingDesc,
  primaryMethodToFund,
  cryptocurrencyTransactions,
  realTimePaymentServices
})
