import * as React from 'react'

import SC from 'styled-components'

//region Styled

const CheckMarkItemContainer = SC.label`
  display: flex;
  align-items: center;
  width: max-content;
  color: "#000";
  font-size: 14px;
  user-select: none;
  margin: auto;
`

const Checkmark = SC.span<{ active: boolean; rounded: boolean }>`
  position: relative;
  display: inline-block;
  height: 18.2px;
  width: 18.2px;
  border-radius: ${(props) => (props.rounded ? '50%' : '4px')};
  border: ${(props) => (props.active ? 'none' : '1px solid #ccc')};
  background-color: ${(props) => (props.active ? props.theme.uiKit.colors.theme.positive.main : '#eee')};
  
  &:after {
    content: "";
    position: absolute;
    display: ${(props) => (props.active ? 'block' : 'none')};
    left: 5px;
    top: 1.5px;
    width: 7px;
    height: 11px;
    border: solid white;
    border-width: 0 2.8px 2.8px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    box-sizing: border-box;
  }
`

const CheckMarkLabel = SC.div`
  margin-left: 10px;
`

//endregion

export interface CheckMarkProps {
  value: boolean
  rounded: boolean
  label?: string | React.ReactNode
}

export const CheckMark = React.memo((props: CheckMarkProps) => {
  const { value: active, rounded, label, ...rest } = props

  return (
    <CheckMarkItemContainer {...rest}>
      <Checkmark active={active} rounded={rounded} />
      {label && <CheckMarkLabel>{label}</CheckMarkLabel>}
    </CheckMarkItemContainer>
  )
})
;(CheckMark as any).getError = () => undefined
