import styled, { css } from 'styled-components'
import { Link } from 'ui/components/Link'
import { CSS } from 'ui/helpers/cssHelpers'

const LEFT_PADDING = '24px'
const ITEM_HEIGHT = '44px'

export const MenuItemInner = styled.span<{ $minimized: boolean }>`
  transition: ${CSS.populateTransition(['opacity'])};
  opacity: ${(p) => (p.$minimized ? '0' : '1')};
`

export const MenuItemDisabled = styled.div`
  height: ${ITEM_HEIGHT};
  display: flex;
  align-items: center;
  background-color: transparent;
  color: ${(p) => p.theme.uiKit.colors.global.grey.grey700};
  overflow-x: hidden;
  white-space: nowrap;
  cursor: default;
  padding-left: ${LEFT_PADDING};
  ${(p) => CSS.populateFont(p.theme.uiKit.typography.bodyL.regular)}
`

export const MenuItem = styled(Link)<{ $active: boolean }>`
  height: ${ITEM_HEIGHT};
  display: flex;
  align-items: center;

  transition: ${CSS.populateTransition(['background-color', 'border-right-color'], '0.3s')};

  background-color: transparent;
  border-right: 3px solid transparent;
  color: ${(p) => p.theme.uiKit.colors.global.general.secondary};
  overflow-x: hidden;
  white-space: nowrap;

  svg {
    ${(p) =>
      p.$active
        ? css`
            color: ${(p) => p.theme.uiKit.colors.theme.brand.primary};
          `
        : css`
            color: ${(p) => p.theme.uiKit.colors.global.grey.grey700};
          `}
  }

  a {
    display: flex;
    padding-left: ${LEFT_PADDING};
    height: 100%;
    width: 100%;
    ${(p) => CSS.populateFont(p.theme.uiKit.typography.bodyL.regular)}
  }

  &:hover,
  &:visited:hover {
    background-color: rgba(0, 0, 0, 0.02);
    border-right-color: ${(p) => p.theme.uiKit.colors.global.grey.grey700};
  }

  &,
  &:hover,
  &:visited:hover {
    ${(p) =>
      p.$active &&
      `
    background-color: ${p.theme.uiKit.colors.theme.positive.transparent.opacity12};
    border-right-color: ${p.theme.uiKit.colors.theme.brand.primary};
  `}
  }

  a {
    &,
    &:hover,
    &:focus,
    &:visited,
    &:visited:hover {
      color: ${(p) => p.theme.uiKit.colors.global.general.secondary};
      text-decoration: none;
    }
  }
`

export const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
