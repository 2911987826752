import React from 'react'

import { DOCUMENTS_PATH } from 'commons/constants/routes'
import { Uploads } from 'commons/types/DTO/bank/alerts/uploads'
import { TextButton } from 'ui/components/Buttons'
import { Link } from 'ui/components/Link'
import { TableColumn } from 'ui/components/Table'

export const columns = (
  openRelatedCompanyList: (subTitle: string, companyList: Array<{ id: number; name: string }>) => void
): TableColumn<Uploads>[] => [
  {
    title: 'Name',
    align: 'left',
    render: (item) => (
      <>
        {item.documentID && <Link to={`${DOCUMENTS_PATH}/${item.documentID}`}>{item.name}</Link>}
        {item.salesDataSourceID && (
          <Link to="/salesdata/$sourceId" params={{ sourceId: item.salesDataSourceID }}>
            {item.name}
          </Link>
        )}
      </>
    )
  },
  {
    title: 'Type',
    align: 'left',
    key: 'type'
  },
  {
    title: 'Relationship',
    align: 'left',
    render: (item) => {
      const relatedCompanies = item.relatedCompanies
      return relatedCompanies?.length > 1 ? (
        <TextButton onClick={() => openRelatedCompanyList('', item.relatedCompanies)}>OPEN LIST</TextButton>
      ) : (
        <Link to="/relationships/$companyId" params={{ companyId: relatedCompanies[0].id }}>
          {relatedCompanies[0].name}
        </Link>
      )
    }
  },
  {
    title: 'Uploaded Date',
    align: 'center',
    sortable: true,
    key: 'uploadedDate'
  }
]
