import React, { FC, memo } from 'react'

import { formatCurrency } from 'commons/utils'
import { CheckMark } from 'ui/components/CheckMark'
import { DateTime } from 'ui/components/DateTime'
import { Info } from 'ui/components/Info'
import { PageSection, PageSectionFace } from 'ui/components/Page'
import { CTRDetailsResponse } from '~bank-bsa-reporting/types'

import { CashInOut } from './components'

import { s } from './styles'

type Props = {
  data: CTRDetailsResponse
}

export const AmountAndTypeOfTransactions: FC<Props> = memo(({ data }) => {
  const {
    transactionInfo: {
      transactionDate,
      sourceAggregated,
      sourceAtm,
      sourceArmored,
      sourceMail,
      sourceNight,
      sourceSharedBranching,
      cashInA,
      cashInB,
      cashInC,
      cashInD,
      cashInE,
      cashInF,
      cashInG,
      cashInH,
      cashInI,
      cashInTypeZ,
      cashInZ,
      cashInTotal,
      cashOutA,
      cashOutB,
      cashOutC,
      cashOutD,
      cashOutE,
      cashOutF,
      cashOutG,
      cashOutH,
      cashOutI,
      cashOutJ,
      cashOutZ,
      cashOutTypeZ,
      cashOutTotal,
      foreignIn,
      foreignOut
    }
  } = data

  return (
    <PageSection face={PageSectionFace.THIRD} title="Step 4. Amount and Type of Transaction(s)" anchor="step4">
      <s.Row>
        <Info label="23. Date of transaction">
          <DateTime date={transactionDate || 0} />
        </Info>
      </s.Row>
      <s.Row>
        <Info label="24. Source">
          <CheckMark label="Aggregated transactions" value={!!sourceAggregated} rounded={false} />
          <CheckMark label="ATM" value={!!sourceAtm} rounded={false} />
          <CheckMark label="Armored car (FI Contract)" value={!!sourceArmored} rounded={false} />
          <CheckMark label="Mail deposit or shipment" value={!!sourceMail} rounded={false} />
          <CheckMark label="Night Deposit" value={!!sourceNight} rounded={false} />
          <CheckMark label="Shared branching" value={!!sourceSharedBranching} rounded={false} />
        </Info>
      </s.Row>
      <s.Row>
        <CashInOut
          title="25. CASH IN: (in U.S. dollar equivalent)"
          rows={[
            { label: 'a. Deposit(s)', value: formatCurrency(cashInA || 0) },
            { label: 'b. Payment(s)', value: formatCurrency(cashInB || 0) },
            {
              label: 'c. Currency received for funds transfer(s) out',
              value: formatCurrency(cashInC || 0)
            },
            {
              label: 'd. Purchase of negotiable instrument(s)',
              value: formatCurrency(cashInD || 0)
            },
            {
              label: 'e. Currency exchange(s)',
              value: formatCurrency(cashInE || 0)
            },
            {
              label: 'f. Currency to prepaid access',
              value: formatCurrency(cashInF || 0)
            },
            {
              label: 'g. Purchases of casinos chips, tokens and other gaming instruments',
              value: formatCurrency(cashInG || 0)
            },
            {
              label: 'h. Currency wager(s) including money plays',
              value: formatCurrency(cashInH || 0)
            },
            {
              label: 'i. Bills inserted into gaming devices',
              value: formatCurrency(cashInI || 0)
            },
            {
              label: '',
              value: ''
            },
            {
              label: `z. Other: ${cashInTypeZ}`,
              value: formatCurrency(cashInZ || 0)
            },
            {
              label: <b>Total cash in</b>,
              value: <b>{formatCurrency(cashInTotal || 0)}</b>
            }
          ]}
        />
        <CashInOut
          title="27. CASH OUT: (in U.S. dollar equivalent)"
          rows={[
            { label: 'a. Withdrawal(s)', value: formatCurrency(cashOutA || 0) },
            {
              label: 'b. Advance(s) on credit (including markers)',
              value: formatCurrency(cashOutB || 0)
            },
            {
              label: 'c. Currency paid from funds transfer(s) in',
              value: formatCurrency(cashOutC || 0)
            },
            {
              label: 'd. Negotiable instrument(s) cashed',
              value: formatCurrency(cashOutD || 0)
            },
            {
              label: 'e. Currency exchange(s)',
              value: formatCurrency(cashOutE || 0)
            },
            {
              label: 'f. Currency to prepaid access',
              value: formatCurrency(cashOutF || 0)
            },
            {
              label: 'g. Redemption(s) of casino chips, tokens, TITO tickets and other gaming instruments',
              value: formatCurrency(cashOutG || 0)
            },
            {
              label: 'h. Payment(s) on wager(s) (including race and OTB or sports pool)',
              value: formatCurrency(cashOutH || 0)
            },
            {
              label: 'i. Travel and complimentary expenses and book gaming incentives',
              value: formatCurrency(cashOutI || 0)
            },
            {
              label: 'j. Payment for tournament, contest or other promotions',
              value: formatCurrency(cashOutJ || 0)
            },
            {
              label: `z. Other: ${cashOutTypeZ}`,
              value: formatCurrency(cashOutZ || 0)
            },
            {
              label: <b>Total cash out</b>,
              value: <b>{formatCurrency(cashOutTotal || 0)}</b>
            }
          ]}
        />
      </s.Row>
      <s.Row>
        <Info label="26. Foreign cash in">
          {foreignIn?.length ? foreignIn.map((t) => <div key={t.cash}>{formatCurrency(t.cash || 0)}</div>) : '---'}
        </Info>
        <Info label="Foreign Country">
          {foreignIn?.length ? foreignIn.map((t) => <div key={t.country}>{t.country}</div>) : '---'}
        </Info>
      </s.Row>
      <s.Row>
        <Info label="28. Foreign cash out">
          {foreignOut?.length ? foreignOut.map((t) => <div key={t.cash}>{formatCurrency(t.cash || 0)}</div>) : '---'}
        </Info>
        <Info label="Foreign Country">
          {foreignOut?.length ? foreignOut.map((t) => <div key={t.country}>{t.country}</div>) : '---'}
        </Info>
      </s.Row>
    </PageSection>
  )
})
