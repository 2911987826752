import { CompanyContactList } from '../../components/OnboardingForm/helpers/getAllContactFromForms'

type GetDataForCopyFromContactSelectParams = {
  companyIndex: number
  contactsList: CompanyContactList
  companyContactsEmailDict: Record<string, ''>
  currentContactEmail: string
}

// eslint-disable-next-line import/no-unused-modules
export const getDataForCopyFromContactSelect = ({
  companyIndex,
  companyContactsEmailDict,
  currentContactEmail,
  contactsList
}: GetDataForCopyFromContactSelectParams): Array<{ label: string; value: string }> => {
  if (companyIndex < 1) {
    return []
  }
  // filter current company contacts
  const allContactsExpectWithSameCompanyIndex = contactsList.filter(
    (contactRecord) =>
      (companyIndex !== contactRecord.companyIndex &&
        // filter rest contacts omitBy item from
        // list of company contact emails
        typeof companyContactsEmailDict[contactRecord.companyContactFormsData.details.formValues.email] ===
          'undefined') ||
      // but leave the contact with email that equal `current email` email of current contact
      currentContactEmail === contactRecord.companyContactFormsData.details.formValues.email
  )

  return allContactsExpectWithSameCompanyIndex.map((contactRecord) => {
    const formData = contactRecord.companyContactFormsData.details.formValues
    return {
      label: [formData.firstName, formData.lastName, formData.email].filter(Boolean).join(' '),
      value: `${contactRecord.companyIndex}-${contactRecord.contactIndex}`
    }
  })
}
