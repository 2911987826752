import { useCallback } from 'react'

import { FormChangedHandler, useFormData } from 'brief-form'

import { useSyncFormData } from '../../hooks'
import {
  AnticipatedMonthlyActivityChangeHanlder,
  AnticipatedMonthlyActivityFormData,
  AnticipatedMonthlyActivityFormValue
} from '../../types/forms'
import { AnticipatedMonthlyActivity } from './AnticipatedMonthlyActivityFormProps'

export const useAnticipatedMonthlyActivityForm = ({
  companyIndex,
  formData,
  onChange,
  anticipatedMonthlyActivityList
}: {
  companyIndex: number
  formData: AnticipatedMonthlyActivityFormData
  onChange: AnticipatedMonthlyActivityChangeHanlder
  anticipatedMonthlyActivityList: AnticipatedMonthlyActivity[]
}) => {
  const onFormChanged: FormChangedHandler<AnticipatedMonthlyActivityFormValue> = useCallback(
    (formValues, formErrors) => {
      anticipatedMonthlyActivityList.forEach((el) => {
        // Reset selects if unchek

        if (!formValues[el.active]) {
          formValues[el.amount] = undefined
          formValues[el.frequency] = undefined
        }
      })

      onChange({ companyIndex, formData: { formValues, formErrors } })
    },
    [anticipatedMonthlyActivityList, onChange, companyIndex]
  )

  const { config, Field, set } = useFormData<AnticipatedMonthlyActivityFormValue>({
    onFormChanged,
    initialValue: formData.formValues,
    initialErrors: formData.formErrors
  })

  useSyncFormData({ set, formData })

  return { Field, config }
}
