import styled from 'styled-components'

export const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(p) => p.theme.uiKit.colors.theme.brand.primary};
  border-radius: 0 70px 0 70px;
  opacity: 1;
  width: 420px;
  overflow: hidden;
  z-index: 1;
  height: 300px;
  align-items: center;
  justify-content: space-around;
  color: white;
  font-size: 21px;
  padding: 0 30px;
  text-align: center;
`
