import React, { FC, memo } from 'react'

import { useParams } from '@tanstack/react-router'
import { TableDataQa } from 'commons/types/enums'
import { Spinner, SpinnerSize } from 'ui/components'
import { ButtonGroup } from 'ui/components/ButtonGroup'
import { HeaderButton, IconButton } from 'ui/components/Buttons'
import { IconName } from 'ui/components/Icon'
import { Page, PageFace } from 'ui/components/Page'
import { useQRDetails } from '~bank-quarterly-report/hooks/qr'

import { HistorySection } from '../../components/HistorySection'
import { UploadedFilesTableWebPage } from '../../components/UploadedFilesTableWebPage'
import { Warnings } from './components'
import { BankAccountData, Footer, GeneralInformation, MonthlyReportsDialog, QuestionnaireForm } from './components'
import { defaultQuestionnaire } from './helpers'

const QRDetails: FC = memo(() => {
  const { id } = useParams({ from: '/qr/$id' })

  const {
    webPageData,
    isFetching,
    uploadedFilesTableState,
    uploadedFilesChangePagination: changePagination,
    openReview,
    ReviewConfirmation,
    openWarnings,
    WarningsConfirmation,
    openDelete,
    DeleteConfirmation,
    onSetQuestionnaire,
    saveNotes,
    monthlyOpen,
    setMonthlyOpen,
    historyPagination,
    invalidate
  } = useQRDetails(+id)

  if (!webPageData) {
    return <Spinner centered size={SpinnerSize.M} />
  }

  const isShowWarning = !!webPageData?.infoWarnings && webPageData?.infoWarnings.length > 0

  return (
    <>
      <Page
        face={PageFace.SECONDARY}
        subTitle="Quarterly Analytics"
        actions={
          <ButtonGroup margin="big">
            <IconButton icon={IconName.DELETE} face="neutral" onClick={openDelete}>
              Delete QA
            </IconButton>
            <HeaderButton overColoredPad={true} size="middle" onClick={() => setMonthlyOpen(true)}>
              Show Monthly Analytics
            </HeaderButton>
            {webPageData?.enableAcknowledgeAllWarnings && (
              <HeaderButton overColoredPad={true} size="middle" onClick={openWarnings}>
                Acknowledge all Warnings
              </HeaderButton>
            )}
          </ButtonGroup>
        }
      >
        {isShowWarning && <Warnings warnings={webPageData?.infoWarnings} title="Common Warning(s)" />}
        <DeleteConfirmation />
        <ReviewConfirmation />
        <WarningsConfirmation />
        <GeneralInformation item={webPageData} onSaveNotes={saveNotes} />
        <BankAccountData item={webPageData} />
        <UploadedFilesTableWebPage
          loading={isFetching}
          uploadedFiles={webPageData?.uploadedFiles}
          uploadedFilesTableState={uploadedFilesTableState}
          uploadedFilesTotalCount={webPageData?.uploadedFilesTotalCount}
          changePagination={changePagination}
          reportQuarterlyId={+id}
          tableKey="qr"
          invalidate={invalidate}
        />
        <HistorySection
          tableDataQa={TableDataQa.BP_QUARTERLY_ANALYTICS_HISTORY}
          loading={isFetching}
          historyItems={webPageData?.history}
          historyPagination={historyPagination}
        />
        <MonthlyReportsDialog
          open={monthlyOpen}
          onClose={() => setMonthlyOpen(false)}
          reportsMonthly={webPageData?.monthlyAnalytics}
          reportPeriod={webPageData?.infoPeriod}
        />
        {webPageData?.enableQuestionnaire && (
          <QuestionnaireForm
            setQuestionnaire={onSetQuestionnaire}
            questionnaire={webPageData?.questionnaire || defaultQuestionnaire}
          />
        )}
      </Page>
      <Footer onReview={webPageData?.enableReview ? openReview : undefined} sticky />
    </>
  )
})

export default QRDetails
