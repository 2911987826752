import { createRoute, redirect } from '@tanstack/react-router'
import { SALESDATA_PATH } from 'commons/constants/routes'

import { SalesDataPageWrapper } from './SalesDataPageWrapper'
import { salesDataBankSourceEditRoute } from './routes/BankSourceEdit'
import { salesDataBankSourceOverrideRoute } from './routes/BankSourceOverride'
import { salesDataSourceDetailsRoute } from './routes/SourceDetails'

export const routeSalesDataTree = (rootRoute: BPRootRoute) => {
  const salesDataIndexRoute = createRoute({
    beforeLoad: async ({ context }) => {
      if (!context.accessibleRoutingFlags.salesdataRootAccess) {
        throw redirect({
          to: '/'
        })
      }
    },
    getParentRoute: () => rootRoute,
    path: SALESDATA_PATH,
    component: SalesDataPageWrapper
  })

  return salesDataIndexRoute.addChildren([
    salesDataBankSourceEditRoute<typeof salesDataIndexRoute>(salesDataIndexRoute),
    salesDataBankSourceOverrideRoute<typeof salesDataIndexRoute>(salesDataIndexRoute),
    salesDataSourceDetailsRoute<typeof salesDataIndexRoute>(salesDataIndexRoute)
  ])
}
