import { FC, ReactNode } from 'react'
import { createPortal } from 'react-dom'

import { usePortal } from '../../hooks/usePortal'

interface Props {
  id: string
  children: ReactNode
  box?: string | HTMLElement
}

/**
 * @example
 * <Portal id="modal">
 *   <p>Thinking with portals</p>
 * </Portal>
 */
export const Portal: FC<Props> = ({ box, id, children }) => {
  const target = usePortal(id, box)
  return createPortal(children, target)
}
