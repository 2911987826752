import * as React from 'react'

import { FieldProps, FormInputProps } from 'brief-form'
import { useGetBankAccount } from 'commons/hooks/bank/bankAccount'
import { Company } from 'commons/types/DTO/bank'
import { FieldSet } from 'ui/components/FieldSet'
import { Validators } from 'ui/components/Form'
import { Hr } from 'ui/components/Hr'
import { IconSize } from 'ui/components/Icon'
import { Info } from 'ui/components/Info'
import { CurrencyCentInput } from 'ui/components/InputV2'
import { Select } from 'ui/components/Select'
import { TextMask } from 'ui/components/TextMask'
import { Col, FormRow, Row } from 'ui/themes/globalStyles'

import { SalesAndDepositFormValue } from './SalesAndDepositFormValue'

interface Props {
  Field: <Input extends React.ComponentType<FormInputProps<any, any>>>(
    props: FieldProps<Input, SalesAndDepositFormValue>
  ) => JSX.Element
  company: Company
  bank_account_id: number
  id: number
  annualReviewId: number
}

export const RelationshipDetail = React.memo((props: Props) => {
  const { Field, company, id, bank_account_id, annualReviewId } = props
  const bankAccount = useGetBankAccount({ id: bank_account_id })

  return (
    <FieldSet legend="Relationship Details">
      <Row>
        <Col>
          {bankAccount.data && (
            <Info label="Bank Account">
              <TextMask isBankAccount text={bankAccount.data.number} iconSize={IconSize.XS} />
            </Info>
          )}
        </Col>
        <Col>
          <Info label="Sales & Deposit Summary Number">SDS-{id}</Info>
        </Col>
      </Row>
      <Row>
        <Col>
          <Info label="Customer Name">{company?.name || '---'}</Info>
        </Col>
        <Col>
          <Info label="Annual Review">AR-{annualReviewId}</Info>
        </Col>
      </Row>
      <FormRow>
        <Col>
          <Field
            required
            name="status"
            input={Select}
            label="Status"
            validator={Validators.required.string}
            inputProps={{
              data: [
                { label: 'Incomplete', value: 'incomplete' },
                { label: 'Complete', value: 'complete' }
              ]
            }}
          />
        </Col>
        <Col>
          <Field
            required
            name="total_financials"
            input={CurrencyCentInput}
            validator={Validators.required.number()}
            label="Total Revenue From Financials"
            inputProps={{}}
          />
        </Col>
      </FormRow>
      <br />
      <Hr />
    </FieldSet>
  )
})
