import { DepositsInfo } from 'commons/types/DTO/bank/reportWebPage'

export class DepositsCreateFormValue {
  bankAccountID = 0
  notes = ''
  reportMonthlyID = 0
  atm = 0
  cashDeposits = 0
  checks = 0
  creditDebit = 0
  internalTransfers = 0
  invoices = 0
  oaAchDebits = 0
  oaBankFees = 0
  oaCashWithdrawals = 0
  oaCashiersChecksPurchased = 0
  oaChecksDebits = 0
  oaCreditCardCharges = 0
  oaCreditCardPayments = 0
  oaDebitCardsCredits = 0
  oaDebitCardsDebits = 0
  oaMoneyOrdersPurchased = 0
  oaSweepsIncoming = 0
  oaSweepsOutgoing = 0
  oaTransferCredits = 0
  oaTransferDebits = 0
  oaWiresIncoming = 0
  oaWiresOutgoing = 0
  other = 0

  fillInfo(depositsInfo: DepositsInfo, bankAccountId: number, reportMonthlyId: number) {
    const fromSales = depositsInfo.depositsFromSales
    const otherActivity = depositsInfo.otherActivity

    this.bankAccountID = bankAccountId
    this.notes = depositsInfo.notes

    this.reportMonthlyID = reportMonthlyId

    this.atm = fromSales.cashATMLoad
    this.cashDeposits = fromSales.cashDeposits
    this.checks = fromSales.checks
    this.creditDebit = fromSales.creditDebitCards
    this.internalTransfers = fromSales.internalTransfers
    this.invoices = fromSales.cashInvoices
    this.other = fromSales.other

    if (otherActivity) {
      this.oaAchDebits = otherActivity.aCHDebits
      this.oaBankFees = otherActivity.bankFees
      this.oaCashWithdrawals = otherActivity.cashWithdrawals
      this.oaCashiersChecksPurchased = otherActivity.cashiersChecksPurchased
      this.oaChecksDebits = otherActivity.checksDebits
      this.oaCreditCardCharges = otherActivity.cBTCreditCardCharges
      this.oaCreditCardPayments = otherActivity.cBTCreditCardPayments
      this.oaDebitCardsCredits = otherActivity.debitCardsCredits
      this.oaDebitCardsDebits = otherActivity.debitCardsDebits
      this.oaMoneyOrdersPurchased = otherActivity.moneyOrdersPurchased
      this.oaSweepsIncoming = otherActivity.sweepsIncoming
      this.oaSweepsOutgoing = otherActivity.sweepsOutgoing
      this.oaTransferCredits = otherActivity.transferCredits
      this.oaTransferDebits = otherActivity.transferDebits
      this.oaWiresIncoming = otherActivity.wiresIncoming
      this.oaWiresOutgoing = otherActivity.wiresOutgoing
    }

    return this
  }
}
