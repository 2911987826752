import SC, { css } from 'styled-components'

import { TextButton } from '../../../Buttons'
import { Table } from '../../../Table'

export const ActionRow = SC.div`${css`
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0 10px 0 100px;
`}`

export const DeleteButton = SC(TextButton)`${css`
  padding: 0;
  height: auto;
  margin-right: 20px;
`}`

export const FilesTable = SC(Table)`${css`
  margin-bottom: 0;

  > table {
    border-bottom: none;
  }
`}`

export const ButtonSPart = SC.span`
  text-transform: lowercase;
`
