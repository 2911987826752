import { Request } from 'commons/utils/request'
import { WriteHook, useMutationManager } from 'state-manager'
import { InvoiceSubmittalDetails } from '~bank-invoices/types/Details'
import { ManageInvoiceSubmittalFormType } from '~bank-invoices/types/Manage'

type InvoiceSubmittalFormRequest = ManageInvoiceSubmittalFormType & { licenseId: number }

const saveInvoice = (item: InvoiceSubmittalFormRequest): Promise<InvoiceSubmittalDetails> => {
  const func = item.id ? Request.put : Request.post
  return func<InvoiceSubmittalDetails>(Request.urls.bank.invoiceSubmittal, item)
}

export const useSaveInvoiceSubmittal: WriteHook<InvoiceSubmittalFormRequest, InvoiceSubmittalDetails> = (opts) =>
  useMutationManager(saveInvoice, opts)
