import { SubmitErrorsPayload } from '../../../../types/actions'
import { OnboardingFormState } from '../../../../types/state'
import { generateNewStateFormValuesFromErrors } from './generateNewStateFormValuesFromErrors'

export const handleSubmitErrors = ({
  currentState,
  submitErrors
}: {
  currentState: OnboardingFormState
  submitErrors: SubmitErrorsPayload
}): OnboardingFormState => ({
  ...currentState,
  ...generateNewStateFormValuesFromErrors({
    currentState,
    submitErrorsKeysValues: Object.entries<string[]>(submitErrors as { [s: string]: string[] })
  })
})
