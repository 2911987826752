import { useCallback, useMemo } from 'react'

import { useFormData } from 'brief-form'

import { ContactDetailsRequest } from '../../../../../../../../../types'
import { FilterProps } from '../Filter.types'

export const useFilter = (props: FilterProps) => {
  const { data, onChange } = props
  const { docFilter } = data.form

  const initialFilter: ContactDetailsRequest['docFilter'] = useMemo(
    () => ({
      alerts: docFilter.alerts ?? null,
      frequency: docFilter.frequency ?? null,
      internal: docFilter.internal ?? null,
      page: docFilter.page ?? null,
      size: docFilter.size ?? null,
      sortBy: docFilter.sortBy ?? null,
      sortDesc: docFilter.sortDesc ?? null
    }),
    []
  )

  const form = useFormData<ContactDetailsRequest['docFilter']>({
    initialValue: initialFilter,
    onFormChanged: onChange
  })
  const { isDirty, config, Field, set } = form

  const reset = useCallback(() => {
    set({ reset: true })
    onChange(initialFilter)
  }, [set, onChange])

  return {
    isDirty,
    config,
    Field,
    reset
  }
}
