import React from 'react'

import { useNavigate, useSearch } from '@tanstack/react-router'
import { HistoryTable } from 'bank-entity-history/src/components/HistoryTable'
import { TableDataQa } from 'commons/types/enums'
import { Layer, PageSection } from 'ui/components'

import { HistoryProps } from './History.types'

import { s } from './styles'

export const History = ({ history, historyTotalCount }: HistoryProps) => {
  const navigate = useNavigate()
  const { size, page } = useSearch({ strict: false })

  return (
    <s.HistorySection>
      <PageSection title="History" anchor="history">
        <Layer rounded shadowed stretch={false}>
          <HistoryTable
            dataQa={TableDataQa.BP_ONBOARDING_DOCUMENTS_HISTORY}
            loading={false}
            items={history}
            pagination={{
              page: page,
              pageSize: size,
              onChange: (page, size) => navigate({ search: { page, size }, resetScroll: false, replace: false }),
              total: historyTotalCount
            }}
          />
        </Layer>
      </PageSection>
    </s.HistorySection>
  )
}
