import React, { FC, memo, useCallback, useState } from 'react'

import { useDetachContactAndCompany } from '../../hooks'
import { ContactRelatedCompanies } from '../../types'
import { ContactMetadataManage } from '../ContactMetadataManage/ContactMetadataManage'
import { ContactCompaniesProps } from './ContactCompaniesProps'
import { ContactCompaniesTable } from './components'

const NO_CHOSEN_META = -1
const NEW_META = 0

export const ContactCompanies: FC<ContactCompaniesProps> = memo((props) => {
  const { contact, data, onCompanyDeleted } = props
  const {
    form: { id },
    relatedCompanies
  } = data
  const [metaId, setMetaId] = useState(NO_CHOSEN_META)
  const { detachContactAndCompany } = useDetachContactAndCompany(onCompanyDeleted)

  const onAddContactMetaData = useCallback(() => {
    setMetaId(NEW_META)
  }, [setMetaId])

  const onEditContactMetaData = useCallback(
    (c: ContactRelatedCompanies) => {
      setMetaId(c.id)
    },
    [setMetaId]
  )

  const onCloseContactMetaData = useCallback(() => {
    setMetaId(NO_CHOSEN_META)
  }, [setMetaId])

  return (
    <>
      {!!data && metaId !== NO_CHOSEN_META && (
        <ContactMetadataManage companyId={metaId} contactId={id} onClose={onCloseContactMetaData} />
      )}
      <ContactCompaniesTable
        contact={contact}
        onAdd={onAddContactMetaData}
        onEdit={onEditContactMetaData}
        onDetach={detachContactAndCompany}
        relatedCompanies={relatedCompanies}
        contactId={id}
      />
    </>
  )
})
