import { useEffect } from 'react'

import { notFound, useNavigate } from '@tanstack/react-router'
import { COMPANIES_PATH } from 'commons/constants/routes'
import { useGetCompany } from 'commons/hooks/bank/company'

import { useCompanyDelete } from '../../../../../hooks'

export const useManageCompanyPage = (id: number) => {
  const navigate = useNavigate()
  const { data: company, isFetching, isFetched } = useGetCompany({ id })

  const { DeleteConfirmation, onDeleteCompany } = useCompanyDelete(() => {
    navigate({ to: COMPANIES_PATH })
  })

  useEffect(() => {
    if (isFetched && !company) {
      throw notFound()
    }
  }, [company, isFetched])

  return {
    company,
    isFetching,
    DeleteConfirmation,
    onDeleteCompany
  }
}
