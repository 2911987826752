export class NotFoundError extends Error {
  constructor() {
    super()

    // because we are extending a built-in class
    Object.setPrototypeOf(this, NotFoundError.prototype)
  }

  getErrorMessage() {
    return 'Page not found'
  }
}
