import React from 'react'

import { IconName } from 'ui/components'
import { IconButton } from 'ui/components/Buttons'

import { emptySAR } from '../../../../../../../../../constants'
import { useSARFormContext } from '../../../../../../context'
import { InstitutionActivityBranchesItem } from './InstitutionActivityBranchesItem'

export const InstitutionActivityBranchesInfo = ({ index }: { index: number }) => {
  const { formApi } = useSARFormContext()
  const noBranchInvolved = formApi.useStore((s) => s.values.institution_activities_info[index].no_branch_involved)

  return (
    <formApi.Field
      mode="array"
      name={
        `institution_activities_info[${index}].institution_activity_branches_info` as 'institution_activities_info[0].institution_activity_branches_info'
      }
    >
      {(field) => {
        if (typeof field.state.value === 'undefined') {
          return null
        }

        return (
          <>
            {field.state.value.map((_, index2) => {
              return <InstitutionActivityBranchesItem key={index2} index={index} index2={index2} field={field} />
            })}
            {!noBranchInvolved && (
              <IconButton
                face="positive"
                icon={IconName.ADD_GREEN}
                onClick={() => {
                  field.pushValue(emptySAR.institution_activities_info[0].institution_activity_branches_info[0])
                }}
              />
            )}
          </>
        )
      }}
    </formApi.Field>
  )
}
