import { ReactNode } from 'react'

import { IconName } from '../../../Icon'
import { MenuIconHoverFace } from './MenuIconHoverFace'
import { MenuItemAlignment } from './MenuItemAlignment'
import { MenuItemBehavior } from './MenuItemBehavior'
import { MenuItemTextFace } from './MenuItemTextFace'
import { MenuItemType } from './MenuItemType'

export type TableActionsItem = {
  key: number
  title?: ReactNode
  behavior?: MenuItemBehavior
  kind?: MenuItemType
  url?: string
  onClick?: () => any
  iconName?: IconName
  iconHoverFace?: MenuIconHoverFace
  textFace?: MenuItemTextFace
  textAlign?: MenuItemAlignment
}

export interface TableActionsProps {
  items: TableActionsItem[]
  menuContainerId?: string
  dataQaAttribute?: string
}
