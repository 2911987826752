import React, { FC } from 'react'

import { InternalTransferList } from 'commons/types/DTO/bank'
import { TableDataQa } from 'commons/types/enums'
import { Table } from 'ui/components/Table'

import { InternalTransferTableProps } from './InternalTransferTableProps'
import { columns } from './columns'
import { Filter } from './components/Filter'
import { useInternalTransferTable } from './hooks'

export const InternalTransferTable: FC<InternalTransferTableProps> = ({
  items,
  tableState,
  filterForm,
  setLoading,
  loading,
  totalCount,
  statuses
}) => {
  const { pagination, filter } = tableState
  const { mappedInternalTransfers, CancelConfirmation, startCancel } = useInternalTransferTable(
    tableState,
    filterForm,
    items,
    setLoading
  )

  return (
    <>
      <CancelConfirmation />
      <Filter form={filterForm} expanded={filter.expanded} onToggle={filter.toggle} statuses={statuses} />
      <Table<InternalTransferList>
        dataQa={TableDataQa.BP_INTERNAL_TRANSFERS_LIST}
        columns={columns({ onCancel: startCancel })}
        dataSource={mappedInternalTransfers}
        loading={loading}
        pagination={{
          total: totalCount,
          page: pagination.page,
          pageSize: pagination.size,
          onChange: (page: number, size: number) => {
            pagination.change(page, size)
          }
        }}
      />
    </>
  )
}
