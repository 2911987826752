import {
  CreateClientUserRequest,
  EditClientUserRequest,
  GetClientUserListRequest,
  GetClientUserListResponse
} from 'commons/types/contracts/api/bank/clientUser'
import { Request } from 'commons/utils/request'

export const getClientUserList = (form?: GetClientUserListRequest): Promise<GetClientUserListResponse> =>
  Request.post(Request.urls.bank.clientUserList, form)

export const createClientUser = (form: CreateClientUserRequest) =>
  Request.post(Request.urls.bank.createClientUser, form)

export const editClientUser = (form: EditClientUserRequest) => Request.post(Request.urls.bank.editClientUser, form)

export const removeClientUser = (id: string) => Request.delete(Request.urls.bank.removeClientUser, { id })

export const sendInviteLinkClientUser = (id: string) => Request.post(Request.urls.bank.sendInviteLinkClientUser, { id })
