import React, { FC, memo, useCallback, useEffect, useState } from 'react'

import { Form, useFormData } from 'brief-form'
import { AdverseMediaSearchCase } from 'commons/types/DTO/bank'
import { Button, IconButton } from 'ui/components/Buttons'
import { DateTime } from 'ui/components/DateTime'
import { Dialog, DialogFace, DialogFooterActions } from 'ui/components/Dialog'
import { Hr } from 'ui/components/Hr'
import { IconName } from 'ui/components/Icon'
import { InfoSetDirection } from 'ui/components/Info'
import { TextareaInput } from 'ui/components/InputV2'
import { LeaveConfirmation } from 'ui/components/LeaveConfirmation'

import { NumBadge } from '../../../components/NumBadge'
import { RiskLevel } from '../../../components/RiskLevel'
import { SearchCaseHistoryDialog } from './SearchCaseHistoryDialog'

import * as SC from './styles'

interface Props {
  item: AdverseMediaSearchCase
  updateNotes: (notes: string) => void
  ShowRelatedEntities: () => void
}

export const GeneralInformation: FC<Props> = memo(({ item, updateNotes, ShowRelatedEntities }) => {
  const { notes, id, search_term, meta } = item
  const { config, isDirty, Field } = useFormData<{ notes: string }>({ initialValue: { notes: notes } })
  const [showHistory, setShowHistory] = useState(false)
  const [isLeaveConfirmationNeeded, setIsLeaveConfirmationNeeded] = useState(false)
  const [isEditNotesDialogOpened, setIsEditNotesDialogOpened] = useState<boolean>(false)

  const originalChangeHandler = config.onChange
  config.onChange = useCallback(
    (v, e) => {
      originalChangeHandler(v, e)
      setIsLeaveConfirmationNeeded(true)
    },
    [originalChangeHandler]
  )

  const onSaveNotes = () => {
    setIsLeaveConfirmationNeeded(false)
    updateNotes(config.value.notes)
    setIsEditNotesDialogOpened(false)
  }

  const onClose = () => {
    config.onChange({ notes: item.notes }, {})
    setIsEditNotesDialogOpened(false)
    setIsLeaveConfirmationNeeded(false)
  }

  const onEditNotesOpen = () => {
    setIsEditNotesDialogOpened(true)
  }

  useEffect(() => {
    config.onChange({ ...item, notes: item.notes }, {})
    setIsLeaveConfirmationNeeded(false)
  }, [item])

  return (
    <div id="adverse-general-information">
      {showHistory && search_term && (
        <SearchCaseHistoryDialog id={id} searchTerm={search_term} onClose={() => setShowHistory(false)} />
      )}
      <Hr />
      <SC.MediaInfoSet direction={InfoSetDirection.COLUMN}>
        <SC.Row>
          <SC.Col>
            <SC.MediaInfo label="Needs Review">
              {meta?.alerted_publications_count ? (
                <NumBadge>{meta.alerted_publications_count <= 50 ? meta.alerted_publications_count : '50+'}</NumBadge>
              ) : (
                '--'
              )}
            </SC.MediaInfo>
          </SC.Col>
        </SC.Row>
        <SC.Row>
          <SC.Col>
            <SC.MediaInfo label="Risk Level">
              {item.risk_level && <RiskLevel riskLevel={item.risk_level} />}
              {item.risk_level && item.risk_level_set_at && (
                <>
                  {' '}
                  (updated on <DateTime date={item.risk_level_set_at} />)
                </>
              )}
            </SC.MediaInfo>
          </SC.Col>
          <SC.Col>
            {item.prev_risk_level && (
              <SC.MediaInfo label="Previous Risk Level">
                <RiskLevel riskLevel={item.prev_risk_level} />
                {item.prev_risk_level_set_at && (
                  <>
                    {' '}
                    (updated on <DateTime date={item.prev_risk_level_set_at} />)
                  </>
                )}
              </SC.MediaInfo>
            )}
          </SC.Col>
        </SC.Row>
        <SC.NotesWrapper>
          <SC.NotesInfo label="Notes">{item.notes || '-'}</SC.NotesInfo>
          <Button face="neutral" onClick={onEditNotesOpen}>
            Edit Notes
          </Button>
        </SC.NotesWrapper>
        <SC.Row>
          <SC.Col>
            <SC.MediaInfo label="Last Scan Date">
              {item.last_screening_date && <DateTime date={item.last_screening_date} withTime />}
            </SC.MediaInfo>
          </SC.Col>
          <SC.Col>
            <SC.MediaInfo label="Last Modified Date">
              {item.risk_related_fields_changed_at && <DateTime date={item.risk_related_fields_changed_at} withTime />}
            </SC.MediaInfo>
          </SC.Col>
        </SC.Row>
        <SC.Row>
          <SC.Col>
            <IconButton face="positive" icon={IconName.GROUP} onClick={ShowRelatedEntities}>
              Show Related Entities
            </IconButton>
          </SC.Col>
        </SC.Row>
        <SC.Row>
          <SC.Col>
            <IconButton face="positive" icon={IconName.HISTORY_GRAY} onClick={() => setShowHistory(true)}>
              Show History
            </IconButton>
          </SC.Col>
        </SC.Row>
      </SC.MediaInfoSet>

      {isEditNotesDialogOpened && (
        <LeaveConfirmation preventLeaving={isDirty && isLeaveConfirmationNeeded}>
          <Dialog
            face={DialogFace.PRIMARY}
            id="edit-notes-dialog"
            onClose={onClose}
            title={item.search_term}
            subTitle="Edit Notes"
            onSubmit={onSaveNotes}
            footerActions={[DialogFooterActions.SAVE, DialogFooterActions.CANCEL]}
          >
            <SC.Content>
              <Form config={config}>
                <Field
                  name="notes"
                  label="Optional Notes"
                  input={TextareaInput}
                  inputProps={{
                    maxLength: 200
                  }}
                />
              </Form>
            </SC.Content>
          </Dialog>
        </LeaveConfirmation>
      )}
    </div>
  )
})
