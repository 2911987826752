import React from 'react'

import { TableDataQa } from 'commons/types/enums'
import { Request } from 'commons/utils/request'
import { Page, PageFace, Panel, PanelFace, Table } from 'ui/components'

import { ExportCSVButton, ReportBreadcrumbs } from '../../components'
import { columns } from './columns'
import { Filter } from './components'
import { useRelationshipsReportEssence } from './useRelationshipsReportEssence'

const RelationshipsReport = () => {
  const {
    isFetching,
    dataSource,
    config,
    filterForm,
    tableState,
    totalCount,
    onPageChange,
    onClear,
    inApplicationProcessOnlyValues
  } = useRelationshipsReportEssence()

  return (
    <Page
      face={PageFace.PRIMARY}
      title={<ReportBreadcrumbs title="Relationships Report" />}
      actions={<ExportCSVButton reportName="Relationships" url={Request.urls.bank.portalReportRelationshipsCSV} />}
    >
      <Panel rounded shadowed collapsible={false} face={PanelFace.SECONDARY}>
        <Filter
          isExpanded={tableState.expanded}
          onToggle={tableState.onFilterToggle}
          onClear={onClear}
          filterForm={filterForm}
          inApplicationProcessOnlyValues={inApplicationProcessOnlyValues}
        />
        <Table
          dataQa={TableDataQa.BP_REPORTS_RELATIONSHIPS_REPORT}
          dataSource={dataSource}
          loading={isFetching}
          columns={columns}
          pagination={{
            page: config.value.page,
            pageSize: config.value.size,
            total: totalCount,
            onChange: onPageChange
          }}
        />
      </Panel>
    </Page>
  )
}

export default RelationshipsReport
