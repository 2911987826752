import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 0px 32px;
  height: 100%;
  width: 100%;

  & > * {
    min-width: 100%;
    width: 100%;
  }
`

export const EmptyTitle = styled.h5`
  padding: 50px;
  color: ${(p) => p.theme.uiKit.colors.global.grey.grey500};
  text-align: center;
`
