import React, { FC, memo, useCallback, useState } from 'react'

import { TableDataQa } from 'commons/types/enums'
import { Table } from 'ui/components/Table'
import { CTRTransaction } from '~bank-bsa-reporting/types'

import { Transactions } from '../../../components'
import { TableProps } from './CTRTable.types'
import { columns } from './columns'
import { FilterForm } from './components'
import { dataSourceSelector } from './dataSourceSelector'

export const CTRTable: FC<TableProps> = memo((props) => {
  const { onItemSelect, onDelete, chosenItems, isAllItemsChosen, onGenerateCTRs, onShowWarnings, tableConfig, data } =
    props
  const {
    items,
    form: { page, size },
    total
  } = data
  const { onFilterChange, isFetching } = tableConfig
  const [modalItem, setModalItem] = useState<{ transactions: CTRTransaction[]; ctrId: number; ctrName: string }>({
    transactions: [],
    ctrId: 0,
    ctrName: ''
  })

  const onPageChange = useCallback(
    (page: number, size: number) => onFilterChange({ ...data.form, page, size }),
    [onFilterChange, data.form]
  )

  return (
    <>
      <Transactions
        onClose={() => setModalItem({ transactions: [], ctrId: 0, ctrName: '' })}
        transactions={modalItem.transactions}
        ctrId={modalItem.ctrId}
      />
      <FilterForm onGenerateCTRs={onGenerateCTRs} data={data} tableConfig={tableConfig} />
      <Table
        dataQa={TableDataQa.BP_CTR_LIST}
        columns={columns({
          onItemSelect,
          chosenItems,
          isAllItemsChosen,
          onDelete,
          onShowWarnings,
          onShowTransactions: setModalItem
        })}
        dataSource={dataSourceSelector(items)}
        pagination={{
          page: page,
          pageSize: size,
          onChange: onPageChange,
          total: total
        }}
        loading={isFetching}
      />
    </>
  )
})
