import { InvoiceSubmittalEditMode, ManageDocumentFormApiType } from '~bank-invoices/types/Manage'

export interface ManageInvoicePageProps {
  formApi: ManageDocumentFormApiType
  appearanceInfo: Omit<InvoiceSubmittalEditMode, 'form'>
  panelTitle: string
  pageTitle: string
  onDelete?: () => void
  onCancel: () => void
}
