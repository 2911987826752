import { getContactList } from 'commons/service/bank/contact'
import { Contact } from 'commons/types/DTO/bank'
import { Keys, ReadHook, useStateManager } from 'state-manager'

export const useGetContact: ReadHook<Contact, number> = (id) => {
  const sm = useStateManager()

  const { data, ...rest } = sm.use(Keys.Contact.ById(id), () => getContactList(id), {
    keepPreviousData: true,
    enabled: id !== 0
  })

  return {
    ...rest,
    data: data?.records[0]
  }
}
