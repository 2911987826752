import { useFormData } from 'brief-form'
import { useSalesDataClientSourceReject } from 'commons/hooks/bank/salesData'
import {
  SalesDataClientSourceRejectPayload,
  SalesDataSourceWebPage
} from 'commons/types/DTO/bank/salesDataSourceWebPage'
import { handlerServerError } from 'errors'

export const useRejectDialog = (
  webPage: SalesDataSourceWebPage,
  closeDialog: () => void,
  invalidate: () => Promise<void>
) => {
  const { config, Field } = useFormData<SalesDataClientSourceRejectPayload>({
    initialValue: new SalesDataClientSourceRejectPayload(webPage)
  })

  const { routine: reject, isLoading: isRejecting } = useSalesDataClientSourceReject({
    onSuccess: async () => {
      invalidate()
      closeDialog()
    },
    onError: (error) => handlerServerError<SalesDataClientSourceRejectPayload>(error, { form: config })
  })

  return {
    config,
    Field,
    reject: () => reject(config.value),
    isRejecting
  }
}
