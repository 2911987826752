import { ApiReadResponse } from 'commons/types'
import { BsaSar } from 'commons/types/DTO/bank/bsa'
import { Request } from 'commons/utils/request'
import { ServerOptions } from 'ui/components/QueryTable'

import { emptySAR } from '../constants'

export const getList = (opts: ServerOptions): Promise<ApiReadResponse<BsaSar>> =>
  Request.post(Request.urls.bank.sarList, opts)

export const getItem = async (id: number): Promise<BsaSar> => {
  const serverOprions: ServerOptions = { _options: { filters: [{ field: 'id', type: 'eq', value: id }] } }
  const promise = id !== 0 ? getList(serverOprions) : Promise.resolve({ records: [emptySAR] })
  const data = await promise
  return data?.records.length ? data.records[0] : null
}

export const remove = (opts: ServerOptions) => Request.delete(Request.urls.bank.sar, opts)

export const save = (opts: ServerOptions) => {
  const saveFunc = opts.id ? Request.put : Request.post
  return saveFunc(Request.urls.bank.sar, opts)
}

export const transactionsZIP = (opts: ServerOptions) => Request.post(Request.urls.bank.bsaSarTransactionsZip, opts)

export const companyList = (opts: ServerOptions) => Request.post(Request.urls.bank.companyList, opts)

export const editPeriod = (opts: ServerOptions) => Request.put(Request.urls.bank.editSarPeriod, opts)

export const generateXML = (opts: ServerOptions) => Request.post(Request.urls.bank.sarFormBatch, opts)

export const approveSAR = (opts: { id: number }) => Request.patch(Request.urls.bank.bsaSarApprove, opts)
