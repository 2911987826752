import React from 'react'

import { extractFilenameFromPath } from 'commons/utils'
import { Request } from 'commons/utils/request'
import { DownloadLink, TableColumn, Toast } from 'ui/components'
import { InvoiceSubmittalDetails } from '~bank-invoices/types/Details'

export const columns = (invoiceSubmittalId: number): TableColumn<InvoiceSubmittalDetails['files'][0]>[] => [
  {
    align: 'center',
    width: '125px',
    title: 'File Name',
    render: ({ name }) => <span data-qa={`file-${name}`}>{extractFilenameFromPath(name)}</span>
  },
  {
    align: 'center',
    width: '125px',
    title: 'Action',
    render: (item) => {
      const originalFileName = extractFilenameFromPath(item.name)
      return (
        <DownloadLink
          direct
          parametersForm={{
            fileKey: item.fileKeyDeprecated,
            fileKeyIndex: item.fileKeyIndex,
            invoiceSubmittalId
          }}
          baseUrl={Request.urls.bank.invoiceSubmittalFileDownload}
          name={originalFileName}
          handleError={(error) => Toast.error(error)}
        >
          Download
        </DownloadLink>
      )
    }
  }
]
