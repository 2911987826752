import React from 'react'

import { TableDataQa } from 'commons/types/enums'
import { Table } from 'ui/components/Table'

import { ReportTableProps } from './ReportTable.types'
import { columns } from './columns'

export const ReportTable = (props: ReportTableProps) => {
  const { dataSource, pagination, sorting } = props

  return (
    <Table
      pagination={pagination}
      sorting={sorting}
      columns={columns}
      dataSource={dataSource.map((item) => ({ item }))}
      dataQa={TableDataQa.BP_REPORTS_CLIENT_USERS_ACTIVITY_REPORT}
    />
  )
}
