import React from 'react'

import { IconName } from 'ui/components'
import { IconButton } from 'ui/components/Buttons'
import { Col2x, FormRow } from 'ui/themes/globalStyles'

import { useSARFormContext } from '../../../../../../context'
import { IPAddressItem } from './IPAddressItem'

export const IPAddresses = () => {
  const { formApi } = useSARFormContext()

  return (
    <>
      <FormRow>
        <Col2x>
          <h5>43. IP Address</h5>
        </Col2x>
      </FormRow>
      <formApi.Field mode="array" name="transactions_info.ip_addresses">
        {(field) => {
          if (typeof field.state.value === 'undefined') {
            return null
          }

          return (
            <>
              {(field.state.value || []).map((_, index) => (
                <IPAddressItem key={index} index={index} field={field} />
              ))}
              <FormRow>
                <IconButton
                  face="positive"
                  icon={IconName.ADD_GREEN}
                  onClick={() => field.pushValue({ date: '', ip: '', time: '' })}
                />
              </FormRow>
            </>
          )
        }}
      </formApi.Field>
    </>
  )
}
