import React, { FC, memo } from 'react'

import { Request } from 'commons/utils/request'
import { Page, PageFace, Panel, PanelFace, Spinner, SpinnerSize } from 'ui/components'

import { ExportCSVButton, ReportBreadcrumbs } from '../../components'
import { AlertsTable } from './components'
import { useAlertsReport } from './hooks'

const AlertsReport: FC = memo(() => {
  const { data, pagination, isFetching } = useAlertsReport()

  if (isFetching && !data) {
    return <Spinner centered size={SpinnerSize.M} />
  }

  return (
    <Page
      face={PageFace.PRIMARY}
      title={<ReportBreadcrumbs title="Alerts Report" />}
      actions={<ExportCSVButton reportName="Alerts" url={Request.urls.bank.portalReportAlertsCSV} />}
    >
      <Panel rounded shadowed collapsible={false} face={PanelFace.SECONDARY}>
        <AlertsTable data={data} pagination={pagination} isFetching={isFetching} />
      </Panel>
    </Page>
  )
})

export default AlertsReport
