import React from 'react'

import { FF, TextInput } from 'ui/components'

import { useSARFormContext } from '../../../../../../../../context'

export const Value = ({ index }: { index: number }) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={
        `transactions_info.product_instrument_desc[${index}].value` as 'transactions_info.product_instrument_desc[0].value'
      }
      children={(field) => <FF label="Value" field={field} input={(p) => <TextInput {...p} />} />}
    />
  )
}
