export type ApplicationRelationshipLicenseDetails = {
  name?: string
  type?: string
  subtype?: string
  license_number?: string
  pos_type?: string
  phone?: string
  pos_type_other?: string
  issue_date?: string
  expiration_date?: string
  license_metrc_api_key?: string
}

export type ApplicationRelationshipLicenseAddressInformation = {
  street?: string
  city?: string
  state?: string
  postal_code?: string
}

export type ApplicationRelationshipLicense = ApplicationRelationshipLicenseDetails &
  ApplicationRelationshipLicenseAddressInformation & {
    // TODO: explore why this field doesn't used in onboarding-form
    country?: string
  }
