import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 19px 35px;
`

export const FormContentLayout = styled.div`
  padding: 0 50px;
`

export const FormStatusMessage = styled.div`
  padding-right: 15px;
  font-style: italic;
  color: ${(p) => p.theme.uiKit.colors.global.grey.grey700};
  display: flex;
  align-items: center;
  gap: 5px;

  svg {
    fill: none;
  }
`

export const FooterButtons = styled.div`
  display: flex;
  gap: 15px;
`
