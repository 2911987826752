import { ContactItemFormValue } from '../../../types/forms'
import { CompanyFormValues } from '../../../types/state'

type GetCompanyContactListParams = { companyItem: CompanyFormValues; companyIndex: number }
type CompanyContactRecord = {
  companyIndex: number
  contactIndex: number
  companyContactFormsData: ContactItemFormValue
}
export type CompanyContactList = CompanyContactRecord[]
type GetCompanyContactListReturn = CompanyContactList
type GetCompanyContactListHandler = (params: GetCompanyContactListParams) => GetCompanyContactListReturn

const getCompanyContactList: GetCompanyContactListHandler = ({ companyItem, companyIndex }) =>
  companyItem.contacts.map((contact, contactIndex) => {
    if (!contact.details.formValues.email) {
      return undefined
    }

    const contactItem = companyItem.contacts[contactIndex]
    return {
      companyIndex,
      contactIndex,
      companyContactFormsData: {
        details: contactItem.details,
        roleInformation: contactItem.roleInformation,
        contactAdditionalInformation: contactItem.contactAdditionalInformation
      }
    }
  })

type GetAllContactFromFormsParams = { companiesFormValues: CompanyFormValues[] }
type GetAllContactFromFormsHandler = (params: GetAllContactFromFormsParams) => GetCompanyContactListReturn

// eslint-disable-next-line import/no-unused-modules
export const getAllContactFromForms: GetAllContactFromFormsHandler = ({ companiesFormValues }) => {
  let allContacts: CompanyContactList = []
  companiesFormValues.forEach((companyItem, companyIndex) => {
    const companyContactList = getCompanyContactList({ companyItem, companyIndex })
    allContacts = [...allContacts, ...companyContactList.filter((c) => c !== undefined)]
  })
  return allContacts
}
