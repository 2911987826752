import { QueryClient, useMutation } from 'react-query'

import { BsaCtr } from 'commons/types/DTO/bank/bsa'
import { BSACTRStatus } from 'commons/types/enums'
import { Toast } from 'ui/components/Toast'

import * as Service from '../../service'

export const useSave = (
  client: QueryClient,
  callbackOnError?: (params: { string: [] }) => void,
  noUpdate?: boolean,
  action?: string
) =>
  useMutation((item: BsaCtr) => Service.CTR.save(item), {
    onError: ({ message, meta, httpCode }: { message: string; meta?: { string: [] }; httpCode?: number }, vars) => {
      let errorMessage = message
      if (vars.status === BSACTRStatus.APPROVED && httpCode === 400) {
        errorMessage = 'The CTR contains errors and can’t be approved.'
      }
      Toast.errorSmall(errorMessage)
      callbackOnError && meta && callbackOnError(meta)
    },
    onSuccess: async (_, item) => {
      if (!noUpdate) {
        Toast.successSmall(`The CTR was ${action ? action : 'updated'} successfully`)
        await client.invalidateQueries(['tables', 'CTRs List'])
        await client.invalidateQueries(['ctrs', item.id])
      }
    }
  })
