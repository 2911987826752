import React, { memo, useCallback } from 'react'

import { DepositFromSales } from 'commons/types/DTO/bank/quarterlyReport/bankAccount/DepositFromSales'
import { formatCurrency } from 'commons/utils'
import SC from 'styled-components'
import { Layer } from 'ui/components/Layer'

//region Styled

const WrappedLayer = SC(Layer)`
  display: inline-block;
  margin-bottom: 47px;
  width: 100%;
`

const Box = SC.div`
  border-top: 2px solid #000;
`

const Title = SC.h2`
  padding: 10px 20px;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  margin: 0;
`

const Columns = SC.div`
  display: flex;
`

const Labels = SC.div`
  background: ${(p) => p.theme.uiKit.colors.global.grey.grey100};
  padding: 49px 0 0 0;
`

const Label = SC.div`
  height: 34px;
  padding: 9px 16px;
  font-size: 13px;
  line-height: 16px;
`

const Table = SC.table`
  border-collapse: collapse;
`

const Th = SC.th<{ $bottomBorder?: boolean }>`
  font-size: 14px;
  line-height: 22px;
  color: #fff;
  background: ${(p) => p.theme.uiKit.colors.global.grey.grey900};
  padding: 1px 30px;
  border-right: 1px solid #fff;
  ${(p) => (p.$bottomBorder ? 'border-bottom: 1px solid #fff' : '')};
  
  &:last-child {
    border-right: none;
  }
`

const Td = SC.td<{ $hl?: boolean }>`
  font-size: 13px;
  line-height: 16px;
  padding: 9px 16px;
  text-align: center;
  border-right: 1px solid ${(p) => p.theme.uiKit.colors.global.grey.grey100};
  background: ${(p) => (p.$hl ? p.theme.uiKit.colors.theme.negative.main : 'transparent')};
  
  &:last-child {
    border-right: none;
  }
`

const TV = SC.span<{ $negative?: boolean }>`
  color: ${(p) =>
    p.$negative ? p.theme.uiKit.colors.theme.negative.main : p.theme.uiKit.colors.global.general.secondary};
`

//endregion

export const DepositsFromSalesTable = memo(
  ({ priorQuarter, currentQuarter, changesPriorQuarter }: DepositFromSales) => {
    const PQ = useCallback((v: number) => {
      if (v === -1) {
        return 'N/A'
      }

      return v >= 0 ? <TV>{v.toFixed(2)}%</TV> : <TV $negative>({(-v).toFixed(2)})%</TV>
    }, [])

    return (
      <WrappedLayer rounded shadowed>
        <Box>
          <Title>DEPOSITS FROM SALES</Title>
          <Columns>
            <Labels>
              <Label>Current Quarter</Label>
              <Label>Prior Quarter</Label>
              <Label>
                <b>% Change</b>
              </Label>
            </Labels>
            <Table>
              <thead>
                <tr>
                  <Th rowSpan={2}>Total</Th>
                  <Th $bottomBorder colSpan={3}>
                    Cash
                  </Th>
                  <Th rowSpan={2}>Internal Transfers</Th>
                  <Th rowSpan={2}>Credit / Debit Cards</Th>
                  <Th rowSpan={2}>Checks</Th>
                  <Th rowSpan={2}>Other</Th>
                </tr>
                <tr>
                  <Th>Deposits</Th>
                  <Th>Invoices</Th>
                  <Th>ATM Load</Th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <Td>{formatCurrency(currentQuarter.total, false)}</Td>
                  <Td>{formatCurrency(currentQuarter.cashDeposits, false)}</Td>
                  <Td>{formatCurrency(currentQuarter.cashInvoices, false)}</Td>
                  <Td>{formatCurrency(currentQuarter.cashATMLoad, false)}</Td>
                  <Td>{formatCurrency(currentQuarter.internalTransfers, false)}</Td>
                  <Td>{formatCurrency(currentQuarter.creditDebitCards, false)}</Td>
                  <Td>{formatCurrency(currentQuarter.checks, false)}</Td>
                  <Td>{formatCurrency(currentQuarter.other, false)}</Td>
                </tr>
                <tr>
                  <Td>{formatCurrency(priorQuarter.total, false)}</Td>
                  <Td>{formatCurrency(priorQuarter.cashDeposits, false)}</Td>
                  <Td>{formatCurrency(priorQuarter.cashInvoices, false)}</Td>
                  <Td>{formatCurrency(priorQuarter.cashATMLoad, false)}</Td>
                  <Td>{formatCurrency(priorQuarter.internalTransfers, false)}</Td>
                  <Td>{formatCurrency(priorQuarter.creditDebitCards, false)}</Td>
                  <Td>{formatCurrency(priorQuarter.checks, false)}</Td>
                  <Td>{formatCurrency(priorQuarter.other, false)}</Td>
                </tr>
                <tr>
                  <Td $hl={changesPriorQuarter.total.alerted}>{PQ(changesPriorQuarter.total.percent)}</Td>
                  <Td $hl={changesPriorQuarter.cashDeposits.alerted}>{PQ(changesPriorQuarter.cashDeposits.percent)}</Td>
                  <Td $hl={changesPriorQuarter.cashInvoices.alerted}>{PQ(changesPriorQuarter.cashInvoices.percent)}</Td>
                  <Td $hl={changesPriorQuarter.cashATMLoad.alerted}>{PQ(changesPriorQuarter.cashATMLoad.percent)}</Td>
                  <Td $hl={changesPriorQuarter.internalTransfers.alerted}>
                    {PQ(changesPriorQuarter.internalTransfers.percent)}
                  </Td>
                  <Td $hl={changesPriorQuarter.creditDebitCards.alerted}>
                    {PQ(changesPriorQuarter.creditDebitCards.percent)}
                  </Td>
                  <Td $hl={changesPriorQuarter.checks.alerted}>{PQ(changesPriorQuarter.checks.percent)}</Td>
                  <Td $hl={changesPriorQuarter.other.alerted}>{PQ(changesPriorQuarter.other.percent)}</Td>
                </tr>
              </tbody>
            </Table>
          </Columns>
        </Box>
      </WrappedLayer>
    )
  }
)
