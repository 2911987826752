import React, { FC, useMemo } from 'react'

import { BsaCtrBatch } from 'commons/types/DTO/bank/bsa'
import { TableDataQa } from 'commons/types/enums'
import { Link } from 'ui/components/Link'
import { DataSourceItem, Table } from 'ui/components/Table'

import { Tag } from './Tag'

export const BatchCTRsTable: FC<{ ctrs: BsaCtrBatch[] }> = ({ ctrs }) => {
  const mappedCTRs: DataSourceItem<BsaCtrBatch>[] = useMemo(
    () =>
      ctrs.map((el) => ({
        item: el
      })),
    [ctrs]
  )
  return (
    <Table<BsaCtrBatch>
      dataQa={TableDataQa.BP_BATCH_CTRS}
      columns={[
        {
          title: 'Name',
          align: 'left',
          render: (item) => (
            <Link to="/bsa/ctrs/$id" params={{ id: item.ctr_id }}>
              {item.ctr_name}
            </Link>
          )
        },
        {
          title: 'Status',
          align: 'right',
          render: ({ ctr_status }) => <Tag status={ctr_status as any} />
        }
      ]}
      dataSource={mappedCTRs}
    />
  )
}
