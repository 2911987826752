export interface DueTooltipProps {
  id: number
  firstAlerted: number
  initialAlerted: number
  pastDue: number
  lastAlerted: number
  expiration_date: string
  firstAlert: number
  initialAlert: number
  lastAlert: number
}
