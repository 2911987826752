import { useMutation } from 'react-query'

import { useNavigate } from '@tanstack/react-router'
import { PortalName } from 'commons/types/enums'
import { authCheckLink } from '~auth/service/user'

export const useCheckLink = (p: PortalName) => {
  const navigate = useNavigate()

  return useMutation((key: string) => authCheckLink(p, key), {
    onError: () => {
      navigate({ to: '/auth/forgot-password', search: { expired: true } })
    },
    onSuccess: async (response) => {
      if (response.IsExpired) {
        navigate({ to: '/auth/forgot-password', search: { expired: true } })
      }
    }
  })
}
