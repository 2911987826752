import { useMutationManager } from 'react-query-state-manager'

import { handlerServerError } from 'errors'
import { Toast } from 'ui/components/Toast'
import { SAR } from '~bank-bsa-reporting/service'

export const useSARApprove = (onSuccess: () => void, onError: () => void) =>
  useMutationManager(SAR.approveSAR, {
    onSuccess: async () => {
      Toast.successSmall('The SAR was approved successfully')
      await onSuccess()
    },
    onError: (error) => {
      handlerServerError(error)
      onError()
    }
  })
