import React, { FC, ReactNode, memo } from 'react'

import { Link as RouterLink, LinkProps as RouterLinkProps } from '@tanstack/react-router'
import SC from 'styled-components'

import { Button, ButtonFace, ButtonSize } from '../Button'
import { IconName, IconPosition, IconSize, IconV2 } from '../Icon'

/**
 * TODO: remove Button functionality, leave Link related stuff only
 */

export type LinkProps = RouterLinkProps & {
  children?: ReactNode
  to: string
  /** @deprecated use Buttons except Link */
  buttonFace?: ButtonFace
  /** @deprecated use Buttons except Link */
  buttonSize?: ButtonSize

  /** look like these icons used for main menu only, do not use it anywhere else,
   *  maybe better to remove them from here
   */
  iconName?: IconName
  iconSize?: IconSize
  iconPosition?: IconPosition
  onClick?: () => any
  active?: boolean
  noUnderline?: boolean
  'data-qa'?: string
  face?: 'brand'
  tabIndex?: number
}

// region Styled

const Outer = SC.span`
  display: inline-block;
`

const Box = SC(RouterLink)<{
  $position?: IconPosition
  $button: boolean
  $active?: boolean
  $noUnderline?: boolean
  $face?: string
}>`
  color: ${(p) =>
    p.$face === 'brand' ? p.theme.uiKit.colors.theme.brand.primary : p.theme.uiKit.colors.global.general.secondary};
  text-decoration: ${(p) => (p.$noUnderline || p.$button ? 'none' : 'underline')};
  display: flex;
  flex-grow: 0;
  align-items: center;
  flex-direction: ${(p) => (p.$position === IconPosition.RIGHT ? 'row-reverse' : 'row')};
  cursor: pointer;
  outline: none;
  ${(p) => p.$active && 'font-weight: bold;'}
  
  &:hover {
    color: ${(p) => (p.$face === 'brand' ? p.theme.uiKit.colors.theme.brand.primary : '#01c699')};
    text-decoration: ${(p) => (p.$button ? 'none' : 'underline')};
  }
  
  &:visited: {
    color: ${(p) => p.theme.uiKit.colors.global.general.secondary} !important;
  }
`

const WrappedIcon = SC(IconV2)<{ $position?: IconPosition }>`
  ${(p) => (p.$position === IconPosition.RIGHT ? 'margin-left: 12px' : 'margin-right: 12px')};
`

// endregion

export const Link: FC<LinkProps> = memo((props) => {
  const {
    children,
    to,
    params,
    hash,
    search,
    buttonFace,
    buttonSize,
    iconName,
    iconSize,
    iconPosition,
    onClick,
    active,
    noUnderline,
    'data-qa': dataQa,
    face,
    ...rest
  } = props

  return (
    <Outer onClick={onClick} {...rest} data-qa={!!dataQa ? dataQa : undefined}>
      <Box
        $position={iconPosition}
        $button={!!buttonFace}
        $active={active}
        $noUnderline={noUnderline}
        to={to}
        params={params}
        search={search}
        hash={hash}
        $face={face}
      >
        {!!iconName && !buttonFace && <WrappedIcon name={iconName} $position={iconPosition} size={iconSize} />}
        {buttonFace ? (
          <Button face={buttonFace} size={buttonSize} icon={iconName} iconPosition={iconPosition}>
            {children}
          </Button>
        ) : (
          <span>{children}</span>
        )}
      </Box>
    </Outer>
  )
})
