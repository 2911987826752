import { HistoryEntity } from '../historyAudit'
import { DailyReport } from './DailyReport'
import { SalesSourceLicense } from './SalesSourceLicense'
import { UploadedFile } from './UploadedFile'

export class SalesDataSourceWebPage {
  dailyReports: DailyReport[]
  editButtonTooltip: string
  enableApprove: boolean
  enableDelete: boolean
  enableDownloadCSV: boolean
  enableEdit: boolean
  enableReject: boolean
  enableUploadFiles: boolean
  gramsSoldAmount: number
  gramsSoldAmountLabel: string
  gramsSoldQty: number
  infoClientNotes: string
  infoEnableEditRejectionReason: boolean
  infoInternalNotes: string
  infoInternalNotesLabel: string
  infoLastModified: string
  infoLastRejectionReason: string
  infoLicenses: SalesSourceLicense[]
  infoMonth: string
  infoRejectionReason: string
  infoReportID: number
  infoReportName: string
  infoStatus: string
  history: HistoryEntity[]
  historyTotalCount: number
  monthlyAnalyticsID: number
  monthlyAnalyticsName: string
  quarterlyAnalyticsID: number
  quarterlyAnalyticsName: string
  salesDataSourceID: number
  salesDataSourceName: string
  title: string
  titleOverline: string
  totalSalesAmount: number
  totalSalesAmountDesc: string
  unitsSoldAmount: number
  unitsSoldAmountLabel: string
  unitsSoldQty: number
  taxesAndNonCannabisSales: number
  uploadedFiles: UploadedFile[]
  uploadedFilesTotalCount: number
}
