import React, { FC, memo } from 'react'

import { Hr } from 'ui/components/Hr'

import { QuestionnaireProps } from './QuestionnaireProps'
import { Answer, AnticipatedMonthly, AnticipatedMonthlyWithActivityDeposits, AtmsInformation } from './components'
import * as SC from './style'

export const Questionnaire: FC<QuestionnaireProps> = memo(({ questionnaire }) => (
  <SC.Wrapper>
    <SC.Title>Questionnaire</SC.Title>
    {questionnaire.contactsForeignConnections !== undefined && (
      <Answer
        marginBottom="36px"
        label={questionnaire.contactsForeignConnections.question}
        value={questionnaire.contactsForeignConnections.answer ? 'Yes' : 'No'}
      />
    )}
    <Answer
      vertical
      marginBottom="36px"
      label={questionnaire.statesLicensed.question}
      value={questionnaire.statesLicensed.answer}
    />
    {questionnaire.aWholesaleProducts !== undefined && (
      <Answer
        label={questionnaire.aWholesaleProducts?.question || ''}
        value={questionnaire.aWholesaleProducts?.answer ? 'Yes' : 'No'}
      />
    )}
    {questionnaire.aWholesaleProducts !== undefined && questionnaire.aWholesaleProductsDesc !== undefined && (
      <Answer
        label={questionnaire.aWholesaleProductsDesc?.question || ''}
        value={questionnaire.aWholesaleProductsDesc?.answer || ''}
      />
    )}
    {questionnaire.bTypeOfProducts !== undefined && (
      <Answer
        label={questionnaire.bTypeOfProducts?.question || ''}
        value={questionnaire.bTypeOfProducts?.answer || ''}
      />
    )}
    {questionnaire.cTypeOfAncillaryProducts !== undefined && (
      <Answer
        label={questionnaire.cTypeOfAncillaryProducts?.question || ''}
        value={questionnaire.cTypeOfAncillaryProducts?.answer || ''}
      />
    )}
    {questionnaire.dHowManyLocations !== undefined && (
      <Answer
        label={questionnaire.dHowManyLocations?.question || ''}
        value={questionnaire.dHowManyLocations?.answer || ''}
      />
    )}
    {questionnaire.eOnlineOrdersForPickup !== undefined && (
      <Answer
        label={questionnaire.eOnlineOrdersForPickup?.question || ''}
        value={questionnaire.eOnlineOrdersForPickup?.answer ? 'Yes' : 'No'}
      />
    )}
    {questionnaire.fMinorSellingEnsure !== undefined && (
      <Answer
        label={questionnaire.fMinorSellingEnsure?.question || ''}
        value={questionnaire.fMinorSellingEnsure?.answer || ''}
      />
    )}
    {questionnaire.gPaymentMethods !== undefined && (
      <Answer
        label={questionnaire.gPaymentMethods?.question || ''}
        value={questionnaire.gPaymentMethods?.answer || ''}
      />
    )}
    {questionnaire.gPaymentMethods !== undefined && (
      <Answer
        label={questionnaire.hTransferToOtherStates?.question || ''}
        value={questionnaire.hTransferToOtherStates?.answer ? 'Yes' : 'No'}
      />
    )}
    {questionnaire.hTransferToOtherStates !== undefined && questionnaire.hTransferToOtherStatesDesc !== undefined && (
      <Answer
        label={questionnaire.hTransferToOtherStatesDesc?.question || ''}
        value={questionnaire.hTransferToOtherStatesDesc?.answer || ''}
      />
    )}
    {questionnaire.iAreYouAffiliated !== undefined && (
      <Answer
        label={questionnaire.iAreYouAffiliated?.question || ''}
        value={questionnaire.iAreYouAffiliated?.answer ? 'Yes' : 'No'}
      />
    )}
    {questionnaire.iAreYouAffiliated !== undefined && questionnaire.iAreYouAffiliatedDesc !== undefined && (
      <Answer
        label={questionnaire.iAreYouAffiliatedDesc?.question || ''}
        value={questionnaire.iAreYouAffiliatedDesc?.answer || ''}
      />
    )}
    <Hr />
    <SC.SubHeader>Professional Partnerships</SC.SubHeader>
    <Answer
      label={questionnaire.proPartCurrentlyBanked.question}
      value={questionnaire.proPartCurrentlyBanked.answer ? 'Yes' : 'No'}
    />
    {questionnaire.proPartPrevClosureReason !== undefined && (
      <Answer
        label={questionnaire.proPartPrevClosureReason?.question || ''}
        value={questionnaire.proPartPrevClosureReason?.answer || ''}
      />
    )}
    <Answer label={questionnaire.proPartCurrentServices.question} value={questionnaire.proPartCurrentServices.answer} />
    {questionnaire.proPartCurrentServicesOtherDesc.answer && (
      <Answer
        label={questionnaire.proPartCurrentServicesOtherDesc.question}
        value={questionnaire.proPartCurrentServicesOtherDesc.answer}
      />
    )}
    {questionnaire.proPartCurrentServicesLogisticDesc?.answer && (
      <Answer
        label={questionnaire.proPartCurrentServicesLogisticDesc.question}
        value={questionnaire.proPartCurrentServicesLogisticDesc.answer}
      />
    )}
    <Answer vertical label={questionnaire.proPartLitigation.question} value={questionnaire.proPartLitigation.answer} />
    <Answer
      vertical
      label={questionnaire.proPartStateEnforcements.question}
      value={questionnaire.proPartStateEnforcements.answer}
    />
    <Answer
      vertical
      label={questionnaire.proPartPayrollSoftware.question}
      value={questionnaire.proPartPayrollSoftware.answer}
    />
    {questionnaire.proPartInternetGambling !== undefined && (
      <Answer
        marginBottom="36px"
        label={questionnaire.proPartInternetGambling.question}
        value={questionnaire.proPartInternetGambling.answer ? 'Yes' : 'No'}
      />
    )}
    <Hr />
    <SC.SubHeader>ATM</SC.SubHeader>
    <Answer label={questionnaire.ATMHave.question} value={questionnaire.ATMHave.answer ? 'Yes' : 'No'} />
    <Answer label={questionnaire.ATMOwn.question} value={questionnaire.ATMOwn.answer ? 'Yes' : 'No'} />
    {questionnaire.ATMHave.answer && (
      <Answer label={questionnaire.ATMCount.question} value={questionnaire.ATMCount.answer} />
    )}
    {questionnaire.ATMHave.answer && (
      <Answer
        label={questionnaire.ATMReplenishingThirdParty.question}
        value={questionnaire.ATMReplenishingThirdParty.answer ? 'Yes' : 'No'}
      />
    )}
    {questionnaire.ATMHave.answer && (
      <Answer
        label={questionnaire.ATMReplenishingCompany.question}
        value={questionnaire.ATMReplenishingCompany.answer ? 'Yes' : 'No'}
      />
    )}
    <AtmsInformation items={questionnaire.ATMInfo} />
    <Hr />
    <SC.SubHeader>Available Services</SC.SubHeader>
    <Answer
      vertical
      marginBottom="36px"
      label={questionnaire.availableServices.question}
      value={questionnaire.availableServices.answer}
    />
    {questionnaire.availServiceHowManyAccountsIsOpening !== undefined && (
      <Answer
        marginBottom="36px"
        label={questionnaire.availServiceHowManyAccountsIsOpening.question}
        value={questionnaire.availServiceHowManyAccountsIsOpening.answer}
      />
    )}
    <Hr />
    <SC.SubHeader>Anticipated Monthly Activity</SC.SubHeader>

    {questionnaire.anticipatedMonthlyActivity.length > 0 && (
      <AnticipatedMonthlyWithActivityDeposits questionnaire={questionnaire} />
    )}
    {questionnaire.anticipatedMonthlyActivityVariant.length > 0 && <AnticipatedMonthly questionnaire={questionnaire} />}

    <Answer label={questionnaire.legacyCash.question} value={questionnaire.legacyCash.answer ? 'Yes' : 'No'} />
    <Answer
      label={questionnaire.merchantProcessing.question}
      value={questionnaire.merchantProcessing.answer ? 'Yes' : 'No'}
    />
    {questionnaire.merchantProcessingDesc && (
      <Answer
        label={questionnaire.merchantProcessingDesc.question}
        value={questionnaire.merchantProcessingDesc.answer}
      />
    )}
    {questionnaire.primaryMethodToFund !== undefined && (
      <Answer label={questionnaire.primaryMethodToFund.question} value={questionnaire.primaryMethodToFund.answer} />
    )}
    {questionnaire.cryptocurrencyTransactions !== undefined && (
      <Answer
        label={questionnaire.cryptocurrencyTransactions.question}
        value={questionnaire.cryptocurrencyTransactions.answer ? 'Yes' : 'No'}
      />
    )}
    {questionnaire.realTimePaymentServices !== undefined && (
      <Answer
        label={questionnaire.realTimePaymentServices.question}
        value={questionnaire.realTimePaymentServices.answer ? 'Yes' : 'No'}
      />
    )}
  </SC.Wrapper>
))
