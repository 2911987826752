import { SortDirection } from 'ui/components'

import { TableState } from '../useTableState/types'

const emptyTableState: TableState = {
  order: [{ field: 'name', direction: SortDirection.ASC }],
  expanded: false,
  total: 10,
  pageSize: 10,
  page: 1,
  shouldInvalidate: false,
  shouldClearFilter: {}
}

export default emptyTableState
