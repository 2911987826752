import React, { FC, memo, useMemo } from 'react'

import { HistoryTable } from 'bank-entity-history/src/components/HistoryTable'
import { TableDataQa } from 'commons/types/enums'
import { Layer } from 'ui/components/Layer'
import { PageSection, PageSectionFace } from 'ui/components/Page'

import { HistoryProps } from './HistoryProps'

export const History: FC<HistoryProps> = memo((props) => {
  const { data, config } = props
  const { filter, onFilterChange, isFetching } = config
  const { page, size } = filter

  const pagination = useMemo(
    () => ({ page, pageSize: size, onChange: onFilterChange, total: data.totalCount }),
    [data, size, page, onFilterChange]
  )

  return (
    <PageSection title="License history" face={PageSectionFace.SECONDARY} anchor="history">
      <Layer rounded shadowed>
        <HistoryTable
          dataQa={TableDataQa.BP_LICENSE_HISTORY}
          loading={isFetching}
          items={data.items}
          pagination={pagination}
        />
      </Layer>
    </PageSection>
  )
})
