import React, { useEffect } from 'react'

import { FieldApi } from '@tanstack/react-form'
import { BsaSar } from 'commons/types/DTO/bank/bsa'
import { typesOfFinancialInstitution } from 'commons/types/dictionaries'
import { FF, Select, Validators } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

const TypeRenderer = ({ field }: { field: FieldApi<BsaSar, 'institution_contact_info.type'> }) => {
  const { formApi } = useSARFormContext()

  useEffect(() => {
    if (field.state.value === 'Other') {
      const typeOtherMeta = formApi.getFieldMeta('institution_contact_info.type_other')
      formApi.setFieldMeta('institution_contact_info.type_other', { ...typeOtherMeta, errors: [], errorMap: {} })
    }
  }, [field.state.value, formApi])

  return (
    <FF
      required
      field={field}
      label="79. Type of financial institution"
      input={(p) => (
        <Select
          {...p}
          data={typesOfFinancialInstitution}
          data-qa="79. Type of financial institution"
          options-data-qa="79. Type of financial institution option"
        />
      )}
    />
  )
}

export const Type = () => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name="institution_contact_info.type"
      validators={{
        onChange: ({ value }) => Validators.required.field(value)
      }}
      children={(field) => <TypeRenderer field={field} />}
    />
  )
}
