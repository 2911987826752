import React, { FC } from 'react'

import { Outlet, getRouteApi } from '@tanstack/react-router'
import { Page, PageFace } from 'ui/components/Page'
import { FilesSection } from '~bank-documents/components/FilesSection'
import { DocumentsPaths } from '~bank-documents/constants'
import { DocumentPeriodDetailsResponse } from '~bank-documents/types/periodDetails'

import { HistorySection } from './components/HistorySection'
import { PeriodInformation } from './components/PeriodInformation'

const PeriodDetails: FC = () => {
  const routeApi = getRouteApi(DocumentsPaths.PERIOD_DETAILS)
  const data = routeApi.useLoaderData<DocumentPeriodDetailsResponse>()
  const periodId = routeApi.useParams({ select: (s) => s.periodId })

  return (
    <>
      <Page face={PageFace.SECONDARY} title="Document" subTitle={data.documentName}>
        <PeriodInformation />

        <FilesSection id={periodId} files={data.documentFiles} withDialog={data.isLastDelivery} />

        <HistorySection />
      </Page>
      <Outlet />
    </>
  )
}

export default PeriodDetails
