import { RoutesEnableList } from './types'

export const emptyEnablesList: RoutesEnableList = {
  isRelationshipsEnabled: true,
  isBankAlertsEnabled: true,
  isDocumentsEnabled: true,
  isContactsEnabled: true,
  isConnectionsEnabled: true,
  isBankUsersEnabled: true,
  isBankOnboardingEnabled: true,
  isHistoryEnabled: true,
  isBsaModuleEnabled: true,
  isAdverseMediaEnabled: true,
  isReportsModuleEnabled: true,
  isInternalTransfersEnabled: true,
  isAccountSettingsEnabled: true,
  isCalendarEnabled: true,
  isQrEnabled: true,
  isMrEnabled: true,
  isAnnualReviewEnabled: true,
  isLicensesEnabled: true,
  isBankInvoicesEnabled: true,
  isBankAccountsEnabled: true,
  isNextCloudLoginEnabled: true
}

export const emptyEnablesKeys = Object.keys(emptyEnablesList)

export const setAllEnabled = () => ({ ...emptyEnablesList })
export const setAllDisabled = (): typeof emptyEnablesList =>
  emptyEnablesKeys.reduce(
    (reviousValue, currentValue) => ({ ...reviousValue, [currentValue]: false }),
    {} as RoutesEnableList
  )
