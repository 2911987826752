import React, { cloneElement, forwardRef, useCallback, useEffect, useState } from 'react'

import {
  ElementProps,
  FloatingFocusManager,
  FloatingPortal,
  autoUpdate,
  flip,
  shift,
  size,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation
} from '@floating-ui/react-dom-interactions'

import { AutoSizeWidth, PopperProps } from './PopperProps'
import { POPPER_PORTAL_ID } from './constants'
import { useFixShaking } from './hooks/useFixShaking'
import { useListItems } from './hooks/useListItems'
import { usePrevious } from './hooks/usePrevious'
import { useStopPropagation } from './hooks/useStopPropagation'

import { Wrapper } from './styles'

export const Popper = forwardRef((props: PopperProps, _) => {
  const {
    placement,
    children,
    render,
    scrollClose = true,
    opened = false,
    disabled = false,
    autoSize = false,
    autoSizeMaxHeight = 0,
    autoSizeMinHeight = 0,
    autoSizeScreenEdgeMargin = 10,
    autoSizeWidth,
    listRef,
    activeIndex,
    setActiveIndex,
    selectedIndex,
    style,
    onClose,
    onOpen
  } = props

  const [open, setOpen] = useState(opened)
  const [controlledScrolling, setControlledScrolling] = useState(false)
  const prevActiveIndex = usePrevious<number | null>(activeIndex)

  useEffect(() => {
    setOpen(opened)
  }, [opened])

  const openAction = useCallback(() => {
    if (disabled) {
      return
    }
    setOpen(true)
    if (onOpen) {
      onOpen()
    }
  }, [setOpen, onOpen, disabled])

  const closeAction = useCallback(() => {
    setOpen(false)
    if (onClose) {
      onClose()
    }
  }, [setOpen, onClose])

  const handleOpenChange = useCallback(
    (open: boolean) => {
      if (open) {
        openAction()
      } else {
        closeAction()
      }
    },
    [open, openAction, closeAction]
  )

  const middleware = [flip(), shift()]

  if (autoSize) {
    middleware.push(
      size({
        apply({ rects, availableHeight, elements }) {
          if (
            autoSizeMaxHeight &&
            availableHeight <= autoSizeMaxHeight &&
            autoSizeMinHeight &&
            availableHeight - autoSizeScreenEdgeMargin > autoSizeMinHeight
          ) {
            Object.assign(elements.floating.style, {
              maxHeight: `${availableHeight - autoSizeScreenEdgeMargin}px`
            })
          } else if (autoSizeMinHeight && availableHeight - autoSizeScreenEdgeMargin <= autoSizeMinHeight) {
            Object.assign(elements.floating.style, {
              maxHeight: `${autoSizeMinHeight}px`
            })
          } else if (!autoSizeMaxHeight) {
            Object.assign(elements.floating.style, {
              maxHeight: `${availableHeight - autoSizeScreenEdgeMargin}px`
            })
          }
          if ((listRef && setActiveIndex) || autoSizeWidth) {
            switch (autoSizeWidth) {
              case AutoSizeWidth.FILL: {
                Object.assign(elements.floating.style, {
                  width: `${rects.reference.width}px`
                })
                break
              }
              case AutoSizeWidth.DOUBLE: {
                Object.assign(elements.floating.style, {
                  width: `${rects.reference.width * 2}px`
                })
                break
              }
              default: {
                Object.assign(elements.floating.style, {
                  width: `${rects.reference.width}px`
                })
              }
            }
          }
        }
      })
    )
  }

  const { x, y, reference, floating, strategy, context, refs, middlewareData } = useFloating({
    open,
    onOpenChange: handleOpenChange,
    middleware: middleware,
    placement,
    whileElementsMounted: autoUpdate
  })

  const useOpenOnKeyDown = (): ElementProps => {
    return {
      reference: {
        onKeyDown(e) {
          if (!open && e.key !== 'Tab') {
            openAction()
          }
        },
        /*
        TODO: tried to make select opening by focus in, for improving filling form using keyboard only
        but we have pages like relationships where edit form use fixed bottom panel
        and that panel hide controls when we navigating through inputs by tab key
        that's make some visual artefacts and complicates understanding whats going on
        should be worked around first: https://helioscompliance.atlassian.net/browse/MP-4971
        onFocus() {
          // use that only for selects
          if (!open && listRef) {
            openAction()
          }
        },
        */
        onClick(e) {
          if (!open) {
            openAction()
          } else {
            if (!(e.target instanceof HTMLInputElement)) {
              closeAction()
            }
          }
        }
      }
    }
  }

  const interactions = [
    useDismiss(context, {
      ancestorScroll: scrollClose
    }),
    useOpenOnKeyDown()
  ]

  if (listRef && setActiveIndex) {
    interactions.push(
      useListNavigation(context, {
        listRef: listRef,
        activeIndex: activeIndex,
        selectedIndex: selectedIndex,
        onNavigate: setActiveIndex,
        virtual: true
      })
    )
    useListItems(
      open,
      prevActiveIndex,
      controlledScrolling,
      activeIndex,
      selectedIndex,
      refs.floating,
      refs.reference,
      listRef,
      middlewareData
    )
  }

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(interactions)

  useStopPropagation(refs.floating)
  useFixShaking()

  return (
    <>
      {cloneElement(
        children,
        getReferenceProps({
          ref: reference,
          ...children.props,
          onKeyUp(event: KeyboardEvent) {
            setControlledScrolling(true)
            if (event.key === 'Enter' && listRef && setActiveIndex) {
              listRef.current[activeIndex]?.click()
            }
            if (event.key === 'Tab') {
              closeAction()
            }
          }
        })
      )}
      <FloatingPortal id={POPPER_PORTAL_ID}>
        {open && (
          <FloatingFocusManager context={context} preventTabbing={!!listRef}>
            <Wrapper
              {...getFloatingProps({
                ref: floating,
                style: {
                  position: strategy,
                  top: y ?? '',
                  left: x ?? '',
                  ...style
                },
                onPointerEnter() {
                  setControlledScrolling(false)
                },
                onPointerMove() {
                  setControlledScrolling(false)
                },
                onKeyDown(event) {
                  setControlledScrolling(true)
                  if (event.key === 'Enter' && listRef && setActiveIndex) {
                    listRef.current[activeIndex]?.click()
                  }
                }
              })}
            >
              {render({
                close: () => {
                  closeAction()
                },
                getItemProps
              })}
            </Wrapper>
          </FloatingFocusManager>
        )}
      </FloatingPortal>
    </>
  )
})
