import React, { FC, memo } from 'react'

import { Spinner } from 'ui/components/Spinner'

import { OverlayProps } from './OverlayProps'

import { Box } from './styles'

export const Overlay: FC<OverlayProps> = memo((props) => {
  const { visible } = props

  if (!visible) {
    return null
  }

  return (
    <Box>
      <Spinner centered size={64} />
    </Box>
  )
})
