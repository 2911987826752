import { CSSProperties } from 'react'

import { FormInputProps } from 'brief-form'
import { ChoiceOption, ChoiceValue } from 'ui/types'

import { SelectListName } from './selectListName'

export type SingleSelectAutoSuggestOpts = {
  // User can search through items by label name.
  searchable?: boolean

  // Predefined list name (aka shortcut to skip explicit data sources etc).
  listName?: SelectListName

  // Custom options set (should be provided for custom lists).
  options?: ChoiceOption[]

  placeholder?: string

  defaultOption?: ChoiceOption

  emptyOption?: ChoiceOption

  // Options describing fetching items from server (limits, filters etc).
  serverOptions?: {
    // API url to fetch items from.
    url: string

    // Mapping function to transform server entity to select option element.
    mapper: (item: any) => ChoiceOption

    // Optional auto suggest length limit.
    limit?: number

    // Optional filters.
    filters?: { field: string; type: string; value: ChoiceValue }[]

    // Explicit filter rule to fetch selected value item.
    valueItemFilter?: { field: string; type: string; value: ChoiceValue }

    // Entity field to compare with search term.
    searchFields?: string[]

    // string for search-parameter in body for new request-endpoints view
    requestSearchString?: string
  }

  // Component CSS class name.
  className?: string

  // Whether control is disabled.
  disabled?: boolean

  // Whether form field is required.
  required?: boolean

  // Whether dropdown matches select component width.
  apiAxios?: any

  // Free form additional props.
  extra?: any

  // Will be passed to Select component.
  containerStyle?: CSSProperties

  noFirstRequest?: boolean

  containerSelectors?: string[]

  'data-qa'?: string
  'options-data-qa'?: string
}

export interface SingleSelectAutoSuggestProps extends FormInputProps<ChoiceValue, SingleSelectAutoSuggestOpts> {}
