import { getSearchCaseList } from 'commons/service/bank/searchCase'
import { AdverseMediaSearchCase } from 'commons/types/DTO/bank'
import { Keys, ReadHook, useStateManager } from 'state-manager'

export const useGetSearchCase: ReadHook<AdverseMediaSearchCase, number> = (id) => {
  const sm = useStateManager()

  const { data, ...rest } = sm.use(
    Keys.AdverseMediaSearchCase.ById(id),
    () => getSearchCaseList({ _options: { filters: [{ field: 'id', type: 'eq', value: id }] } }),
    { keepPreviousData: true }
  )

  return {
    ...rest,
    data: data?.records[0]
  }
}
