import React from 'react'

import { CheckMark } from 'ui/components/CheckMark'
import { DateTime } from 'ui/components/DateTime'
import { Icon, IconName, IconSize } from 'ui/components/Icon'
import {
  MenuIconHoverFace,
  MenuItemBehavior,
  MenuItemTextFace,
  MenuItemType,
  TableActions,
  TableActionsItem
} from 'ui/components/QueryTable'
import { TableColumn } from 'ui/components/Table'
import { Tooltip } from 'ui/components/Tooltip'

import { UsersWebPageResponse } from '../../../../types'
import { UsersTableProps } from './UsersTable.types'

import { s } from './styles'

export const columns = (props: UsersTableProps): TableColumn<UsersWebPageResponse['users'][0]>[] => {
  const { onEdit, onChangeStatus, onResetPassword } = props
  return [
    {
      title: 'First Name',
      align: 'center',
      render: ({ active, firstName }) => <s.Text $inactive={!active}>{firstName}</s.Text>
    },
    {
      title: 'Last Name',
      align: 'center',
      render: ({ lastName, active }) => <s.Text $inactive={!active}>{lastName}</s.Text>
    },
    {
      title: 'Role',
      align: 'left',
      render: ({ role, active, id }) => (
        <s.TooltipContainer>
          <s.Text $inactive={!active}>{role.name}</s.Text>
          {role.description && (
            <s.TooltipWrapper>
              <Tooltip id={`role-${id}`} type="dark" content={<>{role?.description}</>}>
                <Icon name={IconName.INFO} size={IconSize.S} />
              </Tooltip>
            </s.TooltipWrapper>
          )}
        </s.TooltipContainer>
      )
    },
    {
      title: 'Email',
      align: 'center',
      render: ({ email, active }) => <s.Text $inactive={!active}>{email}</s.Text>
    },
    {
      title: 'Phone',
      align: 'center',
      render: ({ phone, active }) => <s.Text $inactive={!active}>{phone}</s.Text>
    },
    {
      title: 'Active',
      align: 'center',
      render: ({ active }) => (active ? <CheckMark value={active} rounded label="" /> : null)
    },
    {
      title: 'Created',
      align: 'center',
      render: ({ createdAt, active }) => (
        <s.TextBlock $inactive={!active}>
          <DateTime date={createdAt} withTime />
        </s.TextBlock>
      )
    },
    {
      title: 'Last Login',
      align: 'center',
      render: ({ lastLogin, active }) => (
        <s.TextBlock $inactive={!active}>
          <DateTime date={lastLogin} withTime />
        </s.TextBlock>
      )
    },
    {
      title: 'Actions',
      align: 'center',
      width: '50px',
      render: (item) => (
        <TableActions
          menuContainerId="userTableMenuContainer"
          items={(() => {
            const result: TableActionsItem[] = [
              item.enableDeactivate && {
                key: 2,
                title: item.active ? 'Deactivate User' : 'Activate User',
                behavior: MenuItemBehavior.BUTTON,
                onClick: () => onChangeStatus(item)
              },
              { key: 3, kind: MenuItemType.DIVIDER },
              item.enableEdit && {
                key: 4,
                title: 'Edit',
                iconName: IconName.EDIT,
                iconHoverFace: MenuIconHoverFace.PRIMARY,
                textFace: MenuItemTextFace.GREY,
                behavior: MenuItemBehavior.BUTTON,
                onClick: () => onEdit(item)
              }
            ].filter(Boolean) as any

            if (item.enablePasswordResetOrInvite) {
              const menuItem: TableActionsItem = {
                key: 1,
                title: item.lastLogin ? 'Reset Password' : 'Send Invite',
                behavior: MenuItemBehavior.BUTTON,
                onClick: () => onResetPassword(item)
              }
              return [menuItem].concat(result)
            }
            return result
          })()}
        />
      )
    }
  ]
}
