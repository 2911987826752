export interface AddressInformationProps {
  street: string
  city: string
  state: string
  postalCode: string
  country: string
  mailingStreet: string
  mailingCity: string
  mailingState: string
  mailingPostalCode: string
  mailingCountry: string
}
