import { Contact } from 'commons/types/DTO/bank'
import { ContactMetadataFormValue } from '~bank-contacts/types/forms'

export interface ContactMetadataManageDialogProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: (metadata: ContactMetadataFormValue) => Promise<Contact>
  metadata?: ContactMetadataFormValue
  contact?: Contact
  companyId?: number
}
