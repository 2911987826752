import styled from 'styled-components'
import { Hr } from 'ui/components/Hr'

export const Contact = styled.div`
  margin: 40px 0 0 0;
`

export const Header = styled.div`
  background-color: #e2e2e2;
  margin: 0 -50px 30px -50px;
  padding: 20px 50px;
  font-size: 22px;
`

export const Label = styled.div`
  font-size: 15px;
  font-weight: 600;
  color: #7b7b7b;
  margin-top: 30px;
  margin-bottom: 14px;
  display: inline-block;
`

export const ClientNotes = styled.div`
  font-size: 14px;
  color: #868686;
  margin-bottom: 34px;
`

export const CustomHr = styled(Hr)<{ $marginTop?: boolean }>`
  margin-bottom: 30px;
  margin-top: ${(p) => (p.$marginTop ? '30px' : '0')};
`

export const NotesWrapper = styled.div`
  width: 848px;
  margin-bottom: 20px;
`
