/* eslint-disable camelcase */

export type MfaDevice = {
  id: number
  active: boolean
  default: boolean
  type: string
  phone_number?: string
  email?: string
  totp_secret?: string
  totp_url?: string
  last_used_at?: Date
  created_at: Date
  updated_at: Date
  deleted_at?: Date
}
