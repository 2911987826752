export interface AccountInformationProps {
  businessType: string
  entityType: string
  stateFounded: string
  dateFounded: string | undefined
  licenseCount: number
  eINLabel: string
  ein: string
  enableInviteUsersToCP: boolean
}
