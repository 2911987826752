import styled from 'styled-components'

const DialogContent = styled.div`
  padding: 27px 42px;
`

const Label = styled.span`
  font-size: 22px;
`

const Copy = styled.div`
  width: 706px;
  display: flex;
  background-color: #f5f5f5;
  margin-top: 35px;
  border-radius: 100px;
  overflow: hidden;
`

const Input = styled.input`
  flex: 1;
  border: none;
  background-color: transparent;
  padding-left: 25px;
  padding-right: 25px;
  font-size: 18px;
  outline: none;
`

const CopyBtn = styled.span`
  cursor: pointer;
  color: #a6a6a6;
  font-size: 18px;
  font-weight: 600;
  padding: 16px 25px;
  border-left: 2px solid #ffffff;

  &:hover {
    color: #ffffff;
    background-color: #b8b8b8;
  }

  &:active {
    color: #ffffff;
    background-color: #b8b8b8;
  }
`

export const s = { DialogContent, Label, Copy, Input, CopyBtn }
