import React from 'react'

import { FieldApi } from '@tanstack/react-form'
import { BsaSar } from 'commons/types/DTO/bank/bsa'
import { IconName } from 'ui/components'
import { IconButton } from 'ui/components/Buttons'
import { useConfirmation } from 'ui/hooks'
import { Col, FormRow } from 'ui/themes/globalStyles'

import { Date, IP, Time } from './fields'

type IPAddressItemProps = {
  index: number
  field: FieldApi<BsaSar, 'transactions_info.ip_addresses'>
}

export const IPAddressItem = ({ index, field }: IPAddressItemProps) => {
  const { open: startDeleting, Confirmation } = useConfirmation({
    message: `Are you sure you want to remove this item?`,
    onConfirm: () => field.removeValue(index),
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  return (
    <>
      <Confirmation />
      <FormRow>
        <Col>
          <IP index={index} />
        </Col>
      </FormRow>
      <FormRow>
        <Col>
          <Date index={index} />
        </Col>
      </FormRow>
      <FormRow>
        <Col>
          <Time index={index} />
        </Col>
      </FormRow>
      {field.state.value.length > 1 && (
        <FormRow>
          <IconButton face="negative" icon={IconName.DELETE} onClick={startDeleting} />
        </FormRow>
      )}
    </>
  )
}
