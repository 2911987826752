import React, { FC } from 'react'

import { Spinner, SpinnerSize } from 'ui/components/Spinner'

import { BPLayoutProps } from './BPLayoutProps'
import { AccountMenu } from './components/AccountMenu'
import { Page } from './components/Page'
import { useBPLayout } from './hooks'

export const BPLayout: FC<BPLayoutProps> = ({ children, sidebar }) => {
  const { layout, barMinimized, changeBarMinimized, menuItems, sideBar } = useBPLayout(sidebar)

  if (!layout) {
    return <Spinner centered size={SpinnerSize.M} />
  }

  const {
    header: { name, accountMenuItems }
  } = layout

  return (
    <Page
      headerChildren={
        <AccountMenu userName={name} canAccessAccountSettings={accountMenuItems.indexOf('settings') != -1} />
      }
      barMinimized={barMinimized}
      onToggleBar={changeBarMinimized}
      menuItems={menuItems}
      menuSecondaryMenuPane={sideBar}
    >
      {children}
    </Page>
  )
}
