import React from 'react'

import { FF, TextInput } from 'ui/components'

import { useSARFormContext } from '../../../../../../../../context'

export const Time = ({ index }: { index: number }) => {
  const { formApi } = useSARFormContext()
  const type = formApi.useStore((s) => s.values.transactions_info.cyber_event_indicators[index].type)

  return (
    <formApi.Field
      name={
        `transactions_info.cyber_event_indicators[${index}].time` as 'transactions_info.cyber_event_indicators[0].time'
      }
      children={(field) => (
        <FF
          label="Timestamp (UTC)"
          field={field}
          input={(p) => (
            <TextInput {...p} disabled={!['Command and control IP address', 'Suspicious IP address'].includes(type)} />
          )}
        />
      )}
    />
  )
}
