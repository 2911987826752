import React, { memo } from 'react'

import { BsaSar } from 'commons/types/DTO/bank/bsa'
import { CheckBox } from 'ui/components/CheckBox'
import { formatDateTime } from 'ui/components/DateTime'
import { IconSize } from 'ui/components/Icon'
import { Info } from 'ui/components/Info'
import { PageSection, PageSectionFace } from 'ui/components/Page'
import { Panel, PanelFace } from 'ui/components/Panel'
import { TextMask } from 'ui/components/TextMask'
import { Col, Col2x, Row } from 'ui/themes/globalStyles'

import * as SC from './styles'

interface Props {
  item: BsaSar
}

export const SubjectInformation = memo(({ item }: Props) => (
  <PageSection face={PageSectionFace.THIRD} title="Step 3. Subject Information" anchor="step3">
    {item.subjects_info?.map((info: any, index) => {
      const idInfo = info.id_info[0]
      return (
        <Panel
          rounded
          shadowed
          key={index}
          collapsible={false}
          title={`Part I Subject Information ${index + 1} of ${item.subjects_info?.length}`}
          face={PanelFace.PRIMARY}
        >
          <SC.InnerPanel>
            <Row>
              <SC.CheckOrRadio>
                <CheckBox value={info.is_entity || false} checkBoxLabel="If entity" />
                <CheckBox
                  value={info.is_all_info_unavailable || false}
                  checkBoxLabel="if all critical* subject information is unavailable"
                />
              </SC.CheckOrRadio>
            </Row>

            <Row>
              <Col>
                <Info label="4. Individual's last name or entity's legal name">
                  {info.is_entity ? info.entity_name : info.last_name}
                </Info>
              </Col>
              <Col>
                <Info label="9. Alternate name">{info.alternate_names[0]?.value}</Info>
              </Col>
            </Row>

            <Row>
              <Col>
                <Info label="5. First name">{info.first_name}</Info>
              </Col>
              <Col>
                <Info label="6. Middle name">{info.middle_name}</Info>
              </Col>
            </Row>

            <Row>
              <Col>
                <Info label="7. Suffix">{info.suffix}</Info>
              </Col>
              <Col>
                <Info label="8. Gender">{info.gender}</Info>
              </Col>
            </Row>

            <Row>
              <Col>
                <Info label="19. Date of birth">{info.birthdate}</Info>
              </Col>
            </Row>

            <Row>
              <Col>
                <Info label="10. Occupation or type of business">{info.business_type}</Info>
              </Col>
              <Col>
                <Info label="10a. NAICS Code">{info.naics_code}</Info>
              </Col>
            </Row>
            <Row>
              <Col>
                <Info label="16. TIN">{info.tin ? <TextMask text={info.tin} iconSize={IconSize.XS} /> : null}</Info>
              </Col>
              <Col>
                <Info label="17. TIN type">{info.tin_type}</Info>
              </Col>
            </Row>
            <Row>
              <Col>
                <Info label="21. Phone number">{info.phone_numbers[0]?.phone}</Info>
              </Col>
              <Col>
                <Info label="Ext.">{info.phone_numbers[0]?.phone_ext}</Info>
              </Col>
            </Row>
            <Row>
              <Info label="20. Type">{info.phone_numbers[0]?.phone_type}</Info>
            </Row>
            <Row>
              <Col>
                <Info label="22. E-mail address">{info.emails[0]?.value}</Info>
              </Col>
              <Col>
                <Info label="22a. Website (URL) address">{info.websites[0]?.value}</Info>
              </Col>
            </Row>
            <Row>
              <Col>
                <Info label="23. Corroborative statement to filer?">{info.corroborative_statement_to_filer}</Info>
              </Col>
              <Col>
                <Info label="28. Subject's role in suspicious activity">{info.role_in_activity}</Info>
              </Col>
            </Row>
            <Row>
              <Info label="11-14. Subject Address Information">
                <div>{info.address_info[0]?.address}</div>
                <div>
                  {[
                    info.address_info[0]?.city,
                    info.address_info[0]?.country === 'US' ? info.address_info[0]?.state : null,
                    info.address_info[0]?.postal_code
                  ]
                    .filter(Boolean)
                    .join(', ') || '-'}
                </div>
                <div>{info.address_info[0]?.country}</div>
              </Info>
            </Row>
            <Row>
              <Info label="18. Form of identification for subject">
                <div>Country {idInfo?.id_country || '-'}</div>
                <div>
                  Form {idInfo?.id_form || '-'} {idInfo?.id_form?.length > 0 ? idInfo?.id_form_other || '-' : ''}
                </div>
                <div>Number {idInfo?.id_number || '-'}</div>
                <div>State {idInfo?.id_state || '-'}</div>
              </Info>
            </Row>
            <Row>
              <Col2x>
                <Info label="24. Relationship of the subject to an institution listed in Part III or IV">
                  <SC.Label>
                    a Institution TIN
                    {info.relationships_info[0]?.tin ? (
                      <SC.TextMaskInline text={info.relationships_info[0]?.tin} iconSize={IconSize.XS} />
                    ) : (
                      '-'
                    )}
                  </SC.Label>
                  <SC.CheckBoxes>
                    <CheckBox value={info.relationships_info[0]?.accountant || false} checkBoxLabel="b Accountant" />
                    <CheckBox value={info.relationships_info[0]?.director || false} checkBoxLabel="h Director" />
                  </SC.CheckBoxes>
                  <SC.CheckBoxes>
                    <CheckBox value={info.relationships_info[0]?.agent || false} checkBoxLabel="c Agent" />
                    <CheckBox value={info.relationships_info[0]?.employee || false} checkBoxLabel="i Employee" />
                  </SC.CheckBoxes>
                  <SC.CheckBoxes>
                    <CheckBox value={info.relationships_info[0]?.appraiser || false} checkBoxLabel="d Appraiser" />
                    <CheckBox
                      value={info.relationships_info[0]?.no_relationship || false}
                      checkBoxLabel="j No relationship to institution"
                    />
                  </SC.CheckBoxes>
                  <SC.CheckBoxes>
                    <CheckBox value={info.relationships_info[0]?.attorney || false} checkBoxLabel="e Attorney" />
                    <CheckBox value={info.relationships_info[0]?.officer || false} checkBoxLabel="k Officer" />
                  </SC.CheckBoxes>
                  <SC.CheckBoxes>
                    <CheckBox value={info.relationships_info[0]?.borrower || false} checkBoxLabel="f Borrower" />
                    <CheckBox
                      value={info.relationships_info[0]?.owner || false}
                      checkBoxLabel="l Owner or Controlling Shareholder"
                    />
                  </SC.CheckBoxes>
                  <SC.CheckBoxes>
                    <CheckBox value={info.relationships_info[0]?.customer || false} checkBoxLabel="g Customer" />
                    <CheckBox value={info.relationships_info[0]?.other || false} checkBoxLabel="z Other" />
                  </SC.CheckBoxes>
                  <SC.CheckBoxes>
                    <div />
                    <SC.Label>{info.relationships_info[0]?.other_desc}</SC.Label>
                  </SC.CheckBoxes>
                </Info>
              </Col2x>
            </Row>
            <Row>
              <Col>
                <Info label="25 Status of relationship">{info.relationships_info[0]?.relationship_status}</Info>
              </Col>
              <Col>
                <Info label="26 Action Date">
                  {info.relationships_info[0]?.action_date
                    ? formatDateTime(info.relationships_info[0]?.action_date)
                    : null}
                </Info>
              </Col>
            </Row>
            <Row>
              <Info label="27. Financial Institution TIN and account number(s) affected that are related to subject">
                <div>
                  Institution TIN
                  {info.institution_info[0]?.tin ? (
                    <SC.TextMaskInline text={info.institution_info[0]?.tin} iconSize={IconSize.XS} />
                  ) : (
                    '-'
                  )}
                </div>
                {(info.institution_info[0]?.account_numbers || []).map((v: any, i: number) => (
                  <div key={i}>{v.number && <TextMask text={v.number} isBankAccount iconSize={IconSize.XS} />}</div>
                ))}
              </Info>
            </Row>
          </SC.InnerPanel>
        </Panel>
      )
    })}
  </PageSection>
))
