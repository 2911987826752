import React from 'react'

import { Request } from 'commons/utils/request'
import SC from 'styled-components'
import { Dialog, DialogFace } from 'ui/components/Dialog'
import { DownloadLink } from 'ui/components/DownloadLink'
import { Icon, IconName, IconSize } from 'ui/components/Icon'
import { Spinner, SpinnerSize } from 'ui/components/Spinner'
import { Toast } from 'ui/components/Toast'

import { Batch } from '../types'

interface Props {
  batch: Batch | null
  onClose: () => void
  isGenerating: boolean
}

//region Styles

const Title = SC.span`
  display: flex;
  align-items: center;
  
  > span {
    display: inline-block;
    margin-left: 10px;
  }
`

const Content = SC.div`
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`

//endregion

export const DownloadXMLDialog = ({ batch, onClose, isGenerating }: Props) => {
  if (!isGenerating && batch === null) {
    return null
  }

  const batchSuccessfullyGenerated = !isGenerating && batch !== null

  const handleError = (message: string) => Toast.error('Error', message)
  const baseUrl = Request.urls.bank.batchFileDownload
  return (
    <Dialog
      face={DialogFace.DEFAULT}
      id="download-xml-dialog"
      onClose={onClose}
      title={
        <Title>
          {isGenerating && (
            <>
              <Icon name={IconName.INFO} size={IconSize.S} /> <span>Batch is generating...</span>
            </>
          )}
          {batchSuccessfullyGenerated && (
            <>
              <Icon name={IconName.INFO_GREEN} /> <span style={{ color: '#46d5b3' }}>Batch successfully generated</span>
            </>
          )}
        </Title>
      }
    >
      <Content>
        {isGenerating ? (
          <Spinner size={SpinnerSize.M} />
        ) : batchSuccessfullyGenerated ? (
          <DownloadLink
            direct
            parametersForm={{ id: batch?.id, file_type: 'xml' }}
            baseUrl={baseUrl}
            name={batch?.name}
            handleError={handleError}
          >
            {batch?.name}
          </DownloadLink>
        ) : null}
      </Content>
    </Dialog>
  )
}
