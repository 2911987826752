import styled from 'styled-components'
import { Hint } from 'ui/components/Hint'

export const ToolTipBox = styled.div`
  display: flex;
`
export const TooltipTriggerButton = styled.button`
  margin-left: 10px;
  padding: 1px;
  border: none;
  background: none;
  cursor: pointer;

  &:focus {
    outline: 4px solid #7b7b7b;
  }
`

export const DescriptionHint = styled(Hint)`
  margin-bottom: 30px;
  max-width: 970px;
`
