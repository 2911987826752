import React, { FC, memo } from 'react'

import { CheckBox } from 'ui/components'
import { Info, InfoSet } from 'ui/components/Info'

import {
  amountDataSource,
  frequencyDataSource,
  getAnticipatedMonthlyActivityList
} from '../../forms/AnticipatedMonthlyActivityForm/helpers'
import * as SC from '../../forms/AnticipatedMonthlyActivityForm/styles'
import { AnticipatedMonthlyActivityDetailsProps } from './AnticipatedMonthlyActivityDetailsProps'

export const AnticipatedMonthlyActivityDetails: FC<AnticipatedMonthlyActivityDetailsProps> = memo(
  ({ value, state: { isAnticipatedActivityWiresInternationalCountriesEnable, conditions } }) => {
    const anticipatedMonthlyActivityList = getAnticipatedMonthlyActivityList(conditions)

    // TODO: https://helioscompliance.atlassian.net/browse/MP-7205
    const filteredAnticipatedMonthlyActivityList = anticipatedMonthlyActivityList.filter((el) => value[el.active])

    return (
      <InfoSet legend="Anticipated Monthly Activity">
        <SC.Table>
          <thead>
            <tr>
              <SC.TableTitle>Select all that apply</SC.TableTitle>
              <SC.TableTitle>Dollar Amount</SC.TableTitle>
              <SC.TableTitle>Frequency</SC.TableTitle>
            </tr>
          </thead>
          <tbody>
            {filteredAnticipatedMonthlyActivityList.length > 0 ? (
              <>
                {filteredAnticipatedMonthlyActivityList.map(({ label, active, amount, frequency }) => (
                  <SC.TableRowReadMode key={label}>
                    <td>
                      <CheckBox value={value[active]} readOnly checkBoxLabel={label} />
                    </td>
                    <td>{amountDataSource.find((el) => el.value === value[amount])?.label || '---'}</td>
                    <td>{frequencyDataSource.find((el) => el.value === value[frequency])?.label || '---'}</td>
                  </SC.TableRowReadMode>
                ))}
              </>
            ) : (
              <tr>
                <td>
                  <em>No selected options</em>
                </td>
                <td></td>
                <td></td>
              </tr>
            )}

            {isAnticipatedActivityWiresInternationalCountriesEnable && (
              <SC.TableRowReadMode>
                <SC.TableInfo>If foreign, what countries:</SC.TableInfo>
                <SC.TableInfo colSpan={2}>
                  <Info>{value.anticipatedActivityWiresInternationalCountries}</Info>
                </SC.TableInfo>
              </SC.TableRowReadMode>
            )}
          </tbody>
        </SC.Table>
      </InfoSet>
    )
  }
)
