import React from 'react'

import { FF, TextInput, Validators } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

export const FilerName = () => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name="institution_contact_info.filer_name"
      validators={{
        onChange: ({ value }) => Validators.required.field(value)
      }}
      children={(field) => (
        <FF
          required
          label="76. Filer Name"
          field={field}
          input={(p) => <TextInput {...p} maxLength={50} data-qa="76. Filer Name" />}
        />
      )}
    />
  )
}
