import React from 'react'

import { Validators } from 'ui/components/Form'
import { TextareaInput } from 'ui/components/InputV2'
import { Select } from 'ui/components/Select'
import { Col, Col2x, FormRow } from 'ui/themes/globalStyles'

import { SecondText } from '../../style'
import { ReviewAllCheckDebitsForPaymentsWasThereProps } from './ReviewAllCheckDebitsForPaymentsWasThereProps'

export const ReviewAllCheckDebitsForPaymentsWasThere = ({
  formValue,
  Field
}: ReviewAllCheckDebitsForPaymentsWasThereProps) => (
  <>
    <SecondText>Review all check debits for out-of-state or unknown merchant/vendor activity.</SecondText>
    <FormRow>
      <Col>
        <Field
          required
          name="reviewAllCheckDebitsForPaymentsWasThere"
          label="Were there out-of-state check debits or unknown merchant/vendor activity?"
          input={Select}
          inputProps={{
            data: [
              {
                label: 'Yes – See comments',
                value: 'yes'
              },
              {
                label: 'All Transactions Ok',
                value: 'all_transactions'
              },
              {
                label: 'No Transactions of This Type',
                value: 'no_transactions'
              }
            ]
          }}
          validator={Validators.required.field}
        />
      </Col>
      <Col>
        <Field
          required
          name="reviewAllCheckDebitsForPaymentsIfNoted"
          label="If noted, how was the activity addressed?"
          input={Select}
          inputProps={{
            data: [
              {
                label: 'N/A',
                value: 'n/a'
              },
              {
                label: 'Transaction cleared with communication to customer during review',
                value: 'cleared'
              },
              {
                label: 'Transaction added to annual questions',
                value: 'added'
              }
            ]
          }}
          validator={Validators.required.field}
        />
      </Col>
    </FormRow>
    <FormRow>
      <Col2x>
        <Field
          required={formValue.reviewAllCheckDebitsForPaymentsWasThere === 'yes'}
          name="reviewAllCheckDebitsForPaymentsComments"
          label="Comments"
          input={TextareaInput}
          inputProps={{}}
          validator={
            formValue.reviewAllCheckDebitsForPaymentsWasThere === 'yes' ? Validators.required.field : undefined
          }
        />
      </Col2x>
    </FormRow>
  </>
)
