import React from 'react'

import { FF, TextInput } from 'ui/components'

import { useSARFormContext } from '../../../../../../../../context'
import { useSubjectInformationItemContext } from '../../../SubjectInformationItemContext'

type SuffixProps = {
  index: number
}

export const Suffix = ({ index }: SuffixProps) => {
  const { formApi } = useSARFormContext()
  const { isAllInfoUnavailable } = useSubjectInformationItemContext()

  return (
    <formApi.Field
      name={`subjects_info[${index}].suffix` as 'subjects_info[0].suffix'}
      children={(field) => (
        <FF field={field} label="7. Suffix" input={(p) => <TextInput {...p} disabled={isAllInfoUnavailable} />} />
      )}
    />
  )
}
