import React from 'react'

import { AnnualReviewSalesDeposits, LicenseDeprecated } from 'commons/types/DTO/bank'
import { TableDataQa } from 'commons/types/enums'
import { QueryTable } from 'ui/components/QueryTable'

import { columns } from './columns'
import { dataSource } from './dataSource'
import { selector } from './selector'

interface AnnualReviewLicensingTableProps {
  companyId: number
  salesAndDeposits: AnnualReviewSalesDeposits[]
  onReturnData?: (data: LicenseDeprecated[]) => void
}

export const AnnualReviewLicensingTable = ({
  salesAndDeposits,
  companyId,
  onReturnData
}: AnnualReviewLicensingTableProps): JSX.Element => (
  <QueryTable
    name="annual-review-licensing-table"
    dataQa={TableDataQa.BP_ANNUAL_REVIEW_LICENSING}
    columns={columns()}
    dataSourceSelector={selector(salesAndDeposits)}
    dataSources={dataSource(companyId)}
    layerProperties={{ rounded: true, shadowed: true, stretch: true }}
    onReturnData={(data) => {
      data.data['license_list'] && onReturnData && onReturnData(data.data.license_list.records)
    }}
  />
)
