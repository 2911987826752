import styled from 'styled-components'
import { CheckBox } from 'ui/components/CheckBox'

export const Wrapper = styled.div`
  position: relative;
`

export const CheckBoxStyled = styled(CheckBox)`
  position: absolute;
  left: 100%;
  top: 10px;
  margin-left: 20px;
`
