import React from 'react'

import { Page, PageFace } from 'ui/components'
import { Spinner, SpinnerSize } from 'ui/components/Spinner'

import { Footer, Form } from './components'
import { useCreate } from './useCreate'

const Create = () => {
  const { cancelManage, data, formApi, isFetching, isLoading, isSuccess, SaveConfirmation, submitForm } = useCreate()

  if (isFetching && !data) {
    return <Spinner centered size={SpinnerSize.M} />
  }

  if (!data) {
    return null
  }

  const { companyName, licenseOptions } = data

  return (
    <Page
      face={PageFace.SECONDARY}
      subTitle="New Bank Account"
      isPending={isFetching}
      footer={
        <formApi.Subscribe
          children={({ isSubmitting }) => (
            <Footer onCancel={cancelManage} onSubmit={submitForm} loading={isSubmitting} />
          )}
        />
      }
    >
      <SaveConfirmation />
      <Form
        licenses={licenseOptions}
        companyName={companyName}
        isSubmitting={isLoading}
        isSuccess={isSuccess}
        formApi={formApi}
      />
    </Page>
  )
}

export default Create
