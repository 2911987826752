export type SiteDocuments = {
  street: string
  city: string
  document_id: number
  frequency: string
  last_notes: string
  last_period_approved: string
  license_number: string
  next_due_date: string
  organization_id: number
  postal_code: string
  relationship_id: number
  relationship_name: string
  state: string
}
