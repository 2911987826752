import { useEffect, useState } from 'react'

import { FormErrorsShape, FormSetValueFunction } from 'brief-form'
import { isEqual } from 'lodash'

import { FormValueErrorRecord } from '../types/forms'

type UseSyncFormDataOpts<FormValue> = {
  formData: FormValueErrorRecord<FormValue, FormErrorsShape<FormValue>>
  set: FormSetValueFunction<FormValue>
}

export const useSyncFormData = <FormValue>({ formData, set }: UseSyncFormDataOpts<FormValue>) => {
  const { formValues, formErrors } = formData
  const [previousFormData, setPreviousFormData] = useState(formData)

  useEffect(() => {
    if (!isEqual(previousFormData, formData)) {
      set({ value: formValues, errors: formErrors })
      setPreviousFormData(formData)
    }
  }, [formValues, formErrors, previousFormData])
}
