import { Keys, useStateManager } from 'state-manager'

import configURL from '../../../public/config.json'
import { Configuration } from './Configuration'
import { loadConfiguration } from './loadConfiguration'

export type UseConfigurationValue = {
  configuration?: Configuration
  isFetching: boolean
}

export const useConfiguration = (): UseConfigurationValue => {
  const sm = useStateManager()

  const { data, isFetching } = sm.use(Keys.Configuration.Item, () => loadConfiguration(configURL.toString()), {
    staleTime: Infinity,
    keepPreviousData: true
  })

  return {
    isFetching,
    configuration: data
      ? {
          environment: data.environment,
          nextCloudPortal: data.nextCloudPortal,
          session: {
            timeoutInMinutes: +data.sessionActivityTimeoutInMinutes
          },
          version: data.version,
          zendesk: {
            widgetKey: data.zendeskWebWidgetKey
          }
        }
      : undefined
  }
}
