import { useCallback } from 'react'
import { useMutation } from 'react-query'

import { localStorageService } from 'commons/service/localStorageService'
import { PortalName } from 'commons/types/enums'
import Cookies from 'js-cookie'
import { Keys, useStateManager } from 'state-manager'

import { logout as apiLogout } from '../../service/user'
import getPublicCookieNameByPortal from '../helpers/getPublicCookieNameByPortal'

/**
 * Callback which is called when logout function completes its execution.
 */
type LogoutCallback = (error?: Error) => void

/**
 * Logout function options.
 */
export type LogoutOptions = {
  returnUrl?: string
  clientOnly?: boolean // omit call api method
}

export type LogoutHandler = (options: LogoutOptions) => Promise<boolean>

/**
 * useLogout hook retutn value.
 */
export type UseLogoutReturnValue = {
  logout: LogoutHandler
}

/**
 * Returns function performing logout. It deletes all auth related state data:
 * MFA, organisation, current user.
 * @param {LogoutCallback} callback - optional success/error callback.
 * @returns {UseLogoutReturnValue} - logount function.
 */
export const useLogoutAuth = ({
  portalName,
  callback
}: {
  portalName: PortalName
  callback?: LogoutCallback
}): UseLogoutReturnValue => {
  const sm = useStateManager()
  const mutation = useMutation(
    async (opts: LogoutOptions): Promise<boolean> => {
      const domain = new URL(window.location.href).hostname
      Cookies.remove(getPublicCookieNameByPortal(portalName), { path: '/api', domain })

      // remove session expiration data
      localStorageService.setSessionExpiresAt()

      if (!opts.clientOnly) {
        await apiLogout({ portalName })
      }
      if (sm.get(Keys.MFA.Item)) {
        sm.remove(Keys.MFA.Item)
      }
      if (sm.get(Keys.Organisation.Current)) {
        sm.remove(Keys.Organisation.Current)
      }
      if (sm.get(Keys.User.Session)) {
        sm.remove(Keys.User.Session)
      }

      return true
    },
    {
      onError: (error: Error) => {
        if (callback) {
          callback(error)
        }
        throw error
      },
      onSuccess: async () => {
        // We don't move this to a callback function because this behavior is actual for all portals using this hook,
        if (callback) {
          callback()
        }
      }
    }
  )

  const logout = useCallback((options) => mutation.mutateAsync(options), [mutation])

  return { logout }
}
