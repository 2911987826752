import { useCallback, useEffect, useState } from 'react'

import { useNavigate } from '@tanstack/react-router'
import { useFormData } from 'brief-form'
import { COMPANIES_PATH } from 'commons/constants/routes'
import { useSaveCompany } from 'commons/hooks/bank/company'
import { Company } from 'commons/types/DTO/bank'
import { CompanyManageFormValue } from 'commons/types/DTO/bank/companyDetails'
import { handlerServerError } from 'errors'
import { Toast } from 'ui/components/Toast'

enum RelationshipPropNames {
  IS_HOLDING = 'isHolding',
  HOLDING_GROUP_COMPANIES_IDS = 'holdingGroupCompaniesIds'
}

export const useManageCompanyForm = (
  initialValue: Company,
  onDelete: (company: { id: number; name: string }) => void
) => {
  const navigate = useNavigate()

  const newInitialValue = new CompanyManageFormValue(initialValue)

  const { config, Field, validate, isDirty } = useFormData<CompanyManageFormValue>({
    initialValue: newInitialValue
  })

  const [isSubmitting, setIsSubmitting] = useState(false)

  const { routine: saveCompany } = useSaveCompany({
    onSuccess: async (_, response) => {
      Toast.successSmall('Company successfully saved')
      navigate({ to: `${COMPANIES_PATH}/${response.id}` })
    },
    onError: async (error) => handlerServerError<CompanyManageFormValue>(error, { form: config }),
    onFinally: async () => setIsSubmitting(false)
  })

  const submitHandler = useCallback(() => {
    if (validate({ updateFields: true }).valid) {
      setIsSubmitting(true)
      return saveCompany({
        ...config.value,
        tags: Array.isArray(config.value.tags) ? config.value.tags?.join(';') : config.value.tags || null
      })
    }
    //this `if`-block necessary to block showing browser confirm()-windows if form hasn't been changed
    if (isDirty) {
      return Promise.resolve(config.value)
    }
    return null
  }, [config.value, validate, isDirty, saveCompany])

  const { value: configValue, onChange: configOnChange, errors: configErrors } = config
  /* MP-5531 6th point 
    OR remove this Holding Company? option completely 
    and keep Subsidiaries select. 
    The idea there is at least one Subsidiary is assigned, 
    then its a Holding company, if 0 is assigned 
    then it’s just a regular relationship (same idea on the view page)
  */
  useEffect(() => {
    // `is_holding` does not need to be changed
    if (
      configValue[RelationshipPropNames.IS_HOLDING] ===
      !!configValue[RelationshipPropNames.HOLDING_GROUP_COMPANIES_IDS]?.length
    ) {
      return
    }
    // set new `is_holding` according `holding_group_companies_ids`
    const newValue = {
      ...configValue,
      [`${RelationshipPropNames.IS_HOLDING}`]: !!configValue[RelationshipPropNames.HOLDING_GROUP_COMPANIES_IDS]?.length
    }
    configOnChange(newValue, configErrors)
  }, [configValue, configOnChange, configErrors])

  const deleteHandler = useCallback(() => onDelete(config.value), [onDelete, config.value])

  return {
    config,
    Field,
    submitHandler,
    deleteHandler,
    isSubmitting,
    isDirty
  }
}
