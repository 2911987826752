import { FormErrorsShape } from 'brief-form'
import { ApiError } from 'commons/types/api'

export const updateFormAfterApiError = <T extends any>(
  value: T,
  formError: FormErrorsShape<T>,
  apiError: ApiError,
  setter: (v: T, e: FormErrorsShape<T>) => void
) => {
  if (!apiError.meta) {
    return
  }
  const prepared = Object.keys(apiError.meta).reduce((p, c) => ({ ...p, [c]: apiError.meta[c][0] }), {})
  setter(value, { ...formError, ...prepared })
}
