import { ATMGeneralInfoFormData } from '../../../types/forms'
import { OnboardingFormState } from '../../../types/state'
import { getSelectedCompany } from './getSelectedCompany'
import { setNewCompanies } from './setNewCompanies'

export const atmGeneralInfoToState = ({
  companyIndex,
  currentState,
  formData
}: {
  companyIndex: number
  currentState: OnboardingFormState
  formData: ATMGeneralInfoFormData
}) => {
  const oldCompany = getSelectedCompany({ companyIndex, currentState })

  // change ref in list
  const newCompaniesFormValues = setNewCompanies({
    companyIndex,
    oldCompanies: currentState.formValue.companiesFormValues,
    newCompany: { ...oldCompany, atmGeneralInfo: { ...formData } }
  })

  return {
    formValue: {
      ...currentState.formValue,
      companiesFormValues: newCompaniesFormValues
    }
  }
}
