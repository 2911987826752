import styled from 'styled-components'
import { CSS } from 'ui/helpers'

import { HintFace } from './HintProps'

export const Hint = styled.div<{ face: HintFace }>`
  ${(p) => CSS.populateFont(p.theme.uiKit.typography.h5.regular)}
  color: ${(p) => p.theme.uiKit.colors.global.grey.grey900};
  padding: 0 0 0 20px;
  margin: 40px 0;
  position: relative;

  &:before {
    content: '';
    width: 4px;
    height: 100%;
    background-color: ${(p) => {
      if (p.face === HintFace.ERROR) {
        return p.theme.uiKit.colors.theme.negative.main
      }

      return p.theme.uiKit.colors.theme.brand.lighter
    }};
    display: block;
    position: absolute;
    left: 0;
    border-radius: 2px;
  }
`
