import styled, { css } from 'styled-components'
import { Required } from 'ui/core/Required'
import { CSS } from 'ui/helpers'

export const RequiredStyled = styled(Required)``

export const Wrapper = styled.div``

export const InputAndRequiredWrapper = styled.span<{ $error?: boolean; $disabled?: boolean }>`
  display: inline-block;
  height: 100%;
  width: 100%;

  position: relative;

  border: 1px solid ${(p) => p.theme.uiKit.colors.global.grey.grey100};
  border-radius: 4px;

  background-color: ${(p) => p.theme.uiKit.colors.global.grey.grey100};

  transition: ${CSS.populateTransition(['border-color', 'background-color'])};

  &:hover,
  &:focus-within {
    border: 1px solid ${(p) => p.theme.uiKit.colors.global.grey.grey500};
  }

  &:focus-within {
    ${RequiredStyled} {
      opacity: 0;
    }
  }

  ${(p) =>
    p.$error
      ? css`
          &,
          &:hover,
          &:focus-within {
            border-color: ${(p) => p.theme.uiKit.colors.theme.negative.main};
            background-color: ${(p) => p.theme.uiKit.colors.theme.negative.transparent.opacity12};
          }
        `
      : null}

  ${(p) =>
    p.$disabled
      ? css`
          cursor: not-allowed;
          &,
          &:hover,
          &:focus-within {
            border-color: ${(p) => p.theme.uiKit.colors.global.grey.grey300};
            background-color: ${(p) => p.theme.uiKit.colors.global.general.main};
            color: ${(p) => p.theme.uiKit.colors.global.grey.grey700};
          }
        `
      : null}
`

export const Textarea = styled.textarea<{ disabled?: boolean }>`
  outline: none;
  border: none;
  background: none;

  width: 100%;
  height: 100%;

  min-height: 112px;
  min-width: 160px;

  padding: 8px 14px 4px 14px;

  resize: vertical;
  vertical-align: top;

  ${(p) => CSS.populateFont(p.theme.uiKit.typography.bodyL.regular)};

  ${(p) =>
    p.disabled
      ? css`
          cursor: not-allowed;
          -webkit-text-fill-color: ${(p) => p.theme.uiKit.colors.global.grey.grey700};
          opacity: 1;
          &,
          &:hover,
          &:focus-within {
            color: ${(p) => p.theme.uiKit.colors.global.grey.grey700};
          }
        `
      : null}
`

export const Counter = styled.span<{ $disabled?: boolean }>`
  position: absolute;
  right: 0;
  top: -25px;
  color: ${(p) => (p.$disabled ? p.theme.uiKit.colors.global.grey.grey700 : p.theme.uiKit.colors.global.grey.grey500)};
  ${(p) => CSS.populateFont(p.theme.uiKit.typography.bodyL.semiBold)};
`
