import { useCallback } from 'react'
import { useMutation } from 'react-query'

import { deleteApplicationDocumentUpload as deleteApplicationDocumentUploadService } from '../service'

type UseDeleteApplicationDocumentUploadReturnValue = {
  deleteApplicationDocumentUpload: (applicationId: number, id: number) => Promise<number>
}

type DocumentUpload = {
  applicationId: number
  id: number
}

export const useDeleteApplicationDocumentUpload = (): UseDeleteApplicationDocumentUploadReturnValue => {
  const mutation = useMutation((document: DocumentUpload) =>
    deleteApplicationDocumentUploadService(document.applicationId, document.id)
  )

  const deleteApplicationDocumentUpload = useCallback(
    (applicationId: number, id: number) => mutation.mutateAsync({ applicationId: applicationId, id: id }),
    [mutation]
  )

  return {
    deleteApplicationDocumentUpload
  }
}
