import React, { FC, memo } from 'react'

import { Form } from 'brief-form'
import { Validators } from 'ui/components'
import { DatePicker } from 'ui/components/DatePicker'
import { InfoSet } from 'ui/components/Info'
import { TextInput, USPhoneInput } from 'ui/components/InputV2'
import { Select } from 'ui/components/Select'
import { Col, FormRow } from 'ui/themes/globalStyles'

import { HiddenBox } from '../../components/HiddenBox'
import { OnboardingFormFieldNames } from '../../enums'
import { mapFilterObjectToValueLabelArray } from '../helpers'
import { LicenseDetailsFormProps } from './LicenseDetailsFormProps'
import { useLicenseDetailsForm } from './useLicenseDetailsForm'

export const LicenseDetailsForm: FC<LicenseDetailsFormProps> = memo(
  ({
    licenseIndex,
    companyIndex,
    value,
    state: { isLicenseMetrcApiKeyEnable },
    availableLicenseTypes,
    availableLicenseSubTypes,
    availableLicensePOSTypes,
    onChange
  }) => {
    const { Field, config } = useLicenseDetailsForm({ companyIndex, licenseIndex, formData: value, onChange })
    const LicenseTypesList = mapFilterObjectToValueLabelArray(availableLicenseTypes)
    const LicenseSubTypesList = mapFilterObjectToValueLabelArray(availableLicenseSubTypes)
    const LicensePOSTypesList = mapFilterObjectToValueLabelArray(availableLicensePOSTypes)

    return (
      <Form config={config}>
        <HiddenBox>
          <Field
            name={OnboardingFormFieldNames.LICENSE_ID}
            label={OnboardingFormFieldNames.LICENSE_ID}
            input={TextInput}
            inputProps={{
              'data-qa': OnboardingFormFieldNames.LICENSE_ID
            }}
          />
        </HiddenBox>
        <InfoSet legend="License Details">
          <FormRow>
            <Col>
              <Field
                name={OnboardingFormFieldNames.LICENSE_NAME}
                label="License Name"
                input={TextInput}
                inputProps={{
                  'data-qa': 'License Name',
                  maxLength: 80
                }}
                required
                validator={Validators.required.field}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <Field
                name={OnboardingFormFieldNames.TYPE}
                input={Select}
                label="License Type"
                inputProps={{
                  'data-qa': 'License Type',
                  'options-data-qa': 'License Type options',
                  data: LicenseTypesList
                }}
                required
                validator={Validators.required.field}
              />
            </Col>
            <Col>
              <Field
                name={OnboardingFormFieldNames.SUBTYPE}
                input={Select}
                label="License Subtype"
                inputProps={{
                  'data-qa': 'License Subtype',
                  'options-data-qa': 'License Subtype options',
                  data: LicenseSubTypesList
                }}
                required
                validator={Validators.required.field}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <Field
                name={OnboardingFormFieldNames.LICENSE_NUMBER}
                label="License Number"
                input={TextInput}
                inputProps={{
                  'data-qa': 'License Number',
                  maxLength: 150
                }}
                required
                validator={Validators.required.field}
              />
            </Col>
            <Col>
              <Field
                name={OnboardingFormFieldNames.POS_TYPE}
                input={Select}
                label="POS Type"
                inputProps={{
                  'data-qa': 'POS Type',
                  'options-data-qa': 'POS Type',
                  data: LicensePOSTypesList
                }}
                required
                validator={Validators.required.field}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <Field
                name={OnboardingFormFieldNames.PHONE}
                label="Phone"
                input={USPhoneInput}
                inputProps={{
                  'data-qa': 'Phone'
                }}
              />
            </Col>

            {config.value.POSType === 'other' && (
              <Col>
                <Field
                  name={OnboardingFormFieldNames.POS_TYPE_OTHER}
                  input={TextInput}
                  label="POS Type Other"
                  inputProps={{
                    'data-qa': 'POS Type Other',
                    maxLength: 150
                  }}
                />
              </Col>
            )}
          </FormRow>
          <FormRow>
            <Col>
              <Field
                name={OnboardingFormFieldNames.ISSUE_DATE}
                label="License Issuance Date"
                input={DatePicker}
                inputProps={{
                  'data-qa': 'License Issuance Date'
                }}
                required
                validator={Validators.required.field}
              />
            </Col>
            <Col>
              <Field
                name={OnboardingFormFieldNames.EXPIRATION_DATE}
                label="License Expiration Date"
                input={DatePicker}
                inputProps={{
                  'data-qa': 'License Expiration Date'
                }}
                required
                validator={Validators.required.field}
              />
            </Col>
          </FormRow>
          {isLicenseMetrcApiKeyEnable && (
            <FormRow>
              <Col>
                <Field
                  name={OnboardingFormFieldNames.LICENSE_METRC_API_KEY}
                  label="METRC API key"
                  input={TextInput}
                  inputProps={{
                    'data-qa': 'METRC API key'
                  }}
                />
              </Col>
            </FormRow>
          )}
        </InfoSet>
      </Form>
    )
  }
)
