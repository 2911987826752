import { UseFormDataReturnType } from 'brief-form'
import { GetClientUserListResponse, GetClientUserListResponseItem } from 'commons/types/contracts/api/bank/clientUser'

import { FilterFormValue } from '../../../../types'

export interface ClientUsersTableProps {
  data?: GetClientUserListResponse
  isFetching: boolean
  filterForm: UseFormDataReturnType<FilterFormValue>
  setEditClientUser: (item: GetClientUserListResponseItem) => void
  setRemovingClientUser: (item: GetClientUserListResponseItem) => void
  sendInviteLink: (id: string) => void
  clearHandler: () => void
}
