import React from 'react'

import { Tag } from 'bank-bsa-reporting/src/components/Tag'
import { BsaBatches } from 'commons/types/DTO/bank/alerts/bsaBatches'
import { BsaBatch } from 'commons/types/DTO/bank/bsa'
import { getBatchFileName } from 'commons/utils'
import { TextButton } from 'ui/components/Buttons'
import { DownloadLink } from 'ui/components/DownloadLink'
import { Link } from 'ui/components/Link'
import { TableColumn } from 'ui/components/Table'
import { Toast } from 'ui/components/Toast'
import { UploadBatchPdfButton } from 'ui/components/UploadBatchPdfButton'

import { LongFilename } from './styles'

type Opts = {
  onShowBatch: (item: BsaBatch) => void
}

const handleError = (message: string) => Toast.error('Error', message)

export const columns = (opts: Opts): TableColumn<BsaBatches>[] => [
  {
    title: 'ID',
    align: 'left',
    render: ({ id }) => (
      <Link to="/bsa/ctr-batch-list/$id" params={{ id }}>
        {id}
      </Link>
    )
  },
  {
    title: 'CTRs Date',
    align: 'center',
    render: ({ CTRsDate }) => <Link to={`/bsa/ctrs?trans_date=${CTRsDate}`}>{CTRsDate}</Link>
  },
  {
    title: 'Status',
    align: 'left',
    render: ({ status }) => (status ? <Tag status={status.toLowerCase() as any} /> : '-')
  },
  {
    title: 'Batch XML',
    align: 'left',
    render: (item) => {
      const fileName = getBatchFileName(item.batchXMLKey)

      if (fileName) {
        return (
          <DownloadLink
            direct
            parametersForm={{ id: item.id, file_type: 'xml' }}
            baseUrl="/bank/v1/bsa/batch-file-download"
            name={fileName}
            handleError={handleError}
          >
            <LongFilename title={fileName}>{fileName}</LongFilename>
          </DownloadLink>
        )
      }
      return null
    }
  },
  {
    title: 'Batch PDF',
    align: 'left',
    render: (item) => {
      const fileName = getBatchFileName(item.batchPDFKey)

      if (fileName) {
        return (
          <DownloadLink
            direct
            parametersForm={{ id: item.id, file_type: 'ctrx' }}
            baseUrl="/bank/v1/bsa/batch-file-download"
            name={fileName}
            handleError={handleError}
          >
            <LongFilename title={fileName}>{fileName}</LongFilename>
          </DownloadLink>
        )
      }
      return <UploadBatchPdfButton batchId={item.id} isAcknowledgments={false} />
    }
  },
  {
    title: 'Acknowledgments PDF',
    align: 'left',
    render: (item) => {
      const fileName = getBatchFileName(item.batchAcknowledgmentsKey)

      if (fileName) {
        return (
          <DownloadLink
            direct
            parametersForm={{ id: item.id, file_type: 'acknowledgments' }}
            baseUrl="/bank/v1/bsa/batch-file-download"
            name={fileName}
            handleError={handleError}
          >
            <LongFilename title={fileName}>{fileName}</LongFilename>
          </DownloadLink>
        )
      }

      return <UploadBatchPdfButton batchId={item.id} isAcknowledgments />
    }
  },
  {
    title: 'CTRs',
    align: 'center',
    render: (item) =>
      item.CTRs && item.CTRs.length ? (
        <TextButton onClick={() => opts.onShowBatch(item)}>{item.CTRs.length}</TextButton>
      ) : null
  },
  {
    title: 'BSA Tracking ID',
    align: 'left',
    key: 'bsaTrackingID'
  }
]
