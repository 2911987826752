import styled from 'styled-components'
import { Icon } from 'ui/components'

export const ErrorIcon = styled(Icon)`
  vertical-align: middle;
  position: relative;
  top: -1px;
  margin-right: 7px;
  svg {
    fill: ${(p) => p.theme.uiKit.colors.theme.negative.main};
    color: ${(p) => p.theme.uiKit.colors.theme.negative.main};
  }
`
