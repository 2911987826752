import styled from 'styled-components'

type BoxProps = {
  $sticky: boolean
}

export const Box = styled.footer<BoxProps>`
  background: #fff;
  height: 66px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 48px;
  border-top: 1px solid ${(p) => p.theme.uiKit.colors.global.grey.grey300};
  ${(p) =>
    p.$sticky &&
    `  position: sticky;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;`};
`
