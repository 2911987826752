import React, { RefObject, memo, useCallback, useEffect } from 'react'

import { Form, useFormData } from 'brief-form'
import { BsaCtrTransactionInfo } from 'commons/types/DTO/bank/bsa'
import SC from 'styled-components'
import { CheckBox, DatePicker, Validators } from 'ui/components'
import { Hr } from 'ui/components/Hr'
import { CurrencyInput, TextInput } from 'ui/components/InputV2'
import { PageSection, PageSectionFace } from 'ui/components/Page'
import { Panel } from 'ui/components/Panel'
import { Col, FormRow } from 'ui/themes/globalStyles'

import { ForeignInOut } from './ForeignInOut'

interface Props {
  value: BsaCtrTransactionInfo
  errors: { [key: string]: string }
  onChange: (value: BsaCtrTransactionInfo, errors: { [key: string]: string }) => void
  validateFunctionRefs: RefObject<any>
  markFormAsDirty: () => void
}

//region Styles

const Inner = SC.div`
  padding: 30px 35px;
`

const ColumnTitle = SC.h4`
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
`

const FieldStub = SC.div`
  width: 1px;
  height: 97px;
`

const WrappedHr = SC(Hr)`
  margin-left: -35px;
  margin-right: -35px;
  margin-bottom: 15px;
`

const CheckBoxWrapper = SC.div<{ withLabel?: boolean }>`
  height: ${(p) => (p.withLabel ? 55 : 35)}px;
`

//endregion

export const AmountAndTypeOfTransactions = memo((props: Props) => {
  const { value, errors, onChange, validateFunctionRefs, markFormAsDirty } = props
  const { isDirty, config, validate, Field } = useFormData<any>({
    initialValue: value,
    initialErrors: errors,
    alwaysSyncWithInitialValueAndErrors: true
  })

  config.value.cash_in_total = getCashInTotal(config.value)
  config.value.cash_out_total = getCashOutTotal(config.value)

  useEffect(() => {
    if (isDirty) {
      markFormAsDirty()
    }
  }, [isDirty])

  useEffect(() => {
    validateFunctionRefs.current.transactions_info = { validate, registeredFields: config.registeredFields.current }
  }, [validate])

  const originalChangeHandler = config.onChange
  config.onChange = useCallback(
    (v, e) => {
      delete e.cash_in_total
      delete e.cash_out_total
      originalChangeHandler(v, e)
      onChange(v, e)
    },
    [originalChangeHandler, onChange, config]
  )

  return (
    <PageSection face={PageSectionFace.THIRD} title="Step 4. Amount and Type of Transaction(s)" anchor="step4">
      <Panel rounded shadowed collapsible={false} title="Edit Amount and Type of Transaction(s)">
        <Form config={config}>
          <Inner>
            <FormRow>
              <Col>
                <Field
                  required
                  name="transaction_date"
                  label="23. Date of transaction"
                  input={DatePicker}
                  validator={Validators.required.field}
                  inputProps={{
                    'data-qa': '23. Date of transaction'
                  }}
                />
              </Col>
            </FormRow>
            <FormRow>
              <Col>
                <CheckBoxWrapper withLabel>
                  <Field
                    name="source_armored"
                    label="24."
                    input={CheckBox}
                    inputProps={{
                      checkBoxLabel: 'Armored car (FI Contract)'
                    }}
                    fieldProps={{
                      errorPosition: 'right'
                    }}
                  />
                </CheckBoxWrapper>
                <CheckBoxWrapper>
                  <Field
                    name="source_atm"
                    input={CheckBox}
                    inputProps={{
                      checkBoxLabel: 'ATM'
                    }}
                    fieldProps={{
                      errorPosition: 'right'
                    }}
                  />
                </CheckBoxWrapper>
                <CheckBoxWrapper>
                  <Field
                    name="source_mail"
                    input={CheckBox}
                    inputProps={{
                      checkBoxLabel: 'Mail deposit or shipment'
                    }}
                    fieldProps={{
                      errorPosition: 'right'
                    }}
                  />
                </CheckBoxWrapper>
                <CheckBoxWrapper>
                  <Field
                    name="source_night"
                    input={CheckBox}
                    inputProps={{
                      checkBoxLabel: 'Night deposit'
                    }}
                    fieldProps={{
                      errorPosition: 'right'
                    }}
                  />
                </CheckBoxWrapper>
                <CheckBoxWrapper>
                  <Field
                    name="source_aggregated"
                    input={CheckBox}
                    inputProps={{
                      checkBoxLabel: 'Aggregated transactions'
                    }}
                    fieldProps={{
                      errorPosition: 'right'
                    }}
                  />
                </CheckBoxWrapper>
                <CheckBoxWrapper>
                  <Field
                    name="source_shared_branching"
                    input={CheckBox}
                    inputProps={{
                      checkBoxLabel: 'Shared branching'
                    }}
                    fieldProps={{
                      errorPosition: 'right'
                    }}
                  />
                </CheckBoxWrapper>
              </Col>
            </FormRow>
            <FormRow>
              <Col>
                <ColumnTitle>25. CASH IN: (in U.S. dollar equivalent)</ColumnTitle>
              </Col>
              <Col>
                <ColumnTitle>27. CASH OUT: (in U.S. dollar equivalent)</ColumnTitle>
              </Col>
            </FormRow>
            <FormRow>
              <Col>
                <Field
                  name="cash_in_a"
                  label="a Deposit(s)"
                  input={CurrencyInput}
                  inputProps={{
                    allowDecimal: false,
                    'data-qa': 'a Deposit(s)'
                  }}
                  validator={Validators.required.notEmpty}
                />
              </Col>
              <Col>
                <Field
                  name="cash_out_a"
                  label="a Withdrawal(s)"
                  input={CurrencyInput}
                  inputProps={{
                    allowDecimal: false,
                    'data-qa': 'a Withdrawal(s)'
                  }}
                  validator={Validators.required.notEmpty}
                />
              </Col>
            </FormRow>
            <FormRow>
              <Col>
                <Field
                  name="cash_in_b"
                  label="b Payment(s)"
                  input={CurrencyInput}
                  inputProps={{
                    allowDecimal: false
                  }}
                  validator={Validators.required.notEmpty}
                />
              </Col>
              <Col>
                <Field
                  name="cash_out_b"
                  label="b Advance(s) on credit (including markers)"
                  input={CurrencyInput}
                  inputProps={{
                    allowDecimal: false
                  }}
                  validator={Validators.required.notEmpty}
                />
              </Col>
            </FormRow>
            <FormRow>
              <Col>
                <Field
                  name="cash_in_c"
                  label="c Currency received for funds transfer(s) out"
                  input={CurrencyInput}
                  inputProps={{
                    allowDecimal: false
                  }}
                  validator={Validators.required.notEmpty}
                />
              </Col>
              <Col>
                <Field
                  name="cash_out_c"
                  label="c Currency paid from funds transfer(s) in"
                  input={CurrencyInput}
                  inputProps={{
                    allowDecimal: false
                  }}
                  validator={Validators.required.notEmpty}
                />
              </Col>
            </FormRow>
            <FormRow>
              <Col>
                <Field
                  name="cash_in_d"
                  label="d Purchase of negotiable instrument(s)"
                  input={CurrencyInput}
                  inputProps={{
                    allowDecimal: false
                  }}
                  validator={Validators.required.notEmpty}
                />
              </Col>
              <Col>
                <Field
                  name="cash_out_d"
                  label="d Negotiable instrument(s) cashed"
                  input={CurrencyInput}
                  inputProps={{
                    allowDecimal: false
                  }}
                  validator={Validators.required.notEmpty}
                />
              </Col>
            </FormRow>
            <FormRow>
              <Col>
                <Field
                  name="cash_in_e"
                  label="e Currency exchange(s)"
                  input={CurrencyInput}
                  inputProps={{
                    allowDecimal: false
                  }}
                  validator={Validators.required.notEmpty}
                />
              </Col>
              <Col>
                <Field
                  name="cash_out_e"
                  label="e Currency exchange(s)"
                  input={CurrencyInput}
                  inputProps={{
                    allowDecimal: false
                  }}
                  validator={Validators.required.notEmpty}
                />
              </Col>
            </FormRow>
            <FormRow>
              <Col>
                <Field
                  name="cash_in_f"
                  label="f Currency to prepaid access"
                  input={CurrencyInput}
                  inputProps={{
                    allowDecimal: false
                  }}
                  validator={Validators.required.notEmpty}
                />
              </Col>
              <Col>
                <Field
                  name="cash_out_f"
                  label="f Currency from prepaid access"
                  input={CurrencyInput}
                  inputProps={{
                    allowDecimal: false
                  }}
                  validator={Validators.required.notEmpty}
                />
              </Col>
            </FormRow>
            <FormRow>
              <Col>
                <Field
                  name="cash_in_g"
                  label="g Purchases of casinos chips, tokens and other gaming instruments"
                  input={CurrencyInput}
                  inputProps={{
                    allowDecimal: false
                  }}
                  validator={Validators.required.notEmpty}
                />
              </Col>
              <Col>
                <Field
                  name="cash_out_g"
                  label="g Redemption(s) of casino chips, tokens, TITO tickets and other gaming instruments"
                  input={CurrencyInput}
                  inputProps={{
                    allowDecimal: false
                  }}
                  validator={Validators.required.notEmpty}
                />
              </Col>
            </FormRow>
            <FormRow>
              <Col>
                <Field
                  name="cash_in_h"
                  label="h Currency wager(s) including money plays"
                  input={CurrencyInput}
                  inputProps={{
                    allowDecimal: false
                  }}
                  validator={Validators.required.notEmpty}
                />
              </Col>
              <Col>
                <Field
                  name="cash_out_h"
                  label="h Payment(s) on wager(s) (including race and OTB or sports pool)"
                  input={CurrencyInput}
                  inputProps={{
                    allowDecimal: false
                  }}
                  validator={Validators.required.notEmpty}
                />
              </Col>
            </FormRow>
            <FormRow>
              <Col>
                <Field
                  name="cash_in_i"
                  label={
                    <span>
                      i Bills inserted into gaming
                      <br />
                      devices
                    </span>
                  }
                  input={CurrencyInput}
                  inputProps={{
                    allowDecimal: false
                  }}
                  validator={Validators.required.notEmpty}
                />
              </Col>
              <Col>
                <Field
                  name="cash_out_i"
                  label="i Travel and complimentary expenses and book gaming incentives"
                  input={CurrencyInput}
                  inputProps={{
                    allowDecimal: false
                  }}
                  validator={Validators.required.notEmpty}
                />
              </Col>
            </FormRow>
            <FormRow>
              <Col>
                <FieldStub />
              </Col>
              <Col>
                <Field
                  name="cash_out_j"
                  label="j Payment for tournament, contest or other promotions"
                  input={CurrencyInput}
                  inputProps={{
                    allowDecimal: false
                  }}
                  validator={Validators.required.notEmpty}
                />
              </Col>
            </FormRow>
            <FormRow>
              <Col>
                <Field
                  name="cash_in_z_type"
                  label="z Other (specify):"
                  required={!!config.value.cash_in_z}
                  validator={() => !!config.value.cash_in_z && Validators.required.field(config.value.cash_in_z_type)}
                  input={TextInput}
                  inputProps={{}}
                />
              </Col>
              <Col>
                <Field
                  name="cash_out_z_type"
                  label="z Other (specify):"
                  required={!!config.value.cash_out_z}
                  validator={() => !!config.value.cash_out_z && Validators.required.field(config.value.cash_out_z_type)}
                  input={TextInput}
                  inputProps={{}}
                />
              </Col>
            </FormRow>
            <FormRow>
              <Col>
                <Field
                  name="cash_in_z"
                  input={CurrencyInput}
                  inputProps={{
                    allowDecimal: false
                  }}
                  validator={Validators.required.notEmpty}
                />
              </Col>
              <Col>
                <Field
                  name="cash_out_z"
                  input={CurrencyInput}
                  inputProps={{
                    allowDecimal: false
                  }}
                  validator={Validators.required.notEmpty}
                />
              </Col>
            </FormRow>
            <WrappedHr thin />
            <FormRow>
              <Col>
                <Field
                  name="cash_in_total"
                  label="Total cash in"
                  input={CurrencyInput}
                  inputProps={{
                    disabled: true,
                    'data-qa': 'Total cash in'
                  }}
                  validator={Validators.required.notEmpty}
                />
              </Col>
              <Col>
                <Field
                  name="cash_out_total"
                  label="Total cash out"
                  input={CurrencyInput}
                  inputProps={{
                    disabled: true,
                    'data-qa': 'Total cash out'
                  }}
                  validator={Validators.required.notEmpty}
                />
              </Col>
            </FormRow>
            <WrappedHr thin />
            <FormRow>
              <Field
                name="foreign_in"
                label="26. Foreign cash in, foreign country (abbreviation)"
                input={ForeignInOut}
                inputProps={{}}
              />
            </FormRow>
            <WrappedHr thin />
            <FormRow>
              <Field
                name="foreign_out"
                label="28. Foreign cash out, foreign country (abbreviation)"
                input={ForeignInOut}
                inputProps={{}}
              />
            </FormRow>
          </Inner>
        </Form>
      </Panel>
    </PageSection>
  )
})

const getCashInTotal = (formValue: BsaCtrTransactionInfo) =>
  (formValue.cash_in_a ? formValue.cash_in_a : 0) +
  (formValue.cash_in_b ? formValue.cash_in_b : 0) +
  (formValue.cash_in_c ? formValue.cash_in_c : 0) +
  (formValue.cash_in_d ? formValue.cash_in_d : 0) +
  (formValue.cash_in_e ? formValue.cash_in_e : 0) +
  (formValue.cash_in_f ? formValue.cash_in_f : 0) +
  (formValue.cash_in_g ? formValue.cash_in_g : 0) +
  (formValue.cash_in_h ? formValue.cash_in_h : 0) +
  (formValue.cash_in_i ? formValue.cash_in_i : 0) +
  (formValue.cash_in_z ? formValue.cash_in_z : 0)

const getCashOutTotal = (formValue: BsaCtrTransactionInfo) =>
  (formValue.cash_out_a ? formValue.cash_out_a : 0) +
  (formValue.cash_out_b ? formValue.cash_out_b : 0) +
  (formValue.cash_out_c ? formValue.cash_out_c : 0) +
  (formValue.cash_out_d ? formValue.cash_out_d : 0) +
  (formValue.cash_out_e ? formValue.cash_out_e : 0) +
  (formValue.cash_out_f ? formValue.cash_out_f : 0) +
  (formValue.cash_out_g ? formValue.cash_out_g : 0) +
  (formValue.cash_out_h ? formValue.cash_out_h : 0) +
  (formValue.cash_out_i ? formValue.cash_out_i : 0) +
  (formValue.cash_out_z ? formValue.cash_out_z : 0) +
  (formValue.cash_out_j ? formValue.cash_out_j : 0)
