import { Request } from 'commons/utils/request'
import { DocumentPeriodDetailsResponse } from '~bank-documents/types/periodDetails'

type Options = { id: number; historyPage: number; historySize: number }

export const getPeriodDetails = ({ id, historyPage = 1, historySize = 10 }: Options) =>
  Request.post<DocumentPeriodDetailsResponse, Options>(Request.urls.bank.documentPeriodDetailsPage, {
    id,
    historyPage,
    historySize
  })
