import React, { FC, memo } from 'react'

import { Form } from 'brief-form'
import { FilterCompanyVendorsWebPage } from 'commons/service/bank/companyDetails'
import { FilterComponentProps } from 'query-table'
import { TextInput } from 'ui/components/InputV2'
import { FilterPanel } from 'ui/components/Table'

import * as SC from './styles'

export const Filter: FC<FilterComponentProps<FilterCompanyVendorsWebPage> & { onClear: () => void }> = memo((props) => {
  const {
    form: { config, isDirty, Field },
    expanded,
    onToggle,
    onClear
  } = props

  return (
    <FilterPanel isDirty={isDirty} onReset={onClear} expanded={expanded} onToggle={onToggle}>
      <SC.FormWrapper>
        <Form config={config}>
          <SC.NameWrapper>
            <Field name="vendorsName" label="Vendor" input={TextInput} inputProps={{}} />
          </SC.NameWrapper>
        </Form>
      </SC.FormWrapper>
    </FilterPanel>
  )
})
