import styled from 'styled-components'

export enum StepDirection {
  LEFT = 'left',
  RIGHT = 'right'
}

export const StepperContainer = styled.div`
  display: flex;
  width: 100%;
  margin-left: 10px;
`

export const StepTitle = styled.div<{ active: boolean }>`
  margin: 10px auto 0;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 11px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.24px;
  text-align: center;
  color: ${({ active }) => (active ? '#000000' : '#b8b8b8')};
`

export const Circle = styled.div<{ active?: boolean; done?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border: solid 2px #e2e2e2;
  background-color: #e2e2e2;
  color: #b8b8b8;
  margin: 0 auto;
  border-radius: 50%;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 16px;
  letter-spacing: 0.4px;
  text-align: center;

  ${(p) =>
    p.active &&
    `
    border: solid 2px ${p.theme.uiKit.colors.theme.positive.main};
    background-color: ${p.theme.uiKit.colors.global.general.main};
  `}

  ${(p) =>
    p.done &&
    `
    border: solid 2px ${p.theme.uiKit.colors.theme.positive.main};
    background-color: ${p.theme.uiKit.colors.theme.positive.main};

    span {
      color: #fff;
    }
  `}
`

export const BankReviewIcon = styled.div<{ done?: boolean }>`
  position: absolute;
  left: 100%;
  bottom: 4px;
  transform: translateX(-50%);
  text-align: center;
  width: 60px;

  div {
    font-size: 10px;
    color: ${(p) => p.theme.uiKit.colors.global.grey.grey500};
  }

  ${(p) =>
    p.done &&
    `
    path {
      fill: ${p.theme.uiKit.colors.theme.brand.lighter ? p.theme.uiKit.colors.theme.brand.lighter : 'inherit'};
    }

    div {
      color: ${p.theme.uiKit.colors.theme.brand.lighter ? p.theme.uiKit.colors.theme.brand.lighter : 'inherit'};
    }
  `}
`

export const StepNumber = styled.span<{ active: boolean }>`
  color: ${(p) => (p.active ? p.theme.uiKit.colors.theme.positive.main : '#fff')};
`

export const StepConteiner = styled.div<{ done?: boolean; active?: boolean }>`
  display: inline-flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  padding: 0 50px;
`

export const StepLine = styled.div<{ direction: StepDirection; active?: boolean; visiblity: boolean }>`
  position: absolute;
  top: 17px;
  height: 1px;
  border-top: 2px ${(p) => (p.active ? 'solid' : 'dashed')} #e2e2e2;

  ${(p) =>
    p.direction === StepDirection.RIGHT
      ? `
    right: 0;
    left: 50%;
    margin-left: 18px;
  `
      : ''}

  ${(p) =>
    p.direction === StepDirection.LEFT
      ? `
    left: 0;
    right: 50%;
    margin-right: 18px;
    `
      : ''}


  ${(p) => (!p.visiblity ? `display: none;` : '')}

  ${(p) => (p.active ? `border-top-color: ${p.theme.uiKit.colors.theme.positive.main};` : '')}
`
export const StepsWrapper = styled.div`
  width: 370px;
  padding-top: 9px;
  padding-bottom: 34px;
  margin-left: -28px;
`
