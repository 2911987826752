import React, { FC, memo } from 'react'

import { Form } from 'brief-form'
import { LeaveConfirmation, Page, PageFace, Panel } from 'ui/components'

import { ContactAddress } from './ContactAddress'
import { ContactDetails } from './ContactDetails'
import { Footer } from './Footer'
import { useContactManage } from './useContactManage'

import * as SC from './styles'

const ContactManage: FC = memo(() => {
  const {
    isMetroPhoenixBank,
    subTitle,
    isContactFetching,
    isContactFormSaving,
    formData,
    DeleteConfirmation,
    returnUrlForCancel,
    onDelete,
    onSubmit
  } = useContactManage()

  const { config, isDirty, Field } = formData
  const isPending = isContactFetching || isContactFormSaving
  return (
    <>
      <DeleteConfirmation />
      <LeaveConfirmation preventLeaving={isDirty && !isPending}>
        <Form config={config}>
          <Page
            subTitle={subTitle}
            face={PageFace.SECONDARY}
            isPending={isPending}
            footer={
              <Footer onSubmit={onSubmit} onDelete={onDelete} isSubmitting={isPending} returnUrl={returnUrlForCancel} />
            }
          >
            <Panel title="Contact Information" collapsible={false} rounded shadowed>
              <SC.FormBody>
                <ContactDetails isMetroPhoenixBank={isMetroPhoenixBank} Field={Field} />
                <SC.WrappedHr />
                <ContactAddress Field={Field} />
              </SC.FormBody>
            </Panel>
          </Page>
        </Form>
      </LeaveConfirmation>
    </>
  )
})

export default ContactManage
