import { useQuery } from 'react-query'

import { BsaCtr } from 'commons/types/DTO/bank/bsa'

import * as Service from '../../service'

export const useCTRItem = (id: number): { isFetching: boolean; item: BsaCtr } => {
  const { data, isFetching } = useQuery<any, Error>(
    ['ctrs', id],
    () =>
      id !== 0
        ? Service.CTR.getList({ _options: { filters: [{ field: 'id', type: 'eq', value: id }] } })
        : Promise.resolve({
            records: [
              {
                id: 0,
                attention: '',
                batches_info: [],
                created_at: null,
                custom: true,
                deleted_at: null,
                filed_date: null,
                filing_name: '',
                filing_type: '',
                fincen_warnings: '',
                institution_contact_info: {
                  address: '',
                  city: '',
                  contact_office: '',
                  country: '',
                  dba: '',
                  ein: '',
                  federal_regulator: '',
                  id_number: '',
                  id_type: '',
                  legal_name: '',
                  phone: '',
                  phone_ext: '',
                  state: '',
                  type: '',
                  type_other: '',
                  zip_code: ''
                },
                name_desc: '',
                notes: '',
                organization_id: 0,
                persons_info: [
                  {
                    cifSubjectId: 0,
                    account_numbers_in: [],
                    account_numbers_out: [],
                    address: null,
                    alternate_name: '',
                    birthdate: null,
                    business_type: '',
                    cash_in: 0,
                    cash_out: 0,
                    city: null,
                    country: null,
                    email: '',
                    entity_name: null,
                    first_name: null,
                    gender: '',
                    id_country: '',
                    id_form: '',
                    id_form_other: '',
                    id_number: '',
                    id_state: '',
                    is_entity: false,
                    last_name: null,
                    middle_name: '',
                    multiple_transactions: false,
                    naics_code: '',
                    phone: '',
                    phone_ext: '',
                    postal_code: null,
                    state: null,
                    suffix: '',
                    tin: '',
                    tin_type: '',
                    type: ''
                  }
                ],
                prior_report_bsa_id: '',
                status: 'new',
                trans_date: null,
                transaction_info: {
                  cash_in_a: 0,
                  cash_in_b: 0,
                  cash_in_c: 0,
                  cash_in_d: 0,
                  cash_in_e: 0,
                  cash_in_f: 0,
                  cash_in_g: 0,
                  cash_in_h: 0,
                  cash_in_i: 0,
                  cash_in_total: 0,
                  cash_in_z: 0,
                  cash_in_z_type: '',
                  cash_out_a: 0,
                  cash_out_b: 0,
                  cash_out_c: 0,
                  cash_out_d: 0,
                  cash_out_e: 0,
                  cash_out_f: 0,
                  cash_out_g: 0,
                  cash_out_h: 0,
                  cash_out_i: 0,
                  cash_out_j: 0,
                  cash_out_total: 0,
                  cash_out_z: 0,
                  cash_out_z_type: '',
                  foreign_in: [],
                  foreign_out: [],
                  source_aggregated: false,
                  source_armored: false,
                  source_atm: false,
                  source_mail: false,
                  source_night: false,
                  source_shared_branching: false,
                  transaction_date: null
                },
                transaction_locations_info: [
                  {
                    address: '',
                    cash_in: 0,
                    cash_out: 0,
                    city: '',
                    country: '',
                    dba: '',
                    ein: null,
                    federal_regulator: '',
                    id_number: '',
                    id_type: '',
                    legal_name: '',
                    state: '',
                    type: '',
                    type_other: '',
                    zip_code: ''
                  }
                ],
                transactions: [],
                updated_at: null
              }
            ]
          }),
    { keepPreviousData: true }
  )

  if (data && data.records?.length && data.records[0].persons_info) {
    for (let i = 0; i < data.records[0].persons_info.length; i++) {
      data.records[0].persons_info[i].cifSubjectId = 0
    }
  }

  return {
    isFetching: isFetching,
    item: data ? data.records[0] : null
  }
}
