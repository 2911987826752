export enum BusinessTypes {
  PRE_LICENSE_MRB = 'pre_license_mrb',
  LICENSED_MRB = 'mrb',
  MRB_RELATED = 'mrb_related',
  TRIBAL_MRB = 'tribal_mrb',
  ANCILLIARY = 'ancillary',
  HEMP = 'hemp',
  INVESTMENT = 'investment',
  MANAGEMENT = 'management',
  CONSUMER_ACCOUNT = 'consumer_account'
}
