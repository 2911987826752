import React, { FC, memo } from 'react'

import { UsPhoneLabel } from 'ui/components'
import { Info, InfoSet } from 'ui/components/Info'
import { Col, Row } from 'ui/themes/globalStyles'

import { RelationshipDetailsProps } from './RelationshipDetailsProps'

export const RelationshipDetails: FC<RelationshipDetailsProps> = memo(({ applicationRelationship }) => (
  <InfoSet legend="Relationship Details">
    <Row>
      <Col>
        <Info label="Company Name">{applicationRelationship.companyName}</Info>
      </Col>
    </Row>
    <Row>
      <Col>
        <Info label="Entity Legal Name">{applicationRelationship.entityLegalName}</Info>
      </Col>
      <Col>
        <Info label="DBA">{applicationRelationship.dba}</Info>
      </Col>
    </Row>
    <Row>
      <Col>
        <Info label="Phone">
          <UsPhoneLabel value={applicationRelationship.companyPhone} />
        </Info>
      </Col>
      <Col>
        <Info label="Website">{applicationRelationship.companyWebsite}</Info>
      </Col>
    </Row>
    <Row>
      <Col>
        <Info label="Description">{applicationRelationship.description}</Info>
      </Col>
    </Row>
  </InfoSet>
))
