import styled from 'styled-components'

const InnerPanel = styled.div`
  padding: 30px 32px;
`

const Row = styled.div`
  display: flex;

  > * {
    width: 400px;

    &:first-child {
      margin-right: 100px;
    }
  }
`

const CheckOrRadio = styled.label`
  display: flex;
  align-items: center;
  padding-bottom: 23px;

  > label {
    margin: 0 7px 0 0;
  }
`

const FlexBox = styled.div`
  display: flex;
  align-items: center;

  > label {
    margin: 0 7px 0 0;
  }
`

const PersonGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  label {
    pointer-events: none;
    cursor: default;
  }
`

export const s = { InnerPanel, Row, CheckOrRadio, FlexBox, PersonGroup }
