import React, { FC } from 'react'

import { ButtonGroup } from 'ui/components/ButtonGroup'

import { ApplicationControlsProps } from './ApplicationControlsProps'
import {
  AdvancePastDocs,
  ApproveAllDocuments,
  ApproveApplication,
  ArchiveReviewFormUpload,
  ComitteeFormUpload,
  ComplianceReviewFormUpload,
  CreditReviewFormUpload,
  FinalReviewFormUpload,
  MartinHoodReviewFormUpload,
  RevertToDocumentRequestPending,
  RevertToRFIPending,
  SendDocumentRequest,
  TimeLine
} from './components'

import * as SC from './styles'

export const ApplicationControls: FC<ApplicationControlsProps & { children: JSX.Element }> = ({
  children,
  application
}) => (
  <SC.Controls>
    <SC.Wrapper>
      <ButtonGroup margin="small">
        {application.enableRevertToRFIPending && <RevertToRFIPending application={application} />}
        {application.enableRevertToDocumentRequestPending && (
          <RevertToDocumentRequestPending application={application} />
        )}
      </ButtonGroup>
      {application.progressTimelineStatus && (
        <>
          <div />
          <TimeLine status={application.progressTimelineStatus} />
          <div />
        </>
      )}
      <ButtonGroup margin="small">
        {application.enableApproveAllDocuments && <ApproveAllDocuments application={application} />}
        {application.enableAdvancePastDocs && <AdvancePastDocs application={application} />}
        {application.enableApproveApplication && <ApproveApplication application={application} />}
        {application.enableComitteeFormUpload && <ComitteeFormUpload application={application} />}
        {application.enableFinalReviewFormUpload && (
          <FinalReviewFormUpload application={application} title={application.formNameFinalReview} />
        )}
        {application.enableArchiveFormUpload && (
          <ArchiveReviewFormUpload application={application} title={application.formNameArchive} />
        )}
        {application.enableMartinHoodReviewFormUpload && <MartinHoodReviewFormUpload application={application} />}
        {application.enableCreditReviewFormUpload && <CreditReviewFormUpload application={application} />}
        {application.enableComplianceReviewFormUpload && <ComplianceReviewFormUpload application={application} />}
        {application.enableSendDocumentRequest && <SendDocumentRequest application={application} />}
        {children}
      </ButtonGroup>
    </SC.Wrapper>
  </SC.Controls>
)
