import React, { FC, memo, useMemo } from 'react'

import { ContactListWebPageResponseItem } from 'commons/types/contracts/api/bank/contactList'
import { TableDataQa } from 'commons/types/enums'
import { DataSourceItem, Layer, Table } from 'ui/components'
import { ButtonGroup } from 'ui/components/ButtonGroup'
import { HeaderButton } from 'ui/components/Buttons'
import { Link } from 'ui/components/Link'
import { Page } from 'ui/components/Page'
import { Search } from 'ui/components/Search'

import { CONTACTS_PATH } from '../../../../constants'
import { ContactListPageProps } from './ContactListPageProps'
import { columns } from './columns'
import { Filter } from './components'

export const ContactListPage: FC<ContactListPageProps> = memo((props) => {
  const {
    webPageData,
    tableState,
    isFetching,
    filterForm,
    onSearch,
    changePagination,
    DeleteConfirmation,
    startContactDelete
  } = props
  const memoizedColumns = useMemo(() => columns(startContactDelete), [startContactDelete])

  const mappedContacts: DataSourceItem<ContactListWebPageResponseItem>[] = useMemo(
    () =>
      webPageData?.items.map((el) => ({
        item: el
      })) || [],
    [webPageData?.items]
  )

  return (
    <Page
      title="Contacts"
      actions={
        <ButtonGroup margin="small">
          <Search onSearch={onSearch} value={filterForm?.config?.value?.searchQuery} placeholder="Search" />
          {webPageData?.enableAddNew && (
            <Link to={`${CONTACTS_PATH}/new`}>
              <HeaderButton size="middle">Add New</HeaderButton>
            </Link>
          )}
        </ButtonGroup>
      }
    >
      <DeleteConfirmation />
      <Layer rounded shadowed stretch>
        <Filter
          form={filterForm}
          expanded={tableState?.expanded}
          onToggle={tableState.onFilterToggle}
          typesFilterValues={webPageData?.types}
          relationshipsFilterValues={[
            ...(webPageData?.relationshipsTypes || []),
            ...(webPageData?.myRelationshipsList || [])
          ]}
        />
        <Table<ContactListWebPageResponseItem>
          dataQa={TableDataQa.BP_CONTACTS_LIST}
          columns={memoizedColumns}
          dataSource={mappedContacts}
          loading={isFetching}
          pagination={{
            page: filterForm.config.value.page,
            pageSize: filterForm.config.value.size,
            onChange: changePagination,
            total: webPageData?.totalCount || 0
          }}
        />
      </Layer>
    </Page>
  )
})
