import { createContext, useContext } from 'react'

type SubjectInformationItemContextValue = {
  isAllInfoUnavailable: boolean
}

export const SubjectInformationItemContext = createContext<SubjectInformationItemContextValue>({
  isAllInfoUnavailable: false
})

export const useSubjectInformationItemContext = () => useContext(SubjectInformationItemContext)
