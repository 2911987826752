import { createRoute, redirect } from '@tanstack/react-router'
import { LICENSES_PATH } from 'commons/constants/routes'

import { BankLicenseWrapper } from './BankLicenseWrapper'
import { CreateRoute } from './routes/CreateLicense'
import { EditRoute } from './routes/EditLicense'
import { DetailsRoute } from './routes/LicenseDetails'

export const routeLicensesTree = (rootRoute: BPRootRoute) => {
  const LicensesIndexRoute = createRoute({
    beforeLoad: async ({ context }) => {
      if (!context.accessibleRoutingFlags.licenseRootAccess) {
        throw redirect({
          to: '/'
        })
      }
    },
    getParentRoute: () => rootRoute,
    path: LICENSES_PATH,
    component: BankLicenseWrapper
  })

  return LicensesIndexRoute.addChildren([
    DetailsRoute<typeof LicensesIndexRoute>(LicensesIndexRoute),
    CreateRoute<typeof LicensesIndexRoute>(LicensesIndexRoute),
    EditRoute<typeof LicensesIndexRoute>(LicensesIndexRoute)
  ])
}
