import React from 'react'

import { InnerMenu } from 'ui/components/SideMenu'

export const SarBatchSideBar = () => (
  <InnerMenu
    items={[
      { label: 'General Information', hash: 'general' },
      { label: 'SARs in the Batch', hash: 'sars' }
    ]}
  />
)
