import React, { RefObject, memo, useCallback, useEffect } from 'react'

import { BeforeFormChangeHandler, Form, FormChangedHandler, useFormData } from 'brief-form'
import { BsaCtr } from 'commons/types/DTO/bank/bsa'
import { bsaCTRStatuses, filingTypes } from 'commons/types/dictionaries'
import SC from 'styled-components'
import { RadioGroup, Select, Validators } from 'ui/components'
import { IntegerInput, TextInput, TextareaInput } from 'ui/components/InputV2'
import { Panel } from 'ui/components/Panel'
import { Col, Col2x, FormRow } from 'ui/themes/globalStyles'

interface Props {
  value: Partial<BsaCtr>
  errors: { [key: string]: any }
  onChange: (value: any, errors?: any) => any
  validateFunctionRefs: RefObject<any>
  markFormAsDirty: () => void
}

//region Styles

const Inner = SC.div`
  padding: 30px 35px;
`

//endregion

export const GeneralInformationForm = memo((props: Props) => {
  const { value, onChange, errors, validateFunctionRefs, markFormAsDirty, ...rest } = props

  const onBeforeChange: BeforeFormChangeHandler<any> = useCallback(({ value, errors: e }) => {
    const errors = { ...e }

    if (value?.filing_type === 'amend' && !value?.prior_report_bsa_id) {
      errors['prior_report_bsa_id'] = '"Prior report BSA Identifier" is required for "Correct/amend" type of filing.'
    } else {
      if (errors['prior_report_bsa_id']?.startsWith('"Prior report BSA Identifier"')) {
        delete errors['prior_report_bsa_id']
      }
    }

    return { value, errors }
  }, [])

  const onFormChanged: FormChangedHandler<any> = useCallback((value, errors) => onChange(value, errors), [onChange])

  const { isDirty, config, validate, Field } = useFormData<any>({
    onBeforeChange,
    onFormChanged,
    alwaysSyncWithInitialValueAndErrors: true,
    initialValue: value,
    initialErrors: errors
  })

  useEffect(() => {
    if (isDirty) {
      markFormAsDirty()
    }
  }, [isDirty])

  useEffect(() => {
    validateFunctionRefs.current.general = { validate, registeredFields: config.registeredFields.current }
  }, [validate, validateFunctionRefs, config.registeredFields])

  return (
    <Panel rounded shadowed collapsible={false} title="Edit General information" anchor="general" {...rest}>
      <Form config={config}>
        <Inner>
          <FormRow>
            <Col>
              <Field
                required
                name="filing_name"
                label="Filing Name"
                input={TextInput}
                validator={Validators.required.field}
                inputProps={{ 'data-qa': 'Filing Name' }}
              />
            </Col>
            <Col>
              <Field
                name="name_desc"
                label="CTR Name in the List"
                input={TextInput}
                inputProps={{ 'data-qa': 'CTR Name in the List' }}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Field
              required
              name="filing_type"
              label="1. Type of filing"
              input={RadioGroup}
              validator={Validators.required.field}
              inputProps={{
                name: 'filing_type',
                data: filingTypes,
                'data-qa': '1. Type of filing'
              }}
            />
          </FormRow>
          <FormRow>
            <Col>
              <Field
                required={value.filing_type === 'amend'}
                name="prior_report_bsa_id"
                label="Prior report BSA Identifier"
                input={IntegerInput}
                validator={() => value.filing_type === 'amend' && Validators.required.field(value.prior_report_bsa_id)}
                inputProps={{
                  maxLength: 14
                }}
              />
            </Col>
            <Col>
              <Field
                required
                name="status"
                label="Status"
                input={Select}
                validator={Validators.required.field}
                inputProps={{
                  disabled: true,
                  data: bsaCTRStatuses,
                  'data-qa': 'Status'
                }}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col2x>
              <Field
                name="notes"
                label="Notes"
                input={TextareaInput}
                inputProps={{
                  'data-qa': 'Notes'
                }}
              />
            </Col2x>
          </FormRow>
        </Inner>
      </Form>
    </Panel>
  )
})
