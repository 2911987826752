import { AnyRoute, createRoute, lazyRouteComponent } from '@tanstack/react-router'
import { NotFoundError } from 'commons/errors'
import { getDocumentDetails } from '~bank-documents/service/documentDetails'

export const createPeriodRoute = <T extends AnyRoute>(indexRoute: T) =>
  createRoute({
    getParentRoute: () => indexRoute,
    component: lazyRouteComponent(() => import('./PeriodCreate')),
    path: '/new-period',
    loader: async ({ params }) => {
      if ('documentId' in params) return await getDocumentDetails({ id: Number(params?.documentId) })
      else throw new NotFoundError()
    },
    // this function checks if it's not first route appearance
    // (so mutating history via LeaveConfirmation for example)
    // we shouldn`t reload route (including loader-fn)
    shouldReload: (p) => p.cause === 'enter'
  })
