import { ServerOptions } from 'commons/types/api'
import { Request } from 'commons/utils/request'
import { DepositsCreateFormValue, SaveNotesRequest } from '~bank-quarterly-report/types'

/** @deprecated */
export const getList = (opts: ServerOptions) => Request.post(Request.urls.bank.reportMonthlyList, opts)

/** @deprecated */
export const update = (body: any, route: string) => {
  const url = {
    monthly: Request.urls.bank.reportMonthly,
    acknowledgeWarnings: Request.urls.bank.reportMonthlyAcknowledgeWarnings,
    review: Request.urls.bank.reportMonthlyReview,
    saveNotes: Request.urls.bank.reportMonthlySaveNotes
  }[route]
  return Request.put(url, body)
}

export const recalculate = (id: number) => Request.put(Request.urls.bank.reportMonthlyRecalculate, { id })

export const review = (id: number) => Request.put(Request.urls.bank.reportMonthlyReview, { id })

export const saveNotes = ({ id, notes }: SaveNotesRequest) =>
  Request.put(Request.urls.bank.reportMonthlySaveNotes, { id, notes })

export const acknowledgeWarnings = (id: number) =>
  Request.put(Request.urls.bank.reportMonthlyAcknowledgeWarnings, { id })

export const updateDeposits = (body: DepositsCreateFormValue) =>
  Request.post(Request.urls.bank.depositsDataBankSource, body)
