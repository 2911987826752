import React from 'react'

import { DatePicker, FF } from 'ui/components'

import { useSARFormContext } from '../../../../../../../../context'

type ActionDateProps = {
  index: number
}

export const ActionDate = ({ index }: ActionDateProps) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={
        `subjects_info[${index}].relationships_info[0].action_date` as 'subjects_info[0].relationships_info[0].action_date'
      }
      children={(field) => <FF field={field} label="26. Action Date" input={(p) => <DatePicker {...p} />} />}
    />
  )
}
