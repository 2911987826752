import styled from 'styled-components'
import { Hr } from 'ui/components/Hr'
import { PageSection } from 'ui/components/Page'

export const WrappedPageSection = styled(PageSection)`
  margin-bottom: 100px;
`

export const WrappedHr = styled(Hr)`
  margin-bottom: 21px;
`
