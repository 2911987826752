import React, { memo } from 'react'

import { desktop1x, desktopSet, tabletSet } from './sources'

import * as SC from './styles'

export const AuthBg = memo(() => (
  <SC.Picture>
    <source srcSet={tabletSet} media="(max-width: 1024px)" />
    <source srcSet={desktopSet} media="(min-width: 1025px)" />
    <SC.Img src={desktop1x} alt="background" />
  </SC.Picture>
))
