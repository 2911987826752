import React from 'react'

import { CurrencyInput, FF } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

export const CurrentCumulativeAmount = () => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name="transactions_info.current_cumulative_amount"
      children={(field) => (
        <FF field={field} label="31. Cumulative amount" input={(p) => <CurrencyInput {...p} readOnly />} />
      )}
    />
  )
}
