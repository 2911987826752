import React, { FC, useContext } from 'react'

import { BankNotes, BlockDelimiter, WelcomeBlock } from '..'
import { YesNoRadioGroup } from 'commons/types/enums'
import { Hr, Layer, PageSection, PageSectionFace, Panel } from 'ui/components'

import {
  ATMInfoDetails,
  ATMServicesDetails,
  AnticipatedMonthlyActivityDetails,
  AnticipatedMonthlyActivityNADetails,
  AvailableServicesDetails,
  CompanyAccountDetailsDetails,
  CompanyInformationDetails,
  CompanyMailingAddressDetails,
  CompanyPhysicalAddressDetails,
  ContactAdditionalInformationDetails,
  ContactDetails,
  LicenseAddressDetails,
  LicenseDetails,
  MerchantQuestionnaireDetails,
  NotesDetails,
  ProfessionalPartnershipsDetails,
  QuestionnaireDetails,
  RoleInformationDetails
} from '../../details'
import * as SC from '../Common/styles'
import { OnboardingFormContext } from '../OnboardingForm/OnboardingFormContext'

export const OnboardingFormDetails: FC = () => {
  const onboardingContext = useContext(OnboardingFormContext)
  const { state, ui } = onboardingContext
  const { formsState } = state
  const {
    anticipatedMonthlyActivityNA: { isAnticipatedMonthlyActivityNAFormEnable }
  } = formsState
  const { formValue, isCurrentStepEditEnable } = state
  const { isCP } = ui
  const { companiesFormValues } = formValue

  return (
    <>
      {isCP && (
        <>
          <WelcomeBlock />
          <BlockDelimiter text="Contact with the bank" />
          <BankNotes bankNotes={state.bankNotes} readonly={!isCurrentStepEditEnable} />
          <NotesDetails value={formValue.notes.formValues} />
        </>
      )}

      <BlockDelimiter text="COMPANY DETAILS" />
      {companiesFormValues.map((company, companyIndex) => {
        const selectedCompanyCurrentValue = Number.isInteger(companyIndex)
          ? formValue.companiesFormValues[companyIndex]
          : undefined

        const atmGeneralInfoCurrentFormValues = selectedCompanyCurrentValue?.atmGeneralInfo.formValues
        const showAtms =
          atmGeneralInfoCurrentFormValues?.ATMHave === YesNoRadioGroup.YES &&
          atmGeneralInfoCurrentFormValues?.ATMReplenishingCompany === YesNoRadioGroup.YES

        return (
          <Layer rounded shadowed>
            <Panel title={`Company ${companyIndex + 1}`}>
              <SC.Wrapper>
                <CompanyInformationDetails
                  value={formValue.companiesFormValues[companyIndex].companyInformation.formValues}
                />
                <Hr />
                <CompanyAccountDetailsDetails
                  value={formValue.companiesFormValues[companyIndex].companyAccountDetails.formValues}
                  state={state.formsState.accoutDetails}
                />
                <Hr />
                <CompanyPhysicalAddressDetails
                  value={formValue.companiesFormValues[companyIndex].physicalAddress.formValues}
                />
                <CompanyMailingAddressDetails
                  value={formValue.companiesFormValues[companyIndex].mailingAddress.formValues}
                />
                {selectedCompanyCurrentValue.companyAccountDetails.formValues.businessType === 'Licensed MRB' && (
                  <>
                    <Hr />
                    <br />
                    <h3>License Information</h3>
                    <br />
                    {company.licenses.map((licenseFormData, licenseIndex) => (
                      <PageSection
                        key={licenseIndex}
                        title={`License #${licenseIndex + 1}`}
                        face={PageSectionFace.THIRD}
                      >
                        <LicenseDetails
                          value={licenseFormData.details.formValues}
                          state={state.formsState.licenseDetails}
                        />
                        <Hr />
                        <LicenseAddressDetails value={licenseFormData.addressInformation.formValues} />
                      </PageSection>
                    ))}
                  </>
                )}
                <Hr />
                <br />
                <h3>Contacts</h3>
                <br />
                {company.contacts.map((contactFormData, contactIndex) => {
                  const roleInformationCurrentFormValue =
                    selectedCompanyCurrentValue.contacts[contactIndex].roleInformation.formValues
                  const showAdditionalInfomationForm =
                    roleInformationCurrentFormValue.accountSigner === YesNoRadioGroup.YES ||
                    roleInformationCurrentFormValue.owner === YesNoRadioGroup.YES ||
                    roleInformationCurrentFormValue.debtholder === YesNoRadioGroup.YES

                  return (
                    <PageSection
                      key={contactIndex}
                      title={`Individual/Entity #${contactIndex + 1}`}
                      face={PageSectionFace.THIRD}
                    >
                      <ContactDetails
                        value={contactFormData.details.formValues}
                        state={state.formsState.contactDetails}
                      />
                      <Hr />
                      <RoleInformationDetails
                        value={contactFormData.roleInformation.formValues}
                        state={state.formsState.roleInformation}
                      />
                      <Hr />
                      {showAdditionalInfomationForm && (
                        <ContactAdditionalInformationDetails
                          value={contactFormData.contactAdditionalInformation.formValues}
                          state={{
                            ...state.formsState.contactAdditionalInformation,
                            isDebtholderRole: roleInformationCurrentFormValue.debtholder === YesNoRadioGroup.YES,
                            isOwnerRole: roleInformationCurrentFormValue.owner === YesNoRadioGroup.YES,
                            isAccountSignerRole: roleInformationCurrentFormValue.accountSigner === YesNoRadioGroup.YES
                          }}
                        />
                      )}
                    </PageSection>
                  )
                })}
                <QuestionnaireDetails value={company.questionnaire.formValues} state={state.formsState.questionnaire} />
                <Hr />
                <ProfessionalPartnershipsDetails
                  state={state.formsState.professionalPartnerships}
                  value={company.professionalPartnerships.formValues}
                />
                <Hr />
                <ATMInfoDetails value={company.atmGeneralInfo.formValues} />
                {showAtms && (
                  <>
                    {company.atms.map((atmItem, atmIndex) => (
                      <PageSection key={atmIndex} title={`ATM #${atmIndex + 1}`} face={PageSectionFace.THIRD}>
                        <ATMServicesDetails value={atmItem.atmServices.formValues} />
                      </PageSection>
                    ))}
                  </>
                )}
                <Hr />
                <AvailableServicesDetails
                  state={state.formsState.availableServices}
                  value={company.availableServices.formValues}
                />
                <Hr />
                {isAnticipatedMonthlyActivityNAFormEnable && (
                  <AnticipatedMonthlyActivityNADetails value={company.anticipatedMonthlyActivityNA.formValues} />
                )}
                {!isAnticipatedMonthlyActivityNAFormEnable && (
                  <AnticipatedMonthlyActivityDetails
                    state={state.formsState.anticipatedMonthlyActivity}
                    value={company.anticipatedMonthlyActivity.formValues}
                  />
                )}
                <Hr />
                <MerchantQuestionnaireDetails
                  value={company.companyMerchantQuestionnaire.formValues}
                  state={state.formsState.companyMerchantQuestionnaire}
                />
              </SC.Wrapper>
            </Panel>
          </Layer>
        )
      })}
    </>
  )
}
