import { useQuery } from 'react-query'

import { AnnualReviewSalesDeposits } from 'commons/types/DTO/bank'
import { ApiReadResponse } from 'commons/types/api'
import { annualReviewSalesDeposits } from '~bank-annual-review/service'

interface AnnualReviewSalesDepositsProp {
  id: number
  byId?: boolean
}

export const useAnnualReviewSalesDeposits = (
  props: AnnualReviewSalesDepositsProp,
  suppressFetch?: boolean
): { isFetching: boolean; data?: ApiReadResponse<AnnualReviewSalesDeposits> } => {
  const { data, isFetching } = useQuery<any, Error>(
    ['annual-review-sales-deposits'],
    () =>
      suppressFetch
        ? Promise.resolve({ isFetching: false, data: undefined })
        : annualReviewSalesDeposits(props.id, props.byId),
    { keepPreviousData: true }
  )

  return {
    isFetching,
    data
  }
}
