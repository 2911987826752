import { Questionnaire } from 'commons/types/DTO/bank/quarterlyReport/questionnaire'

export const defaultQuestionnaire: Questionnaire = {
  comparePriorAndCurrentComments: '',
  comparePriorAndCurrentPercentageComments: '',
  comparePriorAndCurrentPercentageRedFlag: '',
  comparePriorAndCurrentPercentageVariance: '',
  comparePriorAndCurrentRedFlag: '',
  comparePriorAndCurrentVariance: '',
  currentSARFilingStatus: '',
  dueDiligenceCompletedChangeOfAddress: '',
  dueDiligenceCompletedComments: '',
  dueDiligenceCompletedRedFlag: '',
  followUpItemsNotes: '',
  previousSARFilingStatus: '',
  recordAllACHItemsCompleted: false,
  reviewAllCheckDebitsForPaymentsComments: '',
  reviewAllCheckDebitsForPaymentsIfNoted: '',
  reviewAllCheckDebitsForPaymentsWasThere: '',
  reviewCommentsNotes: '',
  reviewCreditCardActivityForInternationalActivityAddressed: '',
  reviewCreditCardActivityForInternationalComments: '',
  reviewCreditCardActivityForInternationalWasThere: '',
  reviewDebitCardActivityForInternationalActivityAddressed: '',
  reviewDebitCardActivityForInternationalComments: '',
  reviewDebitCardActivityForInternationalWasThere: '',
  reviewDepositLogComments: '',
  reviewDepositLogRedFlag: '',
  reviewDepositLogWhoMakesDeposits: '',
  reviewTheApplicableStateCannabisChangeInBusinessLicensing: '',
  reviewTheApplicableStateCannabisChangeInOwnerLicensing: '',
  reviewTheApplicableStateCannabisComments: '',
  reviewTheApplicableStateCannabisRedFlag: '',
  reviewTheApplicableStateCannabisRedFlagCCD: false,
  reviewTheApplicableStateCannabisRedFlagMED: false,
  reviewTheCashActivityComments: '',
  reviewTheCashActivityRedFlag: '',
  reviewTheCashActivityStructuringDeposits: '',
  reviewTheDepositActivityAddressed: '',
  reviewTheDepositActivityComments: '',
  reviewTheDepositActivityOutState: '',
  reviewTheInternationalACHReportActivityAddressed: '',
  reviewTheInternationalACHReportComments: '',
  reviewTheInternationalACHReportWasThere: '',
  reviewTheTransactionActivityComments: '',
  reviewTheTransactionActivityConsumerAccount: '',
  reviewTheTransactionActivityPurposeOfTransferBA: '',
  reviewTheTransactionActivityPurposeOfTransferCA: '',
  reviewTheTransactionActivityRedFlag: '',
  reviewTransactionActivityComments: '',
  reviewTransactionActivityImmediateDeposits: '',
  reviewTransactionActivityRedFlag: '',
  reviewWireActivityForTransactionsActivityAddressed: '',
  reviewWireActivityForTransactionsComment: '',
  reviewWireActivityForTransactionsWireActivity: '',
  reviewWireAndACHActivity: '',
  reviewWireAndACHComments: '',
  reviewWireAndACHRedFlag: '',
  reviewWireAndACHWireActivity: '',
  seeCustomerCommunicationOrAnnualQuestionsRedFlag: '',
  signedCompletionDate: '',
  signedCompletionName: ''
}
