import React, { FC, memo } from 'react'

import { ComparisonInfo } from 'commons/types/DTO/bank/reportWebPage'
import { formatCurrency } from 'commons/utils'

import * as SC from './styles'

export const SalesToDepositComparisonTable: FC<{ comparisonInfo: ComparisonInfo }> = memo(({ comparisonInfo }) => (
  <SC.WrappedLayerComparisonTable rounded shadowed>
    <SC.Title>Sales To Deposit Comparison</SC.Title>
    <SC.ComparisonTable>
      <tbody>
        <tr>
          <SC.Td>Sales</SC.Td>
          <SC.Td>{formatCurrency(comparisonInfo.sales)}</SC.Td>
        </tr>
        <tr>
          <SC.Td>Estimated tax and non-cannabis sales</SC.Td>
          <SC.Td>{formatCurrency(comparisonInfo.estimatedTaxes)}</SC.Td>
        </tr>
        <tr>
          <SC.Td>Total Sales</SC.Td>
          <SC.Td>{formatCurrency(comparisonInfo.totalSales)}</SC.Td>
        </tr>
        <tr>
          <SC.Td>Total Deposits</SC.Td>
          <SC.Td>{formatCurrency(comparisonInfo.totalDeposits)}</SC.Td>
        </tr>
        <tr>
          <SC.Td>$ Difference</SC.Td>
          <SC.Td>{formatCurrency(comparisonInfo.differenceAbsolute)}</SC.Td>
        </tr>
        <tr>
          <SC.Td>% Difference</SC.Td>
          <SC.Td $alerted={comparisonInfo.differenceRelative.alerted}>
            {comparisonInfo.differenceRelative.percent === -1
              ? 'N/A'
              : `${comparisonInfo.differenceRelative.percent} %`}
          </SC.Td>
        </tr>
      </tbody>
    </SC.ComparisonTable>
  </SC.WrappedLayerComparisonTable>
))
