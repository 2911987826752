import React from 'react'

import { DocumentsIsolated } from 'commons/types/DTO/commons/webpagesOnboardinForm'
import { TableColumn } from 'ui/components'
import { DocumentPeriodStatusChip } from 'ui/components/Labels'
import { Tooltip } from 'ui/components/Tooltip'

import { DocumentFormFiles } from '../DocumentFormFiles'
import { UploadButton } from '../UploadButton'

import * as SC from './styles'

export const columns = (
  readonly: boolean,
  companyName: string,
  isLoading: boolean,
  isDocumentDeleting: boolean,

  onAddFiles: (document: File[], documentId: number) => void,
  onRemoveFile: (fileId: number) => void
): TableColumn<DocumentsIsolated>[] => [
  {
    title: 'Document Name',
    key: 'name',
    align: 'left',
    width: '350px',
    render: (item) => (
      <>
        {item.name} {!item.optional && <b>&nbsp;*</b>}
      </>
    )
  },
  readonly
    ? null
    : {
        title: 'Status',
        key: 'status',
        align: 'center',
        width: '165px',
        render: (item) => <DocumentPeriodStatusChip name={item.status} />
      },
  {
    title: 'Notes',
    key: 'notes',
    align: 'left',
    render: (item) => {
      if (item.notes) {
        return (
          <Tooltip
            id={`${item.id}`}
            type="dark"
            event="hover"
            content={<SC.NotesTooltip>{item.notes.trim()}</SC.NotesTooltip>}
          >
            <SC.Notes>{item.notes.trim()}</SC.Notes>
          </Tooltip>
        )
      }
      return '---'
    }
  },
  {
    title: 'Uploaded files',
    key: 'id',
    align: 'right',
    render: (item) => {
      if (item.files.length) {
        return (
          <DocumentFormFiles
            readonly={readonly}
            files={item.files}
            companyName={companyName}
            documentName={item.name}
            documentId={item.id}
            onFilesAdd={onAddFiles}
            isLoading={isLoading}
            isDeleting={isDocumentDeleting}
            onDelete={onRemoveFile}
          />
        )
      }
      return <SC.ItemFilesEmptyMessage>No uploaded files yet</SC.ItemFilesEmptyMessage>
    }
  },
  readonly
    ? null
    : {
        title: 'Action',
        key: 'id',
        align: 'center',
        render: (item) => <UploadButton documentId={item.id} onFilesAdd={onAddFiles} />
      }
]
