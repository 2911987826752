import { ContactItemFormValue } from '../../../types/forms'
import { CompanyFormValues } from '../../../types/state'

export const setNewContacts = ({
  oldCompany,
  contactIndex,
  newContact
}: {
  oldCompany: CompanyFormValues
  contactIndex: number
  newContact: ContactItemFormValue
}) =>
  oldCompany.contacts.map((contactItem, contactItemIndex) =>
    contactItemIndex === contactIndex ? newContact : contactItem
  )
