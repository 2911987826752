import { NotesFormData } from '../../../types/forms'
import { OnboardingFormState } from '../../../types/state'

export const notesFormToState = ({
  currentState,
  formData
}: {
  currentState: OnboardingFormState
  formData: NotesFormData
}) => ({
  formValue: {
    ...currentState.formValue,
    notes: formData
  }
})
