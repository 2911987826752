import styled from 'styled-components'

export const CLASSNAME = 'input-group'

export const Wrapper = styled.span`
  display: inline-block;
  width: 100%;
`

export const Inner = styled.span`
  display: inline-flex;
  width: 100%;
  .${CLASSNAME} {
    height: 40px;
    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      flex: 1;
    }
    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
`
