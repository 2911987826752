import { Link } from '@tanstack/react-router'
import styled from 'styled-components'

import { Icon } from '../../../Icon'
import { MenuIconHoverFace } from './MenuIconHoverFace'
import { MenuItemAlignment } from './MenuItemAlignment'
import { MenuItemTextFace } from './MenuItemTextFace'

export const WrappedIcon = styled(Icon)`
  margin-right: 8px;
`

export const ButtonItem = styled.span<{
  face: MenuItemTextFace
  align: MenuItemAlignment
  $hoverFace?: MenuIconHoverFace
}>`
  color: ${(p) =>
    p.face === MenuItemTextFace.GREY
      ? p.theme.uiKit.colors.global.grey.grey700
      : p.theme.uiKit.colors.global.general.secondary};
  text-align: ${(p) => (p.align === MenuItemAlignment.LEFT ? 'left' : 'center')};
  white-space: nowrap;
  cursor: pointer;
  display: flex;
  align-items: center;

  font-size: 12px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.2px;
  text-transform: uppercase;

  ${(p) =>
    p.$hoverFace
      ? p.$hoverFace === MenuIconHoverFace.PRIMARY
        ? ` 
        &:hover {
          * {
            color: #00c698;
          }
        }`
        : ` 
      &:hover {
        * {
          color: #FA5252;
        }
      }`
      : ''}
`

export const LinkItem = styled(Link)<{
  face: MenuItemTextFace
  align: MenuItemAlignment
  $hoverFace?: MenuIconHoverFace
}>`
  text-decoration: none !important;
  color: ${(p) => p.theme.uiKit.colors.global.grey.grey700} !important;
  text-align: ${(p) => (p.align === MenuItemAlignment.LEFT ? 'left' : 'center')};
  white-space: nowrap;
  cursor: pointer;
  display: flex;
  align-items: center;

  font-size: 12px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.2px;
  text-transform: uppercase;

  &:hover {
    color: ${(p) =>
      p.face === MenuItemTextFace.BLACK
        ? p.theme.uiKit.colors.global.grey.grey900
        : p.theme.uiKit.colors.global.grey.grey700} !important;
  }

  ${(p) =>
    p.$hoverFace
      ? `
  &:hover {
    i {
      color: ${p.$hoverFace === MenuIconHoverFace.PRIMARY ? '#00c698' : '#FA5252'};
      fill: ${p.$hoverFace === MenuIconHoverFace.PRIMARY ? '#006a51' : '#FA5252'};
    }
  }`
      : ''};
`

export const MenuItem = styled.div<{ align: 'left' | 'center' }>`
  text-align: ${(p) => p.align};
  padding: 7px 15px;

  &:first-child {
    padding-top: 13px;
  }

  &:last-child {
    padding-bottom: 13px;
  }
`
