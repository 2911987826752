import React, { FC } from 'react'

import { FieldProps, FormInputProps } from 'brief-form'
import { Questionnaire } from 'commons/types/DTO/bank/quarterlyReport/questionnaire'
import { CheckBox } from 'ui/components/CheckBox'
import { Col, FormRow } from 'ui/themes/globalStyles'

import { SecondText } from '../../style'

type ContinuedReviewProps = {
  Field: <Input extends React.ComponentType<FormInputProps<any, any>>>(
    props: FieldProps<Input, Questionnaire>
  ) => JSX.Element
}

export const ContinuedReview: FC<ContinuedReviewProps> = ({ Field }) => (
  <>
    <SecondText>
      <i>Continued Review</i>
    </SecondText>
    <FormRow>
      <Col>
        <Field
          name="recordAllACHItemsCompleted"
          label="Record all ACH items & identify 2-3 merchants with highest activity."
          input={CheckBox}
          inputProps={{
            checkBoxLabel: 'Completed'
          }}
        />
      </Col>
    </FormRow>
  </>
)
