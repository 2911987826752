import { notFound } from '@tanstack/react-router'
import { getOnboardingWebPage } from 'commons/service/client/application'

import { OnboardingFormLoaderType } from '../../types'

export const onboardingFormLoader: OnboardingFormLoaderType = async ({ portalName, params }) => {
  const { applicationId } = params

  if (!(applicationId > 0)) {
    throw notFound()
  }

  const applicationData = await getOnboardingWebPage(applicationId, portalName)

  if (!applicationData) {
    throw notFound()
  }

  return { applicationData, applicationId }
}
