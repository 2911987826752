import styled from 'styled-components'

export const FormTitle = styled.div`
  margin-bottom: 52px;
  font-size: 28px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 30px;
  letter-spacing: 0.49px;
  color: ${(p) => p.theme.uiKit.colors.theme.brand.darkest};
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`
