import { Request } from 'commons/utils/request'

import {
  ChangeActiveStatusPayload,
  CreateBankUserRequest,
  GetResetUrlPayload,
  ResetUrlResponse,
  SendResetUrlPayload,
  UpdateBankUserRequest,
  UsersWebPagePayload,
  UsersWebPageResponse
} from './types'

export const api = {
  create: (user?: CreateBankUserRequest) => Request.post<number, CreateBankUserRequest>(Request.urls.bank.user, user),

  update: (user?: UpdateBankUserRequest) => Request.put<void, UpdateBankUserRequest>(Request.urls.bank.user, user),

  changeActiveStatus: (form?: ChangeActiveStatusPayload) =>
    Request.patch<void, ChangeActiveStatusPayload>(Request.urls.bank.userStatus, form),

  getResetUrl: (user?: GetResetUrlPayload) =>
    Request.post<ResetUrlResponse, GetResetUrlPayload>(Request.urls.bank.userPasswordResetUrl, user),

  sendResetUrl: (data?: SendResetUrlPayload) =>
    Request.post<unknown, SendResetUrlPayload>(Request.urls.bank.userAuthPasswordReset, data),

  getListPage: (options?: UsersWebPagePayload) =>
    Request.post<UsersWebPageResponse, UsersWebPagePayload>(Request.urls.bank.bankUserListWebpages, options)
}
