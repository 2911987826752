import { CompanyFormValues, OnboardingFormState } from '../../../types/state'

export const getSelectedCompany = ({
  currentState,
  companyIndex
}: {
  currentState: OnboardingFormState
  companyIndex: number
}): CompanyFormValues => {
  const { companiesFormValues } = currentState.formValue
  const selectedCompany = companiesFormValues[companyIndex]
  return selectedCompany
}
