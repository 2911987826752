import loadScript from 'load-script'
import { Toast } from 'ui/components/Toast'

// used in import { useZendesk } from 'commons/hooks/common'

declare global {
  interface Window {
    zE: {
      activate: () => void
      hide: () => void
    }
  }
}

const zendeskSnippetUrl = `https://static.zdassets.com/ekr/snippet.js`
let loading = false
let loaded = false
let widgetKey: string | undefined

const init = ({ key }: { key: string }) => {
  if (loaded || !key || loading) {
    return
  }
  loading = true
  widgetKey = key
  loadScript(`${zendeskSnippetUrl}?key=${widgetKey}`, { attrs: { id: 'ze-snippet' } }, () => {
    loaded = true
    loading = false
  })
}

const setWidgetPosition = () => {
  setTimeout(() => {
    const zEForm = document.getElementById('webWidget')
    if (!zEForm?.style?.bottom) {
      setWidgetPosition()
      return
    }
    zEForm.style.bottom = '50px'
  }, 17)
}

const activateWidget = () => {
  try {
    window.zE.activate()
    setWidgetPosition()
  } catch (error) {
    Toast.error(`Unable to activate Zendesk Widget: ${error.message}`)
  }
}

const hideWidget = () => {
  try {
    window.zE.hide()
  } catch (error) {
    Toast.error('Unable to hide Zendesk Widget')
  }
}

export const zendeskService = {
  init,
  activateWidget,
  hideWidget
}
