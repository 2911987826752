import React, { useState } from 'react'

import { Info } from 'ui/components'
import { Select } from 'ui/components/Select'
import { FilterPanel } from 'ui/components/Table'

import { FilterProps } from './Filter.types'
import { s } from './style'

/**
 * https://helioscompliance.atlassian.net/browse/MP-8850
 * We should re-factor this and use common solution for managing filter
 * panel state.
 */
export const Filter = (props: FilterProps) => {
  const { active, activeValues, onActiveChange, onReset } = props
  const [isDirty, setDirty] = useState(false)
  const [expanded, setExpanded] = useState(false)

  const activeChangeHandler = (active: string | null) => {
    onActiveChange(active)
    setDirty(true)
  }

  const resetFilter = () => {
    setDirty(false)
    onReset()
  }

  return (
    <FilterPanel isDirty={isDirty} onReset={resetFilter} expanded={expanded} onToggle={() => setExpanded((e) => !e)}>
      <s.Columns>
        <s.Column>
          <Info label="Status">
            <Select data={activeValues} value={active} onChange={activeChangeHandler} />
          </Info>
        </s.Column>
      </s.Columns>
    </FilterPanel>
  )
}
