/**
 * themeBP is default main theme, other themes just rewrite it values.
 *
 * Json themes putted to the uikit subdir coz their file's names crossed with old themes.
 */

/** TODO: coz old themes was removed, move some of definitions in this file to separate files */
import themeBP from './uikit/themes/BP/theme.json'
import './uikit/themes/BP/variables.css'
import bisonAuthLogoSVG from './uikit/themes/Bison/authLogo.svg'
import bisonBg1x from './uikit/themes/Bison/bg1x.jpg'
import bisonBg2x from './uikit/themes/Bison/bg2x.jpg'
import bisonBg3x from './uikit/themes/Bison/bg3x.jpg'
import bisonLogoSVG from './uikit/themes/Bison/logo.svg'

/* Bison theme */
import themeBison from './uikit/themes/Bison/theme.json'
import './uikit/themes/Bison/variables.css'
import themeCBT from './uikit/themes/CBT/theme.json'
import './uikit/themes/CBT/variables.css'
import themeCP from './uikit/themes/CP/theme.json'
import './uikit/themes/CP/variables.css'
import themeHerring from './uikit/themes/Herring/theme.json'
import './uikit/themes/Herring/variables.css'
import themeINB from './uikit/themes/INB/theme.json'
import './uikit/themes/INB/variables.css'
import themeShore from './uikit/themes/Shore/theme.json'
import './uikit/themes/Shore/variables.css'
import themeTransact from './uikit/themes/Transact/theme.json'
import './uikit/themes/Transact/variables.css'
import themeValliance from './uikit/themes/Valliance/theme.json'
import './uikit/themes/Valliance/variables.css'

themeBison.logo._1x = bisonLogoSVG
themeBison.logo._2x = bisonLogoSVG
themeBison.logo._3x = bisonLogoSVG

themeBison.authLogo._1x = bisonAuthLogoSVG
themeBison.authLogo._2x = bisonAuthLogoSVG
themeBison.authLogo._3x = bisonAuthLogoSVG

themeBison.background._1x = bisonBg1x
themeBison.background._2x = bisonBg2x
themeBison.background._3x = bisonBg3x

/* End Bison theme */

export type UIKitTheme = typeof themeBP

export enum Themes {
  BP = 'BP',
  CP = 'CP',
  CBT = 'CBT',
  HERRING = 'Herring',
  INB = 'INB',
  SHORE = 'Shore',
  TRANSACT = 'Transact',
  VALLIANCE = 'Valliance',
  BISON = 'Bison'
}

export type ThemeName = `${Themes}`

export const getUIKitTheme = (name: ThemeName): UIKitTheme => {
  switch (name) {
    case Themes.HERRING:
      return mergeThemes(themeBP, themeHerring)
    case Themes.CBT:
      return mergeThemes(themeBP, themeCBT)
    case Themes.INB:
      return mergeThemes(themeBP, themeINB)
    case Themes.TRANSACT:
      return mergeThemes(themeBP, themeTransact)
    case Themes.VALLIANCE:
      return mergeThemes(themeBP, themeValliance)
    case Themes.SHORE:
      return mergeThemes(themeBP, themeShore)
    case Themes.BISON:
      return mergeThemes(themeBP, themeBison)
    case Themes.CP:
      return mergeThemes(themeBP, themeCP)
    case Themes.BP:
      return themeBP
    default:
      return themeBP
  }
}

const mergeThemes = (baseTheme: UIKitTheme, themeToMerge: object): UIKitTheme => {
  const merged = {
    ...baseTheme,
    ...themeToMerge
  }

  for (const key of Object.keys(merged)) {
    if (typeof merged[key] === 'object' && merged[key] !== null && themeToMerge[key]) {
      merged[key] = mergeThemes(baseTheme[key], themeToMerge[key])
    }
  }

  return merged
}
