import React, { FC, memo } from 'react'

import { COMPANIES_PATH } from 'commons/constants/routes'
import { Button } from 'ui/components/Buttons'
import { Hr } from 'ui/components/Hr'
import { Info, InfoSet, InfoSetDirection } from 'ui/components/Info'
import { Link } from 'ui/components/Link'
import { PageSection, PageSectionFace } from 'ui/components/Page'

import { GeneralInformationProps } from './GeneralInformationProps'
import { AccountInformation } from './components/AccountInformation'
import { AddressInformation } from './components/AddressInformation'
import { RelationShipInformation } from './components/RelationshipInformation'

export const GeneralInformation: FC<GeneralInformationProps> = memo((props) => {
  const {
    id,
    name,
    legalName,
    dba,
    isHolding,
    holding,
    holding_group_companies,
    customerStatus,
    applicationId,
    assignedBankUsers,
    availableOwners,
    onSetCompanyOwners,
    phone,
    website,
    tags,
    description,
    businessType,
    entityType,
    stateFounded,
    dateFounded,
    licenseCount,
    ein,
    eINLabel,
    enableInviteUsersToCP,
    city,
    state,
    street,
    postalCode,
    country,
    mailingCity,
    mailingState,
    mailingStreet,
    mailingPostalCode,
    mailingCountry
  } = props

  return (
    <PageSection
      face={PageSectionFace.SECONDARY}
      title="Company Information"
      actions={
        <Link to={`${COMPANIES_PATH}/edit/${id}`}>
          <Button face="positive">Edit</Button>
        </Link>
      }
      id="general"
    >
      <Hr />
      <RelationShipInformation
        id={id}
        name={name}
        legalName={legalName}
        dba={dba}
        isHolding={isHolding}
        holding={holding}
        holding_group_companies={holding_group_companies}
        customerStatus={customerStatus}
        applicationId={applicationId}
        assignedBankUsers={assignedBankUsers}
        availableOwners={availableOwners}
        onSetCompanyOwners={onSetCompanyOwners}
        phone={phone}
        website={website}
        tags={tags}
      />
      <Hr />
      <InfoSet direction={InfoSetDirection.ROW} legend="Description">
        <Info>{description}</Info>
      </InfoSet>
      <Hr />
      <AccountInformation
        businessType={businessType}
        entityType={entityType}
        stateFounded={stateFounded}
        dateFounded={dateFounded}
        licenseCount={licenseCount}
        ein={ein}
        eINLabel={eINLabel}
        enableInviteUsersToCP={enableInviteUsersToCP}
      />
      <Hr />
      <AddressInformation
        city={city}
        state={state}
        street={street}
        postalCode={postalCode}
        country={country}
        mailingCity={mailingCity}
        mailingState={mailingState}
        mailingStreet={mailingStreet}
        mailingPostalCode={mailingPostalCode}
        mailingCountry={mailingCountry}
      />
    </PageSection>
  )
})
