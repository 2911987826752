import styled from 'styled-components'

const PageContent = styled.div`
  flex: 1;
  position: relative;
  background: #fff;
  border-radius: 31px 0 0 0;
  box-shadow: 5px 0 7px rgba(0, 0, 0, 0.05);
`

export default PageContent
