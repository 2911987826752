import { useCallback } from 'react'

import { FormChangedHandler, useFormData } from 'brief-form'

import { useSyncFormData } from '../../hooks'
import { NotesFormValue } from '../../types'
import { NotesHookFormProps } from './types'

const useSaveNotes = ({ formData, onChange, onSave }: NotesHookFormProps) => {
  const { formValues, formErrors } = formData

  const onFormChanged: FormChangedHandler<NotesFormValue> = useCallback(
    (formValues, formErrors) => {
      onChange({ formValues, formErrors })
    },
    [onChange]
  )

  const { config, Field, set } = useFormData<NotesFormValue>({
    onFormChanged,
    initialValue: formValues,
    initialErrors: formErrors
  })

  useSyncFormData({ set, formData })

  const saveHandler = useCallback(
    () => onSave({ formValues: config.value, formErrors: config.errors }),
    [onSave, config.value, config.errors]
  )

  return { Field, config, saveHandler }
}

export default useSaveNotes
