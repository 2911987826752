import { DataSourceItem } from 'ui/components'
import { CTRItem } from '~bank-bsa-reporting/types'

export const dataSourceSelector = (items: CTRItem[]): DataSourceItem<CTRItem>[] =>
  items.map((item) => ({
    item: {
      id: item.id,
      date: item.date,
      name: item.name,
      cashIn: item.cashIn,
      cashOut: item.cashOut,
      filingType: item.filingType,
      status: item.status,
      lastModifiedAt: item.lastModifiedAt,
      transactions: item.transactions,
      attention: item.attention,
      fincenWarnings: item.fincenWarnings
    }
  }))
