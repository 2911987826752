import { AnnualReviewSalesDeposits } from 'commons/types/DTO/bank/annualReviewSalesDeposits'

import { SalesAndDepositFormValue } from './SalesAndDepositFormValue'

export const generateFormData = (salesAndDeposits?: AnnualReviewSalesDeposits): SalesAndDepositFormValue => ({
  status: salesAndDeposits?.status || 'complete',
  total_financials: salesAndDeposits?.total_financials.toString() || '0',
  'quarter1_info.label': salesAndDeposits?.quarter1_info?.label || '',
  'quarter1_info.deposits': salesAndDeposits?.quarter1_info?.deposits?.toString() || '0',
  'quarter1_info.sales': salesAndDeposits?.quarter1_info?.sales?.toString() || '0',
  'quarter1_info.invoices': salesAndDeposits?.quarter1_info?.invoices?.toString() || '0',
  'quarter1_info.checks': salesAndDeposits?.quarter1_info?.checks?.toString() || '0',
  'quarter1_info.other': salesAndDeposits?.quarter1_info?.other?.toString() || '0',
  'quarter2_info.label': salesAndDeposits?.quarter2_info?.label || '',
  'quarter2_info.deposits': salesAndDeposits?.quarter2_info?.deposits?.toString() || '0',
  'quarter2_info.sales': salesAndDeposits?.quarter2_info?.sales?.toString() || '0',
  'quarter2_info.invoices': salesAndDeposits?.quarter2_info?.invoices?.toString() || '0',
  'quarter2_info.checks': salesAndDeposits?.quarter2_info?.checks?.toString() || '0',
  'quarter2_info.other': salesAndDeposits?.quarter2_info?.other?.toString() || '0',
  'quarter3_info.label': salesAndDeposits?.quarter3_info?.label || '',
  'quarter3_info.deposits': salesAndDeposits?.quarter3_info?.deposits?.toString() || '0',
  'quarter3_info.sales': salesAndDeposits?.quarter3_info?.sales?.toString() || '0',
  'quarter3_info.invoices': salesAndDeposits?.quarter3_info?.invoices?.toString() || '0',
  'quarter3_info.checks': salesAndDeposits?.quarter3_info?.checks?.toString() || '0',
  'quarter3_info.other': salesAndDeposits?.quarter3_info?.other?.toString() || '0',
  'quarter4_info.label': salesAndDeposits?.quarter4_info?.label || '',
  'quarter4_info.deposits': salesAndDeposits?.quarter4_info?.deposits?.toString() || '0',
  'quarter4_info.sales': salesAndDeposits?.quarter4_info?.sales?.toString() || '0',
  'quarter4_info.invoices': salesAndDeposits?.quarter4_info?.invoices?.toString() || '0',
  'quarter4_info.checks': salesAndDeposits?.quarter4_info?.checks?.toString() || '0',
  'quarter4_info.other': salesAndDeposits?.quarter4_info?.other?.toString() || '0',
  'quarter1_info.cash': salesAndDeposits?.quarter1_info?.cash?.toString() || '0',
  'quarter1_info.transfers': salesAndDeposits?.quarter1_info?.transfers?.toString() || '0',
  'quarter1_info.credit_debit': salesAndDeposits?.quarter1_info?.credit_debit?.toString() || '0',
  'quarter2_info.cash': salesAndDeposits?.quarter2_info?.cash?.toString() || '0',
  'quarter2_info.transfers': salesAndDeposits?.quarter2_info?.transfers?.toString() || '0',
  'quarter2_info.credit_debit': salesAndDeposits?.quarter2_info?.credit_debit?.toString() || '0',
  'quarter3_info.cash': salesAndDeposits?.quarter3_info?.cash?.toString() || '0',
  'quarter3_info.transfers': salesAndDeposits?.quarter3_info?.transfers?.toString() || '0',
  'quarter3_info.credit_debit': salesAndDeposits?.quarter3_info?.credit_debit?.toString() || '0',
  'quarter4_info.cash': salesAndDeposits?.quarter4_info?.cash?.toString() || '0',
  'quarter4_info.transfers': salesAndDeposits?.quarter4_info?.transfers?.toString() || '0',
  'quarter4_info.credit_debit': salesAndDeposits?.quarter4_info?.credit_debit?.toString() || '0'
})
