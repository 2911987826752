import React, { FC } from 'react'

import { Icon, IconName } from 'ui/components'
import { Dialog, DialogFace } from 'ui/components/Dialog'

import { CreateModalProps } from './CreateModalProps'
import { FormFooter, KeySetupResult, ResultFooter, SetupKeyForm } from './components'
import { useCreateModal } from './hooks/useCreateModal'

import { SuccessIndicatorToast } from './styles'

export const CreateModal: FC<CreateModalProps> = ({ open, closeSetupKeyDialog, invalidateTable }) => {
  const {
    close,
    isKeySetuppingEnd,
    keySetupResult,
    isSetupPending,
    isSuccessSetuping,
    setupKey,
    resetSetupKey,
    config,
    Field
  } = useCreateModal(invalidateTable, closeSetupKeyDialog)

  if (!open) return null

  return (
    <Dialog
      id="setup-key-dialog"
      title="Setup Key"
      face={DialogFace.PRIMARY}
      onClose={closeSetupKeyDialog}
      stickyFooter={
        isKeySetuppingEnd ? (
          <ResultFooter onClose={close} onReset={resetSetupKey} />
        ) : (
          <FormFooter onSetupKey={setupKey} onClose={close} isPending={isSetupPending} />
        )
      }
      customHeader={
        keySetupResult && (
          <SuccessIndicatorToast $isSuccess={isSuccessSetuping}>
            <Icon name={isSuccessSetuping ? IconName.CHECK_GREEN : IconName.DELETE_RED_CROSS} />
            <span>{isSuccessSetuping ? 'The key was added successfully' : 'The key setup failed'}</span>
          </SuccessIndicatorToast>
        )
      }
    >
      {isKeySetuppingEnd ? (
        <KeySetupResult keySetupResult={keySetupResult} />
      ) : (
        <SetupKeyForm config={config} Field={Field} isPending={isSetupPending} />
      )}
    </Dialog>
  )
}
