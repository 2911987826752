import React, { Suspense } from 'react'

import { createRoute, redirect } from '@tanstack/react-router'
import { Outlet } from '@tanstack/react-router'
import { BANK_USERS_PATH } from 'commons/constants/routes'
import { BPLayout } from 'ui/components/BPLayout'
import { Spinner, SpinnerSize } from 'ui/components/Spinner'

import { usersRoute } from './Users'

export const bankUsersRoutingNode = (rootRoute: BPRootRoute) => {
  const usersIndexRoute = createRoute({
    beforeLoad: async ({ context }) => {
      if (!context.accessibleRoutingFlags.userRootAccess) {
        throw redirect({
          to: '/'
        })
      }
    },
    getParentRoute: () => rootRoute,
    path: BANK_USERS_PATH,
    component: () => (
      <BPLayout>
        <Suspense fallback={<Spinner centered size={SpinnerSize.M} />}>
          <Outlet />
        </Suspense>
      </BPLayout>
    )
  })

  return usersIndexRoute.addChildren([usersRoute<typeof usersIndexRoute>(usersIndexRoute)])
}
