import { useCallback } from 'react'
import { useMutation } from 'react-query'

import { ApiReadResponse } from 'commons/types'
import { Application } from 'commons/types/DTO/commons'

import { getOldApplication as getOldApplicationService } from '../service'

type UseGetOldApplicationReturnValue = {
  getOldApplication: (id: number) => Promise<ApiReadResponse<Application>>
  isGetOldApplicationLoading: boolean
}

export const useGetOldApplication = (): UseGetOldApplicationReturnValue => {
  const mutation = useMutation((id: number) => getOldApplicationService(id))

  const getOldApplication = useCallback((id: number) => mutation.mutateAsync(id), [mutation])

  return {
    getOldApplication,
    isGetOldApplicationLoading: mutation.isLoading
  }
}
