import { DocumentSubjectType } from 'commons/types/enums'
import { ManageDocumentFormType } from '~bank-documents/types/documentEdit'

export const mapManageDocumentRequest = (value: ManageDocumentFormType): ManageDocumentFormType => {
  const newValue = { ...value }

  if (value.frequency === 'one-time') {
    newValue.startDateType = 'account_opening'
  }

  if (value.subjectType === DocumentSubjectType.COMPANY) {
    newValue.licenseId = null
    newValue.contactId = null
    newValue.bankAccountId = null
  }

  if (value.subjectType === DocumentSubjectType.LICENSE) {
    newValue.companyId = value.companyId || null
    newValue.contactId = null
    newValue.bankAccountId = null
  }

  if (value.subjectType === DocumentSubjectType.BANK_ACCOUNT) {
    newValue.companyId = value.companyId || null
    newValue.contactId = null
    newValue.licenseId = null
  }

  if (value.subjectType === DocumentSubjectType.CONTACT) {
    newValue.companyId = value.companyId || null
    newValue.bankAccountId = null
    newValue.licenseId = null
  }

  return newValue
}
