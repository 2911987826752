import React, { FC, useState } from 'react'

import { ButtonGroup } from 'ui/components/ButtonGroup'
import { Button } from 'ui/components/Buttons'
import { Link } from 'ui/components/Link'
import { DocumentStatuses } from '~bank-documents/types/enums/DocumentStatuses'

import { DocumentActionsProps } from './DocumentActions.types'
import { ChangeStatusDialog } from './components/ChangeStatusDialog'

import { s } from './styles'

export const DocumentActions: FC<DocumentActionsProps> = ({ id, flags, editButtonParams }) => {
  const { showCanAccept, showCanIncomplete, showCanReject, showNotes } = flags

  const [openedDialogStatus, setOpenedDialogStatus] = useState<DocumentStatuses | null>(null)

  const closeDialog = () => setOpenedDialogStatus(null)

  return (
    <s.Box>
      <ChangeStatusDialog id={id} status={openedDialogStatus} onClose={closeDialog} notes={showNotes} />

      <ButtonGroup margin="small">
        <Link {...editButtonParams}>
          <Button face="positive">Edit</Button>
        </Link>

        {showCanAccept && (
          <Button
            face="positive"
            onClick={() => setOpenedDialogStatus(DocumentStatuses.APPROVED)}
            data-qa="document-accept-btn"
          >
            Accept
          </Button>
        )}
        {showCanReject && (
          <Button
            face="positive"
            onClick={() => setOpenedDialogStatus(DocumentStatuses.REJECTED)}
            data-qa="document-reject-btn"
          >
            Reject
          </Button>
        )}
        {showCanIncomplete && (
          <Button
            face="positive"
            onClick={() => setOpenedDialogStatus(DocumentStatuses.INCOMPLETE)}
            data-qa="document-incomplete-btn"
          >
            Incomplete
          </Button>
        )}
      </ButtonGroup>
    </s.Box>
  )
}
