import styled from 'styled-components'
import { Info } from 'ui/components/Info'

export const Columns = styled.div`
  display: flex;
  width: 680px;
  gap: 30px;
`

export const Column = styled.div`
  flex: 1;
`

export const EINInfo = styled(Info)<{ $hasValue: boolean }>`
  padding-bottom: ${(p) => (p.$hasValue ? 21 : 23)}px;
`
