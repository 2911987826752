import React from 'react'

import { Validators } from 'ui/components/Form'
import { TextareaInput } from 'ui/components/InputV2'
import { Select } from 'ui/components/Select'
import { Col, Col2x, FormRow } from 'ui/themes/globalStyles'

import { MainText, SecondText } from '../../style'
import { DueDiligenceCompletedRedFlagProps } from './DueDiligenceCompletedRedFlagProps'

export const DueDiligenceCompletedRedFlag = ({ formValue, Field }: DueDiligenceCompletedRedFlagProps) => (
  <>
    <MainText>
      Owner or manager of a marijuana-related business reside outside the state in which the business is located.
    </MainText>
    <SecondText>
      Due diligence completed during the account opening process. Check file maintenance for address changes on business
      or signers.
    </SecondText>
    <FormRow>
      <Col>
        <Field
          required
          name="dueDiligenceCompletedRedFlag"
          label="Red Flag?"
          input={Select}
          inputProps={{
            data: [
              {
                label: 'Yes',
                value: 'yes'
              },
              {
                label: 'No',
                value: 'no'
              }
            ]
          }}
          validator={Validators.required.field}
        />
      </Col>
      <Col>
        <Field
          required
          name="dueDiligenceCompletedChangeOfAddress"
          label="Change of Address?"
          input={Select}
          inputProps={{
            data: [
              {
                label: 'No',
                value: 'no'
              },
              {
                label: 'Yes – See comments',
                value: 'yes'
              }
            ]
          }}
          validator={Validators.required.field}
        />
      </Col>
    </FormRow>
    <FormRow>
      <Col2x>
        <Field
          debounced
          required={formValue.dueDiligenceCompletedChangeOfAddress === 'yes'}
          name="dueDiligenceCompletedComments"
          label="Comments"
          input={TextareaInput}
          inputProps={{}}
          validator={formValue.dueDiligenceCompletedChangeOfAddress === 'yes' ? Validators.required.field : undefined}
        />
      </Col2x>
    </FormRow>
  </>
)
