import React from 'react'

import { LabelValue, Value } from '../../styles'
import { TitleProps } from './TitleProps'

export const Title = ({ companyRoleTitle }: TitleProps) => (
  <LabelValue>
    <div>Title:</div>
    <Value>{companyRoleTitle || '---'}</Value>
  </LabelValue>
)
