import React from 'react'

import { Validators } from 'ui/components/Form'
import { TextareaInput } from 'ui/components/InputV2'
import { Select } from 'ui/components/Select'
import { Col, Col2x, FormRow } from 'ui/themes/globalStyles'

import { MainText, SecondText } from '../../style'
import { ComparePriorAndCurrentQuarterProps } from './ComparePriorAndCurrentQuarterProps'

export const ComparePriorAndCurrentQuarter = ({ formValue, Field }: ComparePriorAndCurrentQuarterProps) => (
  <>
    <MainText>
      Business receives substantially more revenue than may be reasonably expected given the limitations imposed by the
      state of licensure.
      <br />
      Business makes cash deposits or withdrawals over a short period of time that are excessive relative to local
      competitors or the expected business activity.
    </MainText>
    <SecondText>
      Compare prior and current quarter’s activity for a 25% variance. If not within the variance, contact customer to
      understand differences. (Fed approved 11/07/17)
    </SecondText>
    <FormRow>
      <Col>
        <Field
          required
          name="comparePriorAndCurrentRedFlag"
          label="Red Flag?"
          input={Select}
          inputProps={{
            data: [
              {
                label: 'Yes',
                value: 'yes'
              },
              {
                label: 'No',
                value: 'no'
              }
            ]
          }}
          validator={Validators.required.field}
        />
      </Col>
      <Col>
        <Field
          required
          name="comparePriorAndCurrentVariance"
          label="Is activity within Variance?"
          input={Select}
          inputProps={{
            data: [
              {
                label: 'All activity in Variance',
                value: 'all'
              },
              {
                label: 'See validated Variance and/or Annual Questions',
                value: 'see_validated'
              },
              {
                label: 'Other – See comments',
                value: 'other'
              }
            ]
          }}
          validator={Validators.required.field}
        />
      </Col>
    </FormRow>
    <FormRow>
      <Col2x>
        <Field
          debounced
          required={formValue.comparePriorAndCurrentVariance === 'other'}
          name="comparePriorAndCurrentComments"
          label="Comments"
          input={TextareaInput}
          inputProps={{}}
          validator={formValue.comparePriorAndCurrentVariance === 'other' ? Validators.required.field : undefined}
        />
      </Col2x>
    </FormRow>
  </>
)
