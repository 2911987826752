import React, { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useQueryClient } from 'react-query'

import { getRouteApi } from '@tanstack/react-router'
import { BSA_CTR_PATH } from 'commons/constants/routes'
import { BsaCtr, BsaCtrPersonInfo } from 'commons/types/DTO/bank/bsa'
import { Logger } from 'commons/utils/logger'
import isBoolean from 'lodash/isBoolean'
import isEqual from 'lodash/isEqual'
import { LeaveConfirmation } from 'ui/components'
import { Page, PageFace } from 'ui/components/Page'
import { useConfirmation } from 'ui/hooks'
import { CTR } from '~bank-bsa-reporting/hooks'

import { Footer, Form } from './components'
import { FormErrors } from './formErrors'
import { getRefFromMetaErrors, parseErrorMeta } from './workWithMetaErrors'

const routeApi = getRouteApi('/bsa/ctrs/$id/edit')

const ManageCTR: FC = memo(() => {
  const [errorMeta, setErrorMeta] = useState({})
  const [errorsReceived, setErrorsReceived] = useState(false)
  const getErrorMeta = (e: { string: [] }) => setErrorMeta(e)

  const search = routeApi.useSearch()
  const params = routeApi.useParams()
  const navigate = routeApi.useNavigate()

  const { isFetching, item } = CTR.useCTRItem(+(params.id || 0))
  const queryClient = useQueryClient()
  const deleteMutation = CTR.useDelete(queryClient)
  const saveMutation = CTR.useSave(queryClient, getErrorMeta)
  const validateMutation = CTR.useValidate(getErrorMeta)
  const [isFormDirty, setIsFormDirty] = useState(false)
  const [isLeaveConfirmationNeeded, setIsLeaveConfirmationNeeded] = useState(true)

  const validateFunctionRefs = useRef<any>({
    general: null,
    contact_information: null,
    transaction_locations_info: (item?.transaction_locations_info || []).map(() => null),
    persons_info: (item?.persons_info || []).map(() => null),
    transactions_info: null
  })

  const [formValue, setFormValue] = useState<BsaCtr>(item)
  const [formErrors, setFormErrors] = useState<FormErrors>({
    general: [],
    contactInformation: {},
    transactionLocations: (item?.transaction_locations_info || []).map(() => ({})),
    personsInfo: [],
    transactionsInfo: {},
    institutionLocationsInfo: {}
  })

  const markFormAsDirty = useCallback(() => {
    setIsFormDirty(true)
  }, [setIsFormDirty])

  const onFormChange = useCallback(
    (v: Partial<BsaCtr>, e: FormErrors) => {
      if (!isEqual({ ...formValue, ...v }, formValue)) {
        setFormValue({ ...formValue, ...v })
      }

      // We remove it because we get errors from the backend
      delete e.transactionsInfo.cash_in_total
      delete e.transactionsInfo.cash_out_total

      // clear backend errors, because they block submitting
      setErrorMeta({})

      setFormErrors({ ...formErrors, ...e })
    },
    [formValue, formErrors]
  )

  useEffect(() => {
    if (!isEqual(item, formValue)) {
      onFormChange(item, formErrors)
    }
  }, [item])

  const deleteCTR = () => {
    setIsLeaveConfirmationNeeded(false)
    deleteMutation.mutateAsync([item?.id || 0]).then(() => {
      navigate({ to: BSA_CTR_PATH })
    })
  }

  const { open: startDeleting, Confirmation } = useConfirmation({
    message: `Are you sure you want to delete ${item?.name_desc} CTR?`,
    onConfirm: deleteCTR,
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  const onCancel = useCallback(() => {
    if (search.returnUrl) {
      navigate({ to: decodeURIComponent(search.returnUrl) })
    } else {
      navigate({ to: BSA_CTR_PATH })
    }
  }, [search.returnUrl, navigate])

  const validateBlock = useCallback((name: string, array?: boolean) => {
    const scroll = (errors: any, index?: number) => {
      if (Object.keys(errors).length) {
        const fieldName = Object.keys(errors)[0]

        const ref =
          typeof index !== 'undefined'
            ? validateFunctionRefs.current[name][index]?.registeredFields[fieldName]?.ref
            : validateFunctionRefs.current[name]?.registeredFields[fieldName]?.ref

        if (ref) {
          ref.current.scrollIntoView()
        }
      }
    }

    if (validateFunctionRefs.current[name]?.validate !== null) {
      if (array) {
        const e = validateFunctionRefs.current[name]
          .map((item: any, index: number) => {
            const e = item.validate({ updateFields: true })
            scroll(e.errors, index)
            if (!e.valid) {
              Logger.getInstance().info('ctr validation 1', e.errors)
            }
            return !e.valid
          })
          .filter(Boolean)
        return !e.length
      } else {
        const errors = validateFunctionRefs.current[name]?.validate({ updateFields: true })
        errors && scroll(errors.errors)
        if (!errors.valid) {
          Logger.getInstance().info('ctr validation 2', errors.errors)
        }
        return errors ? errors.valid : true
      }
    }

    return true
  }, [])

  const onSubmit = useCallback(
    (first?: boolean) => {
      if (!validateBlock('general')) {
        return
      }
      if (!validateBlock('contact_information')) {
        return
      }
      if (!validateBlock('transaction_locations_info', true)) {
        return
      }
      if (!validateBlock('persons_info', true)) {
        return
      }
      if (!validateBlock('transactions_info')) {
        return
      }

      !isBoolean(first) && setIsLeaveConfirmationNeeded(false)

      let personsInfo: BsaCtrPersonInfo[] = []

      if (formValue && formValue.persons_info) {
        personsInfo = formValue.persons_info.map((item) => ({
          ...item,
          id_form: item.id_form === '' ? '' : item.id_form,
          id_number: !item.id_number ? '' : item.id_number
        }))
      }
      if (formValue?.institution_contact_info?.ein) {
        formValue.institution_contact_info.ein = formValue.institution_contact_info.ein.replace('-', '')
      }
      if (formValue && formValue.transaction_locations_info) {
        formValue.transaction_locations_info.map((item) => {
          item.ein = item?.ein?.replace('-', '')
        })
      }

      if (formValue.prior_report_bsa_id) {
        formValue.prior_report_bsa_id = formValue.prior_report_bsa_id.toString()
      }

      const ctr = { ...formValue, trans_date: formValue.transaction_info?.transaction_date, persons_info: personsInfo }

      if (isBoolean(first) && first) {
        validateMutation.mutateAsync(ctr)
      } else {
        saveMutation.mutateAsync(ctr).then((item: BsaCtr) => {
          navigate({ to: '/bsa/ctrs/$id', params: { id: item.id.toString() } })
        })
      }
    },
    [saveMutation, validateMutation, formValue, validateBlock]
  )

  useEffect(() => {
    if (formValue && !errorsReceived && !isFetching) {
      onSubmit(true)
      setErrorsReceived(true)
    }
  }, [formValue, errorsReceived, isFetching])

  const parsedErrors = useMemo(() => parseErrorMeta(errorMeta), [errorMeta])

  useEffect(() => {
    const ref = getRefFromMetaErrors(validateFunctionRefs, parsedErrors)
    if (ref) {
      ref.current.scrollIntoView()
    }
  }, [validateFunctionRefs, parsedErrors])

  return (
    <Page
      face={PageFace.SECONDARY}
      title={item?.name_desc}
      subTitle="CTR"
      isPending={isFetching}
      footer={
        <Footer
          onSubmit={onSubmit}
          onDelete={formValue?.id ? startDeleting : undefined}
          onCancel={onCancel}
          isSubmitting={saveMutation.isLoading}
        />
      }
    >
      <Confirmation />
      <LeaveConfirmation preventLeaving={isLeaveConfirmationNeeded && isFormDirty}>
        {!!formValue && (
          <Form
            value={formValue}
            errors={{
              ...formErrors,
              general: parsedErrors.general,
              transactionsInfo: parsedErrors.transaction_info,
              personsInfo: parsedErrors.persons_info,
              contactInformation: parsedErrors.institution_contact_info,
              transactionLocations: parsedErrors.transaction_locations_info.concat(
                parsedErrors.institution_locations_info
              )
            }}
            onChange={onFormChange}
            validateFunctionRefs={validateFunctionRefs}
            markFormAsDirty={markFormAsDirty}
          />
        )}
      </LeaveConfirmation>
    </Page>
  )
})

export default ManageCTR
