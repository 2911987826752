import styled from 'styled-components'

export const Footer = styled.div`
  height: 65px;
  max-width: 1920px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid #e2e2e2;
  background: #fff;
  padding: 0 50px;
  z-index: 1;

  @media print {
    border-top-width: 0px;
  }
`

export const PanelBody = styled.div`
  padding: 30px 36px 36px 36px;
`
