import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { ApiError } from 'commons/types/api'

import { apiUrls } from './apiUrls'

Axios.defaults.withCredentials = true

const getError = (error: AxiosError<any>) => {
  if (error.message.toLowerCase() === 'network error' || error.code === 'ECONNABORTED') {
    return new ApiError('Error connecting to server.', null, error?.response?.status)
  }

  if (error.request?.responseType === 'arraybuffer' && error.response?.data.toString() === '[object ArrayBuffer]') {
    const res = JSON.parse(Buffer.from(error.response.data).toString('utf8'))
    return new ApiError(res.message, null, error?.response.status)
  }

  if (error?.response?.data?.error) {
    return new ApiError(
      error?.response?.data?.error.message,
      error?.response?.data?.error.meta ? error?.response?.data?.error.meta : null,
      error?.response?.status
    )
  }

  return new ApiError(error?.response?.data?.message, error?.response?.data?.meta, error?.response?.status)
}

const dataHandler = <T, D>(response: AxiosResponse<T, D>) => response.data

const errorHandler = (error: any) => {
  throw getError(error)
}

const apiPrefix = (url: string): string => (url.split('/api').length === 1 ? `/api${url}` : url)

export const Request = {
  get: async <T = any, D = any>(url: string, config?: AxiosRequestConfig<D>) =>
    Axios.get<T, AxiosResponse<T, D>, D>(apiPrefix(url), { ...config })
      .then(dataHandler)
      .catch(errorHandler),
  post: async <T = any, D = any>(url: string, body?: D, config?: AxiosRequestConfig<D>) =>
    Axios.post<T, AxiosResponse<T, D>, D>(apiPrefix(url), body, { ...config })
      .then(dataHandler)
      .catch(errorHandler),
  put: async <T = any, D = any>(url: string, body?: D, config?: AxiosRequestConfig<D>) =>
    Axios.put<T, AxiosResponse<T, D>, D>(apiPrefix(url), body, { ...config })
      .then(dataHandler)
      .catch(errorHandler),
  patch: async <T = any, D = any>(url: string, body?: D, config?: AxiosRequestConfig<D>) =>
    Axios.patch<T, AxiosResponse<T, D>, D>(apiPrefix(url), body, { ...config })
      .then(dataHandler)
      .catch(errorHandler),
  delete: async <T = any, D = any>(url: string, body?: D, config?: AxiosRequestConfig<D>) =>
    Axios.delete<T, AxiosResponse<T, D>, D>(apiPrefix(url), { data: body, ...config })
      .then(dataHandler)
      .catch(errorHandler),
  urls: apiUrls
}
