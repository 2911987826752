import { ATMServices } from 'commons/types/DTO/commons'

import { ATMServicesFormValue } from '../../../../types'

export const mapATMServicesForm = ({
  ATMAddress,
  ATMAvgAmountReplenished,
  ATMMaxAmount,
  ATMReplenishFrequency,
  ATMFundsFromBank,
  ATMFundsFromBankFalseDesc
}: ATMServicesFormValue): ATMServices => ({
  ATMAddress,
  ATMAvgAmountReplenished,
  ATMMaxAmount,
  ATMReplenishFrequency,
  ATMFundsFromBank,
  ATMFundsFromBankFalseDesc
})
