import { FormPaths, OnboardingFormFieldNames } from '../../../../enums'
import { CompanyFormValues } from '../../../../types/state'
import { setNewContacts } from '../setNewContacts'

export const fromContact = ({
  oldCompany,
  contactIndex,
  formName,
  fieldName,
  errors
}: {
  oldCompany: CompanyFormValues
  contactIndex: number
  formName: FormPaths
  fieldName: OnboardingFormFieldNames
  errors: string[]
}): CompanyFormValues => {
  const oldContacts = oldCompany.contacts
  const formData = oldContacts[contactIndex][formName]

  const newContact = {
    ...oldContacts[contactIndex],
    [formName]: {
      ...formData,
      formErrors: { ...formData.formErrors, [`${fieldName}`]: errors.join('.') }
    }
  }

  const newContacts = setNewContacts({
    contactIndex,
    newContact,
    oldCompany
  })

  const newCompany = { ...oldCompany, contacts: newContacts }

  return newCompany
}
