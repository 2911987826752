import styled from 'styled-components'

export const ConfirmationContainer = styled.div`
  max-width: 480px;
  margin: 20px auto 0 auto;
  padding-bottom: 20px;
  text-align: center;
`

export const ActionsWrapper = styled.div`
  margin-top: 40px;
  display: grid;
  justify-content: center;
  grid-template-columns: max-content max-content;
  grid-column-gap: 10px;
`

export const Message = styled.div`
  max-width: 340px;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #000000;
  margin: 0 auto;
`
