import React, { memo } from 'react'

import { IconName, IconSize } from 'ui/components/Icon'

import { Box, List, Title, WarningIcon } from './warningStyles'

interface FincenWarningsProps {
  warnings: string
}

export const FincenWarnings = memo(({ warnings }: FincenWarningsProps) => {
  return (
    <Box>
      <Title $weight={700}>
        <WarningIcon name={IconName.ERROR_RED} size={IconSize.M} /> FinCEN Warning(s)
      </Title>
      <br />
      <Title $weight={600}>All FinCEN warnings will be cleared once SAR is corrected and re-submitted.</Title>
      <List>
        {warnings.split('\n').map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </List>
    </Box>
  )
})
