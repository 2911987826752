import { SiteDocuments } from 'commons/types/DTO/bank'

export const dataSourceSelector = (data: any) =>
  (data?.list?.records || []).map((record: SiteDocuments) => ({
    item: {
      street: record.street,
      city: record.city,
      document_id: record.document_id,
      frequency: record.frequency,
      last_notes: record.last_notes,
      last_period_approved: record.last_period_approved,
      license_number: record.license_number,
      next_due_date: record.next_due_date,
      organization_id: record.organization_id,
      postal_code: record.postal_code,
      relationship_id: record.relationship_id,
      relationship_name: record.relationship_name,
      state: record.state
    }
  }))
