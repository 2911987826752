import React, { FC, memo } from 'react'

import { ButtonGroup } from 'ui/components/ButtonGroup'
import { Button } from 'ui/components/Buttons'
import { IconName } from 'ui/components/Icon'
import { Footer as F } from 'ui/temp/Footer'

import { FooterProps } from './Footer.types'

import { s } from './styles'

export const Footer: FC<FooterProps> = memo(({ onDelete, onSubmit, onCancel, isSubmitting, disabled }) => (
  <F
    childrenLeftSide={
      !!onDelete && (
        <s.DeleteButton data-qa="Delete" icon={IconName.DELETE} face="negative" onClick={onDelete} disabled={disabled}>
          Delete
        </s.DeleteButton>
      )
    }
  >
    <ButtonGroup margin="small">
      <Button face="neutral" onClick={onCancel} disabled={disabled} loading={isSubmitting}>
        Cancel
      </Button>
      <Button data-qa="Submit" onClick={onSubmit} face="positive" disabled={disabled} loading={isSubmitting}>
        Submit
      </Button>
    </ButtonGroup>
  </F>
))
