import React, { memo } from 'react'

import IMask from 'imask'
import { Validators } from 'ui/components/Form'

import { BaseMaskedInput } from './BaseMaskedInput'
import { BaseInputProps } from './props'
import { InputIconPosition } from './types'

const PERCENT_MIN = 0
const PERCENT_MAX = 100
const PERCENT_SYMBOL = '%'

export const PercentInput = memo((props: BaseInputProps) => {
  const mask = IMask.createMask({
    mask: Number,
    scale: 2,
    radix: '.'
  })

  return (
    <BaseMaskedInput
      {...props}
      mask={mask}
      validator={Validators.number.between(PERCENT_MIN, PERCENT_MAX)}
      renderIcon={PERCENT_SYMBOL}
      iconPosition={InputIconPosition.RIGHT_CLOSE}
    />
  )
})
