import SC from 'styled-components'
import { Panel } from 'ui/components'

const FormInner = SC.div`
  padding: 30px 35px;
`

const FilingTypeWrapper = SC.div`
  display: flex;
`

const PanelWithBottomMargin = SC(Panel)`
  margin-bottom: 50px;
`

export const s = {
  FormInner,
  FilingTypeWrapper,
  PanelWithBottomMargin
}
