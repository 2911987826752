import styled from 'styled-components'
import { Spinner as UnstyledSpinner } from 'ui/components/Spinner'
import { CSS } from 'ui/helpers'

export const Spinner = styled(UnstyledSpinner)`
  border-color: var(--theme-colors-theme-brand-lighter) transparent var(--theme-colors-theme-brand-lighter) transparent;
`

export const Box = styled.button`
  height: 54px;
  outline: none;
  cursor: pointer;
  border: 1.4px solid var(--theme-colors-global-general-main);
  border-radius: 100px;
  background: none;
  color: var(--theme-colors-global-general-main);

  transition: ${CSS.populateTransition(['background-color', 'box-shadow', 'color'])};

  path {
    fill: var(--theme-colors-global-general-main);
    color: var(--theme-colors-global-general-main);

    transition: ${CSS.populateTransition(['fill', 'color'])};
  }

  &:hover,
  &:focus-visible {
    background-color: var(--theme-colors-global-general-main);
    color: var(--theme-colors-theme-brand-lighter);

    box-shadow: var(--theme-boxShadows-offset-offset100Y4);

    path {
      fill: var(--theme-colors-theme-brand-lighter);
      color: var(--theme-colors-theme-brand-lighter);
    }
  }
`

export const BoxLoading = styled(Box)`
  background-color: var(--theme-colors-global-general-main);
  color: var(--theme-colors-theme-brand-lighter);

  box-shadow: var(--theme-boxShadows-offset-offset100Y4);

  path {
    fill: var(--theme-colors-theme-brand-lighter);
    color: var(--theme-colors-theme-brand-lighter);
  }
`

export const Inner = styled.span.attrs({ className: 'theme-tags-typography-bodyM-semiBold' })`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 19px;
  gap: 29px;
`
