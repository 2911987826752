import { useCallback, useState } from 'react'

import { handlerServerError } from 'errors'
import { Toast } from 'ui/components/Toast'

import { useSendClientPortalInvite } from '../../../hooks'
import { ClientPortalInvite } from '../../../types'

export const useClientPortalInvite = () => {
  const [dialogVisible, setDialogVisible] = useState(false)
  const openDialog = useCallback(() => setDialogVisible(true), [setDialogVisible])
  const closeDialog = useCallback(() => setDialogVisible(false), [setDialogVisible])
  const { routine: sendClientPortalInvite, isLoading } = useSendClientPortalInvite()

  const sendInvite = useCallback(
    async (invite: ClientPortalInvite) => {
      try {
        await sendClientPortalInvite(invite)
        Toast.successSmall('Invite was successfully sent')
        closeDialog()
      } catch (error) {
        handlerServerError(error)
      }
    },
    [sendClientPortalInvite, closeDialog]
  )

  return {
    dialogVisible,
    openDialog,
    closeDialog,
    sendInvite,
    isLoading
  }
}
