import React, { FC, memo } from 'react'

import { getRouteApi } from '@tanstack/react-router'
import { TableDataQa } from 'commons/types/enums'
import { Layer } from 'ui/components/Layer'
import { PageSection, PageSectionFace } from 'ui/components/Page'
import { Table } from 'ui/components/Table'
import { InvoiceSubmittalPaths } from '~bank-invoices/constants'
import { InvoiceSubmittalDetails } from '~bank-invoices/types/Details'

import { columns } from './columns'
import { UploadButton } from './components/UploadButton'

const routeApi = getRouteApi(InvoiceSubmittalPaths.DETAILS)

export const TaxReportTable: FC = memo(() => {
  const invoice = routeApi.useLoaderData<InvoiceSubmittalDetails>()

  return (
    <PageSection title="Files" face={PageSectionFace.SECONDARY} actions={<UploadButton />}>
      <Layer rounded shadowed>
        <Table
          dataQa={TableDataQa.BP_INVOICE_TAX_REPORT}
          columns={columns(invoice.id)}
          dataSource={invoice.files.map((el) => ({ item: el }))}
        />
      </Layer>
    </PageSection>
  )
})
