import { useCallback, useMemo } from 'react'

import { useFormData } from 'brief-form'
import { CTRListRequest, CTRListResponse } from '~bank-bsa-reporting/types'

type FilterProps = {
  data: CTRListResponse
  onChange: (props: any) => void
  onClear: () => void
}

export const useFilter = (props: FilterProps) => {
  const {
    data: { form },
    onChange,
    onClear
  } = props

  const initialFilter: CTRListRequest = useMemo(
    () => ({
      filingType: form.filingType,
      page: form.page,
      size: form.size,
      status: form.status,
      transactionDate: form.transactionDate
    }),
    [form]
  )

  const formData = useFormData<CTRListRequest>({
    initialValue: initialFilter,
    onFormChanged: onChange
  })
  const { isDirty, config, Field, set } = formData

  const reset = useCallback(() => {
    set({ reset: true })
    onClear()
  }, [set, onChange])

  return {
    isDirty,
    config,
    Field,
    reset
  }
}
