import { getLicenseList } from 'commons/service/bank/license'
import { LicenseDeprecated } from 'commons/types/DTO/bank'
import { ApiReadResponse } from 'commons/types/api'
import { Keys, ReadHook, useStateManager } from 'state-manager'

export type UseGetLicenseListByIdsParams = number[]

export const useGetLicenseListByIds: ReadHook<ApiReadResponse<LicenseDeprecated>, UseGetLicenseListByIdsParams> = (
  opts
) => {
  const sm = useStateManager()

  return sm.use(
    Keys.License.List(opts),
    () => getLicenseList({ _options: { filters: [{ field: 'id', type: 'in', value: opts }] } }),
    {
      keepPreviousData: true
    }
  )
}
