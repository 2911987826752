import { FormInputProps } from 'brief-form'
import { ChoiceOption, ChoiceValue } from 'ui/types'

import { SingleSelectAutoSuggestOpts } from '../SingleSelectAutoSuggest'

export type CompanySelectOpts = Omit<SingleSelectAutoSuggestOpts, 'listName' | 'options'> & {
  filters?: { field: string; type: string; value: ChoiceValue }[]
  hideMyCompaniesFilter?: boolean
  preloadedOptions?: ChoiceOption[]
  isSearchRequest?: boolean
}

export interface CompanySelectProps extends FormInputProps<ChoiceValue, CompanySelectOpts> {}
