import React, { useEffect } from 'react'

import { FieldApi } from '@tanstack/react-form'
import { BsaSar } from 'commons/types/DTO/bank/bsa'
import { CheckBox, FF } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

type NoAmountInvolvedRendererProps = {
  field: FieldApi<BsaSar, 'transactions_info.no_amount_involved'>
}

const NoAmountInvolvedRenderer = ({ field }: NoAmountInvolvedRendererProps) => {
  const { formApi } = useSARFormContext()

  useEffect(() => {
    if (field.state.value) {
      formApi.setFieldValue('transactions_info.amount_unknown', false, { dontUpdateMeta: true })
      const amountMeta = formApi.getFieldMeta('transactions_info.current_amount')
      formApi.setFieldMeta('transactions_info.current_amount', {
        ...amountMeta,
        errors: [],
        errorMap: {}
      })
    }
  }, [field.state.value])

  return <FF field={field} input={(p) => <CheckBox {...p} checkBoxLabel="No amount involved" />} />
}

export const NoAmountInvolved = () => {
  const { formApi } = useSARFormContext()
  return (
    <formApi.Field
      name="transactions_info.no_amount_involved"
      children={(field) => <NoAmountInvolvedRenderer field={field} />}
    />
  )
}
