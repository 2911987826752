import styled from 'styled-components'
import { CSS } from 'ui/helpers'

import { ErrorPosition } from './FF.types'

const Container = styled.div`
  margin: 0;
  width: 100%;
`

const Error = styled.span<{ $error: boolean; $position: ErrorPosition }>`
  opacity: ${(p) => (p.$error ? 1 : 0)};
  color: ${(p) => p.theme.uiKit.colors.theme.negative.main};
  ${(p) => CSS.populateFont(p.theme.uiKit.typography.bodyS.regular)};
  vertical-align: top;
  margin-top: ${(p) => (p.$position === 'bottom' ? 3 : 0)}px;
  display: inline-block;
`

const ErrorWrapperRight = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  label {
    margin-bottom: 0;
  }
`

export const s = { Container, Error, ErrorWrapperRight }
