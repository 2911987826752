import SC, { css } from 'styled-components'
import { Layer } from 'ui/components/Layer'

const WrappedLayerCss = css`
  display: inline-block;
  margin-bottom: 47px;
  width: 100%;
`

const BoxCss = css`
  border-top: 2px solid #000;
`

const TitleCss = css`
  padding: 10px 20px;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  margin: 0;
`

const ColumnsCss = css`
  position: relative;
`

const LabelsCss = css`
  background: ${(p) => p.theme.uiKit.colors.global.grey.grey100};
  padding: 68px 0 0 0;
  min-width: 124px;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 20px 20px rgb(0 0 0 / 20%);
`

const LabelCss = css`
  padding: 9px 16px;
  font-size: 13px;
  line-height: 16px;
  height: 34px;
`

const TableCss = css`
  border-collapse: collapse;
`

const ThCss = css<{ $bottomBorder?: boolean }>`
  font-size: 14px;
  line-height: 22px;
  color: #fff;
  background: ${(p) => p.theme.uiKit.colors.global.grey.grey900};
  padding: 1px 30px;
  border-right: 1px solid #fff;
  ${(p) => (p.$bottomBorder ? 'border-bottom: 1px solid #fff' : '')};
`

const TdCss = css<{ $hl?: boolean }>`
  font-size: 13px;
  line-height: 16px;
  padding: 9px 16px;
  text-align: center;
  border-right: 1px solid ${(p) => p.theme.uiKit.colors.global.grey.grey100};
  background: ${(p) => (p.$hl ? p.theme.uiKit.colors.theme.negative.main : 'transparent')};

  &:last-child {
    border-right: none;
  }
`

const TableWrapCss = css`
  overflow-x: scroll;
  margin-left: 125px;
`

const TVCss = css<{ $negative?: boolean }>`
  color: ${(p) =>
    p.$negative ? p.theme.uiKit.colors.theme.negative.main : p.theme.uiKit.colors.global.general.secondary};
`

export const WrappedLayer = SC(Layer)`${WrappedLayerCss}`

export const Box = SC.div`${BoxCss}`

export const Title = SC.h2`${TitleCss}`

export const Columns = SC.div`${ColumnsCss}`

export const Labels = SC.div`${LabelsCss}`

export const Label = SC.div`${LabelCss}`

export const Table = SC.table`${TableCss}`

export const Th = SC.th<{ $bottomBorder?: boolean }>`${ThCss}`

export const Td = SC.td<{ $hl?: boolean }>`${TdCss}`

export const TableWrap = SC.div`${TableWrapCss}`

export const TV = SC.span<{ $negative?: boolean }>`${TVCss}`
