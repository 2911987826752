import React from 'react'

import { Page, PageFace } from 'ui/components'

import { CompanyContainer, ManagePageFooter } from '../../components'
import { ManageContainerProps } from './ManageContainer.types'
import { BankAccountFormSection } from './components/BankAccountFormSection'
import { CompanyDocumentFormSection } from './components/CompanyDocumentFormSection'
import { useManageContainer } from './hooks/useManageContainer'

export const ManageContainer = ({
  webPageData,
  pageTitle,
  pageSubTitle,
  onSubmitForm,
  footerSubmitButtonText
}: ManageContainerProps) => {
  const { mappedCompaniesPageData, form, onDeleteBankAccount, addBankAccountToCompany } = useManageContainer(
    webPageData,
    onSubmitForm
  )

  return (
    <Page
      title={pageTitle}
      face={PageFace.SECONDARY}
      subTitle={pageSubTitle}
      footer={
        <form.Subscribe
          children={({ isSubmitting, isTouched, isFieldsValid }) => (
            <ManagePageFooter
              disabledSubmitting={isSubmitting || !isTouched || (isTouched && !isFieldsValid)}
              onSubmit={form.handleSubmit}
              positiveButtonText={footerSubmitButtonText}
            />
          )}
        />
      }
      noPadding={true}
    >
      <form.Field name="companiesData" mode="array">
        {(companiesListField) => (
          <>
            {companiesListField.state.value?.map((_, companyIndex) => {
              const company = mappedCompaniesPageData[companyIndex]

              return (
                <CompanyContainer
                  key={company.companyId || company.companyUid}
                  number={companyIndex + 1}
                  totalCount={webPageData.companiesTotalCount}
                  companyName={company.companyName}
                  anchor={company.companyName.toLowerCase().replace(/\s+/g, '-')}
                >
                  <CompanyDocumentFormSection form={form} companyIndex={companyIndex} company={company} />

                  <BankAccountFormSection
                    form={form}
                    companyIndex={companyIndex}
                    onDeleteBankAccount={onDeleteBankAccount}
                    addBankAccountToCompany={addBankAccountToCompany}
                  />
                </CompanyContainer>
              )
            })}
          </>
        )}
      </form.Field>
    </Page>
  )
}
