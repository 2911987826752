import { getRouteApi } from '@tanstack/react-router'

const routeApi = getRouteApi('/bsa/sars/$id/edit')

export const useRouting = () => {
  const { returnUrl } = routeApi.useSearch()
  const navigate = routeApi.useNavigate()
  const params = routeApi.useParams()
  const id = +(params.id || 0)

  const comeBackToDetailsOrReturnUrl = () => {
    if (returnUrl) {
      navigate({ to: decodeURIComponent(returnUrl) })
    } else {
      if (id) {
        navigate({ to: '/bsa/sars/$id', params: { id } })
      } else {
        navigate({ to: '/bsa/sars' })
      }
    }
  }

  return {
    id,
    comeBackToDetailsOrReturnUrl
  }
}
