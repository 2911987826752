import { ReadHook, WriteHook, useMutationManager, useStateManager } from 'react-query-state-manager'

import { api } from './api'
import {
  BankAccountCreatePage,
  BankAccountCreatePagePayload,
  BankAccountDeletePayload,
  BankAccountDetailsPage,
  BankAccountDetailsPagePayload,
  BankAccountEditPage,
  BankAccountEditPagePayload,
  BankAccountForm
} from './types'

const DETAILS_WEBPAGE_KEY_ROOT = 'BANK_ACCOUNT_DETAILS'
const CREATE_WEBPAGE_KEY_ROOT = 'BANK_ACCOUNT_CREATE'
const EDIT_WEBPAGE_KEY_ROOT = 'BANK_ACCOUNT_EDIT'

const useDetailsPage: ReadHook<BankAccountDetailsPage, BankAccountDetailsPagePayload> = (payload, opts) =>
  useStateManager().use([DETAILS_WEBPAGE_KEY_ROOT, payload], () => api.getDetailsPage(payload), opts)

const useCreatePage: ReadHook<BankAccountCreatePage, BankAccountCreatePagePayload> = (payload, opts) =>
  useStateManager().use([CREATE_WEBPAGE_KEY_ROOT, payload], () => api.getCreatePage(payload), opts)

const useEditPage: ReadHook<BankAccountEditPage, BankAccountEditPagePayload> = (payload, opts) =>
  useStateManager().use([EDIT_WEBPAGE_KEY_ROOT, payload], () => api.getEditPage(payload), opts)

const useUpdate: WriteHook<BankAccountForm, void> = (opts) =>
  useMutationManager<BankAccountForm, void>(api.update, opts)

const useDelete: WriteHook<BankAccountDeletePayload, void> = (opts) =>
  useMutationManager<BankAccountDeletePayload, void>(api.delete, opts)

export const hooks = {
  useDetailsPage,
  useCreatePage,
  useEditPage,
  useUpdate,
  useDelete
}
