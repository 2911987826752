import { HistoryChangeTypes } from 'commons/types/enums'

// TODO: check types of response and structring by type
export type HistoryChanges = {
  // "application_link", 'string_list_changed', 'assigned_entities_changed'
  // must be deleted too later
  type: HistoryChangeTypes

  value?: string
  warning?: string
  to?: string
  from?: string
  subject: string
  mustBeMasked: boolean

  // this structure`s must be rethink
  after?:
    | string[]
    | {
        id: string | number
        name: string
      }[]
  before?:
    | string[]
    | {
        id: string | number
        name: string
      }[]
  entityType?: number
  entityTypeLabel?: string
  id?: number
  name?: string
  text?: string
}
