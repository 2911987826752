import * as z from 'zod'

export const documentListFilterParamsSchema = z.object({
  page: z.number().int().positive().optional(),
  size: z.number().int().positive().optional(),
  sortBy: z.string().optional(),
  sortDesc: z.boolean().optional(),
  alerts: z.string().optional(),
  onlyMyCompanies: z.boolean().optional(),
  internal: z.boolean().optional(),
  bankAccountId: z.number().int().positive().optional(),
  companyId: z.number().int().positive().optional(),
  contactId: z.number().int().positive().optional(),
  frequency: z.string().optional(),
  licenseId: z.number().int().positive().optional(),
  search: z.string().optional(),
  subjectType: z.string().optional()
})

export type DocumentListFilterParams = z.infer<typeof documentListFilterParamsSchema>

export type DocumentListRequestConfig = {
  filter: Omit<DocumentListFilterParams, 'search'>
  search: DocumentListFilterParams['search']
}
