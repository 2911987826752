export const getFormattedEin = (firstFormat: boolean, ein?: string): string => {
  if (!ein) {
    return ''
  }

  const splitEin = ein.split('')
  const newEin: string[] = []

  splitEin.forEach((item, index) => {
    newEin.push(item)
    if (firstFormat) {
      if (index === 1) {
        newEin.push('-')
      }
    } else {
      if (index === 2 || index === 5) {
        newEin.push('-')
      }
    }
  })

  return newEin.join('')
}
