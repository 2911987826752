export type SalesSourceItemInfo = {
  approvalNeeded: boolean
  dangerAlertNotes: string
  editButtonTooltip: string
  enableDelete: boolean
  enableEdit: boolean
  enableView: boolean
  enableCancelTask: boolean
  isPrimary: boolean
  sourceID: number
  sourceType: string
  status: string
  totalSales: number
  warningAlertNotes: string
}
