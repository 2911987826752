import { TableDataQa } from 'commons/types/enums'

import { HistoryEntityType } from '../../../../enums'

export type TableProps = {
  id?: number
  type?: HistoryEntityType
  name?: string
  showTypeColumn?: boolean
  hideFilter?: boolean
  dataQa: TableDataQa
}
