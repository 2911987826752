import React from 'react'

import { CurrencyInput, FF, Validators } from 'ui/components'

import { useSARFormContext } from '../../../../../../context'

type WithdrawalsOutProps = {
  index: number
}

export const WithdrawalsOut = ({ index }: WithdrawalsOutProps) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={
        `transactions_info.by_account[${index}].withdrawals_out` as 'transactions_info.by_account[0].withdrawals_out'
      }
      validators={{ onChange: ({ value }) => Validators.required.notEmpty(value) }}
      children={(field) => <FF required field={field} label="Withdrawals" input={(p) => <CurrencyInput {...p} />} />}
    />
  )
}
