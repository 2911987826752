import React, { FC, memo } from 'react'

import { getRouteApi } from '@tanstack/react-router'
import { BankAccountsPath, ContactsPath, LicensesPath } from 'commons/constants/routes'
import { IconSize } from 'ui/components'
import { DateTime } from 'ui/components/DateTime'
import { Hr } from 'ui/components/Hr'
import { Info, InfoSet } from 'ui/components/Info'
import { FrequencyLabel } from 'ui/components/Labels'
import { Link } from 'ui/components/Link'
import { PageSection, PageSectionFace } from 'ui/components/Page'
import { TextMask } from 'ui/components/TextMask'
import { Col } from 'ui/themes/globalStyles'
import { DocumentActions } from '~bank-documents/components/DocumentActions'
import { DocumentPeriodStatusChip } from '~bank-documents/components/DocumentPeriodStatusChip'
import { RelatedCompany } from '~bank-documents/components/RelatedCompany'
import { DocumentsPaths } from '~bank-documents/constants'
import { DocumentDetailsPage } from '~bank-documents/types/documentDetails'

import { s } from './styles'

export const DocumentDetailsInformation: FC = memo(() => {
  const routeApi = getRouteApi(DocumentsPaths.DETAILS)
  const documentId = routeApi.useParams({ select: (s) => s.documentId })
  const data = routeApi.useLoaderData<DocumentDetailsPage>()

  const {
    showDueDateType,
    name,
    expirationDate,
    frequency,
    internal,
    subjectType,
    showRelatedCompanies,

    showBankAccountId,
    showCompanyId,
    showContactId,
    showLicenseId,
    showCompanyName,
    showContactName,
    showLicenseName,
    showBankAccountNumber,
    showStatus,
    showUpdatedAt,
    showNotes,
    showCanAccept,
    showCanIncomplete,
    showCanReject
  } = data

  return (
    <PageSection
      face={PageSectionFace.SECONDARY}
      title="Document Information"
      actions={
        <DocumentActions
          id={data?.showOneTimeDocPeriodId}
          flags={{ showCanAccept, showCanIncomplete, showCanReject, showNotes }}
          editButtonParams={{
            to: DocumentsPaths.EDIT,
            params: { documentId },
            search: { returnUrl: `/documents/${documentId}` }
          }}
        />
      }
    >
      <Hr />

      <InfoSet legend="Client Document Details">
        <s.EnlargedRow>
          <Col>
            <Info label="Document Name">{name}</Info>
          </Col>

          <Col>
            <Info label="Internal">
              <s.InternalCheckMark rounded value={internal} />
            </Info>
          </Col>
        </s.EnlargedRow>

        <s.EnlargedRow>
          <Col>
            <Info label="Subject Type">{subjectType}</Info>
          </Col>

          <Col>
            {showBankAccountId && (
              <Info label="Bank Account">
                <Link to={BankAccountsPath.DETAILS} params={{ bankAccountId: showBankAccountId }}>
                  <TextMask isBankAccount text={showBankAccountNumber} iconSize={IconSize.XS} />
                </Link>
              </Info>
            )}

            {showLicenseId && (
              <Info label="License">
                <Link to={LicensesPath.DETAILS} params={{ licenseId: showLicenseId }}>
                  {showLicenseName}
                </Link>
              </Info>
            )}
            {showContactId && (
              <Info label="Contact">
                <Link to={ContactsPath.DETAILS} params={{ contactId: showContactId }}>
                  {showContactName}
                </Link>
              </Info>
            )}
          </Col>
        </s.EnlargedRow>

        <s.EnlargedRow>
          <Col>
            <Info label="Frequency">
              <FrequencyLabel name={frequency} dataQa="frequency label" />
            </Info>
          </Col>

          <Col>
            {showStatus && (
              <Info label="Status">
                <DocumentPeriodStatusChip name={showStatus as any} withoutMargins />
              </Info>
            )}
            {!!showDueDateType && <Info label="Due Date Type">{showDueDateType}</Info>}
          </Col>
        </s.EnlargedRow>

        <s.EnlargedRow>
          <Col>
            <RelatedCompany
              asInfoBlock={true}
              relatedCompanies={showRelatedCompanies || [{ id: showCompanyId, name: showCompanyName }]}
            />
          </Col>

          <Col>
            {showUpdatedAt && (
              <Info label="Last Modified Date">
                <DateTime withTime date={showUpdatedAt} />
              </Info>
            )}
          </Col>
        </s.EnlargedRow>

        <s.EnlargedRow>
          <Col>
            <Info label="Expiration Date">{expirationDate}</Info>
          </Col>
        </s.EnlargedRow>

        {showNotes !== undefined && <Info label="Notes (visible for client)">{showNotes}</Info>}
      </InfoSet>
      <Hr />
    </PageSection>
  )
})
