import { TableSortingChangeHandler, TableSortingOrder } from 'ui/components'

import { ContactDetailsRequest, ContactDetailsResponse } from '../../../../../../types'

export interface ContactDocumentsProps {
  data: ContactDetailsResponse
  contactName: string
  invalidate: () => Promise<void>
  config: {
    isFetching: boolean
    onFilterChange: (filter: ContactDetailsRequest['docFilter']) => void
    order: TableSortingOrder[]
    onOrderChange: TableSortingChangeHandler
  }
}
