import React, { FC } from 'react'

import { Hint, Info } from 'ui/components'
import { Col2x, Row } from 'ui/themes/globalStyles'

import { BankNotesProps } from './BankNotesProps'

export const BankNotes: FC<BankNotesProps> = (props) => {
  const { bankNotes, readonly } = props

  return (
    <>
      {!readonly && (
        <Col2x>
          <Hint>
            Throughout the application process, please use this area to leave any notes and/or questions and a Bank
            representative will be in touch with you shortly.
          </Hint>
        </Col2x>
      )}

      <Row>
        <Col2x>
          <Info label="Message from the bank">{bankNotes}</Info>
        </Col2x>
      </Row>
    </>
  )
}
