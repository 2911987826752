export enum Scope {
  ADVERSE_MEDIA_PUBLICATION = 'AdverseMediaPublication',
  ADVERSE_MEDIA_SEARCH_CASE = 'AdverseMediaSearchCase',
  APPLICATION_PIPELINE_REPORT = 'ApplicationPipelineReport',
  AFFILIATED_COMPANY = 'AffiliatedCompany',
  ALERTS = 'Alerts',
  BACK_OFFICE_STATISTICS = 'BackOfficeStatistics',
  BANK = 'Bank',
  BANK_APPLICATION = 'BankApplication',
  APPLICATION_BANK_ACCOUNTS = 'ApplicationBankAccounts',
  BANK_PORTAL = 'BankPortal',
  BANK_ACCOUNT_DETAILS = 'BankAccountDetails',
  CLIENT_PORTAL = 'ClientPortal',
  BIOTRACK_KEY = 'BiotrackKey',
  BSA = 'BSA',
  CALENDAR = 'Calendar',
  CALENDAR_EVENT = 'CalendarEvent',
  CALENDAR_EVENT_CATEGORY = 'CalendarEventCategory',
  CLIENT_APPLICATION = 'ClientApplication',
  COMPANY = 'Company',
  COMPANY_DETAILS = 'CompanyDetails',
  COMPANY_DETAILS_CONTACTS_LIST = 'CompanyDetailsContactsList',
  CONFIGURATION = 'Configuration',
  CONNECTION = 'Connection',
  CONTACT = 'Contact',
  DOCUMENT = 'Document',
  DOCUMENT_FILE = 'DocumentFile',
  DOCUMENT_PERIOD = 'DocumentPeriod',
  INTERNAL_TRANSFER = 'InternalTransfer',
  INTERNAL_TRANSFER_EXPORT = 'InternalTransferExport',
  INVOICE = 'Invoice',
  LICENSE = 'License',
  METRC_KEY = 'MetrcKey',
  MFA = 'MFA',
  MR = 'MR',
  QR = 'QR',
  ORGANISATION = 'Organisation',
  RELATIONSHIPS = 'Relationships',
  RELATIONSHIPS_REPORT = 'RelationshipsReport',
  SALES_DATA = 'SalesData',
  TASK = 'Task',
  TEST = 'Test',
  USER = 'User',
  USER_LIST = 'UserList',
  CLIENT_USER = 'ClientUser',
  WEB_PAGE_DOCUMENT = 'WebPageDocument',
  WEB_PAGE_ONBOARDING = 'WebPageOnboarding',
  HISTORY_AUDIT = 'HistoryAudit',
  BANK_DOCUMENT_ONBOARDING_SIGNERS = 'BankDocumentOnboardingSigners'
}
