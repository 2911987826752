import React from 'react'

import { FF, SecuredInput, Validators } from 'ui/components'

import { useSARFormContext } from '../../../../../../context'
import { useSubjectInformationItemContext } from '../SubjectInformationItemContext'

type TINProps = {
  index: number
}

export const TIN = ({ index }: TINProps) => {
  const { formApi } = useSARFormContext()
  const { isAllInfoUnavailable } = useSubjectInformationItemContext()

  return (
    <formApi.Field
      name={`subjects_info[${index}].tin` as 'subjects_info[0].tin'}
      validators={{
        onChange: ({ value }) => (isAllInfoUnavailable ? undefined : Validators.required.field(value))
      }}
      children={(field) => (
        <FF
          required={!isAllInfoUnavailable}
          field={field}
          label="16. TIN"
          input={(p) => <SecuredInput {...p} disabled={isAllInfoUnavailable} maxLength={25} data-qa="16. TIN" />}
        />
      )}
    />
  )
}
