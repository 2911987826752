import { useRouter } from '@tanstack/react-router'
import { handlerServerError } from 'errors'
import { DialogFace, Toast } from 'ui/components'
import { useConfirmation } from 'ui/hooks'
import { useDeleteDocumentPeriodFile } from '~bank-documents/service/deleteDocFile'

export const useDeleteFile = (onSuccessDeleting?: () => void) => {
  const router = useRouter()

  const { routine, isLoading } = useDeleteDocumentPeriodFile({
    onSuccess: async () => {
      Toast.successSmall('Document file was deleted.')
      onSuccessDeleting()
      router.invalidate()
    },
    onError: (error) => handlerServerError(error)
  })

  const { open: openDeleteConfirmation, Confirmation: DeleteConfirmation } = useConfirmation<{ fileId: number }>({
    face: DialogFace.PRIMARY,
    title: 'Delete File',
    message: 'Are you sure you want to delete this file?',
    onConfirm: async ({ fileId }) => {
      await routine(fileId)
    },
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  return {
    openDeleteConfirmation,
    DeleteConfirmation,
    isDeleting: isLoading
  }
}
