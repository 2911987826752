import SC from 'styled-components'

export const Question = SC.div<{ $bottomIndent?: boolean }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: ${(props) => (props.$bottomIndent ? '42px' : '20px')};
`

export const Label = SC.div<{ $labelPadding?: boolean }>`
  font-style: italic;
  margin-right: 32px;
  padding-top: ${(props) => (props.$labelPadding ? '10px' : '0')};
`

export const Value = SC.div`
  margin-top: 8px;
  flex-grow: 1;
  width: 100%;
`
