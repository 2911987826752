import { useCallback } from 'react'

import { FormChangedHandler, useFormData } from 'brief-form'

import { useSyncFormData } from '../../hooks'
import {
  CompanyMailingAddressChangeHandler,
  CompanyMailingAddressFormData,
  CompanyMailingAddressFormValue
} from '../../types/forms'

export const useCompanyMailingAddressForm = ({
  companyIndex,
  formData,
  onChange
}: {
  companyIndex: number
  formData: CompanyMailingAddressFormData

  onChange: CompanyMailingAddressChangeHandler
}) => {
  const onFormChanged: FormChangedHandler<CompanyMailingAddressFormValue> = useCallback(
    (formValues, formErrors) => onChange({ companyIndex, formData: { formValues, formErrors } }),
    [companyIndex, onChange]
  )

  const { config, Field, set } = useFormData<CompanyMailingAddressFormValue>({
    onFormChanged,
    initialValue: formData.formValues,
    initialErrors: formData.formErrors
  })

  useSyncFormData({ set, formData })

  return { Field, config }
}
