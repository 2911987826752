import React, { FC, memo, useCallback } from 'react'

import { useFormData } from 'brief-form'
import { Request } from 'commons/utils/request'
import { useTableState } from 'query-table'
import { Page, PageFace } from 'ui/components/Page'
import { Panel, PanelFace } from 'ui/components/Panel'

import { ExportCSVButton, ReportBreadcrumbs } from '../../components'
import { SICSAPIStatusReportTable } from './SICSAPIStatusReportTable'
import { SICSAPIStatusReportFilterValue } from './types'

const SICSAPIStatusReport: FC = memo(() => {
  const tableState = useTableState({
    filter: {},
    order: [{ field: 'relationship_name', direction: 'ASC' }],
    pagination: {
      page: 1,
      size: 20
    }
  })

  const filterForm = useFormData<SICSAPIStatusReportFilterValue>({
    initialValue: {
      relationship_id: 0,
      license_number: '',
      relationship_name: '',
      license_name: '',
      license_state: '',
      connected: false // MP-7563: ... default show `not connected`
    }
  })

  const originalExportsChange = filterForm.config.onChange
  filterForm.config.onChange = useCallback(
    (v, e) => {
      tableState.pagination.change(1, tableState.pagination.size)
      originalExportsChange(v, e)
    },
    [originalExportsChange, tableState]
  )

  return (
    <Page
      face={PageFace.PRIMARY}
      title={<ReportBreadcrumbs title="State Inventory Control Systems API Status Report" />}
      actions={
        <ExportCSVButton
          reportName="Systems API Status"
          url={Request.urls.bank.portalReportLicensesCSV}
          parametersForm={filterForm.config.value}
        />
      }
    >
      <Panel rounded shadowed collapsible={false} face={PanelFace.SECONDARY}>
        <SICSAPIStatusReportTable tableState={tableState} filterForm={filterForm} />
      </Panel>
    </Page>
  )
})

export default SICSAPIStatusReport
