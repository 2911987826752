import { getInternalTransfer } from 'commons/service/bank/internalTransfer'
import { InternalTransferDeprecated } from 'commons/types/DTO/bank'
import { ApiReadResponse } from 'commons/types/api'
import { Keys, ReadHook, useStateManager } from 'state-manager'

type UseGetInternalTransfer = {
  id: number
  enabled: boolean
}

export const useGetInternalTransferList: ReadHook<ApiReadResponse<InternalTransferDeprecated>> = (
  { id, enabled }: UseGetInternalTransfer,
  callbacks
) => {
  const sm = useStateManager()

  return sm.use(Keys.InternalTransfer.ById(id), () => getInternalTransfer(id), {
    enabled,
    ...callbacks
  })
}
