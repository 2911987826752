import styled from 'styled-components'

const Box = styled.div`
  padding-top: 28px;
  padding-bottom: 32px;
  padding-left: 32px;
  padding-right: 32px;
`

export const s = { Box }
