/*
  Route path and their types declarations

  Warning:
    Here we can't inherit one path by another, coz for unknown reasons typescript doesn't understand resulted const
    in the generatePath function after importing that one and strong types doens't work at all.
    But if we typing something like `${basePath}/:param` directly in the generatePath, that works.
    Some research needed.
*/

export const ADVERSE_MEDIA_PATH = '/adverse-media'
export const CLIENT_INTERNAL_TRANSFERS_PATH = '/companies/:companyId/internal-transfers'
export const CLIENT_INVOICES_PATH = '/companies/:companyId/invoices'
export const COMPANY_SECTION_PATH = '/companies/:companyId'
export const APP_SETTINGS_PATH = '/app-settings'
export const CLIENT_USERS_PATH = '/app-settings/users'
export const BANK_USERS_PATH = '/users'
export const BANK_INVOICES_PATH = '/invoices'
export const BANK_CLIENT_USERS_PATH = '/client-users'
export const CLIENT_USER_COMPANIES_PATH = '/app-settings/companies'
export const CLIENT_CONNECTIONS_PATH = '/app-settings/connections'
export const CLIENT_USER_PROFILE_PATH = '/app-settings/user-profile'
export const INTERNAL_TRANSFERS_PATH = '/internal-transfers'
export const COMPANIES_PATH = '/relationships'
export const COMPANY_PATH = '/relationships/:companyId'
export const LICENSES_PATH = '/licenses'
export const LICENSES_PATH_DETAILS = `/licenses/:licenseId`
export const DOCUMENTS_PATH = '/documents'
export const CLIENT_TASKS_PATH = '/tasks'
export const CLIENT_ONBOARDING_PATH = '/onboarding'
export const BANK_ONBOARDING_EDIT_PATH = '/onboarding/applications/edit'
export const NEXT_CLOUD_LOGIN = '/nextcloud'
export const BANK_ALERTS = '/alerts'

export const BANK_ANNUAL_REVIEW = '/annual-review'
export const BANK_ANNUAL_REVIEW_DETAIL = '/annual-review/detail'
export const NOT_FOUND_PATH = '/404'
export const CONTACTS_PATH = '/contacts'
export const BANK_ACCOUNTS = '/bank-accounts'
export const CALENDAR_PATH = `/calendar`
export const REPORTS_PATH = '/reports'
export const HISTORY_PATH = '/history'
export const BSA_MODULE_PATH = '/bsa'
export const KEY_CONNECTIONS_PATH = '/key-connections'

export const BSA_CTR_PATH = '/bsa/ctrs'
export const BSA_SARS_PATH = '/bsa/sars'

export const BSA_CTR_BATCH_PATH = '/bsa/ctr-batch-list'
export const TERMS_CONDITION = '/terms-conditions'
export const PRIVACY_POLICY = '/privacy-policy'
export const AUTH_PATH = '/auth'
export const MR_PATH = '/mr'
export const QR_PATH = '/qr'
export const SALESDATA_PATH = '/salesdata'

// Companies/Relationships

export enum CompaniesPath {
  EDIT = '/relationships/edit/$companyId',
  CREATE = '/relationships/create',
  DETAILS = '/relationships/$companyId',
  LIST = '/relationships/'
}

// Licenses

export enum LicensesPath {
  DETAILS = '/licenses/$licenseId',
  CREATE = '/licenses/add',
  EDIT = '/licenses/$id/edit'
}

// Contacts

export enum ContactsPath {
  DETAILS = '/contacts/$contactId'
  // create = '/licenses/add',
  // edit = '/licenses/$id/edit'
}

// Bank Accounts
export enum BankAccountsPath {
  DETAILS = '/bank-accounts/$bankAccountId',
  EDIT = '/bank-accounts/$bankAccountId/edit'
}

/* BP Applications + Onboarding */
export enum BankApplicationPaths {
  LIST = '/onboarding/applications',
  DETAILS = '/onboarding/applications/$applicationId',
  EDIT = `/onboarding/applications/edit/$applicationId`
}

// using in router
export enum BankApplicationsRouterPaths {
  INDEX = `/onboarding/applications`,
  LIST = `/`,
  DETAILS = `$applicationId`,
  EDIT = `edit/$applicationId`
}

export enum ClientOnboardingPath {
  EDIT = `/onboarding/$applicationId`
}
