import React, { FC, memo, useCallback, useEffect, useState } from 'react'

import { useRouterState } from '@tanstack/react-router'
import { Tooltip } from 'ui/components'
import { MenuItem } from 'ui/components/SideMenu/components/MenuItem'

import { MainMenuProps } from './MainMenuProps'
import { Toggle } from './Toggle'

import { MainMenuBox } from './styles'

export const MainMenu: FC<MainMenuProps> = memo((props) => {
  const { items, minimized, secondaryMenuPane } = props
  const [isMainActive, setMainActive] = useState(true)
  const { pathname } = useRouterState({ select: (s) => s.location })
  const onClickMain = useCallback(() => setMainActive(true), [setMainActive])
  const onClickPage = useCallback(() => setMainActive(false), [setMainActive])

  useEffect(() => {
    setMainActive(!secondaryMenuPane)
  }, [secondaryMenuPane, setMainActive])

  return (
    <>
      <Toggle
        isMainActive={isMainActive}
        isVisible={!!secondaryMenuPane}
        isMinimized={minimized}
        onClickMain={onClickMain}
        onClickPage={onClickPage}
      />
      {isMainActive ? (
        <MainMenuBox>
          {items.map((item, index) => (
            <Tooltip key={index} id={item.to} type="dark" place="right" isVisible={minimized} content={item.children}>
              <MenuItem
                {...item}
                iconName={item.icon}
                isMinimized={minimized}
                isActive={pathname.indexOf(item.to) === 0}
              >
                {item.children}
              </MenuItem>
            </Tooltip>
          ))}
        </MainMenuBox>
      ) : (
        secondaryMenuPane
      )}
    </>
  )
})
