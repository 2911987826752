import React, { memo } from 'react'

import * as SC from './styles/ButtonInputStyles'
import { Button } from 'ui/components/Buttons'
import { TextInput } from 'ui/components/InputV2'
import { InputHint } from 'ui/core/InputHint'

import { useEmpty } from './hooks'
import { InputGroupProps } from './props'

export const ButtonInput = memo((props: InputGroupProps) => {
  const { value, onChange, error, disabled, hint, buttonText, ...rest } = props
  const { isEmpty, handleChange } = useEmpty(value, onChange)

  return (
    <SC.Wrapper {...rest}>
      <SC.Inner>
        <TextInput
          disabled={disabled}
          hideHint // we are rendering hint here (in this file/component, below), so turn it off for input
          value={value}
          onChange={handleChange}
          error={error}
          className={SC.CLASSNAME}
          {...rest}
        />
        <Button disabled={isEmpty || disabled || !!error} face="positive" className={SC.CLASSNAME}>
          {buttonText}
        </Button>
      </SC.Inner>
      <InputHint hint={hint} disabled={disabled} />
    </SC.Wrapper>
  )
})
