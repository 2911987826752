import { useCallback, useState } from 'react'

import { useFormData } from 'brief-form'
import { AffiliatedCompany } from 'commons/types/DTO/bank'

import { MappedAffiliatedCompany } from '../../AffiliatedCompaniesTable/MappedAffiliatedCompany'

export const useComponentEssence = (
  initialValue: MappedAffiliatedCompany,
  onSubmit: (item: MappedAffiliatedCompany) => Promise<AffiliatedCompany>
) => {
  const { config, validate, isDirty, isValid, Field } = useFormData<MappedAffiliatedCompany>({ initialValue })
  const [isSubmitting, setIsSubmitting] = useState(false)

  const onSubmitWrapper = useCallback(() => {
    if (!validate({ updateFields: true }).valid) {
      return Promise.resolve()
    }
    setIsSubmitting(true)
    return onSubmit(config.value).finally(() => setIsSubmitting(false))
  }, [onSubmit, config.value, validate])

  const validateAffiliated = useCallback((v: number | string, f: MappedAffiliatedCompany) => {
    if (!v) {
      return 'Required'
    }
    if (+v === f.mainId) {
      return 'Cannot link company to itself'
    }
    return undefined
  }, [])

  return {
    config,
    Field,
    onSubmitWrapper,
    validateAffiliated,
    isDirty,
    isSubmitting,
    isValid
  }
}
