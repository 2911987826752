import React, { FC, memo } from 'react'

import { Form } from 'brief-form'
import { useGetBankName } from 'commons/hooks/bank/organisation'
import {
  LicenseType,
  getLicenseSubTypesOnboardList,
  licensePosTypes,
  licenseTypes,
  selectStateListWithAll
} from 'commons/types/dictionaries'
import { BankName } from 'commons/types/enums'
import { Toggle } from 'ui/components'
import { DatePicker } from 'ui/components/DatePicker'
import { Hr } from 'ui/components/Hr'
import { PercentInput, TextInput, USPhoneInput } from 'ui/components/InputV2'
import { Select } from 'ui/components/Select'
import { Col, FormRow } from 'ui/themes/globalStyles'

import { ManageLicenseFormProps } from './ManageLicenseFormProps'

import * as SC from './styles'

export const ManageLicenseForm: FC<ManageLicenseFormProps> = memo((props) => {
  const { companyName, Confirmation, config, Field } = props
  const bankName = useGetBankName()
  const isBison = bankName.id === BankName.BISON
  const isShore = bankName.id === BankName.SHORE
  const isCBT = bankName.id === BankName.CBT

  let filteredLicenseType: LicenseType[]

  filteredLicenseType = isBison
    ? licenseTypes.filter((e) => e.value !== 'rec' && e.value !== 'medrec')
    : licenseTypes.filter((e) => e.value !== 'comp' && e.value !== 'medrec')

  if (isShore) filteredLicenseType = licenseTypes.filter((e) => e.value !== 'comp')

  // https://helioscompliance.atlassian.net/browse/MP-6617
  const filteredLicenseSubTypes = isCBT
    ? getLicenseSubTypesOnboardList()
    : getLicenseSubTypesOnboardList().filter((e) => e.value !== 'operator')

  return (
    <SC.Box>
      <Confirmation />
      <Form config={config}>
        <SC.FieldSetStyled legend="License Details">
          <FormRow>
            <Col>
              <Field
                required
                name="name"
                label="Name"
                input={TextInput}
                inputProps={{
                  'data-qa': 'License Name',
                  maxLength: 100
                }}
                validator={(v) => (v ? undefined : 'Name is required')}
              />
            </Col>
            <Col>
              <Field
                name="active"
                label="Active"
                input={Toggle}
                inputProps={{
                  'data-qa': 'Active',
                  label: ''
                }}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <Field
                name="companyId"
                label="Customer"
                input={Select}
                inputProps={{
                  data: [{ label: companyName, value: config.value.companyId }],
                  'options-data-qa': 'opt',
                  disabled: true
                }}
              />
            </Col>
            <Col>
              <Field
                required
                name="type"
                input={Select}
                inputProps={{
                  'data-qa': 'License Type',
                  'options-data-qa': 'License Type',
                  data: filteredLicenseType
                }}
                label="Type"
                validator={(v) => (v ? undefined : 'Type is required')}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <Field
                required
                name="licenseNumber"
                label="License Number"
                input={TextInput}
                inputProps={{
                  'data-qa': 'License Number',
                  maxLength: 100
                }}
                validator={(v) => (v ? undefined : 'License Number is required')}
              />
            </Col>
            <Col>
              <Field
                required
                name="subtype"
                input={Select}
                inputProps={{
                  'data-qa': 'License Subtype',
                  'options-data-qa': 'License Subtype',
                  data: filteredLicenseSubTypes
                }}
                label="Subtype"
                validator={(v) => (v ? undefined : 'License Subtype is required')}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <Field
                required
                name="issueDate"
                label="Issue Date"
                input={DatePicker}
                validator={(v) => (v ? undefined : 'Issue date is required')}
                inputProps={{}}
              />
            </Col>
            <Col>
              <Field
                required
                name="posType"
                input={Select}
                inputProps={{
                  'data-qa': 'POS Type',
                  'options-data-qa': 'POS Type',
                  data: licensePosTypes
                }}
                label="POS Type"
                validator={(v) => (v ? undefined : 'POS Type is required')}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <Field
                name="phone"
                label="Phone"
                input={USPhoneInput}
                inputProps={{
                  'data-qa': 'Phone',
                  maxLength: 25
                }}
              />
            </Col>
            <Col>
              <SC.VisibilityBox $visible={config.value.posType == 'other'}>
                <Field
                  required={config.value.posType == 'other'}
                  name="posTypeOther"
                  input={TextInput}
                  inputProps={{
                    'data-qa': 'POS Type Other',
                    maxLength: 100
                  }}
                  label="POS Type Other"
                  validator={(v, f) => (!v && f.posType == 'other' ? 'POS Type Other is required' : undefined)}
                />
              </SC.VisibilityBox>
            </Col>
          </FormRow>
          <Hr />
        </SC.FieldSetStyled>
        <SC.FieldSetStyled legend="Tax rates for License">
          <FormRow>
            <Col>
              <Field
                name="cityTax"
                label="City Tax"
                input={PercentInput}
                inputProps={{
                  'data-qa': 'City Tax'
                }}
              />
            </Col>
            <Col>
              <Field
                name="countyTax"
                label="County Tax"
                input={PercentInput}
                inputProps={{
                  'data-qa': 'County Tax'
                }}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <Field
                name="mjRetailTax"
                label="MJ Retail Tax"
                input={PercentInput}
                inputProps={{
                  'data-qa': 'MJ Retail Tax'
                }}
              />
            </Col>
            <Col>
              <Field
                name="stateTax"
                label="State Tax"
                input={PercentInput}
                inputProps={{
                  'data-qa': 'State Tax'
                }}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <Field
                name="specialTax"
                label="Special Tax"
                input={PercentInput}
                inputProps={{
                  'data-qa': 'Special Tax'
                }}
              />
            </Col>
          </FormRow>
          <Hr />
        </SC.FieldSetStyled>
        <SC.FieldSetStyled legend="Address Information">
          <FormRow>
            <Col>
              <Field
                name="streetAddress"
                label="Street Address"
                input={TextInput}
                inputProps={{
                  'data-qa': 'Street Address',
                  maxLength: 100
                }}
              />
            </Col>
            <Col>
              <Field
                required
                name="state"
                label="State"
                input={Select}
                inputProps={{
                  'data-qa': 'State',
                  'options-data-qa': 'State',
                  data: selectStateListWithAll
                }}
                validator={(v) => (v ? undefined : 'State is required')}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <Field
                name="postalCode"
                label="Zip Code"
                input={TextInput}
                inputProps={{
                  'data-qa': 'Zip Code',
                  maxLength: 25
                }}
              />
            </Col>
            <Col>
              <Field
                name="city"
                label="City"
                input={TextInput}
                inputProps={{
                  'data-qa': 'City',
                  maxLength: 100
                }}
              />
            </Col>
          </FormRow>
        </SC.FieldSetStyled>
      </Form>
    </SC.Box>
  )
})
