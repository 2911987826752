import React from 'react'

import { FF, Toggle, Validators } from 'ui/components'

import { CompanySectionPanel, TemplatePanel } from '../../../../components'
import { s } from '../../styles'
import { getCorrectFieldInput } from '../../utils/getCorrectFieldInput'
import { CompanyDocumentFormSectionProps } from './CompanyDocumentFormSection.types'

export const CompanyDocumentFormSection = ({ form, companyIndex, company }: CompanyDocumentFormSectionProps) => (
  <form.Field name={`companiesData[${companyIndex}].documents`} mode="array">
    {(documentsField) => (
      <CompanySectionPanel title="Company Documents">
        {documentsField.state.value.map((document, documentIndex) => {
          const { name } = company.companyDocuments[document.id]
          const isDocumentSelected = form.useStore(
            (s) => s.values.companiesData[companyIndex].documents[documentIndex].isSelected
          )

          return (
            <TemplatePanel
              key={documentIndex}
              title={name}
              expanded={isDocumentSelected}
              disabled={!isDocumentSelected}
              actions={
                <form.Field name={`companiesData[${companyIndex}].documents[${documentIndex}].isSelected`}>
                  {(documentToggleField) => (
                    <Toggle value={documentToggleField.state.value} onChange={documentToggleField.handleChange} />
                  )}
                </form.Field>
              }
            >
              <s.FormBox>
                <form.Field name={`companiesData[${companyIndex}].documents[${documentIndex}].fields`} mode="array">
                  {(ff) =>
                    ff.state.value.map((documentField, documentFieldIndex) => (
                      <form.Field
                        key={documentFieldIndex}
                        // eslint-disable-next-line max-len
                        name={`companiesData[${companyIndex}].documents[${documentIndex}].fields[${documentFieldIndex}].value`}
                        validators={{
                          onSubmitAsync: ({ value }) => Validators.required.field(value)
                        }}
                        children={(field) => (
                          <FF
                            label={documentField.label}
                            field={field}
                            input={(props) =>
                              getCorrectFieldInput({
                                fieldName: documentField.externalKey,
                                props: { ...props, required: true }
                              })
                            }
                          />
                        )}
                      />
                    ))
                  }
                </form.Field>
              </s.FormBox>
            </TemplatePanel>
          )
        })}
      </CompanySectionPanel>
    )}
  </form.Field>
)
