import { useCallback, useMemo, useState } from 'react'

import { useFormData } from 'brief-form'
import { FilterCompanyVendorsWebPage } from 'commons/service/bank/companyDetails'
import { useTableState } from 'ui/components/QueryTable'

export const useComponentsEssence = (
  companyId: number,
  onFilterCompanyVendorswWebPage: (filter: FilterCompanyVendorsWebPage) => void
) => {
  const [loading, setLoading] = useState(false)

  const defaultFilter = useMemo(
    () => ({
      relationshipID: companyId,
      vendorsOffset: undefined,
      vendorsLimit: undefined,
      vendorsName: undefined
    }),
    [companyId]
  )

  const [tableFilter, setTableFilter] = useState<FilterCompanyVendorsWebPage>(defaultFilter)

  const tableState = useTableState({ filter: {} })

  const changePagination = useCallback(
    async (page: number, size: number) => {
      setLoading(true)

      tableState.onPageChange(page, size)

      const filter = {
        ...tableFilter,
        vendorsOffset: (page - 1) * size,
        vendorsLimit: size
      }

      setTableFilter(filter)

      await onFilterCompanyVendorswWebPage(filter)

      setLoading(false)
    },
    [onFilterCompanyVendorswWebPage, tableFilter, tableState]
  )

  const onFilterChange = async (value: FilterCompanyVendorsWebPage) => {
    tableState.filterForm.set({ value })

    setLoading(true)

    const filter = {
      ...value,
      VendorsOffset: undefined,
      VendorsLimit: undefined
    }

    setTableFilter(filter)

    await onFilterCompanyVendorswWebPage(filter)

    setLoading(false)
  }

  const filterForm = useFormData<FilterCompanyVendorsWebPage>({
    initialValue: {
      relationshipID: companyId,
      vendorsOffset: undefined,
      vendorsLimit: undefined,
      vendorsName: undefined
    }
  })

  const originalChangeHandler = filterForm.config.onChange
  filterForm.config.onChange = useCallback(
    (v, e) => {
      onFilterChange(v)
      originalChangeHandler(v, e)
    },
    [originalChangeHandler, tableState]
  )

  const clearFilter = useCallback(() => {
    onFilterChange(defaultFilter)
    filterForm.set({ reset: true })
  }, [onFilterChange, defaultFilter, filterForm])

  return {
    loading,
    tableState,
    filterForm,
    changePagination,
    clearFilter
  }
}
