import React, { FC, memo, useCallback, useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'

import { Link, getRouteApi } from '@tanstack/react-router'
import { useFormData } from 'brief-form'
import { BsaSar } from 'commons/types/DTO/bank/bsa'
import { isValid } from 'date-fns'
import { Button } from 'ui/components/Buttons'
import { IconName, IconSize } from 'ui/components/Icon'
import { Page, PageFace } from 'ui/components/Page'
import { PanelFace } from 'ui/components/Panel'
import { TableData, useTableState } from 'ui/components/QueryTable'
import { Toast } from 'ui/components/Toast'
import { useConfirmation } from 'ui/hooks'
import { SAR } from '~bank-bsa-reporting/hooks'
import { Batch } from '~bank-bsa-reporting/types/sar'

import { DownloadXMLDialog, Footer, Table, Warnings } from './components'
import { Filter } from './components/Table/components'

import { Arrow, TablePanel, Title } from './styles'

const routeApi = getRouteApi('/bsa/sars')

const SARList: FC = memo(() => {
  const [chosenItems, setChosenItems] = useState<any[]>([])
  const [generatedBatch, setGeneratedBatch] = useState<null | Batch>(null)
  const [isGeneratingXML, setIsGeneratingXML] = useState(false)
  const [itemToDelete, setItemToDelete] = useState<BsaSar | null>(null)
  const [warnings, setWarnings] = useState<string | null>(null)
  const [tableData, setTableData] = useState<BsaSar[]>()
  const queryClient = useQueryClient()
  const deleteMutation = SAR.useDelete(queryClient)
  const xmlMutation = SAR.useXMLGeneration(setGeneratedBatch)

  const search = routeApi.useSearch()
  const navigate = routeApi.useNavigate()

  const { config, Field, set, isDirty } = useFormData<Filter>({ initialValue: new Filter() })

  const tableState = useTableState({
    filter: {},
    order: [{ field: 'updated_at', direction: 'DESC' }]
  })

  useEffect(() => {
    const transStartDate = search.trans_start_date
    const isStartDateValid = transStartDate && isValid(new Date(transStartDate))
    const transEndDate = search.trans_end_date
    const isEndDateValid = transEndDate && isValid(new Date(transEndDate))

    if (isStartDateValid && isEndDateValid) {
      config.value.trans_end_date = search.trans_end_date
      config.value.trans_start_date = search.trans_start_date
    }
  }, [])

  const onItemSelect = (item?: any) => {
    if (!item) {
      if (chosenItems.length < (tableData?.length || 0)) {
        setChosenItems((tableData || []).map((i) => ({ id: i.id })))
      } else {
        setChosenItems([])
      }
    } else {
      const index = chosenItems.findIndex((i) => i.id === item.id)
      if (index === -1) {
        setChosenItems(chosenItems.concat(item))
      } else {
        const copy = [...chosenItems]
        copy.splice(index, 1)
        setChosenItems(copy)
      }
    }
  }

  const { open: startGenerateXML, Confirmation: GenerateXMLConfirmation } = useConfirmation({
    message: (
      <span>
        Are you sure you want to generate Batch from{' '}
        <i>{chosenItems.length === 1 ? chosenItems[0].name_desc : 'these'}</i> SAR(s)?
      </span>
    ),
    onConfirm: () => {
      setIsGeneratingXML(true)
      xmlMutation
        .mutateAsync(chosenItems.map((i) => i.id))
        .then(() => {
          setChosenItems([])
        })
        .finally(() => {
          setIsGeneratingXML(false)
        })
    },
    confirmationButtonText: 'Generate'
  })

  const { open: startDeleting, Confirmation: DeleteConfirmation } = useConfirmation({
    message: (
      <span>
        Are you sure you want to delete{' '}
        <i>{itemToDelete || chosenItems.length === 1 ? (itemToDelete || chosenItems[0]).name : 'these'}</i> SAR(s)?
      </span>
    ),
    onConfirm: () =>
      deleteMutation.mutateAsync(itemToDelete ? [itemToDelete.id] : chosenItems.map((i) => i.id)).then(() => {
        setChosenItems([])
        setItemToDelete(null)
        Toast.successSmall(`SAR #${itemToDelete.id} was successfully deleted.`)
      }),
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  const onCloseXMLDialog = () => {
    if (isGeneratingXML) {
      return
    }

    setGeneratedBatch(null)
  }

  const onDeleteFromTableMenu = useCallback(
    (item: BsaSar) => {
      setItemToDelete(item)
      startDeleting()
    },
    [setItemToDelete, startDeleting]
  )

  const onGoToNewSAR = useCallback(() => {
    navigate({ to: '/bsa/sars/$id/edit', params: { id: '0' } })
  }, [navigate])

  const onShowWarnings = useCallback(
    (data: string) => {
      setWarnings(data)
    },
    [setWarnings]
  )

  const onReturnData = (data: TableData) => {
    if (data?.data?.list?.records) {
      setTableData(data?.data?.list?.records)
    }
  }

  return (
    <Page
      face={PageFace.PRIMARY}
      title={
        <Title>
          <Link to="/bsa">BSA Reporting</Link>
          <Arrow name={IconName.ARROW_SMALL} size={IconSize.XS} />
          SARs List
        </Title>
      }
      actions={
        <Button face="neutral" onClick={onGoToNewSAR} data-qa="New SAR">
          New SAR
        </Button>
      }
    >
      <DeleteConfirmation />
      <GenerateXMLConfirmation />
      <Warnings warnings={warnings} onClose={() => setWarnings(null)} />
      <DownloadXMLDialog batch={generatedBatch} isGenerating={isGeneratingXML} onClose={onCloseXMLDialog} />
      <TablePanel rounded shadowed collapsible={false} face={PanelFace.SECONDARY}>
        <Table
          tableState={tableState}
          onItemSelect={onItemSelect}
          onDelete={onDeleteFromTableMenu}
          onShowWarnings={onShowWarnings}
          chosenItems={chosenItems}
          isAllItemsChosen={chosenItems.length === tableState.size}
          onReturnData={onReturnData}
          isDirty={isDirty}
          Field={Field}
          reset={() => set({ reset: true })}
          config={config}
        />
      </TablePanel>
      <Footer hasChosenItems={!!chosenItems.length} onDeleteSARs={startDeleting} onXMLGenerate={startGenerateXML} />
    </Page>
  )
})

export default SARList
