import React, { memo } from 'react'

import { FieldRendererProps, FormInputProps } from 'brief-form'
import { Label } from 'ui/temp/Label'

import { FormFieldProps } from './FormFieldProps'
import * as SC from './style'

/**
 * This class names are needed to access the error style inside the components.
 */
export enum FormFieldClassNames {
  FORM_FIELD_ERROR = 'formfield_error',
  ERROR_VISIBLE = 'formfield_error_visible'
}

interface GetContentProps {
  children: React.ReactElement<unknown & FormInputProps<unknown, unknown>>
  position: string
  error?: string | { [key: string]: string }
  name: string
}

const getContent = ({ children, position, error, name }: GetContentProps) => {
  const errorText = typeof error === 'string' ? error : 'Field is filled with errors'
  const commonProps = {
    ['data-qa']: `Error ${errorText} ${name}`,
    error: !!error,
    className: error
      ? `${FormFieldClassNames.FORM_FIELD_ERROR} ${FormFieldClassNames.ERROR_VISIBLE}`
      : `${FormFieldClassNames.FORM_FIELD_ERROR}`
  }
  switch (position) {
    case 'bottom':
      return (
        <>
          {children}
          {!!error && <SC.Error {...commonProps}>{errorText}</SC.Error>}
        </>
      )
    case 'right':
      return (
        <SC.ErrorWrapperRight>
          {children}
          <SC.Error {...commonProps}>{errorText}</SC.Error>
        </SC.ErrorWrapperRight>
      )
    case 'none':
      return <>{children}</>
    default:
      return null
  }
}

export const FormField = memo(
  <ValueType, InputProps>(props: FieldRendererProps<ValueType, InputProps, FormFieldProps>) => {
    const { label, children, error, name, fieldProps, containerRef, dataId, ...rest } = props
    const position = fieldProps && fieldProps.errorPosition ? fieldProps.errorPosition : 'bottom'
    const { suffix, render, value } = dataId
    const attrs = {}

    if (render) {
      attrs[`data-${suffix}`] = value
    }

    return (
      <SC.Container {...rest} {...attrs} ref={containerRef}>
        {!!label && <Label $error={!!error}>{label}</Label>}
        {getContent({ children, position, error, name })}
      </SC.Container>
    )
  }
)
