{
  "name": "Herring",
  "logo": {
    "_1x": "logo1x.png",
    "_2x": "logo2x.png",
    "_3x": "logo3x.png"
  },
  "authLogo": {
    "_1x": "authLogo1x.png",
    "_2x": "authLogo2x.png",
    "_3x": "authLogo3x.png"
  },
  "background": {
    "_1x": "bg1x.png",
    "_2x": "bg2x.png",
    "_3x": "bg3x.png"
  },
  "uiKit": {
    "colors": {
      "theme": {
        "brand": {
          "lightest": "#C6E295",
          "lighter": "#A0CE4E",
          "primary": "#1D8355",
          "darker": "#176944",
          "darkest": "#114F33"
        },
        "gradient": {
          "login": "linear-gradient(200deg, #1D8355 0%, #176944 100%)"
        },
        "positive": {
          "main": "#1D8355",
          "darker": "#176944",
          "darkest": "#114F33"
        },
        "warning": {
          "main": "#FF822E"
        }
      }
    },
    "borderRadius": {
      "default": "0px",
      "roundedModal": "0px"
    },
    "typography": {
      "bodyM": {
        "button": {
          "fontFamily": "Source Sans Pro",
          "fontWeight": "700",
          "lineHeight": "16px",
          "fontSize": "14px",
          "letterSpacing": "0.2px",
          "paragraphSpacing": "0px",
          "textCase": "capitalize",
          "textDecoration": "none"
        }
      },
      "bodyL": {
        "buttonL": {
          "fontFamily": "Source Sans Pro",
          "fontWeight": "700",
          "lineHeight": "20px",
          "fontSize": "16px",
          "letterSpacing": "0.2px",
          "paragraphSpacing": "0px",
          "textDecoration": "none",
          "textCase": "capitalize"
        }
      }
    }
  }
}
