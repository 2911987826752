import { ApplicationStatus } from 'commons/types/enums'

import { OnboardingFormState } from '../../types/state'

// import { getEmptyCompany } from './helpers/emptyEntity/getEmptyCompany'

/**
 * https://www.notion.so/CP-Onboard-State-0e6a5fdc7adc4c3f82268227cfec8af0
 */

// const initCompany = getEmptyCompany()

export const onboardingFormInitValue: OnboardingFormState = {
  applicationStatus: ApplicationStatus.RFI_PENDING,
  applicationName: '',
  bankName: '',
  bankNotes: '',
  bankPercents: 0,
  currentStep: 0,

  availableSteps: [],
  proxyWebpageData: {
    updatedAt: '' // "2023-11-13T12:48:43.035222Z"
  },

  // form state
  companiesDiff: [], // initCompany,
  formValue: {
    notes: {
      formValues: {
        clientNotes: ''
      },
      formErrors: {}
    },
    companiesFormValues: []
  },
  formsState: {
    accoutDetails: {
      /** isCBT */
      isConsumerAccountAvailable: true
    },
    licenseDetails: {
      /** isBison */
      isLicenseMetrcApiKeyEnable: true
    },
    contactDetails: {
      /** isCBT */
      isMobileEnable: true,
      /** isCBT */
      isHomePhoneEnable: true,
      /** isCBT */
      isOtherMjRelatedBusinessEnable: true,
      /** !isHerring && !isINB && !isCBT */
      isBioEnable: true,
      // TODO: type it
      allContacts: []
    },
    roleInformation: {
      /** isCBT */
      isConductorRoleEnable: true
    },
    contactAdditionalInformation: {
      /** role === Debtholder */
      isDebtholderRole: false,
      /** role === Owner */
      isOwnerRole: false,
      /** role === AccountSigner */
      isAccountSignerRole: false,
      /** isHerring */
      isOwnerWithPercentContactRequired: true,
      showOwnerBirthdayAndSsn: false,
      showOwnerPhysicalAddress: false,
      showOwnerMailingAddress: false,
      showAccountSignerMailingAddress: false,
      contactPersonalAddressLabel: '',
      showOwnerSsn: false,
      showOwnerBirthday: false,
      // for Shore
      conditionPercentOwnerSsn: 0
    },
    questionnaire: {
      /** isBison */
      isContactsForeignConnectionsEnable: true,
      /** isHerring */
      isWholesaleProductsEnable: true,
      /** isHerring */
      isWholesaleProductsDescEnable: true,
      /** isHerring */
      isTypeOfProductsEnable: true,
      /** isHerring */
      isTypeOfAncillaryProductsEnable: true,
      /** isHerring */
      isWhoSuppliesYouEnable: true,
      /** isHerring */
      isWhereIsYourSupplierEnable: true,
      /** isHerring */
      isWhoDoYouSupplyGrowerEnable: true,
      /** isHerring */
      isGrowerCustomersLocationEnable: true,
      /** isHerring */
      isHowManyLocationsEnable: true,
      /** isHerring */
      isOnlineOrdersForPickupEnable: true,
      /** isHerring */
      isMinorSellingEnsureEnable: true,
      /** isHerring */
      isPaymentMethodsEnable: true,
      /** isHerring */
      isTransferToOtherStatesEnable: true,
      /** isHerring */
      isTransferToOtherStatesDescEnable: true,
      /** isHerring */
      isAreYouAffiliatedEnable: true,
      /** isHerring */
      isAreYouAffiliatedDescEnable: true,
      statesLicensedLabel: ''
    },
    professionalPartnerships: {
      /** isCBT */
      isProPartPrevClosureReasonEnable: true,
      /** isCBT */
      isProPartCurrentServicesOtherDescEnable: true,
      /** isCBT */
      isProPartCurrentServicesOtherDescDisabledEnable: true,
      /** isINB */
      isProPartCurrentServicesLogisticEnable: true,
      /** isINB */
      isProPartCurrentServicesLogisticDescEnable: true,
      /** isBison */
      isProPartInternetGamblingEnable: true
    },
    atmGeneralInfo: {
      /** isINB */
      isAdditionalHintEnable: true
    },
    availableServices: {
      /** isINB */
      isAvailableServicesDescEnable: true,
      /** isBison */
      isAvailServiceHowManyAccountsIsOpeningEnable: true,
      isAvailServiceOnlineBankingDescEnable: false,
      conditions: {
        /** !isCBT */
        isAvailServiceCheckingOperatingEnable: true,
        /** !isCBT */
        isAvailServiceCheckingPayrollEnable: true,
        /** !isCBT */
        isAvailServiceAchOriginationEnable: true,
        /** !isCBT */
        isAvailServiceRemoteDepositCaptureEnable: true,
        /** !isCBT */
        isAvailServiceEscrowEnable: true,
        /** isCBT */
        isAvailServiceCheckingEnable: true,
        /** isCBT */
        isBusinessSavingsEnable: true,
        /** isCBT */
        isAvailServiceMobileDepositEnable: true,
        /** isCBT */
        isWireTransferEnable: true,
        /** isCBT */
        isAvailServiceCreditCardEnable: true,
        /** isCBT */
        isAvailServicePersonalCheckingEnable: true,
        /** isCBT */
        isAvailServicePersonalSavingsEnable: true,
        /** isHerring */
        isAvailServiceRollingCoinChangeOrderEnable: true,
        /** isHerring */
        isAnticipatedActivityAtmSettlementEnable: true,
        /** !isCBT && !isBison  */
        isAvailServiceWireDomesticEnable: true,
        /** !isCBT && !isBison  */
        isAvailServiceWireForeignEnable: true,
        /** isBison */
        isAvailServiceWireDomesticOnlyEnable: true
      }
    },
    anticipatedMonthlyActivity: {
      /** !isCBT && !isBison */
      isAnticipatedActivityWiresInternationalCountriesEnable: true,
      /** !isCBT */
      isAdditionalAgreementRequiredEnable: true,
      conditions: {
        /** isBison */
        isAnticipatedActivityAchOriginatingRequired: true,
        /** isBison */
        isAnticipatedActivityWiresDomesticOnlyEnable: true,
        /** !isBison */
        isAnticipatedActivityWiresDomesticEnable: true,
        /** isHerring */
        isAnticipatedActivityCheckDepositsEnable: true,
        /** isHerring */
        isAnticipatedActivityAtmSettlementEnable: true,
        /** !isCBT */
        isAnticipatedActivityRemoteDepositsEnable: true,
        /** !isCBT && !isBison */
        isAnticipatedActivityWiresInternationalEnable: true
      }
    },
    anticipatedMonthlyActivityNA: {
      isAnticipatedMonthlyActivityNAFormEnable: true
    },
    companyMerchantQuestionnaire: {
      isPrimaryMethodToFundEnable: true,
      isCryptocurrencyTransactionsEnable: true,
      isRealTimePaymentServicesEnable: true
    }
  },
  generalErrors: [],
  lastErrorCount: 0,
  // ui
  isAutosaveUpdating: false,
  isCurrentStepEditEnable: false,
  isDirty: false,
  isFormSubmitting: false,
  firstCompanyError: undefined,
  hasChanges: false,
  step: 0,
  isShowClientNotesDialog: false,
  //
  licenseTypesValues: [],
  licenseSubTypesValues: [],
  licensePOSTypesValues: [],
  businessTypesValues: [],
  entityTypesValues: [],
  documentsUploading: [],
  businessTypesDescription: [],
  shouldCustomerAcknowledgementDialogBeShown: false,
  documentSign: {
    title: '',
    description: ''
  },
  isSubmitRFIFormEnabled: false,
  isIsolatedApplicationFromEntities: false,
  applicationClientDocumentsUploading: []
}
