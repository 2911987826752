import { useForm } from '@tanstack/react-form'
import {
  OnboardingDocumentCreateModeWebPage,
  OnboardingDocumentEditModeWebPage,
  OnboardingDocumentSetupFormRequest
} from 'commons/types/DTO/bank/applications/onboardingDocuments'
import { handleFormSubmissionError } from 'ui/components'

import { BankAccountsListFieldType } from '../../../types/BankAccountsListField'
import { SetupDocumentFormType } from '../../../types/SetupDocumentFormType'
import { mapDataRequest } from '../utils/mapDataRequest'
import { mapResponseCompanies } from '../utils/mapResponseCompanies'

export const useManageContainer = (
  webPageData: OnboardingDocumentCreateModeWebPage | OnboardingDocumentEditModeWebPage,
  onSubmit: (payload: OnboardingDocumentSetupFormRequest) => Promise<void>
) => {
  const { mappedCompaniesPageData, initialFormValue } = mapResponseCompanies(webPageData)

  const form = useForm<{ companiesData: SetupDocumentFormType[] }>({
    defaultValues: {
      companiesData: initialFormValue as SetupDocumentFormType[]
    },
    onSubmit: async ({ value }) => {
      const mappedFormValue = mapDataRequest(value, mappedCompaniesPageData)

      onSubmit(mappedFormValue).catch((error) => {
        handleFormSubmissionError(error, form)
      })
    }
  })

  const addBankAccountToCompany = (
    bankAccountsListField: BankAccountsListFieldType,
    bankAccountNumber: string,
    companyIndex: number
  ) => {
    bankAccountsListField.pushValue({
      bankAccountNumber: bankAccountNumber,
      bankAccountTemplates: mappedCompaniesPageData[companyIndex].availableBankAccountsTemplates
    })
  }

  const onDeleteBankAccount = (bankAccountsListField: BankAccountsListFieldType, bankAccountIndex: number) => {
    bankAccountsListField.removeValue(bankAccountIndex)
  }

  return {
    mappedCompaniesPageData,
    form,
    onDeleteBankAccount,
    addBankAccountToCompany
  }
}
