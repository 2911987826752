const startDateTypeLabelMap: { [key: string]: string } = {
  calendar: 'Calendar',
  account_opening: 'Account Opening',
  license_issue: 'License Issue',
  last_delivery: 'Last Delivery'
}

export const getStartDateTypeLabel = (name: string) => {
  if (name === '') {
    return 'Manual Period'
  }
  return startDateTypeLabelMap[name] || name
}
