import React, { ReactElement, ReactNode } from 'react'

import { Navigate, Outlet, createRootRoute, createRoute, createRouter, redirect } from '@tanstack/react-router'
import { PortalName } from 'commons/types/enums'
import { TanStackRouterDevTools } from 'commons/utils'
import { Spinner } from 'ui/components'

import * as SC from './components/style'
import { AUTH_PATH } from './constants'
import { ApplicationValidateError, ResetPassword, SetPassword, SignIn } from './routes'

type GetRouterArgs = {
  portalName: PortalName
  hasSession: boolean
  logoElement: ReactElement
  footer: ReactNode
  backgroundElement: ReactElement
}

export const getRouter = (args: GetRouterArgs) => {
  const { hasSession, logoElement, footer, backgroundElement } = args

  const rootRoute = createRootRoute({
    component: () => (
      <>
        <Outlet />
        {backgroundElement}
        <TanStackRouterDevTools position="top-right" />
      </>
    ),
    notFoundComponent: () => <Navigate to={window.location.pathname + window.location.search} />
  })

  const authRoute = createRoute({
    getParentRoute: () => rootRoute,
    validateSearch: (search: Record<'returnUrl', string>): Record<'returnUrl', unknown> | object => {
      if (search.returnUrl) {
        return {
          returnUrl: search.returnUrl
        }
      } else return {}
    },
    path: AUTH_PATH,
    notFoundComponent: () => <Navigate to={window.location.pathname + window.location.search} />,
    pendingComponent: () => (
      <SC.SpinnerContainer>
        <Spinner centered size={64} />
      </SC.SpinnerContainer>
    )
  })

  const applicationValidationErrorRoute = createRoute({
    getParentRoute: () => authRoute,
    path: 'application-validate-error',
    validateSearch: (search: Record<'error', unknown>): Record<'errorMessage', unknown> => ({
      errorMessage: search.error || null
    }),
    component: ApplicationValidateError
  })

  const loginRoute = createRoute({
    getParentRoute: () => authRoute,
    beforeLoad: () => {
      if (hasSession) {
        throw redirect({
          to: '/'
        })
      }
    },
    path: 'login',
    component: () => <SignIn logoElement={logoElement} footer={footer} />
  })

  const forgotPasswordRoute = createRoute({
    getParentRoute: () => authRoute,
    path: 'forgot-password',
    component: () => <ResetPassword logoElement={logoElement} footer={footer} />
  })

  const passwordResetRoute = createRoute({
    getParentRoute: () => authRoute,
    path: 'password-reset',
    component: () => <SetPassword logoElement={logoElement} footer={footer} />
  })

  const routeTree = rootRoute.addChildren([
    authRoute.addChildren([applicationValidationErrorRoute, forgotPasswordRoute, passwordResetRoute, loginRoute])
  ])

  const router = createRouter({
    routeTree,
    defaultPreload: 'intent'
  })

  return router
}
