import { AnyRoute, createRoute, lazyRouteComponent } from '@tanstack/react-router'

export const EditRoute = <T extends AnyRoute>(indexRoute: T) =>
  createRoute({
    getParentRoute: () => indexRoute,
    path: `$id/edit`,
    component: lazyRouteComponent(() => import('./EditLicense')),
    validateSearch: (search: Record<string, unknown>) => {
      return {
        returnUrl: (search.returnUrl as string) || ''
      }
    }
  })
