import { useContext, useEffect } from 'react'

import { FormConfig, FormValidateFunction } from 'brief-form'

import { OnboardingFormContext } from '../components/OnboardingForm/OnboardingFormContext'
import { FormsValidation } from '../types/state'

export const useValidateFormPersist = <T>({
  formName,
  validate
}: // config
{
  formName: string
  validate: FormValidateFunction<T>
  config: FormConfig<T>
}) => {
  // const onceFlagEnabled = useRef<boolean>(true)
  // ... context
  const onboardingContext = useContext(OnboardingFormContext)
  const {
    validation: { validateFunctionRefs }
  } = onboardingContext

  useEffect(() => {
    if (!validateFunctionRefs.current) {
      validateFunctionRefs.current = { formsValidation: {} as FormsValidation }
    }
    validateFunctionRefs.current.formsValidation[formName] = {
      validate,
      registeredFields: undefined // config.registeredFields.current
    }
  }, [formName, validate])

  // useEffect(() => {
  //   if (!onceFlagEnabled.current) {
  //     return
  //   }
  //   onceFlagEnabled.current = false
  //   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //   // @ts-ignore
  //   if (!config?.value) {
  //     return
  //   }
  //   //   debugger
  //   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //   // @ts-ignore
  //   config.onChange(config?.value, config.errors || {})
  //   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //   // @ts-ignore
  // }, [config.value, config.errors, config.onChange])
}
