import React, { FC } from 'react'

import SC from 'styled-components'

//region Styled

const FileInput = SC.input.attrs((props) => ({
  type: 'file',
  id: props.id,
  accept: props.accept,
  multiple: props.multiple
}))`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  &:focus + label {
    outline: 4px solid #7b7b7b;
  }
`

const Label = SC.label.attrs((props) => ({
  htmlFor: props.htmlFor,
  role: 'button'
}))`
  color: ${(p) => p.theme.uiKit.colors.theme.negative.main};
  display: inline-block;
  cursor: pointer;
  font-size: 14px;
  line-height: 1.5;
`

//endregion

interface Props {
  id: string
  acceptFiles: string
  multiple?: boolean
  onChange: (files: any) => void
}

export const UploadButton: FC<Props> = ({ id, acceptFiles, multiple = false, onChange }) => (
  <span>
    <FileInput id={id} accept={acceptFiles} multiple={multiple} onChange={onChange} />
    <Label htmlFor={id}>UPLOAD</Label>
  </span>
)
