import { BsaBatch } from 'commons/types/DTO/bank/bsa'

export const dataSourceSelector = (data: any) =>
  (data?.list?.records || []).map((record: BsaBatch) => ({
    item: {
      id: record.id,
      start_date: record.start_date,
      status: record.status,
      xml_key: record.xml_key,
      ctrx_pdf_key: record.ctrx_pdf_key,
      acknowledgments_key: record.acknowledgments_key,
      ctrs_info: record.ctrs_info,
      tracking_id: record.tracking_id
    }
  }))
