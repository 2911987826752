import { BankName } from '../enums'

export const getBusinessTypes = (bankName: BankName): { label: string; value: string }[] =>
  [
    { label: 'Pre License MRB', value: 'pre_license_mrb' },
    { label: 'Licensed MRB', value: 'mrb' },
    { label: 'MRB Related', value: 'mrb_related' },
    { label: 'Tribal MRB', value: 'tribal_mrb' },
    { label: 'Ancillary', value: 'ancillary' },
    bankName === BankName.CBT ? { label: 'Consumer Account', value: 'consumer_account' } : (null as any),
    { label: `Hemp${bankName === BankName.INB ? '' : '/CBD'}`, value: 'hemp' },
    { label: 'Investment', value: 'investment' },
    { label: 'Management Company', value: 'management' }
  ].filter(Boolean)
