import React, { useCallback, useState } from 'react'

import { useRouter } from '@tanstack/react-router'
import { handlerServerError } from 'errors'
import { Button } from 'ui/components/Buttons'
import { Toast } from 'ui/components/Toast'

import { useAddApplicationDocumentUpload } from '../../../../../../hooks/useAddApplicationDocumentUpload'
import { useAddReview } from '../../../../../../hooks/useAddReview'
import { ReviewFormUpload } from '../ReviewFormUpload'
import { ReviewFormUploadFormData } from '../ReviewFormUpload/ReviewFormUploadFormData'
import { ReviewFormUploadWrapProps } from './ReviewFormUploadWrapProps'

export const ReviewFormUploadWrap = ({
  application,
  type,
  notes,
  title,
  fieldName,
  buttonName,
  onlyNotes,
  requiredNotes,
  disabledDocumentType
}: ReviewFormUploadWrapProps) => {
  const router = useRouter()

  const { addApplicationDocumentUpload } = useAddApplicationDocumentUpload()
  const { addReview } = useAddReview()

  const [showModal, setShowModal] = useState(false)

  const onShowModal = useCallback(() => {
    setShowModal(true)
  }, [])

  const onHideModal = useCallback(() => {
    setShowModal(false)
  }, [])

  const onSave = useCallback(
    async (formValue: ReviewFormUploadFormData, callback: () => void) => {
      if (!onlyNotes) {
        const form = new FormData()

        form.append('application_id', application.applicationID.toString())
        form.append('type', 'internal_bank_document')
        form.append('document_name', (formValue.type === 'other' ? formValue.document_name : formValue.type) || '')
        form.append('notes', formValue.notes || '')

        if (formValue.file) {
          form.append('document', formValue.file)
        }

        try {
          await addApplicationDocumentUpload(form)
          if (!fieldName) {
            setShowModal(false)
            Toast.success('Application was updated')
            router.invalidate()
          }
        } catch (error) {
          handlerServerError(error)
          callback()
          return
        }
      }

      if (fieldName) {
        const body = {
          id: application.applicationID
        }

        body[fieldName] = { reviewed: true, notes: formValue.notes || '' }

        try {
          await addReview(body)
          Toast.success('Reviews was saved')
          setShowModal(false)
          router.invalidate()
          callback()
        } catch (e) {
          Toast.error('A trouble was occurred while saving review')
          callback()
        }
      }
    },
    [application, fieldName]
  )

  return (
    <>
      {showModal && (
        <ReviewFormUpload
          onSave={onSave}
          onHideModal={onHideModal}
          title={title}
          onlyNotes={onlyNotes}
          requiredNotes={requiredNotes}
          notes={notes}
          type={type}
          disabledDocumentType={disabledDocumentType}
          internalDocumentNames={application.internalDocumentNames}
        />
      )}
      <Button onClick={onShowModal} face="positive">
        {buttonName}
      </Button>
    </>
  )
}
