import styled from 'styled-components'
import { Info } from 'ui/components/Info'

export const CompanyName = styled(Info)`
  margin-bottom: 25px;
`

export const Inner = styled.div`
  padding: 20px 30px 35px;
`
