import { AnyRoute, createRoute, lazyRouteComponent } from '@tanstack/react-router'
import { NotFoundError } from 'commons/errors'
import { getInvoiceSubmittalEditMode } from '~bank-invoices/service/invoiceSubmittalEditMode'

export const EditRoute = <T extends AnyRoute>(indexRoute: T) =>
  createRoute({
    getParentRoute: () => indexRoute,
    path: `$id/edit`,
    component: lazyRouteComponent(() => import('./EditInvoice')),
    beforeLoad: ({ params, ...all }) => {
      if ('id' in params) return { ...all, flatDeps: { id: Number(params.id) } }
      else throw new NotFoundError()
    },
    loader: async ({ context: { flatDeps } }) => await getInvoiceSubmittalEditMode({ id: flatDeps.id }),
    validateSearch: (search: Record<string, string>) => ({
      returnUrl: (search.returnUrl as string) || ''
    })
  })
