import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { Spinner, SpinnerSize } from 'ui/components'
import { Dialog, DialogFace, DialogFooterActions, DialogOverlay } from 'ui/components/Dialog'

import { ResetPasswordProps } from './ResetPassword.types'
import { useResetPassword } from './useResetPassword'

import { s } from './styles'

export const ResetPassword = (props: ResetPasswordProps) => {
  const { onClose, user } = props
  const { url, sendResetEmail } = useResetPassword(props)

  if (!user) {
    return null
  }

  return (
    <Dialog
      id="reset-password-dialog"
      title="Resend Invite"
      face={DialogFace.PRIMARY}
      overlay={DialogOverlay.DARK}
      onClose={onClose}
      footerActions={[DialogFooterActions.SEND, DialogFooterActions.CANCEL]}
      onSubmit={sendResetEmail}
    >
      {url ? (
        <s.DialogContent>
          <s.Label>
            The link below will be sent to <i>{user.email}</i>
          </s.Label>
          <s.Copy>
            <s.Input readOnly value={url} />
            <CopyToClipboard text={url}>
              <s.CopyBtn>COPY LINK</s.CopyBtn>
            </CopyToClipboard>
          </s.Copy>
        </s.DialogContent>
      ) : (
        <Spinner centered size={SpinnerSize.S} />
      )}
    </Dialog>
  )
}
