import { ChoiceOption } from 'ui/types'

export class Batch {
  id: number
  name: string

  constructor(id?: number, name?: string) {
    if (!id || !name) {
      return null
    }

    this.id = id
    this.name = name
  }
}

export class SARListForm {
  // TODO: is it possible to create middleware for auto type convertion of form data to entity
  // based on types of properties
  batchId: number | string = null
  bsaTrackingId: string = null
  date: string = null
  status: string = null

  page = 1
  size = 10
}

export type SARInfo = {
  id: number
  name: string
  status: string
}

export type SARListItem = {
  id: number
  acknowledgmentsPDF: string
  batchPDF: string
  batchXML: string
  bsaTrackingId: string
  endDate: string
  sarsCount: number
  sarsInfo: SARInfo[]
  startDate: string
  status: string

  enableDelete: boolean
}

export type SARListWebPage = {
  statusOptions: ChoiceOption[]
  form: SARListForm
  items: SARListItem[]
  total: number
}
