import {
  ATMGeneralInfo,
  AccountDetails,
  AnticipatedMonthlyActivity,
  AnticipatedMonthlyActivityNA,
  AvailableServices,
  CompanyInformation,
  CompanyMailingAddress,
  CompanyPhysicalAddress,
  MerchantQuestionnaire,
  ProfessionalPartnership,
  Questionnaire
} from 'commons/types/DTO/commons'

import { CompanyFormValues } from '../../../../types/state'
import {
  getEmptyAnticipatedMonthlyActivity,
  getEmptyAnticipatedMonthlyActivityNA,
  getEmptyAtmGeneralInfo,
  getEmptyAvailableServices,
  getEmptyCompanyAccountDetails,
  getEmptyCompanyInformation,
  getEmptyMailingAddress,
  getEmptyMerchantQuestionnaire,
  getEmptyPhysicalAddress,
  getEmptyProfessionalPartnerships,
  getEmptyQuestionnaire
} from '../mappersResponse'
import { selectATMs, selectContacts, selectLicenses } from '../selectors'
import { getEmptyATM } from './getEmptyATM'
import { getEmptyContact } from './getEmptyContact'
import { getEmptyLicense } from './getEmptyLicense'

const emptyCompanyInformation: CompanyInformation = {
  companyID: 0,
  entityUID: '',
  legalName: '',
  DBA: '',
  phone: '',
  website: '',
  description: ''
}

/**
 * https://www.notion.so/CP-Onboard-State-0e6a5fdc7adc4c3f82268227cfec8af0
 */
const emptyCompanyAccountDetails: AccountDetails = {
  businessType: '',
  entityType: '',
  stateOfIncorporation: '',
  EIN: '',
  preLicenseMRBInfo: ''
}

const emptyPhysicalAddress: CompanyPhysicalAddress = {
  street: '',
  city: '',
  state: '',
  country: 'US',
  postalCode: ''
}

const emptyMailingAddress: CompanyMailingAddress = {
  mailingStreet: '',
  mailingCity: '',
  mailingState: '',
  mailingCountry: 'US',
  mailingPostalCode: ''
}

const emptyQuestionnaire: Questionnaire = {
  statesLicensed: '',
  aWholesaleProducts: 0,
  aWholesaleProductsDesc: '',
  bTypeOfProducts: '',
  cTypeOfAncillaryProducts: '',
  whoSuppliesYou: '',
  whereIsYourSupplier: '',
  whoDoYourSupplyGrower: '',
  growerCustomersLocation: '',
  dHowManyLocations: '',
  eOnlineOrdersForPickup: 0,
  fMinorSellingEnsure: '',
  gPaymentMethods: '',
  hTransferToOtherStates: 0,
  hTransferToOtherStatesDesc: '',
  iAreYouAffiliated: 0,
  iAreYouAffiliatedDesc: '',
  statesLicensedLabel: '',
  contactsForeignConnections: 0
}

const emptyProfessionalPartnerships: ProfessionalPartnership = {
  proPartCurrentlyBanked: 0,
  proPartPrevClosureReason: '',
  proPartCurrentServicesCPAs: false,
  proPartCurrentServicesLaw: false,
  proPartCurrentServicesLogistic: false,
  proPartCurrentServicesOther: false,
  proPartCurrentServicesLogisticDesc: '',
  proPartCurrentServicesOtherDesc: '',
  proPartLitigation: '',
  proPartStateEnforcements: '',
  proPartInternetGambling: 0,
  proPartPayrollSoftware: ''
}

const emptyAtmGeneralInfo: ATMGeneralInfo = {
  ATMHave: 0,
  ATMOwn: 0,
  ATMCount: '',
  ATMReplenishingThirdParty: 0,
  ATMReplenishingCompany: 0
}

const emptyAvailableServices: AvailableServices = {
  availServiceCheckingOperating: false,
  availServiceCheckingPayroll: false,
  availServiceChecking: false,
  availServiceSavings: false,
  availServiceACHOrigination: false,
  availServiceMobileDeposit: false,
  availServiceWire: false,
  availServiceRollingCoinChangeOrder: false,
  availServiceRemoteDepositCapture: false,
  availServiceEscrow: false,
  availServiceOnlineMobileBanking: false,
  availServiceBillPay: false,
  availServiceDebitCard: false,
  availServiceCreditCard: false,
  availServiceWireDomestic: false,
  availServiceWireDomesticOnly: false,
  availServiceWireForeign: false,
  availServicePersonalChecking: false,
  availServicePersonalSavings: false,
  availServiceATMSettlement: false,
  availServiceHowManyAccountsIsOpening: ''
}

const emptyAnticipatedMonthlyActivity: AnticipatedMonthlyActivity = {
  anticipatedActivityACHDeposits: false,
  anticipatedActivityACHDepositsAmount: 0,
  anticipatedActivityACHDepositsFrequency: '',
  anticipatedActivityWiresInternationalCountries: '',
  anticipatedActivityWiresInternational: false,
  anticipatedActivityWiresInternationalAmount: 0,
  anticipatedActivityWiresInternationalFrequency: '',
  anticipatedActivityACHOriginating: false,
  anticipatedActivityACHOriginatingAmount: 0,
  anticipatedActivityACHOriginatingFrequency: '',
  anticipatedActivityATMWithdrawals: false,
  anticipatedActivityATMWithdrawalsAmount: 0,
  anticipatedActivityATMWithdrawalsFrequency: '',
  anticipatedActivityCashDeposits: false,
  anticipatedActivityCashDepositsAmount: 0,
  anticipatedActivityCashDepositsFrequency: '',
  anticipatedActivityCheckDeposits: false,
  anticipatedActivityCheckDepositsAmount: 0,
  anticipatedActivityCheckDepositsFrequency: '',
  anticipatedActivityCashWithdrawals: false,
  anticipatedActivityCashWithdrawalsAmount: 0,
  anticipatedActivityCashWithdrawalsFrequency: '',
  anticipatedActivityCheckWithdrawals: false,
  anticipatedActivityCheckWithdrawalsAmount: 0,
  anticipatedActivityCheckWithdrawalsFrequency: '',
  anticipatedActivityRemoteDeposits: false,
  anticipatedActivityRemoteDepositsAmount: 0,
  anticipatedActivityRemoteDepositsFrequency: '',
  anticipatedActivityWiresDomesticOnly: false,
  anticipatedActivityWiresDomesticOnlyAmount: 0,
  anticipatedActivityWiresDomesticOnlyFrequency: '',
  anticipatedActivityWiresDomestic: false,
  anticipatedActivityWiresDomesticAmount: 0,
  anticipatedActivityWiresDomesticFrequency: '',
  anticipatedActivityATMSettlement: false,
  anticipatedActivityATMSettlementAmount: 0,
  anticipatedActivityATMSettlementFrequency: ''
}

const emptyAnticipatedMonthlyActivityNA: AnticipatedMonthlyActivityNA = {
  activityDepositsChecksItems: 0,
  activityDepositsChecksAmount: 0,
  activityDepositsChecksNA: false,
  activityDepositsCashItems: 0,
  activityDepositsCashAmount: 0,
  activityDepositsCashNA: false,
  activityDepositsOtherItems: 0,
  activityDepositsOtherAmount: 0,
  activityDepositsOtherNA: false,
  activityWithdrawalsCashItems: 0,
  activityWithdrawalsCashAmount: 0,
  activityWithdrawalsCashNA: false
}

const emptyMerchantQuestionnaire: MerchantQuestionnaire = {
  legacyCash: 0,
  merchantProcessing: 0,
  merchantProcessingDesc: '',
  primaryMethodToFund: null,
  cryptocurrencyTransactions: 0,
  realTimePaymentServices: 0
}

export const parseCompanyFromApplication = ({ responseData, companyIndex = 0 }): CompanyFormValues => ({
  companyInformation: getEmptyCompanyInformation({
    responseData,
    companyIndex
  }),

  companyAccountDetails: getEmptyCompanyAccountDetails({
    responseData,
    companyIndex
  }),

  physicalAddress: getEmptyPhysicalAddress({ responseData, companyIndex }),
  mailingAddress: getEmptyMailingAddress({ responseData, companyIndex }),
  //
  licenses: selectLicenses({ responseData, companyIndex }).map((license) => getEmptyLicense({ license })), // ?
  contacts: selectContacts({ responseData, companyIndex }).map((contact) => getEmptyContact({ contact })), // ?
  //
  questionnaire: getEmptyQuestionnaire({ responseData, companyIndex }),
  professionalPartnerships: getEmptyProfessionalPartnerships({
    responseData,
    companyIndex
  }),

  atmGeneralInfo: getEmptyAtmGeneralInfo({ responseData, companyIndex }),
  atms: selectATMs({ responseData, companyIndex }).map((atm) => getEmptyATM({ atm })),
  availableServices: getEmptyAvailableServices({ responseData, companyIndex }),
  anticipatedMonthlyActivity: getEmptyAnticipatedMonthlyActivity({
    responseData,
    companyIndex
  }),

  anticipatedMonthlyActivityNA: getEmptyAnticipatedMonthlyActivityNA({
    responseData,
    companyIndex
  }),

  companyMerchantQuestionnaire: getEmptyMerchantQuestionnaire({
    responseData,
    companyIndex
  })
})

const emptyApplication = {
  companies: [
    {
      ...emptyCompanyInformation,
      ...emptyCompanyAccountDetails,
      ...emptyPhysicalAddress,
      ...emptyMailingAddress,
      licenses: [],
      contacts: [],
      questionnaire: {
        ...emptyQuestionnaire,
        ...emptyAtmGeneralInfo,
        ...emptyAvailableServices,
        ...emptyAnticipatedMonthlyActivity,
        ...emptyAnticipatedMonthlyActivityNA,
        ...emptyMerchantQuestionnaire,
        ...emptyProfessionalPartnerships,
        ATMs: []
      }
    }
  ]
}

export const getEmptyCompany = () => parseCompanyFromApplication({ responseData: emptyApplication })
