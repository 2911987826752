import React, { FC, memo, useState } from 'react'

import { useFormData } from 'brief-form'
import { TableDataQa } from 'commons/types/enums'
import { Request } from 'commons/utils/request'
import { QueryTable } from 'ui/components/QueryTable'

import { BatchView } from '../../../components/BatchView'
import { getOptionsFilters } from '../../getOptionsFilters'
import { BatchTableProps } from './TableProps'
import { columns } from './columns'
import { FilterForm } from './components'
import { Filter } from './components/Filter'
import { dataSourceSelector } from './dataSourceSelector'

export const Table: FC<BatchTableProps> = memo((props) => {
  const { tableState, onItemSelect, onDelete, chosenItems, isAllItemsChosen, onReturnData } = props
  const [modalItem, setModalItem] = useState<any>()
  const { config, Field, set, isDirty } = useFormData<Filter>({ initialValue: new Filter() })

  const originalChangeHandler = config.onChange
  config.onChange = (value: Filter, e) => {
    originalChangeHandler(value, e)
  }

  return (
    <>
      <BatchView item={modalItem} onClose={() => setModalItem(undefined)} />
      <QueryTable
        name="CTR Batch List"
        dataQa={TableDataQa.BP_BATCH_LIST}
        dataSources={[
          {
            key: 'list',
            url: Request.urls.bank.batchList,
            options: (_: any, filter?: any) => ({
              _options: {
                offset: 0,
                limit: tableState.size,
                filters: getOptionsFilters(filter)
              }
            })
          }
        ]}
        columns={columns({
          onItemSelect,
          chosenItems,
          isAllItemsChosen,
          onDelete,
          onShowBatch: setModalItem
        })}
        dataSourceSelector={dataSourceSelector}
        sorting={{
          order: tableState.order as any,
          onChange: tableState.onOrderChange
        }}
        pagination={{
          page: tableState.page,
          size: tableState.size,
          onChange: tableState.onPageChange
        }}
        filter={{
          value: config.value,
          component: <FilterForm isDirty={isDirty} reset={() => set({ reset: true })} config={config} Field={Field} />
        }}
        onReturnData={onReturnData}
      />
    </>
  )
})
