import React, { useCallback, useState } from 'react'

import { useFormData } from 'brief-form'
import { AnnualReview, AnnualReviewStatuses } from 'commons/types/DTO/bank'
import { useConfirmation } from 'ui/hooks'
import { useAffiliatedCompanies, useDeleteAnnualReview } from '~bank-annual-review/hooks'

import { AnnualReviewFormValue } from './AnnualReviewFormValue'
import lastARDateValidator from './lastARDateValidator'

export const useAnnualReviewEditForm = (
  id: number,
  companyId: number,
  annualReview: AnnualReview,
  sumbitHandler: (value: AnnualReviewFormValue) => void,
  generateFormData: (value) => any
) => {
  const [isLeaveConfirmationNeeded, setIsLeaveConfirmationNeeded] = useState(true)
  const affiliatedCompaniesList = useAffiliatedCompanies({ company_id: companyId })
  const deleteMutation = useDeleteAnnualReview(companyId)
  const affiliatedCompanies = affiliatedCompaniesList.data?.records?.filter((item) => item.id !== companyId)

  const { config, Field, validate, isDirty } = useFormData<AnnualReviewFormValue>({
    initialValue: generateFormData(annualReview)
  })

  const originalChangeHandler = config.onChange

  config.onChange = (value, e) => {
    const newErrors = {
      ...e,
      [`last_ar_date`]: lastARDateValidator(value)
    }
    originalChangeHandler(value, newErrors)
  }

  const handleSubmit = useCallback(() => {
    setIsLeaveConfirmationNeeded(false)
    if (config.value.is_review_completed) {
      if (
        config.value.status !== AnnualReviewStatuses.COMPLETED &&
        config.value.status !== AnnualReviewStatuses.APPROVED
      ) {
        config.value.status = AnnualReviewStatuses.COMPLETED
      }
    } else {
      config.value.status = AnnualReviewStatuses.NEW
    }
    if (validate({ updateFields: true }).valid) {
      sumbitHandler(config.value)
    }
  }, [config.value, validate, sumbitHandler])

  const deleteReview = useCallback(() => {
    deleteMutation.mutate({
      id: id,
      company_id: 0,
      annual_review_id: 0
    })
  }, [deleteMutation, id])

  const { Confirmation: DeleteConfirmation, open: openDeleteConfirmation } = useConfirmation({
    message: (
      <span>
        Are you sure want to delete <i>AR-{id}</i> Annual Review?
      </span>
    ),
    onConfirm: deleteReview,
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  return {
    config,
    Field,
    affiliatedCompanies,
    preventLeaving: isLeaveConfirmationNeeded && isDirty,
    DeleteConfirmation,
    openDeleteConfirmation,
    handleSubmit
  }
}
