import React, { FC, memo } from 'react'

import { CheckMark } from 'ui/components/CheckMark'

import { AnticipatedMonthlyWithActivityDepositsProps } from './AnticipatedMonthlyWithActivityDepositsProps'

import * as SC from './styles'

export const AnticipatedMonthlyWithActivityDeposits: FC<AnticipatedMonthlyWithActivityDepositsProps> = memo(
  ({ questionnaire: { anticipatedMonthlyActivity } }) => (
    <SC.Activity>
      <thead>
        <tr>
          <SC.Column1 />
          <SC.Column3>Active</SC.Column3>
          <SC.Column3>Total Amount</SC.Column3>
          <SC.Column2>Frequency</SC.Column2>
        </tr>
      </thead>
      <tbody>
        {anticipatedMonthlyActivity.map((el) => (
          <tr key={el.name}>
            <td>{el.name}</td>
            <td>
              <CheckMark value={el.active} rounded />
            </td>
            <td>{el.totalAmount}</td>
            <td>{el.frequency}</td>
          </tr>
        ))}
      </tbody>
    </SC.Activity>
  )
)
