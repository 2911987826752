import { ServerOptions } from 'commons/types'
import { BSAReportingPage, BsaBatchDetailsOptions, BsaBatchDetailsWebPage } from 'commons/types/DTO/bank/bsa'
import { Request } from 'commons/utils/request'

export const getBSAReportingPage = (): Promise<BSAReportingPage> => Request.get(Request.urls.bank.bsaReportingPage)

export const getBsaBatchDetailsWebPage = (filter?: BsaBatchDetailsOptions): Promise<BsaBatchDetailsWebPage> =>
  Request.post(Request.urls.bank.bsaBatchDetailsWebPage, filter)

export const deleteBatch = (opts: ServerOptions) => Request.delete(Request.urls.bank.bsaBatch, opts)
