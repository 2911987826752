import styled from 'styled-components'
import { Panel } from 'ui/components'

const WrappedPanel = styled(Panel)`
  margin-bottom: 50px;
`

const Inner = styled.div`
  padding: 30px 35px;
`

export const s = { Inner, WrappedPanel }
