/**
 * Atom respresents data purpose/meaning under Scope.
 * It can be "List" or "Item" for example.
 * Is shoudn't be bound to any specifil scope,
 * for example "UserItem" is a bad atom because it makes no sense
 * outside of `Scope.User` (but technically can be used anywhere).
 * So better option is to give it "Item" name, then it can be used
 * in any scope. Generally `Atom` answers on "what" question, while
 * `Scope` answers on "whose/where" question.
 */
export enum Atom {
  TEST = 'test',
  ITEM = 'item',
  LIST = 'list'
}
