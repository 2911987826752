/**
 * Used to store dialog order on z axle, here we can determine which dialog on the top
 */
export class DialogStack {
  private static _instance: DialogStack

  private stack = []

  public isOnTop(id: string | number) {
    return this.stack[this.stack.length - 1] === id
  }

  public push(id: string | number) {
    this.stack.push(id)
  }

  public pop() {
    this.stack.pop()
  }

  public static getInstance() {
    return this._instance || (this._instance = new this())
  }
}
