import { FormPaths, OnboardingFormFieldNames } from '../../../../enums'
import { CompanyFormValues } from '../../../../types/state'
import { setNewAtms } from '../setNewAtms'

export const fromAtm = ({
  oldCompany,
  atmIndex,
  formName,
  fieldName,
  errors
}: {
  oldCompany: CompanyFormValues
  atmIndex: number
  formName: FormPaths
  fieldName: OnboardingFormFieldNames
  errors: string[]
}): CompanyFormValues => {
  const oldAtms = oldCompany.atms
  const formData = oldAtms[atmIndex][formName].formData
  const newATM = {
    ...oldAtms[atmIndex],
    [formName]: {
      formData,
      formErrors: { ...formData.formErrors, [`${fieldName}`]: errors.join('.') }
    }
  }
  const newAtms = setNewAtms({
    atmIndex,
    newATM,
    oldCompany
  })
  const newCompany = { ...oldCompany, atms: newAtms }

  return newCompany
}
