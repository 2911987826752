import { Request } from 'commons/utils/request'
import { Keys, ReadHook, useStateManager } from 'state-manager'

import { InternalTransferData, InternalTransferDataFilter } from '../types'

export const useInternalTransfersList: ReadHook<InternalTransferData, InternalTransferDataFilter> = (opts) => {
  const {
    internalTransferExportsStatus,
    internalTransferExportsPage,
    internalTransferExportsSize,
    internalTransferPage,
    internalTransferSize,
    internalTransferStatus,
    internalTransfersID,
    internalTransfersDateFrom,
    internalTransfersDateTo,
    internalTransfersSenderCompanyID,
    internalTransfersSenderLicenseID,
    internalTransfersRecipientLicenseID,
    internalTransfersRecipientCompanyID
  } = opts || {}

  return useStateManager().use(
    Keys.InternalTransfer.ListByCriteria({
      internalTransferExportsStatus,
      internalTransferExportsPage,
      internalTransferExportsSize,
      internalTransferPage,
      internalTransferSize,
      internalTransferStatus,
      internalTransfersID,
      internalTransfersDateFrom,
      internalTransfersDateTo,
      internalTransfersSenderCompanyID,
      internalTransfersSenderLicenseID,
      internalTransfersRecipientLicenseID,
      internalTransfersRecipientCompanyID
    }),
    () =>
      Request.post(Request.urls.bank.internalTransferWebPage, {
        internalTransfersID: internalTransfersID || undefined,
        internalTransfersDateFrom: internalTransfersDateFrom || undefined,
        internalTransfersDateTo: internalTransfersDateTo || undefined,
        internalTransfersSenderCompanyID: internalTransfersSenderCompanyID || undefined,
        internalTransfersSenderLicenseID: internalTransfersSenderLicenseID || undefined,
        internalTransfersRecipientLicenseID: internalTransfersRecipientLicenseID || undefined,
        internalTransfersRecipientCompanyID: internalTransfersRecipientCompanyID || undefined,
        internalTransferExportsStatus: internalTransferExportsStatus || undefined,
        internalTransferExportsLimit: internalTransferExportsSize,
        internalTransferExportsOffset: ((internalTransferExportsPage || 1) - 1) * (internalTransferExportsSize || 10),
        internalTransfersLimit: internalTransferSize,
        internalTransfersOffset: ((internalTransferPage || 1) - 1) * (internalTransferSize || 10),
        internalTransfersStatus: internalTransferStatus || undefined
      })
  )
}
