import { ReadHook, useStateManager } from 'react-query-state-manager'

import { api } from './api'
import { CLIENT_PORTAL_USERS_ACTIVITY_REPORT_KEY } from './keys'
import { Payload, Response } from './types'

const usePage: ReadHook<Response, Payload> = (opts, callbacks) =>
  useStateManager().use([CLIENT_PORTAL_USERS_ACTIVITY_REPORT_KEY, opts], () => api.getPage(opts), {
    keepPreviousData: true,
    onSuccess: callbacks?.onSuccess,
    onError: callbacks?.onError
  })

export const hooks = {
  usePage
}
