import { ChangeEvent } from 'react'

import { ChoiceValue } from 'ui/types'

export interface RadioButtonProps {
  readonly?: boolean
  dataQa?: string
  disabled?: boolean
  error?: string
  required?: boolean
  name?: string
  checked: boolean
  label: string
  value: ChoiceValue
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
}
