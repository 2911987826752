import { ApplicationStatus } from 'commons/types/enums'
import { ChoiceOption } from 'ui/types'

import { ApplicationOwner } from './ApplicationOwner'
import { ApplicationClientUploadedDocuments, ClientUploadedDocuments } from './ClientUploadedDocuments'
import { InternalDocumentNames } from './InternalDocumentNames'
import { InternalDocuments } from './InternalDocuments'
import { OnboardingDocumentsItem } from './OnboardingDocumentsItem'
import { Relationships } from './Relationships'
import { Reviews } from './Reviews'

export type ApplicationWebPageDetail = {
  applicationID: number
  availableOwners: ChoiceOption[]
  documentRequestAcknowledgedAtFormatted: string
  documentRequestAcknowledgedBy: string
  clientUploadedDocuments: ClientUploadedDocuments[]
  // use this if isIsolatedApplicationFromEntities = true
  applicationClientUploadedDocuments: ApplicationClientUploadedDocuments[]
  createdBy: ApplicationOwner
  currentOwner: ApplicationOwner
  enableActiveToggle: boolean

  enableAddClientDocument: boolean
  enableAdvancePastDocs: boolean
  enableApplicationInProgressWarning: boolean
  enableApproveAllDocuments: boolean
  enableApproveApplication: boolean
  enableArchiveFormUpload: boolean
  enableAddCreditReview: boolean
  enableClientDocuments: boolean
  enableClientDocumentsActions: boolean
  enableComitteeFormUpload: boolean
  enableComplianceReviewFormUpload: boolean
  enableCreditReviewFormUpload: boolean
  enableDeleteApplication: boolean
  enableDenyApplication: boolean
  enableDownloadAllDocuments: boolean
  enableFinalReviewFormUpload: boolean
  enableFinalizeApplication: boolean
  enableInternalDocumentUpload: boolean
  enableMartinHoodReviewFormUpload: boolean
  enableNotesFromApplicantModification: boolean
  enableInternalNotesModification: boolean
  enableNotesForApplicantModification: boolean
  enableRevertToDocumentRequestPending: boolean
  enableRevertToRFIPending: boolean
  enableSendDocumentRequest: boolean
  enableReviews: boolean
  enableSetupOnboardingDocuments: boolean
  formNameArchive: string
  formNameFinalReview: string
  infoApplicationCreateDate: string
  infoApplicationLastModifiedDate: string
  infoCompanyName: string
  infoContactEmail: string
  infoContactFullname: string
  infoContactPhone: string
  infoCurrentApplicationStatus: string
  infoIsApplicationActive: boolean
  internalDocuments: InternalDocuments[]
  internalDocumentNames: InternalDocumentNames
  isIsolatedApplicationFromEntities: boolean
  isDeletableApplicationClientDocuments: boolean
  isViewableApplicationClientDocuments: boolean
  notesBankInternal: string
  notesForApplicant: string
  notesFromApplicant: string
  onboardingDocuments: OnboardingDocumentsItem[] | undefined
  progressArchiveFormUploadStepName: string
  progressFinalReviewStepName: string
  progressTimelineStatus: ApplicationStatus | null
  relationships: Relationships[]
  statusApproveAllDocuments: string
  showOnboardingDocuments: boolean
  title: string
  titleOverline: string
  reviews: Reviews | null
  enableEditRFI: boolean
}
