import React, { FC, memo } from 'react'

import { YesNoRadioGroup } from 'commons/types/enums'
import { Info, InfoSet } from 'ui/components/Info'
import { RadioGroup } from 'ui/components/RadioGroup'
import { Col2x, Row } from 'ui/themes/globalStyles'

import { ATMInfoDetailsProps } from './ATMInfoDetailsProps'

export const ATMInfoDetails: FC<ATMInfoDetailsProps> = memo(({ value }) => (
  <InfoSet legend="ATM">
    <Row>
      <Col2x>
        <Info label="Do you have any ATMs located on premise where you operate?">
          <RadioGroup
            readonly
            value={value.ATMHave}
            name=""
            data={[
              { value: YesNoRadioGroup.YES, label: 'Yes' },
              { value: YesNoRadioGroup.NO, label: 'No' }
            ]}
          />
        </Info>
      </Col2x>
    </Row>
    {value.ATMHave === YesNoRadioGroup.YES && (
      <>
        <Row>
          <Col2x>
            <Info label="Do you own the ATMs?">
              <RadioGroup
                readonly
                value={value.ATMOwn}
                data={[
                  { value: YesNoRadioGroup.YES, label: 'Yes' },
                  { value: YesNoRadioGroup.NO, label: 'No' }
                ]}
              />
            </Info>
          </Col2x>
        </Row>
        <Row>
          <Col2x>
            <Info label="How many ATMs?">{value.ATMCount}</Info>
          </Col2x>
        </Row>
        <Row>
          <Col2x>
            <Info label="Do you have any ATMs replenished by a Third Party?">
              <RadioGroup
                readonly
                value={value.ATMReplenishingThirdParty}
                data={[
                  { value: YesNoRadioGroup.YES, label: 'Yes' },
                  { value: YesNoRadioGroup.NO, label: 'No' }
                ]}
              />
            </Info>
          </Col2x>
        </Row>
        <Row>
          <Col2x>
            <Info label="Do you have any ATMs replenished by Your Company?">
              <RadioGroup
                readonly
                value={value.ATMReplenishingCompany}
                data={[
                  { value: YesNoRadioGroup.YES, label: 'Yes' },
                  { value: YesNoRadioGroup.NO, label: 'No' }
                ]}
              />
            </Info>
          </Col2x>
        </Row>
      </>
    )}
  </InfoSet>
))
