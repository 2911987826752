import React, { FC, memo, useCallback } from 'react'
import Dropzone from 'react-dropzone'

import { TableButton } from 'ui/components/Buttons'

import { UploadButtonProps } from './UploadButtonProps'

export const UploadButton: FC<UploadButtonProps> = memo((props) => {
  const { documentId, onFilesAdd, children } = props

  const onFilesAddHandler = useCallback(
    (files: File[]) => {
      onFilesAdd(files, documentId)
    },
    [onFilesAdd, documentId]
  )

  return (
    <Dropzone onDrop={onFilesAddHandler}>
      {({ getRootProps, getInputProps }) => {
        const { ref, ...rootProps } = getRootProps()
        return (
          <span {...rootProps}>
            <input {...getInputProps()} />
            {children || (
              <TableButton innerRef={ref} face="brand">
                Upload
              </TableButton>
            )}
          </span>
        )
      }}
    </Dropzone>
  )
})
