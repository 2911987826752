import * as React from 'react'
import { CSSProperties } from 'react'

import { ChoiceOption, ChoiceValue } from 'ui/types'

import { MultiLicenseSelectAutoSuggest } from './MultiSelectAutoSuggest/MultiLicenseSelectAutoSuggest'

export interface LicenseMultiSelectProps {
  value: ChoiceValue[]
  onChange: (value: any) => any
  searchable?: boolean
  className?: string
  filters?: { field: string; type: string; value: ChoiceValue }[]
  emptyOption?: ChoiceOption
  disabled?: boolean
  containerStyle?: CSSProperties
  showId?: boolean
}

const defaultProps: Partial<LicenseMultiSelectProps> = {
  searchable: true
}

export const LicenseMultiSelect = React.memo((props: LicenseMultiSelectProps) => {
  const {
    value,
    onChange,
    searchable,
    className,
    filters,
    emptyOption,
    disabled,
    showId = false,
    ...rest
  } = {
    ...defaultProps,
    ...props
  }

  return (
    <MultiLicenseSelectAutoSuggest
      {...rest}
      value={value}
      onChange={onChange}
      valueTitle="Start entering the license name"
      serverOptions={{
        filters,
        url: '/bank/v1/license-list',
        limit: 500,
        mapper: (item) => ({ label: showId ? `${item.name} (${item?.license_number})` : item.name, value: item.id }),
        searchFields: ['name']
      }}
    />
  )
})
