import React, { useCallback, useEffect, useState } from 'react'

import { BeforeFormChangeHandler, useFormData } from 'brief-form'
import { useGetApplicationsWebPage } from 'commons/hooks/bank/applications'
import { UseWebPageProps, WebPage } from 'commons/types/DTO/bank'
import { BPLayout } from 'ui/components/BPLayout'
import { Page } from 'ui/components/Page'
import { useTableState } from 'ui/components/QueryTable'
import { Spinner, SpinnerSize } from 'ui/components/Spinner'

import { Actions } from './components/Actions'
import { ApplicationsListTable } from './components/ApplicationsListTable'
import { ApplicationListFilterValue } from './components/ApplicationsListTable/ApplicationListFilterValue'
import { CreateApplicationDialog } from './components/CreateApplicationDialog'

const APPLICATIONS_PAGE_NUMBER = 1
const APPLICATIONS_PER_PAGE = 10
// default is ''
// https://helioscompliance.atlassian.net/browse/MP-8614
const APPLICATIONS_INITIAL_FILTER_STATUS = ''

const ApplicationList = () => {
  const [webPageData, setWebPageData] = useState<WebPage | undefined>(undefined)
  const [webPageFilter, setWebPageFilter] = useState<UseWebPageProps>({
    applicationsGeneralStatus: APPLICATIONS_INITIAL_FILTER_STATUS,
    applicationsModifiedSortDirection: '',
    applicationsPageNumber: APPLICATIONS_PAGE_NUMBER,
    applicationsPerPage: APPLICATIONS_PER_PAGE,
    applicationsSearchQuery: '',
    applicationsCreatedByID: undefined,
    applicationsCurrentOwnerID: undefined
  })

  const tableState = useTableState({
    size: APPLICATIONS_PER_PAGE,
    page: APPLICATIONS_PAGE_NUMBER,
    order: [{ field: 'lastModified', direction: 'DESC' }],
    filter: {
      status: APPLICATIONS_INITIAL_FILTER_STATUS,
      applicationsCreatedByID: undefined,
      applicationsCurrentOwnerID: undefined,
      searchQuery: ''
    }
  })

  const onBeforeChange: BeforeFormChangeHandler<ApplicationListFilterValue> = useCallback(({ value, errors }) => {
    setWebPageFilter((filter) => ({
      ...filter,
      applicationsGeneralStatus: value.status,
      applicationsPageNumber: APPLICATIONS_PAGE_NUMBER,
      applicationsPerPage: APPLICATIONS_PER_PAGE,
      applicationsSearchQuery: value.searchQuery,
      applicationsCreatedByID: value.applicationsCreatedByID,
      applicationsCurrentOwnerID: value.applicationsCurrentOwnerID
    }))
    tableState.onPageChange(APPLICATIONS_PAGE_NUMBER, tableState.size)

    return {
      value,
      errors
    }
  }, [])

  const filterForm = useFormData<ApplicationListFilterValue>({
    onBeforeChange,
    initialValue: {
      status: APPLICATIONS_INITIAL_FILTER_STATUS,
      applicationsCreatedByID: undefined,
      applicationsCurrentOwnerID: undefined,
      searchQuery: ''
    }
  })

  const { data: webPage, isFetching: isFetchingWebPage } = useGetApplicationsWebPage(webPageFilter)
  const [showCreateModal, setShowCreateModal] = useState(false)

  const clearHandler = useCallback(() => {
    setWebPageFilter((filter) => ({
      ...filter,
      applicationsPageNumber: APPLICATIONS_PAGE_NUMBER,
      applicationsSearchQuery: '',
      applicationsGeneralStatus: APPLICATIONS_INITIAL_FILTER_STATUS,
      applicationsCreatedByID: undefined,
      applicationsCurrentOwnerID: undefined
    }))
    filterForm.set({
      value: {
        ...filterForm.config.value,
        status: APPLICATIONS_INITIAL_FILTER_STATUS,
        applicationsCreatedByID: undefined,
        applicationsCurrentOwnerID: undefined
      },
      reset: true
    })
    tableState.onPageChange(APPLICATIONS_PAGE_NUMBER, tableState.size)
    tableState.filterForm.set({
      value: {
        ...tableState.filterForm.config.value,
        searchQuery: '',
        applicationsCreatedByID: undefined,
        applicationsCurrentOwnerID: undefined,
        status: APPLICATIONS_INITIAL_FILTER_STATUS
      },
      reset: true
    })
  }, [])

  const onSetWebPagePagination = (applicationsPageNumber: number, applicationsPerPage: number) => {
    tableState.onPageChange(applicationsPageNumber, applicationsPerPage)
    setWebPageFilter((filter) => ({ ...filter, applicationsPageNumber, applicationsPerPage }))
  }

  const onSetWebPageSorting = (direction: 'DESC' | 'ASC') => {
    tableState.onOrderChange({ field: 'lastModified', direction: direction })
    setWebPageFilter((filter) => ({ ...filter, applicationsModifiedSortDirection: direction.toLowerCase() }))
  }

  const onSetWebPageQuery = (searchWord: string) => {
    filterForm.config.onChange({ ...filterForm.config.value, searchQuery: searchWord }, {})
    tableState.onPageChange(APPLICATIONS_PAGE_NUMBER, tableState.size)
    tableState.filterForm.set({
      value: {
        ...tableState.filterForm.config.value,
        searchQuery: searchWord
      }
    })
  }

  useEffect(() => {
    webPage && setWebPageData(webPage)
  }, [webPage])

  if (!webPageData) {
    return <Spinner centered size={SpinnerSize.M} />
  }

  const { header, applications, pagination, filters } = webPageData

  return (
    <BPLayout>
      {showCreateModal && <CreateApplicationDialog onClose={() => setShowCreateModal(false)} />}
      <Page
        title="Application List"
        actions={
          <Actions
            showNewButton={header.canCreateApplication}
            onSearchTermChange={(searchWord) => onSetWebPageQuery(searchWord)}
            onOpenCreateModal={() => setShowCreateModal(true)}
            searchTerm={tableState.filterForm.config.value.searchQuery}
          />
        }
      >
        <ApplicationsListTable
          onClean={clearHandler}
          applications={applications}
          pagination={pagination}
          loading={isFetchingWebPage}
          filters={filters}
          showDeleteBtn={true}
          tableState={tableState}
          filterForm={filterForm}
          onSetWebPagePagination={onSetWebPagePagination}
          onSetWebPageSorting={onSetWebPageSorting}
          onSetWebPageQuery={onSetWebPageQuery}
        />
      </Page>
    </BPLayout>
  )
}

export default ApplicationList
