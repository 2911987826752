import styled from 'styled-components'
import { CSS } from 'ui/helpers'

export const Inner = styled.span`
  ${(p) => CSS.populateFont(p.theme.uiKit.typography.bodyM.linkButton)}
  display: block;
`

export const Box = styled.button<{ $overColoredPad: boolean; disabled: boolean }>`
  background: none;
  border: none;
  outline: none;

  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')};

  color: ${(p) =>
    p.$overColoredPad ? p.theme.uiKit.colors.global.general.main : p.theme.uiKit.colors.global.grey.grey700};

  transition: background 0.15s, color 0.15s, outline 0.15s, box-shadow 0.15s, border-color 0.15s;

  &:hover {
    color: ${(p) =>
      p.$overColoredPad ? p.theme.uiKit.colors.global.general.main : p.theme.uiKit.colors.global.grey.grey900};
  }

  &:focus-visible {
    color: ${(p) =>
      p.$overColoredPad ? p.theme.uiKit.colors.global.grey.grey300 : p.theme.uiKit.colors.global.grey.grey900};
  }

  &:active {
    color: ${(p) =>
      p.$overColoredPad ? p.theme.uiKit.colors.global.grey.grey300 : p.theme.uiKit.colors.global.grey.grey700};
  }

  &:disabled {
    color: ${(p) =>
      p.$overColoredPad ? p.theme.uiKit.colors.global.general.main : p.theme.uiKit.colors.global.grey.grey300};
    opacity: ${(p) => (p.$overColoredPad ? p.theme.uiKit.opacity.opacity600 : p.theme.uiKit.opacity.visible)};
  }
`
