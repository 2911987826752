import { useCallback, useEffect, useRef } from 'react'
import { useQueryClient } from 'react-query'

import { useNavigate, useParams, useSearch } from '@tanstack/react-router'
import { useFormData } from 'brief-form'
import { useDeleteContact } from 'commons/hooks/bank/contact'
import { useGetBankName } from 'commons/hooks/bank/organisation'
import { Contact } from 'commons/types/DTO/bank'
import { BankName } from 'commons/types/enums'
import { handlerServerError } from 'errors'
import { Toast } from 'ui/components'
import { useConfirmation } from 'ui/hooks'

import { CONTACTS_PATH } from '../../constants'
import { emptyContact, useContactItem, useSaveContact } from '../../hooks'
import { messages } from '../../messages'

export const useContactManage = () => {
  const oncePerformedFlag = useRef<boolean>(false)
  const { contactId } = useParams({ strict: false })

  const { contact, isFetching: isContactFetching } = useContactItem(+(contactId || 0))
  const bank = useGetBankName()

  const formData = useFormData<Contact>({ initialValue: contact || { ...emptyContact } })

  // edit. contact loaded, change form value
  useEffect(() => {
    if (!contact || oncePerformedFlag.current === true) {
      return
    }
    formData.set({ reset: true, value: contact })
    oncePerformedFlag.current = true
  }, [contact, formData])

  const client = useQueryClient()

  const navigate = useNavigate({ from: '/contacts/new' })

  const { returnUrl } = useSearch({ strict: false })

  const { saveContact, isLoading: isContactFormSaving } = useSaveContact({
    onSuccess: async (...[, responseContact]) => {
      const successMessage =
        contact && contact.id ? messages['bankContacts.updateSuccess'] : messages['bankContacts.createSuccess']
      Toast.successSmall(successMessage)
      if (!returnUrl) navigate({ to: '/contacts/$contactId', params: { contactId: responseContact.id } })
      // this row for flow "Company Page > Create New Contact > Back to Company Page with Opened Edit Contact dialog
      if (returnUrl && returnUrl.includes('contact-edit')) navigate({ to: returnUrl + responseContact.id })
      else navigate({ to: returnUrl })
    }
  })

  const { routine: deleteContact } = useDeleteContact({
    onSuccess: async () => {
      await client.invalidateQueries(['tables', 'contacts'])
      navigate({ to: CONTACTS_PATH })
      Toast.successSmall(messages['bankContacts.deleteSuccess'])
    },
    onError: (error) => handlerServerError(error)
  })

  const { open: openDeleteConfirmation, Confirmation: DeleteConfirmation } = useConfirmation({
    message: messages['bankContacts.deleteConfirmation']({
      contactFirstName: contact?.first_name,
      contactLastName: contact?.last_name
    }),
    onConfirm: () => contact && deleteContact(contact.id),
    confirmationButtonText: messages['bankContacts.delete'],
    isConfirmationNegative: true
  })

  const handleSubmitContactForm = useCallback(async () => {
    if (!formData.validate({ updateFields: true }).valid) {
      return
    }

    await saveContact(formData.config.value)
  }, [saveContact, formData])

  const returnUrlForCancel = returnUrl || CONTACTS_PATH

  return {
    formData,
    subTitle: contact && contact.id ? messages['bankContacts.editTitle'] : messages['bankContacts.createTitle'],
    isContactFetching,
    isContactFormSaving,
    isMetroPhoenixBank: bank?.id === BankName.MPB,
    DeleteConfirmation,
    returnUrlForCancel,
    onDelete: openDeleteConfirmation,
    onSubmit: handleSubmitContactForm
  }
}
