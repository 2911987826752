import { AnyRoute, createRoute } from '@tanstack/react-router'

import { DetailsRoute } from './Details'
import { ListRoute } from './List'
import { ManageRoute } from './Manage'
import { PrintRoute } from './PrintCTRList'

export const CTRRoute = <T extends AnyRoute>(indexRoute: T) => {
  const route = createRoute({
    getParentRoute: () => indexRoute,
    path: `ctrs`
  })

  return route.addChildren([ManageRoute(route), ListRoute(route), DetailsRoute(route), PrintRoute(route)])
}
