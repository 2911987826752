export const usAndTerritories = [
  { label: 'United States of America', value: 'US' },
  { label: 'American Samoa', value: 'AS' },
  { label: 'Guam', value: 'GU' },
  { label: 'Marshal Islands', value: 'MH' },
  { label: 'Micronesia, Federated States', value: 'FM' },
  { label: 'Northern Mariana Islands', value: 'MP' },
  { label: 'Palau', value: 'PW' },
  { label: 'Puerto Rico', value: 'PR' },
  { label: 'U.S. Virgin Islands', value: 'VI' }
]
