import React, { memo } from 'react'

import { InnerMenu } from 'ui/components/SideMenu'
import { useGetAlertsWebPage } from '~bank-alerts/hooks/useGetAlertsWebPage'

const AlertsSideBar = memo(() => {
  const { webPage } = useGetAlertsWebPage()

  return (
    <InnerMenu
      items={[
        { label: 'Documents Due', hash: 'documents-due' },
        webPage?.uploads !== undefined ? { label: 'Uploads Pending Approval', hash: 'uploads-pending-approval' } : null,
        webPage?.applications !== undefined ? { label: 'Applications', hash: 'applications' } : null,
        webPage?.CTRs !== undefined ? { label: 'CTRs with FinCEN warnings', hash: 'ctr' } : null,
        webPage?.bsaBatches !== undefined ? { label: 'BSA Batches to finalize', hash: 'batch' } : null,
        webPage?.SARs !== undefined ? { label: 'SARs List', hash: 'sar' } : null,
        webPage?.monthlyAnalytics !== undefined ? { label: 'Monthly Analytics', hash: 'mr' } : null,
        webPage?.quarterlyAnalytics !== undefined ? { label: 'Quarterly Analytics', hash: 'qr' } : null,
        webPage?.annualReviews !== undefined ? { label: 'Annual Reviews', hash: 'annual-review' } : null,
        webPage?.adverseMedia !== undefined ? { label: 'Adverse Media', hash: 'adverse-media' } : null
      ].filter(Boolean)}
    />
  )
})

export default AlertsSideBar
