import React, { FC, memo } from 'react'

import { FieldProps, FormInputProps } from 'brief-form'
import { BankSourceCreateFormValue } from 'commons/types/DTO/bank/salesDataSourceWebPage'
import { FieldSet, Validators } from 'ui/components'
import { CurrencyInput, IntegerInput, TextareaInput } from 'ui/components/InputV2'
import { Col, Col2x, FormRow } from 'ui/themes/globalStyles'

export const SalesDataSourceForm: FC<{
  Field: <Input extends React.ComponentType<FormInputProps<any, any>>>(
    props: FieldProps<Input, BankSourceCreateFormValue>
  ) => JSX.Element
}> = memo(({ Field }) => (
  <FieldSet>
    <FormRow>
      <Col>
        <Field
          required
          name="gramsQty"
          input={IntegerInput}
          label="Grams Sold #"
          validator={Validators.required.number()}
          inputProps={{
            'data-qa': 'gramsQty',
            formatted: true
          }}
        />
      </Col>
      <Col>
        <Field
          required
          name="gramsSold"
          input={CurrencyInput}
          label="Grams Sold: $ Excluding Taxes"
          validator={Validators.required.number()}
          inputProps={{
            'data-qa': 'gramsSold'
          }}
        />
      </Col>
    </FormRow>
    <FormRow>
      <Col>
        <Field
          required
          name="unitsQty"
          input={IntegerInput}
          label="Units Sold #"
          validator={Validators.required.number()}
          inputProps={{
            'data-qa': 'unitsQty',
            formatted: true
          }}
        />
      </Col>
      <Col>
        <Field
          required
          name="unitsSold"
          input={CurrencyInput}
          label="Units Sold: $ Excluding Taxes"
          validator={Validators.required.number()}
          inputProps={{
            'data-qa': 'unitsSold'
          }}
        />
      </Col>
    </FormRow>
    <FormRow>
      <Col>
        <Field
          required
          name="totalWithTaxesAndParaphernaliaSales"
          input={CurrencyInput}
          label="Total Sales Amount"
          validator={Validators.required.number()}
          inputProps={{
            'data-qa': 'totalWithTaxesAndParaphernaliaSales',
            hint: 'Includes Taxes and Non-cannabis Sales'
          }}
        />
      </Col>
      <Col></Col>
    </FormRow>
    <FormRow>
      <Col2x>
        <Field
          name="internalNotes"
          input={TextareaInput}
          label="Internal Notes"
          inputProps={{
            'data-qa': 'internalNotes'
          }}
        />
      </Col2x>
    </FormRow>
  </FieldSet>
))
