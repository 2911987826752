import React, { FC, memo } from 'react'

import { Panel, PanelFace } from 'ui/components/Panel'

import { EntityHistoryProps } from './EntityHistoryProps'
import { Table } from './components'

export const EntityHistory: FC<EntityHistoryProps> = memo((props) => {
  const { id, type, dataQa, showTypeColumn, name, hideFilter, roundedTable = true } = props

  return (
    <Panel rounded={roundedTable} shadowed collapsible={false} face={PanelFace.SECONDARY}>
      <Table dataQa={dataQa} name={name} id={id} type={type} showTypeColumn={showTypeColumn} hideFilter={hideFilter} />
    </Panel>
  )
})
