import React, { memo } from 'react'

import { EntityHistory, HistoryEntityType } from 'bank-entity-history'
import { TableDataQa } from 'commons/types/enums'
import { PageSection, PageSectionFace } from 'ui/components/Page'

interface Props {
  id: number
}

export const History = memo(({ id }: Props) => (
  <PageSection face={PageSectionFace.THIRD} title="History" anchor="history">
    <EntityHistory
      hideFilter
      name="sar"
      dataQa={TableDataQa.BP_BSA_REPORTING_SAR_HISTORY}
      type={HistoryEntityType.BSA_SAR}
      id={id}
    />
  </PageSection>
))
