import React, { FC, memo } from 'react'

import { SpinnerSize } from '../../../../Spinner'
import { ContentProps } from './ContentProps'

import * as SC from './styles'

export const Content: FC<ContentProps> = memo(({ children, face, isPending, noPadding }) => (
  <SC.Box id="orbit-page" $face={face} $noPadding={noPadding}>
    {isPending && <SC.WrappedSpinner centered size={SpinnerSize.M} />}
    <SC.Inner isPending={isPending}>{children}</SC.Inner>
  </SC.Box>
))
