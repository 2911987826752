import React from 'react'

import { LicensesSICS } from 'commons/types/DTO/bank'
import { DateTime } from 'ui/components/DateTime'
import { Link } from 'ui/components/Link'
import { TableColumn } from 'ui/components/Table'

import { CheckMark } from '../../components/CheckMark'

export const columns = (): TableColumn<LicensesSICS>[] => [
  {
    title: 'Relationship',
    align: 'left',
    sortable: true,
    key: 'relationship_name',
    width: '400px',
    render: ({ relationship_id, relationship_name }) => (
      <Link to={`/relationships/${relationship_id}`}>{relationship_name}</Link>
    )
  },
  {
    title: 'DBA',
    align: 'left',
    sortable: true,
    key: 'dba_name',
    width: '300px',
    render: ({ dba_name }) => dba_name || '---'
  },
  {
    title: 'License Name',
    align: 'left',
    sortable: true,
    key: 'license_name',
    render: ({ license_name }) => license_name || '---'
  },
  {
    title: 'License Number',
    key: 'license_number',
    align: 'left',
    sortable: true,
    width: '300px'
  },
  {
    title: 'License Subtype',
    key: 'license_subtype',
    align: 'left',
    sortable: true,
    width: '300px'
  },
  {
    title: 'License State',
    align: 'left',
    sortable: true,
    key: 'license_name',
    render: ({ license_state }) => license_state || '---'
  },
  {
    title: 'Connected',
    align: 'center',
    sortable: true,
    key: 'connected',
    render: ({ connected }) => <CheckMark active={connected} />
  },
  {
    title: 'Last Connection Date',
    align: 'right',
    sortable: true,
    key: 'last_connection_date',
    render: ({ last_connection_date }) => <DateTime date={last_connection_date} />
  }
]
