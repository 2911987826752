import { useMutation, useQueryClient } from 'react-query'

import { PortalName } from 'commons/types/enums'
import { sendChallenge } from '~auth/service/mfa'
import { AuthMFAState, AuthSendChallengeResponse } from '~auth/types/DTO'

export const useSendChallenge = (p: PortalName) => {
  const client = useQueryClient()

  return useMutation((data: { deviceId: number; token: string }) => sendChallenge(p, data.deviceId), {
    onError: (error: Error) => {
      throw error
    },
    onSuccess: async (response: AuthSendChallengeResponse) => {
      const mfa = client.getQueryData<AuthMFAState>(['mfa'])
      client.setQueryData(['mfa'], {
        ...mfa,
        challengeId: response.challenge_id,
        challengeExpiresAt: response.expires_at,
        challengeStartedAt: Date.now()
      })
    }
  })
}
