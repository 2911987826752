import * as React from 'react'

import SC from 'styled-components'

const HeadingTitleSC = SC.div<{ large?: boolean }>`
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  font-size: ${(props) => (props.large ? '20px' : 'inherit')};
  margin-bottom: ${(props) => (props.large ? '20px' : 'inherit')};
`

interface Props {
  children: React.ReactNode
  large?: boolean
}

export const HeadingTitle = React.memo((props: Props) => (
  <HeadingTitleSC large={props.large}>{props.children}</HeadingTitleSC>
))
