import { useCallback } from 'react'

import { isEqual } from 'lodash'
import { useConfirmation } from 'ui/hooks'

import { OnboardingFormDispatch } from '../../types/actions'
import { ContactCopyHandler } from '../../types/forms'
import {
  RemoveATMHandler,
  RemoveCompanyHandler,
  RemoveContactHandler,
  RemoveLicenseHandler
} from '../../types/handlers'
import { CompanyFormValues } from '../../types/state'
import * as actions from './actions'
import { emptyATMServices, getEmptyATM } from './helpers/emptyEntity/getEmptyATM'
import { getEmptyCompany } from './helpers/emptyEntity/getEmptyCompany'
import {
  emptyContactAdditionalInformation,
  emptyContactDetails,
  emptyContactRoleInformation,
  getEmptyContact
} from './helpers/emptyEntity/getEmptyContact'
import { emptyAddressInformation, emptyLicenseDetails, getEmptyLicense } from './helpers/emptyEntity/getEmptyLicense'

export const useOnboardingFormAddRemoveItems = ({
  formDispatch,
  companiesFormValues
}: {
  formDispatch: OnboardingFormDispatch
  companiesFormValues: CompanyFormValues[]
}) => {
  const legalName = ''

  // Company Add, Select, Remove
  const handleAddCompany = useCallback(() => formDispatch(actions.addCompany()), [formDispatch])

  const callRemoveCompanyAction: RemoveCompanyHandler = useCallback(
    ({ companyIndex }) => formDispatch(actions.removeCompany({ companyIndex })),
    [formDispatch]
  )

  const { Confirmation: CompanyDelete, open: doRemoveCompany } = useConfirmation({
    message: `Are you sure you want to delete Company${legalName ? ' ' + legalName : ''}?`,
    onConfirm: callRemoveCompanyAction,
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  const handleRemoveCompany: RemoveCompanyHandler = useCallback(
    ({ companyIndex }) => {
      const companyForDelete = companiesFormValues[companyIndex]
      const emptyCompany = getEmptyCompany()
      if (isEqual(companyForDelete, emptyCompany)) {
        callRemoveCompanyAction({ companyIndex })
        return
      }
      doRemoveCompany({ companyIndex })
    },
    [companiesFormValues, callRemoveCompanyAction, doRemoveCompany]
  )

  // Licenses Add, Remove
  const handleAddLicense = useCallback(
    ({ companyIndex }: { companyIndex: number }) => formDispatch(actions.addLicense(companyIndex)),
    [formDispatch]
  )

  const callRemoveLicenseAction: RemoveLicenseHandler = useCallback(
    ({ companyIndex, licenseIndex }) => formDispatch(actions.removeLicense({ licenseIndex, companyIndex })),
    [formDispatch]
  )
  const { Confirmation: LicenseDelete, open: doRemoveLicense } = useConfirmation({
    message: `Are you sure you want to delete license?`,
    onConfirm: callRemoveLicenseAction,
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })
  const handleRemoveLicense: RemoveLicenseHandler = useCallback(
    ({ companyIndex, licenseIndex }) => {
      const licenseForDelete = companiesFormValues[companyIndex].licenses[licenseIndex]
      const emptyLicense = getEmptyLicense({ license: { ...emptyLicenseDetails, ...emptyAddressInformation } })
      if (isEqual(licenseForDelete, emptyLicense)) {
        callRemoveLicenseAction({ companyIndex, licenseIndex })
        return
      }
      doRemoveLicense({ companyIndex, licenseIndex })
    },
    [companiesFormValues, callRemoveLicenseAction, doRemoveLicense]
  )

  // Contacts Add, Remove
  const handleAddContact = useCallback(
    ({ companyIndex }: { companyIndex: number }) => formDispatch(actions.addContact(companyIndex)),
    [formDispatch]
  )
  const callRemoveContactAction: RemoveContactHandler = useCallback(
    ({ contactIndex, companyIndex }) => formDispatch(actions.removeContact({ contactIndex, companyIndex })),
    [formDispatch]
  )
  const { Confirmation: ContactDelete, open: doRemoveContact } = useConfirmation({
    dialogId: 'onboarding-first-step-contact-delete-confirmation-dialog',
    message: `Are you sure you want to delete contact?`,
    onConfirm: callRemoveContactAction,
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })
  const handleRemoveContact: RemoveContactHandler = useCallback(
    ({ contactIndex, companyIndex }) => {
      const contactsForDelete = companiesFormValues[companyIndex].contacts[contactIndex]
      const emptycontacts = getEmptyContact({
        contact: { ...emptyContactDetails, ...emptyContactRoleInformation, ...emptyContactAdditionalInformation }
      })
      if (isEqual(contactsForDelete, emptycontacts)) {
        callRemoveContactAction({ companyIndex, contactIndex })
        return
      }
      doRemoveContact({ companyIndex, contactIndex })
    },
    [companiesFormValues, callRemoveContactAction, doRemoveContact]
  )

  // ATMs Add, Remove
  const handleAddATM = useCallback(
    ({ companyIndex }: { companyIndex: number }) => formDispatch(actions.addATM(companyIndex)),
    [formDispatch]
  )

  const callRemoveATMAction: RemoveATMHandler = useCallback(
    ({ atmIndex, companyIndex }) => formDispatch(actions.removeATM({ companyIndex, atmIndex })),
    [formDispatch]
  )

  const { Confirmation: AtmDelete, open: doRemoveATM } = useConfirmation({
    message: `Are you sure you want to delete ATM?`,
    onConfirm: callRemoveATMAction,
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  const handleRemoveATM: RemoveATMHandler = useCallback(
    ({ companyIndex, atmIndex }) => {
      const atmForDelete = companiesFormValues[companyIndex].atms[atmIndex]
      const emptyAtm = getEmptyATM({ atm: { ...emptyATMServices } })
      if (isEqual(atmForDelete, emptyAtm)) {
        callRemoveATMAction({ companyIndex, atmIndex })
        return
      }
      doRemoveATM({ companyIndex, atmIndex })
    },
    [companiesFormValues, callRemoveATMAction, doRemoveATM]
  )

  const handleContactCopy: ContactCopyHandler = useCallback(
    (copyData) => formDispatch(actions.copyContact(copyData)),
    [formDispatch]
  )

  return {
    confirmations: { CompanyDelete, LicenseDelete, ContactDelete, AtmDelete },
    handlers: {
      handleContactCopy,
      handleAddCompany,
      handleRemoveCompany,
      handleAddLicense,
      handleAddContact,
      handleAddATM,
      handleRemoveLicense,
      handleRemoveContact,
      handleRemoveATM
    }
  }
}
