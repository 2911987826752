import React from 'react'

import { CONTACTS_PATH } from 'bank-contacts/src/constants'
import { Contact } from 'commons/types/DTO/bank/companyDetails/contact'
import { CheckMark } from 'ui/components'
import { Icon, IconName, IconSize } from 'ui/components/Icon'
import { MenuIconHoverFace, MenuItemBehavior, MenuItemTextFace, TableActions } from 'ui/components/QueryTable'
import { TableColumn } from 'ui/components/Table'
import { Tooltip } from 'ui/components/Tooltip'

import { NameLink, Phone } from './styles'

export const columns = (
  onDelete: (item: Contact) => any,
  setEditContact: (item: Contact) => void
): TableColumn<any>[] => [
  {
    align: 'left',
    title: 'Name',
    render: (item) => (
      <NameLink to={`${CONTACTS_PATH}/${item.id}`}>
        {item.name}
        {item.isShared && (
          <Tooltip id={`shared${item.id}`} type="dark" content="Shared" event="hover">
            <Icon name={IconName.SHARED} size={IconSize.XS} />
          </Tooltip>
        )}
        {item.isDocuments && (
          <Tooltip id={`documents${item.id}`} type="dark" content="Documents" event="hover">
            <Icon name={IconName.DOCUMENT} size={IconSize.XS} />
          </Tooltip>
        )}
        {item.isFinancials && (
          <Tooltip id={`financials${item.id}`} type="dark" content="Financials" event="hover">
            <Icon name={IconName.FINANCIALS} size={IconSize.XS} />
          </Tooltip>
        )}
      </NameLink>
    )
  },
  {
    title: 'Title',
    key: 'title'
  },
  {
    align: 'center',
    title: 'Email',
    key: 'email'
  },
  {
    align: 'center',
    title: 'Phone',
    render: ({ phone }) => <Phone>{phone}</Phone>
  },
  {
    title: 'Owner (Ownership %)',
    render: ({ isOwner, ownerPercentLabel }) => isOwner && <CheckMark value rounded label={ownerPercentLabel} />
  },
  {
    title: 'Debtholder',
    render: ({ isDebtholder }) => isDebtholder && <CheckMark value rounded label="" />
  },
  {
    title: 'Account Signer',
    render: ({ isAccountSigner }) => isAccountSigner && <CheckMark value rounded label="" />
  },
  {
    title: 'Actions',
    align: 'center',
    width: '50px',
    render: (item) => (
      <TableActions
        items={[
          {
            key: 1,
            title: 'Edit',
            behavior: MenuItemBehavior.BUTTON,
            iconName: IconName.EDIT,
            iconHoverFace: MenuIconHoverFace.PRIMARY,
            textFace: MenuItemTextFace.GREY,
            onClick: () => setEditContact(item)
          },
          {
            key: 2,
            title: 'Disconnect',
            behavior: MenuItemBehavior.BUTTON,
            iconName: IconName.DELETE,
            iconHoverFace: MenuIconHoverFace.DANGER,
            textFace: MenuItemTextFace.GREY,
            onClick: () => onDelete(item)
          }
        ]}
      />
    )
  }
]
