import { handlerServerError } from 'errors'

import { useSendAgreement } from './useSendAgreement'

export const useCustomerAcknowledgement = ({ onSuccess, onError }: { onSuccess: () => void; onError: () => void }) => {
  const { routine: sendAgreement } = useSendAgreement({
    onSuccess: async () => {
      onSuccess()
    },
    onError: (error) => {
      onError()
      handlerServerError(error)
    }
  })

  return { sendAgreement }
}
