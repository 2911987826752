import React from 'react'

import { DatePicker, TextInput } from 'ui/components'

type ArgsType = {
  fieldName: string
  props: any
}

export const getCorrectFieldInput = ({ fieldName, props }: ArgsType) => {
  if (fieldName.toLocaleLowerCase().includes('date')) {
    return <DatePicker {...props} />
  } else {
    return <TextInput {...props} />
  }
}
