import { editEnvelopeOnboardingDocuments } from 'commons/service/bank/onboardingDocuments'
import { OnboardingDocumentSetupFormRequest } from 'commons/types/DTO/bank/applications/onboardingDocuments'
import { WriteHook, useMutationManager } from 'state-manager'

export const useEditEnvelopeOnboardingDocuments: WriteHook<
  OnboardingDocumentSetupFormRequest & { applicationId: number; id: number },
  void
> = (opts) =>
  useMutationManager<OnboardingDocumentSetupFormRequest & { applicationId: number; id: number }, void>(
    editEnvelopeOnboardingDocuments,
    opts
  )
