import { LicenseItemFormValue } from '../../../types/forms'
import { CompanyFormValues } from '../../../types/state'

export const setNewLicenses = ({
  oldCompany,
  licenseIndex,
  newLicense
}: {
  oldCompany: CompanyFormValues
  licenseIndex: number
  newLicense: LicenseItemFormValue
}) =>
  oldCompany.licenses.map((licenseItem, licenseItemIndex) =>
    licenseItemIndex === licenseIndex ? newLicense : licenseItem
  )
