import { CSSProperties, HTMLProps, MutableRefObject, ReactNode } from 'react'

import { Placement } from '@floating-ui/react-dom-interactions'

export const enum AutoSizeWidth {
  FILL = 'fill',
  DOUBLE = 'double'
}

export interface PopperProps {
  placement?: Placement
  scrollClose?: boolean
  style?: CSSProperties
  opened?: boolean
  disabled?: boolean

  autoSize?: boolean
  autoSizeMaxHeight?: number
  autoSizeMinHeight?: number
  autoSizeScreenEdgeMargin?: number
  autoSizeWidth?: `${AutoSizeWidth}`

  /**
   * next for list content
   */

  listRef?: MutableRefObject<Array<HTMLElement | null>>
  /* active means focused, hover, etc */
  activeIndex?: number
  setActiveIndex?: (index: number) => void
  /* selected means selected value */
  selectedIndex?: number
  setSelectedIndex?: (index: number) => void

  /**
   * end for list content
   */

  children: JSX.Element

  render: (data: { close: () => void; getItemProps?: (userProps?: HTMLProps<HTMLElement>) => any }) => ReactNode

  onClose?: () => void
  onOpen?: () => void
}
