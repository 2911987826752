import styled, { css } from 'styled-components'
import { Button } from 'ui/components/Buttons'

export const WelcomeBlock = styled.div``

export const StatusHeader = styled.div`
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.29px;
  color: #7b7b7b;
  margin-bottom: 36px;
`

export const StatusValue = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  margin-top: 5px;
  margin-bottom: 5px;
`

export const Text = css`
  margin-bottom: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: black;
`

export const Mark = styled.div`
  font-weight: 600;
  font-style: italic;
  color: #868686;
`

export const Incomplete = styled.div`
  font-weight: 600;
  font-style: italic;
  color: #df7837;
`

export const Approved = styled.div`
  font-weight: 600;
  font-style: italic;
  color: #6098b6;
`

export const Notes = css`
  max-width: 850px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: black;
  margin-top: 14px;
`

export const WelcomeButton = styled(Button)`
  margin-bottom: 40px;
`

export const ClientNotes = styled.div`
  ${Notes}
  ${Text}
`
