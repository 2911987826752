import { useMutationManager } from 'react-query-state-manager'

import { handlerServerError } from 'errors'
import { Toast } from 'ui/components/Toast'
import { CTR } from '~bank-bsa-reporting/service'

export const useCTRApprove = (onSuccess: () => void, onError: () => void) =>
  useMutationManager(CTR.approveCTR, {
    onSuccess: async () => {
      Toast.successSmall('The CTR was approved successfully')
      await onSuccess()
    },
    onError: (error) => {
      handlerServerError(error)
      onError()
    }
  })
