export type CompanyWebPage = {
  accountName: string
  active: boolean
  businessType: string
  customerStatus: string
  dba: string | null
  entityType: string
  holdingCompany: string
  holdingID: number | null
  id: number
}
