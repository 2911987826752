export enum OnboardingDocumentsItemStatusEnum {
  CREATED = 'created',
  SENT = 'sent',
  SIGNED = 'signed',
  CANCELLED = 'cancelled'
}

export type OnboardingDocumentsItem = {
  canCancel: boolean
  canDelete: boolean
  canDownload: boolean
  canEdit: boolean
  canSend: boolean
  canClone: boolean
  createdAt: Date
  documentsCount: number
  id: number
  sentAt: Date
  status: OnboardingDocumentsItemStatusEnum
}
