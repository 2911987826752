import React, { useCallback } from 'react'

import { getRouteApi } from '@tanstack/react-router'
import { SpinnerSize } from 'ui/components/Spinner'
import { useAnnualReviewList, useCompany, useNotFound, useUpdateAnnualReview } from '~bank-annual-review/hooks'

import { AnnualReviewEditForm, AnnualReviewFormValue } from './components/AnnualReviewEditForm'
import { generateCBTSubmitModel, generateSubmitModel } from './generateSubmitModel'

import { WrappedSpinner } from './styles'

const AnnualReviewEditContainer: React.FC = () => {
  const { id, companyId } = getRouteApi('/annual-review/edit/$companyId/$id').useParams()

  const intId = +id
  const intCompanyId = +companyId

  const annualReviewList = useAnnualReviewList({
    company_id: intCompanyId,
    id: intId
  })
  const companyList = useCompany({ id: intCompanyId })
  const updateAnnualReviewMutation = useUpdateAnnualReview(intCompanyId, intId)

  const annualReview = annualReviewList.data?.records[0]
  const company = companyList.data?.records[0]
  const isPending = annualReviewList.isFetching

  const [isNotFound] = useNotFound(annualReview, null, isPending)

  const onSubmit = useCallback(
    (formValue: AnnualReviewFormValue) => {
      if (formValue.hasOwnProperty('questionnaire.has_more_revenue')) {
        updateAnnualReviewMutation.mutate(generateCBTSubmitModel(formValue, annualReview))
      } else {
        updateAnnualReviewMutation.mutate(generateSubmitModel(formValue, annualReview))
      }
    },
    [updateAnnualReviewMutation.mutate, annualReview]
  )

  return annualReview && company ? (
    <AnnualReviewEditForm
      id={intId}
      companyId={intCompanyId}
      isPending={isPending}
      annualReview={annualReview}
      company={company}
      onSubmit={onSubmit}
      isNotFound={isNotFound}
    />
  ) : (
    <WrappedSpinner centered size={SpinnerSize.M} />
  )
}

export default AnnualReviewEditContainer
