import { DocumentReport } from 'commons/types/DTO/bank'

export const dataSourceSelector = (data: any) =>
  (data?.list?.records || []).map((record: DocumentReport) => ({
    item: {
      account_signer: record.account_signer,
      active: record.active,
      ancillary: record.ancillary,
      consumer_account: record.consumer_account,
      corporation: record.corporation,
      created_at: record.created_at,
      deleted_at: record.deleted_at,
      due_start_date: record.due_start_date,
      expiration_delay_days: record.expiration_delay_days,
      frequency: record.frequency,
      hemp: record.hemp,
      id: record.id,
      internal: record.internal,
      investment: record.investment,
      level: record.level,
      license_dispensary: record.license_dispensary,
      license_grower: record.license_grower,
      license_medical: record.license_medical,
      license_processor: record.license_processor,
      license_recreational: record.license_recreational,
      license_waste_disposal: record.license_waste_disposal,
      llc: record.llc,
      management: record.management,
      minimal_ownership_percent: record.minimal_ownership_percent,
      mrb: record.mrb,
      mrb_related: record.mrb_related,
      name: record.name,
      optional: record.optional,
      organization_id: record.organization_id,
      owner: record.owner,
      package: record.package,
      partnership: record.partnership,
      pre_license_mrb: record.pre_license_mrb,
      sole_proprietor: record.sole_proprietor,
      start_date_type: record.start_date_type,
      state: record.state,
      tribal_mrb: record.tribal_mrb,
      trust: record.trust,
      type: record.type,
      updated_at: record.updated_at,
      license_delivery: record.license_delivery,
      license_operator: record.license_operator
    }
  }))
