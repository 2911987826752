import { useQuery } from 'react-query'

import { Company } from 'commons/types/DTO/bank'
import { ApiReadResponse } from 'commons/types/api'
import { affiliatedCompaniesList, affiliatedCompanyList } from '~bank-annual-review/service'

interface AffiliatedCompaniesProp {
  company_id?: number
}

export const useAffiliatedCompanies = (
  props: AffiliatedCompaniesProp,
  suppressFetch?: boolean
): { isFetching: boolean; data?: ApiReadResponse<Company> } => {
  const { data } = useQuery<any, Error>(
    ['affiliated-companies'],
    () =>
      suppressFetch
        ? Promise.resolve({ isFetching: false, data: undefined })
        : affiliatedCompaniesList(props.company_id),
    { keepPreviousData: true }
  )

  const companies = data?.records

  const result = useQuery<any, Error>(
    ['companies-list'],
    () => (suppressFetch ? Promise.resolve({ isFetching: false, data: undefined }) : affiliatedCompanyList(companies)),
    { keepPreviousData: true, enabled: !!companies }
  )

  return {
    isFetching: result.isFetching,
    data: result.data
  }
}
