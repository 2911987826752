import { useMutation, useQueryClient } from 'react-query'

import { useNavigate } from '@tanstack/react-router'
import { AnnualReview } from 'commons/types/DTO/bank'
import { Request } from 'commons/utils/request'
import { handlerServerError } from 'errors'

export const useUpdateAnnualReview = (companyId: number, id: number) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  return useMutation((annualReview: AnnualReview) => Request.put(Request.urls.bank.annualReview, { ...annualReview }), {
    onError: (error) => handlerServerError(error),
    onSuccess: () => {
      navigate({ to: '/annual-review/detail/$companyId/$id', params: { companyId: companyId, id: id } })
      queryClient.invalidateQueries('annual-review-list')
    }
  })
}
