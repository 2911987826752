export enum ctrIdentificationTypesLabels {
  CTR_ID_TYPE_UNKNOWN = 'Unknown',
  CTR_ID_TYPE_DRIVER_LICENSE = "Driver's license/State ID",
  CTR_ID_TYPE_PASSPORT = 'Passport',
  CTR_ID_TYPE_ALIEN = 'Alien Registration',
  CTR_ID_TYPE_OTHER = 'Other'
}

export enum ctrIdentificationTypesValues {
  CTR_ID_TYPE_UNKNOWN = '',
  CTR_ID_TYPE_PASSPORT = 'Passport',
  CTR_ID_TYPE_ALIEN = 'Alien Registration',
  CTR_ID_TYPE_OTHER = 'Other',
  CTR_ID_TYPE_DRIVER_LICENSE = "Driver's license/State ID"
}

export const ctrIdentificationTypes = [
  { label: ctrIdentificationTypesLabels.CTR_ID_TYPE_UNKNOWN, value: ctrIdentificationTypesValues.CTR_ID_TYPE_UNKNOWN },
  {
    label: ctrIdentificationTypesLabels.CTR_ID_TYPE_DRIVER_LICENSE,
    value: ctrIdentificationTypesValues.CTR_ID_TYPE_DRIVER_LICENSE
  },
  {
    label: ctrIdentificationTypesLabels.CTR_ID_TYPE_PASSPORT,
    value: ctrIdentificationTypesValues.CTR_ID_TYPE_PASSPORT
  },
  { label: ctrIdentificationTypesLabels.CTR_ID_TYPE_ALIEN, value: ctrIdentificationTypesValues.CTR_ID_TYPE_ALIEN },
  { label: ctrIdentificationTypesLabels.CTR_ID_TYPE_OTHER, value: ctrIdentificationTypesValues.CTR_ID_TYPE_OTHER }
]
