import styled from 'styled-components'
import { CSS } from 'ui/helpers/cssHelpers'

export const Box = styled.div`
  position: relative;
  display: inline-block;
`

export const Menu = styled.div`
  box-shadow: 0 7px 21px 0 rgba(0, 0, 0, 0.11);
  border-radius: 8px;
  background: #fff;
  ${(p) => CSS.populateFont(p.theme.uiKit.typography.bodyM.regular)};
`

export const ButtonWrapper = styled.div`
  position: relative;
  cursor: pointer;
  display: inline-block;
`
