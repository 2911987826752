import { RoleInformation } from 'commons/types/DTO/commons'

import { RoleInformationFormValue } from '../../../../types'

export const mapRoleInformationForm = ({
  accountSigner,
  conductor,
  debtholder,
  owner
}: RoleInformationFormValue): RoleInformation => ({
  accountSigner,
  conductor,
  debtholder,
  owner
})
