import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 19px 35px;
  background-color: rgba(0, 0, 0, 0.02);
  margin: 35px -35px;
`

export const Header = styled.div`
  background-color: #f5f5f5;
  padding: 14px 35px;
  margin: -19px -35px 19px -35px;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  color: #484848;
`
export const Columns = styled.div`
  display: flex;
`

export const Column = styled.div`
  width: 416px;
`
