export const fakeLinkDownload = (url: string, filename?: string) => {
  const dummy = document.createElement('a')
  dummy.href = url
  dummy.target = '_blank'
  document.body.appendChild(dummy)

  if (filename) {
    dummy.download = filename
  }

  dummy.click()
  document.body.removeChild(dummy)
}
