import React from 'react'

import { Col, Col2x, FormRow } from 'ui/themes/globalStyles'

import { useSARFormContext } from '../../../../../../../context'
import {
  Accountant,
  ActionDate,
  Agent,
  Appraiser,
  Attorney,
  Borrower,
  Customer,
  Director,
  Employee,
  NoRelationship,
  Officer,
  Other,
  OtherDesk,
  Owner,
  RelationshipStatus,
  TIN
} from './fields'

import { s } from './styles'

type RelationshipsInfoProps = {
  index: number
}

export const RelationshipsInfo = ({ index }: RelationshipsInfoProps) => {
  const { formApi } = useSARFormContext()
  const other = formApi.useStore((s) => s.values.subjects_info[index].relationships_info[0].other)

  return (
    <>
      <FormRow>
        <Col2x>
          <h5>24. Relationship of the subject to an institution listed in Part III or IV</h5>
        </Col2x>
      </FormRow>
      <FormRow>
        <Col>
          <TIN index={index} />
        </Col>
      </FormRow>
      <FormRow>
        <Col>
          <s.CheckBoxWrapper>
            <Accountant index={index} />
          </s.CheckBoxWrapper>
          <s.CheckBoxWrapper>
            <Agent index={index} />
          </s.CheckBoxWrapper>
          <s.CheckBoxWrapper>
            <Appraiser index={index} />
          </s.CheckBoxWrapper>
          <s.CheckBoxWrapper>
            <Attorney index={index} />
          </s.CheckBoxWrapper>
          <s.CheckBoxWrapper>
            <Borrower index={index} />
          </s.CheckBoxWrapper>
          <s.CheckBoxWrapper>
            <Customer index={index} />
          </s.CheckBoxWrapper>
        </Col>
        <Col>
          <s.CheckBoxWrapper>
            <Director index={index} />
          </s.CheckBoxWrapper>
          <s.CheckBoxWrapper>
            <Employee index={index} />
          </s.CheckBoxWrapper>
          <s.CheckBoxWrapper>
            <NoRelationship index={index} />
          </s.CheckBoxWrapper>
          <s.CheckBoxWrapper>
            <Officer index={index} />
          </s.CheckBoxWrapper>
          <s.CheckBoxWrapper>
            <Owner index={index} />
          </s.CheckBoxWrapper>
          <s.CheckBoxWrapper>
            <Other index={index} />
          </s.CheckBoxWrapper>
          <s.VisibilityBox $visible={other}>
            <OtherDesk index={index} />
          </s.VisibilityBox>
        </Col>
      </FormRow>
      <FormRow>
        <Col>
          <RelationshipStatus index={index} />
        </Col>
        <Col>
          <ActionDate index={index} />
        </Col>
      </FormRow>
    </>
  )
}
