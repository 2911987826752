import { AnyRoute, createRoute, lazyRouteComponent } from '@tanstack/react-router'

export const bankAccountCreateRoute = <T extends AnyRoute>(indexRoute: T) =>
  createRoute({
    getParentRoute: () => indexRoute,
    path: `/new`,
    component: lazyRouteComponent(() => import('./Create')),
    validateSearch: (search: Record<string, unknown>) => {
      const companyId = Number(search.companyId)
      return {
        companyId: Number.isNaN(companyId) ? 0 : companyId
      }
    }
  })
