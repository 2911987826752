import { bankAccountNumber } from './bankAccountNumber'
import { email } from './email'
import { number } from './number'
import { required } from './required'
import { string } from './string'
import { url } from './url'

export const Validators = {
  required,
  bankAccountNumber,
  email,
  number,
  url,
  string
}
