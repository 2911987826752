import React, { forwardRef, memo, useCallback } from 'react'

import IMask from 'imask'

import { BaseMaskedInput } from './BaseMaskedInput'
import { CurrencyInputProps } from './props'
import { InputIconPosition, Value } from './types'

const DOLLAR_SYMBOL = '$'
// limit input length, coz we are easy to overflow Int type
const MAX_LENGTH = 15

export const CurrencyInput = memo(
  forwardRef<HTMLInputElement, CurrencyInputProps>((props: CurrencyInputProps, ref) => {
    const { onChange, allowDecimal = true, defaultEmptyValue = '', ...rest } = props
    /* temp solution, lets think how work with MR forms (validation, type convertion) */
    const handleChange = useCallback(
      (value: Value, error) => {
        const floatValue = parseFloat(value as string)
        onChange(isNaN(floatValue) ? defaultEmptyValue : floatValue, error)
      },
      [onChange]
    )

    const mask = IMask.createMask({
      mask: Number,
      thousandsSeparator: ',',
      scale: allowDecimal ? 2 : 0,
      radix: '.'
    })

    return (
      <BaseMaskedInput
        ref={ref}
        onChange={handleChange}
        {...rest}
        mask={mask}
        renderIcon={DOLLAR_SYMBOL}
        iconPosition={InputIconPosition.RIGHT_CLOSE}
        maxLength={MAX_LENGTH}
      />
    )
  })
)
