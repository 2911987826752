import React, { FC, useCallback } from 'react'

import { useRouter } from '@tanstack/react-router'
import { Form, useFormData } from 'brief-form'
import { ApplicationStatus } from 'commons/types/enums'
import { Button } from 'ui/components/Buttons'
import { Dialog, DialogFace } from 'ui/components/Dialog'
import { TextareaInput } from 'ui/components/InputV2'
import { Toast } from 'ui/components/Toast'
import { FormRow } from 'ui/themes/globalStyles'

import { useGetOldApplication } from '../../../../../../hooks/useGetOldApplication'
import { useRevertApplication } from '../../../../../../hooks/useRevertApplication'
import { useUpdateApplication } from '../../../../../../hooks/useUpdateApplication'
import { RevertToDialogProps } from './RevertToDialogProps'

import { Content } from './styles'

export const RevertToDialog: FC<RevertToDialogProps> = ({ application, onCloseModal, status }) => {
  const router = useRouter()
  const { revertApplicationToRFI } = useRevertApplication()
  const { getOldApplication } = useGetOldApplication()
  const { updateApplication } = useUpdateApplication()

  const { config, Field } = useFormData({
    initialValue: {
      notes: ''
    }
  })

  const onRevert = useCallback(async () => {
    try {
      // TODO: this is hot fix for tests
      // TODO: remove this after the backend creates separate endpoints
      const oldApplication = await getOldApplication(application.applicationID)

      const statusKey = Object.keys(ApplicationStatus).find((name) => ApplicationStatus[name] === status)

      await updateApplication({
        ...oldApplication.records[0],
        status: ApplicationStatus[statusKey || ''],
        bank_notes: config.value.notes
      })
      Toast.success('Application was reverted')
      router.invalidate()
    } catch (error) {
      Toast.error(error?.message || 'An error has occurred while reverting')
    }
  }, [getOldApplication, router, updateApplication, config.value, application, status])

  const onRevertToRFI = useCallback(async () => {
    try {
      await revertApplicationToRFI(application.applicationID, config.value.notes)
      Toast.success('Application was reverted')
      router.invalidate()
    } catch (error) {
      Toast.error(error?.message || 'An error has occurred while reverting')
    }
  }, [application.applicationID, config.value.notes, revertApplicationToRFI, router])

  const onRevertApplication = status === ApplicationStatus.RFI_PENDING ? onRevertToRFI : onRevert

  return (
    <Dialog
      id="revert-to-dialog"
      onClose={onCloseModal}
      face={DialogFace.DEFAULT}
      title="Are you sure you want to revert the Application?"
      customFooterActions={
        <>
          <Button onClick={onRevertApplication} face="negative">
            Revert
          </Button>
          <Button face="neutral" onClick={onCloseModal}>
            Cancel
          </Button>
        </>
      }
    >
      <Content>
        <Form config={config}>
          <FormRow>
            <Field name="notes" label="Notes" input={TextareaInput} inputProps={{}} />
          </FormRow>
        </Form>
      </Content>
    </Dialog>
  )
}
