import * as React from 'react'

import { Button, ButtonFace, ButtonSize } from '../Button'
import { Pages } from './Pages'

interface Props {
  size?: ButtonSize
  onPageChange: (page: number, size: number) => void
  pageCount: number
  selectedPage: number
}

export const CompactPages = ({ pageCount, selectedPage, onPageChange, size }: Props) => {
  if (selectedPage > pageCount - 4) {
    return (
      <>
        <Button face={ButtonFace.SECONDARY} onClick={() => onPageChange(1, pageCount)} size={size}>
          {1}
        </Button>
        {'...'}
        <Pages
          pageCount={5}
          selectedPage={selectedPage}
          onPageChange={onPageChange}
          enlargement={pageCount - 4}
          size={size}
        />
      </>
    )
  }
  if (selectedPage < 5) {
    return (
      <>
        <Pages pageCount={5} selectedPage={selectedPage} onPageChange={onPageChange} size={size} />
        {'...'}
        <Button face={ButtonFace.SECONDARY} onClick={() => onPageChange(pageCount, pageCount)} size={size}>
          {pageCount}
        </Button>
      </>
    )
  }

  return (
    <>
      <Button face={ButtonFace.SECONDARY} onClick={() => onPageChange(1, pageCount)} size={size}>
        {1}
      </Button>
      {'...'}
      <Pages
        pageCount={3}
        selectedPage={selectedPage}
        onPageChange={onPageChange}
        enlargement={selectedPage - 1}
        size={size}
      />
      {'...'}
      <Button face={ButtonFace.SECONDARY} onClick={() => onPageChange(pageCount, pageCount)} size={size}>
        {pageCount}
      </Button>
    </>
  )
}
