import * as React from 'react'
import { Id, UpdateOptions, toast } from 'react-toastify'

import { Icon, IconName, IconSize } from 'ui/components/Icon'

import * as SC from './styles'

const ThinCloseButton = ({ closeToast }: any) => (
  <SC.Button onClick={closeToast}>
    <Icon name={IconName.CLEAR_THIN} />
  </SC.Button>
)

export const Toast = {
  dismiss: () => {
    toast.dismiss()
  },
  update: (toastId: Id, options: UpdateOptions) => {
    toast.update(toastId, options)
  },
  success: (title: React.ReactNode, message?: React.ReactNode) => {
    toast.success(
      <SC.BigToastContent>
        <SC.Title>
          <SC.WrappedIcon name={IconName.CHECK_WHITE} /> {title || 'Success'}
        </SC.Title>
        {message && <SC.Content>{message}</SC.Content>}
      </SC.BigToastContent>,
      {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 4000
      }
    )
  },
  successSmall: (message: React.ReactNode) => {
    toast.success(
      <SC.SmallToastContent>
        <SC.SmallToastIcon name={IconName.CHECK_WHITE} size={IconSize.S} /> {message}
      </SC.SmallToastContent>,
      {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
        closeButton: ThinCloseButton,
        hideProgressBar: true
      }
    )
  },
  error: (title: React.ReactNode, message?: React.ReactNode) => {
    toast.error(
      <SC.BigToastContent>
        <SC.Title>
          <SC.WrappedIcon name={IconName.EXCLAMATION_WHITE} /> {title || 'Error'}
        </SC.Title>
        {message && <SC.Content>{message}</SC.Content>}
      </SC.BigToastContent>,
      {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 4000
      }
    )
  },
  errorSmall: (message: React.ReactNode) => {
    toast.error(
      <SC.SmallToastContent>
        <SC.SmallToastIcon name={IconName.EXCLAMATION_WHITE} size={IconSize.S} /> {message}
      </SC.SmallToastContent>,
      {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
        closeButton: ThinCloseButton,
        hideProgressBar: true
      }
    )
  },
  info: (title: React.ReactNode, message?: React.ReactNode) => {
    toast.info(
      <SC.BigToastContent>
        <SC.Title>
          <SC.WrappedIcon name={IconName.INFO_WHITE} /> {title || 'Information'}
        </SC.Title>
        {message && <SC.Content>{message}</SC.Content>}
      </SC.BigToastContent>,
      {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 4000
      }
    )
  },
  infoSuccessSmall: (message: React.ReactNode) => {
    toast.info(
      <SC.SmallToastContent>
        <SC.SmallToastIcon name={IconName.CHECK_WHITE} size={IconSize.S} /> {message}
      </SC.SmallToastContent>,
      {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
        closeButton: ThinCloseButton,
        hideProgressBar: true
      }
    )
  },
  infoSmall: (message: React.ReactNode) => {
    toast.info(
      <SC.SmallToastContent>
        <SC.SmallToastIcon name={IconName.INFO_WHITE} size={IconSize.S} /> {message}
      </SC.SmallToastContent>,
      {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
        closeButton: ThinCloseButton,
        hideProgressBar: true
      }
    )
  },
  default: (title: React.ReactNode, message?: React.ReactNode) => {
    toast(
      <SC.BigToastContent>
        <SC.Title>
          <SC.WrappedIconGreen name={IconName.INFO_GREEN} /> {title || 'Information'}
        </SC.Title>
        {message && <SC.Content>{message}</SC.Content>}
      </SC.BigToastContent>,
      {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 4000
      }
    )
  },
  defaultSmall: (message: React.ReactNode) => {
    toast(
      <SC.SmallToastContent>
        <SC.SmallToastIconGreen name={IconName.INFO_GREEN} size={IconSize.S} /> {message}
      </SC.SmallToastContent>,
      {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
        closeButton: ThinCloseButton,
        hideProgressBar: true
      }
    )
  }
}
