import React, { memo } from 'react'

import { FieldProps, Form, FormInputProps } from 'brief-form'
import { bsaCTRStatuses } from 'commons/types/dictionaries'
import { CompanySelect, Select } from 'ui/components'
import { FilterPanel } from 'ui/components/Table'

import { Filter } from './Filter'

import * as SC from './styles'

interface Props {
  config: any
  isDirty: boolean
  reset: () => void
  Field: <Input extends React.ComponentType<FormInputProps<any, any>>>(props: FieldProps<Input, Filter>) => JSX.Element
}

export const FilterForm = memo(({ config, isDirty, reset, Field }: Props) => (
  <FilterPanel isDirty={isDirty} onReset={reset}>
    <SC.FormWrapper>
      <Form config={config}>
        <SC.Row>
          <Field
            name="company_id"
            label="Company"
            input={CompanySelect}
            inputProps={{
              defaultOption: { label: 'All', value: '' }
            }}
          />
          <Field
            name="status"
            label="Status"
            input={Select}
            inputProps={{
              data: [{ label: 'All', value: '' }].concat(bsaCTRStatuses)
            }}
          />
        </SC.Row>
      </Form>
    </SC.FormWrapper>
  </FilterPanel>
))
