import styled from 'styled-components'

export const Container = styled.div`
  display: block;
`

export const InfoColumn = styled.div`
  flex: 1;
  margin-right: 30px;
`
