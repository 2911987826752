[data-theme='Transact'] {
  --theme-colors-theme-brand-lightest: #70d8ed;
  --decimal--theme-colors-theme-brand-lightest: 112, 216, 237;
  --theme-colors-theme-brand-lighter: #11bee1;
  --decimal--theme-colors-theme-brand-lighter: 17, 190, 225;
  --theme-colors-theme-brand-primary: #0267da;
  --decimal--theme-colors-theme-brand-primary: 2, 103, 218;
  --theme-colors-theme-brand-darker: #2d3845;
  --decimal--theme-colors-theme-brand-darker: 45, 56, 69;
  --theme-colors-theme-brand-darkest: #1b2229;
  --decimal--theme-colors-theme-brand-darkest: 27, 34, 41;
  --theme-colors-theme-brand-transparent-opacity12: rgba(var(--decimal--theme-colors-theme-brand-primary), 0.12);
  --theme-colors-theme-brand-transparent-opacity60: rgba(var(--decimal--theme-colors-theme-brand-primary), 0.6);
  --theme-colors-theme-gradient-login: linear-gradient(200deg, #3585e1 0%, #356bc7 100%);
  --theme-colors-theme-positive-main: var(--theme-colors-theme-brand-primary);
  --theme-colors-theme-positive-darker: var(--theme-colors-theme-brand-darker);
  --theme-colors-theme-positive-darkest: var(--theme-colors-theme-brand-darkest);
  --theme-colors-theme-positive-transparent-opacity12: rgba(var(--decimal--theme-colors-theme-positive-main), 0.12);
  --theme-colors-theme-negative-main: #fa5252;
  --decimal--theme-colors-theme-negative-main: 250, 82, 82;
  --theme-colors-theme-negative-darker: #b63c3c;
  --decimal--theme-colors-theme-negative-darker: 182, 60, 60;
  --theme-colors-theme-negative-darkest: #722626;
  --decimal--theme-colors-theme-negative-darkest: 114, 38, 38;
  --theme-colors-theme-negative-transparent-opacity12: rgba(var(--decimal--theme-colors-theme-negative-main), 0.12);
  --theme-colors-theme-negative-transparent-opacity24: rgba(var(--decimal--theme-colors-theme-negative-main), 0.24);
  --theme-colors-theme-negative-transparent-opacity60: rgba(var(--decimal--theme-colors-theme-negative-main), 0.6);
  --theme-colors-theme-warning-main: #ffb930;
  --decimal--theme-colors-theme-warning-main: 255, 185, 48;
  --theme-colors-theme-warning-secondary: #aa80ef;
  --decimal--theme-colors-theme-warning-secondary: 170, 128, 239;
  --theme-colors-theme-warning-transparent-opacity12: rgba(var(--decimal--theme-colors-theme-warning-main), 0.12);
  --theme-colors-theme-warning-transparent-opacity60: rgba(var(--decimal--theme-colors-theme-warning-main), 0.6);
  --theme-colors-global-grey-grey100: #f5f5f5;
  --decimal--theme-colors-global-grey-grey100: 245, 245, 245;
  --theme-colors-global-grey-grey300: #e2e2e2;
  --decimal--theme-colors-global-grey-grey300: 226, 226, 226;
  --theme-colors-global-grey-grey500: #b8b8b8;
  --decimal--theme-colors-global-grey-grey500: 184, 184, 184;
  --theme-colors-global-grey-grey700: #868686;
  --decimal--theme-colors-global-grey-grey700: 134, 134, 134;
  --theme-colors-global-grey-grey900: #484848;
  --decimal--theme-colors-global-grey-grey900: 72, 72, 72;
  --theme-colors-global-general-main: #ffffff;
  --decimal--theme-colors-global-general-main: 255, 255, 255;
  --theme-colors-global-general-secondary: #000000;
  --decimal--theme-colors-global-general-secondary: 0, 0, 0;
  --theme-colors-global-general-transparent: #ffffff00;
  --decimal--theme-colors-global-general-transparent: 255, 255, 255;
  --theme-boxShadows-soft-soft100: 0px 0px 20px 0px #0000001a;
  --theme-boxShadows-soft-soft200: 0px 0px 20px 0px #00000033;
  --theme-boxShadows-offset-offset100Y4: 0px 4px 12px 0px #0000001a;
  --theme-boxShadows-offset-offset100Y8: 0px 8px 20px 0px #0000001a;
  --theme-boxShadows-offset-offset80Y6: 0px 6px 24px 0px #00000014;
  --theme-boxShadows-none-shadowNone: 0px 0px 0px 0px #000000;
  --theme-fontFamilies-brandFont: Source Sans Pro;
  --theme-lineHeights-xxl: calc(var(--theme-size-grid) * 8);
  --theme-lineHeights-xl: calc(var(--theme-size-grid) * 7);
  --theme-lineHeights-l: calc(var(--theme-size-grid) * 6);
  --theme-lineHeights-m: calc(var(--theme-size-grid) * 5);
  --theme-lineHeights-s: calc(var(--theme-size-grid) * 4);
  --theme-fontWeights-400: 400;
  --theme-fontWeights-600: 600;
  --theme-fontWeights-700: 700;
  --theme-fontWeights-italic: Italic;
  --theme-fontSize-h1: calc(var(--theme-size-grid) * 6.5);
  --theme-fontSize-h2: calc(var(--theme-size-grid) * 6);
  --theme-fontSize-h3: calc(var(--theme-size-grid) * 5.5);
  --theme-fontSize-h4: calc(var(--theme-size-grid) * 5);
  --theme-fontSize-h5: calc(var(--theme-size-grid) * 4.5);
  --theme-fontSize-bodyL: calc(var(--theme-size-grid) * 4);
  --theme-fontSize-bodyM: calc(var(--theme-size-grid) * 3.5);
  --theme-fontSize-bodySm: calc(var(--theme-size-grid) * 3.25);
  --theme-fontSize-bodyS: calc(var(--theme-size-grid) * 3);
  --theme-fontSize-special: calc(var(--theme-size-grid) * 2.5);
  --theme-letterSpacing-l: calc(var(--theme-size-grid) * 0.15);
  --theme-letterSpacing-m: calc(var(--theme-size-grid) * 0.125);
  --theme-letterSpacing-s: calc(var(--theme-size-grid) * 0.05);
  --theme-letterSpacing-none: 0px;
  --theme-paragraphSpacing-none: 0px;
  --theme-typography-h1-regular: var(--theme-fontWeights-400) var(--theme-fontSize-h1) / var(--theme-lineHeights-xxl)
    var(--theme-fontFamilies-brandFont);
  --theme-typography-h2-semiBold: var(--theme-fontWeights-600) var(--theme-fontSize-h2) / var(--theme-lineHeights-xxl)
    var(--theme-fontFamilies-brandFont);
  --theme-typography-h2-regular: var(--theme-fontWeights-400) var(--theme-fontSize-h2) / var(--theme-lineHeights-xxl)
    var(--theme-fontFamilies-brandFont);
  --theme-typography-h3-regular: var(--theme-fontWeights-400) var(--theme-fontSize-h3) / var(--theme-lineHeights-xl)
    var(--theme-fontFamilies-brandFont);
  --theme-typography-h4-semiBold: var(--theme-fontWeights-600) var(--theme-fontSize-h4) / var(--theme-lineHeights-xl)
    var(--theme-fontFamilies-brandFont);
  --theme-typography-h4-regular: var(--theme-fontWeights-400) var(--theme-fontSize-h4) / var(--theme-lineHeights-xl)
    var(--theme-fontFamilies-brandFont);
  --theme-typography-h5-semiBold: var(--theme-fontWeights-600) var(--theme-fontSize-h5) / var(--theme-lineHeights-l)
    var(--theme-fontFamilies-brandFont);
  --theme-typography-h5-regular: var(--theme-fontWeights-400) var(--theme-fontSize-h5) / var(--theme-lineHeights-l)
    var(--theme-fontFamilies-brandFont);
  --theme-typography-bodyL-semiBold: var(--theme-fontWeights-600) var(--theme-fontSize-bodyL) /
    var(--theme-lineHeights-m) var(--theme-fontFamilies-brandFont);
  --theme-typography-bodyL-regular: var(--theme-fontWeights-400) var(--theme-fontSize-bodyL) /
    var(--theme-lineHeights-m) var(--theme-fontFamilies-brandFont);
  --theme-typography-bodyL-buttonL: var(--theme-fontWeights-700) var(--theme-fontSize-bodyL) /
    var(--theme-lineHeights-m) var(--theme-fontFamilies-brandFont);
  --theme-typography-bodyL-linkButtonL: var(--theme-fontWeights-700) var(--theme-fontSize-bodyL) /
    var(--theme-lineHeights-m) var(--theme-fontFamilies-brandFont);
  --theme-typography-bodyM-semiBold: var(--theme-fontWeights-600) var(--theme-fontSize-bodyM) /
    var(--theme-lineHeights-m) var(--theme-fontFamilies-brandFont);
  --theme-typography-bodyM-regular: var(--theme-fontWeights-400) var(--theme-fontSize-bodyM) /
    var(--theme-lineHeights-m) var(--theme-fontFamilies-brandFont);
  --theme-typography-bodyM-regularItalic: var(--theme-fontWeights-italic) var(--theme-fontSize-bodyM) /
    var(--theme-lineHeights-m) var(--theme-fontFamilies-brandFont);
  --theme-typography-bodyM-underline: var(--theme-fontWeights-400) var(--theme-fontSize-bodyM) /
    var(--theme-lineHeights-m) var(--theme-fontFamilies-brandFont);
  --theme-typography-bodyM-button: var(--theme-fontWeights-700) var(--theme-fontSize-bodyM) / var(--theme-lineHeights-s)
    var(--theme-fontFamilies-brandFont);
  --theme-typography-bodyM-linkButton: var(--theme-fontWeights-700) var(--theme-fontSize-bodyM) /
    var(--theme-lineHeights-s) var(--theme-fontFamilies-brandFont);
  --theme-typography-bodySm-activeMenu: var(--theme-fontWeights-600) var(--theme-fontSize-bodySm) /
    var(--theme-lineHeights-s) var(--theme-fontFamilies-brandFont);
  --theme-typography-bodySm-menuItem: var(--theme-fontWeights-400) var(--theme-fontSize-bodySm) /
    var(--theme-lineHeights-s) var(--theme-fontFamilies-brandFont);
  --theme-typography-bodySm-note: var(--theme-fontWeights-italic) var(--theme-fontSize-bodySm) /
    var(--theme-lineHeights-s) var(--theme-fontFamilies-brandFont);
  --theme-typography-bodyS-semiBold: var(--theme-fontWeights-600) var(--theme-fontSize-bodyS) /
    var(--theme-lineHeights-s) var(--theme-fontFamilies-brandFont);
  --theme-typography-bodyS-regular: var(--theme-fontWeights-400) var(--theme-fontSize-bodyS) /
    var(--theme-lineHeights-s) var(--theme-fontFamilies-brandFont);
  --theme-typography-bodyS-tableButton: var(--theme-fontWeights-600) var(--theme-fontSize-bodyS) /
    var(--theme-lineHeights-s) var(--theme-fontFamilies-brandFont);
  --theme-typography-special-tip: var(--theme-fontWeights-400) var(--theme-fontSize-special) /
    var(--theme-lineHeights-s) var(--theme-fontFamilies-brandFont);
  --theme-textCase-uppercase: uppercase;
  --theme-textCase-none: none;
  --theme-textCase-capitalize: capitalize;
  --theme-textDecoration-underline: underline;
  --theme-textDecoration-none: none;
  --theme-size-grid: 4px;
  --theme-size-buttonsHeight-small: calc(var(--theme-size-grid) * 8);
  --theme-size-buttonsHeight-middle: calc(var(--theme-size-grid) * 11);
  --theme-size-buttonsHeight-large: calc(var(--theme-size-grid) * 12);
  --theme-size-buttonsHeight-table: calc(var(--theme-size-grid) * 6);
  --theme-borderRadius-roundedNone: 0px;
  --theme-borderRadius-default: var(--theme-borderRadius-roundedNone);
  --theme-borderRadius-roundedFull: 100px;
  --theme-borderRadius-roundedModal: var(--theme-borderRadius-default);
  --theme-opacity-opacity600: 60%;
  --theme-opacity-visible: 100%;
  --theme-opacity-unvisible: 0%;
  --theme-borderWidth-strokeM: calc(var(--theme-size-grid) * 0.5);
  --theme-borderWidth-strokeS: calc(var(--theme-size-grid) * 0.25);
  --theme-borderWidth-strokeNone: 0px;
  --theme-space-padding-headerButton-large: calc(var(--theme-size-grid) * 12);
  --theme-space-padding-headerButton-middle: calc(var(--theme-size-grid) * 9);
  --theme-space-padding-button-large: calc(var(--theme-size-grid) * 10);
  --theme-space-padding-button-middle: calc(var(--theme-size-grid) * 8);
  --theme-space-padding-button-sm: calc(var(--theme-size-grid) * 6.5);
  --theme-space-padding-button-small: calc(var(--theme-size-grid) * 6);
  --theme-space-padding-button-xs: calc(var(--theme-size-grid) * 4);
  --theme-space-padding-tableButton-table: var(--theme-size-buttonsHeight-table) / 2;
  --theme-space-spacing-default: var(--theme-size-grid);
  --theme-space-spacing-outlineButton: calc(var(--theme-size-grid) * 1.5);

  .theme-tags-typography-h1-regular {
    font: var(--theme-fontWeights-400) var(--theme-fontSize-h1) / var(--theme-lineHeights-xxl)
      var(--theme-fontFamilies-brandFont);
    letter-spacing: var(--theme-letterSpacing-l);
    margin: var(--theme-paragraphSpacing-none);
    text-transform: var(--theme-textCase-none);
    text-decoration: var(--theme-textDecoration-none);
  }
  .theme-tags-typography-h2-semiBold {
    font: var(--theme-fontWeights-600) var(--theme-fontSize-h2) / var(--theme-lineHeights-xxl)
      var(--theme-fontFamilies-brandFont);
    letter-spacing: var(--theme-letterSpacing-l);
    margin: var(--theme-paragraphSpacing-none);
    text-transform: var(--theme-textCase-none);
    text-decoration: var(--theme-textDecoration-none);
  }
  .theme-tags-typography-h2-regular {
    font: var(--theme-fontWeights-400) var(--theme-fontSize-h2) / var(--theme-lineHeights-xxl)
      var(--theme-fontFamilies-brandFont);
    letter-spacing: var(--theme-letterSpacing-l);
    margin: var(--theme-paragraphSpacing-none);
    text-transform: var(--theme-textCase-none);
    text-decoration: var(--theme-textDecoration-none);
  }
  .theme-tags-typography-h3-regular {
    font: var(--theme-fontWeights-400) var(--theme-fontSize-h3) / var(--theme-lineHeights-xl)
      var(--theme-fontFamilies-brandFont);
    letter-spacing: var(--theme-letterSpacing-m);
    margin: var(--theme-paragraphSpacing-none);
    text-transform: var(--theme-textCase-none);
    text-decoration: var(--theme-textDecoration-none);
  }
  .theme-tags-typography-h4-semiBold {
    font: var(--theme-fontWeights-600) var(--theme-fontSize-h4) / var(--theme-lineHeights-xl)
      var(--theme-fontFamilies-brandFont);
    letter-spacing: var(--theme-letterSpacing-s);
    margin: var(--theme-paragraphSpacing-none);
    text-transform: var(--theme-textCase-none);
    text-decoration: var(--theme-textDecoration-none);
  }
  .theme-tags-typography-h4-regular {
    font: var(--theme-fontWeights-400) var(--theme-fontSize-h4) / var(--theme-lineHeights-xl)
      var(--theme-fontFamilies-brandFont);
    letter-spacing: var(--theme-letterSpacing-s);
    margin: var(--theme-paragraphSpacing-none);
    text-transform: var(--theme-textCase-none);
    text-decoration: var(--theme-textDecoration-none);
  }
  .theme-tags-typography-h5-semiBold {
    font: var(--theme-fontWeights-600) var(--theme-fontSize-h5) / var(--theme-lineHeights-l)
      var(--theme-fontFamilies-brandFont);
    letter-spacing: var(--theme-letterSpacing-s);
    margin: var(--theme-paragraphSpacing-none);
    text-transform: var(--theme-textCase-none);
    text-decoration: var(--theme-textDecoration-none);
  }
  .theme-tags-typography-h5-regular {
    font: var(--theme-fontWeights-400) var(--theme-fontSize-h5) / var(--theme-lineHeights-l)
      var(--theme-fontFamilies-brandFont);
    letter-spacing: var(--theme-letterSpacing-s);
    margin: var(--theme-paragraphSpacing-none);
    text-transform: var(--theme-textCase-none);
    text-decoration: var(--theme-textDecoration-none);
  }
  .theme-tags-typography-bodyL-semiBold {
    font: var(--theme-fontWeights-600) var(--theme-fontSize-bodyL) / var(--theme-lineHeights-m)
      var(--theme-fontFamilies-brandFont);
    letter-spacing: var(--theme-letterSpacing-s);
    margin: var(--theme-paragraphSpacing-none);
    text-transform: var(--theme-textCase-none);
    text-decoration: var(--theme-textDecoration-none);
  }
  .theme-tags-typography-bodyL-regular {
    font: var(--theme-fontWeights-400) var(--theme-fontSize-bodyL) / var(--theme-lineHeights-m)
      var(--theme-fontFamilies-brandFont);
    letter-spacing: var(--theme-letterSpacing-s);
    margin: var(--theme-paragraphSpacing-none);
    text-transform: var(--theme-textCase-none);
    text-decoration: var(--theme-textDecoration-none);
  }
  .theme-tags-typography-bodyL-buttonL {
    font: var(--theme-fontWeights-700) var(--theme-fontSize-bodyL) / var(--theme-lineHeights-m)
      var(--theme-fontFamilies-brandFont);
    letter-spacing: var(--theme-letterSpacing-s);
    margin: var(--theme-paragraphSpacing-none);
    text-transform: var(--theme-textCase-capitalize);
    text-decoration: var(--theme-textDecoration-none);
  }
  .theme-tags-typography-bodyL-linkButtonL {
    font: var(--theme-fontWeights-700) var(--theme-fontSize-bodyL) / var(--theme-lineHeights-m)
      var(--theme-fontFamilies-brandFont);
    letter-spacing: var(--theme-letterSpacing-s);
    margin: var(--theme-paragraphSpacing-none);
    text-transform: var(--theme-textCase-capitalize);
    text-decoration: var(--theme-textDecoration-underline);
  }
  .theme-tags-typography-bodyM-semiBold {
    font: var(--theme-fontWeights-600) var(--theme-fontSize-bodyM) / var(--theme-lineHeights-m)
      var(--theme-fontFamilies-brandFont);
    letter-spacing: var(--theme-letterSpacing-none);
    margin: var(--theme-paragraphSpacing-none);
    text-transform: var(--theme-textCase-none);
    text-decoration: var(--theme-textDecoration-none);
  }
  .theme-tags-typography-bodyM-regular {
    font: var(--theme-fontWeights-400) var(--theme-fontSize-bodyM) / var(--theme-lineHeights-m)
      var(--theme-fontFamilies-brandFont);
    letter-spacing: var(--theme-letterSpacing-none);
    margin: var(--theme-paragraphSpacing-none);
    text-transform: var(--theme-textCase-none);
    text-decoration: var(--theme-textDecoration-none);
  }
  .theme-tags-typography-bodyM-regularItalic {
    font: var(--theme-fontWeights-italic) var(--theme-fontSize-bodyM) / var(--theme-lineHeights-m)
      var(--theme-fontFamilies-brandFont);
    letter-spacing: var(--theme-letterSpacing-none);
    margin: var(--theme-paragraphSpacing-none);
    text-transform: var(--theme-textCase-none);
    text-decoration: var(--theme-textDecoration-none);
  }
  .theme-tags-typography-bodyM-underline {
    font: var(--theme-fontWeights-400) var(--theme-fontSize-bodyM) / var(--theme-lineHeights-m)
      var(--theme-fontFamilies-brandFont);
    letter-spacing: var(--theme-letterSpacing-none);
    margin: var(--theme-paragraphSpacing-none);
    text-transform: var(--theme-textCase-none);
    text-decoration: var(--theme-textDecoration-underline);
  }
  .theme-tags-typography-bodyM-button {
    font: var(--theme-fontWeights-700) var(--theme-fontSize-bodyM) / var(--theme-lineHeights-s)
      var(--theme-fontFamilies-brandFont);
    letter-spacing: var(--theme-letterSpacing-s);
    margin: var(--theme-paragraphSpacing-none);
    text-transform: var(--theme-textCase-capitalize);
    text-decoration: var(--theme-textDecoration-none);
  }
  .theme-tags-typography-bodyM-linkButton {
    font: var(--theme-fontWeights-700) var(--theme-fontSize-bodyM) / var(--theme-lineHeights-s)
      var(--theme-fontFamilies-brandFont);
    letter-spacing: var(--theme-letterSpacing-s);
    margin: var(--theme-paragraphSpacing-none);
    text-transform: var(--theme-textCase-capitalize);
    text-decoration: var(--theme-textDecoration-underline);
  }
  .theme-tags-typography-bodySm-activeMenu {
    font: var(--theme-fontWeights-600) var(--theme-fontSize-bodySm) / var(--theme-lineHeights-s)
      var(--theme-fontFamilies-brandFont);
    letter-spacing: var(--theme-letterSpacing-s);
    margin: var(--theme-paragraphSpacing-none);
    text-transform: var(--theme-textCase-none);
    text-decoration: var(--theme-textDecoration-none);
  }
  .theme-tags-typography-bodySm-menuItem {
    font: var(--theme-fontWeights-400) var(--theme-fontSize-bodySm) / var(--theme-lineHeights-s)
      var(--theme-fontFamilies-brandFont);
    letter-spacing: var(--theme-letterSpacing-s);
    margin: var(--theme-paragraphSpacing-none);
    text-transform: var(--theme-textCase-none);
    text-decoration: var(--theme-textDecoration-none);
  }
  .theme-tags-typography-bodySm-note {
    font: var(--theme-fontWeights-italic) var(--theme-fontSize-bodySm) / var(--theme-lineHeights-s)
      var(--theme-fontFamilies-brandFont);
    letter-spacing: var(--theme-letterSpacing-s);
    margin: var(--theme-paragraphSpacing-none);
    text-transform: var(--theme-textCase-none);
    text-decoration: var(--theme-textDecoration-none);
  }
  .theme-tags-typography-bodyS-semiBold {
    font: var(--theme-fontWeights-600) var(--theme-fontSize-bodyS) / var(--theme-lineHeights-s)
      var(--theme-fontFamilies-brandFont);
    letter-spacing: var(--theme-letterSpacing-s);
    margin: var(--theme-paragraphSpacing-none);
    text-transform: var(--theme-textCase-none);
    text-decoration: var(--theme-textDecoration-none);
  }
  .theme-tags-typography-bodyS-regular {
    font: var(--theme-fontWeights-400) var(--theme-fontSize-bodyS) / var(--theme-lineHeights-s)
      var(--theme-fontFamilies-brandFont);
    letter-spacing: var(--theme-letterSpacing-s);
    margin: var(--theme-paragraphSpacing-none);
    text-transform: var(--theme-textCase-none);
    text-decoration: var(--theme-textDecoration-none);
  }
  .theme-tags-typography-bodyS-tableButton {
    font: var(--theme-fontWeights-600) var(--theme-fontSize-bodyS) / var(--theme-lineHeights-s)
      var(--theme-fontFamilies-brandFont);
    letter-spacing: var(--theme-letterSpacing-s);
    margin: var(--theme-paragraphSpacing-none);
    text-transform: var(--theme-textCase-uppercase);
    text-decoration: var(--theme-textDecoration-none);
  }
  .theme-tags-typography-special-tip {
    font: var(--theme-fontWeights-400) var(--theme-fontSize-special) / var(--theme-lineHeights-s)
      var(--theme-fontFamilies-brandFont);
    letter-spacing: var(--theme-letterSpacing-s);
    margin: var(--theme-paragraphSpacing-none);
    text-transform: var(--theme-textCase-none);
    text-decoration: var(--theme-textDecoration-none);
  }
  .theme-tags-typography-bodyM-button {
    font: var(--theme-fontWeights-700) var(--theme-fontSize-bodyM) / var(--theme-lineHeights-s)
      var(--theme-fontFamilies-brandFont);
    letter-spacing: var(--theme-letterSpacing-s);
    margin: var(--theme-paragraphSpacing-none);
    text-transform: var(--theme-textCase-capitalize);
    text-decoration: var(--theme-textDecoration-none);
  }
  .theme-tags-typography-bodyL-buttonL {
    font: var(--theme-fontWeights-700) var(--theme-fontSize-bodyL) / var(--theme-lineHeights-m)
      var(--theme-fontFamilies-brandFont);
    letter-spacing: var(--theme-letterSpacing-s);
    margin: var(--theme-paragraphSpacing-none);
    text-transform: var(--theme-textCase-capitalize);
    text-decoration: var(--theme-textDecoration-none);
  }
  .theme-tags-typography-bodyM-button {
    font: var(--theme-fontWeights-700) var(--theme-fontSize-bodyM) / var(--theme-lineHeights-s)
      var(--theme-fontFamilies-brandFont);
    letter-spacing: var(--theme-letterSpacing-s);
    margin: var(--theme-paragraphSpacing-none);
    text-transform: var(--theme-textCase-uppercase);
    text-decoration: var(--theme-textDecoration-none);
  }
  .theme-tags-typography-bodyL-buttonL {
    font: var(--theme-fontWeights-700) var(--theme-fontSize-bodyL) / var(--theme-lineHeights-m)
      var(--theme-fontFamilies-brandFont);
    letter-spacing: var(--theme-letterSpacing-s);
    margin: var(--theme-paragraphSpacing-none);
    text-transform: var(--theme-textCase-uppercase);
    text-decoration: var(--theme-textDecoration-none);
  }
  .theme-tags-typography-bodyM-button {
    font: var(--theme-fontWeights-700) var(--theme-fontSize-bodyM) / var(--theme-lineHeights-s)
      var(--theme-fontFamilies-brandFont);
    letter-spacing: var(--theme-letterSpacing-s);
    margin: var(--theme-paragraphSpacing-none);
    text-transform: var(--theme-textCase-capitalize);
    text-decoration: var(--theme-textDecoration-none);
  }
  .theme-tags-typography-bodyL-buttonL {
    font: var(--theme-fontWeights-700) var(--theme-fontSize-bodyL) / var(--theme-lineHeights-m)
      var(--theme-fontFamilies-brandFont);
    letter-spacing: var(--theme-letterSpacing-s);
    margin: var(--theme-paragraphSpacing-none);
    text-transform: var(--theme-textCase-capitalize);
    text-decoration: var(--theme-textDecoration-none);
  }
  .theme-tags-typography-bodyM-button {
    font: var(--theme-fontWeights-700) var(--theme-fontSize-bodyM) / var(--theme-lineHeights-s)
      var(--theme-fontFamilies-brandFont);
    letter-spacing: var(--theme-letterSpacing-s);
    margin: var(--theme-paragraphSpacing-none);
    text-transform: var(--theme-textCase-uppercase);
    text-decoration: var(--theme-textDecoration-none);
  }
  .theme-tags-typography-bodyL-buttonL {
    font: var(--theme-fontWeights-700) var(--theme-fontSize-bodyL) / var(--theme-lineHeights-m)
      var(--theme-fontFamilies-brandFont);
    letter-spacing: var(--theme-letterSpacing-s);
    margin: var(--theme-paragraphSpacing-none);
    text-transform: var(--theme-textCase-uppercase);
    text-decoration: var(--theme-textDecoration-none);
  }
  .theme-tags-typography-bodyM-button {
    font: var(--theme-fontWeights-700) var(--theme-fontSize-bodyM) / var(--theme-lineHeights-s)
      var(--theme-fontFamilies-brandFont);
    letter-spacing: var(--theme-letterSpacing-s);
    margin: var(--theme-paragraphSpacing-none);
    text-transform: var(--theme-textCase-capitalize);
    text-decoration: var(--theme-textDecoration-none);
  }
  .theme-tags-typography-bodyL-buttonL {
    font: var(--theme-fontWeights-700) var(--theme-fontSize-bodyL) / var(--theme-lineHeights-m)
      var(--theme-fontFamilies-brandFont);
    letter-spacing: var(--theme-letterSpacing-s);
    margin: var(--theme-paragraphSpacing-none);
    text-transform: var(--theme-textCase-capitalize);
    text-decoration: var(--theme-textDecoration-none);
  }
}
