import React from 'react'

import { usAndTerritories } from 'commons/types/dictionaries'
import { FF, Select } from 'ui/components'

import { useSARFormContext } from '../../../../../../../../context'
import { useSubjectInformationItemContext } from '../../../SubjectInformationItemContext'

const EMPTY_OPTION = { label: 'Not selected', value: '' }

type IdCountryProps = {
  index: number
}

export const IdCountry = ({ index }: IdCountryProps) => {
  const { formApi } = useSARFormContext()
  const { isAllInfoUnavailable } = useSubjectInformationItemContext()

  return (
    <formApi.Field
      name={`subjects_info[${index}].id_info[0].id_country` as 'subjects_info[0].id_info[0].id_country'}
      children={(field) => (
        <FF
          field={field}
          label="Country"
          input={(p) => (
            <Select {...p} data={usAndTerritories} emptyOption={EMPTY_OPTION} disabled={isAllInfoUnavailable} />
          )}
        />
      )}
    />
  )
}
