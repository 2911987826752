import React, { useEffect } from 'react'

import { FieldApi } from '@tanstack/react-form'
import { BsaSar } from 'commons/types/DTO/bank/bsa'
import { CheckBox, FF } from 'ui/components'

import { useSARFormContext } from '../../../../../../../context'

type ZRendererProps = {
  field: FieldApi<BsaSar, 'transactions_info.product_types.z'>
}

const ZRenderer = ({ field }: ZRendererProps) => {
  const { formApi } = useSARFormContext()

  useEffect(() => {
    if (!field.state.value) {
      formApi.setFieldValue('transactions_info.product_types_other', '', { dontUpdateMeta: true })
    }
  }, [field.state.value, formApi])

  return <FF field={field} input={(p) => <CheckBox {...p} checkBoxLabel="z. Other" />} errorPosition="right" />
}

export const Z = () => {
  const { formApi } = useSARFormContext()
  return <formApi.Field name="transactions_info.product_types.z" children={(field) => <ZRenderer field={field} />} />
}
