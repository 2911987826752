import { useCallback, useMemo, useState } from 'react'

import { FilterCompanyAdverseMediaWebPage } from 'commons/service/bank/companyDetails'
import { useTableState } from 'ui/components/QueryTable'

export const useComponentsEssence = (
  companyId: number,
  onFilterCompanyAdverseMediawWebPage: (filter: FilterCompanyAdverseMediaWebPage) => void
) => {
  const [loading, setLoading] = useState(false)

  const defaultFilter = useMemo(
    () => ({
      relationshipID: companyId,
      adverseMediaOffset: undefined,
      advsereMediaLimit: undefined
    }),
    [companyId]
  )

  const [tableFilter, setTableFilter] = useState<FilterCompanyAdverseMediaWebPage>(defaultFilter)

  const tableState = useTableState({ filter: {} })

  const changePagination = useCallback(
    async (page: number, size: number) => {
      setLoading(true)

      tableState.onPageChange(page, size)

      const filter = {
        ...tableFilter,
        adverseMediaOffset: (page - 1) * size,
        adverseMediaLimit: size
      }

      setTableFilter(filter)

      await onFilterCompanyAdverseMediawWebPage(filter)

      setLoading(false)
    },
    [onFilterCompanyAdverseMediawWebPage, tableFilter, tableState]
  )

  return {
    loading,
    tableState,
    changePagination
  }
}
