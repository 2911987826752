import React from 'react'

import { FF, TextInput } from 'ui/components'

import { useSARFormContext } from '../../../../../../../../context'

export const Time = ({ index }: { index: number }) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={`transactions_info.ip_addresses[${index}].time` as 'transactions_info.ip_addresses[0].time'}
      children={(field) => <FF label="Timestamp (UTC)" field={field} input={(p) => <TextInput {...p} />} />}
    />
  )
}
