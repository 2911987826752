import { MutableRefObject, useLayoutEffect } from 'react'

import { MiddlewareData } from '@floating-ui/core/src/types'
import { ReferenceType } from '@floating-ui/react-dom-interactions'

export const useListItems = (
  open: boolean,
  prevActiveIndex: number | null,
  controlledScrolling: boolean,
  activeIndex: number | null,
  selectedIndex: number | null,
  floatingRef: MutableRefObject<HTMLElement | null>,
  referenceRef: MutableRefObject<ReferenceType | null>,
  listRef: MutableRefObject<HTMLElement[]>,
  middlewareData: MiddlewareData
) => {
  // Scroll the active or selected item into view when in `controlledScrolling`
  // mode (i.e. arrow key nav).
  useLayoutEffect(() => {
    const floating = floatingRef.current

    if (open && controlledScrolling && floating) {
      const item =
        activeIndex != null
          ? listRef.current[activeIndex]
          : selectedIndex != null
          ? listRef.current[selectedIndex]
          : null

      if (item && prevActiveIndex != null) {
        const itemHeight = listRef.current[prevActiveIndex]?.offsetHeight ?? 0

        const floatingHeight = floating.offsetHeight
        const top = item.offsetTop
        const bottom = top + itemHeight

        if (top < floating.scrollTop) {
          floating.scrollTop -= floating.scrollTop - top
        } else if (bottom > floatingHeight + floating.scrollTop) {
          floating.scrollTop += bottom - floatingHeight - floating.scrollTop
        }
      }
    }
  }, [open, prevActiveIndex, controlledScrolling, activeIndex, selectedIndex, floatingRef])

  // Sync the height and the scrollTop values
  useLayoutEffect(() => {
    const floating = floatingRef.current
    if (open && floating && floating.offsetHeight < floating.scrollHeight) {
      const item = listRef.current[selectedIndex]
      if (item) {
        floating.scrollTop = item.offsetTop - floating.offsetHeight / 2 + item.offsetHeight / 2
      }
    }
  }, [
    open,
    selectedIndex,
    floatingRef,
    referenceRef,
    // Always re-run this effect when the position has been computed so the
    // .scrollTop change works with fresh sizing.
    middlewareData
  ])
}
