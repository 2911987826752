import React from 'react'

import { CheckBox, FF } from 'ui/components'

import { useSARFormContext } from '../../../../../../../context'

export const A = () => {
  const { formApi } = useSARFormContext()
  return (
    <formApi.Field
      name="transactions_info.structuring.a"
      children={(field) => (
        <FF
          field={field}
          label="32. Structuring"
          input={(p) => (
            <CheckBox
              {...p}
              checkBoxLabel="a. Alters or cancels transaction to avoid BSA record keeping requirement"
              data-qa="a. Alters or cancels transaction to avoid BSA record keeping requirement"
            />
          )}
          errorPosition="right"
        />
      )}
    />
  )
}
