import React, { KeyboardEventHandler, ReactNode, memo, useCallback, useState } from 'react'

import { FormInputProps } from 'brief-form'
import isDate from 'date-fns/isDate'

import { ButtonFace, ButtonSize } from '../Button'
import { getRawDate } from '../DateTime'
import { Popper } from '../Popper'
import { DatePickerInput } from './DatePickerInput'

import { CheckBoxWrapper, DateControlWrapper, OKButton, Outer } from './styles'

const DateControl = require('react-datepicker-styled-components').default

type Opts = {
  displayFormat?: string
  showTimeSelect?: boolean
  containerSelectors?: string[]
  disableCheckBoxText?: ReactNode
  'data-qa'?: string
  isClearable?: boolean
}

export interface DatePickerProps<T> extends FormInputProps<T, Opts> {}

export const DatePicker = memo(<T,>(props: DatePickerProps<T>) => {
  const {
    required,
    value,
    error,
    onChange,
    'data-qa': dataQA = 'data-picker-input',
    displayFormat = 'MM/dd/yyyy',
    showTimeSelect = false,
    disableCheckBoxText,
    isClearable = true,
    ...rest
  } = props

  /* use open state for virtual input focus drawing (when open, just draw border, coz real focus imposible here, 
    focus will be on calendar) */
  const [opened, setOpened] = useState(false)
  const [checked, setChecked] = useState(!!(disableCheckBoxText && value === ''))
  const newValue = checked ? '' : value === '' ? null : value
  const dateAsString = isDate(newValue) ? (newValue as Date).toISOString() : newValue?.toString()
  const [showDatepicker, setShowDatepicker] = useState(false)

  if (dateAsString && !/^(\d{4})-(\d{2})-(\d{2})/.test(dateAsString)) {
    throw new Error('Invalid date string format')
  }

  let date: Date | null

  if (showTimeSelect) {
    date = dateAsString ? new Date(dateAsString) : null
  } else {
    const split = dateAsString?.substr(0, 10)?.split('-')
    date = dateAsString ? new Date(+split[0], +split[1] - 1, +split[2]) : null
  }

  const onChangeWrapper = useCallback(
    (v, e) => {
      if (onChange) {
        onChange(v, e)
      }
      setShowDatepicker(false)
    },
    [onChange]
  )

  const clearDate = useCallback(
    (e) => {
      e.preventDefault()
      e.stopPropagation()
      onChangeWrapper(null, required && !newValue ? 'Required' : undefined)
    },
    [onChangeWrapper, required, newValue]
  )

  const onDateChange = useCallback(
    (date: string | Date, close?: () => void) => {
      if (typeof date === 'string' && date.length !== 10) {
        if (date.length === 0) {
          onChangeWrapper(null, required && !newValue ? 'Required' : undefined)
        }
        return
      }
      if (typeof date === 'string') {
        date = new Date(date)
      }
      if (date instanceof Date) {
        const value = showTimeSelect ? date.toISOString() : getRawDate(date)
        onChangeWrapper(value, undefined)
        if (!showTimeSelect && close) {
          close()
        }
      }
    },
    [onChangeWrapper, showTimeSelect, newValue, required]
  )

  const handleKeyDown: KeyboardEventHandler = useCallback(
    (e) => {
      if (e.code == 'tab') {
        e.preventDefault()
        e.stopPropagation()
        setShowDatepicker(false)
      }
    },
    [setShowDatepicker]
  )

  const onCheckedChange = useCallback(
    (value) => {
      setChecked(value)
      onChangeWrapper(value ? '' : null, error)
    },
    [onChangeWrapper, error]
  )

  const handleOpen = useCallback(() => {
    setOpened(true)
  }, [setOpened])

  const handleClose = useCallback(() => {
    setOpened(false)
  }, [setOpened])

  return (
    <Outer>
      <Popper
        onOpen={handleOpen}
        onClose={handleClose}
        opened={showDatepicker}
        scrollClose={true}
        autoSize={true}
        placement="bottom-start"
        style={{
          overflow: 'auto',
          boxShadow: '0 7px 21px 0 rgba(0, 0, 0, 0.11)',
          marginTop: '8px'
        }}
        render={({ close }) => (
          <DateControlWrapper $withTime={showTimeSelect}>
            <div className="react-datepicker-popper">
              <DateControl
                {...rest}
                showMonthDropdown
                showYearDropdown
                scrollableYearDropdown
                showTimeSelect={showTimeSelect}
                timeIntervals={5}
                dateFormatCalendar="MMMM"
                yearDropdownItemNumber={100}
                dropdownMode="select"
                dateFormat={showTimeSelect ? 'Pp' : displayFormat}
                onChange={(date: string | Date) => {
                  onDateChange(date, close)
                }}
                selected={date}
                shouldCloseOnSelect={!showTimeSelect}
                disabled={checked}
                inline
              >
                {showTimeSelect && (
                  <OKButton size={ButtonSize.SMALL} face={ButtonFace.PRIMARY} onClick={close}>
                    OK
                  </OKButton>
                )}
              </DateControl>
              {!!disableCheckBoxText && (
                <CheckBoxWrapper value={checked} onChange={onCheckedChange} checkBoxLabel={disableCheckBoxText} />
              )}
            </div>
          </DateControlWrapper>
        )}
      >
        <DateControlWrapper $withTime={showTimeSelect} $error={!!error} $required={required} $opened={opened}>
          <DatePickerInput
            {...rest}
            isClearable={isClearable}
            showTimeSelect={showTimeSelect}
            onChange={onDateChange as any}
            value={dateAsString}
            clearDate={clearDate}
            needValue={required}
            hasDate={!!date}
            onKeyDown={handleKeyDown}
            data-qa={dataQA}
          />
        </DateControlWrapper>
      </Popper>
    </Outer>
  )
})
