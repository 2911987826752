import styled from 'styled-components'
import SC from 'styled-components'

export const NameRow = styled.span`
  display: flex;
  align-items: center;
`

export const IconButton = SC.button`
  margin-right: 10px;
  padding: 2px;
  border: none;
  background: none;
  height: 20px;
  &:focus {
    outline: 4px solid ${(p) => p.theme.uiKit.colors.global.grey.grey700};
  }
`
