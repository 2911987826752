import React, { FC, memo } from 'react'

import { Button } from 'ui/components/Buttons'

import { FooterProps } from './FooterProps'

import * as SC from './styles'

export const Footer: FC<FooterProps> = memo(({ onReview, sticky = false }) => {
  if (!onReview) {
    return null
  }

  return (
    <SC.Box $sticky={sticky}>
      <Button onClick={onReview} face="positive">
        Review
      </Button>
    </SC.Box>
  )
})
