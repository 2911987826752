import styled from 'styled-components'

export const Title = styled.span<{ weight: number }>`
  display: flex;
  align-items: center;

  > span {
    display: inline-block;
    margin-left: 10px;
    color: ${(p) => p.theme.uiKit.colors.theme.negative.main};
    font-weight: ${(p) => p.weight};
  }
`

export const Content = styled.ol`
  padding: 0 50px;
  line-height: 2;
  color: ${(p) => p.theme.uiKit.colors.theme.negative.main};
`

export const MaskWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
