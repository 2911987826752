import React, { FC, memo, useState } from 'react'

import { TableDataQa } from 'commons/types/enums'
import { Layer } from 'ui/components'
import { FilterPanel, Table } from 'ui/components/Table'

import { DocumentListTableFilter } from '../DocumentListTableFilter'
import { DocumentListFilters, DocumentListTableConfig, TableMappedDocument } from './types'
import { useDocumentListTable } from './useDocumentListTable'
import { TableState } from './useTableState/types'

const defaultFilterFlags: DocumentListTableConfig = {
  isCompanyFilterHidden: false,
  isSubjectTypeFilterHidden: false,
  isContactFilterHidden: false,
  isLicenseFilterHidden: true,
  isBankAccountFilterHidden: false,
  hideSubjectColumn: false,
  hideRelatedCompanyColumn: false
}

type DocumentListTableProps = {
  tableId: string // need for sm key calculation
  dataQa: TableDataQa
  initFilters?: DocumentListFilters
  externalTableState?: Partial<TableState>
  returnUrl: string
  config?: DocumentListTableConfig
  onFilterChange?: (value: DocumentListFilters) => void
}

export const DocumentListTable: FC<DocumentListTableProps> = memo((props) => {
  const { tableId, dataQa, returnUrl, initFilters, externalTableState, config } = props
  const tableConfig = { ...defaultFilterFlags, ...(config || {}) }

  const [isDirty, setIsDirty] = useState<boolean>(false)

  const tableState = useDocumentListTable({
    tableId,
    returnUrl,
    initFilters,
    externalTableState,
    tableConfig,
    onExternalFilterChange: props.onFilterChange,
    setIsDirty
  })

  const {
    currentFilterValue,
    DeleteConfirmation,
    filterSources,
    onFilterChange,
    isFetching,
    tableItems,
    documentColumns
  } = tableState

  const isFilterAvailable = !!filterSources && !!currentFilterValue

  return (
    <Layer rounded shadowed stretch>
      <DeleteConfirmation />

      <FilterPanel
        isDirty={isDirty}
        onReset={tableState.onFilterClearClick}
        expanded={tableState.filterExpanded}
        onToggle={() => tableState.onFilterExpanded(!tableState.filterExpanded)}
      >
        {isFilterAvailable && (
          <DocumentListTableFilter
            currentFilterValue={currentFilterValue}
            filterSources={filterSources}
            filterFlags={tableConfig}
            clearFlag={tableState.shouldClearFilter}
            onFilterChange={onFilterChange}
            onSetDirty={setIsDirty}
          />
        )}
      </FilterPanel>
      <Table<TableMappedDocument>
        dataQa={dataQa || TableDataQa.BP_DOCUMENTS_LIST_V2}
        loading={isFetching}
        dataSource={tableItems}
        columns={documentColumns}
        pagination={tableState.pagination}
        sorting={tableState.sorting}
      />
    </Layer>
  )
})
