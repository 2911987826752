import styled from 'styled-components'

export const Inner = styled.span.attrs({ className: 'theme-tags-typography-bodyS-tableButton' })`
  color: inherit;
  display: block;
`

export const Box = styled.button`
  cursor: pointer;
  outline: none;
  min-height: var(--theme-size-buttonsHeight-table);

  padding-left: calc(var(--theme-space-padding-tableButton-table) - var(--theme-borderWidth-strokeM));
  padding-right: calc(var(--theme-space-padding-tableButton-table) - var(--theme-borderWidth-strokeM));

  transition: background 0.15s, color 0.15s, border 0.15s, box-shadow 0.15s;

  border-style: solid;
`

export const Brand = styled(Box)`
  border-style: solid;
  border-width: var(--theme-borderWidth-strokeM);
  border-color: var(--theme-colors-global-general-transparent);
  border-radius: var(--theme-borderRadius-default);
  background-color: var(--theme-colors-global-general-transparent);

  color: var(--theme-colors-theme-brand-primary);

  &:hover {
    background-color: var(--theme-colors-theme-brand-transparent-opacity12);
  }

  &:active {
    background-color: var(--theme-colors-theme-brand-transparent-opacity60);
    color: var(--theme-colors-global-general-main);
  }

  &:focus-visible {
    background-color: var(--theme-colors-theme-brand-transparent-opacity12);
    border-color: var(--theme-colors-global-grey-grey500);
    opacity: var(--theme-opacity-visible);
    color: var(--theme-colors-theme-brand-primary);
  }
`

export const Warning = styled(Box)`
  border-style: solid;
  border-width: var(--theme-borderWidth-strokeM);
  border-color: var(--theme-colors-global-general-transparent);
  border-radius: var(--theme-borderRadius-default);
  background-color: var(--theme-colors-global-general-transparent);

  color: var(--theme-colors-theme-warning-main);

  &:hover {
    background-color: var(--theme-colors-theme-warning-transparent-opacity12);
  }

  &:active {
    background-color: var(--theme-colors-theme-warning-transparent-opacity60);
    color: var(--theme-colors-global-general-main);
  }

  &:focus-visible {
    background-color: var(--theme-colors-theme-warning-transparent-opacity12);
    border-color: var(--theme-colors-global-grey-grey500);
    opacity: var(--theme-opacity-visible);
    color: var(--theme-colors-theme-warning-main);
  }
`

export const Dander = styled(Box)`
  border-style: solid;
  border-width: var(--theme-borderWidth-strokeM);
  border-color: var(--theme-colors-global-general-transparent);
  border-radius: var(--theme-borderRadius-default);
  background-color: var(--theme-colors-global-general-transparent);

  color: var(--theme-colors-theme-negative-main);

  &:hover {
    background-color: var(--theme-colors-theme-negative-transparent-opacity12);
  }

  &:active {
    background-color: var(--theme-colors-theme-negative-transparent-opacity60);
    color: var(--theme-colors-global-general-main);
  }

  &:focus-visible {
    background-color: var(--theme-colors-theme-negative-transparent-opacity12);
    border-color: var(--theme-colors-global-grey-grey500);
    opacity: var(--theme-opacity-visible);
    color: var(--theme-colors-theme-negative-main);
  }
`

export const Neutral = styled(Box)`
  border-style: solid;
  border-width: var(--theme-borderWidth-strokeM);
  border-color: var(--theme-colors-global-general-transparent);
  border-radius: var(--theme-borderRadius-default);
  background-color: var(--theme-colors-global-general-transparent);

  color: var(--theme-colors-global-grey-grey700);

  &:hover {
    background-color: var(--theme-colors-global-grey-grey300);
  }

  &:active {
    background-color: var(--theme-colors-global-grey-grey500);
    color: var(--theme-colors-global-general-main);
  }

  &:focus-visible {
    background-color: var(--theme-colors-global-grey-grey300);
    border-color: var(--theme-colors-global-grey-grey500);
    opacity: var(--theme-opacity-visible);
    color: var(--theme-colors-global-grey-grey700);
  }
`
