import { Request } from 'commons/utils/request'

import { ApplicationDocumentEditWebpage, ApplicationDocumentUpdatePayload } from './types'

export const getApplicationDocumentEditWebPage = (id: number) =>
  Request.post<ApplicationDocumentEditWebpage>(Request.urls.bank.applicationClientDocumentEditWebpage, {
    id
  })

export const putApplicationClientDocument = (payload: ApplicationDocumentUpdatePayload) =>
  Request.put(Request.urls.bank.applicationClientDocument, payload)
