import styled from 'styled-components'
import { CSS } from 'ui/helpers'
import { HEADER_HEIGHT } from 'ui/themes/variables'

export const Box = styled.main<{ $widthFooter?: boolean; $noPadding?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0;
  height: ${(props) => (props.$widthFooter ? `calc(100vh - ${HEADER_HEIGHT})` : `auto`)};
`

export const BoxWithFooterInner = styled.div`
  flex: 1;
  overflow: auto;
`

export const BreadCrumbsWrapper = styled.div`
  ${(p) => CSS.populateFont(p.theme.uiKit.typography.bodyS.semiBold)};
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${(p) => p.theme.uiKit.colors.global.grey.grey500};
  padding: 0 50px;
  height: 35px;
`
