import { AnyRoute, createRoute, lazyRouteComponent } from '@tanstack/react-router'

export const salesDataBankSourceEditRoute = <T extends AnyRoute>(indexRoute: T) =>
  createRoute({
    getParentRoute: () => indexRoute,
    path: `$sourceId/edit`,
    component: lazyRouteComponent(() => import('./BankSourceEdit')),
    validateSearch: (search: Record<string, string>) => {
      return {
        returnUrl: search.returnUrl
      }
    }
  })
