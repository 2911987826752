import React, { Suspense, useContext } from 'react'

import { BankPortalContext } from 'bank-portal-context'
import { Spinner, SpinnerSize } from 'ui/components/Spinner'

import { useGetMenuItems } from './useGetMenuItems'

export const useBPLayout = (sidebar?: JSX.Element) => {
  const { barMinimized, changeBarMinimized, layout } = useContext(BankPortalContext)
  const { menuItems } = useGetMenuItems()

  const sideBar = sidebar ? <Suspense fallback={<Spinner centered size={SpinnerSize.M} />}>{sidebar}</Suspense> : null

  return {
    barMinimized,
    changeBarMinimized,
    layout,
    menuItems,
    sideBar
  }
}
