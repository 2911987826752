import { UserReport } from 'commons/types/DTO/bank'

export const dataSourceSelector = (data: any) =>
  (data?.list?.records || []).map((record: UserReport) => ({
    item: {
      active: record.active,
      created_at: record.created_at,
      email: record.email,
      first_name: record.first_name,
      last_login_at: record.last_login_at,
      last_name: record.last_name,
      phone: record.phone,
      role: record.role
    }
  }))
