import React from 'react'

import { CheckBox, FF } from 'ui/components'

import { useSARFormContext } from '../../../../../../context'

type IsEntityProps = {
  index: number
}

export const IsEntity = ({ index }: IsEntityProps) => {
  const { formApi } = useSARFormContext()
  return (
    <formApi.Field
      name={`subjects_info[${index}].is_entity` as 'subjects_info[0].is_entity'}
      children={(field) => (
        <FF field={field} label="3. Check" input={(p) => <CheckBox {...p} checkBoxLabel="If entity" />} />
      )}
    />
  )
}
