import React from 'react'

import { HistoryRecordDetails } from 'bank-entity-history/src/components/HistoryRecordDetails'
import format from 'date-fns/format'
import { TableColumn } from 'ui/components/Table'

import { getNameByEntityType } from '../../../../helpers'

export const columns = (showTypeColumn?: boolean) => (): TableColumn<any>[] => {
  const actions: any = {
    '1': 'Create',
    '2': 'Update',
    '3': 'Delete'
  }

  const usersTypes: any = {
    '0': 'Unknown',
    '1': 'Bank Portal User',
    '2': 'Client Portal User',
    '3': 'System User'
  }

  return [
    {
      title: 'User',
      align: 'center',
      dataIndex: 'user_email',
      width: '170px',
      sortable: false,
      render: ({ user_email, user_type, user_first_name, user_last_name }: any) => (
        <div>
          {user_email}
          {(user_first_name || user_last_name) && <div>{`(${user_first_name} ${user_last_name})`.trim()}</div>}
          <div>
            <i>{usersTypes[user_type]}</i>
          </div>
        </div>
      )
    },
    {
      title: 'Date',
      align: 'center',
      dataIndex: 'created_at',
      width: '170px',
      sortable: false,
      render: ({ created_at }) => !!created_at && format(new Date(created_at), 'MM/dd/yyyy hh:mm a')
    },
    {
      title: 'Action',
      align: 'center',
      dataIndex: 'action',
      width: '100px',
      sortable: false,
      render: ({ action }) => <span data-qa="action-type-row">{actions[action]}</span>
    },
    (showTypeColumn
      ? {
          title: 'Entity Type',
          align: 'center',
          dataIndex: 'proto_type',
          width: '200px',
          sortable: false,
          render: ({ proto_type }) => {
            try {
              return <span data-qa="entity-type-row">{getNameByEntityType(proto_type)}</span>
            } catch {
              return <span data-qa="entity-type-row">Unknown entity type</span>
            }
          }
        }
      : null) as any,
    {
      title: 'Details',
      dataIndex: 'id',
      sortable: false,
      render: ({ changes, links }) => <HistoryRecordDetails links={links} changes={changes} />
    }
  ].filter(Boolean)
}
