import React from 'react'

import { BPLayout } from 'ui/components/BPLayout'
import { PageFace } from 'ui/components/Page'
import { Warning } from 'ui/components/Warning'

import OnboardingSideBar from '../../OnboardingSideBar'
import { AddBankAccountsDialog } from '../../components/AddBankAccountsDialog'
import {
  Actions,
  ApplicationControls,
  CompanyDetails,
  ContactWithTheApplicant,
  DocumentsList,
  GeneralInformation,
  InternalDocuments,
  Reviews
} from './components'
import { FinalizeApplication } from './components/ApplicationControls/components'
import { DocumentsListIsolated } from './components/DocumentListIsolated'
import { OnboardingDocumentsList } from './components/OnboardingDocumentsList'
import { SetupOnboardingDocumentsButton } from './components/SetupOnboardingDocumentsButton'
import { ApplicationPage, PageWrapper } from './styledComponents'
import useApplicationDetails from './useApplicationDetails'

const ApplicationDetails = () => {
  const state = useApplicationDetails({})
  const { application, isShowBankAccountsDialog, onFinalizeApplication } = state

  const bankAccountDialogState = {
    applicationId: state.applicationId,
    showDialog: state.isShowBankAccountsDialog,
    onCancel: state.onCancel,
    onSubmit: state.onSubmit
  }

  return (
    <BPLayout sidebar={<OnboardingSideBar application={application} />}>
      <ApplicationPage
        title={application.titleOverline}
        subTitle={application.title}
        face={PageFace.SECONDARY}
        actions={<Actions application={application} />}
        $active={application.infoIsApplicationActive}
      >
        <PageWrapper>
          {isShowBankAccountsDialog && <AddBankAccountsDialog state={bankAccountDialogState} />}
          {application.enableApplicationInProgressWarning && (
            <Warning
              title="Application in progress"
              description="Please wait for the client to finish filling out the application."
            />
          )}
          <GeneralInformation item={application} />
          <ContactWithTheApplicant application={application} />
          <CompanyDetails application={application} />
          <section id="client-documents">
            {application.enableClientDocuments && !application.isIsolatedApplicationFromEntities && (
              <DocumentsList application={application} companies={application.relationships || []} />
            )}
            {application.enableClientDocuments && application.isIsolatedApplicationFromEntities && (
              <DocumentsListIsolated application={application} companies={application.relationships || []} />
            )}
          </section>
          <section id="reviews">{application.enableReviews && <Reviews application={application} />}</section>
          <InternalDocuments application={application} />
          {application.showOnboardingDocuments && <OnboardingDocumentsList application={application} />}
        </PageWrapper>
        <ApplicationControls application={application}>
          <>
            {application.showOnboardingDocuments && (
              <SetupOnboardingDocumentsButton
                applicationId={state.applicationId}
                enableSetupOnboardingDocuments={application.enableSetupOnboardingDocuments}
              />
            )}
            {application.enableFinalizeApplication && (
              <FinalizeApplication onFinalizeApplication={onFinalizeApplication} />
            )}
          </>
        </ApplicationControls>
      </ApplicationPage>
    </BPLayout>
  )
}

export default ApplicationDetails
