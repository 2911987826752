import { createContext } from 'react'

import { PortalName } from 'commons/types/enums'

import { LogoutHandler } from './hooks'

export type ContextShape = {
  portalName: PortalName
  logout: LogoutHandler
}

export const AuthContext = createContext<ContextShape>({
  portalName: PortalName.BP,
  logout: async () => {
    throw new Error('logout lost context or used outside context')
  }
})
