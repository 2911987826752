import SC from 'styled-components'

export const DialogContent = SC.div`
  padding: 34px 32px 32px 32px;  
`

export const Column = SC.div`
  display: flex;
  flex-direction: column;
  width: 270px;
`
