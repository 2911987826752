/* eslint-disable camelcase */
import { AnnualReviewQuestionnaire } from './annualReviewQuestionnaire'

export enum AnnualReviewStatuses {
  APPROVED = 'approved',
  COMPLETED = 'completed',
  NEW = 'new'
}

export type AnnualReview = {
  company_id?: number
  completed_date?: string
  created_at?: string
  deleted_at?: string
  financials_start_date?: string
  financials_end_date?: string
  id?: number
  last_ar_date?: string | null
  questionnaire?: AnnualReviewQuestionnaire
  sf_external_id?: string
  status?: AnnualReviewStatuses | string // TODO: remove all hardcode statuses with enum, after remove `| string`
  updated_at?: string
}
