import React from 'react'

import { IconSize } from 'ui/components/Icon'
import { Info } from 'ui/components/Info'
import { TextMask } from 'ui/components/TextMask'

import { SocialSecurityNumberProps } from './SocialSecurityNumberProps'

export const SocialSecurityNumber = ({ EINSSN }: SocialSecurityNumberProps) => (
  <Info label="Social Security Number / Tax ID Number">
    {EINSSN ? <TextMask text={EINSSN} iconSize={IconSize.XS} valueType="SSN" /> : '---'}
  </Info>
)
