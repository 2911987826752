import React, { FC, memo, useEffect, useMemo, useState } from 'react'

import { Customer } from 'commons/types/DTO/bank/companyDetails/customer'
import { TableDataQa } from 'commons/types/enums'
import { handlerServerError } from 'errors'
import { DataSourceItem, Layer, PageSection, PageSectionFace, Table } from 'ui/components'

import { CompanyCustomersTableProps } from './CompanyCustomersTableProps'
import { columns } from './columns'
import { Filter } from './components'
import { useComponentsEssence } from './hooks/useComponentsEssence'
import { useFilterCompanyCustomersWebPage } from './hooks/useFilterCompanyCustomersWebPage'

export const CompanyCustomersTable: FC<CompanyCustomersTableProps> = memo(
  ({ companyId, initialCustomers, initialCustomersTotalCount }) => {
    const [webPageData, setWebPageData] = useState({
      customers: initialCustomers,
      customersTotalCount: initialCustomersTotalCount
    })

    useEffect(() => {
      setWebPageData({
        customers: initialCustomers,
        customersTotalCount: initialCustomersTotalCount
      })
    }, [initialCustomers, initialCustomersTotalCount])

    const { routine: filterCompanyCustomersWebPage } = useFilterCompanyCustomersWebPage({
      onSuccess: async (_, result) => {
        await setWebPageData(result)
      },
      onError: (error) => handlerServerError(error)
    })

    const { loading, tableState, filterForm, changePagination, clearFilter } = useComponentsEssence(
      companyId,
      filterCompanyCustomersWebPage
    )

    const mappedCustomers: DataSourceItem<Customer>[] = useMemo(
      () =>
        webPageData.customers.map((el) => ({
          item: el
        })),
      [webPageData]
    )

    return (
      <PageSection face={PageSectionFace.PRIMARY} title="Customers" anchor="customers">
        <Layer rounded shadowed stretch>
          <Filter
            form={filterForm}
            expanded={tableState.expanded}
            onToggle={tableState.onFilterToggle}
            onClear={clearFilter}
          />
          <Table<Customer>
            dataQa={TableDataQa.BP_COMPANY_CUSTOMERS}
            columns={columns}
            dataSource={mappedCustomers}
            loading={loading}
            pagination={{
              page: tableState.page,
              pageSize: tableState.size,
              onChange: changePagination,
              total: webPageData.customersTotalCount
            }}
          />
        </Layer>
      </PageSection>
    )
  }
)
