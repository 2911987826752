import React, { FC, memo } from 'react'

import { Button } from '../../Buttons'
import { Dialog } from '../Dialog'
import { ConfirmationDialogProps } from './ConfirmationDialogProps'

import * as SC from './styles'

export const ConfirmationDialog: FC<ConfirmationDialogProps> = memo((props) => {
  const {
    id,
    onCancel,
    message,
    onConfirm,
    isConfirmationNegative,
    confirmationButtonText,
    title,
    subTitle,
    face,
    'data-qa': dataQA,
    isCancelButtonNeeded = true
  } = props

  return (
    <Dialog id={id} onClose={onCancel} title={title} subTitle={subTitle} face={face} data-qa={dataQA}>
      <SC.ConfirmationContainer>
        <SC.Message data-qa={`${dataQA}-text`}>{message}</SC.Message>
        <SC.ActionsWrapper>
          <Button
            onClick={onConfirm}
            face={isConfirmationNegative ? 'negative' : 'positive'}
            data-qa={isConfirmationNegative ? 'Delete' : 'Confirm'}
          >
            {confirmationButtonText}
          </Button>
          {isCancelButtonNeeded && (
            <Button face="neutral" onClick={onCancel}>
              Cancel
            </Button>
          )}
        </SC.ActionsWrapper>
      </SC.ConfirmationContainer>
    </Dialog>
  )
})
