import React, { FC, memo } from 'react'

import { BankAccount } from 'commons/types/DTO/bank/quarterlyReport/bankAccount'
import { IconSize } from 'ui/components/Icon'
import { PageSection, PageSectionFace } from 'ui/components/Page'
import { TextMask } from 'ui/components/TextMask'

import { BankAccountDataProps } from './BankAccountDataProps'
import {
  DepositsFromSalesTable,
  OtherBankActivityTable,
  SalesReportTable,
  SalesToDepositComparisonTable
} from './components'

import * as SC from './styles'

export const BankAccountData: FC<BankAccountDataProps> = memo(({ item }) => {
  const { bankAccounts } = item

  return (
    <>
      {bankAccounts.map((account: BankAccount, index) => {
        const title = (
          <p style={{ display: 'flex', columnGap: '10px' }}>
            <span>Bank Account #{index + 1}</span>
            <TextMask
              isBankAccount
              text={account.bankAccountNumber || '---'}
              link={`/bank-accounts/${account.bankAccountID}`}
              iconSize={IconSize.XS}
            />
          </p>
        )

        return (
          <PageSection key={index} face={PageSectionFace.THIRD} title={title} anchor={`bank-account-${index}`}>
            {account.warnings && account.warnings.length > 0 && (
              <>
                <SC.WrappedWarnings warnings={account.warnings} />
                <br />
              </>
            )}
            {account.enableSalesRetail && account.salesRetail && (
              <>
                <SalesReportTable item={account?.salesRetail} title="Retail" />
                <br />
              </>
            )}
            {account.enableSalesWholesale && account?.salesWholesale && (
              <>
                <SalesReportTable item={account?.salesWholesale} title="Wholesale" />
                <br />
              </>
            )}
            {account.depositsFromSales && <DepositsFromSalesTable {...account.depositsFromSales} />}
            {!!account.enableOtherActivity && account.otherActivity && (
              <OtherBankActivityTable {...account.otherActivity} />
            )}
            <br />
            {account.salesToDepositsComparison && (
              <SalesToDepositComparisonTable item={account.salesToDepositsComparison} />
            )}
          </PageSection>
        )
      })}
    </>
  )
})
