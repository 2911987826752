import React, { FC, memo } from 'react'

import { Form } from 'brief-form'
import { usAndTerritories } from 'commons/types/dictionaries'
import { InfoSet } from 'ui/components/Info'
import { TextInput } from 'ui/components/InputV2'
import { Select, SelectListName, getOptionsByListName } from 'ui/components/Select'
import { Col, FormRow } from 'ui/themes/globalStyles'

import { OnboardingFormFieldNames } from '../../enums'
import { CompanyMailingAddressChangeHandler, CompanyMailingAddressFormData } from '../../types/forms'
import { useCompanyMailingAddressForm } from './useCompanyPhysicalAddressForm'

type CompanyMailingAddressFormProps = {
  companyIndex: number
  value: CompanyMailingAddressFormData
  onChange: CompanyMailingAddressChangeHandler
}

export const CompanyMailingAddressForm: FC<CompanyMailingAddressFormProps> = memo(
  ({ companyIndex, value, onChange }) => {
    const { Field, config } = useCompanyMailingAddressForm({ companyIndex, formData: value, onChange })
    return (
      <Form config={config}>
        <InfoSet legend="Mailing Address">
          <FormRow>
            <Col>
              <Field
                name={OnboardingFormFieldNames.MAILING_STREET}
                label="Street"
                input={TextInput}
                inputProps={{
                  'data-qa': 'Street',
                  maxLength: 255
                }}
              />
            </Col>
            <Col>
              <Field
                name={OnboardingFormFieldNames.MAILING_CITY}
                label="City"
                input={TextInput}
                inputProps={{
                  'data-qa': 'City',
                  maxLength: 40
                }}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <Field
                name={OnboardingFormFieldNames.MAILING_STATE}
                label="State/Province"
                input={Select}
                inputProps={{
                  'data-qa': 'State/Province',
                  'options-data-qa': 'State/Province',
                  data: getOptionsByListName(SelectListName.STATE_OF_INCORPORATION)
                }}
              />
            </Col>
            <Col>
              <Field
                name={OnboardingFormFieldNames.MAILING_COUNTRY}
                label="Country"
                input={Select}
                inputProps={{
                  'data-qa': 'Country',
                  'options-data-qa': 'Country',
                  data: usAndTerritories
                }}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <Field
                name={OnboardingFormFieldNames.MAILING_POSTAL_CODE}
                label="Zip/Postal Code"
                input={TextInput}
                inputProps={{
                  'data-qa': 'Zip/Postal Code',
                  maxLength: 20
                }}
              />
            </Col>
          </FormRow>
        </InfoSet>
      </Form>
    )
  }
)
