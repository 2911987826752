import { getSettings } from 'commons/service/bank/settings'
import { OrganizationSetting } from 'commons/types/DTO/bank/organizationSetting'
import { Keys, ReadHook, useStateManager } from 'state-manager'

export const useGetBankSettings: ReadHook<OrganizationSetting> = () => {
  const sm = useStateManager()

  const { data, ...rest } = sm.use(Keys.Bank.Settings, getSettings, {
    keepPreviousData: true
  })

  return {
    ...rest,
    data: data ?? undefined
  }
}
