const eventRemindMap: { [key: string]: string } = {
  never: 'Never',
  '5min': '5 minutes',
  '15min': '15 minutes',
  '30min': '30 minutes',
  '1hour': '1 hour',
  '2hour': '2 hours',
  '12hour': '12 hours',
  '1day': 'Day',
  '1week': 'Week'
}

export const getEventRemindLabel = (name: string) => {
  return eventRemindMap[name] || name
}
