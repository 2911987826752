import React, { FC, useState } from 'react'

import * as SC from '../styles'
import { HistoryChanges } from 'commons/types/DTO/bank/historyAudit'

import { EntityHistoryDetailsTextMask } from './EntityHistoryDetailsTextMask'

export const HistoryCreateLabel: FC<Pick<HistoryChanges, 'value' | 'subject' | 'mustBeMasked' | 'warning'>> = ({
  value,
  subject,
  mustBeMasked,
  warning
}) => {
  const [expanded, setExpanded] = useState<boolean>(false)

  if (warning) value = warning

  if (!value || value?.length === 0) return <></>

  const switchExpanded = () => setExpanded(!expanded)

  const valueTooBig = value?.toString().length > 50

  if (valueTooBig)
    return (
      <SC.Row>
        <b data-qa="details-field-label">{subject}</b>:{' '}
        <b data-qa="details-field-value">
          <i>{valueTooBig && !expanded ? `${value.toString().slice(0, 50)}...` : value || '""'}</i>
        </b>
        <SC.SwitcherLink onClick={switchExpanded}>{expanded ? 'Collapse' : 'Expand'}</SC.SwitcherLink>
      </SC.Row>
    )
  return (
    <SC.Row>
      <b data-qa="details-field-label">{subject}</b>:{' '}
      {mustBeMasked ? (
        <EntityHistoryDetailsTextMask text={value} />
      ) : (
        <b data-qa={`details-field-value-${subject}`}>{value}</b>
      )}
    </SC.Row>
  )
}
