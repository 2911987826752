import React, { FC, memo } from 'react'

import { getRouteApi } from '@tanstack/react-router'
import { BPLayout } from 'ui/components/BPLayout'
import { Spinner, SpinnerSize } from 'ui/components/Spinner'

import { LicenseDetailsPage } from './components/LicenseDetailsPage'
import { LicenseSideBar } from './components/LicenseSideBar'
import { useLicenseDetails } from './hooks'

const routeApi = getRouteApi('/licenses/$licenseId')

const LicenseDetails: FC = memo(() => {
  const { licenseId } = routeApi.useParams()
  const id = parseInt(licenseId || '0')

  const {
    isFetching,
    data,
    invoicesTableConfig,
    documentsTableConfig,
    historyTableConfig,
    onInvoiceDeleted,
    onDocumentDeleted
  } = useLicenseDetails(id)

  return (
    <BPLayout sidebar={<LicenseSideBar availableItems={data?.sidebar} />}>
      {isFetching && !data && <Spinner centered size={SpinnerSize.M} />}
      {!!data && (
        <LicenseDetailsPage
          data={data}
          invoicesTableConfig={invoicesTableConfig}
          documentsTableConfig={documentsTableConfig}
          historyTableConfig={historyTableConfig}
          onInvoiceDeleted={onInvoiceDeleted}
          onDocumentDeleted={onDocumentDeleted}
        />
      )}
    </BPLayout>
  )
})

export default LicenseDetails
