import { useCallback, useEffect, useState } from 'react'

import { useGetBankPortalLayout } from 'commons/hooks/bank/layout'
import { useZendesk } from 'commons/hooks/common'
import { BPRouterItems } from 'commons/types/enums'
import { useConfiguration } from 'modules/configuration'
import { useStateManager } from 'state-manager'

export const useMainPage = () => {
  const { configuration } = useConfiguration()
  const { activateZendeskWidget } = useZendesk({ zendeskWidgetKey: configuration?.zendesk.widgetKey })
  const { data: layout } = useGetBankPortalLayout()
  const [barMinimized, setBarMinimized] = useState(false)
  const [customSidebarItems, setCustomSidebarItems] = useState([])
  const sm = useStateManager()

  const changeBarMinimized = useCallback(() => {
    setBarMinimized((value) => !value)
  }, [])

  let isOnlyApplicationsAndAccountSettingsAccessible = false

  if (layout) {
    const { routerItems } = layout

    isOnlyApplicationsAndAccountSettingsAccessible =
      (routerItems.length === 1 && routerItems.indexOf(BPRouterItems.APPLICATIONS) === 0) ||
      (routerItems.length === 2 &&
        routerItems.indexOf(BPRouterItems.APPLICATIONS) !== -1 &&
        routerItems.indexOf(BPRouterItems.ACCOUNT_SETTINGS) !== -1)
  }

  /*
    Clear query cache on logout.
    Can't do that in Auth packages, coz some queries begins refetching in infinite loop.
  */
  // Run only on unmount.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => sm.removeAll(), [])

  return {
    activateZendeskWidget,
    changeBarMinimized,
    barMinimized,
    layout,
    isOnlyApplicationsAndAccountSettingsAccessible,
    customSidebarItems,
    setCustomSidebarItems
  }
}
