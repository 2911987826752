import { Link } from '@tanstack/react-router'
import styled from 'styled-components'
import { HeaderButton } from 'ui/components/Buttons'

export const LoginCol = styled.div`
  width: 314px;
`

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
`

export const ForgotLink = styled(Link)`
  text-decoration: underline;
  color: #fff !important;
  font-size: 12px;

  &:visited {
    color: #fff !important;
  }
`

export const SubmitButton = styled(HeaderButton)`
  width: 142px;
  padding: 0 20px;
  position: relative;

  path {
    fill: #fff;
  }

  &:hover {
    path {
      fill: ${(p) => p.theme.uiKit.colors.theme.brand.primary};
    }
  }

  &:disabled {
    path {
      fill: ${(p) => p.theme.uiKit.colors.global.grey.grey500};
    }
  }
`
