import React from 'react'

import { ReviewFormUploadWrap } from '../ReviewFormUploadWrap'
import { FinalReviewFormUploadProps } from './FinalReviewFormUploadProps'

export const FinalReviewFormUpload = ({ application, title }: FinalReviewFormUploadProps) => (
  <ReviewFormUploadWrap
    application={application}
    title={title}
    onlyNotes={false}
    requiredNotes={false}
    type="Final Review Form"
    notes=""
    buttonName={title}
  />
)
