import React, { FC, memo } from 'react'

import { usStates } from 'commons/types/dictionaries'
import { Select } from 'ui/components'
import { FieldSet } from 'ui/components/FieldSet'
import { TextInput } from 'ui/components/InputV2'
import { Col, FormRow } from 'ui/themes/globalStyles'

import { AddressInformationProps } from './AddressInformationProps'

export const AddressInformation: FC<AddressInformationProps> = memo(({ Field }) => (
  <FieldSet legend="Physical Address">
    <FormRow>
      <Col>
        <Field
          name="street"
          label="Street"
          input={TextInput}
          inputProps={{
            'data-qa': 'Physical Address Street'
          }}
        />
      </Col>
    </FormRow>
    <FormRow>
      <Col>
        <Field
          name="city"
          label="City"
          input={TextInput}
          inputProps={{
            'data-qa': 'Physical Address City'
          }}
        />
      </Col>
    </FormRow>
    <FormRow>
      <Col>
        <Field
          name="state"
          label="State/Province"
          input={Select}
          inputProps={{
            'data-qa': 'Physical Address State/Province',
            'options-data-qa': 'Physical Address State/Province',
            data: usStates,
            emptyOption: { label: 'Not selected', value: '' }
          }}
        />
      </Col>
    </FormRow>
    <FormRow>
      <Col>
        <Field
          name="postalCode"
          label="Zip/Postal Code"
          input={TextInput}
          inputProps={{
            'data-qa': 'Physical Address Zip/Postal Code'
          }}
        />
      </Col>
    </FormRow>
  </FieldSet>
))
