import React from 'react'

import { FF, TextInput } from 'ui/components'

import { useSARFormContext } from '../../../../../../../../context'
import { useSubjectInformationItemContext } from '../../../SubjectInformationItemContext'

type PostalCodeProps = {
  index: number
}

export const PostalCode = ({ index }: PostalCodeProps) => {
  const { formApi } = useSARFormContext()
  const { isAllInfoUnavailable } = useSubjectInformationItemContext()

  return (
    <formApi.Field
      name={`subjects_info[${index}].address_info[0].postal_code` as 'subjects_info[0].address_info[0].postal_code'}
      children={(field) => (
        <FF
          field={field}
          label="14. ZIP/Postal Code"
          input={(p) => <TextInput {...p} disabled={isAllInfoUnavailable} maxLength={9} />}
        />
      )}
    />
  )
}
