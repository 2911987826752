import { useCallback } from 'react'

import { FormChangedHandler, useFormData } from 'brief-form'

import { useSyncFormData } from '../../hooks'
import {
  AvailableServicesChangeHandler,
  AvailableServicesFormData,
  AvailableServicesFormValue
} from '../../types/forms'

export const useAvailableServicesForm = ({
  companyIndex,
  formData,
  onChange
}: {
  companyIndex: number
  formData: AvailableServicesFormData
  onChange: AvailableServicesChangeHandler
}) => {
  const onFormChanged: FormChangedHandler<AvailableServicesFormValue> = useCallback(
    (formValues, formErrors) => onChange({ companyIndex, formData: { formValues, formErrors } }),
    [onChange, companyIndex]
  )

  const { config, Field, set } = useFormData<AvailableServicesFormValue>({
    onFormChanged,
    initialValue: formData.formValues,
    initialErrors: formData.formErrors
  })

  useSyncFormData({ set, formData })

  return { Field, config }
}
