import React, { memo, useCallback } from 'react'

import { SalesRetail } from 'commons/types/DTO/bank/quarterlyReport/bankAccount/SalesRetail'
import { formatCurrency, formatNumber } from 'commons/utils'
import { Link } from 'ui/components/Link'

import * as SC from './styles'

interface Props {
  item: SalesRetail
  title: string
}

export const SalesReportTable = memo(({ item, title }: Props) => {
  const { changesPeerGroup, changesPriorQuarter, currentQuarter, licenses, peerGroupAverage, priorQuarter } = item

  const getPercentValue = useCallback((v: number) => {
    if (v === -1) {
      return 'N/A'
    }

    return v >= 0 ? <SC.TV>{v.toFixed(2)}%</SC.TV> : <SC.TV $negative>({(-v).toFixed(2)})%</SC.TV>
  }, [])

  return (
    <SC.WrappedLayer rounded shadowed>
      <SC.Box>
        <SC.Title>
          SALES REPORT - {title} (
          {licenses.map((license, i) => (
            <SC.SubTitle key={i}>
              <Link to="/licenses/$licenseId" params={{ licenseId: license.id }}>
                {license.licenseNumber}
              </Link>
              {i === licenses.length - 1 ? '' : ', '}
            </SC.SubTitle>
          ))}
          )
        </SC.Title>
        <SC.Columns>
          <SC.Labels>
            <SC.Label>Current Quarter</SC.Label>
            <SC.Label>Prior Quarter</SC.Label>
            <SC.Label>Peer Group Average</SC.Label>
            <SC.Label>
              <b>% Change</b>
            </SC.Label>
            <SC.Label>Prior Quarter</SC.Label>
            <SC.Label>Peer Group </SC.Label>
          </SC.Labels>
          <SC.Table>
            <thead>
              <tr>
                <SC.Th>Total</SC.Th>
                <SC.Th colSpan={3}>Per Gram</SC.Th>
                <SC.Th colSpan={3}>Per Unit</SC.Th>
              </tr>
              <tr>
                <SC.Th2>$ Sold</SC.Th2>
                <SC.Th2>Grams Sold</SC.Th2>
                <SC.Th2>$ Sold</SC.Th2>
                <SC.Th2>$ per Gram </SC.Th2>
                <SC.Th2>Units Sold </SC.Th2>
                <SC.Th2>$ Sold</SC.Th2>
                <SC.Th2>$ per Unit</SC.Th2>
              </tr>
            </thead>
            <tbody>
              <tr>
                <SC.Td>{formatCurrency(currentQuarter.total, false)}</SC.Td>
                <SC.Td>{formatNumber(currentQuarter.gramsQty, false)}</SC.Td>
                <SC.Td>{formatCurrency(currentQuarter.gramsSold, false)}</SC.Td>
                <SC.Td>{formatCurrency(currentQuarter.gramsPerQty)}</SC.Td>
                <SC.Td>{formatNumber(currentQuarter.unitsQty, false)}</SC.Td>
                <SC.Td>{formatCurrency(currentQuarter.unitsSold, false)}</SC.Td>
                <SC.Td>{formatCurrency(currentQuarter.unitsPerQty)}</SC.Td>
              </tr>
              <tr>
                <SC.Td>{formatCurrency(priorQuarter.total, false)}</SC.Td>
                <SC.Td>{formatNumber(priorQuarter.gramsQty, false)}</SC.Td>
                <SC.Td>{formatCurrency(priorQuarter.gramsSold, false)}</SC.Td>
                <SC.Td>{formatCurrency(priorQuarter.gramsPerQty)}</SC.Td>
                <SC.Td>{formatNumber(priorQuarter.unitsQty, false)}</SC.Td>
                <SC.Td>{formatCurrency(priorQuarter.unitsSold, false)}</SC.Td>
                <SC.Td>{formatCurrency(priorQuarter.unitsPerQty)}</SC.Td>
              </tr>
              <tr>
                <SC.Td>{formatCurrency(peerGroupAverage.total, false)}</SC.Td>
                <SC.Td>{formatNumber(peerGroupAverage.gramsQty, false)}</SC.Td>
                <SC.Td>{formatCurrency(peerGroupAverage.gramsSold, false)}</SC.Td>
                <SC.Td>{formatCurrency(peerGroupAverage.gramsPerQty)}</SC.Td>
                <SC.Td>{formatNumber(peerGroupAverage.unitsQty, false)}</SC.Td>
                <SC.Td>{formatCurrency(peerGroupAverage.unitsSold, false)}</SC.Td>
                <SC.Td>{formatCurrency(peerGroupAverage.unitsPerQty)}</SC.Td>
              </tr>
              <tr>
                <SC.Stub colSpan={7} />
              </tr>
              <tr>
                <SC.Td $hl={changesPriorQuarter.total.alerted}>
                  {getPercentValue(changesPriorQuarter.total.percent)}
                </SC.Td>
                <SC.Td $hl={changesPriorQuarter.gramsQty.alerted}>
                  {getPercentValue(changesPriorQuarter.gramsQty.percent)}
                </SC.Td>
                <SC.Td $hl={changesPriorQuarter.gramsSold.alerted}>
                  {getPercentValue(changesPriorQuarter.gramsSold.percent)}
                </SC.Td>
                <SC.Td $hl={changesPriorQuarter.gramsPerQty.alerted}>
                  {getPercentValue(changesPriorQuarter.gramsPerQty.percent)}
                </SC.Td>
                <SC.Td $hl={changesPriorQuarter.unitsQty.alerted}>
                  {getPercentValue(changesPriorQuarter.unitsQty.percent)}
                </SC.Td>
                <SC.Td $hl={changesPriorQuarter.unitsSold.alerted}>
                  {getPercentValue(changesPriorQuarter.unitsSold.percent)}
                </SC.Td>
                <SC.Td $hl={changesPriorQuarter.unitsPerQty.alerted}>
                  {getPercentValue(changesPriorQuarter.unitsPerQty.percent)}
                </SC.Td>
              </tr>
              <tr>
                <SC.Td>--</SC.Td>
                <SC.Td>--</SC.Td>
                <SC.Td>--</SC.Td>
                <SC.Td $hl={changesPeerGroup.gramsPerQty.alerted}>
                  {getPercentValue(changesPeerGroup.gramsPerQty.percent)}
                </SC.Td>
                <SC.Td>--</SC.Td>
                <SC.Td>--</SC.Td>
                <SC.Td $hl={changesPeerGroup.unitsPerQty.alerted}>
                  {getPercentValue(changesPeerGroup.unitsPerQty.percent)}
                </SC.Td>
              </tr>
            </tbody>
          </SC.Table>
        </SC.Columns>
      </SC.Box>
    </SC.WrappedLayer>
  )
})
