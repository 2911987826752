import React from 'react'

import { CheckBox, FF } from 'ui/components'

import { useSARFormContext } from '../../../../../../../context'

export const P = () => {
  const { formApi } = useSARFormContext()
  return (
    <formApi.Field
      name="transactions_info.other_activities.p"
      children={(field) => (
        <FF
          field={field}
          input={(p) => (
            <CheckBox {...p} checkBoxLabel="p. Transaction with no apparent economic, business, or lawful purpose" />
          )}
          errorPosition="right"
        />
      )}
    />
  )
}
