import React, { FC, memo } from 'react'

import { COMPANIES_PATH } from 'commons/constants/routes'
import { AnnualReview, Company } from 'commons/types/DTO/bank'
import { DateTime } from 'ui/components/DateTime'
import { HeadingTitle } from 'ui/components/HeadingTitle'
import { Info, InfoSet, InfoSetDirection } from 'ui/components/Info'
import { AnnualReviewStatusLabel } from 'ui/components/Labels'
import { PageSection, PageSectionFace } from 'ui/components/Page'

import { AnnualReviewActions } from '../AnnualReviewActions'

import { CompanyLink, InfoColumn } from './styles'

export interface AnnualReviewDetailsInfoProps {
  company: Company
  annualReview: AnnualReview
  editUrl: string
  onApprove?: () => void
  affiliatedCompanies: Company[]
}

const isStart = (annualReview: AnnualReview) => annualReview?.created_at === annualReview?.updated_at

export const AnnualReviewInfo: FC<AnnualReviewDetailsInfoProps> = memo(
  ({ company, annualReview, editUrl, onApprove, affiliatedCompanies }: AnnualReviewDetailsInfoProps) => (
    <PageSection
      first
      title="Annual Review"
      face={PageSectionFace.THIRD}
      actions={
        <AnnualReviewActions
          onApprove={annualReview?.status === 'completed' ? onApprove : undefined}
          edit={annualReview?.status === 'completed' || annualReview?.status === 'approved'}
          isStart={isStart(annualReview)}
          editUrl={editUrl}
        />
      }
    >
      <HeadingTitle large={true}>Overview</HeadingTitle>
      <InfoSet direction={InfoSetDirection.ROW}>
        <InfoColumn>
          <Info label="Relationship Name">
            <CompanyLink to={`${COMPANIES_PATH}/${company.id}`}>{company.name || '---'}</CompanyLink>
          </Info>
          <Info label="Licensed Entity Legal Name">{company.legal_name || '---'}</Info>
          <Info label="Date of Initial Approval">
            {annualReview.created_at ? <DateTime date={annualReview.created_at} /> : '---'}
          </Info>
          <Info label="Status">
            <AnnualReviewStatusLabel
              name={annualReview.status || '---'}
              created={Date.parse(annualReview.created_at || '')}
              updated={Date.parse(annualReview.updated_at || '')}
              data-qa="Status"
            />
          </Info>
        </InfoColumn>
        <InfoColumn>
          <Info label="DBA (if applicable)">{company.dba || '---'}</Info>
          <Info label="Related Companies">
            {affiliatedCompanies.length ? affiliatedCompanies.map((c) => c.name).join(', ') : '-'}
          </Info>
          <Info label="Date of Last Annual Review">
            {annualReview.last_ar_date ? <DateTime date={annualReview.last_ar_date} /> : '---'}
          </Info>
          <Info label="Financials Period Start">
            {annualReview.financials_start_date ? <DateTime date={annualReview.financials_start_date} /> : '---'}
          </Info>
          <Info label="Financials Period End">
            {annualReview.financials_end_date ? <DateTime date={annualReview.financials_end_date} /> : '---'}
          </Info>
        </InfoColumn>
      </InfoSet>
    </PageSection>
  )
)
