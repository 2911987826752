import * as React from 'react'

import SC from 'styled-components'

const Column = SC.div`
  flex: 1;
  margin-right: 30px;
`

export interface AnnualReviewQuestionColumnProps {
  children: React.ReactNode
}

export const AnnualReviewQuestionColumn: React.FC<AnnualReviewQuestionColumnProps> = ({
  children
}: AnnualReviewQuestionColumnProps) => <Column>{children}</Column>
