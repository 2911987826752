/**
  Remove useless spaces, and remove a set of zeros at the beginning
 */
export const bankAccountNumberClear = (input: string): string => {
  if (!input) {
    return input
  }
  // clear spaces
  input = input.replace(/\s+/g, '')
  // clear zeros
  input = input.replace(/^0+/g, '')

  return input
}
