import React from 'react'

import { usStates } from 'commons/types/dictionaries'
import { FF, Select } from 'ui/components'

import { useSARFormContext } from '../../../../../../../context'

const EMPTY_OPTION = { label: 'Not selected', value: '' }

export const State = ({ index1, index2 }: { index1: number; index2: number }) => {
  const { formApi } = useSARFormContext()
  const noBranchInvolved = formApi.useStore((s) => s.values.institution_activities_info[index1].no_branch_involved)

  return (
    <formApi.Field
      name={
        `institution_activities_info[${index1}].institution_activity_branches_info[${index2}].state` as 'institution_activities_info[0].institution_activity_branches_info[0].state'
      }
      children={(field) => (
        <FF
          field={field}
          label="72. State"
          input={(p) => (
            <Select
              {...p}
              data={usStates}
              emptyOption={EMPTY_OPTION}
              disabled={noBranchInvolved}
              data-qa="72. State"
              options-data-qa="72. State option"
            />
          )}
        ></FF>
      )}
    />
  )
}
