export enum DocumentsPaths {
  LIST = '/documents',
  DETAILS = '/documents/$documentId',
  NEW = '/documents/new',
  EDIT = `/documents/$documentId/edit`,
  NEW_PERIOD = `/documents/$documentId/new-period`,
  DOCUMENT_DETAILS_EDIT_PERIOD = `/documents/$documentId/edit-period/$periodId`,
  PERIOD_DETAILS = `/documents/$documentId/periods/$periodId`,
  EDIT_PERIOD_DETAILS = `/documents/$documentId/periods/$periodId/edit`
}
