import React, { FC, memo } from 'react'

import { Button, IconButton } from 'ui/components/Buttons'
import { IconName } from 'ui/components/Icon'

import { s } from './styles'

type Props = {
  canGenerate: boolean
  onXMLGenerate: () => void
  onDeleteCTRs: () => void
  hasChosenItems: boolean
  date: string
  type: string
  status: string
  page: number
  size: number
}

export const Footer: FC<Props> = memo((props) => {
  const { onXMLGenerate, canGenerate, hasChosenItems, onDeleteCTRs, date, type, status, page, size } = props

  return (
    <s.Box>
      <s.Left>
        {canGenerate && (
          <a
            target="_blank"
            href={`/bsa/ctrs/print?date=${date}&type=${type}&status=${status}&page=${page}&size=${size}`}
          >
            <Button face="positive">Print PDF</Button>
          </a>
        )}
        {hasChosenItems && (
          <IconButton face="negative" icon={IconName.DELETE} onClick={onDeleteCTRs} data-qa="Delete">
            Remove CTRs
          </IconButton>
        )}
      </s.Left>
      {hasChosenItems && (
        <Button face="positive" onClick={onXMLGenerate}>
          Generate CTR Batch XML
        </Button>
      )}
    </s.Box>
  )
})
