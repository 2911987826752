import { useCallback, useMemo, useState } from 'react'

import { Request } from 'commons/utils/request'
import { isEqual } from 'lodash'
import { ReadHook, useStateManager } from 'state-manager'
import { CTRListRequest, CTRListResponse } from '~bank-bsa-reporting/types'

export const CTR_LIST_WEBPAGE_KEY = 'ctr-list-webpage'

const request = (opts: CTRListRequest) =>
  Request.post<CTRListResponse, CTRListRequest>(Request.urls.bank.ctrListWebpage, opts)

const useGetList: ReadHook<CTRListResponse, CTRListRequest> = (opts) =>
  useStateManager().use([CTR_LIST_WEBPAGE_KEY, opts], () => request(opts), {
    keepPreviousData: true
  })

const defaultFilter = (): CTRListRequest => ({
  filingType: null,
  page: 1,
  size: 50,
  status: null,
  transactionDate: null
})

export const useGetCTRList = (pdfFilter?: CTRListRequest) => {
  const [filter, setFilter] = useState<CTRListRequest>(defaultFilter())

  const { isFetching, data } = useGetList(pdfFilter ?? filter)

  const onFilterChange = useCallback(
    (value: CTRListRequest) => {
      if (!isEqual(filter, value)) {
        setFilter({ ...filter, ...value })
      }
    },
    [filter]
  )

  const onClear = useCallback(() => {
    setFilter(defaultFilter)
  }, [])

  const tableConfig = useMemo(
    () => ({
      isFetching,
      onFilterChange,
      onClear
    }),
    [isFetching, onClear, onFilterChange]
  )

  return {
    data,
    isFetching,
    tableConfig
  }
}
