import { createContext } from 'react'

import { GetBankPortalLayoutResponse } from 'commons/types/DTO/bank/layout'
import { SubMenuItem } from 'commons/types/DTO/commons'

export type BankPortalContextValue = {
  barMinimized: boolean
  changeBarMinimized: () => void
  activateZendeskWidget: () => void
  layout: GetBankPortalLayoutResponse
  isOnlyApplicationsAndAccountSettingsAccessible: boolean
  setCustomSidebarItems: (items: SubMenuItem[]) => void
  customSidebarItems: SubMenuItem[]
}

export const BankPortalContext = createContext<BankPortalContextValue>({
  barMinimized: false,
  changeBarMinimized: () => ({}),
  activateZendeskWidget: () => ({}),
  layout: {
    routerItems: [],
    sidebarItems: [],
    assignedCompaniesIDs: [],
    nextCloudRedirectURL: '',
    header: {
      accountMenuItems: [],
      name: ''
    }
  },
  isOnlyApplicationsAndAccountSettingsAccessible: false,

  customSidebarItems: [],
  setCustomSidebarItems: () => ({})
})
