import { useQuery } from 'react-query'

import { Company } from 'commons/types/DTO/bank'
import { ApiReadResponse } from 'commons/types/api'
import { companyList } from '~bank-annual-review/service'

interface CompanyProps {
  id?: number
  enabled?: boolean
}

export const useCompany = (
  props: CompanyProps,
  suppressFetch?: boolean
): { isFetching: boolean; data?: ApiReadResponse<Company> } => {
  const { data, isFetching } = useQuery<any, Error>(
    ['company'],
    () => (suppressFetch ? Promise.resolve({ isFetching: false, data: undefined }) : companyList(props.id)),
    {
      keepPreviousData: true,
      enabled: props.enabled
    }
  )

  return {
    isFetching,
    data
  }
}
