import React from 'react'

import { DocumentsPaths } from 'bank-documents/src/constants'
import { ClientUploadedDocuments } from 'commons/types/DTO/bank/applications/manage/ClientUploadedDocuments'
import { Relationships } from 'commons/types/DTO/bank/applications/manage/Relationships'
import {
  CheckMark,
  DocumentPeriodStatusChip,
  Link,
  MenuItemBehavior,
  MenuItemTextFace,
  TableActions,
  TableColumn,
  Tooltip
} from 'ui/components'

import { Files } from './components/Files'

export const columns = (
  company: Relationships,
  readonly: boolean,
  onDeleteDocument: (doc: { id: number; name: string }) => void
): TableColumn<ClientUploadedDocuments>[] =>
  [
    {
      title: 'Document Name',
      align: 'left',
      render: (item) =>
        item.isViewable ? (
          <Link key={item.documentID} to={DocumentsPaths.DETAILS} params={{ documentId: item.documentID }}>
            {item.documentName}
          </Link>
        ) : (
          <div>{item.documentName}</div>
        )
    },
    {
      title: 'Internal',
      align: 'center',
      width: '100px',
      render: (item) =>
        item.internal && (
          <Tooltip id={`internal_${item.documentID}`} content="Not visible for an applicant" type="dark">
            <CheckMark value rounded />
          </Tooltip>
        )
    },
    {
      title: 'Status',
      align: 'center',
      width: '200px',
      render: (item) => (item.status ? <DocumentPeriodStatusChip name={item.status} /> : null)
    },
    {
      align: 'center',
      title: 'Files',
      width: '300px',
      render: (item) => {
        if (!company.companyName || !item.documentName) {
          return <></>
        }
        return (
          company.companyName &&
          item.documentName && (
            <Files files={item.files} companyName={company.companyName} documentName={item.documentName} />
          )
        )
      }
    },
    readonly
      ? null
      : {
          title: 'Actions',
          align: 'center',
          width: '150px',
          render: (item) => (
            <TableActions
              menuContainerId={`internalDocumentsContainer${company.companyID}`}
              items={[
                {
                  key: 1,
                  title: 'Delete Document',
                  behavior: MenuItemBehavior.BUTTON,
                  textFace: MenuItemTextFace.GREY,
                  onClick: () => onDeleteDocument({ id: item.documentID, name: item.documentName || '' })
                }
              ]}
            />
          )
        }
  ].filter(Boolean) as TableColumn<ClientUploadedDocuments>[]
