import React, { memo, useCallback } from 'react'

import { useGetLicenseListByIds } from 'commons/hooks/bank/license'
import { MultiSelectAutoSuggest } from 'ui/components'
import { IconName } from 'ui/components/Icon'

import { LicensesInputProps } from './LicensesInput.types'

import * as SC from './styles'

export const LicensesInput = memo((props: LicensesInputProps) => {
  const { value, onChange, options } = props

  const { data: licenses } = useGetLicenseListByIds(value || [])

  const onDelete = useCallback(
    (index: number) => {
      const copy = [...value]
      copy.splice(index, 1)
      onChange(copy, undefined)
    },
    [value, onChange]
  )

  return (
    <div>
      <SC.Title>Assigned Licenses</SC.Title>
      <SC.SelectRow>
        <MultiSelectAutoSuggest
          options={options}
          value={value}
          valueTitle="No selected items"
          onChange={onChange}
          data-qa="Assigned Licenses"
        />
      </SC.SelectRow>
      {(value || []).map((licenseId, index) => (
        <SC.Row key={index}>
          <SC.DeleteIcon name={IconName.CLEAR_THIN} onClick={onDelete.bind(null, index)} size={12} />
          {licenses?.records.find((l) => l.id === licenseId)?.name} (
          {licenses?.records.find((l) => l.id === licenseId)?.license_number})
        </SC.Row>
      ))}
    </div>
  )
})
