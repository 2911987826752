export enum TableDataQa {
  BACK_OFFICE_CLIENT_REPORT_LIST = 'back-office-client-report-list',
  BACK_OFFICE_DOCUMENT_TEMPLATE_REPORT = 'back-office-document-template-report',
  BACK_OFFICE_RELATIONSHIPS_DUPLICATE_REPORT = 'back-office-relationships-duplicate-report',
  BACK_OFFICE_CLIENT_REPORT_ADDED_ITEMS = 'back-office-client-report-added-items',
  BACK_OFFICE_CLIENT_REPORT_CHANGED_ITEMS = 'back-office-client-report-changed-items',
  BACK_OFFICE_LICENSES_WITH_BANK_ACCOUNTS_REPORT_LIST = 'back-office-licenses-with-bank-accounts-report-list',
  MFA_DEVICES = 'mfa-devices',
  BACK_OFFICE_STATISTICS = 'back-office-statistics',
  CP_ONBOARDING_DOCUMENT_FORM = 'cp-onboarding-document-form',
  CP_ONBOARDING_SIGNATURE_DOCUMENT_FORM = 'cp-onboarding-signature-document-form',
  CP_ONBOARDING_DOCUMENTS = 'cp-onboarding-documents',
  CP_ONBOARDING_DOCUMENTS_FILES = 'cp-onboarding-documents-files',
  CP_APPLICATION_INVOICES_LIST = 'cp-application-invoices-list',
  CP_USER_COMPANIES_LIST = 'cp-users-companies-list',
  CP_CONNECTIONS_METRC_KEYS_UNMAPPED_LICENSES = 'cp-connections-metrc-keys-unmapped-licenses',
  CP_CONNECTIONS_POS_KEYS_UNMAPPED_LICENSES = 'cp-connections-pos-keys-unmapped-licenses',
  CP_CONNECTIONS_POS_KEYS_KEYS_LIST = 'cp-connections-pos-keys-keys-list',
  CP_CONNECTIONS_BIOTRACK_KEYS_KEYS_LIST = 'cp-connections-biotrack-keys-keys-list',
  CP_INTERNAL_TRANSFERS_HISTORY = 'cp-internal-transfers-history',
  CP_INTERNAL_TRANSFERS_LIST = 'cp-internal-transfers-list',
  ADVERSE_MEDIA_LIST = 'adverse-media-list',
  BP_ADVERSE_MEDIA_SEARCH_CASE_HISTORY = 'bp-adverse-media-search-case-history',
  BP_ALERTS_ADVERSE_MEDIA = 'bp-alerts-adverse-media',
  BP_ALERTS_ANNUAL_REVIEWS = 'bp-alerts-annual-reviews',
  BP_ALERTS_APPLICATIONS = 'bp-alerts-applications',
  BP_ALERTS_BSA_BATCHES = 'bp-alerts-bsa-batches',
  BP_ALERTS_CTRS = 'bp-alerts-ctrs',
  BP_ALERTS_QUARTERLY_ANALYTICS = 'bp-alerts-quarterly-analytics',
  BP_ALERTS_MONTHLY_ANALYTICS = 'bp-alerts-monthly-analytics',
  BP_ALERTS_SARS = 'bp-alerts-sars',
  BP_ALERTS_UPLOADS_PENDING_APPROVAL = 'bp-alerts-uploads-pending-approval',
  BP_ALERTS_UPLOADS = 'bp-alerts-uploads',
  BP_ANNUAL_REVIEW_DETAILS_SALES_SUMMARY = 'bp-annual-review-details-sales-summary',
  BP_ANNUAL_REVIEW_DETAILS_DEPOSIT_DETAIL = 'bp-annual-review-details-deposit-detail',
  BP_APPLICATION_LIST = 'bp-application-list',
  BP_BATCH_CTRS = 'bp-batch-ctrs',
  BP_BATCH_LIST = 'bp-batch-list',
  BP_BATCH_SARS = 'bp-batch-sars',
  BP_BATCH_SAR_LIST = 'bp-batch-sar-list',
  BP_BSA_REPORTING_BATCH_HISTORY = 'bp-bsa-reporting-batch-history',
  BP_BSA_REPORTING_CTR_HISTORY = 'bp-bsa-reporting-ctr-history',
  BP_BSA_REPORTING_SAR_HISTORY = 'bp-bsa-reporting-sar-history',
  BP_CTR_TRANSACTIONS = 'bp-ctr-transactions',
  BP_CLIENT_USERS_LIST = 'bp-client-users',
  BP_CLIENT_USERS_RELATIONSHIPS_AND_APPLICATIONS = 'bp-client-users-relationships-and-applications',
  BP_COMPANY_LIST = 'bp-company-list',
  BP_COMPANY_AFFILIATED_COMPANIES = 'bp-company-affiliated-companies',
  BP_COMPANY_ADVERSE_MEDIA_CASE = 'bp-company-adverse-media-case',
  BP_COMPANY_BANK_ACCOUNTS = 'bp-company-bank-accounts',
  BP_COMPANY_CONTACTS = 'bp-company-contacts',
  BP_COMPANY_CUSTOMERS = 'bp-company-customers',
  BP_COMPANY_DOCUMENTS = 'bp-company-documents',
  BP_COMPANY_HISTORY = 'bp-company-history',
  BP_COMPANY_LICENSES = 'bp-company-licenses',
  BP_COMPANY_VENDORS = 'bp-company-vendors',
  BP_COMPANY_ANNUAL_REVIEW = 'bp-company-annual-review',
  BP_COMPANY_QUARTERLY_ANALYTICS = 'bp-company-quarterly-analytics',
  BP_CONNECTIONS_LICENSES = 'bp-connections-licenses',
  BP_CONNECTIONS_HISTORY = 'bp-connections-history',
  BP_CONTACT_RELATED_COMPANIES = 'bp-contact-related-companies',
  BP_CONTACT_HISTORY = 'bp-contact-related-history',
  BP_CONTACTS_LIST = 'bp-contacts-list',
  BP_DOCUMENT_RELATED_COMPANIES = 'bp-document-related-companies',
  BP_DOCUMENTS_LIST = 'bp-documents-list',
  BP_DOCUMENTS_LIST_V2 = 'bp-documents-list-v2',
  BP_DOCUMENTS_LIST_V2_RELATIONSHIPS = 'bp-documents-list-v2-relationships',
  BP_DOCUMENT_HISTORY = 'bp-document-history',
  BP_DOCUMENTS_PERIOD_HISTORY = 'bp-documents-period-history',
  BP_DOCUMENT_DOCUMENTS_PERIODS = 'bp-document-documents-periods',
  BP_BANK_ACCOUNT_DOCUMENTS = 'bp-bank-account-documents',
  BP_LICENSE_DOCUMENTS = 'bp-license-documents',
  BP_LICENSE_HISTORY = 'bp-license-history',
  BP_LICENSE_INVOICES = 'bp-license-invoices',
  BP_DOCUMENT_PERIOD_FILES = 'bp-document-period-files',
  BP_ENTITY_HISTORY_LIST = 'bp-entity-history-list',
  BP_ADVERSE_MEDIA_SEARCH_TERM_RELATED_ENTITIES = 'bp-adverse-media-search-term-related-entities',
  BP_ANNUAL_REVIEW_LICENSING = 'bp-annual-review-licensing',
  BP_CTR_LIST = 'bp-ctr-list',
  BP_PARSED_CASH_TRANSACTIONS_LIST = 'bp-parsed-cash-transactions-list',
  BP_SAR_LIST = 'bp-sar-list',
  BP_CONTACT_DOCUMENTS = 'bp-contact-documents',
  BP_QUARTERLY_ANALYTICS_UPLOADED_FILES = 'bp-quarterly-analytics-uploaded-files',
  BP_QUARTERLY_ANALYTICS_MONTHLY_REPORTS_LIST = 'bp-quarterly-analytics-monthly-reports-list',
  BP_QUARTERLY_ANALYTICS_HISTORY = 'bp-quarterly-analytics-history',
  BP_REPORTS_CLIENT_USERS_ACTIVITY_REPORT = 'bp-reports-client-users-activity-report',
  BP_REPORTS_DOCUMENTS_REPORT = 'bp-reports-documents-report',
  BP_REPORTS_SICS_API_STATUS_REPORT = 'bp-reports-sics-api-status-report',
  BP_REPORTS_SITE_AUDIT_DOCUMENTS_REPORT = 'bp-reports-site-audit-documents-report',
  BP_REPORTS_USERS_REPORT = 'bp-reports-users-report',
  BP_REPORTS_EMAIL_HISTORY_REPORT = 'bp-reports-email-history-report',
  BP_REPORTS_ALERTS_REPORT = 'bp-reports-alerts-report',
  BP_REPORTS_ALERTS_REPORT_CLIENT_USERS = 'bp-reports-alerts-report-client-users',
  BP_REPORTS_RELATIONSHIPS_REPORT = 'bp-reports-relationships-report',
  BP_REPORTS_APPLICATION_PIPELINE_REPORT = 'bp-reports-application-pipeline-report',
  BP_INTERNAL_TRANSFERS_HISTORY = 'bp-internal-transfers-history',
  BP_INTERNAL_TRANSFERS_LIST = 'bp-internal-transfers-list',
  BP_INTERNAL_TRANSFERS_EXPORTS_LIST = 'bp-internal-transfers-exports-list',
  BP_INVOICE_TAX_REPORT = 'bp-invoice-tax-report',
  BP_USERS_LIST = 'bp-users-list',
  BP_MONTHLY_ANALYTICS_REPORTS = 'bp-monthly-analytics-reports',
  BP_MONTHLY_ANALYTICS_HISTORY = 'bp-monthly-analytics-history',
  BP_MONTHLY_ANALYTICS_BANK_ACCOUNT_SALES_SOURCE = 'bp-monthly-analytics-bank-account-sales-source',
  BP_MONTHLY_ANALYTICS_UPLOADED_FILES = 'bp-monthly-analytics-uploaded-files',
  BP_SALESDATA_SOURCE_DETAILS_UPLOADED_FILES = 'bp-salesdata-source-details-uploaded-files',
  BP_SALESDATA_SOURCE_DETAILS_METRC_SOURCE = 'bp-salesdata-source-details-metrc-source',
  BP_SALESDATA_SOURCE_DETAILS_HISTORY = 'bp-salesdata-source-details-history',
  BP_ONBOARDING_APPLICATION_DOCUMENTS_FILES = 'bp-onboarding-application-documents-files',
  BP_ONBOARDING_APPLICATION_DOCUMENTS_LIST = 'bp-onboarding-application-documents-list',
  BP_ONBOARDING_APPLICATION_INTERNAL_DOCUMENTS = 'bp-onboarding-application-internal-documents',
  BP_ONBOARDING_SETUPPING_DOCUMENTS_LIST = 'bp-onboarding-setupping-documents-list',
  BP_ONBOARDING_DOCUMENTS_HISTORY = 'bp-onboarding-documents-history',
  BP_ONBOARDING_DOCUMENTS_SIGNERS = 'bp-onboarding-documents-signers',
  ONBOARDING_FORM_DOCUMENTS_LIST = 'onboarding-form-documents-list',
  ONBOARDING_FORM_COMPANY_DOCUMENTS_LIST = 'onboarding-form-company-documents-list',
  FILES_SELECTOR_LIST = 'files-selector-list'
}
