import React from 'react'

import { Request } from 'commons/utils/request'
import { DownloadLink, Toast, UploadBatchPdfButton } from 'ui/components'

import { BatchPdfLinkProps } from './BatchPdfLink.types'

import { s } from './styles'

export const BatchPdfLink = (props: BatchPdfLinkProps) => {
  const { id, showDownloadBatchPDF, showUploadBatchPDF } = props

  if (showDownloadBatchPDF) {
    return (
      <DownloadLink
        direct
        parametersForm={{ id, file_type: 'ctrx' }}
        baseUrl={Request.urls.bank.batchFileDownload}
        name={showDownloadBatchPDF}
        handleError={(message) => Toast.error('Error', message)}
      >
        <s.LongFilename title={showDownloadBatchPDF}>{showDownloadBatchPDF}</s.LongFilename>
      </DownloadLink>
    )
  }

  if (showUploadBatchPDF) {
    return <UploadBatchPdfButton batchId={id} isAcknowledgments={false} />
  }

  return null
}
