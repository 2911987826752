import { useCallback } from 'react'

import { FormChangedHandler, useFormData } from 'brief-form'

import { FormPaths } from '../../enums'
import { useSyncFormData } from '../../hooks'
import { useValidateFormPersist } from '../../hooks/useValidateFormPersist'
import {
  LicenseAddressInformationChangeHanlder,
  LicenseAddressInformationFormData,
  LicenseAddressInformationFormValue
} from '../../types/forms'

export const useLicenseAddressForm = ({
  licenseIndex,
  companyIndex,
  formData,
  onChange
}: {
  licenseIndex: number
  companyIndex: number
  formData: LicenseAddressInformationFormData
  onChange: LicenseAddressInformationChangeHanlder
}) => {
  const onFormChanged: FormChangedHandler<LicenseAddressInformationFormValue> = useCallback(
    (formValues, formErrors) => onChange({ companyIndex, formData: { formValues, formErrors }, licenseIndex }),
    [companyIndex, onChange, licenseIndex]
  )

  const { config, Field, set, validate } = useFormData<LicenseAddressInformationFormValue>({
    onFormChanged,
    initialValue: formData.formValues,
    initialErrors: formData.formErrors
  })

  useSyncFormData({ set, formData })
  const formKey = `${FormPaths.LICENSE_ADDRESS_INFORMATION}-${companyIndex}-${licenseIndex}`

  useValidateFormPersist<LicenseAddressInformationFormValue>({
    formName: formKey,
    validate,
    config
  })

  return { Field, config }
}
