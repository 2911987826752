import React, { FC, Suspense, memo } from 'react'

import { RouterProvider } from '@tanstack/react-router'
import { BPRouterItems } from 'commons/types/enums'
import { Spinner, SpinnerSize } from 'ui/components/Spinner'

import { getBPAccessibleRoutingFlags } from './getBPAccessibleRoutingFlags'
import { getRouter } from './tanRouter'

export interface RoutesProps {
  accessibleRoutes?: BPRouterItems[]
}

export const AppRoutes: FC<RoutesProps> = memo((props) => {
  const { accessibleRoutes } = props

  if (!accessibleRoutes) {
    return <Spinner centered size={SpinnerSize.M} />
  }

  return (
    <Suspense fallback={<Spinner centered size={SpinnerSize.M} />}>
      <RouterProvider
        router={getRouter()}
        context={{
          accessibleRoutingFlags: getBPAccessibleRoutingFlags(accessibleRoutes)
        }}
      />
    </Suspense>
  )
})
