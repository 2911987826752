import * as VARS from 'ui/themes/variables'

import { ButtonFace } from '../ButtonFace'

export const getFocusBgColor = (face: ButtonFace) => {
  switch (face) {
    case 'neutral':
      return 'var(--theme-colors-global-grey-grey500)'
    case 'negative':
      return 'var(--theme-colors-theme-negative-darker)'
    case 'positive':
      return 'var(--theme-colors-theme-positive-darker)'
    default:
      return VARS.COLOR_NOT_FOUND
  }
}
