import { AnyRoute, createRoute, lazyRouteComponent } from '@tanstack/react-router'

export const ManageRoute = <T extends AnyRoute>(indexRoute: T) =>
  createRoute({
    getParentRoute: () => indexRoute,
    path: `$id/edit`,
    component: lazyRouteComponent(() => import('./ManageCTR')),
    validateSearch: (search: Record<string, string>) => {
      return {
        returnUrl: (search.returnUrl as string) || ''
      }
    }
  })
