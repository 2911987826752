import { Request } from 'commons/utils/request'

import { HistoryEntityType } from '../enums'

export const getDirectDownloadLinkByEntityType = (type: HistoryEntityType): string => {
  switch (type) {
    case HistoryEntityType.DOCUMENT_FILE:
      return Request.urls.bank.documentFileDirectDownload
    default:
      return ''
  }
}
