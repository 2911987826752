import React from 'react'

import { CheckBox, FF } from 'ui/components'

import { useSARFormContext } from '../../../../../../../context'

export const A = () => {
  const { formApi } = useSARFormContext()
  return (
    <formApi.Field
      name="transactions_info.identification.a"
      children={(field) => (
        <FF
          field={field}
          label="37. Identification/Documentation"
          input={(p) => <CheckBox {...p} checkBoxLabel="a. Changes spelling or arrangement of name" />}
          errorPosition="right"
        />
      )}
    />
  )
}
