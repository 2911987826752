import { FilterCompanyContactsWebPage, getCompanyContactsWebPage } from 'commons/service/bank/companyDetails'
import { CompanyContactsWebPage } from 'commons/types/DTO/bank/companyDetails/contact'
import { Keys, ReadHook, useStateManager } from 'state-manager'

export const useGetCompanyContactsWebPage: ReadHook<CompanyContactsWebPage, FilterCompanyContactsWebPage> = (opts) => {
  const sm = useStateManager()

  const { data, ...rest } = sm.use(Keys.CompanyDetailsContactsList.List(opts), () => getCompanyContactsWebPage(opts), {
    keepPreviousData: true
  })

  return {
    ...rest,
    data
  }
}
