import styled from 'styled-components'
import { Info } from 'ui/components/Info'

export const Columns = styled.div`
  display: flex;
  width: 680px;
  gap: 30px;
`

export const Column = styled.div`
  flex: 1;
`

export const IconInfo = styled(Info)`
  padding-bottom: 17px;
`

export const CompanyOwnersRow = styled.div`
  display: flex;
  word-break: break-word;

  > i {
    margin-left: 16px;
    cursor: pointer;
  }
`
