import React, { FC } from 'react'

import { Form } from 'brief-form'
import { ApplicationRelationshipLicense } from 'commons/types/DTO/bank/applications'
import { ChoiceOption } from 'commons/types/DTO/commons'
import { DatePicker, MultiSelectAutoSuggest, Validators } from 'ui/components'
import { bankAccountNumber } from 'ui/components/Form/validators/bankAccountNumber'
import { TextInput } from 'ui/components/InputV2'
import { Col, FormRow } from 'ui/themes/globalStyles'

import selectMapper from '../../helpers/selectMapper'
import { m } from '../../messages'
import { ConnectBankAccountToLicensesFormFieldNames, ConnectBankAccountToLicensesFormState } from '../../types'
import { Chip } from '../Chip'
import { useConnectBankAccountToLicensesForm } from './useConnectBankAccountToLicensesForm'

type ConnectBankAccountToLicensesFormProps = {
  formState: ConnectBankAccountToLicensesFormState
}

export const ConnectBankAccountToLicensesForm: FC<ConnectBankAccountToLicensesFormProps> = (props) => {
  const { formState } = props
  const { assignedLicensesSelectData, licenses } = formState
  const { formData, onRemoveLicense } = useConnectBankAccountToLicensesForm({ formState })
  const { Field, config } = formData
  const selectedLicenseList = config.value.assignedLicenses
  const showSelectedLicenseList = !!selectedLicenseList

  // multi-select edge case
  const selectedAssignedLicenses: ChoiceOption[] = (
    config.value.assignedLicenses.map((licenseId) =>
      licenses.find((licenseItem) => licenseItem.id === licenseId || licenseItem.entityUID === licenseId)
    ) as unknown as ApplicationRelationshipLicense[]
  )
    .filter(Boolean)
    .map(selectMapper)
  const selectData = [...assignedLicensesSelectData, ...selectedAssignedLicenses]

  const assignedLicensesValidator = Validators.required.array()
  return (
    <Form config={config} disabled={formState.isDisabled}>
      <FormRow>
        <Col>
          <Field
            required
            name={ConnectBankAccountToLicensesFormFieldNames.ACCOUNT_NUMBER}
            label={m['ConnectBankAccountToLicensesForm.accountNumber']}
            input={TextInput}
            inputProps={{
              autoFocus: true,
              'data-qa': m['ConnectBankAccountToLicensesForm.accountNumber'],
              className: formState.formName
            }}
            validator={bankAccountNumber.notEmptyAndValid}
          />
        </Col>
        <Col>
          <Field
            name={ConnectBankAccountToLicensesFormFieldNames.OPENING_DATE}
            label={m['ConnectBankAccountToLicensesForm.openingDate']}
            input={DatePicker}
            required
            validator={Validators.required.field}
            inputProps={{
              'data-qa': m['ConnectBankAccountToLicensesForm.openingDate']
            }}
          />
        </Col>
      </FormRow>
      <FormRow>
        <Field
          name={ConnectBankAccountToLicensesFormFieldNames.ASSIGNED_LICENSES}
          label={m['ConnectBankAccountToLicensesForm.assignedLicenses']}
          input={MultiSelectAutoSuggest}
          required
          validator={assignedLicensesValidator}
          inputProps={{
            'data-qa': m['ConnectBankAccountToLicensesForm.assignedLicenses'],
            options: selectData,
            valueTitle: m['ConnectBankAccountToLicensesForm.assignedLicensesPlaceholder']
          }}
        />
      </FormRow>
      {showSelectedLicenseList && (
        <FormRow>
          {selectedLicenseList.map((licenseSelectItem, index) => {
            const license = licenses.find(
              (item) => item.id === licenseSelectItem || item.entityUID === licenseSelectItem
            )
            return (
              <Chip key={index} label={String(license?.name)} onDelete={() => onRemoveLicense(licenseSelectItem)} />
            )
          })}
        </FormRow>
      )}
    </Form>
  )
}
