import { ApplicationBankAccountsFormDataList } from '../../../types'

export const makeExpandedList = ({
  formList
}: {
  formList: ApplicationBankAccountsFormDataList
}): Record<string, boolean> => {
  const formExpandedList = {}
  Object.keys(formList).forEach((key) => (formExpandedList[key] = false))
  return formExpandedList
}
