import { modelCurrencyCentToView } from 'commons/utils/currencyCent'
import numeral from 'numeral'

export const formatCurrency = (value: string | number, withDecimal = true): string => {
  const format = withDecimal ? '$0,0.00' : '$0,0'
  return numeral(value).format(format)
}

export const formatCurrencyCent = (value: string | number, withDecimal = true): string => {
  const v = modelCurrencyCentToView(value)
  return formatCurrency(v, withDecimal)
}
