import React from 'react'

import { usStates } from 'commons/types/dictionaries'
import { FF, Select } from 'ui/components'

import { useSARFormContext } from '../../../../../../../../context'
import { useSubjectInformationItemContext } from '../../../SubjectInformationItemContext'

const EMPTY_OPTION = { label: 'Not selected', value: '' }

type StateProps = {
  index: number
}

export const State = ({ index }: StateProps) => {
  const { formApi } = useSARFormContext()
  const { isAllInfoUnavailable } = useSubjectInformationItemContext()
  const country = formApi.useStore((s) => s.values.subjects_info[index].address_info[0].country)

  return (
    <formApi.Field
      name={`subjects_info[${index}].address_info[0].state` as 'subjects_info[0].address_info[0].state'}
      validators={{
        onChange: ({ value }) =>
          !!value || isAllInfoUnavailable || country !== 'US'
            ? undefined
            : '"13. State" required when choose "United States of America" as "15. Country"'
      }}
      children={(field) => (
        <FF
          required={country === 'US' && !isAllInfoUnavailable}
          field={field}
          label="13. State"
          input={(p) => (
            <Select
              {...p}
              data={usStates}
              emptyOption={EMPTY_OPTION}
              disabled={isAllInfoUnavailable}
              data-qa="13. State"
              options-data-qa="13. State option"
            />
          )}
        />
      )}
    />
  )
}
