import styled from 'styled-components'
import SC from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 20000;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #fff;
`

export const ErrorBox = SC.div`
  color: ${(p) => p.theme.uiKit.colors.theme.negative.main};
`
