import styled from 'styled-components'

export const FormWrapper = styled.div`
  padding: 20px 30px 30px 30px;
`

export const Row = styled.div`
  display: flex;
  align-items: flex-end;

  > * {
    margin-right: 10px;
    width: 160px;
  }
`
