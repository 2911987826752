import { OnboardingFormState } from '../../../types/state'
import { setNewCompanies } from './setNewCompanies'

export const getStateAfterContactRemoved = ({
  currentState,
  contactIndex,
  companyIndex
}: {
  currentState: OnboardingFormState
  contactIndex: number
  companyIndex: number
}) => {
  const selectedCompany = currentState.formValue.companiesFormValues[companyIndex]
  const oldContacts = selectedCompany.contacts

  const newContacts = oldContacts.filter((_, index) => index !== contactIndex)
  const newCompany = { ...selectedCompany, contacts: newContacts }
  const newCompaniesFormValues = setNewCompanies({
    companyIndex,
    oldCompanies: currentState.formValue.companiesFormValues,
    newCompany
  })

  return {
    selectedCompany: newCompany,
    formValue: {
      ...currentState.formValue,
      companiesFormValues: newCompaniesFormValues
    }
  }
}
