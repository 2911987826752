import React, { FC } from 'react'

import { getRouteApi } from '@tanstack/react-router'
import { LicensesPath } from 'commons/constants/routes'
import { DateTime, Hr } from 'ui/components'
import { Info, InfoSet } from 'ui/components/Info'
import { Link } from 'ui/components/Link'
import { PageSection, PageSectionFace } from 'ui/components/Page'
import { Col, Row } from 'ui/themes/globalStyles'
import { DocumentActions } from '~bank-documents/components/DocumentActions'
import { DocumentPeriodStatusChip } from '~bank-documents/components/DocumentPeriodStatusChip'
import { DocumentsPaths } from '~bank-documents/constants'
import { DocumentPeriodDetailsResponse } from '~bank-documents/types/periodDetails'

export const PeriodInformation: FC = () => {
  const routeApi = getRouteApi(DocumentsPaths.PERIOD_DETAILS)
  const periodId = routeApi.useParams({ select: (s) => Number(s.periodId) })
  const data = routeApi.useLoaderData<DocumentPeriodDetailsResponse>()

  const {
    documentId,
    documentName,
    expirationDate,
    showDeliveredAt,
    startDate,
    endDate,
    showLicenseName,
    showLicenseId,
    updatedAt,
    status,
    notes,
    showCanAccept,
    showCanIncomplete,
    showCanReject
  } = data

  const leftColInfo: React.JSX.Element[] = [
    <Info label="Document Name">
      <Link to={`${DocumentsPaths.DETAILS}`} params={{ documentId }}>
        {documentName}
      </Link>
    </Info>,
    showDeliveredAt ? <Info label="Delivered at">{showDeliveredAt}</Info> : null,
    <Info label="Start Date">{startDate}</Info>,
    <Info label="End Date">{endDate}</Info>,
    <Info label="Status">
      <DocumentPeriodStatusChip name={status} />
    </Info>
  ].filter(Boolean)

  const rightColInfo = [
    showLicenseName ? (
      <Info label="License Name">
        {showLicenseName && (
          <Link to={LicensesPath.DETAILS} params={{ licenseId: showLicenseId }}>
            {showLicenseName}
          </Link>
        )}
      </Info>
    ) : null,
    <Info label="Expiration Date">{expirationDate}</Info>,
    <Info label="Last Modified Date">
      <DateTime date={updatedAt} withTime />
    </Info>
  ].filter(Boolean)

  const arrayLength = leftColInfo.length > rightColInfo.length ? leftColInfo.length : rightColInfo.length

  return (
    <PageSection
      face={PageSectionFace.SECONDARY}
      title="Period Information"
      actions={
        <DocumentActions
          id={periodId}
          flags={{ showCanAccept, showCanIncomplete, showCanReject, showNotes: notes }}
          editButtonParams={{ to: DocumentsPaths.EDIT_PERIOD_DETAILS, params: { documentId, periodId } }}
        />
      }
    >
      <Hr />

      <InfoSet>
        {new Array(arrayLength).fill(null).map((_, index) => (
          <Row key={'row' + index}>
            <Col>{leftColInfo[index]}</Col>

            <Col>{rightColInfo[index]}</Col>
          </Row>
        ))}

        <Info label="Notes (visible for client)">{notes || null}</Info>
      </InfoSet>
      <Hr />
    </PageSection>
  )
}
