import { AnnualReviewSalesDeposits } from 'commons/types/DTO/bank/annualReviewSalesDeposits'

import { SalesAndDepositFormValue } from './SalesAndDepositFormValue'

export const generateSubmitModel = (
  formValue: SalesAndDepositFormValue,
  salesAndDeposits: AnnualReviewSalesDeposits
) => ({
  ...salesAndDeposits,
  status: formValue.status ? formValue.status : salesAndDeposits.status,
  total_financials: formValue.total_financials
    ? parseFloat(formValue.total_financials)
    : salesAndDeposits.total_financials,
  quarter1_info: {
    label: formValue['quarter1_info.label'] ? formValue['quarter1_info.label'] : salesAndDeposits.quarter1_info?.label,
    deposits: formValue['quarter1_info.deposits']
      ? parseFloat(formValue['quarter1_info.deposits'])
      : salesAndDeposits.quarter1_info?.deposits,
    sales: formValue['quarter1_info.sales']
      ? parseFloat(formValue['quarter1_info.sales'])
      : salesAndDeposits.quarter1_info?.sales,
    invoices: formValue['quarter1_info.invoices']
      ? parseFloat(formValue['quarter1_info.invoices'])
      : salesAndDeposits.quarter1_info?.invoices,
    checks: formValue['quarter1_info.checks']
      ? parseFloat(formValue['quarter1_info.checks'])
      : salesAndDeposits.quarter1_info?.checks,
    other: formValue['quarter1_info.other']
      ? parseFloat(formValue['quarter1_info.other'])
      : salesAndDeposits.quarter1_info?.other,
    cash: formValue['quarter1_info.cash']
      ? parseFloat(formValue['quarter1_info.cash'])
      : salesAndDeposits.quarter1_info?.cash,
    transfers: formValue['quarter1_info.transfers']
      ? parseFloat(formValue['quarter1_info.transfers'])
      : salesAndDeposits.quarter1_info?.transfers,
    credit_debit: formValue['quarter1_info.credit_debit']
      ? parseFloat(formValue['quarter1_info.credit_debit'])
      : salesAndDeposits.quarter1_info?.credit_debit
  },
  quarter2_info: {
    label: formValue['quarter2_info.label'] ? formValue['quarter2_info.label'] : salesAndDeposits.quarter2_info?.label,
    deposits: formValue['quarter2_info.deposits']
      ? parseFloat(formValue['quarter2_info.deposits'])
      : salesAndDeposits.quarter2_info?.deposits,
    sales: formValue['quarter2_info.sales']
      ? parseFloat(formValue['quarter2_info.sales'])
      : salesAndDeposits.quarter2_info?.sales,
    invoices: formValue['quarter2_info.invoices']
      ? parseFloat(formValue['quarter2_info.invoices'])
      : salesAndDeposits.quarter2_info?.invoices,
    checks: formValue['quarter2_info.checks']
      ? parseFloat(formValue['quarter2_info.checks'])
      : salesAndDeposits.quarter2_info?.checks,
    other: formValue['quarter2_info.other']
      ? parseFloat(formValue['quarter2_info.other'])
      : salesAndDeposits.quarter2_info?.other,
    cash: formValue['quarter2_info.cash']
      ? parseFloat(formValue['quarter2_info.cash'])
      : salesAndDeposits.quarter2_info?.cash,
    transfers: formValue['quarter2_info.transfers']
      ? parseFloat(formValue['quarter2_info.transfers'])
      : salesAndDeposits.quarter2_info?.transfers,
    credit_debit: formValue['quarter2_info.credit_debit']
      ? parseFloat(formValue['quarter2_info.credit_debit'])
      : salesAndDeposits.quarter2_info?.credit_debit
  },
  quarter3_info: {
    label: formValue['quarter3_info.label'] ? formValue['quarter3_info.label'] : salesAndDeposits.quarter3_info?.label,
    deposits: formValue['quarter3_info.deposits']
      ? parseFloat(formValue['quarter3_info.deposits'])
      : salesAndDeposits.quarter3_info?.deposits,
    sales: formValue['quarter3_info.sales']
      ? parseFloat(formValue['quarter3_info.sales'])
      : salesAndDeposits.quarter3_info?.sales,
    invoices: formValue['quarter3_info.invoices']
      ? parseFloat(formValue['quarter3_info.invoices'])
      : salesAndDeposits.quarter3_info?.invoices,
    checks: formValue['quarter3_info.checks']
      ? parseFloat(formValue['quarter3_info.checks'])
      : salesAndDeposits.quarter3_info?.checks,
    other: formValue['quarter3_info.other']
      ? parseFloat(formValue['quarter3_info.other'])
      : salesAndDeposits.quarter3_info?.other,
    cash: formValue['quarter3_info.cash']
      ? parseFloat(formValue['quarter3_info.cash'])
      : salesAndDeposits.quarter3_info?.cash,
    transfers: formValue['quarter3_info.transfers']
      ? parseFloat(formValue['quarter3_info.transfers'])
      : salesAndDeposits.quarter3_info?.transfers,
    credit_debit: formValue['quarter3_info.credit_debit']
      ? parseFloat(formValue['quarter3_info.credit_debit'])
      : salesAndDeposits.quarter3_info?.credit_debit
  },
  quarter4_info: {
    label: formValue['quarter4_info.label'] ? formValue['quarter4_info.label'] : salesAndDeposits.quarter4_info?.label,
    deposits: formValue['quarter4_info.deposits']
      ? parseFloat(formValue['quarter4_info.deposits'])
      : salesAndDeposits.quarter4_info?.deposits,
    sales: formValue['quarter4_info.sales']
      ? parseFloat(formValue['quarter4_info.sales'])
      : salesAndDeposits.quarter4_info?.sales,
    invoices: formValue['quarter4_info.invoices']
      ? parseFloat(formValue['quarter4_info.invoices'])
      : salesAndDeposits.quarter4_info?.invoices,
    checks: formValue['quarter4_info.checks']
      ? parseFloat(formValue['quarter4_info.checks'])
      : salesAndDeposits.quarter4_info?.checks,
    other: formValue['quarter4_info.other']
      ? parseFloat(formValue['quarter4_info.other'])
      : salesAndDeposits.quarter4_info?.other,
    cash: formValue['quarter4_info.cash']
      ? parseFloat(formValue['quarter4_info.cash'])
      : salesAndDeposits.quarter4_info?.cash,
    transfers: formValue['quarter4_info.transfers']
      ? parseFloat(formValue['quarter4_info.transfers'])
      : salesAndDeposits.quarter4_info?.transfers,
    credit_debit: formValue['quarter4_info.credit_debit']
      ? parseFloat(formValue['quarter4_info.credit_debit'])
      : salesAndDeposits.quarter4_info?.credit_debit
  }
})
