import React from 'react'

import { bsaSARStatuses } from 'commons/types/dictionaries'
import { FF, Select, Validators } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

export const Status = () => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name="status"
      validators={{ onChange: ({ value }) => Validators.required.field(value) }}
      children={(field) => (
        <FF
          required
          field={field}
          label="Status"
          input={(p) => (
            <Select {...p} data={bsaSARStatuses} data-qa="General. Status" options-data-qa="General. Status option" />
          )}
        />
      )}
    />
  )
}
