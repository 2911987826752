import { CSSProperties } from 'react'

import { FormInputProps } from 'brief-form'

export type UserMultiSelectOpts = {
  filters?: { field: string; type: string; value: string | number }[]
  containerStyle?: CSSProperties
  options: { id: string; fullName: string }[]
}

export interface UserMultiSelectProps extends FormInputProps<string[], UserMultiSelectOpts> {}
