import React, { FC } from 'react'

import { YesNoRadioGroup } from 'commons/types/enums'
import { Info, InfoSet } from 'ui/components/Info'
import { RadioGroup } from 'ui/components/RadioGroup'
import { Col2x, Row } from 'ui/themes/globalStyles'

import { MerchantQuestionnaireDetailsProps } from './MerchantQuestionnaireDetailsProps'

export const MerchantQuestionnaireDetails: FC<MerchantQuestionnaireDetailsProps> = ({
  value: {
    legacyCash,
    merchantProcessing,
    merchantProcessingDesc,
    primaryMethodToFund,
    cryptocurrencyTransactions,
    realTimePaymentServices
  },
  state: { isPrimaryMethodToFundEnable, isCryptocurrencyTransactionsEnable, isRealTimePaymentServicesEnable }
}) => (
  <InfoSet>
    <Row>
      <Col2x>
        <Info label="Does the organization anticipate depositing any legacy cash?">
          <RadioGroup
            readonly
            value={legacyCash}
            data={[
              { value: YesNoRadioGroup.YES, label: 'Yes' },
              { value: YesNoRadioGroup.NO, label: 'No' }
            ]}
          />
        </Info>
      </Col2x>
    </Row>
    <Row>
      <Col2x>
        <Info label="Does the company have any merchant processing (Credit Cards, Debit Cards, etc.)?">
          <RadioGroup
            readonly
            value={merchantProcessing}
            data={[
              { value: YesNoRadioGroup.YES, label: 'Yes' },
              { value: YesNoRadioGroup.NO, label: 'No' }
            ]}
          />
        </Info>
      </Col2x>
    </Row>
    {merchantProcessing === YesNoRadioGroup.YES && (
      <Row>
        <Col2x>
          <Info label="Please list all merchant processors currently in use by the business.">
            {merchantProcessingDesc}
          </Info>
        </Col2x>
      </Row>
    )}
    {isPrimaryMethodToFundEnable && (
      <Row>
        <Col2x>
          <Info
            label="What is the primary method used to fund the initial deposit on this account? 
            (Cash, wire transfer, check)"
          >
            <RadioGroup
              readonly
              value={primaryMethodToFund}
              data={[
                { value: YesNoRadioGroup.YES, label: 'Yes' },
                { value: YesNoRadioGroup.NO, label: 'No' }
              ]}
            />
          </Info>
        </Col2x>
      </Row>
    )}
    {isCryptocurrencyTransactionsEnable && (
      <Row>
        <Col2x>
          <Info label="Does the business exchange or invest in or conduct transactions with cryptocurrency?">
            <RadioGroup
              readonly
              value={cryptocurrencyTransactions}
              data={[
                { value: YesNoRadioGroup.YES, label: 'Yes' },
                { value: YesNoRadioGroup.NO, label: 'No' }
              ]}
            />
          </Info>
        </Col2x>
      </Row>
    )}
    {isRealTimePaymentServicesEnable && (
      <Row>
        <Col2x>
          <Info
            label="Does the business anticipate using real-time payment services 
          (ex: PayPal, Venmo, Google Wallet, etc.)?"
          >
            <RadioGroup
              readonly
              value={realTimePaymentServices}
              data={[
                { value: YesNoRadioGroup.YES, label: 'Yes' },
                { value: YesNoRadioGroup.NO, label: 'No' }
              ]}
            />
          </Info>
        </Col2x>
      </Row>
    )}
  </InfoSet>
)
