import { useMutation, useQueryClient } from 'react-query'

import { PortalName } from 'commons/types/enums'
import { deleteDevice } from '~auth/service/mfa'

export const useDeleteDevice = (p: PortalName) => {
  const client = useQueryClient()

  return useMutation((data: { id: number }) => deleteDevice(p, data.id), {
    onError: (error: Error) => {
      throw error
    },
    onSuccess: async () => {
      await client.invalidateQueries(['tables', 'mfa-devices-table'])
    }
  })
}
