import React from 'react'

import { Panel } from 'ui/components'

import { CompanyContainerProps } from './CompanyContaines.types'

import { s } from './styles'

export const CompanyContainer = ({ number, totalCount, companyName, children, anchor }: CompanyContainerProps) => (
  <Panel
    title={`Company (${number}/${totalCount}) — ${companyName}`}
    titleTagAs="h4"
    isArrowButton={true}
    anchor={anchor}
  >
    <s.CompanyBox>{children}</s.CompanyBox>
  </Panel>
)
