import SC from 'styled-components'
import { Icon } from 'ui/components/Icon'

export const CustomerName = SC.span`
  display: flex;
  align-items: center;
`

export const WrappedIcon = SC(Icon)`
  margin-left: 10px;
`
