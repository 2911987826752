import React, { FC, memo } from 'react'

import {
  CurrencyCentInput,
  DatePicker,
  FF,
  FieldSet,
  Info,
  LeaveConfirmation,
  Panel,
  TextInput,
  TextareaInput,
  Validators
} from 'ui/components'
import { Page, PageFace } from 'ui/components/Page'
import { Col, Col2x, FormRow } from 'ui/themes/globalStyles'

import { ManageInvoicePageProps } from './ManageInvoicePage.types'
import { Footer } from './components/Footer'

import { s } from './styles'

export const ManageInvoicePage: FC<ManageInvoicePageProps> = memo(
  ({ formApi, appearanceInfo, panelTitle, pageTitle, onDelete, onCancel }) => (
    <Page
      face={PageFace.SECONDARY}
      title="Invoice Submittal"
      subTitle={pageTitle}
      footer={
        <formApi.Subscribe
          children={({ isSubmitting, isDirty, isFieldsValid }) => (
            <Footer
              isSubmitting={isSubmitting}
              disabled={isDirty && !isFieldsValid}
              onDelete={onDelete}
              onCancel={onCancel}
              onSubmit={formApi.handleSubmit}
            />
          )}
        />
      }
    >
      <Panel rounded shadowed collapsible={false} title={panelTitle}>
        <s.Box>
          <LeaveConfirmation preventLeaving={formApi.useStore((state) => state.isDirty && !state.isSubmitting)}>
            <FieldSet legend="Invoice Submittal Details">
              <FormRow>
                <Col>
                  <Info label="License" data-qa="License Name">
                    {appearanceInfo.licenseName || '---'}
                  </Info>
                </Col>
              </FormRow>
              <FormRow>
                <Col>
                  <formApi.Field
                    name="amount"
                    validators={{
                      onSubmitAsync: ({ value }) =>
                        Validators.required.fieldWithCustomMessage('Amount is required')(value)
                    }}
                    children={(amountField) => (
                      <FF
                        field={amountField}
                        label="Amount"
                        input={(p) => <CurrencyCentInput required data-qa="Amount" {...p} />}
                      />
                    )}
                  />
                </Col>
                <Col>
                  <formApi.Field
                    name="date"
                    validators={{
                      onSubmit: ({ value }) => Validators.required.fieldWithCustomMessage('Date is required')(value)
                    }}
                    children={(dateField) => (
                      <FF field={dateField} label="Date" required input={(p) => <DatePicker {...p} />} />
                    )}
                  />
                </Col>
              </FormRow>
              <FormRow>
                <Col>
                  <formApi.Field
                    name="manifestNumber"
                    children={(manifestNumberField) => (
                      <FF
                        field={manifestNumberField}
                        label="Manifest Number (If Applicable)"
                        input={(p) => <TextInput data-qa="Manifest Number" {...p} />}
                      />
                    )}
                  />
                </Col>
              </FormRow>
              <FormRow>
                <Col2x>
                  <formApi.Field
                    name="notes"
                    children={(notesField) => (
                      <FF field={notesField} label="Notes" input={(p) => <TextareaInput data-qa="Notes" {...p} />} />
                    )}
                  />
                </Col2x>
              </FormRow>
            </FieldSet>
          </LeaveConfirmation>
        </s.Box>
      </Panel>
    </Page>
  )
)
