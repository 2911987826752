import React, { FC } from 'react'

import { CompaniesPath } from 'commons/constants/routes'
import { TableDataQa } from 'commons/types/enums'
import { Dialog, DialogFace } from 'ui/components/Dialog'
import { Link } from 'ui/components/Link'
import { Table, TableColumnAlign } from 'ui/components/Table'

import * as SC from './styles'

type RelationshipsDialogProps = {
  relationships: Array<{ id: number; name: string }>
  onClose: () => void
}

export const RelationshipsDialog: FC<RelationshipsDialogProps> = ({ relationships, onClose }) => {
  return (
    <Dialog id="related-company-list" title="Related Companies" face={DialogFace.PRIMARY} onClose={onClose}>
      <SC.DialogContent>
        <Table<{ id: number; name: string }>
          dataQa={TableDataQa.BP_DOCUMENTS_LIST_V2_RELATIONSHIPS}
          columns={[
            {
              align: TableColumnAlign.LEFT,
              title: 'Relationship',
              render: (item) => (
                <Link
                  to={CompaniesPath.DETAILS}
                  params={{
                    companyId: item.id
                  }}
                >
                  {item.name}
                </Link>
              )
            }
          ]}
          dataSource={relationships.map((item) => ({
            item: item
          }))}
        />
      </SC.DialogContent>
    </Dialog>
  )
}
