import { createRoute, redirect } from '@tanstack/react-router'

import { BankNextCloudWrapper } from './BankNextCloudWrapper'

export const routeNextCloudTree = (rootRoute: BPRootRoute) =>
  createRoute({
    beforeLoad: async ({ context }) => {
      if (!context.accessibleRoutingFlags.nextCloudRootAccess) {
        throw redirect({
          to: '/'
        })
      }
    },
    getParentRoute: () => rootRoute,
    path: '/nextcloud',
    component: BankNextCloudWrapper
  })
