/* eslint-disable max-len */
import React, { useEffect } from 'react'

import { FieldApi } from '@tanstack/react-form'
import { BsaSar } from 'commons/types/DTO/bank/bsa'
import { usAndTerritories } from 'commons/types/dictionaries'
import { FF, Select, Validators } from 'ui/components'

import { useSARFormContext } from '../../../../../../../context'

const CountryRenderer = ({
  field,
  index,
  noBranchInvolved
}: {
  field: FieldApi<BsaSar, 'institution_activities_info[0].institution_activity_branches_info[0].country'>
  index: number
  noBranchInvolved: boolean
}) => {
  const { formApi } = useSARFormContext()

  useEffect(() => {
    if (typeof field.state.value !== 'undefined' && field.state.value !== 'US') {
      const stateFieldName =
        `institution_activities_info[${index}].institution_activity_branches_info[0].state` as 'institution_activities_info[0].institution_activity_branches_info[0].state'
      const stateMeta = formApi.getFieldMeta(stateFieldName)
      formApi.setFieldValue(stateFieldName, '', { dontUpdateMeta: true })
      formApi.setFieldMeta(stateFieldName, { ...stateMeta, errors: [], errorMap: {} })
    }
  }, [field.state.value, formApi])

  return (
    <FF
      required={!noBranchInvolved}
      label="86. Country"
      field={field}
      input={(p) => (
        <Select
          {...p}
          data={usAndTerritories}
          data-qa="74. Country"
          options-data-qa="74. Country option"
          disabled={noBranchInvolved}
        />
      )}
    />
  )
}

export const Country = ({ index1, index2 }: { index1: number; index2: number }) => {
  const { formApi } = useSARFormContext()
  const noBranchInvolved = formApi.useStore((s) => s.values.institution_activities_info[index1].no_branch_involved)

  return (
    <formApi.Field
      name={
        `institution_activities_info[${index1}].institution_activity_branches_info[${index2}].country` as 'institution_activities_info[0].institution_activity_branches_info[0].country'
      }
      validators={{
        onChange: ({ value }) => (noBranchInvolved ? undefined : Validators.required.field(value))
      }}
      children={(field) => <CountryRenderer field={field} index={index1} noBranchInvolved={noBranchInvolved} />}
    />
  )
}
