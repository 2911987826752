import styled from 'styled-components'

const GeneralInformationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 50px 40px 50px;
`

const TitleWrapper = styled.div`
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Row = styled.div`
  display: flex;

  > * {
    width: 400px;

    &:first-child {
      margin-right: 100px;
    }
  }
`

export const s = { GeneralInformationWrapper, TitleWrapper, Row }
