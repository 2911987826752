export interface LicenseInfoProps {
  companyId?: number
  companyName?: string
  licenseId?: number
  licenseName?: string
  licenseType?: string
  companyDetailsPath?: string
  licenseDetailsPath?: string
  hideLicenseDetailsUrl?: boolean
}
