import { getLicenseList } from 'commons/service/bank/license'
import { LicenseDeprecated } from 'commons/types/DTO/bank'
import { ApiReadResponse, ServerOptions } from 'commons/types/api'
import { Keys, ReadHook, useStateManager } from 'state-manager'

export type UseGetLicenseListParams = {
  opts: ServerOptions
  disabled?: boolean
}

export const useGetLicenseList: ReadHook<ApiReadResponse<LicenseDeprecated>, UseGetLicenseListParams> = (opts) => {
  const sm = useStateManager()

  return sm.use(Keys.License.List(opts.opts), () => getLicenseList(opts.opts), {
    keepPreviousData: true,
    enabled: !opts.disabled
  })
}
