import styled from 'styled-components'
import { Page } from 'ui/components/Page'

export const ApplicationPage = styled(Page)<{ $active: boolean }>`
  > header {
    background: ${(p) => (p.$active ? '#00C698' : '#868686')};
  }

  #orbit-page {
    padding: 0;
  }
`

export const PageWrapper = styled.div`
  padding: 20px 50px 34px 50px;
`
