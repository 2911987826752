import React from 'react'

import { CurrencyInput, FF } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

export const CurrentAmount = () => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name="transactions_info.current_amount"
      children={(field) => <FF field={field} input={(p) => <CurrencyInput {...p} readOnly />} />}
    />
  )
}
