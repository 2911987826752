import React, { FC, KeyboardEvent, memo, useCallback, useState } from 'react'

import { ChoiceValue } from 'ui/types'

import { CheckBox } from '../CheckBox'
import { CheckBoxGroupProps } from './CheckBoxGroupProps'

import * as SC from './styles'

/* eslint-disable import/no-unused-modules */
export const CheckBoxGroup: FC<CheckBoxGroupProps> = memo((props) => {
  const {
    data,
    value,
    onChange,
    required,

    disabled = false,
    hint,
    readOnly = false,
    error
  } = props

  const [selectedValues, setSelectedValues] = useState<unknown[]>(value || [])

  if (!data) {
    throw new Error('Data property should be provided.')
  }

  // Readonly is always disabled
  const isDisabled = readOnly ? true : disabled ? true : false

  const handleChange = useCallback(
    (value: ChoiceValue) => {
      let newArr = []
      if (selectedValues.includes(value)) {
        newArr = selectedValues.filter((el) => el !== value)
      } else {
        newArr = [...selectedValues]
        newArr.push(value)
      }
      setSelectedValues(newArr)
      onChange(newArr)
    },
    [selectedValues, onChange]
  )

  return (
    <>
      <SC.CheckBoxGroupContainer>
        {data.map((item, index) => (
          <div
            key={index}
            onClick={() => {
              handleChange(item.value)
            }}
            onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
              if (event.key == 'Enter') {
                handleChange(item.value)
              }
            }}
          >
            <CheckBox
              checkBoxLabel={item.label}
              value={selectedValues.includes(item.value)}
              disabled={isDisabled}
              error={error}
              required={required}
            />
          </div>
        ))}
      </SC.CheckBoxGroupContainer>
      {hint && (
        <SC.Hint data-testid="hint" disabled={disabled}>
          {hint}
        </SC.Hint>
      )}
    </>
  )
})
