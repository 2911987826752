import React from 'react'

import { DocumentsPaths } from 'bank-documents/src/constants'
import { DOCUMENTS_PATH } from 'commons/constants/routes'
import { LicenseWebPageDocumentItem } from 'commons/types/DTO/bank/license'
import { CheckMark } from 'ui/components/CheckMark'
import { DateTime } from 'ui/components/DateTime'
import { IconName } from 'ui/components/Icon'
import { Link } from 'ui/components/Link'
import { MenuIconHoverFace, MenuItemBehavior, MenuItemTextFace, TableActions } from 'ui/components/QueryTable'
import { TableColumn } from 'ui/components/Table'

import { DueTooltip } from './components/DueTooltip'

export const useColumns = (
  deleteDocument: (id: number, name: string) => void,
  returnUrl: string
): TableColumn<LicenseWebPageDocumentItem>[] => [
  {
    sortable: true,
    key: 'name',
    title: 'Document Name',
    render: ({ id, name }) => (
      <Link to={DocumentsPaths.DETAILS} params={{ documentId: id }}>
        {name}
      </Link>
    )
  },
  {
    sortable: true,
    key: 'internal',
    title: 'Internal',
    render: ({ internal }) => (internal ? <CheckMark value rounded /> : null)
  },
  {
    sortable: true,
    key: 'frequency',
    title: 'Frequency'
  },
  {
    sortable: true,
    key: 'expirationDate',
    title: 'Expiration Date',
    render: ({ expirationDate }) => <DateTime date={expirationDate} />
  },
  {
    align: 'left',
    title: 'Alerts',
    render: ({ id, alerts: { firstAlerted, lastAlerted, initialAlerted, pastDue } }) => (
      <DueTooltip
        id={id}
        firstAlerted={firstAlerted}
        initialAlerted={initialAlerted}
        lastAlerted={lastAlerted}
        pastDue={pastDue}
      />
    )
  },
  {
    title: 'Actions',
    width: '50px',
    render: ({ id, name }) => (
      <TableActions
        items={[
          {
            key: 1,
            title: 'Edit',
            iconName: IconName.EDIT,
            iconHoverFace: MenuIconHoverFace.PRIMARY,
            textFace: MenuItemTextFace.GREY,
            url: `${DOCUMENTS_PATH}/${id}/edit?returnUrl=${returnUrl}`
          },
          {
            key: 2,
            title: 'Delete',
            behavior: MenuItemBehavior.BUTTON,
            iconName: IconName.DELETE,
            iconHoverFace: MenuIconHoverFace.DANGER,
            textFace: MenuItemTextFace.GREY,
            onClick: () => deleteDocument(id, name)
          }
        ]}
      />
    )
  }
]
