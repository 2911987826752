import { getAlertsWebPage } from 'commons/service/bank/alerts'
import { AlertsWebPageReturnValue } from 'commons/types/DTO/bank'
import { Keys, useStateManager } from 'state-manager'

export const useGetAlertsWebPage = (): AlertsWebPageReturnValue => {
  const sm = useStateManager()

  const { isFetching, data } = sm.use(Keys.Alerts.WebPage, () => getAlertsWebPage({}), {
    keepPreviousData: true
  })

  return {
    isWebPageRequestPending: isFetching,
    webPage: data
  }
}
