import SC from 'styled-components'
import { CSS } from 'ui/helpers'

export const HeaderWrapper = SC.div<{ $active?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  height: 68px; 

  padding: 0px 24px;
  border-box: box-sizing;
  border-radius: 8px 8px 0px 0px;
  overflow: hidden;

  hr {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    border: none;
    border-top: 2px solid ${(p) =>
      p?.$active ? p.theme.uiKit.colors.theme.brand.primary : p.theme.uiKit.colors.theme.negative.main};
  }
`

export const SectionWrapper = SC.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  gap: 0px 32px;
`

export const Title = SC.h5`
  ${(p) => CSS.populateFont(p.theme.uiKit.typography.h5.semiBold)};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0px 8px;
`

export const StatusBadge = SC.div<{ $active?: boolean }>`
  background-color: ${(p) =>
    p?.$active ? p.theme.uiKit.colors.theme.brand.primary : p.theme.uiKit.colors.theme.negative.main};
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  box-sizing: border-box;
  padding: 1px 6px 1px 6px;
  border-radius: 20px;
  color: white;
  ${(p) => CSS.populateFont(p.theme.uiKit.typography.bodyM.regular)};
  text-transform: uppercase;
`

export const TextBadge = SC.div<{ $isDarkText?: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0px 4px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: black;
`
