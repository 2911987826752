import styled from 'styled-components'

export const Wrapper = styled.div`
  margin: 40px 0 60px 0;
`

export const Header = styled.div`
  background-color: #e2e2e2;
  margin: 0 -50px 30px -50px;
  padding: 12px 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Left = styled.div`
  span {
    font-size: 12px;
    color: #868686;
    display: block;
  }
`

export const Notes = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 250px;
  overflow: hidden;
  display: block;
`
