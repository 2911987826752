import { AnyRoute, createRoute, lazyRouteComponent } from '@tanstack/react-router'

import { applicationDocumentLoader } from './applicationDocumentLoader'
import { HistorySearch } from './types'

export const DocumentDetailsRoute = <T extends AnyRoute>(indexRoute: T) =>
  createRoute({
    getParentRoute: () => indexRoute,
    path: '$applicationId/document/$documentId',
    component: lazyRouteComponent(() => import('./ApplicationDocument')),
    loader: applicationDocumentLoader,
    loaderDeps: ({ search: { historyPage, historySize } }) => ({ historyPage, historySize }),
    validateSearch: (search: Record<string, unknown>): HistorySearch => {
      return {
        historyPage: Number(search?.historyPage ?? 1),
        historySize: Number(search?.historySize ?? 10)
      }
    },
    gcTime: 0
  })
