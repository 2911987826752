import { OnboardingRFIFormSavePayload } from 'commons/types/DTO/commons/webpagesOnboardinForm'

import { OnboardingFormsData, OnboardingProxyWebpageData } from '../../../types/state'
import { mapATMGeneralInfoForm } from './mappers/mapATMGeneralInfoForm'
import { mapATMServicesForm } from './mappers/mapATMServicesForm'
import { mapAccountDetailsForm } from './mappers/mapAccountDetailsForm'
import { mapAnticipatedMonthlyActivityForm } from './mappers/mapAnticipatedMonthlyActivityForm'
import { mapAnticipatedMonthlyActivityNAForm } from './mappers/mapAnticipatedMonthlyActivityNAForm'
import { mapAvailableServicesForm } from './mappers/mapAvailableServicesForm'
import { mapCompanyInformationForm } from './mappers/mapCompanyInformationForm'
import { mapCompanyMailingAddressForm } from './mappers/mapCompanyMailingAddressForm'
import { mapCompanyPhysicalAddressForm } from './mappers/mapCompanyPhysicalAddressForm'
import { mapContactAdditionalInformationForm } from './mappers/mapContactAdditionalInformationForm'
import { mapContactDetailsForm } from './mappers/mapContactDetailsForm'
import { mapLicenseAddressInformationForm } from './mappers/mapLicenseAddressInformationForm'
import { mapLicenseDetailsForm } from './mappers/mapLicenseDetailsForm'
import { mapMerchantQuestionnaireForm } from './mappers/mapMerchantQuestionnaireForm'
import { mapProfessionalPartnershipForm } from './mappers/mapProfessionalPartnershipForm'
import { mapQuestionnaireForm } from './mappers/mapQuestionnaireForm'
import { mapRoleInformationForm } from './mappers/mapRoleInformationForm'

export const mapFormValuesForSave = ({
  applicationId,
  formValue,
  proxyWebpageData: { updatedAt }
}: {
  applicationId: number
  formValue: OnboardingFormsData
  proxyWebpageData: OnboardingProxyWebpageData
}): OnboardingRFIFormSavePayload => {
  const companies = formValue.companiesFormValues

  return {
    id: applicationId,
    rfiInfo: {
      relationships: companies.map((companyForms) => ({
        questionnaire: {
          // QuestionnaireFormValue
          ...mapQuestionnaireForm(companyForms.questionnaire.formValues),
          // ProfessionalPartnershipFormValue
          ...mapProfessionalPartnershipForm(companyForms.professionalPartnerships.formValues),
          // ATMGeneralInfoFormValue
          ...mapATMGeneralInfoForm(companyForms.atmGeneralInfo.formValues),
          // AvailableServicesFormValue
          ...mapAvailableServicesForm(companyForms.availableServices.formValues),
          // AnticipatedMonthlyActivityNAFormValue
          ...mapAnticipatedMonthlyActivityNAForm(companyForms.anticipatedMonthlyActivityNA.formValues),
          // AnticipatedMonthlyActivityFormValue
          ...mapAnticipatedMonthlyActivityForm(companyForms.anticipatedMonthlyActivity.formValues),
          // MerchantQuestionnaireFormValue
          ...mapMerchantQuestionnaireForm(companyForms.companyMerchantQuestionnaire.formValues),
          ATMs: companyForms.atms.map((atmFormData) => ({
            ...mapATMServicesForm(atmFormData.atmServices.formValues)
          }))
        },
        // CompanyInformationFormValue
        ...mapCompanyInformationForm(companyForms.companyInformation.formValues),
        // AccountDetailsFormValue
        ...mapAccountDetailsForm(companyForms.companyAccountDetails.formValues),

        // CompanyPhysicalAddressFormValue
        ...mapCompanyPhysicalAddressForm(companyForms.physicalAddress.formValues),
        // CompanyMailingAddressFormValue
        ...mapCompanyMailingAddressForm(companyForms.mailingAddress.formValues),

        licenses:
          companyForms.companyAccountDetails.formValues.businessType !== 'mrb'
            ? []
            : companyForms.licenses.map((licenseItemForms) => ({
                // LicenseDetailsFormValue
                ...mapLicenseDetailsForm(licenseItemForms.details.formValues),
                // LicenseAddressInformationFormValue
                ...mapLicenseAddressInformationForm(licenseItemForms.addressInformation.formValues)
              })),
        contacts: companyForms.contacts.map((contactItemForms) => ({
          // ContactDetailsFormValue
          ...mapContactDetailsForm(contactItemForms.details.formValues),
          // RoleInformationFormValue
          ...mapRoleInformationForm(contactItemForms.roleInformation.formValues),
          // ContactAdditionalInformationFormValue
          ...mapContactAdditionalInformationForm(contactItemForms.contactAdditionalInformation.formValues)
        }))
      }))
    },
    updatedAt
  }
}
