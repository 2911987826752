import { TableSortingOrder } from 'ui/components'

export type TableState = {
  order: TableSortingOrder[]
  expanded: boolean
  total: number
  pageSize: number
  page: number
  shouldInvalidate: boolean
  shouldClearFilter: Record<PropertyKey, never> // observing changing ref
}

export type PaginationChangeHandler = (page: number, size: number) => void

export type TableStatePagination = {
  page: number
  pageSize: number
  total: number
  onChange: PaginationChangeHandler
}

export enum TableStateActions {
  ON_CHANGE = 'onChange'
}

interface TableStateOnChangeAction {
  type: TableStateActions.ON_CHANGE
  payload: Partial<TableState>
}

// actions

export const onChangeTableState = (payload: Partial<TableState>) => ({
  type: TableStateActions.ON_CHANGE,
  payload
})

export type TableStateAction = TableStateOnChangeAction
