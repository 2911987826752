import { FormConfig } from 'brief-form'

import { ContactAdditionalInformationFormValue } from '../../types'

export const onCopyFromPhysicalAddressToMailingAddress = (
  config: FormConfig<ContactAdditionalInformationFormValue>
) => {
  const valueToCopy = {
    mailingStreet: config.value.street,
    mailingCity: config.value.city,
    mailingState: config.value.state,
    mailingCountry: config.value.country,
    mailingPostalCode: config.value.postalCode
  }

  config.onChange({ ...config.value, ...valueToCopy }, {})
}
