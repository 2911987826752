export type TableState = {
  filter: {
    expanded: boolean
    toggle: () => void
    open: () => void
    close: () => void
  }
  pagination: {
    change: (page: number, size: number) => void
    page: number
    size: number
  }
  sorting: {
    order: { field: string; direction: 'ASC' | 'DESC' }[]
    change: (value: { field: string; direction: 'ASC' | 'DESC' }) => any
  }
}
