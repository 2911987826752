import styled, { css } from 'styled-components'
import { CSS } from 'ui/helpers'

export const Box = styled.button`
  ${({
    theme: {
      uiKit: { borderRadius, colors, borderWidth, size, opacity, boxShadows }
    }
  }) => css`
    cursor: pointer;
    border: none;

    width: ${size.buttonsHeight.middle};
    height: ${size.buttonsHeight.middle};

    color: ${colors.global.general.main};
    background-color: ${colors.global.grey.grey700};

    border-radius: ${borderRadius.roundedFull};

    outline: solid ${colors.global.general.transparent} ${borderWidth.strokeM};

    opacity: ${opacity.opacity600};

    transition: background 0.15s, color 0.15s, outline 0.15s, box-shadow 0.15s;

    &:hover {
      background-color: ${colors.global.grey.grey900};
    }

    &:active {
      background-color: ${colors.global.grey.grey700};
    }

    &:focus-visible {
      background-color: ${colors.global.grey.grey900};
      outline-color: ${colors.global.grey.grey500};

      box-shadow: ${CSS.populateBoxShadow(boxShadows.offset.offset100Y8)};
    }
  `}
`
