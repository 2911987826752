import { useCallback, useState } from 'react'

import { useNavigate } from '@tanstack/react-router'
import { FormConfig } from 'brief-form'
import { useBankSourceUpdate, useSourceFileDelete, useSourceFileUpload } from 'commons/hooks/bank/salesData'
import { MRWebPagePayload } from 'commons/types/DTO/bank/reportWebPage'
import { BankSourceCreateFormValue, SalesDataSourceWebPagePayload } from 'commons/types/DTO/bank/salesDataSourceWebPage'
import { handlerServerError } from 'errors'
import { Keys, useStateManager } from 'state-manager'
import { Toast } from 'ui/components'

export const useSaveSourceAndFilesManage = (
  sourceId: number | undefined,
  mrId: number,
  config: FormConfig<BankSourceCreateFormValue>,
  returnUrl?: string
) => {
  const navigate = useNavigate()
  const sm = useStateManager()
  const [files, setFiles] = useState<File[]>([])

  let sourceUpdated = false
  let filesUploadError = false

  const { routine: uploadFile, isLoading: isFileUploading } = useSourceFileUpload({
    onError: (error) => () => {
      filesUploadError = true
      return handlerServerError(error)
    }
  })

  const { routine: deleteUploadedFile, isLoading: isUploadedFileDeleting } = useSourceFileDelete({
    onSuccess: async () => {
      Toast.successSmall('File was deleted.')
      if (sourceId) {
        sm.invalidate(Keys.SalesData.Details(new SalesDataSourceWebPagePayload(sourceId)))
      }
    },
    onError: (error) => () => handlerServerError(error)
  })

  const { routine: updateBankSource, isLoading: isSubmitting } = useBankSourceUpdate({
    onError: (error) => handlerServerError<BankSourceCreateFormValue>(error, { form: config }),
    onSuccess: async (_, response) => {
      sourceUpdated = true
      files.forEach(async (file) => {
        const form = new FormData()
        form.append('salesDataSourceId', response.id.toString())
        form.append('file', file)
        await uploadFile(form)
      })
      sm.invalidate(Keys.MR.Details(new MRWebPagePayload(mrId)))
    }
  })

  const checkErrorsAndRedirect = useCallback(() => {
    if (!isSubmitting && !isFileUploading && sourceUpdated) {
      if (files.length && filesUploadError) {
        Toast.errorSmall(`Banker source has been saved, but some files aren't uploaded.`)
      } else {
        Toast.successSmall('Banker source has been saved.')
      }
    }
    if (returnUrl) {
      navigate({ to: returnUrl })
    } else {
      navigate({ to: '/mr/$id', params: { id: mrId.toString() } })
    }
  }, [files, isFileUploading, isSubmitting, sourceUpdated, filesUploadError, navigate, mrId, returnUrl])

  const addFiles = useCallback(
    (newFiles: File[]) => {
      setFiles([...files, ...newFiles])
    },
    [files]
  )

  const deleteFile = useCallback(
    (index: number) => {
      files.splice(index, 1)
      setFiles([...files])
    },
    [files]
  )

  const onSubmit = useCallback(
    () => updateBankSource(config.value).then(() => setTimeout(() => checkErrorsAndRedirect(), 0)),
    [updateBankSource, config.value, checkErrorsAndRedirect]
  )

  return {
    files,
    addFiles,
    deleteFile,
    onSubmit,
    deleteUploadedFile,
    isFileUploading,
    isSubmitting,
    isUploadedFileDeleting
  }
}
