export enum DocumentSubjectType {
  BANK_ACCOUNT = 'bank_account',
  COMPANY = 'company',
  CONTACT = 'contact',
  LICENSE = 'license'
}

export const documentSubjectLabelByType = {
  [DocumentSubjectType.BANK_ACCOUNT]: 'Bank Account',
  [DocumentSubjectType.COMPANY]: 'Relationship',
  [DocumentSubjectType.CONTACT]: 'Contact',
  [DocumentSubjectType.LICENSE]: 'License'
}
