import React, { FC, memo, useCallback } from 'react'

import { FormInputProps } from 'brief-form'
import styled from 'styled-components'
import { ButtonGroup } from 'ui/components/ButtonGroup'

//region Styled

const CustomButton = styled.button<{ $active?: boolean }>`
  height: 43px;
  background: ${(p) => (p.$active ? '#fff' : 'transparent')};
  border: solid 2px #fff;
  opacity: 0.96;
  border-radius: 21.5px;
  color: ${(p) => (p.$active ? '#000' : '#fff')};
  cursor: pointer;
  padding: 0px 20px;
`

//endregion

export const DeviceTypeSelector: FC<FormInputProps<string, unknown>> = memo((props) => {
  const { value, onChange } = props

  const getFace = useCallback((type: string) => (value === type ? true : false), [value])

  const getClickHandler = useCallback((type: string) => () => onChange(type, undefined), [onChange])

  return (
    <ButtonGroup margin="small">
      <CustomButton $active={getFace('sms')} onClick={getClickHandler('sms')}>
        Sms
      </CustomButton>
      <CustomButton $active={getFace('email')} onClick={getClickHandler('email')}>
        Email
      </CustomButton>
      <CustomButton $active={getFace('totp')} onClick={getClickHandler('totp')}>
        Application
      </CustomButton>
    </ButtonGroup>
  )
})
