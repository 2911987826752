import { useCallback } from 'react'

import { FormChangedHandler, useFormData } from 'brief-form'

import { useSyncFormData } from '../../hooks'
import {
  AnticipatedMonthlyActivityNAChangeHandler,
  AnticipatedMonthlyActivityNAFormData,
  AnticipatedMonthlyActivityNAFormValue
} from '../../types/forms'
import { AnticipatedMonthlyActivityNA } from './AnticipatedMonthlyActivityNAFormProps'

export const useAnticipatedMonthlyActivityNAForm = ({
  companyIndex,
  formData,
  onChange,
  anticipatedMonthlyActivityNAList
}: {
  companyIndex: number
  formData: AnticipatedMonthlyActivityNAFormData
  onChange: AnticipatedMonthlyActivityNAChangeHandler
  anticipatedMonthlyActivityNAList: AnticipatedMonthlyActivityNA[]
}) => {
  const onFormChanged: FormChangedHandler<AnticipatedMonthlyActivityNAFormValue> = useCallback(
    (formValues, formErrors) => {
      anticipatedMonthlyActivityNAList.forEach((el) => {
        // Reset selects if check

        if (formValues[el.na]) {
          formValues[el.amount] = undefined
          formValues[el.items] = undefined

          delete formErrors[el.amount]
          delete formErrors[el.items]
        }
      })

      onChange({ companyIndex, formData: { formValues, formErrors } })
    },
    [anticipatedMonthlyActivityNAList, onChange, companyIndex]
  )

  const { config, Field, set } = useFormData<AnticipatedMonthlyActivityNAFormValue>({
    onFormChanged,
    initialValue: formData.formValues,
    initialErrors: formData.formErrors
  })

  useSyncFormData({ set, formData })

  return { Field, config }
}
