import * as React from 'react'

import SC from 'styled-components'

const Questions = SC.div`
  display: flex;
  flex-direction: row;
`

export interface AnnualReviewQuestionsSetProps {
  children: React.ReactNode
}

export const AnnualReviewQuestionsSet: React.FC<AnnualReviewQuestionsSetProps> = ({
  children
}: AnnualReviewQuestionsSetProps) => <Questions>{children}</Questions>
