export enum ctrPersonTypesLabels {
  CTR_PERSON_TYPE_A = 'a. Person conducting transaction on own behalf',
  CTR_PERSON_TYPE_B = 'b. Person conducting transaction for another',
  CTR_PERSON_TYPE_C = 'c. Person on whose behalf transaction was conducted',
  CTR_PERSON_TYPE_D = 'd. Common carrier'
}

export enum ctrPersonTypesValues {
  CTR_PERSON_TYPE_A = 'Person conducting transaction on own behalf',
  CTR_PERSON_TYPE_B = 'Person conducting transaction for another',
  CTR_PERSON_TYPE_C = 'Person on whose behalf transaction was conducted',
  CTR_PERSON_TYPE_D = 'Common carrier'
}

export const ctrPersonTypes = [
  { label: ctrPersonTypesLabels.CTR_PERSON_TYPE_A, value: ctrPersonTypesValues.CTR_PERSON_TYPE_A },
  { label: ctrPersonTypesLabels.CTR_PERSON_TYPE_B, value: ctrPersonTypesValues.CTR_PERSON_TYPE_B },
  { label: ctrPersonTypesLabels.CTR_PERSON_TYPE_C, value: ctrPersonTypesValues.CTR_PERSON_TYPE_C },
  { label: ctrPersonTypesLabels.CTR_PERSON_TYPE_D, value: ctrPersonTypesValues.CTR_PERSON_TYPE_D }
]
