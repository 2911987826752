import styled from 'styled-components'

export const NoFiles = styled.span`
  color: #868686;
`

export const HasFiles = styled.span`
  color: #006a51;
  text-decoration: underline;
  cursor: pointer;
`
