import { useCallback, useState } from 'react'

export const useComponentEssence = () => {
  const [open, setOpen] = useState(false)
  const [localFiles, setLocalFiles] = useState<File[]>([])
  const openDialog = useCallback(() => setOpen(true), [setOpen])
  const closeDialog = useCallback(() => setOpen(false), [setOpen])

  return {
    closeDialog,
    open,
    openDialog,
    localFiles,
    setLocalFiles
  }
}
