export type RelationshipsReportItem = {
  businessType: string
  customerStatus: string
  dba: string
  holdingCompany: string
  inApplicationProcess: boolean
  relationshipID: number
  relationshipName: string
  state: string
}
