import { OnboardingFormActions } from '../../../enums'
import { SetNextStepAction, SetPrevStepAction } from '../../../types/actions'

export const setPrevStep = (): SetPrevStepAction => ({
  type: OnboardingFormActions.SET_PREV_STEP
})

export const setNextStep = (): SetNextStepAction => ({
  type: OnboardingFormActions.SET_NEXT_STEP
})
