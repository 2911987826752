import React, { FC } from 'react'

import { IconSize } from 'ui/components'
import { CheckMark } from 'ui/components/CheckMark'
import { DateTime } from 'ui/components/DateTime'
import { Info, InfoSet, InfoSetDirection } from 'ui/components/Info'
import { Link } from 'ui/components/Link'
import { TextMask } from 'ui/components/TextMask'

import { GeneralInformationProps } from './GeneralInformationProps'

import * as SC from './styles'

export const GeneralInformation: FC<GeneralInformationProps> = ({
  companyId,
  companyName,
  bankAccountNumber,
  isLicensed,
  openingDate,
  internalTransferType,
  updatedAt,
  createdAt,
  notes,
  licenses
}) => (
  <InfoSet direction={InfoSetDirection.COLUMN}>
    <SC.Row>
      <Info label="Relationship Name">
        <Link to="/relationships/$companyId" params={{ companyId }}>
          {companyName || '---'}
        </Link>
      </Info>
      <Info label="Account Number">
        <TextMask isBankAccount text={bankAccountNumber} iconSize={IconSize.XS} />
      </Info>
    </SC.Row>
    <SC.Row>
      <Info label="Licensed">
        <div style={{ width: '20px' }}>
          <CheckMark value={isLicensed} label="" rounded={false} />
        </div>
      </Info>
      <Info label="Opening Date">
        <DateTime date={openingDate} />
      </Info>
    </SC.Row>
    <SC.Row>
      <Info label="Participation in the Transfers program">{internalTransferType}</Info>
    </SC.Row>
    <SC.Row>
      <Info label="Created At">
        <DateTime date={createdAt} withTime />
      </Info>
      <Info label="Last Modified At">
        <DateTime date={updatedAt} withTime />
      </Info>
    </SC.Row>
    <SC.Row>
      <Info label="Notes">{notes || '--'}</Info>
    </SC.Row>
    {isLicensed && (
      <>
        <SC.WrappedHr />
        <SC.Title>Licenses ({(licenses || []).length})</SC.Title>
        {licenses?.map((license, index) => (
          <SC.License key={index}>
            <Link
              to="/licenses/$licenseId"
              params={{ licenseId: license.id }}
            >{`${license.name} (${license.number})`}</Link>
          </SC.License>
        ))}
      </>
    )}
  </InfoSet>
)
