import { Request } from 'commons/utils/request'

import {
  BankAccountCreatePage,
  BankAccountCreatePagePayload,
  BankAccountDeletePayload,
  BankAccountDetailsPage,
  BankAccountDetailsPagePayload,
  BankAccountEditPage,
  BankAccountEditPagePayload,
  BankAccountForm
} from './types'

export const api = {
  getDetailsPage: (payload?: BankAccountDetailsPagePayload) =>
    Request.post<BankAccountDetailsPage, BankAccountDetailsPagePayload>(
      '/api/bank/v1/webpages/bank-account-details',
      payload
    ),
  getCreatePage: (payload?: BankAccountCreatePagePayload) =>
    Request.post<BankAccountCreatePage, BankAccountCreatePagePayload>(
      '/api/bank/v1/webpages/bank-account-create-mode',
      payload
    ),
  getEditPage: (payload?: BankAccountEditPagePayload) =>
    Request.post<BankAccountEditPage, BankAccountEditPagePayload>(
      '/api/bank/v1/webpages/bank-account-edit-mode',
      payload
    ),
  update: (payload?: BankAccountForm) =>
    payload.id
      ? Request.put<void, BankAccountForm>('/api/bank/v1/bank-account', payload)
      : Request.post<void, BankAccountForm>('/api/bank/v1/bank-account', payload),
  delete: (payload?: BankAccountDeletePayload) =>
    Request.delete<void, BankAccountDeletePayload>('/api/bank/v1/bank-account', payload)
}
