import React, { FC, memo } from 'react'

import { Form } from 'brief-form'
import { Select } from 'ui/components/Select'
import { FilterPanel } from 'ui/components/Table'

import { FilterProps } from './FilterProps'

import * as SC from './styles'

export const Filter: FC<FilterProps> = memo((props) => {
  const {
    onClear,
    form: { isDirty, config, Field },
    expanded,
    onToggle,
    relationshipsScopeValues,
    relationshipsCustomerStatusValues,
    relationshipsEntityTypeValues,
    relationshipsBusinessTypeValues,
    relationshipsIsHoldingValueValues
  } = props

  const DEFAULT_OPTION = [{ label: 'All', value: 'all' }]

  return (
    <FilterPanel isDirty={isDirty} onReset={onClear} expanded={expanded} onToggle={onToggle}>
      <SC.FormWrapper>
        <Form config={config}>
          <SC.FilterWrapper>
            <SC.ItemWrapper>
              <Field
                name="relationshipsScope"
                label="Relationships"
                input={Select}
                inputProps={{
                  data: relationshipsScopeValues || DEFAULT_OPTION,
                  'options-data-qa': 'Relationships',
                  'data-qa': 'Relationships Type'
                }}
              />
            </SC.ItemWrapper>
            <SC.ItemWrapper>
              <Field
                name="relationshipsCustomerStatus"
                label="Customer Status"
                input={Select}
                inputProps={{
                  data: relationshipsCustomerStatusValues || DEFAULT_OPTION
                }}
              />
            </SC.ItemWrapper>
            <SC.ItemWrapper>
              <Field
                name="relationshipsEntityType"
                label="Entity Type"
                input={Select}
                inputProps={{
                  data: relationshipsEntityTypeValues || DEFAULT_OPTION
                }}
              />
            </SC.ItemWrapper>
            <SC.ItemWrapper>
              <Field
                name="relationshipsBusinessType"
                label="Business Type"
                input={Select}
                inputProps={{
                  data: relationshipsBusinessTypeValues || DEFAULT_OPTION
                }}
              />
            </SC.ItemWrapper>
            <SC.ItemWrapper>
              <Field
                name="relationshipsIsHolding"
                label="Holding Company"
                input={Select}
                inputProps={{
                  data: relationshipsIsHoldingValueValues || [{ label: 'All', value: 'null' }]
                }}
              />
            </SC.ItemWrapper>
          </SC.FilterWrapper>
        </Form>
      </SC.FormWrapper>
    </FilterPanel>
  )
})
