import { OnboardingFormState } from '../../../types/state'
import {
  emptyContactAdditionalInformation,
  emptyContactDetails,
  emptyContactRoleInformation,
  getEmptyContact
} from './emptyEntity/getEmptyContact'
import { setNewCompanies } from './setNewCompanies'

export const getStateAfterAddedContact = ({
  currentState,
  companyIndex
}: {
  currentState: OnboardingFormState
  companyIndex: number
}) => {
  const selectedCompany = currentState.formValue.companiesFormValues[companyIndex]
  const oldContacts = selectedCompany.contacts
  const newContact = getEmptyContact({
    contact: { ...emptyContactDetails, ...emptyContactRoleInformation, ...emptyContactAdditionalInformation }
  })
  const newContacts = [...oldContacts, newContact]
  const newCompany = { ...selectedCompany, contacts: newContacts }
  const newCompaniesFormValues = setNewCompanies({
    companyIndex,
    oldCompanies: currentState.formValue.companiesFormValues,
    newCompany
  })
  return {
    formValue: {
      ...currentState.formValue,
      companiesFormValues: newCompaniesFormValues
    }
  }
}
