import React from 'react'

import { CurrencyInput, FF, Validators } from 'ui/components'

import { useSARFormContext } from '../../../../../../context'

type TotalBankFeesProps = {
  index: number
}

export const TotalBankFees = ({ index }: TotalBankFeesProps) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={
        `transactions_info.by_account[${index}].total_bank_fees` as 'transactions_info.by_account[0].total_bank_fees'
      }
      validators={{ onChange: ({ value }) => Validators.required.notEmpty(value) }}
      children={(field) => (
        <FF required field={field} label="Total Bank Fees" input={(p) => <CurrencyInput {...p} />} />
      )}
    />
  )
}
