import { useCallback, useMemo, useState } from 'react'

import { useBankPortalContext } from 'bank-portal-context'
import { BeforeFormChangeHandler, useFormData } from 'brief-form'
import { useGetCompanyList } from 'commons/hooks/bank/company'
import { useTableState } from 'ui/components'

import { useCompanyDelete } from '../../../hooks'
import { FilterFormValue } from '../types'

const DEFAULT_RELATIONSHIPS_LIMIT = 20
const DEFAULT_RELATIONSHIPS_OFFSET = 0

export const useRelationships = () => {
  const {
    layout: { assignedCompaniesIDs }
  } = useBankPortalContext()

  const tableState = useTableState({
    filter: {},
    order: [{ field: 'accountName', direction: 'ASC' }],
    page: 1,
    size: 20
  })

  // TODO: fix relationshipsIsHolding mutations
  const defaultFilter: FilterFormValue = useMemo(
    () => ({
      relationshipsIDs: assignedCompaniesIDs.length > 0 ? assignedCompaniesIDs : [],
      relationshipsScope: assignedCompaniesIDs.length > 0 ? 'my' : 'all',
      relationshipsCustomerStatus: 'all',
      relationshipsEntityType: 'all',
      relationshipsBusinessType: 'all',
      relationshipsIsHolding: null,
      relationshipsSearchQuery: '',
      relationshipsLimit: DEFAULT_RELATIONSHIPS_LIMIT,
      relationshipsOffset: DEFAULT_RELATIONSHIPS_OFFSET,
      relationshipsSortBy: 'accountName',
      relationshipsSortDesc: false
    }),
    []
  )

  const [tableFilter, setTableFilter] = useState<FilterFormValue>(defaultFilter)

  const { data: webPageData, isFetching, invalidate } = useGetCompanyList(tableFilter)

  const onBeforeChange: BeforeFormChangeHandler<FilterFormValue> = useCallback(({ value, errors, oldValue }) => {
    const filterChanged =
      value.relationshipsScope !== oldValue.relationshipsScope ||
      value.relationshipsCustomerStatus !== oldValue.relationshipsCustomerStatus ||
      value.relationshipsEntityType !== oldValue.relationshipsEntityType ||
      value.relationshipsBusinessType !== oldValue.relationshipsBusinessType ||
      value.relationshipsIsHolding !== oldValue.relationshipsIsHolding ||
      value.relationshipsSearchQuery !== oldValue.relationshipsSearchQuery ||
      value.relationshipsSortBy !== oldValue.relationshipsSortBy ||
      value.relationshipsSortDesc !== oldValue.relationshipsSortDesc

    if (filterChanged) {
      tableState.onPageChange(1, tableState.size)
      value.relationshipsOffset = DEFAULT_RELATIONSHIPS_OFFSET
    }

    setTableFilter({
      ...value,
      relationshipsIsHolding:
        value.relationshipsIsHolding === 'null'
          ? null
          : JSON.parse(typeof value?.relationshipsIsHolding === 'string' ? value?.relationshipsIsHolding : '')
    })

    return {
      value,
      errors
    }
  }, [])

  const filterForm = useFormData<FilterFormValue>({
    initialValue: { ...defaultFilter, relationshipsIsHolding: 'null' },
    onBeforeChange
  })

  const updateFilter = (newOptions) => {
    setTableFilter({ ...tableFilter, ...newOptions })
    filterForm.set({ value: { ...filterForm.config.value, ...newOptions } })
  }

  const changePagination = useCallback(
    async (page: number, size: number) => {
      tableState.onPageChange(page, size)

      const newOptions = {
        relationshipsOffset: (page - 1) * size,
        relationshipsLimit: size
      }

      updateFilter(newOptions)
    },
    [defaultFilter, tableState]
  )

  const onClear = useCallback(() => {
    setTableFilter(defaultFilter)
    tableState.onFilterClear()
    tableState.onPageChange(1, tableState.size)
    filterForm.set({ value: { ...defaultFilter, relationshipsIsHolding: 'null' }, reset: true })
  }, [])

  const onSort = useCallback(
    async (direction: 'ASC' | 'DESC', field) => {
      const isDesc = direction === 'DESC'

      tableState.onOrderChange({ field, direction })

      const newOptions = {
        relationshipsOffset: DEFAULT_RELATIONSHIPS_OFFSET,
        relationshipsLimit: DEFAULT_RELATIONSHIPS_LIMIT,
        relationshipsSortBy: field,
        relationshipsSortDesc: isDesc
      }

      updateFilter(newOptions)
    },
    [tableState]
  )

  const onSearch = useCallback(
    (text: string) => {
      const newOptions = {
        relationshipsOffset: DEFAULT_RELATIONSHIPS_OFFSET,
        relationshipsSearchQuery: text
      }
      updateFilter(newOptions)
    },
    [tableFilter]
  )

  const { DeleteConfirmation, onDeleteCompany } = useCompanyDelete(invalidate)

  return {
    onClear,
    filterForm,
    tableState,
    loading: isFetching,
    webPageData,
    changePagination,
    onSearch,
    onSort,
    DeleteConfirmation,
    onDeleteCompany
  }
}
