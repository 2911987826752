import SC from 'styled-components'

const DueRow = SC.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const TooltipContent = SC.span`
  display: flex;
  align-items: center;
`

export const s = { DueRow, TooltipContent }
