import React, { FC, memo } from 'react'

import { Hr, Info, Validators } from 'ui/components'
import { PhoneInput, TextInput } from 'ui/components/InputV2'

import * as SC from './styles'

export const ClientUserGeneralInformationForm: FC<any> = memo(({ Field }) => (
  <>
    <SC.Wrapper>
      <SC.Title>General Information</SC.Title>
      <SC.Columns>
        <SC.Column>
          <Field
            name="firstName"
            label="First Name"
            input={TextInput}
            required
            validator={Validators.required.notEmpty}
            inputProps={{
              'data-qa': 'firstName'
            }}
          />
          <Field
            name="email"
            label="Email"
            input={TextInput}
            required
            validator={Validators.required.field}
            inputProps={{
              'data-qa': 'email'
            }}
          />
          <Info label="Status">Customer</Info>
        </SC.Column>
        <SC.Column>
          <Field
            name="lastName"
            label="Last Name"
            input={TextInput}
            required
            validator={Validators.required.notEmpty}
            inputProps={{
              'data-qa': 'lastName'
            }}
          />
          <Field
            name="phone"
            label="Phone"
            input={PhoneInput}
            inputProps={{
              'data-qa': 'phone'
            }}
          />
        </SC.Column>
      </SC.Columns>
    </SC.Wrapper>
    <Hr />
  </>
))
