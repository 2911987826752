import React, { FC, memo } from 'react'

import { MultiSelectAutoSuggest } from '../MultiSelectAutoSuggest'
import { UserMultiSelectProps } from './UserMultiSelectProps'

export const UserMultiSelect: FC<UserMultiSelectProps> = memo((props) => {
  const { value, onChange, options, ...rest } = props

  return (
    <MultiSelectAutoSuggest
      {...rest}
      value={value}
      onChange={onChange}
      valueTitle="Add User"
      placeholder="Add user"
      options={options.map((item) => ({ label: item.fullName, value: item.id, title: item.fullName }))}
    />
  )
})
