import React from 'react'

import { FF, TextInput } from 'ui/components'

import { useSARFormContext } from '../../../../../../../../context'

export const IP = ({ index }: { index: number }) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={`transactions_info.ip_addresses[${index}].ip` as 'transactions_info.ip_addresses[0].ip'}
      children={(field) => <FF label="IP" field={field} input={(p) => <TextInput {...p} maxLength={50} />} />}
    />
  )
}
