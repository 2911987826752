import React, { FC, memo } from 'react'

import { Form } from 'brief-form'
import { DatePicker, Select } from 'ui/components'
import { NumberInput, TextInput } from 'ui/components/InputV2'
import { FilterPanel } from 'ui/components/Table'
import { FilterWrapper } from 'ui/temp/Filter'

import { FilterFormProps } from './FilterFormProps'

import * as SC from './styles'

export const FilterForm: FC<FilterFormProps> = memo(({ optionStatus, form, onClear }) => {
  const { config, Field, isDirty } = form

  return (
    <FilterPanel isDirty={isDirty} onReset={onClear}>
      <FilterWrapper>
        <Form config={config}>
          <SC.Row>
            <SC.LeftWrapper>
              <Field name="batchId" label="Batch ID" input={NumberInput} inputProps={{}} />
              <Field name="bsaTrackingId" label="BSA Tracking ID" input={TextInput} inputProps={{}} />
            </SC.LeftWrapper>
            <SC.RightWrapper>
              <Field name="date" label="Date" input={DatePicker} inputProps={{}} />
              <Field
                name="status"
                label="Status"
                input={Select}
                inputProps={{
                  data: optionStatus
                }}
              />
            </SC.RightWrapper>
          </SC.Row>
        </Form>
      </FilterWrapper>
    </FilterPanel>
  )
})
