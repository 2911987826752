import React, { FC, memo } from 'react'

import { formatDateTime } from 'ui/components/DateTime'
import { Info, InfoSet, InfoSetDirection } from 'ui/components/Info'
import { PageSection, PageSectionFace } from 'ui/components/Page'
import { TextMask } from 'ui/components/TextMask'
import { CTRDetailsResponse } from '~bank-bsa-reporting/types'

import { getFormattedEin } from '../helpers'

import { s } from './styles'

type Props = {
  data: CTRDetailsResponse
}

export const FilingInstitutionContactInformation: FC<Props> = memo(({ data }) => {
  const {
    institutionContactInfo: {
      type,
      federalRegulator,
      legalName,
      dba,
      ein,
      address,
      city,
      country,
      state,
      zipCode,
      idType,
      idNumber,
      contactOffice,
      phone,
      filedDate,
      phoneExt
    }
  } = data

  return (
    <PageSection face={PageSectionFace.THIRD} title="Step 1. Filing Institution Contact Information" anchor="step1">
      <InfoSet direction={InfoSetDirection.COLUMN}>
        <s.Row>
          <Info label="52. Type of financial institution">{type}</Info>
          <Info label="43. Primary federal regulator">{federalRegulator}</Info>
        </s.Row>
        <s.Row>
          <Info label="44. Legal name of filing institution">{legalName}</Info>
          <Info label="45. Alternate name, e.g. trade name, DBA">{dba}</Info>
        </s.Row>
        <s.Row>
          <Info label="46. EIN">
            <TextMask text={getFormattedEin(true, ein)} />
          </Info>
        </s.Row>
        <s.Row>
          <Info label="47-51. Address">
            {address}
            <div>{[city, country === 'US' ? state : null, zipCode].filter(Boolean).join(', ') || '-'}</div>
            <div>{country}</div>
          </Info>
        </s.Row>
        <s.Row>
          <Info label="54. Filing institution ID type">{idType}</Info>
          <Info label="ID number">{idNumber}</Info>
        </s.Row>
        <s.Row>
          <Info label="55. Contact Office">{contactOffice}</Info>
          <Info label="56. Phone Number">{phone}</Info>
        </s.Row>
        <s.Row>
          {filedDate && <Info label="57. Date Filed">{formatDateTime(filedDate)}</Info>}
          <Info label="Ext.">{phoneExt}</Info>
        </s.Row>
      </InfoSet>
    </PageSection>
  )
})
