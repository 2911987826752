/* eslint-disable camelcase */
import { ApplicationQuestionnaire } from './applicationQuestionnaire'
import { ApplicationRelationshipContact } from './applicationRelationshipContact'
import { ApplicationRelationshipLicense } from './applicationRelatioshipLicense'

export type ApplicationRelationshipAccountDetails = {
  business_type?: string
  entity_type?: string
  ein?: string
  state_of_incorporation?: string
  pre_license_mrb_info?: string
}

export type ApplicationRelationshipPhysicalAddress = {
  street?: string
  city?: string
  state?: string
  country?: string
  postal_code?: string
}

export type ApplicationRelationshipMailingAddress = {
  mailing_street?: string
  mailing_city?: string
  mailing_state?: string
  mailing_country?: string
  mailing_postal_code?: string
}

export type ApplicationRelationshipCompanyInformation = {
  legal_name?: string
  dba?: string
  phone?: string
  website?: string
  description?: string
}

export type ApplicationRelationship = ApplicationRelationshipAccountDetails &
  ApplicationRelationshipPhysicalAddress &
  ApplicationRelationshipMailingAddress &
  ApplicationRelationshipCompanyInformation & {
    // AccountDetailsFormValue
    // ApplicationRelationshipPhysicalAddress
    // ApplicationRelationshipCompanyInformation

    name?: string
    company_id?: number
    external_company_id?: string
    primary_account_opening_date?: string

    licenses?: ApplicationRelationshipLicense[]
    contacts?: ApplicationRelationshipContact[]
    questionnaire?: ApplicationQuestionnaire
  }
