import React from 'react'

import { DatePicker, FF } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

export const FiledDate = () => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name="filed_date"
      children={(field) => <FF label="57. Date filed" field={field} input={(p) => <DatePicker {...p} />} />}
    />
  )
}
