import React, { FC, memo, useContext } from 'react'

import { Icon, IconName, IconSize } from 'ui/components'
import { Button } from 'ui/components/Buttons'

import { FormStatusMessages } from '../../enums'
import * as SC from '../Common/styles'
import { OnboardingFooter } from '../FormFooter'
import { OnboardingFormContext } from './OnboardingFormContext'

export const Footer: FC = memo(() => {
  const onboardingContext = useContext(OnboardingFormContext)
  const {
    state: { isFormSubmitting },
    ui,
    ui: {
      buttonsProps: { pageLabel, isShowPrev, isShowSubmit, isShowNext },
      isCP,
      isBP,
      isSubmitRFIFormEnabled
    },
    handlers: { handleSetPrevStep, handleSubmitForm, handleSetNextStep, handleManualSaveForm }
  } = onboardingContext

  return (
    <OnboardingFooter pageLabel={pageLabel}>
      <SC.FormStatusMessage>
        {ui.formStatusMessage === FormStatusMessages.CHANGES_SAVED && (
          <Icon name={IconName.CHECK_GREY} size={IconSize.XS} />
        )}
        {ui.formStatusMessage}
      </SC.FormStatusMessage>
      <SC.FooterButtons>
        {isCP && (
          <>
            {isShowPrev && (
              <Button face="positive" onClick={handleSetPrevStep}>
                Prev
              </Button>
            )}
            {isShowSubmit && (
              <Button face="positive" onClick={handleSubmitForm} loading={isFormSubmitting}>
                Submit
              </Button>
            )}
            {isShowNext && (
              <Button face="positive" onClick={handleSetNextStep}>
                Next
              </Button>
            )}
          </>
        )}

        {isBP && (
          <>
            {isSubmitRFIFormEnabled ? (
              <Button face="positive" onClick={handleSubmitForm} loading={isFormSubmitting}>
                Submit
              </Button>
            ) : (
              <Button face="positive" onClick={handleManualSaveForm} loading={isFormSubmitting}>
                Save
              </Button>
            )}
          </>
        )}
      </SC.FooterButtons>
    </OnboardingFooter>
  )
})
