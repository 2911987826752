import React, { RefObject, useCallback } from 'react'

import { BsaCtrTransactionLocationInfo } from 'commons/types/DTO/bank/bsa'
import isEqual from 'lodash/isEqual'
import { Button } from 'ui/components/Buttons'
import { PageSection, PageSectionFace } from 'ui/components/Page'

import { TransactionLocationInformationForm } from './TransactionLocationInformationForm'

interface Props {
  value: BsaCtrTransactionLocationInfo[]
  errors: any
  onChange: (value: BsaCtrTransactionLocationInfo[], errors: { [key: string]: any }[]) => any
  validateFunctionRefs: RefObject<any>
  markFormAsDirty: () => void
}

const EMPTY_TRANSACTION_LOCATION_INFO = {
  type: '',
  type_other: undefined,
  federal_regulator: '',
  legal_name: '',
  dba: '',
  ein: '',
  address: '',
  city: '',
  state: '',
  zip_code: '',
  country: '',
  id_type: '',
  id_number: '',
  cash_in: 0,
  cash_out: 0
}

export const TransactionLocationsInformationList = (props: Props) => {
  const { value, onChange, errors, validateFunctionRefs, markFormAsDirty } = props

  const onChangeWrapper = useCallback(
    (i, v, e) => {
      const valueChanged = !isEqual(v, value[i])
      const errorsChanged = !isEqual(e, errors[i])

      if (valueChanged) {
        value[i] = v
      }
      if (errorsChanged) {
        errors[i] = e
      }
      if (valueChanged || errorsChanged) {
        onChange(value, errors)
      }
    },
    [value, onChange, errors]
  )

  const onAddWrapper = useCallback(() => {
    onChange(value.concat(EMPTY_TRANSACTION_LOCATION_INFO), errors)

    markFormAsDirty()
  }, [value, errors, onChange])

  const onDeleteWrapper = useCallback(
    (index: number) => {
      const copy = [...value]
      copy.splice(index, 1)
      onChange(copy, errors)

      markFormAsDirty()
    },
    [value, errors, onChange]
  )

  return (
    <PageSection face={PageSectionFace.THIRD} title="Step 2. Transaction Location(s) Information" anchor="step2">
      {value.map((item, index) => (
        <TransactionLocationInformationForm
          key={index}
          value={item}
          errors={errors[index]}
          onChange={onChangeWrapper}
          onDelete={value.length > 1 ? onDeleteWrapper : undefined}
          index={index}
          total={value.length}
          validateFunctionRefs={validateFunctionRefs}
          markFormAsDirty={markFormAsDirty}
        />
      ))}
      <Button face="positive" onClick={onAddWrapper}>
        Add
      </Button>
    </PageSection>
  )
}
