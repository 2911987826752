import styled from 'styled-components'
import { CheckMark } from 'ui/components/CheckMark'
import { Row } from 'ui/themes/globalStyles'

const InternalCheckMark = styled(CheckMark)`
  margin: 0;
`

const EnlargedRow = styled(Row)`
  min-height: 70px;
  height: auto;
`

export const s = { InternalCheckMark, EnlargedRow }
