import styled from 'styled-components'

export const FormWrapper = styled.div`
  padding: 20px 30px 32px 30px;
`

export const Row = styled.div`
  display: flex;
  align-items: flex-end;

  > * {
    margin-right: 30px;
    width: 230px;
  }
`

export const NoteTooltipContent = styled.div`
  max-width: 214px;
`
