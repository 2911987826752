import { Link } from '@tanstack/react-router'
import styled from 'styled-components'

export const Box = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  color: ${(p) => p.theme.uiKit.colors.global.general.secondary};
  width: 308px;
  height: 76px;
  padding: 12px 30px 12px 30px;
  border-radius: 8px;
  background: ${(p) => p.theme.uiKit.colors.global.grey.grey100};
  transition: all 0.5s;
  &:visited {
    color: ${(p) => p.theme.uiKit.colors.global.general.secondary};
  }
  &:hover {
    background: #fff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    transition: all 0.5s;
    color: ${(p) => p.theme.uiKit.colors.global.general.secondary};
    text-decoration: none;
  }
`

export const Text = styled.span`
  font-size: 18px;
  line-height: 26px;
  max-width: 200px;
`
