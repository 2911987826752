import React, { FC, memo } from 'react'

import { YesNoRadioGroup } from 'commons/types/enums'
import { Info, InfoSet } from 'ui/components/Info'
import { Col2x, Row } from 'ui/themes/globalStyles'

import { OnboardingFormFieldNames } from '../../enums'
import { ProfessionalPartnershipsDetailsProps } from './ProfessionalPartnershipsDetailsProps'

export const ProfessionalPartnershipsDetails: FC<ProfessionalPartnershipsDetailsProps> = memo(
  ({
    value,
    state: {
      isProPartPrevClosureReasonEnable,
      isProPartCurrentServicesOtherDescEnable,
      isProPartCurrentServicesLogisticEnable,
      isProPartCurrentServicesLogisticDescEnable
    }
  }) => {
    // TODO: https://helioscompliance.atlassian.net/browse/MP-7205
    const professionalServices = [
      value[OnboardingFormFieldNames.PRO_PART_CURRENT_SERVICES_CP_AS] && {
        label: 'CPAs',
        selected: value[OnboardingFormFieldNames.PRO_PART_CURRENT_SERVICES_CP_AS]
      },
      value['proPartCurrentServicesLaw'] && {
        label: 'Lawyers/General Counsel',
        selected: value['proPartCurrentServicesLaw']
      },
      value['proPartCurrentServicesLogistic'] &&
        isProPartCurrentServicesLogisticEnable && {
          label: 'Cash Logistic Services (list below)',
          selected: value['proPartCurrentServicesLogistic']
        },
      value['proPartCurrentServicesOther'] && {
        label: isProPartCurrentServicesOtherDescEnable
          ? 'Other (For example, cash processor, physical security, compliance related vendors)'
          : 'Other (describe below)',
        selected: value['proPartCurrentServicesOther']
      }
    ].filter(Boolean)

    // TODO: https://helioscompliance.atlassian.net/browse/MP-7205
    const proPartCurrentlyBanked = [
      { label: value.proPartCurrentlyBanked === YesNoRadioGroup.YES ? 'Yes' : 'No', selected: true }
    ]

    return (
      <InfoSet legend="Professional Partnerships">
        <Row>
          <Col2x>
            <Info
              label="Does the organization currently have an existing banking relationship?"
              content={proPartCurrentlyBanked}
              type="radio"
            />
          </Col2x>
        </Row>
        <Row>
          <Col2x>
            <Info label="What payroll software or service provider is the organization currently utilizing?">
              {value.proPartPayrollSoftware}
            </Info>
          </Col2x>
        </Row>
        {isProPartPrevClosureReasonEnable && (
          <Row>
            <Col2x>
              <Info label="Reason for any previous account closures?">{value.proPartPrevClosureReason}</Info>
            </Col2x>
          </Row>
        )}
        <Row>
          <Col2x>
            <Info
              label="What professional services is the organization currently in contract with or have on staff?"
              content={professionalServices}
              type="checkbox"
            />
          </Col2x>
        </Row>

        {isProPartCurrentServicesLogisticDescEnable && (
          <Row>
            <Col2x>
              <Info label="Cash Logistic Services list">{value.proPartCurrentServicesLogisticDesc}</Info>
            </Col2x>
          </Row>
        )}

        <Row>
          <Col2x>
            <Info label="Other services description">{value.proPartCurrentServicesOtherDesc}</Info>
          </Col2x>
        </Row>
        <Row>
          <Col2x>
            <Info
              label="List any litigation against the business entity in the last ten (10) years and provide a brief 
      summary of each, if any:"
            >
              {value.proPartLitigation}
            </Info>
          </Col2x>
        </Row>
        <Row>
          <Col2x>
            <Info
              label="List any State Enforcement Actions against the MRB in the last ten (10) years and provide a brief
      summary of each, if any:"
            >
              {value.proPartStateEnforcements}
            </Info>
          </Col2x>
        </Row>
      </InfoSet>
    )
  }
)
