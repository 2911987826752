import styled from 'styled-components'

const LongFilename = styled.span`
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const s = { LongFilename }
