import * as React from 'react'

import { getAdverseInformationHitMatchStatusLabel } from './getAdverseInformationHitMatchStatusLabel'

interface Props {
  name: string
}

export const AdverseInformationHitMatchStatusLabel = ({ name }: Props) => (
  <span>{getAdverseInformationHitMatchStatusLabel(name)}</span>
)
