import React, { FC, memo, useCallback, useState } from 'react'

import { Form, useFormData } from 'brief-form'
import { MonthPicker, formatTheDateYall, getRawDate } from 'ui/components'
import { Dialog, DialogFace, DialogFooterActions, DialogOverlay } from 'ui/components/Dialog'
import { Validators } from 'ui/components/Form'
import { Info } from 'ui/components/Info'
import { LeaveConfirmation } from 'ui/components/LeaveConfirmation'
import { FormRow } from 'ui/themes/globalStyles'

import { NewArDialogProps } from './NewArDialogProps'
import { NewArFormValue } from './NewArFormValue'

import * as SC from './styles'

export const AddNewArDialog: FC<NewArDialogProps> = memo((props) => {
  const { closeDialog, onSave, companyName } = props
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { Field, config, isDirty, validate } = useFormData<NewArFormValue>({
    initialValue: {
      financials_start_date: null,
      financials_end_date: null,
      last_ar_date: null
    }
  })

  const originalChangeHandler = config.onChange
  config.onChange = useCallback(
    (v, e) => {
      if (v.financials_start_date && !v.financials_end_date) {
        const start = new Date(formatTheDateYall(v.financials_start_date))
        const end = new Date(start.setMonth(start.getMonth() + 11))
        v.financials_end_date = getRawDate(end)
      }

      originalChangeHandler(v, e)
    },
    [originalChangeHandler]
  )

  const onSaveWrapper = useCallback(async () => {
    if (validate({ updateFields: true }).valid) {
      setIsSubmitting(true)
      try {
        await onSave(config.value)
      } catch (error) {
        setIsSubmitting(false)
      }
    }
    return Promise.resolve()
  }, [onSave, config, validate])

  return (
    <LeaveConfirmation ignoreUrlHashChanging={true} onLeave={closeDialog} preventLeaving={isDirty && !isSubmitting}>
      <Dialog
        id="add-new-ar-dialog"
        title="Create New Annual Review"
        face={DialogFace.PRIMARY}
        overlay={DialogOverlay.DARK}
        headerStyle={undefined}
        onClose={closeDialog}
        headerActions={undefined}
        stickyFooter={undefined}
        footerActions={[DialogFooterActions.ADD, DialogFooterActions.CANCEL]}
        onSubmit={onSaveWrapper}
        isSubmitting={isSubmitting}
      >
        <SC.DialogContent>
          <Form config={config}>
            <Info label="Company">{companyName}</Info>
            <FormRow>
              <SC.Column>
                <Field
                  required
                  name="financials_start_date"
                  label="Financials Period Start"
                  input={MonthPicker}
                  validator={Validators.required.field}
                  inputProps={{ 'data-qa': 'Financials Period Start' }}
                />
              </SC.Column>
            </FormRow>
            <FormRow>
              <SC.Column>
                <Field
                  required
                  name="financials_end_date"
                  label="Financials Period End"
                  input={MonthPicker}
                  validator={Validators.required.field}
                  inputProps={{ 'data-qa': 'Financials Period End' }}
                />
              </SC.Column>
            </FormRow>
          </Form>
        </SC.DialogContent>
      </Dialog>
    </LeaveConfirmation>
  )
})
