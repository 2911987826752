import { useEffect } from 'react'

import { useFormData } from 'brief-form'
import { useSalesDataSourceSaveRejectionReason } from 'commons/hooks/bank/salesData'
import { SalesDataSourceWebPage } from 'commons/types/DTO/bank/salesDataSourceWebPage'
import { handlerServerError } from 'errors'
import { Toast } from 'ui/components'

export const useRejectionReason = (webPageData: SalesDataSourceWebPage, invalidate: () => Promise<void>) => {
  const { config, Field } = useFormData<{ rejectionReason: string }>({
    initialValue: { rejectionReason: '' }
  })

  useEffect(() => {
    if (webPageData) {
      config.onChange({ rejectionReason: webPageData.infoRejectionReason }, config.errors || {})
    }
  }, [webPageData?.infoRejectionReason])

  const { routine: saveRejectionNotes, isLoading: isRejectionNotesSubmitting } = useSalesDataSourceSaveRejectionReason({
    onSuccess: async () => {
      invalidate()
      Toast.successSmall('Rejection Notes has been saved.')
    },
    onError: (error) => handlerServerError(error)
  })

  return {
    config,
    Field,
    isRejectionNotesSubmitting,
    saveRejectionNotes: () =>
      saveRejectionNotes({ id: webPageData.salesDataSourceID, rejectionReason: config.value.rejectionReason })
  }
}
