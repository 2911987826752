import SC, { css } from 'styled-components'
import { Icon } from 'ui/components/Icon'
import { Panel } from 'ui/components/Panel'

const TitleCss = css`
  font-weight: normal;
  font-size: 22px;
  line-height: 22px;
  margin: 19px 0;
  display: flex;
  align-items: center;

  > a {
    color: ${(p) => p.theme.uiKit.colors.global.grey.grey500};
    text-decoration: none;
    font-size: 26px;
    line-height: 26px;
  }
`

const ArrowCss = css`
  margin: 0 20px;
  opacity: 0.3;
  position: relative;
  top: -5px;
  line-height: 26px;
  font-size: 26px;
`

const TablePanelCss = css`
  margin-bottom: 70px;
`

export const Title = SC.span`${TitleCss}`

export const Arrow = SC(Icon)`${ArrowCss}`

export const TablePanel = SC(Panel)`${TablePanelCss}`
