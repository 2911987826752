import React, { FC, memo } from 'react'

import { Form } from 'brief-form'
import { SearchInput } from 'ui/components'
import { DatePicker } from 'ui/components/DatePicker'
import { Select } from 'ui/components/Select'
import { FilterPanel } from 'ui/components/Table'
import { FilterWrapper } from 'ui/temp/Filter'
import { TableFilterRow } from 'ui/temp/TableFilterRow'

import { FilterProps } from './FilterProps'

import * as SC from './styles'

export const Filter: FC<FilterProps> = memo((properties) => {
  const {
    form: { config, isDirty, Field },
    expanded,
    onToggle,
    onClear,
    actionOptions,
    bankUserOptions,
    entityTypeOptions
  } = properties

  return (
    <FilterPanel isDirty={isDirty} onReset={() => onClear()} expanded={expanded} onToggle={onToggle}>
      <Form config={config}>
        <FilterWrapper>
          <TableFilterRow>
            <SC.FixedColumn>
              <Field
                name="entityType"
                label="Entity Type"
                input={Select}
                inputProps={{
                  data: entityTypeOptions,
                  'options-data-qa': 'Entity',
                  'data-qa': 'Entity Type'
                }}
              />
            </SC.FixedColumn>
            <SC.FixedColumn>
              <Field name="entityId" label="Entity ID" input={SearchInput} inputProps={{}} />
            </SC.FixedColumn>
            <SC.FixedColumn>
              <Field
                name="action"
                label="Action Type"
                input={Select}
                inputProps={{
                  data: actionOptions
                }}
              />
            </SC.FixedColumn>

            <div>
              <Field
                name="timeFrom"
                label="Date From"
                input={DatePicker}
                inputProps={{
                  showTimeSelect: true
                }}
              />
            </div>
            <div>
              <Field
                name="timeTo"
                label="Date To"
                input={DatePicker}
                inputProps={{
                  showTimeSelect: true
                }}
              />
            </div>
            <SC.FixedColumn>
              <Field
                name="bankUserId"
                label="Bank User"
                input={Select}
                inputProps={{
                  searchable: true,
                  data: bankUserOptions
                }}
              />
            </SC.FixedColumn>
          </TableFilterRow>
        </FilterWrapper>
      </Form>
    </FilterPanel>
  )
})
