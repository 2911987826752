import { AnyRoute, createRoute, lazyRouteComponent } from '@tanstack/react-router'
import { documentListFilterParamsSchema } from '~bank-documents/types/documentList'

export const documentListRoute = <T extends AnyRoute>(indexRoute: T) =>
  createRoute({
    getParentRoute: () => indexRoute,
    path: '/',
    validateSearch: (search: Record<string, unknown>) => documentListFilterParamsSchema.parse(search),
    component: lazyRouteComponent(() => import('./DocumentList'))
  })
