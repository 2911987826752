import { ChoiceOption } from '../../commons'
import { HistoryEntity } from '../historyAudit'
import { LicenseWebPageRequest } from './LicenseWebPageRequest'

export type LicenseWebPageAddress = {
  city: string
  postalCode: string
  state: string
  street: string
}

export type LicenseWebPageTaxRates = {
  cityTax: number
  countyTax: number
  mJRetailTax: number
  specialTax: number
  stateTax: number
}

export type LicenseWebPageRelationship = {
  id: number
  name: string
}

export type LicenseWebPageInvoiceSubmitalItem = {
  amount: number
  id: number
  notes: string
  number: string
  updatedAt: string
}

export type LicenseWebPageInvoiceSubmital = {
  items: LicenseWebPageInvoiceSubmitalItem[]
  totalCount: number
}

export type LicenseWebPageDocumentItem = {
  alerts: {
    firstAlerted: {
      count: number
      label: string
    }
    initialAlerted: {
      count: number
      label: string
    }
    lastAlerted: {
      count: number
      label: string
    }
    pastDue: {
      count: number
      label: string
    }
  }
  expirationDate: string
  frequency: string
  id: number
  internal: boolean
  name: string
}

export type LicenseWebPageDocuments = {
  filters: {
    alerts: {
      items: [
        {
          label: string
          value: string
        }
      ]
      value: string
    }
    frequency: {
      items: [
        {
          label: string
          value: string
        }
      ]
      value: string
    }
    isInternal: {
      items: [
        {
          label: string
          value: string
        }
      ]
      value: string
    }
  }
  items: LicenseWebPageDocumentItem[]
  size: number
  sortFields: string[]
  total: number
}

export type LicenseWebPageHistory = {
  items: HistoryEntity[]
  totalCount: number
}

export type LicenseWebPageResponse = {
  alertsOptions: ChoiceOption[]
  frequencyOptions: ChoiceOption[]
  internalOptions: ChoiceOption[]
  id: number
  active: boolean
  createdAt: string
  issueDate: string
  name: string
  number: string
  phone: string
  posType: string
  sidebar: string[]
  subType: string
  type: string
  updatedAt: string
  address: LicenseWebPageAddress
  documents: LicenseWebPageDocuments
  history: LicenseWebPageHistory
  invoiceSubmittal: LicenseWebPageInvoiceSubmital
  relationship: LicenseWebPageRelationship
  taxRates: LicenseWebPageTaxRates
  APIConnection: string
  config: LicenseWebPageRequest
}
