import React from 'react'

import { FF, TextInput } from 'ui/components'

import { useSARFormContext } from '../../../../../../context'

type PhoneNumberProps = {
  index: number
}

export const PhoneNumber = ({ index }: PhoneNumberProps) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={`subjects_info[${index}].phone_numbers[0].phone` as 'subjects_info[0].phone_numbers[0].phone'}
      children={(field) => (
        <FF field={field} label="21. Phone number" input={(p) => <TextInput {...p} maxLength={16} />} />
      )}
    />
  )
}
