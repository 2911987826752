import React, { FC, memo, useCallback, useState } from 'react'

import { BeforeFormChangeHandler, useFormData } from 'brief-form'
import { Dialog, DialogFace } from 'ui/components/Dialog'
import { updateFormAfterApiError } from 'ui/components/Form'
import { Toast } from 'ui/components/Toast'

import { Footer } from './Footer'
import { PasswordForm, PasswordFormValue } from './PasswordForm'

export interface ChangePasswordFormProps {
  visible: boolean
  onClose: () => void
  onSubmit: (v: PasswordFormValue) => Promise<any>
}

const INITIAL_FORM_VALUE = {
  oldPassword: '',
  password: '',
  confirmation: ''
}

export const ChangePasswordForm: FC<ChangePasswordFormProps> = memo((props) => {
  const { onClose, onSubmit, visible } = props
  const [isSubmitting, setIsSubmitting] = useState(false)

  const onBeforeChange: BeforeFormChangeHandler<PasswordFormValue> = useCallback(({ value, errors }) => {
    const matchError = value.password === value.confirmation ? undefined : 'Passwords do not match'

    return {
      value,
      errors: {
        ...errors,
        confirmation: matchError
      }
    }
  }, [])

  const form = useFormData<PasswordFormValue>({ initialValue: INITIAL_FORM_VALUE, onBeforeChange })
  const { config, set, validate } = form
  const { value, errors, onChange } = config

  const onCloseWrapper = useCallback(() => {
    set({ reset: true })
    onClose()
  }, [set, onClose])

  const onSubmitWrapper = useCallback(async () => {
    if (!validate({ updateFields: true }).valid) {
      return
    }

    setIsSubmitting(true)

    try {
      await onSubmit(value)
      Toast.successSmall('Password was successfully set.')
      onCloseWrapper()
    } catch (e) {
      updateFormAfterApiError(value, errors, e, onChange)
      Toast.errorSmall('Error setting new password.')
      setIsSubmitting(false)
    } finally {
      setIsSubmitting(false)
    }
  }, [value, errors, onChange, onSubmit, onCloseWrapper, validate])

  if (!visible) {
    return null
  }

  return (
    <Dialog
      id="change-password"
      onClose={onCloseWrapper}
      face={DialogFace.PRIMARY}
      title="Change Password"
      stickyFooter={<Footer onSubmit={onSubmitWrapper} onCancel={onCloseWrapper} isSubmitting={isSubmitting} />}
    >
      <PasswordForm form={form} />
    </Dialog>
  )
})
