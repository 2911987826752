import styled from 'styled-components'

import { InfoSetDirection } from './infoSetDirection'

export const Box = styled.div`
  padding: 20px 0;
  min-width: 0;
  margin-bottom: 30px;
`

export const Legend = styled.h5`
  color: ${(p) => p.theme.uiKit.colors.global.general.secondary};
  padding: 20px 0 20px 0;
`

export const Body = styled.div<{ $direction?: InfoSetDirection }>`
  display: flex;
  flex-direction: ${(p) => (p.$direction === InfoSetDirection.COLUMN ? 'column' : 'row')};
  max-width: ${(p) => (p.$direction === InfoSetDirection.ROW ? '680px' : 'auto')};
  flex-wrap: wrap;
  justify-content: flex-start;
`
