import { OnboardingFormState } from '../../../types/state'
import { emptyAddressInformation, emptyLicenseDetails, getEmptyLicense } from './emptyEntity/getEmptyLicense'
import { setNewCompanies } from './setNewCompanies'

export const getStateAfterAddedLicense = ({
  currentState,
  companyIndex
}: {
  companyIndex: number
  currentState: OnboardingFormState
}) => {
  const selectedCompany = currentState.formValue.companiesFormValues[companyIndex]
  const oldLicenses = selectedCompany.licenses
  const newLicense = getEmptyLicense({ license: { ...emptyLicenseDetails, ...emptyAddressInformation } })
  const newLicenses = [...oldLicenses, newLicense]
  const newCompany = { ...selectedCompany, licenses: newLicenses }
  const newCompaniesFormValues = setNewCompanies({
    companyIndex,
    oldCompanies: currentState.formValue.companiesFormValues,
    newCompany
  })
  return {
    formValue: {
      ...currentState.formValue,
      companiesFormValues: newCompaniesFormValues
    }
  }
}
