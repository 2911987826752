import { FormPaths } from '../../../enums'
import { OnChangeAdditionParams } from '../../../types/actions'
import { FormData } from '../../../types/forms'
import { OnboardingFormState } from '../../../types/state'
import { calculateFormValue } from './calculateFormValue'

export const calculateNewState = ({
  currentState,
  path,
  formData,
  params
}: {
  currentState: OnboardingFormState
  path: FormPaths
  formData: FormData
  params: OnChangeAdditionParams
}): OnboardingFormState => {
  // calclulate form values
  let newStateValue = calculateFormValue({ currentState, path, formData, params })
  newStateValue = newStateValue ? newStateValue : currentState

  return {
    ...currentState,
    ...newStateValue
  }
}
