import styled from 'styled-components'
import { Icon } from 'ui/components/Icon'

export const SelectRow = styled.div`
  margin-bottom: 20px;
`

export const Row = styled.div`
  background: #f5f5f5;
  border-radius: 3px;
  display: inline-flex;
  align-items: center;
  padding: 5px;
  font-size: 14px;
  margin: 0 14px 14px 0;
`

export const AddIcon = styled(Icon)`
  margin-left: 9px;
  cursor: pointer;
`

export const DeleteIcon = styled(Icon)`
  margin: -3px 9px 0 0;
  cursor: pointer;
  path {
    fill: #868686;
  }
`

export const Title = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: ${(p) => p.theme.uiKit.colors.global.grey.grey700};
  margin: 0 0 10px 0;
`

export const Info = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 20px;
`

export const LicenseCount = styled.div`
  background: #868686;
  width: 22px;
  height: 22px;
  text-align: center;
  border-radius: 50%;
  line-height: 22px;
  font-size: 14px;
  color: #fff;
  position: absolute;
  right: 28px;
`
