import { UseFormDataReturnType } from 'brief-form'
import { GetClientUserListResponse } from 'commons/types/contracts/api/bank/clientUser'

import { FilterFormValue } from '../../types'

export interface ClientUsersPageProps {
  data?: GetClientUserListResponse
  isFetching: boolean
  filterForm: UseFormDataReturnType<FilterFormValue>
  reloadClientUserList: () => void
  clearHandler: () => void
}
