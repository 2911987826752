import React, { FC } from 'react'

import { FieldProps, FormInputProps } from 'brief-form'
import { AnnualReview, Company } from 'commons/types/DTO/bank'
import { DatePicker } from 'ui/components'
import { DateTime } from 'ui/components/DateTime'
import { HeadingTitle } from 'ui/components/HeadingTitle'
import { Info, InfoSet, InfoSetDirection } from 'ui/components/Info'
import { AnnualReviewStatusLabel } from 'ui/components/Labels'

import { AnnualReviewFormValue } from '../../AnnualReviewFormValue'

import * as SC from './styles'

interface AnnualReviewEditOverviewProps {
  annualReview: AnnualReview
  company: Company
  Field: <Input extends React.ComponentType<FormInputProps<any, any>>>(
    props: FieldProps<Input, AnnualReviewFormValue>
  ) => JSX.Element
  affiliatedCompanies: Company[]
}

export const AnnualReviewEditOverview: FC<AnnualReviewEditOverviewProps> = ({
  annualReview,
  company,
  Field,
  affiliatedCompanies
}) => (
  <SC.Container>
    <HeadingTitle large>Overview</HeadingTitle>
    <InfoSet direction={InfoSetDirection.ROW}>
      <SC.InfoColumn>
        <Info label="Relationship Name">{company?.name || '---'}</Info>
        <Info label="Licensed Entity Legal Name">{company?.legal_name || '---'}</Info>
        <Info label="Date of Initial Approval">
          {annualReview.created_at ? <DateTime date={annualReview.created_at} /> : '---'}
        </Info>
        <Info label="Status">
          <AnnualReviewStatusLabel
            name={annualReview.status ?? ''}
            created={Date.parse(annualReview.created_at ?? '')}
            updated={Date.parse(annualReview.updated_at ?? '')}
            data-qa="Status"
          />
        </Info>
      </SC.InfoColumn>

      <SC.InfoColumn>
        <Info label="DBA (if applicable)">{company?.dba || '---'}</Info>
        <Info label="Related Companies">
          {affiliatedCompanies.length ? affiliatedCompanies.map((c) => c.name).join(', ') : '-'}
        </Info>
        <Info label="Financials Period Start">
          <DateTime date={annualReview.financials_start_date} />
        </Info>
        <Info label="Financials Period End">
          <DateTime date={annualReview.financials_end_date} />
        </Info>
        <Field name="last_ar_date" input={DatePicker} label="Date of Last Annual Review" inputProps={{}} />
      </SC.InfoColumn>
    </InfoSet>
  </SC.Container>
)
