/*
/documents
	/ (list)
	/new
	/$documentId (details)
		/edit
		/new-period (not a page, just modal)
    /edit-period/$periodId

		/periods/$periodId page
		/periods/$periodId/edit (not a page, just modal)
*/
import { createRoute, redirect } from '@tanstack/react-router'

import { BankDocumentsWrapper } from './BankDocumentsWrapper'
import { DocumentsPaths } from './constants'
import { createDocumentRoute } from './routes/CreateDocument'
import { createPeriodRoute } from './routes/CreatePeriod'
import { documentDetailsRoute } from './routes/DocumentDetails'
import { documentListRoute } from './routes/DocumentList'
import { editDocumentRoute } from './routes/EditDocument'
import { editPeriodRoute } from './routes/EditPeriod'
import { periodDetailsRoute } from './routes/PeriodDetails'

export const routeDocumentsTree = (rootRoute: BPRootRoute) => {
  const documentIndexRoute = createRoute({
    beforeLoad: async ({ context }) => {
      if (!context.accessibleRoutingFlags.documentRootAccess) {
        throw redirect({
          to: '/relationships'
        })
      }
    },
    path: DocumentsPaths.LIST,
    component: BankDocumentsWrapper,
    getParentRoute: () => rootRoute
  })

  const documentDetails = documentDetailsRoute<typeof documentIndexRoute>(documentIndexRoute)

  documentDetails.addChildren([
    editPeriodRoute<typeof documentDetails>(documentDetails, true),
    createPeriodRoute<typeof documentDetails>(documentDetails)
  ])

  const periodDetails = periodDetailsRoute<typeof documentIndexRoute>(documentIndexRoute)

  periodDetails.addChildren([editPeriodRoute<typeof periodDetails>(periodDetails, false)])

  return documentIndexRoute.addChildren([
    documentListRoute<typeof documentIndexRoute>(documentIndexRoute),
    editDocumentRoute<typeof documentIndexRoute>(documentIndexRoute),
    createDocumentRoute<typeof documentIndexRoute>(documentIndexRoute),
    periodDetails,
    documentDetails
  ])
}
