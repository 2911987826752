import { Atom } from './Atom'
import { Key } from './Key'
import { Scope } from './Scope'

export const buildKey = (scope: Scope, atom: Atom, opts?: unknown): Key => {
  if (!opts) {
    return [scope, atom]
  }
  return [scope, atom, opts]
}
