import { CompanyFormValues } from '../../../types/state'

export const setNewCompanies = ({
  oldCompanies,
  newCompany,
  companyIndex
}: {
  newCompany: CompanyFormValues
  oldCompanies: CompanyFormValues[]
  companyIndex: number
}) =>
  oldCompanies.map((companiesFormValueItem, index) => (index === companyIndex ? newCompany : companiesFormValueItem))
