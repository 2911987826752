import React from 'react'

import { BsaBatch } from 'commons/types/DTO/bank/bsa'
import { bsaBatchStatuses } from 'commons/types/dictionaries'
import { getBatchFileName } from 'commons/utils'
import { Request } from 'commons/utils/request'
import styled from 'styled-components'
import { IconButton, TextButton } from 'ui/components/Buttons'
import { CheckBox } from 'ui/components/CheckBox'
import { formatDateTime } from 'ui/components/DateTime'
import { DownloadLink } from 'ui/components/DownloadLink'
import { IconName, Link } from 'ui/components/Link'
import { TableColumn } from 'ui/components/Table'
import { Toast } from 'ui/components/Toast'
import { UploadBatchPdfButton } from 'ui/components/UploadBatchPdfButton'

import { Tag } from '../../../../../components/Tag'
import { BSAStatus } from '../../../../../types'

const WrappedCheckBox = styled(CheckBox)`
  margin: 0;

  & > span {
    margin: 0;
  }
`

const TableButton = styled(TextButton)`
  padding: 0 10px;
`

const LongFilename = styled.span`
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 1920px) {
    max-width: calc((100vw / 10) - 20px);
  }

  @media (max-width: 1650px) {
    max-width: calc((100vw / 10) - 60px);
  }

  @media (max-width: 1470px) {
    max-width: 70px;
  }
`

type Opts = {
  onShowBatch: (item: any) => void
  onItemSelect: (item?: any) => void
  onDelete: (item: BsaBatch) => void
  chosenItems: any[]
  isAllItemsChosen: boolean
}

const handleError = (message: string) => Toast.error('Error', message)

export const columns = (opts: Opts) => (): TableColumn<any>[] => {
  const baseUrl = Request.urls.bank.batchFileDownload
  return [
    {
      title: (
        <WrappedCheckBox checkBoxLabel="" value={opts.isAllItemsChosen} onChange={() => opts.onItemSelect(null)} />
      ),
      align: 'center',
      sortable: false,
      render: (item) => (
        <WrappedCheckBox
          checkBoxLabel=""
          value={opts.chosenItems.findIndex((i) => i.id === item.id) !== -1}
          onChange={() => opts.onItemSelect(item)}
        />
      )
    },
    {
      title: 'ID',
      align: 'left',
      render: ({ id }) => (
        <Link to="/bsa/batch-details/$id" params={{ id }}>
          {id}
        </Link>
      )
    },
    {
      title: 'CTRs Date',
      align: 'center',
      render: ({ start_date }) => {
        const ctrsDate = formatDateTime(start_date)
        return (
          <Link to="/bsa/ctrs" search={{ trans_date: ctrsDate }}>
            {ctrsDate}
          </Link>
        )
      }
    },
    {
      title: 'Status',
      align: 'left',
      render: ({ status }) => {
        const type = bsaBatchStatuses.find((t) => t.value === status)
        return type ? <Tag status={type.value as BSAStatus} /> : '-'
      }
    },
    {
      title: 'Batch XML',
      align: 'left',
      sortable: false,
      render: ({ xml_key, id }) => {
        const fileName = xml_key && getBatchFileName(xml_key)

        if (fileName) {
          return (
            <DownloadLink
              direct
              parametersForm={{ id, file_type: 'xml' }}
              baseUrl={baseUrl}
              name={fileName}
              handleError={handleError}
            >
              <LongFilename title={fileName}>{fileName}</LongFilename>
            </DownloadLink>
          )
        }

        return null
      }
    },
    {
      title: 'Batch PDF',
      align: 'left',
      sortable: false,
      render: ({ ctrx_pdf_key, id }) => {
        const fileName = ctrx_pdf_key && getBatchFileName(ctrx_pdf_key)

        if (fileName) {
          return (
            <DownloadLink
              direct
              parametersForm={{ id, file_type: 'ctrx' }}
              baseUrl={baseUrl}
              name={fileName}
              handleError={handleError}
            >
              <LongFilename title={fileName}>{fileName}</LongFilename>
            </DownloadLink>
          )
        }

        return <UploadBatchPdfButton batchId={id} isAcknowledgments={false} />
      }
    },
    {
      title: 'Acknowledgments PDF',
      align: 'left',
      sortable: false,
      render: ({ acknowledgments_key, id }) => {
        const fileName = acknowledgments_key && getBatchFileName(acknowledgments_key)

        if (fileName) {
          return (
            <DownloadLink
              direct
              parametersForm={{ id, file_type: 'acknowledgments' }}
              baseUrl={baseUrl}
              name={fileName}
              handleError={handleError}
            >
              <LongFilename title={fileName}>{fileName}</LongFilename>
            </DownloadLink>
          )
        }

        return <UploadBatchPdfButton batchId={id} isAcknowledgments />
      }
    },
    {
      title: 'CTRs',
      align: 'center',
      sortable: false,
      render: (item) =>
        item.ctrs_info && item.ctrs_info.length ? (
          <TableButton onClick={() => opts.onShowBatch(item)}>{item.ctrs_info.length}</TableButton>
        ) : null
    },
    {
      title: 'BSA Tracking ID',
      key: 'tracking_id',
      align: 'left'
    },
    {
      title: 'Action',
      align: 'center',
      sortable: false,
      render: (item) => {
        if (item.status === BSAStatus.NEW) {
          return (
            <IconButton icon={IconName.DELETE} face="negative" onClick={() => opts.onDelete(item)}>
              Delete
            </IconButton>
          )
        }

        return null
      }
    }
  ]
}
