import React, { memo } from 'react'

import { BsaSar } from 'commons/types/DTO/bank/bsa'
import { sanitize } from 'dompurify'
import { Info, InfoSet, InfoSetDirection } from 'ui/components/Info'
import { PageSection, PageSectionFace } from 'ui/components/Page'

interface Props {
  item: BsaSar
}

export const Narrative = memo(({ item }: Props) => (
  <PageSection face={PageSectionFace.THIRD} title="Step 5. Narrative" anchor="step5">
    <InfoSet direction={InfoSetDirection.COLUMN}>
      <Info>
        <div
          dangerouslySetInnerHTML={{
            __html: item.narrative ? sanitize(item.narrative).replace(/\n/g, '<br />') : '--'
          }}
        />
      </Info>
    </InfoSet>
  </PageSection>
))
