import SC from 'styled-components'
import { Button } from 'ui/components/Buttons'

export const FormBody = SC.div`
  padding: 28px 42px;
`

export const Question = SC.div`
  margin-bottom: 28px;
`

export const Footer = SC.div`
  margin-top: 20px;
  display: flex;
`

export const NextButton = SC(Button)`
  margin-right: 10px;
`
