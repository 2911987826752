import styled from 'styled-components'
import { CSS } from 'ui/helpers'

export const Notes = styled.div`
  white-space: nowrap;
  overflow: hidden;
  max-width: 330px;
  text-overflow: ellipsis;
  display: inline-block;
`

export const NotesTooltip = styled.div`
  max-width: 214px;
`

export const ItemFilesEmptyMessage = styled.div`
  ${(p) => CSS.populateFont(p.theme.uiKit.typography.special.tip)};
  color: ${(p) => p.theme.uiKit.colors.global.grey.grey700};
`

export const Subtitle = styled.div`
  ${(p) => CSS.populateFont(p.theme.uiKit.typography.bodyL.regular)}
`
