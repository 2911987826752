import { createRoute, redirect } from '@tanstack/react-router'
import { COMPANIES_PATH } from 'commons/constants/routes'

import BankCompaniesWrapper from './BankCompaniesWrapper'
import { companiesListRoute } from './Routes/CompaniesList/route'
import { companyDetailsRoute } from './Routes/CompanyDetails/route'
import { createCompanyRoute, editCompanyRoute } from './Routes/ManageCompany/route'

export const routeCompaniesTree = (rootRoute: BPRootRoute) => {
  const companiesIndexRoute = createRoute({
    beforeLoad: async ({ context }) => {
      if (!context.accessibleRoutingFlags.companyRootAccess) {
        if (context.accessibleRoutingFlags.onboardingRootAccess) {
          throw redirect({
            to: '/onboarding/applications'
          })
        } else
          throw redirect({
            to: '/account-settings'
          })
      }
    },
    getParentRoute: () => rootRoute,
    path: COMPANIES_PATH,
    component: BankCompaniesWrapper
  })

  return companiesIndexRoute.addChildren([
    createCompanyRoute<typeof companiesIndexRoute>(companiesIndexRoute),
    editCompanyRoute<typeof companiesIndexRoute>(companiesIndexRoute),
    companyDetailsRoute<typeof companiesIndexRoute>(companiesIndexRoute),
    companiesListRoute<typeof companiesIndexRoute>(companiesIndexRoute)
  ])
}
