import { notFound } from '@tanstack/react-router'
import { ApiError } from 'commons/types'
import isInteger from 'lodash/isInteger'

import { getApplicationDocumentWebPage } from './services'

export const applicationDocumentLoader = async ({ params, deps }) => {
  const { applicationId, documentId } = params
  const { historyPage, historySize } = deps

  const documentIdRequest = parseInt(documentId, 10)
  const applicationIdRequest = parseInt(applicationId, 10)

  if (!isInteger(documentIdRequest) || !isInteger(applicationIdRequest)) {
    throw notFound()
  }

  let webPage = null

  try {
    webPage = await getApplicationDocumentWebPage(documentIdRequest, historyPage, historySize)
  } catch (e) {
    if (e instanceof ApiError && e.httpCode === 400) {
      // let's show 404
      webPage = null
    }
    throw e
  }

  if (!webPage) {
    throw notFound()
  }

  return { webPage, applicationId: applicationIdRequest, documentId: documentIdRequest }
}
