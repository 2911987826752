import { LoggerInterface } from './loggerInterface'

export class ConsoleLogger implements LoggerInterface {
  active = true

  log(message: string, ...args): void {
    if (this.active) {
      console.log(message, ...args)
    }
  }

  info(message: string, ...args): void {
    if (this.active) {
      const styles = ['font-size: 16px', 'color: black']
      console.info('%c' + message, styles.join(';'), ...args)
    }
  }

  warning(message: string, ...args): void {
    if (this.active) {
      const styles = ['font-size: 16px', 'color: blue']
      console.warn('%c' + message, styles.join(';'), ...args)
    }
  }

  error(message: string, ...args): void {
    if (this.active) {
      const styles = ['font-size: 16px', 'color: red']
      console.error('%c' + message, styles.join(';'), ...args)
    }
  }
}
