import styled from 'styled-components'
import { Icon } from 'ui/components/Icon'

export const Box = styled.div`
  background: #f5f5f5;
  border-radius: 3px;
  display: inline-flex;
  align-items: center;
  padding: 5px;
  font-size: 14px;
  margin: 0 14px 14px 0;
`

export const DeleteIcon = styled(Icon)`
  margin: -3px 9px 0 0;
  cursor: pointer;
  path {
    fill: #868686;
  }
`
