export enum HistoryEntityType {
  DOCUMENT = 1,
  COMPANY = 2,
  LICENSE = 3,
  BSA_CTR = 4,
  INTERNAL_TRANSFER = 5,
  DOCUMENT_FILE = 6,
  DOCUMENT_PERIOD = 7,
  DOCUMENT_TEMPLATE = 8,
  BSA_BATCH = 9,
  BSA_CTR_BATCH = 10,
  BSA_SETTING = 11,
  BSA_DDA_SUBJECT = 12,
  BSA_CIF_SUBJECT = 13,
  INTERNAL_TRANSFERS_EXPORT = 14,
  ADVERSE_INFORMATION_SEARCH = 15,
  ADVERSE_INFORMATION_HIT = 16,
  CONTACT_METADATA = 17,
  CUSTOMERS_COMPANIES = 18,
  VENDORS_COMPANIES = 19,
  ANNUAL_REVIEW = 20,
  AFFILIATED_COMPANY = 21,
  BSA_SAR = 22,
  RETAIL_REPORT = 23,
  WHOLESALE_REPORT = 24,
  TAX_RECONCILIATION_REPORT = 25,
  BANK_ACCOUNT = 26,
  TASK = 27,
  BANK_ACCOUNT_LICENSES = 28,
  REPORT_MONTHLY = 29,
  REPORT_QUARTERLY = 30,
  ADVERSE_MEDIA_CASE = 31,
  ADVERSE_MEDIA_PUBLICATION = 32,
  CONTACT = 33,
  HISTORY_ENTITY_INVOICE_SUBMITTAL = 34,
  REPORT_FILE = 35,
  APPLICATION = 36,
  METRC_CREDENTIALS = 37,
  SALES_DATA = 38,
  BANK_USER = 44,
  CLIENT_USER = 45,
  APPLICATION_DOCUSIGN_ENVELOPE = 46,
  APPLICATION_DOCUMENT = 47,
  APPLICATION_DOCUMENT_TEMPLATE = 48,
  APPLICATION_CLIENT_DOCUMENT = 49,
  APPLICATION_CLIENT_DOCUMENT_FILE = 50
}
