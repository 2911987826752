import React, { FC, memo, useCallback, useState } from 'react'

import { Icon, IconName } from 'ui/components/Icon'
import { Link } from 'ui/components/Link'

import { DocumentNameDialog } from '../DocumentNameDialog'
import { DocumentFormFilesProps } from './DocumentFormFilesProps'

import * as SC from './styles'

export const DocumentFormFiles: FC<DocumentFormFilesProps> = memo((props) => {
  const { files, companyName, documentName, readonly, periodId, onFilesAdd, isLoading, isDeleting, onDelete } = props
  const [visible, setVisible] = useState(false)
  const handleClose = useCallback(() => setVisible(false), [])
  const onClick = useCallback(() => setVisible(true), [])

  return (
    <div>
      <SC.ItemFiles>
        <Icon name={IconName.FILES} />
        <Link tabIndex={-1} to={''} onClick={onClick} face="brand">
          {files.length} files have been uploaded
        </Link>
      </SC.ItemFiles>
      <DocumentNameDialog
        companyName={companyName}
        visible={visible}
        onClose={handleClose}
        documentName={documentName}
        files={files}
        readonly={readonly}
        periodId={periodId}
        onFilesAdd={onFilesAdd}
        isLoading={isLoading}
        isDeleting={isDeleting}
        onDelete={onDelete}
      />
    </div>
  )
})
