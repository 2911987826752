import styled from 'styled-components'

export const Title = styled.span`
  font-size: 24px;
  line-height: 32px;
  white-space: initial !important;
`

export const Content = styled.div`
  padding: 30px;
  line-height: 22px;
  max-width: 800px;

  & mark {
    background-color: rgba(119, 224, 200, 0.4);
  }
`

export const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  margin-top: 25px;
`

export const InfoRow = styled.span`
  font-size: 14px;
  line-height: 17px;
  color: #868686;
`
