import { Request } from 'commons/utils/request/request'

export type FilterAlertsWebPage = {
  CTRsLimit?: number
  CTRsOffset?: number
  SARsLimit?: number
  SARsOffset?: number
  SARsRelationshipsScope?: string

  adverseMediaLimit?: number
  adverseMediaOffset?: number
  adverseMediaCompanyID?: number | null

  annualReviewsLimit?: number
  annualReviewsOffset?: number
  annualReviewsStatus?: string | null
  annualReviewsCompanyID?: number | null

  applicationsLimit?: number
  applicationsOffset?: number
  applicationsRelationshipsScope?: string
  applicationsSortDesc?: boolean
  applicationsSortBy?: string
  bsaBatchesLimit?: number
  bsaBatchesOffset?: number

  uploadsSortBy?: string
  uploadsSortDesc?: boolean
  uploadsLimit?: number
  uploadsOffset?: number
  uploadsCompanyID?: number
  uploadsType?: string

  monthlyAnalyticsLimit?: number
  monthlyAnalyticsOffset?: number
  monthlyAnalyticsCompanyID?: number | null

  quarterlyAnalyticsLimit?: number
  quarterlyAnalyticsOffset?: number
  quarterlyAnalyticsCompanyID?: number | null
}

export const getAlertsWebPage = (filter: FilterAlertsWebPage) =>
  Request.post(Request.urls.bank.alertsView, { ...filter })
