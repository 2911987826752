import { BPRouterItems } from 'commons/types/enums'

export const getBPAccessibleRoutingFlags = (accessibleRoutes: BPRouterItems[]): BPAccessibleRoutingFlagsType => ({
  companyRootAccess: accessibleRoutes.includes(BPRouterItems.COMPANIES),
  quarterlyReportRootAccess: accessibleRoutes.includes(BPRouterItems.COMPANIES),
  monthlyReportRootAccess: accessibleRoutes.includes(BPRouterItems.COMPANIES),
  salesdataRootAccess: accessibleRoutes.includes(BPRouterItems.COMPANIES),
  annualReviewRootAccess: accessibleRoutes.includes(BPRouterItems.COMPANIES),
  licenseRootAccess: accessibleRoutes.includes(BPRouterItems.COMPANIES),
  bankInvoiceRootAccess: accessibleRoutes.includes(BPRouterItems.COMPANIES),
  bankAccountRootAccess: accessibleRoutes.includes(BPRouterItems.COMPANIES),
  alertRootAccess: accessibleRoutes.includes(BPRouterItems.ALERTS),
  documentRootAccess: accessibleRoutes.includes(BPRouterItems.DOCUMENTS),
  contactRootAccess: accessibleRoutes.includes(BPRouterItems.CONTACTS),
  userRootAccess: accessibleRoutes.includes(BPRouterItems.USERS),
  onboardingRootAccess: accessibleRoutes.includes(BPRouterItems.APPLICATIONS),
  historyRootAccess: accessibleRoutes.includes(BPRouterItems.HISTORY),
  bsaRootAccess: accessibleRoutes.includes(BPRouterItems.BSA),
  adverseMediaRootAccess: accessibleRoutes.includes(BPRouterItems.ADVERSE_MEDIA),
  reportRootAccess: accessibleRoutes.includes(BPRouterItems.REPORTS),
  internalTransferRootAccess: accessibleRoutes.includes(BPRouterItems.INTERNAL_TRANSFERS),
  accountSettingRootAccess: accessibleRoutes.includes(BPRouterItems.ACCOUNT_SETTINGS),
  nextCloudRootAccess: accessibleRoutes.includes(BPRouterItems.NEXT_CLOUD_LOGIN),
  connectionRootAccess: accessibleRoutes.includes(BPRouterItems.CONNECTIONS),
  clientUserRootAccess: accessibleRoutes.includes(BPRouterItems.CLIENT_USER_MANAGEMENT),
  setupOnboardingDocumentsRootAccess: accessibleRoutes.includes(BPRouterItems.SETUP_ONBOARDING_DOCUMENTS)
})
