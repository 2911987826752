import React from 'react'

import { CheckBox, FF } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

export const FilingTypeJoint = () => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name="filing_type_joint"
      children={(field) => <FF field={field} input={(p) => <CheckBox {...p} checkBoxLabel="Joint report" />} />}
    />
  )
}
