import React, { FC, memo } from 'react'

import { YesNoRadioGroup } from 'commons/types/enums'
import { Info, InfoSet } from 'ui/components/Info'
import { RadioGroup } from 'ui/components/RadioGroup'

import { RoleInformationFormValue } from '../../types/forms'
import { RoleInformationFormState } from '../../types/state'

type RoleInformationDetailsProps = {
  value: RoleInformationFormValue
  state: RoleInformationFormState
}

export const RoleInformationDetails: FC<RoleInformationDetailsProps> = memo(
  ({ value, state: { isConductorRoleEnable } }) => (
    <InfoSet legend="Role Information">
      <Info
        label={
          <>
            Is this individual/entity an&nbsp;<b>Owner</b>?
          </>
        }
      >
        <RadioGroup
          readonly
          value={value.owner}
          data={[
            { value: YesNoRadioGroup.YES, label: 'Yes' },
            { value: YesNoRadioGroup.NO, label: 'No' }
          ]}
        />
      </Info>
      <Info
        label={
          <>
            Is this individual/entity an&nbsp;<b>Debtholder</b>?
          </>
        }
      >
        <RadioGroup
          readonly
          value={value.debtholder}
          data={[
            { value: YesNoRadioGroup.YES, label: 'Yes' },
            { value: YesNoRadioGroup.NO, label: 'No' }
          ]}
        />
      </Info>
      <Info
        label={
          <>
            Is this individual/entity an&nbsp;<b>Account Signer</b>?
          </>
        }
      >
        <RadioGroup
          readonly
          value={value.accountSigner}
          data={[
            { value: YesNoRadioGroup.YES, label: 'Yes' },
            { value: YesNoRadioGroup.NO, label: 'No' }
          ]}
        />
      </Info>
      {isConductorRoleEnable && (
        <Info
          label={
            <>
              Is this individual/entity an&nbsp;<b>Conductor</b>?
            </>
          }
        >
          <RadioGroup
            readonly
            value={value.conductor}
            data={[
              { value: YesNoRadioGroup.YES, label: 'Yes' },
              { value: YesNoRadioGroup.NO, label: 'No' }
            ]}
          />
        </Info>
      )}
    </InfoSet>
  )
)
