import { AccountDetails } from 'commons/types/DTO/commons'

import { AccountDetailsFormValue } from '../../../../types'

export const mapAccountDetailsForm = ({
  businessType,
  entityType,
  stateOfIncorporation,
  EIN,
  preLicenseMRBInfo
}: AccountDetailsFormValue): AccountDetails => ({
  businessType,
  entityType,
  stateOfIncorporation,
  EIN,
  preLicenseMRBInfo
})
