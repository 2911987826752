export class HistoryAuditListFilter {
  action: string | undefined
  bankUserId: string | undefined
  entityType: string | undefined
  entityId: string | undefined
  page = 1
  size = 10
  timeFrom: Date | string | undefined
  timeTo: Date | string | undefined

  constructor(initValue: Partial<HistoryAuditListFilter>) {
    this.action = initValue?.action
    this.bankUserId = initValue?.bankUserId
    this.entityType = initValue?.entityType
    this.entityId = initValue?.entityId
    this.page = initValue?.page ? Number(initValue?.page) : this.page
    this.size = initValue?.size ? Number(initValue?.size) : this.size
    this.timeFrom = initValue?.timeFrom instanceof Date ? new Date(initValue?.timeFrom).toISOString() : undefined
    this.timeTo = initValue?.timeTo instanceof Date ? new Date(initValue?.timeTo).toISOString() : undefined
  }
}
