import * as React from 'react'

import { Label, Question, Value } from './AnnualReviewQuestionStyles'

export interface AnnualReviewQuestionProps {
  label: string
  children: React.ReactNode
  bottomIndent?: boolean
  multiline?: boolean
  labelPadding?: boolean
}

export const AnnualReviewQuestion: React.FC<AnnualReviewQuestionProps> = ({
  label,
  bottomIndent = true,
  labelPadding,
  multiline,
  children
}: AnnualReviewQuestionProps) => (
  <Question $bottomIndent={bottomIndent}>
    <Label $labelPadding={labelPadding}>{label}</Label>
    {multiline ? <Value>{children}</Value> : <div>{children}</div>}
  </Question>
)
