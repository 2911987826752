/* eslint-disable camelcase */

export type InternalTransfersExportList = {
  CSVFileS3Key: string
  createdAt: string
  creditFileS3Key: string
  debitFileS3Key: string
  id: number
  status: string
  enableProcess: boolean
  processedAt?: string
}
