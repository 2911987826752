import { useCallback, useState } from 'react'

import { IWarnings } from 'bank-bsa-reporting/src/routes/CTR/List'
import { CTRTransaction } from 'bank-bsa-reporting/src/types'
import { FilterAlertsWebPage } from 'commons/service/bank/alerts'
import { useTableState } from 'ui/components'

export const useComponentsEssence = (onAlertFilterChange: (filter: FilterAlertsWebPage) => void) => {
  const [modalItem, setModalItem] = useState<{ transactions: CTRTransaction[]; ctrId: number; ctrName: string }>({
    transactions: [],
    ctrId: 0,
    ctrName: ''
  })
  const [warnings, setWarnings] = useState<IWarnings>({
    warnings: null,
    warningsType: null
  })
  const [loading, setLoading] = useState(false)

  const onShowWarnings = useCallback(
    (data: string, warningsType: string) =>
      setWarnings({
        warningsType: warningsType,
        warnings: data
      }),
    [setWarnings]
  )

  const tableState = useTableState({
    filter: {}
  })

  const changePagination = useCallback(
    async (page: number, size: number) => {
      setLoading(true)

      tableState.onPageChange(page, size)

      const filter = {
        CTRsOffset: (page - 1) * size,
        CTRsLimit: size
      }

      await onAlertFilterChange(filter)

      setLoading(false)
    },
    [onAlertFilterChange, tableState]
  )

  const onClose = useCallback(() => setModalItem({ transactions: [], ctrId: 0, ctrName: '' }), [])

  const onSetWarnings = useCallback((warnings) => setWarnings(warnings), [])

  const onSetModalItem = useCallback((modalItem) => setModalItem(modalItem), [])

  return {
    modalItem,
    warnings,
    loading,
    onShowWarnings,
    tableState,
    changePagination,
    onClose,
    onSetWarnings,
    onSetModalItem
  }
}
