import { BankName } from 'commons/types/enums'

export const isAdverseMediaShow = (bankId: number): boolean =>
  bankId === BankName.CBT ||
  bankId === BankName.HERRING ||
  bankId === BankName.DEV ||
  bankId === BankName.VALLIANCE ||
  bankId === BankName.INB ||
  bankId === BankName.SHORE ||
  bankId === BankName.BISON
