import { FormPaths, OnboardingFormFieldNames } from '../../../enums'

export const getFormNameByFieldName = ({
  fieldName,
  isContact
}: {
  fieldName?: OnboardingFormFieldNames
  isContact: boolean
}): FormPaths | undefined => {
  if (!fieldName) {
    // eslint-disable-next-line no-console
    console.log('###', 'fildName is undefined')
  }
  if (isContact) {
    switch (fieldName) {
      /* Contacts */
      // ContactDetailsForm
      case OnboardingFormFieldNames.FIRST_NAME:
      case OnboardingFormFieldNames.LAST_NAME:
      case OnboardingFormFieldNames.EMAIL:
      case OnboardingFormFieldNames.CONTACT_PHONE:
      case OnboardingFormFieldNames.MOBILE:
      case OnboardingFormFieldNames.HOME_PHONE:
      case OnboardingFormFieldNames.TITLE:
      case OnboardingFormFieldNames.OTHER_MJ_RELATED_BUSINESS:
      case OnboardingFormFieldNames.BIO:
        return FormPaths.CONTACT_DETAILS
      // RoleInformationForm
      case OnboardingFormFieldNames.OWNER:
      case OnboardingFormFieldNames.DEBTHOLDER:
      case OnboardingFormFieldNames.ACCOUNT_SIGNER:
      case OnboardingFormFieldNames.CONDUCTOR:
        return FormPaths.ROLE_INFORMATION
      // ContactAdditionalInformationForm
      case OnboardingFormFieldNames.DEBTHOLDER_DEBT_AMOUNT:
      case OnboardingFormFieldNames.DEBTHOLDER_PAYMENT_FREQUENCY:
      case OnboardingFormFieldNames.DEBTHOLDER_PAYMENT_AMOUNT:
      case OnboardingFormFieldNames.DEBTHOLDER_MATURITY_DATE:
      case OnboardingFormFieldNames.OWNER_INVESTMENT_AMOUNT:
      case OnboardingFormFieldNames.OWNER_OWNERSHIP_PERCENT:
      case OnboardingFormFieldNames.BIRTHDATE:
      case OnboardingFormFieldNames.EINSSN:
      case OnboardingFormFieldNames.STREET:
      case OnboardingFormFieldNames.CITY:
      case OnboardingFormFieldNames.STATE:
      case OnboardingFormFieldNames.COUNTRY:
      case OnboardingFormFieldNames.POSTAL_CODE:
      case OnboardingFormFieldNames.CONTACT_MAILING_STREET:
      case OnboardingFormFieldNames.CONTACT_MAILING_CITY:
      case OnboardingFormFieldNames.CONTACT_MAILING_STATE:
      case OnboardingFormFieldNames.CONTACT_MAILING_COUNTRY:
      case OnboardingFormFieldNames.CONTACT_MAILING_POSTAL_CODE:
      case OnboardingFormFieldNames.US_CITIZEN:
        return FormPaths.CONTACT_ADDITIONAL_INFORMATION
      default:
        // eslint-disable-next-line no-console
        console.log('###', 'fieldName not found: ', fieldName)
        return undefined
    }
  }

  // TODO: domain: isLicense, isATM, isContact in order to handle dublicate fields
  // steet etc.
  switch (fieldName) {
    case OnboardingFormFieldNames.CLIENT_NOTES:
      return FormPaths.NOTES
    // CompanyInformationForm
    case OnboardingFormFieldNames.LEGAL_NAME:
    case OnboardingFormFieldNames.DBA:
    case OnboardingFormFieldNames.PHONE:
    case OnboardingFormFieldNames.WEBSITE:
    case OnboardingFormFieldNames.DESCRIPTION:
      return FormPaths.COMPANY_INFORMATION
    // CompanyAccountDetailsForm
    case OnboardingFormFieldNames.BUSINESS_TYPE:
    case OnboardingFormFieldNames.ENTITY_TYPE:
    case OnboardingFormFieldNames.STATE_OF_INCORPORATION:
    case OnboardingFormFieldNames.EIN:
    case OnboardingFormFieldNames.PRE_LICENSE_MRB_INFO:
      return FormPaths.COMPANY_ACCOUNT_DETAILS
    // CompanyPhysicalAddressForm
    case OnboardingFormFieldNames.COMPANY_CITY:
    case OnboardingFormFieldNames.COMPANY_STATE:
    case OnboardingFormFieldNames.COMPANY_COUNTRY:
    case OnboardingFormFieldNames.COMPANY_POSTAL_CODE:
      return FormPaths.COMPANY_PHYSICAL_ADDRESS
    // CompanyMailingAddressForm
    case OnboardingFormFieldNames.MAILING_STREET:
    case OnboardingFormFieldNames.MAILING_CITY:
    case OnboardingFormFieldNames.MAILING_STATE:
    case OnboardingFormFieldNames.MAILING_COUNTRY:
    case OnboardingFormFieldNames.MAILING_POSTAL_CODE:
      return FormPaths.COMPANY_MAILING_ADDRESS
    /* Licenses */
    // LicenseDetailsForm
    case OnboardingFormFieldNames.LICENSE_NAME:
    case OnboardingFormFieldNames.TYPE:
    case OnboardingFormFieldNames.SUBTYPE:
    case OnboardingFormFieldNames.LICENSE_NUMBER:
    case OnboardingFormFieldNames.POS_TYPE:
    case OnboardingFormFieldNames.LICENSE_PHONE:
    case OnboardingFormFieldNames.POS_TYPE_OTHER:
    case OnboardingFormFieldNames.ISSUE_DATE:
    case OnboardingFormFieldNames.EXPIRATION_DATE:
    case OnboardingFormFieldNames.LICENSE_METRC_API_KEY:
      return FormPaths.LICENSE_DETAILS
    // LicenseAddressForm

    case OnboardingFormFieldNames.LICENSE_STREET:
    case OnboardingFormFieldNames.LICENSE_CITY:
    case OnboardingFormFieldNames.LICENSE_POSTAL_CODE:
    case OnboardingFormFieldNames.LICENSE_STATE:
      return FormPaths.LICENSE_ADDRESS_INFORMATION

    // QuestionnaireForm
    case OnboardingFormFieldNames.CONTACTS_FOREIGN_CONNECTIONS:
    case OnboardingFormFieldNames.STATES_LICENSED:
    case OnboardingFormFieldNames.A_WHOLESALE_PRODUCTS:
    case OnboardingFormFieldNames.A_WHOLESALE_PRODUCTS_DESC:
    case OnboardingFormFieldNames.B_TYPE_OF_PRODUCTS:
    case OnboardingFormFieldNames.C_TYPE_OF_ANCILLARY_PRODUCTS:
    case OnboardingFormFieldNames.WHO_SUPPLIES_YOU:
    case OnboardingFormFieldNames.WHERE_IS_YOUR_SUPPLIER:
    case OnboardingFormFieldNames.WHO_DO_YOUR_SUPPLY_GROWER:
    case OnboardingFormFieldNames.GROWER_CUSTOMERS_LOCATION:
    case OnboardingFormFieldNames.D_HOW_MANY_LOCATIONS:
    case OnboardingFormFieldNames.E_ONLINE_ORDERS_FOR_PICKUP:
    case OnboardingFormFieldNames.F_MINOR_SELLING_ENSURE:
    case OnboardingFormFieldNames.G_PAYMENT_METHODS:
    case OnboardingFormFieldNames.H_TRANSFER_TO_OTHER_STATES:
    case OnboardingFormFieldNames.H_TRANSFER_TO_OTHER_STATES_DESC:
    case OnboardingFormFieldNames.I_ARE_YOU_AFFILIATED:
    case OnboardingFormFieldNames.I_ARE_YOU_AFFILIATED_DESC:
      return FormPaths.QUESTIONNAIRE
    // ProfessionalPartnershipsForm
    case OnboardingFormFieldNames.PRO_PART_CURRENTLY_BANKED:
    case OnboardingFormFieldNames.PRO_PART_PREV_CLOSURE_REASON:
    case OnboardingFormFieldNames.PRO_PART_CURRENT_SERVICES_CP_AS:
    case OnboardingFormFieldNames.PRO_PART_CURRENT_SERVICES_LAW:
    case OnboardingFormFieldNames.PRO_PART_CURRENT_SERVICES_LOGISTIC:
    case OnboardingFormFieldNames.PRO_PART_CURRENT_SERVICES_OTHER:
    case OnboardingFormFieldNames.PRO_PART_CURRENT_SERVICES_LOGISTIC_DESC:
    case OnboardingFormFieldNames.PRO_PART_CURRENT_SERVICES_OTHER_DESC:
    case OnboardingFormFieldNames.PRO_PART_LITIGATION:
    case OnboardingFormFieldNames.PRO_PART_STATE_ENFORCEMENTS:
    case OnboardingFormFieldNames.PRO_PART_INTERNET_GAMBLING:
    case OnboardingFormFieldNames.PRO_PART_PAYROLL_SOFTWARE:
      return FormPaths.PROFESSIONAL_PARTNERSHIPS
    // ATMInfoForm
    case OnboardingFormFieldNames.ATM_HAVE:
    case OnboardingFormFieldNames.ATM_OWN:
    case OnboardingFormFieldNames.ATM_COUNT:
    case OnboardingFormFieldNames.ATM_REPLENISHING_THIRD_PARTY:
    case OnboardingFormFieldNames.ATM_REPLENISHING_COMPANY:
      return FormPaths.ATM_GENERAL_INFO
    // ATMServicesForm
    case OnboardingFormFieldNames.ATM_ADDRESS:
    case OnboardingFormFieldNames.ATM_AVG_AMOUNT_REPLENISHED:
    case OnboardingFormFieldNames.ATM_MAX_AMOUNT:
    case OnboardingFormFieldNames.ATM_REPLENISH_FREQUENCY:
    case OnboardingFormFieldNames.ATM_FUNDS_FROM_BANK:
    case OnboardingFormFieldNames.ATM_FUNDS_FROM_BANK_FALSE_DESC:
      return FormPaths.ATM_SERVICES
    // AvailableServicesForm
    case OnboardingFormFieldNames.AVAIL_SERVICE_HOW_MANY_ACCOUNTS_IS_OPENING:
      return FormPaths.AVAILABLE_SERVICES

    // AnticipatedMonthlyActivityNAForm
    case OnboardingFormFieldNames.ACTIVITY_DEPOSITS_CHECKS_NA:
    case OnboardingFormFieldNames.ACTIVITY_DEPOSITS_CHECKS_ITEMS:
    case OnboardingFormFieldNames.ACTIVITY_DEPOSITS_CHECKS_AMOUNT:
    case OnboardingFormFieldNames.ACTIVITY_DEPOSITS_CASH_NA:
    case OnboardingFormFieldNames.ACTIVITY_DEPOSITS_CASH_ITEMS:
    case OnboardingFormFieldNames.ACTIVITY_DEPOSITS_CASH_AMOUNT:
    case OnboardingFormFieldNames.ACTIVITY_DEPOSITS_OTHER_NA:
    case OnboardingFormFieldNames.ACTIVITY_DEPOSITS_OTHER_ITEMS:
    case OnboardingFormFieldNames.ACTIVITY_DEPOSITS_OTHER_AMOUNT:
    case OnboardingFormFieldNames.ACTIVITY_WITHDRAWALS_CASH_NA:
    case OnboardingFormFieldNames.ACTIVITY_WITHDRAWALS_CASH_ITEMS:
    case OnboardingFormFieldNames.ACTIVITY_WITHDRAWALS_CASH_AMOUNT:
      return FormPaths.ANTICIPATED_MONTHLY_ACTIVITY_NA

    // AnticipatedMonthlyActivityForm
    case OnboardingFormFieldNames.ANTICIPATED_ACTIVITY_ACH_DEPOSITS:
    case OnboardingFormFieldNames.ANTICIPATED_ACTIVITY_ACH_DEPOSITS_AMOUNT:
    case OnboardingFormFieldNames.ANTICIPATED_ACTIVITY_ACH_DEPOSITS_FREQUENCY:
    case OnboardingFormFieldNames.ANTICIPATED_ACTIVITY_WIRES_INTERNATIONAL_AMOUNT:
    case OnboardingFormFieldNames.ANTICIPATED_ACTIVITY_WIRES_INTERNATIONAL_FREQUENCY:
    case OnboardingFormFieldNames.ANTICIPATED_ACTIVITY_ACH_ORIGINATING_AMOUNT:
    case OnboardingFormFieldNames.ANTICIPATED_ACTIVITY_ACH_ORIGINATING_FREQUENCY:
    case OnboardingFormFieldNames.ANTICIPATED_ACTIVITY_ATM_WITHDRAWALS_AMOUNT:
    case OnboardingFormFieldNames.ANTICIPATED_ACTIVITY_ATM_WITHDRAWALS_FREQUENCY:
    case OnboardingFormFieldNames.ANTICIPATED_ACTIVITY_CASH_DEPOSITS_AMOUNT:
    case OnboardingFormFieldNames.ANTICIPATED_ACTIVITY_CASH_DEPOSITS_FREQUENCY:
    case OnboardingFormFieldNames.ANTICIPATED_ACTIVITY_CHECK_DEPOSITS_AMOUNT:
    case OnboardingFormFieldNames.ANTICIPATED_ACTIVITY_CHECK_DEPOSITS_FREQUENCY:
    case OnboardingFormFieldNames.ANTICIPATED_ACTIVITY_CASH_WITHDRAWALS_AMOUNT:
    case OnboardingFormFieldNames.ANTICIPATED_ACTIVITY_CASH_WITHDRAWALS_FREQUENCY:
    case OnboardingFormFieldNames.ANTICIPATED_ACTIVITY_CHECK_WITHDRAWALS_AMOUNT:
    case OnboardingFormFieldNames.ANTICIPATED_ACTIVITY_CHECK_WITHDRAWALS_FREQUENCY:
    case OnboardingFormFieldNames.ANTICIPATED_ACTIVITY_REMOTE_DEPOSITS_AMOUNT:
    case OnboardingFormFieldNames.ANTICIPATED_ACTIVITY_REMOTE_DEPOSITS_FREQUENCY:
    case OnboardingFormFieldNames.ANTICIPATED_ACTIVITY_WIRES_DOMESTIC_ONLY_AMOUNT:
    case OnboardingFormFieldNames.ANTICIPATED_ACTIVITY_WIRES_DOMESTIC_ONLY_FREQUENCY:
    case OnboardingFormFieldNames.ANTICIPATED_ACTIVITY_WIRES_DOMESTIC_AMOUNT:
    case OnboardingFormFieldNames.ANTICIPATED_ACTIVITY_WIRES_DOMESTIC_FREQUENCY:
    case OnboardingFormFieldNames.ANTICIPATED_ACTIVITY_ATM_SETTLEMENT_AMOUNT:
    case OnboardingFormFieldNames.ANTICIPATED_ACTIVITY_ATM_SETTLEMENT_FREQUENCY:
      return FormPaths.ANTICIPATED_MONTHLY_ACTIVITY

    // MerchantQuestionnaireForm
    case OnboardingFormFieldNames.LEGACY_CASH:
    case OnboardingFormFieldNames.MERCHANT_PROCESSING:
    case OnboardingFormFieldNames.MERCHANT_PROCESSING_DESC:
    case OnboardingFormFieldNames.PRIMARY_METHOD_TO_FUND:
    case OnboardingFormFieldNames.CRYPTOCURRENCY_TRANSACTIONS:
    case OnboardingFormFieldNames.REAL_TIME_PAYMENT_SERVICES:
      return FormPaths.COMPANY_MERCHANT_QUESTIONNAIRE
    default:
      // eslint-disable-next-line no-console
      console.log('###', 'fieldName not found: ', fieldName)
      return undefined
  }
}
