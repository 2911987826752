/* eslint-disable camelcase */

export interface AnnualReviewQuestionnaire {
  covenant_complying?: string
  covenant_non_compliance_desc?: string
  covenant_transparent?: string
  financials_becoming_failing?: string
  financials_business_condition?: string
  financials_current_condition_is_acceptable?: string
  financials_deposits_matching?: string
  financials_investor_debtholders?: string
  financials_is_materials_regarding_income?: string
  financials_period_collected?: string
  financials_profitability_trend?: string
  financials_acceptable_notes?: string
  financials_materials_notes?: string
  licensing_loc_changes?: string
  licensing_loc_changes_mmcc?: string
  licensing_name_changes?: string
  licensing_name_changes_mmcc?: string
  licensing_name_prior?: string
  ownership_bad_news?: string
  ownership_bad_news_cleared?: string
  ownership_bad_news_cleared_explain?: string
  ownership_bad_news_desc?: string
  ownership_changes?: string
  ownership_changes_approved?: string
  ownership_ofac_tlo_reviewed?: string
  ownership_provided_forms?: string
  recom_change_visits?: string
  recom_recommended?: string
  recom_status?: string
  site_visit_complying_regulations?: string
  site_visit_freq?: string
  site_visit_last_date?: string
  site_visit_scores_acceptable?: string
  site_visit_scores_acceptable_exactly?: string
  site_visit_shutdown_risk?: string
  site_visit_violation_rectified?: string
  transactions_ach?: boolean
  transactions_atm?: boolean
  transactions_cash?: boolean
  transactions_check?: boolean
  transactions_expect_exactly?: string
  transactions_expect_explain?: string
  transactions_match_deposit?: string
  transactions_match_trans?: string
  transactions_notes?: string
  transactions_transfer?: boolean
  transactions_vol_projected?: string
  transactions_vol_projected_exactly?: string
  has_more_revenue?: string
  revenue_from_mj_only?: string
  comparing_sics_and_bank_deposits_red_flag?: string
  comparing_sics_and_bank_deposits_variance?: string
  comparing_sics_and_bank_deposits_comments?: string
  has_more_cash_deposits?: string
  comparing_cash_deposits_and_tax_returns_red_flag?: string
  comparing_cash_deposits_and_tax_returns_variance?: string
  comparing_cash_deposits_and_tax_returns_comments?: string
  inconsistent_financial_statement?: string
  financial_statement_validation_red_flag?: string
  financial_statement_validation_variance?: string
  financial_statement_validation_comments?: string
  disguising_mrb_activity?: string
  inconsistent_mrb_purporting?: string
  inconsistent_mrb_purporting_due_diligence_completed?: string
  owner_outside_state?: string
  owner_outside_state_approved?: string
  adverse_media_required?: string
  adverse_media_reviewed?: string
  mrb_on_federal_property?: string
  mrb_school_proximity_consistent?: string
  mrb_school_proximity_due_diligence_completed?: string
  site_visit_has_state_audit_at_review_period?: string
  site_visit_state_audit_notes?: string
  ownership_has_material_changes_in_management_at_review_period?: string
  ownership_material_changes_in_management_notes?: string
  recom_comments?: string
}
