import { Contact } from 'commons/types/DTO/bank'
import { TableSortingChangeHandler, TableSortingOrder } from 'ui/components/Table'

import { ContactDetailsRequest, ContactDetailsResponse } from '../../../../types'

export interface ContactDetailsPageProps {
  contact?: Contact

  data: ContactDetailsResponse
  invalidate: () => Promise<void>
  historyTableConfig: {
    isFetching: boolean
    filter: {
      page: number
      size: number
    }
    onFilterChange: (page: number, size: number) => void
  }

  documentsTableConfig: {
    isFetching: boolean
    onFilterChange: (filter: ContactDetailsRequest['docFilter']) => void
    order: TableSortingOrder[]
    onOrderChange: TableSortingChangeHandler
  }
}
