import React, { FC, memo } from 'react'

import { useNavigate } from '@tanstack/react-router'

import { AlertedItemProps } from './AlertedItemProps'

import { FirstRow, Item, ListLink, NotDue, PastDue } from './styles'

export const AlertedItem: FC<AlertedItemProps> = memo((props) => {
  const { past, count, daysDue, url, incomplete } = props
  const navigate = useNavigate()

  const pushTo = (link: string) => {
    navigate({ to: link })
  }

  return (
    <Item rounded shadowed={false} onClick={() => pushTo(url)}>
      <FirstRow>{getAlertedItemContent({ count, past, incomplete, daysDue })}</FirstRow>
      <ListLink to={url}>Go to List</ListLink>
    </Item>
  )
})

interface AlertedItemContent {
  count: number
  past: boolean
  incomplete: boolean
  daysDue: number
}

const getAlertedItemContent = ({ count, past, incomplete, daysDue }: AlertedItemContent) => {
  const countDocuments = `${count} Document(s)`
  if (incomplete) {
    return `${countDocuments} Incomplete`
  }
  if (past) {
    return (
      <>
        {countDocuments} <PastDue>past due</PastDue>
      </>
    )
  }
  return (
    <>
      {countDocuments} due <NotDue>in {daysDue} days</NotDue>
    </>
  )
}
