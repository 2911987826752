import React, { FC, memo } from 'react'

import { getBusinessTypes } from 'commons/types/dictionaries'

import { Select } from '../Select'
import { BusinessTypeSelectProps } from './BusinessTypeSelectProps'

export const BusinessTypeSelect: FC<BusinessTypeSelectProps> = memo((props) => {
  const { bankName, ...rest } = props
  return <Select {...rest} data={getBusinessTypes(bankName)} />
})
