import styled from 'styled-components'

export const NoConnections = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 50px;

  img {
    width: 205px;
  }
`

export const NoConnectionsTitle = styled.h1`
  font-size: 32px;
  font-weight: 200;
  margin-bottom: 60px;
`
