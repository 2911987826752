import { useCallback } from 'react'
import { useMutation } from 'react-query'

import { deleteApplication as deleteApplicationService } from '../service'

type UseDeleteApplicationReturnValue = {
  deleteApplication: (id: number) => Promise<number>
}

export const useDeleteApplication = (): UseDeleteApplicationReturnValue => {
  const mutation = useMutation((id: number) => deleteApplicationService(id))

  const deleteApplication = useCallback((id: number) => mutation.mutateAsync(id), [mutation])

  return {
    deleteApplication
  }
}
