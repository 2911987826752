import styled from 'styled-components'

const BankAccountInputBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  margin-top: 20px;
  gap: 0px 12px;

  & > * {
    max-width: 320px;
  }
`

const BankAccountInputButton = styled.div`
  width: fit-content;
  margin-top: 20px;
`

export const s = { BankAccountInputBox, BankAccountInputButton }
