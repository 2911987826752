/* eslint-disable max-len */
import React from 'react'

import { CurrencyInput, FF, Validators } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

export const LossToFinInstitution = ({ index }: { index: number }) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={
        `institution_activities_info[${index}].loss_to_fin_institution` as 'institution_activities_info[0].loss_to_fin_institution'
      }
      validators={{
        onChange: ({ value }) => Validators.required.field(value)
      }}
      children={(field) => (
        <FF required label="67. Loss to financial institution" field={field} input={(p) => <CurrencyInput {...p} />} />
      )}
    />
  )
}
