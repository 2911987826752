import React, { FC, memo, useCallback, useState } from 'react'

import { Dialog, DialogFace } from 'ui/components/Dialog'
import { Select } from 'ui/components/Select'

import { ApplicationOwnerDialogProps } from './ApplicationOwnerDialogProps'

import * as SC from './styles'

export const ApplicationOwnerDialog: FC<ApplicationOwnerDialogProps> = memo((props) => {
  const { initialValue, onClose, visible, onSubmit } = props
  const [value, setValue] = useState<string>(initialValue?.value as string)

  const availableOwners = [initialValue, ...props.availableOwners]

  const onSetValue = useCallback((val) => setValue(val), [])

  if (!visible) {
    return null
  }

  return (
    <Dialog id="application-owner" onClose={onClose} face={DialogFace.PRIMARY} title="Current Application Owner">
      <SC.Inner>
        <Select searchable value={value} onChange={(value) => onSetValue(value)} data={availableOwners} />
        <SC.SaveButton onClick={() => onSubmit(value)} face="positive">
          Save
        </SC.SaveButton>
      </SC.Inner>
    </Dialog>
  )
})
