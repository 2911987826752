import { AnyRoute, createRoute, lazyRouteComponent } from '@tanstack/react-router'

export const createCompanyRoute = <T extends AnyRoute>(indexRoute: T) =>
  createRoute({
    getParentRoute: () => indexRoute,
    path: `create`,
    component: lazyRouteComponent(() => import('./ManageCompany'))
  })

export const editCompanyRoute = <T extends AnyRoute>(indexRoute: T) =>
  createRoute({
    getParentRoute: () => indexRoute,
    path: `edit/$companyId`,
    component: lazyRouteComponent(() => import('./ManageCompany'))
  })
