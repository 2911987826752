import { FilterCompanyDocumentsWebPage } from 'commons/service/bank/companyDetails'
import { AdverseMedia } from 'commons/types/DTO/bank/companyDetails/adverseMedia'
import { AffiliatedCompany } from 'commons/types/DTO/bank/companyDetails/affiliatedCompany'
import { AnnualReview } from 'commons/types/DTO/bank/companyDetails/annualReview'
import { BankAccount } from 'commons/types/DTO/bank/companyDetails/bankAccount'
import { Contact } from 'commons/types/DTO/bank/companyDetails/contact'
import { Customer } from 'commons/types/DTO/bank/companyDetails/customer'
import { Document } from 'commons/types/DTO/bank/companyDetails/document'
import { License } from 'commons/types/DTO/bank/companyDetails/license'
import { QuarterlyAnalytics } from 'commons/types/DTO/bank/companyDetails/quarterlyAnalytics'

import { ChoiceOption } from '../../commons'
import { HistoryEntity } from '../historyAudit'

export type CompanyDetailsWebPage = {
  alertsOptions: ChoiceOption[]
  frequencyOptions: ChoiceOption[]
  internalOptions: ChoiceOption[]
  subjectTypeOptions: ChoiceOption[]

  config: FilterCompanyDocumentsWebPage & {
    contactsType: string
    customersName: string
    relationshipID: number
    vendorsName: string
  }

  adverseMedia: AdverseMedia[]
  adverseMediaTotalCount: number
  affiliatedCompanies: AffiliatedCompany[]
  affiliatedCompaniesTotalCount: number
  annualReviews: AnnualReview[]
  annualReviewsTotalCount: number
  applicationID: number
  availableOwners: { id: string; fullName: string }[]
  bankAccounts: BankAccount[]
  bankAccountsTotalCount: number
  companyName: string
  contacts: Contact[]
  contactsTotalCount: number
  contactsTypeValues: any
  customerStatus: string
  customers: Customer[]
  customersTotalCount: number
  dba: string
  description: string
  documents: Document[]
  documentsTotalCount: number
  eINLabel: string
  ein: string
  enableInviteUsersToCP: boolean
  entityType: string
  hasAdverseMediaSection: boolean
  hasAnnualReviewsSection: boolean
  history: HistoryEntity[]
  historyTotalCount: number
  id: number
  isAPIConnectionColumnEnabled: boolean
  isHolding: boolean
  legalName: string
  licenses: License[]
  licensesTotalCount: number
  mailingAddress: any
  name: string
  numberOfLicenses: number
  parentHoldingID: number
  parentHoldingName: string
  phone: string
  physicalAddress: any
  primaryAccountOpeningDate: string
  quarterlyAnalytics: QuarterlyAnalytics[]
  relationshipOwners: { id: string; name: string }[]
  stateOfIncorporation: string
  subsidiaries: any[]
  tags: string
  vendors: any[]
  vendorsTotalCount: number
  website: string
}
