import { useCallback, useEffect, useMemo, useState } from 'react'

import { notFound, useNavigate } from '@tanstack/react-router'
import { useDebounce } from 'commons/hooks/common'
import { QRWebPagePayload, QuarterlyReportWebPage } from 'commons/types/DTO/bank/quarterlyReport'
import { Questionnaire } from 'commons/types/DTO/bank/quarterlyReport/questionnaire'
import { handlerServerError } from 'errors'
import { TablePagination, Toast, useTableState } from 'ui/components'
import { useConfirmation } from 'ui/hooks'
import { QR } from '~bank-quarterly-report/hooks'

enum QRConfig {
  UPLOAD_FILES_LIMIT = 20,
  UPLOAD_FILES_OFFSET = 0,
  HISTORY_LIMIT = 10,
  HISTORY_OFFSET = 0
}

export const useQRDetails = (quarterlyAnalyticsID?: number) => {
  const navigate = useNavigate()

  const defaultFilter: QRWebPagePayload = useMemo(
    () => ({
      quarterlyAnalyticsID: +quarterlyAnalyticsID,
      uploadedFilesLimit: QRConfig.UPLOAD_FILES_LIMIT,
      uploadedFilesOffset: QRConfig.UPLOAD_FILES_OFFSET,
      uploadedFilesSortBy: undefined,
      uploadedFilesSortDesc: true,
      historyLimit: QRConfig.HISTORY_LIMIT,
      historyOffset: QRConfig.HISTORY_OFFSET
    }),
    [quarterlyAnalyticsID]
  )

  const uploadedFilesTableState = useTableState({
    filter: {},
    order: [{ field: 'name', direction: 'DESC' }],
    page: 1,
    size: QRConfig.UPLOAD_FILES_LIMIT
  })

  const [webPageData, setWebPageData] = useState<QuarterlyReportWebPage | undefined>(undefined)
  const [filter, setFilter] = useState<QRWebPagePayload>(defaultFilter)

  const { data, isFetching, isFetched, invalidate } = QR.useGetQRWebPage(filter)

  useEffect(() => {
    if (isFetched && !data) {
      throw notFound()
    }
  })

  useEffect(() => {
    if (isFetched) {
      setWebPageData(data)
    }
  }, [isFetched, data])

  const changePagination = useCallback(
    async (page: number, size: number) => {
      uploadedFilesTableState.onPageChange(page, size)

      const filter = {
        ...defaultFilter,
        uploadedFilesOffset: (page - 1) * size,
        uploadedFilesLimit: size
      }

      setFilter(filter)
    },
    [defaultFilter, uploadedFilesTableState]
  )

  const [monthlyOpen, setMonthlyOpen] = useState(false)

  const { routine: saveNotes } = QR.useSaveNotes({
    onSuccess: async () => {
      invalidate()
      Toast.successSmall('Notes have been saved.')
    },
    onError: (error) => handlerServerError(error)
  })

  const { routine: deleteQR } = QR.useDeleteQR({
    onSuccess: async () => {
      navigate({ to: '/relationships/$companyId', params: { companyId: webPageData.infoRelationshipID.toString() } })
    }
  })

  const { routine: reviewQR } = QR.useReviewQR({
    onSuccess: async () => invalidate(),
    onError: (error) => Toast.errorSmall(error.message)
  })

  const { routine: updateQuestionnaire } = QR.useUpdateQuestionnaire({
    onSuccess: async () => {
      Toast.successSmall('Questionnaire was saved')
    },
    onError: (error: Error) => handlerServerError(error)
  })

  const handleSetQuestionnaire = useCallback(
    (newQuestionnaire: Questionnaire) => {
      if (webPageData) {
        updateQuestionnaire({
          id: webPageData?.quarterlyAnalyticsID,
          item: newQuestionnaire
        })
      }
    },
    [updateQuestionnaire, webPageData]
  )

  const onSetQuestionnaire = useDebounce(handleSetQuestionnaire, 1000) // MP-7568

  const { routine: resolveWarnings } = QR.useResolveWarnings({
    onSuccess: async () => {
      invalidate()
      Toast.successSmall('All warnings have been resolved.')
    },
    onError: (error: Error) => handlerServerError(error)
  })

  const { open: openDelete, Confirmation: DeleteConfirmation } = useConfirmation({
    dialogId: 'qr-delete-confirmation',
    message: `Are you sure want to remove this report?`,
    onConfirm: () => deleteQR(filter.quarterlyAnalyticsID),
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  const { open: openWarnings, Confirmation: WarningsConfirmation } = useConfirmation({
    dialogId: 'qr-warnings-confirmation',
    message: `Are you sure want to acknowledge all warnings?`,
    onConfirm: () => resolveWarnings(filter.quarterlyAnalyticsID),
    confirmationButtonText: 'Confirm',
    isConfirmationNegative: false
  })

  const { open: openReview, Confirmation: ReviewConfirmation } = useConfirmation({
    dialogId: 'qr-review-confirmation',
    message: `Are you sure want to review this report?`,
    onConfirm: () => reviewQR(filter.quarterlyAnalyticsID),
    confirmationButtonText: 'Review',
    isConfirmationNegative: false
  })

  const historyPagination: TablePagination = useMemo(
    () => ({
      total: data?.historyTotalCount,
      page: filter.historyOffset / filter.historyLimit + 1,
      pageSize: filter.historyLimit,
      onChange: (page: number, size: number) =>
        setFilter({ ...filter, historyOffset: (page - 1) * size, historyLimit: size })
    }),
    [filter, data]
  )

  return {
    webPageData,
    isFetching,
    uploadedFilesTableState,
    uploadedFilesChangePagination: changePagination,
    openReview,
    ReviewConfirmation,
    openWarnings,
    WarningsConfirmation,
    openDelete,
    DeleteConfirmation,
    onSetQuestionnaire,
    saveNotes,
    monthlyOpen,
    setMonthlyOpen,
    historyPagination,
    invalidate
  }
}
