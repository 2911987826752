import { createRoute, redirect } from '@tanstack/react-router'
import { KEY_CONNECTIONS_PATH } from 'commons/constants/routes'

import { BankConnectionsWrapper } from './BankConnectionsWrapper'
import { connectionsListRoute } from './routes/ConnectionsList/routes'

export const routeConnectionsTree = (rootRoute: BPRootRoute) => {
  const companiesIndexRoute = createRoute({
    beforeLoad: async ({ context }) => {
      if (!context.accessibleRoutingFlags.connectionRootAccess) {
        throw redirect({
          to: '/'
        })
      }
    },
    getParentRoute: () => rootRoute,
    path: KEY_CONNECTIONS_PATH,
    component: BankConnectionsWrapper
  })

  return companiesIndexRoute.addChildren([connectionsListRoute<typeof companiesIndexRoute>(companiesIndexRoute)])
}
