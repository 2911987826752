import React from 'react'

import { EmailInput, FF } from 'ui/components'

import { useSARFormContext } from '../../../../../../context'

type EmailProps = {
  index: number
}

export const Email = ({ index }: EmailProps) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={`subjects_info[${index}].emails[0].value` as 'subjects_info[0].emails[0].value'}
      children={(field) => <FF field={field} label="22. E-mail address" input={(p) => <EmailInput {...p} />} />}
    />
  )
}
