import React, { FC, memo } from 'react'

import { Form } from 'brief-form'
import { YesNoRadioGroup } from 'commons/types/enums'
import { Validators } from 'ui/components'
import { InfoSet } from 'ui/components/Info'
import { CurrencyInput, TextInput, TextareaInput } from 'ui/components/InputV2'
import { RadioGroup } from 'ui/components/RadioGroup'
import { Col, Col2x, FormRow } from 'ui/themes/globalStyles'

import { OnboardingFormFieldNames } from '../../enums'
import { ATMServicesFormProps } from './ATMServicesFormProps'
import { useATMServicesForm } from './useATMServicesForm'

export const ATMServicesForm: FC<ATMServicesFormProps> = memo(({ atmIndex, companyIndex, value, onChange }) => {
  const { Field, config } = useATMServicesForm({ companyIndex, atmIndex, formData: value, onChange })

  return (
    <Form config={config}>
      <InfoSet>
        <FormRow>
          <Col>
            <Field
              name={OnboardingFormFieldNames.ATM_ADDRESS}
              label="Physical address of ATM location"
              input={TextInput}
              inputProps={{
                'data-qa': `${atmIndex} - Physical address of ATM location`
              }}
            />
          </Col>
          <Col>
            <Field
              name={OnboardingFormFieldNames.ATM_AVG_AMOUNT_REPLENISHED}
              label="Avg amount replenished per month"
              input={CurrencyInput}
              inputProps={{
                'data-qa': `${atmIndex} - Avg amount replenished per month`
              }}
            />
          </Col>
        </FormRow>
        <FormRow>
          <Col>
            <Field
              name={OnboardingFormFieldNames.ATM_MAX_AMOUNT}
              label="ATM size (max amount held):"
              input={CurrencyInput}
              inputProps={{
                'data-qa': `${atmIndex} - ATM size`
              }}
            />
          </Col>
          <Col>
            <Field
              name={OnboardingFormFieldNames.ATM_REPLENISH_FREQUENCY}
              label="How often will the ATM be replenished?"
              input={TextInput}
              inputProps={{
                'data-qa': `${atmIndex} - How often will the ATM be replenished?`
              }}
            />
          </Col>
        </FormRow>
        <FormRow>
          <Col2x>
            <Field
              name={OnboardingFormFieldNames.ATM_FUNDS_FROM_BANK}
              label="Will you be receiving funds for replenishment from the Bank"
              input={RadioGroup}
              inputProps={{
                name: `${OnboardingFormFieldNames.ATM_FUNDS_FROM_BANK}${companyIndex}${atmIndex}`,
                'data-qa': `${atmIndex} - Will you be receiving funds for replenishment from the Bank`,
                data: [
                  { value: YesNoRadioGroup.YES, label: 'Yes' },
                  { value: YesNoRadioGroup.NO, label: 'No' }
                ]
              }}
              required
              validator={Validators.required.notNull}
            />
          </Col2x>
        </FormRow>
        <FormRow>
          <Col2x>
            <Field
              name={OnboardingFormFieldNames.ATM_FUNDS_FROM_BANK_FALSE_DESC}
              label="If no, how will you obtain funds for replenishment?"
              input={TextareaInput}
              inputProps={{
                'data-qa': `${atmIndex} - If no, how will you obtain funds for replenishment?`,
                disabled: config.value.ATMFundsFromBank === YesNoRadioGroup.YES
              }}
            />
          </Col2x>
        </FormRow>
      </InfoSet>
    </Form>
  )
})
