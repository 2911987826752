import { UseWebPageProps, WebPage } from 'commons/types/DTO/bank/connections'
import { Request } from 'commons/utils/request'
import { Keys, ReadHook, useStateManager } from 'state-manager'

export const useGetConnectionsWebPage: ReadHook<WebPage, UseWebPageProps> = ({
  connectionsLimit,
  connectionsOffset,
  connectionsSearchQuery
}) => {
  const sm = useStateManager()

  const { data, ...rest } = sm.use(
    Keys.Connection.List(connectionsLimit, connectionsOffset, connectionsSearchQuery),
    () =>
      Request.post(Request.urls.bank.connectionsListWebpages, {
        connectionsLimit,
        connectionsOffset,
        connectionsSearchQuery
      }),
    { keepPreviousData: true }
  )

  return {
    ...rest,
    data
  }
}
