import React, { useCallback, useState } from 'react'

import { Form, useFormData } from 'brief-form'
import { CreateApplicationRequest } from 'commons/types/DTO/bank/applications/manage/CreateApplicationRequest'
import { Keys, useStateManager } from 'state-manager'
import { Dialog, DialogFace, DialogFooterActions } from 'ui/components/Dialog'
import { Validators } from 'ui/components/Form/validators'
import { EmailInput, TextInput, TextareaInput, USPhoneInput } from 'ui/components/InputV2'
import { LeaveConfirmation } from 'ui/components/LeaveConfirmation'
import { Toast } from 'ui/components/Toast'
import { Col, Col2x, FormRow } from 'ui/themes/globalStyles'

import { useCreateApplication } from '../../../../hooks/useCreateApplication'
import CreateApplicationDialogProps from './CreateApplicationDialogProps'

import * as SC from './styles'

export const CreateApplicationDialog = ({ onClose }: CreateApplicationDialogProps) => {
  const sm = useStateManager()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { isDirty, config, validate, Field } = useFormData<CreateApplicationRequest>({
    initialValue: {
      company_name: '',
      contact_first_name: '',
      contact_last_name: '',
      email: '',
      phone: '',
      bank_notes: '',
      internal_bank_notes: '',
      status: 'rfi_pending'
    }
  })

  const { createApplication } = useCreateApplication()

  const onCreateApplication = useCallback(() => {
    if (validate({ updateFields: true }).valid) {
      setIsSubmitting(true)
      createApplication(config.value).then(
        async () => {
          Toast.success('Application has been saved')
          await sm.invalidate(Keys.BankApplication.List)
          onClose()
          setIsSubmitting(false)
        },
        () => {
          Toast.error('A trouble was occurred while saved')
          setIsSubmitting(false)
        }
      )
    }
  }, [config.value, onClose, sm, validate, createApplication])

  return (
    <LeaveConfirmation preventLeaving={isDirty && !isSubmitting}>
      <Dialog
        id="create-application"
        onClose={onClose}
        face={DialogFace.PRIMARY}
        title="New Application"
        onSubmit={onCreateApplication}
        footerActions={[DialogFooterActions.ADD, DialogFooterActions.CANCEL]}
        isSubmitting={isSubmitting}
      >
        <SC.ModalContent>
          <Form config={config}>
            <FormRow>
              <Col>
                <Field
                  required
                  name="company_name"
                  label="Company Name"
                  input={TextInput}
                  validator={Validators.required.string}
                  inputProps={{
                    autoComplete: 'off',
                    'data-qa': 'Company Name'
                  }}
                />
              </Col>
            </FormRow>
            <FormRow>
              <Col>
                <Field
                  name="contact_first_name"
                  label="First Name"
                  input={TextInput}
                  inputProps={{
                    'data-qa': 'First Name'
                  }}
                />
              </Col>
              <Col>
                <Field
                  required
                  name="contact_last_name"
                  label="Last Name"
                  input={TextInput}
                  validator={Validators.required.string}
                  inputProps={{
                    'data-qa': 'Last Name'
                  }}
                />
              </Col>
            </FormRow>
            <FormRow>
              <Col>
                <Field
                  required
                  name="email"
                  label="Email"
                  input={EmailInput}
                  validator={Validators.required.string}
                  inputProps={{
                    'data-qa': 'Email'
                  }}
                />
              </Col>
              <Col>
                <Field
                  name="phone"
                  label="Phone"
                  input={USPhoneInput}
                  inputProps={{
                    'data-qa': 'Phone'
                  }}
                />
              </Col>
            </FormRow>
            <FormRow>
              <Col2x>
                <Field
                  name="bank_notes"
                  label="Notes for the Applicant (visible for client)"
                  input={TextareaInput}
                  inputProps={{
                    'data-qa': 'Notes for the Applicant'
                  }}
                />
              </Col2x>
            </FormRow>
            <FormRow>
              <Col2x>
                <Field
                  name="internal_bank_notes"
                  label="Internal Notes (only visible to Bank)"
                  input={TextareaInput}
                  inputProps={{
                    'data-qa': 'Internal Notes'
                  }}
                />
              </Col2x>
            </FormRow>
          </Form>
        </SC.ModalContent>
      </Dialog>
    </LeaveConfirmation>
  )
}
