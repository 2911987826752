import styled from 'styled-components'

export const RowsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
`

export const HoldingLabel = styled.span`
  display: flex;
  align-items: center;
  gap: 10px;
  height: 19.5px;
`

export const ActiveWrapper = styled.div`
  margin-bottom: 5px;
`

export const IsHoldingWrapper = styled.div`
  margin-bottom: 8px;
`
