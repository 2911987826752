import React from 'react'

import { useRouter } from '@tanstack/react-router'
import { ApplicationStatus } from 'commons/types/enums'
import { handlerServerError } from 'errors'
import { Button } from 'ui/components/Buttons'
import { Toast } from 'ui/components/Toast'
import { useConfirmation } from 'ui/hooks'

import { useGetOldApplication } from '../../../../../../hooks/useGetOldApplication'
import { useUpdateApplication } from '../../../../../../hooks/useUpdateApplication'
import { ApproveApplicationProps } from './ApproveApplicationProps'

export const ApproveApplication = ({ application }: ApproveApplicationProps) => {
  const router = useRouter()

  const { getOldApplication } = useGetOldApplication()
  const { updateApplication } = useUpdateApplication()

  const onApproveApplication = () => {
    // TODO: this is hot fix for tests
    // TODO: remove this after the backend creates separate endpoints
    const oldApplication = getOldApplication(application.applicationID)

    oldApplication.then((value) => {
      updateApplication({ ...value.records[0], status: ApplicationStatus.PENDING_CUSTOMER_REVIEW }).then(
        () => {
          Toast.success('Application has been approved')
          router.invalidate()
        },
        (error) => handlerServerError(error)
      )
    })
  }

  const { Confirmation: ApproveApplicationConfirmation, open: openApproveApplicationConfirmation } = useConfirmation({
    message: <span>Are you sure want to approve Application?</span>,
    onConfirm: () => onApproveApplication(),
    confirmationButtonText: 'Approve'
  })

  return (
    <>
      <ApproveApplicationConfirmation />
      <Button face="positive" onClick={openApproveApplicationConfirmation}>
        Approve Application
      </Button>
    </>
  )
}
