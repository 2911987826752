import styled from 'styled-components'
import { CSS } from 'ui/helpers'

const Wrapper = styled.div<{ $disabled: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px 0px;
  margin: 28px 32px 28px 32px;
  ${(p) => p.$disabled && 'opacity: 50%;'}
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0px 8px;
`

const Title = styled.h5`
  font-weight: ${(p) => p.theme.uiKit.fontWeights['600']};
`

const SubTitle = styled.span`
  ${(p) => CSS.populateFont(p.theme.uiKit.typography.bodyM.regular)};
`

export const s = { Wrapper, TitleWrapper, Title, SubTitle }
