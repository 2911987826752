import React, { RefObject, memo, useCallback, useEffect, useState } from 'react'

import { Form, useFormData } from 'brief-form'
import { BsaCtrPersonInfo } from 'commons/types/DTO/bank/bsa'
import {
  asStates,
  caStates,
  countries,
  ctrIdentificationTypes,
  ctrIdentificationTypesLabels,
  ctrIdentificationTypesValues,
  ctrPersonTypes,
  ctrPersonTypesValues,
  ctrTINTypes,
  fmStates,
  gender,
  guStates,
  mhStates,
  usStates
} from 'commons/types/dictionaries'
import { mpStates } from 'commons/types/dictionaries/mpStates'
import { mxStates } from 'commons/types/dictionaries/mxStates'
import { prStates } from 'commons/types/dictionaries/prStates'
import { pwStates } from 'commons/types/dictionaries/pwStates'
import { viStates } from 'commons/types/dictionaries/viStates'
import { CheckBox, DatePicker, RadioGroup, RadioGroupOrientation, Select, Validators } from 'ui/components'
import { IconButton } from 'ui/components/Buttons'
import { IconName } from 'ui/components/Icon'
import { CurrencyInput, SecuredInput, TextInput, WithCheckboxInput } from 'ui/components/InputV2'
import { useConfirmation } from 'ui/hooks'
import { Col, FormRow } from 'ui/themes/globalStyles'
import { CifSubject } from '~bank-bsa-reporting/hooks'

import { AccountNumbers } from './AccountNumbers'

import { CheckBoxWrapper, Inner, Item, TinColumn, VisibilityBox } from './styles'

const EMPTY_OPTION = { label: 'Not selected', value: '' }

interface Props {
  value: BsaCtrPersonInfo
  errors: { [key: string]: any }
  onChange: (index: number, value: BsaCtrPersonInfo, errors?: { [key: string]: string }) => void
  onDelete?: (index: number) => void
  index: number
  total: number
  validateFunctionRefs: RefObject<any>
  markFormAsDirty: () => void
}

const isIssuingStateRequiredCountry = (country: string) => {
  if (['US', 'AS', 'GU', 'MH', 'FM', 'CA', 'MP', 'PW', 'PR', 'VI', 'MX'].indexOf(country) !== -1) {
    return true
  }
  return false
}

const determineIssuingStateData = (country: string) => {
  switch (country) {
    case 'US':
      return usStates
    case 'AS':
      return asStates
    case 'GU':
      return guStates
    case 'MH':
      return mhStates
    case 'FM':
      return fmStates
    case 'CA':
      return caStates
    case 'MP':
      return mpStates
    case 'PW':
      return pwStates
    case 'PR':
      return prStates
    case 'VI':
      return viStates
    case 'MX':
      return mxStates
  }
  return []
}

export const PersonInvolvedInformationForm = memo((props: Props) => {
  const { value, onChange, onDelete, errors, index, total, validateFunctionRefs, markFormAsDirty } = props
  const { isDirty, config, validate, Field } = useFormData<any>({
    initialValue: value,
    initialErrors: errors,
    alwaysSyncWithInitialValueAndErrors: true
  })
  const [tinChanged, setTinChanged] = useState(false)

  const { item: cifSubject } = CifSubject.useCifSubjectItem(config.value.tin || '', tinChanged)
  const [checked, setChecked] = useState(false)

  const originalChangeHandler = config.onChange
  const onCheckedChange = useCallback(
    (value) => {
      setChecked(value)
      const newValue = {
        ...config.value,
        tin: '',
        tin_type: ''
      }
      if (config.errors['tin_type']) {
        delete config.errors['tin_type']
      }
      originalChangeHandler(newValue, config.errors)
      onChange(index, newValue, config.errors)
    },
    [config.value, config.errors, index, originalChangeHandler, onChange]
  )

  useEffect(() => {
    if (isDirty) {
      markFormAsDirty()
    }
  }, [isDirty])

  useEffect(() => {
    validateFunctionRefs.current.persons_info[index] = { validate, registeredFields: config.registeredFields.current }
  }, [validate, validateFunctionRefs, config.registeredFields, index])

  useEffect(() => {
    if (cifSubject && tinChanged) {
      const newValue = {
        ...config.value,
        cifSubjectId: cifSubject.id,
        is_entity: !!cifSubject.entity_name && !cifSubject.last_name,
        last_name: cifSubject.last_name,
        entity_name: cifSubject.entity_name,
        first_name: cifSubject.first_name,
        middle_name: cifSubject.middle_name,
        suffix: cifSubject.suffix,
        gender: cifSubject.gender,
        alternate_name: cifSubject.alternate_name,
        business_type: cifSubject.business_type,
        naics_code: cifSubject.naics_code,
        address: cifSubject.address,
        city: cifSubject.city,
        state: cifSubject.state,
        postal_code: cifSubject.postal_code,
        country: cifSubject.country,
        tin_type: cifSubject.tin_type,
        birthdate: cifSubject.birthdate,
        phone: cifSubject.phone,
        phone_ext: cifSubject.phone_ext,
        email: cifSubject.email,
        id_form: cifSubject.id_form,
        id_form_other: cifSubject.id_form_other,
        id_number: cifSubject.id_number,
        id_country: cifSubject.id_country,
        id_state: cifSubject.id_state
      }
      originalChangeHandler(newValue, config.errors)
      onChange(index, newValue, config.errors)
      setTinChanged(false)
    }
  }, [cifSubject, onChange, originalChangeHandler, config.value, config.errors, index, setTinChanged, tinChanged])

  if (checked) {
    config.value.tin = ''
    config.value.tin_type = ''
  }

  config.onChange = useCallback(
    (v: BsaCtrPersonInfo, e) => {
      if (
        v.is_entity &&
        (v.type === ctrPersonTypesValues.CTR_PERSON_TYPE_A || v.type === ctrPersonTypesValues.CTR_PERSON_TYPE_B)
      ) {
        e['type'] = `An entity cannot has checked "a" or "b" in Item "2.".
         Uncheck the "If entity" checkbox or check either "c" or "d" items.`
      } else {
        if (e['type']?.startsWith('An entity cannot has checked')) {
          delete e['type']
        }
      }

      if (
        v.id_form &&
        v.id_form !== ctrIdentificationTypesValues.CTR_ID_TYPE_OTHER &&
        v.id_form !== ctrIdentificationTypesValues.CTR_ID_TYPE_UNKNOWN &&
        !v.id_number
      ) {
        e['id_number'] = '"Number" in Item "20. Form of identification used to verify identity" required'
      } else {
        delete e['id_number']
      }

      if (!!v.tin && !v.tin_type && !checked) {
        e['tin_type'] = '"16. TIN type" is required, when "15. TIN" is filled.'
      } else {
        delete e['tin_type']
      }

      // when user select country, then select state, then select another country,
      // we do not want to store incorrect value of state
      if (config.value.id_country && config.value.id_country !== v.id_country) {
        v.id_state = null
        delete e['id_state']
      }
      // remove stored error if field not required
      if (v.id_form !== ctrIdentificationTypesValues.CTR_ID_TYPE_DRIVER_LICENSE) {
        delete e['id_state']
      }

      if (v.tin !== config.value.tin) {
        setTinChanged(true)
      }

      originalChangeHandler(v, e)
      onChange(index, v, e)
    },
    [onChange, originalChangeHandler, index, setTinChanged, checked, config.value.tin, config.value.id_country]
  )

  const { open: startDeleting, Confirmation } = useConfirmation({
    message: `Are you sure you want to remove this item?`,
    onConfirm: () => {
      if (onDelete) {
        onDelete(index)
      }
    },
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  return (
    <Item
      rounded
      shadowed
      collapsible={false}
      title={`Edit Part I Person Involved in Transaction(s) ${+index + 1} of ${total}`}
      data-qa={`Edit Part I Person Involved in Transaction(s) ${+index + 1}`}
      actions={
        !!onDelete && (
          <IconButton face="negative" icon={IconName.DELETE} onClick={startDeleting}>
            Remove
          </IconButton>
        )
      }
    >
      <Confirmation />
      <Form config={config}>
        <Inner>
          <FormRow>
            <Col>
              <Field
                required
                name="type"
                label="2."
                input={RadioGroup}
                validator={Validators.required.field}
                inputProps={{
                  data: ctrPersonTypes,
                  orientation: RadioGroupOrientation.VERTICAL,
                  'data-qa': '2.'
                }}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <Field
                name="multiple_transactions"
                label="1. Type of filing"
                input={CheckBox}
                inputProps={{
                  checkBoxLabel: 'Multiple transactions'
                }}
              />
            </Col>
            <Col>
              <Field
                name="is_entity"
                label="Check"
                input={CheckBox}
                inputProps={{
                  checkBoxLabel: 'If entity'
                }}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <Field
                name={config.value.is_entity ? 'entity_name' : 'last_name'}
                label="4. Individual's last name or entity's legal name"
                input={WithCheckboxInput}
                inputProps={{
                  input: TextInput,
                  maxLength: 150,
                  'data-qa': `4. Individual's last name or entity's legal name`
                }}
              />
            </Col>
          </FormRow>
          {!config.value.is_entity && (
            <>
              <FormRow>
                <Col>
                  <Field
                    name="first_name"
                    label="5. First name"
                    input={WithCheckboxInput}
                    inputProps={{
                      input: TextInput,
                      maxLength: 35,
                      'data-qa': '5. First name'
                    }}
                  />
                </Col>
              </FormRow>
              <FormRow>
                <Col>
                  <Field
                    name="middle_name"
                    label="6. Middle name"
                    input={TextInput}
                    inputProps={{
                      maxLength: 35
                    }}
                  />
                </Col>
              </FormRow>
              <FormRow>
                <Col>
                  <Field
                    name="suffix"
                    label="Suffix"
                    input={TextInput}
                    inputProps={{
                      maxLength: 35
                    }}
                  />
                </Col>
              </FormRow>
              <FormRow>
                <Col>
                  <Field
                    name="gender"
                    label="Gender"
                    input={Select}
                    inputProps={{
                      data: gender,
                      emptyOption: EMPTY_OPTION
                    }}
                  />
                </Col>
              </FormRow>
            </>
          )}
          <FormRow>
            <Col>
              <Field
                name="alternate_name"
                label="8. Alternate name"
                input={TextInput}
                inputProps={{
                  maxLength: 150
                }}
              />
            </Col>
            <Col>
              <Field
                name="business_type"
                label="9. Occupation or type of business"
                input={TextInput}
                inputProps={{
                  maxLength: 30
                }}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <Field
                name="naics_code"
                label="9a. NAICS Code"
                input={TextInput}
                inputProps={{
                  maxLength: 6
                }}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <Field
                name="address"
                label="10. Address"
                input={WithCheckboxInput}
                inputProps={{
                  input: TextInput,
                  maxLength: 100
                }}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <Field
                name="city"
                label="11. City"
                input={WithCheckboxInput}
                inputProps={{
                  input: TextInput,
                  maxLength: 50
                }}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <Field
                name="state"
                label="12. State (abbreviation)"
                input={WithCheckboxInput}
                inputProps={{
                  input: TextInput,
                  maxLength: 2,
                  toUpper: true
                }}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <Field
                name="postal_code"
                label="13. ZIP/Postal Code"
                input={WithCheckboxInput}
                inputProps={{
                  input: TextInput,
                  maxLength: 9
                }}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <Field
                name="country"
                label="14. Country (abbreviation)"
                input={WithCheckboxInput}
                inputProps={{
                  input: TextInput,
                  maxLength: 2,
                  toUpper: true
                }}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <Field
                name="tin"
                label="15. TIN"
                input={SecuredInput}
                inputProps={{
                  disabled: checked,
                  maxLength: 25
                }}
              />
            </Col>
            <TinColumn>
              <Field
                name="tin_type"
                label="16. TIN type"
                input={Select}
                inputProps={{
                  data: [{ label: '-', value: '' }].concat(ctrTINTypes),
                  disabled: checked
                }}
              />
              <CheckBoxWrapper value={checked} onChange={onCheckedChange} checkBoxLabel={'Unknown'} />
            </TinColumn>
          </FormRow>
          {!config.value.is_entity && (
            <FormRow>
              <Col>
                <Field name="birthdate" label="17. Date of Birth" input={DatePicker} inputProps={{}} />
              </Col>
            </FormRow>
          )}
          <FormRow>
            <Col>
              <Field
                name="phone"
                label="18. Contact phone number"
                input={TextInput}
                inputProps={{
                  maxLength: 16
                }}
              />
            </Col>
            <Col>
              <Field
                name="phone_ext"
                label="Ext."
                input={TextInput}
                inputProps={{
                  maxLength: 6
                }}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <Field
                name="email"
                label="19. E-mail address"
                input={TextInput}
                inputProps={{
                  maxLength: 517
                }}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <Field
                name="id_form"
                label="20. Form of identification used to verify identity"
                input={RadioGroup}
                inputProps={{
                  data: ctrIdentificationTypes,
                  orientation: RadioGroupOrientation.VERTICAL
                }}
              />
            </Col>
          </FormRow>
          <VisibilityBox $visible={config.value.id_form === ctrIdentificationTypesLabels.CTR_ID_TYPE_OTHER}>
            <FormRow>
              <Col>
                <Field
                  required={config.value.id_form === ctrIdentificationTypesLabels.CTR_ID_TYPE_OTHER}
                  validator={(v) =>
                    !!v || config.value.id_form !== ctrIdentificationTypesLabels.CTR_ID_TYPE_OTHER
                      ? undefined
                      : 'Specify Other in Item "20. Form of identification used to verify identity"'
                  }
                  name="id_form_other"
                  label="Other form of identification"
                  input={TextInput}
                  inputProps={{
                    maxLength: 50
                  }}
                />
              </Col>
            </FormRow>
          </VisibilityBox>
          <FormRow>
            <Col>
              <Field
                name="id_number"
                label="Number"
                required={
                  config.value.id_form !== '' && config.value.id_form !== ctrIdentificationTypesLabels.CTR_ID_TYPE_OTHER
                }
                input={TextInput}
                validator={() =>
                  config.value.id_form !== '' &&
                  config.value.id_form !== ctrIdentificationTypesLabels.CTR_ID_TYPE_OTHER &&
                  Validators.required.field(config.value.id_number)
                }
                inputProps={{
                  maxLength: 25
                }}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <Field
                required={config.value.id_form !== ''}
                validator={(v) =>
                  (config.value.id_form === '' && v?.toString().length === 1) ||
                  ((!v || v?.toString().length !== 2) && config.value.id_form !== '')
                    ? '"Country (abbreviation)" in Item 20 should contain 2 characters abbreviation'
                    : undefined
                }
                name="id_country"
                label="Country (abbreviation)	"
                input={Select}
                inputProps={{
                  searchable: true,
                  data: countries
                }}
              />
            </Col>
            <VisibilityBox $visible={isIssuingStateRequiredCountry(config.value.id_country)}>
              <Col>
                <Field
                  required={
                    config.value.id_form !== '' &&
                    config.value.id_form === ctrIdentificationTypesLabels.CTR_ID_TYPE_DRIVER_LICENSE &&
                    isIssuingStateRequiredCountry(config.value.id_country)
                  }
                  name="id_state"
                  label="Issuing State"
                  input={Select}
                  validator={(v) =>
                    config.value.id_form !== '' &&
                    config.value.id_form === ctrIdentificationTypesLabels.CTR_ID_TYPE_DRIVER_LICENSE &&
                    isIssuingStateRequiredCountry(config.value.id_country) &&
                    Validators.required.fieldWithCustomMessage(
                      'Specify Issuing State in Item "20. Form of identification used to verify identity"'
                    )(v)
                  }
                  inputProps={{
                    data: determineIssuingStateData(config.value.id_country),
                    emptyOption: EMPTY_OPTION
                  }}
                />
              </Col>
            </VisibilityBox>
          </FormRow>
          <FormRow>
            <Col>
              <Field
                name="cash_in"
                label="21. Cash in amount for individual or entity listed in Item 4"
                input={CurrencyInput}
                inputProps={{
                  allowDecimal: false
                }}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <Field name="account_numbers_in" label="Account number (s)" input={AccountNumbers} inputProps={{}} />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <Field
                name="cash_out"
                label="22. Cash out amount for individual or entity listed in Item 4"
                input={CurrencyInput}
                inputProps={{}}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <Field name="account_numbers_out" label="Account number (s)" input={AccountNumbers} inputProps={{}} />
            </Col>
          </FormRow>
        </Inner>
      </Form>
    </Item>
  )
})
