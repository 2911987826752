import { ApiReadResponse } from 'commons/types'
import { CreateApplicationRequest } from 'commons/types/DTO/bank/applications/manage/CreateApplicationRequest'
import { AddReview } from 'commons/types/DTO/bank/applications/manage/Reviews/addReview'
import { Application } from 'commons/types/DTO/commons'
import { Request } from 'commons/utils/request'

import { Notes } from '../interfaces/Notes'

export const createApplication = (formValue: CreateApplicationRequest) =>
  Request.post<CreateApplicationRequest>(Request.urls.bank.application, { ...formValue })

export const deleteApplication = (id: number) => Request.delete<number>(Request.urls.bank.application, { id: id })

export const getOldApplication = (id: number) =>
  Request.post<ApiReadResponse<Application>>(Request.urls.bank.applicationList, { id: id })

export const updateApplication = (application: Application) =>
  Request.put<Application>(Request.urls.bank.application, { ...application })

export const revertApplicationToRFI = (id: number, notes: string) =>
  Request.put<{ id: number; notes: string }>(Request.urls.bank.applicationRevert, { id, notes })

export const addApplicationRelatedRecords = (id: number) =>
  Request.post<number>(Request.urls.bank.applicationRelatedRecords, { application_id: id })

export const addApplicationDocumentUpload = (form: FormData) =>
  Request.post<number>(Request.urls.bank.applicationDocumentUpload, form)

export const deleteApplicationDocumentUpload = (applicationId: number, id: number) =>
  Request.delete<number>(Request.urls.bank.applicationDocument, { applicationId: applicationId, id: id })

export const addReview = (review: AddReview) => Request.patch<Application>(Request.urls.bank.application, { ...review })

export const addNotes = (notes: Notes) => Request.patch<Application>(Request.urls.bank.application, { ...notes })

export const deleteDocumentIsolated = (id: number) =>
  Request.delete(Request.urls.bank.applicationClientDocument, { id })

export const deleteDocument = (appId: number, documentId: number) =>
  Request.delete<number>(Request.urls.bank.document, { applicationId: appId, id: documentId })

export const setActiveApplication = (id: number, active: boolean) =>
  Request.patch<Application>(Request.urls.bank.application, { id: id, active: active })
