import { AnyRoute, createRoute, lazyRouteComponent } from '@tanstack/react-router'

export const ManageSARRoute = <T extends AnyRoute>(indexRoute: T) =>
  createRoute({
    getParentRoute: () => indexRoute,
    path: `$id/edit`,
    component: lazyRouteComponent(() => import('./ManageSARPage')),
    validateSearch: (search: Record<string, string>) => ({
      returnUrl: (search.returnUrl as string) || ''
    })
  })
