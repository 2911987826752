import { createRoute, redirect } from '@tanstack/react-router'
import { BANK_ACCOUNTS } from 'commons/constants/routes'

import { BankAccountWrapper } from '../BankAccountWrapper'
import { bankAccountCreateRoute } from './Create'
import { bankAccountDetailsRoute } from './Details'
import { bankAccountEditRoute } from './Edit'

export const routeBankAccountsTree = (rootRoute: BPRootRoute) => {
  const bankAccountIndexRoute = createRoute({
    beforeLoad: async ({ context }) => {
      if (!context.accessibleRoutingFlags.bankAccountRootAccess) {
        throw redirect({
          to: '/'
        })
      }
    },
    getParentRoute: () => rootRoute,
    path: BANK_ACCOUNTS,
    component: BankAccountWrapper
  })

  return bankAccountIndexRoute.addChildren([
    bankAccountCreateRoute<typeof bankAccountIndexRoute>(bankAccountIndexRoute),
    bankAccountEditRoute<typeof bankAccountIndexRoute>(bankAccountIndexRoute),
    bankAccountDetailsRoute<typeof bankAccountIndexRoute>(bankAccountIndexRoute)
  ])
}
