import { getCompanyList } from 'commons/service/bank/company'
import { Company } from 'commons/types/DTO/bank'
import { ServerOptions } from 'commons/types/api'
import { Keys, ReadHook, useStateManager } from 'state-manager'

export type UseGetCompaniesByIdsParams = {
  ids: number[]
  disabled?: boolean
}

export const useGetCompaniesByIds: ReadHook<Company[], UseGetCompaniesByIdsParams> = ({ ids, disabled }) => {
  const sm = useStateManager()

  const options: ServerOptions = {
    _options: {
      filters: [
        {
          field: 'id',
          type: 'in',
          value: ids
        }
      ]
    }
  }

  const { data, ...rest } = sm.use(Keys.Company.ListByIds(ids), () => getCompanyList(options), {
    keepPreviousData: true,
    enabled: !disabled
  })

  return {
    ...rest,
    data: data?.records || []
  }
}
