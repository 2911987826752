import React from 'react'

import { AnswerProps } from './AnswerProps'

import { Label, Wrapper } from './styles'

export const Answer = ({ label, value, vertical, marginBottom }: AnswerProps) => (
  <Wrapper $vertical={vertical || false} $marginBottom={marginBottom}>
    <Label>{label}</Label>
    <span>{value}</span>
  </Wrapper>
)
