import styled from 'styled-components'
import { Spinner } from 'ui/components'

const WrappedSpinner = styled(Spinner)`
  margin-top: 10px;
`

const Box = styled.div`
  padding: 27px 42px 35px 40px;
  gap: 24px 0px;
`

const NotesWrapper = styled.div`
  width: 418px;
`

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
`

export const s = { WrappedSpinner, Box, NotesWrapper, Footer }
