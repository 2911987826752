import React, { FC, memo } from 'react'

import { useGetBankName } from 'commons/hooks/bank/organisation'
import {
  AnnualReview,
  AnnualReviewSalesDeposits,
  BankAccountDeprecated,
  Company,
  LicenseDeprecated
} from 'commons/types/DTO/bank'
import { BankName } from 'commons/types/enums'
import { PageSection } from 'ui/components/Page'

import { AnnualReviewQuestions } from '../../../AnnualReviewQuestions'
import { Licensing } from './Licensing'
import { Overview } from './Overview'
import { SalesAndDepositTable } from './SalesAndDepositTable'

interface ReportProps {
  ar?: AnnualReview
  company?: Company
  affiliatedCompanies?: Company[]
  licenses?: LicenseDeprecated[]
  salesAndDeposits?: AnnualReviewSalesDeposits[]
  bankAccounts?: BankAccountDeprecated[]
}

export const Report: FC<ReportProps> = memo((props: ReportProps) => {
  const { ar, company, affiliatedCompanies, licenses, salesAndDeposits, bankAccounts } = props
  const { id: bankId } = useGetBankName()
  const isCBT = bankId === BankName.CBT

  return (
    <>
      <Overview ar={ar} company={company} affiliatedCompanies={affiliatedCompanies} />
      {licenses && <Licensing licenses={licenses} questionnaire={ar?.questionnaire} />}
      {!(ar?.status === 'new' && ar.created_at === ar.updated_at) && ar?.questionnaire && (
        <AnnualReviewQuestions questionnaire={ar?.questionnaire} isCBT={isCBT} />
      )}
      {!!salesAndDeposits &&
        !!bankAccounts &&
        salesAndDeposits.map((sd: AnnualReviewSalesDeposits, index) => (
          <PageSection title={!index ? 'Sales & Deposit Summary Tables' : undefined}>
            <SalesAndDepositTable
              license={licenses?.find((l) => l.id === sd.license_id)}
              salesAndDeposits={sd}
              bankAccounts={bankAccounts}
            />
          </PageSection>
        ))}
    </>
  )
})
