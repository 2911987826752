/* eslint-disable camelcase */

export type InternalTransferList = {
  amount: number
  createdDate: string
  id: number
  recipientCompanyID: number
  recipientCompanyName: string
  recipientLicenseID: number
  recipientLicenseName: string
  recipientLicenseType: string
  recipientName: string
  senderCompanyID: number
  senderCompanyName: string
  senderName: string
  status: string
  transferNumber: string
  enableCancel: boolean
}
