import React, { FC } from 'react'

import { getTaskRelatedEntityTypeLabel } from './getTaskRelatedEntityTypeLabel'

export interface TaskRelatedEntityTypeLabelProps {
  name: string
}

export const TaskRelatedEntityTypeLabel: FC<TaskRelatedEntityTypeLabelProps> = ({ name }) => (
  <span>{getTaskRelatedEntityTypeLabel(name)}</span>
)
