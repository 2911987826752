import { FormInputProps } from 'brief-form'
import { ChoiceValue } from 'ui/types'

import { SingleSelectAutoSuggestOpts } from '../SingleSelectAutoSuggest'

export type LicenseSelectOpts = Omit<SingleSelectAutoSuggestOpts, 'listName' | 'options'> & {
  filters?: { field: string; type: string; value: string | number }[]
  withInitialValue?: boolean
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface LicenseSelectProps extends FormInputProps<ChoiceValue, LicenseSelectOpts> {}
