import React, { FC, memo } from 'react'

import { IconSize, TextMask } from 'ui/components'
import { Info, InfoSet } from 'ui/components/Info'
import { Col, Row } from 'ui/themes/globalStyles'

import { CompanyAccountDetailsDetailsProps } from './CompanyAccountDetailsDetailsProps'

export const CompanyAccountDetailsDetails: FC<CompanyAccountDetailsDetailsProps> = memo(
  ({ value, state: { isConsumerAccountAvailable } }) => (
    <InfoSet legend="Account Details">
      <Row>
        <Col>
          <Info label="Business Type">{value.businessType}</Info>
        </Col>
        <Col>
          <Info label="Entity Type">{value.entityType}</Info>
        </Col>
      </Row>
      <Row>
        <Col>
          <Info label="State of Incorporation or Organization">{value.stateOfIncorporation}</Info>
        </Col>
        <Col>
          <Info label={isConsumerAccountAvailable ? 'EIN/TIN #' : 'EIN #'}>
            <TextMask text={value.EIN} iconSize={IconSize.XS} valueType="EIN" />
          </Info>
        </Col>
      </Row>
      {value.preLicenseMRBInfo && (
        <Row>
          <Col>
            <Info label="Please list the pending license application submission date and license type.">
              {value.preLicenseMRBInfo}
            </Info>
          </Col>
        </Row>
      )}
    </InfoSet>
  )
)
