import styled from 'styled-components'

export const PanelBody = styled.div`
  padding: 30px 36px 18px 36px;
`

export const Footer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 65px;
  max-width: 1920px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid #e2e2e2;
  background: #fff;
  padding: 0 50px;
  z-index: 1;
`

export const IsReviewCompleted = styled.div`
  > div {
    position: absolute;
    right: 450px;
    top: 25px;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
  }

  label {
    margin: 0;
  }
`
