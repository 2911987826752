import SC from 'styled-components'
import { CSS } from 'ui/helpers'

export const Box = SC.div<{ $hasImage: boolean }>`
  margin: 4px;
  margin-right: 12px;

  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 30px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
  text-align: center;
  overflow: hidden;
  background: ${(p) => (p.$hasImage ? 'transparent' : p.theme.uiKit.colors.global.grey.grey300)};
  position: relative;
  float: left;
  
  &:after {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    content: "";
    background: rgba(128, 128, 128, 0.11);
  }
`

export const Image = SC.img`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #fff;
`

export const Abbr = SC.span<{ $oneLetter: boolean }>`
  color: ${(p) => p.theme.uiKit.colors.global.grey.grey700};
  ${(p) => CSS.populateFont(p.theme.uiKit.typography.bodyM.regular)};
`
