import React, { FC, memo, useCallback, useEffect, useState } from 'react'

import { getRouteApi } from '@tanstack/react-router'
import { useGetBankAccountList } from 'commons/hooks/bank/bankAccount'
import { LicenseDeprecated } from 'commons/types/DTO/bank'
import {
  useAffiliatedCompanies,
  useAnnualReviewList,
  useAnnualReviewSalesDeposits,
  useCompany
} from '~bank-annual-review/hooks'

import { AnnualReviewLicensing } from '../AnnualReviewLicensing'
import { Report } from './components/Report'

import * as SC from './styles'

const AnnualReviewPrint: FC = memo(() => {
  const { id, companyId } = getRouteApi('/annual-review/detail/$companyId/$id/print').useParams()
  const [licenses, setLicenses] = useState<LicenseDeprecated[] | undefined>(undefined)
  const onReturnData = (data: LicenseDeprecated[]): void => setLicenses(data)
  const annualReviewSalesDeposits = useAnnualReviewSalesDeposits({ id: +id })
  const salesAndDeposits = annualReviewSalesDeposits.data?.records
  const salesAndDepositsLoading = annualReviewSalesDeposits.isFetching
  const bankAccountIds: number[] = []

  !salesAndDepositsLoading &&
    !!salesAndDeposits &&
    salesAndDeposits.forEach((item) => item.bank_account_id && bankAccountIds.push(item.bank_account_id))

  const { isFetching: bankAccountListLoading, data: bankAccountList } = useGetBankAccountList({ ids: bankAccountIds })

  const annualReviewList = useAnnualReviewList({
    company_id: +companyId,
    id: +id
  })

  const annualReview = annualReviewList.data?.records[0]
  const annualLoading = annualReviewList.isFetching

  const companyList = useCompany({ id: +companyId })
  const companyLoading = companyList.isFetching

  const affiliatedCompaniesList = useAffiliatedCompanies({ company_id: +companyId })
  const affiliatedCompaniesLoading = affiliatedCompaniesList.isFetching

  const company = companyList.data?.records[0]
  const affiliatedCompanies = affiliatedCompaniesList.data?.records?.filter((item) => item.id !== +companyId)

  const closeAfterPrint = useCallback(() => {
    setTimeout(() => {
      window.close()
    }, 0)
  }, [])

  useEffect(() => {
    window.addEventListener('afterprint', closeAfterPrint)
    return () => {
      window.removeEventListener('afterprint', closeAfterPrint)
    }
  }, [closeAfterPrint])

  useEffect(() => {
    if (
      !annualLoading &&
      !companyLoading &&
      !affiliatedCompaniesLoading &&
      !salesAndDepositsLoading &&
      !bankAccountListLoading &&
      licenses
    ) {
      window.print()
    }
  }, [
    annualLoading,
    companyLoading,
    affiliatedCompaniesLoading,
    salesAndDepositsLoading,
    bankAccountListLoading,
    licenses
  ])

  return (
    <SC.Container>
      <Report
        ar={annualReview}
        company={company}
        affiliatedCompanies={affiliatedCompanies}
        licenses={licenses}
        salesAndDeposits={salesAndDeposits}
        bankAccounts={bankAccountList}
      />
      <SC.Hidden>
        <AnnualReviewLicensing id={+id} companyId={+companyId} onReturnData={onReturnData} />
      </SC.Hidden>
    </SC.Container>
  )
})

export default AnnualReviewPrint
