{
  "name": "CBT",
  "logo": {
    "_1x": "logo1x.png",
    "_2x": "logo2x.png",
    "_3x": "logo3x.png"
  },
  "authLogo": {
    "_1x": "authLogo1x.png",
    "_2x": "authLogo2x.png",
    "_3x": "authLogo3x.png"
  },
  "background": {
    "_1x": "bg1x.png",
    "_2x": "bg2x.png",
    "_3x": "bg3x.png"
  },
  "uiKit": {
    "colors": {
      "theme": {
        "brand": {
          "lightest": "#4C9DCD",
          "lighter": "#0073B7",
          "primary": "#003E62",
          "darker": "#002B45",
          "darkest": "#0C1B2D"
        },
        "gradient": {
          "login": "linear-gradient(200deg, #345C75 0%, #002B45 100%)"
        },
        "positive": {
          "main": "#003E62",
          "darker": "#002B45",
          "darkest": "#0C1B2D"
        },
        "negative": {
          "main": "#B61A16",
          "darker": "#921512",
          "darkest": "#6D100D"
        }
      }
    },
    "borderRadius": {
      "default": "3px",
      "roundedModal": "6px"
    },
    "typography": {
      "bodyM": {
        "button": {
          "fontFamily": "Source Sans Pro",
          "fontWeight": "700",
          "lineHeight": "16px",
          "fontSize": "14px",
          "letterSpacing": "0.2px",
          "paragraphSpacing": "0px",
          "textCase": "uppercase",
          "textDecoration": "none"
        }
      },
      "bodyL": {
        "buttonL": {
          "textDecoration": "none",
          "textCase": "uppercase",
          "fontFamily": "Source Sans Pro",
          "fontWeight": "700",
          "lineHeight": "20px",
          "fontSize": "16px",
          "letterSpacing": "0.2px",
          "paragraphSpacing": "0px"
        }
      }
    }
  }
}
