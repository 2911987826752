import { LoggerInterface } from '../logger/loggersAndHandlers'

export class Logger {
  private static _instance: Logger

  loggers: LoggerInterface[] = []

  private constructor(loggers?: LoggerInterface[]) {
    if (loggers?.length) {
      this.loggers = loggers
    }
  }

  public static getInstance(loggers?: LoggerInterface[]): Logger {
    return this._instance || (this._instance = new this(loggers))
  }

  public addLoggers(loggers: LoggerInterface[]): void {
    loggers.forEach((logger) => {
      this.loggers.push(logger)
    })
  }

  public log(message: string, ...args): void {
    this.loggers.forEach((logger) => {
      logger.log(message, ...args)
    })
  }

  public error(message: string, ...args): void {
    this.loggers.forEach((logger) => {
      logger.error(message, ...args)
    })
  }

  public info(message: string, ...args): void {
    this.loggers.forEach((logger) => {
      logger.info(message, ...args)
    })
  }

  warning(message: string, ...args): void {
    this.loggers.forEach((logger) => {
      logger.warning(message, ...args)
    })
  }
}
