import { DocumentStatuses } from 'commons/types/DTO/commons'

import { ChipFace } from '../../Chip'

const documentPeriodFaceMap: Partial<Record<DocumentStatuses, ChipFace>> = {
  [DocumentStatuses.APPROVED]: ChipFace.PRIMARY,
  [DocumentStatuses.REJECTED]: ChipFace.DANGER,
  [DocumentStatuses.INCOMPLETE]: ChipFace.DANGER
}

export const getDocumentPeriodChip = (name: DocumentStatuses): ChipFace =>
  documentPeriodFaceMap[name] || ChipFace.DEFAULT
