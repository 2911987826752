import('./bootstrap')

document.addEventListener('DOMContentLoaded', () => {
  const timerId = setInterval(() => {
    const launcher = document.getElementById('launcher')
    if (launcher) {
      launcher.setAttribute('hidden', 'true')
      ;(window as any).zE.hide()
      clearInterval(timerId)
    }
  }, 50)
})
