import { Company, Contact, ExternalLicense, LicenseDeprecated } from 'commons/types/DTO/bank'
import { ApiReadResponse } from 'commons/types/api'
import { DataSourceItem } from 'ui/components'

type TableDataShape = {
  companies: ApiReadResponse<Company>
  contacts: ApiReadResponse<Contact>
  licenses: ApiReadResponse<LicenseDeprecated>
  externalLicenses: ApiReadResponse<ExternalLicense>
}

type RelatedEntitiesSelector = {
  id?: number
  name?: string
  type: string
}

export const dataSourceSelector = (data: TableDataShape): DataSourceItem<RelatedEntitiesSelector>[] => {
  const companies = (data?.companies?.records || []).map((company) => ({
    item: {
      id: company.id,
      name: company.name,
      type: 'Company'
    }
  }))

  const contacts = (data?.contacts?.records || []).map((contact) => ({
    item: {
      id: contact.id,
      name: [contact.first_name, contact.last_name].join(' '),
      type: 'Contact'
    }
  }))

  const externalLicenses = (data?.externalLicenses?.records || []).map((license) => {
    const internalLicenseId = license.license_id

    const internalLicense = internalLicenseId
      ? data?.licenses?.records.find((internalLicense) => internalLicense.id === internalLicenseId)
      : null

    return {
      item: {
        id: license.id,
        name: `${license.name} (${license.external_id})`,
        type: 'Vendor/Customer',
        internalLicense: internalLicense && { id: internalLicense.id, companyId: internalLicense.company_id }
      }
    }
  })

  return [...companies, ...contacts, ...externalLicenses]
}
