import SC from 'styled-components'
import { Link } from 'ui/components/Link'

export const NameLink = SC(Link)`
  > a {
    > span {
      display: flex !important;
      align-items: center;
      white-space: nowrap;
      
      i {
        margin-left: 10px;
        flex-shrink: 0;
      }
    }
  }
`
