import { useCallback } from 'react'

import { FormChangedHandler, useFormData } from 'brief-form'

import { FormPaths } from '../../enums'
import { useSyncFormData } from '../../hooks'
import { useValidateFormPersist } from '../../hooks/useValidateFormPersist'
import { MerchantQuestionnaireFormValue } from '../../types'
import { UseMerchantQuestionnaireForm, UseMerchantQuestionnaireFormReturnType } from './types'

export const useMerchantQuestionnaireForm = (
  props: UseMerchantQuestionnaireForm
): UseMerchantQuestionnaireFormReturnType => {
  const { companyIndex, onChange, formData } = props

  const onFormChanged: FormChangedHandler<MerchantQuestionnaireFormValue> = useCallback(
    (formValues, formErrors) => onChange({ companyIndex, formData: { formValues, formErrors } }),
    [onChange, companyIndex]
  )

  const { config, Field, set, validate } = useFormData<MerchantQuestionnaireFormValue>({
    onFormChanged,
    initialValue: formData.formValues,
    initialErrors: formData.formErrors
  })

  useSyncFormData({ set, formData })
  const formKey = `${FormPaths.COMPANY_MERCHANT_QUESTIONNAIRE}-${companyIndex}`
  useValidateFormPersist<MerchantQuestionnaireFormValue>({
    formName: formKey,
    validate,
    config
  })

  return { Field, config }
}
