import React from 'react'

import SC from 'styled-components'

import { statusColorMap, statusLabel } from '../constants'
import { BSAStatus } from '../types'

const ColoredTag = SC.span`
  background-color: ${(props) => props.color};
  color: #fff;
  line-height: 22px;
  border-radius: 20px;
  padding: 3px 7px;
`

interface TagProps {
  status: BSAStatus
}

export const Tag = ({ status }: TagProps) => (
  <ColoredTag color={statusColorMap[status]}>{statusLabel[status]}</ColoredTag>
)
