import React, { FC, memo } from 'react'

import { BreadCrubmsProps } from './BreadCrubmsProps'

import * as SC from './styles'

export const BreadCrubms: FC<BreadCrubmsProps> = memo(
  ({ monthlyAnalyticsName, quarterlyAnalyticsID, quarterlyAnalyticsName }) => (
    <>
      <SC.Quarter to="/qr/$id" params={{ id: quarterlyAnalyticsID }}>
        {quarterlyAnalyticsName}
      </SC.Quarter>
      <SC.Arrow>&gt;</SC.Arrow>
      <SC.Month>{monthlyAnalyticsName}</SC.Month>
    </>
  )
)
