import React, { Suspense } from 'react'

import { Outlet } from '@tanstack/react-router'
import { BPLayout } from 'ui/components/BPLayout'
import { Spinner, SpinnerSize } from 'ui/components/Spinner'

import { MRSideBar } from './components'

export const MRPageWrapper = () => (
  <BPLayout sidebar={<MRSideBar />}>
    <Suspense fallback={<Spinner centered size={SpinnerSize.M} />}>
      <Outlet />
    </Suspense>
  </BPLayout>
)
