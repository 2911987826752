/* eslint-disable camelcase */

export type AnnualReviewSalesDepositsQuarterInfo = {
  cash?: number
  checks?: number
  credit_debit?: number
  deposits?: number
  invoices?: number
  label?: string
  sales?: number
  other?: number
  pos?: number
  transfers?: number
}
