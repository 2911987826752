import { useState } from 'react'

import { Toast } from 'ui/components'

import { documentAccept } from '../services'

export const useAccept = (documentId: number, invalidate: () => Promise<void>) => {
  const [isShowingAcceptDialog, setIsShowingAcceptDialog] = useState(false)
  const [isAccepting, setIsAccepting] = useState(false)

  const closeAcceptDialog = () => {
    setIsShowingAcceptDialog(false)
  }

  const openAcceptDialog = () => {
    setIsShowingAcceptDialog(true)
  }

  const accept = async (notes: string) => {
    setIsAccepting(true)
    await documentAccept(documentId, notes)
      .catch((err: Error) => {
        Toast.errorSmall(err.message)
      })
      .finally(async () => {
        await invalidate()
        setIsAccepting(false)
        closeAcceptDialog()
      })
  }

  return {
    isShowingAcceptDialog,
    isAccepting,

    openAcceptDialog,
    closeAcceptDialog,
    accept
  }
}
