import { AnyRoute, createRoute, lazyRouteComponent } from '@tanstack/react-router'

import { applicationDocumentCreateLoader } from './applicationDocumentCreateLoader'

export const DocumentCreateRoute = <T extends AnyRoute>(indexRoute: T) =>
  createRoute({
    getParentRoute: () => indexRoute,
    path: '$applicationId/document/create',
    loader: applicationDocumentCreateLoader,
    component: lazyRouteComponent(() => import('./ApplicationDocumentCreate')),
    shouldReload: (p) => p.cause === 'enter'
  })
