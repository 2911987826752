import { MutableRefObject, useEffect } from 'react'

// block event bubbling for compatibility with Dialog's outside click closing
// coz Popper create DOM container in the root of the Body
// and Dialog's outside click will work on Popper click
// so, just block this Popper click
// and that doesn't work with "click" event, make sure outside click handler also listen "mousedown" event
export const useStopPropagation = (refs: MutableRefObject<HTMLElement | null>) => {
  useEffect(() => {
    const stopPropagation = (e: Event) => {
      e.stopPropagation()
    }
    const { current: popperDom } = refs

    refs.current?.addEventListener('mousedown', stopPropagation)

    return () => {
      popperDom?.removeEventListener('mousedown', stopPropagation)
    }
  }, [refs.current])
}
