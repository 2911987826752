import { ServerOptions } from './serverOptions'

export type RemoteDataSource<Data extends { [key: string]: any }, Filter extends { [key: string]: any }> = {
  /**
   * Key to store data.
   */
  key: string

  /**
   * URL to fetch data from.
   */
  url: string

  /**
   * HTTP method (POST by default).
   */
  method?: string

  /**
   * Function designed to build proper options object for current datasource. It may consider
   * current table data to build result object.
   */
  options?: (data?: Data, filter?: Filter) => ServerOptions
}
