export const posTypes = [
  { value: 'manual', label: 'Manual (None)' },
  { value: 'adilas', label: 'Adilas' },
  { value: 'biotrackthc', label: 'BioTrackTHC' },
  { value: 'greenbits', label: 'GreenBits' },
  { value: 'flowhub', label: 'Flowhub' },
  { value: 'mj_freeway', label: 'MJ Freeway' },
  { value: 'leaf_logix', label: 'Leaf Logix' },
  { value: 'thsuite', label: 'TH Suite' },
  { value: 'other', label: 'Other' }
]
