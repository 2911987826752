import { useCallback } from 'react'

import { FormChangedHandler, useFormData } from 'brief-form'

import { FormPaths } from '../../enums'
import { useSyncFormData } from '../../hooks'
import { useValidateFormPersist } from '../../hooks/useValidateFormPersist'
import { ATMServicesChangeHandler, ATMServicesFormData, ATMServicesFormValue } from '../../types/forms'

export const useATMServicesForm = ({
  atmIndex,
  companyIndex,
  formData,
  onChange
}: {
  atmIndex: number
  companyIndex: number
  formData: ATMServicesFormData
  onChange: ATMServicesChangeHandler
}) => {
  const onFormChanged: FormChangedHandler<ATMServicesFormValue> = useCallback(
    (formValues, formErrors) => onChange({ atmIndex, companyIndex, formData: { formValues, formErrors } }),
    [onChange, atmIndex, companyIndex]
  )

  const { config, Field, set, validate } = useFormData<ATMServicesFormValue>({
    onFormChanged,
    initialValue: formData.formValues,
    initialErrors: formData.formErrors
  })

  useSyncFormData({ set, formData })
  const formKey = `${FormPaths.ATM_SERVICES}-${companyIndex}`
  useValidateFormPersist<ATMServicesFormValue>({
    formName: formKey,
    validate,
    config
  })
  return { Field, config }
}
