import { PortalName } from 'commons/types/enums'

const publicCookieNamesByPortal = {
  [PortalName.BP]: 'bp_public_token',
  [PortalName.CP]: 'cp_public_token',
  [PortalName.BOP]: 'bo_public_token'
}

const getPublicCookieNameByPortal = (portalName: PortalName) => publicCookieNamesByPortal[portalName]

export default getPublicCookieNameByPortal
