import { WriteHook, useMutationManager, useStateManager } from 'react-query-state-manager'

import { BsaSar } from 'commons/types/DTO/bank/bsa'

import * as Service from '../../service'

export const useSaveSAR: WriteHook<BsaSar, BsaSar> = (opts) => {
  const sm = useStateManager()

  return useMutationManager<BsaSar, BsaSar>((sar) => Service.SAR.save(sar), {
    ...opts,
    onSuccess: async (payload, response) => {
      await sm.invalidate(['tables', 'SARs List'])
      await sm.invalidate(['sars', response.id])
      await opts?.onSuccess(payload, response)
    }
  })
}
