import { SalesSourceItem } from './SalesSourceItem'

export type SalesInfo = {
  gramsPerQty: number
  gramsQty: number
  gramsSold: number
  salesSourceItems: SalesSourceItem[]
  total: number
  unitsPerQty: number
  unitsQty: number
  unitsSold: number
}
