import { InternalTransferDeprecated, InternalTransfersExport } from 'commons/types/DTO/bank'
import { ServerOptions } from 'commons/types/api'
import { Request } from 'commons/utils/request/request'

export const getInternalTransferList = (options: ServerOptions) =>
  Request.post(Request.urls.bank.internalTransferList, options)

export const getInternalTransfer = (id: number) => Request.post(Request.urls.bank.internalTransferList, { id })

export const getInternalTransferExportsList = (id: number) =>
  Request.post(Request.urls.bank.internalTransfersExportList, { id })

export const processInternalTransferExport = (id: number) =>
  Request.put<InternalTransfersExport>(Request.urls.bank.internalTransfersExportProcess, { id })

export const deleteInternalTransfer = (id: number) => Request.delete(Request.urls.bank.internalTransfer, { id })

export const saveInternalTransfer = (item: InternalTransferDeprecated) => {
  const func = item.id ? Request.put : Request.post
  return func<InternalTransferDeprecated>(Request.urls.bank.internalTransfer, item)
}

export const cancelInternalTransfer = (id: number) => Request.put(Request.urls.bank.internalTransferCancel, { id })
