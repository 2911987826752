export type CompanyListWebPageRequest = {
  relationshipsIDs: number[]
  relationshipsIsHolding: boolean | string | null
  relationshipsLimit: number
  relationshipsOffset: number
  relationshipsScope: string
  relationshipsSearchQuery: string
  relationshipsSortBy: string
  relationshipsSortDesc: boolean
  relationshipsEntityType?: string | null
  relationshipsCustomerStatus?: string | null
  relationshipsBusinessType?: string | null
}
