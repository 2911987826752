import { AnyRoute, createRoute, lazyRouteComponent } from '@tanstack/react-router'

export const createContactRoute = <T extends AnyRoute>(indexRoute: T) =>
  createRoute({
    getParentRoute: () => indexRoute,
    path: `new`,
    component: lazyRouteComponent(() => import('./ContactManage'))
  })

export const editContactRoute = <T extends AnyRoute>(indexRoute: T) =>
  createRoute({
    getParentRoute: () => indexRoute,
    path: `$contactId/edit`,
    component: lazyRouteComponent(() => import('./ContactManage'))
  })
