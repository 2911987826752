import isArray from 'lodash/isArray'
import omit from 'lodash/omit'
import { SortDirection, TableSortingOrder } from 'ui/components'
import { DocumentListFilterValue, DocumentListV2RequestPayload } from '~bank-documents/types/documentListV2'

import { TableState } from '../useTableState/types'

type MapValueToRequestPayloadParams = {
  externalFilters: DocumentListV2RequestPayload
  currentFilter: Partial<DocumentListFilterValue>
  tableStatePagination: Partial<TableState>
  tableStateSorting: TableSortingOrder[]
}

// MP-7974, hack: in case of onlyMyCompanies: true, CompanySelect return companyId as array
export const mapValueToRequestPayload = ({
  externalFilters,
  currentFilter,
  tableStatePagination,
  tableStateSorting
}: MapValueToRequestPayloadParams): DocumentListV2RequestPayload => {
  const rawFilter = {
    ...externalFilters.filter,
    ...currentFilter,
    page: tableStatePagination.page,
    size: tableStatePagination.pageSize,
    sortBy: tableStateSorting[0].field,
    sortDesc: tableStateSorting[0].direction === SortDirection.DESC
  }

  const filter = isArray(rawFilter.companyId) ? omit(rawFilter, 'companyId') : { ...rawFilter, onlyMyCompanies: false }

  return {
    filter,
    ...({ search: externalFilters?.search } || {})
  }
}
