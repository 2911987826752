import styled from 'styled-components'

const Title = styled.span`
  display: flex;
  align-items: center;

  > span {
    display: inline-block;
    margin-left: 10px;
  }
`

const Content = styled.div`
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const s = { Title, Content }
