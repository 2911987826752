export type BankUser = {
  active: boolean
  assigned_companies_ids: number[]
  created_at: string
  deleted_at: string
  email: string
  first_name: string
  id: string
  language: string
  last_login: string
  last_name: string
  mfa_required: boolean
  organization_id: number
  permissions: string[]
  phone: string
  profile: string
  sf_id: string
  staff_member: boolean
  time_zone: string
  updated_at: string
  is_auditor: boolean
  is_credit: boolean
  is_martinhood: boolean
  is_bsa: boolean
  is_sales_personnel?: boolean
}
