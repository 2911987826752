import React, { FC, memo } from 'react'
import { useQueryClient } from 'react-query'

import { useNavigate } from '@tanstack/react-router'
import { Button } from 'ui/components/Buttons'
import { IconName } from 'ui/components/Icon'
import { Link } from 'ui/components/Link'
import { useCTRApprove, useCTRCancel } from '~bank-bsa-reporting/hooks/ctr'
import { CTR_DETAILS_KEY } from '~bank-bsa-reporting/hooks/ctr/useGetCTRDetails'

import { s } from './styles'

type Props = {
  id: number
  nextId?: number
  previousId?: number
  showApprove: boolean
  showCancel: boolean
}

export const Footer: FC<Props> = memo((props) => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const { id, nextId, previousId, showApprove, showCancel } = props

  const onSuccessMutation = async () => {
    await queryClient.invalidateQueries([CTR_DETAILS_KEY])
  }

  const onErrorMutation = () => navigate({ to: './edit' })

  const { routine: approveCTR, isLoading: isApproving } = useCTRApprove(onSuccessMutation, onErrorMutation)
  const { routine: cancelCTR, isLoading: isCanceling } = useCTRCancel(onSuccessMutation, onErrorMutation)

  return (
    <s.Box>
      <Link to="/bsa/ctrs">
        <Button face="neutral">Go Back to List</Button>
      </Link>
      <s.Right>
        {previousId ? (
          <Link to="/bsa/ctrs/$id" params={{ id: previousId }}>
            <s.IconButton face="neutral">
              <s.StyledIcon name={IconName.ARROW_PREVIOUS} /> Prev CTR
            </s.IconButton>
          </Link>
        ) : (
          <span />
        )}
        {!!nextId && (
          <Link to="/bsa/ctrs/$id" params={{ id: nextId }}>
            <s.IconButton face="neutral">
              <s.StyledIcon name={IconName.ARROW_NEXT} /> Next CTR
            </s.IconButton>
          </Link>
        )}
        {showCancel && (
          <Button face="neutral" disabled={isApproving || isCanceling} onClick={() => cancelCTR({ id })}>
            Cancel CTR
          </Button>
        )}
        {showApprove && (
          <Button disabled={isApproving || isCanceling} onClick={() => approveCTR({ id })} face="positive">
            Approve CTR
          </Button>
        )}
      </s.Right>
    </s.Box>
  )
})
