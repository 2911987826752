import React, { FC, memo } from 'react'

import { Form } from 'brief-form'
import { Select } from 'ui/components/Select'
import { FilterPanel } from 'ui/components/Table'

import { FilterProps } from './FilterProps'

import * as SC from './styles'

export const Filter: FC<FilterProps> = memo((props) => {
  const {
    form: { isDirty, config, set, Field },
    expanded,
    onToggle,
    statuses
  } = props

  const mappedStatuses = Object.keys(statuses).map((item) => ({
    label: statuses[item],
    value: item
  }))

  return (
    <FilterPanel isDirty={isDirty} onReset={() => set({ reset: true })} expanded={expanded} onToggle={onToggle}>
      <SC.FormWrapper>
        <Form config={config}>
          <SC.StatusWrapper>
            <Field
              name="status"
              label="Status"
              input={Select}
              inputProps={{
                data: mappedStatuses
              }}
            />
          </SC.StatusWrapper>
        </Form>
      </SC.FormWrapper>
    </FilterPanel>
  )
})
