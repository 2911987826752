import { AnnualReviewSalesDeposits } from 'commons/types/DTO/bank'
import { TableColumn } from 'ui/components/Table'

export const createColumns = (item: AnnualReviewSalesDeposits): TableColumn<any>[] => [
  {
    title: 'Name',
    key: 'name'
  },
  {
    title: item.quarter1_info?.label,
    key: 'quarter1',
    align: 'right'
  },
  {
    title: item.quarter2_info?.label,
    key: 'quarter2',
    align: 'right'
  },
  {
    title: item.quarter3_info?.label,
    key: 'quarter3',
    align: 'right'
  },
  {
    title: item.quarter4_info?.label,
    key: 'quarter4',
    align: 'right'
  },
  {
    title: 'Total',
    key: 'total',
    align: 'right'
  }
]
