import * as React from 'react'

import { FieldProps, FormInputProps } from 'brief-form'
import { AnnualReviewQuestionnaire } from 'commons/types/DTO/bank'
import SC from 'styled-components'
import { CheckBox } from 'ui/components/CheckBox'
import { DatePicker } from 'ui/components/DatePicker'
import { Hr } from 'ui/components/Hr'
import { TextInput, TextareaInput } from 'ui/components/InputV2'
import { PageSection, PageSectionFace } from 'ui/components/Page'
import { RadioGroup } from 'ui/components/RadioGroup'

import { AnnualReviewQuestionList } from '../../../../../AnnualReviewDetail/components/AnnualReviewQuestions'
// eslint-disable-next-line max-len
import { annualReviewQuestionnaireMapping } from '../../../../../AnnualReviewDetail/components/AnnualReviewQuestions/selector'
import { AnnualReviewFormValue } from '../../AnnualReviewFormValue'

interface AnnualReviewEditQuestionsProps {
  Field: <Input extends React.ComponentType<FormInputProps<any, any>>>(
    props: FieldProps<Input, AnnualReviewFormValue>
  ) => JSX.Element
  questionnaire: AnnualReviewQuestionnaire
  formValue: AnnualReviewFormValue
  isShore: boolean
}

const FieldGroup = SC.div`
  display: flex;
  flex-direction: row;
`

export const AnnualReviewEditQuestions = ({
  Field,
  formValue,
  isShore
}: AnnualReviewEditQuestionsProps): JSX.Element => {
  const items = annualReviewQuestionnaireMapping(
    {
      financials: {
        profitabilityTrend: (
          <Field
            name="questionnaire.financials_profitability_trend"
            input={TextareaInput}
            input-multiline
            inputProps={{}}
          />
        ),
        periodCollected: <Field name="questionnaire.financials_period_collected" input={TextInput} inputProps={{}} />,
        investorDebtholders: (
          <Field name="questionnaire.financials_investor_debtholders" input={TextareaInput} inputProps={{}} />
        ),
        depositsMatching: (
          <Field
            name="questionnaire.financials_deposits_matching"
            label=""
            input={RadioGroup}
            inputProps={{
              name: 'questionnaire.financials_deposits_matching',
              data: [
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' }
              ]
            }}
          />
        ),
        businessCondition: (
          <Field name="questionnaire.financials_business_condition" input={TextareaInput} inputProps={{}} />
        ),
        becomingFailing: (
          <Field name="questionnaire.financials_becoming_failing" input={TextareaInput} inputProps={{}} />
        ),
        isMaterialsRegardingIncome: (
          <Field
            name="questionnaire.financials_is_materials_regarding_income"
            input={RadioGroup}
            inputProps={{
              name: 'questionnaire.financials_is_materials_regarding_income',
              data: [
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' }
              ]
            }}
          />
        ),
        currentConditionIsAcceptable: (
          <Field
            name="questionnaire.financials_current_condition_is_acceptable"
            input={RadioGroup}
            inputProps={{
              name: 'questionnaire.financials_current_condition_is_acceptable',
              data: [
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' }
              ]
            }}
          />
        ),
        materialsNotes: (
          <Field
            name="questionnaire.financials_materials_notes"
            input={TextareaInput}
            inputProps={{
              disabled: formValue['questionnaire.financials_is_materials_regarding_income'] !== 'Yes'
            }}
          />
        ),
        acceptableNotes: (
          <Field
            name="questionnaire.financials_acceptable_notes"
            input={TextareaInput}
            inputProps={{
              disabled: formValue['questionnaire.financials_current_condition_is_acceptable'] !== 'No'
            }}
          />
        )
      },
      transactions: {
        type: (
          <FieldGroup>
            <Field name="questionnaire.transactions_cash" input={CheckBox} inputProps={{ checkBoxLabel: 'Cash' }} />
            <Field name="questionnaire.transactions_atm" input={CheckBox} inputProps={{ checkBoxLabel: 'ATM' }} />
            <Field name="questionnaire.transactions_ach" input={CheckBox} inputProps={{ checkBoxLabel: 'ACH' }} />
            <Field
              name="questionnaire.transactions_check"
              input={CheckBox}
              inputProps={{ checkBoxLabel: 'Check Deposit' }}
            />
          </FieldGroup>
        ),
        volProjected: (
          <FieldGroup>
            <Field
              name="questionnaire.transactions_vol_projected"
              label=""
              input={RadioGroup}
              inputProps={{
                name: 'questionnaire.transactions_vol_projected',
                data: [
                  { label: 'Yes', value: 'Yes' },
                  { label: 'No', value: 'No' }
                ]
              }}
            />
            <i style={{ marginRight: '1.45rem' }}>(</i>
            <Field
              name="questionnaire.transactions_vol_projected_exactly"
              label=""
              input={RadioGroup}
              inputProps={{
                name: 'questionnaire.transactions_vol_projected_exactly',
                data: [
                  { label: 'Higher or', value: 'Higher' },
                  { label: 'Lower', value: 'Lower' }
                ]
              }}
            />
            <i>)</i>
          </FieldGroup>
        ),
        volProjectedExactly: null,
        expectExactly: (
          <Field
            name="questionnaire.transactions_expect_exactly"
            label=""
            input={RadioGroup}
            inputProps={{
              name: 'questionnaire.transactions_expect_exactly',
              data: [
                { label: 'Increase', value: 'Increase' },
                { label: 'Decrease', value: 'Decrease' },
                { label: 'Stable', value: 'Stable' },
                { label: 'Unknown', value: 'Unknown' }
              ]
            }}
          />
        ),
        expectExplain:
          formValue['questionnaire.transactions_expect_exactly'] === 'Unknown' ? (
            <Field
              name="questionnaire.transactions_expect_explain"
              input={TextareaInput}
              inputProps={{
                disabled: formValue['questionnaire.transactions_expect_exactly'] !== 'Unknown'
              }}
            />
          ) : undefined,
        matchTrans: (
          <Field
            name="questionnaire.transactions_match_trans"
            label=""
            input={RadioGroup}
            inputProps={{
              name: 'questionnaire.transactions_match_trans',
              data: [
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' }
              ]
            }}
          />
        ),
        notes: (
          <Field
            name="questionnaire.transactions_notes"
            input={TextareaInput}
            inputProps={{
              disabled: formValue['questionnaire.transactions_match_trans'] !== 'No'
            }}
          />
        )
      },
      covenant: {
        complying: (
          <Field
            name="questionnaire.covenant_complying"
            label=""
            input={RadioGroup}
            inputProps={{
              name: 'questionnaire.covenant_complying',
              data: [
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' }
              ]
            }}
          />
        ),
        nonComplianceDesc: (
          <Field name="questionnaire.covenant_non_compliance_desc" input={TextareaInput} inputProps={{}} />
        ),
        transparent: (
          <Field
            name="questionnaire.covenant_transparent"
            label=""
            input={RadioGroup}
            inputProps={{
              name: 'questionnaire.covenant_transparent',
              data: [
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' }
              ]
            }}
          />
        )
      },
      siteVisit: {
        freq: (
          <Field
            name="questionnaire.site_visit_freq"
            label=""
            input={RadioGroup}
            inputProps={{
              name: 'questionnaire.site_visit_freq',
              data: isShore
                ? [
                    { label: 'Quarterly', value: 'Quarterly' },
                    { label: 'Semi-Annual', value: 'Semi-Annual' },
                    { label: 'Annual', value: 'Annual' },
                    { label: '18-Month', value: '18-Month' }
                  ]
                : [
                    { label: 'Quarterly', value: 'Quarterly' },
                    { label: 'Semi-Annual', value: 'Semi-Annual' }
                  ]
            }}
          />
        ),
        lastDate: <Field name="questionnaire.site_visit_last_date" input={DatePicker} inputProps={{}} />,
        complyingRegulations: (
          <Field
            name="questionnaire.site_visit_complying_regulations"
            label=""
            input={RadioGroup}
            inputProps={{
              name: 'questionnaire.site_visit_complying_regulations',
              data: [
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' }
              ]
            }}
          />
        ),
        violationRectified: (
          <Field
            name="questionnaire.site_visit_violation_rectified"
            label=""
            input={RadioGroup}
            inputProps={{
              name: 'questionnaire.site_visit_violation_rectified',
              data: [
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
                { label: 'N/A', value: 'N/A' }
              ]
            }}
          />
        ),
        scoresAcceptable: (
          <Field
            name="questionnaire.site_visit_scores_acceptable"
            label=""
            input={RadioGroup}
            inputProps={{
              name: 'questionnaire.site_visit_scores_acceptable',
              data: [
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' }
              ]
            }}
          />
        ),
        scoresAcceptableExactly: (
          <Field
            name="questionnaire.site_visit_scores_acceptable_exactly"
            label=""
            input={RadioGroup}
            inputProps={{
              name: 'questionnaire.site_visit_scores_acceptable_exactly',
              data: [
                { label: 'Improving', value: 'Improving' },
                { label: 'Declining', value: 'Declining' },
                { label: 'Stable', value: 'Stable' }
              ]
            }}
          />
        ),
        shutdownRisk: (
          <Field
            name="questionnaire.site_visit_shutdown_risk"
            label=""
            input={RadioGroup}
            inputProps={{
              name: 'questionnaire.site_visit_shutdown_risk',
              data: [
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' }
              ]
            }}
          />
        ),
        siteVisitHasStateAuditAtReviewPeriod: (
          <Field
            name="questionnaire.site_visit_has_state_audit_at_review_period"
            label=""
            input={RadioGroup}
            inputProps={{
              name: 'questionnaire.site_visit_has_state_audit_at_review_period',
              data: [
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' }
              ]
            }}
          />
        ),
        siteVisitStateAuditNotes: (
          <Field
            name="questionnaire.site_visit_state_audit_notes"
            label=""
            input={TextareaInput}
            inputProps={{
              disabled: formValue['questionnaire.site_visit_has_state_audit_at_review_period'] !== 'Yes'
            }}
          />
        )
      },
      ownership: {
        changes: (
          <Field
            name="questionnaire.ownership_changes"
            label=""
            input={RadioGroup}
            inputProps={{
              name: 'questionnaire.ownership_changes',
              data: [
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' }
              ]
            }}
          />
        ),
        providedForms: (
          <Field
            name="questionnaire.ownership_provided_forms"
            label=""
            input={RadioGroup}
            inputProps={{
              name: 'questionnaire.ownership_provided_forms',
              data: [
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' }
              ]
            }}
          />
        ),
        changesApproved: (
          <Field
            name="questionnaire.ownership_changes_approved"
            label=""
            input={RadioGroup}
            inputProps={{
              name: 'questionnaire.ownership_changes_approved',
              data: [
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
                { label: 'N/A', value: 'N/A' }
              ]
            }}
          />
        ),
        ofacTloReviewed: (
          <Field
            name="questionnaire.ownership_ofac_tlo_reviewed"
            label=""
            input={RadioGroup}
            inputProps={{
              name: 'questionnaire.ownership_ofac_tlo_reviewed',
              data: [
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
                { label: 'N/A', value: 'N/A' }
              ]
            }}
          />
        ),
        badNews: (
          <Field
            name="questionnaire.ownership_bad_news"
            label=""
            input={RadioGroup}
            inputProps={{
              name: 'questionnaire.ownership_bad_news',
              data: [
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' }
              ]
            }}
          />
        ),
        badNewsDesc: (
          <Field
            name="questionnaire.ownership_bad_news_desc"
            input={TextareaInput}
            inputProps={{
              disabled: formValue['questionnaire.ownership_bad_news'] !== 'Yes'
            }}
          />
        ),
        badNewsCleared: (
          <Field
            name="questionnaire.ownership_bad_news_cleared"
            label=""
            input={RadioGroup}
            inputProps={{
              name: 'questionnaire.ownership_bad_news_cleared',
              data: [
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' }
              ]
            }}
          />
        ),
        badNewsClearedExplain: (
          <Field
            name="questionnaire.ownership_bad_news_cleared_explain"
            input={TextareaInput}
            inputProps={{
              disabled: formValue['questionnaire.ownership_bad_news_cleared'] !== 'No'
            }}
          />
        ),
        ownershipHasMaterialChangesInManagementAtReviewPeriod: (
          <Field
            name="questionnaire.ownership_has_material_changes_in_management_at_review_period"
            label=""
            input={RadioGroup}
            inputProps={{
              name: 'questionnaire.ownership_has_material_changes_in_management_at_review_period',
              data: [
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' }
              ]
            }}
          />
        ),
        ownershipMaterialChangesInManagementNotes: (
          <Field
            name="questionnaire.ownership_material_changes_in_management_notes"
            input={TextareaInput}
            inputProps={{
              disabled:
                formValue['questionnaire.ownership_has_material_changes_in_management_at_review_period'] !== 'Yes'
            }}
          />
        )
      },
      recom: {
        recommended: (
          <Field
            name="questionnaire.recom_recommended"
            label=""
            input={RadioGroup}
            inputProps={{
              name: 'questionnaire.recom_recommended',
              data: [
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' }
              ]
            }}
          />
        ),
        status: (
          <Field
            name="questionnaire.recom_status"
            label=""
            input={RadioGroup}
            inputProps={{
              name: 'questionnaire.recom_status',
              data: [
                { label: 'Satisfactory', value: 'Satisfactory' },
                { label: 'Requires Additional Monitoring', value: 'Requires Additional Monitoring' },
                { label: 'Unsatisfactory', value: 'Unsatisfactory' }
              ]
            }}
          />
        ),
        changeVisits: (
          <Field
            name="questionnaire.recom_change_visits"
            label=""
            input={RadioGroup}
            inputProps={{
              name: 'questionnaire.recom_change_visits',
              data: isShore
                ? [
                    { label: 'Quarterly', value: 'Quarterly' },
                    { label: 'Semi-Annual', value: 'Semi-Annual' },
                    { label: 'Annual', value: 'Annual' },
                    { label: '18-Month', value: '18-Month' }
                  ]
                : [
                    { label: 'Quarterly', value: 'Quarterly' },
                    { label: 'Semi-Annual', value: 'Semi-Annual' }
                  ]
            }}
          />
        ),
        recomComments: <Field name="questionnaire.recom_comments" input={TextareaInput} inputProps={{}} />
      }
    },
    {
      financials: {
        profitabilityTrend: {
          multiline: true
        },
        investorDebtholders: {
          multiline: true
        },
        businessCondition: {
          multiline: true
        },
        becomingFailing: {
          multiline: true
        },
        acceptableNotes: {
          multiline: true
        },
        materialsNotes: {
          multiline: true
        }
      },
      siteVisit: {
        siteVisitStateAuditNotes: {
          multiline: true
        }
      },
      transactions: {
        expectExplain: {
          multiline: true
        },
        notes: {
          multiline: true
        }
      },
      covenant: {
        nonComplianceDesc: {
          multiline: true
        }
      },
      ownership: {
        badNewsDesc: {
          multiline: true
        },
        badNewsClearedExplain: {
          multiline: true
        },
        ownershipMaterialChangesInManagementNotes: {
          multiline: true
        }
      },
      recom: {
        recomComments: {
          multiline: true
        }
      }
    },
    undefined,
    isShore
  )

  return (
    <>
      {items.map((data) => (
        <section key={data.title}>
          <PageSection key={`${data.title}-0`} title={data.title} face={PageSectionFace.SECONDARY}>
            <AnnualReviewQuestionList items={data.items} bottomIndent={false} />
          </PageSection>
          <Hr key={`${data.title}-1`} />
        </section>
      ))}
    </>
  )
}
