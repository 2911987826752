import React, { FC, memo, useCallback, useEffect } from 'react'

import { formatCurrency } from 'commons/utils'
import { DateTime } from 'ui/components/DateTime'

import { CTRListProps } from './CTRListProps'

import { Table, Td, Th } from './styles'

export const CTRList: FC<CTRListProps> = memo((props) => {
  const { items } = props

  const closeAfterPrint = useCallback(() => {
    setTimeout(() => {
      window.close()
    }, 0)
  }, [])

  useEffect(() => {
    window.addEventListener('afterprint', closeAfterPrint)
    return () => {
      window.removeEventListener('afterprint', closeAfterPrint)
    }
  }, [])

  useEffect(() => {
    if (items.length) {
      window.print()
    }
  }, [items])

  return (
    <Table>
      <thead>
        <tr>
          <Th>No</Th>
          <Th>Name</Th>
          <Th>Date</Th>
          <Th>Cash In</Th>
          <Th>Cash Out</Th>
          <Th>Status</Th>
        </tr>
      </thead>
      <tbody>
        {items.map((ctr, index) => (
          <tr key={index}>
            <Td>{index + 1}</Td>
            <Td>{ctr.name}</Td>
            <Td>
              <DateTime date={ctr.date || ''} />
            </Td>
            <Td>{formatCurrency(ctr.cashIn || 0)}</Td>
            <Td>{formatCurrency(ctr.cashOut || 0)}</Td>
            <Td>{ctr.status || '-'}</Td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
})
