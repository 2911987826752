import { ReactNode } from 'react'

export type HintProps = {
  children: ReactNode
  face?: HintFace
}

export enum HintFace {
  DEFAULT = 'default',
  ERROR = 'error'
}
