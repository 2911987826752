import React, { FC, memo, useCallback, useEffect, useState } from 'react'

import { BeforeFormChangeHandler, useFormData } from 'brief-form'
import { MFA_CODE_LENGTH } from 'commons/constants/common'
import { Dialog, DialogFace } from 'ui/components/Dialog'
import { updateFormAfterApiError } from 'ui/components/Form'

import { CreateDeviceForm, CreateDeviceFormValue } from './CreateDeviceForm'
import { Footer } from './Footer'

export interface CreateDeviceDialogProps {
  onClose: () => void
  onCreateDevice: (value: { type: string; name: string }) => Promise<any>
  onResetChallenge: () => void
  onRetryChallenge: () => void
  onSendCode: (code: string) => Promise<any>
  open: boolean
  challengeStartedAt: number
  totpUrl?: string
}

const INITIAL_FORM_VALUE = {
  type: 'sms',
  name: '',
  code: ''
}

export const CreateDeviceDialog: FC<CreateDeviceDialogProps> = memo((props) => {
  const { onClose, onCreateDevice, onResetChallenge, onRetryChallenge, onSendCode, open, challengeStartedAt, totpUrl } =
    props

  const [isSubmitting, setIsSubmitting] = useState(false)

  const onBeforeChange: BeforeFormChangeHandler<CreateDeviceFormValue> = useCallback(
    ({ value, errors, oldValue }) => {
      const isTypeChanged = value.type !== oldValue.type
      const isNameChanged = value.name !== oldValue.name

      // If we change device name or type, reset challenge, it's not actual anymore.
      if (challengeStartedAt && (value.name !== oldValue.name || value.type !== oldValue.type)) {
        onResetChallenge()
      }

      return {
        value: {
          ...value,
          name: isTypeChanged ? '' : value.name,
          code: isTypeChanged || isNameChanged ? '' : value.code
        },
        errors: isTypeChanged ? {} : errors
      }
    },
    [challengeStartedAt, onResetChallenge]
  )

  const form = useFormData<CreateDeviceFormValue>({ onBeforeChange, initialValue: INITIAL_FORM_VALUE })
  const { config, validate, set } = form
  const { value, errors, onChange } = config

  const onCloseWrapper = useCallback(() => {
    set({ reset: true })
    onClose()
  }, [onClose, set])

  const sendCode = useCallback(
    (code: string) => {
      onSendCode(code)
        .then(() => {
          onCloseWrapper()
        })
        .catch((error) => {
          onChange(value, { ...errors, code: error.message })
        })
    },
    [onChange, onSendCode, onCloseWrapper, value, errors]
  )

  useEffect(() => {
    if (value.code.length === MFA_CODE_LENGTH) {
      sendCode(value.code)
    }
  }, [value])

  const onSubmitWrapper = useCallback(() => {
    if (validate({ updateFields: true }).valid) {
      setIsSubmitting(true)
      onCreateDevice(value).catch((error) => {
        updateFormAfterApiError(value, errors, error, onChange)
        setIsSubmitting(false)
      })
    }
  }, [value, errors, onCreateDevice, onChange, validate])

  if (!open) {
    return null
  }

  return (
    <Dialog
      id="create-device-dialog"
      title="Add New MFA Device"
      face={DialogFace.PRIMARY}
      onClose={onCloseWrapper}
      stickyFooter={
        challengeStartedAt ? undefined : (
          <Footer onCancel={onClose} onSubmit={onSubmitWrapper} isSubmitting={isSubmitting} />
        )
      }
    >
      <CreateDeviceForm
        form={form}
        onRetryChallenge={onRetryChallenge}
        challengeStartedAt={challengeStartedAt}
        totpUrl={totpUrl}
      />
    </Dialog>
  )
})
