const isBetween = (v: string, from: number, to: number) => {
  if (!v) {
    return undefined
  }
  if (to < from) {
    throw new Error(`'from' should be lower than 'to', 'from': ${from} and 'to': ${to} are given`)
  }

  const numberValue = parseFloat(v)
  if (numberValue < from || numberValue > to) {
    return `Value should be between ${from} and ${to}`
  }

  return undefined
}

export const number = {
  between: (from: number, to: number) => (v: string) => isBetween(v, from, to)
}
