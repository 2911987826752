import { Link } from '@tanstack/react-router'
import styled from 'styled-components'
import { CSS } from 'ui/helpers'

export const DetailsLink = styled(Link)`
  color: ${(p) => p.theme.uiKit.colors.global.general.secondary};
  text-decoration: underline !important;
  &:visited {
    color: ${(p) => p.theme.uiKit.colors.global.general.secondary};
    text-decoration: underline !important;
  }
  &:hover,
  &:active {
    color: #00c698 !important;
    cursor: pointer;
    transition: color 0.3s;
  }
`

export const HighlightStatus = styled.span`
  color: ${(p) => p.theme.uiKit.colors.theme.negative.main};
  ${(p) => CSS.populateFont(p.theme.uiKit.typography.bodyM.semiBold)};
`
