import styled from 'styled-components'

export const Item = styled.div<{ $active: boolean }>`
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: ${(p) => (p.$active ? '#000' : '#b8b8b8')};
  font-weight: bold;
`

export const TimeLineBlock = styled.div`
  display: none;
  background: #fff;
  box-shadow: 0 7px 21px rgba(0, 0, 0, 0.110031);
  border-radius: 8.4px;
  padding: 30px 30px;
  position: absolute;
  bottom: 57px;
  left: 50%;
  transform: translateX(-50%);
  justify-content: space-around;
  align-items: center;

  i {
    width: 77px;
    height: 14px;
    margin: 0 10px;
  }

  svg {
    fill: none;
    width: 77px;
    height: 14px;
  }
`

export const WatchWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`

export const Trigger = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  color: #b8b8b8;
  padding: 6px 0 6px 35px;
  cursor: pointer;

  &:hover ${TimeLineBlock} {
    display: flex;
  }

  &:hover ${WatchWrapper} {
    svg {
      fill: #76e0c8;
    }
  }
`
