import { createRoute, redirect } from '@tanstack/react-router'
import { REPORTS_PATH } from 'commons/constants/routes'

import { BankReportsWrapper } from './BankReportsWrapper'
import { Overview, documentsReportRoute, emailHistoryReportRoute } from './routes'
import { alertsReportRoute } from './routes/AlertsReport'
import { applicationPipelineReportRoute } from './routes/ApplicationPipelineReport'
import { clientPortalUsersActivityReportRoute } from './routes/ClientPortalUsersActivityReport'
import { relationshipsReportRoute } from './routes/RelationshipsReport'
import { stateReportRoute } from './routes/SICSAPIStatusReport'
import { siteAuditDocumentsReportRoute } from './routes/SiteAuditDocumentsReport'
import { userReportRoute } from './routes/UserReport'

export const routeReportsTree = (rootRoute: BPRootRoute) => {
  const reportsIndexRoute = createRoute({
    beforeLoad: async ({ context }) => {
      if (!context.accessibleRoutingFlags.reportRootAccess) {
        throw redirect({
          to: '/'
        })
      }
    },
    getParentRoute: () => rootRoute,
    path: REPORTS_PATH,
    component: BankReportsWrapper
  })

  const overviewRoute = createRoute({
    getParentRoute: () => reportsIndexRoute,
    path: '/',
    component: Overview
  })

  return reportsIndexRoute.addChildren([
    applicationPipelineReportRoute<typeof reportsIndexRoute>(reportsIndexRoute),
    clientPortalUsersActivityReportRoute<typeof reportsIndexRoute>(reportsIndexRoute),
    documentsReportRoute<typeof reportsIndexRoute>(reportsIndexRoute),
    relationshipsReportRoute<typeof reportsIndexRoute>(reportsIndexRoute),
    stateReportRoute<typeof reportsIndexRoute>(reportsIndexRoute),
    siteAuditDocumentsReportRoute<typeof reportsIndexRoute>(reportsIndexRoute),
    userReportRoute<typeof reportsIndexRoute>(reportsIndexRoute),
    emailHistoryReportRoute<typeof reportsIndexRoute>(reportsIndexRoute),
    alertsReportRoute<typeof reportsIndexRoute>(reportsIndexRoute),
    overviewRoute
  ])
}
