import React, { FC, memo } from 'react'

import { MultiSelectAutoSuggest } from 'ui/components/Select'

import { TagsSelectProps } from './TagsSelectProps'

export const TagsSelect: FC<TagsSelectProps> = memo((props) => {
  const { value, onChange, ...rest } = props

  return (
    <MultiSelectAutoSuggest
      value={value || []}
      onChange={onChange}
      valueTitle="Add tags"
      options={[
        {
          label: 'Tier 1',
          value: 'Tier 1'
        },
        {
          label: 'Tier 2',
          value: 'Tier 2'
        },
        {
          label: 'Tier 3',
          value: 'Tier 3'
        }
      ]}
      {...rest}
    />
  )
})
