import { useCallback, useMemo, useState } from 'react'

import { BeforeFormChangeHandler } from 'brief-form'
import { FilterAlertsWebPage } from 'commons/service/bank/alerts'
import { AlertsWebPage } from 'commons/types/DTO/bank/alerts'
import { useTableState } from 'ui/components'

import { FilterValue } from '../types'

export const useComponentsEssence = (
  onAlertFilterChange: (filter: FilterAlertsWebPage) => void,
  webPage: AlertsWebPage
) => {
  const [loading, setLoading] = useState(false)
  const { config } = webPage

  const initialFilter = useMemo(
    () => ({
      quarterlyAnalyticsOffset: config.quarterlyAnalyticsOffset,
      quarterlyAnalyticsLimit: config.quarterlyAnalyticsLimit,
      quarterlyAnalyticsCompanyID: config.quarterlyAnalyticsCompanyID
    }),
    []
  )

  const [tableFilter, setTableFilter] = useState(initialFilter)

  const onBeforeFilterChange: BeforeFormChangeHandler<FilterValue> = useCallback(
    ({ value, errors }) => {
      setLoading(true)

      const filter = {
        ...tableFilter,
        quarterlyAnalyticsOffset: undefined,
        quarterlyAnalyticsLimit: undefined,
        quarterlyAnalyticsCompanyID: value.company_id
      }

      setTableFilter(filter)

      onAlertFilterChange(filter)

      setLoading(false)

      return {
        value,
        errors
      }
    },
    [tableFilter, onAlertFilterChange]
  )

  const tableState = useTableState<FilterValue>({
    onBeforeFilterChange,
    filter: {
      company_id: config.quarterlyAnalyticsCompanyID
    }
  })

  const changePagination = useCallback(
    async (page: number, size: number) => {
      setLoading(true)

      tableState.onPageChange(page, size)

      const filter = { ...tableFilter, quarterlyAnalyticsOffset: (page - 1) * size, quarterlyAnalyticsLimit: size }

      setTableFilter(filter)

      await onAlertFilterChange(filter)

      setLoading(false)
    },
    [onAlertFilterChange, tableFilter, tableState]
  )

  const onClear = async () => {
    tableState.onFilterClear()
    tableState.filterForm.set({ reset: true })

    setTableFilter(initialFilter)

    setLoading(true)

    await onAlertFilterChange(initialFilter)

    setLoading(false)
  }

  return {
    loading,
    changePagination,
    onClear,
    tableState
  }
}
