import {
  useCreateDocumentPeriodFile as useFileCreate,
  useCreateDocumentFileIsolated as useFileCreateIsolated,
  useDeleteDocumentPeriodFile as useFileDelete,
  useDeleteDocumentFileIsolated as useFileDeleteIsolated
} from 'commons/hooks/client/documentFile'
import { handlerServerError } from 'errors'
import { Toast } from 'ui/components/Toast'

export const useDocumentFileCreateIsolated = ({
  onSuccess,
  onError
}: {
  onSuccess: () => void
  onError: () => void
}) => {
  const { routine: createDocumentFileIsolated, isLoading } = useFileCreateIsolated({
    onSuccess: async () => {
      onSuccess()
      Toast.successSmall('Your file was uploaded successfully.')
    },
    onError: (error) => {
      onError()
      handlerServerError(error)
    }
  })

  return {
    createDocumentFileIsolated,
    isLoading
  }
}

export const useDocumentPeriodFileCreate = ({ onSuccess, onError }: { onSuccess: () => void; onError: () => void }) => {
  const { routine: createDocumentPeriodFile, isLoading } = useFileCreate({
    onSuccess: async () => {
      onSuccess()
      Toast.successSmall('Your file was uploaded successfully.')
    },
    onError: (error) => {
      onError()
      handlerServerError(error)
    }
  })

  return {
    createDocumentPeriodFile,
    isLoading
  }
}

export const useDocumentFileDeleteIsolated = ({
  onSuccess,
  onError
}: {
  onSuccess: () => void
  onError: () => void
}) => {
  const { routine: deleteDocumentFileIsolated, isLoading } = useFileDeleteIsolated({
    onSuccess: async () => {
      onSuccess()
      Toast.successSmall('Document file was deleted.')
    },
    onError: (error) => {
      onError()
      handlerServerError(error)
    }
  })

  return {
    deleteDocumentFileIsolated,
    isLoading
  }
}

export const useDocumentPeriodFileDelete = ({ onSuccess, onError }: { onSuccess: () => void; onError: () => void }) => {
  const { routine: deleteDocumentPeriodFile, isLoading } = useFileDelete({
    onSuccess: async () => {
      onSuccess()
      Toast.successSmall('Document file was deleted.')
    },
    onError: (error) => {
      onError()
      handlerServerError(error)
    }
  })

  return {
    deleteDocumentPeriodFile,
    isLoading
  }
}
