import React from 'react'

import { CheckBox, FF } from 'ui/components'

import { useSARFormContext } from '../../../../../../../context'

export const L = () => {
  const { formApi } = useSARFormContext()
  return (
    <formApi.Field
      name="transactions_info.other_activities.l"
      children={(field) => (
        <FF
          field={field}
          input={(p) => <CheckBox {...p} checkBoxLabel="l. Suspected public/private corruption (domestic)" />}
          errorPosition="right"
        />
      )}
    />
  )
}
