import { OnboardingFormActions } from '../../../enums'
import { CopyCompanyPhysicalAddressToMailingAction } from '../../../types/actions'

export const copyCompanyPhysicalAddressToMailing = (
  companyIndex: number
): CopyCompanyPhysicalAddressToMailingAction => ({
  type: OnboardingFormActions.COPY_COMPANY_PHYSICAL_ADDRESS_TO_MAILING,
  payload: {
    companyIndex
  }
})
