import { FormPaths, OnboardingFormFieldNames } from '../../../../enums'
import { CompanyFormValues } from '../../../../types/state'
import { setNewLicenses } from '../setNewLicenses'

export const fromLicense = ({
  oldCompany,
  licenseIndex,
  formName,
  fieldName,
  errors
}: {
  oldCompany: CompanyFormValues
  licenseIndex: number
  formName: FormPaths
  fieldName: OnboardingFormFieldNames
  errors: string[]
}): CompanyFormValues => {
  const oldLicenses = oldCompany.licenses
  const formData = oldLicenses[licenseIndex][formName]?.formData

  const newLicense = {
    ...oldLicenses[licenseIndex],
    [formName]: {
      formData,
      formErrors: { ...formData?.formErrors, [`${fieldName}`]: errors.join('.') }
    }
  }
  const newLicenses = setNewLicenses({
    licenseIndex,
    newLicense,
    oldCompany
  })
  const newCompany = { ...oldCompany, licenses: newLicenses }
  return newCompany
}
