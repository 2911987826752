import SC from 'styled-components'
import { Link } from 'ui/components/Link'

export const InfoColumn = SC.div`
  flex: 1;
  margin-right: 30px;
`

export const CompanyLink = SC(Link)`
  a {
    font-size: 14px;
  }
`
