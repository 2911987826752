import * as VARS from 'ui/themes/variables'

import { ButtonFace } from '../ButtonFace'

/* `main color` means not hover, not active, not focused, etc */
export const getMainTextColor = (face: ButtonFace) => {
  switch (face) {
    case 'neutral':
      return 'var(--theme-colors-global-grey-grey900)'
    case 'negative':
      return 'var(--theme-colors-global-general-main)'
    case 'positive':
      return 'var(--theme-colors-global-general-main)'
    default:
      return VARS.COLOR_NOT_FOUND
  }
}
