import { OnboardingWebPageResponse } from 'commons/types/DTO/commons'

export const selectCompany = ({
  responseData,
  companyIndex
}: {
  responseData: Partial<OnboardingWebPageResponse>
  companyIndex: number
}) => responseData?.companies[companyIndex]

export const selectQuestionnaire = ({
  responseData,
  companyIndex
}: {
  responseData: Partial<OnboardingWebPageResponse>
  companyIndex: number
}) => selectCompany({ responseData, companyIndex })?.questionnaire

export const selectLicenses = ({
  responseData,
  companyIndex
}: {
  responseData: Partial<OnboardingWebPageResponse>
  companyIndex: number
}) => selectCompany({ responseData, companyIndex }).licenses

export const selectContacts = ({
  responseData,
  companyIndex
}: {
  responseData: Partial<OnboardingWebPageResponse>
  companyIndex: number
}) => selectCompany({ responseData, companyIndex }).contacts

export const selectATMs = ({
  responseData,
  companyIndex
}: {
  responseData: Partial<OnboardingWebPageResponse>
  companyIndex: number
}) => selectQuestionnaire({ responseData, companyIndex }).ATMs
