import React from 'react'

import { getRouteApi, useNavigate } from '@tanstack/react-router'

import { ContactMetadataManage } from './ContactMetadataManage'

export const ContactEditDialogContainer = () => {
  const navigate = useNavigate({ from: '/relationships/$companyId/contact-edit/$contactId' })
  const { contactId, companyId } = getRouteApi('/relationships/$companyId/contact-edit/$contactId').useParams()

  const onClose = () =>
    navigate({ to: '/relationships/$companyId', params: { companyId: companyId }, hash: 'contacts' })

  return <ContactMetadataManage companyId={Number(companyId)} contactId={Number(contactId)} onClose={onClose} />
}
