import { OnboardingFormActions } from '../../../enums'
import { AddContactAction, CopyContactAction, RemoveContactAction } from '../../../types/actions'
import { ContactCopyHandlerParams } from '../../../types/forms'

export const addContact = (companyIndex: number): AddContactAction => ({
  type: OnboardingFormActions.ADD_CONTACT,
  payload: {
    companyIndex
  }
})

export const removeContact = ({
  companyIndex,
  contactIndex
}: {
  contactIndex: number
  companyIndex: number
}): RemoveContactAction => ({
  type: OnboardingFormActions.REMOVE_CONTACT,
  payload: { contactIndex, companyIndex }
})

export const copyContact = (copyData: ContactCopyHandlerParams): CopyContactAction => ({
  type: OnboardingFormActions.COPY_CONTACT,
  payload: copyData
})
