import { useConfirmation } from 'ui/hooks'

import { useDeleteSAR } from '../../../../hooks/sar'
import { routeApi } from '../constants'

export const useDelete = (id: number, setNotDirty: () => void) => {
  const { routine: deleteSAR } = useDeleteSAR({
    onSuccess: () => {
      setNotDirty()
      return navigate({ to: '/bsa/sars' })
    }
  })
  const navigate = routeApi.useNavigate()

  const { open: startDeletion, Confirmation: DeleteConfirmation } = useConfirmation({
    message: `Are you sure you want to delete SAR?`,
    onConfirm: () => deleteSAR(id),
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  return {
    startDeletion,
    DeleteConfirmation
  }
}
