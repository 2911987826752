import { WriteHook, useMutationManager } from 'react-query-state-manager'

import { Request } from 'commons/utils/request'

type DocumentFile = {
  id: number
  name: string
  notes: string
}

const updateDocumentFile = (file: Partial<DocumentFile>) =>
  Request.put<DocumentFile>(Request.urls.bank.documentFile, file)

export const useUpdateDocumentFile: WriteHook<Partial<DocumentFile>, unknown> = (opts) =>
  useMutationManager<DocumentFile, unknown>(updateDocumentFile, opts)
