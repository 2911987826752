import React, { FC, memo, useCallback, useEffect, useState } from 'react'

import { useDebounce } from 'commons/hooks/common'

import { Icon, IconName, IconSize } from '../Icon'
import { InputIconPosition } from '../InputV2'
import { SearchProps } from './SearchProps'

import * as SC from './styles'

export const Search: FC<SearchProps> = memo((props) => {
  const { value, onSearch, placeholder, 'data-qa': dataQa = 'Search', ...rest } = props

  const [searchText, setSearchText] = useState(value ?? '')

  const debouncedOnSearch = useDebounce(() => {
    onSearch(searchText)
  })

  const onChange = useCallback(
    (value: string) => {
      setSearchText(value)
      debouncedOnSearch()
    },
    [debouncedOnSearch]
  )

  useEffect(() => {
    setSearchText(value)
  }, [value])

  return (
    <SC.Box {...rest} data-qa={`${dataQa} container`}>
      <SC.InputWrapper
        value={searchText}
        onChange={onChange}
        placeholder={placeholder}
        renderIcon={<Icon name={IconName.SEARCH} size={IconSize.S} />}
        iconPosition={InputIconPosition.LEFT_CLOSE}
        data-qa={dataQa}
      />
    </SC.Box>
  )
})
