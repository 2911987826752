import { LicenseAddressInformationFormData } from '../../../types/forms'
import { OnboardingFormState } from '../../../types/state'
import { getSelectedCompany } from './getSelectedCompany'
import { setNewCompanies } from './setNewCompanies'
import { setNewLicenses } from './setNewLicenses'

export const licenseAddressInformationToState = ({
  companyIndex,
  licenseIndex,
  currentState,
  formData
}: {
  companyIndex: number
  licenseIndex: number
  currentState: OnboardingFormState
  formData: LicenseAddressInformationFormData
}) => {
  const oldCompany = getSelectedCompany({ companyIndex, currentState })

  // show changes contacts
  const newLicenses = setNewLicenses({
    licenseIndex,
    newLicense: { ...oldCompany.licenses[licenseIndex], addressInformation: { ...formData } },
    oldCompany
  })

  // change ref in list
  const newCompaniesFormValues = setNewCompanies({
    companyIndex,
    oldCompanies: currentState.formValue.companiesFormValues,
    newCompany: { ...oldCompany, licenses: newLicenses }
  })

  return {
    formValue: {
      ...currentState.formValue,
      companiesFormValues: newCompaniesFormValues
    }
  }
}
