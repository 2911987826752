import { AnyRoute, createRoute, lazyRouteComponent } from '@tanstack/react-router'
import { NotFoundError } from 'commons/errors'
import { getDocumentPeriodEditModePage } from '~bank-documents/service/periodEdit'

export const editPeriodRoute = <T extends AnyRoute>(indexRoute: T, isDocumentDetailsPage: boolean) =>
  createRoute({
    getParentRoute: () => indexRoute,
    component: lazyRouteComponent(() => import('./EditPeriod')),
    path: isDocumentDetailsPage ? '/edit-period/$periodId' : 'edit',
    beforeLoad: (opts) => ({ ...opts, isDocumentDetailsPage }),
    loader: async ({ params }) => {
      if ('periodId' in params) return await getDocumentPeriodEditModePage({ id: Number(params?.periodId) })
      else throw new NotFoundError()
    },
    // this function checks if it's not first route appearance
    // (so mutating history via LeaveConfirmation for example)
    // we shouldn`t reload route (including loader-fn)
    shouldReload: (p) => p.cause === 'enter'
  })
