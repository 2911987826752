import React from 'react'

import { DatePicker, FF } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

export const LEContactDate = () => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name="institution_contact_info.le_contact_date"
      children={(field) => <FF label="92. LE contact date" field={field} input={(p) => <DatePicker {...p} />} />}
    />
  )
}
