import styled from 'styled-components'
import { Panel } from 'ui/components'

const Inner = styled.div`
  padding: 30px 35px;
`

const Item = styled(Panel)`
  margin-bottom: 30px;
`

export const s = { Inner, Item }
