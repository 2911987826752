export type ApplicationPipelineReportItem = {
  applicationID: number
  applicationNotes: string
  companyName: string
  contactName: string
  createdBy: string
  currentOwner: string
  email: string
  lastModified: Date
  phone: string
  status: string
}
