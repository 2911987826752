import { HTMLAttributes, MouseEventHandler } from 'react'

export interface LayerProps extends HTMLAttributes<HTMLDivElement> {
  className?: string
  children?: any
  rounded?: boolean
  shadowed?: boolean
  stretch?: boolean
  onClick?: MouseEventHandler
}
