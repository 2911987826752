import styled from 'styled-components'
import { CSS } from 'ui/helpers'

import { IconV2 } from '../../../Icon'

export const BarContainer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  background: #f5f5f5;
  z-index: 12;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  transition: width 0.2s ease-in;
  width: ${({ minimized }: { minimized: boolean }) => (minimized ? `72` : `224`)}px;
  height: 100vh;
`

export const MenuToggleIcon = styled(IconV2)`
  cursor: pointer;
  color: ${(p) => p.theme.uiKit.colors.global.general.secondary};
  &:hover {
    color: ${(p) => p.theme.uiKit.colors.theme.brand.primary};
  }
  transition: ${CSS.populateTransition(['color'], '0.15s')};
`

export const BarHeaderContainer = styled.div`
  height: 56px;
  display: flex;
  gap: 16px;
  flex-direction: row;
  align-items: center;
  padding-left: 24px;
  overflow: hidden;
`

export const BarContent = styled.div`
  margin-top: 1px;
  overflow-x: hidden;
  flex: 1;
`

export const HelpLabel = styled.span<{ $minimized: boolean }>`
  transition: ${CSS.populateTransition(['opacity'], '0.3s')};
  white-space: nowrap;
  opacity: ${({ $minimized }) => ($minimized ? `0` : `1`)};
  ${(p) => CSS.populateFont(p.theme.uiKit.typography.bodyL.regular)};
`

export const ButtonsIcon = styled(IconV2)`
  cursor: pointer;
  color: ${(p) => p.theme.uiKit.colors.global.grey.grey700};
  transition: ${CSS.populateTransition(['color'], '0.15s')};
  &:hover {
    color: ${(p) => p.theme.uiKit.colors.theme.brand.primary};
  }
`

export const HelpButtonContent = styled.span`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 12px;

  color: ${(p) => p.theme.uiKit.colors.global.general.secondary};

  transition: ${CSS.populateTransition(['color'], '0.15s')};

  &:hover {
    color: ${(p) => p.theme.uiKit.colors.theme.positive.main};

    ${ButtonsIcon} {
      color: ${(p) => p.theme.uiKit.colors.theme.brand.primary};
    }
  }
`

export const HelpButton = styled.div`
  height: 65px;
  padding: 21px 20px 21px 24px;
  overflow: hidden;
  display: flex;
  align-items: center;
`

export const NextCloudButton = styled.div<{ $minimized: boolean }>`
  height: 65px;
  display: flex;
  align-items: center;
  flex: 1 1;
  background: none;
  border-left: 1px solid ${(p) => p.theme.uiKit.colors.global.grey.grey300};

  color: ${(p) => p.theme.uiKit.colors.global.grey.grey700};

  &:hover {
    background: none;
    color: ${(p) => p.theme.uiKit.colors.theme.brand.primary};
  }

  ${ButtonsIcon} {
    margin: 0 auto;
  }

  transition: ${CSS.populateTransition(['opacity'], '0.3s')};
  opacity: ${({ $minimized }) => ($minimized ? `0` : `1`)};
`

export const Footer = styled.div`
  border-top: 1px solid ${(p) => p.theme.uiKit.colors.global.grey.grey300};
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
`
