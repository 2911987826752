import React, { FC, memo } from 'react'

import { useParams } from '@tanstack/react-router'
import { Form } from 'brief-form'
import {
  FieldSet,
  IconSize,
  Page,
  PageFace,
  PageSection,
  PageSectionFace,
  Panel,
  Spinner,
  SpinnerSize,
  TextMask
} from 'ui/components'
import { Button } from 'ui/components/Buttons'
import { CurrencyInput, TextareaInput } from 'ui/components/InputV2'
import { Footer } from 'ui/temp/Footer'
import { Col, Col2x, FormRow } from 'ui/themes/globalStyles'

import { useMRWebPageDepositsManage } from './useMRWebPageDepositsManage'

import * as SC from './styles'

const MRWebPageDepositsManage: FC = memo(() => {
  const { id: MRId, bankAccountId } = useParams({ from: '/mr/$id/$bankAccountId/deposits-edit' })

  const { webPageData, bankAccount, config, Field, onCancel, onSubmit, isSubmitting } = useMRWebPageDepositsManage(
    +MRId,
    +bankAccountId
  )

  if (!bankAccount) {
    return <Spinner centered size={SpinnerSize.M} />
  }

  return (
    <Page
      face={PageFace.SECONDARY}
      title={webPageData.titleOverline}
      subTitle={webPageData.title}
      footer={
        <Footer>
          <Button face="neutral" onClick={onCancel}>
            Cancel
          </Button>
          <Button loading={isSubmitting} onClick={onSubmit} face="positive">
            Save
          </Button>
        </Footer>
      }
    >
      <PageSection
        face={PageSectionFace.THIRD}
        title={
          <span style={{ display: 'flex', columnGap: '10px' }}>
            <span>Bank Account #{bankAccountId + 1}</span>
            <TextMask
              isBankAccount
              text={bankAccount.bankAccountNumber || '---'}
              link={`/bank-accounts/${bankAccount.bankAccountID}`}
              iconSize={IconSize.XS}
            />
          </span>
        }
      >
        <Panel rounded shadowed collapsible={false} title="Edit Deposits">
          <SC.Box>
            <Form config={config}>
              <FieldSet legend="Deposits From Sales">
                <FormRow>
                  <Col>
                    <Field
                      name="cashDeposits"
                      input={CurrencyInput}
                      label="Deposits"
                      inputProps={{
                        'data-qa': 'cashDeposits'
                      }}
                    />
                  </Col>
                  <Col>
                    <Field
                      name="invoices"
                      input={CurrencyInput}
                      label="Cash - invoices"
                      inputProps={{
                        'data-qa': 'invoices'
                      }}
                    />
                  </Col>
                </FormRow>
                <FormRow>
                  <Col>
                    <Field
                      name="atm"
                      input={CurrencyInput}
                      label="Cash - ATM Load"
                      inputProps={{
                        'data-qa': 'atm'
                      }}
                    />
                  </Col>
                  <Col>
                    <Field
                      name="internalTransfers"
                      input={CurrencyInput}
                      label="Cash - Internal Transfers"
                      inputProps={{
                        'data-qa': 'internalTransfers'
                      }}
                    />
                  </Col>
                </FormRow>
                <FormRow>
                  <Col>
                    <Field
                      name="creditDebit"
                      input={CurrencyInput}
                      label="Credit/Debit Cards"
                      inputProps={{
                        'data-qa': 'creditDebit'
                      }}
                    />
                  </Col>
                  <Col>
                    <Field
                      name="checks"
                      input={CurrencyInput}
                      label="Checks"
                      inputProps={{
                        'data-qa': 'checks'
                      }}
                    />
                  </Col>
                </FormRow>
                <FormRow>
                  <Col>
                    <Field
                      name="other"
                      input={CurrencyInput}
                      label="Other"
                      inputProps={{
                        'data-qa': 'other'
                      }}
                    />
                  </Col>
                  <Col></Col>
                </FormRow>
              </FieldSet>

              {bankAccount.enableOtherActivity && (
                <FieldSet legend="Other Banking Activity">
                  <FormRow>
                    <Col>
                      <Field
                        name="oaCashWithdrawals"
                        input={CurrencyInput}
                        label="Cash Withdrawals"
                        inputProps={{
                          'data-qa': 'oaCashWithdrawals'
                        }}
                      />
                    </Col>
                    <Col></Col>
                  </FormRow>
                  <FormRow>
                    <Col>
                      <Field
                        name="oaChecksDebits"
                        input={CurrencyInput}
                        label="Check Debits"
                        inputProps={{
                          'data-qa': 'oaChecksDebits'
                        }}
                      />
                    </Col>
                    <Col>
                      <Field
                        name="oaAchDebits"
                        input={CurrencyInput}
                        label="ACH Debits"
                        inputProps={{
                          'data-qa': 'oaAchDebits'
                        }}
                      />
                    </Col>
                  </FormRow>
                  <FormRow>
                    <Col>
                      <Field
                        name="oaDebitCardsCredits"
                        input={CurrencyInput}
                        label="Debit Cards - Credit"
                        inputProps={{
                          'data-qa': 'oaDebitCardsCredits'
                        }}
                      />
                    </Col>
                    <Col>
                      <Field
                        name="oaDebitCardsDebits"
                        input={CurrencyInput}
                        label="Debit Cards - Debits"
                        inputProps={{
                          'data-qa': 'oaDebitCardsDebits'
                        }}
                      />
                    </Col>
                  </FormRow>
                  <FormRow>
                    <Col>
                      <Field
                        name="oaTransferCredits"
                        input={CurrencyInput}
                        label="Transfer - Credits"
                        inputProps={{
                          'data-qa': 'oaTransferCredits'
                        }}
                      />
                    </Col>
                    <Col>
                      <Field
                        name="oaTransferDebits"
                        input={CurrencyInput}
                        label="Transfer - Debits"
                        inputProps={{
                          'data-qa': 'oaTransferDebits'
                        }}
                      />
                    </Col>
                  </FormRow>
                  <FormRow>
                    <Col>
                      <Field
                        name="oaWiresIncoming"
                        input={CurrencyInput}
                        label="Wires - Incoming"
                        inputProps={{
                          'data-qa': 'oaWiresIncoming'
                        }}
                      />
                    </Col>
                    <Col>
                      <Field
                        name="oaWiresOutgoing"
                        input={CurrencyInput}
                        label="Wires - Outgoing"
                        inputProps={{
                          'data-qa': 'oaWiresOutgoing'
                        }}
                      />
                    </Col>
                  </FormRow>
                  <FormRow>
                    <Col>
                      <Field
                        name="oaSweepsIncoming"
                        input={CurrencyInput}
                        label="Sweeps - Incoming"
                        inputProps={{
                          'data-qa': 'oaSweepsIncoming'
                        }}
                      />
                    </Col>
                    <Col>
                      <Field
                        name="oaSweepsOutgoing"
                        input={CurrencyInput}
                        label="Sweeps - Outgoing"
                        inputProps={{
                          'data-qa': 'oaSweepsOutgoing'
                        }}
                      />
                    </Col>
                  </FormRow>
                  <FormRow>
                    <Col>
                      <Field
                        name="oaBankFees"
                        input={CurrencyInput}
                        label="Bank Fees"
                        inputProps={{
                          'data-qa': 'oaBankFees'
                        }}
                      />
                    </Col>
                    <Col></Col>
                  </FormRow>
                  <FormRow>
                    <Col>
                      <Field
                        name="oaMoneyOrdersPurchased"
                        input={CurrencyInput}
                        label="Money Orders Purchased"
                        inputProps={{
                          'data-qa': 'oaMoneyOrdersPurchased'
                        }}
                      />
                    </Col>
                    <Col>
                      <Field
                        name="oaCashiersChecksPurchased"
                        input={CurrencyInput}
                        label="Cashiers Checks Purchased"
                        inputProps={{
                          'data-qa': 'oaCashiersChecksPurchased'
                        }}
                      />
                    </Col>
                  </FormRow>
                  <FormRow>
                    <Col>
                      <Field
                        name="oaCreditCardCharges"
                        input={CurrencyInput}
                        label="CB&T Credit Card - Charges"
                        inputProps={{
                          'data-qa': 'oaCreditCardCharges'
                        }}
                      />
                    </Col>
                    <Col>
                      <Field
                        name="oaCreditCardPayments"
                        input={CurrencyInput}
                        label="CB&T Credit Card - Payments"
                        inputProps={{
                          'data-qa': 'oaCreditCardPayments'
                        }}
                      />
                    </Col>
                  </FormRow>
                </FieldSet>
              )}

              <FieldSet>
                <FormRow>
                  <Col2x>
                    <Field
                      name="notes"
                      input={TextareaInput}
                      label="Notes"
                      inputProps={{
                        'data-qa': 'notes'
                      }}
                    />
                  </Col2x>
                </FormRow>
              </FieldSet>
            </Form>
          </SC.Box>
        </Panel>
      </PageSection>
    </Page>
  )
})

export default MRWebPageDepositsManage
