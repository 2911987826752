import styled from 'styled-components'

export const CheckBoxes = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-row-gap: 10px;
  min-height: 110px;
`

export const SubTitle = styled.div`
  margin-bottom: 10px;
`

export const CheckBoxItem = styled.div``
