import styled from 'styled-components'
import { Icon } from 'ui/components/Icon'

const Box = styled.div`
  background: ${(p) => p.theme.uiKit.colors.theme.negative.transparent.opacity12};
  padding: 12px 12px 20px 12px;
  border-radius: 8px;
`

const WarningIcon = styled(Icon)`
  margin-right: 10px;
`

const Title = styled.span<{ weight: number }>`
  color: ${(p) => p.theme.uiKit.colors.theme.negative.main};
  display: flex;
  align-items: center;
  font-size: 13px;
  line-height: 16px;
  font-weight: ${(p) => p.weight};
`

const List = styled.ol`
  font-size: 14px;
  line-height: 22px;
  margin: 14px 0 0 0;
  padding: 0 0 0 22px;
  color: ${(p) => p.theme.uiKit.colors.theme.negative.main};
`

export const s = { Box, WarningIcon, Title, List }
