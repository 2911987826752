// used: apps/client-portal/src/application/Application/router.tsx
import { AnyRoute, createRoute, lazyRouteComponent } from '@tanstack/react-router'
import { PortalName } from 'commons/types/enums'

import { onboardingFormLoader } from './onboardingFormLoader'

export const routeClientApplicationEdit = <T extends AnyRoute>(indexRoute: T) =>
  createRoute({
    getParentRoute: () => indexRoute,
    path: `$applicationId`,
    parseParams: (rawParams): Record<'applicationId', number> => ({ applicationId: Number(rawParams?.applicationId) }),
    loader: async ({ params }) => await onboardingFormLoader({ portalName: PortalName.CP, params }),
    component: lazyRouteComponent(() => import('./ClientOnboardingForm'))
  })
