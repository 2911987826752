import styled from 'styled-components'
import { DownloadLink } from 'ui/components/DownloadLink'

export const Documents = styled.div`
  margin: 40px 0 0 0;
`

export const Header = styled.div`
  background-color: #e2e2e2;
  margin: 0 -50px 30px -50px;
  padding: 12px 50px;
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Company = styled.div`
  margin-bottom: 30px;
`

export const CompanyName = styled.b`
  display: block;
  margin-bottom: 10px;
`

export const DownloadLinkBtn = styled(DownloadLink)`
  margin-top: 20px;
`
