import { addDays, format } from 'date-fns'
import { formatDateTime } from 'ui/components/DateTime'

export const getOptionsFilters = (filter: any) => {
  const formattedDate = formatDateTime(filter?.date)
  const split = formattedDate?.split('/')

  const dayStart = split?.length ? new Date(parseInt(split[2]), parseInt(split[0]) - 1, parseInt(split[1])) : null

  return [
    filter?.id ? { field: 'id', type: 'eq', value: filter.id } : (null as any),
    filter?.tracking_id ? { field: 'tracking_id', type: 'like', value: `%${filter.tracking_id}%` } : (null as any),
    dayStart ? { field: 'start_date', type: 'gte', value: format(dayStart, 'yyyy-MM-dd') } : (null as any),
    dayStart ? { field: 'start_date', type: 'lt', value: format(addDays(dayStart, 1), 'yyyy-MM-dd') } : (null as any),
    filter?.status ? { field: 'status', type: 'eq', value: filter.status } : (null as any),
    { field: 'type', type: 'eq', value: 'ctr' }
  ].filter(Boolean)
}
