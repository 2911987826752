const FILE_DIALOG_OPENED = 'dialog__fileDialogOpened'

export class DialogUtils {
  private static timeoutId: NodeJS.Timeout | number | null = null

  private static getHTMLTag(): HTMLElement {
    const html = document.getElementsByTagName('html')[0]
    if (html) {
      return html
    } else {
      throw new Error('Where is HTML tag?')
    }
  }

  public static isFileDialogOpened(): boolean {
    return this.getHTMLTag().classList.contains(FILE_DIALOG_OPENED)
  }

  public static toggleFileDialogOpened() {
    const html = this.getHTMLTag()
    if (html.classList.contains(FILE_DIALOG_OPENED)) {
      if (this.timeoutId === null) {
        this.timeoutId = setTimeout(() => {
          html.classList.remove(FILE_DIALOG_OPENED)
          this.timeoutId = null
        }, 300)
      }
    } else {
      html.classList.add(FILE_DIALOG_OPENED)
    }
  }
}
