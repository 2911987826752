import { useCallback } from 'react'

import { handlerServerError } from 'errors'
import { WriteHook, useMutationManager } from 'state-manager'
import { Toast } from 'ui/components/Toast'

import { deleteFile } from './DeleteFileRequest'

export const useFileRequest: WriteHook<number, unknown> = (id) => useMutationManager<number, unknown>(deleteFile, id)

export const useDeleteFile = (mrId: number, invalidateMR: () => Promise<void>) => {
  const { routine, isLoading } = useFileRequest({
    onSuccess: async () => {
      Toast.successSmall('File was deleted.')
      if (mrId) invalidateMR()
    },
    onError: (error) => handlerServerError(error)
  })

  const deleteFile = useCallback((id: number) => routine(id), [routine])

  return {
    deleteFile,
    isLoading
  }
}
