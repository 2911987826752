import React, { FC, memo } from 'react'

import { UsPhoneLabel } from 'ui/components'
import { Button } from 'ui/components/Buttons'
import { DateTime } from 'ui/components/DateTime'
import { Hr } from 'ui/components/Hr'
import { Info, InfoSet, InfoSetDirection } from 'ui/components/Info'
import { Link } from 'ui/components/Link'
import { PageSection, PageSectionFace } from 'ui/components/Page'
import { DotLabelBadge } from 'ui/temp/DotLabelBadge'

import { LicenseDetailsProps } from './LicenseDetailsProps'

import * as SC from './styles'

export const LicenseDetails: FC<LicenseDetailsProps> = memo((props) => {
  const {
    id,
    active,
    name,
    relationship,
    number,
    issueDate,
    type,
    subType,
    posType,
    phone,
    taxRates,
    address,
    createdAt,
    updatedAt,
    apiConnection
  } = props

  const { cityTax, mJRetailTax, specialTax, stateTax, countyTax } = taxRates
  const { street, postalCode, state, city } = address

  return (
    <PageSection
      face={PageSectionFace.SECONDARY}
      title="License Information"
      actions={
        <Link to="/licenses/$id/edit" params={{ id }}>
          <Button face="positive">Edit</Button>
        </Link>
      }
      anchor="general"
    >
      <Hr />
      <InfoSet direction={InfoSetDirection.ROW} legend="License Details">
        <SC.RelationshipDetailColumn>
          <Info label="Name">{name}</Info>
          <Info label="Relationship">
            <SC.RelationshipDetailLink to="/relationships/$companyId" params={{ companyId: relationship.id }}>
              {relationship.name}
            </SC.RelationshipDetailLink>
          </Info>
          <Info label="License Number">{number}</Info>
          <Info label="Issue Date">
            <DateTime date={issueDate} />
          </Info>
          <Info label="API Connection">
            <DotLabelBadge label={apiConnection} dotActive={apiConnection === 'Active'} />
          </Info>
        </SC.RelationshipDetailColumn>
        <SC.RelationshipDetailColumn>
          <Info label="Active">
            <DotLabelBadge label="" dotActive={active} />
          </Info>
          <Info label="Type">{type}</Info>
          <Info label="Subtype">{subType}</Info>
          <Info label="POS Type">{posType}</Info>
          <Info label="Phone">
            <UsPhoneLabel value={phone || ''} />
          </Info>
        </SC.RelationshipDetailColumn>
      </InfoSet>
      <Hr />
      <InfoSet direction={InfoSetDirection.ROW} legend="Tax rates for License">
        <SC.RelationshipDetailColumn>
          <Info label="City Tax">{`${cityTax || '---'} %`}</Info>
          <Info label="MJ Retail Tax">{`${mJRetailTax || '---'} %`}</Info>
          <Info label="Special Tax">{`${specialTax || '---'} %`}</Info>
        </SC.RelationshipDetailColumn>
        <SC.RelationshipDetailColumn>
          <Info label="County Tax">{`${countyTax || '---'} %`}</Info>
          <Info label="State Tax">{`${stateTax || '---'} %`}</Info>
        </SC.RelationshipDetailColumn>
      </InfoSet>
      <Hr />
      <InfoSet direction={InfoSetDirection.ROW} legend="Address Information">
        <SC.RelationshipDetailColumn>
          <Info label="Address Information">{street}</Info>
          <Info label="Zip Code">{postalCode}</Info>
        </SC.RelationshipDetailColumn>
        <SC.RelationshipDetailColumn>
          <Info label="State">{state}</Info>
          <Info label="City">{city}</Info>
        </SC.RelationshipDetailColumn>
      </InfoSet>
      <Hr />
      <InfoSet direction={InfoSetDirection.ROW} legend="System Information">
        <SC.RelationshipDetailColumn>
          <Info label="Created At">
            <DateTime date={createdAt} withTime />
          </Info>
        </SC.RelationshipDetailColumn>
        <SC.RelationshipDetailColumn>
          <Info label="Last Modified At">
            <DateTime date={updatedAt} withTime />
          </Info>
        </SC.RelationshipDetailColumn>
      </InfoSet>
    </PageSection>
  )
})
