import styled from 'styled-components'

export const LabelValue = styled.div`
  display: flex;
  margin-bottom: 30px;
  color: #000;
  font-weight: normal;
`
export const Value = styled.div`
  margin-left: 35px;
`
