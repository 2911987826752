import { OnboardingFormState } from '../../../types/state'

export const getStateAfterCompanyRemoved = ({
  currentState,
  companyIndex
}: {
  currentState: OnboardingFormState
  companyIndex: number
}) => {
  const newCompaniesFormValues = currentState.formValue.companiesFormValues.filter((_, index) => index !== companyIndex)
  return {
    companiesDiff: newCompaniesFormValues,
    formValue: {
      ...currentState.formValue,
      companiesFormValues: newCompaniesFormValues
    }
  }
}
