import React, { FC, memo, useCallback, useState } from 'react'

import { emptyMetadata } from 'bank-contacts/src/hooks/useContactMetadataItem'
import { useFormData } from 'brief-form'
import { Dialog, DialogFace, DialogFooterActions } from 'ui/components'
import { LeaveConfirmation } from 'ui/components/LeaveConfirmation'
import { Spinner, SpinnerSize } from 'ui/components/Spinner'

import { ContactMetadataManageDialogProps } from './ContactMetadataManageDialogProps'
import { ContactMetadataFormValue, ContactMetadataManageForm } from './components'

export const ContactMetadataManageDialog: FC<ContactMetadataManageDialogProps> = memo((props) => {
  const { isOpen, onClose, onSubmit, metadata, contact, companyId } = props
  const initialValue = metadata ? metadata : { ...emptyMetadata, permissions: [] }

  const { config, Field, validate, isDirty, set } = useFormData<ContactMetadataFormValue>({
    initialValue,
    alwaysSyncWithInitialValueAndErrors: true
  })

  const [isSubmitting, setIsSubmitting] = useState(false)

  const close = useCallback(() => {
    set({ value: metadata })
    onClose()
  }, [metadata, onClose, set])

  const submitWrapper = useCallback(async () => {
    if (validate({ updateFields: true }).valid) {
      try {
        setIsSubmitting(true)
        await onSubmit(config.value)
        onClose()
      } finally {
        setIsSubmitting(false)
      }
    }
  }, [onSubmit, config.value, onClose, validate])

  if (!isOpen) {
    return null
  }
  return (
    <LeaveConfirmation preventLeaving={!isSubmitting && isDirty} ignoreUrlHashChanging={false}>
      <Dialog
        id="contact-metadata"
        onClose={close}
        face={DialogFace.PRIMARY}
        title="Edit company related Contact Info"
        onSubmit={submitWrapper}
        submitDisabled={isSubmitting}
        footerActions={[DialogFooterActions.SAVE, DialogFooterActions.CANCEL]}
      >
        {metadata ? (
          <ContactMetadataManageForm config={config} Field={Field} contact={contact} companyId={companyId} />
        ) : (
          <Spinner centered size={SpinnerSize.M} />
        )}
      </Dialog>
    </LeaveConfirmation>
  )
})
