import styled from 'styled-components'
import { Button } from 'ui/components/Buttons'
import { Hr } from 'ui/components/Hr'
import { Link } from 'ui/components/Link'

export const WrappedHr = styled(Hr)`
  margin-bottom: 21px;
`

export const SaveButton = styled(Button)`
  align-self: flex-start;
`

export const WrappedLink = styled(Link)`
  a {
    font-size: 14px;
  }
`
