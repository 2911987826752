import React from 'react'

import { CheckBox, FF } from 'ui/components'

import { useSARFormContext } from '../../../../../../../../context'

type OwnerProps = {
  index: number
}

export const Owner = ({ index }: OwnerProps) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={`subjects_info[${index}].relationships_info[0].owner` as 'subjects_info[0].relationships_info[0].owner'}
      children={(field) => (
        <FF field={field} input={(p) => <CheckBox {...p} checkBoxLabel="l. Owner or Controlling Shareholder" />} />
      )}
    />
  )
}
