import React from 'react'

import { AlertsTooltip } from 'bank-documents/src/components/AlertsTooltip'
import { DOCUMENTS_PATH } from 'commons/constants/routes'
// import { DocumentItemAlerts } from 'commons/types/DTO/commons'
import { CheckMark } from 'ui/components/CheckMark'
import { IconName } from 'ui/components/Icon'
import { FrequencyLabel } from 'ui/components/Labels'
import { Link } from 'ui/components/Link'
import { MenuIconHoverFace, MenuItemBehavior, MenuItemTextFace, TableActions } from 'ui/components/QueryTable'
import { TableColumn } from 'ui/components/Table'

import { ContactDocuments } from '../../../../../../types'

type TableProps = {
  onDocumentDelete: (documentData: { id: number; name: string }) => void
  returnUrl: string
}

export const columns = ({ returnUrl, onDocumentDelete }: TableProps): TableColumn<ContactDocuments>[] => [
  {
    title: 'Document Name',
    sortable: true,
    align: 'left',
    key: 'name',
    render: (item) => <Link to={`${DOCUMENTS_PATH}/${item.id}`}>{item.name}</Link>
  },
  {
    title: 'Internal',
    sortable: true,
    key: 'internal',
    render: ({ internal }) => (internal ? <CheckMark value rounded /> : null)
  },
  {
    title: 'Frequency',
    sortable: true,
    key: 'frequency',
    align: 'left',
    render: ({ frequency }) => <FrequencyLabel name={frequency} />
  },
  {
    title: 'Expiration Date',
    sortable: true,
    align: 'left',
    key: 'expirationDate',
    render: ({ expirationDate }) => expirationDate
  },
  {
    align: 'left',
    title: 'Alerts',
    render: (item) => <AlertsTooltip alerts={item.alerts} id={item.id} expirationDate={item.expirationDate} />
  },
  {
    title: 'Actions',
    width: '50px',
    render: (item) => (
      <TableActions
        items={[
          {
            key: 1,
            title: 'Edit',
            iconName: IconName.EDIT,
            iconHoverFace: MenuIconHoverFace.PRIMARY,
            textFace: MenuItemTextFace.GREY,
            url: `${DOCUMENTS_PATH}/${item?.id}/edit?returnUrl=${returnUrl}`
          },
          {
            key: 2,
            title: 'Delete',
            behavior: MenuItemBehavior.BUTTON,
            iconName: IconName.DELETE,
            iconHoverFace: MenuIconHoverFace.DANGER,
            textFace: MenuItemTextFace.GREY,
            onClick: () => onDocumentDelete(item)
          }
        ]}
      />
    )
  }
]
