import React, { memo } from 'react'

import { Button, IconButton } from 'ui/components/Buttons'
import { IconName } from 'ui/components/Icon'

import * as SC from './styles'

interface FooterProps {
  hasChosenItems: boolean
  onDeleteSARs: () => void
  onXMLGenerate: () => void
}

export const Footer = memo((props: FooterProps) => {
  const { hasChosenItems, onDeleteSARs, onXMLGenerate } = props

  return (
    <SC.Box>
      <div>
        {hasChosenItems && (
          <IconButton face="negative" icon={IconName.DELETE} onClick={onDeleteSARs} data-qa="Delete">
            Remove SARs
          </IconButton>
        )}
      </div>
      {hasChosenItems && (
        <Button face="positive" onClick={onXMLGenerate}>
          Generate SAR Batch XML
        </Button>
      )}
    </SC.Box>
  )
})
