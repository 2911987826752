import styled from 'styled-components'

export const Button = styled.div`
  display: inline-block;
  width: 22px;
  height: 22px;
  padding: 3px;
  overflow: hidden;
  cursor: pointer;
`

export const IconWrapper = styled.div<{ opened: boolean }>`
  transform: rotate(${(p) => (p.opened ? '90' : '0')}deg);
  transition: transform 0.25s;
`
