export type DocumentReport = {
  account_signer: boolean
  active: boolean
  ancillary: boolean
  consumer_account: boolean
  corporation: boolean
  created_at: string
  deleted_at: string
  due_start_date: boolean
  expiration_delay_days: number
  frequency: string
  hemp: boolean
  id: number
  internal: boolean
  investment: boolean
  level: string
  license_dispensary: boolean
  license_grower: boolean
  license_medical: boolean
  license_processor: boolean
  license_recreational: boolean
  license_waste_disposal: boolean
  llc: boolean
  management: boolean
  minimal_ownership_percent: number
  mrb: boolean
  mrb_related: boolean
  name: string
  optional: boolean
  organization_id: number
  owner: boolean
  package: number
  partnership: boolean
  pre_license_mrb: boolean
  sole_proprietor: boolean
  start_date_type: string
  state: string
  tribal_mrb: boolean
  trust: boolean
  type: string
  updated_at: string
  license_delivery: boolean
  license_operator: boolean
}
