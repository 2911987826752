import SC from 'styled-components'
import { CheckBox } from 'ui/components/CheckBox'
import { Panel } from 'ui/components/Panel'
import { Col } from 'ui/themes/globalStyles'

export const Inner = SC.div`
  padding: 30px 35px;
`

export const TinColumn = SC(Col)`
  display: flex;
  width: 160px;
  align-items: center;
  justify-content: center;
  position: relative;
`

export const Item = SC(Panel)`
  margin-bottom: 30px;
`

export const CheckBoxWrapper = SC(CheckBox)`
  position: absolute;
  left: 100%;
  top: 34px;
  margin-left: 20px;
`

export const VisibilityBox = SC.span<{ $visible: boolean }>`
  display: ${(p) => (p.$visible ? 'inline' : 'none')}
`
