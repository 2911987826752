import { useCallback } from 'react'

import { FormChangedHandler, useFormData } from 'brief-form'
import { AccountDetails } from 'commons/types/DTO/commons'

import { FormPaths } from '../../enums'
import { useSyncFormData } from '../../hooks'
import { useValidateFormPersist } from '../../hooks/useValidateFormPersist'
import { AccountDetailsFormValue } from '../../types'
import { CompanyAccountDetailsChangeHandler, CompanyAccountDetailsFormData } from '../../types/forms'

type UseAccountDetailsHookProps = {
  companyIndex: number
  formData: CompanyAccountDetailsFormData
  onChange: CompanyAccountDetailsChangeHandler
}

export const useCompanyAccountDetailsForm = ({ companyIndex, formData, onChange }: UseAccountDetailsHookProps) => {
  const onFormChanged: FormChangedHandler<AccountDetailsFormValue> = useCallback(
    (formValues, formErrors) => onChange({ companyIndex, formData: { formValues, formErrors } }),
    [onChange, companyIndex]
  )

  const { config, Field, set, validate } = useFormData<AccountDetailsFormValue>({
    onFormChanged,
    initialValue: formData.formValues,
    initialErrors: formData.formErrors
  })
  // sync form values/errors if formData have changed
  useSyncFormData({ set, formData })

  const formKey = `${FormPaths.COMPANY_ACCOUNT_DETAILS}-${companyIndex}`
  useValidateFormPersist<AccountDetails>({
    formName: formKey,
    validate,
    config
  })
  return { Field, config }
}
