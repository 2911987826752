import React, { memo } from 'react'

import { FormInputProps } from 'brief-form'
import { Request } from 'commons/utils/request'
import { MultiSelectAutoSuggest } from 'ui/components/Select'

interface Props
  extends FormInputProps<
    string[],
    {
      holdingId?: number | null
      companyId?: number | null
      'data-qa'?: string
    }
  > {}

export const CompaniesBeingHeld = memo((props: Props) => {
  const { value, onChange, holdingId, companyId } = props

  return (
    <MultiSelectAutoSuggest
      value={value || []}
      data-qa="Add Company selector"
      onChange={onChange}
      valueTitle="Add Company"
      serverOptions={{
        url: Request.urls.bank.companyList,
        limit: 500,
        mapper: (item) => {
          const isDisabled = item.holding_id && item.holding_id !== companyId
          const title = isDisabled ? 'This company has been already added to another holding' : ''

          // exclude company itself and it parent
          if (item.id === holdingId || item.id === companyId) {
            // see in MultiSelectAutoSuggest: collection.map(serverOptions.mapper).filter(Boolean)
            return undefined
          }
          return {
            label: item.name,
            value: item.id,
            disabled: isDisabled,
            title: title || ''
          }
        },
        searchFields: ['name']
      }}
    />
  )
})
