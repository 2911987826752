import React, { FC, memo } from 'react'

import { YesNoRadioGroup } from 'commons/types/enums'
import { Info, InfoSet } from 'ui/components/Info'
import { RadioGroup } from 'ui/components/RadioGroup'

import { QuestionnaireDetailsProps } from './QuestionnaireDetailsProps'

export const QuestionnaireDetails: FC<QuestionnaireDetailsProps> = memo(
  ({
    value,
    state: {
      statesLicensedLabel,
      isWholesaleProductsEnable,
      isWholesaleProductsDescEnable,
      isTypeOfProductsEnable,
      isTypeOfAncillaryProductsEnable,
      isWhoSuppliesYouEnable,
      isWhereIsYourSupplierEnable,
      isWhoDoYouSupplyGrowerEnable,
      isGrowerCustomersLocationEnable,
      isHowManyLocationsEnable,
      isOnlineOrdersForPickupEnable,
      isMinorSellingEnsureEnable,
      isPaymentMethodsEnable,
      isTransferToOtherStatesEnable,
      isTransferToOtherStatesDescEnable,
      isAreYouAffiliatedEnable,
      isAreYouAffiliatedDescEnable
    }
  }) => (
    <>
      <h3>Questionnaire</h3>
      <InfoSet>
        <Info label={statesLicensedLabel}>{value.statesLicensed}</Info>
        {isWholesaleProductsEnable && (
          <Info label="Do you wholesale any of your products?">
            <RadioGroup
              readonly
              value={value.aWholesaleProducts}
              data={[
                { value: YesNoRadioGroup.YES, label: 'Yes' },
                { value: YesNoRadioGroup.NO, label: 'No' }
              ]}
            />
          </Info>
        )}
        {isWholesaleProductsDescEnable && value.aWholesaleProducts === YesNoRadioGroup.YES && (
          <Info label="What products and how often?">{value.aWholesaleProductsDesc}</Info>
        )}
        {isTypeOfProductsEnable && (
          <Info
            label="What type of products will you be selling (medical, recreational/adult use, edibles, oils, 
          topical lotions, CBD, etc.)?"
          >
            {value.bTypeOfProducts}
          </Info>
        )}
        {isTypeOfAncillaryProductsEnable && (
          <Info label="What type of ancillary products will you be selling (pipes, papers, store/brand apparel, etc.)?">
            {value.cTypeOfAncillaryProducts}
          </Info>
        )}
        {isWhoSuppliesYouEnable && <Info label="Who supplies you with your product?">{value.whoSuppliesYou}</Info>}
        {isWhereIsYourSupplierEnable && (
          <Info label="Where is your supplier located?">{value.whereIsYourSupplier}</Info>
        )}
        {isWhoDoYouSupplyGrowerEnable && (
          <Info label="If grower, who do you supply your product to? Please enter “N/A” if not applicable">
            {value.whoDoYourSupplyGrower}
          </Info>
        )}
        {isGrowerCustomersLocationEnable && (
          <Info
            label="If grower/processor, which states are your customers located? Please enter “N/A” if not 
          applicable"
          >
            {value.growerCustomersLocation}
          </Info>
        )}
        {isHowManyLocationsEnable && (
          <Info label="How many locations does this company currently have in total?">{value.dHowManyLocations}</Info>
        )}
        {isOnlineOrdersForPickupEnable && (
          <Info label="Can orders be placed online for pickup or delivery?">
            <RadioGroup
              readonly
              value={value.eOnlineOrdersForPickup}
              data={[
                { value: YesNoRadioGroup.YES, label: 'Yes' },
                { value: YesNoRadioGroup.NO, label: 'No' }
              ]}
            />
          </Info>
        )}
        {isMinorSellingEnsureEnable && (
          <Info label="How do you ensure you are not selling to a minor?">{value.fMinorSellingEnsure}</Info>
        )}
        {isPaymentMethodsEnable && (
          <Info label="What payment methods will/does the business accept?">{value.gPaymentMethods}</Info>
        )}
        {isTransferToOtherStatesEnable && (
          <Info label="Do you mail or transfer products to other states or internationally?">
            <RadioGroup
              readonly
              value={value.hTransferToOtherStates}
              data={[
                { value: YesNoRadioGroup.YES, label: 'Yes' },
                { value: YesNoRadioGroup.NO, label: 'No' }
              ]}
            />
          </Info>
        )}
        {isTransferToOtherStatesDescEnable && value.hTransferToOtherStates === YesNoRadioGroup.YES && (
          <Info label="What are the requirements?">{value.hTransferToOtherStatesDesc}</Info>
        )}
        {isAreYouAffiliatedEnable && (
          <Info label="Are you affiliated with another dispensary, cultivator, or processor?">
            <RadioGroup
              readonly
              value={value.iAreYouAffiliated}
              data={[
                { value: YesNoRadioGroup.YES, label: 'Yes' },
                { value: YesNoRadioGroup.NO, label: 'No' }
              ]}
            />
          </Info>
        )}
        {isAreYouAffiliatedDescEnable && value.iAreYouAffiliated === YesNoRadioGroup.YES && (
          <Info label="Please list business name(s) and location(s)">{value.iAreYouAffiliatedDesc}</Info>
        )}
      </InfoSet>
    </>
  )
)
