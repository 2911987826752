import React, { FC } from 'react'

import { ConnectionHistory } from 'commons/types/DTO/bank/connections/connection'
import { TableDataQa } from 'commons/types/enums'
import { IconSize, TextMask } from 'ui/components'
import { Dialog, DialogFace } from 'ui/components/Dialog'
import { Table } from 'ui/components/Table'

import { KeyHistoryModalProps } from './KeyHistoryModalProps'
import { columns } from './columns'

import { DialogTitle } from './styles'

export const KeyHistoryModal: FC<KeyHistoryModalProps> = ({ connectionKey, history, onClose }) => {
  const mappedHistoriesList = history.map((el) => ({
    item: el
  }))

  return (
    <Dialog
      id="view-connection-history"
      onClose={onClose}
      face={DialogFace.PRIMARY}
      title={
        <DialogTitle>
          {connectionKey.slice(0, connectionKey.indexOf(' '))}
          {'  '}
          <TextMask
            fullyHidden
            text={connectionKey.slice(connectionKey.indexOf(' '))}
            iconSize={IconSize.XS}
            iconFill="#FFFFFF"
          />
        </DialogTitle>
      }
      subTitle="History"
    >
      <Table<ConnectionHistory>
        dataQa={TableDataQa.BP_CONNECTIONS_HISTORY}
        columns={columns}
        dataSource={mappedHistoriesList}
      />
    </Dialog>
  )
}
