import styled from 'styled-components'
import { Hr } from 'ui/components/Hr'

export const FormBody = styled.div`
  padding: 20px 36px;
  padding-bottom: 60px;
`

export const WrappedHr = styled(Hr)`
  margin-bottom: 20px;
`
