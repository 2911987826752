import React, { FC } from 'react'

import { TextMask } from 'ui/components'
import { IconButton } from 'ui/components/Buttons'
import { Icon, IconName, IconSize } from 'ui/components/Icon'

import { HeaderProps } from './HeaderProps'

import { HeaderWrapper, SectionWrapper, StatusBadge, TextBadge, Title } from './styles'

export const Header: FC<HeaderProps> = ({ connectionKey, addedAt, status, setShowingHistoryModalKey }) => {
  const isActive = status === 'Active'

  return (
    <HeaderWrapper $active={isActive}>
      <hr />
      <SectionWrapper>
        <Title>
          Key <TextMask fullyHidden text={connectionKey.slice(connectionKey.indexOf(' '))} iconSize={IconSize.M} />
        </Title>
        <StatusBadge $active={isActive}>{status}</StatusBadge>
      </SectionWrapper>
      <SectionWrapper>
        <TextBadge>
          <Icon name={IconName.INFO} size={IconSize.S} /> {addedAt}
        </TextBadge>
        <IconButton
          icon={IconName.HISTORY_GRAY}
          face="positive"
          onClick={() => setShowingHistoryModalKey(connectionKey)}
        >
          View History
        </IconButton>
      </SectionWrapper>
    </HeaderWrapper>
  )
}
