import styled, { css } from 'styled-components'

import { IconButton } from '../Buttons'
import { DialogFace } from './dialogFace'
import { DialogOverlay } from './dialogOverlay'

export const WrappedButton = styled(IconButton)`
  margin-left: 10px;
`

export const DialogBackdrop = styled.div<{ overlay: DialogOverlay }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1005;
  background: ${(props) => (props.overlay === DialogOverlay.DARK ? 'rgba(0, 0, 0, 0.4)' : 'none')};
`

export const DialogWindow = styled.div<{
  overlay: DialogOverlay
  withFooter: boolean
  $fullScreen?: boolean
  $height?: string
}>`
  display: flex;
  flex-direction: column;
  background: #fff;
  min-width: 500px;
  max-width: ${(p) => (p.$fullScreen ? '100%' : '90%')};
  width: ${(p) => (p.$fullScreen ? '100%' : 'auto')};
  height: ${(p) => (p.$fullScreen ? '100%' : 'auto')};
  border-radius: ${(p) => (p.$fullScreen ? '0' : '10px')};
  overflow: hidden;
  padding-bottom: ${(props) => (props.withFooter ? '5px' : '0')};
  box-shadow: ${(props) => (props.overlay === DialogOverlay.NONE ? '0 0 10px 5px rgba(0, 0, 0, 0.1)' : 'none')};
  ${(p) =>
    p.$height
      ? css`
          height: ${p.$height};
        `
      : ``}
`

export const Header = styled.header<{ face: DialogFace; $fullScreen?: boolean }>`
  box-sizing: border-box;
  height: 80px;
  max-height: 80px;
  padding: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${(props) =>
    props.face === DialogFace.PRIMARY ? props.theme.uiKit.colors.theme.brand.primary : 'transparent'};

  @media print {
    display: ${(p) => (p.$fullScreen ? 'none' : 'flex')};
  }

  h2,
  h5 {
    color: ${(props) => (props.face === DialogFace.PRIMARY ? 'white' : 'black')};
  }

  button svg {
    fill: ${(props) => (props.face === DialogFace.PRIMARY ? 'white' : '#b8b8b8')};
  }
`

export const Title = styled.h2<{ big: boolean }>`
  font-weight: 600;
  font-size: ${(props) => (props.big ? '24px' : '18px')};
  line-height: ${(props) => (props.big ? '32px' : '26px')};
  letter-spacing: 0.53px;
  flex: 1;
  margin: 0;
  white-space: nowrap;
`

export const ActionsWrapper = styled.div`
  display: flex;
  margin-left: 20px;
`

export const DialogContent = styled.div`
  min-height: 146px;
  max-height: calc(90vh - 80px - 100px);
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
`

export const StickyFooter = styled.footer`
  margin-top: auto;
  max-height: 100px;
  border-top: 1px solid ${(p) => p.theme.uiKit.colors.global.grey.grey500};
`

export const Footer = styled.footer`
  margin-top: auto;
  border-top: 1px solid ${(p) => p.theme.uiKit.colors.global.grey.grey500};
  display: flex;
  padding: 30px 40px;
`

export const CustomHeader = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;
`

export const CustomCloseButtonWrapper = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
`
