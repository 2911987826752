import React, { memo } from 'react'

import { BsaBatch } from 'commons/types/DTO/bank/bsa'
import { Dialog, DialogFace } from 'ui/components/Dialog'

import { BatchCTRsTable } from '../../../../components/BatchCTRsTable'

interface Props {
  item: BsaBatch | null
  onClose: () => void
}

export const BatchView = memo((props: Props) => {
  const { item, onClose } = props

  return item ? (
    <Dialog
      id={`batch-${item.id}`}
      onClose={onClose}
      face={DialogFace.PRIMARY}
      title={`Batch ID ${item.id}`}
      subTitle="CTRs in the Batch"
      headerStyle={{ width: 800 }}
    >
      <BatchCTRsTable ctrs={item.ctrs_info!} />
    </Dialog>
  ) : null
})
