import { useCallback, useState } from 'react'

import { FilterAlertsWebPage } from 'commons/service/bank/alerts'
import { useTableState } from 'ui/components'

export const useComponentsEssence = (onAlertFilterChange: (filter: FilterAlertsWebPage) => void) => {
  const [warnings, setWarnings] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)

  const onShowWarnings = useCallback((data: string) => setWarnings(data), [])

  const tableState = useTableState({ filter: {} })

  const onClose = useCallback(() => setWarnings(null), [])

  const changePagination = useCallback(
    async (page: number, size: number) => {
      setLoading(true)

      tableState.onPageChange(page, size)

      const filter = {
        SARsOffset: (page - 1) * size,
        SARsLimit: size
      }

      await onAlertFilterChange(filter)

      setLoading(false)
    },
    [onAlertFilterChange, tableState]
  )

  return {
    warnings,
    loading,
    onShowWarnings,
    tableState,
    changePagination,
    onClose
  }
}
