export const formValidate = (validateFunctionRefs) => {
  const keys = Object.keys(validateFunctionRefs.current.formsValidation)

  let lastErrorCount = 0
  keys.forEach((formPath) => {
    const validationDate = validateFunctionRefs.current.formsValidation[formPath].validate({ updateFields: true })
    if (validationDate.valid) {
      return
    }
    lastErrorCount += Object.keys(validationDate.errors).length
  })
  return lastErrorCount
}
