import React from 'react'

import { FF, TextInput, Validators } from 'ui/components'

import { useSARFormContext } from '../../../../../../../../context'
import { useSubjectInformationItemContext } from '../../../SubjectInformationItemContext'

type IdFormOtherProps = {
  index: number
}

export const IdFormOther = ({ index }: IdFormOtherProps) => {
  const { formApi } = useSARFormContext()
  const { isAllInfoUnavailable } = useSubjectInformationItemContext()
  const idForm = formApi.useStore((s) => s.values.subjects_info[index].id_info[0].id_form)

  return (
    <formApi.Field
      name={`subjects_info[${index}].id_info[0].id_form_other` as 'subjects_info[0].id_info[0].id_form_other'}
      validators={{
        onChange: ({ value }) =>
          !!value || idForm !== 'Other' || isAllInfoUnavailable ? undefined : Validators.required.field(value)
      }}
      children={(field) => (
        <FF
          required={idForm === 'Other' && !isAllInfoUnavailable}
          field={field}
          label="Other Type"
          input={(p) => <TextInput {...p} disabled={isAllInfoUnavailable} />}
        />
      )}
    />
  )
}
