import styled from 'styled-components'

export const ConfirmationContainer = styled.div`
  max-width: 470px;
  margin: 0 auto;
  padding: 40px 20px 20px;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: #000000;

  * {
    font-size: 18px;
  }
`

export const Footer = styled.footer`
  display: flex;
  padding: 30px 40px;
  justify-content: space-around;
`
