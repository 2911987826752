import { useMutationManager } from 'react-query-state-manager'

import { handlerServerError } from 'errors'
import { Toast } from 'ui/components/Toast'
import { CTR } from '~bank-bsa-reporting/service'

export const useCTRCancel = (onSuccess: () => void, onError: () => void) =>
  useMutationManager(CTR.cancelCTR, {
    onSuccess: async () => {
      Toast.successSmall('The CTR was canceled successfully')
      await onSuccess()
    },
    onError: (error) => {
      onError()
      handlerServerError(error)
    }
  })
