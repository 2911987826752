import React from 'react'

import { Validators } from 'ui/components/Form'
import { TextareaInput } from 'ui/components/InputV2'
import { Select } from 'ui/components/Select'
import { Col, Col2x, FormRow } from 'ui/themes/globalStyles'

import { SecondText } from '../../style'
// eslint-disable-next-line max-len
import { ReviewCreditCardActivityForInternationalWasThereProps } from './ReviewCreditCardActivityForInternationalWasThereProps'

export const ReviewCreditCardActivityForInternationalWasThere = ({
  formValue,
  Field
}: ReviewCreditCardActivityForInternationalWasThereProps) => (
  <>
    <SecondText>
      Review the credit card activity for international transactions and unknown merchant/vendor activity.
    </SecondText>
    <FormRow>
      <Col>
        <Field
          required
          name="reviewCreditCardActivityForInternationalWasThere"
          label="Was there international or unknown merchant/vendor activity?"
          input={Select}
          inputProps={{
            data: [
              {
                label: 'Yes – See comments',
                value: 'yes'
              },
              {
                label: 'All Transactions Ok',
                value: 'all_transactions'
              },
              {
                label: 'No Transactions of This Type',
                value: 'no_transactions'
              }
            ]
          }}
          validator={Validators.required.field}
        />
      </Col>
      <Col>
        <Field
          required
          name="reviewCreditCardActivityForInternationalActivityAddressed"
          label="If noted, how was the activity addressed?"
          input={Select}
          inputProps={{
            data: [
              {
                label: 'N/A',
                value: 'n/a'
              },
              {
                label: 'Transaction cleared with communication to customer during review',
                value: 'cleared'
              },
              {
                label: 'Transaction added to annual questions',
                value: 'added'
              }
            ]
          }}
          validator={Validators.required.field}
        />
      </Col>
    </FormRow>
    <FormRow>
      <Col2x>
        <Field
          required={formValue.reviewCreditCardActivityForInternationalWasThere === 'yes'}
          name="reviewCreditCardActivityForInternationalComments"
          label="Comments"
          input={TextareaInput}
          inputProps={{}}
          validator={
            formValue.reviewCreditCardActivityForInternationalWasThere === 'yes' ? Validators.required.field : undefined
          }
        />
      </Col2x>
    </FormRow>
  </>
)
