import { HistoryAuditListFilter } from 'commons/types/DTO/bank/historyAudit'

export const createParamsFromFilter = (filter: Partial<HistoryAuditListFilter>) => {
  const { page, size, action, entityId, entityType, bankUserId, timeFrom, timeTo } = filter

  const params = {
    page: page,
    size: size,
    ...(action ? { action } : {}),
    ...(entityId ? { entityId } : {}),
    ...(entityType ? { entityType } : {}),
    ...(bankUserId ? { bankUserId } : {}),
    ...(timeFrom ? { timeFrom: new Date(timeFrom).toISOString() } : {}),
    ...(timeTo ? { timeTo: new Date(timeTo).toISOString() } : {})
  }

  return params
}
