/*
 * Bank production IDs.
 */

export const BANK_DEV_ID = 1
export const BANK_SHORE_ID = 2
export const BANK_CBT_ID = 3
export const BANK_INB_ID = 5
export const BANK_MPB_ID = 6
export const BANK_HERRING_ID = 7
export const BANK_TRANSACT_ID = 8
export const BANK_CHAMPION_ID = 9
export const BANK_DEMO_ID = 10
export const BANK_MEDMEN_ID = 11
export const BANK_FLAGLER_ID = 12
export const BANK_VALLIANCE_ID = 13
export const BANK_BISON_ID = 14

export const banksMap = new Map<string, number>([
  ['BP', 0],
  ['Herring', BANK_HERRING_ID],
  ['CBT', BANK_CBT_ID],
  ['INB', BANK_INB_ID],
  ['Shore', BANK_SHORE_ID],
  ['Transact', BANK_TRANSACT_ID],
  ['Valliance', BANK_VALLIANCE_ID],
  ['Bison', BANK_BISON_ID]
])
