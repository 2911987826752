import React, { FC, useMemo } from 'react'

import { TableDataQa } from 'commons/types/enums'
import { PageSection, PageSectionFace } from 'ui/components'
import { Layer } from 'ui/components/Layer'
import { DataSourceItem, Table } from 'ui/components/Table'
import { DocumentDetailsFile } from '~bank-documents/types/documentDetailsFile'

import { FilesSectionProps } from './FilesSection.types'
import { columns } from './columns'
import { ManageFileDialog } from './components/ManageFileDialog'
import { UploadButton } from './components/UploadButton'
import { UploadFileDialog } from './components/UploadFileDialog'
import { useDocFiles } from './hooks/useDocFiles'

export const FilesSection: FC<FilesSectionProps> = (props) => {
  const { files, id, withDialog = false } = props

  const {
    DeleteConfirmation,
    openDeleteConfirmation,

    fileForEditDialog,
    openFileEditDialog,
    closeFileEditDialog,

    onUpload,
    showUploadFilesDialog,
    closeFileUploadDialog,
    onSubmitFileDialog
  } = useDocFiles({
    id,
    withDialog
  })

  const filesCols = useMemo(
    () => columns(openFileEditDialog, openDeleteConfirmation),
    [openFileEditDialog, openDeleteConfirmation]
  )
  const mappedFiles: DataSourceItem<any>[] = files.map((fileItem) => ({
    item: fileItem
  }))

  return (
    <>
      <DeleteConfirmation />

      {Boolean(fileForEditDialog) && (
        <ManageFileDialog
          file={fileForEditDialog}
          onClose={closeFileEditDialog}
          deleteInfo={{ DeleteConfirmation, openDeleteConfirmation }}
        />
      )}

      {showUploadFilesDialog && <UploadFileDialog onSubmit={onSubmitFileDialog} onClose={closeFileUploadDialog} />}

      <PageSection
        face={PageSectionFace.SECONDARY}
        title="Files"
        actions={<UploadButton data-qa="upload-file" onUpload={onUpload} />}
      >
        <Layer rounded shadowed>
          <Table<DocumentDetailsFile>
            dataQa={TableDataQa.BP_DOCUMENT_PERIOD_FILES}
            columns={filesCols}
            dataSource={mappedFiles}
          />
        </Layer>
      </PageSection>
    </>
  )
}
