import { useEffect, useMemo } from 'react'

import { PortalName } from 'commons/types/enums'
import { useApi401Interceptor, useHeartbeat } from '~auth/hooks/user'

import { LocationParts, getNoUserRedirectPath } from '../helpers/getNoUserRedirectPath'
import { useValidateApplicationToken } from './useValidateApplicationToken'

export const useSmartRedirect = (portalName: PortalName) => {
  const searchParams = new URLSearchParams(window.location.search)
  const query: { application_token?: string; returnUrl?: string } = {}
  for (const [key, value] of searchParams.entries()) {
    query[key] = value
  }

  const { data: session, isFetching } = useHeartbeat({ portalName })

  const {
    routine: applicationTokenValidate,
    isError: isValidationFailed,
    isSuccess: isValidationSucceeded
  } = useValidateApplicationToken({
    onError: (error) => {
      window.history.pushState(null, '', `/auth/application-validate-error?error=${error.message}`)
    },
    onSuccess: async (_, result) => {
      if (result.password_reset_key) {
        // eslint-disable-next-line max-len
        window.history.pushState(
          null,
          '',
          `/auth/password-reset?key=${result.password_reset_key}&returnUrl=${window.location.pathname}`
        )
      } else {
        window.history.pushState(null, '', `/auth/login?returnUrl=${window.location.pathname}`)
      }
    }
  })

  const isTokenValidationFinished = isValidationFailed || isValidationSucceeded

  // logout if intercepted axios 401 error
  useApi401Interceptor(portalName)

  useEffect(() => {
    if (!isFetching) {
      const handleRoute = (location: LocationParts) => {
        const applicationToken = query?.application_token
        const isOnBoarding = location.pathname.indexOf('onboarding') !== -1

        if (isOnBoarding) {
          if (session && applicationToken) {
            window.history.pushState(null, '', location.pathname)
          }

          if (!session && applicationToken) {
            applicationTokenValidate(applicationToken)
          }
        }

        const onTheAuthRoutes = window.location.pathname.indexOf('/auth') === 0
        const isOnRestPasswordRoute = location.pathname.indexOf('/auth/password-reset') === 0

        if (session && onTheAuthRoutes && !isOnRestPasswordRoute && query.returnUrl !== window.location.pathname) {
          window.history.pushState(null, '', location.search ? `${location.search}` : '/')
          return
        }

        if (session && isOnRestPasswordRoute) return window.history.pushState(null, '', '/')

        if (!session && !isFetching && !onTheAuthRoutes && (!applicationToken || isTokenValidationFinished)) {
          const redirectPath = getNoUserRedirectPath(location)
          window.history.pushState({}, '', redirectPath)
          return
        }
      }

      const location = {
        pathname: window.location.pathname,
        search: window.location.search
      }

      handleRoute(location)
    }
  }, [isFetching, session, window.location.pathname])

  const sessionInfo = useMemo(
    () => ({
      session,
      isFetching
    }),
    [session, isFetching]
  )

  return sessionInfo
}
