import { Request } from 'commons/utils/request'
import { WriteHook, useMutationManager } from 'state-manager'

type SubmitInvoiceFileRequest = {
  file: File
  invoiceSubmittalId: string
}

export const uploadInvoiceFile = (item: SubmitInvoiceFileRequest): Promise<unknown> => {
  const form = new FormData()
  form.append('file', item.file)
  form.append('invoiceSubmittalId', item.invoiceSubmittalId)
  return Request.post(Request.urls.bank.invoiceSubmittalFileUpload, form)
}

export const useUploadInvoiceFile: WriteHook<SubmitInvoiceFileRequest, unknown> = (opts) =>
  useMutationManager(uploadInvoiceFile, opts)
