import React, { FC, memo } from 'react'

import { TableDataQa } from 'commons/types/enums'
import { Request } from 'commons/utils/request'
import { QueryTable } from 'ui/components/QueryTable'

import { StatusReportTableProps } from './TableProps'
import { columns } from './columns'
import { FilterForm } from './components'
import { dataSourceSelector } from './dataSourceSelector'

export const Table: FC<StatusReportTableProps> = memo((props) => {
  const {
    tableState: { filter, pagination, sorting },
    filterForm
  } = props

  return (
    <QueryTable
      name="Documents Report"
      dataQa={TableDataQa.BP_REPORTS_DOCUMENTS_REPORT}
      dataSources={[
        {
          key: 'list',
          url: Request.urls.bank.portalReportDocuments,
          options: (_: any, filter?: any) => ({
            active: filter.active.length ? JSON.parse(filter.active) : null,
            document_name: filter.document_name,
            document_type: filter.document_type,
            frequency: filter.frequency,
            level: filter.level,
            start_date_type: filter.start_date_type,
            state: filter.state,
            offset: (pagination.page - 1) * pagination.size,
            limit: pagination.size
          })
        }
      ]}
      columns={columns}
      dataSourceSelector={dataSourceSelector}
      sorting={{
        order: sorting.order,
        onChange: sorting.change
      }}
      pagination={{
        page: pagination.page,
        size: pagination.size,
        onChange: pagination.change
      }}
      filter={{
        value: filterForm.config.value,
        // TODO: remove this fix when we are on the latest brief-form
        component: <FilterForm form={filterForm as any} expanded={filter.expanded} onToggle={filter.toggle} />
      }}
    />
  )
})
