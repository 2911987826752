import { OnboardingFormActions } from '../../../enums'
import { SetCancelClientNoteDialogAction, SetShowClientNoteDialogAction } from '../../../types/actions'

export const setShowClientNoteDialog = (payload: boolean): SetShowClientNoteDialogAction => ({
  type: OnboardingFormActions.SET_SHOW_CLIENT_NOTE_DIALOG,
  payload
})

export const setCancelClientNoteDialog = (): SetCancelClientNoteDialogAction => ({
  type: OnboardingFormActions.SET_CANCEL_CLIENT_NOTE_DIALOG
})
