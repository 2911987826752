import React, { FC, memo } from 'react'

import { TableDataQa } from 'commons/types/enums'
import { Link } from 'ui/components'
import { Dialog, DialogFace } from 'ui/components/Dialog'
import { QuarterlyReportStatusLabel } from 'ui/components/Labels'
import { Table } from 'ui/components/Table'

import { MonthlyReportsDialogProps } from './MonthlyReportsDialogProps'

import * as SC from './styles'

export const MonthlyReportsDialog: FC<MonthlyReportsDialogProps> = memo((props) => {
  const { open, onClose, reportsMonthly, reportPeriod } = props

  if (!open) {
    return null
  }

  return (
    <Dialog id="monthly" face={DialogFace.PRIMARY} title={reportPeriod} subTitle="Monthly Analytics" onClose={onClose}>
      <Table
        dataQa={TableDataQa.BP_QUARTERLY_ANALYTICS_MONTHLY_REPORTS_LIST}
        columns={[
          {
            title: 'Month',
            align: 'center',
            sortable: false,
            render: (item) => (
              <Link to={`/mr/$id`} params={{ id: item.id }}>
                {item.name}
              </Link>
            )
          },
          {
            title: 'Status',
            align: 'center',
            sortable: false,
            render: ({ status }) => (
              <SC.Chip>
                <QuarterlyReportStatusLabel name={status} />
              </SC.Chip>
            )
          }
        ]}
        dataSource={reportsMonthly.reverse().map((el) => ({
          item: el
        }))}
      />
    </Dialog>
  )
})
