import styled, { css } from 'styled-components'

export const IdColumnWithNotes = styled.div`
  display: flex;
  align-items: center;
  width: 100px;
  gap: 5px;
`

const EmailAndPhoneStyle = css`
  font-size: 12px !important;
  font-weight: 600;
  line-height: 15px !important;
  letter-spacing: 0.24px;
  white-space: nowrap;
  color: #000 !important;
  text-decoration: none;
`

export const Phone = styled.div`
  ${EmailAndPhoneStyle}
`

export const EmailLink = styled.a`
  ${EmailAndPhoneStyle}
`
