import { OnboardingWebPageResponse } from 'commons/types/DTO/commons'
import isNil from 'lodash/isNil'
import omitBy from 'lodash/omitBy'

import { OnboardingFormFieldNames } from '../../../../enums'
import {
  AnticipatedMonthlyActivityFormData,
  AvailableServicesFormData,
  AvailableServicesFormValue,
  CompanyInformationFormData,
  QuestionnaireFormData
} from '../../../../types/forms'
import { selectCompany, selectQuestionnaire } from '../selectors'

export const getEmptyCompanyInformation = ({
  responseData,
  companyIndex
}: {
  responseData: Partial<OnboardingWebPageResponse>
  companyIndex: number
}): CompanyInformationFormData => {
  const selectedCompany = selectCompany({ responseData, companyIndex })
  return {
    formValues: {
      [OnboardingFormFieldNames.ENTITY_UID]: selectedCompany[OnboardingFormFieldNames.ENTITY_UID],

      [OnboardingFormFieldNames.COMPANY_ID]: selectedCompany[OnboardingFormFieldNames.COMPANY_ID],
      [OnboardingFormFieldNames.LEGAL_NAME]: selectedCompany[OnboardingFormFieldNames.LEGAL_NAME],
      [OnboardingFormFieldNames.DBA]: selectedCompany[OnboardingFormFieldNames.DBA],
      [OnboardingFormFieldNames.PHONE]: selectedCompany[OnboardingFormFieldNames.PHONE],
      [OnboardingFormFieldNames.WEBSITE]: selectedCompany[OnboardingFormFieldNames.WEBSITE],
      [OnboardingFormFieldNames.DESCRIPTION]: selectedCompany[OnboardingFormFieldNames.DESCRIPTION]
    },
    formErrors: {}
  }
}

export const getEmptyCompanyAccountDetails = ({
  companyIndex,
  responseData
}: {
  responseData: Partial<OnboardingWebPageResponse>
  companyIndex: number
}) => ({
  formValues: {
    businessType: selectCompany({ responseData, companyIndex })?.businessType,
    entityType: selectCompany({ responseData, companyIndex })?.entityType,
    stateOfIncorporation: selectCompany({ responseData, companyIndex })?.stateOfIncorporation,
    EIN: selectCompany({ responseData, companyIndex })?.EIN,
    preLicenseMRBInfo: selectCompany({ responseData, companyIndex })?.preLicenseMRBInfo
  },
  formErrors: {}
})

export const getEmptyPhysicalAddress = ({
  responseData,
  companyIndex
}: {
  responseData: Partial<OnboardingWebPageResponse>
  companyIndex: number
}) => ({
  formValues: {
    street: selectCompany({ responseData, companyIndex })?.street,
    city: selectCompany({ responseData, companyIndex })?.city,
    state: selectCompany({ responseData, companyIndex })?.state,
    country: selectCompany({ responseData, companyIndex })?.country,
    postalCode: selectCompany({ responseData, companyIndex })?.postalCode
  },
  formErrors: {}
})

export const getEmptyMailingAddress = ({
  responseData,
  companyIndex
}: {
  responseData: Partial<OnboardingWebPageResponse>
  companyIndex: number
}) => ({
  formValues: {
    mailingStreet: selectCompany({ responseData, companyIndex })?.mailingStreet,
    mailingCity: selectCompany({ responseData, companyIndex })?.mailingCity,
    mailingState: selectCompany({ responseData, companyIndex })?.mailingState,
    mailingCountry: selectCompany({ responseData, companyIndex })?.mailingCountry,
    mailingPostalCode: selectCompany({ responseData, companyIndex })?.mailingPostalCode
  },
  formErrors: {}
})

export const getEmptyQuestionnaire = ({
  responseData,
  companyIndex
}: {
  responseData: Partial<OnboardingWebPageResponse>
  companyIndex: number
}): QuestionnaireFormData => ({
  formValues: {
    statesLicensed: selectQuestionnaire({ responseData, companyIndex })?.statesLicensed,
    aWholesaleProducts: selectQuestionnaire({ responseData, companyIndex })?.aWholesaleProducts,
    aWholesaleProductsDesc: selectQuestionnaire({ responseData, companyIndex })?.aWholesaleProductsDesc,
    bTypeOfProducts: selectQuestionnaire({ responseData, companyIndex })?.bTypeOfProducts,
    cTypeOfAncillaryProducts: selectQuestionnaire({ responseData, companyIndex })?.cTypeOfAncillaryProducts,
    whoSuppliesYou: selectQuestionnaire({ responseData, companyIndex })?.whoSuppliesYou,
    whereIsYourSupplier: selectQuestionnaire({ responseData, companyIndex })?.whereIsYourSupplier,
    whoDoYourSupplyGrower: selectQuestionnaire({ responseData, companyIndex })?.whoDoYourSupplyGrower,
    growerCustomersLocation: selectQuestionnaire({ responseData, companyIndex })?.growerCustomersLocation,
    dHowManyLocations: selectQuestionnaire({ responseData, companyIndex })?.dHowManyLocations,
    eOnlineOrdersForPickup: selectQuestionnaire({ responseData, companyIndex })?.eOnlineOrdersForPickup,
    fMinorSellingEnsure: selectQuestionnaire({ responseData, companyIndex })?.fMinorSellingEnsure,
    gPaymentMethods: selectQuestionnaire({ responseData, companyIndex })?.gPaymentMethods,
    hTransferToOtherStates: selectQuestionnaire({ responseData, companyIndex })?.hTransferToOtherStates,
    hTransferToOtherStatesDesc: selectQuestionnaire({ responseData, companyIndex })?.hTransferToOtherStatesDesc,
    iAreYouAffiliated: selectQuestionnaire({ responseData, companyIndex })?.iAreYouAffiliated,
    iAreYouAffiliatedDesc: selectQuestionnaire({ responseData, companyIndex })?.iAreYouAffiliatedDesc,
    statesLicensedLabel: selectQuestionnaire({ responseData, companyIndex })?.statesLicensedLabel,
    contactsForeignConnections: selectQuestionnaire({ responseData, companyIndex })?.contactsForeignConnections
  },
  formErrors: {}
})

export const getEmptyProfessionalPartnerships = ({
  responseData,
  companyIndex
}: {
  responseData: Partial<OnboardingWebPageResponse>
  companyIndex: number
}) => ({
  formValues: {
    proPartCurrentlyBanked: selectQuestionnaire({ responseData, companyIndex })?.proPartCurrentlyBanked,
    proPartPrevClosureReason: selectQuestionnaire({ responseData, companyIndex })?.proPartPrevClosureReason,
    proPartCurrentServicesCPAs: selectQuestionnaire({ responseData, companyIndex })?.proPartCurrentServicesCPAs,
    proPartCurrentServicesLaw: selectQuestionnaire({ responseData, companyIndex })?.proPartCurrentServicesLaw,
    proPartCurrentServicesLogistic: selectQuestionnaire({ responseData, companyIndex })?.proPartCurrentServicesLogistic,
    proPartCurrentServicesOther: selectQuestionnaire({ responseData, companyIndex })?.proPartCurrentServicesOther,
    proPartCurrentServicesLogisticDesc: selectQuestionnaire({ responseData, companyIndex })
      ?.proPartCurrentServicesLogisticDesc,
    proPartCurrentServicesOtherDesc: selectQuestionnaire({ responseData, companyIndex })
      ?.proPartCurrentServicesOtherDesc,
    proPartLitigation: selectQuestionnaire({ responseData, companyIndex })?.proPartLitigation,
    proPartStateEnforcements: selectQuestionnaire({ responseData, companyIndex })?.proPartStateEnforcements,
    proPartInternetGambling: selectQuestionnaire({ responseData, companyIndex })?.proPartInternetGambling,
    proPartPayrollSoftware: selectQuestionnaire({ responseData, companyIndex })?.proPartPayrollSoftware
  },
  formErrors: {}
})

export const getEmptyAtmGeneralInfo = ({
  responseData,
  companyIndex
}: {
  responseData: Partial<OnboardingWebPageResponse>
  companyIndex: number
}) => ({
  formValues: {
    ATMHave: selectQuestionnaire({ responseData, companyIndex })?.ATMHave,
    ATMOwn: selectQuestionnaire({ responseData, companyIndex })?.ATMOwn,
    ATMCount: selectQuestionnaire({ responseData, companyIndex })?.ATMCount,
    ATMReplenishingThirdParty: selectQuestionnaire({ responseData, companyIndex })?.ATMReplenishingThirdParty,
    ATMReplenishingCompany: selectQuestionnaire({ responseData, companyIndex })?.ATMReplenishingCompany
  },
  formErrors: {}
})

export const getEmptyAvailableServices = ({
  responseData,
  companyIndex
}: {
  responseData: Partial<OnboardingWebPageResponse>
  companyIndex: number
}): AvailableServicesFormData => {
  const formValues = omitBy<AvailableServicesFormValue>(
    {
      availServiceCheckingOperating: selectQuestionnaire({ responseData, companyIndex })?.availServiceCheckingOperating,
      availServiceCheckingPayroll: selectQuestionnaire({ responseData, companyIndex })?.availServiceCheckingPayroll,
      availServiceChecking: selectQuestionnaire({ responseData, companyIndex })?.availServiceChecking,
      availServiceSavings: selectQuestionnaire({ responseData, companyIndex })?.availServiceSavings,
      availServiceACHOrigination: selectQuestionnaire({ responseData, companyIndex })?.availServiceACHOrigination,
      availServiceMobileDeposit: selectQuestionnaire({ responseData, companyIndex })?.availServiceMobileDeposit,
      availServiceWire: selectQuestionnaire({ responseData, companyIndex })?.availServiceWire,
      availServiceRollingCoinChangeOrder: selectQuestionnaire({ responseData, companyIndex })
        ?.availServiceRollingCoinChangeOrder,
      availServiceRemoteDepositCapture: selectQuestionnaire({ responseData, companyIndex })
        ?.availServiceRemoteDepositCapture,
      availServiceEscrow: selectQuestionnaire({ responseData, companyIndex })?.availServiceEscrow,
      availServiceOnlineMobileBanking: selectQuestionnaire({ responseData, companyIndex })
        ?.availServiceOnlineMobileBanking,
      availServiceBillPay: selectQuestionnaire({ responseData, companyIndex })?.availServiceBillPay,
      availServiceDebitCard: selectQuestionnaire({ responseData, companyIndex })?.availServiceDebitCard,
      availServiceCreditCard: selectQuestionnaire({ responseData, companyIndex })?.availServiceCreditCard,
      availServiceWireDomestic: selectQuestionnaire({ responseData, companyIndex })?.availServiceWireDomestic,
      availServiceWireDomesticOnly: selectQuestionnaire({ responseData, companyIndex })?.availServiceWireDomesticOnly,
      availServiceWireForeign: selectQuestionnaire({ responseData, companyIndex })?.availServiceWireForeign,
      availServicePersonalChecking: selectQuestionnaire({ responseData, companyIndex })?.availServicePersonalChecking,
      availServicePersonalSavings: selectQuestionnaire({ responseData, companyIndex })?.availServicePersonalSavings,
      availServiceATMSettlement: selectQuestionnaire({ responseData, companyIndex })?.availServiceATMSettlement,
      availServiceHowManyAccountsIsOpening: selectQuestionnaire({ responseData, companyIndex })
        ?.availServiceHowManyAccountsIsOpening
    },
    isNil
  ) as AvailableServicesFormValue
  return {
    formValues,
    formErrors: {}
  }
}

export const getEmptyAnticipatedMonthlyActivity = ({
  responseData,
  companyIndex
}: {
  responseData: Partial<OnboardingWebPageResponse>
  companyIndex: number
}): AnticipatedMonthlyActivityFormData => ({
  formValues: {
    anticipatedActivityACHDeposits: selectQuestionnaire({ responseData, companyIndex })?.anticipatedActivityACHDeposits,
    anticipatedActivityACHDepositsAmount: selectQuestionnaire({ responseData, companyIndex })
      ?.anticipatedActivityACHDepositsAmount,
    anticipatedActivityACHDepositsFrequency: selectQuestionnaire({ responseData, companyIndex })
      ?.anticipatedActivityACHDepositsFrequency,
    anticipatedActivityWiresInternationalCountries: selectQuestionnaire({ responseData, companyIndex })
      ?.anticipatedActivityWiresInternationalCountries,
    anticipatedActivityWiresInternational: selectQuestionnaire({ responseData, companyIndex })
      ?.anticipatedActivityWiresInternational,
    anticipatedActivityWiresInternationalAmount: selectQuestionnaire({ responseData, companyIndex })
      ?.anticipatedActivityWiresInternationalAmount,
    anticipatedActivityWiresInternationalFrequency: selectQuestionnaire({ responseData, companyIndex })
      ?.anticipatedActivityWiresInternationalFrequency,
    anticipatedActivityACHOriginating: selectQuestionnaire({ responseData, companyIndex })
      ?.anticipatedActivityACHOriginating,
    anticipatedActivityACHOriginatingAmount: selectQuestionnaire({ responseData, companyIndex })
      ?.anticipatedActivityACHOriginatingAmount,
    anticipatedActivityACHOriginatingFrequency: selectQuestionnaire({ responseData, companyIndex })
      ?.anticipatedActivityACHOriginatingFrequency,
    anticipatedActivityATMWithdrawals: selectQuestionnaire({ responseData, companyIndex })
      ?.anticipatedActivityATMWithdrawals,
    anticipatedActivityATMWithdrawalsAmount: selectQuestionnaire({ responseData, companyIndex })
      ?.anticipatedActivityATMWithdrawalsAmount,
    anticipatedActivityATMWithdrawalsFrequency: selectQuestionnaire({ responseData, companyIndex })
      ?.anticipatedActivityATMWithdrawalsFrequency,
    anticipatedActivityCashDeposits: selectQuestionnaire({ responseData, companyIndex })
      ?.anticipatedActivityCashDeposits,
    anticipatedActivityCashDepositsAmount: selectQuestionnaire({ responseData, companyIndex })
      ?.anticipatedActivityCashDepositsAmount,
    anticipatedActivityCashDepositsFrequency: selectQuestionnaire({ responseData, companyIndex })
      ?.anticipatedActivityCashDepositsFrequency,
    anticipatedActivityCheckDeposits: selectQuestionnaire({ responseData, companyIndex })
      ?.anticipatedActivityCheckDeposits,
    anticipatedActivityCheckDepositsAmount: selectQuestionnaire({ responseData, companyIndex })
      ?.anticipatedActivityCheckDepositsAmount,
    anticipatedActivityCheckDepositsFrequency: selectQuestionnaire({ responseData, companyIndex })
      ?.anticipatedActivityCheckDepositsFrequency,
    anticipatedActivityCashWithdrawals: selectQuestionnaire({ responseData, companyIndex })
      ?.anticipatedActivityCashWithdrawals,
    anticipatedActivityCashWithdrawalsAmount: selectQuestionnaire({ responseData, companyIndex })
      ?.anticipatedActivityCashWithdrawalsAmount,
    anticipatedActivityCashWithdrawalsFrequency: selectQuestionnaire({ responseData, companyIndex })
      ?.anticipatedActivityCashWithdrawalsFrequency,
    anticipatedActivityCheckWithdrawals: selectQuestionnaire({ responseData, companyIndex })
      ?.anticipatedActivityCheckWithdrawals,
    anticipatedActivityCheckWithdrawalsAmount: selectQuestionnaire({ responseData, companyIndex })
      ?.anticipatedActivityCheckWithdrawalsAmount,
    anticipatedActivityCheckWithdrawalsFrequency: selectQuestionnaire({ responseData, companyIndex })
      ?.anticipatedActivityCheckWithdrawalsFrequency,
    anticipatedActivityRemoteDeposits: selectQuestionnaire({ responseData, companyIndex })
      ?.anticipatedActivityRemoteDeposits,
    anticipatedActivityRemoteDepositsAmount: selectQuestionnaire({ responseData, companyIndex })
      ?.anticipatedActivityRemoteDepositsAmount,
    anticipatedActivityRemoteDepositsFrequency: selectQuestionnaire({ responseData, companyIndex })
      ?.anticipatedActivityRemoteDepositsFrequency,
    anticipatedActivityWiresDomesticOnly: selectQuestionnaire({ responseData, companyIndex })
      ?.anticipatedActivityWiresDomesticOnly,
    anticipatedActivityWiresDomesticOnlyAmount: selectQuestionnaire({ responseData, companyIndex })
      ?.anticipatedActivityWiresDomesticOnlyAmount,
    anticipatedActivityWiresDomesticOnlyFrequency: selectQuestionnaire({ responseData, companyIndex })
      ?.anticipatedActivityWiresDomesticOnlyFrequency,
    anticipatedActivityWiresDomestic: selectQuestionnaire({ responseData, companyIndex })
      ?.anticipatedActivityWiresDomestic,
    anticipatedActivityWiresDomesticAmount: selectQuestionnaire({ responseData, companyIndex })
      ?.anticipatedActivityWiresDomesticAmount,
    anticipatedActivityWiresDomesticFrequency: selectQuestionnaire({ responseData, companyIndex })
      ?.anticipatedActivityWiresDomesticFrequency,
    anticipatedActivityATMSettlement: selectQuestionnaire({ responseData, companyIndex })
      ?.anticipatedActivityATMSettlement,
    anticipatedActivityATMSettlementAmount: selectQuestionnaire({ responseData, companyIndex })
      ?.anticipatedActivityATMSettlementAmount,
    anticipatedActivityATMSettlementFrequency: selectQuestionnaire({ responseData, companyIndex })
      ?.anticipatedActivityATMSettlementFrequency
  },
  formErrors: {}
})

export const getEmptyAnticipatedMonthlyActivityNA = ({
  responseData,
  companyIndex
}: {
  responseData: Partial<OnboardingWebPageResponse>
  companyIndex: number
}) => ({
  formValues: {
    activityDepositsChecksItems: selectQuestionnaire({ responseData, companyIndex })?.activityDepositsChecksItems,
    activityDepositsChecksAmount: selectQuestionnaire({ responseData, companyIndex })?.activityDepositsChecksAmount,
    activityDepositsChecksNA: selectQuestionnaire({ responseData, companyIndex })?.activityDepositsChecksNA,
    activityDepositsCashItems: selectQuestionnaire({ responseData, companyIndex })?.activityDepositsCashItems,
    activityDepositsCashAmount: selectQuestionnaire({ responseData, companyIndex })?.activityDepositsCashAmount,
    activityDepositsCashNA: selectQuestionnaire({ responseData, companyIndex })?.activityDepositsCashNA,
    activityDepositsOtherItems: selectQuestionnaire({ responseData, companyIndex })?.activityDepositsOtherItems,
    activityDepositsOtherAmount: selectQuestionnaire({ responseData, companyIndex })?.activityDepositsOtherAmount,
    activityDepositsOtherNA: selectQuestionnaire({ responseData, companyIndex })?.activityDepositsOtherNA,
    activityWithdrawalsCashItems: selectQuestionnaire({ responseData, companyIndex })?.activityWithdrawalsCashItems,
    activityWithdrawalsCashAmount: selectQuestionnaire({ responseData, companyIndex })?.activityWithdrawalsCashAmount,
    activityWithdrawalsCashNA: selectQuestionnaire({ responseData, companyIndex })?.activityWithdrawalsCashNA
  },
  formErrors: {}
})

export const getEmptyMerchantQuestionnaire = ({
  responseData,
  companyIndex
}: {
  responseData: Partial<OnboardingWebPageResponse>
  companyIndex: number
}) => ({
  formValues: {
    legacyCash: selectQuestionnaire({ responseData, companyIndex })?.legacyCash,
    merchantProcessing: selectQuestionnaire({ responseData, companyIndex })?.merchantProcessing,
    merchantProcessingDesc: selectQuestionnaire({ responseData, companyIndex })?.merchantProcessingDesc,
    primaryMethodToFund: selectQuestionnaire({ responseData, companyIndex })?.primaryMethodToFund,
    cryptocurrencyTransactions: selectQuestionnaire({ responseData, companyIndex })?.cryptocurrencyTransactions,
    realTimePaymentServices: selectQuestionnaire({ responseData, companyIndex })?.realTimePaymentServices
  },
  formErrors: {}
})
