import { PortalName } from 'commons/types/enums'
import { Request } from 'commons/utils/request'

const urls = {
  [PortalName.BP]: Request.urls.bank.authMfaDevice,
  [PortalName.CP]: Request.urls.client.authMfaDevice,
  [PortalName.BOP]: Request.urls.support.authMfaDevice
}

export const createDevice = (p: PortalName, type: string, name: string) =>
  Request.post(urls[p], {
    type,
    phone_number: type === 'sms' ? name : undefined,
    email: type === 'email' ? name : undefined
  })

export const deleteDevice = (p: PortalName, id: number) => Request.delete(urls[p], { id })

export const setDeviceAsDefault = (p: PortalName, id: number) => {
  const urlsDeviceDefault = {
    [PortalName.BP]: Request.urls.bank.authMfaDeviceDefault,
    [PortalName.CP]: Request.urls.client.authMfaDeviceDefault,
    [PortalName.BOP]: Request.urls.support.authMfaDeviceDefault
  }
  return Request.post(urlsDeviceDefault[p], { device_id: id })
}

export const sendChallenge = (p: PortalName, deviceId: number) => {
  const urlsChallenge = {
    [PortalName.BP]: Request.urls.bank.authMfaChallenge,
    [PortalName.CP]: Request.urls.client.authMfaChallenge,
    [PortalName.BOP]: Request.urls.support.authMfaChallenge
  }
  return Request.post(urlsChallenge[p], { device_id: deviceId })
}

export const sendSecurityCodeUrls = {
  [PortalName.BP]: Request.urls.bank.authMfaLogin,
  [PortalName.CP]: Request.urls.client.authMfaLogin,
  [PortalName.BOP]: Request.urls.support.authMfaLogin
}

export const sendSecurityCode = (p: PortalName, challengeId: string, code: string) =>
  Request.post<{ expires_at: string }>(sendSecurityCodeUrls[p], { code, challenge_id: challengeId })
