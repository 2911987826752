import React, { FC, memo, useEffect, useMemo, useState } from 'react'

import { AffiliatedCompany } from 'commons/types/DTO/bank/companyDetails/affiliatedCompany'
import { TableDataQa } from 'commons/types/enums'
import { handlerServerError } from 'errors'
import { DataSourceItem, Layer, Table } from 'ui/components'
import { HeaderButton } from 'ui/components/Buttons'
import { PageSection, PageSectionFace } from 'ui/components/Page'

import { ManageAffiliatedCompany } from '../ManageAffiliatedCompany/ManageAffiliatedCompany'
import { AffiliatedCompaniesTableProps } from './AffiliatedCompaniesTableProps'
import { columns } from './columns'
import { useComponentEssence } from './hooks'
import { useFilterCompanyAffiliatedCompaniesWebPage } from './hooks/useFilterCompanyAffiliatedCompaniesWebPage'

export const AffiliatedCompaniesTable: FC<AffiliatedCompaniesTableProps> = memo(
  ({ companyId, initialAffiliatedCompanies, initialAffiliatedCompaniesTotalCount }) => {
    const [webPageData, setWebPageData] = useState({
      affiliatedCompanies: initialAffiliatedCompanies,
      affiliatedCompaniesTotalCount: initialAffiliatedCompaniesTotalCount
    })

    useEffect(() => {
      setWebPageData({
        affiliatedCompanies: initialAffiliatedCompanies,
        affiliatedCompaniesTotalCount: initialAffiliatedCompaniesTotalCount
      })
    }, [initialAffiliatedCompanies, initialAffiliatedCompaniesTotalCount])

    const { routine: filterCompanyAffiliatedCompaniesWebPage } = useFilterCompanyAffiliatedCompaniesWebPage({
      onSuccess: async (_, result) => {
        await setWebPageData(result)
      },
      onError: (error) => handlerServerError(error)
    })

    const {
      tableState,
      loading,
      changePagination,
      DeleteConfirmation,
      openDeleteConfirmation,
      manageData: { relation, closeManageDialog, onSubmit, isLoading, openAddDialog, openEditDialog }
    } = useComponentEssence(
      companyId,
      filterCompanyAffiliatedCompaniesWebPage,
      webPageData.affiliatedCompaniesTotalCount
    )

    const memoizedColumns = useMemo(() => columns(companyId, openEditDialog, openDeleteConfirmation), [webPageData])

    const mappedAffiliatedCompanies: DataSourceItem<AffiliatedCompany>[] = useMemo(
      () => webPageData.affiliatedCompanies.map((el) => ({ item: el })),
      [webPageData.affiliatedCompanies]
    )

    return (
      <>
        <ManageAffiliatedCompany
          initialValue={relation}
          onClose={closeManageDialog}
          onSubmit={onSubmit}
          isSubmitting={isLoading}
        />
        <DeleteConfirmation />
        <PageSection
          face={PageSectionFace.PRIMARY}
          title="Affiliated Companies"
          id="affiliated"
          actions={
            <HeaderButton size="middle" onClick={openAddDialog}>
              Add New
            </HeaderButton>
          }
        >
          <Layer rounded shadowed stretch>
            <Table<AffiliatedCompany>
              dataQa={TableDataQa.BP_COMPANY_AFFILIATED_COMPANIES}
              dataSource={mappedAffiliatedCompanies}
              columns={memoizedColumns}
              loading={loading}
              pagination={{
                page: tableState.page,
                pageSize: tableState.size,
                onChange: changePagination,
                total: webPageData.affiliatedCompaniesTotalCount
              }}
            />
          </Layer>
        </PageSection>
      </>
    )
  }
)
