import styled, { css } from 'styled-components'
import { Required } from 'ui/core/Required'
import { CSS } from 'ui/helpers'

import { InputIconPosition } from '../types'

export const RequiredStyled = styled(Required)``

export const IconWrapper = styled.span<{
  $position?: InputIconPosition
  $error?: boolean
  $disabled?: boolean
}>`
  align-items: center;
  display: flex;
  padding: 0;
  ${(p) =>
    p.$position === InputIconPosition.RIGHT_FAR
      ? css`
          padding-right: 13px;
        `
      : p.$position === InputIconPosition.RIGHT_CLOSE
      ? css`
          padding-right: 12px;
        `
      : css`
          padding-left: 12px;
        `}

  color: ${(p) => p.theme.uiKit.colors.global.grey.grey900};

  transition: ${CSS.populateTransition(['color'])};

  svg {
    transition: ${CSS.populateTransition(['color', 'fill'])};
  }

  ${(p) =>
    p.$error
      ? css`
          color: ${(p) => p.theme.uiKit.colors.theme.negative.main};

          svg {
            color: ${(p) => p.theme.uiKit.colors.theme.negative.main};
            fill: ${(p) => p.theme.uiKit.colors.theme.negative.main};
          }
        `
      : null}

  ${(p) =>
    p.$disabled
      ? css`
          color: ${(p) => p.theme.uiKit.colors.global.grey.grey300};

          svg {
            color: ${(p) => p.theme.uiKit.colors.global.grey.grey300};
            fill: ${(p) => p.theme.uiKit.colors.global.grey.grey300};
          }
        `
      : null}
`

export const Wrapper = styled.span<{ $error?: boolean; $disabled?: boolean }>`
  position: relative;
  display: inline-block;
  height: 40px;
  min-width: 160px;
  width: 100%;

  ${(p) => CSS.populateFont(p.theme.uiKit.typography.bodyL.regular)};

  border: 1px solid ${(p) => p.theme.uiKit.colors.global.grey.grey100};
  border-radius: 4px;

  background-color: ${(p) => p.theme.uiKit.colors.global.grey.grey100};

  transition: ${CSS.populateTransition(['border-color', 'background-color'])};

  &:hover,
  &:focus-within {
    border: 1px solid ${(p) => p.theme.uiKit.colors.global.grey.grey500};
  }

  &:focus-within {
    ${IconWrapper} {
      ${(p) =>
        !p.$disabled && !p.$error
          ? css`
              color: ${(p) => p.theme.uiKit.colors.theme.brand.lighter};

              svg {
                color: ${(p) => p.theme.uiKit.colors.theme.brand.lighter};
                fill: ${(p) => p.theme.uiKit.colors.theme.brand.lighter};
              }
            `
          : null}
    }
    ${RequiredStyled} {
      opacity: 0;
    }
  }

  ${(p) =>
    p.$error
      ? css`
          &,
          &:hover,
          &:focus-within {
            border-color: ${(p) => p.theme.uiKit.colors.theme.negative.main};
            background-color: ${(p) => p.theme.uiKit.colors.theme.negative.transparent.opacity12};
          }
        `
      : null}

  ${(p) =>
    p.$disabled
      ? css`
          cursor: not-allowed;
          &,
          &:hover,
          &:focus-within {
            border-color: ${(p) => p.theme.uiKit.colors.global.grey.grey300};
            background-color: ${(p) => p.theme.uiKit.colors.global.general.main};
          }
        `
      : null}
`

export const Input = styled.input<{ disabled?: boolean }>`
  border: none;
  outline: none;
  background: none;

  height: 100%;
  width: 100%;

  padding: 0 14px;

  font: inherit;

  ${(p) =>
    p.disabled
      ? css`
          cursor: not-allowed;
          color: ${(p) => p.theme.uiKit.colors.global.grey.grey700};
          -webkit-text-fill-color: ${(p) => p.theme.uiKit.colors.global.grey.grey700};
          opacity: 1;
        `
      : null}
`

export const InputAndIconWrapper = styled.span<{ $position?: InputIconPosition }>`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  flex-direction: ${(p) => (p.$position === InputIconPosition.LEFT_CLOSE ? 'row-reverse' : 'row')};
`
