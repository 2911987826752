import React, { FC, memo } from 'react'

import { ButtonGroupProps } from './ButtonGroupProps'

import * as SC from './styles'

export const ButtonGroup: FC<ButtonGroupProps> = memo((props) => {
  const { children, margin, ...rest } = props

  return (
    <SC.Group margin={margin} {...rest}>
      {children}
    </SC.Group>
  )
})
