import 'react-datepicker/dist/react-datepicker.css'

import styled from 'styled-components'
import { CSS } from 'ui/helpers'

export const MonthPickerWrapper = styled.div`
  .react-datepicker {
    background-color: ${(p) => p.theme.uiKit.colors.global.general.main};
    box-shadow: ${(p) => CSS.populateBoxShadow(p.theme.uiKit.boxShadows.offset.offset80Y6)};
    border: none;
    border-radius: 12px;

    &__month {
      margin: 20px;

      &-text {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 112px;
        height: 46px;
        border-radius: 4px;
        ${(p) => CSS.populateFont(p.theme.uiKit.typography.h5.regular)}

        &--today {
          ${(p) => CSS.populateFont(p.theme.uiKit.typography.h5.semiBold)}

          text-decoration: underline;
          text-underline-offset: 4px;
        }

        &:hover {
          background: ${(p) => p.theme.uiKit.colors.global.grey.grey100};
        }

        &--keyboard-selected {
          background: ${(p) => p.theme.uiKit.colors.theme.brand.transparent.opacity60};

          &:hover {
            background: ${(p) => p.theme.uiKit.colors.theme.brand.transparent.opacity60} !important;
          }
        }
      }

      &-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }

    &__header {
      background-color: ${(p) => p.theme.uiKit.colors.global.general.main};
      border-bottom: none;
      padding: 20px 20px 0;
    }

    &__triangle {
      display: none;
    }
  }
`

export const MonthPickerHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const MonthPickerHeaderButton = styled.button`
  background-color: ${(p) => p.theme.uiKit.colors.global.general.main};
  border: none;
  width: 24px;
  height: 24px;

  svg {
    path {
      fill: #000;
    }
  }
`

export const MonthPickerHeaderDate = styled.div`
  ${(p) => CSS.populateFont(p.theme.uiKit.typography.h5.semiBold)}
`

export const MonthPickerIconWrapper = styled.div`
  position: absolute;
  right: 8px;
  top: 7px;

  svg {
    path {
      fill: ${(p) => p.theme.uiKit.colors.global.grey.grey500};
    }
  }
`

export const MonthPickerInputWrapper = styled.div`
  position: relative;
`

export const MonthPickerInput = styled.input`
  position: relative;
  display: inline-block;
  height: 40px;
  min-width: 160px;
  width: 100%;
  padding: 0 14px;

  ${(p) => CSS.populateFont(p.theme.uiKit.typography.bodyL.regular)};

  border: 1px solid ${(p) => p.theme.uiKit.colors.global.grey.grey100};
  border-radius: 4px;
  background-color: ${(p) => p.theme.uiKit.colors.global.grey.grey100};
  transition: ${CSS.populateTransition(['border-color', 'background-color'])};

  &:hover,
  &:focus-within {
    border: 1px solid ${(p) => p.theme.uiKit.colors.global.grey.grey500};
  }

  &:focus {
    + ${MonthPickerIconWrapper} {
      svg {
        path {
          fill: ${(p) => p.theme.uiKit.colors.theme.brand.lighter};
        }
      }
    }
  }
`
