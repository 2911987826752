import { ReactNode } from 'react'

export interface FileSelectorDialogProps {
  title: ReactNode
  onClose: () => void
  initialFiles: File[]
  submitButtonText: ReactNode
  onSubmit: (files: File[]) => void
  showDialogAfterConfirm?: boolean
  showSpinner?: boolean
}
