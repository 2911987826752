import React, { FC, memo } from 'react'

import { useForm } from '@tanstack/react-form'
import { getRouteApi } from '@tanstack/react-router'
import { Toast, handleFormSubmissionError } from 'ui/components'
import { InvoiceSubmittalPaths } from '~bank-invoices/constants'
import { useSaveInvoiceSubmittal } from '~bank-invoices/service/saveInvoiceSubmittal'
import { ManageInvoiceSubmittalFormType } from '~bank-invoices/types/Manage'

import { ManageInvoicePage } from '../../components/ManageInvoicePage'

const routeApi = getRouteApi(InvoiceSubmittalPaths.ADD)

const CreateInvoice: FC = memo(() => {
  const navigate = routeApi.useNavigate()

  const { licenseId, licenseName, returnUrl } = routeApi.useSearch()

  const appearanceInfo = { licenseId, licenseName }

  const formApi = useForm({
    defaultValues: new ManageInvoiceSubmittalFormType(true),
    onSubmit: async ({ value }) => await saveInvoiceSubmittal({ ...value, licenseId })
  })

  const { routine: saveInvoiceSubmittal } = useSaveInvoiceSubmittal({
    onSuccess: async (_, response) => {
      await navigate({ to: InvoiceSubmittalPaths.DETAILS, params: { id: response?.id } })
      Toast.successSmall('Invoice created')
    },
    onError: (error) => {
      handleFormSubmissionError<ManageInvoiceSubmittalFormType>(error, formApi)
    }
  })

  const onCancel = () => {
    if (returnUrl) {
      navigate({ to: returnUrl })
    } else {
      navigate({ to: '/licenses/$licenseId', params: { licenseId: licenseId } })
    }
  }

  return (
    <ManageInvoicePage
      pageTitle="New"
      panelTitle="Add New Invoice Submittal Detail"
      formApi={formApi}
      appearanceInfo={appearanceInfo}
      onCancel={onCancel}
    />
  )
})

export default CreateInvoice
