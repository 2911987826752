import React from 'react'

import { ButtonGroup } from 'ui/components/ButtonGroup'
import { Button, IconButton } from 'ui/components/Buttons'
import { IconName, Link } from 'ui/components/Link'

export interface ActionsProps {
  id?: number
  editUrl: string
  deleteCallback?: (id: number) => void
  isStart?: boolean
  edit?: boolean
  onApprove?: () => void
  onRecalculate?: (id: number) => void
}

export const AnnualReviewActions: React.FC<ActionsProps> = ({
  id,
  editUrl,
  deleteCallback,
  isStart,
  edit,
  onApprove,
  onRecalculate
}: ActionsProps) => (
  <ButtonGroup margin="big">
    {deleteCallback && (
      <IconButton face="negative" icon={IconName.DELETE} onClick={() => id && deleteCallback(id)}>
        Delete
      </IconButton>
    )}
    <Link to={editUrl}>
      <Button face={onApprove ? 'neutral' : 'positive'}>{edit ? 'Edit' : isStart ? 'Start' : 'Continue'}</Button>
    </Link>
    {!!onApprove && (
      <Button face="positive" onClick={onApprove}>
        Approve
      </Button>
    )}
    {!!onRecalculate && (
      <Button face="positive" onClick={() => id && onRecalculate(id)}>
        Recalculate
      </Button>
    )}
  </ButtonGroup>
)
