import { CompanyPhysicalAddress } from 'commons/types/DTO/commons'

import { CompanyPhysicalAddressFormValue } from '../../../../types'

export const mapCompanyPhysicalAddressForm = ({
  street,
  city,
  state,
  country,
  postalCode
}: CompanyPhysicalAddressFormValue): CompanyPhysicalAddress => ({
  street,
  city,
  state,
  country,
  postalCode
})
