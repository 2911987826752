import { ButtonHTMLAttributes } from 'react'

import { IconName } from 'ui/components/Icon'

import { OutlineButtonFace } from './OutlineButtonFace'
import { OutlineButtonSize } from './OutlineButtonSize'

export interface OutlineButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  face: OutlineButtonFace
  size?: OutlineButtonSize
  icon?: IconName

  'data-qa'?: string
}
