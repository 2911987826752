import styled from 'styled-components'

export const SuccessIndicatorToast = styled.div<{ $isSuccess: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 56px;
  width: 492px;
  gap: 0px 8px;

  box-sizing: border-box;
  padding: 16px;
  margin: 24px 72px 12px 32px;

  border-radius: ${(p) => p.theme.uiKit.borderRadius.roundedModal};
  border: 1px dashed
    ${(p) => (p.$isSuccess ? p.theme.uiKit.colors.theme.brand.primary : p.theme.uiKit.colors.theme.negative.main)};
  background-color: ${(p) =>
    p.$isSuccess
      ? p.theme.uiKit.colors.theme.brand.transparent.opacity12
      : p.theme.uiKit.colors.theme.negative.transparent.opacity12};

  span {
    color: ${(p) =>
      p.$isSuccess ? p.theme.uiKit.colors.theme.brand.primary : p.theme.uiKit.colors.theme.negative.main};
    font-weight: ${(p) => p.theme.uiKit.fontWeights['600']};
    font-size: ${(p) => p.theme.uiKit.fontSize.h5};
    line-height: ${(p) => p.theme.uiKit.lineHeights.l};
    letter-spacing: ${(p) => p.theme.uiKit.letterSpacing.s};
  }
`
