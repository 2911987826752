import {
  BankSourceCreateFormValue,
  SalesDataClientSourceCancelTaskPayload,
  SalesDataClientSourceRejectPayload,
  SalesDataClientSourceSendTaskPayload,
  SalesDataSourceSaveInternalNotesPayload,
  SalesDataSourceSaveRejectionReasonPayload,
  SalesDataSourceWebPage,
  SalesDataSourceWebPagePayload
} from 'commons/types/DTO/bank/salesDataSourceWebPage'
import { Request } from 'commons/utils/request'

export const updateBankSource = (body: BankSourceCreateFormValue) =>
  Request.post(Request.urls.bank.salesDataBankSource, body)

export const deleteBankSource = (id: number) => Request.delete(Request.urls.bank.salesDataBankSource, { id })

export const salesDataClientSourceReject = (payload: SalesDataClientSourceRejectPayload) =>
  Request.put(Request.urls.bank.salesDataClientSourceReject, { ...payload })

export const salesDataClientSourceApprove = (salesDataSourceId: number) =>
  Request.put(Request.urls.bank.salesDataClientSourceApprove, { salesDataSourceId })

export const sourceFileUpload = (form: FormData) => Request.post(Request.urls.bank.salesDataSourceFileUpload, form)

export const sourceFileDelete = (id: number) => Request.delete(Request.urls.bank.salesDataSourceFile, { id })

export const getSalesDataSourceWebPage = (payload: SalesDataSourceWebPagePayload) =>
  Request.post<SalesDataSourceWebPage>(Request.urls.bank.salesDataSourceWebPage, { ...payload })

export const salesDataSourceSaveInternalNotes = (payload: SalesDataSourceSaveInternalNotesPayload) =>
  Request.put(Request.urls.bank.salesDataSourceSaveInternalNotes, { ...payload })

export const salesDataSourceSaveRejectionReason = (payload: SalesDataSourceSaveRejectionReasonPayload) =>
  Request.put(Request.urls.bank.salesDataSourceSaveRejectionReason, { ...payload })

export const salesDataClientSourceTaskCancellation = (payload: SalesDataClientSourceCancelTaskPayload) =>
  Request.post(Request.urls.bank.salesDataClientSourceTaskCancellation, { ...payload })

export const salesDataClientSourceTaskSending = (payload: SalesDataClientSourceSendTaskPayload) =>
  Request.post(Request.urls.bank.salesDataClientSourceTaskSending, { ...payload })
