import styled from 'styled-components'

export const Box = styled.footer`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${(p) => p.theme.uiKit.colors.global.general.main};
  height: 66px;
  display: flex;
  align-items: center;
  padding: 0 48px 0 272px;
  border-top: 1px solid ${(p) => p.theme.uiKit.colors.global.grey.grey300};
`
