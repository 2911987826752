import React, { FC, memo } from 'react'

import { DOCUMENTS_PATH } from 'commons/constants/routes'

import { Hr } from '../../../../Hr'
import { PageSection, PageSectionFace } from '../../../../Page'
import { DocumentsDueProps } from './DocumentsDueProps'
import { AlertedItem } from './components/AlertedItem'

export const DocumentsDue: FC<DocumentsDueProps> = memo((props) => {
  const {
    documentsFirstNotificationCount,
    documentsPastNotificationCount,
    documentsLastNotificationCount,
    documentsPreNotificationCount,
    documentsIncompleteCount,
    initialAlert,
    firstAlert,
    lastAlert
  } = props

  return (
    <>
      <Hr />
      <PageSection title="Documents Due" face={PageSectionFace.SECONDARY} anchor="documents-due">
        <AlertedItem
          past
          count={documentsPastNotificationCount}
          daysDue={0}
          url={`${DOCUMENTS_PATH}?alerts=past-due`}
        />
        <AlertedItem
          past={false}
          count={documentsLastNotificationCount}
          daysDue={lastAlert}
          url={`${DOCUMENTS_PATH}?alerts=last-alert`}
        />
        <AlertedItem
          past={false}
          count={documentsFirstNotificationCount}
          daysDue={firstAlert}
          url={`${DOCUMENTS_PATH}?alerts=first-alert`}
        />
        <AlertedItem
          past={false}
          count={documentsPreNotificationCount}
          daysDue={initialAlert}
          url={`${DOCUMENTS_PATH}?alerts=initial-alert`}
        />
        <AlertedItem
          past={false}
          count={documentsIncompleteCount}
          incomplete={true}
          url={`${DOCUMENTS_PATH}?alerts=incomplete`}
        />
      </PageSection>
    </>
  )
})
