import React, { FC, memo } from 'react'

import { Link } from 'ui/components'

import { ResourcePathProps } from './ResourcePathProps'

export const ResourcePath: FC<ResourcePathProps> = memo((props) => {
  const { finishedPath, name, id } = props

  if (id === 0) {
    return <>{name}</>
  }

  return <Link to={finishedPath}>{name}</Link>
})
