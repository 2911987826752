import { useCallback } from 'react'

import { FormChangedHandler, useFormData } from 'brief-form'

import { useSyncFormData } from '../../hooks'
import { QuestionnaireChangeHandler, QuestionnaireFormData, QuestionnaireFormValue } from '../../types/forms'

type CompanyInformationHookReturnType = {
  companyIndex: number
  formData: QuestionnaireFormData
  onChange: QuestionnaireChangeHandler
}

export const useQuestionnaireForm = ({ companyIndex, formData, onChange }: CompanyInformationHookReturnType) => {
  const onFormChanged: FormChangedHandler<QuestionnaireFormValue> = useCallback(
    (formValues, formErrors) => onChange({ companyIndex, formData: { formValues, formErrors } }),
    [onChange, companyIndex]
  )

  const { config, Field, set } = useFormData<QuestionnaireFormValue>({
    onFormChanged,
    initialValue: formData.formValues,
    initialErrors: formData.formErrors
  })

  useSyncFormData({ set, formData })

  return { Field, config }
}
