import { CompanyFormValues, OnboardingFormState, OnboardingFormsData } from '../../../../types/state'
import { parseSubmitErrorPath } from '../parseSubmitErrorPath'
import { setNewCompanies } from '../setNewCompanies'
import { setNewCompanyFromErrors } from './setNewCompanyFromErrors'

export const generateNewStateFormValuesFromErrors = ({
  currentState,
  submitErrorsKeysValues
}: {
  currentState: OnboardingFormState
  submitErrorsKeysValues: [string, string[]][]
}): {
  companiesDiff: CompanyFormValues[]
  formValue: OnboardingFormsData
  generalErrors: Array<string>
  firstCompanyError: number | undefined
  lastErrorCount: number
} => {
  const result = submitErrorsKeysValues.reduce(
    (previosValue, currentValue: [string, string[]]) => {
      const [filedPath, errors] = currentValue
      const parsedPathInfo = parseSubmitErrorPath(filedPath)

      const { companyIndex, fieldName } = parsedPathInfo
      const oldCompany = previosValue.formValue.companiesFormValues[companyIndex]
      const newCompany = setNewCompanyFromErrors({
        oldCompany,
        parsedPathInfo,
        errors
      })

      const newCompaniesFormValues = setNewCompanies({
        oldCompanies: previosValue.formValue.companiesFormValues,
        companyIndex,
        newCompany
      })

      const newFormValues = {
        ...currentState.formValue,

        companiesFormValues: newCompaniesFormValues
      }

      let newFormErrors = previosValue.generalErrors
      let firstCompanyError = undefined
      if (!fieldName) {
        newFormErrors = [...newFormErrors, ...errors]
        if (!previosValue.firstCompanyError) {
          firstCompanyError = companyIndex
        }
      }

      return {
        companiesDiff: newCompaniesFormValues,
        formValue: newFormValues,
        generalErrors: newFormErrors,
        firstCompanyError
      }
    },
    {
      companiesDiff: currentState.companiesDiff,
      formValue: currentState.formValue,
      generalErrors: [], // reset previous errors
      firstCompanyError: currentState.firstCompanyError
    }
  )

  return { ...result, lastErrorCount: submitErrorsKeysValues.length - result.generalErrors.length }
}
