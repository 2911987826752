import SC from 'styled-components'

export const Box = SC.footer`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${(p) => p.theme.uiKit.colors.global.general.main};
  height: 66px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 48px 0 272px;
  border-top: 1px solid ${(p) => p.theme.uiKit.colors.global.grey.grey300};
`

export const Title = SC.span`
  display: flex;
  align-items: center;
  
  > span {
    display: inline-block;
    margin-left: 10px;
  }
`

export const Content = SC.div`
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`
