import { ApplicationSaveBankAccountsPayload } from 'commons/types/DTO/bank/applications/bankAccounts/bankAccounts'

import { ApplicationBankAccountsFormDataList } from '../../../types'
import { mapFormItemtoPayloadItemFn } from './mapFormItemtoPayloadItemFn'

export const mapBankAccountsFormValuesToSavePayload = ({
  applicationId,
  formsData,
  relationshipId,
  relationshipUId,
  withNew,
  isIsolated
}: {
  applicationId: number
  formsData: ApplicationBankAccountsFormDataList
  relationshipId: number
  relationshipUId: string
  withNew: boolean
  isIsolated: boolean
}): ApplicationSaveBankAccountsPayload => {
  const mapFormItemtoPayloadItem = mapFormItemtoPayloadItemFn({
    formsData,
    isIsolated
  })
  const formKeys = withNew ? Object.keys(formsData) : Object.keys(formsData).filter((key) => key !== 'new')
  const bankAccounts = formKeys.map(mapFormItemtoPayloadItem)

  return {
    id: applicationId,
    relationshipId,
    relationshipUId,
    bankAccounts
  }
}
