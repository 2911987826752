export class SalesDataSourceWebPagePayload {
  salesDataSourceID = 0
  uploadedFilesLimit = 0
  uploadedFilesOffset = 0
  uploadedFilesSortBy = ''
  uploadedFilesSortDesc = true
  historyOffset = 0
  historyLimit = 10

  constructor(salesDataSourceID?: number) {
    this.salesDataSourceID = salesDataSourceID || 0
  }
}
