/* eslint-disable camelcase */

// it's used in Annual Review details only (should be removed after the implementation of the webpage)
export type AffiliatedCompanyDeprecated = {
  child_company_id?: number
  created_at?: string
  deleted_at?: string
  id?: number
  notes?: string
  parent_company_id?: number
  sf_external_id?: string
  updated_at?: string
}
