import { LicenseWebPageDocumentFilter, LicenseWebPageResponse } from 'commons/types/DTO/bank'
import { TableSortingChangeHandler, TableSortingOrder } from 'ui/components/Table'

export interface LicenseDetailsPageProps {
  data: LicenseWebPageResponse
  invoicesTableConfig: {
    isFetching: boolean
    filter: {
      page: number
      size: number
    }
    onFilterChange: (page: number, size: number) => void
  }
  documentsTableConfig: {
    isFetching: boolean
    onFilterChange: (filter: LicenseWebPageDocumentFilter) => void
    order: TableSortingOrder[]
    onOrderChange: TableSortingChangeHandler
  }
  historyTableConfig: {
    isFetching: boolean
    filter: {
      page: number
      size: number
    }
    onFilterChange: (page: number, size: number) => void
  }
  onInvoiceDeleted: () => void
  onDocumentDeleted: (id: number) => void
}
