import React, { FC, memo } from 'react'

import { getRouteApi } from '@tanstack/react-router'
import { Form } from 'brief-form'
import { Icon, IconName, IconSize, Page, PageFace, Panel, Spinner, SpinnerSize } from 'ui/components'
import { Button } from 'ui/components/Buttons'
import { Footer } from 'ui/temp/Footer'
import { LicensesTitle } from 'ui/temp/LicensesTitle'

import { SalesDataSourceFileUpload } from '../../components/SalesDataSourceFileUpload'
import { SalesDataSourceForm } from '../../components/SalesDataSourceForm'
import { useBankSourceCreate } from './useBankSourceCreate'

import * as SC from './styles'

const routeApi = getRouteApi('/salesdata/bank-source-override/$mrId/$licenseId')

const BankSourceOverride: FC = memo(() => {
  const { mrId, licenseId } = routeApi.useParams()

  const {
    webPageData,
    licenses,
    config,
    Field,
    onCancel,
    openSubmitConfirm,
    SubmitConfirmation,
    isSubmitting,
    files,
    addFiles,
    deleteFile
  } = useBankSourceCreate(+mrId, +licenseId)

  if (!webPageData) {
    return <Spinner centered size={SpinnerSize.M} />
  }

  return (
    <Page
      face={PageFace.SECONDARY}
      subTitle="Manual Sales Data Override"
      footer={
        <Footer>
          <Button face="neutral" onClick={onCancel}>
            Cancel
          </Button>
          <Button loading={isSubmitting} onClick={openSubmitConfirm} face="positive">
            Save
          </Button>
        </Footer>
      }
    >
      <SubmitConfirmation />
      <Panel rounded shadowed collapsible={false} title="Add Sales Data">
        <SC.Box>
          <article>
            <h5>
              Sales Data for <strong>{webPageData.infoMonth}</strong>
            </h5>
            <h5>
              License{licenses.length > 1 ? 's' : ''}&nbsp;
              <strong>
                <LicensesTitle licenses={licenses} withUrl={false} />
              </strong>
            </h5>
          </article>
        </SC.Box>
        <SC.StyledHr />
        <SC.BoxSection>
          <p style={{ display: 'flex', alignItems: 'center' }}>
            <Icon name={IconName.INFO} size={IconSize.S} />
            &nbsp;&nbsp;Manual Override will be the primary Sales Data source
          </p>
          <br />
          <Form config={config}>
            <SalesDataSourceForm Field={Field} />
          </Form>
        </SC.BoxSection>
        <SC.StyledHr />
        <SC.BoxSection>
          <article>
            <h5>Upload Documents</h5>
            <p>
              Please upload a document showing sales for <strong>{webPageData.infoMonth}</strong>
            </p>
            <SalesDataSourceFileUpload
              files={files}
              addFiles={addFiles}
              deleteFile={deleteFile}
              deleteUploadedFile={() => {}}
              uploadedFiles={[]}
            />
          </article>
        </SC.BoxSection>
      </Panel>
    </Page>
  )
})

export default BankSourceOverride
