const statusMap: { [key: string]: string } = {
  incomplete: 'Incomplete',
  complete: 'Complete',
  pending_review: 'Pending Review',
  reviewed: 'Reviewed',
  new: 'New',
  completed: 'Completed',
  approved: 'Approved',
  processed: 'Processed',
  cancelled: 'Cancelled',
  pending_approval: 'Pending Approval',
  pending_upload: 'Pending Upload',
  rejected: 'Rejected'
}

export const getStatusLabel = (status: string): string | null => (status ? statusMap[status] : status)
