import styled from 'styled-components'
import { CSS } from 'ui/helpers'

export const RadioItemContainer = styled.label<{
  disabled: boolean
  error?: string
  readOnly?: boolean
}>`
  display: inline-flex;
  align-items: center;
  width: max-content;
  margin-bottom: 10px;
  margin-right: 25px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  user-select: none;
  position: relative;

  & input {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    cursor: pointer;
  }

  & input:enabled ~ .checkmark {
    background-color: ${(p) =>
      p.error ? p.theme.uiKit.colors.theme.negative.transparent.opacity12 : p.theme.uiKit.colors.global.grey.grey300};

    border-color: ${(p) =>
      p.error ? p.theme.uiKit.colors.theme.negative.main : p.theme.uiKit.colors.global.grey.grey300};
  }

  &:hover input:enabled:not(:disabled) ~ .checkmark {
    background-color: ${(p) =>
      p.error ? p.theme.uiKit.colors.theme.negative.transparent.opacity12 : p.theme.uiKit.colors.global.grey.grey300};
    border: ${(p) => p.theme.uiKit.borderWidth.strokeS} solid;
    border-color: ${(p) =>
      p.error ? p.theme.uiKit.colors.theme.negative.main : p.theme.uiKit.colors.global.grey.grey500};
    box-shadow: ${(p) => CSS.populateBoxShadow(p.theme.uiKit.boxShadows.offset.offset100Y4)};
  }

  &:hover input:checked:not(:disabled) ~ .checkmark {
    background-color: ${(p) =>
      p.error ? p.theme.uiKit.colors.theme.negative.transparent.opacity12 : p.theme.uiKit.colors.theme.brand.lighter};

    border: ${(p) => p.theme.uiKit.borderWidth.strokeS} solid;
    border-color: ${(p) =>
      p.error ? p.theme.uiKit.colors.theme.negative.main : p.theme.uiKit.colors.theme.brand.primary};
    box-shadow: ${(p) => CSS.populateBoxShadow(p.theme.uiKit.boxShadows.offset.offset100Y4)};
  }

  & input:checked:not(:disabled) ~ .checkmark {
    background-color: ${(p) =>
      p.error ? p.theme.uiKit.colors.theme.negative.transparent.opacity12 : p.theme.uiKit.colors.theme.brand.lighter};

    border-color: ${(p) =>
      p.error ? p.theme.uiKit.colors.theme.negative.main : p.theme.uiKit.colors.theme.brand.lighter};

    &:after {
      background: ${(p) =>
        p.error ? p.theme.uiKit.colors.theme.negative.main : p.theme.uiKit.colors.global.general.main};
    }
  }

  & input:disabled ~ .checkmark {
    border-color: ${(p) => p.theme.uiKit.colors.global.grey.grey500};

    &:after {
      background: ${(p) => p.theme.uiKit.colors.global.grey.grey300};
    }
  }

  & input:disabled:checked ~ .checkmark {
    background-color: ${(p) => p.theme.uiKit.colors.global.grey.grey500};
    box-shadow: ${(p) => CSS.populateBoxShadow(p.theme.uiKit.boxShadows.none.shadowNone)};
  }

  & input:disabled:not(:checked) ~ .checkmark {
    background-color: ${(p) => p.theme.uiKit.colors.global.grey.grey500};
    box-shadow: ${(p) => CSS.populateBoxShadow(p.theme.uiKit.boxShadows.none.shadowNone)};
    border-color: ${(p) => p.theme.uiKit.colors.global.grey.grey500};
  }

  & input:checked ~ .checkmark:after {
    display: block;
  }

  &:focus-within input ~ .checkmark {
    box-shadow: ${(p) => CSS.populateBoxShadow(p.theme.uiKit.boxShadows.offset.offset100Y4)};
    border: ${(p) => p.theme.uiKit.borderWidth.strokeS} solid;
    border-color: ${(p) => p.theme.uiKit.colors.theme.brand.primary};
  }
`

export const Checkmark = styled.span<{ disabled: boolean; error?: string }>`
  position: relative;
  height: 16px;
  width: 16px;
  background-color: ${(p) => p.theme.uiKit.colors.global.grey.grey300};
  border-radius: 50%;
  margin-right: 10px;
  border: ${(p) => p.theme.uiKit.borderWidth.strokeS} solid;
  border-color: ${(p) => p.theme.uiKit.colors.global.grey.grey300};

  transition: border-color 0.15s, background-color 0.15s, box-shadow 0.15s;

  &:after {
    content: '';
    position: absolute;
    display: none;

    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: ${(p) => p.theme.uiKit.colors.global.general.main};
  }
`

export const Label = styled.div<{ disabled: boolean; error?: string; readOnly?: boolean }>`
  ${(p) => CSS.populateFont(p.theme.uiKit.typography.bodyL.regular)}
  color: ${(p) => {
    if (p.readOnly) {
      return p.theme.uiKit.colors.global.general.secondary
    } else if (p.disabled) {
      return p.theme.uiKit.colors.global.grey.grey700
    } else if (p.error) {
      return p.theme.uiKit.colors.theme.negative.main
    } else {
      return p.theme.uiKit.colors.global.general.secondary
    }
  }};
`
