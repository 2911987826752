import { useMutation, useQueryClient } from 'react-query'

import { Request } from 'commons/utils/request'
import { handlerServerError } from 'errors'

export const useRecalculateAnnualReviewSalesDeposits = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (tableId: number) => Request.put(Request.urls.bank.annualReviewSalesDepositsRecalculation, { id: tableId }),
    {
      onError: (error) => handlerServerError(error),
      onSuccess: () => {
        queryClient.invalidateQueries('annual-review-sales-deposits')
      }
    }
  )
}
