/* eslint-disable camelcase */
import { AnnualReviewSalesDepositsQuarterInfo } from './annualReviewSalesDepositsQuarterInfo'

export type AnnualReviewSalesDeposits = {
  annual_review_id?: number
  bank_account_id?: number
  chart_s3_key?: string
  created_at?: string
  deleted_at?: string
  id?: number
  license_id?: number
  quarter1_info?: AnnualReviewSalesDepositsQuarterInfo
  quarter2_info?: AnnualReviewSalesDepositsQuarterInfo
  quarter3_info?: AnnualReviewSalesDepositsQuarterInfo
  quarter4_info?: AnnualReviewSalesDepositsQuarterInfo
  quarters_all_info?: AnnualReviewSalesDepositsQuarterInfo
  sf_external_id?: string
  status?: string
  total_financials?: number
  updated_at?: string
}
