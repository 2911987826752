import { LicenseManageRequest } from 'commons/types/DTO/bank'
import isInteger from 'lodash/isInteger'

const percentToNumber = (value: unknown): number | undefined => {
  if (isInteger(value)) {
    return value as number
  }

  if (typeof value === 'string') {
    const newValue = parseInt(value.split('$')[0], 10)
    if (isInteger(newValue)) {
      return newValue
    }
  }

  return undefined
}

export const transformEditLicenseFormValues = (value: Partial<LicenseManageRequest>): Partial<LicenseManageRequest> => {
  const newValue = { ...value }
  newValue.cityTax = percentToNumber(value.cityTax)
  newValue.mjRetailTax = percentToNumber(value.mjRetailTax)
  newValue.specialTax = percentToNumber(value.specialTax)
  newValue.countyTax = percentToNumber(value.countyTax)
  newValue.stateTax = percentToNumber(value.stateTax)

  return newValue
}
