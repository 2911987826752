import React from 'react'

import { financialInstitutionRoles } from 'commons/types/dictionaries'
import { FF, Select } from 'ui/components'

import { useSARFormContext } from '../../../../../../../context'

const EMPTY_OPTION = { label: 'Not selected', value: '' }

export const RoleInTransaction = ({ index1, index2 }: { index1: number; index2: number }) => {
  const { formApi } = useSARFormContext()
  const noBranchInvolved = formApi.useStore((s) => s.values.institution_activities_info[index1].no_branch_involved)

  return (
    <formApi.Field
      name={
        `institution_activities_info[${index1}].institution_activity_branches_info[${index2}].role_in_transaction` as 'institution_activities_info[0].institution_activity_branches_info[0].role_in_transaction'
      }
      children={(field) => (
        <FF
          field={field}
          label="68. Branch's role in transaction"
          input={(p) => (
            <Select {...p} data={financialInstitutionRoles} emptyOption={EMPTY_OPTION} disabled={noBranchInvolved} />
          )}
        />
      )}
    />
  )
}
