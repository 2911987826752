import React, { FC, memo } from 'react'

import { Page, PageFace } from 'ui/components/Page'

import { ManageLicensePageProps } from './ManageLicensePageProps'
import { ManageLicenseForm } from './components/ManageLicenseForm'
import { Footer } from './components/ManageLicenseForm/components/Footer'
import { useBehavior } from './components/ManageLicenseForm/hooks'

import * as SC from './styles'

export const ManageLicensePage: FC<ManageLicensePageProps> = memo((props) => {
  const { license, onDelete, onSave, onCancel, isSaving, companyName } = props
  const { Field, config, deleteLicense, saveLicense, Confirmation } = useBehavior(license, onDelete, onSave)

  return (
    <Page
      face={PageFace.SECONDARY}
      title="License Page"
      subTitle={license.id ? license.name : 'Create New License'}
      footer={
        <Footer
          onDelete={license.id ? deleteLicense : undefined}
          onSubmit={saveLicense}
          onCancel={onCancel}
          isSaving={isSaving}
        />
      }
    >
      <SC.PanelWithMargin
        rounded
        shadowed
        collapsible={false}
        title={`${license.id ? 'Edit' : 'Add New'} License Information`}
      >
        <ManageLicenseForm companyName={companyName} Confirmation={Confirmation} config={config} Field={Field} />
      </SC.PanelWithMargin>
    </Page>
  )
})
