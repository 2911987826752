export type LicenseManageRequest = {
  id: number | null
  name: string
  active: boolean
  companyId: number
  type: string
  licenseNumber: string
  subtype: string
  issueDate: string
  posType: string
  posTypeOther: string
  phone: string
  cityTax: number
  countyTax: number
  stateTax: number
  mjRetailTax: number
  specialTax: number
  streetAddress: string
  state: string
  city: string
  postalCode: string
}
