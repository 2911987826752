import { createRoute, redirect } from '@tanstack/react-router'
import { INTERNAL_TRANSFERS_PATH } from 'commons/constants/routes'

import { BankInternalTransfersWrapper } from './BankInternalTransfersWrapper'
import { internalTransferDetailsRoute } from './Details'
import { internalTransfersListRoute } from './List'

export const routeInternalTransfersTree = (rootRoute: BPRootRoute) => {
  const internalTransfersIndexRoute = createRoute({
    beforeLoad: async ({ context }) => {
      if (!context.accessibleRoutingFlags.internalTransferRootAccess) {
        throw redirect({
          to: '/'
        })
      }
    },
    getParentRoute: () => rootRoute,
    path: INTERNAL_TRANSFERS_PATH,
    component: BankInternalTransfersWrapper
  })

  return internalTransfersIndexRoute.addChildren([
    internalTransferDetailsRoute<typeof internalTransfersIndexRoute>(internalTransfersIndexRoute),
    internalTransfersListRoute<typeof internalTransfersIndexRoute>(internalTransfersIndexRoute)
  ])
}
