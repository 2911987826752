import { BsaSar } from 'commons/types/DTO/bank/bsa'
import { formatDateTime } from 'ui/components/DateTime'

export const dataSourceSelector = (data: any) =>
  (data?.list?.records || []).map((record: BsaSar) => {
    const start = formatDateTime(record?.trans_start_date)
    const end = formatDateTime(record?.trans_end_date)
    const company = (data?.companies?.records || []).find((i: any) => i.id === record.company_id)

    return {
      item: {
        id: record.id,
        period: `${start} - ${end}`,
        status: record.status,
        company,
        created_at: record.created_at,
        updated_at: record.updated_at,
        attention: record.attention,
        total: record.transactions_info?.total?.current || 0
      }
    }
  })
