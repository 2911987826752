import React from 'react'

import { ReviewFormUploadWrap } from '../ReviewFormUploadWrap'
import { MartinHoodReviewFormUploadProps } from './MartinHoodReviewFormUploadProps'

export const MartinHoodReviewFormUpload = ({ application }: MartinHoodReviewFormUploadProps) => (
  <ReviewFormUploadWrap
    application={application}
    title="Add the Martin Hood Review"
    type="Martin Hood Review"
    onlyNotes={false}
    requiredNotes={true}
    notes={application.reviews?.martinHood?.Notes || ''}
    buttonName="Add Review"
    fieldName="internal_review_mhcpa"
  />
)
