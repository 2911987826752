import React, { FC, memo, useEffect, useMemo, useState } from 'react'

import { useNavigate } from '@tanstack/react-router'
import { BankAccount } from 'commons/types/DTO/bank/companyDetails/bankAccount'
import { TableDataQa } from 'commons/types/enums'
import { handlerServerError } from 'errors'
import { DataSourceItem, Layer, PageSection, PageSectionFace, Table } from 'ui/components'
import { HeaderButton } from 'ui/components/Buttons'

import { BANK_ACCOUNTS_PATH } from '@orbit/bank-accounts'

import { CompanyBankAccountsTableProps } from './CompanyBankAccountsTableProps'
import { columns } from './columns'
import { useComponentsEssence } from './hooks/useComponentsEssence'
import { useFilterCompanyBankAccountsWebPage } from './hooks/useFilterCompanyBankAccountsWebPage'

export const CompanyBankAccountsTable: FC<CompanyBankAccountsTableProps> = memo(
  ({ companyId, initialBankAccounts, initialBankAccountsTotalCount }) => {
    const navigate = useNavigate()

    const [webPageData, setWebPageData] = useState({
      bankAccounts: initialBankAccounts,
      bankAccountsTotalCount: initialBankAccountsTotalCount
    })

    useEffect(() => {
      setWebPageData({
        bankAccounts: initialBankAccounts,
        bankAccountsTotalCount: initialBankAccountsTotalCount
      })
    }, [initialBankAccounts, initialBankAccountsTotalCount])

    const { routine: filterCompanyBankAccountsWebPage } = useFilterCompanyBankAccountsWebPage({
      onSuccess: async (_, result) => {
        await setWebPageData(result)
      },
      onError: (error) => handlerServerError(error)
    })

    const { changePagination, loading, tableState, startDeleting, Confirmation } = useComponentsEssence(
      companyId,
      filterCompanyBankAccountsWebPage,
      webPageData.bankAccountsTotalCount
    )

    const memoizedColumns = useMemo(() => columns(startDeleting), [startDeleting])

    const mappedBankAccounts: DataSourceItem<BankAccount>[] = useMemo(
      () =>
        webPageData.bankAccounts.map((el) => ({
          item: el
        })),
      [webPageData]
    )

    return (
      <PageSection
        face={PageSectionFace.PRIMARY}
        title="Bank Accounts"
        anchor="bank-accounts"
        actions={
          <HeaderButton
            size="middle"
            onClick={() =>
              navigate({
                to: `${BANK_ACCOUNTS_PATH}/new?companyId=${companyId}&returnUrl=${
                  window.location.pathname + window.location.hash
                }`
              })
            }
          >
            Add New
          </HeaderButton>
        }
      >
        <Confirmation />
        <Layer rounded shadowed stretch>
          <Table
            dataQa={TableDataQa.BP_COMPANY_BANK_ACCOUNTS}
            columns={memoizedColumns}
            dataSource={mappedBankAccounts}
            loading={loading}
            pagination={{
              page: tableState.page,
              pageSize: tableState.size,
              onChange: changePagination,
              total: webPageData.bankAccountsTotalCount
            }}
          />
        </Layer>
      </PageSection>
    )
  }
)
