import React from 'react'
import { useQueryClient } from 'react-query'

import { Form, useFormData } from 'brief-form'
import { BsaPeriodEditRequest } from 'commons/types/DTO/bank/bsa'
import { handlerServerError } from 'errors'
import { DatePicker, Dialog, DialogFace, DialogFooterActions, Toast, Validators } from 'ui/components'
import { useConfirmation } from 'ui/hooks'
import { SAR } from '~bank-bsa-reporting/hooks'

import * as SC from './styles'

type Props = {
  id: number
  initialValue: Omit<BsaPeriodEditRequest, 'id'>
  onClose: () => void
}

export const EditPeriodDialog = ({ id, initialValue, onClose }: Props) => {
  const { config, Field, validate } = useFormData<Omit<BsaPeriodEditRequest, 'id'>>({
    initialValue
  })

  const queryClient = useQueryClient()
  const editPeriodMutation = SAR.useEditPeriod()

  const { open: startEditing, Confirmation } = useConfirmation({
    message: 'The SAR will be recalculated based on the chosen period. Do you wish to continue?',
    onConfirm: () => {
      editPeriodMutation
        .mutateAsync({ ...config.value, id })
        .then(async () => {
          Toast.successSmall(`SAR period was successfully changed.`)
          await queryClient.invalidateQueries(['sars', id])
          await queryClient.invalidateQueries(['tables', 'SARs List'])
          await queryClient.invalidateQueries(['tables', 'sar', 'main'])
          onClose()
        })
        .catch((error) => handlerServerError(error, { form: config }))
    },
    confirmationButtonText: 'Continue',
    isConfirmationNegative: false
  })

  return (
    <Dialog
      id="edit-sar-period"
      title="Edit SAR Period"
      face={DialogFace.PRIMARY}
      onClose={onClose}
      onSubmit={() => {
        if (validate({ updateFields: true }).valid) startEditing()
      }}
      isSubmitting={editPeriodMutation.isLoading}
      footerActions={[DialogFooterActions.SAVE, DialogFooterActions.CANCEL]}
    >
      <Confirmation />
      <SC.EditPeriodDialogInner>
        <Form config={config} disabled={editPeriodMutation.isLoading}>
          <Field
            name="startDate"
            label="Transactions Start Date"
            input={DatePicker}
            validator={Validators.required.field}
            inputProps={{
              'data-qa': 'Start Date'
            }}
          />
          <Field
            name="endDate"
            label="Transactions End Date"
            input={DatePicker}
            validator={Validators.required.field}
            inputProps={{
              'data-qa': 'End Date'
            }}
          />
        </Form>
      </SC.EditPeriodDialogInner>
    </Dialog>
  )
}
