import { RefObject, useCallback, useState } from 'react'

export const useOpenHandler = (ref?: RefObject<HTMLElement>, onOpen?: () => void) => {
  const [open, setOpen] = useState(false)

  const handleOpen = useCallback(() => {
    setOpen(true)
    if (onOpen) {
      onOpen()
    }
  }, [setOpen, onOpen])

  const handleClose = useCallback(() => {
    setOpen(false)
    if (ref && ref.current) {
      ref.current.focus()
    }
  }, [setOpen, ref])

  return {
    open,
    handleOpen,
    handleClose
  }
}
