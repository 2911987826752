import React, { FC, memo, useMemo } from 'react'

import { TableDataQa } from 'commons/types/enums'
import { Table, TableColumn } from 'ui/components'

import { DocumentsTableProps } from './DocumentsTableProps'
import { columns } from './columns'

export const DocumentsTable: FC<DocumentsTableProps> = memo((props) => {
  const { files, readonly, isLoading, isDeleting, onDelete } = props

  const mappedFiles = useMemo(
    () =>
      files.map((el) => ({
        item: el
      })),
    [files]
  )

  return (
    <Table
      dataQa={TableDataQa.ONBOARDING_FORM_DOCUMENTS_LIST}
      columns={columns(readonly, onDelete) as TableColumn<any>[]}
      dataSource={mappedFiles}
      loading={isLoading || isDeleting}
    />
  )
})
