import React, { FC, memo } from 'react'

import { ButtonGroup } from 'ui/components/ButtonGroup'

import { FooterProps } from './FooterProps'

import * as SC from './styles'

export const Footer: FC<FooterProps> = memo((props) => {
  const { children, childrenLeftSide } = props

  return (
    <SC.Box>
      <SC.LeftSideBox>{childrenLeftSide}</SC.LeftSideBox>
      <ButtonGroup margin="small">{children}</ButtonGroup>
    </SC.Box>
  )
})
