import { useMutation, useQueryClient } from 'react-query'

import { Request } from 'commons/utils/request'
import { handlerServerError } from 'errors'

export const deletePublication = (id: number) =>
  Request.put(Request.urls.bank.adverseMediaCaseClearUnreviewedPublications, { id })

export const useAdverseMediaDeletePublications = () => {
  const client = useQueryClient()

  return useMutation((id: number) => deletePublication(id), {
    onError: (error) => handlerServerError(error),
    onSuccess: async () => {
      await client.invalidateQueries(['publications'])
    }
  })
}
