import { AnyRoute, createRoute, lazyRouteComponent } from '@tanstack/react-router'

export const annualReviewPrintRoute = <T extends AnyRoute>(indexRoute: T) =>
  createRoute({
    getParentRoute: () => indexRoute,
    path: 'print',
    component: lazyRouteComponent(() => import('./components/AnnualReviewPrint/AnnualReviewPrint'))
  })

export const annualReviewDetailsRoute = <T extends AnyRoute>(indexRoute: T) => {
  const route = createRoute({
    getParentRoute: () => indexRoute,
    path: 'detail/$companyId/$id'
  })

  const details = createRoute({
    getParentRoute: () => route,
    path: '/',
    component: lazyRouteComponent(() => import('./AnnualReviewDetail'))
  })

  return route.addChildren([annualReviewPrintRoute<typeof route>(route), details])
}
