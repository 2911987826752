import styled from 'styled-components'
import { Icon } from 'ui/components/Icon'
import { Panel } from 'ui/components/Panel'

export const Title = styled.span`
  font-weight: normal;
  font-size: 26px;
  line-height: 26px;
  margin: 19px 0px;
  display: flex;
  align-items: center;

  > a {
    color: ${(p) => p.theme.uiKit.colors.global.grey.grey500};
    text-decoration: none;
    font-size: 26px;
    line-height: 26px;
  }
`

export const Arrow = styled(Icon)`
  margin: 0 20px;
  opacity: 0.3;
  position: relative;
  top: -5px;
  line-height: 26px;
  font-size: 26px;
`

export const TablePanel = styled(Panel)`
  margin-bottom: 70px;
`
