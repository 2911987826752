import React, { FC, memo, useMemo } from 'react'

import { CreateModeSelectDialog } from 'bank-contacts/src/components'
import { Contact } from 'commons/types/DTO/bank/companyDetails/contact'
import { TableDataQa } from 'commons/types/enums'
import { DataSourceItem, Layer, PageSection, PageSectionFace, Table } from 'ui/components'
import { ButtonGroup } from 'ui/components/ButtonGroup'
import { HeaderButton } from 'ui/components/Buttons'

import { CompanyContactsTableProps } from './CompanyContactsTableProps'
import { columns } from './columns'
import { Filter } from './components'
import { useComponentsEssence } from './hooks/useComponentsEssence'

export const CompanyContactsTable: FC<CompanyContactsTableProps> = memo(
  ({ companyId, initialContacts, initialContactsTotalCount, contactsTypeValues }) => {
    const {
      webPageData,
      isFetching,
      tableState,
      filterForm,
      changePagination,
      DeleteConfirmation,
      onDeleteContact,
      openedAddModeDialog,
      setOpenedAddModelDialog,
      onSubmitChooseAddModeDialog,
      clearFilter,
      onEditContactMetaData
    } = useComponentsEssence(companyId, initialContacts, initialContactsTotalCount)

    const mappedContacts: DataSourceItem<Contact>[] = useMemo(
      () =>
        webPageData.contacts.map((el) => ({
          item: el
        })),
      [webPageData]
    )

    const memoizedColumns = useMemo(() => columns(onDeleteContact, onEditContactMetaData), [])

    return (
      <PageSection
        face={PageSectionFace.PRIMARY}
        title="Contacts"
        anchor="contacts"
        actions={
          <ButtonGroup margin="small">
            <HeaderButton size="middle" onClick={() => setOpenedAddModelDialog(true)}>
              Add New
            </HeaderButton>
          </ButtonGroup>
        }
      >
        <DeleteConfirmation />
        <CreateModeSelectDialog
          isOpen={openedAddModeDialog}
          onClose={() => setOpenedAddModelDialog(false)}
          onSubmit={onSubmitChooseAddModeDialog}
        />
        <Layer rounded shadowed stretch>
          <Filter
            form={filterForm}
            expanded={tableState.expanded}
            onToggle={tableState.onFilterToggle}
            contactsTypeValues={contactsTypeValues}
            onClear={clearFilter}
          />
          <Table<Contact>
            dataQa={TableDataQa.BP_COMPANY_CONTACTS}
            columns={memoizedColumns}
            dataSource={mappedContacts}
            loading={isFetching}
            pagination={{
              page: tableState.page,
              pageSize: tableState.size,
              onChange: changePagination,
              total: webPageData.contactsTotalCount
            }}
          />
        </Layer>
      </PageSection>
    )
  }
)
