import { useCallback, useMemo, useState } from 'react'

import { FilterCompanyAnnualReviewWebPage } from 'commons/service/bank/companyDetails'
import { AnnualReview } from 'commons/types/DTO/bank/companyDetails/annualReview'
import { handlerServerError } from 'errors'
import { Toast, useTableState } from 'ui/components'
import { useConfirmation } from 'ui/hooks'

import { useDeleteAnnualReviewOnWebpage } from './useDeleteAnnualReviewOnWebpage'

export const useComponentsEssence = (
  companyId: number,
  filterCompanyAnnualReviewWebPage: (filter: FilterCompanyAnnualReviewWebPage) => void,
  annualReviewsTotalCount: number
) => {
  const [loading, setLoading] = useState(false)

  const defaultFilter = useMemo(
    () => ({
      relationshipID: companyId,
      annualReviewsOffset: undefined,
      annualReviewsLimit: undefined
    }),
    []
  )

  const [tableFilter, setTableFilter] = useState(defaultFilter)

  const tableState = useTableState({ filter: {} })

  const changePagination = useCallback(
    async (page: number, size: number) => {
      setLoading(true)

      tableState.onPageChange(page, size)

      const filter = { ...tableFilter, annualReviewsOffset: (page - 1) * size, annualReviewsLimit: size }

      setTableFilter(filter)

      await filterCompanyAnnualReviewWebPage(filter)

      setLoading(false)
    },
    [filterCompanyAnnualReviewWebPage, tableFilter, tableState]
  )

  const getCompanyAnnualReviewAfterDeleteWebPage = () => {
    setLoading(true)

    const isDeletingAnnualReviewLastOnPage = annualReviewsTotalCount === (tableFilter.annualReviewsOffset || 0) + 1

    const offset = isDeletingAnnualReviewLastOnPage
      ? (tableFilter?.annualReviewsOffset || 0) - (tableFilter?.annualReviewsLimit || 0)
      : tableFilter.annualReviewsOffset

    filterCompanyAnnualReviewWebPage({
      ...tableFilter,
      annualReviewsOffset: offset
    })

    tableState.onPageChange(isDeletingAnnualReviewLastOnPage ? tableState.page - 1 : tableState.page, tableState.size)

    const filter = { ...tableFilter, annualReviewsOffset: offset }

    setTableFilter(filter)

    setLoading(false)
  }
  const [reviewForDelete, setReviewForDelete] = useState<AnnualReview | null>(null)

  const { routine: deleteMutation } = useDeleteAnnualReviewOnWebpage({
    onSuccess: async () => {
      await getCompanyAnnualReviewAfterDeleteWebPage()
    },
    onError: (error) => handlerServerError(error)
  })

  const deleteReview = useCallback(async () => {
    try {
      await deleteMutation({
        id: reviewForDelete?.id || 0
      })

      Toast.successSmall(`Annual Review "AR-${reviewForDelete?.id}" was successfully deleted.`)
    } catch (error) {
      handlerServerError(error)
    }
  }, [deleteMutation, reviewForDelete])

  const { Confirmation: DeleteConfirmation, open: openDeleteConfirmation } = useConfirmation({
    message: `Are you sure want to delete "AR-${reviewForDelete?.id}" Annual Review?`,
    onConfirm: deleteReview,
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  const onDeleteReview = (review: AnnualReview) => {
    setReviewForDelete(review)
    openDeleteConfirmation()
  }

  return {
    loading,
    changePagination,
    tableState,
    onDeleteReview,
    DeleteConfirmation,
    openDeleteConfirmation,
    deleteReview
  }
}
