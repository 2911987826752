import { AdverseMedia } from 'commons/types/DTO/bank/alerts/adverseMedia'
import { AnnualReviews } from 'commons/types/DTO/bank/alerts/annualReviews'
import { Applications } from 'commons/types/DTO/bank/alerts/applications'
import { BsaBatches } from 'commons/types/DTO/bank/alerts/bsaBatches'
import { Ctrs } from 'commons/types/DTO/bank/alerts/ctrs'
import { MonthlyAnalytics } from 'commons/types/DTO/bank/alerts/monthlyAnalytics'
import { QuarterlyAnalytics } from 'commons/types/DTO/bank/alerts/quarterlyAnalytics'
import { Sars } from 'commons/types/DTO/bank/alerts/sars'
import { Uploads } from 'commons/types/DTO/bank/alerts/uploads'

import { ChoiceOption } from '../../commons'
import { Config } from './config'

export type AlertsWebPage = {
  CTRs: Ctrs[]
  CTRsTotalCount: number
  SARs: Sars[]
  SARsTotalCount: number
  adverseMedia: AdverseMedia[]
  adverseMediaTotalCount: number
  annualReviewStatuses: ChoiceOption[]
  annualReviews: AnnualReviews[]
  annualReviewsTotalCount: number
  applications: Applications[]
  applicationsTotalCount: number
  bsaBatches: BsaBatches[]
  bsaBatchesTotalCount: number
  documentsDaysToFirstNotificationBeforeExpiration: number
  documentsDaysToLastNotificationBeforeExpiration: number
  documentsDaysToPreNotificationBeforeExpiration: number
  documentsFirstNotificationCount: number
  documentsIncompleteCount: number
  documentsLastNotificationCount: number
  documentsPastNotificationCount: number
  documentsPreNotificationCount: number
  monthlyAnalytics: MonthlyAnalytics[]
  monthlyAnalyticsTotalCount: number
  quarterlyAnalytics: QuarterlyAnalytics[]
  quarterlyAnalyticsTotalCount: number
  relationshipOptions: ChoiceOption[]
  uploadTypeOptions: ChoiceOption[]
  uploads: Uploads[]
  uploadsTotalCount: number
  config: Config
}
