import React, { FC, memo } from 'react'

import { useRouter } from '@tanstack/react-router'
import { ApplicationStatus } from 'commons/types/enums'
import { Button } from 'ui/components/Buttons'
import { Toast } from 'ui/components/Toast'
import { useConfirmation } from 'ui/hooks'

import { useGetOldApplication } from '../../../../../../hooks/useGetOldApplication'
import { useUpdateApplication } from '../../../../../../hooks/useUpdateApplication'
import { ApproveAllDocumentsProps } from './ApproveAllDocumentsProps'

export const ApproveAllDocuments: FC<ApproveAllDocumentsProps> = memo(({ application }) => {
  const router = useRouter()
  const { getOldApplication, isGetOldApplicationLoading } = useGetOldApplication()
  const { updateApplication, isUpdateApplicationLoading } = useUpdateApplication()

  const onApproveAllDocs = () => {
    // TODO: this is hot fix for tests
    // TODO: remove this after the backend creates separate endpoints
    const oldApplication = getOldApplication(application.applicationID)

    oldApplication.then((value) => {
      // TODO: fix this after backend creates separate endpoints
      const statusKey = Object.keys(ApplicationStatus).find(
        (name) => ApplicationStatus[name] === application.statusApproveAllDocuments
      )

      updateApplication({ ...value.records[0], status: ApplicationStatus[statusKey || ''] }).then(
        () => {
          Toast.success('All Documents has been approved')
          router.invalidate()
        },
        () => {
          Toast.error('A trouble was occurred while approving')
        }
      )
    })
  }

  const { Confirmation: ApproveAllDocsConfirmation, open: openApproveAllDocsConfirmation } = useConfirmation({
    message: <span>Are you sure want to approve all Client Documents?</span>,
    onConfirm: () => onApproveAllDocs(),
    confirmationButtonText: 'Approve'
  })

  return (
    <>
      <ApproveAllDocsConfirmation />
      <Button
        face="positive"
        onClick={openApproveAllDocsConfirmation}
        loading={isGetOldApplicationLoading || isUpdateApplicationLoading}
      >
        Approve All Documents
      </Button>
    </>
  )
})
