import React from 'react'

import { Props } from './Props'

import * as SC from './styles'

export const Required = (props: Props) => {
  const { isInputEmpty, ...rest } = props
  return <SC.Required $isEmpty={isInputEmpty} {...rest} />
}
