import React from 'react'

import { FieldApi } from '@tanstack/react-form'
import { BsaSar } from 'commons/types/DTO/bank/bsa'
import { Hr, IconName } from 'ui/components'
import { IconButton } from 'ui/components/Buttons'
import { useConfirmation } from 'ui/hooks'
import { Col, FormRow } from 'ui/themes/globalStyles'

import {
  Address,
  City,
  Country,
  DBA,
  FederalRegulator,
  FinancialInstitution,
  FinancialInstitutionRole,
  IDNumber,
  InstitutionActivityBranchesInfo,
  InternalControlNumber,
  LegalName,
  LossToFinInstitution,
  NoBranchInvolved,
  State,
  TIN,
  TINType,
  Type,
  TypeOther,
  ZipCode
} from './fields'

import { s } from './styles'

type InstitutionActivitiesItemProps = {
  index: number
  field: FieldApi<BsaSar, 'institution_activities_info'>
}

export const InstitutionActivitiesItem = (props: InstitutionActivitiesItemProps) => {
  const { index, field } = props

  const { open: openDeleteConfirmation, Confirmation } = useConfirmation({
    message: `Are you sure you want to remove this item?`,
    onConfirm: async () => {
      if (field && index !== -1) {
        await field.removeValue(index)
      }
    },
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  return (
    <>
      <Confirmation />
      <s.Item
        rounded
        shadowed
        key={index}
        collapsible={false}
        title={`Edit Part III Information about Institution(s) ${index + 1} of ${field.state.value.length}`}
        actions={
          field.state.value.length > 1 && (
            <IconButton face="negative" icon={IconName.DELETE} onClick={openDeleteConfirmation}>
              Remove
            </IconButton>
          )
        }
      >
        <s.Inner>
          <FormRow>
            <Col>
              <Type index={index} />
            </Col>
            <Col>
              <TypeOther index={index} />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <FederalRegulator index={index} />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <FinancialInstitution index={index} />
            </Col>
            <Col>
              <IDNumber index={index} />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <FinancialInstitutionRole index={index} />
            </Col>
            <Col>
              <LegalName index={index} />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <DBA index={index} />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <TIN index={index} />
            </Col>
            <Col>
              <TINType index={index} />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <Address index={index} />
            </Col>
            <Col>
              <City index={index} />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <State index={index} />
            </Col>
            <Col>
              <ZipCode index={index} />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <Country index={index} />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <InternalControlNumber index={index} />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <LossToFinInstitution index={index} />
            </Col>
          </FormRow>
          <Hr />
          <FormRow>
            <Col>
              <h5>Branch where activity occurred information</h5>
            </Col>
            <Col>
              <NoBranchInvolved index={index} />
            </Col>
          </FormRow>
          <InstitutionActivityBranchesInfo index={index} />
        </s.Inner>
      </s.Item>
    </>
  )
}
