import React, { FC, useMemo } from 'react'

import { Icon, IconName } from 'ui/components/Icon'

import { StepperProps } from './StepperProps'
import getSteps from './getSteps'

import * as SC from './styles'

export const Stepper: FC<StepperProps> = ({ step, availableSteps }) => {
  const steps = useMemo(() => getSteps({ step, availableSteps }), [step, availableSteps])

  return (
    <SC.StepsWrapper>
      <SC.StepperContainer>
        {steps.map((step, idx) => (
          <SC.StepConteiner key={idx}>
            <SC.Circle active={step.active} done={step.done}>
              <SC.StepNumber active={step.active}>{idx + 1}</SC.StepNumber>
            </SC.Circle>
            <SC.StepTitle active={step.active || step.done}>{step.title}</SC.StepTitle>

            <SC.StepLine
              direction={SC.StepDirection.LEFT}
              active={(step.active || step.done) && (steps[idx - 1]?.active || steps[idx - 1]?.done)}
              visiblity={idx !== 0}
            />
            <SC.StepLine
              direction={SC.StepDirection.RIGHT}
              active={(step.active || step.done) && (steps[idx + 1]?.active || steps[idx + 1]?.done)}
              visiblity={steps.length - 1 !== idx}
            >
              <SC.BankReviewIcon done={step.done}>
                <Icon name={IconName.UNION} />
                <div>Bank Review</div>
              </SC.BankReviewIcon>
            </SC.StepLine>
          </SC.StepConteiner>
        ))}
      </SC.StepperContainer>
    </SC.StepsWrapper>
  )
}
