import {
  FilterCompanyQuarterlyAnalyticsWebPage,
  getCompanyQuarterlyAnalyticsWebPage
} from 'commons/service/bank/companyDetails'
import { CompanyQuarterlyAnalyticsWebPage } from 'commons/types/DTO/bank/companyDetails/quarterlyAnalytics'
import { WriteHook, useMutationManager } from 'state-manager'

export const useFilterCompanyQuarterlyAnalyticsWebPage: WriteHook<
  FilterCompanyQuarterlyAnalyticsWebPage,
  CompanyQuarterlyAnalyticsWebPage
> = (opts) => useMutationManager(getCompanyQuarterlyAnalyticsWebPage, opts)
