import React, { memo } from 'react'

import IMask from 'imask'

import { BaseMaskedInput } from './BaseMaskedInput'
import { BaseInputProps } from './props'

export const USPhoneInput = memo((props: BaseInputProps) => {
  // ignore, coz do not know now what cause type exception, all works fine in runtime
  // @ts-ignore
  const mask = IMask.createMask({
    mask: '(#00) 000-0000',
    definitions: {
      '#': /[1-9]/
    },
    lazy: true
  })
  return <BaseMaskedInput {...props} mask={mask} />
})
