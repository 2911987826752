import { DifferenceRelative } from './DifferenceRelative'

export type ComparisonInfo = {
  differenceAbsolute: number
  differenceRelative: DifferenceRelative
  estimatedTaxes: number
  sales: number
  totalDeposits: number
  totalSales: number
}
