import styled from 'styled-components'

const InnerPanel = styled.div`
  padding: 30px 32px;
`

const Row = styled.div`
  display: flex;

  > * {
    width: 400px;

    &:first-child {
      margin-right: 100px;
    }
  }
`

export const s = { InnerPanel, Row }
