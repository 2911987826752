import styled from 'styled-components'

const Inner = styled.div`
  padding: 30px 35px;
`

const DateBox = styled.div`
  i {
    right: 9px;
    left: auto;

    &:first-of-type {
      right: 28px;
      left: auto;
    }
  }
  input {
    width: 250px;
  }
`

export const s = { DateBox, Inner }
