/* eslint-disable max-len */
import React from 'react'

import { CurrencyInput, FF, Validators } from 'ui/components'

import { useSARFormContext } from '../../../../../../context'

type ReturnedCheckOutProps = {
  index: number
}

export const ReturnedCheckOut = ({ index }: ReturnedCheckOutProps) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={
        `transactions_info.by_account[${index}].returned_checks_out` as 'transactions_info.by_account[0].returned_checks_out'
      }
      validators={{ onChange: ({ value }) => Validators.required.notEmpty(value) }}
      children={(field) => (
        <FF required field={field} label="Returned Checks Out" input={(p) => <CurrencyInput {...p} />} />
      )}
    />
  )
}
