import { setSmartFilter } from 'commons/service/bank/publication'
import { AdverseMediaSearchCase } from 'commons/types/DTO/bank'
import { WriteHook, useMutationManager } from 'state-manager'

export type UseSetSmartFilterOpts = {
  id: number
  smart: boolean
}

export const useSetSmartFilter: WriteHook<UseSetSmartFilterOpts, AdverseMediaSearchCase> = (opts) =>
  useMutationManager<UseSetSmartFilterOpts, AdverseMediaSearchCase>(setSmartFilter, opts)
