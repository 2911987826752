import { useCallback, useState } from 'react'
import { useQueryClient } from 'react-query'

import { PortalName } from 'commons/types/enums'
import { useChallengeExpiration, useCreateDevice, useResetChallenge, useSendSecurityCode } from '~auth/hooks/mfa'
import { AuthMFAState } from '~auth/types/DTO'

export const useCreation = (portal: PortalName) => {
  const [visible, setVisible] = useState(false)
  const open = useCallback(() => setVisible(true), [setVisible])
  const close = useCallback(() => setVisible(false), [setVisible])
  const deviceMutation = useCreateDevice(portal)
  const resetMutation = useResetChallenge()
  const securityCodeMutation = useSendSecurityCode(portal, true)
  const client = useQueryClient()
  const mfa = client.getQueryData<AuthMFAState>(['mfa'])

  // Start challenge expiration listener.
  useChallengeExpiration(false)

  const createDevice = useCallback(
    (value: { type: string; name: string }) =>
      deviceMutation.mutateAsync({
        type: value.type,
        name: value.name,
        token: ''
      }),
    [deviceMutation]
  )

  const resetChallenge = useCallback(() => {
    resetMutation.mutate()
  }, [resetMutation])

  const retryChallenge = useCallback(
    () =>
      deviceMutation.mutateAsync({
        type: mfa?.chosen?.type || '',
        name: mfa?.chosen?.name || '',
        token: ''
      }),
    [mfa]
  )

  const sendSecurityCode = useCallback(
    (code: string) =>
      securityCodeMutation.mutateAsync({
        code,
        challengeId: mfa?.challengeId || '',
        token: ''
      }),
    [securityCodeMutation, mfa]
  )

  return {
    resetChallenge,
    retryChallenge,
    createDevice,
    sendSecurityCode,
    challengeStartedAt: mfa?.challengeStartedAt || 0,
    totpUrl: mfa?.chosen?.totpUrl,
    isCreationDialogOpen: visible,
    openCreationDialog: open,
    closeCreationDialog: close
  }
}
