import { AnyRoute, createRoute, lazyRouteComponent } from '@tanstack/react-router'
import { BankApplicationsRouterPaths } from 'commons/constants/routes'

export const ListRoute = <T extends AnyRoute>(indexRoute: T) =>
  createRoute({
    getParentRoute: () => indexRoute,
    // /onboarding/applications
    path: BankApplicationsRouterPaths.LIST,
    component: lazyRouteComponent(() => import('./index'))
  })
