import { useCallback } from 'react'

import { ReportFile } from 'commons/types/DTO/bank'
import { handlerServerError } from 'errors'
import { WriteHook, useMutationManager, useStateManager } from 'state-manager'

import { UploadReportFileRequest, uploadReportFile } from './UploadReportFileRequest'

export const useUploadReportFileRequest: WriteHook<UploadReportFileRequest, ReportFile> = (opts) =>
  useMutationManager<UploadReportFileRequest, ReportFile>(uploadReportFile, opts)

export const useUploadReportFileCreate = (
  tableKey: string,
  invalidate?: () => Promise<void>,
  reportQuarterlyId?: number
) => {
  const sm = useStateManager()
  const queryClient = sm.__client__

  const { routine } = useUploadReportFileRequest({
    onSuccess: async () => {
      if (reportQuarterlyId) invalidate()
      else queryClient.invalidateQueries(['tables', `uploaded-files-${tableKey}`])
    },
    onError: (error) => handlerServerError(error)
  })

  const createReportFile = useCallback(
    (payload: UploadReportFileRequest) =>
      routine({
        file: payload.file,
        reportMonthlyId: payload.reportMonthlyId,
        reportQuarterlyId: payload.reportQuarterlyId
      }),
    [routine]
  )

  return {
    createReportFile
  }
}
