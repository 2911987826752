import { useCallback, useEffect, useMemo, useState } from 'react'

import { useNavigate, useRouterState } from '@tanstack/react-router'
import { CreateMetadataModeFormValue } from 'bank-contacts/src/types/forms'
import { useFormData } from 'brief-form'
import { FilterCompanyContactsWebPage } from 'commons/service/bank/companyDetails'
import { CompanyContactsWebPage, Contact } from 'commons/types/DTO/bank/companyDetails/contact'
import { handlerServerError } from 'errors'
import { Toast } from 'ui/components'
import { useTableState } from 'ui/components/QueryTable'
import { useConfirmation } from 'ui/hooks'

import { useDisconnectContactOnWebpage } from './useDisconnectContactOnWebPage'
import { useGetCompanyContactsWebPage } from './useGetCompanyContactsWebPage'

export const useComponentsEssence = (
  companyId: number,
  initialContacts: Contact[],
  initialContactsTotalCount: number
) => {
  const navigate = useNavigate()
  const { pathname } = useRouterState({ select: (s) => s.location })

  const defaultFilter = useMemo(
    () => ({
      relationshipID: companyId,
      contactsOffset: undefined,
      contactsLimit: undefined,
      contactsType: 'all'
    }),
    [companyId]
  )

  const [webPageData, setWebPageData] = useState<CompanyContactsWebPage>({
    contacts: initialContacts,
    contactsTotalCount: initialContactsTotalCount
  })

  const [tableFilter, setTableFilter] = useState<FilterCompanyContactsWebPage>(defaultFilter)

  useEffect(() => {
    setWebPageData({
      contacts: initialContacts,
      contactsTotalCount: initialContactsTotalCount
    })
  }, [initialContacts, initialContactsTotalCount])

  const { isFetched, isFetching, data, invalidate } = useGetCompanyContactsWebPage(tableFilter)

  useEffect(() => {
    if (isFetched) {
      setWebPageData(data)
    }
  }, [isFetched, data])

  const onEditContactMetaData = useCallback(
    (c) =>
      navigate({
        to: `/relationships/$companyId/contact-edit/$contactId`,
        params: { companyId: companyId, contactId: c.id }
      }),
    [navigate, pathname]
  )

  const tableState = useTableState({ filter: {} })

  const changePagination = useCallback(
    async (page: number, size: number) => {
      tableState.onPageChange(page, size)

      const newFilter = {
        ...tableFilter,
        contactsOffset: (page - 1) * size,
        contactsLimit: size
      }

      setTableFilter(newFilter)
    },
    [setTableFilter, tableFilter, tableState]
  )

  const onFilterChange = async (value: FilterCompanyContactsWebPage) => {
    tableState.filterForm.set({ value })
    tableState.onPageChange(1, tableState.size)

    const newFilter = {
      ...value,
      contactsLimit: undefined
    }

    setTableFilter(newFilter)
  }

  const filterForm = useFormData<FilterCompanyContactsWebPage>({
    initialValue: {
      relationshipID: companyId,
      contactsOffset: undefined,
      contactsLimit: undefined,
      contactsType: 'all'
    }
  })

  const originalChangeHandler = filterForm.config.onChange
  filterForm.config.onChange = useCallback(
    (v, e) => {
      onFilterChange(v)
      originalChangeHandler(v, e)
    },
    [originalChangeHandler, tableState]
  )

  const clearFilter = useCallback(() => {
    onFilterChange(defaultFilter)
    filterForm.set({ reset: true })
  }, [onFilterChange, filterForm])

  const getCompanyContactsAfterDeleteWebPage = () => {
    const isDeletingContactLastOnPage = webPageData.contactsTotalCount === (tableFilter.contactsOffset || 0) + 1

    const offset = isDeletingContactLastOnPage
      ? (tableFilter?.contactsOffset || 0) - (tableFilter?.contactsLimit || 0)
      : tableFilter.contactsOffset

    tableState.onPageChange(isDeletingContactLastOnPage ? tableState.page - 1 : tableState.page, tableState.size)

    const filter = { ...tableFilter, contactsOffset: offset }

    // if contact isn't last on the table page we just reload page
    // else change filter and it also pulls new request
    if (filter.contactsOffset === tableFilter.contactsOffset) invalidate()
    else setTableFilter(filter)
  }

  const [contactForDelete, setContactForDelete] = useState<Contact | undefined>(undefined)
  const { routine: disconnectMutation } = useDisconnectContactOnWebpage({
    onSuccess: async () => {
      getCompanyContactsAfterDeleteWebPage()
    },
    onError: (error) => handlerServerError(error)
  })

  const disconnectContact = useCallback(async () => {
    try {
      await disconnectMutation({
        id: contactForDelete?.id || 0,
        companyId: companyId
      })

      Toast.successSmall(`Contact and company were detached.`)
    } catch (error) {
      handlerServerError(error)
    }
  }, [disconnectMutation, contactForDelete])

  const { open: openDeleteConfirmation, Confirmation: DeleteConfirmation } = useConfirmation({
    message: `Are you sure want to disconnect ${contactForDelete?.name} contact from this company?`,
    onConfirm: disconnectContact,
    confirmationButtonText: 'Disconnect',
    isConfirmationNegative: true
  })

  const onDeleteContact = (contact: Contact | undefined) => {
    setContactForDelete(contact)
    openDeleteConfirmation()
  }

  const [openedAddModeDialog, setOpenedAddModelDialog] = useState(false)

  const onSubmitChooseAddModeDialog = useCallback(
    (data: CreateMetadataModeFormValue) => {
      setOpenedAddModelDialog(false)

      if (data.mode === 'new') {
        navigate({
          to: `/contacts/new`,
          search: { returnUrl: pathname + '/contact-edit/' },
          replace: true
        })
      } else {
        navigate({
          to: `/relationships/$companyId/contact-edit/$contactId`,
          params: { companyId: companyId, contactId: data.contact_id },
          replace: true
        })
      }
    },
    [navigate, pathname]
  )

  return {
    webPageData,
    isFetching,
    tableState,
    filterForm,
    changePagination,
    onDeleteContact,
    DeleteConfirmation,
    openDeleteConfirmation,
    disconnectContact,
    openedAddModeDialog,
    setOpenedAddModelDialog,
    onSubmitChooseAddModeDialog,
    clearFilter,
    onEditContactMetaData
  }
}
