import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'

import { BsaCIFSubject } from 'commons/types/DTO/bank/bsa'

import * as Service from '../../service'

// see https://stackoverflow.com/questions/76030996/how-to-use-debounce-with-usequery-in-react-query
const useDebounce = (value, delay) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay] // Only re-call effect if value or delay changes
  )

  return debouncedValue
}

export const useCifSubjectItem = (tin: string, enabled): { isFetching: boolean; item: BsaCIFSubject } => {
  const [searchParams] = useDebounce([tin], 1000)

  const { data, isFetching } = useQuery<any, Error>(
    ['cif-subject-list', ...searchParams],
    () => Service.CifSubject.getList({ _options: { filters: [{ field: 'tin', type: 'eq', value: tin }] } }),
    { keepPreviousData: true, enabled: enabled }
  )

  return {
    isFetching,
    item: data ? data.records[0] : null
  }
}
