import React, { FC, memo } from 'react'

import { Request } from 'commons/utils/request'

import { SingleSelectAutoSuggest } from '../SingleSelectAutoSuggest'
import { VendorSelectProps } from './VendorSelectProps'

export const VendorSelect: FC<VendorSelectProps> = memo((props) => {
  const { value, emptyOption, filters, ...rest } = props

  return (
    <SingleSelectAutoSuggest
      options={[emptyOption]}
      value={value}
      serverOptions={{
        filters,
        valueItemFilter: value ? { field: 'id', type: 'eq', value } : undefined,
        url: Request.urls.bank.vendorList,
        limit: 50,
        mapper: (item) => ({
          label: `${item.name}`.trim() || item.email || `Vendor #${item.id}`,
          value: item.id
        }),
        searchFields: ['name']
      }}
      {...rest}
    />
  )
})
