import React from 'react'

import { Col, FormRow } from 'ui/themes/globalStyles'

import { Address, City, Country, PostalCode, State } from './fields'

type AddressFieldsProps = {
  index: number
}

export const AddressFields = ({ index }: AddressFieldsProps) => {
  return (
    <>
      <FormRow>
        <Col>
          <h5>Subject Address Information</h5>
        </Col>
      </FormRow>
      <FormRow>
        <Col>
          <Address index={index} />
        </Col>
        <Col>
          <City index={index} />
        </Col>
      </FormRow>
      <FormRow>
        <Col>
          <State index={index} />
        </Col>
        <Col>
          <PostalCode index={index} />
        </Col>
      </FormRow>
      <FormRow>
        <Col>
          <Country index={index} />
        </Col>
      </FormRow>
    </>
  )
}
