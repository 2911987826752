import React, { FC, memo } from 'react'

import { DropDown } from '../../../DropDown'
import { Hr } from '../../../Hr'
import { IconSize } from '../../../Icon'
import { MenuItemAlignment } from './MenuItemAlignment'
import { MenuItemBehavior } from './MenuItemBehavior'
import { MenuItemTextFace } from './MenuItemTextFace'
import { MenuItemType } from './MenuItemType'
import { TableActionsProps } from './TableActionsProps'

import { ButtonItem, LinkItem, MenuItem, WrappedIcon } from './styles'

export const TableActions: FC<TableActionsProps> = memo((props) => {
  const { items, dataQaAttribute } = props

  if (!items.length) {
    throw new Error('"TableActions" component needs at least one menu item.')
  }

  if (items.length > 1) {
    return (
      <DropDown menuPosition={'bottom-end'} dotsButtonQaAttribute={dataQaAttribute || 'Table-menu-dots-button'}>
        {items.map(({ kind, behavior, title, onClick, url, iconName, textFace, key, textAlign, iconHoverFace }) => {
          if (kind === MenuItemType.DIVIDER) {
            return <Hr key={key} />
          }

          const iconBox = iconName ? <WrappedIcon name={iconName} size={IconSize.XS} /> : null
          const titleForQA = title ? title.toString().toUpperCase() : undefined

          return (
            <MenuItem key={key} align={textAlign || MenuItemAlignment.LEFT}>
              {behavior === MenuItemBehavior.BUTTON ? (
                <ButtonItem
                  face={textFace || MenuItemTextFace.BLACK}
                  align={textAlign || MenuItemAlignment.LEFT}
                  onClick={onClick}
                  $hoverFace={iconHoverFace}
                  data-qa={`table-action-${titleForQA}`}
                >
                  {iconBox}
                  <span>{title}</span>
                </ButtonItem>
              ) : (
                <LinkItem
                  face={textFace || MenuItemTextFace.BLACK}
                  align={textAlign || MenuItemAlignment.LEFT}
                  to={url || '#'}
                  $hoverFace={iconHoverFace}
                  data-qa={`table-action-${titleForQA}`}
                >
                  {iconBox}
                  <span>{title}</span>
                </LinkItem>
              )}
            </MenuItem>
          )
        })}
      </DropDown>
    )
  }

  const { behavior, textAlign, textFace, iconName, title, url, onClick } = items[0]
  const iconBox = iconName ? <WrappedIcon name={iconName} size={IconSize.XS} /> : null

  const titleForQA = title ? title.toString().toUpperCase() : undefined

  return behavior === 'button' ? (
    <ButtonItem
      face={textFace || MenuItemTextFace.BLACK}
      align={textAlign || MenuItemAlignment.LEFT}
      onClick={onClick}
      data-qa={dataQaAttribute || `table-action-${titleForQA}`}
    >
      {iconBox}
      <span>{title}</span>
    </ButtonItem>
  ) : (
    <LinkItem
      face={textFace || MenuItemTextFace.BLACK}
      align={textAlign || MenuItemAlignment.LEFT}
      to={url || '#'}
      data-qa={dataQaAttribute || `table-action-${titleForQA}`}
    >
      {iconBox}
      <span>{title}</span>
    </LinkItem>
  )
})
