import { useCallback } from 'react'
import { useMutation } from 'react-query'

import { addApplicationRelatedRecords as addApplicationRelatedRecordsService } from '../service'

type UseAddApplicationRelatedRecordsReturnValue = {
  addApplicationRelated: (id: number) => Promise<number>
  isAddApplicationRelatedLoading: boolean
}

export const useAddApplicationRelatedRecords = (): UseAddApplicationRelatedRecordsReturnValue => {
  const mutation = useMutation((id: number) => addApplicationRelatedRecordsService(id))

  const addApplicationRelated = useCallback((id: number) => mutation.mutateAsync(id), [mutation])

  return {
    addApplicationRelated,
    isAddApplicationRelatedLoading: mutation.isLoading
  }
}
