import React from 'react'

import { CheckBox, FF } from 'ui/components'

import { useSARFormContext } from '../../../../../../../context'

export const F = () => {
  const { formApi } = useSARFormContext()
  return (
    <formApi.Field
      name="transactions_info.fraud.f"
      children={(field) => (
        <FF
          field={field}
          input={(p) => <CheckBox {...p} checkBoxLabel="f. Credit/Debit card" />}
          errorPosition="right"
        />
      )}
    />
  )
}
