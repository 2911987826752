import { errorMessages } from './enums'

// TODO: delete this, as it has very weird use cases now.
export const getErrorsWithMeta = (error: any) => {
  const errors: string[] = []

  if (error.meta) {
    Object.keys(error.meta).forEach((key) => {
      errors.push(`${key}: ${error.meta[key]}`)
    })
  }

  return (errors.length ? errors.join('; ') : error.message) || errorMessages.SOMETHING_WENT_WRONT
}
