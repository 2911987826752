import SC from 'styled-components'

export const FormBox = SC.div`
  padding: 20px 30px 32px 30px;
  display: flex;
`

export const CompanyWrapper = SC.div`
  width: 230px;
  margin-right: 30px;
`

export const TypeWrapper = SC.div`
  width: 230px;
`
