import { AffiliatedCompanyDeprecated } from 'commons/types/DTO/bank'
import { Request } from 'commons/utils/request'
import uniq from 'lodash/uniq'

export const companyList = (id?: number) => Request.post(Request.urls.bank.companyList, { id })

export const affiliatedCompanyList = (companies: AffiliatedCompanyDeprecated[]) =>
  Request.post(Request.urls.bank.companyList, {
    _options: {
      filters: [
        {
          field: 'id',
          type: 'in',
          value: uniq(companies.map((i) => i.child_company_id).concat(companies.map((i) => i.parent_company_id)))
        }
      ]
    }
  })
