import { AvailableServicesConditions } from '../../types/state'
import { AvailableService } from './AvailableServicesFormProps'

export const getAvailableServicesList = ({
  isAvailServiceCheckingOperatingEnable,
  isAvailServiceCheckingPayrollEnable,
  isAvailServiceCheckingEnable,
  isBusinessSavingsEnable,
  isAvailServiceAchOriginationEnable,
  isAvailServiceMobileDepositEnable,
  isWireTransferEnable,
  isAvailServiceRollingCoinChangeOrderEnable,
  isAvailServiceRemoteDepositCaptureEnable,
  isAvailServiceEscrowEnable,
  isAvailServiceCreditCardEnable,
  isAvailServiceWireDomesticEnable,
  isAvailServiceWireDomesticOnlyEnable,
  isAvailServiceWireForeignEnable,
  isAvailServicePersonalCheckingEnable,
  isAvailServicePersonalSavingsEnable,
  isAnticipatedActivityAtmSettlementEnable
}: AvailableServicesConditions): AvailableService[] =>
  [
    isAvailServiceCheckingOperatingEnable
      ? {
          name: 'availServiceCheckingOperating',
          label: 'Checking - Operating',
          dataQa: 'Available Services - Checking - Operating'
        }
      : null,
    isAvailServiceCheckingPayrollEnable
      ? {
          name: 'availServiceCheckingPayroll',
          label: 'Checking - Payroll',
          dataQa: 'Checking - Payroll'
        }
      : null,
    isAvailServiceCheckingEnable
      ? {
          name: 'availServiceChecking',
          label: 'Business Checking',
          dataQa: 'Business Checking'
        }
      : null,
    {
      name: 'availServiceSavings',
      label: isBusinessSavingsEnable ? 'Business Savings' : 'Savings',
      dataQa: isBusinessSavingsEnable ? 'Business Savings' : 'Savings'
    },
    isAvailServiceAchOriginationEnable
      ? {
          name: 'availServiceACHOrigination',
          label: 'ACH Origination',
          dataQa: 'ACH Origination'
        }
      : null,
    isAvailServiceMobileDepositEnable
      ? {
          name: 'availServiceMobileDeposit',
          label: 'Mobile Deposit',
          dataQa: 'Mobile Deposit'
        }
      : null,
    isWireTransferEnable
      ? {
          name: 'availServiceWire',
          label: 'Wire Transfer',
          dataQa: 'Wire Transfer'
        }
      : null,
    isAvailServiceRollingCoinChangeOrderEnable
      ? {
          name: 'availServiceRollingCoinChangeOrder',
          label: 'Rolled Coin/Change Order',
          dataQa: 'Rolled Coin/Change Order'
        }
      : null,
    isAvailServiceRemoteDepositCaptureEnable
      ? {
          name: 'availServiceRemoteDepositCapture',
          label: 'Remote Deposit Capture',
          dataQa: 'Available Services - Remote Deposit Capture'
        }
      : null,
    isAvailServiceEscrowEnable
      ? {
          name: 'availServiceEscrow',
          label: 'Escrow',
          dataQa: 'Escrow'
        }
      : null,
    {
      name: 'availServiceOnlineMobileBanking',
      label: 'Online/Mobile Banking',
      dataQa: 'Online/Mobile Banking'
    },
    {
      name: 'availServiceBillPay',
      label: 'Bill Pay',
      dataQa: 'Bill Pay'
    },
    {
      name: 'availServiceDebitCard',
      label: 'Debit Card',
      dataQa: 'Debit Card'
    },
    isAvailServiceCreditCardEnable
      ? {
          name: 'availServiceCreditCard',
          label: 'Credit Card',
          dataQa: 'Credit Card'
        }
      : null,
    isAvailServiceWireDomesticEnable
      ? {
          name: 'availServiceWireDomestic',
          label: 'Wire Transfer - Domestic',
          dataQa: 'Wire Transfer - Domestic'
        }
      : null,
    isAvailServiceWireDomesticOnlyEnable
      ? {
          name: 'availServiceWireDomesticOnly',
          label: 'Wire Transfer - Domestic Only',
          dataQa: 'Wire Transfer - Domestic Only'
        }
      : null,
    isAvailServiceWireForeignEnable
      ? {
          name: 'availServiceWireForeign',
          label: 'Wire Transfer - Foreign',
          dataQa: 'Wire Transfer - Foreign'
        }
      : null,
    isAvailServicePersonalCheckingEnable
      ? {
          name: 'availServicePersonalChecking',
          label: 'Personal Checking',
          dataQa: 'Personal Checking'
        }
      : null,
    isAvailServicePersonalSavingsEnable
      ? {
          name: 'availServicePersonalSavings',
          label: 'Personal Savings',
          dataQa: 'Personal Savings'
        }
      : null,
    isAnticipatedActivityAtmSettlementEnable
      ? {
          name: 'availServiceATMSettlement',
          label: 'ATM Settlement',
          dataQa: 'ATM Settlement'
        }
      : null
  ].filter(Boolean)
