import { Contact } from 'commons/types/DTO/bank'

import { ContactRelatedCompanies } from '../../../../types'

export interface ContactCompaniesTableProps {
  contact: Contact
  onAdd: () => any
  onEdit: (item: ContactRelatedCompanies) => any
  onDetach: (contact: Contact, contactId: number, companyId: number) => any

  relatedCompanies: ContactRelatedCompanies[]
  contactId: number
}
