import SC, { css } from 'styled-components'
import { DownloadLink } from 'ui/components/DownloadLink'

const FilesWrapperCss = css`
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
`

const DownloadLinkCss = css`
  &:hover {
    color: #01c699 !important;
  }
`

export const FilesWrapper = SC.div`${FilesWrapperCss}`
export const DownloadLinkWithDecoration = SC(DownloadLink)`${DownloadLinkCss}`
