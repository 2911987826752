import { useQueryClient } from 'react-query'

import { useSaveContact as useSaveContactHook } from 'commons/hooks/bank/contact'
import { Contact } from 'commons/types/DTO/bank'
import { handlerServerError } from 'errors'
import { Keys, useStateManager } from 'state-manager'

import { CONTACT_DETAILS_WEBPAGE_KEY } from './useGetContactDetails'

type UseSaveContactParams = {
  onSuccess?: (payload: Contact, result: Contact) => Promise<void>
  onError?: (error: Error) => Promise<void>
}

export const useSaveContact = ({ onSuccess, onError }: UseSaveContactParams) => {
  const sm = useStateManager()
  const client = useQueryClient()

  const { routine: saveContact, ...rest } = useSaveContactHook({
    onSuccess: async (payloadContact, resultContact) => {
      if (onSuccess) {
        onSuccess(payloadContact, resultContact)
      }

      sm.invalidate(Keys.CompanyDetailsContactsList.List())
      await client.invalidateQueries(['tables', 'company-contacts'])
      sm.invalidate(CONTACT_DETAILS_WEBPAGE_KEY)
    },
    onError: (error) => {
      if (onError) {
        onError(error)
      }
      handlerServerError(error)
    }
  })

  return {
    saveContact,
    ...rest
  }
}

export const useSaveContactOnWebPage = (refreshWebpage: (() => Promise<void>) | undefined) => {
  const { routine: saveContactOnWebPage } = useSaveContactHook({
    onSuccess: async () => {
      if (refreshWebpage) refreshWebpage()
    },
    onError: (error) => handlerServerError(error)
  })

  return {
    saveContactOnWebPage
  }
}
