import { LicenseWebPageHistory } from 'commons/types/DTO/bank'

export interface HistoryProps {
  data: LicenseWebPageHistory
  config: {
    isFetching: boolean
    filter: {
      page: number
      size: number
    }
    onFilterChange: (page: number, size: number) => void
  }
}
