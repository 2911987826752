import { UseFormDataReturnType } from 'brief-form'
import {
  ContactListWebPageRequest,
  ContactListWebPageResponse,
  ContactListWebPageResponseItem
} from 'commons/types/contracts/api/bank/contactList'
import { TableState } from 'ui/components'

export interface ContactListPageProps {
  webPageData: ContactListWebPageResponse | undefined
  tableState: TableState<object>
  isFetching: boolean
  filterForm: UseFormDataReturnType<ContactListWebPageRequest, unknown>
  onSearch: (term: string) => void
  changePagination: (page: number, size: number) => Promise<void>
  DeleteConfirmation: () => JSX.Element | null
  startContactDelete: (item: ContactListWebPageResponseItem) => void
}
