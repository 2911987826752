import { useCallback, useMemo, useState } from 'react'

import { UseFormDataReturnType } from 'brief-form'
import { useCancelInternalTransfer, useGetInternalTransferList } from 'commons/hooks/bank/internalTransfer'
import { InternalTransferList } from 'commons/types/DTO/bank'
import { handlerServerError } from 'errors'
import { TableState } from 'query-table'
import { Keys, useStateManager } from 'state-manager'
import { DataSourceItem } from 'ui/components/Table'
import { Toast } from 'ui/components/Toast'
import { useConfirmation } from 'ui/hooks'

import { InternalTransferTableFilterValue } from '../../../types'

export const useInternalTransferTable = (
  { pagination }: TableState,
  { config: { value } }: UseFormDataReturnType<InternalTransferTableFilterValue>,
  items: InternalTransferList[],
  setLoading: (isLoading: boolean) => void
) => {
  const [selectedTransfer, setSelectedTransfer] = useState<null | InternalTransferList>(null)
  const [startProcess, setStartProcess] = useState(false)
  const sm = useStateManager()

  const mappedInternalTransfers: DataSourceItem<InternalTransferList>[] = useMemo(
    () =>
      items.map((el) => ({
        item: el
      })),
    [items]
  )

  const { routine: cancelInternalTransfer } = useCancelInternalTransfer({
    onSuccess: async (id) => {
      await sm.invalidate(
        Keys.InternalTransfer.ListByCriteria({
          internalTransferSize: pagination.size,
          internalTransferPage: pagination.page,
          internalTransferStatus: value.status,
          internalTransfersID: value.id,
          internalTransfersDateFrom: value.dateFrom,
          internalTransfersDateTo: value.dateTo,
          internalTransfersSenderCompanyID: value.senderCompanyID,
          internalTransfersSenderLicenseID: value.senderLicenseID,
          internalTransfersRecipientCompanyID: value.recipientCompanyID,
          internalTransfersRecipientLicenseID: value.recipientLicenseID
        })
      )
      Toast.successSmall(`IT-${id} was canceled.`)
      setLoading(false)
    },
    onError: (error) => {
      handlerServerError(error)
      setLoading(false)
    }
  })

  useGetInternalTransferList(
    {
      id: selectedTransfer?.id,
      enabled: startProcess
    },
    {
      onSuccess: async (data) => {
        await cancelInternalTransfer(data.records[0].id)
        setLoading(false)
        setStartProcess(false)
      },
      onError: (error) => {
        handlerServerError(error)
        setLoading(false)
        setStartProcess(false)
      }
    }
  )

  const { open: openCancelConfirmation, Confirmation: CancelConfirmation } = useConfirmation({
    message: `Are you sure you want to cancel ${selectedTransfer?.transferNumber}?`,
    onConfirm: () => {
      setLoading(true)
      setStartProcess(true)
    },
    confirmationButtonText: 'Yes',
    isConfirmationNegative: true
  })

  const startCancel = useCallback(
    (item: InternalTransferList) => {
      setSelectedTransfer(item)
      openCancelConfirmation()
    },
    [setSelectedTransfer, openCancelConfirmation]
  )

  return {
    mappedInternalTransfers,
    CancelConfirmation,
    startCancel
  }
}
