import React from 'react'

import { useDeleteOnboardingDocument } from 'commons/hooks/bank/onboardingSetupDocuments'
import { handlerServerError } from 'errors'
import { Toast } from 'ui/components'
import { useConfirmation } from 'ui/hooks'

export const useDeleteDocument = (id: number, onSuccess: () => void) => {
  const { routine: deleteOnboardingDocument } = useDeleteOnboardingDocument({
    onSuccess: async () => {
      onSuccess()
      Toast.successSmall(`The set of documents ID-${id} already deleted`)
    },
    onError: (error) => handlerServerError(error)
  })

  const { Confirmation: DeleteConfirmation, open: openDeleteConfirmation } = useConfirmation({
    message: <span>{`You are going to delete Onboarding Documents ID-${id}. Are you sure?`}</span>,
    onConfirm: () => deleteOnboardingDocument(id),
    confirmationButtonText: 'Confirm',
    isConfirmationNegative: true
  })

  return {
    DeleteConfirmation,
    openDeleteConfirmation
  }
}
