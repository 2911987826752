import { ApplicationRelationshipAddBankAccountsPayload } from 'commons/types/DTO/bank/applications'
import { ApiDate } from 'commons/types/base'

import { ApplicationBankAccountsFormDataList } from '../../../types'

export const mapFormItemtoPayloadItemFn =
  ({ formsData, isIsolated }: { formsData: ApplicationBankAccountsFormDataList; isIsolated: boolean }) =>
  (formName: string): ApplicationRelationshipAddBankAccountsPayload => {
    const formData = formsData[formName].value

    const id = formData.id || 0

    if (isIsolated) {
      return {
        id,
        accountNumber: formData.accountNumber,
        assignedLicensesUIds: formData.assignedLicenses as string[],
        openingDate: formData.openingDate as unknown as ApiDate
      }
    }

    return {
      id,
      accountNumber: formData.accountNumber,
      assignedLicenses: formData.assignedLicenses as number[],
      openingDate: formData.openingDate as unknown as ApiDate
    }
  }
