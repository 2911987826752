import React from 'react'

import { Validators } from 'ui/components/Form'
import { TextareaInput } from 'ui/components/InputV2'
import { Select } from 'ui/components/Select'
import { Col, Col2x, FormRow } from 'ui/themes/globalStyles'

import { MainText, SecondText } from '../../style'
import { RapidMovementOfFundsProps } from './RapidMovementOfFundsProps'

export const RapidMovementOfFunds = ({ formValue, Field }: RapidMovementOfFundsProps) => (
  <>
    <MainText>Rapid movement of funds, such as cash deposits followed by immediate cash withdrawals.</MainText>
    <SecondText>
      Review Quarterly Analytics for cash withdrawals. If withdrawals are found, check the date for immediate cash
      deposits prior to the withdrawal.
    </SecondText>
    <FormRow>
      <Col>
        <Field
          required
          name="reviewTransactionActivityRedFlag"
          label="Red Flag?"
          input={Select}
          inputProps={{
            data: [
              {
                label: 'Yes',
                value: 'yes'
              },
              {
                label: 'No',
                value: 'no'
              }
            ]
          }}
          validator={Validators.required.field}
        />
      </Col>
      <Col>
        <Field
          required
          name="reviewTransactionActivityImmediateDeposits"
          label="Were there immediate deposits before cash withdraws? If so, what type?"
          input={Select}
          inputProps={{
            data: [
              {
                label: 'No Transactions of this Type',
                value: 'no_transactions'
              },
              {
                label: 'Change orders',
                value: 'change_orders'
              },
              {
                label: 'Product purchases',
                value: 'product_purchases'
              },
              {
                label: 'Other - See Comments',
                value: 'other'
              }
            ]
          }}
          validator={Validators.required.field}
        />
      </Col>
    </FormRow>
    <FormRow>
      <Col2x>
        <Field
          required={formValue.reviewTransactionActivityImmediateDeposits === 'other'}
          name="reviewTransactionActivityComments"
          label="Comments"
          input={TextareaInput}
          inputProps={{}}
          validator={
            formValue.reviewTransactionActivityImmediateDeposits === 'other' ? Validators.required.field : undefined
          }
        />
      </Col2x>
    </FormRow>
  </>
)
