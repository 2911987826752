import React, { memo } from 'react'

import { HistoryTable } from 'bank-entity-history/src/components/HistoryTable'
import { TableDataQa } from 'commons/types/enums'
import { Dialog, DialogFace } from 'ui/components/Dialog'

import { HistoryProps } from './History.types'

export const History = memo((props: HistoryProps) => {
  const { batchId, items, onClose, isOpen, onChange, page, pageSize, total } = props

  if (!isOpen) {
    return null
  }

  return (
    <Dialog
      id={`batch-${batchId}-history`}
      onClose={onClose}
      face={DialogFace.PRIMARY}
      title={`Batch ID ${batchId}`}
      subTitle=" History"
    >
      <HistoryTable
        dataQa={TableDataQa.BP_BSA_REPORTING_BATCH_HISTORY}
        items={items}
        pagination={{ total, page, pageSize, onChange }}
      />
    </Dialog>
  )
})
