import React from 'react'

import { DateTime } from 'ui/components/DateTime'
import { LicenseTypeLabel } from 'ui/components/Labels'
import { TableColumn } from 'ui/components/Table'

import { AnnualReviewLicensingTableSelector } from './selector'

export const columns = () => (): TableColumn<AnnualReviewLicensingTableSelector>[] =>
  [
    {
      title: 'License Type',
      align: 'left',
      render: ({ type }) => <LicenseTypeLabel name={type} />
    },
    {
      title: 'Issue Date',
      align: 'center',
      render: ({ issue_date }) => issue_date && <DateTime date={issue_date} />
    },
    {
      title: 'Expiration Date',
      align: 'center',
      render: ({ expiration_date }) => expiration_date && <DateTime date={expiration_date} />
    },
    {
      title: 'Number',
      align: 'center',
      key: 'license_number'
    },
    {
      title: 'Location',
      align: 'center',
      key: 'street_address'
    }
  ]
