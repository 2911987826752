import React, { FC, memo } from 'react'

import { DocumentsDue } from './components/DocumentsDue'
import { DueDocumentsSectionsProps } from './interface'

export const DueDocumentsSection: FC<DueDocumentsSectionsProps> = memo((props) => {
  const {
    documentsLastNotificationCount,
    documentsFirstNotificationCount,
    documentsPreNotificationCount,
    documentsPastNotificationCount,
    documentsIncompleteCount,
    documentsDaysToFirstNotificationBeforeExpiration,
    documentsDaysToLastNotificationBeforeExpiration,
    documentsDaysToPreNotificationBeforeExpiration
  } = props

  return (
    <DocumentsDue
      documentsLastNotificationCount={documentsLastNotificationCount}
      documentsFirstNotificationCount={documentsFirstNotificationCount}
      documentsPreNotificationCount={documentsPreNotificationCount}
      documentsPastNotificationCount={documentsPastNotificationCount}
      documentsIncompleteCount={documentsIncompleteCount}
      initialAlert={documentsDaysToPreNotificationBeforeExpiration || 30}
      firstAlert={documentsDaysToFirstNotificationBeforeExpiration || 7}
      lastAlert={documentsDaysToLastNotificationBeforeExpiration || 3}
    />
  )
})
