import React, { FC, memo } from 'react'

import { Tooltip } from 'ui/components/Tooltip'

import { PermissionsProps } from './PermissionsProps'
import { Details } from './components/Details'

export const Permissions: FC<PermissionsProps> = memo((props) => {
  const { id, value } = props

  const topLevelLabel =
    value
      .filter((permission) => permission.enabled)
      .slice(0, 3)
      .map((i) => i.label)
      .join(', ') + (value.length > 3 ? '...' : '')

  return (
    <Tooltip
      id={`client-user-permissions-tooltip-${id}`}
      type="dark"
      place="right"
      content={<Details permissions={value.filter((permission) => permission.enabled)} />}
      event="hover"
    >
      <span>{topLevelLabel}</span>
    </Tooltip>
  )
})
