import React from 'react'

import { getRouteApi, useNavigate } from '@tanstack/react-router'
import { useCreateEnvelopeOnboardingDocuments } from 'commons/hooks/bank/onboardingSetupDocuments'
import { OnboardingDocumentEditModeWebPage } from 'commons/types/DTO/bank/applications/onboardingDocuments'

import { ManageContainer } from '../../containers/ManageContainer'

const routeApi = getRouteApi('/application/$applicationId/onboarding-documents/$id/clone')

const SetupDocumentsClone = () => {
  const navigate = useNavigate()

  const id = routeApi.useParams({ select: (s) => s.id })
  const applicationId = routeApi.useParams({ select: (s) => s.applicationId })
  const webPageData: OnboardingDocumentEditModeWebPage = routeApi.useLoaderData({ select: (s) => s.webPageData })

  const { routine: create } = useCreateEnvelopeOnboardingDocuments({
    onSuccess: () =>
      navigate({
        from: '/application/$applicationId/onboarding-documents/$id/clone',
        to: 'onboarding/applications/$applicationId',
        params: applicationId
      })
  })

  const onSubmit = (form) => create({ ...form, applicationId: Number(applicationId) })

  return (
    <ManageContainer
      webPageData={webPageData}
      pageTitle={`Onboarding Document ID-${id}`}
      pageSubTitle="Clone Onboarding Documents"
      onSubmitForm={onSubmit}
      footerSubmitButtonText="Clone"
    />
  )
}

export default SetupDocumentsClone
