import { useCallback } from 'react'
import { useMutation } from 'react-query'

import { AddReview } from 'commons/types/DTO/bank/applications/manage/Reviews/addReview'
import { Application } from 'commons/types/DTO/commons'

import { addReview as addReviewService } from '../service'

type UseAddReviewReturnValue = {
  addReview: (review: AddReview) => Promise<Application>
}

export const useAddReview = (): UseAddReviewReturnValue => {
  const mutation = useMutation((review: AddReview) => addReviewService(review))

  const addReview = useCallback((review: AddReview) => mutation.mutateAsync(review), [mutation])

  return {
    addReview
  }
}
