import React, { FC } from 'react'

import * as SC from '../../styles'
import { OwnerProps } from './OwnerProps'

export const Owner: FC<OwnerProps> = ({ contact }) => {
  const { isCompanyOwner } = contact

  return (
    <>
      <SC.LabelValue>
        <div>
          Is this contact the <b>Owner</b>?
        </div>
        <SC.Value>{isCompanyOwner ? 'Yes' : 'No'}</SC.Value>
      </SC.LabelValue>
    </>
  )
}
