import styled from 'styled-components'

export const Title = styled.div`
  font-size: 18px;
  line-height: 26px;
  width: 480px;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Content = styled.div`
  padding: 28px 32px 32px 32px;
`
