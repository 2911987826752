import styled from 'styled-components'

import { Spinner } from '../../../../Spinner'
import { PageFace } from '../../pageFace'

export const Box = styled.div<{ $face?: PageFace; $noPadding?: boolean }>`
  overflow: visible;
  position: relative;
  flex: 1;
  padding: ${(p) => !p.$noPadding && (p.$face === PageFace.PRIMARY ? '10px 50px 34px 50px' : '20px 50px 34px 50px')};
`

export const Inner = styled.div<{ isPending?: boolean }>`
  display: ${(p) => (p.isPending ? 'none' : 'block')};
`

export const WrappedSpinner = styled(Spinner)`
  position: relative;
  z-index: 1000;
  margin-top: 1px;
`
