import { FieldProps, FormConfig, FormInputProps } from 'brief-form'

import { MappedAffiliatedCompany } from '../../../AffiliatedCompaniesTable/MappedAffiliatedCompany'

export interface ManageAffiliatedCompanyFormProps {
  config: FormConfig<MappedAffiliatedCompany>
  Field: <Input extends React.ComponentType<FormInputProps<any, any>>>(
    props: FieldProps<Input, MappedAffiliatedCompany>
  ) => JSX.Element
  validateAffiliated: (v: number | string, f: MappedAffiliatedCompany) => string | undefined
}
