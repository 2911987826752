import { useCallback } from 'react'
import { useMutation } from 'react-query'

import { addApplicationDocumentUpload as addApplicationDocumentUploadService } from '../service'

type UseAddApplicationDocumentUploadReturnValue = {
  addApplicationDocumentUpload: (formData: FormData) => Promise<number>
}

export const useAddApplicationDocumentUpload = (): UseAddApplicationDocumentUploadReturnValue => {
  const mutation = useMutation((formData: FormData) => addApplicationDocumentUploadService(formData))

  const addApplicationDocumentUpload = useCallback((formData: FormData) => mutation.mutateAsync(formData), [mutation])

  return {
    addApplicationDocumentUpload
  }
}
