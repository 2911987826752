import * as React from 'react'

import { fakeLinkDownload } from 'commons/utils'
import { Request } from 'commons/utils/request'
import SC from 'styled-components'

import { Button, ButtonFace } from '../Button'
import { IconName } from '../Icon'

interface Properties {
  baseUrl: string
  parametersForm: any
  handleError: (error: string) => void
  children?: React.ReactNode
  className?: string
  direct?: boolean
  name?: string
  mimeType?: string
  withIcon?: boolean
  padding?: string
  method?: string
}

//region Styled

const Link = SC(Button)`
  color: #000 !important;
  font-weight: normal;
  padding: 0;
`

//endregion

export const DownloadLink = React.memo((properties: Properties) => {
  const {
    children,
    className,
    baseUrl,
    parametersForm,
    direct,
    name,
    mimeType,
    withIcon = true,
    method = 'post',
    handleError
  } = properties

  const downloadFile = React.useCallback(() => {
    const request = method === 'put' ? Request.put : Request.post

    request(baseUrl, parametersForm, {
      responseType: direct ? 'arraybuffer' : 'json',
      transformResponse: (data, headers) => {
        const disposition = headers?.['content-disposition']
        let filename: string | undefined
        if (disposition) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          const matches = filenameRegex.exec(disposition)
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '')
          }
        }
        return {
          data,
          filename
        }
      }
    })
      .then((response: { data: any; filename: string | undefined }) => {
        const fileData = response?.data
        let url

        if (direct) {
          url = window.URL.createObjectURL(new Blob([fileData], { type: mimeType || 'image/jpg' }))
        } else {
          url = fileData?.link
        }

        fakeLinkDownload(url, response.filename || name)
      })
      .catch((error) => {
        const json =
          error.response && JSON.parse(String.fromCharCode.apply(null, new Uint8Array(error.response.data) as any))

        const message = error.message || 'Cannot download file'

        handleError(json && json.message ? json.message : message)
      })
  }, [parametersForm, direct])

  return (
    <Link
      className={className || ''}
      face={ButtonFace.LINK}
      icon={withIcon ? IconName.DOWNLOAD : undefined}
      onClick={downloadFile}
      data-qa={`${name}-link`}
    >
      {children}
    </Link>
  )
})
