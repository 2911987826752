import { useCallback } from 'react'

import { FormChangedHandler, useFormData } from 'brief-form'

import { FormPaths } from '../../enums'
import { useSyncFormData } from '../../hooks'
import { useValidateFormPersist } from '../../hooks/useValidateFormPersist'
import {
  CompanyInformationChangeHandler,
  CompanyInformationFormData,
  CompanyInformationFormValue
} from '../../types/forms'

type CompanyInformationHookReturnType = {
  companyIndex: number
  formData: CompanyInformationFormData
  onChange: CompanyInformationChangeHandler
}

export const useCompanyInformationForm = ({ companyIndex, formData, onChange }: CompanyInformationHookReturnType) => {
  const onFormChanged: FormChangedHandler<CompanyInformationFormValue> = useCallback(
    (formValues, formErrors) => onChange({ formData: { formValues, formErrors }, companyIndex }),
    [onChange, companyIndex]
  )

  const { config, Field, set, validate } = useFormData<CompanyInformationFormValue>({
    onFormChanged,
    initialValue: formData.formValues,
    initialErrors: formData.formErrors
  })

  useSyncFormData({ set, formData })
  const formKey = `${FormPaths.COMPANY_INFORMATION}-${companyIndex}`

  useValidateFormPersist({
    formName: formKey,
    validate,
    config
  })

  return { Field, config }
}
