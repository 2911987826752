import { AnyRoute, createRoute, lazyRouteComponent } from '@tanstack/react-router'

import { documentNewSchema } from './CreateDocument.types'

export const createDocumentRoute = <T extends AnyRoute>(indexRoute: T) =>
  createRoute({
    getParentRoute: () => indexRoute,
    component: lazyRouteComponent(() => import('./CreateDocument')),
    path: 'new',
    validateSearch: (search) => documentNewSchema.parse(search),
    // this function checks if it's not first route appearance
    // (so mutating history via LeaveConfirmation for example)
    // we shouldn`t reload route (including loader-fn)
    shouldReload: (p) => p.cause === 'enter'
  })
