import React, { FC, memo, useCallback, useMemo, useState } from 'react'

import { UploadedFile } from 'commons/types/DTO/bank/reportWebPage'
import { TableDataQa } from 'commons/types/enums'
import { Layer } from 'ui/components/Layer'
import { PageSection } from 'ui/components/Page'
import { DataSourceItem, Table } from 'ui/components/Table'
import { useConfirmation } from 'ui/hooks'

import { UploadedFilesTableProps } from './UploadedFilesTableProps'
import { columns } from './colums'
import { UploadButton } from './components/UploadButton/UploadButton'
import { useDeleteFile } from './hooks/useDeleteFile'
import { useUploadFileCreate } from './hooks/useUploadFileRequest'

export const UploadedFilesTable: FC<UploadedFilesTableProps> = memo((props) => {
  const { webPage, invalidateMR } = props
  const { createFile, isLoading: isFileUploading } = useUploadFileCreate(webPage.monthlyAnalyticsID, invalidateMR)
  const { deleteFile, isLoading: isFileDeleting } = useDeleteFile(webPage.monthlyAnalyticsID, invalidateMR)
  const [fileToDelete, setFileToDelete] = useState<number>()

  const { Confirmation: FileDeleteConfirmation, open: fileDeleteConfirmationOpen } = useConfirmation({
    message: `Are you sure you want to delete this file?`,
    onConfirm: () => deleteFile(fileToDelete),
    onCancel: () => setFileToDelete(null),
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  const onFileUpload = useCallback(
    (file: File) => {
      createFile({
        file,
        reportMonthlyId: webPage.monthlyAnalyticsID ?? 0
      })
    },
    [createFile, webPage.monthlyAnalyticsID]
  )

  const onFileDelete = useCallback(
    (id: number) => {
      setFileToDelete(id)
      fileDeleteConfirmationOpen()
    },
    [setFileToDelete, fileDeleteConfirmationOpen]
  )

  const dataSource: DataSourceItem<UploadedFile>[] = useMemo(
    () =>
      webPage.uploadedFiles.map((el) => ({
        item: el
      })),
    [webPage.uploadedFiles]
  )

  return (
    <PageSection title="Uploaded Files" anchor="uploaded_files" actions={<UploadButton onUpload={onFileUpload} />}>
      <Layer shadowed rounded>
        <Table<UploadedFile>
          dataQa={TableDataQa.BP_MONTHLY_ANALYTICS_UPLOADED_FILES}
          loading={isFileDeleting || isFileUploading}
          dataSource={dataSource}
          columns={columns(onFileDelete)()}
        />
        <FileDeleteConfirmation />
      </Layer>
    </PageSection>
  )
})
