import React from 'react'

import { CheckBox, FF } from 'ui/components'

import { useSARFormContext } from '../../../../../../../context'

export const C = () => {
  const { formApi } = useSARFormContext()
  return (
    <formApi.Field
      name="transactions_info.fraud.c"
      children={(field) => (
        <FF field={field} input={(p) => <CheckBox {...p} checkBoxLabel="c. Business loan" />} errorPosition="right" />
      )}
    />
  )
}
