import React, { useState } from 'react'

import { getRouteApi } from '@tanstack/react-router'
import { OnboardingDocumentsItemStatusEnum } from 'commons/types/DTO/bank/applications/manage/OnboardingDocumentsItem'
import { OnboardingDocumentsDetailsWebPage } from 'commons/types/DTO/bank/applications/onboardingDocuments'
import { Info, Link, Page, PageFace } from 'ui/components'
import { ButtonGroup } from 'ui/components/ButtonGroup'
import { Button } from 'ui/components/Buttons'

import { BankAccountPanelHeader, CompanyContainer, CompanySectionPanel, TemplatePanel } from '../../components'
import { SignersListModal } from '../../components/SignersListModal'
import { Actions } from './components/Actions'
import { BreadCrumbs } from './components/BreadCrumbs'
import { GeneralInformation } from './components/GeneralInformation'
import { History } from './components/History'

import { s } from './styles'

const routeApi = getRouteApi('/application/$applicationId/onboarding-documents/$id/details')

const SetupDocumentsViewDetails = () => {
  const id = routeApi.useParams({ select: (s) => Number(s.id) })
  const applicationId = routeApi.useParams({ select: (s) => Number(s.applicationId) })
  const webPageData: OnboardingDocumentsDetailsWebPage = routeApi.useLoaderData({ select: (s) => s.webPageData })

  const face =
    webPageData.status === OnboardingDocumentsItemStatusEnum.CANCELLED ? PageFace.DISABLED : PageFace.SECONDARY

  const [showSignersModal, setOpenSignersModal] = useState<boolean>(false)

  return (
    <Page
      title={`Application ID-${applicationId}`}
      subTitle="Onboarding Documents"
      face={face}
      noPadding={true}
      breadCrumbs={<BreadCrumbs applicationId={applicationId} idLabel={webPageData.idLabel} />}
      actions={<Actions webPageData={webPageData} applicationId={applicationId} />}
      footer={
        webPageData.enableEdit && (
          <s.Footer>
            <ButtonGroup margin="small">
              <Link to="/application/$applicationId/onboarding-documents/$id/edit" params={{ applicationId, id }}>
                <Button face="positive">Edit</Button>
              </Link>
            </ButtonGroup>
          </s.Footer>
        )
      }
    >
      {showSignersModal && <SignersListModal id={id} onClose={() => setOpenSignersModal(false)} />}
      <GeneralInformation
        status={webPageData.status}
        createdAt={webPageData.createdAt}
        sentAt={webPageData.sentAt}
        openSignersModal={() => setOpenSignersModal(true)}
      />
      {webPageData.companies.map((company, companyIndex) => (
        <CompanyContainer
          key={companyIndex}
          number={companyIndex + 1}
          totalCount={webPageData.companiesTotalCount}
          companyName={company.companyName}
          anchor={company.companyName.toLowerCase().replace(/\s+/g, '-')}
        >
          <CompanySectionPanel title="Company Documents">
            {company.companyDocuments.map((companyDocuments, companyDocumentsIndex) => (
              <TemplatePanel key={companyDocumentsIndex} title={companyDocuments.name}>
                <s.TemplateInsideBox>
                  {companyDocuments.values.map((value) => (
                    <Info key={value.label} label={value.label}>
                      {value.value}
                    </Info>
                  ))}
                </s.TemplateInsideBox>
              </TemplatePanel>
            ))}
          </CompanySectionPanel>

          {company.bankAccounts.map((bankAccount, bankAccountIndex) => (
            <CompanySectionPanel
              key={bankAccountIndex}
              title={<BankAccountPanelHeader bankAccountNumber={bankAccount.number} />}
            >
              {bankAccount.templates.map((template, templateIndex) => (
                <TemplatePanel key={templateIndex} title={template.name}>
                  <s.TemplateInsideBox>
                    {template.values.map((value) => (
                      <Info key={value.value} label={value.label}>
                        {value.value}
                      </Info>
                    ))}
                  </s.TemplateInsideBox>
                </TemplatePanel>
              ))}
            </CompanySectionPanel>
          ))}
        </CompanyContainer>
      ))}
      <History history={webPageData.history} historyTotalCount={webPageData.historyTotalCount} />
    </Page>
  )
}

export default SetupDocumentsViewDetails
