import React from 'react'

import { FF, TextInput } from 'ui/components'

import { useSARFormContext } from '../../../../../../../context'

export const City = ({ index1, index2 }: { index1: number; index2: number }) => {
  const { formApi } = useSARFormContext()
  const noBranchInvolved = formApi.useStore((s) => s.values.institution_activities_info[index1].no_branch_involved)

  return (
    <formApi.Field
      name={
        `institution_activities_info[${index1}].institution_activity_branches_info[${index2}].city` as 'institution_activities_info[0].institution_activity_branches_info[0].city'
      }
      children={(field) => (
        <FF field={field} label="71. City" input={(p) => <TextInput {...p} disabled={noBranchInvolved} />} />
      )}
    />
  )
}
