import React, { forwardRef, memo } from 'react'

import { Icon, IconName, IconSize } from 'ui/components/Icon'

import { DotsButtonProps } from './DotsButtonProps'
import { defaultProps } from './defaultProps'

import * as SC from './styles'

export const DotsButton = memo(
  forwardRef<any, DotsButtonProps>((props: DotsButtonProps, ref) => {
    const { opened, onClick, qaAttribute } = { ...defaultProps, ...props }

    return (
      <SC.Button role="button" onClick={onClick} ref={ref} data-qa={qaAttribute}>
        <SC.IconWrapper opened={opened}>
          <Icon name={IconName.DOTH_HORIZONTAL} size={IconSize.XS} />
        </SC.IconWrapper>
      </SC.Button>
    )
  })
)
