import styled from 'styled-components'
import { Hr } from 'ui/components/Hr'
import { Info, InfoSet } from 'ui/components/Info'

export const WrappedHr = styled(Hr)`
  margin-bottom: 28px;
`

export const Col = styled.div`
  &:first-child {
    width: 400px;
  }
`

export const Row = styled.div`
  display: flex;

  > * {
    &:first-child {
      margin-right: 100px;
    }
  }
`

export const NotesWrapper = styled.div`
  width: 700px;
  margin-bottom: 28px;
`

export const NotesInfo = styled(Info)`
  padding-bottom: 10px;
`

export const MediaInfo = styled(Info)`
  padding-bottom: 20px;
`

export const MediaInfoSet = styled(InfoSet)`
  padding: 20px 0;
  margin-bottom: 36px;
`

export const Content = styled.div`
  padding: 28px 32px 32px 32px;
  max-width: 595px;

  button {
    margin-right: 10px;
  }
`

export const PublicationList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`
