import React, { FC, memo, useCallback, useState } from 'react'

import { useRouter } from '@tanstack/react-router'
import { BankApplicationPaths } from 'commons/constants/routes'
import { Link, UsPhoneLabel } from 'ui/components'
import { Button } from 'ui/components/Buttons'
import { DateTime } from 'ui/components/DateTime'
import { Hr } from 'ui/components/Hr'
import { Icon, IconFace, IconName, IconSize } from 'ui/components/Icon'
import { Info } from 'ui/components/Info'
import { PageSection, PageSectionFace } from 'ui/components/Page'
import { Toggle } from 'ui/components/Toggle'
import { Tooltip } from 'ui/components/Tooltip'
import { useConfirmation } from 'ui/hooks'
import { Col, Row } from 'ui/themes/globalStyles'

import { useSetActiveApplication } from '../../../../hooks/useSetActiveApplication'
import { GeneralInformationProps } from './GeneralInformationProps'
import { useApplicationOwner } from './components/ApplicationOwner'

import * as SC from './styles'

export const GeneralInformation: FC<GeneralInformationProps> = memo(({ item }) => {
  const {
    infoIsApplicationActive,
    applicationID,
    availableOwners,
    currentOwner,
    createdBy,
    infoCompanyName,
    infoContactFullname,
    infoCurrentApplicationStatus,
    infoApplicationCreateDate,
    infoContactEmail,
    infoContactPhone,
    enableActiveToggle,
    infoApplicationLastModifiedDate,
    documentRequestAcknowledgedBy,
    documentRequestAcknowledgedAtFormatted,
    enableEditRFI
  } = item
  const router = useRouter()
  const [activeApplicationToggle, setActiveApplicationToggle] = useState(infoIsApplicationActive)
  const { setActiveApplication } = useSetActiveApplication()
  const activeApplicationTooltipContent = activeApplicationToggle
    ? 'If the application is marked Inactive it will remain Inactive until it receives an update from the customer.'
    : 'Application will remain stale until it receives an update from the customer.'

  const onToggleApplication = useCallback(async () => {
    await setActiveApplication(applicationID, !activeApplicationToggle)
    await router.invalidate()
    setActiveApplicationToggle((oldValue: boolean) => !oldValue)
  }, [activeApplicationToggle, applicationID, router, setActiveApplication])

  const { Confirmation: ToggleApplicationActiveConfirmation, open: openToggleApplicationActiveConfirmation } =
    useConfirmation({
      message: (
        <span>Are you sure you want to mark the application as {infoIsApplicationActive ? 'Inactive' : 'Active'}?</span>
      ),
      onConfirm: onToggleApplication,
      confirmationButtonText: 'Confirm',
      isConfirmationNegative: true
    })

  const { ApplicationOwnerDialog, openApplicationOwnerDialog } = useApplicationOwner(
    applicationID,
    { label: currentOwner?.name, value: currentOwner?.id },
    availableOwners,
    () => router.invalidate()
  )

  return (
    <PageSection
      title="General Information"
      face={PageSectionFace.SECONDARY}
      anchor="general"
      actions={
        enableEditRFI ? (
          <Link to={BankApplicationPaths.EDIT} params={{ applicationId: applicationID }}>
            <Button face="positive">Edit RFI</Button>
          </Link>
        ) : null
      }
    >
      <ToggleApplicationActiveConfirmation />
      <Hr />
      <Row>
        <Col>
          <Info label="Company Name">{infoCompanyName}</Info>
        </Col>
        <Col>
          <Info label="Email">{infoContactEmail}</Info>
        </Col>
      </Row>
      <Row>
        <Col>
          <Info label="Contact Name">{infoContactFullname}</Info>
        </Col>
        <Col>
          <Info label="Phone">
            <UsPhoneLabel value={infoContactPhone} />
          </Info>
        </Col>
      </Row>
      <Row>
        <Col>
          <Info label="Current Application Status">{infoCurrentApplicationStatus}</Info>
        </Col>
        <Col>
          <Info label="Application Active">
            <SC.SliderWrapper>
              <Toggle
                value={activeApplicationToggle}
                onChange={openToggleApplicationActiveConfirmation}
                label={<span>{activeApplicationToggle ? 'Yes' : 'No'}</span>}
                disabled={!enableActiveToggle}
                data-qa="applicationActiveSwitcher"
              />
              <SC.IconWrapper>
                <Tooltip
                  id="info-about-active-application"
                  type="dark"
                  event="hover"
                  place="top"
                  content={<>{activeApplicationTooltipContent}</>}
                >
                  <Icon name={IconName.INFO} size={IconSize.XS} />
                </Tooltip>
              </SC.IconWrapper>
            </SC.SliderWrapper>
          </Info>
        </Col>
      </Row>
      <Row>
        <Col>
          <Info label="Created Date">
            <DateTime date={infoApplicationCreateDate} withTime />
          </Info>
        </Col>
        <Col>
          <Info label="Last Modified Date">
            <DateTime date={infoApplicationLastModifiedDate} withTime />
          </Info>
        </Col>
      </Row>
      <Row>
        <Col>
          <Info label="Created By">{createdBy?.name}</Info>
        </Col>
        <Col>
          {currentOwner && (
            <SC.OwnersLabelRow>
              <ApplicationOwnerDialog />
              <Info label="Current Owner">{currentOwner?.name}</Info>
              <Icon name={IconName.EDIT} size={IconSize.S} onClick={openApplicationOwnerDialog} />
            </SC.OwnersLabelRow>
          )}
        </Col>
      </Row>
      {('documentRequestAcknowledgedAtFormatted' in item || 'documentRequestAcknowledgedBy' in item) && (
        <>
          <Row>
            <Col>
              <Info label="CTR Reference Guide, Compliance Expectations, Cole Memo Guidelines">
                <Icon face={IconFace.ACTIVE} size={IconSize.S} name={IconName.CHECK} />
              </Info>
            </Col>
          </Row>
          <Row>
            <Col>
              <Info label="Acknowledged by">{documentRequestAcknowledgedBy}</Info>
            </Col>
            <Col>
              <Info label="Acknowledged at">
                <DateTime date={documentRequestAcknowledgedAtFormatted} withTime />
              </Info>
            </Col>
          </Row>
        </>
      )}
    </PageSection>
  )
})
