import styled from 'styled-components'
import { CSS } from 'ui/helpers'

const Box = styled.button`
  outline: none;
  border: none;
  background: none;

  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')};

  transition: background 0.15s, color 0.15s, outline 0.15s, box-shadow 0.15s, border-color 0.15s;

  svg {
    transition: fill 0.15s, color 0.15s;
  }
`

export const Inner = styled.span`
  display: flex;
  align-items: center;

  ${(p) => CSS.populateFont(p.theme.uiKit.typography.bodyM.button)}

  > i {
    margin-right: ${(p) => p.theme.uiKit.space.spacing.default};
  }
`

export const BoxPositive = styled(Box)`
  color: ${(p) => p.theme.uiKit.colors.global.grey.grey700};

  &:hover,
  &:focus-visible {
    color: ${(p) => p.theme.uiKit.colors.theme.positive.main};

    svg {
      fill: ${(p) => p.theme.uiKit.colors.theme.positive.main};
      color: ${(p) => p.theme.uiKit.colors.theme.positive.main};
    }
  }

  &:active {
    color: ${(p) => p.theme.uiKit.colors.theme.positive.darker};

    svg {
      fill: ${(p) => p.theme.uiKit.colors.theme.positive.darker};
      color: ${(p) => p.theme.uiKit.colors.theme.positive.darker};
    }
  }

  &:disabled {
    color: ${(p) => p.theme.uiKit.colors.global.grey.grey300};

    svg {
      fill: ${(p) => p.theme.uiKit.colors.global.grey.grey300};
      color: ${(p) => p.theme.uiKit.colors.global.grey.grey300};
    }
  }
`

export const BoxNegative = styled(Box)`
  color: ${(p) => p.theme.uiKit.colors.global.grey.grey700};

  &:hover,
  &:focus-visible {
    color: ${(p) => p.theme.uiKit.colors.theme.negative.main};

    svg {
      fill: ${(p) => p.theme.uiKit.colors.theme.negative.main};
      color: ${(p) => p.theme.uiKit.colors.theme.negative.main};
    }
  }

  &:active {
    color: ${(p) => p.theme.uiKit.colors.theme.negative.darker};

    svg {
      fill: ${(p) => p.theme.uiKit.colors.theme.negative.darker};
      color: ${(p) => p.theme.uiKit.colors.theme.negative.darker};
    }
  }

  &:disabled {
    color: ${(p) => p.theme.uiKit.colors.global.grey.grey300};

    svg {
      fill: ${(p) => p.theme.uiKit.colors.global.grey.grey300};
      color: ${(p) => p.theme.uiKit.colors.global.grey.grey300};
    }
  }
`

export const BoxNeutral = styled(Box)`
  color: ${(p) => p.theme.uiKit.colors.global.general.main};

  svg {
    fill: ${(p) => p.theme.uiKit.colors.global.general.main};
    color: ${(p) => p.theme.uiKit.colors.global.general.main};
  }

  &:active {
    color: ${(p) => p.theme.uiKit.colors.global.grey.grey300};

    svg {
      fill: ${(p) => p.theme.uiKit.colors.global.grey.grey300};
      color: ${(p) => p.theme.uiKit.colors.global.grey.grey300};
    }
  }

  &:focus-visible {
    color: ${(p) => p.theme.uiKit.colors.global.grey.grey300};

    svg {
      fill: ${(p) => p.theme.uiKit.colors.global.grey.grey300};
      color: ${(p) => p.theme.uiKit.colors.global.grey.grey300};
    }
  }

  &:disabled {
    color: ${(p) => p.theme.uiKit.colors.global.general.main};
    opacity: ${(p) => p.theme.uiKit.opacity.opacity600};

    svg {
      fill: ${(p) => p.theme.uiKit.colors.global.general.main};
      color: ${(p) => p.theme.uiKit.colors.global.general.main};
    }
  }
`
