export enum ClientUserPermissions {
  CONNECTIONS_LIST = 'connections:list',
  CONNECTIONS_CREATE = 'connections:create',
  CONNECTIONS_DELETE = 'connections:delete',
  DOCUMENT_LIST = 'documents:list',
  DOCUMENTS_UPLOAD = 'documents:upload',
  INTERNAL_TRANSFERS_LIST = 'internal_transfers:list',
  INTERNAL_TRANSFERS_CREATE = 'internal_transfers:create',
  INTERNAL_TRANSFERS_APPROVE = 'internal_transfers:approve',
  INTERNAL_TRANSFERS_CANCEL = 'internal_transfers:cancel',
  INTERNAL_TRANSFERS_MANAGE_SETTINGS = 'internal_transfers:manage_settings',
  INVOICES_CREATE = 'invoices:create',
  INVOICES_LIST = 'invoices:list',
  USERS_LIST = 'users:list',
  USERS_CREATE = 'users:create',
  USERS_DELETE = 'users:delete',
  USERS_SET_PERMISSIONS = 'users:set_permissions',
  FINANCIAL_ACCESS = 'financial:*'
}
