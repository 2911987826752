import { useCallback, useState } from 'react'

import { FilterAlertsWebPage } from 'commons/service/bank/alerts'
import { useTableState } from 'ui/components'

export const useComponentsEssence = (onAlertFilterChange: (filter: FilterAlertsWebPage) => void) => {
  const [openGetLinkModal, setOpenGetLinkModal] = useState(false)
  const [applicationId, setApplicationId] = useState<number | null>(null)
  const [loading, setLoading] = useState(false)

  const showGetLinkModal = (applicationId: number) => {
    setOpenGetLinkModal(true)
    setApplicationId(applicationId)
  }
  const tableState = useTableState({
    filter: {},
    order: [{ field: 'lastModifiedDate', direction: 'DESC' }]
  })

  const changePagination = useCallback(
    async (page: number, size: number) => {
      setLoading(true)

      tableState.onPageChange(page, size)
      const filter = {
        applicationsOffset: (page - 1) * size,
        applicationsLimit: size
      }

      await onAlertFilterChange(filter)

      setLoading(false)
    },
    [onAlertFilterChange, tableState]
  )

  const onSort = useCallback(
    async (direction: 'ASC' | 'DESC', field) => {
      const isDesc = direction === 'DESC'

      tableState.onOrderChange({ field, direction })

      setLoading(true)

      const filter = {
        applicationsOffset: (tableState.page - 1) * tableState.size,
        applicationsLimit: tableState.size,
        applicationsSortBy: field,
        applicationsSortDesc: isDesc
      }

      await onAlertFilterChange(filter)

      setLoading(false)
    },
    [onAlertFilterChange, tableState]
  )

  const onClose = useCallback(() => setOpenGetLinkModal(false), [])

  return {
    openGetLinkModal,
    applicationId,
    loading,
    showGetLinkModal,
    tableState,
    changePagination,
    onSort,
    onClose
  }
}
