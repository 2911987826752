import React, { FC, memo } from 'react'

import { Form } from 'brief-form'
import { Hint, Validators } from 'ui/components'
import { FieldSet } from 'ui/components/FieldSet'
import { TextInput, TextareaInput, USPhoneInput } from 'ui/components/InputV2'
import { Col, Col2x, FormRow } from 'ui/themes/globalStyles'

import { HiddenBox } from '../../components/HiddenBox'
import { OnboardingFormFieldNames } from '../../enums'
import { CompanyInformationFormProps } from './CompanyInformationFormProps'
import { useCompanyInformationForm } from './useCompanyInformationForm'

import * as SC from './styles'

export const CompanyInformationForm: FC<CompanyInformationFormProps> = memo(({ companyIndex, value, onChange }) => {
  const { Field, config } = useCompanyInformationForm({ companyIndex, formData: value, onChange })

  return (
    <Form config={config}>
      <SC.Title>Company Information</SC.Title>
      <HiddenBox>
        <Field
          name={OnboardingFormFieldNames.COMPANY_ID}
          label={OnboardingFormFieldNames.COMPANY_ID}
          input={TextInput}
          inputProps={{
            'data-qa': OnboardingFormFieldNames.COMPANY_ID
          }}
        />
      </HiddenBox>
      <FieldSet legend="Relationship Details">
        <FormRow>
          <Col>
            <Field
              name={OnboardingFormFieldNames.LEGAL_NAME}
              label="Legal Entity Name"
              input={TextInput}
              inputProps={{
                'data-qa': 'Legal Entity Name',
                maxLength: 255
              }}
              required
              validator={Validators.required.field}
            />
          </Col>
          <Col>
            <Field
              name={OnboardingFormFieldNames.DBA}
              label="DBA"
              input={TextInput}
              inputProps={{
                'data-qa': 'DBA',
                maxLength: 75
              }}
            />
          </Col>
        </FormRow>
        <FormRow>
          <Col>
            <Field
              name={OnboardingFormFieldNames.PHONE}
              label="Phone"
              input={USPhoneInput}
              inputProps={{
                'data-qa': 'Phone'
              }}
            />
          </Col>
          <Col>
            <Field
              name={OnboardingFormFieldNames.WEBSITE}
              label="Website"
              input={TextInput}
              inputProps={{
                'data-qa': 'Website',
                maxLength: 255
              }}
            />
          </Col>
        </FormRow>
        <Col2x>
          <Hint>
            Please use the area below to provide an overview of the company named above. Include the following details:
            the primary product or service the business provides, how long the business has been operating, management
            agreements or partnerships in place for the business or the business owners, and other details that would
            assist bank personnel in understanding how the business operates.
          </Hint>
        </Col2x>
        <FormRow>
          <Col2x>
            <Field
              label="Description"
              name={OnboardingFormFieldNames.DESCRIPTION}
              input={TextareaInput}
              inputProps={{
                'data-qa': 'Description'
              }}
            />
          </Col2x>
        </FormRow>
      </FieldSet>
    </Form>
  )
})
