import { ApplicationRelationshipLicense } from 'commons/types/DTO/bank/applications'

import { ApplicationBankAccountsFormDataList } from '../../../types'
import { LicensesCurrentState } from '../types'

export const getLicensesCurrentState = ({
  licenses,
  formsData
}: {
  licenses: ApplicationRelationshipLicense[]
  formsData: ApplicationBankAccountsFormDataList
}): LicensesCurrentState => {
  let allFromsAssignedLicensesWithoutNew: (number | string)[] = []
  let allFromsAssignedLicenses: (number | string)[] = []
  Object.keys(formsData).forEach((formKey) => {
    if (formKey !== 'new') {
      allFromsAssignedLicensesWithoutNew = [
        ...allFromsAssignedLicensesWithoutNew,
        ...formsData[formKey].value.assignedLicenses
      ]
    }
    allFromsAssignedLicenses = [...allFromsAssignedLicensesWithoutNew, ...formsData[formKey].value.assignedLicenses]
  })
  return {
    licensesWithNewForm: licenses.map((license) => ({
      ...license,
      isAssigned: allFromsAssignedLicenses.includes(license.id) || allFromsAssignedLicenses.includes(license.entityUID)
    })),
    licensesWithoutNewForm: licenses.map((license) => ({
      ...license,
      isAssigned:
        allFromsAssignedLicensesWithoutNew.includes(license.id) ||
        allFromsAssignedLicensesWithoutNew.includes(license.entityUID)
    }))
  }
}
