import React, { FC, memo } from 'react'

import { Outlet, getRouteApi } from '@tanstack/react-router'
import { Page, PageFace } from 'ui/components/Page'
import { FilesSection } from '~bank-documents/components/FilesSection'
import { DocumentsPaths } from '~bank-documents/constants'
import { DocumentDetailsPage } from '~bank-documents/types/documentDetails'

import { DocumentDetailsInformation } from './components/DocumentDetailsInformation'
import { DocumentPeriods } from './components/DocumentPeriods'
import { HistorySection } from './components/HistorySection'

const DocumentDetails: FC = memo(() => {
  const routeApi = getRouteApi(DocumentsPaths.DETAILS)
  const data = routeApi.useLoaderData<DocumentDetailsPage>()
  const documentId = routeApi.useParams({ select: (s) => s.documentId })

  return (
    <>
      <Outlet />
      <Page face={PageFace.SECONDARY} title="Document" subTitle={data.name}>
        <DocumentDetailsInformation />
        {data.showDocumentFiles && (
          <FilesSection id={Number(data.showOneTimeDocPeriodId)} files={data.showDocumentFiles} />
        )}
        {data.showDocumentPeriods && (
          <DocumentPeriods
            documentId={documentId}
            isLastDelivery={data.isLastDelivery}
            documentPeriods={data.showDocumentPeriods}
          />
        )}

        <HistorySection />
      </Page>
    </>
  )
})

export default DocumentDetails
