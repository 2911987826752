import React, { FC, memo } from 'react'

import { IconName, IconSize } from 'ui/components/Icon'

import { s } from './styles'

type Props = {
  attention: string
}

export const Warnings: FC<Props> = memo(({ attention }) => (
  <s.Box>
    <s.Title weight={700}>
      <s.WarningIcon name={IconName.ERROR_RED} size={IconSize.M} /> Warning(s)
    </s.Title>
    <br />

    <s.Title weight={600}>All warnings will be cleared once the CTR is Approved.</s.Title>
    <s.List>
      {attention.split('\n').map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </s.List>
  </s.Box>
))
