import React, { FC, memo, useCallback, useContext, useState } from 'react'

import { BankNotes, BlockDelimiter, CustomerAcknowledgementDialog, WelcomeBlock } from '..'
import { Hint } from 'ui/components'

import { NotesForm } from '../../forms'
import { OnboardingFormContext } from '../OnboardingForm/OnboardingFormContext'
import { CompanyDocuments } from './components'

type DocumentsProps = {
  applicationId: number
}

export const DocumentsIsolated: FC<DocumentsProps> = memo((props) => {
  const { applicationId } = props

  const onboardingContext = useContext(OnboardingFormContext)

  const {
    state,
    handlers,
    handlers: { handleAddFilesIsolated, handleRemoveFileIsolated, handleRequestAcknowledge },
    ui: { isOnboardingDocumentsEnable, isDocumentsLoadingIsolated, isDocumentDeletingIsolated }
  } = onboardingContext

  const { formValue, isCurrentStepEditEnable } = state

  const { applicationClientDocumentsUploading, shouldCustomerAcknowledgementDialogBeShown } = state
  const [isDialogShown, setIsDialogShown] = useState(shouldCustomerAcknowledgementDialogBeShown)

  const onSendAgreement = useCallback(
    (id: number) => {
      handleRequestAcknowledge(id)
      setIsDialogShown(false)
    },
    [handleRequestAcknowledge]
  )

  return (
    <>
      <WelcomeBlock />
      <BlockDelimiter text="Contact with the bank" />
      <BankNotes bankNotes={state.bankNotes} readonly={!isCurrentStepEditEnable} />

      {isCurrentStepEditEnable && (
        <NotesForm value={formValue.notes} onChange={handlers.handleNotesChange} onSave={handlers.handleNotesSave} />
      )}

      <BlockDelimiter text="Document Uploading" />

      {isCurrentStepEditEnable && (
        <Hint>
          If a document is not applicable to your organization (e.g. ATM Agreement) please upload a text document
          explaining the reason.
          <br />
          <b>Please note that required documents are marked with an asterisk (*).</b>
        </Hint>
      )}

      <CustomerAcknowledgementDialog id={applicationId} visible={isDialogShown} onClick={onSendAgreement} />

      {applicationClientDocumentsUploading.map((el, index) => (
        <CompanyDocuments
          key={el.companyUid}
          index={index}
          applicationClientDocumentsUploading={el}
          readonly={isOnboardingDocumentsEnable || !isCurrentStepEditEnable}
          isLoading={isDocumentsLoadingIsolated}
          onAddFiles={handleAddFilesIsolated}
          onRemoveFile={handleRemoveFileIsolated}
          isDocumentDeleting={isDocumentDeletingIsolated}
        />
      ))}
    </>
  )
})
