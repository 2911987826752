import { useEffect } from 'react'

import { POPPER_PORTAL_ID } from '../constants'

/* fix popper shaking (focus scroll on popper showing)
 * https://helioscompliance.atlassian.net/browse/MP-3960
 */
export const useFixShaking = () => {
  useEffect(() => {
    const root = document.getElementById(POPPER_PORTAL_ID)
    if (root) {
      root.style.position = 'fixed'
      root.style.zIndex = '1010'
    }
  }, [])
}
