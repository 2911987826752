const isValid = (v: string) => {
  if (!v) {
    return undefined
  }
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test((v || '').toString()) ? undefined : 'Invalid email format'
}

export const email = {
  valid: (v: string) => isValid(v),
  requiredAndValid: (v: string) => (!v ? 'Field is required' : isValid(v))
}
