// ?TODO: mb need replace logic to reducer
import { Reducer, useCallback, useEffect, useMemo, useReducer, useRef } from 'react'

import { ApplicationRelationshipBankAccountsResponse } from 'commons/types/DTO/bank/applications'

import { ApplicationBankAccountsDialogState, ConnectBankAccountToLicensesFormOnChangeState } from '../../types'
import { addBankAccountDialogStateReducer } from './addBankAccountDialogStateReducer'
import { applicationBankAccountsDialogStateEmptyValue } from './applicationBankAccountsDialogStateEmptyValue'
import { checkReadyForSubmit } from './helpers'
import { getFormDataList } from './helpers/getFormDataList'
import { makeExpandedList } from './helpers/makeExpandedList'
import { newApplicationBankAccount } from './newApplicationBankAccount'
import { AddBankAccountDialogStateAction, AddBankAccountDialogStateActions } from './types'

type AddBankAccountDialogHandlers = {
  setAddBankAccountFormValid: (isValid: boolean) => void
  formOnChange: ConnectBankAccountToLicensesFormOnChangeState
  selectRelationship: (newSelectedRelationshipIndex: number) => void
  selectNextRelationship: () => void
  removeBankAccount: (bankAccoutIndex: number) => void
  setShowCustomErrors: (isShow: boolean) => void
  addToFormDataListBankAccountFromNewForm: () => void
  setWOSelectedRelationship: (params: { woSelectedRelationship: boolean }) => void
}

type UseAddBankAccountDialogStateHookReturn = {
  state: ApplicationBankAccountsDialogState
  handlers: AddBankAccountDialogHandlers
}
type UseAddBankAccountDialogStateHookParams = {
  relationshipsBankAccounts: ApplicationRelationshipBankAccountsResponse | undefined
}
type UseAddBankAccountDialogStateHook = (
  params: UseAddBankAccountDialogStateHookParams
) => UseAddBankAccountDialogStateHookReturn

/*
  update data happend in last useEffect hook, 
  that observe changes of `relationshipsBankAccounts`
  and call `setInitData` if needed
*/
export const useAddBankAccountDialogState: UseAddBankAccountDialogStateHook = ({ relationshipsBankAccounts }) => {
  const [state, dispatch] = useReducer<Reducer<ApplicationBankAccountsDialogState, AddBankAccountDialogStateAction>>(
    addBankAccountDialogStateReducer,
    applicationBankAccountsDialogStateEmptyValue
  )

  const setAddBankAccountFormValid = useCallback(
    (isValid: boolean) =>
      dispatch({
        type: AddBankAccountDialogStateActions.ON_CHANGE,
        payload: { isAddBankAccountFormValid: isValid }
      }),
    []
  )

  const removeBankAccount = useCallback(
    (bankAccountIndex: number) => {
      const newFormKeys = Object.keys(state.formDataList).filter((formKey) => Number(formKey) !== bankAccountIndex)
      const newFormDataList = {}
      const newFormExpandedList = {}
      newFormKeys.forEach((formKey, index) => {
        const newKey = formKey === 'new' ? formKey : String(index)
        newFormDataList[newKey] = state.formDataList[formKey]
        newFormExpandedList[newKey] = state.formExpandedList[formKey]
      })

      dispatch({
        type: AddBankAccountDialogStateActions.ON_CHANGE,
        payload: {
          isOldDirty: true,
          isReadyForSubmit: false,
          formDataList: newFormDataList,
          formExpandedList: newFormExpandedList
        }
      })
    },
    [state]
  )

  const addToFormDataListBankAccountFromNewForm = useCallback(() => {
    // state.formDataList["new"] -> state.formDataList[ last index ]
    // "+" create empty state.formDataList["new"]
    const newFormDataList = { ...state.formDataList }
    const newIndex = Object.keys(newFormDataList).length - 1
    newFormDataList[String(newIndex)] = newFormDataList['new']
    newFormDataList['new'] = { value: { ...newApplicationBankAccount }, errors: {} }

    const newFormExpandedList = {}

    Object.keys(newFormDataList).forEach((formKey) => (newFormExpandedList[formKey] = false))

    dispatch({
      type: AddBankAccountDialogStateActions.ON_CHANGE,
      payload: {
        isAddBankAccountFormValid: false,
        isOldDirty: true,
        formDataList: newFormDataList,
        formExpandedList: newFormExpandedList,
        showFormErrors: false
      }
    })
  }, [state.formDataList])

  // TODO: mb replace to separate module
  const setInitData = useCallback(
    (params: {
      relationshipsBankAccounts: ApplicationRelationshipBankAccountsResponse
      woSelectedRelationship: boolean
      relationshipIndex: number
    }) => {
      {
        const { relationshipsBankAccounts, woSelectedRelationship, relationshipIndex } = params
        const { selectedRelationship, relationships } = relationshipsBankAccounts
        const selectedRelationshipIndex = woSelectedRelationship ? relationshipIndex : selectedRelationship
        const newFormDataList = getFormDataList({
          selectedRelationshipIndex,
          relationships
        })
        const isReadyForSubmit = checkReadyForSubmit({ relationships })
        const formExpandedList = makeExpandedList({ formList: newFormDataList })
        const payload = {
          relationshipsBankAccounts,
          formDataList: newFormDataList,

          formExpandedList,
          isReadyForSubmit,
          isOldDirty: false,
          woSelectedRelationship: false,
          ...(woSelectedRelationship ? {} : { selectedRelationshipIndex })
        }

        dispatch({
          type: AddBankAccountDialogStateActions.ON_CHANGE,
          payload
        })
      }
    },
    []
  )

  const formOnChange: ConnectBankAccountToLicensesFormOnChangeState = useCallback(
    (onChangeParams) => {
      // TODO: figure out why new ba form makes onChange
      if (!state.relationshipsBankAccounts) {
        return
      }
      const { formDataList: oldFormDataList, isOldDirty } = state
      const { formName, formData } = onChangeParams

      const newFormDataList = { ...oldFormDataList, [`${formName}`]: formData }
      dispatch({
        type: AddBankAccountDialogStateActions.ON_CHANGE,
        payload: { formDataList: newFormDataList, isOldDirty: formName !== 'new' ? true : isOldDirty }
      })
    },
    [state]
  )

  const selectRelationship = useCallback(
    (newSelectedRelationshipIndex: number) => {
      const { relationshipsBankAccounts } = state
      if (!relationshipsBankAccounts) {
        return
      }

      const newFormDataList = getFormDataList({
        selectedRelationshipIndex: newSelectedRelationshipIndex,
        relationships: relationshipsBankAccounts.relationships
      })

      const formExpandedList = makeExpandedList({ formList: newFormDataList })

      dispatch({
        type: AddBankAccountDialogStateActions.ON_CHANGE,
        payload: {
          selectedRelationshipIndex: newSelectedRelationshipIndex,
          formDataList: newFormDataList,
          formExpandedList
        }
      })
    },
    [state]
  )

  const selectNextRelationship = useCallback(() => {
    const { selectedRelationshipIndex, relationshipsBankAccounts } = state
    const len = relationshipsBankAccounts?.relationships.length || 0
    const newSelectedRelationshipIndex = selectedRelationshipIndex + 1
    if (newSelectedRelationshipIndex >= len) {
      return
    }

    selectRelationship(newSelectedRelationshipIndex)
  }, [state, selectRelationship])

  const setShowCustomErrors = useCallback(
    (showFormErrors: boolean) =>
      dispatch({
        type: AddBankAccountDialogStateActions.ON_CHANGE,
        payload: { showFormErrors }
      }),
    []
  )

  const setWOSelectedRelationship = useCallback(({ woSelectedRelationship = false }) => {
    dispatch({
      type: AddBankAccountDialogStateActions.ON_CHANGE,
      payload: { woSelectedRelationship }
    })
  }, [])

  // workaround: infinity updates
  const oldRelationshipsBankAccounts = useRef<ApplicationRelationshipBankAccountsResponse | undefined>(undefined)

  // update data ***
  // observe changes of relationshipsBankAccounts
  useEffect(() => {
    if (!relationshipsBankAccounts || relationshipsBankAccounts === oldRelationshipsBankAccounts.current) {
      return
    }
    // workaround: infinity updates
    oldRelationshipsBankAccounts.current = relationshipsBankAccounts

    setInitData({
      relationshipsBankAccounts,
      woSelectedRelationship: state.woSelectedRelationship,
      relationshipIndex: state.selectedRelationshipIndex
    })
  }, [relationshipsBankAccounts, setInitData, state])

  const handlers = useMemo(
    () => ({
      setAddBankAccountFormValid,
      formOnChange,
      selectRelationship,
      selectNextRelationship,
      removeBankAccount,
      addToFormDataListBankAccountFromNewForm,
      setShowCustomErrors,
      setWOSelectedRelationship
    }),
    [
      setAddBankAccountFormValid,
      formOnChange,
      selectRelationship,
      selectNextRelationship,
      removeBankAccount,
      addToFormDataListBankAccountFromNewForm,
      setShowCustomErrors,
      setWOSelectedRelationship
    ]
  )
  return {
    state,
    handlers
  }
}
