import React from 'react'

import { HistoryEntity } from 'commons/types/DTO/bank/historyAudit'
import { DateTime, Link } from 'ui/components'
import { TableColumn } from 'ui/components/Table'

import { HistoryRecordDetails } from '../HistoryRecordDetails'

export const columns = (
  showTypeColumn: boolean,
  showEntityIdColumn: boolean,
  onClickEntityId?: (entityId: string) => void
): TableColumn<HistoryEntity>[] => {
  const a: TableColumn<HistoryEntity>[] = [
    {
      title: 'User',
      align: 'center',
      width: '200px',
      render: ({ userInfo }) => (
        <div>
          {userInfo?.userEmail}
          <div>{userInfo?.userFullname}</div>
          <div>
            <i>{userInfo?.userTypeLabel}</i>
          </div>
        </div>
      )
    },
    {
      title: 'Date',
      align: 'center',
      width: '170px',
      render: ({ date }) => <DateTime withTime date={date} />
    },
    {
      title: 'Action',
      align: 'center',
      width: '100px',
      render: ({ actionLabel }) => <span data-qa="action-type-row">{actionLabel}</span>
    },
    showEntityIdColumn
      ? {
          title: 'Entity ID',
          align: 'left',
          width: '200px',
          render: ({ entityId }) => (
            <Link to="" onClick={() => onClickEntityId(entityId)}>
              {entityId}
            </Link>
          )
        }
      : null,
    showTypeColumn
      ? {
          title: 'Entity Type',
          align: 'center',
          width: '200px',
          render: ({ entityTypeLabel }) => <span data-qa="entity-type-row">{entityTypeLabel}</span>
        }
      : null,
    {
      title: 'Details',
      sortable: false,
      render: ({ details, links }) => <HistoryRecordDetails links={links} changes={details} />
    }
  ]

  const b = a.filter(Boolean)
  return b
}
