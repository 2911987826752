import React from 'react'

import { CheckBox, FF } from 'ui/components'

import { useSARFormContext } from '../../../../../../../context'

export const T = () => {
  const { formApi } = useSARFormContext()
  return (
    <formApi.Field
      name="transactions_info.product_types.t"
      children={(field) => (
        <FF
          field={field}
          input={(p) => <CheckBox {...p} checkBoxLabel="t. Swap, hybrid, or other derivatives" />}
          errorPosition="right"
        />
      )}
    />
  )
}
