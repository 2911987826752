import React, { memo } from 'react'

import { Icon, IconName, IconSize } from 'ui/components/Icon'

import { PageButtonProps } from './PageButtonProps'

import * as SC from './styles'

export const PageButton = memo(({ text, url, ...rest }: PageButtonProps) => (
  <SC.Box to={url} {...rest}>
    <SC.Text>{text}</SC.Text>
    <Icon name={IconName.ARROW_SMALL} size={IconSize.XS} />
  </SC.Box>
))
