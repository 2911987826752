import React, { ChangeEvent, FC, memo } from 'react'

import { RadioButton } from './RadioButton'
import { RadioGroupOrientation } from './RadioGroupOrientation'
import { RadioGroupProps } from './RadioGroupProps'

import * as SC from './styles'

export const RadioGroup: FC<RadioGroupProps> = memo((props) => {
  const {
    value,
    onChange,
    required = false,
    data,
    name,
    disabled = false,
    hint,
    valueType,
    orientation = RadioGroupOrientation.HORIZONTAL,
    'data-qa': dataQa,
    readonly = false,
    error
  } = props

  if (!data) {
    throw new Error('Data property must be set')
  } else if (data.length <= 1) {
    throw new Error('Must be at least 2 options in RadioGroup')
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let newVal
    // support init value as null
    const parseValueType = valueType || typeof value
    // Convert return value to initial type
    switch (parseValueType) {
      case 'boolean':
        newVal = JSON.parse(e.target.value)
        break
      case 'number':
        newVal = +e.target.value
        break
      default:
        newVal = e.target.value
        break
    }

    onChange(newVal, undefined)
    e.stopPropagation()
  }

  // Readonly is always disabled
  const isDisabled = readonly ? true : disabled ? true : false

  return (
    <>
      <SC.RadioGroupContainer orientation={orientation}>
        {data.map((item, index) => (
          <RadioButton
            key={index}
            readonly={readonly}
            dataQa={dataQa}
            disabled={isDisabled}
            error={error}
            required={required && index === 0}
            name={name}
            checked={value === item.value}
            value={item.value}
            label={item.label}
            onChange={handleChange}
          />
        ))}
      </SC.RadioGroupContainer>
      {hint && <SC.Hint disabled={disabled}>{hint}</SC.Hint>}
    </>
  )
})
