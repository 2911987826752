import styled from 'styled-components'
import { Button } from 'ui/components/Buttons'

const FormWrapper = styled.div`
  padding: 20px 30px 30px 30px;
`

const Row = styled.div`
  display: flex;
  align-items: flex-end;

  > * {
    margin-right: 10px;
    width: 160px;
  }
`

const GenerateButton = styled(Button)`
  height: 40px;
  width: 175px;
`

export const s = { FormWrapper, Row, GenerateButton }
