import React from 'react'

import { FF, TextInput } from 'ui/components'

import { useSARFormContext } from '../../../../../../../../context'
import { useSubjectInformationItemContext } from '../../../SubjectInformationItemContext'

type IdNumberProps = {
  index: number
}

export const IdNumber = ({ index }: IdNumberProps) => {
  const { formApi } = useSARFormContext()
  const { isAllInfoUnavailable } = useSubjectInformationItemContext()

  return (
    <formApi.Field
      name={`subjects_info[${index}].id_info[0].id_number` as 'subjects_info[0].id_info[0].id_number'}
      children={(field) => (
        <FF field={field} label="Number" input={(p) => <TextInput {...p} disabled={isAllInfoUnavailable} />} />
      )}
    />
  )
}
