import SC from 'styled-components'

export const Table = SC.table`
  width: calc(100% - 80px);
  margin: 40px;
  border-collapse: collapse;
`

export const Th = SC.td`
  text-align: center;
  font-weight: bold;
  border: 1px solid ${(p) => p.theme.uiKit.colors.global.grey.grey300};
  padding: 10px;
  background: ${(p) => p.theme.uiKit.colors.global.grey.grey300};
  
  @media print {
    background: ${(p) => p.theme.uiKit.colors.global.grey.grey300};
    -webkit-print-color-adjust: exact;
  }
`

export const Td = SC.td`
  text-align: center;
  border: 1px solid ${(p) => p.theme.uiKit.colors.global.grey.grey300};
  padding: 10px;
`
