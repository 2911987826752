import { AnnualReviewStatuses } from 'commons/types/DTO/bank'
import { AnnualReview } from 'commons/types/DTO/bank/annualReview'

import { AnnualReviewFormValue } from './AnnualReviewFormValue'

export const generateFormData = (annualReview: AnnualReview): AnnualReviewFormValue => ({
  last_ar_date: annualReview.last_ar_date ?? null,
  financials_start_date: annualReview.financials_start_date ?? '',
  financials_end_date: annualReview.financials_end_date ?? '',
  status: annualReview.status ?? '',
  is_review_completed:
    annualReview.status === AnnualReviewStatuses.COMPLETED || annualReview.status === AnnualReviewStatuses.APPROVED,
  'questionnaire.licensing_name_prior': annualReview?.questionnaire?.licensing_name_prior ?? '',
  'questionnaire.licensing_loc_changes': annualReview?.questionnaire?.licensing_loc_changes ?? '',
  'questionnaire.licensing_name_changes': annualReview?.questionnaire?.licensing_name_changes ?? '',
  'questionnaire.licensing_loc_changes_mmcc': annualReview?.questionnaire?.licensing_loc_changes_mmcc ?? '',
  'questionnaire.licensing_name_changes_mmcc': annualReview?.questionnaire?.licensing_name_changes_mmcc ?? '',
  'questionnaire.financials_profitability_trend': annualReview?.questionnaire?.financials_profitability_trend ?? '',
  'questionnaire.financials_period_collected': annualReview?.questionnaire?.financials_period_collected ?? '',
  'questionnaire.financials_investor_debtholders': annualReview?.questionnaire?.financials_investor_debtholders ?? '',
  'questionnaire.financials_deposits_matching': annualReview?.questionnaire?.financials_deposits_matching ?? '',
  'questionnaire.financials_business_condition': annualReview?.questionnaire?.financials_business_condition ?? '',
  'questionnaire.financials_becoming_failing': annualReview?.questionnaire?.financials_becoming_failing ?? '',
  'questionnaire.financials_acceptable_notes': annualReview?.questionnaire?.financials_acceptable_notes ?? '',
  'questionnaire.financials_current_condition_is_acceptable':
    annualReview?.questionnaire?.financials_current_condition_is_acceptable ?? '',
  'questionnaire.financials_materials_notes': annualReview?.questionnaire?.financials_materials_notes ?? '',
  'questionnaire.financials_is_materials_regarding_income':
    annualReview?.questionnaire?.financials_is_materials_regarding_income ?? '',
  'questionnaire.transactions_cash': annualReview?.questionnaire?.transactions_cash ?? false,
  'questionnaire.transactions_atm': annualReview?.questionnaire?.transactions_atm ?? false,
  'questionnaire.transactions_ach': annualReview?.questionnaire?.transactions_ach ?? false,
  'questionnaire.transactions_check': annualReview?.questionnaire?.transactions_check ?? false,
  'questionnaire.transactions_vol_projected': annualReview?.questionnaire?.transactions_vol_projected ?? '',
  'questionnaire.transactions_vol_projected_exactly':
    annualReview?.questionnaire?.transactions_vol_projected_exactly ?? '',
  'questionnaire.transactions_expect_exactly': annualReview?.questionnaire?.transactions_expect_exactly ?? '',
  'questionnaire.transactions_expect_explain': annualReview?.questionnaire?.transactions_expect_explain ?? '',
  'questionnaire.transactions_match_trans': annualReview?.questionnaire?.transactions_match_trans ?? '',
  'questionnaire.transactions_notes': annualReview?.questionnaire?.transactions_notes ?? '',
  'questionnaire.covenant_complying': annualReview?.questionnaire?.covenant_complying ?? '',
  'questionnaire.covenant_non_compliance_desc': annualReview?.questionnaire?.covenant_non_compliance_desc ?? '',
  'questionnaire.covenant_transparent': annualReview?.questionnaire?.covenant_transparent ?? '',
  'questionnaire.site_visit_freq': annualReview?.questionnaire?.site_visit_freq ?? '',
  'questionnaire.site_visit_last_date': annualReview?.questionnaire?.site_visit_last_date ?? '',
  'questionnaire.site_visit_complying_regulations': annualReview?.questionnaire?.site_visit_complying_regulations ?? '',
  'questionnaire.site_visit_violation_rectified': annualReview?.questionnaire?.site_visit_violation_rectified ?? '',
  'questionnaire.site_visit_scores_acceptable': annualReview?.questionnaire?.site_visit_scores_acceptable ?? '',
  'questionnaire.site_visit_scores_acceptable_exactly':
    annualReview?.questionnaire?.site_visit_scores_acceptable_exactly ?? '',
  'questionnaire.site_visit_shutdown_risk': annualReview?.questionnaire?.site_visit_shutdown_risk ?? '',
  'questionnaire.ownership_changes': annualReview?.questionnaire?.ownership_changes ?? '',
  'questionnaire.ownership_provided_forms': annualReview?.questionnaire?.ownership_provided_forms ?? '',
  'questionnaire.ownership_changes_approved': annualReview?.questionnaire?.ownership_changes_approved ?? '',
  'questionnaire.ownership_ofac_tlo_reviewed': annualReview?.questionnaire?.ownership_ofac_tlo_reviewed ?? '',
  'questionnaire.ownership_bad_news': annualReview?.questionnaire?.ownership_bad_news ?? '',
  'questionnaire.ownership_bad_news_desc': annualReview?.questionnaire?.ownership_bad_news_desc ?? '',
  'questionnaire.ownership_bad_news_cleared': annualReview?.questionnaire?.ownership_bad_news_cleared ?? '',
  'questionnaire.ownership_bad_news_cleared_explain':
    annualReview?.questionnaire?.ownership_bad_news_cleared_explain ?? '',
  'questionnaire.recom_recommended': annualReview?.questionnaire?.recom_recommended ?? '',
  'questionnaire.recom_status': annualReview?.questionnaire?.recom_status ?? '',
  'questionnaire.recom_change_visits': annualReview?.questionnaire?.recom_change_visits ?? '',
  'questionnaire.site_visit_has_state_audit_at_review_period':
    annualReview?.questionnaire?.site_visit_has_state_audit_at_review_period ?? '',
  'questionnaire.site_visit_state_audit_notes': annualReview?.questionnaire?.site_visit_state_audit_notes ?? '',
  'questionnaire.ownership_has_material_changes_in_management_at_review_period':
    annualReview?.questionnaire?.ownership_has_material_changes_in_management_at_review_period ?? '',
  'questionnaire.ownership_material_changes_in_management_notes':
    annualReview?.questionnaire?.ownership_material_changes_in_management_notes ?? '',
  'questionnaire.recom_comments': annualReview?.questionnaire?.recom_comments ?? ''
})

export const generateFormDataForCBT = (annualReview: AnnualReview) => ({
  last_ar_date: annualReview.last_ar_date ?? null,
  financials_start_date: annualReview.financials_start_date ?? '',
  financials_end_date: annualReview.financials_end_date ?? '',
  status: annualReview.status ?? '',
  is_review_completed:
    annualReview.status === AnnualReviewStatuses.COMPLETED || annualReview.status === AnnualReviewStatuses.APPROVED,
  'questionnaire.has_more_revenue': annualReview?.questionnaire?.has_more_revenue ?? '',
  'questionnaire.revenue_from_mj_only': annualReview?.questionnaire?.revenue_from_mj_only ?? '',
  'questionnaire.information_for_METRC_dump': '',
  'questionnaire.comparing_sics_and_bank_deposits_red_flag':
    annualReview?.questionnaire?.comparing_sics_and_bank_deposits_red_flag ?? '',
  'questionnaire.comparing_sics_and_bank_deposits_variance':
    annualReview?.questionnaire?.comparing_sics_and_bank_deposits_variance ?? '',
  'questionnaire.comparing_sics_and_bank_deposits_comments':
    annualReview?.questionnaire?.comparing_sics_and_bank_deposits_comments ?? '',
  'questionnaire.has_more_cash_deposits': annualReview?.questionnaire?.has_more_cash_deposits ?? '',
  'questionnaire.compare_cash_amount_dump': '',
  'questionnaire.comparing_cash_deposits_and_tax_returns_red_flag':
    annualReview?.questionnaire?.comparing_cash_deposits_and_tax_returns_red_flag ?? '',
  'questionnaire.comparing_cash_deposits_and_tax_returns_variance':
    annualReview?.questionnaire?.comparing_cash_deposits_and_tax_returns_variance ?? '',
  'questionnaire.comparing_cash_deposits_and_tax_returns_comments':
    annualReview?.questionnaire?.comparing_cash_deposits_and_tax_returns_comments ?? '',
  'questionnaire.inconsistent_financial_statement': annualReview?.questionnaire?.inconsistent_financial_statement ?? '',
  'questionnaire.validate_contributions_dump': '',
  'questionnaire.financial_statement_validation_red_flag':
    annualReview?.questionnaire?.financial_statement_validation_red_flag ?? '',
  'questionnaire.financial_statement_validation_variance':
    annualReview?.questionnaire?.financial_statement_validation_variance ?? '',
  'questionnaire.financial_statement_validation_comments':
    annualReview?.questionnaire?.financial_statement_validation_comments ?? '',
  'questionnaire.disguising_mrb_activity': annualReview?.questionnaire?.disguising_mrb_activity ?? '',
  'questionnaire.inconsistent_mrb_purporting': annualReview?.questionnaire?.inconsistent_mrb_purporting ?? '',
  'questionnaire.inconsistent_mrb_purporting_due_diligence_completed':
    annualReview?.questionnaire?.inconsistent_mrb_purporting_due_diligence_completed ?? '',
  'questionnaire.owner_outside_state': annualReview?.questionnaire?.owner_outside_state ?? '',
  'questionnaire.owner_outside_state_approved': annualReview?.questionnaire?.owner_outside_state_approved ?? '',
  'questionnaire.adverse_media_required': annualReview?.questionnaire?.adverse_media_required ?? '',
  'questionnaire.adverse_media_reviewed': annualReview?.questionnaire?.adverse_media_reviewed ?? '',
  'questionnaire.mrb_on_federal_property': annualReview?.questionnaire?.mrb_on_federal_property ?? '',
  'questionnaire.mrb_school_proximity_consistent': annualReview?.questionnaire?.mrb_school_proximity_consistent ?? '',
  'questionnaire.mrb_school_proximity_due_diligence_completed':
    annualReview?.questionnaire?.mrb_school_proximity_due_diligence_completed ?? ''
})
