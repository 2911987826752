import React from 'react'

import { primaryFederalRegulators } from 'commons/types/dictionaries'
import { FF, Select, Validators } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

export const FederalRegulator = () => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name="institution_contact_info.federal_regulator"
      validators={{
        onChange: ({ value }) => Validators.required.field(value)
      }}
      children={(field) => (
        <FF
          required
          label="75. Primary federal regulator"
          field={field}
          input={(p) => (
            <Select
              {...p}
              data={primaryFederalRegulators}
              data-qa="75. Primary federal regulator"
              options-data-qa="75. Primary federal regulator option"
            />
          )}
        ></FF>
      )}
    />
  )
}
