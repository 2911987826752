import { ATMServicesFormData } from '../../../types/forms'
import { OnboardingFormState } from '../../../types/state'
import { getSelectedCompany } from './getSelectedCompany'
import { setNewAtms } from './setNewAtms'
import { setNewCompanies } from './setNewCompanies'

export const atmServicesToState = ({
  companyIndex,
  atmIndex,
  currentState,
  formData
}: {
  companyIndex: number
  atmIndex: number
  currentState: OnboardingFormState
  formData: ATMServicesFormData
}) => {
  const oldCompany = getSelectedCompany({ companyIndex, currentState })

  // show changes contacts
  const newATMs = setNewAtms({
    atmIndex,
    newATM: { ...oldCompany.atms[atmIndex], atmServices: { ...formData } },
    oldCompany
  })

  // change ref in list
  const newCompaniesFormValues = setNewCompanies({
    companyIndex,
    oldCompanies: currentState.formValue.companiesFormValues,
    newCompany: { ...oldCompany, atms: newATMs }
  })

  return {
    formValue: {
      ...currentState.formValue,
      companiesFormValues: newCompaniesFormValues
    }
  }
}
