import { useForm } from '@tanstack/react-form'
import { getRouteApi } from '@tanstack/react-router'
import { BsaSar } from 'commons/types/DTO/bank/bsa'
import { handleFormSubmissionError } from 'ui/components/Form'

import { useSaveSAR } from '../../../../hooks/sar'

const routeApi = getRouteApi('/bsa/sars/$id/edit')

export const useSARForm = (sar: BsaSar) => {
  const { returnUrl } = routeApi.useSearch()
  const navigate = routeApi.useNavigate()

  const comeBackToSarOrReturnUrl = () => {
    if (returnUrl) {
      return navigate({ to: decodeURIComponent(returnUrl) })
    } else {
      if (sar.id) {
        return navigate({ to: '/bsa/sars/$id', params: { id: sar.id } })
      } else {
        return navigate({ to: '/bsa/sars' })
      }
    }
  }

  const { routine } = useSaveSAR({
    onSuccess: async () => {
      setNotDirty()
      // Calling `form.reset()` doesn't update state immediately.
      // So we schedule route change on the next render when state is updated.
      setTimeout(() => {
        comeBackToSarOrReturnUrl()
      }, 0)
    },
    onError: (error) => {
      handleFormSubmissionError<BsaSar>(error, formApi)
    }
  })

  const formApi = useForm<BsaSar>({
    defaultValues: sar,
    onSubmit: ({ value }) =>
      routine({
        ...value,
        prior_report_bsa_id: value.prior_report_bsa_id?.toString() || null
      })
  })

  const { useStore } = formApi
  const isDirty = useStore((state) => state.isDirty)
  const isFormValidating = useStore((state) => state.isFormValidating)

  const setNotDirty = () => {
    formApi.reset()
  }

  return {
    formApi,
    isFormValidating,
    setNotDirty,
    isFormDirty: isDirty
  }
}
