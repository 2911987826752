import SC, { css } from 'styled-components'

export const Box = SC.div`${css`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.4);
`}`
