import styled from 'styled-components'
import { CheckBox } from 'ui/components/CheckBox'

const IconButton = styled.button`
  margin-right: 10px;
  padding: 2px;
  border: none;
  background: none;
  height: 20px;
  cursor: pointer;

  &:focus {
    outline: 4px solid ${(p) => p.theme.uiKit.colors.global.grey.grey700};
  }
`

const NameRow = styled.span`
  display: flex;
  align-items: center;
`

const WrappedCheckBox = styled(CheckBox)`
  margin: 0;

  & > span {
    margin: 0;
  }
`

export const s = { IconButton, NameRow, WrappedCheckBox }
