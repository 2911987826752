const licenseSubTypeMap: { [key: string]: string } = {
  processor: 'Processor',
  dispensary: 'Dispensary',
  grow: 'Grow',
  delivery: 'Delivery',
  transportation_storage: 'Transportation/Storage',
  research_and_development: 'Research and Development',
  testing_facility: 'Testing Facility',
  waste_disposal: 'Waste Disposal',
  operator: 'Operator'
}

export const getLicenseSubTypeLabel = (name: string) => licenseSubTypeMap[name] || name
