import styled from 'styled-components'

const Box = styled.footer`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  height: 66px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 48px 0 272px;
  border-top: 1px solid ${(p) => p.theme.uiKit.colors.global.grey.grey300};
`

const Left = styled.div`
  display: flex;
  align-items: center;
`

export const s = { Box, Left }
