import React, { FC, memo } from 'react'

import { Form } from 'brief-form'
import { BankAccountSelect, CompanySelect, ContactSelect, LicenseSelect, Select } from 'ui/components/Select'

import { DocumentFilterSources, DocumentListFilterFormValue, DocumentListTableConfig } from '../DocumentListTable/types'
import { OnFilterChangeHandler, useDocumentListTableFilter } from './useDocumentListTableFilter'

import * as SC from './styles'

export const DocumentListTableFilter: FC<{
  currentFilterValue: DocumentListFilterFormValue
  filterSources: DocumentFilterSources
  filterFlags: DocumentListTableConfig
  clearFlag: Record<PropertyKey, never>
  onFilterChange: OnFilterChangeHandler
  onSetDirty: React.Dispatch<React.SetStateAction<boolean>>
}> = memo((props) => {
  const { currentFilterValue, filterSources, filterFlags, clearFlag, onFilterChange, onSetDirty } = props

  const {
    filterState: { config, Field }
  } = useDocumentListTableFilter({ currentFilterValue, clearFlag, onFilterChange, onSetDirty })

  const showLicenseFilter = config.value.subjectType === 'license'
  const showContactFilter = config.value.subjectType === 'contact'
  const showBankAccountFilter = config.value.subjectType === 'bank_account'

  const { isCompanyFilterHidden, isSubjectTypeFilterHidden, isContactFilterHidden, isBankAccountFilterHidden } =
    filterFlags

  return (
    <Form config={config}>
      <SC.FormBox>
        <SC.SelectWrapper>
          <Field
            name="internal"
            label="Internal"
            input={Select}
            inputProps={{
              data: filterSources.internalOptions
            }}
          />
        </SC.SelectWrapper>
        {!isSubjectTypeFilterHidden && (
          <SC.SelectWrapper>
            <Field
              name="subjectType"
              label="Subject Type"
              input={Select}
              inputProps={{
                data: filterSources.subjectTypeOptions
              }}
            />
          </SC.SelectWrapper>
        )}
        {showLicenseFilter && (
          <SC.SelectWrapper>
            <Field
              name="licenseId"
              label="License"
              input={LicenseSelect}
              inputProps={{
                defaultOption: { label: 'All', value: null }
              }}
            />
          </SC.SelectWrapper>
        )}
        {showContactFilter && !isContactFilterHidden && (
          <SC.SelectWrapper>
            <Field
              name="contactId"
              label="Contact"
              input={ContactSelect}
              inputProps={{
                defaultOption: { label: 'All', value: null }
              }}
            />
          </SC.SelectWrapper>
        )}
        {showBankAccountFilter && !isBankAccountFilterHidden && (
          <SC.SelectWrapper>
            <Field
              name="bankAccountId"
              label="Bank Account"
              input={BankAccountSelect}
              inputProps={{
                defaultOption: { label: 'All', value: null }
              }}
            />
          </SC.SelectWrapper>
        )}
        {!isCompanyFilterHidden && !showContactFilter && (
          <SC.SelectWrapper>
            <Field
              name="companyId"
              label="Relationship"
              input={CompanySelect}
              inputProps={{
                defaultOption: { label: 'All', value: null },
                'options-data-qa': 'Relationships',
                'data-qa': 'Relationships Type'
              }}
            />
          </SC.SelectWrapper>
        )}
        <SC.SelectWrapper>
          <Field
            name="frequency"
            label="Frequency"
            input={Select}
            inputProps={{
              data: filterSources.frequencyOptions
            }}
          />
        </SC.SelectWrapper>
        <SC.SelectWrapper>
          <Field
            name="alerts"
            label="Alerts"
            input={Select}
            inputProps={{
              data: filterSources.alertsOptions
            }}
          />
        </SC.SelectWrapper>
      </SC.FormBox>
    </Form>
  )
})
