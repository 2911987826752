import { SalesSourceItem as MRSalesSourceItem } from 'commons/types/DTO/bank/reportWebPage'
import { SalesDataSourceWebPage } from 'commons/types/DTO/bank/salesDataSourceWebPage'

export class BankSourceCreateFormValue {
  gramsQty: number = null
  gramsSold: number = null
  internalNotes = ''
  licenseId: number = null
  reportMonthlyId: number = null
  totalWithTaxesAndParaphernaliaSales: number = null
  unitsQty: number = null
  unitsSold: number = null

  constructor(reportMonthlyId?: number, licenseId?: number) {
    this.reportMonthlyId = reportMonthlyId || 0
    this.licenseId = licenseId || 0
  }

  fillFromMRSalesSourceItem(mRSalesSourceItem: MRSalesSourceItem) {
    this.gramsQty = mRSalesSourceItem.overrideGramsSoldQty
    this.gramsSold = mRSalesSourceItem.overrideGramsSoldAmount
    this.totalWithTaxesAndParaphernaliaSales = mRSalesSourceItem.overrideTotalSalesAmount
    this.unitsQty = mRSalesSourceItem.overrideUnitsSoldQty
    this.unitsSold = mRSalesSourceItem.overrideUnitsSoldAmount

    return this
  }

  fillFromSalesDataSourceWebPage(salesDataSourceWebPage: SalesDataSourceWebPage) {
    this.gramsQty = salesDataSourceWebPage.gramsSoldQty
    this.gramsSold = salesDataSourceWebPage.gramsSoldAmount
    this.internalNotes = salesDataSourceWebPage.infoInternalNotes
    this.licenseId = salesDataSourceWebPage.infoLicenses.find((el) => el.isMain).licenseID
    this.reportMonthlyId = salesDataSourceWebPage.infoReportID
    this.totalWithTaxesAndParaphernaliaSales = salesDataSourceWebPage.totalSalesAmount
    this.unitsQty = salesDataSourceWebPage.unitsSoldQty
    this.unitsSold = salesDataSourceWebPage.unitsSoldAmount

    return this
  }
}
