export enum SelectListName {
  BOOLEAN_FILTER = 'boolean-filter',
  ANNUAL_REVIEW_STATUS = 'annual-review-status',
  BUSINESS_TYPE = 'business-type',
  CTR_STATUS = 'ctr-status',
  CUSTOMER_STATUS = 'customer-status',
  CUSTOMER_STATUS_VALLIANCE = 'customer-status-valliance',
  ENTITY_TYPE = 'entity-type',
  ENTITY_TYPE_BISON = 'entity-type-bison',
  ENTITY_TYPE_SHORE = 'entity-type-shore',
  FREQUENCY = 'frequency',
  INTERNAL_TRANSFER_STATUS = 'internal-transfer-status',
  INTERNAL_TRANSFER_EXPORT_STATUS = 'internal-transfer-export-status',
  LICENSE_POS_TYPE = 'license-pos-type',
  LICENSE_SUB_TYPE = 'license-sub-type',
  LICENSE_TYPE = 'license-type',
  START_DATE_TYPE = 'start-date-type',
  DUE_START_DATE = 'due-start-date',
  DOCUMENT_PERIOD_STATUS = 'document-period-status',
  WHOLESALE_REPORT_QUEUE_STATUS = 'wholesale-report-queue-status',
  RETAIL_REPORT_QUEUE_STATUS = 'retail-report-queue-status',
  TAX_RECONCILIATION_REPORT_QUEUE_STATUS = 'tax-reconciliation-report-queue-status',
  ANNUAL_REVIEWS_STATUS = 'annual-review-status',
  STATE_OF_INCORPORATION = 'state-of-incorporation',
  ONBOARDING_APPLICATION_STATUS = 'onboarding-application-status',
  ONBOARDING_APPLICATION_STATUS_FOR_MARTIN_HOOD_AND_CREDIT = 'onboarding-application-status-for-martinhood-and-credit',
  REPEAT_STATUS = 'repeat-status',
  REMIND_STATUS = 'remind-status',
  ORGANISATIONS = 'organisations',
  SUBJECT = 'subject'
}
