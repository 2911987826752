import styled from 'styled-components'

const CheckBoxWrapper = styled.div<{ withLabel?: boolean }>`
  height: ${(p) => (p.withLabel ? 55 : 35)}px;
`

export const VisibilityBox = styled.span<{ $visible: boolean }>`
  display: ${(p) => (p.$visible ? 'inline' : 'none')};
`

export const s = { CheckBoxWrapper, VisibilityBox }
