import { createRoute, redirect } from '@tanstack/react-router'
import { CONTACTS_PATH } from 'commons/constants/routes'

import { BankContactsWrapper } from './BankContactsWrapper'
import { contactDetailsRoute } from './routes/ContactDetails/route'
import { contactsListRoute } from './routes/ContactList/route'
import { createContactRoute, editContactRoute } from './routes/ContactManage/route'

export const routeContactsTree = (rootRoute: BPRootRoute) => {
  const contactsIndexRoute = createRoute({
    beforeLoad: async ({ context }) => {
      if (!context.accessibleRoutingFlags.contactRootAccess) {
        throw redirect({
          to: '/'
        })
      }
    },
    getParentRoute: () => rootRoute,
    path: CONTACTS_PATH,
    component: BankContactsWrapper
  })

  return contactsIndexRoute.addChildren([
    createContactRoute<typeof contactsIndexRoute>(contactsIndexRoute),
    editContactRoute<typeof contactsIndexRoute>(contactsIndexRoute),
    contactDetailsRoute<typeof contactsIndexRoute>(contactsIndexRoute),
    contactsListRoute<typeof contactsIndexRoute>(contactsIndexRoute)
  ])
}
