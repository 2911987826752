import * as React from 'react'

import { AnnualReview, Company } from 'commons/types/DTO/bank'
import { formatDateTime } from 'ui/components/DateTime'
import { PageSection } from 'ui/components/Page'

import { TextLine } from './TextLine'

interface Props {
  ar?: AnnualReview
  company?: Company
  affiliatedCompanies?: Company[]
}

export const Overview = React.memo((props: Props) => {
  const { company, affiliatedCompanies, ar } = props

  const formatter = (date: string) => formatDateTime(date)?.replaceAll('/', '-')

  const dateFounded = company && company.dateFounded ? `${formatter(company.dateFounded)}` : '---'
  const lastArDate = ar && ar.last_ar_date ? `${formatter(ar.last_ar_date)}` : '---'

  return (
    <PageSection title="Overview">
      <TextLine label="Relationship Name:" value={company?.name || '---'} />
      <TextLine label="Licensed Entity Legal Name:" value={company?.legal_name || '---'} />
      <TextLine label="DBA (if applicable):" value={company?.dba || '---'} />
      <TextLine
        label="Related Companies:"
        value={affiliatedCompanies?.length ? affiliatedCompanies.map((c) => c.name).join(', ') : '-'}
      />
      <TextLine label="Date of Initial Approval:" value={dateFounded} />
      <TextLine label="Date of Last Annual Review (if applicable):" value={lastArDate} />
    </PageSection>
  )
})
