import React, { memo, useEffect } from 'react'

import { UseFormDataReturnType } from 'brief-form'
import styled from 'styled-components'

import { Layer, LayerProps } from '../../../Layer'
import { Spinner, SpinnerSize } from '../../../Spinner'
import { DataSourceItem, Table, TableColumn } from '../../../Table'
import { useTableData } from '../../hooks'
import { RemoteDataSource, TableData } from '../../types'

/**
 * DISCLAIMER!
 *
 * QueryTable is reborn of ConnectedTable component, but based on react-query.
 * Some facts about QueryTable:
 * 1. It doesn't use redux or redux saga.
 * 2. It's applicable for getting and representing data from remote server (use component/Table
 *    to show static data persisted in array).
 * 3. Can perform background data updates with given interval.
 * 4. I haven't found any use cases of parallel data fetching, so data sources are being fetched
 *    sequentially.
 * 5. If you don't want to fetch some data source, just return null from it's query function.
 */

export interface QueryTableProps<T, Data, Filter> {
  // Unique table name.
  name: string

  // Table name for dataQa-attr
  dataQa: string

  // Server Data sources.
  dataSources: RemoteDataSource<Data, Filter>[]

  // Background data refresh interval in ms.
  refreshInterval?: number

  // Background fetch indicator is visible by default, pass this flag to hide it.
  hideFetchIndicator?: boolean

  // Initial table data.
  initialData?: Data

  // UI filter value (will be passed to options builder).
  filter?: {
    value?: Filter
    component?: React.ReactNode
  }

  // Table sorting and sorting change handler.
  sorting?: {
    order: { field: string; direction: 'ASC' | 'DESC' }[]
    onChange: (value: { field: string; direction: 'ASC' | 'DESC' }) => any
  }

  // Set od table columns descriptors.
  columns: (data: any) => TableColumn<any>[]

  // Selector for transforming raw data to plain "table" representation.
  // TODO: make it more typed https://helioscompliance.atlassian.net/browse/MP-8411
  dataSourceSelector: (data: any) => DataSourceItem<T>[]

  // Table pagination parameters. If not provided, table will use client pagination.
  pagination?: {
    page: number
    size: number
    onChange: (page: number, size: number) => any
    pageSizeOptions?: string[]
    tooMany?: boolean
  }

  // Page layer properties.
  layerProperties?: LayerProps

  // ID of div containing menu.
  menuContainerId?: string

  // HTTP method to read data.
  httpMethod?: string

  // Way to pass table data to consumer.
  onReturnData?: (data: TableData) => any

  noDataMessage?: string

  form?: UseFormDataReturnType<unknown>
}

//region Styled

const Wrapper = styled.div`
  position: relative;
`

const Fetching = styled.div`
  background: white;
  color: #00c698;
  position: absolute;
  left: 16px;
  bottom: 16px;
  padding: 2px;
  border-radius: 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.5px;
  z-index: 10000;
`

//endregion

const typedMemo: <T>(c: T) => T = memo

const QueryTableComponent = <T, Data extends { [key: string]: any }, Filter extends { [key: string]: any }>(
  props: QueryTableProps<T, Data, Filter>
) => {
  const {
    name,
    dataQa,
    dataSources,
    refreshInterval,
    hideFetchIndicator,
    initialData,
    filter,
    sorting,
    layerProperties,
    columns,
    dataSourceSelector,
    pagination,
    menuContainerId,
    httpMethod,
    onReturnData,
    noDataMessage,
    form
  } = {
    hideFetchIndicator: true,
    ...props
  }

  const tableData = useTableData(
    name,
    dataSources,
    form,
    refreshInterval,
    initialData,
    filter?.value,
    pagination,
    sorting?.order,
    httpMethod
  )

  useEffect(() => {
    if (onReturnData) {
      onReturnData(tableData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData.fetching])

  const total =
    tableData.data && dataSources.length
      ? tableData.data[dataSources[0].key]?.total_count || tableData.data[dataSources[0].key]?.totalCount || 0
      : 0
  const tooMany = name === 'main-history' && total >= 10000

  return (
    <Wrapper>
      {tableData.fetching && !hideFetchIndicator && (
        <Fetching>
          <Spinner size={SpinnerSize.S} />
        </Fetching>
      )}
      <Layer {...layerProperties}>
        {filter?.component}
        {menuContainerId && <div id={menuContainerId} />}
        <Table
          dataQa={dataQa}
          loading={tableData.fetching}
          columns={columns(tableData.data)}
          dataSource={dataSourceSelector(tableData.data)}
          sorting={sorting}
          noDataMessage={noDataMessage}
          pagination={
            pagination
              ? {
                  total,
                  page: pagination.page,
                  pageSize: pagination.size,
                  onChange: pagination.onChange,
                  pageSizeOptions: pagination.pageSizeOptions,
                  tooMany
                }
              : undefined
          }
        />
      </Layer>
    </Wrapper>
  )
}

export const QueryTable = typedMemo(QueryTableComponent)
