import React, { FC } from 'react'

import { IconName } from 'ui/components'

import * as SC from './styles'

export const Chip: FC<{ label: string; onDelete: () => void }> = ({ label, onDelete }) => (
  <SC.Box>
    <SC.DeleteIcon name={IconName.CLEAR_THIN} onClick={onDelete} size={12} />
    {label}
  </SC.Box>
)
