import { AnticipatedMonthlyActivityNA } from './AnticipatedMonthlyActivityNAFormProps'

export const getAnticipatedMonthlyActivityNAList = (): AnticipatedMonthlyActivityNA[] => [
  {
    label: 'Deposits (checks)',
    items: 'activityDepositsChecksItems',
    amount: 'activityDepositsChecksAmount',
    na: 'activityDepositsChecksNA'
  },
  {
    label: 'Deposits (cash)',
    items: 'activityDepositsCashItems',
    amount: 'activityDepositsCashAmount',
    na: 'activityDepositsCashNA'
  },
  {
    label: 'Deposits (ACH, Merchant, etc.)',
    items: 'activityDepositsOtherItems',
    amount: 'activityDepositsOtherAmount',
    na: 'activityDepositsOtherNA'
  },
  {
    label: 'Withdrawals (cash)',
    items: 'activityWithdrawalsCashItems',
    amount: 'activityWithdrawalsCashAmount',
    na: 'activityWithdrawalsCashNA'
  }
]
