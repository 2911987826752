import styled from 'styled-components'
import { Icon } from 'ui/components'

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0px 20px;

  > a {
    color: ${(p) => p.theme.uiKit.colors.global.grey.grey500};
    text-decoration: none;
  }
`

export const Arrow = styled(Icon)`
  opacity: 0.3;
  position: relative;
  top: -5px;
  line-height: 26px;
  font-size: 26px;
`
