import { useEffect, useState } from 'react'

import { Toast } from 'ui/components'

import { hooks } from '../../../../hooks'
import { ResetPasswordProps } from './ResetPassword.types'

export const useResetPassword = (props: ResetPasswordProps) => {
  const { user, onClose } = props
  const [url, setUrl] = useState('')

  const { routine: getResetUrl } = hooks.useGetResetUrl({
    onSuccess: async (_, { url }) => {
      setUrl(url)
    },
    onError: (error) => Toast.errorSmall(error.message)
  })

  const { routine: sendResetLink } = hooks.useSendResetUrl({
    onSuccess: async () => {
      Toast.success('Success', 'The password reset link has been sent to the user’s email')
      onClose()
    },
    onError: (error) => Toast.errorSmall(error.message)
  })

  useEffect(() => {
    if (user) {
      getResetUrl({ userId: user.id })
    }
  }, [user])

  const sendResetEmail = () => {
    if (url && user) {
      sendResetLink({
        url,
        login: user.email
      })
    }
  }

  return {
    url,
    sendResetEmail
  }
}
