import { createEnvelopeOnboardingDocuments } from 'commons/service/bank/onboardingDocuments'
import { OnboardingDocumentSetupFormRequest } from 'commons/types/DTO/bank/applications/onboardingDocuments'
import { WriteHook, useMutationManager } from 'state-manager'

export const useCreateEnvelopeOnboardingDocuments: WriteHook<
  OnboardingDocumentSetupFormRequest & { applicationId: number },
  void
> = (opts) =>
  useMutationManager<OnboardingDocumentSetupFormRequest & { applicationId: number }, void>(
    createEnvelopeOnboardingDocuments,
    opts
  )
