import React from 'react'

import { DateTime } from 'ui/components/DateTime'
import { Link } from 'ui/components/Link'
import { TableColumn } from 'ui/components/Table'

import { CheckMark } from '../../../../components/CheckMark'

export const columns = (): TableColumn<any>[] => [
  {
    title: 'First Name',
    key: 'first_name',
    align: 'left',
    sortable: true
  },
  {
    title: 'Last Name',
    key: 'last_name',
    align: 'left',
    sortable: true
  },
  {
    title: 'Active',
    align: 'left',
    sortable: true,
    key: 'active',
    render: ({ active }) => <CheckMark active={active} />
  },
  {
    title: 'Email',
    align: 'left',
    sortable: true,
    key: 'email',
    render: ({ email }) => <Link to={`mailto:${email}`}>{email}</Link>
  },
  {
    title: 'Phone',
    key: 'phone',
    align: 'left',
    sortable: true
  },
  {
    title: 'Role',
    key: 'role',
    align: 'left',
    sortable: true
  },
  {
    title: 'Created',
    align: 'left',
    sortable: true,
    key: 'created_at',
    render: ({ created_at }) => <DateTime date={created_at} />
  },
  {
    title: 'Last login',
    align: 'left',
    sortable: true,
    key: 'last_login_at',
    render: ({ last_login_at }) => <DateTime date={last_login_at} />
  }
]
