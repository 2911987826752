import { OnboardingFormActions } from '../../../enums'
import { RemoveLicenseAction } from '../../../types/actions'

export const removeLicense = ({
  companyIndex,
  licenseIndex
}: {
  licenseIndex: number
  companyIndex: number
}): RemoveLicenseAction => ({
  type: OnboardingFormActions.REMOVE_LICENSE,
  payload: { licenseIndex, companyIndex }
})
