import React, { FC, memo, useCallback, useState } from 'react'
import { useQueryClient } from 'react-query'

import { BeforeFormChangeHandler, Form, useFormData } from 'brief-form'
import { MFA_CODE_LENGTH } from 'commons/constants/common'
import { Validators } from 'ui/components/Form/validators'
import { AuthForm, AuthInput, QR, RetryLink } from '~auth/components'
import { useChallengeExpiration } from '~auth/hooks/mfa'
import { AuthMFAState } from '~auth/types/DTO'

import { SecurityCodeFormFormShape, SecurityCodeFormProps } from './SecurityCodeFormProps'
import { getDeviceLabel } from './getDeviceLabel'

import * as SC from './styles'

export const SecurityCodeForm: FC<SecurityCodeFormProps> = memo(({ onSubmit, onRetryChallenge, logoElement }) => {
  const client = useQueryClient()
  const [error, setError] = useState<string>('')
  const mfa = client.getQueryData<AuthMFAState>(['mfa'])

  const onBeforeChange: BeforeFormChangeHandler<SecurityCodeFormFormShape> = useCallback(
    ({ value, errors }) => {
      setError('')

      if (value.code.length === MFA_CODE_LENGTH) {
        onSubmit(value).catch((error) => {
          setError(error.message)
        })
        return {
          value: { code: '' },
          errors: {}
        }
      }

      return {
        value,
        errors
      }
    },
    [setError]
  )

  const { config, Field } = useFormData<SecurityCodeFormFormShape>({
    onBeforeChange,
    initialValue: { code: '' }
  })

  const resetChallenge = useCallback(() => {
    client.setQueryData<Partial<AuthMFAState>>(['mfa'], {
      ...mfa,
      challengeId: '',
      challengeExpiresAt: ''
    })
  }, [mfa, client])

  // Start challenge expiration listener.
  useChallengeExpiration(true)

  return (
    <AuthForm error={error} logo={logoElement}>
      {mfa?.chosen && <SC.Hint>Enter security code {getDeviceLabel(mfa.chosen.type, mfa.chosen.name)}</SC.Hint>}
      <Form config={config}>
        <Field
          required
          name="code"
          input={AuthInput}
          validator={Validators.required.string}
          inputProps={{
            autoFocus: true,
            skipLocalState: true,
            placeholder: '• • • • • •',
            maxLength: 6,
            type: 'password',
            'data-lpignore': true
          }}
        />
        <QR url={mfa?.chosen?.totpUrl || ''} />
        {!mfa?.chosen?.totpUrl && (
          <SC.Footer>
            {mfa?.chosen?.type === 'totp' ? (
              <span />
            ) : (
              <RetryLink startedAt={mfa?.challengeStartedAt || 0} onRetry={onRetryChallenge} />
            )}
            {(mfa?.devices || []).length > 1 && (
              <SC.ChooseDeviceLink to="/auth/login" hash="choose-device" onClick={resetChallenge}>
                Try another device
              </SC.ChooseDeviceLink>
            )}
          </SC.Footer>
        )}
      </Form>
    </AuthForm>
  )
})
