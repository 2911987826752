import React from 'react'

import { FF, TextInput } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

export const LEContactPhoneExt = () => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name="institution_contact_info.le_contact_phone_ext"
      children={(field) => <FF label="Ext." field={field} input={(p) => <TextInput {...p} maxLength={6} />} />}
    />
  )
}
