import { useCallback, useState } from 'react'

import { isEqual } from 'lodash'

export type UseFilterStateOpts<T> = {
  // Initial filter value.
  initialValue: T
  // Whether filter panel should be initially expanded.
  initiallyExpanded?: boolean
  // Hook which is called before form should be changed. If it returns
  // undefined, no transformations applied. If any transformations are
  // needed, this function should return transformed value.
  onBeforeChange?: (value: T, oldValue: T) => T | undefined
}

type UpdateFilterOpts<T> = {
  // If "true", value should be set to initial filter value ("initialValue" from "opts").
  reset?: boolean
  // If passed, this should become a new value of filter (can be used along with "reset"
  // field to set new initial filter value). Take attention that value can be partial, so
  // it's always merged with existing value. To fully replace old value, just pass full
  // value of "T" type.
  newValue?: Partial<T>
}

export type FilterState<F> = {
  // Current filter value.
  value: F
  // Whether filter has changes.
  isDirty: boolean
  // Filter panel "expanded" state,
  expanded: boolean
  // Toggle panel "expanded" state.
  toggle: () => void
  // Set filter value. If used with "reset" flag, new value will become new initial filter value.
  set: (opts: UpdateFilterOpts<F>) => void
}

export const useFilterFormState = <F>(opts: UseFilterStateOpts<F>): FilterState<F> => {
  const { initialValue, initiallyExpanded, onBeforeChange } = opts
  const [initialFilterValue, setInitialFilterValue] = useState(initialValue)
  const [value, setValue] = useState(initialValue)
  const [expanded, setExpanded] = useState(initiallyExpanded || false)
  const toggle = () => setExpanded((e) => !e)
  const isDirty = !isEqual(value, initialFilterValue)

  const set: FilterState<F>['set'] = useCallback(
    (opts) => {
      const { newValue, reset } = opts

      if (reset) {
        if (newValue) {
          setInitialFilterValue((old) => ({ ...old, ...newValue }))
        }
        setValue((old) => ({ ...old, ...(newValue || initialFilterValue) }))
      } else {
        if (newValue) {
          setValue((old) => {
            const value1 = { ...old, ...newValue }
            const valuesAreEqual = isEqual(value1, value)
            const value2 = onBeforeChange && !valuesAreEqual ? onBeforeChange(value1, value) : undefined
            return value2 || value1
          })
        }
      }
    },
    [initialFilterValue, onBeforeChange, value]
  )

  return {
    value,
    isDirty,
    expanded,
    toggle,
    set
  }
}
