import React from 'react'

import { FF, TextInput } from 'ui/components'

import { useSARFormContext } from '../../../../../../../../context'
import { useSubjectInformationItemContext } from '../../../SubjectInformationItemContext'

type FirstNameProps = {
  index: number
}

export const FirstName = ({ index }: FirstNameProps) => {
  const { formApi } = useSARFormContext()
  const { isAllInfoUnavailable } = useSubjectInformationItemContext()

  return (
    <formApi.Field
      name={`subjects_info[${index}].first_name` as 'subjects_info[0].first_name'}
      children={(field) => (
        <FF field={field} label="5. First name" input={(p) => <TextInput {...p} disabled={isAllInfoUnavailable} />} />
      )}
    />
  )
}
