import { AnnualReviewSalesDeposits, LicenseDeprecated } from 'commons/types/DTO/bank'
import { DataSourceItem } from 'ui/components'

export type LicenseRecordsResponse = {
  license_list: {
    records: LicenseDeprecated[]
    total_count: number
  }
}

export type AnnualReviewLicensingTableSelector = LicenseDeprecated & {
  status?: string | null
  depositId?: number
}

// eslint-disable-next-line
export const selector =
  (deposits: AnnualReviewSalesDeposits[]) =>
  (data: LicenseRecordsResponse): DataSourceItem<AnnualReviewLicensingTableSelector>[] => {
    if (!data || Object.keys(data).length === 0) {
      return []
    }

    return data.license_list.records.map((i) => {
      const deposit = deposits.find((d) => d.license_id === i.id)

      return {
        item: {
          ...i,
          status: deposit?.status || null,
          depositId: deposit?.id || 0
        }
      }
    })
  }
