import styled from 'styled-components'

const Row = styled.div`
  display: flex;

  > * {
    width: 400px;

    &:first-child {
      margin-right: 100px;
    }

    label {
      margin: 0 0 5px 0;
    }
  }
`

export const s = { Row }
