import { useQuery } from 'react-query'

import { AnnualReview } from 'commons/types/DTO/bank'
import { ApiReadResponse } from 'commons/types/api'
import { annualReviewList } from '~bank-annual-review/service'

interface AnnualReviewProp {
  company_id?: number
  id?: number
}

export const useAnnualReviewList = (
  props: AnnualReviewProp,
  suppressFetch?: boolean
): { isFetching: boolean; data?: ApiReadResponse<AnnualReview> } => {
  const { data, isFetching } = useQuery<any, Error>(
    ['annual-review-list', props.id],
    () => (suppressFetch ? Promise.resolve({ isFetching: false, data: undefined }) : annualReviewList(props)),
    { keepPreviousData: true }
  )

  return {
    isFetching,
    data
  }
}
