import React, { memo } from 'react'

import { Spinner, SpinnerSize } from 'ui/components'
import { Layer } from 'ui/components/Layer'
import { Page, PageFace } from 'ui/components/Page'

import { Actions } from './components/Actions'
import { AdverseMediaListTable } from './components/AdverseMediaListTable'
import { Filter } from './components/Filter'
import { useAdverseMediaList } from './hooks'

const List = memo(() => {
  const { data, isFetching, pagination, form, termChangeHangler } = useAdverseMediaList()

  return (
    <Page
      face={PageFace.PRIMARY}
      title="Adverse Media"
      actions={!!data && <Actions searchTerm={form.config.value.searchCase} onSearchTermChange={termChangeHangler} />}
    >
      {isFetching && !data && <Spinner centered size={SpinnerSize.M} />}
      {!!data && (
        <Layer rounded shadowed>
          <Filter riskLevelOptions={data?.riskLevels || []} entityTypeOptions={data?.entityTypes || []} form={form} />
          <AdverseMediaListTable data={data} isLoading={isFetching} pagination={pagination} />
        </Layer>
      )}
    </Page>
  )
})

export default List
