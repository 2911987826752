import { HistoryChanges } from './HistoryChanges'
import { HistoryLink } from './HistoryLink'
import { UserHistoryInfo } from './UserHistoryInfo'

export type HistoryEntity = {
  action: number
  actionLabel: string
  date: Date
  details: HistoryChanges[]
  entityId: string
  entityType: number
  entityTypeLabel: string
  id: number
  links: HistoryLink[]
  message: string
  protoType: number
  userInfo: UserHistoryInfo
}
