import React, { FC, memo } from 'react'

import { Icon, IconName, IconSize } from 'ui/components'

import { LoginButtonProps } from './LoginButtonProps'

import * as SC from './styles'

export const LoginButton: FC<LoginButtonProps> = memo((props) => {
  const { children, loading, 'data-qa': dataQA, ...rest } = props

  const data_qa = typeof children === 'string' && !dataQA ? children : dataQA

  return (
    <>
      {loading ? (
        <SC.BoxLoading disabled data-qa={data_qa} {...rest}>
          <SC.Inner>
            {children} <SC.Spinner size={24} />
          </SC.Inner>
        </SC.BoxLoading>
      ) : (
        <SC.Box data-qa={data_qa} {...rest}>
          <SC.Inner>
            {children} <Icon size={IconSize.L} name={IconName.ARROW_NEXT} />
          </SC.Inner>
        </SC.Box>
      )}
    </>
  )
})
