import styled, { css } from 'styled-components'
import { Icon } from 'ui/components'
import { CSS } from 'ui/helpers'

export const Box = styled.div`
  border-radius: 8px;
  overflow: hidden;
  box-shadow: ${(p) => CSS.populateBoxShadow(p.theme.uiKit.boxShadows.offset.offset100Y4)};
  margin-bottom: 32px;
  margin-top: 32px;
`

export const Header = styled.div<{ $opened: boolean; $warning: boolean }>`
  cursor: pointer;
  height: ${(p) => (p.$opened ? '68px' : '52px')};
  display: flex;
  align-items: center;
  padding: 0 20px;
  ${(p) =>
    p.$opened
      ? css`
          border-top: 2px solid
            ${p.$warning ? p.theme.uiKit.colors.theme.negative.main : p.theme.uiKit.colors.global.grey.grey900};
        `
      : ''};
`

export const Content = styled.div``

export const Title = styled.div`
  ${(p) => CSS.populateFont(p.theme.uiKit.typography.h5.semiBold)};
  display: flex;
  align-items: center;
`

export const WrappedIcon = styled(Icon)`
  margin-right: 8px;
  svg {
    fill: black;
  }
`

export const Action = styled.div`
  margin-left: auto;
`
