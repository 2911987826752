import styled from 'styled-components'

export const Row = styled.div`
  padding: 6px 0;
  border-bottom: #ddd 1px solid;

  &:last-child {
    border-bottom: none;
  }
`

export const SwitcherLink = styled.a`
  margin-left: 10px;
  font-style: italic;
`

export const MultiLinerRow = styled(Row)`
  white-space: pre;
`

export const SC = { Row, SwitcherLink, MultiLinerRow }
