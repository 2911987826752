import React, { memo } from 'react'

import SC from 'styled-components'
import { IconButton } from 'ui/components/Buttons'
import { IconName } from 'ui/components/Icon'

interface Props {
  onDeleteBatches: () => void
  hasChosenItems: boolean
}

//region Styles

const Box = SC.footer`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  height: 66px;
  display: flex;
  align-items: center;
  padding: 0 48px 0 272px;
  border-top: 1px solid ${(p) => p.theme.uiKit.colors.global.grey.grey300};
`

//endregion

export const Footer = memo((props: Props) => {
  const { hasChosenItems, onDeleteBatches } = props

  if (!hasChosenItems) {
    return null
  }

  return (
    <Box>
      {hasChosenItems && (
        <IconButton face="negative" icon={IconName.DELETE} onClick={onDeleteBatches}>
          Delete Batch(es)
        </IconButton>
      )}
    </Box>
  )
})
