import { useMemo } from 'react'

import { useRouteContext } from '@tanstack/react-router'
import {
  ADVERSE_MEDIA_PATH,
  BANK_ALERTS,
  BANK_CLIENT_USERS_PATH,
  BANK_USERS_PATH,
  BSA_MODULE_PATH,
  BankApplicationPaths,
  COMPANIES_PATH,
  CONTACTS_PATH,
  DOCUMENTS_PATH,
  HISTORY_PATH,
  INTERNAL_TRANSFERS_PATH,
  KEY_CONNECTIONS_PATH,
  REPORTS_PATH
} from 'commons/constants/routes'

import { allMenuItems } from './allMenuItems'

export const useGetMenuItems = () => {
  const context: BPRoutingContext = useRouteContext({ from: '' })

  const menuItems = useMemo(
    () =>
      allMenuItems.filter((item) => {
        if (item.to === COMPANIES_PATH && context.accessibleRoutingFlags.companyRootAccess) {
          return true
        }
        if (item.to === BANK_ALERTS && context.accessibleRoutingFlags.alertRootAccess) {
          return true
        }
        if (item.to === DOCUMENTS_PATH && context.accessibleRoutingFlags.documentRootAccess) {
          return true
        }
        if (item.to === INTERNAL_TRANSFERS_PATH && context.accessibleRoutingFlags.internalTransferRootAccess) {
          return true
        }
        if (item.to === CONTACTS_PATH && context.accessibleRoutingFlags.contactRootAccess) {
          return true
        }
        if (item.to === BANK_USERS_PATH && context.accessibleRoutingFlags.userRootAccess) {
          return true
        }
        if (item.to === BankApplicationPaths.LIST && context.accessibleRoutingFlags.onboardingRootAccess) {
          return true
        }
        if (item.to === HISTORY_PATH && context.accessibleRoutingFlags.historyRootAccess) {
          return true
        }
        if (item.to === BSA_MODULE_PATH && context.accessibleRoutingFlags.bsaRootAccess) {
          return true
        }
        if (item.to === ADVERSE_MEDIA_PATH && context.accessibleRoutingFlags.adverseMediaRootAccess) {
          return true
        }
        if (item.to === REPORTS_PATH && context.accessibleRoutingFlags.reportRootAccess) {
          return true
        }
        if (item.to === BANK_CLIENT_USERS_PATH && context.accessibleRoutingFlags.clientUserRootAccess) {
          return true
        }
        if (item.to === KEY_CONNECTIONS_PATH && context.accessibleRoutingFlags.connectionRootAccess) {
          return true
        }
        return false
      }),
    [context]
  )

  return {
    menuItems
  }
}
