import styled from 'styled-components'
import { Link } from 'ui/components'

export const Quarter = styled(Link)`
  a,
  a:hover,
  a:focus,
  a:visited {
    color: ${(p) => p.theme.uiKit.colors.global.grey.grey500};
    text-decoration: none;
  }
`

export const Month = styled.span`
  color: ${(p) => p.theme.uiKit.colors.global.general.secondary};
  margin-left: 10px;
  display: block;
`

export const Arrow = styled.b`
  display: block;
  margin-left: 10px;
  color: ${(p) => p.theme.uiKit.colors.global.grey.grey500};
`
