import { getLicenseWebPage } from 'commons/service/bank/license'
import { LicenseWebPageRequest, LicenseWebPageResponse } from 'commons/types/DTO/bank'
import { Keys, ReadHook, useStateManager } from 'state-manager'

export const useGetLicenseWebPage: ReadHook<LicenseWebPageResponse, LicenseWebPageRequest> = (opts, config) => {
  const sm = useStateManager()

  return sm.use(Keys.License.Details(opts), () => getLicenseWebPage(opts), {
    keepPreviousData: true,
    onSuccess: config.onSuccess,
    onError: config.onError
  })
}
