import styled from 'styled-components'
import { Icon } from 'ui/components/Icon'

export const HelpUl = styled.ul`
  padding-left: 17px;
  margin-bottom: 0;
`

export const HintTitle = styled.div`
  margin-bottom: 10px;
`

export const ErrorTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`

export const WarningIcon = styled(Icon)`
  margin-right: 10px;
`
