import React, { memo } from 'react'

import { ContactListPage } from './components'
import { useContactList } from './hooks'

const ContactList = memo(() => {
  const {
    webPageData: data,
    tableState,
    isFetching,
    filterForm,
    onSearch,
    changePagination,
    DeleteConfirmation,
    startContactDelete
  } = useContactList()

  return (
    <ContactListPage
      webPageData={data}
      tableState={tableState}
      isFetching={isFetching}
      filterForm={filterForm}
      onSearch={onSearch}
      changePagination={changePagination}
      DeleteConfirmation={DeleteConfirmation}
      startContactDelete={startContactDelete}
    />
  )
})

export default ContactList
