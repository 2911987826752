import { ApplicationRelationshipBankAccountsItem } from 'commons/types/DTO/bank/applications'

import { newApplicationBankAccount } from '../newApplicationBankAccount'
import { getRelationshipBankAccountsFormData } from './getRelationshipBankAccountsFormData'

export const getFormDataList = ({
  selectedRelationshipIndex,
  relationships
}: {
  selectedRelationshipIndex: number
  relationships: ApplicationRelationshipBankAccountsItem[]
}) => {
  const selectedRelationship = relationships[selectedRelationshipIndex]

  const formDataListOfExistsBankAccounts = getRelationshipBankAccountsFormData({
    relationship: selectedRelationship
  })

  const formDataList = {
    ...formDataListOfExistsBankAccounts,
    new: { value: newApplicationBankAccount, errors: {} }
  }

  return formDataList
}
