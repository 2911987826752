export enum InternalTransfersType {
  SENDING_AND_RECEIVING = 'Sending & Receiving',
  SENDING_ONLY = 'Sending Only',
  RECEIVING_ONLY = 'Receiving Only',
  DISABLED = 'Disabled'
}

export const SENDING_AND_RECEIVING = 0
export const SENDING_ONLY = 1
export const RECEIVING_ONLY = 2
export const DISABLED = 3

const typesMapping: { [key: number]: InternalTransfersType | undefined } = {
  [SENDING_AND_RECEIVING]: InternalTransfersType.SENDING_AND_RECEIVING,
  [SENDING_ONLY]: InternalTransfersType.SENDING_ONLY,
  [RECEIVING_ONLY]: InternalTransfersType.RECEIVING_ONLY,
  [DISABLED]: InternalTransfersType.DISABLED
}

export const getInternalTransfersType = (id: number): string => typesMapping[id]
