import React, { FC, memo, useCallback, useEffect, useState } from 'react'

import { getRouteApi } from '@tanstack/react-router'
import { Link } from '@tanstack/react-router'
import { NotFoundError } from 'commons/errors'
import { useGetBankName } from 'commons/hooks/bank/organisation'
import { LicenseDeprecated } from 'commons/types/DTO/bank'
import { BankName } from 'commons/types/enums'
import { ButtonGroup } from 'ui/components/ButtonGroup'
import { HeaderButton, IconButton } from 'ui/components/Buttons'
import { IconName } from 'ui/components/Icon'
import { Page, PageFace } from 'ui/components/Page'
import { useConfirmation } from 'ui/hooks'
import {
  useAffiliatedCompanies,
  useAnnualReviewList,
  useCompany,
  useDeleteAnnualReview,
  useUpdateAnnualReview
} from '~bank-annual-review/hooks'

import { getAnnualReviewDetailUrl, getAnnualReviewEditUrl } from '../route'
import { AnnualReviewInfo } from './components/AnnualReviewInfo'
import { AnnualReviewLicensing } from './components/AnnualReviewLicensing'
import { AnnualReviewQuestions } from './components/AnnualReviewQuestions'
import { AnnualReviewSummaryTables } from './components/AnnualReviewSummaryTables'

const AnnualReviewDetail: FC = memo(() => {
  const { id, companyId } = getRouteApi('/annual-review/detail/$companyId/$id').useParams()
  const [licenses, setLicenses] = useState<LicenseDeprecated[]>([])
  const { id: bankId } = useGetBankName()
  const isCBT = bankId === BankName.CBT
  const intId = +id
  const intCompanyId = +companyId
  const updateAnnualReviewMutation = useUpdateAnnualReview(intCompanyId, intId)

  const { data: annualReviewList, isFetching: isAnnualReviewListLoading } = useAnnualReviewList({
    company_id: intCompanyId,
    id: intId
  })
  const deleteMutation = useDeleteAnnualReview(intCompanyId)
  const { data: companyList, isFetching: isCompanyListFetching } = useCompany({ id: intCompanyId })
  const affiliatedCompaniesList = useAffiliatedCompanies({ company_id: intCompanyId })

  const company = companyList?.records[0]
  const annualReview = annualReviewList?.records[0]

  useEffect(() => {
    const _404 = () => {
      throw new NotFoundError()
    }
    if (intId === 0 || intCompanyId === 0 || isNaN(intId) || isNaN(intCompanyId)) {
      _404()
    } else if (!isCompanyListFetching && !isAnnualReviewListLoading) {
      if (companyList?.total_count != 1 || !annualReview || annualReview.company_id !== intCompanyId) {
        _404()
      }
    }
  }, [
    intId,
    intCompanyId,
    companyList?.total_count, // companyList not empty if id=0, looks backend bug
    annualReview,
    isCompanyListFetching,
    isAnnualReviewListLoading
  ])

  const affiliatedCompanies = affiliatedCompaniesList.data?.records?.filter((item) => item.id !== intCompanyId)

  const showRecalculateBtn = annualReview?.status !== 'approved'

  const deleteReview = useCallback(() => {
    deleteMutation.mutate({
      id: intId,
      company_id: 0,
      annual_review_id: 0
    })
  }, [deleteMutation, intId])

  const { Confirmation: DeleteConfirmation, open: openDeleteConfirmation } = useConfirmation({
    message: `Are you sure want to delete "AR-${annualReview?.id}" Annual Review?`,
    onConfirm: deleteReview,
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  const renderActions = () => (
    <ButtonGroup margin="big">
      <Link to={getAnnualReviewDetailUrl({ id, companyId }) + '/print'} target="_blank">
        <HeaderButton size="middle" overColoredPad={true}>
          Print PDF
        </HeaderButton>
      </Link>
      <IconButton face="neutral" icon={IconName.DELETE} onClick={openDeleteConfirmation}>
        Delete Review
      </IconButton>
    </ButtonGroup>
  )

  const onReturnData = (data: LicenseDeprecated[]): void => setLicenses(data)

  return (
    <Page
      isPending={false}
      title="MRB Annual Relationship Review"
      face={PageFace.SECONDARY}
      subTitle={`AR-${id}`}
      actions={renderActions()}
    >
      <DeleteConfirmation />
      {annualReview && company && (
        <AnnualReviewInfo
          company={company}
          annualReview={annualReview}
          affiliatedCompanies={affiliatedCompanies || []}
          editUrl={getAnnualReviewEditUrl({
            companyId: intCompanyId,
            id: intId
          })}
          onApprove={() => {
            updateAnnualReviewMutation.mutate({
              ...annualReview,
              status: 'approved'
            })
          }}
        />
      )}
      {annualReview && annualReview.questionnaire && (
        <AnnualReviewLicensing
          id={intId}
          companyId={intCompanyId}
          questionnaire={annualReview.questionnaire}
          onReturnData={onReturnData}
        />
      )}
      {!(annualReview?.status === 'new' && annualReview.created_at === annualReview.updated_at) &&
        annualReview?.questionnaire && (
          <AnnualReviewQuestions questionnaire={annualReview?.questionnaire} isCBT={isCBT} />
        )}
      <AnnualReviewSummaryTables
        id={intId}
        companyId={intCompanyId}
        showRecalculateBtn={showRecalculateBtn}
        licenses={licenses}
      />
    </Page>
  )
})

export default AnnualReviewDetail
