import React, { FC, memo, useEffect, useMemo, useState } from 'react'

import { useNavigate } from '@tanstack/react-router'
import { DocumentsPaths } from 'bank-documents'
import { COMPANIES_PATH } from 'commons/constants/routes'
import { useGetBankSettings } from 'commons/hooks/bank/settings'
import { CompanyDocumentsWebPage, Document } from 'commons/types/DTO/bank/companyDetails/document'
import { TableDataQa } from 'commons/types/enums'
import { handlerServerError } from 'errors'
import { DataSourceItem, Layer, PageSection, PageSectionFace, Table } from 'ui/components'
import { ButtonGroup } from 'ui/components/ButtonGroup'
import { HeaderButton } from 'ui/components/Buttons'

import { CompanyDocumentsTableProps } from './CompanyDocumentsTableProps'
import { columns } from './columns'
import { Filter } from './components/Filter'
import { useComponentsEssence } from './hooks/useComponentsEssence'
import { useFilterCompanyDocumentsWebPage } from './hooks/useFilterCompanyDocumentsWebPage'

export const CompanyDocumentsTable: FC<CompanyDocumentsTableProps> = memo(({ companyId, webPage }) => {
  const navigate = useNavigate()
  const { data: settings } = useGetBankSettings()
  const returnUrl = encodeURIComponent(`${COMPANIES_PATH}/${companyId}#documents`)

  const firstAlert = settings?.bankDocumentFirstNotificationBeforeExpiration || 0
  const initialAlert = settings?.bankDocumentPreNotificationBeforeExpiration || 0
  const lastAlert = settings?.bankDocumentLastNotificationBeforeExpiration || 0

  const [webPageData, setWebPageData] = useState<CompanyDocumentsWebPage>({
    alertsOptions: webPage.alertsOptions,
    frequencyOptions: webPage.frequencyOptions,
    internalOptions: webPage.internalOptions,
    subjectTypeOptions: webPage.subjectTypeOptions,
    config: webPage.config,
    documents: webPage.documents,
    documentsTotalCount: webPage.documentsTotalCount
  })

  useEffect(() => {
    setWebPageData({
      alertsOptions: webPage.alertsOptions,
      frequencyOptions: webPage.frequencyOptions,
      internalOptions: webPage.internalOptions,
      subjectTypeOptions: webPage.subjectTypeOptions,
      config: webPage.config,
      documents: webPage.documents,
      documentsTotalCount: webPage.documentsTotalCount
    })
  }, [
    webPage.documents,
    webPage.documentsTotalCount,
    webPage.config,
    webPage.subjectTypeOptions,
    webPage.internalOptions,
    webPage.frequencyOptions,
    webPage.alertsOptions
  ])

  const { routine: filterCompanyDocumentsWebPage } = useFilterCompanyDocumentsWebPage({
    onSuccess: async (_, result) => {
      setWebPageData(result)
    },
    onError: (error) => handlerServerError(error)
  })

  const {
    loading,
    tableState,
    filterForm,
    changePagination,
    onSort,
    DeleteConfirmation,
    onDeleteDocument,
    clearFilter
  } = useComponentsEssence(filterCompanyDocumentsWebPage, webPageData)

  const mappedDocuments: DataSourceItem<Document>[] = useMemo(
    () =>
      webPageData.documents.map((el) => ({
        item: el
      })),
    [webPageData]
  )

  const memoizedColumns = useMemo(
    () => columns(initialAlert, firstAlert, lastAlert, onDeleteDocument, returnUrl),
    [settings]
  )

  return (
    <PageSection
      face={PageSectionFace.PRIMARY}
      title="Documents"
      anchor="documents"
      actions={
        <ButtonGroup margin="small">
          <HeaderButton
            size="middle"
            onClick={() =>
              navigate({
                to: DocumentsPaths.NEW,
                search: {
                  subjectType: 'company',
                  companyId: companyId,
                  subjectName: webPage.name,
                  returnUrl: returnUrl
                }
              })
            }
          >
            Add New
          </HeaderButton>
        </ButtonGroup>
      }
    >
      <DeleteConfirmation />
      <Layer rounded shadowed stretch>
        <Filter
          form={filterForm}
          expanded={tableState.expanded}
          onToggle={tableState.onFilterToggle}
          webPage={webPage}
          onClear={clearFilter}
        />
        <Table<Document>
          dataQa={TableDataQa.BP_COMPANY_DOCUMENTS}
          columns={memoizedColumns}
          dataSource={mappedDocuments}
          loading={loading}
          pagination={{
            page: tableState.page,
            pageSize: tableState.size,
            onChange: changePagination,
            total: webPageData.documentsTotalCount
          }}
          sorting={{ order: tableState.order || [], onChange: (value) => onSort(value.direction, value.field) }}
        />
      </Layer>
    </PageSection>
  )
})
