import React, { FC, memo } from 'react'

import { useRouterState } from '@tanstack/react-router'
import { Form } from 'brief-form'
import { CheckBox, CompanySelect, ContactSelect, MultiSelectAutoSuggest } from 'ui/components'
import { PercentInput, TextInput } from 'ui/components/InputV2'
import { Col, FormRow } from 'ui/themes/globalStyles'

import { CONTACTS_PATH } from '../../../../../../constants'
import { ContactMetadataManageFormProps } from './ContactMetadataManageFormProps'

import { EditLink, FormBody } from './styles'

export const ContactMetadataManageForm: FC<ContactMetadataManageFormProps> = memo((props) => {
  const { config, Field, contact, companyId } = props

  const finalContactId = contact?.id || config.value.contact_id
  const { hash, pathname } = useRouterState({ select: (s) => s.location })
  const returnUrl = pathname + hash

  const emptyContactOption =
    contact?.id || config.value.id
      ? {
          label: `${contact?.first_name || ''} ${contact?.last_name || ''}`,
          value: contact?.id || config.value.id || 0
        }
      : undefined

  return (
    <Form config={config}>
      <FormBody>
        <FormRow>
          <Col>
            <Field
              required
              name="company_id"
              label="Company"
              input={CompanySelect}
              inputProps={{
                hideMyCompaniesFilter: true,
                disabled: !!companyId || !!config.value.id,
                'data-qa': 'Company',
                'options-data-qa': 'Company'
              }}
              validator={(v) => (v ? undefined : 'Company required')}
            />
          </Col>
          <Col>
            <Field
              required
              name="contact_id"
              label="Contact"
              input={ContactSelect}
              inputProps={{
                emptyOption: emptyContactOption,
                disabled: !!contact?.id || !!config.value.id
              }}
              validator={(v) => (v ? undefined : 'Contact required')}
            />
          </Col>
        </FormRow>
        <FormRow>
          <Col>
            <Field
              required
              name="title"
              label="Title"
              input={TextInput}
              inputProps={{
                'data-qa': 'Title'
              }}
              validator={(v) => (v?.toString().length ? undefined : 'Title required')}
            />
          </Col>
          <Col>
            <Field
              name="permissions"
              label="Permissions"
              input={MultiSelectAutoSuggest}
              inputProps={{
                options: [
                  { label: 'Documents', value: 'documents' },
                  { label: 'Financials', value: 'financials' }
                ],
                valueTitle: 'Choose permissions',
                'data-qa': 'Permissions'
              }}
            />
          </Col>
        </FormRow>
        <FormRow>
          <Col>
            <Field
              name="is_owner"
              label="Owner"
              input={CheckBox}
              inputProps={{
                'data-qa': 'Owner'
              }}
            />
          </Col>
          <Col>
            <Field name="is_debtholder" label="Debtholder" input={CheckBox} inputProps={{}} />
          </Col>
        </FormRow>
        <FormRow>
          <Col>
            <Field
              name="ownership"
              label="Ownership %"
              input={PercentInput}
              inputProps={{
                'data-qa': 'Ownership %'
              }}
            />
          </Col>
          <Col>
            <Field name="is_account_signer" label="Account Signer" input={CheckBox} inputProps={{}} />
          </Col>
        </FormRow>
        {!!finalContactId && (
          <EditLink to={`${CONTACTS_PATH}/${finalContactId}/edit?returnUrl=${encodeURIComponent(returnUrl)}`}>
            Edit Contact Info
          </EditLink>
        )}
      </FormBody>
    </Form>
  )
})
