import styled from 'styled-components'
import { CSS } from 'ui/helpers'

export const Container = styled.div`
  margin: 0;
  width: 100%;
`

export const Error = styled.span<{ error: boolean }>`
  opacity: ${(p) => (p.error ? 1 : 0)};
  color: ${(p) => p.theme.uiKit.colors.theme.negative.main};
  ${(p) => CSS.populateFont(p.theme.uiKit.typography.bodyS.regular)};
  vertical-align: top;
  margin-top: 3px;
  display: inline-block;
`

export const ErrorWrapperRight = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  label {
    margin-bottom: 0;
  }
`
