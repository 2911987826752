import { useCallback } from 'react'
import { useMutation } from 'react-query'

import { revertApplicationToRFI as revertApplicationService } from '../service'

type UseRevertApplicationReturnValue = {
  revertApplicationToRFI: (id: number, notes: string) => Promise<{ id: number; notes: string }>
}

export const useRevertApplication = (): UseRevertApplicationReturnValue => {
  const mutation = useMutation(({ id, notes }: { id: number; notes: string }) => revertApplicationService(id, notes))

  const revertApplicationToRFI = useCallback(
    (id: number, notes: string) => mutation.mutateAsync({ id, notes }),
    [mutation]
  )

  return {
    revertApplicationToRFI
  }
}
