import React, { FC, memo, useMemo } from 'react'

import { DailyReport } from 'commons/types/DTO/bank/salesDataSourceWebPage/DailyReport'
import { TableDataQa } from 'commons/types/enums'
import { DataSourceItem, IconName, IconSize, Layer, PageSection, Table } from 'ui/components'
import { DownloadLink } from 'ui/components/DownloadLink'

import { MetrcSourceTableProps } from './MetrcSourceTableProps'

import { ErrorIcon } from './styles'

export const MetrcSourceTable: FC<MetrcSourceTableProps> = memo((props) => {
  const { webPage } = props

  const dataSource: DataSourceItem<DailyReport>[] = useMemo(
    () =>
      webPage.dailyReports.map((daily) => ({
        item: daily,
        rowProps: { errorHighlight: daily.alert.length > 0 }
      })),
    [webPage.dailyReports]
  )

  return (
    <PageSection
      title="Daily Metrc Source"
      anchor="metrc"
      actions={
        <DownloadLink
          direct
          parametersForm={{
            salesDataSourceId: webPage.salesDataSourceID
          }}
          handleError={() => ({})}
          baseUrl="/bank/v1/sales-data/sics-source-download-csv"
          name={'Metrc Source.csv'}
        >
          Download .csv
        </DownloadLink>
      }
    >
      <Layer shadowed rounded>
        <Table<DailyReport>
          dataQa={TableDataQa.BP_SALESDATA_SOURCE_DETAILS_METRC_SOURCE}
          columns={[
            {
              title: 'Date',
              key: 'date',
              sortable: false,
              align: 'left'
            },
            {
              title: 'Alert',
              sortable: false,
              align: 'left',
              render: ({ alert }) => (
                <>
                  {alert.length > 0 && (
                    <>
                      <ErrorIcon size={IconSize.S} name={IconName.ERROR} /> {alert}
                    </>
                  )}
                </>
              )
            },
            {
              title: 'Grams Sold #',
              key: 'gramsSoldQty',
              sortable: false,
              align: 'left'
            },
            {
              title: 'Grams Sold $',
              key: 'gramsSoldAmount',
              sortable: false,
              align: 'left'
            },
            {
              title: 'Units Sold #',
              key: 'unitsSoldQty',
              sortable: false,
              align: 'left'
            },
            {
              title: 'Units Sold $',
              key: 'unitsSoldAmount',
              sortable: false,
              align: 'left'
            }
          ]}
          dataSource={dataSource}
        />
      </Layer>
    </PageSection>
  )
})
