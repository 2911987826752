import styled from 'styled-components'

import { PageFace } from '../../pageFace'

export const Box = styled.header<{ face?: PageFace }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 94px;
  flex-shrink: 0;
  padding: 0 50px;
  background: ${(p) =>
    p.face === PageFace.SECONDARY
      ? p.theme.uiKit.colors.theme.brand.primary
      : p.face === PageFace.DISABLED
      ? p.theme.uiKit.colors.global.grey.grey700
      : 'transparent'};
  border-top-left-radius: ${(p) => (p.face === PageFace.SECONDARY ? 32 : 0)}px;
  color: ${(p) => (p.face === PageFace.PRIMARY ? p.theme.uiKit.colors.global.general.secondary : '#fff')};
`

export const Caption = styled.div`
  flex: 1;
  overflow: hidden;
  text-align: left;
`

export const Actions = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
`
