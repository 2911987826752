import * as React from 'react'

interface Props {
  id: string
  name: string
  children: any
  suspended?: boolean
}

export const Profiler = React.memo((props: Props) => {
  const { id, name, children, suspended } = props

  if (suspended) {
    return children
  }

  const onRender = React.useCallback(
    (
      id: string,
      phase: 'mount' | 'update',
      actualDuration: number,
      baseDuration: number,
      startTime: number,
      commitTime: number
    ) => {
      console.log(name, {
        Id: id,
        Phase: phase,
        Duration: actualDuration,
        'Base Duration': baseDuration,
        'Start Time': startTime,
        'Commit Time': commitTime
      })
    },
    [name]
  )

  return (
    <React.Profiler id={id} onRender={onRender}>
      {children}
    </React.Profiler>
  )
})
