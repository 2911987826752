import { AddBankAccountValidateFunctionRefs, ApplicationBankAccountsFormDataList } from '../../../types'
import { LicensesCurrentState } from '../types'
import { isExistsUnAssigned } from './isExistsUnAssigned'
import { validateFormByKey } from './validateFormByKey'

export const doFormsValidate = ({
  formDataList,
  formsValidateData,
  licensesCurrentState
}: {
  formDataList: ApplicationBankAccountsFormDataList
  formsValidateData: AddBankAccountValidateFunctionRefs
  licensesCurrentState: LicensesCurrentState
}): { shouldTerminate: boolean; shouldSetErrors: boolean } => {
  if (isExistsUnAssigned({ licenses: licensesCurrentState.licensesWithoutNewForm })) {
    Object.keys(formsValidateData).forEach((formKey) => {
      validateFormByKey({
        formValidateData: formsValidateData[formKey],
        formValue: formDataList[formKey],
        withFormUpdate: true
      })
    })
    return { shouldTerminate: true, shouldSetErrors: true }
  }

  return { shouldTerminate: false, shouldSetErrors: false }
}
