import React from 'react'

import { CheckBox, FF } from 'ui/components'

import { useSARFormContext } from '../../../../../../../context'

export const A = () => {
  const { formApi } = useSARFormContext()
  return (
    <formApi.Field
      name="transactions_info.payment_mechanisms.a"
      children={(field) => (
        <FF
          field={field}
          label="46. Were any of the following instrument type(s)/payment mechanizm(s) involved in the suspicious activity?"
          input={(p) => <CheckBox {...p} checkBoxLabel="a. Bank/Cashier's check" />}
          errorPosition="right"
        />
      )}
    />
  )
}
