import { ContactCopyHandlerParams, ContactDetailsFormData, ContactItemFormValue } from '../../../types/forms'
import { CompanyFormValues, OnboardingFormState, OnboardingFormsData } from '../../../types/state'

export const getStateAfterContactCopy = ({
  currentState,
  copyData
}: {
  currentState: OnboardingFormState
  copyData: ContactCopyHandlerParams
}): OnboardingFormState => {
  const { formValue } = currentState
  const { fromCompanyIndex, fromContactIndex, toCompanyIndex, toContactIndex } = copyData
  const oldCompany = formValue.companiesFormValues[toCompanyIndex]
  const oldContact = oldCompany.contacts[toContactIndex]
  const srcContactDetailsFormData = formValue.companiesFormValues[fromCompanyIndex].contacts[fromContactIndex].details
  const newDetails: ContactDetailsFormData = {
    formValues: { ...srcContactDetailsFormData.formValues, title: oldContact.details.formValues.title },
    formErrors: { ...srcContactDetailsFormData.formErrors }
  }
  const newContact: ContactItemFormValue = { ...oldContact, details: newDetails }
  const newContacts: ContactItemFormValue[] = oldCompany.contacts.map((contactItem, contactIndex) =>
    contactIndex === toContactIndex ? newContact : contactItem
  )
  const newCompany: CompanyFormValues = { ...oldCompany, contacts: newContacts }
  const newCompaniesFormValues: CompanyFormValues[] = formValue.companiesFormValues.map((companyItem, companyIndex) =>
    companyIndex === toCompanyIndex ? newCompany : companyItem
  )
  const newFormValue: OnboardingFormsData = { ...formValue, companiesFormValues: newCompaniesFormValues }

  return { ...currentState, companiesDiff: newCompaniesFormValues, formValue: newFormValue }
}
