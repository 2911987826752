import React, { FC, memo } from 'react'

import { useRouter } from '@tanstack/react-router'
import { ApplicationStatus } from 'commons/types/enums'
import { handlerServerError } from 'errors'
import { Button } from 'ui/components/Buttons'
import { Toast } from 'ui/components/Toast'
import { useConfirmation } from 'ui/hooks'

import { useAddApplicationRelatedRecords } from '../../../../../../hooks/useAddApplicationRelatedRecords'
import { useGetOldApplication } from '../../../../../../hooks/useGetOldApplication'
import { useUpdateApplication } from '../../../../../../hooks/useUpdateApplication'
import { SendDocumentRequestProps } from './SendDocumentRequestProps'

export const SendDocumentRequest: FC<SendDocumentRequestProps> = memo(({ application }) => {
  const router = useRouter()

  const { getOldApplication, isGetOldApplicationLoading } = useGetOldApplication()
  const { updateApplication, isUpdateApplicationLoading } = useUpdateApplication()
  const { addApplicationRelated, isAddApplicationRelatedLoading } = useAddApplicationRelatedRecords()

  const onSendDocumentRequest = async () => {
    try {
      await addApplicationRelated(application.applicationID)
      // TODO: this is hot fix for tests
      // TODO: remove this after the backend creates separate endpoints
      const oldApplicationValue = await getOldApplication(application.applicationID)

      await updateApplication({ ...oldApplicationValue.records[0], status: ApplicationStatus.DOCUMENT_REQUEST_PENDING })

      Toast.success('Application has been saved')
      router.invalidate()
    } catch (error) {
      handlerServerError(error)
    }
  }

  const { Confirmation: SendDocumentRequestConfirmation, open: openSendDocumentRequestConfirmation } = useConfirmation({
    message: <span>Are you sure want to Send Document Request?</span>,
    onConfirm: () => onSendDocumentRequest(),
    confirmationButtonText: 'Send'
  })

  return (
    <>
      <SendDocumentRequestConfirmation />
      <Button
        face="positive"
        onClick={openSendDocumentRequestConfirmation}
        loading={isUpdateApplicationLoading || isAddApplicationRelatedLoading || isGetOldApplicationLoading}
      >
        Send Document Request
      </Button>
    </>
  )
})
