import {
  FilterCompanyAffiliatedCompaniesWebPage,
  getCompanyAffiliatedCompaniesWebPage
} from 'commons/service/bank/companyDetails'
import { CompanyAffiliatedCompaniesWebPage } from 'commons/types/DTO/bank/companyDetails/affiliatedCompany'
import { WriteHook, useMutationManager } from 'state-manager'

export const useFilterCompanyAffiliatedCompaniesWebPage: WriteHook<
  FilterCompanyAffiliatedCompaniesWebPage,
  CompanyAffiliatedCompaniesWebPage
> = (opts) => useMutationManager(getCompanyAffiliatedCompaniesWebPage, opts)
