import React, { FC, memo } from 'react'

import { HistoryTable } from 'bank-entity-history/src/components/HistoryTable'
import { TableDataQa } from 'commons/types/enums'
import { Layer } from 'ui/components'
import { PageSection } from 'ui/components/Page'

import { CompanyHistoryProps } from './CompanyHistoryProps'
import { useCompanyHistory } from './hooks/useCompanyHistory'

export const CompanyHistoryTable: FC<CompanyHistoryProps> = memo(
  ({ companyId, initialCompanyHistory, initialCompanyHistoryTotalCount }) => {
    const { loading, webPageData, tableState, changePagination } = useCompanyHistory(
      companyId,
      initialCompanyHistory,
      initialCompanyHistoryTotalCount
    )

    return (
      <PageSection title="Company History" anchor="history">
        <Layer rounded shadowed stretch>
          <HistoryTable
            dataQa={TableDataQa.BP_COMPANY_HISTORY}
            loading={loading}
            items={webPageData.history}
            pagination={{
              page: tableState.page,
              pageSize: tableState.size,
              onChange: changePagination,
              total: webPageData.historyTotalCount
            }}
          />
        </Layer>
      </PageSection>
    )
  }
)
