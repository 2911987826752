import { ReadHook, useStateManager } from 'react-query-state-manager'

import { Request } from 'commons/utils/request'

import { AlertsReportPayload, AlertsReportResponse } from '../types'

const getReport = (user?: AlertsReportPayload) =>
  Request.post<AlertsReportResponse, AlertsReportPayload>(Request.urls.bank.portalReportAlerts, user)

const ALERT_REPORT_KEY = 'alerts-report'

export const useGetAlertsReport: ReadHook<AlertsReportResponse, AlertsReportPayload> = (opts, callbacks) =>
  useStateManager().use([ALERT_REPORT_KEY, opts], () => getReport(opts), {
    keepPreviousData: true,
    onSuccess: callbacks?.onSuccess,
    onError: callbacks?.onError
  })
