export const getBatchFileName = (longName: string): string => {
  const fileName = longName.split('_id__')[1]

  if (fileName) {
    return fileName
  } else {
    const longNameArray = longName.split('/')
    return longNameArray[longNameArray.length - 1]
  }
}
