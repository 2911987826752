import { MutableRefObject, createContext } from 'react'

import { ChoiceValue } from 'ui/types'

export interface SelectContextValue {
  selectedIndex: number
  setSelectedIndex: (index: number) => void
  activeIndex: number | null
  setActiveIndex: (index: number | null) => void
  listRef: MutableRefObject<Array<HTMLElement | null>>
  setOption: (index: number, value: ChoiceValue) => void
  onChange: (value: ChoiceValue, error?: string) => void
}

export const SelectContext = createContext<SelectContextValue>({} as SelectContextValue)
