import { useState } from 'react'

export const useSelectedIndex = (index: number | null) => {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(index)

  return {
    selectedIndex,
    setSelectedIndex
  }
}
