import { FormApi, ReactFormApi } from '@tanstack/react-form'

import { DocumentStatuses } from '../enums/DocumentStatuses'
import { DocumentEditWebPageResponse } from './response.types'

export class ManageDocumentFormType {
  expirationDelayDays: number | undefined
  frequency: string | undefined
  internal: boolean | undefined
  name: string | undefined
  optional: boolean | undefined
  disablePeriodsCreation: boolean | undefined
  dueDateType: boolean | undefined
  startDateType: string | undefined
  status: DocumentStatuses
  subjectType: string | undefined
  notes: string | undefined

  licenseId: number | undefined
  companyId: number | undefined
  bankAccountId: number | undefined
  contactId: number | undefined

  constructor(
    isNew: boolean,
    form?: DocumentEditWebPageResponse['form'],
    presetSubject?: { id: number | undefined; type: string }
  ) {
    if (isNew) {
      this.name = undefined
      this.internal = undefined

      this.subjectType = presetSubject?.type || undefined
      this.companyId = presetSubject?.type === 'company' ? presetSubject?.id : undefined
      this.licenseId = presetSubject?.type === 'license' ? presetSubject?.id : undefined
      this.bankAccountId = presetSubject?.type === 'bank_account' ? presetSubject?.id : undefined
      this.contactId = presetSubject?.type === 'contact' ? presetSubject?.id : undefined

      this.frequency = 'annual'
      this.startDateType = undefined
      this.dueDateType = false
      this.expirationDelayDays = 0
      this.optional = false
      this.notes = ''
    } else {
      this.name = form?.name
      this.internal = form?.internal

      this.frequency = form?.frequency
      this.dueDateType = form?.dueDateType
      this.expirationDelayDays = form?.expirationDelayDays
      this.optional = form?.optional

      this.disablePeriodsCreation = form?.disablePeriodsCreation
      this.dueDateType = form?.dueDateType
      this.startDateType = form?.startDateType
    }
  }
}

export type ManageDocumentFormApiType = FormApi<ManageDocumentFormType, undefined> &
  ReactFormApi<ManageDocumentFormType, undefined>
