import { ReactNode } from 'react'

import { PanelFace } from './panelFace'

export interface PanelProps {
  title?: ReactNode
  children?: any
  expanded?: boolean
  onToggle?: (expanded: boolean) => void
  face?: PanelFace
  collapsible?: boolean
  actions?: ReactNode
  rounded?: boolean
  shadowed?: boolean
  stretch?: boolean
  anchor?: string
  isArrowButton?: boolean
  titleTagAs?: React.ElementType
  disabled?: boolean
  blockOnClickOnTitle?: boolean
}
