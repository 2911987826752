import styled, { css } from 'styled-components'
import { CSS } from 'ui/helpers'

import {
  getActiveBgColor,
  getActiveTextColor,
  getFocusBgColor,
  getFocusTextColor,
  getHoverBgColor,
  getHoverTextColor,
  getMainBgColor,
  getMainTextColor
} from './helpers'

export const Inner = styled.span`
  color: inherit;
  display: block;
`

const Box = styled.button<{ $overColoredPad: boolean }>`
  ${({
    $overColoredPad,
    theme: {
      uiKit: { borderRadius, colors, borderWidth, boxShadows }
    }
  }) => css`
    cursor: pointer;

    background-color: ${(p) => getMainBgColor(p.theme, $overColoredPad)};

    outline: solid ${colors.global.general.transparent} ${borderWidth.strokeM};

    border: solid ${$overColoredPad ? colors.global.general.main : colors.theme.brand.primary} ${borderWidth.strokeM};

    color: ${(p) => getMainTextColor(p.theme, $overColoredPad)};

    border-radius: ${borderRadius.roundedFull};

    transition: background 0.15s, color 0.15s, outline 0.15s, box-shadow 0.15s, border-color 0.15s;

    &:hover {
      background-color: ${(p) => getHoverBgColor(p.theme, $overColoredPad)};
      color: ${(p) => getHoverTextColor(p.theme, $overColoredPad)};
      border-color: ${colors.global.general.transparent};

      box-shadow: ${CSS.populateBoxShadow(boxShadows.offset.offset100Y4)};
    }

    &:focus-visible {
      background-color: ${(p) => getFocusBgColor(p.theme, $overColoredPad)};
      color: ${(p) => getFocusTextColor(p.theme, $overColoredPad)};
      border-color: ${colors.global.general.transparent};

      outline-color: ${colors.global.grey.grey500};

      box-shadow: ${CSS.populateBoxShadow(boxShadows.offset.offset100Y4)};
    }

    &:active {
      background-color: ${(p) => getActiveBgColor(p.theme, $overColoredPad)};
      color: ${(p) => getActiveTextColor(p.theme, $overColoredPad)};
      border-color: ${colors.global.general.transparent};

      box-shadow: none;
    }

    &:disabled {
      background-color: ${colors.global.grey.grey300};
      color: ${colors.global.grey.grey500};
      border: solid ${colors.global.general.transparent} ${borderWidth.strokeNone};
      cursor: not-allowed;
      box-shadow: none;
    }
  `}
`

export const BoxLarge = styled(Box)`
  ${({
    theme: {
      uiKit: { size, space, typography }
    }
  }) => css`
    height: ${size.buttonsHeight.large};
    padding-left: ${space.padding.headerButton.large};
    padding-right: ${space.padding.headerButton.large};

    ${Inner} {
      ${CSS.populateFont(typography.bodyL.buttonL)}
    }
  `}
`

export const BoxMiddle = styled(Box)`
  ${({
    theme: {
      uiKit: { size, space, typography }
    }
  }) => css`
    height: ${size.buttonsHeight.middle};
    padding-left: ${space.padding.headerButton.middle};
    padding-right: ${space.padding.headerButton.middle};

    ${Inner} {
      ${CSS.populateFont(typography.bodyM.button)}
    }
  `}
`
