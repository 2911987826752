import { useCallback, useEffect, useState } from 'react'

import { useFormData } from 'brief-form'
import { useSetupKey } from 'commons/hooks/bank/connections'
import { BankKeySetupResponse } from 'commons/types/DTO/bank/connections'
import { handlerServerError } from 'errors'

export const useCreateModal = (invalidateTable: () => Promise<void>, closeSetupKeyDialog: () => void) => {
  const { config, Field, set, validate } = useFormData<{ key: string }>({ initialValue: { key: '' } })

  const setupKey = useCallback(async () => {
    if (validate({ updateFields: true }).valid) {
      onSetupKey({ key: config.value.key })
    }
  }, [config])

  const [keySetupResult, setKeySetupResult] = useState<BankKeySetupResponse>(undefined)

  const { routine: onSetupKey, isLoading: isSetupPending } = useSetupKey({
    onSuccess: async (_, payload) => {
      setKeySetupResult(payload)
      invalidateTable()
    },
    onError: (error) => handlerServerError(error, { form: config })
  })

  const isKeySetuppingEnd = !!keySetupResult

  // setupping of key can be success if the only one or more licenses are passed
  const isSuccessSetuping: boolean =
    keySetupResult && keySetupResult.passedValidation && keySetupResult.passedValidation.length !== 0

  useEffect(() => {
    if (isKeySetuppingEnd) {
      set({ reset: true })
    }
  }, [set, isKeySetuppingEnd])

  const resetSetupKey = () => {
    setKeySetupResult(undefined)
  }

  const close = useCallback(() => {
    closeSetupKeyDialog()
    resetSetupKey()
    setKeySetupResult(undefined)
  }, [closeSetupKeyDialog])

  return {
    close,
    isKeySetuppingEnd,
    keySetupResult,
    isSetupPending,
    isSuccessSetuping,
    setupKey,
    resetSetupKey,
    config,
    Field
  }
}
