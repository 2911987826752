export { calculateFormValue } from './calculateFormValue'

export { getStateAfterCompanyAdded } from './getStateAfterCompanyAdded'
export { getStateAfterCompanyRemoved } from './getStateAfterCompanyRemoved'
export { getStateAfterAddedLicense } from './getStateAfterAddedLicense'
export { getStateAfterAddedContact } from './getStateAfterAddedContact'
export { getStateAfterAddedATM } from './getStateAfterAddedATM'
export { getStateAfterLicenseRemoved } from './getStateAfterLicenseRemoved'
export { getStateAfterContactRemoved } from './getStateAfterContactRemoved'
export { getStateAfterATMRemoved } from './getStateAfterATMRemoved'
export { mapApplicationToState } from './mapApplicationToState'
export { stateAfterCopyComapnyPhysicalAddressToMailing } from './stateAfterCopyComapnyPhysicalAddressToMailing'
export { handleSubmitErrors } from './handleSubmitErrors/handleSubmitErrors'
export { calculateNewState } from './calculateNewState'
export { getStateAfterContactCopy } from './getStateAfterContactCopy'
