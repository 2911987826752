import React, { FC } from 'react'

import { ApplicationRelationshipLicense } from 'commons/types/DTO/bank/applications'
import { ChipFace, FieldSet } from 'ui/components'

import * as SC from './styles'

export const RelationshipLicenses: FC<{ licenses: ApplicationRelationshipLicense[]; showErrors: boolean }> = ({
  licenses,
  showErrors
}) => {
  const unassignedFace = showErrors ? ChipFace.DANGER : ChipFace.DEFAULT
  return (
    <SC.BadgeSection>
      <FieldSet legend="Licenses">
        <SC.BadgesGroup>
          {licenses.map(({ name: licenseName, id, isAssigned }) => (
            <SC.BadgeText key={id} face={isAssigned ? ChipFace.PRIMARY : unassignedFace} children={licenseName} />
          ))}
        </SC.BadgesGroup>
      </FieldSet>
    </SC.BadgeSection>
  )
}
