import { Request } from 'commons/utils/request'
import { DocumentDetailsPage } from '~bank-documents/types/documentDetails'

type Options = { id: number; historyPage?: number; historySize?: number }

export const getDocumentDetails = (opts: Options) =>
  Request.post<DocumentDetailsPage, Options>(Request.urls.bank.documentDetailsPage, opts)

// export const documentDetailsQueryOptions = ({ id, historyPage = 1, historySize = 10 }: Options) =>
//   queryOptions({
//     queryKey: ['documents-details', { id, historySize, historyPage }],
//     queryFn: () => getDocumentDetails({ id, historySize, historyPage }),
//     placeholderData: keepPreviousData,
//     refetchOnWindowFocus: false
//   })
