import styled from 'styled-components'

export const Activity = styled.table`
  th {
    font-size: 14px;
    font-weight: normal;
    padding-bottom: 19px;
    text-align: center;

    &:first-child {
      text-align: left;
    }
  }

  td {
    font-size: 13px;
    padding-bottom: 29px;
    text-align: center;

    &:first-child {
      text-align: left;
    }
  }
`

export const Column1 = styled.th`
  width: 239px;
`

export const Column2 = styled.th`
  width: 248px;
`

export const Column3 = styled.th`
  width: 200px;
`
