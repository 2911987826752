import styled from 'styled-components'
import { CSS } from 'ui/helpers'

export const UserType = styled.div`
  ${(p) => CSS.populateFont(p.theme.uiKit.typography.bodyS.regular)};
  white-space: nowrap;
  color: #000 !important;
`

export const UserName = styled.div`
  ${(p) => CSS.populateFont(p.theme.uiKit.typography.bodyM.regular)}
  white-space: nowrap;
  color: ${(p) => p.theme.uiKit.colors.global.general.secondary};
`

export const HistoryChangesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px 0px;
`

export const ChangeSection = styled.div`
  display: flex;
  flex-direction: column;
`

export const DialogTitle = styled.h5`
  min-width: 400px;
  display: flex;
  flex-direction: row;
  align-items: center;
  > * {
    &:first-child {
      margin-left: 8px;
    }
  }
`
