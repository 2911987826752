import styled from 'styled-components'

export const Action = styled.span`
  cursor: pointer;
  display: inline-block;
  user-select: none;
  line-height: 0;
  margin: 0;
  padding: 0;
`

export const File = styled.p`
  display: flex;
  align-items: center;
  color: ${(p) => p.theme.uiKit.colors.global.grey.grey500};
`

export const UploadedFile = styled(File)`
  color: ${(p) => p.theme.uiKit.colors.global.general.secondary};
`
