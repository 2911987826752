import React, { FC, memo, useContext } from 'react'

import { ApplicationStatus, FormTitle, Stepper } from '..'

import { OnboardingFormContext } from '../OnboardingForm/OnboardingFormContext'

import * as SC from './styles'

export const WelcomeBlock: FC = memo(() => {
  const onboardingContext = useContext(OnboardingFormContext)
  const { state } = onboardingContext
  const { applicationStatus, availableSteps, currentStep } = state

  return (
    <SC.Wrapper>
      <FormTitle bankName={state.bankName} />
      <Stepper step={currentStep} availableSteps={availableSteps} />
      <ApplicationStatus applicationStatus={applicationStatus} />
    </SC.Wrapper>
  )
})
