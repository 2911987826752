import React, { memo } from 'react'

import { Request } from 'commons/utils/request'
import { Layer, Spinner, SpinnerSize } from 'ui/components'
import { Page, PageFace } from 'ui/components/Page'

import { ExportCSVButton, ReportBreadcrumbs } from '../../../components'
import { Filter, ReportTable } from './components'
import { useReportPage } from './useReportPage'

const ReportPage = memo(() => {
  const { data, isFetching, pagination, filter, sorting } = useReportPage()
  const { page } = filter.value

  if (isFetching && !page) {
    return <Spinner centered size={SpinnerSize.M} />
  }

  // Don't render page until filter contains values returned from API.
  if (!page || !data || page === null) {
    return null
  }

  const { statusOptions } = data

  return (
    <Page
      face={PageFace.PRIMARY}
      title={<ReportBreadcrumbs title="Client Portal Users Activity Report" />}
      actions={
        <ExportCSVButton
          reportName="Client Portal"
          url={Request.urls.bank.portalReportCompaniesCSV}
          parametersForm={{
            relationship_id: filter.value.relationshipId
          }}
        />
      }
    >
      <Layer rounded shadowed stretch>
        <Filter {...filter} statusOptions={statusOptions} />
        <ReportTable dataSource={data.items} pagination={pagination} sorting={sorting} />
      </Layer>
    </Page>
  )
})

export default ReportPage
