import { AnnualReview } from 'commons/types/DTO/bank'
import { Request } from 'commons/utils/request'

// eslint-disable-next-line import/no-unused-modules
export const deleteAnnualReviewCompany = ({ id }: { id: number }) =>
  Request.delete(Request.urls.bank.annualReview, { id, company_id: 0, annual_review_id: 0 })

// eslint-disable-next-line import/no-unused-modules
export const addAnnualReview = (annualReview: AnnualReview) =>
  Request.post<AnnualReview, AnnualReview>(Request.urls.bank.annualReview, annualReview)
