import styled from 'styled-components'

export const Label = styled.div`
  color: #fff;
  font-size: 13px;
  margin-bottom: 4px;
`

export const LoginCol = styled.div`
  width: 314px;
`

export const Footer = styled.div`
  margin-top: 30px;
`
