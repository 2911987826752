import { QueryClient, useMutation } from 'react-query'

import { BsaSar } from 'commons/types/DTO/bank/bsa'
import { Toast } from 'ui/components/Toast'

import * as Service from '../../service'

interface SaveError {
  message: string
  meta?: {
    organization_id?: number[]
  }
}

export const useSave = (client: QueryClient) => {
  return useMutation((sar: BsaSar) => Service.SAR.save(sar), {
    onError: (error: SaveError) => {
      let message = error.message
      if (error?.meta?.organization_id?.length) {
        message = `${error.message}. ${error?.meta?.organization_id[0]} organization_id`
      }
      Toast.errorSmall(message)
    },
    onSuccess: async (_, item) => {
      await client.invalidateQueries(['tables', 'SARs List'])
      await client.invalidateQueries(['sars', item.id])
    }
  })
}
