import { LicenseWebPageAddress, LicenseWebPageRelationship, LicenseWebPageTaxRates } from 'commons/types/DTO/bank'

export interface LicenseDetailsProps {
  id: number
  active: boolean
  createdAt: string
  issueDate: string
  name: string
  number: string
  phone: string
  posType: string
  subType: string
  type: string
  updatedAt: string
  relationship: LicenseWebPageRelationship
  taxRates: LicenseWebPageTaxRates
  address: LicenseWebPageAddress
  apiConnection: string
}
